import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { AppDispatch, RootState } from "app/store";
import { IconPlayerPlay } from "common/assets";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { BandageIcon } from "features/creator/assets";
import { selectProjectById } from "features/creator/slice";
import { updateProjectContentImmediately } from "features/creator/api";
import { EditProjectModal } from "features/creator/elements/EditProjectModal";

export const AppMenu = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [setting, setSetting] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const project_id = useSelector(
    (state: RootState) => state.creator.editingProjectId
  );
  const project = useSelector((state: RootState) =>
    selectProjectById(state, project_id)
  );
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleCollapse = () => {
    play();
    setCollapse(!collapse);
  };

  const handleOpenSetting = () => {
    play();
    btnDelay(() => setSetting(true));
  };
  const handleCloseSetting = () => {
    play();
    setSetting(false);
  };

  const handleRunPreview = () => {
    play();
    dispatch(updateProjectContentImmediately({ uid: user.active.uid }));
    btnDelay(() =>
      dispatch(
        actions.updateDialog({
          type: DialogType.PROJECT_PREVIEW,
          value: true,
          args: { projectId: project_id, isUserProject: "true" },
        })
      )
    );
  };

  return (
    <div
      className="flex-row-center !absolute top-[85%] z-[1000] origin-top-right"
      style={{ right: -255 * scale, transform: `scale(${scale})` }}
    >
      <EditProjectModal
        visible={setting}
        project={project}
        close={handleCloseSetting}
      />

      <div
        className="flex-row-view bg-white rounded-l-[16px] p-2 transition-transform duration-500"
        style={{
          transform: `translate(${collapse ? -48 + 16 : -255}px, 0)`,
          filter: "drop-shadow(3px 3px 3px rgba(63, 74, 97, 0.4))",
        }}
      >
        <div
          onClick={handleCollapse}
          className="flex-col-center w-[48px] h-[48px] !absolute top-[39px] left-[-48px] cursor-pointer"
        >
          <BandageIcon />
        </div>

        <div className="flex-col-el flex-center h-full relative upsilon">
          <button className="btn btn-primary" onClick={handleOpenSetting}>
            <div className="flex-col-el flex-center relative mb-[3px]">
              <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                <EditCalendarIcon sx={{ fontSize: 28, color: "white" }} />
              </div>
              <p className="text-body text-white text-[16px] whitespace-pre">
                {I18n.t("MSG_CREATOR_MENU_SET_APP_BTN")}
              </p>
            </div>
          </button>
        </div>

        <div className="flex-col-el flex-center h-full relative upsilon">
          <button className="btn btn-secondary" onClick={handleRunPreview}>
            <div className="flex-col-el flex-center relative mb-[3px]">
              <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                <IconPlayerPlay color="white" width={2} />
              </div>
              <p className="text-body text-white text-[16px] whitespace-pre">
                {I18n.t("MSG_CREATOR_MENU_RUN_APP_BTN")}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
