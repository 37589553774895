const ToolboxInfo = {
  maxInstances: {
    controls_repeat_internal: 2,
    character_motion_move: 1,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "controls_repeat_internal",
            extraState: {
              max: 2,
            },
          },
          {
            kind: "block",
            type: "character_motion_move",
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
