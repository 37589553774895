import {
  useRef,
  useState,
  useEffect,
  ChangeEventHandler,
  KeyboardEventHandler,
} from "react";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { animated, useSpring } from "@react-spring/web";
import I18n from "i18n-js";
import produce from "immer";
import AppsOutageOutlinedIcon from "@mui/icons-material/AppsOutageOutlined";
import AppSettingsAltOutlinedIcon from "@mui/icons-material/AppSettingsAltOutlined";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import { updateParentsUserAppConfig } from "app/api";
import Constants from "common/constant";
import { RouteProps } from "./type";
import { GoBackBtn } from "./GoBackBtn";

const ENABLE_LAB_PASSWORD = "SAWARU";

export const EnableLab = (props: RouteProps) => {
  const PASSWORD_LENGTH = 6;
  const ref = useRef<HTMLDivElement>(null);
  const [empty, setEmpty] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [disabled, setDisabled] = useState(false);
  const [animationStyles, animationApi] = useSpring(() => ({
    from: { x: 0 },
  }));
  const user = useSelector((state: RootState) => state.user.appUser);
  const [password, setPassword] = useState(["", "", "", "", "", ""]);
  const appConfig = useSelector((state: RootState) => state.config.appConfig);

  useEffect(() => {
    if (ref.current && ref.current.clientWidth === 0) {
      setEmpty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const handleGoBack = () => props.navigate("/");

  const handlePasswordChange = (
    index: number
  ): ChangeEventHandler<HTMLInputElement> => (e) => {
    setError(false);
    const next = e.target.nextElementSibling as HTMLInputElement;
    if (e.target.value || e.target.value === "") {
      setPassword(
        produce(password, (draft) => {
          draft[index] = e.target.value;
        })
      );
      if (next && e.target.value !== "") {
        next.focus();
      }
    }
  };

  const handleClick = () => {
    if (!disabled && !error) {
      setDisabled(true);
      if (password.join("").toUpperCase() === ENABLE_LAB_PASSWORD) {
        dispatch(
          actions.updateDialog({
            type: DialogType.LOADING,
            value: true,
          })
        );

        var newAppConfig = { ...appConfig, enable_lab: true };

        dispatch(actions.updateAppConfig(newAppConfig));
        dispatch(updateParentsUserAppConfig({ uid: user.main.uid }))
          .then(unwrapResult)
          .then(() => {
            setPassword(["", "", "", "", "", ""]);
            setEmpty(false);
            toast(
              <div>
                <p className="text text-textcolor/black">
                  登録が成功しました。
                </p>
              </div>
            );
          })
          .catch(() => {
            setError(true);
            animationApi.start({
              to: async (next, cancel) => {
                await next({ x: 10 });
                await next({ x: -10 });
                await next({ x: 10 });
                await next({ x: -10 });
              },
              config: { duration: 100 },
            });
          })
          .finally(() => {
            setDisabled(false);
            setTimeout(() => {
              dispatch(
                actions.updateDialog({
                  type: DialogType.LOADING,
                  value: false,
                })
              );
            }, 500);
          });
      } else {
        setError(true);
        setDisabled(false);
        animationApi.start({
          to: async (next, cancel) => {
            await next({ x: 10 });
            await next({ x: -10 });
            await next({ x: 10 });
            await next({ x: -10 });
          },
          config: { duration: 100 },
        });
      }
    }
  };

  const handleEnterKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.keyCode === 13 && !(password.length === 0 || error)) {
      handleClick();
    }
  };

  return (
    <div className="flex-col-center !justify-start !flex-1 w-full h-full">
      <div className="flex-col-center w-full px-6 gap-8 mt-[32px] mb-[30px]">
        <div className="flex-row-el flex-center ">
          <p className="text-body text-[20px] text-brown/100">
            『まなんでパズル』認証コード（企業提携コード）をお持ちの方は、下記に指定されたコードを入力してください。
          </p>
        </div>

        <div className="relative flex-row-el gap-4 flex-center w-full py-6 bg-white/40 rounded-[8px]">
          <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
            <AppSettingsAltOutlinedIcon />
            <p className="text text-textcolor/black !text-[16px] !px-[8px]">
              認証コード入力
            </p>
          </div>

          <div className="flex-col-center">
            <p className="text text-textcolor/black !leading-[30px]">
              {I18n.t("MSG_SETTING_PARENTS_LINK_ADD_USER_AUTH_CODE_TITLE")}
            </p>
            <animated.div
              className="flex-row-center"
              style={animationStyles}
              onKeyDown={handleEnterKeyDown}
            >
              {Array(PASSWORD_LENGTH)
                .fill(0)
                .map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={password[index]}
                    onChange={handlePasswordChange(index)}
                    className={`relative flex-col-el flex-center w-14 outline-none appearance-none overflow-hidden border-solid border-[2px] border-l-0 border-gray/40 rounded-none first:border-l-[2px] first:rounded-l-[8px] last:rounded-r-[8px] text-body text-textcolor/black text-center text-[32px] ${
                      error && "!border-danger"
                    }`}
                  />
                ))}
            </animated.div>
          </div>

          <div className="flex-row-el flex-center mt-8 alpha">
            <button
              onClick={handleClick}
              className="btn btn-primary"
              disabled={password.length === 0 || error}
            >
              <p className="text-body text-white text-[24px] !mb-[3px]">決定</p>
            </button>
          </div>
        </div>

        <div className="relative flex-row-el gap-4 items-center justify-start w-full bg-white/40 p-8 rounded-[8px]">
          <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
            <AppsOutageOutlinedIcon />
            <p className="text text-textcolor/black !text-[16px] !px-[8px]">
              許可されたコンテンツ（カセット）
            </p>
          </div>

          <div ref={ref} className="flex-row-center gap-2 h-[168px]">
            {appConfig.enable_lab && (
              <div className="flex-col-center w-[136px] h-[168px]">
                <img
                  alt="タンジブル"
                  src={`${Constants.assetHost}/assets/images/course_soft_tangible.png`}
                  className="w-full h-full"
                />
              </div>
            )}
          </div>

          {empty && (
            <div className="flex-row-el flex-center absolute -z-1 w-full">
              <p className="text-body text-[24px] text-textcolor/black">
                許可された追加コンテンツ（カセット）はありません。
              </p>
            </div>
          )}
        </div>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
