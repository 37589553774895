/* eslint-disable no-new-func */
import { useState, useEffect, PointerEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import * as Blockly from "blockly";
import utc from "dayjs/plugin/utc";
import { Dialog } from "@mui/material";
import timezone from "dayjs/plugin/timezone";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { IconRotate } from "common/assets";
import { FadeTransition } from "common/elements";
import { useScale, usePlaySound } from "common/utils";
import {
  updateProgramLevelAnswer,
  updateProgramLevelProgresses,
} from "features/courses/api";
import { actions } from "features/courses/coursesSlice";
import { Failed } from "./Failed";
import { Success } from "./Success";

export const RuntimeLayout = ({
  sound,
  stage,
  step,
  isFinished,
  question,
  event,
  workspace,
  reset,
  Runtime,
}: {
  sound: () => void;
  stage: number;
  step: number;
  isFinished: boolean;
  question: string;
  event: string[];
  workspace: Blockly.WorkspaceSvg;
  reset: PointerEventHandler<HTMLButtonElement>;
  Runtime: any;
}) => {
  const { scale } = useScale();
  const SHOW_RESULT_DELAY = 1000;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [running, setRunning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [size, setSize] = useState({ x: 0, y: 0 });
  const [showResult, setShowResult] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const user = useSelector((state: RootState) => state.user.appUser);
  const summary = useSelector(
    (state: RootState) => state.courses.progresses.program.summary
  );

  const failureSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/failure.mp3`
  );
  const levelclearSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/levelclear.mp3`
  );
  const levelclearCheersSound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/levelclear_cheers.mp3`
  );

  const highlightBlock = (id: string, opt_state: boolean) => {
    if (opt_state) {
      sound();
    }
    if (!workspace.getBlockById(id)) {
      const xml = Blockly.Xml.workspaceToDom(workspace);
      const xml_text = Blockly.Xml.domToText(xml);
      console.error(
        `failed to get block: stage:${stage}, step:${step}, xml_text: ${xml_text}`
      );
    } else {
      workspace.getBlockById(id).setHighlighted(opt_state);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("courses-program-display-panel");
      const rect = panel.getBoundingClientRect();
      setPosition({ x: rect.x, y: rect.y });
      setSize({ x: rect.width, y: rect.height });
      if (workspace) {
        workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
          block.setMovable(false);
          block.setEditable(false);
        });
      }
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  useEffect(() => {
    if (gameOver) {
      const stamp = document.getElementById("course-program-level-clear-stamp");
      if (stamp) {
        stamp.style.zIndex = "10102";
      }
      if (success) {
        setTimeout(() => {
          setShowResult(true);
          levelclearSound();
          levelclearCheersSound();
        }, SHOW_RESULT_DELAY);

        const xml = Blockly.Xml.workspaceToDom(workspace);
        const answer = Blockly.Xml.domToText(xml);

        if (!isFinished) {
          dayjs.extend(utc);
          dayjs.extend(timezone);
          const now = dayjs().tz("Asia/Tokyo").format("YYYY-MM-DDTHH:mm:ssZ"); // 現状は強制的にJSTにする、今後UTCにする

          dispatch(
            actions.updateProgramLevelProgresses({
              stageId: stage,
              stepId: step,
              totalFinished: summary.finished + 1,
              answer,
              finishedAt: now,
            })
          );
          updateProgramLevelProgresses({
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            totalFinished: summary.finished + 1,
            answer,
            finishedAt: now,
          });
        } else {
          dispatch(
            actions.updateProgramLevelAnswer({
              stageId: stage,
              stepId: step,
              answer,
            })
          );
          updateProgramLevelAnswer({
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            answer,
          });
        }
      } else {
        setTimeout(() => {
          setShowResult(true);
          failureSound();
        }, SHOW_RESULT_DELAY);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameOver]);

  const handleBack = () => {
    navigate(`/courses/program/stage/${stage}`);
  };

  const handleReset: PointerEventHandler<HTMLButtonElement> = (e) => {
    if (!running) {
      reset(e);
    }
  };

  return (
    <>
      {showResult && (
        <Dialog
          open
          fullScreen
          maxWidth={false}
          sx={{ zIndex: 10102 }}
          TransitionComponent={FadeTransition}
          componentsProps={{
            backdrop: {
              style: { backgroundColor: "transparent", pointerEvents: "none" },
            },
          }}
          PaperProps={{ style: { backgroundColor: "transparent" } }}
          transitionDuration={{ enter: 1000, exit: 500 }}
        >
          {success && (
            <Success
              scale={scale}
              handleBack={handleBack}
              handleReset={reset}
            />
          )}
          {!success && <Failed scale={scale} handleReset={reset} />}
        </Dialog>
      )}

      {position.x !== 0 && (
        <div className="flex-col-el flex-center absolute top-0 left-0 w-full h-full z-[10002] pointer-events-auto bg-textcolor/black/50">
          <div
            style={{
              width: size.x,
              height: size.y,
              borderWidth: 8 * scale,
              borderRadius: 6 * scale,
              transform: `translate(${position.x}px, ${position.y}px)`,
            }}
            className="flex-col-el flex-center absolute top-0 left-0 border-solid border-[#b2771f]"
          >
            <div className="flex-col-center overflow-visible w-full h-full z-10">
              <img
                alt="courses blackboard"
                className="w-full h-full absolute top-0 left-0"
                src={`${Constants.assetHost}/assets/images/blackboard.png`}
              />
            </div>

            <div
              className="flex-row-el items-center w-full absolute top-0 left-0 z-50 origin-top-left"
              style={{ transform: `scale(${scale})` }}
            >
              <div className="flex-row-center">
                <div className="flex-row-el items-center h-[48px] bg-white/80 border-solid border-[1px] border-l-0 border-white rounded-r-[8px]">
                  <div className="flex-col-el flex-center shrink-0 w-[48px] h-[48px] bg-red/100 rounded-br-[10px]">
                    <p className="title-text text-white !text-[39px]">Q</p>
                  </div>

                  <div className="flex-col-center shrink-0 ml-[12px] pr-2">
                    <p className="text-body text-textcolor/black text-left text-[20px]">
                      {question}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex-col-el flex-center origin-top absolute top-0 z-50 w-[800px] h-[360px]"
              style={{ transform: `scale(${scale})` }}
            >
              <Runtime
                event={event}
                highlightBlock={highlightBlock}
                setRunning={setRunning}
                setSuccess={setSuccess}
                gameOver={gameOver}
                setGameOver={setGameOver}
              />
            </div>
          </div>

          {stage > 2 && (
            <div
              className="flex-col-el flex-center absolute bottom-0 origin-top-left w-[250px]"
              style={{
                bottom: 20 * scale,
                left: position.x + (size.x - 250 * scale) / 2,
                transform: `scale(${scale})`,
              }}
            >
              <div className="flex-row-el flex-center relative alpha">
                <button
                  disabled={running}
                  onPointerDown={handleReset}
                  className="btn btn-danger"
                >
                  <div className="flex-row-center gap-1 mb-[3px]">
                    <div className="flex-col-center w-[40px] h-[40px]">
                      <IconRotate color="white" width={2} />
                    </div>

                    <div className="flex-col-center">
                      <p className="text-body text-white text-[36px]">
                        {I18n.t("MSG_COURSE_GAME_RESET_BTN")}
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
