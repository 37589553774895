import Constants from "common/constant";

export const Step2 = () => {
  return (
    <div className="flex-row-view w-full h-full">
      <div className="flex-row-view mt-[30px] w-full h-[400px]">
        <div className="flex-col-center w-[514px] h-full ml-[39px] mr-[48px]">
          <p className="text text-textcolor/black">
            「<span className="text text-safe">コースでまなぶ</span>」では、
            <br />
            プログラミングの基礎を
            <br />
            ブロックパズル形式で学びます。
            <br />
            <br />
            ブロックを組み合わせて
            <br />
            キャラクターを操作しゴールへ導く、
            <br />
            ゲームのような感覚で
            <br />
            ロジカルシンキングと
            <br />
            プログラミングに必要なコマンドの
            <br />
            特徴を段階的に理解することができます。
          </p>
        </div>
        <div className="flex-row-center w-[400px] h-full">
          <img
            alt="コースでまなぶ"
            src={`${Constants.assetHost}/assets/images/course_top_icon_capture.png`}
            className="w-[357px] h-[272px]"
          />
        </div>
      </div>
    </div>
  );
};
