import { useState, FormEvent, PointerEvent } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import Visibility from "@mui/icons-material/Visibility";
import { Button, Dialog, IconButton } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { parentsFirebase } from "app/Auth";
import { DialogType, LocalStorageKey } from "app/types";
import { CloseBtn, WarningMessageBox } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";

export const Resign = ({
  redirect,
  navigate,
  close,
}: {
  redirect: string;
  navigate: (path: string) => void;
  close: () => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const [required, setRequired] = useState(false);
  const [signError, setSignError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser.main);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const email = data.get("email").toString();
      const password = data.get("password").toString();
      if (email && password) {
        handleDialog({ type: DialogType.LOADING, value: true });
        const auth = getAuth(parentsFirebase);
        signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            toast(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_SIGN_IN_SUCCESS_CONTENT")}
                </p>
              </div>
            );
            localStorage.setItem(
              LocalStorageKey.LAST_SIGN_AT,
              dayjs().unix().toString()
            );
            navigate(redirect);
          })
          .catch((error) => {
            setSignError(true);
            console.error(error);
          })
          .finally(() =>
            handleDialog({ type: DialogType.LOADING, value: false })
          );
      }
    } catch (error) {
      console.error(error);
      handleDialog({ type: DialogType.LOADING, value: false });
    }
  };

  const handleGotoResetPassword = (e: PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    btnDelay(() => navigate("/mailResetPassword"));
  };

  const handleClickShowPassword = () => {
    play();
    setShowPassword(!showPassword);
  };

  const handleCloseNotFound = () => {
    play();
    setSignError(false);
  };

  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 100000 }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-center !absolute inset-0 bg-textcolor/black/50 w-full h-full">
        <div
          className="flex-col-view items-center w-[500px] h-[550px] rounded-[8px] border-[8px] border-solid border-white bg-white"
          style={{ transform: `scale(${scale})` }}
        >
          <CloseBtn close={close} />
          <div className="flex-col-el flex-center w-full h-full bg-beige/60">
            <div className="flex-col-center mx-8 mb-4 cursor-pointer">
              <div
                className="flex-col-center bg-white rounded-full mb-2 w-[160px] h-[160px]"
                style={{
                  boxShadow:
                    "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                }}
              >
                <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                  <img
                    key={user.icon}
                    alt="ユーザプロフィール画像"
                    src={user.icon}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>

              <div
                className="flex-col-center w-[250px] h-[40px] bg-beige/10 rounded-[32px] text-textcolor/black mt-[-20px]"
                style={{
                  boxShadow:
                    "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                }}
              >
                <p className="text">{user.display_name}</p>
              </div>
            </div>

            <div className="flex-col-center gap-3">
              <div className="flex-col-el items-center justify-around">
                {signError && (
                  <WarningMessageBox
                    zIndex={999999}
                    title={I18n.t("MSG_SIGN_IN_FAILED_TITLE")}
                    message={I18n.t("MSG_SIGN_IN_FAILED_MESSAGE")}
                    close={handleCloseNotFound}
                  />
                )}

                <div className="flex-col-center w-[375px] h-[300px]">
                  <form
                    className="flex-col-el w-full gap-5"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex-col-el">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        required={required}
                        defaultValue={user.email}
                        readOnly={true}
                        className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
                        autoFocus={false}
                      />
                    </div>

                    <div className="flex-row-el relative items-center">
                      <input
                        id="password"
                        name="password"
                        required={required}
                        type={showPassword ? "text" : "password"}
                        className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
                        placeholder={I18n.t("MSG_SIGN_USER_PASSWORD")}
                        autoFocus={false}
                        onChange={() => setRequired(false)}
                      />
                      <div className="flex-col-el absolute right-0 pr-3">
                        <IconButton
                          onClick={handleClickShowPassword}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    </div>

                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{ height: 40, borderRadius: 2 }}
                      onClick={() => setRequired(true)}
                    >
                      <p className="text-body text-[16px] text-white">
                        {I18n.t("MSG_SIGN_IN_BTN")}
                      </p>
                    </Button>

                    <div className="flex-row-el justify-end">
                      <Button
                        color="primary"
                        onPointerDown={handleGotoResetPassword}
                      >
                        {I18n.t("MSG_SIGN_IN_FORGOT_PASSWORD")}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
