import { useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { useScale, usePlaySound } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";

export const Subscription = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const subscription = useSelector(
    (state: RootState) => state.config.dialog.subscription
  );
  const handleClose = () => {
    play();
    handleDialog({ type: DialogType.SUBSCRIPTION, value: false });
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 99999 }}
      open={subscription.value}
      TransitionComponent={FadeTransition}
    >
      <div className="flex-col-center !absolute inset-0 bg-textcolor/black/50 w-full h-full">
        <div
          className="flex-col-view items-center justify-start w-[1180px] h-[890px] rounded-lg border-[8px] border-solid border-white bg-white"
          style={{ transform: `scale(${scale})`, marginTop: 32 * scale }}
        >
          <CloseBtn close={handleClose} />
          <div className="flex-col-el w-full h-full relative rounded-[4px] bg-white overflow-hidden"></div>
        </div>
      </div>
    </Dialog>
  );
};
