import I18n from "i18n-js";
import { Category, Block, Value, Shadow, Field, Label } from "common/blockly";

export const Position = () => {
  return (
    <Category
      name="shape"
      categorystyle="text_category"
      toolboxitemid="position"
    >
      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_ZINDEX")}
        web-class="toolbox-label"
      />
      <Block type="component_property_setter_order">
        <Field name="value">1</Field>
      </Block>
      <Block type="component_property_setter_order">
        <Field name="value">0</Field>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_X")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_x" />
      <Block type="component_property_setter_x">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">0</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_x_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_Y")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_y" />
      <Block type="component_property_setter_y">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">0</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_y_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Block type="component_property_setter_x_y">
        <Value name="x">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
        <Value name="y">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
    </Category>
  );
};
