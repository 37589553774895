import { useRef, useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import I18n from "i18n-js";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { CloseBtnOuter } from "common/elements";
import { GuideMessageBalloonSvg } from "features/courses/assets";
import { StepProps } from "./type";

const Step = (props: StepProps) => {
  const { scale } = useScale();
  const { message, close } = props;
  const ref = useRef<HTMLDivElement>();
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [arrowOffset, setArrowOffset] = useState({ x: 0, y: 0 });
  const [animationStyles, animationApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const handleArrowAnimation = () =>
    animationApi.start({
      to: [
        { opacity: 1 },
        { opacity: 0 },
        { opacity: 1 },
        { opacity: 0 },
        { opacity: 1 },
      ],
      config: { duration: 1000 },
    });

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = message;

      const startBtnElement = document.getElementById("game-scene-start-btn");
      if (startBtnElement) {
        const rect = startBtnElement.getBoundingClientRect();
        setOffset({
          x: rect.x + rect.width / 2,
          y: rect.y - 250 * scale,
        });
        setArrowOffset({
          x: rect.x + rect.width / 2 - 50 * scale,
          y: rect.y + 30 * scale,
        });

        handleArrowAnimation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <>
      <div
        className="flex-col-view !absolute top-0 left-0 z-[100]"
        style={{
          width: 109 * scale,
          height: 109 * scale,
          transform: `translate(${arrowOffset.x}px, ${arrowOffset.y}px)`,
        }}
      >
        <div
          className="flex-col-view w-[109px] h-[109px] origin-top-left"
          style={{
            transform: `rotate(270deg) scale(${scale})`,
          }}
        >
          <animated.div
            className="w-full h-full"
            style={{ ...animationStyles }}
          >
            <img
              alt="*"
              className="w-full h-full"
              src={`${Constants.assetHost}/assets/images/arrow.png`}
            />
          </animated.div>
        </div>
      </div>
      <div
        className="flex-col-view !absolute top-0 left-0"
        style={{
          opacity: offset.x * offset.y === 0 ? 0 : 1,
          width: 380 * scale,
          height: 228 * scale,
          transform: `translate(${offset.x}px, ${offset.y}px)`,
        }}
      >
        <div
          className="flex-col-view items-center origin-top-left w-[380px] h-[228px]"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-el absolute top-2 right-2">
            <CloseBtnOuter close={close} />
          </div>

          <div className="flex-col-view !absolute top-0 left-0 w-[380px] h-[228px]">
            <GuideMessageBalloonSvg />
          </div>

          <div className="flex-col-center top-[45px] w-[380px] h-[107px]">
            <p
              ref={ref}
              className="text !leading-[30px] text-textcolor/black"
            />
          </div>

          <div className="flex-row-el flex-center gap-4 absolute bottom-6 alpha">
            <button className="btn btn-primary" onClick={close}>
              <p className="text-body text-white text-[20px] !mb-[3px]">
                {I18n.t("MSG_COURSE_GAME_TIPS_ACTION_BTN")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step;
