import I18n from "i18n-js";
import Constants from "common/constant";
import {
  ProgressArrow,
  ProgressStep1,
  ProgressStep2,
  ProgressStep3,
  ProgressStep4,
  ProgressStep5,
  ProgressStep6,
} from "features/tutorial/assets";
import { PageProps } from "features/tutorial/types";

interface LayoutProps extends PageProps {
  navigate: (step: number) => () => void;
  step: number;
  isInApp?: boolean;
  children: any;
}

export const StepProgressLayout = (props: LayoutProps) => {
  const { navigate, next, close, step, children, isInApp } = props;
  return (
    <div className="flex-col-view items-center w-full h-full">
      <img
        alt="title logo"
        src={`${Constants.assetHost}/assets/images/title_logo.png`}
        className="w-[356px] h-[80px] mt-[24px]"
      />

      <div className="flex-row-view items-center justify-around w-[969px] my-[16px]">
        <div className="flex-col-center" onClick={navigate(1)}>
          <ProgressStep1
            fill={step === 1 ? "#2BDC98" : step > 1 ? "#B7D8F9" : "#DBD8DA"}
          />
          <div className="flex-col-center w-[200px] h-full !absolute top-0">
            <p
              className={`text !text-[16px] ${
                step >= 1 ? "text-textcolor/black" : "text-gray2/80"
              }`}
            >
              {I18n.t("MSG_TOP_TUTORIAL_STEP_TITLE_1")}
            </p>
          </div>
        </div>

        <ProgressArrow fill={step === 1 ? "#FDA3A0" : "#DBD8DA"} />

        <div className="flex-col-center" onClick={navigate(2)}>
          <ProgressStep2
            fill={step === 2 ? "#2BDC98" : step > 2 ? "#B7D8F9" : "#DBD8DA"}
          />
          <div className="flex-col-center w-[200px] h-full !absolute top-0">
            <p
              className={`text !text-[16px] ${
                step >= 2 ? "text-textcolor/black" : "text-gray2/80"
              }`}
            >
              {I18n.t("MSG_TOP_TUTORIAL_STEP_TITLE_2")}
            </p>
          </div>
        </div>

        <ProgressArrow fill={step === 2 ? "#FDA3A0" : "#DBD8DA"} />

        <div className="flex-col-center" onClick={navigate(3)}>
          <ProgressStep3
            fill={step === 3 ? "#2BDC98" : step > 3 ? "#B7D8F9" : "#DBD8DA"}
          />
          <div className="flex-col-center w-[200px] h-full !absolute top-0">
            <p
              className={`text !text-[16px] ${
                step >= 3 ? "text-textcolor/black" : "text-gray2/80"
              }`}
            >
              {I18n.t("MSG_TOP_TUTORIAL_STEP_TITLE_3")}
            </p>
          </div>
        </div>

        <ProgressArrow fill={step === 3 ? "#FDA3A0" : "#DBD8DA"} />

        <div className="flex-col-center" onClick={navigate(4)}>
          <ProgressStep4
            fill={step === 4 ? "#2BDC98" : step > 4 ? "#B7D8F9" : "#DBD8DA"}
          />
          <div className="flex-col-center w-[200px] h-full !absolute top-0">
            <p
              className={`text !text-[16px] ${
                step >= 4 ? "text-textcolor/black" : "text-gray2/80"
              }`}
            >
              {I18n.t("MSG_TOP_TUTORIAL_STEP_TITLE_4")}
            </p>
          </div>
        </div>

        <ProgressArrow fill={step === 4 ? "#FDA3A0" : "#DBD8DA"} />

        <div className="flex-col-center" onClick={navigate(5)}>
          <ProgressStep5
            fill={step === 5 ? "#2BDC98" : step > 5 ? "#B7D8F9" : "#DBD8DA"}
          />
          <div className="flex-col-center w-[200px] h-full !absolute top-0">
            <p
              className={`text !text-[16px] ${
                step >= 5 ? "text-textcolor/black" : "text-gray2/80"
              }`}
            >
              {I18n.t("MSG_TOP_TUTORIAL_STEP_TITLE_5")}
            </p>
          </div>
        </div>

        <ProgressArrow fill={step === 5 ? "#FDA3A0" : "#DBD8DA"} />

        <div className="flex-col-center" onClick={navigate(6)}>
          <ProgressStep6
            fill={step === 6 ? "#2BDC98" : step > 6 ? "#B7D8F9" : "#DBD8DA"}
          />
          <div className="flex-col-center w-[200px] h-full !absolute top-0">
            <p
              className={`text !text-[16px] ${
                step >= 6 ? "text-textcolor/black" : "text-gray2/80"
              }`}
            >
              {I18n.t("MSG_TOP_TUTORIAL_STEP_TITLE_6")}
            </p>
          </div>
        </div>
      </div>

      <div className="flex-col-view w-[1040px] h-[536px] bg-white rounded-[12px]">
        <div className="flex-col-view w-full h-full !absolute top-0 left-0 pointer-events-none border-solid border-beige/alt border-[1px] rounded-[12px]" />
        {children}

        <div className="flex-row-el flex-center w-full gap-14 absolute bottom-7 alpha">
          <button className="btn btn-blue" onClick={close}>
            <p className="text-body text-white text-[24px] !mb-[3px]">
              {I18n.t("MSG_TOP_TUTORIAL_CLOSE_PAGE")}
            </p>
          </button>

          {(!isInApp || step !== 6) && (
            <button
              className="btn btn-primary"
              onClick={step === 6 ? close : next}
            >
              <p className="text-body text-white text-[24px] !mb-[3px]">
                {step === 6
                  ? I18n.t("MSG_TOP_TUTORIAL_START_APP")
                  : I18n.t("MSG_TOP_TUTORIAL_NEXT_PAGE")}
              </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
