import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Howl } from "howler";
import * as Blockly from "blockly";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { sleep, useScale, usePlaySound, btnDelay } from "common/utils";
import { useGameSound } from "features/courses/hook";
import {
  performMove,
  performJump,
  performTurnToLeft,
  performTurnToRight,
} from "features/courses/algorithm/game/scene/animations";
import {
  Failed,
  offset,
  Success,
  StartBtn,
  ResetBtn,
} from "features/courses/algorithm/game/scene/components";
import { checkBtnColor } from "features/courses/algorithm/game/scene/maps";
import StageMaps from "./maps";
import { GameScene } from "./GameScene";

export const MainScenePage = ({
  stage,
  step,
  reload,
  setReload,
  scene,
  gameStart,
  setGameStart,
  setScene,
  event,
  workspace,
  setLoading,
}: {
  stage: number;
  step: number;
  reload: boolean;
  setReload: (reload: boolean) => void;
  scene: Scene;
  gameBgm: Howl | null;
  gameStart: boolean;
  setGameStart: (gameStart: boolean) => void;
  setScene: (scene: Scene) => void;
  workspace: Blockly.WorkspaceSvg;
  event: string;
  setLoading: (loading: boolean) => void;
}) => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const gameSound = useGameSound();
  const { scale, width, height } = useScale();
  const [gemScore, setGemScore] = useState(0);
  const [success, setSuccess] = useState(false);
  const stepMap = StageMaps[stage - 1][step - 1];
  const [gameOver, setGameOver] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [character, setCharacter] = useState<AbstractMesh | null>(null);

  useEffect(() => {
    if (gameOver) {
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(true);
        block.setEditable(true);
      });
      if (success) {
        setSuccessShow(true);
        gameSound.levelclearSound();
        gameSound.levelclearCheersSound();
      } else {
        gameSound.failureSound();
      }
    }
  }, [gameOver]);

  useEffect(() => {
    gameSound.itemGetSound();
  }, [gemScore]);

  const handlePlusGemScore = (scene: Scene, totalGem: number) => {
    setGemScore((prevScore) => {
      const newScore = prevScore + 1;
      if (newScore === totalGem) {
        setSuccess(true);
        scene.metadata = { result: true };
      } else {
      }
      return newScore;
    });
  };

  const highlightBlock = (id: string, opt_state: boolean) => {
    if (!scene.isDisposed) {
      if (workspace.getBlockById(id)) {
        workspace.getBlockById(id).setHighlighted(opt_state);
      }
    }
  };

  const handleStart = () => {
    btnDelay(() => {
      gameSound.startSound();
      setGameStart(true);
      scene.metadata = { result: false };

      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(false);
        block.setEditable(false);
      });
      try {
        const handleEvent = new Function(
          "highlightBlock",
          "sleep",
          "scene",
          "character",
          "stepMap",
          "offset",
          "performMove",
          "performJump",
          "performTurnToLeft",
          "performTurnToRight",
          "moveSound",
          "jumpSound",
          "turnSound",
          "checkBtnColor",
          "setGameOver",
          "setSuccess",
          event
            ? event
            : `return () => {
            setGameOver(true);
          }
          `
        );
        handleEvent(
          highlightBlock,
          sleep,
          scene,
          character,
          stepMap,
          offset,
          performMove,
          performJump,
          performTurnToLeft,
          performTurnToRight,
          gameSound.moveSound,
          gameSound.jumpSound,
          gameSound.turnSound,
          checkBtnColor,
          setGameOver,
          setSuccess
        )();
      } catch (error) {
        const xml = Blockly.Xml.workspaceToDom(workspace);
        const xml_text = Blockly.Xml.domToText(xml);
        console.log(event);
        console.log(error);
        console.log(xml_text);
      }
    });
  };

  const handleReset = () => {
    btnDelay(() => {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGemScore(0);
      setLoading(true);
      setSuccess(false);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setSuccessShow(false);
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setHighlighted(false);
        block.setMovable(true);
        block.setEditable(true);
      });
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      setSuccessShow(false);
      navigate("/courses");
    });
  };

  return (
    <>
      {gameOver && !success && <Failed scale={scale} reset={handleReset} />}

      {successShow && (
        <Success scale={scale} back={handleBack} reset={handleReset} />
      )}

      <div className="flex-col-view items-center w-full h-full pointer-events-none">
        <div className="flex-col-center w-full h-full pointer-events-none">
          <GameScene
            width={width}
            height={height}
            scale={scale}
            map={stepMap}
            reload={reload}
            config={{
              setLoading,
              setScene,
              setCharacter,
              handlePlusGemScore,
            }}
          />
        </div>

        {!gameStart ? (
          <StartBtn scale={scale} disabled={false} start={handleStart} />
        ) : (
          <ResetBtn scale={scale} reset={handleReset} />
        )}
      </div>
    </>
  );
};

export default MainScenePage;
