import { btnDelay } from "common/utils";
import { RouteProps } from "../type";
import { Purchase } from "./Purchase";
import { GoBackBtn } from "../GoBackBtn";

export const Subscription = (props: RouteProps) => {
  const { navigate } = props;

  const handleGoBack = () => btnDelay(() => navigate("/"));

  return (
    <div className="flex-col-center w-full h-full p-4 pb-0">
      <div className="flex-row-el w-full flex-grow overflow-hidden gap-2">
        <Purchase />
      </div>

      <div className="flex-row-el">
        <GoBackBtn handleGoBack={handleGoBack} />
      </div>
    </div>
  );
};
