import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import anime from "animejs";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { RootState, AppDispatch } from "app/store";
import { TutorialArrow } from "common/assets";
import { DeleteMessageBox } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { removeProject } from "features/creator/api";
import { DisplayMode, ProjectType } from "features/creator/types";
import { actions, selectAllProjects } from "features/creator/slice";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const { next, close } = props;
  const handleDialog = useDialog();
  const dispatch = useDispatch<AppDispatch>();
  const [deleted, setDeleted] = useState(false);
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const sortedProjects = projects
    .filter(
      (project) =>
        project.author_uid === user.active.uid &&
        !project.deleted_at &&
        project.type === ProjectType.BASIC
    )
    .sort((a, b) => (dayjs(a.created_time) < dayjs(b.created_time) ? -1 : 1));
  const selectedProject = sortedProjects[sortedProjects.length - 1];

  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-display-delete-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 10 * scale,
        y: rect.y - 20 * scale,
        width: rect.width + 20 * scale,
        height: rect.width + 20 * scale,
      });
    }, 300);
  }, [scale]);

  const handleClick = () => {
    play();
    btnDelay(() => {
      setDeleted(true);
    });
  };
  const handleDeleteProject = () => {
    setDeleted(false);
    handleDialog({ type: DialogType.LOADING, value: true });
    anime({
      targets: document.getElementById(
        `user-app-project-container-${selectedProject.project_id}`
      ),
      scale: 0.8,
      opacity: 0,
      duration: 400,
      easing: "easeInOutQuad",
    }).finished.then(() => {
      dispatch(actions.updateDisplayMode(DisplayMode.NONE));
      dispatch(
        removeProject({
          uid: user.active.uid,
          project_id: selectedProject.project_id,
        })
      )
        .then(unwrapResult)
        .then(() =>
          toast.success(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_TOAST_DELETED_SUCCESS")}
              </p>
            </div>
          )
        )
        .catch((error) => {
          console.error(error);
          anime({
            targets: document.getElementById(
              `user-app-project-container-${selectedProject.project_id}`
            ),
            scale: 1,
            opacity: 1,
            duration: 400,
            easing: "easeInOutQuad",
          });
          toast.success(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_TOAST_FAILED")}
              </p>
            </div>
          );
        })
        .finally(() => {
          handleDialog({ type: DialogType.LOADING, value: false });
          next();
        });
    });
  };

  return (
    <>
      <DeleteMessageBox
        zIndex={10000}
        visible={deleted}
        title={I18n.t("MSG_CREATOR_APP_DELETE_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_DELETE_WARNING_MESSAGE", {
          name: selectedProject?.name,
        })}
        close={close}
        handleDelete={handleDeleteProject}
      />

      <Overlay area={panelRect} onClick={handleClick}>
        <div
          className="absolute z-50"
          style={{
            width: 108 * scale,
            height: 105 * scale,
            transform: `translate(${panelRect.x - 90 * scale}px, ${
              panelRect.y + 20 * scale
            }px) rotate(-45deg)`,
          }}
        >
          <TutorialArrow />
        </div>
      </Overlay>
    </>
  );
};

export default Step;
