import { SVGProps } from "react";

export const IconCourseClear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={52}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.98 2.002h.04c7.896-.077 15.887 2.229 23.856 5.29a198.505 198.505 0 0 1-1.009 4.636c-1.062 4.704-2.216 9.812-2.4 14.899-13.526 4.23-27.408 4.23-40.934 0-.184-5.088-1.338-10.197-2.4-14.902-.36-1.596-.71-3.147-1.009-4.633 7.97-3.061 15.96-5.367 23.856-5.29Zm24.033 4.592Z"
      fill="#F9E532"
      stroke="#3F4A61"
      strokeWidth={4}
    />
    <path
      d="M41.126 23.023c-9.985 2.636-20.267 2.636-30.252 0-.227-.06-.409-.366-.42-.682-.126-3.939-1.587-7.805-2.438-11.42-.069-.291.091-.595.352-.679C14.128 8.413 20.064 6.954 26 7.002c5.936-.048 11.871 1.411 17.632 3.24.259.084.42.388.352.679-.851 3.616-2.312 7.482-2.439 11.42-.01.316-.192.622-.419.682Z"
      fill="#FFA03B"
    />
  </svg>
);
