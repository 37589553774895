import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { ComponentProperty } from "common/components";
import {
  SizeProperty,
  BasicProperty,
  ColorProperty,
  PositionProperty,
  AppearanceProperty,
} from "features/creator/property/panels";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType } from "features/creator/types";
import { selectPropertyById } from "features/creator/slice";
import { TabIndex, PropertyProps } from "features/creator/property/types";

const PropertyPanel = (props: PropertyProps) => {
  const { selectedTabIndex } = props;
  switch (selectedTabIndex) {
    case TabIndex.BASIC:
      return <BasicProperty {...props} />;
    case TabIndex.POSITION:
      return <PositionProperty {...props} />;
    case TabIndex.SIZE:
      return <SizeProperty {...props} />;
    case TabIndex.APPEARANCE:
      return <AppearanceProperty {...props} />;
    case TabIndex.COLOR:
      return <ColorProperty {...props} />;
    case TabIndex.LOGIC:
      return <></>;
    default:
      return <></>;
  }
};

export const Panel = (props: { selectedTabIndex: TabIndex }) => {
  const { scale } = useScale();
  const { selectedTabIndex } = props;
  const handleAction = useActionCommand();
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const selectedComponentProperty = useSelector((state: RootState) =>
    selectPropertyById(state, selectedComponentId)
  );

  const handleUpdateProperty = (id?: string, property?: ComponentProperty) => {
    if (id && property) {
      handleAction({
        type: ActionCommandType.CHANGE_PROPERTY,
        componentId: id,
        property,
      });
    }
  };

  return (
    <div
      className="flex-col-view bg-beige/60 border-solid border-white drop-shadow-normal_show"
      style={{
        padding: 12 * scale,
        borderWidth: 4 * scale,
        borderRadius: 12 * scale,
      }}
    >
      <PropertyPanel
        selectedTabIndex={selectedTabIndex}
        scale={scale}
        updateProperty={handleUpdateProperty}
        propertyEntity={selectedComponentProperty}
      />
    </div>
  );
};
