import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useScale } from "common/utils";
import { TutorialArrow } from "common/assets";
import { actions } from "features/creator/slice";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const { scale } = useScale();
  const dispatch = useDispatch();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        "shared-project-panel-filter-official-btn"
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x,
        y: rect.y - rect.width / 2 + 20 * scale,
        width: rect.width,
        height: rect.width,
      });
    }, 300);
  }, [scale]);

  const handleClick = () => {
    dispatch(
      actions.handleUpdatePublishedProjectFilter({
        type: "onlyOfficial",
        value: false,
      })
    );
    next();
  };

  return (
    <Overlay area={panelRect} onClick={handleClick}>
      <div
        className="absolute z-50"
        style={{
          width: 108 * scale,
          height: 105 * scale,
          transform: `translate(${
            panelRect.x + panelRect.width / 2 - 50 * scale
          }px, ${panelRect.y - 80 * scale}px) rotate(45deg)`,
        }}
      >
        <TutorialArrow />
      </div>
    </Overlay>
  );
};

export default Step;
