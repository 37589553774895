import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { IconPlayerPlay } from "common/assets";
import { btnDelay, usePlaySound } from "common/utils";
import { ProjectAuthor } from "features/creator/elements";
import { IconBasic } from "features/creator/property/components";
import { actions as creatorActions } from "features/creator/slice";
import { DisplayMode, ProjectEntity } from "features/creator/types";
import { IconBack, IconRunPreviewBg } from "features/creator/assets";
import { Path } from "./type";
import { DetailTitle } from "./elements";
import { FavoritedMarkReadOnly } from "./FavoritedMark";

export const ProjectDetailPanel = ({
  id,
  path,
  show,
  project,
  ranking,
  close,
}: {
  id: string;
  path: Path;
  show: boolean;
  project: ProjectEntity;
  ranking: number;
  close: () => void;
}) => {
  const play = usePlaySound();
  const [init, setInit] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.appUser);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const panelAction = useSelector((state: RootState) => state.creator.action);

  useEffect(() => {
    if (displayMode === DisplayMode.RANKING && panelAction.back && project) {
      close();
      dispatch(
        creatorActions.updateProjectPanelAction({
          ...panelAction,
          back: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelAction.back]);

  useEffect(() => {
    if (ref.current && path === Path.APP) {
      setTimeout(() => setInit(true), 300);
    } else {
      setInit(false);
      if (ref.current) {
        ref.current.style.opacity = "0%";
        ref.current.style.zIndex = "-1";
        ref.current.classList.remove("animate__fadeOutUp");
        ref.current.classList.remove("animate__fadeInDown");
      }
    }
  }, [path]);

  useEffect(() => {
    if (ref.current) {
      if (show) {
        ref.current.style.opacity = "100%";
        ref.current.style.zIndex = "30011";
        ref.current.classList.remove("animate__fadeOutUp");
        ref.current.classList.add("animate__fadeInDown");
      } else {
        if (init) {
          ref.current.classList.remove("animate__fadeInDown");
          ref.current.classList.add("animate__fadeOutUp");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleRunProject = () => {
    play();
    btnDelay(() =>
      dispatch(
        actions.updateDialog({
          type: DialogType.PROJECT_PREVIEW,
          value: true,
          args: {
            projectId: project?.project_id,
            isUserProject: "false",
          },
        })
      )
    );
  };

  return (
    <div
      ref={ref}
      className="flex-col-el items-center w-full h-full absolute -z-1 opacity-0 animate__animated gap-3"
    >
      <img
        alt="creator display default bg"
        src={`${Constants.assetHost}/assets/images/creator_display_bg.webp`}
        className="w-full h-full object-fill absolute -z-10"
      />

      <div
        onClick={close}
        className="flex-col-el flex-center absolute top-2 left-2 active:opacity-70 cursor-pointer z-50"
      >
        <div className="flex-col-center">
          <IconBack stroke="#A54D21" />
        </div>
        <p className="text-body text-[20px] text-[#A54D21]">もどる</p>
      </div>

      <img
        className="w-[315px] h-[54px] mt-4 z-10"
        alt="creator ranking top title"
        src={`${Constants.assetHost}/assets/images/creator_ranking_favorited_title.png`}
      />

      <div className="flex-row-center w-full">
        <div className="flex-row-el w-full h-[6px] bg-[#EBA646] absolute mt-5" />

        <div className="flex-row-el flex-center relative">
          <div
            style={{ boxShadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.25)" }}
            className="flex-row-el items-center bg-beige/20 rounded-l-xl w-[366px] h-[80px] pl-4"
          >
            <div className="flex-col-center mr-2">
              <DetailTitle ranking={ranking} />
            </div>

            <div className="flex-col-el w-[262px] mr-1">
              <p className="text-body text-textcolor/black text-left text-[26px] whitespace-nowrap overflow-hidden text-ellipsis">
                {project?.name}
              </p>
            </div>
          </div>
        </div>

        <div className="flex-col-el flex-center relative w-[98px] h-[120px]">
          <img
            alt="app card"
            src={`${Constants.assetHost}/assets/images/${
              project?.author_name === "まなパズ公式"
                ? "app_card_official"
                : project?.author_uid === user.active.uid
                ? "app_card_user"
                : "app_card_shared"
            }.png`}
            className="w-full h-full"
          />

          <div className="flex-col-el flex-center absolute top-[24px] w-full px-[6px] h-[82px]">
            {project?.capture ? (
              <div className="flex-col-center w-full h-full overflow-hidden bg-white">
                <img
                  alt="アプリのキャプチャー"
                  className="w-full h-full object-cover"
                  src={project?.capture}
                  onError={(e) =>
                    // @ts-ignore
                    (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                  }
                />
              </div>
            ) : (
              <div className="w-full h-full bg-white" />
            )}
          </div>

          <div className="flex-col-el absolute right-3 bottom-3">
            {project && <FavoritedMarkReadOnly favorited={project.favorited} />}
          </div>
        </div>
      </div>

      <div className="flex-row-el gap-2">
        <div className="flex-row-el items-start w-[304px] h-[210px]">
          <p
            className={`text-body text-left text-[20px] w-full ${
              project?.description ? "text-textcolor/black" : "text-gray2/60"
            }`}
          >
            <span className="inline-block align-middle w-[32px] h-[32px] mb-1 mr-1">
              <IconBasic />
            </span>
            {project?.description ??
              I18n.t("MSG_CREATOR_APP_DETAIL_DESCRIPTION_NONE")}
          </p>
        </div>

        <ProjectAuthor project={project} />
      </div>

      <div className="flex-col-el flex-center absolute bottom-0">
        <button
          onClick={handleRunProject}
          className="opacity-button w-[152px] h-[109px]"
        >
          <div className="flex-col-center w-full h-full">
            <div className="flex-col-el flex-center w-full h-full">
              <div className="flex-col-center w-full h-full">
                <IconRunPreviewBg id={id} />
              </div>
            </div>

            <div className="flex-col-el flex-center absolute -bottom-1 mt-0">
              <div className="flex-col-center w-[56px] h-[56px]">
                <IconPlayerPlay color="white" width={2} />
              </div>
              <p className="text-body text-white text-[28px]">
                {I18n.t("MSG_CREATOR_RUN_APP_BTN")}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
