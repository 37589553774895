import { CursorKey } from "./CursorKey";
import { SubDisplay } from "./SubDisplay";
import { ActionButton } from "./ActionButton";

export const Controller = ({ isUserProject }: { isUserProject: boolean }) => {
  return (
    <div className="flex-row-el flex-center gap-4 w-[778px] h-[218px] absolute z-50 bottom-0 left-0">
      <CursorKey />
      <SubDisplay isUserProject={isUserProject} />
      <ActionButton />
    </div>
  );
};
