import { useEffect } from "react";
import { useDispatch } from "react-redux";
import I18n from "i18n-js";
import { actions } from "features/creator/slice";
import { ProjectPanelType } from "features/creator/types";
import { TopLayout } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.updateProjectPanel(ProjectPanelType.SHARED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TopLayout next={next}>
      <div className="flex-col-el flex-center gap-6 ml-11 mt-10">
        <p className="text-body text-[80px] text-white leading-[70px]">2</p>

        <div className="flex-col-center gap-2">
          <div className="flex-row-el flex-center px-3 bg-orange/100 border-solid border-[2px] border-white rounded-xl">
            <p className="text-body text-[20px] leading-9 text-white">
              {I18n.t("MSG_CREATOR_APP_MY_APPS")}
            </p>
          </div>

          <p className="text-body text-[18px] text-white">
            {I18n.locale === "ja" ? "のつくりかた" : "の作り方"}
          </p>
        </div>
      </div>
    </TopLayout>
  );
};

export default Step;
