/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useRef, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Howl } from "howler";
import Blockly from "blockly";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { RootState } from "app/store";
import { useCourseRecord } from "app/hook";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { DialogProgramCourse } from "common/blockly";
import { ConfigContext } from "features/top";
import { BillingType } from "features/user/types";
import { LevelClearStamp } from "features/courses/assets";
import Stages from "features/courses/program/game/stages";
import { GuidePage } from "features/courses/program/game/Guide";
import { BlockPanel } from "features/courses/program/game/blockly";
import { Course, CourseMessageContent } from "features/courses/types";
import { DisplayPanel } from "features/courses/program/game/components";
import { selectProgressProgramById } from "features/courses/coursesSlice";
import { GameSceneTitle, BackToCourseTop } from "features/courses/components";

export const ProgramGameMainPage = ({
  stage,
  step,
}: {
  stage: number;
  step: number;
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const FLYOUT_OFFSET = 152;
  const { scale } = useScale();
  const navigate = useNavigate();
  const [run, setRun] = useState(false);
  const [event, setEvent] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const configContext = useContext(ConfigContext);
  const handleTrack = useCourseRecord(Course.PROGRAM, stage, step);
  const display = useSelector(
    (state: RootState) => state.courses.message[Course.PROGRAM][stage - 1]
  );
  const { stage: stageProgress, level } = useSelector((state: RootState) =>
    selectProgressProgramById(state, stage)
  );
  const [gameBgm, setGameBgm] = useState<Howl | null>(null);
  const { answer, is_finished, finished_at } = level[step - 1];
  const user = useSelector((state: RootState) => state.user.appUser);
  const { Display, Runtime, ToolboxInfo } = Stages[stage - 1][step - 1];
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const question = display[config.locale]?.level[step - 1].goal;
  const [toolboxInfo, _setToolboxInfo] = useState(
    JSON.parse(JSON.stringify(ToolboxInfo))
  );
  const toolboxRef = useRef(toolboxInfo);
  const setToolboxInfo = (toolbox: any) => {
    _setToolboxInfo(JSON.parse(JSON.stringify(toolbox)));
  };

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      navigate(`/courses`);
    }
  }, [user]);

  useEffect(() => {
    handleTrack(user.main.billing_type === BillingType.EDUCATION);
    return () => {
      handleTrack(false);
    };
  }, []);

  useEffect(() => {
    if (!is_finished && stageProgress.finished + 1 !== step) {
      navigate(`/courses/${Course.PROGRAM}/stage/${stage}`);
    }
    if ("AudioContext" in window) {
      const bgmSound = new Howl({
        mute: !config.bgm.enable,
        volume: config.bgm.volume,
        autoplay: false,
        loop: true,
        src: [`${Constants.assetHost}/assets/bgm/bgm_game.mp3`],
      });
      setGameBgm(bgmSound);
    }
  }, []);

  useEffect(() => {
    if (gameBgm) {
      gameBgm.mute(!config.bgm.enable);
      gameBgm.volume(config.bgm.volume);
    }
  }, [config]);

  useEffect(() => {
    if (configContext.bgmSound && gameBgm) {
      gameBgm.play();
      configContext.bgmSound.stop();
    }
    return () => {
      if (configContext.bgmSound && gameBgm) {
        gameBgm.stop();
        configContext.bgmSound.play();
      }
    };
  }, [configContext.bgmSound, gameBgm]);

  const handleHideChaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target && workspace) {
      var target = e.target as HTMLElement;

      while (target) {
        const className = target.getAttribute("class");
        if (className !== null && className.indexOf("blocklySelected") !== -1) {
          return;
        }
        target = target.parentElement;
      }
      workspace.hideChaff(true);
    }
  };

  return (
    <div
      className="flex-col-view w-full h-full bg-transparent"
      onClick={handleHideChaff}
    >
      <DialogProgramCourse />

      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_course_program_scene.png`}
        className="absolute top-0 left-0 w-full h-full object-cover"
      />

      <div className="flex-row-view justify-center w-full h-full">
        <GameSceneTitle
          level={step}
          title={
            display
              ? (display[config.locale] as CourseMessageContent)?.level[
                  step - 1
                ].name.replace("\n", "")
              : ""
          }
        />

        <DisplayPanel
          stage={stage}
          step={step}
          question={question}
          isFinished={is_finished}
          event={event}
          workspace={workspace}
          Runtime={Runtime}
          run={run}
          setRun={setRun}
        >
          <Display />
        </DisplayPanel>

        <BlockPanel
          stage={stage}
          step={step}
          answer={answer}
          offset={FLYOUT_OFFSET}
          run={run}
          setEvent={setEvent}
          workspace={workspace}
          setWorkspace={setWorkspace}
          toolboxRef={toolboxRef}
          toolboxTree={ToolboxInfo["maxInstances"]}
          setToolboxInfo={setToolboxInfo}
        />
      </div>

      <GuidePage
        courseDisplay={display ? display[config.locale] : ""}
        stage={stage}
        step={step}
      />

      {is_finished && (
        <LevelClearStamp
          scale={scale}
          date={dayjs(finished_at).tz("Asia/Tokyo").format("YYYY.M.DD")}
        />
      )}
    </div>
  );
};

export const ProgramGamePage = () => {
  const { stageId, stepId } = useParams<{
    stageId?: string;
    stepId?: string;
  }>();
  const step = Number(stepId);
  const stage = Number(stageId);

  return isNaN(stage) || isNaN(step) || stage > 7 || step > 7 ? (
    <BackToCourseTop />
  ) : (
    <ProgramGameMainPage stage={stage} step={step} />
  );
};
