import { useRef, useState, useEffect } from "react";
import { animated, useSprings } from "@react-spring/web";
import { useScale } from "common/utils";
import { CloseBtnOuter } from "common/elements";
import { GuideMessageBalloonSvg } from "features/courses/assets";
import { Button } from "features/courses/algorithm/game/tips/Button";
import { StepProps } from "./type";

const Step = (props: StepProps) => {
  const {
    message,
    characterCoordinates,
    handleBack,
    handleMove,
    close,
  } = props;
  const { scale } = useScale();
  const ref = useRef<HTMLDivElement>();
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [numberOffset, setNumberOffset] = useState({ x: 0, y: 0 });
  const [numberSprings, numberApi] = useSprings(3, () => ({ opacity: 0 }));
  const handleNumberAnimation = () => {
    numberApi.set({ opacity: 0 });
    numberApi.start((index) => ({
      to: { opacity: 1 },
      delay: index * 1000,
    }));
  };

  useEffect(() => {
    if (!characterCoordinates) {
      handleMove();
    }

    if (ref.current) {
      ref.current.innerHTML = message;

      setOffset({
        x: characterCoordinates.x - 600 * scale,
        y: 300 * scale,
      });
      setNumberOffset({
        x: characterCoordinates.x + 40 * scale,
        y: characterCoordinates.y - 30 * scale,
      });

      handleNumberAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <>
      {numberSprings.map((styles, index) => (
        <animated.div
          style={{
            width: 36 * scale,
            height: 80 * scale,
            transform: `translate(${numberOffset.x + 60 * index * scale}px, ${
              numberOffset.y - 40 * index * scale
            }px)`,
            position: "absolute",
            top: 0,
            left: 0,
            ...styles,
          }}
          key={index}
        >
          <div
            className="flex-col-view w-[36px] h-[80px] origin-top-right"
            style={{ transform: `scale(${scale})` }}
          >
            <p className="text !text-[54px] !leading-[80px] text-white !tracking-[-0.02em]">
              {index + 1}
            </p>
          </div>
        </animated.div>
      ))}
      <div
        className="flex-col-view !absolute top-0 left-0"
        style={{
          opacity: offset.x * offset.y === 0 ? 0 : 1,
          width: 500 * scale,
          height: 300 * scale,
          transform: `translate(${offset.x}px, ${offset.y}px)`,
        }}
      >
        <div
          className="flex-col-view items-center origin-top-left w-[500px] h-[300px]"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-el absolute top-4 right-4">
            <CloseBtnOuter close={close} />
          </div>

          <div className="flex-col-view !absolute top-0 left-0 w-[500px] h-[300px]">
            <GuideMessageBalloonSvg />
          </div>

          <div className="flex-col-center top-[45px] w-[500px] h-[158px]">
            <p
              ref={ref}
              className="text !leading-[30px] text-textcolor/black"
            ></p>
          </div>
          <Button handleBack={handleBack} handleMove={handleMove} />
        </div>
      </div>
    </>
  );
};

export default Step;
