import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { CloseBtn } from "common/elements";
import { useScale, usePlaySound } from "common/utils";
import {
  IconMail,
  IconItem,
  IconSmile,
  IconSetting,
  IconProgress,
  IconUserProfile,
} from "features/user/assets";
import { UserStock } from "features/user/UserStock";
import { UserStatus } from "features/user/UserStatus";
import { UserProfile } from "features/user/UserProfile";
import { UserMessage } from "features/user/userMessage";
import { UserAccount } from "features/user/UserAccount";
import { UserSetting } from "features/user/UserSetting";
import { selectAllChatRoomMessageSync } from "features/user/slice";
import { UserProgressCourse } from "features/user/UserProgressCourse";
import { UserProgressCreator } from "features/user/UserProgressCreator";

export enum UserProfileNavi {
  PROGRESS_COURSE = "PROGRESS_COURSE",
  PROGRESS_CREATOR = "PROGRESS_CREATOR",
  PROFILE = "profile",
  STOCK = "stock",
  SETTING = "setting",
  MESSAGE = "message",
}

export const UserPage = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const [navi, setNavi] = useState(UserProfileNavi.PROGRESS_COURSE);
  const userProfile = useSelector(
    (state: RootState) => state.config.dialog.user_profile
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const chatRoomMessageSync = useSelector(selectAllChatRoomMessageSync);

  const handleClose = () => {
    play();
    dispatch(
      actions.updateDialog({
        type: DialogType.USER_PROFILE,
        value: false,
        args: {},
      })
    );
  };

  const hasUnreadMessage =
    chatRoomMessageSync.filter(
      (sync) =>
        sync.uid === user.active.uid &&
        (dayjs(sync.latestMessageCreatedAt, "YYYY-MM-DDTHH:mm:ssZ").diff(
          dayjs(sync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")
        ) > 0 ||
          (sync.latestReadAt === null && sync.latestMessageCreatedAt !== null))
    ).length > 0;

  const handleNavi = (navi: UserProfileNavi) => () => {
    play();
    setNavi(navi);
  };

  useEffect(() => {
    setNavi(UserProfileNavi.PROGRESS_COURSE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.active.uid]);

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 10103 }}
      open={userProfile.value}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-col-center !absolute inset-0 bg-textcolor/black/50 w-full h-full">
        <div
          className="flex-col-view items-center justify-start w-[1180px] h-[890px] rounded-lg border-[8px] border-solid border-white bg-white"
          style={{ transform: `scale(${scale})`, marginTop: 32 * scale }}
        >
          <CloseBtn close={handleClose} />
          <div className="flex-col-el w-full h-full relative rounded-[4px] bg-white overflow-hidden">
            <div
              className="flex-row-center w-full h-[220px] bg-beige/60 pr-[100px]"
              style={{ boxShadow: "0px 12px 0px #FFD1A1" }}
            >
              <UserAccount />

              <div className="flex-col-center mx-8 mb-4 cursor-pointer">
                <div
                  className="flex-col-center bg-white rounded-full mb-2 w-[160px] h-[160px]"
                  onClick={handleNavi(UserProfileNavi.PROFILE)}
                  style={{
                    boxShadow:
                      "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                  }}
                >
                  <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                    <img
                      key={user.active.icon}
                      alt="ユーザプロフィール画像"
                      src={user.active.icon}
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>

                <div
                  className="flex-col-center w-[250px] h-[40px] bg-beige/10 rounded-[32px] text-textcolor/black mt-[-20px]"
                  onClick={handleNavi(UserProfileNavi.PROFILE)}
                  style={{
                    boxShadow:
                      "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                  }}
                >
                  <p className="text">{user.active.display_name}</p>
                </div>
              </div>

              <UserStatus />
            </div>

            <div className="flex-col-el relative w-full items-center shrink-0 flex-1">
              <div className="flex-row-el absolute -top-4 z-50">
                <div
                  className="flex-row-view bg-gray2/10 border-solid border-white rounded-xl border-4 mb-2 overflow-hidden"
                  style={{ boxShadow: "1px 1px 2px rgba(63, 74, 97, 0.4)" }}
                >
                  <div className="flex-row-el flex-center bg-beige/alt3 h-[72px] p-1 gap-1">
                    <div className="flex-row-el mx-1">
                      <p className="text-body text-textcolor/black text-[16px] whitespace-pre leading-6">
                        {I18n.t("MSG_USER_PROFILE_PROGRESS")}
                      </p>
                    </div>

                    <div className="flex-row-el flex-center h-full bg-gray2/10 rounded-lg overflow-hidden">
                      <button
                        className={`opacity-button w-[168px] h-full !rounded-none text-textcolor/black ${
                          navi === UserProfileNavi.PROGRESS_COURSE
                            ? "!bg-green/altcolor"
                            : "!bg-gray2/10"
                        }`}
                        onClick={handleNavi(UserProfileNavi.PROGRESS_COURSE)}
                      >
                        <div className="flex-row-center">
                          <div className="flex-col-center mr-2">
                            <IconProgress />
                          </div>
                          <p className="text-body text-textcolor/black text-[20px] whitespace-pre leading-6">
                            {I18n.t("MSG_USER_PROFILE_PROGRESS_COURSE")}
                          </p>
                        </div>
                      </button>
                      <button
                        className={`opacity-button w-[168px] h-full !rounded-none text-textcolor/black ${
                          navi === UserProfileNavi.PROGRESS_CREATOR
                            ? "!bg-green/altcolor"
                            : "!bg-gray2/10"
                        }`}
                        onClick={handleNavi(UserProfileNavi.PROGRESS_CREATOR)}
                      >
                        <div className="flex-row-center">
                          <div className="flex-col-center mr-2">
                            <IconSmile />
                          </div>
                          <p className="text-body text-textcolor/black text-[20px] whitespace-pre leading-6">
                            {I18n.t("MSG_USER_PROFILE_PROGRESS_CREATOR")}
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>

                  <button
                    className={`opacity-button w-[168px] h-[72px] !rounded-none text-textcolor/black ${
                      navi === UserProfileNavi.PROFILE && "!bg-green/altcolor"
                    }`}
                    onClick={handleNavi(UserProfileNavi.PROFILE)}
                  >
                    <div className="flex-row-center">
                      <div className="flex-col-center">
                        <IconUserProfile />
                      </div>
                      <p className="text">{I18n.t("MSG_USER_PROFILE_INFO")}</p>
                    </div>
                  </button>

                  <button
                    className={`opacity-button w-[168px] h-[72px] !rounded-none text-textcolor/black ${
                      navi === UserProfileNavi.STOCK && "!bg-green/altcolor"
                    }`}
                    onClick={handleNavi(UserProfileNavi.STOCK)}
                  >
                    <div className="flex-row-center">
                      <div className="flex-col-center mr-[4px]">
                        <IconItem />
                      </div>
                      <p className="text">{I18n.t("MSG_USER_PROFILE_STOCK")}</p>
                    </div>
                  </button>
                  <button
                    className={`opacity-button w-[168px] h-[72px] !rounded-none text-textcolor/black ${
                      navi === UserProfileNavi.SETTING && "!bg-green/altcolor"
                    }`}
                    onClick={handleNavi(UserProfileNavi.SETTING)}
                  >
                    <div className="flex-row-center">
                      <div className="flex-col-center mr-2">
                        <IconSetting />
                      </div>
                      <p className="text">
                        {I18n.t("MSG_USER_PROFILE_SETTING")}
                      </p>
                    </div>
                  </button>
                  <button
                    className={`opacity-button w-[168px] h-[72px] !rounded-tl-none !rounded-bl-none text-textcolor/black ${
                      navi === UserProfileNavi.MESSAGE && "!bg-green/altcolor"
                    }`}
                    onClick={handleNavi(UserProfileNavi.MESSAGE)}
                  >
                    <div className="flex-row-center">
                      <div className="flex-col-center mr-2">
                        {hasUnreadMessage && (
                          <div className="flex-col-center !absolute right-[-5px] -top-0.5 bg-danger rounded-full w-[20px] h-[20px] z-50" />
                        )}
                        <IconMail />
                      </div>
                      <p className="text">
                        {I18n.t("MSG_USER_PROFILE_MESSAGE")}
                      </p>
                    </div>
                  </button>
                </div>
              </div>

              <UserProgressCourse
                selected={navi === UserProfileNavi.PROGRESS_COURSE}
              />
              <UserProgressCreator
                selected={navi === UserProfileNavi.PROGRESS_CREATOR}
                close={handleClose}
              />

              <div
                className="flex-col-view w-[1128px] h-[560px] mt-[72px] bg-gray2/10 !box-content border-solid border-4 border-white rounded-xl overflow-hidden"
                style={{
                  boxShadow: "1px 1px 4px rgba(63, 74, 97, 0.4)",
                  display:
                    navi === UserProfileNavi.PROGRESS_COURSE ||
                    navi === UserProfileNavi.PROGRESS_CREATOR
                      ? "none"
                      : "flex",
                }}
              >
                <UserProfile selected={navi === UserProfileNavi.PROFILE} />
                <UserStock selected={navi === UserProfileNavi.STOCK} />
                <UserSetting selected={navi === UserProfileNavi.SETTING} />
                <UserMessage selected={navi === UserProfileNavi.MESSAGE} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
