import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

export const ToggleSwitch = styled((props: SwitchProps) => (
  <Switch {...props} />
))(({ theme }) => ({
  width: 80,
  height: 40,
  borderStyle: "solid",
  borderRadius: 30,
  borderWidth: 3,
  borderColor: "#FEFDFE",
  boxSizing: "content-box",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(40px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "#94C4F7",
        border: 0,
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-input": {
    width: 80,
    height: 40,
  },
  "& .MuiSwitch-thumb": {
    margin: 5,
    boxSizing: "border-box",
    backgroundColor: "#4C9DF1",
    borderStyle: "solid",
    borderRadius: 30,
    borderWidth: 3,
    borderColor: "#FEFDFE",
    width: 30,
    height: 30,
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#DBD8DA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
