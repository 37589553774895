import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { AppIcon } from "features/creator/assets";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        "shared-project-panel-filter-official-btn"
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x,
        y: rect.y - rect.width / 2 + 20 * scale,
        width: rect.width,
        height: rect.width,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={9} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            もういちど、
          </p>

          <div className="flex-row-center gap-1">
            <div className="flex-row-center bg-green/alt2 rounded-[4px] px-4">
              <p className="text-body text-white text-[18px]">
                {I18n.t("MSG_CREATOR_APP_FILTER_TITLE")}
              </p>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              の
            </p>
          </div>

          <div className="flex-row-center gap-1 upsilon">
            <div className="shrink-0 !px-2 !m-0 btn btn-blue-outlined">
              <div className="flex-row-center">
                <AppIcon />
                <p className="text-body text-[20px] !mb-[2 px]">
                  {I18n.t("MSG_CREATOR_APP_FILTER_OFFICIAL")}
                </p>
              </div>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              の ボタンを
            </p>
          </div>

          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja" ? "おしてみましょう。" : "押してみましょう。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
