import { Category, Block, Value, Shadow, Field } from "common/blockly";

export const Logic = () => {
  return (
    <Category
      name="ロジック"
      categorystyle="logic_category"
      toolboxitemid="logic"
    >
      <Block type="controls_if" />
      <Block type="controls_ifelse" />
      <Block type="logic_compare" />
      <Block type="logic_operation" />
      <Block type="logic_negate" />
      <Block type="logic_boolean" />
      <Block type="toggle_getter_value" />
      <Block type="toggle_setter_value">
        <Value name="value">
          <Shadow type="logic_boolean">
            <Field name="BOOL">TRUE</Field>
          </Shadow>
        </Value>
      </Block>
    </Category>
  );
};
