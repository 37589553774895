export const IconCrown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={38} fill="none">
    <path
      fill="#FEFDFE"
      fillRule="evenodd"
      d="M34.345 8.607c1.008-.862 1.663-2.268 1.662-3.857C36.004 2.127 34.212 0 32.003 0c-2.21 0-3.998 2.127-3.996 4.75.001 1.588.659 2.995 1.668 3.857l-2.515 11.66h-2.28l-2.535-11.66c1.008-.862 1.664-2.268 1.662-3.857C24.005 2.127 22.212 0 20.003 0c-2.209 0-3.998 2.127-3.995 4.75 0 1.588.658 2.994 1.667 3.857l-2.516 11.66h-2.28l-2.534-11.66c1.008-.862 1.664-2.268 1.662-3.857C12.005 2.127 10.213 0 8.004 0c-2.21 0-3.999 2.127-3.996 4.75 0 1.588.658 2.994 1.667 3.857L3.16 20.267H.017L.032 38h40l-.015-17.733H36.88l-2.534-11.66Z"
      clipRule="evenodd"
      opacity={0.8}
    />
  </svg>
);

export const IconSmallCrown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={22} fill="none">
    <path
      fill="#FEFDFE"
      fillRule="evenodd"
      d="M6.483 5.061c.576-.492.95-1.296.95-2.204C7.432 1.358 6.407.143 5.145.143c-1.262 0-2.284 1.215-2.283 2.714 0 .908.376 1.711.953 2.204l-1.438 6.663H.581L.59 21.857h22.857l-.008-10.133h-1.794L20.197 5.06c.576-.493.95-1.296.95-2.204C21.145 1.358 20.12.143 18.859.143c-1.263 0-2.285 1.215-2.284 2.714.001.908.377 1.711.954 2.204l-1.438 6.663h-1.303L13.34 5.06c.576-.492.95-1.296.95-2.204-.001-1.499-1.026-2.714-2.288-2.714-1.262 0-2.285 1.215-2.283 2.714 0 .908.376 1.711.952 2.204l-1.437 6.663H7.931L6.483 5.06Z"
      clipRule="evenodd"
      opacity={0.8}
    />
  </svg>
);
