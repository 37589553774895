import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { IconBack } from "features/user/assets";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("app-back-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 20 * scale,
        y: rect.y - 15 * scale,
        width: rect.height + 30 * scale,
        height: rect.height + 30 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={12} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "まえのがめんに もどるには"
              : "前の画面に戻るには"}
          </p>

          <div className="flex-row-el flex-center">
            <div className="flex-col-el flex-center w-[72px] h-[72px] bg-brown/100 scale-75">
              <div className="flex-col-center">
                <div className="flex-col-center w-[32px] h-[32px] mt-1 mb-1">
                  <IconBack />
                </div>

                <p className="text text-white !text-[16px]">
                  {I18n.t("MSG_BACK_BTN")}
                </p>
              </div>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              {I18n.locale === "ja" ? `を おします。` : `を押します。`}
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Step;
