import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`,
            width: 30,
            height: 30,
            alt: "Vehicle",
          },
          "button_1",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
      return async () => {
        highlightBlock("${block.id}", true);
        await sleep(100);
        highlightBlock("${block.id}", false);
        var result = [false, false];
        let target;
        if(${result}){
          ${logic}
          setSuccess(!result.includes(false));
          setGameOver(true);
        } else {
          setSuccess(false);
          setGameOver(true);
        };
      }
    `;
    return code;
  };
};

export const InitLabelTextShowSetterBlock = () => {
  Blockly.Blocks["label_text_show_setter_block"] = {
    init: function () {
      this.jsonInit({
        type: "label_text_show_setter_block",
        message0: "%1に%2%{BKY_DISPLAY_EVENT}",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [["ボックス1", "1"]],
          },
          {
            type: "input_value",
            name: "value",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "logic_blocks",
      });
    },
  };
  BlocklyJs["label_text_show_setter_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        target = document.getElementById("course-program-label-1");
        value = Number(${value});
        target.innerText = value;
        target.style.opacity = "1";
        target.style.transform = "scale(1)";
        await sleep(700);
        result[1] = value === 70;
        if(result[1]){
          answerMark = document.getElementById("course-program-answer-mark-wrong-2");
          if(answerMark){
            answerMark.style.opacity = "0";
          };
          answerMark = document.getElementById("course-program-answer-mark-right-2");
          if(answerMark){
            answerMark.style.opacity = "1";
          };
        } else {
          answerMark = document.getElementById("course-program-answer-mark-right-2");
          if(answerMark){
            answerMark.style.opacity = "0";
          };
          answerMark = document.getElementById("course-program-answer-mark-wrong-2");
          if(answerMark){
            answerMark.style.opacity = "1";
          };
          setSuccess(false);
          setGameOver(true);
        };
        await sleep(700);
        highlightBlock("${block.id}", false);
    `;
  };
};

export const InitImageGetterXBlock = () => {
  Blockly.Blocks["image_getter_x_block"] = {
    init: function () {
      this.jsonInit({
        type: "image_getter_x_block",
        message0: "%{BKY_GETTER_X_COORDINATE}",
        args0: [
          {
            type: "field_dropdown",
            name: "vehicle",
            options: [
              [
                {
                  src: `${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`,
                  width: 30,
                  height: 30,
                  alt: "Vehicle",
                },
                "Vehicle",
              ],
            ],
          },
        ],
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["image_getter_x_block"] = function (block: Blockly.Block) {
    const code = `document.getElementById("course-program-image-1").dataset.value`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitImageSetterXBlock = () => {
  Blockly.Blocks["image_setter_x_block"] = {
    init: function () {
      this.jsonInit({
        type: "image_setter_x_block",
        message0: "%{BKY_COURSE_PROGRAM_SETTER_X_COORDINATE}",
        args0: [
          {
            type: "field_dropdown",
            name: "vehicle",
            options: [
              [
                {
                  src: `${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`,
                  width: 30,
                  height: 30,
                  alt: "Vehicle",
                },
                "Vehicle",
              ],
            ],
          },
          {
            type: "input_value",
            name: "value",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["image_setter_x_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        target = document.getElementById("course-program-image-1");
        if(target instanceof HTMLDivElement){
          target.dataset.value = ${Number(value)}
          await anime({
            targets: target,
            scale: 1.5,
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
          await anime({
            targets: target,
            left: ${Number(value) * 4.3 - 40},
            duration: 1000,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
          await anime({
            targets: target,
            scale: 1,
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        };
        result[0] = ${value} === 70;
        if(result[0]){
          answerMark = document.getElementById("course-program-answer-mark-wrong-1");
          if(answerMark){
            answerMark.style.opacity = "0";
          };
          answerMark = document.getElementById("course-program-answer-mark-right-1");
          if(answerMark){
            answerMark.style.opacity = "1";
          };
          await sleep(700);
        } else {
          answerMark = document.getElementById("course-program-answer-mark-right-1");
          if(answerMark){
            answerMark.style.opacity = "0";
          };
          answerMark = document.getElementById("course-program-answer-mark-wrong-1");
          if(answerMark){
            answerMark.style.opacity = "1";
          };
          setSuccess(false);
          setGameOver(true);
          await sleep(700);
          return;
        };
        highlightBlock("${block.id}", false);
    `;
  };
};
