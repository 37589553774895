import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        "shared-project-panel-sort-filter-panel"
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 0 * scale,
        y: rect.y - 20 * scale,
        width: rect.width + 0 * scale,
        height: rect.height + 40 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={4} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <div className="flex-row-center gap-1">
            <div className="flex-row-center bg-green/alt2 rounded-[4px] px-4">
              <p className="text-body text-white text-[18px]">
                {I18n.t("MSG_CREATOR_APP_SORT_TITLE")}
              </p>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              と
            </p>
          </div>

          <div className="flex-row-center gap-1">
            <div className="flex-row-center bg-green/alt2 rounded-[4px] px-4">
              <p className="text-body text-white text-[18px]">
                {I18n.t("MSG_CREATOR_APP_FILTER_TITLE")}
              </p>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              が
            </p>
          </div>

          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja" ? "ひょうじされました！" : "表示されました！"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
