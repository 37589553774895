export type Vector2D = {
  x: number;
  y: number;
};

export class Vector2 {
  x: number;
  y: number;

  static Zero: Vector2 = new Vector2(0, 0);

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export type Vector3D = {
  x: number;
  y: number;
  z: number;
};

export type ClickEvent =
  | React.MouseEvent<HTMLDivElement>
  | React.TouchEvent<HTMLDivElement>
  | TouchEvent
  | MouseEvent;
