import { createAsyncThunk } from "@reduxjs/toolkit";
import pako from "pako";
import axios from "axios";
import dayjs from "dayjs";
import {
  doc,
  setDoc,
  getFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { Buffer } from "buffer";
import throttle from "lodash-es/throttle";
import { Auth, connectAuthEmulator } from "firebase/auth";
import { UserConfig } from "app/types";
import { fetchAuthToken, parentsFirebase } from "app/Auth";
import Constants from "common/constant";
import { client } from "common/apiClient";
import { Course } from "features/courses/types";

export const connectEmulator = (auth: Auth, parentsAuth: Auth) => {
  const db = getFirestore();
  connectFirestoreEmulator(
    db,
    Constants.firebaseEmulatorsHost,
    Number(Constants.firebaseEmulatorsFirestorePort)
  );
  const parentsDb = getFirestore(parentsFirebase);
  connectFirestoreEmulator(
    parentsDb,
    Constants.firebaseEmulatorsHost,
    Number(Constants.firebaseEmulatorsFirestorePort)
  );
  connectAuthEmulator(
    auth,
    `http://${Constants.firebaseEmulatorsHost}:${Constants.firebaseEmulatorsAuthPort}`,
    { disableWarnings: true }
  );
  connectAuthEmulator(
    parentsAuth,
    `http://${Constants.firebaseEmulatorsHost}:${Constants.firebaseEmulatorsAuthPort}`,
    { disableWarnings: true }
  );
};

export const getAllParentsKidsUserConfigs = async ({
  uid,
}: {
  uid: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).get(`/v1/user/config/parents/${uid}/all`);
};

export const getUserConfig = createAsyncThunk(
  "app/getUserConfig",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/config/${uid}`
    );
    return response.data.data;
  }
);

export const updateUserConfig = createAsyncThunk(
  "app/updateUserConfig",
  throttle(async ({ uid }: { uid: string }, thunkAPI) => {
    const { config } = thunkAPI.getState();
    const token = await fetchAuthToken();
    await client({ token: token }).put(
      `/v1/user/config/${uid}`,
      config.userConfig
    );
    return;
  }, 1000)
);

export const updateSpecificUserConfig = throttle(
  async ({ uid, config }: { uid: string; config: UserConfig }) => {
    const token = await fetchAuthToken();
    return client({ token: token }).put(`/v1/user/config/${uid}`, config);
  },
  300
);

export const getParentsUserAppConfig = createAsyncThunk(
  "app/getParentsUserAppConfig",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/app/config/parents/${uid}`
    );
    return response.data.data;
  }
);

export const updateParentsUserAppConfig = createAsyncThunk(
  "app/updateParentsUserAppConfig",
  throttle(async ({ uid }: { uid: string }, thunkAPI) => {
    const { config } = thunkAPI.getState();
    const token = await fetchAuthToken();
    await client({ token: token }).put(
      `/v1/user/app/config/parents/${uid}`,
      config.appConfig
    );
    return;
  }, 1000)
);

export const getKidsLatestVersion = async ({ key }: { key: string }) => {
  const response = await client({ token: key }).get("/v1/release/kids/latest");
  return response.data.data.releases;
};

export const getNotifications = createAsyncThunk(
  "app/getNotifications",
  async () => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get("/v1/notifications");
    return response.data.data;
  }
);

export const getAllNotifications = createAsyncThunk(
  "app/getAllNotifications",
  async () => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      "/v1/notifications/all"
    );
    return response.data.data;
  }
);

export const getBannedWords = createAsyncThunk(
  "app/getBannedWords",
  async () => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get("/v1/banned/words");
    return response.data.data.words;
  }
);

export const uploadImageFile = async ({
  is_sync,
  fileBlob,
  filename,
  metadata,
  contentType,
}: {
  is_sync?: boolean;
  fileBlob: Blob | File;
  filename: string;
  metadata?: any;
  contentType?: string;
}) => {
  const token = await fetchAuthToken();
  const client = axios.create({ baseURL: Constants.apiHost });
  client.defaults.headers.common["Authorization"] = token;
  const formData = new FormData();
  formData.append("file", fileBlob);
  formData.append("filename", filename);
  formData.append("metadata", JSON.stringify(metadata));
  formData.append("contentType", contentType);
  //is_sync:false statusの200はサーバは完全にファイルアップロード処理が完了したわけではない
  //is_sync:true statusの200はサーバは完全にファイルアップロード処理が完了した
  return client.post(
    `/v1/upload/file/image?is_sync=${is_sync ?? true}`,
    formData
  );
};

export const uploadImageAsset = async ({
  uid,
  is_sync,
  fileBlob,
  object_key,
  filename,
  tags,
  metadata,
  contentType,
}: {
  uid: string;
  is_sync?: boolean;
  fileBlob: Blob | File;
  object_key: string;
  filename: string;
  tags: string;
  metadata?: any;
  contentType?: string;
}) => {
  const token = await fetchAuthToken();
  const client = axios.create({ baseURL: Constants.apiHost });
  client.defaults.headers.common["Authorization"] = token;
  const formData = new FormData();
  formData.append("file", fileBlob);
  formData.append("filename", filename);
  formData.append("tags", tags);
  formData.append("object_key", object_key);
  formData.append("metadata", JSON.stringify(metadata));
  formData.append("contentType", contentType);
  //is_sync:false statusの200はサーバは完全にファイルアップロード処理が完了したわけではない
  //is_sync:true statusの200はサーバは完全にファイルアップロード処理が完了した
  return client.post(
    `/v1/asset/user/${uid}/image?is_sync=${is_sync ?? true}`,
    formData
  );
};

export const updateImageAsset = async ({
  id,
  name,
  tags,
}: {
  id: number;
  name: string;
  tags: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token }).put(`/v1/asset/${id}`, { name, tags });
};

export const deleteAsset = async ({ id }: { id: number }) => {
  const token = await fetchAuthToken();
  return client({ token }).delete(`/v1/asset/${id}`);
};

export const recordSessionId = async ({
  uid,
  sessionId,
  course,
  stageId,
  stepId,
}: {
  uid: string;
  sessionId: string;
  course: Course;
  stageId: number;
  stepId: number;
}) => {
  try {
    console.log({ sessionId });
    const timestamp = dayjs().unix();
    const db = getFirestore(parentsFirebase);
    await setDoc(
      doc(
        db,
        "record_user_session_id",
        uid,
        course,
        stageId.toString(),
        stepId.toString(),
        sessionId
      ),
      {
        uid: uid,
        session_id: sessionId,
        course,
        stage_id: stageId,
        step_id: stepId,
        created_at: timestamp,
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const recordEventId = async ({
  uid,
  sessionId,
  eventId,
  course,
  stageId,
  stepId,
}: {
  uid: string;
  sessionId: string;
  eventId: string;
  course: Course;
  stageId: number;
  stepId: number;
}) => {
  try {
    console.log({ eventId });
    const timestamp = dayjs().unix();
    const db = getFirestore(parentsFirebase);
    await setDoc(doc(db, "record_user_event_id", sessionId, "event", eventId), {
      uid: uid,
      session_id: sessionId,
      event_id: eventId,
      course,
      stage_id: stageId,
      step_id: stepId,
      created_at: timestamp,
    });
  } catch (error) {
    console.log(error);
  }
};

export const record = async ({
  eventId,
  events,
}: {
  eventId: string;
  events: object[];
}) => {
  if (events.length > 0) {
    const eventStr = JSON.stringify(events);
    const db = getFirestore(parentsFirebase);
    const compressed = pako.deflate(eventStr);
    const compressedStr = Buffer.from(compressed).toString("base64");
    const timestamp = dayjs().unix();
    await setDoc(doc(db, "record", eventId, "event", `${timestamp}`), {
      events: compressedStr,
      created_at: timestamp,
    });
  }
};
