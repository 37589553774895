import I18n from "i18n-js";
import { css } from "@emotion/css";
import { Modal } from "common/elements";
import { useScale } from "common/utils";
import Constants, { BASE_HEIGHT, BASE_WIDTH } from "common/constant";
import {
  AllStageClearHouse,
  AllStageClearFlower,
  AllStageClearKujira,
  AllStageClearSakana,
  AllStageClearFooter,
} from ".";

export const AllStageClear = (props: { close: () => void }) => {
  const { width, height, scale } = useScale();
  const scaleX = width / BASE_WIDTH;
  const scaleY = height / BASE_HEIGHT;
  return (
    <Modal transparent>
      <div className="flex-col-view w-full h-full bg-white/50 backdrop-blur-[12px] animate-fade-in">
        <div
          className="flex-col-center origin-top"
          style={{ transform: `scale(${scale})`, marginTop: 45 * scale }}
        >
          <div className="flex-col-view">
            <div className="flex-col-view !absolute left-[20px] top-[54px] z-[-1]">
              <AllStageClearHouse />
            </div>
            <div className="flex-col-view !absolute left-[160px] top-[80px] z-[-1]">
              <AllStageClearFlower />
            </div>

            <img
              alt="あたらしいステージをおたのしみに！"
              className="w-[668px] h-[432px]"
              src={`${Constants.assetHost}/assets/images/all_stage_clear_title.png`}
            />

            <div className="flex-col-view !absolute left-[-240px] bottom-[-75px]">
              <AllStageClearKujira />
            </div>
            <div className="flex-col-view !absolute right-[-180px] bottom-[-125px]">
              <AllStageClearSakana />
            </div>
          </div>

          <div className="flex-col-center my-[40px]">
            <p
              className="text text-textcolor/black !text-[32px] !leading-[48px]"
              style={{
                WebkitTextStroke: "8px #FEFDFE",
              }}
            >
              <span
                className="inline-block w-full absolute top-0 left-0"
                style={{
                  WebkitTextStroke: "0",
                }}
              >
                {I18n.t("MSG_COURSE_ALL_GAME_STAGE_CLEAR")}
              </span>
              {I18n.t("MSG_COURSE_ALL_GAME_STAGE_CLEAR")}
            </p>
          </div>

          <div className="flex-row-el flex-center alpha">
            <button className="btn btn-primary" onClick={props.close}>
              <p className="text-body text-white text-[24px] !mb-[3px]">
                {I18n.t("MSG_CONFIRM_BTN")}
              </p>
            </button>
          </div>
        </div>

        <div
          className={`flex-col-center !absolute bottom-0 z-[-1] ${css({
            width: width,
            height: 202 * scaleY,
          })}`}
        >
          <div
            className={`flex-col-center w-[1280px] h-[202px] ${css({
              transform: `scale(${scaleX}, ${scaleY})`,
            })}`}
          >
            <AllStageClearFooter />
          </div>
        </div>
      </div>
    </Modal>
  );
};
