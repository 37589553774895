/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { Block } from "features/courses/ocha/game/type";

export const extractAnchorRoi = (
  hsvFrame: any,
  // @ts-ignore
  lower: cv.matFromArray,
  // @ts-ignore
  upper: cv.matFromArray
) => {
  let frameHeight = hsvFrame.rows;
  let frameWidth = hsvFrame.cols;
  let centerX = Math.floor(frameWidth / 2);
  let centerY = Math.floor(frameHeight / 2);
  let roiSize = 500;
  let roiXMin = centerX - Math.floor(roiSize / 2);
  let roiXMax = centerX + Math.floor(roiSize / 2);
  let roiYMin = centerY - Math.floor(roiSize / 2) - 200;
  let roiYMax = centerY + Math.floor(roiSize / 2) + 200;

  // @ts-ignore
  let mask = new cv.Mat();
  // @ts-ignore
  cv.inRange(hsvFrame, lower, upper, mask);

  // @ts-ignore
  let labels = new cv.Mat();
  // @ts-ignore
  let stats = new cv.Mat();
  // @ts-ignore
  let centroids = new cv.Mat();
  // @ts-ignore
  let numLabels = cv.connectedComponentsWithStats(
    mask,
    labels,
    stats,
    centroids,
    8
  );

  // 删除背景标签
  numLabels = numLabels - 1;

  let anchor: Block;
  for (let i = 1; i < numLabels; i++) {
    // @ts-ignore
    let area = stats.intPtr(i, cv.CC_STAT_AREA)[0];
    let centroidX = centroids.doublePtr(i, 0)[0];
    let centroidY = centroids.doublePtr(i, 1)[0];
    if (area >= 1000) {
      // @ts-ignore
      let x = stats.intPtr(i, cv.CC_STAT_LEFT)[0];
      // @ts-ignore
      let y = stats.intPtr(i, cv.CC_STAT_TOP)[0];
      // @ts-ignore
      let w = stats.intPtr(i, cv.CC_STAT_WIDTH)[0];
      // @ts-ignore
      let h = stats.intPtr(i, cv.CC_STAT_HEIGHT)[0];
      // @ts-ignore
      let s = stats.intPtr(i, cv.CC_STAT_AREA)[0];
      anchor = {
        type: "anchor",
        bbox: [x, y, w, h],
        centroid: [centroidX, centroidY],
        area: s,
      };
    }
  }

  mask.delete();
  labels.delete();
  stats.delete();
  centroids.delete();

  return anchor;
};

export const extractColorRoi = (
  hsvFrame: any,
  anchor: Block,
  color: string,
  // @ts-ignore
  lower: cv.matFromArray,
  // @ts-ignore
  upper: cv.matFromArray
) => {
  let blocks = [];

  // @ts-ignore
  let mask = new cv.Mat();
  // @ts-ignore
  cv.inRange(hsvFrame, lower, upper, mask);

  // @ts-ignore
  let labels = new cv.Mat();
  // @ts-ignore
  let stats = new cv.Mat();
  // @ts-ignore
  let centroids = new cv.Mat();
  // @ts-ignore
  let numLabels = cv.connectedComponentsWithStats(
    mask,
    labels,
    stats,
    centroids,
    8
  );

  // 删除背景标签
  numLabels = numLabels - 1;

  for (let i = 1; i < numLabels; i++) {
    // @ts-ignore
    let area = stats.intPtr(i, cv.CC_STAT_AREA)[0];
    if (area >= 4000) {
      let mx = Math.round(centroids.doublePtr(i, 0)[0]);
      let my = Math.round(centroids.doublePtr(i, 1)[0]);
      if (
        mx > anchor.bbox[0] + anchor.bbox[2] ||
        mx < anchor.bbox[0] ||
        my > anchor.bbox[1]
      )
        continue;

      // @ts-ignore
      let x = stats.intPtr(i, cv.CC_STAT_LEFT)[0];
      // @ts-ignore
      let y = stats.intPtr(i, cv.CC_STAT_TOP)[0];
      // @ts-ignore
      let w = stats.intPtr(i, cv.CC_STAT_WIDTH)[0];
      // @ts-ignore
      let h = stats.intPtr(i, cv.CC_STAT_HEIGHT)[0];
      // @ts-ignore
      let s = stats.intPtr(i, cv.CC_STAT_AREA)[0];

      blocks.push({
        type: color,
        bbox: [x, y, w, h],
        centroid: [mx, my],
        area: s,
      });
    }
  }

  mask.delete();
  labels.delete();
  stats.delete();
  centroids.delete();

  return blocks;
};

export const useProcessing = () => {
  const handleObjectDetection = useCallback((frame: any) => {
    // @ts-ignore
    let frame_hsv = new cv.Mat();
    // @ts-ignore
    cv.cvtColor(frame, frame_hsv, cv.COLOR_RGB2HSV);

    var blocks = [];
    // @ts-ignore
    let lower = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      10,
      100,
      100,
      0,
    ]);
    // @ts-ignore
    let upper = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      22,
      255,
      255,
      0,
    ]);
    let anchor = extractAnchorRoi(frame_hsv, lower, upper);
    lower.delete();
    upper.delete();

    if (!anchor) {
      frame_hsv.delete();
      return [];
    } else {
      blocks = blocks.concat(anchor);
    }

    // @ts-ignore
    lower = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      20,
      110,
      100,
      0,
    ]);
    // @ts-ignore
    upper = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      30,
      255,
      255,
      0,
    ]);
    let yellow_blocks = extractColorRoi(
      frame_hsv,
      anchor,
      "yellow",
      lower,
      upper
    );
    blocks = blocks.concat(yellow_blocks);
    lower.delete();
    upper.delete();

    // @ts-ignore
    lower = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      40,
      75,
      0,
      0,
    ]);
    // @ts-ignore
    upper = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      58,
      255,
      255,
      0,
    ]);
    let green_blocks = extractColorRoi(
      frame_hsv,
      anchor,
      "green",
      lower,
      upper
    );
    blocks = blocks.concat(green_blocks);
    lower.delete();
    upper.delete();

    // @ts-ignore
    lower = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      80,
      60,
      0,
      0,
    ]);
    // @ts-ignore
    upper = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      95,
      255,
      255,
      0,
    ]);
    let aqua_blocks = extractColorRoi(frame_hsv, anchor, "aqua", lower, upper);
    blocks = blocks.concat(aqua_blocks);
    lower.delete();
    upper.delete();

    // @ts-ignore
    lower = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      96,
      70,
      0,
      0,
    ]);
    // @ts-ignore
    upper = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      120,
      255,
      255,
      0,
    ]);
    let blue_blocks = extractColorRoi(frame_hsv, anchor, "blue", lower, upper);
    blocks = blocks.concat(blue_blocks);
    lower.delete();
    upper.delete();

    // @ts-ignore
    lower = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      121,
      50,
      60,
      0,
    ]);
    // @ts-ignore
    upper = new cv.Mat(frame_hsv.rows, frame_hsv.cols, frame_hsv.type(), [
      170,
      255,
      255,
      0,
    ]);
    let purple_blocks = extractColorRoi(
      frame_hsv,
      anchor,
      "purple",
      lower,
      upper
    );
    blocks = blocks.concat(purple_blocks);
    lower.delete();
    upper.delete();

    frame_hsv.delete();

    blocks.sort((a, b) => (a.bbox[1] < b.bbox[1] ? -1 : 1));
    const start_blocks = blocks.filter((block) => block.type === "yellow");
    if (start_blocks.length > 0) {
      const start_block = start_blocks[0];
      blocks = blocks.filter((block) => block.bbox[1] > start_block.bbox[1]);
      if (blocks.length > 0) {
        const new_blocks = [start_block];
        for (let i = 0; i < blocks.length; i++) {
          let block = blocks[i];
          if (block.type === "anchor") continue;
          let count = Math.round(block.bbox[3] / start_block.bbox[3]);
          let x = block.bbox[0];
          let w = block.bbox[2];
          let h = block.bbox[3] / count;
          let area = block.area / count;
          let mx = block.centroid[0];
          for (let j = 0; j < count; j++) {
            let y = block.bbox[1] + h * j;
            let my = block.centroid[1] + h * j;
            new_blocks.push({
              type: block.type,
              bbox: [x, y, w, h],
              centroid: [mx, my],
              area: area,
            });
          }
        }
        console.log(new_blocks);
        new_blocks.push(anchor);
        return new_blocks;
      } else {
        return [anchor];
      }
    } else {
      return [anchor];
    }
  }, []);

  return { handleObjectDetection };
};
