const ToolboxInfo = {
  maxInstances: {
    controls_repeat_var_internal: 2,
    character_motion_move: 4,
    character_motion_turn_left: 1,
    character_motion_turn_right: 1,
    variables_set: 1,
    variables_get: 2,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "controls_repeat_var_internal",
          },
          {
            kind: "block",
            type: "character_motion_move",
          },
          {
            kind: "block",
            type: "character_motion_turn_left",
          },
          {
            kind: "block",
            type: "character_motion_turn_right",
          },
          {
            kind: "block",
            type: "variables_set",
            fields: {
              VAR: { name: "へんすう" },
            },
            inputs: {
              VALUE: {
                shadow: {
                  type: "math_number",
                  fields: {
                    NUM: 1,
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: "variables_get",
            fields: {
              VAR: { name: "へんすう" },
            },
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
