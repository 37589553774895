import { SVGProps } from "react";

export const IconArrowCircleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    <g
      stroke="#FEFDFE"
      strokeWidth={3.5}
      clipPath="url(#icon-arrow-circle-left-a)"
    >
      <circle cx={16} cy={16} r={11.05} />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.6 12.8 12.8 16l4.8 3.2"
      />
    </g>
    <defs>
      <clipPath id="icon-arrow-circle-left-a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
