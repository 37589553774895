import { useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";

export const AppLoading = () => {
  const loading = useSelector(
    (state: RootState) => state.config.dialog.loading
  );
  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={loading.value}
      sx={{ zIndex: 900000 }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-row-center w-full h-full bg-textcolor/black/10">
        <div className="flex-col-center w-[100px] h-[100px]">
          <div className="dots-loader" />
        </div>
      </div>
    </Dialog>
  );
};
