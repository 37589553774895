import React, { useEffect, PointerEventHandler } from "react";

export const Mask = React.memo(
  (props: {
    onPointerMove?: PointerEventHandler<HTMLDivElement>;
    onPointerUp?: PointerEventHandler<HTMLDivElement>;
    onMouseMove?: (e: MouseEvent) => void;
    onMouseUp?: (e: MouseEvent) => void;
  }) => {
    const { onMouseMove, onMouseUp, onPointerMove, onPointerUp } = props;

    useEffect(() => {
      const newNode = document.createElement("div");
      newNode.style.position = "absolute";
      newNode.style.top = "0";
      newNode.style.left = "0";
      newNode.style.width = "100%";
      newNode.style.height = "100%";
      newNode.style.cursor = "grab";
      const root = document.body;
      root.appendChild(newNode);
      newNode.onpointermove = onPointerMove;
      newNode.onpointerup = onPointerUp;
      newNode.onmousemove = onMouseMove;
      newNode.onmouseup = onMouseUp;
      return () => {
        newNode.onpointermove = null;
        newNode.onpointerup = null;
        newNode.onmousemove = null;
        newNode.onmouseup = null;
        root.removeChild(newNode);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
  }
);
