import { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { ActionButton } from "common/elements/button";
import { CloseBtn, RadioButton, FadeTransition } from "common/elements";
import {
  IconPublish,
  IconStopPublish,
  IconCopiedEnable,
  IconCopiedDisable,
} from "features/creator/assets";

export const PublishProjectDialogMessage = ({
  name,
  visible,
  published,
  enableCopied,
  publish,
  close,
}: {
  name: string;
  visible: boolean;
  published: boolean;
  enableCopied: boolean;
  publish: (enable: boolean) => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const [disable, setDisable] = useState(false);
  const project_copied = useSelector(
    (state: RootState) => state.config.userConfig.project_copied
  );
  const [enable, setEnable] = useState(project_copied && enableCopied);

  useEffect(() => {
    if (visible) {
      setDisable(false);
    }
  }, [visible]);

  const handleEnableCopied = (enable: boolean) => () => {
    setEnable(enable);
  };

  const handlePublish = () => {
    if (disable) return;
    setDisable(true);
    publish(enable);
  };

  const title = useMemo(
    () =>
      published
        ? I18n.t("MSG_CREATOR_APP_DISABLE_PUBLISH_TITLE")
        : I18n.t("MSG_CREATOR_APP_PUBLISH_TITLE"),
    [visible]
  );

  const message = useMemo(
    () =>
      published
        ? I18n.t("MSG_CREATOR_APP_DISABLE_PUBLISH_WARNING", {
            name: name?.length < 10 ? name : name?.slice(0, 10) + "...",
          })
        : I18n.t("MSG_CREATOR_APP_PUBLISH_WARNING", {
            name: name?.length < 10 ? name : name?.slice(0, 10) + "...",
          }),
    [visible]
  );

  const btnText = useMemo(
    () =>
      published
        ? I18n.t("MSG_CREATOR_DISABLE_PUBLISH_CONFIRM_BTN")
        : I18n.t("MSG_CREATOR_PUBLISH_CONFIRM_BTN"),
    [visible]
  );

  return (
    <Dialog
      maxWidth={false}
      open={visible}
      sx={{ zIndex: 99999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{
          filter:
            "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
        }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            width: (published ? 744 : 680) * scale,
            height: (published ? 330 : 550) * scale,
          }}
        >
          <div
            className="flex-col-el flex-center relative"
            style={{ transform: `scale(${scale})` }}
          >
            <div
              className={`flex-col-el justify-start ${
                published ? "w-[744px] h-[330px]" : "w-[680px] h-[550px]"
              } relative border-solid border-white border-[8px] rounded-[8px] bg-white`}
            >
              <CloseBtn close={close} />

              <div className="flex-col-view justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
                <div className="flex-row-view items-center justify-start py-4">
                  <div className="flex-col-center mr-2">
                    <InfoOutlinedIcon sx={{ fontSize: 40, color: "#4C9DF1" }} />
                  </div>
                  <p className="text-body text-textcolor/black text-[28px]">
                    {title}
                  </p>
                </div>
                <div className="flex-col-view items-start py-4">
                  {message?.split("\n").map((line, index) => {
                    return (
                      <div key={index} className="flex-row-view flex-wrap mb-3">
                        <p className="text-body text-textcolor/black leading-[30px] text-[20px] flex-1">
                          {line}
                        </p>
                      </div>
                    );
                  })}
                </div>

                {!published && (
                  <div className="flex-col-center my-5 gap-4">
                    <div className="flex-col-el items-start gap-4">
                      <div
                        className="flex-row-center gap-2"
                        onClick={handleEnableCopied(true)}
                      >
                        <div className="flex-col-center w-[32px] h-[32px]">
                          <RadioButton
                            label=""
                            size={32}
                            value={enable}
                            onChange={handleEnableCopied(true)}
                          />
                        </div>
                        <div className="flex-col-center w-[40px] h-[40px]">
                          <IconCopiedEnable fill="none" />
                        </div>
                        <p className="text-body text-textcolor/black text-[20px]">
                          {I18n.t("MSG_CREATOR_APP_PUBLISH_COPIED_ENABLE")}
                        </p>
                      </div>

                      <div
                        className="flex-row-center gap-2"
                        onClick={handleEnableCopied(false)}
                      >
                        <div className="flex-col-center w-[32px] h-[32px]">
                          <RadioButton
                            label=""
                            size={32}
                            value={!enable}
                            onChange={handleEnableCopied(false)}
                          />
                        </div>
                        <div className="flex-col-center w-[40px] h-[40px]">
                          <IconCopiedDisable fill="none" stroke="#FFF5EB" />
                        </div>
                        <p className="text-body text-textcolor/black text-[20px]">
                          {I18n.t("MSG_CREATOR_APP_PUBLISH_COPIED_DISABLE")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex-col-center gap-4">
                  <ActionButton
                    type="primary"
                    text={btnText}
                    Icon={
                      published ? (
                        <IconStopPublish fill="none" stroke="#FFF" />
                      ) : (
                        <IconPublish fill="none" stroke="#FFF" />
                      )
                    }
                    onClick={handlePublish}
                  />

                  {!published && (
                    <p className="text-body text-textcolor/black text-[18px]">
                      {I18n.t("MSG_CREATOR_APP_PUBLISH_COPIED_NOTICE")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
