import * as Blockly from "blockly";
import { DialogColourPicker } from "./DialogColourPicker";
import { DialogComponentList } from "./DialogComponentList";
import { DialogPositionPicker } from "./DialogPositionPicker";

export const DialogCreator = (props: { workspace: Blockly.WorkspaceSvg }) => {
  return (
    <>
      <DialogColourPicker />
      <DialogComponentList {...props} />
      <DialogPositionPicker {...props} />
    </>
  );
};
