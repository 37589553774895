import { sleep } from "common/utils";
import { calcDirection } from "./direction";
import {
  tilesets,
  TileMapType,
  TILE_TYPE_NOTHING,
  TILE_TYPE_BUTTON,
  isStep,
  isButton,
  isGround,
  checkMapTitleType,
} from "../../maps";
import { performMarkJitter } from "./mark";
import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

export enum JumpType {
  JUMP_TYPE_PLACE = 0,
  JUMP_TYPE_LOW_TO_HIGHT = 1,
  JUMP_TYPE_HIGHT_TO_LOW = 2,
  JUMP_TYPE_PLACE_NONE = 3,
}

const checkNextStepStatus = (
  map: TileMapType,
  offset: Vector3,
  position: Vector3,
  direction: Vector3
): [JumpType, number] => {
  if (!position) {
    return [JumpType.JUMP_TYPE_PLACE_NONE, 0];
  }
  const [
    currentTileType,
    currentHightLayerTileType,
    nextStepSameLayerTileType,
    nextStepLowLayerTileType,
    nextStepHightLayerTileType,
  ] = checkMapTitleType(map, offset, position, direction);
  if (currentTileType === null) {
    return [JumpType.JUMP_TYPE_PLACE_NONE, 0];
  }

  let nextStepOffset: number;
  if (
    !isButton(currentHightLayerTileType) &&
    (isButton(nextStepHightLayerTileType) ||
      isButton(nextStepSameLayerTileType))
  ) {
    nextStepOffset = tilesets[TILE_TYPE_BUTTON].size.y;
  } else if (
    (isButton(currentHightLayerTileType) || isButton(currentTileType)) &&
    !isButton(nextStepHightLayerTileType) &&
    !isButton(nextStepSameLayerTileType)
  ) {
    nextStepOffset = -tilesets[TILE_TYPE_BUTTON].size.y;
  } else {
    nextStepOffset = 0;
  }

  if (currentTileType === TILE_TYPE_NOTHING || isButton(currentTileType)) {
    if (isStep(nextStepSameLayerTileType)) {
      return [JumpType.JUMP_TYPE_LOW_TO_HIGHT, nextStepOffset];
    } else if (isStep(nextStepLowLayerTileType)) {
      return [JumpType.JUMP_TYPE_HIGHT_TO_LOW, nextStepOffset];
    } else {
      return [JumpType.JUMP_TYPE_PLACE, 0];
    }
  } else if (isStep(currentTileType)) {
    if (
      (nextStepSameLayerTileType === TILE_TYPE_NOTHING ||
        isButton(nextStepSameLayerTileType)) &&
      isGround(nextStepLowLayerTileType)
    ) {
      return [JumpType.JUMP_TYPE_HIGHT_TO_LOW, nextStepOffset];
    } else if (isGround(nextStepSameLayerTileType)) {
      return [JumpType.JUMP_TYPE_LOW_TO_HIGHT, nextStepOffset];
    } else {
      return [JumpType.JUMP_TYPE_PLACE, 0];
    }
  } else {
    return [JumpType.JUMP_TYPE_PLACE_NONE, 0];
  }
};

const performLowHightJump = (
  scene: Scene,
  map: TileMapType,
  character: AbstractMesh,
  direction: Vector3,
  jumpStep: number,
  nextStepOffset: number
) => {
  const frames = jumpStep * 2;
  const characterMove = new Animation(
    "jump",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const moveStep = map.tileSize;
  const initailPoisiton = character.position;

  const animationKeys = [];
  // 2*sin(w*frames) = 1 // 2はジャンプのトップの高さ、1は最終着地の高さ
  // sin(5*PI/6) = 1/2　→ w = 5*PI/(6*frames)
  const A = moveStep; // ジャプのトップ
  const w = (5 * Math.PI) / (6 * frames);
  const phi = 0;
  const k = 0;
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: initailPoisiton
        .multiply(new Vector3(100, 100, 100))
        .add(
          new Vector3(
            ((i * (moveStep * direction.x)) / (jumpStep * 2)) * 100,
            Math.round(
              (A * Math.sin(w * i + phi) +
                k +
                (i > frames / 2 ? nextStepOffset : 0)) *
                100
            ),
            ((i * (moveStep * direction.z)) / (jumpStep * 2)) * 100
          )
        )
        .divide(new Vector3(100, 100, 100)),
    });
  }

  characterMove.setKeys(animationKeys);
  scene.getAnimationGroupByName("ArmLeftAction")?.start();
  scene.getAnimationGroupByName("ArmRightAction")?.start();
  scene.beginDirectAnimation(character, [characterMove], 0, frames, false);
};

const performHightLowJump = (
  scene: Scene,
  map: TileMapType,
  character: AbstractMesh,
  direction: Vector3,
  jumpStep: number,
  nextStepOffset: number
) => {
  const frames = jumpStep * 2;
  const characterMove = new Animation(
    "jump",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const moveStep = map.tileSize;
  const initailPoisiton = character.position;

  const animationKeys = [];
  // A*sin(frames*w + phi)
  // 2*sin(0*w + phi)=1 → phi = PI/6
  // 2*sin(6*w + phi)=0
  const A = moveStep; // ジャプのトップ
  const w = (5 * Math.PI) / (6 * frames);
  const phi = Math.PI / 6;
  const k = -1;

  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: initailPoisiton
        .multiply(new Vector3(100, 100, 100))
        .add(
          new Vector3(
            ((i * (moveStep * direction.x)) / (jumpStep * 2)) * 100,
            Math.round(
              (A * Math.sin(w * i + phi) +
                k +
                (i > frames / 2 ? nextStepOffset : 0)) *
                100
            ),
            ((i * (moveStep * direction.z)) / (jumpStep * 2)) * 100
          )
        )
        .divide(new Vector3(100, 100, 100)),
    });
  }

  characterMove.setKeys(animationKeys);
  scene.beginDirectAnimation(character, [characterMove], 0, frames, false);
};

const performPlaceJump = (scene: Scene, character: AbstractMesh) => {
  const jumpStep = 5;
  const frames = jumpStep * 2;

  performMarkJitter(scene, character, frames);

  const placeJump = new Animation(
    "placeJump",
    "position.y",
    frames,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const initial = character.position.y;
  const animationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: initial + 0.1 * (i % 2),
    });
  }

  placeJump.setKeys(animationKeys);

  scene.beginDirectAnimation(character, [placeJump], 0, frames, false);
};

export const performJump = async (
  scene: Scene,
  character: AbstractMesh,
  map: TileMapType,
  offset: Vector3,
  setSuccess: (success: boolean) => void,
  setGameOver: (over: boolean) => void
) => {
  if (scene.metadata["result"]) {
    if (scene && !scene.isDisposed) {
      scene.dispose();
    }
    await sleep(1500);
    setSuccess(false);
    setGameOver(true);
    return;
  }

  const jumpStep = 3;

  const direction = calcDirection(character);
  if (!direction) return;

  const [jumpType, nextStepOffset] = checkNextStepStatus(
    map,
    offset,
    character.position,
    direction
  );

  scene.getAnimationGroupByName("ArmLeftAction")?.start();
  scene.getAnimationGroupByName("ArmRightAction")?.start();

  switch (jumpType) {
    case JumpType.JUMP_TYPE_PLACE:
      performPlaceJump(scene, character);
      await sleep(1500);
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setSuccess(false);
      setGameOver(true);
      return;
    case JumpType.JUMP_TYPE_LOW_TO_HIGHT:
      performLowHightJump(
        scene,
        map,
        character,
        direction,
        jumpStep,
        nextStepOffset
      );
      break;
    case JumpType.JUMP_TYPE_HIGHT_TO_LOW:
      performHightLowJump(
        scene,
        map,
        character,
        direction,
        jumpStep,
        nextStepOffset
      );
      break;
    default:
      break;
  }
  await sleep(2000);
};
