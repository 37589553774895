import { IconComponentProps } from ".";

export const IconTools = (props: IconComponentProps) => {
  const width = props.width ? props.width : 2;
  const color = props.color ? props.color : "#3F4A61";
  return (
    <svg
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 24.98h5.33L23.66 7.65a3.77 3.77 0 00-5.33-5.33L1 19.65v5.33M16.33 4.33l5.34 5.34"
        stroke={color}
        strokeWidth={width}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 7.67L6.33 1 1 6.33 7.67 13M6.33 7.67l-2 2M18.33 13L25 19.67 19.67 25 13 18.33M18.33 19.67l-2 2"
        stroke={color}
        strokeWidth={width}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
