import I18n from "i18n-js";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { btnDelay, usePlaySound } from "common/utils";
import { IconContact, IconFeedback } from "features/universal/assets";

export const ContactActionBar = (props: { visible: boolean }) => {
  const { visible } = props;
  const play = usePlaySound();
  const handleDialog = useDialog();

  const handleOpenPage = (type: DialogType) => () => {
    play();
    btnDelay(() => {
      handleDialog({
        type: DialogType.USER_PROFILE,
        value: false,
      });
      handleDialog({
        type: type,
        value: true,
      });
    });
  };

  return (
    <div
      className="flex-row-view items-center justify-evenly w-full h-[112px] z-50 bg-white"
      style={{ display: !visible && "none" }}
    >
      <button
        onClick={handleOpenPage(DialogType.CONTACT)}
        className="flex-col-el flex-center p-0 bg-orange/100 w-[50%] h-full border-solid border-white border-[6px] border-r-[3px] cursor-pointer active:opacity-70"
      >
        <div className="flex-row-center rounded-[4px] w-full">
          <div className="flex-col-center w-[100px] h-full">
            <IconContact />
          </div>

          <p className="text text-white !text-[24px] !ml-3 !mt-3">
            {I18n.t("MSG_SETTING_GO_TO_CONTACT")}
          </p>
        </div>
      </button>

      <button
        onClick={handleOpenPage(DialogType.RATING)}
        className="flex-col-el flex-center p-0 bg-orange/100 w-[50%] h-full border-solid border-white border-[6px] border-l-[3px] cursor-pointer active:opacity-70"
      >
        <div className="flex-row-center rounded-[4px] w-full">
          <div className="flex-col-center w-[100px] h-full">
            <IconFeedback />
          </div>

          <p className="text text-white !text-[24px] !ml-3 !mt-3">
            {I18n.t("MSG_SETTING_GO_TO_REVIEW")}
          </p>
        </div>
      </button>
    </div>
  );
};
