import { PointerEventHandler } from "react";
import I18n from "i18n-js";
import Constants from "common/constant";

export const Failed = (props: {
  scale: number;
  handleReset: PointerEventHandler<HTMLButtonElement>;
}) => {
  const { scale, handleReset } = props;
  return (
    <div className="flex-col-center !absolute top-0 left-0 z-50 w-full h-full bg-textcolor/black/20">
      <div
        className="flex-col-center w-[650px] h-[450px] rounded-[8px] border-[8px] border-solid border-white bg-white"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div className="flex-col-center w-full h-full p-4 rounded-[4px] bg-beige/60">
          <div className="flex-row-center !justify-around w-full">
            <img
              className="w-[209px] h-[240px] flex-col-view"
              src={`${Constants.assetHost}/assets/images/duck_attention.png`}
              alt="*"
            />

            <div className="flex-col-center h-full">
              <p className="text-body text-textcolor/black text-[32px] whitespace-pre">
                {I18n.t("MSG_COURSE_GAME_FAILED_TITLE")}
              </p>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_COURSE_GAME_FAILED_TEXT")}
              </p>
            </div>
          </div>

          <div className="flex-row-el flex-center alpha mt-12 ml-12">
            <button className="btn btn-danger" onPointerDown={handleReset}>
              <p className="text-body text-white text-[20px] !mb-[3px]">
                {I18n.t("MSG_COURSE_GAME_FAILED_RESET_BTN")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
