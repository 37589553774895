import I18n from "i18n-js";
import { useDialog, useRecordEvent } from "app/hook";
import { DialogType, AnalyticsEventType } from "app/types";
import { IconSettings } from "common/assets";
import { btnDelay, useScale, usePlaySound } from "common/utils";

export const ParentGateButton = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const handleRecordEvent = useRecordEvent();

  const handleOpenParentSetting = () => {
    btnDelay(() => {
      play();
      handleDialog({
        type: DialogType.PARENT_GATE,
        value: true,
      });
      handleRecordEvent(AnalyticsEventType.PARENT_GATE_BTN_CLICK);
    });
  };

  return (
    <div
      className="flex-col-el z-[1000] absolute alpha origin-right"
      style={{
        bottom: 30 * scale,
        right: 16 * scale,
        transform: `scale(${scale})`,
      }}
    >
      <button className="btn btn-danger" onClick={handleOpenParentSetting}>
        <div className="flex-row-el flex-center gap-2 relative mb-[3px]">
          <div className="w-[40px] h-[40px]">
            <IconSettings />
          </div>
          <p className="text-body text-white text-[36px]">
            {I18n.t("MSG_SETTING_PARENT_GATE")}
          </p>
        </div>
      </button>
    </div>
  );
};
