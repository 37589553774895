/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  logEvent,
  Analytics,
  setUserId,
  isSupported,
  getAnalytics,
  initializeAnalytics,
} from "firebase/analytics";
import { RootState } from "app/store";
import { actions } from "app/configSlice";
import { AnalyticsEventType } from "app/types";
import { isProd } from "common/utils";

export const useAnalytics = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [analytics, setAnalytics] = useState<Analytics | null>(null);
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleVisibilityState = useCallback(() => {
    console.log(document.visibilityState);
    if (analytics && user.active) {
      logEvent(
        analytics,
        document.visibilityState === "visible"
          ? AnalyticsEventType.ACTIVE
          : AnalyticsEventType.BACKGROUND,
        {
          page_path: location.pathname,
          page_title: location.pathname,
        }
      );
    }
  }, [user.active?.uid]);

  useEffect(() => {
    isSupported()
      .then((supported) => {
        if (supported) {
          try {
            const analytics = getAnalytics();
            setAnalytics(analytics);
            dispatch(actions.updateAnalyticsSupported(true));
          } catch (error) {
            console.warn("Google Analytics Error.");
            console.error(error);
          }
        } else {
          console.warn("Google Analytics is not supported.");
        }
      })
      .catch((error) => {
        console.warn(error);
      });

    document.addEventListener("visibilitychange", handleVisibilityState);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityState);
    };
  }, []);

  useEffect(() => {
    if (analytics) {
      setUserId(analytics, user.active?.uid);
    }
  }, [analytics, user.active?.uid]);

  useEffect(() => {
    if (isProd && analytics) {
      logEvent(analytics, "page_view", {
        page_path: location.pathname,
        page_title: location.pathname,
      });
    }
  }, [location]);
};
