/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useRef, useMemo, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import throttle from "lodash-es/throttle";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { Loading } from "common/elements";
import { ConfigContext } from "features/top";
import { Course } from "features/courses/types";
import { BackToCourseTop } from "features/courses/components";
import { MainScenePage } from "features/courses/ocha/game/scene";

export const OchaGameMainPage = ({
  stage,
  step,
}: {
  stage: number;
  step: number;
}) => {
  const { scale, width } = useScale();
  const navigate = useNavigate();
  const [isFirst, setIsFirst] = useState(true);
  const [reload, _setReload] = useState(false);
  const reloadRef = useRef(reload);
  const setReload = (reload: boolean) => {
    reloadRef.current = reload;
    _setReload(reload);
  };
  const [loading, setLoading] = useState(true);
  const configContext = useContext(ConfigContext);
  const user = useSelector((state: RootState) => state.user.appUser);
  const courseDisplay = useSelector(
    (state: RootState) => state.courses.message[Course.ALGORITHM][stage - 1]
  );

  const handleResize = useMemo(
    () =>
      throttle(
        () => {
          setReload(!reloadRef.current);
        },
        1000,
        { leading: false, trailing: true }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      navigate("/courses");
    }
  }, [user]);

  useEffect(() => {
    if (!isFirst) {
      handleResize();
    }
  }, [scale]);

  useEffect(() => {
    if (configContext.bgmSound) {
      configContext.bgmSound.stop();
    }
    return () => {
      if (configContext.bgmSound) {
        configContext.bgmSound.play();
      }
    };
  }, [configContext.bgmSound]);

  return (
    <div className="flex-col-view w-full h-full bg-transparent">
      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_scene.jpg`}
        className="absolute top-0 left-0 w-full h-full"
      />

      <div className="flex-col-view items-start w-full h-full z-[1] opacity-100 pointer-events-none">
        <div
          style={{ width: width }}
          id="game-main-scene-canvas"
          className="flex-col-el absolute left-0 h-full rounded-[10px] pointer-events-none"
        >
          <MainScenePage
            stage={stage}
            step={step}
            reload={reload}
            setReload={setReload}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>

      {(loading || !courseDisplay) && <Loading />}
    </div>
  );
};

export const OchaGamePage = () => {
  const { stageId, stepId } = useParams<{
    stageId?: string;
    stepId?: string;
  }>();
  const step = Number(stepId);
  const stage = Number(stageId);
  return isNaN(stage) || isNaN(step) || stage > 2 || step > 7 ? (
    <BackToCourseTop />
  ) : (
    <OchaGameMainPage stage={stage} step={step} />
  );
};
