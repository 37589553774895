import $ from "jquery";
import anime from "animejs";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";
import { calcOffset, getTransformPropertyValue } from "common/blockly/codeGen";

export const setCheckboxTextFontSize = (id: string, value: number) => {
  const container = $(`#checkbox-container-${id}`);
  const checkbox = $(`#checkbox-${id}`);
  const text = $(`#checkbox-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const fontSizeScale = value / currentFontSize;
  const [translateX, translateY, rotate, scale] = getTransformPropertyValue(
    container
  );

  const offset = calcOffset(
    currentContainerWidth,
    currentContainerHeight,
    (rotate * Math.PI) / 180,
    {
      x: currentContainerWidth * (fontSizeScale - 1),
      y: currentContainerHeight * (fontSizeScale - 1),
    }
  );

  container.css(
    "transform",
    `translateX(${translateX - offset.x}px) translateY(${
      translateY + offset.y
    }px) rotate(${rotate}deg) scale(${scale})`
  );

  checkbox.css("width", `${value}`);
  checkbox.css("height", `${value}`);
  text.css("font-size", `${value}px`);
  container.css("width", `${currentContainerWidth * fontSizeScale}`);
  container.css("height", `${currentContainerHeight * fontSizeScale}`);
};
export const setCheckboxTextFontSizeByStep = (id: string, value: number) => {
  const container = $(`#checkbox-container-${id}`);
  const checkbox = $(`#checkbox-${id}`);
  const text = $(`#checkbox-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const fontSizeScale = (currentFontSize + value) / currentFontSize;
  const [translateX, translateY, rotate, scale] = getTransformPropertyValue(
    container
  );

  const offset = calcOffset(
    currentContainerWidth,
    currentContainerHeight,
    (rotate * Math.PI) / 180,
    {
      x: currentContainerWidth * (fontSizeScale - 1),
      y: currentContainerHeight * (fontSizeScale - 1),
    }
  );

  container.css(
    "transform",
    `translateX(${translateX - offset.x}px) translateY(${
      translateY + offset.y
    }px) rotate(${rotate}deg) scale(${scale})`
  );

  checkbox.css("width", `${currentFontSize + value}`);
  checkbox.css("height", `${currentFontSize + value}`);
  text.css("font-size", `${currentFontSize + value}px`);
  container.css("width", `${currentContainerWidth * fontSizeScale}`);
  container.css("height", `${currentContainerHeight * fontSizeScale}`);
};

export const setCheckboxTextFontSizeByTime = (
  id: string,
  time: number,
  value: number
) => {
  const container = $(`#checkbox-container-${id}`);
  const text = $(`#checkbox-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const fontSizeScale = value / currentFontSize;
  const [, , rotate] = getTransformPropertyValue(container);

  const offset = calcOffset(
    currentContainerWidth,
    currentContainerHeight,
    (rotate * Math.PI) / 180,
    {
      x: currentContainerWidth * (fontSizeScale - 1),
      y: currentContainerHeight * (fontSizeScale - 1),
    }
  );

  const animation1 = anime({
    targets: `#checkbox-container-${id}`,
    translateX: `-=${offset.x}`,
    translateY: `+=${offset.y}`,
    width: `+=${currentContainerWidth * (fontSizeScale - 1)}`,
    height: `+=${currentContainerHeight * (fontSizeScale - 1)}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#checkbox-${id}`,
    width: value,
    height: value,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation3 = anime({
    targets: `#checkbox-text-${id}`,
    fontSize: value,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  animation3.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
    animation3.pause();
  });
  return Promise.all([
    animation1.finished,
    animation2.finished,
    animation3.finished,
  ]);
};

export const getCheckboxText = (id: string): string =>
  String($(`#checkbox-text-${id}`).text());
export const setCheckboxText = (id: string, value?: string) => {
  const obj = $(`#checkbox-container-${id}`);
  const text = $(`#checkbox-text-${id}`);
  const currentWidth = obj.width();
  const currentHeight = obj.width();
  const currentText = text.text();
  const fontSize = Number(text.css("font-size").split("px")[0]);
  const letterSpacing = Number(text.css("letter-spacing").split("px")[0]);

  const [translateX, translateY, rotate, scale] = getTransformPropertyValue(
    obj
  );
  const textValue = value ?? "";
  const delta =
    (textValue.length - currentText.length) * (fontSize + letterSpacing);
  const offset = calcOffset(
    currentWidth,
    currentHeight,
    (rotate * Math.PI) / 180,
    {
      x: delta,
      y: 0,
    }
  );
  obj.css(
    "transform",
    `translateX(${translateX - offset.x}px) translateY(${
      translateY + offset.y
    }px) rotate(${rotate}deg) scale(${scale})`
  );

  obj.css(
    "width",
    `${
      currentWidth +
      (value.length - currentText.length) * (fontSize + letterSpacing)
    }`
  );
  text.text(textValue);
};
