import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { css } from "@emotion/css";
import { RootState } from "app/store";
import { IconTools } from "common/assets";
import { useScale, usePlaySound } from "common/utils";
import {
  IconStamp,
  IconStock,
  IconShapeParts,
  IconActionParts,
} from "features/creator/assets";
import { actions } from "features/creator/slice";
import { CreatorOptions } from "features/creator/types";
import { ShapePanel } from "features/creator/material/Shape";
import { StampPanel } from "features/creator/material/Stamp";
import { StockPanel } from "features/creator/material/StockPanel";
import { ActionPanel } from "features/creator/material/ActionPanel";

export enum TabIndex {
  NONE = 0,
  ACTION = 1,
  SHAPE = 2,
  STAMP = 3,
  STOCK = 4,
}

export const Material = (props: { options: CreatorOptions }) => {
  const offsetX = 650;
  const panelWidth = 350;
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const { margin, tabHeight } = props.options;
  const [selectedTabIndex, setSelectedTabIndex] = useState<TabIndex>(
    TabIndex.NONE
  );
  const collapse = useSelector(
    (state: RootState) => state.creator.panelCollapse.material
  );

  const handleTabClick = (tabIndex: TabIndex) => () => {
    play();
    setSelectedTabIndex(tabIndex);
    if (tabIndex === selectedTabIndex || collapse) {
      dispatch(actions.handleMaterialPanelCollapse(!collapse));
    }
    dispatch(actions.handleBlocklyWorkspaceCollapse(true));
    dispatch(actions.handlePropertyCollapse(true));
    dispatch(actions.handleComponentListCollapse(true));
  };

  return (
    <div
      id="material-tab-panel"
      className="flex-row-center z-[2] !absolute top-0 left-0"
    >
      <div
        id="material-tab"
        className={`flex-row-view origin-top-left !absolute drop-shadow-normal_show ${css(
          {
            height: tabHeight,
            top: margin * scale,
            left: offsetX * scale,
            transform: `scale(${scale})`,
          }
        )}`}
      >
        <div className="flex-col-center w-[64px] h-[74px] p-[4px] rounded-l-[12px] bg-beige/alt">
          <div className="flex-col-center w-[24px] h-[24px] mb-[4px]">
            <IconTools color="#FEFDFE" />
          </div>
          <p className="text w-full text-white !text-[16px] !leading-[18px]">
            {I18n.t("MSG_CREATOR_MENU_COMPONENT_BTN")}
          </p>
        </div>

        <div className="flex-row-center h-full bg-beige/60 rounded-r-[12px] border-[4px] !border-l-0 border-solid border-white">
          <div
            onClick={handleTabClick(TabIndex.ACTION)}
            className={`flex-col-center cursor-pointer w-[80px] h-full ${
              selectedTabIndex === TabIndex.ACTION && !collapse
                ? "bg-green/altcolor"
                : ""
            }`}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block w-[24px] h-[24px] mb-[4px]">
                <IconActionParts />
              </span>
              {I18n.t("MSG_CREATOR_MENU_COMPONENT_ACTION_BTN")}
            </p>
          </div>

          <div
            onClick={handleTabClick(TabIndex.SHAPE)}
            className={`flex-col-center cursor-pointer w-[80px] h-full ${
              selectedTabIndex === TabIndex.SHAPE && !collapse
                ? "bg-green/altcolor"
                : ""
            }`}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block w-[24px] h-[24px] mb-[4px]">
                <IconShapeParts />
              </span>
              {I18n.t("MSG_CREATOR_MENU_COMPONENT_SHAPE_BTN")}
            </p>
          </div>

          <div
            onClick={handleTabClick(TabIndex.STAMP)}
            className={`flex-col-center cursor-pointer w-[80px] h-full ${
              selectedTabIndex === TabIndex.STAMP && !collapse
                ? "bg-green/altcolor"
                : ""
            }`}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block w-[24px] h-[24px] mb-[4px]">
                <IconStamp />
              </span>
              {I18n.t("MSG_CREATOR_MENU_COMPONENT_STAMP_BTN")}
            </p>
          </div>

          <div
            onClick={handleTabClick(TabIndex.STOCK)}
            className={`flex-col-center cursor-pointer w-[100px] rounded-r-[8px] h-full ${
              selectedTabIndex === TabIndex.STOCK && !collapse
                ? "bg-green/altcolor"
                : ""
            }`}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block w-[24px] h-[24px] mb-[4px]">
                <IconStock />
              </span>
              {I18n.t("MSG_CREATOR_MENU_COMPONENT_STOCK_BTN")}
            </p>
          </div>
        </div>
      </div>

      <div
        className="flex-row-view !absolute origin-top-left"
        style={{
          left: offsetX * scale,
          top: (tabHeight + 25) * scale,
          transform: `translateZ(0) scale(${scale})`,
          visibility: collapse ? "hidden" : "visible",
          backfaceVisibility: "hidden",
          WebkitBackfaceVisibility: "hidden",
        }}
      >
        <div
          className="flex-row-view drop-shadow-normal_show"
          style={{
            width: panelWidth * 2,
          }}
        >
          {selectedTabIndex === TabIndex.ACTION && <ActionPanel />}
          {selectedTabIndex === TabIndex.SHAPE && <ShapePanel />}
          {selectedTabIndex === TabIndex.STAMP && <StampPanel />}
          {selectedTabIndex === TabIndex.STOCK && <StockPanel />}
        </div>
      </div>
    </div>
  );
};
