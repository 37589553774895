export const Stage1LevelRect = [
  {
    left: 80,
    top: 120,
  },
  {
    left: 400,
    top: 120,
  },
  {
    left: 700,
    top: 120,
  },
  {
    left: 1000,
    top: 120,
  },
  {
    left: 1000,
    top: 450,
  },
  {
    left: 700,
    top: 450,
  },
  {
    left: 400,
    top: 450,
  },
];
export const Stage2LevelRect = [
  {
    left: 80,
    top: 100,
  },
  {
    left: 80,
    top: 450,
  },
  {
    left: 400,
    top: 450,
  },
  {
    left: 400,
    top: 100,
  },
  {
    left: 700,
    top: 100,
  },
  {
    left: 700,
    top: 450,
  },
  {
    left: 1000,
    top: 450,
  },
];
export const Stage3LevelRect = [
  {
    left: 100,
    top: 100,
  },
  {
    left: 100,
    top: 450,
  },
  {
    left: 400,
    top: 450,
  },
  {
    left: 700,
    top: 450,
  },
  {
    left: 1000,
    top: 450,
  },
  {
    left: 1000,
    top: 100,
  },
  {
    left: 700,
    top: 100,
  },
];
export const Stage4LevelRect = [
  {
    left: 80,
    top: 450,
  },
  {
    left: 80,
    top: 100,
  },
  {
    left: 400,
    top: 100,
  },
  {
    left: 400,
    top: 450,
  },
  {
    left: 700,
    top: 450,
  },
  {
    left: 700,
    top: 100,
  },
  {
    left: 1000,
    top: 100,
  },
];
export const Stage5LevelRect = [
  {
    left: 100,
    top: 100,
  },
  {
    left: 100,
    top: 450,
  },
  {
    left: 400,
    top: 450,
  },
  {
    left: 400,
    top: 100,
  },
  {
    left: 700,
    top: 100,
  },
  {
    left: 1000,
    top: 100,
  },
  {
    left: 1000,
    top: 450,
  },
];
export const Stage6LevelRect = [
  {
    left: 100,
    top: 100,
  },
  {
    left: 100,
    top: 450,
  },
  {
    left: 400,
    top: 450,
  },
  {
    left: 700,
    top: 450,
  },
  {
    left: 700,
    top: 100,
  },
  {
    left: 1000,
    top: 100,
  },
  {
    left: 1000,
    top: 450,
  },
];
export const Stage7LevelRect = [
  {
    left: 80,
    top: 120,
  },
  {
    left: 400,
    top: 120,
  },
  {
    left: 400,
    top: 450,
  },
  {
    left: 700,
    top: 450,
  },
  {
    left: 700,
    top: 120,
  },
  {
    left: 1000,
    top: 120,
  },
  {
    left: 1000,
    top: 450,
  },
];

export const StageLevelRects = [
  Stage1LevelRect,
  Stage2LevelRect,
  Stage3LevelRect,
  Stage4LevelRect,
  Stage5LevelRect,
  Stage6LevelRect,
  Stage7LevelRect,
];
