export const StageLevelRect = [
  {
    left: 80,
    top: 170,
  },
  {
    left: 300,
    top: 400,
  },
  {
    left: 400,
    top: 90,
  },
  {
    left: 700,
    top: 0,
  },
  {
    left: 680,
    top: 300,
  },
  {
    left: 900,
    top: 520,
  },
  {
    left: 1010,
    top: 250,
  },
];

export const StageLevelPath = (
  radius: number,
  scale: number,
  offset: number
) => [
  `M ${(StageLevelRect[0].left + radius) * scale + offset} ${
    (StageLevelRect[0].top + radius * 2) * scale
  } Q ${(StageLevelRect[0].left + radius) * scale + offset} ${
    (StageLevelRect[0].top +
      radius * 2 +
      (StageLevelRect[1].top - StageLevelRect[0].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[1].left * scale + offset} ${
    (StageLevelRect[1].top + radius) * scale
  }`,
  `M ${(StageLevelRect[1].left + radius * 1.5) * scale + offset} ${
    StageLevelRect[1].top * scale
  } Q ${(StageLevelRect[2].left + radius) * scale + offset} ${
    (StageLevelRect[2].top +
      radius * 2 +
      (StageLevelRect[1].top - StageLevelRect[2].top - radius * 2) / 2) *
    scale
  } ${(StageLevelRect[2].left + radius) * scale + offset} ${
    (StageLevelRect[2].top + radius * 2) * scale
  }`,
  `M ${(StageLevelRect[2].left + radius * 2) * scale + offset} ${
    (StageLevelRect[2].top + radius) * scale
  } Q ${
    (StageLevelRect[2].left +
      radius * 2 +
      (StageLevelRect[3].left - StageLevelRect[2].left - radius * 2) / 2) *
      scale +
    offset
  } ${(StageLevelRect[3].top + radius) * scale} ${
    StageLevelRect[3].left * scale + offset
  } ${(StageLevelRect[3].top + radius) * scale}`,
  `M ${(StageLevelRect[3].left + radius) * scale + offset} ${
    (StageLevelRect[3].top + radius * 2) * scale
  } Q ${(StageLevelRect[3].left + radius) * scale + offset} ${
    (StageLevelRect[3].top +
      radius * 2 +
      (StageLevelRect[4].top - StageLevelRect[3].top - radius * 2) / 2) *
    scale
  }  ${(StageLevelRect[4].left + radius) * scale + offset} ${
    StageLevelRect[4].top * scale
  }`,
  `M ${(StageLevelRect[4].left + radius) * scale + offset} ${
    (StageLevelRect[4].top + radius * 2) * scale
  } Q ${(StageLevelRect[4].left + radius) * scale + offset} ${
    (StageLevelRect[4].top +
      radius * 2 +
      (StageLevelRect[5].top - StageLevelRect[4].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[5].left * scale + offset} ${
    (StageLevelRect[5].top + radius) * scale
  }`,
  `M ${(StageLevelRect[5].left + radius * 1.5) * scale + offset} ${
    StageLevelRect[5].top * scale
  } Q ${(StageLevelRect[6].left + radius) * scale + offset} ${
    (StageLevelRect[6].top +
      radius * 2 +
      (StageLevelRect[5].top - StageLevelRect[6].top - radius * 2) / 2) *
    scale
  } ${(StageLevelRect[6].left + radius) * scale + offset} ${
    (StageLevelRect[6].top + radius * 2) * scale
  }`,
];
