/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { RootState } from "app/store";
import { fetchAuthToken } from "app/Auth";
import Constants from "common/constant";
import { Switch } from "common/elements";
import { actions } from "./slice";
import { QrCodeScan } from "./QrCodeScan";
import { PeeringInfo } from "./PeeringInfo";
import { PeeringStatus, ConnectionStatus } from "./type";

export const Connection = () => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState(false);
  const [scan, setScan] = useState(false);
  const peeringStatus = useSelector(
    (state: RootState) => state.connection.peeringStatus
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const content = useSelector((state: RootState) => state.connection.send);
  const peerId = useSelector((state: RootState) => state.connection.peerId);
  const status = useSelector((state: RootState) => state.connection.status);

  const socket = useMemo(
    () =>
      io(Constants.wsHost, {
        autoConnect: false,
        auth: async (cb) => {
          cb({ token: await fetchAuthToken() });
        },
        query: { uid: user.active.uid },
      }),
    []
  );

  useEffect(() => {
    if (socket.connected) {
      socket.emit("peer_message", {
        peer_id: peerId,
        content: content,
      });
    }
  }, [content]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket connect: ", socket.id);
      dispatch(actions.updateConnectionStatus(ConnectionStatus.ONLINE));
    });
    socket.on("peer_connect", (message) => {
      console.log("peer connect: ", message);
      setInfo(false);
      const { caller_uid } = message;
      dispatch(
        actions.updatePeerConnectionStatus({
          status: PeeringStatus.CONNECTED,
          peerId: caller_uid,
        })
      );
    });
    socket.on("peer_message", async (message) => {
      console.log("peer_message", message);
      const { content } = message;
      dispatch(actions.receiveMessage(content));
    });
    socket.on("peer_disconnect", async () => {
      console.log("peer disconnect.");
      dispatch(
        actions.updatePeerConnectionStatus({
          status: PeeringStatus.DISCONNECTED,
        })
      );
    });
    socket.on("disconnect", async () => {
      console.log("socket disconnect.");
      dispatch(
        actions.updatePeerConnectionStatus({
          status: PeeringStatus.DISCONNECTED,
        })
      );
      dispatch(actions.updateConnectionStatus(ConnectionStatus.OFFLINE));
    });
    return () => {
      dispatch(
        actions.updatePeerConnectionStatus({
          status: PeeringStatus.DISCONNECTED,
        })
      );
      dispatch(actions.updateConnectionStatus(ConnectionStatus.OFFLINE));
      if (socket) {
        console.log("clean up socket and listeners.");
        socket.removeAllListeners();
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    socket.connect();
  }, []);

  const handleConnect = () => {
    if (status === ConnectionStatus.OFFLINE) {
      socket.connect();
    } else if (status === ConnectionStatus.ONLINE) {
      socket.disconnect();
      dispatch(actions.updateConnectionStatus(ConnectionStatus.OFFLINE));
    }
  };

  const handleLocalCamera = () => {
    setScan(true);
  };

  const peerConnect = (callee_uid: string) => {
    setScan(false);
    socket.emit("peer_connect", {
      caller_uid: user.active.uid,
      callee_uid,
    });
    dispatch(
      actions.updatePeerConnectionStatus({
        status: PeeringStatus.CONNECTED,
        peerId: callee_uid,
      })
    );
  };
  const peerDisconnect = () => {
    setScan(false);
    socket.emit("peer_disconnect");
    dispatch(
      actions.updatePeerConnectionStatus({
        status: PeeringStatus.DISCONNECTED,
      })
    );
  };

  const handleOpenPeeringInfo = () => {
    setInfo(true);
  };
  const handleClosePeeringInfo = () => {
    setInfo(false);
  };

  return (
    <>
      <QrCodeScan open={scan} setOpen={setScan} peerConnect={peerConnect} />
      <PeeringInfo
        open={info}
        value={user.active.uid}
        close={handleClosePeeringInfo}
      />

      {peeringStatus === PeeringStatus.CONNECTED ? (
        <tr>
          <td className="pb-[52px] w-[160px]">
            <p className="text text-white !text-right !text-[30px] !leading-[30px]">
              ペアリング
            </p>
          </td>
          <td className="pl-[20px] pb-[52px]">
            <div className="flex-row-center w-full h-[80px] gap-3">
              <div className="flex-col-el flex-center w-full h-full upsilon pointer-events-auto">
                <button
                  className="btn btn-danger w-full h-full"
                  onClick={peerDisconnect}
                >
                  <div className="flex-col-el flex-center mb-[3px]">
                    <span className="material-symbols-outlined text-[48px] text-white">
                      cancel
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </td>
        </tr>
      ) : status === ConnectionStatus.ONLINE ? (
        <>
          <tr>
            <td className="pb-[52px] w-[160px]">
              <p className="text text-white !text-right !text-[30px] !leading-[30px]">
                QRコード
              </p>
            </td>
            <td className="pl-[20px] pb-[52px]">
              <div className="flex-row-center w-full h-[80px] gap-3">
                <div className="flex-col-el flex-center w-full h-full upsilon pointer-events-auto">
                  <button
                    className="btn btn-primary w-full h-full"
                    onClick={handleOpenPeeringInfo}
                  >
                    <div className="flex-col-el flex-center mb-[3px]">
                      <span className="material-symbols-outlined text-[48px] text-white">
                        qr_code_scanner
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="pb-[52px] w-[160px]">
              <p className="text text-white !text-right !text-[30px] !leading-[30px]">
                カメラ
              </p>
            </td>
            <td className="pl-[20px] pb-[52px]">
              <div className="flex-row-center w-full h-[80px] gap-3">
                <div className="flex-col-el flex-center w-full h-full upsilon pointer-events-auto">
                  <button
                    className="btn btn-primary w-full h-full"
                    onClick={handleLocalCamera}
                  >
                    <div className="flex-col-el flex-center mb-[3px]">
                      <span className="material-symbols-outlined text-[48px] text-white">
                        photo_camera
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </>
      ) : (
        <tr>
          <td className="pb-[52px] w-[250px]">
            <p className="text text-white !text-right !text-[30px] !leading-[30px]">
              タンジブルモード
            </p>
          </td>
          <td className="px-[20px] pb-[52px]">
            <div className="flex-row-center w-full gap-3">
              <Switch
                width={120}
                height={50}
                value={false}
                onClick={handleConnect}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
