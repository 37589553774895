import * as React from "react";

export const MaterialImageSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={48}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.56 2.165H1.81v26.85h40.75V2.165Z"
        fill="#F8F1E8"
        stroke="#E6CCA1"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="m1.81 2.165 40.75 26.85M42.56 2.165 1.81 29.015"
        stroke="#E6CCA1"
        strokeWidth={3}
        strokeMiterlimit={10}
      />
      <path
        d="M42.19 10.505H28.86a4 4 0 0 0-4 4v13.33a4 4 0 0 0 4 4h13.33a4 4 0 0 0 4-4v-13.33a4 4 0 0 0-4-4Z"
        fill="#FEFDFE"
        stroke="#E6CCA1"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.53 15.835h.01M24.87 25.165l5.33-5.33a2.69 2.69 0 0 1 4 0l6.67 6.69"
        stroke="#E6CCA1"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m46.17 25.165-2.64-2.64a2.69 2.69 0 0 0-4 0l-1.36 1.31"
        stroke="#E6CCA1"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.19 10.505H28.86a4 4 0 0 0-4 4v13.33a4 4 0 0 0 4 4h13.33a4 4 0 0 0 4-4v-13.33a4 4 0 0 0-4-4Z"
        stroke="#E6CCA1"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
