import React, { useState, useEffect, PointerEvent } from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getEmptyImage } from "react-dnd-html5-backend";
import i18n from "i18n-js";
import { RootState, AppDispatch } from "app/store";
import { ComponentCategory, ComponentTypeIds } from "common/components";
import {
  MaterialLineSvg,
  MaterialStarSvg,
  MaterialCircleSvg,
  MaterialTriangleSvg,
  MaterialRectangleSvg,
} from "features/creator/assets/material";
import { actions } from "features/creator/slice";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType } from "features/creator/types";

type MaterialListDataType = {
  id: ComponentTypeIds;
  categoryId: ComponentCategory;
  text: string;
  icon: React.FC<any>;
};

const shapeComponents: MaterialListDataType[] = [
  {
    id: ComponentTypeIds.CIRCLE,
    categoryId: ComponentCategory.SHAPE,
    text: "MSG_CREATOR_COMPONENT_LABEL_CIRCLE",
    icon: () => <MaterialCircleSvg />,
  },
  {
    id: ComponentTypeIds.TRIANGLE,
    categoryId: ComponentCategory.SHAPE,
    text: "MSG_CREATOR_COMPONENT_LABEL_TRIANGLE",
    icon: () => <MaterialTriangleSvg />,
  },
  {
    id: ComponentTypeIds.RECTANGLE,
    categoryId: ComponentCategory.SHAPE,
    text: "MSG_CREATOR_COMPONENT_LABEL_RECT",
    icon: () => <MaterialRectangleSvg />,
  },
  {
    id: ComponentTypeIds.STAR,
    categoryId: ComponentCategory.SHAPE,
    text: "MSG_CREATOR_COMPONENT_LABEL_STAR",
    icon: () => <MaterialStarSvg />,
  },
  {
    id: ComponentTypeIds.LINE,
    categoryId: ComponentCategory.SHAPE,
    text: "MSG_CREATOR_COMPONENT_LABEL_LINE",
    icon: () => <MaterialLineSvg />,
  },
];

const RenderItem = React.memo(({ item }: { item: MaterialListDataType }) => {
  const handleAction = useActionCommand();
  const [click, setClick] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );

  const onClickStart = () => setClick(true);
  const onClickEnd = (e: PointerEvent) => {
    e.stopPropagation();
    if (click) {
      handleAction({
        type: ActionCommandType.ADD_COMPONENT,
        componentType: item.id,
        category: item.categoryId,
        screenId: selectedScreenId,
      });
      setClick(false);
    }
  };

  const [collected, drag, dragPreview] = useDrag(() => ({
    type: item.id,
    item: { type: item.id, category: item.categoryId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      didDrop: !!monitor.didDrop(),
    }),
  }));

  useEffect(() => {
    if (collected.isDragging) {
      setClick(false);
      dispatch(actions.handleMaterialPanelCollapse(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collected]);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  const ComponentIcon = item.icon;

  return (
    <div
      ref={drag}
      className="flex-col-center cursor-pointer w-[102px] h-[104px] bg-beige/alt2 rounded-[12px] mx-[3px] drop-shadow-[2px_2px_0px_rgba(63,74,97,0.1)]"
      onPointerDown={onClickStart}
      onPointerUp={onClickEnd}
    >
      <div className="w-full h-[56px] flex-col-center">
        <ComponentIcon />
      </div>
      <div className="flex-col-center w-full h-[30%]">
        <p className="text !text-[16px] !leading-[22px] text-gray/100">
          {i18n.t(item.text)}
        </p>
      </div>
    </div>
  );
});

export const ShapePanel = () => {
  const TitleComponent = ({ title }) => (
    <div className="w-full h-[32px] bg-beige/alt flex-col-view justify-center items-start rounded-t-[12px]">
      <p className="text !leading-[30px] text-white !ml-[16px]">{title}</p>
    </div>
  );

  return (
    <div className="w-[55%] flex-col-view justify-start bg-beige/60 p-[24px] rounded-[12px] border-solid border-white border-[4px]">
      <TitleComponent
        title={i18n.t("MSG_CREATOR_MENU_COMPONENT_PIECE_SHAPE")}
      />
      <div className="w-full h-[236px] bg-white flex-row-view flex-wrap items-center rounded-b-[12px]">
        {shapeComponents.map((item, index) => (
          <RenderItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};
