/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useMemo, useState, useEffect, WheelEventHandler } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import anime from "animejs";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { DialogMessage } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";
import {
  DisplayMode,
  ProjectType,
  ProjectEntity,
  ProjectPanelType,
} from "features/creator/types";
import {
  CardBg,
  IconDrawer,
  GuideBtnIcon,
  IconPublishCopiedOn,
  IconPublishCopiedOff,
  IconProjectCardFavorited,
  IconProjectCardFavoritedReverse,
} from "features/creator/assets";
import { AppUser } from "features/user/types";
import { actions } from "features/creator/slice";
import { IconViewed } from "features/user/assets";
import { createProject } from "features/creator/api";
import { CreateProjectModal } from "features/creator/elements";
import { projectAnimation } from "./util";

export const ProjectEmpty = () => (
  <div className="flex-col-el flex-center w-[904px] h-full gap-4">
    <div className="flex-row-center">
      <p className="text-body text-textcolor/black text-[24px] px-6 py-2 bg-yellow/100 rounded-3xl">
        まだ アプリが ありません
      </p>
    </div>
    <div className="flex-row-center gap-4">
      <div className="flex-col-el flex-center">
        <div className="flex-col-el flex-center">
          <GuideBtnIcon />
        </div>
        <p className="text-body text-[18px] text-blue/100">
          {I18n.t("MSG_GUIDE")}
        </p>
      </div>
      <p className="text-body whitespace-pre text-[20px] leading-7 text-blue/100">{`ガイドを　さんこうにして\nアプリを　つくってみましょう！`}</p>
    </div>
  </div>
);

const ProjectCardPlaceholder = () => (
  <div className="flex-col-el flex-center gap-4 w-[168px]">
    <div className="flex-col-center gap-3">
      <div className="flex-col-center w-[80px] h-[64px]" />

      <div className="flex-col-center rounded-[16px] p-2">
        <div className="flex-col-el flex-center w-[168px] h-[186px] bg-textcolor/black/15">
          <CardBg />
        </div>
      </div>
    </div>

    <div className="flex-col-el items-center justify-start w-full h-[56px]" />

    <div className="flex-col-center w-full gap-2 h-[72px]" />
  </div>
);

const ProjectCard = ({
  user,
  project,
  handleSelectProject,
}: {
  user: AppUser;
  project?: ProjectEntity;
  handleSelectProject: (projectId: string) => () => void;
}) => (
  <div
    id={`user-app-project-container-${project.project_id}`}
    onClick={handleSelectProject(project.project_id)}
    className="flex-col-el flex-center gap-4 w-[168px] cursor-pointer pointer-events-auto"
  >
    <div className="flex-col-center gap-3">
      <div
        id={`user-app-project-publish-icon-${project.project_id}`}
        className="flex-col-center w-[80px] h-[64px]"
      >
        {project.published ? (
          <div className="flex-col-center w-[60px] h-[64px]">
            {project?.enable_copied ? (
              <IconPublishCopiedOn />
            ) : (
              <IconPublishCopiedOff />
            )}
          </div>
        ) : (
          <img
            alt="publish btn"
            src={`${Constants.assetHost}/assets/images/publish_on.png`}
            className="w-[48px] h-[48px]"
          />
        )}
      </div>

      <div className="flex-col-center rounded-[16px] p-2">
        <div className="flex-col-el flex-center w-[168px] h-[186px] absolute -z-0 bg-textcolor/black/15">
          <CardBg />
        </div>

        <div className="flex-col-center w-[144px] h-[178px] ml-4">
          <div className="flex-col-el flex-center absolute w-[62px] h-[64px]">
            <img
              alt="switch"
              className="w-full h-full"
              src={`${Constants.assetHost}/assets/images/project_card_switch.png`}
            />
          </div>

          <div
            className={`flex-col-center w-full h-full app-project-card-${project.project_id}`}
          >
            <img
              alt="app card"
              src={`${Constants.assetHost}/assets/images/${
                project.author_name === "まなパズ公式"
                  ? "app_card_official"
                  : project.author_uid === user.uid
                  ? "app_card_user"
                  : "app_card_shared"
              }.png`}
              className="w-full h-full"
            />

            <div className="flex-col-el flex-center absolute top-[39px] w-full px-[9px] h-[120px]">
              {project?.cover || project?.capture ? (
                <div className="flex-col-center w-full h-full overflow-hidden bg-white">
                  <img
                    alt="アプリのキャプチャー"
                    className="w-full h-full object-cover"
                    src={project?.cover ?? project?.capture}
                    onError={(e) =>
                      // @ts-ignore
                      (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                    }
                  />
                </div>
              ) : (
                <div className="w-full h-full bg-white" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="flex-col-el items-center justify-start w-full h-[56px]">
      <p
        className="text-body text-center text-[22px] leading-7 text-textcolor/black w-full text-ellipsis overflow-hidden"
        style={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
        }}
      >
        {project.name}
      </p>
    </div>

    <div className="flex-row-center w-full gap-2">
      <div className="flex-col-center">
        <div
          id={`app-project-card-viewed-${project.project_id}`}
          className="flex-col-center w-[40px] h-[40px] z-10"
        >
          <IconViewed />
        </div>

        <div className="flex-col-center -mt-2">
          <p
            className="text-body text-font-size-body1 text-center text-brown/100 leading-10 bg-white rounded-[20px] min-w-[40px] px-2"
            style={{
              WebkitTextStroke: "6px #FEFDFE",
            }}
          >
            <span
              className="inline-block leading-10"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {project.viewed}
            </span>
            {project.viewed}
          </p>
        </div>
      </div>

      <div className="flex-col-center">
        {project.is_favorited ? (
          <div
            id={`app-project-card-favorited-${project.project_id}`}
            className="flex-col-center w-[40px] h-[40px] z-10"
          >
            <IconProjectCardFavorited />
          </div>
        ) : (
          <div
            id={`app-project-card-favorited-${project.project_id}`}
            className="flex-col-center w-[40px] h-[40px] z-10"
          >
            <IconProjectCardFavoritedReverse />
          </div>
        )}

        <div className="flex-col-center -mt-2">
          <p
            className="text-body text-font-size-body1 text-center text-red/100 leading-10 bg-white rounded-[30px] min-w-[40px] px-2"
            style={{
              WebkitTextStroke: "6px #FEFDFE",
            }}
          >
            <span
              className="inline-block leading-10"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {project.favorited}
            </span>
            {project.favorited}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export const UserProjectPanel = ({
  user,
  projects,
}: {
  user: AppUser;
  projects: ProjectEntity[];
}) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [create, setCreate] = useState(false);
  const [guidance, setGuidance] = useState(false);
  const projectPanel = useSelector(
    (state: RootState) => state.creator.projectPanel
  );
  const isCopyProject = useSelector(
    (state: RootState) => state.creator.isCopyProject
  );
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );
  const sortedProjects = useMemo(
    () =>
      projects.sort((a, b) =>
        dayjs(a.created_time) < dayjs(b.created_time) ? -1 : 1
      ),
    [projects]
  );

  const [localSelectedProjectId, setLocalSelectedProjectId] = useState(
    selectedProjectId
  );

  useEffect(() => {
    setLocalSelectedProjectId(selectedProjectId);
  }, [selectedProjectId]);

  useEffect(() => {
    if (isCopyProject) {
      projectAnimation(
        localSelectedProjectId,
        sortedProjects[sortedProjects.length - 1].project_id,
        () => {
          dispatch(actions.handleUpdateIsCopyProject(false));
          setLocalSelectedProjectId(
            sortedProjects[sortedProjects.length - 1].project_id
          );
          dispatch(
            actions.updateSelectedProjectId(
              sortedProjects[sortedProjects.length - 1].project_id
            )
          );
        }
      );
    }
  }, [isCopyProject]);

  const handleExpend = () => {
    play();
    if (projectPanel === ProjectPanelType.USER) {
      dispatch(actions.updateProjectPanel(ProjectPanelType.NONE));
    } else {
      dispatch(actions.updateSortFilterPanel(true));
      dispatch(actions.updateProjectPanel(ProjectPanelType.USER));
    }
  };

  const handleSelectProject = (projectId: string) => () => {
    if (projectId !== localSelectedProjectId) {
      play();
      setLocalSelectedProjectId(projectId);
      projectAnimation(localSelectedProjectId, projectId, () => {
        dispatch(actions.updateProjectPanel(ProjectPanelType.NONE));
        dispatch(actions.updateDisplayMode(DisplayMode.CAPTURE));
        dispatch(actions.updateSelectedProjectId(projectId));
      });
    }
  };

  const handleOnTransitionEnd = () => {
    if (
      projectPanel === ProjectPanelType.NONE &&
      selectedProjectId !== localSelectedProjectId
    ) {
      dispatch(actions.updateDisplayMode(DisplayMode.CAPTURE));
      dispatch(actions.updateSelectedProjectId(localSelectedProjectId));
    }
  };

  const handleCreateProjectConfirm = () => {
    play();
    if (sortedProjects.length > 4) {
      btnDelay(() => setGuidance(true));
    } else {
      btnDelay(() => {
        setCreate(true);
      });
    }
  };
  const handleCreateProject = (name: string, type: ProjectType) => {
    play();
    handleDialog({ type: DialogType.LOADING, value: true });
    btnDelay(() => {
      dispatch(
        createProject({
          uid: user.uid,
          name,
          type,
          tags: "オリジナル",
        })
      )
        .then(unwrapResult)
        .then((result) => {
          anime({
            targets: document.getElementById(
              `user-app-project-container-${result.project.project_id}`
            ),
            scale: [1.3, 1],
            opacity: [0, 1],
            duration: 400,
            easing: "easeInOutQuad",
          });
        })
        .finally(() => {
          setCreate(false);
          setTimeout(
            () => handleDialog({ type: DialogType.LOADING, value: false }),
            300
          );
        });
    });
  };
  const handleCreateProjectCancel = () => {
    play();
    btnDelay(() => setCreate(false));
  };

  const handleCloseGuidance = () => {
    play();
    btnDelay(() => setGuidance(false));
  };

  const handleScroll = (direction: number) => () => {
    play();
    if (direction > 0) {
      // 右へ
      if (ref.current) {
        ref.current.scrollLeft += 120;
      }
    } else {
      // 左へ
      if (ref.current) {
        ref.current.scrollLeft -= 120;
      }
    }
  };

  const handleWheel: WheelEventHandler<HTMLDivElement> = (e) => {
    if (ref.current) {
      ref.current.scrollBy(e.deltaX, 0);
    }
  };

  return (
    <>
      <CreateProjectModal
        visible={create}
        name={`${I18n.t("MSG_CREATOR_CREATE_APP_NAME_PREFIX")}${
          projects.length + 1
        }`}
        type={ProjectType.BASIC}
        create={handleCreateProject}
        close={handleCreateProjectCancel}
      />

      <DialogMessage
        open={guidance}
        title={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_MESSAGE")}
        close={handleCloseGuidance}
      />

      <div
        onTransitionEnd={handleOnTransitionEnd}
        style={{
          transform: `translateX(${
            projectPanel === ProjectPanelType.USER ? -1200 + 242 : 0
          }px)`,
        }}
        className="flex-row-el transition-transform duration-700 will-change-transform origin-top-left pointer-events-auto"
      >
        <div className="flex-col-el relative w-[1200px] h-[458px] shrink-0">
          <div className="flex-col-el w-full items-end">
            <div className="flex-row-el w-full">
              <div>
                <div className="flex-col-el absolute top-0 w-[56px] h-[254px] bg-[#E1AD60] rounded-tl-[24px] rounded-bl-[32px]" />

                <div
                  id="user-project-panel-drawer"
                  onClick={handleExpend}
                  className="flex-col-el shrink-0 items-center justify-between relative w-[56px] h-[246px] rounded-l-[24px] py-4 cursor-pointer bg-orange/100 overflow-hidden"
                >
                  <p className="text-body text-white text-[28px] w-[28px] leading-[32px]">
                    {I18n.t("MSG_CREATOR_APP_MY_APPS")}
                  </p>

                  <div>
                    <IconDrawer />
                  </div>
                </div>
              </div>

              <div className="flex-col-el w-full">
                <div className="flex-col-el absolute top-0 -ml-[1px] w-full h-[467px] bg-[#E1AD60] rounded-bl-[20px]" />

                <div className="flex-row-el items-center justify-start relative w-full h-[458px] transition-transform duration-700 px-2 rounded-bl-[16px] bg-orange/10 overflow-hidden border-solid border-0 border-t-[1px] border-[#E5B876]">
                  {sortedProjects.length === 0 ? (
                    <ProjectEmpty />
                  ) : (
                    <div className="flex-row-el gap-4">
                      {Array(5)
                        .fill(0)
                        .map((_, index) =>
                          sortedProjects[index] ? (
                            <ProjectCard
                              key={sortedProjects[index].project_id}
                              user={user}
                              project={sortedProjects[index]}
                              handleSelectProject={handleSelectProject}
                            />
                          ) : (
                            <ProjectCardPlaceholder key={index} />
                          )
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row-el absolute pointer-events-none">
        <div className="flex-col-el relative w-[1200px] h-[458px] shrink-0">
          <div className="flex-col-el w-full items-end">
            <div className="flex-row-el w-full">
              <div
                onClick={handleExpend}
                className="flex-col-el shrink-0 items-center justify-between relative w-[56px] h-[246px] rounded-l-[24px] py-4 cursor-pointer overflow-hidden"
              />

              <div className="w-full overflow-hidden">
                <div
                  style={{
                    transform: `translateX(${
                      projectPanel === ProjectPanelType.USER
                        ? -912 - 40
                        : sortedProjects.length === 0
                        ? -1200 + 288
                        : 0
                    }px)`,
                  }}
                  className="flex-row-el items-center justify-start relative w-full h-[443px] transition-transform duration-700 gap-4 px-2 rounded-bl-[16px] overflow-hidden border-solid border-0 border-t-[1px] border-[#E5B876]"
                >
                  <div className="flex-col-el flex-center w-[904px] h-full" />

                  <div className="flex-col-center upsilon cursor-pointer w-[168px] pointer-events-auto">
                    <div
                      id="user-project-panel-new-app-btn"
                      onPointerDown={handleCreateProjectConfirm}
                      className="flex-col-el flex-center !flex gap-3 btn btn-primary w-[116px] h-[128px] !p-2 !m-0"
                    >
                      <div className="flex-row-center">
                        <div className="flex-col-el flex-center w-[40px] h-[9px] bg-white absolute" />
                        <div className="flex-col-center w-[9px] h-[40px] bg-white" />
                      </div>

                      <div className="flex-col-el flex-center h-[40px] mt-1">
                        <p className="text-body text-white text-center text-[20px] leading-[20px]">
                          {I18n.t("MSG_CREATOR_CREATE_APP")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
