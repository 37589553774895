import { Common } from "features/creator/blockly/toolboxXml/common";
import { Components } from "features/creator/blockly/toolboxXml/components";

export const ToolboxXml = (props: { toolboxId: string }) => {
  return (
    <xml is="Blockly" id={props.toolboxId} style={{ display: "none" }}>
      <Components />
      <Common />
    </xml>
  );
};
