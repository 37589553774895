import { SVGProps } from "react";

export const ProgressStep2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={88} height={88} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.918.114C59.81-.841 75.671 4.193 84.02 15.534c7.83 10.638 2.217 24.057-.822 36.583-3.074 12.67-3.585 28.1-16.27 33.877-12.763 5.812-27.036-2.244-39.582-8.441C15.785 71.843 3.917 65.029.816 53.335c-3.141-11.85 3.261-23.48 11.308-33.191C20.484 10.057 31.132.998 44.918.114Z"
      fill={props.fill}
    />
  </svg>
);
