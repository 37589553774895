/* eslint-disable no-new-func */
import { useState } from "react";
import I18n from "i18n-js";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { Clock, AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const targetEles = [11, 20, 11, 20, 11, 40, 11, 40];
  const [clicked, setClicked] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const [result, setResult] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleEvent = new Function(
    "anime",
    "sleep",
    "clickedIndex",
    "setClickedIndex",
    "highlightBlock",
    "targetEles",
    "result",
    "setResult",
    "setClicked",
    "setRunning",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked && clickedIndex < 2) {
      play();
      setRunning(true);
      setClicked(true);
      handleEvent(
        anime,
        sleep,
        clickedIndex,
        setClickedIndex,
        highlightBlock,
        targetEles,
        result,
        setResult,
        setClicked,
        setRunning,
        setSuccess,
        setGameOver
      )();
    }
  };

  return (
    <div className="flex-row-el flex-center gap-8 w-full mt-8">
      <div className="flex-row-el items-end justify-center gap-2">
        <Clock question={{ hour: 1, minutes: 0 }} />
        <img
          alt="*"
          onClick={handleClick}
          src={`${Constants.assetHost}/stamps/electronic/89ac81d0297168e63110e5778eff4617.png`}
          className="w-[80px] h-[80px] active:opacity-70 cursor-pointer"
        />
      </div>

      <div className="flex-col-el items-start justify-center w-[430px]">
        <div className="flex-row-el items-end justify-center gap-2">
          <div className="flex-col-center">
            <div className="flex-row-center gap-2 p-2 border-solid border-[4px] rounded-[6px] border-white/20">
              <p
                id="course-program-answer-label-1"
                className="text-body text-textcolor/black text-[90px] leading-[90px] opacity-30 absolute"
                style={{
                  filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
                }}
              >
                11
              </p>
              <p
                id="course-program-answer-label-2"
                className="text-body text-textcolor/black text-[90px] leading-[90px] opacity-0"
                style={{
                  filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
                }}
              >
                11
              </p>
              <p
                id="course-program-label-1"
                className="text-body text-white text-[90px] leading-[90px] opacity-0 absolute transition duration-700 scale-150"
              />
              <AnswerMark id={1} />
            </div>
            <p className="text text-white">ボックス1</p>
          </div>

          <div className="flex-col-center">
            <div className="flex-col-center">
              <p className="text-body text-white text-[50px] leading-[50px]">
                {I18n.t("MSG_DATETIME_FORMAT_HOUR")}
              </p>
            </div>
            <div className="h-[30px]" />
          </div>
        </div>

        <div className="flex-row-el items-end justify-center gap-2">
          <div className="flex-col-center">
            <div className="flex-row-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
              <p
                id="course-program-answer-label-3"
                className="text-body text-textcolor/black text-[90px] leading-[90px] opacity-30 absolute"
                style={{
                  filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
                }}
              >
                20
              </p>
              <p
                id="course-program-answer-label-4"
                className="text-body text-textcolor/black text-[90px] leading-[90px] opacity-0"
                style={{
                  filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
                }}
              >
                40
              </p>
              <p
                id="course-program-label-2"
                className="text-body text-white text-[90px] leading-[90px] opacity-0 absolute transition duration-700 scale-150"
              />
              <AnswerMark id={2} />
            </div>
            <p className="text text-white">ボックス2</p>
          </div>

          <div className="flex-col-center">
            <div className="flex-col-center">
              <p className="text-body text-white text-[50px] leading-[50px]">
                {I18n.t("MSG_DATETIME_FORMAT_MINUTE")}
              </p>
            </div>
            <div className="h-[30px]" />
          </div>
        </div>
      </div>
    </div>
  );
};
