import { Vector2D } from "common/types";
import { calcVector } from "common/utils";
import { ComponentProperty } from "common/components";

export const calcOffset = (
  property: ComponentProperty,
  delta: Vector2D
): Vector2D => {
  const preWidth = property.style.layout.width;
  const preHeight = property.style.layout.height;
  const radius = property.style.transform.radius;

  const dragPointUnitVector = {
    x: delta.x === 0 ? 0 : 1,
    y: delta.y === 0 ? 0 : 1,
  };
  const preRefPoint = calcVector(
    (-preWidth * dragPointUnitVector.x) / 2,
    (preHeight * dragPointUnitVector.y) / 2,
    -radius
  );

  const originOffset = calcVector(delta.x / 2, delta.y / 2, 0);
  const newWidth = preWidth + delta.x;
  const newHeight = preHeight + delta.y;
  const newRefPoint = calcVector(
    (-newWidth * dragPointUnitVector.x) / 2,
    (newHeight * dragPointUnitVector.y) / 2,
    -radius
  );

  const offsetX = newRefPoint.x - (preRefPoint.x - originOffset.x);
  const offsetY = newRefPoint.y - (preRefPoint.y + originOffset.y);

  return { x: offsetX, y: offsetY };
};
