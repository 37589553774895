/* eslint-disable no-new-func */
import { useState } from "react";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import {
  CheckMark,
  AnswerMark,
} from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const sound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/itemget.mp3`
  );
  const targetEles = ["Grape", "Grape", "Grape"];
  const [clicked, setClicked] = useState(false);
  const [result, setResult] = useState([false, false, false]);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "sleep",
    "sound",
    "highlightBlock",
    "targetEles",
    "result",
    "setResult",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      play();
      setRunning(true);
      setClicked(true);
      handleEvent(
        sleep,
        sound,
        highlightBlock,
        targetEles,
        result,
        setResult,
        setSuccess,
        setGameOver
      )();
    }
  };
  return (
    <div className="flex-row-center gap-8">
      <div
        id="course-program-image-1"
        onClick={handleClick}
        className="flex-col-center w-[100px] h-[160px] cursor-pointer active:opacity-70"
      >
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
          className="w-[100px] h-[100px] absolute top-2"
        />
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
          className="w-[100px] h-[100px] absolute top-10"
        />
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
          className="w-[100px] h-[100px] absolute top-[72px]"
        />
        <CheckMark id={1} />
      </div>

      <div className="flex-row-center gap-2">
        <div
          id="course-program-border-1"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-2"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={1} />
        </div>
        <p
          id="course-program-arrow-1"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-2"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-3"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={2} />
        </div>
        <p
          id="course-program-arrow-2"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-3"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-4"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={3} />
        </div>
      </div>
    </div>
  );
};
