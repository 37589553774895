import { SVGProps } from "react";

export const MaterialLineSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={38}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35.376 31.07v-24a3.938 3.938 0 0 0-5.488-3.82 3.94 3.94 0 0 0-2.382 3.82v24l3.93 4 3.94-4Z"
      fill="#F8F1E8"
      stroke="#E6CCA1"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.506 11.07h7.87M30.619 35.067l-28-.067"
      stroke="#E6CCA1"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
