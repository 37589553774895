import I18n from "i18n-js";
import { Category, Block, Label } from "common/blockly";

export const Event = () => {
  return (
    <Category name="shape" categorystyle="text_category" toolboxitemid="event">
      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_SCREEN")}
        web-class="toolbox-label"
      />
      <Block type="screen_loading_event" />
      <Block type="screen_click_event" />
      <Block type="screen_navigate_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_BUTTON")}
        web-class="toolbox-label"
      />
      <Block type="button_click_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_CHECKBOX")}
        web-class="toolbox-label"
      />
      <Block type="checkbox_value_change_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_IMAGE")}
        web-class="toolbox-label"
      />
      <Block type="image_click_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_LABEL")}
        web-class="toolbox-label"
      />
      <Block type="label_click_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_SWITCH")}
        web-class="toolbox-label"
      />
      <Block type="switch_value_change_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_FORM")}
        web-class="toolbox-label"
      />
      <Block type="textinput_value_change_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_STAMP")}
        web-class="toolbox-label"
      />
      <Block type="stamp_click_event" />

      <Label
        text={I18n.t("MSG_CREATOR_COMPONENT_NAME_DRAWING")}
        web-class="toolbox-label"
      />
      <Block type="drawing_click_event" />
    </Category>
  );
};
