import I18n from "i18n-js";

export const Button = (props: {
  handleMove: () => void;
  handleBack?: () => void;
}) => {
  const { handleBack, handleMove } = props;
  return (
    <div className="flex-row-el flex-center gap-4 absolute bottom-8 alpha">
      {handleBack && (
        <button className="btn btn-blue" onClick={handleBack}>
          <p className="text-body text-white text-[20px] !mb-[3px]">
            {I18n.t("MSG_COURSE_GAME_TIPS_BACK_BTN")}
          </p>
        </button>
      )}

      <button className="btn btn-primary" onClick={handleMove}>
        <p className="text-body text-white text-[20px] !mb-[3px]">
          {I18n.t("MSG_COURSE_GAME_TIPS_NEXT_BTN")}
        </p>
      </button>
    </div>
  );
};
