import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import Constants from "common/constant";

const firebaseConfig = {
  apiKey: Constants.apiKey,
  authDomain: Constants.authDomain,
  projectId: Constants.projectId,
  appId: Constants.appId,
  measurementId: Constants.measurementId,
};

const firebase = initializeApp(firebaseConfig);

export default firebase;

const parentsFirebaseConfig = {
  apiKey: Constants.parentsApiKey,
  authDomain: Constants.parentsAuthDomain,
  projectId: Constants.parentsProjectId,
  appId: Constants.parentsAppId,
};

export const parentsFirebase = initializeApp(parentsFirebaseConfig, "parents");

export const fetchAuthToken = async () => {
  const auth = getAuth();
  const parentAuth = getAuth(parentsFirebase);
  let token: string;
  if (auth.currentUser) {
    token = await auth.currentUser.getIdToken(false);
  } else {
    token = await parentAuth.currentUser.getIdToken(false);
  }
  return token;
};
