export const IconRunPreviewBg = ({ id }: { id?: string }) => (
  <svg
    width={152}
    height={109}
    fill="none"
    viewBox="0 0 152 109"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M148 75c0 40.317-32.683 73-73 73S2 115.317 2 75 34.683 2 75 2s73 32.683 73 73Z"
      fill="#FFA03B"
    />
    <path
      d="M148 75c0 40.317-32.683 73-73 73S2 115.317 2 75 34.683 2 75 2s73 32.683 73 73Z"
      fill={`url(#project-run-preview-bg-${id}-a)`}
      fillOpacity={0.8}
    />
    <mask
      id={`project-run-preview-bg-${id}-b`}
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={2}
      y={2}
      width={146}
      height={146}
    >
      <path
        d="M148 75c0 40.317-32.683 73-73 73S2 115.317 2 75 34.683 2 75 2s73 32.683 73 73Z"
        fill="#FFA03B"
      />
    </mask>
    <g mask={`url(#project-run-preview-bg-${id}-b)`}>
      <path
        d="M139.483 32.417c0 16.798-28.87 30.416-64.483 30.416S10.517 49.215 10.517 32.417C10.517 15.618 39.387 2 75 2s64.483 13.618 64.483 30.417Z"
        fill={`url(#project-run-preview-bg-${id}-c)`}
        fillOpacity={0.5}
      />
    </g>
    <g filter={`url(#project-run-preview-bg-${id}-d)`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75 140.7c36.285 0 65.7-29.415 65.7-65.7 0-36.285-29.415-65.7-65.7-65.7C38.715 9.3 9.3 38.715 9.3 75c0 36.285 29.415 65.7 65.7 65.7Zm0 7.3c40.317 0 73-32.683 73-73S115.317 2 75 2 2 34.683 2 75s32.683 73 73 73Z"
        fill="#FEFDFE"
      />
    </g>
    <defs>
      <radialGradient
        id={`project-run-preview-bg-${id}-a`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -52.3167 108.243 0 75 41.542)"
      >
        <stop stopColor="#FEFDFE" stopOpacity={0.7} />
        <stop offset={0.276} stopColor="#FEFDFE" stopOpacity={0.3} />
        <stop offset={1} stopColor="#FEFDFE" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id={`project-run-preview-bg-${id}-c`}
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -62.1007 284.227 0 75 62.833)"
      >
        <stop stopColor="#FEFDFE" stopOpacity={0.6} />
        <stop offset={1} stopColor="#FEFDFE" stopOpacity={0} />
      </radialGradient>
      <filter
        id={`project-run-preview-bg-${id}-d`}
        x={0.777}
        y={0.777}
        width={150.892}
        height={150.892}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.223} dy={1.223} />
        <feGaussianBlur stdDeviation={1.223} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.290196 0 0 0 0 0.380392 0 0 0 0.15 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4146_10993"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4146_10993"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-1.223} dy={-1.223} />
        <feGaussianBlur stdDeviation={1.223} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.290196 0 0 0 0 0.380392 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_4146_10993" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1.223} dy={-1.223} />
        <feGaussianBlur stdDeviation={0.611} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.290196 0 0 0 0 0.380392 0 0 0 0.1 0" />
        <feBlend
          in2="effect2_innerShadow_4146_10993"
          result="effect3_innerShadow_4146_10993"
        />
      </filter>
    </defs>
  </svg>
);
