import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import StarIcon from "@mui/icons-material/Star";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Dialog, Rating as RatingComponent, Slide } from "@mui/material";
import { DialogType } from "app/types";
import { getEnv, actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { Spin, CloseBtn } from "common/elements";
import { useScale, usePlaySound } from "common/utils";
import { createRating } from "features/review/api";
import { IconCharacter } from "./assets";
import { Background } from "./Background";
import { ThanksToRating } from "./ThanksToRating";

export const Rating = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const [value, setValue] = useState(0);
  const [thanks, setThanks] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser);
  const rating = useSelector((state: RootState) => state.config.dialog.rating);

  const handleRateChange = (_: any, value: number | null) => {
    if (value) {
      setValue(value);
    }
  };

  const handleClose = () => {
    play();
    dispatch(
      actions.updateDialog({ type: DialogType.RATING, value: false, args: {} })
    );
  };

  const handleConfirm = () => {
    play();
    setLoading(true);
    const rating = {
      rating: value,
      web_version: `${Constants.major}.${Constants.minor}.${Constants.buildNumber}`,
      app_version:
        "ReactNativeWebView" in window
          ? `${window.kidsAppVersion}(${window.kidsBuildVersion})`
          : "",
      env: getEnv(),
    };

    createRating({
      uid: user.active.uid,
      rating,
    })
      .then(() => {
        setLoading(false);
        if (value <= 2.5) {
          dispatch(
            actions.updateDialog({
              type: DialogType.RATING,
              value: false,
            })
          );
          dispatch(
            actions.updateDialog({
              type: DialogType.FEEDBACK,
              value: true,
              args: { rating: String(value) },
            })
          );
        } else {
          setThanks(true);
        }
      })
      .catch((error) => {
        console.error(rating);
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={rating.value}
      sx={{ zIndex: 100000 }}
      PaperProps={{ style: { backgroundColor: "rgba(63, 74, 97, 0.5)" } }}
      onClose={handleClose}
      transitionDuration={{ exit: 500 }}
      TransitionProps={{
        onExited: () => {
          setValue(0);
          setThanks(false);
        },
      }}
    >
      <div
        className="flex-col-center w-full h-full !absolute top-0 left-0 z-50"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin />
      </div>

      <Slide in={!thanks} direction="down" timeout={{ enter: 1000 }}>
        <div className="flex-col-view items-center w-full h-full">
          <div
            className="flex-col-view"
            style={{
              width: 550 * scale,
              height: 418 * scale,
              marginTop: 200 * scale,
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              transform: `translateZ(0)`,
            }}
          >
            <div
              className="flex-col-center w-[550px] h-[418px] border-solid border-[12px] rounded-[8px] border-white bg-beige/60 origin-top-left"
              style={{ transform: `scale(${scale})` }}
            >
              <CloseBtn close={handleClose} />

              <div className="flex-col-center ml-[100px]">
                <p className="text text-textcolor/black !leading-[36px] !text-[24px]">
                  {I18n.t("MSG_REVIEW_RATING_TITLE")}
                </p>
              </div>

              <div className="flex-col-center mt-[14px]">
                <div className="flex-col-center !absolute left-[40px] bottom-[94px]">
                  <IconCharacter />
                </div>

                <Background />

                <div
                  className="flex-col-center !absolute w-[400px] h-[80px]"
                  style={{
                    backfaceVisibility: "hidden",
                    WebkitBackfaceVisibility: "hidden",
                    transform: `translateZ(0)`, // for safari
                  }}
                >
                  <RatingComponent
                    value={value}
                    size="large"
                    sx={{
                      fontSize: "80px",
                    }}
                    emptyIcon={
                      <StarIcon fontSize="inherit" sx={{ color: "white" }} />
                    }
                    onChange={handleRateChange}
                  />
                </div>
              </div>

              <div className="flex-row-el flex-center relative mt-8 alpha">
                <button
                  className="btn btn-primary"
                  onClick={handleConfirm}
                  disabled={value === 0}
                >
                  <div className="flex-row-el flex-center gap-1 mb-[3px]">
                    <MailOutlineIcon
                      sx={{ color: "white", fontSize: "40px" }}
                    />
                    <p className="text-body text-white text-[24px]">
                      {I18n.t("MSG_REVIEW_SEND_RATING_CONFIRM")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Slide>

      <ThanksToRating visible={thanks} close={handleClose} />
    </Dialog>
  );
};
