import { useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import i18n from "i18n-js";
import produce from "immer";
import { btnDelay, shuffle, usePlaySound } from "common/utils";

enum ShapeType {
  RECT = "MSG_PARENT_GATE_SHAPE_RECT",
  STAR = "MSG_PARENT_GATE_SHAPE_STAR",
  CIRCLE = "MSG_PARENT_GATE_SHAPE_CIRCLE",
  TRIANGLE = "MSG_PARENT_GATE_SHAPE_TRIANGLE",
}

type ShapeProps = {
  borderWidth: number;
  isTapped: boolean;
  tappedOrder: number;
};

const Shapes = [
  (props: ShapeProps) => <Rect {...props} />,
  (props: ShapeProps) => <Circle {...props} />,
  (props: ShapeProps) => <Star {...props} />,
  (props: ShapeProps) => <Triangle {...props} />,
];

export const Shape = (props: { setSuccess: (success: boolean) => void }) => {
  const SHAPE_BTN_BORDER = 8;

  const play = usePlaySound();
  const [over, setOver] = useState(false);
  const [shapes, setShapes] = useState([]);
  const initialTapped = [
    { shape: ShapeType.RECT, tapped: false, tappedOrder: 0 },
    { shape: ShapeType.CIRCLE, tapped: false, tappedOrder: 0 },
    { shape: ShapeType.STAR, tapped: false, tappedOrder: 0 },
    { shape: ShapeType.TRIANGLE, tapped: false, tappedOrder: 0 },
  ];
  const [clicked, setClicked] = useState(false);
  const [tapped, setTapped] = useState(initialTapped);

  const [animationStyles, animationApi] = useSpring(() => ({
    from: { x: 0 },
  }));

  const handleTapped = (index: number) => () => {
    if (clicked) return;
    setClicked(true);
    play();
    btnDelay(() => {
      const currentTappedNumber = tapped.filter((t) => t.tappedOrder !== 0)
        .length;
      setTapped(
        produce((draft) => {
          draft[index].tapped = true;
          draft[index].tappedOrder = currentTappedNumber + 1;
        })
      );
      if (currentTappedNumber + 1 === 4) {
        setOver(true);
      }
      setClicked(false);
    });
  };

  useEffect(() => {
    const shapes = shuffle([
      ShapeType.RECT,
      ShapeType.STAR,
      ShapeType.CIRCLE,
      ShapeType.TRIANGLE,
    ]);
    setShapes(shapes);
  }, []);

  useEffect(() => {
    if (over) {
      const result = tapped.map((t) => shapes[t.tappedOrder - 1] === t.shape);
      if (result.includes(false)) {
        animationApi.start({
          to: async (next) => {
            await next({ x: 10 });
            await next({ x: -10 });
            await next({ x: 10 });
            await next({ x: -10 });
            const shapes = shuffle([
              ShapeType.RECT,
              ShapeType.STAR,
              ShapeType.CIRCLE,
              ShapeType.TRIANGLE,
            ]);
            setOver(false);
            setShapes(shapes);
            setTapped(initialTapped);
          },
          config: { duration: 100 },
        });
      } else {
        props.setSuccess(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [over]);

  return (
    <animated.div
      style={{
        width: "100%",
        height: "100%",
        ...animationStyles,
      }}
    >
      <div className="w-full h-full flex-col-view bg-white">
        <div className="flex-col-view w-full h-[64px] bg-[#C9947A]">
          <p className="text text-white !leading-[30px]">
            {i18n.t("MSG_PARENT_GATE_QUESTION_TITLE")}
          </p>
          <div className="flex-row-center">
            {shapes.map((s, i) => (
              <p key={i} className="text text-white !leading-[30px] !mr-[20px]">
                {i18n.t(s)}
              </p>
            ))}
          </div>
        </div>
        <div className="flex-row-el flex-center flex-1 pointer-events-auto">
          {Shapes.map((Shape, index) => (
            <button
              key={index}
              disabled={over || tapped[index].tapped}
              className="border-none bg-transparent relative mx-5 valid:active:scale-[1.2]"
              onClick={handleTapped(index)}
            >
              <Shape
                borderWidth={SHAPE_BTN_BORDER}
                isTapped={tapped[index].tapped}
                tappedOrder={tapped[index].tappedOrder}
              />
            </button>
          ))}
        </div>
      </div>
    </animated.div>
  );
};

export const Rect = (props: ShapeProps) => {
  const { borderWidth, isTapped, tappedOrder } = props;
  return (
    <div className="flex-col-view w-[60px] h-[60px]">
      <svg viewBox="0 0 60 60">
        <rect
          x={borderWidth / 2}
          y={borderWidth / 2}
          rx={5}
          ry={5}
          width={60 - borderWidth}
          height={60 - borderWidth}
          stroke={isTapped ? "#FFA03B" : "#E0E4E7"}
          strokeWidth={borderWidth}
          fill="none"
        />
        {isTapped && (
          <>
            <circle cx={60 / 2} cy={60 / 2} r={15} fill="#F8F1E8" />
            <text
              x="50%"
              y="50%"
              dominantBaseline="central"
              textAnchor="middle"
              fontFamily="MPLUSRounded1c-Bold"
              fontSize={20}
              fill="#3F4A61"
            >
              {tappedOrder}
            </text>
          </>
        )}
      </svg>
    </div>
  );
};

export const Circle = (props: ShapeProps) => {
  const { borderWidth, isTapped, tappedOrder } = props;
  return (
    <div className="flex-col-view w-[60px] h-[60px]">
      <svg viewBox="0 0 60 60">
        <circle
          cx={60 / 2}
          cy={60 / 2}
          r={(60 - borderWidth) / 2}
          stroke={isTapped ? "#FFA03B" : "#E0E4E7"}
          strokeWidth={borderWidth}
          fill="none"
        ></circle>
        {isTapped && (
          <>
            <circle cx={60 / 2} cy={60 / 2} r={15} fill="#F8F1E8" />
            <text
              x="50%"
              y="50%"
              dominantBaseline="central"
              textAnchor="middle"
              fontFamily="MPLUSRounded1c-Bold"
              fontSize={20}
              fill="#3F4A61"
            >
              {tappedOrder}
            </text>
          </>
        )}
      </svg>
    </div>
  );
};

export const Star = (props: ShapeProps) => {
  const { borderWidth, isTapped, tappedOrder } = props;
  return (
    <div className="flex-col-view w-[56px] h-[55px]">
      <svg viewBox="0 0 56 55">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M27.1751 5.27922C27.5723 4.69964 28.4277 4.69965 28.8249 5.27921L35.3335 14.7761C36.5038 16.4837 38.2269 17.7356 40.2126 18.321L51.256 21.5764C51.9299 21.775 52.1942 22.5885 51.7658 23.1454L44.745 32.2702C43.4826 33.9108 42.8244 35.9365 42.8813 38.0058L43.1978 49.5147C43.2172 50.217 42.5252 50.7198 41.8632 50.4844L31.0154 46.6269C29.065 45.9333 26.935 45.9333 24.9846 46.6269L14.1368 50.4844C13.4748 50.7198 12.7828 50.217 12.8022 49.5147L13.1187 38.0058C13.1756 35.9365 12.5174 33.9108 11.255 32.2702L4.23424 23.1454C3.80577 22.5885 4.07009 21.775 4.74404 21.5764L15.7874 18.321C17.773 17.7356 19.4962 16.4837 20.6665 14.7761L27.1751 5.27922Z"
          stroke={isTapped ? "#FFA03B" : "#E0E4E7"}
          strokeWidth={borderWidth}
          fill="none"
        />
        {isTapped && (
          <>
            <circle cx={56 / 2} cy={30} r={15} fill="#F8F1E8" />
            <text
              x="50%"
              y={30}
              dominantBaseline="central"
              textAnchor="middle"
              fontFamily="MPLUSRounded1c-Bold"
              fontSize={20}
              fill="#3F4A61"
            >
              {tappedOrder}
            </text>
          </>
        )}
      </svg>
    </div>
  );
};

export const Triangle = (props: ShapeProps) => {
  const { borderWidth, isTapped, tappedOrder } = props;
  return (
    <div className="flex-col-view w-[62px] h-[55px]">
      <svg viewBox="0 0 62 55">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M30.134 4.5C30.5189 3.83333 31.4811 3.83334 31.866 4.5L57.8468 49.5C58.2317 50.1667 57.7506 51 56.9808 51H5.01924C4.24943 51 3.76832 50.1667 4.15321 49.5L30.134 4.5Z"
          stroke={isTapped ? "#FFA03B" : "#E0E4E7"}
          strokeWidth={borderWidth}
          fill="none"
        />
        {isTapped && (
          <>
            <circle cx={62 / 2} cy={33} r={15} fill="#F8F1E8" />
            <text
              x="50%"
              y={33}
              dominantBaseline="central"
              textAnchor="middle"
              fontFamily="MPLUSRounded1c-Bold"
              fontSize={20}
              fill="#3F4A61"
            >
              {tappedOrder}
            </text>
          </>
        )}
      </svg>
    </div>
  );
};
