import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import anime from "animejs";
import { RootState } from "app/store";
import { IconTrash } from "common/assets";
import { usePlaySound } from "common/utils";
import { ComponentTypeIds } from "common/components";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType } from "features/creator/types";
import { selectPropertyById } from "features/creator/slice";

export const DeleteBtn = () => {
  const play = usePlaySound();
  const handleAction = useActionCommand();
  const [canDelete, setCanDelete] = useState(true);
  const defaultScreenId = useSelector(
    (state: RootState) => state.creator.defaultScreenId
  );
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedComponentProperty = useSelector((state: RootState) =>
    selectPropertyById(state, selectedComponentId)
  );
  const grouping = useSelector((state: RootState) => state.creator.grouping);
  const disabled = selectedComponentId === defaultScreenId && !grouping.enable;

  const handleDeleteComponent = () => {
    setCanDelete(false);
    if (grouping.enable) {
      handleAction({
        type: ActionCommandType.REMOVE_GROUPING,
        screenId: selectedScreenId,
        componentIds: grouping.componentIds,
      });
      setCanDelete(true);
    } else {
      if (selectedComponentProperty.typeId === ComponentTypeIds.SCREEN) {
        if (selectedComponentId !== defaultScreenId) {
          anime({
            targets: document.getElementById(`wrap-${selectedComponentId}`),
            scale: 0.9,
            opacity: 0,
            duration: 400,
            easing: "easeInOutQuad",
          }).finished.then(() => {
            handleAction({
              type: ActionCommandType.REMOVE_SCREEN,
              screenId: selectedComponentId,
            });
            setCanDelete(true);
          });
        }
      } else {
        anime({
          targets: [
            document.getElementById(
              `component-move-panel-${selectedComponentId}`
            ),
            document.getElementById(
              `component-resize-panel-${selectedComponentId}`
            ),
          ],
          scale: 0.9,
          opacity: 0,
          duration: 400,
          easing: "easeInOutQuad",
        }).finished.then(() => {
          handleAction({
            type: ActionCommandType.REMOVE_COMPONENT,
            screenId: selectedComponentProperty.screenId,
            componentId: selectedComponentId,
          });
          setCanDelete(true);
        });
      }
    }
  };

  const handleDelete = () => {
    play();
    if (disabled || !canDelete) return;
    handleDeleteComponent();
    toast.success(
      <div>
        <p className="text text-textcolor/black">
          {I18n.t("MSG_TOAST_DELETED_SUCCESS")}
        </p>
      </div>
    );
  };

  return (
    <div className="flex-col-el flex-center h-full relative upsilon-square">
      <button
        id="creator-delete-btn"
        className="btn btn-danger"
        onPointerDown={handleDelete}
      >
        <div className="flex-col-el flex-center relative w-[32px] h-[32px] mb-[3px]">
          <IconTrash width={2} />
        </div>
      </button>
    </div>
  );
};
