import { SVGProps } from "react";

export const IconNew = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <rect
      width={37}
      height={21}
      x={1.5}
      y={9.5}
      fill="#FEFDFE"
      stroke={props.color}
      strokeWidth={3}
      rx={6.5}
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M8 8a8 8 0 0 0-8 8v8a8 8 0 0 0 8 8h24a8 8 0 0 0 8-8v-8a8 8 0 0 0-8-8H8ZM5.812 25a.816.816 0 0 1-.58-.233A.751.751 0 0 1 5 24.22v-8.438c0-.21.077-.393.232-.548a.816.816 0 0 1 .58-.233h.319c.55 0 .956.228 1.217.685l3.57 6.09c.007.013.01.033.025.033.01 0 .015-.004.015-.014v-6.013a.73.73 0 0 1 .246-.548.817.817 0 0 1 .58-.233h.319c.222 0 .41.078.565.233a.73.73 0 0 1 .247.548v8.438a.73.73 0 0 1-.247.548.767.767 0 0 1-.565.233h-.319c-.55 0-.957-.228-1.218-.685l-3.568-6.09c-.008-.013-.012-.033-.027-.033-.01 0-.014.004-.014.014v6.013a.73.73 0 0 1-.246.548.816.816 0 0 1-.58.233h-.32Zm10.22 0a.816.816 0 0 1-.579-.233.73.73 0 0 1-.246-.548v-8.438a.73.73 0 0 1 .246-.548.816.816 0 0 1 .58-.233h4.928c.223 0 .416.078.58.233a.73.73 0 0 1 0 1.096.844.844 0 0 1-.58.219h-3.595c-.087 0-.13.041-.13.123v2.206c0 .082.043.123.13.123h3.349c.222 0 .406.073.55.22.155.145.233.319.233.52 0 .2-.078.374-.232.52a.77.77 0 0 1-.551.206h-3.349c-.087 0-.13.04-.13.123v2.74c0 .082.043.123.13.123h3.595c.223 0 .416.078.58.233a.689.689 0 0 1 .247.534.73.73 0 0 1-.247.548.817.817 0 0 1-.58.233h-4.928Zm7.387-9.233 1.856 8.466c.048.22.164.402.348.548.183.146.396.219.638.219h.768c.241 0 .45-.073.623-.22a.878.878 0 0 0 .334-.547l1.199-7.183c.003-.015.003-.036.018-.036s.016.02.018.036l1.2 7.183a.9.9 0 0 0 .319.548c.183.146.396.219.638.219h.768a.995.995 0 0 0 .638-.22.943.943 0 0 0 .348-.547l1.855-8.466a.601.601 0 0 0-.145-.534.66.66 0 0 0-.522-.233h-.362a.938.938 0 0 0-.942.767l-1.2 7.183c-.002.015-.003.036-.018.036s-.016-.021-.018-.036l-1.17-7.183a.938.938 0 0 0-.942-.767h-.87a.938.938 0 0 0-.943.767l-1.154 7.17c-.004.024-.01.05-.035.05-.015 0-.015-.022-.018-.037l-1.2-7.183a.938.938 0 0 0-.942-.767h-.42a.704.704 0 0 0-.536.233.568.568 0 0 0-.13.534Z"
      clipRule="evenodd"
    />
    <rect
      width={37.5}
      height={21.5}
      x={1.25}
      y={9.25}
      stroke={props.color}
      strokeWidth={2.5}
      rx={6.75}
    />
  </svg>
);
