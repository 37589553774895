import { SVGProps } from "react";

export const CardBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 168 186"
    fill="none"
    {...props}
  >
    <g filter="url(#card-bg-a)">
      <path
        fill="#3F4A61"
        fillOpacity={0.1}
        fillRule="evenodd"
        d="M24.47 0A8.47 8.47 0 0 0 16 8.47v59.225C6.54 72.188 0 81.83 0 93s6.54 20.812 16 25.305v46.449c0 2.26.904 4.427 2.51 6.017l11.658 11.55A12.705 12.705 0 0 0 39.11 186H159.53a8.47 8.47 0 0 0 8.471-8.471V8.471A8.47 8.47 0 0 0 159.529 0H24.471Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="card-bg-a"
        width={172}
        height={190}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={4} dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow_4301_61484" />
      </filter>
    </defs>
  </svg>
);
