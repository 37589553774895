import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { btnDelay, usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { IconViewed } from "features/user/assets";
import { IconBack } from "features/creator/assets";
import { DisplayMode } from "features/creator/types";
import { getUserRankingProjects } from "features/creator/api";
import { actions as creatorActions } from "features/creator/slice";
import { Path } from "./type";
import { DetailTitle, RankingPlate } from "./elements";
import { FavoritedMarkReadOnly } from "./FavoritedMark";
import { ProjectDetailPanel } from "./ProjectDetailPanel";

export const ProjectViewedWithIcon = ({ viewed }: { viewed: number }) => (
  <div className="flex-row-el items-center">
    <div className="flex-col-center w-[40px] h-[40px]">
      <IconViewed />
    </div>
    <div className="flex-col-center">
      <p
        className="text-body text-body-ellipsis text-brown/100 text-[20px] z-50 px-1"
        style={{
          WebkitTextStroke: "4px #FEFDFE",
        }}
      >
        <span
          style={{
            WebkitTextStroke: "0",
            position: "absolute",
          }}
        >
          {Number(viewed)}
        </span>
        {Number(viewed)}
      </p>
    </div>
  </div>
);

export const UserDetailPanel = ({
  path,
  show,
  user,
  ranking,
  close,
}: {
  path: Path;
  show: boolean;
  user: any;
  ranking: number;
  close: () => void;
}) => {
  const play = usePlaySound();
  const [init, setInit] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [detail, setDetail] = useState(false);
  const [userProjects, setUserProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const panelAction = useSelector((state: RootState) => state.creator.action);

  useEffect(() => {
    if (displayMode === DisplayMode.RANKING && panelAction.back && user) {
      close();
      dispatch(
        creatorActions.updateProjectPanelAction({
          ...panelAction,
          back: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelAction.back]);

  useEffect(() => {
    if (ref.current && path === Path.USER) {
      setTimeout(() => setInit(true), 300);
    } else {
      setInit(false);
      setUserProjects([]);
      if (ref.current) {
        ref.current.style.opacity = "0%";
        ref.current.style.zIndex = "-1";
        ref.current.classList.remove("animate__fadeOutUp");
        ref.current.classList.remove("animate__fadeInDown");
      }
    }
  }, [path]);

  useEffect(() => {
    if (ref.current) {
      if (show) {
        getUserRankingProjects({ uid: user.uid }).then((response) =>
          setUserProjects(response.data.data.projects)
        );
        ref.current.style.opacity = "100%";
        ref.current.style.zIndex = "30010";
        ref.current.classList.remove("animate__fadeOutUp");
        ref.current.classList.add("animate__fadeInDown");
      } else {
        if (init) {
          ref.current.classList.remove("animate__fadeInDown");
          ref.current.classList.add("animate__fadeOutUp");
          setTimeout(() => setUserProjects([]), 1000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleSelectProject = (project: any) => () => {
    btnDelay(() => {
      play();
      setSelectedProject(project);
      dispatch(actions.updateRecommendProject(project));
      setDetail(true);
    });
  };

  const handleCloseDetail = () => {
    btnDelay(async () => {
      play();
      setDetail(false);
      setTimeout(() => {
        setSelectedProject(null);
        dispatch(actions.updateRecommendProject(null));
      }, 1000);
    });
  };

  return (
    <>
      <ProjectDetailPanel
        id="ranking-user"
        path={Path.APP}
        show={detail}
        project={selectedProject}
        ranking={selectedProject?.ranking}
        close={handleCloseDetail}
      />

      <div
        ref={ref}
        className="flex-col-el items-center w-full h-full absolute -z-1 opacity-0 animate__animated gap-5"
      >
        <img
          alt="creator display default bg"
          src={`${Constants.assetHost}/assets/images/creator_display_bg.webp`}
          className="w-full h-full object-fill absolute -z-10"
        />

        <div
          onClick={close}
          className="flex-col-el flex-center absolute top-2 left-2 active:opacity-70 cursor-pointer z-50"
        >
          <div className="flex-col-center">
            <IconBack stroke="#A54D21" />
          </div>
          <p className="text-body text-[20px] text-[#A54D21]">もどる</p>
        </div>

        <div className="flex-row-center mt-4">
          <img
            className="w-[400px] h-[54px]"
            alt="creator ranking top title"
            src={`${Constants.assetHost}/assets/images/creator_ranking_viewed_title.png`}
          />
        </div>

        <div className="flex-row-center w-full">
          <div className="flex-row-el w-full h-[6px] bg-[#EBA646] absolute mt-5" />

          <div className="flex-row-el flex-center relative">
            <div
              style={{ boxShadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.25)" }}
              className="flex-row-el items-center bg-beige/20 rounded-l-xl w-[432px] h-[80px] pl-4"
            >
              <div className="flex-col-center mr-2">
                <DetailTitle ranking={ranking} />
              </div>

              <div className="flex-col-el w-[256px] mr-1">
                <p className="text-body text-textcolor/black text-left text-[26px] leading-6 whitespace-nowrap overflow-hidden text-ellipsis">
                  {user?.display_name}
                </p>
                <div className="flex-col-el">
                  {user && (
                    <ProjectViewedWithIcon viewed={Number(user.viewed)} />
                  )}
                </div>
              </div>
            </div>

            <div
              className="flex-col-el flex-center absolute -right-6 w-[96px] h-[96px] cursor-pointer bg-white rounded-full"
              style={{
                filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
              }}
            >
              <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                <img
                  alt="user icon"
                  src={user?.icon}
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-row-center">
          <div
            onClick={handleSelectProject(userProjects[0])}
            className="flex-col-center cursor-pointer"
          >
            <div
              className="flex-row-el flex-center relative"
              style={{
                filter:
                  "drop-shadow(-1px 1px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 3px rgba(63, 74, 97, 0.25))",
              }}
            >
              <div className="flex-col-center -mr-6 z-50">
                <DetailTitle ranking={userProjects[0]?.ranking} />
              </div>

              <div className="flex-col-el justify-center relative w-[176px] h-[52px] bg-white rounded-r-[26px] pl-7 py-1">
                <div className="flex-row-center w-[144px]">
                  <p className="text-body text-body-ellipsis text-textcolor/black text-left text-[20px] w-full">
                    {userProjects[0]?.name}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex-col-el flex-center relative mt-7">
              <div className="flex-col-el flex-center absolute -top-6 w-[104px] h-[128px] z-50">
                <img
                  alt="app card"
                  src={`${Constants.assetHost}/assets/images/app_card_shared.png`}
                  className="w-full h-full"
                />

                <div className="flex-col-el flex-center absolute top-[22px] w-full px-[6px] h-[92px]">
                  {userProjects[0]?.cover || userProjects[0]?.capture ? (
                    <div className="flex-col-center w-full h-full overflow-hidden">
                      <img
                        alt="アプリのキャプチャー"
                        className="w-full h-full object-cover"
                        src={userProjects[0]?.cover ?? userProjects[0]?.capture}
                        onError={(e) =>
                          // @ts-ignore
                          (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                        }
                      />
                    </div>
                  ) : (
                    <div className="w-full h-full bg-white" />
                  )}
                </div>

                <div className="flex-col-el absolute right-3 bottom-3">
                  {userProjects.length > 0 && (
                    <FavoritedMarkReadOnly
                      favorited={userProjects[0].favorited}
                    />
                  )}
                </div>
              </div>

              <img
                alt="トロフィー"
                className="w-[140px] h-[156px]"
                src={`${Constants.assetHost}/assets/images/trophy.png`}
              />
            </div>
          </div>

          <div className="flex-col-el h-[320px] justify-start px-11">
            <div className="flex-col-el gap-2 invisible-scrollbar">
              {userProjects.slice(1).map((project) => (
                <div
                  key={project.project_id}
                  onClick={handleSelectProject(project)}
                  style={{
                    boxShadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.25)",
                  }}
                  className="clickable flex-row-el items-center bg-beige/20 rounded-xl w-[320px] h-[74px] px-2 pb-1"
                >
                  <div className="flex-col-center mx-2">
                    <RankingPlate ranking={project.ranking} />
                  </div>

                  <div className="flex-col-el">
                    <div className="flex-col-el w-[200px]">
                      <p className="text-body text-textcolor/black text-left text-[20px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {project.name}
                      </p>
                    </div>
                  </div>

                  <div className="flex-col-center w-[52px] h-[64px]">
                    <img
                      alt="app card"
                      src={`${Constants.assetHost}/assets/images/app_card_user.png`}
                      className="w-full h-full"
                    />

                    <div className="flex-col-el flex-center absolute top-[14px] w-full px-[3px] h-[42px]">
                      {project.capture ? (
                        <div className="flex-col-center w-full h-full overflow-hidden">
                          <img
                            alt="アプリのキャプチャー"
                            className="w-full h-full object-cover"
                            src={project?.capture}
                            onError={(e) =>
                              // @ts-ignore
                              (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                            }
                          />
                        </div>
                      ) : (
                        <div className="w-full h-full bg-white" />
                      )}
                    </div>

                    <div className="flex-row-el items-center absolute bottom-1">
                      <FavoritedMarkReadOnly favorited={project.favorited} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
