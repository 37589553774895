import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippetByClickIndex } from "features/courses/program/game/blockly/uitls";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/internet/1b69d8850494bfd07a6c05a64a721633.png`,
            width: 30,
            height: 30,
            alt: "Vehicle",
          },
          "button_1",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          setClickedIndex(clickedIndex + 1);
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          let target;
          if(${result}){
            ${logic}
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitMathPlusBlock = () => {
  Blockly.Blocks["program_math_plus_block"] = {
    init: function () {
      this.jsonInit({
        type: "program_math_plus_block",
        message0: "%1+%2",
        args0: [
          {
            type: "input_value",
            name: "A",
            check: "Number",
          },
          {
            type: "input_value",
            name: "B",
            check: "Number",
          },
        ],
        inputsInline: true,
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["program_math_plus_block"] = function (block: Blockly.Block) {
    const A = BlocklyJs.valueToCode(block, "A", BlocklyJsOperator.ORDER_NONE);
    const B = BlocklyJs.valueToCode(block, "B", BlocklyJsOperator.ORDER_NONE);
    return [`(Number(${A}) + Number(${B}))`, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitImageGetterXBlock = () => {
  Blockly.Blocks["image_getter_x_block"] = {
    init: function () {
      this.jsonInit({
        type: "image_getter_x_block",
        message0: "%{BKY_GETTER_X_COORDINATE}",
        args0: [
          {
            type: "field_dropdown",
            name: "component_id",
            options: [
              [
                {
                  src: `${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`,
                  width: 30,
                  height: 30,
                  alt: "Vehicle",
                },
                "Vehicle",
              ],
            ],
          },
        ],
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["image_getter_x_block"] = function (block: Blockly.Block) {
    const code = `document.getElementById("course-program-image-1").dataset.value`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitImageSetterXBlock = () => {
  Blockly.Blocks["image_setter_x_block"] = {
    init: function () {
      this.jsonInit({
        type: "image_setter_x_block",
        message0: "%{BKY_COURSE_PROGRAM_SETTER_X_COORDINATE}",
        args0: [
          {
            type: "field_dropdown",
            name: "component_id",
            options: [
              [
                {
                  src: `${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`,
                  width: 30,
                  height: 30,
                  alt: "Vehicle",
                },
                "Vehicle",
              ],
            ],
          },
          {
            type: "input_value",
            name: "value",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["image_setter_x_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );

    return `
        highlightBlock("${block.id}", true);
        const offset = ${value === "" ? 0 : value};
        target = document.getElementById("course-program-image-1");
        if(target instanceof HTMLDivElement){
          target.dataset.value = offset;
          await anime({
            targets: target,
            scale: 1.5,
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
          await anime({
            targets: target,
            left: offset * 4.5 - 32,
            duration: 1000,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
          await anime({
            targets: target,
            scale: 1,
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        };
        result[clickedIndex] = offset === targetEles[clickedIndex];
        ${showAnswerSnippetByClickIndex()}
        await sleep(700);
        highlightBlock("${block.id}", false);
    `;
  };
};
