import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { DisableTopBlocks } from "@blockly/disable-top-blocks";
import { RootState } from "app/store";
import { InitMsg } from "common/blockly";
import { ToolboxInfoType } from "features/courses/algorithm/game/type";
import {
  InitColourBlock,
  InitControlsBlock,
  InitVariableBlock,
  InitCharacterBlock,
  InitProceduresCallBlock,
} from "./blocks";
import { ToolboxDefinition } from "./type";
import { BlocklyOptions, PreviewBlocklyOptions } from "./BlocklyOptions";

export const useInitWorkspace = (
  show: boolean,
  id: string,
  toolboxDef: ToolboxDefinition
) => {
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    InitMsg(config.locale);
  }, [config.locale]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        InitCharacterBlock();
        InitControlsBlock();
        InitColourBlock();
        InitVariableBlock();
        InitProceduresCallBlock();

        Blockly.WorkspaceSvg.prototype.refreshToolboxSelection = function () {
          // disable refresh toolbox
        };

        const workspace: Blockly.WorkspaceSvg = Blockly.inject(
          id,
          BlocklyOptions(toolboxDef)
        );
        BlocklyJs.init(workspace);

        const toolbox = workspace.getToolbox() as Blockly.Toolbox;
        const div = toolbox.HtmlDiv as HTMLDivElement;
        div.style.width = "0px";
        div.style.top = "0px";
        div.style.display = "none";

        workspace.updateToolbox(toolboxDef);

        const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
        const blockToolbox = workspace.getToolbox() as Blockly.Toolbox;
        const item = blockToolbox.getToolboxItemById(
          "category"
        ) as Blockly.ToolboxCategory;
        blockToolbox.setSelectedItem(item);
        blocklyFlyout.autoClose = false;
      }, 500);
    }
  }, [show]);
};

const fixProcedures = (workspace, offset: number, scale: number) => {
  const blocklyFlyout1 = workspace.getFlyout() as Blockly.Flyout;
  if (blocklyFlyout1) {
    const blocklyFlyoutWs1 = blocklyFlyout1.getWorkspace();
    const result = blocklyFlyoutWs1
      .getBlocksByType("procedures_defnoreturn", false)
      .map((block) => {
        block.setMutator(null);
        block.setEditable(false);
        return block;
      });
    if (result.length > 0) {
      // @ts-ignore
      blocklyFlyout1.positionAt_(
        blocklyFlyout1.getWidth(),
        blocklyFlyout1.getHeight() - offset - 75 * scale,
        0,
        offset
      );
    }
  }
};

const updateFlyout = (workspace, offset: number, scale: number) => {
  const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
  const blockToolbox = workspace.getToolbox() as Blockly.Toolbox;
  const item = blockToolbox.getToolboxItemById(
    "category"
  ) as Blockly.ToolboxCategory;
  blockToolbox.setSelectedItem(item);
  if (!blocklyFlyout.isVisible()) {
    blockToolbox.setSelectedItem(item);
  }
  // @ts-ignore
  blocklyFlyout.positionAt_(
    blocklyFlyout.getWidth(),
    blocklyFlyout.getHeight() - offset - 75 * scale,
    0,
    offset
  );
};

const useInitPreviewWorkspace = (
  answer: string,
  scale: number,
  offset: number,
  locale: string,
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void,
  toolboxRef: React.MutableRefObject<ToolboxInfoType>
) => {
  useEffect(() => {
    InitMsg(locale);
  }, [locale]);

  useEffect(() => {
    InitCharacterBlock();
    InitControlsBlock();
    InitColourBlock();
    InitVariableBlock();
    InitProceduresCallBlock();

    // @ts-ignore
    Blockly.FieldTextInput.prototype.showEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this)
      );
    };

    // @ts-ignore
    Blockly.FieldTextInput.prototype.showPromptEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this)
      );
    };

    // @ts-ignore
    Blockly.FieldNumber.prototype.showEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this)
      );
    };

    Blockly.WorkspaceSvg.prototype.refreshToolboxSelection = function () {
      // disable refresh toolbox
    };

    const workspace: Blockly.WorkspaceSvg = Blockly.inject(
      "blocklyDiv",
      PreviewBlocklyOptions(
        scale,
        toolboxRef.current.maxInstances,
        toolboxRef.current.json
      )
    );
    BlocklyJs.init(workspace);

    const toolbox = workspace.getToolbox() as Blockly.Toolbox;
    const div = toolbox.HtmlDiv as HTMLDivElement;
    div.style.width = "0px";
    div.style.top = `${offset * scale}px`;
    div.style.display = "none";

    workspace.updateToolbox(toolboxRef.current.json);
    updateFlyout(workspace, offset * scale, scale);
    fixProcedures(workspace, offset * scale, scale);

    const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
    blocklyFlyout.autoClose = false;

    const blocklyFlyoutWs = blocklyFlyout.getWorkspace();
    blocklyFlyoutWs
      .getBlocksByType("procedures_defnoreturn", false)
      .forEach((block) => block.setMutator(null));

    const blocklyScrollbarVerticals = document.getElementsByClassName(
      "blocklyScrollbarVertical"
    );
    for (let i: number = 0; i < blocklyScrollbarVerticals.length; i++) {
      let e = blocklyScrollbarVerticals[i] as SVGElement;
      const display = e.getAttribute("display");
      if (display !== "block") {
        e.setAttribute("style", "pointer-events: none;");
      }
    }

    if (!answer) {
      const eventBlock = new Blockly.BlockSvg(workspace, "character_event");
      eventBlock.initSvg();
      eventBlock.render();
      eventBlock.moveBy(blocklyFlyout.getWidth() / scale + 50, offset);
    }
    workspace.clearUndo();
    workspace.showContextMenu = () => {};

    workspace.addChangeListener(Blockly.Events.disableOrphans);
    const disableTopBlocksPlugin = new DisableTopBlocks();
    disableTopBlocksPlugin.init();

    BlocklyJs.addReservedWords("highlightBlock");
    setWorkspace(workspace);

    return () => {
      setWorkspace(null);
    };
  }, []);
};

const useWorkspaceListener = (
  answer: string,
  scale: number,
  offset: number,
  locale: string,
  workspace: Blockly.WorkspaceSvg,
  setEvent: (code: string) => void,
  toolboxRef: React.MutableRefObject<ToolboxInfoType>,
  setToolboxInfo: (toolboxInfo: any) => void,
  toolboxTree: any
) => {
  const [clear, _setClear] = useState(0);
  const clearRef = useRef(clear);
  const setClear = (clear: number) => {
    clearRef.current = clear;
    _setClear(clear);
  };
  const [blockXmlText, setBlockXmlText] = useState(null);

  useEffect(() => {
    if (workspace && answer) {
      const xml = Blockly.Xml.textToDom(answer);
      Blockly.Xml.domToWorkspace(xml, workspace);
    }
  }, [workspace]);

  useEffect(() => {
    if (workspace) {
      workspace.setScale(scale);
      workspace.updateToolbox(toolboxRef.current.json);
      updateFlyout(workspace, offset * scale, scale);
      fixProcedures(workspace, offset * scale, scale);
      setTimeout(() => {
        updateFlyout(workspace, offset * scale, scale);
        fixProcedures(workspace, offset * scale, scale);
      }, 300);

      const onWorkspaceChange = (event) => {
        switch (event.type) {
          case Blockly.Events.VAR_RENAME:
            const newToolBoxContents = toolboxRef.current.json.contents[0].contents.map(
              (e) => {
                if (
                  (e.type === "variables_set" || e.type === "variables_get") &&
                  e.fields.VAR.name === event.oldName
                ) {
                  e.fields.VAR.name = event.newName;
                  return e;
                } else {
                  return e;
                }
              }
            );
            toolboxRef.current.json.contents[0].contents = newToolBoxContents;
            workspace.updateToolbox(toolboxRef.current.json);
            updateFlyout(workspace, offset * scale, scale);
            break;
          case Blockly.Events.BLOCK_CHANGE:
          case Blockly.Events.BLOCK_CREATE:
          case Blockly.Events.BLOCK_DELETE:
          case Blockly.Events.BLOCK_MOVE:
            if (event.type === Blockly.Events.BLOCK_CREATE) {
              const block = workspace.getBlockById(event.blockId);
              if (!block) {
                // スペック低い端末でブロックをdragの後にすぐに手放して削除されると、ここでblockを読み取ることができなくなる。
                return;
              }

              Object.keys(toolboxRef.current.maxInstances).forEach(
                (type) =>
                  (toolboxRef.current.maxInstances[type] = toolboxTree[type])
              );

              if (block.type !== "character_event") {
                if (event.json.type === "procedures_defnoreturn") {
                  const proceduresBlock = workspace.getBlockById(event.blockId);
                  const funcName = block.getFieldValue("NAME");
                  proceduresBlock.setMutator(null);
                  // 今関数ブロックは最大2個しか使えない。
                  let callFuncType: string;
                  const currentCalls = toolboxRef.current.json.contents[0].contents.filter(
                    (content) =>
                      content.type === "procedures_callnoreturn_1" ||
                      content.type === "procedures_callnoreturn_2"
                  );
                  if (currentCalls.length === 0) {
                    callFuncType = "procedures_callnoreturn_1";
                  } else if (currentCalls.length === 1) {
                    if (
                      currentCalls[0].type === "procedures_callnoreturn_1" &&
                      toolboxTree["procedures_callnoreturn_2"] > 0
                    ) {
                      callFuncType = "procedures_callnoreturn_2";
                    } else if (
                      currentCalls[0].type === "procedures_callnoreturn_2" &&
                      toolboxTree["procedures_callnoreturn_1"] > 0
                    ) {
                      callFuncType = "procedures_callnoreturn_1";
                    }
                  }
                  if (callFuncType) {
                    const callFunc = {
                      kind: "block",
                      type: callFuncType,
                      fields: {
                        NAME: funcName,
                      },
                    };

                    toolboxRef.current.json.contents[0].contents.push(callFunc);
                    workspace.updateToolbox(toolboxRef.current.json);
                  }
                  updateFlyout(workspace, offset * scale, scale);
                }
                workspace.getAllBlocks(true).forEach((block) => {
                  block.contextMenu = false;
                  if (block.type !== "character_event") {
                    toolboxRef.current.maxInstances[block.type] -= 1;
                  } else {
                    // 古いブロックも削除できるようにするため。
                    if (!block.isDeletable()) {
                      block.setDeletable(true);
                    }
                  }
                });
              } else {
                workspace.getAllBlocks(true).forEach((block) => {
                  block.contextMenu = false;
                  if (block.type !== "character_event") {
                    if (block.type !== "procedures_defnoreturn") {
                      toolboxRef.current.maxInstances[block.type] -= 1;
                    }
                  } else {
                    // 古いブロックも削除できるようにするため。
                    if (!block.isDeletable()) {
                      block.setDeletable(true);
                    }
                  }
                });
              }

              setToolboxInfo(toolboxRef.current);
              updateFlyout(workspace, offset * scale, scale);
            }

            if (event.type === Blockly.Events.BLOCK_CHANGE) {
              const block = workspace.getBlockById(event.blockId);
              if (!block) {
                // スペック低い端末でブロックをdragの後にすぐに手放して削除されると、ここでblockを読み取ることができなくなる。
                return;
              }

              if (block.type === "procedures_defnoreturn") {
                const funcName = block.getFieldValue("NAME");

                const callFuncType = toolboxRef.current.json.contents[0].contents.filter(
                  (content) =>
                    (content.type === "procedures_callnoreturn_1" ||
                      content.type === "procedures_callnoreturn_2") &&
                    content.fields.NAME === event.oldValue
                );
                if (callFuncType.length > 0) {
                  callFuncType[0].fields.NAME = funcName;
                  workspace
                    .getBlocksByType(callFuncType[0].type, false)
                    .forEach((block) => block.setFieldValue(funcName, "NAME"));
                  workspace.updateToolbox(toolboxRef.current.json);
                  updateFlyout(workspace, offset * scale, scale);
                } else {
                  console.log(event);
                }
              }
            }

            if (event.type === Blockly.Events.DELETE) {
              if (
                event.oldJson.type === "character_event" &&
                workspace.getBlocksByType("character_event", false).length === 0
              ) {
                const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
                const eventBlock = new Blockly.BlockSvg(
                  workspace,
                  "character_event"
                );
                eventBlock.initSvg();
                eventBlock.render();
                eventBlock.moveBy(
                  blocklyFlyout.getWidth() / scale + 50,
                  offset
                );
              }

              Object.keys(toolboxRef.current.maxInstances).forEach(
                (type) =>
                  (toolboxRef.current.maxInstances[type] = toolboxTree[type])
              );
              workspace.getAllBlocks(true).forEach((block) => {
                if (
                  block.type !== "character_event" &&
                  toolboxRef.current.maxInstances[block.type] &&
                  toolboxTree[block.type]
                ) {
                  // maxInstancesからキャンパスにあるブロックの数を引いてからのこりブロックの数となる。
                  toolboxRef.current.maxInstances[block.type] -= 1;
                }
              });

              if (event.oldJson.type === "procedures_defnoreturn") {
                if (clearRef.current > 0) {
                  setClear(clearRef.current - 1);
                } else {
                  const target = event.oldJson;
                  const newToolBoxContents = toolboxRef.current.json.contents[0].contents.filter(
                    (e) =>
                      (e.type !== "procedures_callnoreturn_1" &&
                        e.type !== "procedures_callnoreturn_2") ||
                      e.fields.NAME !== target.fields.NAME
                  );
                  const callFuncType = toolboxRef.current.json.contents[0].contents.filter(
                    (content) =>
                      (content.type === "procedures_callnoreturn_1" ||
                        content.type === "procedures_callnoreturn_2") &&
                      content.fields.NAME === target.fields.NAME
                  );
                  if (callFuncType.length > 0) {
                    workspace
                      .getBlocksByType(callFuncType[0].type, false)
                      .forEach((block) => block.dispose(false));
                    toolboxRef.current.json.contents[0].contents = newToolBoxContents;
                  }
                }
              }

              workspace.updateToolbox(toolboxRef.current.json);
              const flyoutIsVisible = (workspace.getFlyout() as Blockly.Flyout).isVisible();
              updateFlyout(workspace, offset * scale, scale);
              if (!flyoutIsVisible) {
                (workspace.getFlyout() as Blockly.Flyout).setVisible(false);
              }
              setToolboxInfo(toolboxRef.current);
              workspace.trashcan.emptyContents();
            }

            fixProcedures(workspace, offset * scale, scale);

            if (!workspace.isDragging()) {
              const code = BlocklyJs.workspaceToCode(workspace).replace(
                /function /g,
                "async $&"
              ); // safari maybe has some problems.
              setEvent(code);
              const xml = Blockly.Xml.workspaceToDom(workspace);
              const blockXmlText = Blockly.Xml.domToText(xml);
              setBlockXmlText(blockXmlText);
            }

            break;
          default:
            fixProcedures(workspace, offset * scale, scale);
            break;
        }
      };

      workspace.addChangeListener(onWorkspaceChange);

      if (blockXmlText) {
        const domXml = Blockly.Xml.textToDom(blockXmlText);
        Blockly.Xml.domToWorkspace(domXml, workspace);
      }

      return () => {
        setClear(
          workspace.getBlocksByType("procedures_defnoreturn", true).length
        );
        if (workspace) {
          workspace.removeChangeListener(onWorkspaceChange);
          workspace.clear();
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, workspace, scale]);

  useEffect(() => {
    return () => {
      if (workspace) {
        workspace.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useBlocklyInit = (
  scale: number,
  offset: number,
  locale: string,
  toolboxRef: React.MutableRefObject<ToolboxInfoType>,
  setToolboxInfo: (toolboxInfo: any) => void,
  toolboxTree: any,
  answer: string,
  workspace: Blockly.WorkspaceSvg,
  setWorkspace: (workspace: any) => void,
  setEvent: (code: string) => void
) => {
  useInitPreviewWorkspace(
    answer,
    scale,
    offset,
    locale,
    setWorkspace,
    toolboxRef
  );
  useWorkspaceListener(
    answer,
    scale,
    offset,
    locale,
    workspace,
    setEvent,
    toolboxRef,
    setToolboxInfo,
    toolboxTree
  );
};
