/* eslint-disable no-new-func */
import { useState } from "react";
import I18n from "i18n-js";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const [clicked, setClicked] = useState(false);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;

  const handleEvent = new Function(
    "anime",
    "sleep",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      play();
      setRunning(true);
      setClicked(true);
      handleEvent(anime, sleep, highlightBlock, setSuccess, setGameOver)();
    }
  };

  return (
    <div className="flex-row-center w-full gap-20 mt-8">
      <div className="flex-col-el flex-center">
        <div className="flex-row-el flex-center absolute top-0">
          <p className="text-body text-white text-[20px]">
            {I18n.t("MSG_COURSE_COORDINATE_X")}
          </p>
        </div>

        <div className="flex-col-center w-[300px] h-[5px]">
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
            <p className="text text-white !mb-[35px]">0</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
            <p className="text text-white !mb-[35px]">35</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
            <p className="text text-white !mb-[35px]">70</p>
          </div>
          <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

          <div
            data-value="0"
            id="course-program-image-1"
            className="flex-row-el flex-center absolute -left-10 top-4 z-50 cursor-pointer"
          >
            <img
              alt="*"
              onClick={handleClick}
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] active:opacity-70"
            />
            <AnswerMark id={1} />
          </div>

          <div
            className="flex-row-el absolute -left-10 top-4"
            style={{ transform: `translateX(${70 * 4.3}px)` }}
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
            />
          </div>
        </div>
      </div>

      <div className="flex-col-center">
        <div className="flex-row-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
          <p
            id="course-program-answer-label-1"
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            70
          </p>
          <p
            id="course-program-label-1"
            className="text-body text-white text-[100px] leading-[100px] opacity-0 absolute transition duration-700 scale-150"
          />
          <AnswerMark id={2} />
        </div>

        <p className="text text-white">ボックス1</p>
      </div>
    </div>
  );
};
