import { SVGProps } from "react";

export const IconLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-right-a)">
      <path
        stroke="#FEFDFE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="m18.666 9.333-8 6.667 8 6.666"
      />
    </g>
    <defs>
      <clipPath id="icon-right-a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-right-a)">
      <path
        stroke="#FEFDFE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="m16.666 28.333 10-8.333-10-8.334"
      />
    </g>
    <defs>
      <clipPath id="icon-right-a">
        <path fill="#fff" d="M40 40H0V0h40z" />
      </clipPath>
    </defs>
  </svg>
);
