const ToolboxInfo = {
  maxInstances: {
    program_image_click_event_block: 1,
    hand_getter_rotate_block: 2,
    hand_setter_rotate_block: 1,
    label_text_show_setter_block: 2,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "program_image_click_event_block",
          },
          {
            kind: "block",
            type: "hand_getter_rotate_block",
          },
          {
            kind: "block",
            type: "hand_setter_rotate_block",
            inputs: {
              hour: {
                shadow: {
                  type: "math_number",
                  fields: {
                    NUM: 0,
                  },
                },
              },
              minute: {
                shadow: {
                  type: "math_number",
                  fields: {
                    NUM: 0,
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: "label_text_show_setter_block",
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
