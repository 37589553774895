import { useEffect, useState } from "react";
import I18n from "i18n-js";
import { IconAward } from "common/assets";
import {
  IconHart,
  IconMap,
  IconProject,
  IconPublished,
} from "features/user/assets";
import { AppUser } from "features/user/types";
import { fetchUserStatus } from "features/user/api";

type UserStatusType = {
  course: {
    finished: number;
    total: number;
  };
  project: {
    total: number;
    viewed: number;
    published: number;
    favorited: number;
  };
};

export const UserStatus = ({ selectedUser }: { selectedUser: AppUser }) => {
  const [status, setStatus] = useState<UserStatusType | null>(null);

  useEffect(() => {
    fetchUserStatus({
      uid: selectedUser.uid,
    }).then((response) => setStatus(response.data.data));
  }, [selectedUser]);

  return (
    <div className="flex-col-center ">
      <div className="flex-row-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10">
        <IconAward size={24} />
        <p className="text text-textcolor/black !text-[16px] !px-[8px]">
          {I18n.t("MSG_USER_PROFILE_USER_STATUS")}
        </p>
      </div>
      <div className="flex-row-center mt-[-14px] pt-[20px] bg-white/40 rounded-[12px]">
        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconMap />
            </div>
            <p className="text text-gray2/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_COURSE_CLEARED")}
            </p>
          </div>
          <div className="flex-col-center h-[40px]">
            <p className="text text-textcolor/black">
              {status?.course.finished}/{status?.course.total}
            </p>
          </div>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconProject />
            </div>
            <p className="text text-gray2/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_PROJECTS")}
            </p>
          </div>
          <div className="flex-col-center h-[40px]">
            <p className="text text-textcolor/black">{status?.project.total}</p>
          </div>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconPublished />
            </div>
            <p className="text text-gray2/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_PUBLISHED_PROJECTS")}
            </p>
          </div>
          <div className="flex-col-center h-[40px]">
            <p className="text text-textcolor/black">
              {status?.project.published}
            </p>
          </div>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center w-[104px]">
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconHart />
            </div>
            <p className="text text-gray2/100 !text-[16px]">
              {I18n.t("MSG_USER_PROFILE_STATUS_PROJECT_RECEIVED_FAVORITE")}
            </p>
          </div>
          <div className="flex-col-center h-[40px]">
            <p className="text text-textcolor/black">
              {status?.project.favorited}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
