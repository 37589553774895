import { useEffect } from "react";
import I18n from "i18n-js";
import Constants from "common/constant";
import {
  InitMathPlusBlock,
  InitImageClickBlock,
  InitImageGetterXBlock,
  InitImageSetterXBlock,
  InitLabelTextShowSetterBlock,
} from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitMathPlusBlock();
    InitImageClickBlock();
    InitImageGetterXBlock();
    InitImageSetterXBlock();
    InitLabelTextShowSetterBlock();
  }, []);

  return (
    <div className="flex-row-center w-full gap-20">
      <div className="flex-col-el flex-center mt-8">
        <div className="flex-row-el flex-center absolute top-8">
          <p className="text-body text-white text-[20px]">
            {I18n.t("MSG_COURSE_COORDINATE_X")}
          </p>
        </div>

        <div className="flex-col-center w-[360px] h-[5px]">
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
            <p className="text text-white !mb-[35px]">0</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[25%]">
            <p className="text text-white !mb-[35px]">20</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
            <p className="text text-white !mb-[35px]">40</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[75%]">
            <p className="text text-white !mb-[35px]">60</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
            <p className="text text-white !mb-[35px]">80</p>
          </div>
          <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px]"
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${20 * 4.5}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${40 * 4.5}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${60 * 4.5}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${80 * 4.5}px)` }}
            />
          </div>
        </div>
      </div>

      <div className="flex-col-center gap-2">
        <div className="flex-col-center w-[312px]">
          <div className="flex-row-center gap-2 p-2 border-solid border-[4px] rounded-[6px] border-white/20">
            <p
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              40
            </p>
            <p className="text text-white !text-[50px] opacity-70">→</p>
            <p
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              80
            </p>
          </div>

          <p className="text text-white">ボックス1</p>
        </div>

        <div className="flex-row-el flex-center w-[150px] h-[50px] relative">
          <div className="flex-row-el flex-center upsilon absolute bottom-0 pointer-events-auto cursor-pointer z-50">
            <button disabled className="btn btn-blue">
              <p className="text !mb-[2px]">ボタン１</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
