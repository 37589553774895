import { MouseEventHandler } from "react";
import I18n from "i18n-js";
import { Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { snackbarClasses } from "@mui/material/Snackbar";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { CloseBtn, SlideDownTransition } from "common/elements";
import { Decoration, Background, InstallIcon } from "./assets";

const InstallPrompt = styled(Snackbar)(({ theme }) => ({
  [`&.${snackbarClasses.anchorOriginBottomLeft}`]: {
    width: "100%",
    zIndex: 10000,
    bottom: 0,
  },
}));

export const Banner = (props: {
  open: boolean;
  dismiss: MouseEventHandler<HTMLDivElement>;
  close: () => void;
  confirm: () => void;
  label: string;
}) => {
  const { scale } = useScale();
  const realScale = scale > 0.5 ? scale : scale * 2;
  const { open, close, dismiss, confirm, label } = props;
  return (
    <InstallPrompt
      open={open}
      TransitionComponent={SlideDownTransition}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      autoHideDuration={10000}
      onClose={close}
    >
      <div
        className="flex-col-center"
        style={{
          width: 580 * realScale,
          height: 140 * realScale,
          filter: "drop-shadow(0px 0px 30px rgba(63, 74, 97, 0.35))",
        }}
      >
        <div
          className="flex-col-center w-[580px] h-[140px] cursor-pointer"
          style={{ transform: `scale(${realScale})` }}
          onClick={confirm}
        >
          <div
            onClick={dismiss}
            className="flex-col-center !absolute top-[16px] right-[40px] pointer-events-auto"
          >
            <CloseBtn />
          </div>

          <div className="absolute block z-[-1]">
            <div>
              <Background />
            </div>

            <div className="absolute block top-[32px] left-[-40px]">
              <Decoration />
            </div>
          </div>

          <div className="flex flex-row items-center">
            <div
              className="flex-col-center bg-white rounded-full w-[96px] h-[96px] mr-4"
              style={{
                boxShadow:
                  "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
              }}
            >
              <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                <img
                  alt="アプリケーションのアイコン"
                  src={`${Constants.assetHost}/assets/images/app-icon.png`}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>

            <div className="flex flex-col flex-1">
              <div className="flex flex-col">
                <p className="text text-textcolor/black !text-left !text-[24px]">
                  {I18n.t("MSG_APP_BANNER_TITLE")}
                </p>
                <p className="text text-textcolor/black !text-left !text-[18px]">
                  {I18n.t("MSG_APP_BANNER_SBUTITLE")}
                </p>
              </div>

              <div className="flex flex-row w-full items-center justify-end mt-2">
                <p className="text text-blue/60 !text-[24px]">{label}</p>
                <div className="ml-2">
                  <InstallIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InstallPrompt>
  );
};
