import { SVGProps } from "react";

export const IconMapColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m4 4 5 3 6-2.5L20 6v13l-5-2-6 2.5L4 18V4Z" fill="#DBEBFC" />
    <path
      d="m21.447 6.105-6-3a1 1 0 0 0-.895 0L9 5.882 3.447 3.105A1 1 0 0 0 2 4v13c0 .379.214.725.553.895l6 3a1 1 0 0 0 .895 0L15 18.118l5.553 2.776a.992.992 0 0 0 .972-.043c.295-.183.475-.504.475-.851V7c0-.379-.214-.725-.553-.895ZM10 7.618l4-2v10.764l-4 2V7.618Zm-6-2 4 2v10.764l-4-2V5.618Zm16 12.764-4-2V5.618l4 2v10.764Z"
      fill="#A69DA3"
    />
  </svg>
);
