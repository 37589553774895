import { SVGProps } from "react";

export const OverviewMessageBalloonSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={394}
    height={202}
    viewBox="0 0 394 202"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M356.106 25.482c-18.365-9.946-39.175-13.6-63.843-17.94a463.75 463.75 0 0 0-56.739-6.205c-22.141-1.285-67.538-3.682-123.611 4.61-36.377 5.375-54.565 8.062-71.993 17.234C1.642 43.353.528 78.912.147 85.214c-1.698 24.85 11.139 58.321 46.184 73.458 12.307 5.325 24.954 6.765 50.26 9.665 37.124 4.243 72.672 2.213 77.426 1.933 16.423-.966 52.433-.367 69.277-.097h2.975c41.661.426 55.448-1.681 64.372-3.45 10.487-2.078 28.933-5.877 45.451-16.083 13.299-8.226 18.868-16.596 24.614-25.276 4.143-6.224 12.945-18.983 13.257-35.607.204-9.878.829-43.32-37.857-64.276Z"
      fill="#F2EFF1"
    />
    <path
      d="M239 202c-18.675 0-37.657-26.264-35.885-36h21.93c0 7.472 3.322 23.094 13.955 36Z"
      fill="#F2EFF1"
    />
  </svg>
);
