import { BlockType } from "features/builder/types";

export const ToolboxDef1 = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      toolboxitemid: "category",
      contents: [
        {
          kind: "block",
          type: BlockType.MOTION_MOVE,
        },
        {
          kind: "block",
          type: BlockType.MOTION_JUMP,
        },
        {
          kind: "block",
          type: BlockType.MOTION_TURN_LEFT,
        },
        {
          kind: "block",
          type: BlockType.MOTION_TURN_RIGHT,
        },
      ],
    },
  ],
};
