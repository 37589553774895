import { useRef, useState, WheelEventHandler } from "react";
import { useSelector } from "react-redux";
import MobileStepper from "@mui/material/MobileStepper";
import { RootState } from "app/store";
import { stampCategories } from "app/assets";
import Constants from "common/constant";

export const Stamp = (props: {
  handleSendStamp: (url: string) => () => void;
}) => {
  const { handleSendStamp } = props;
  const ref = useRef<HTMLDivElement>();
  const [activeStep, setActiveStep] = useState(0);
  const assets = useSelector((state: RootState) => state.config.assets);
  const [selectedCategory, setSelectedCategory] = useState("stamps/character");

  const handleSelectCategory = (category: string, index: number) => () => {
    setActiveStep(index);
    setSelectedCategory(category);
  };

  const handleWheel: WheelEventHandler<HTMLDivElement> = (e) => {
    if (ref.current) {
      ref.current.scrollBy(e.deltaY, 0);
    }
  };

  return (
    <div className="flex-col-el items-center justify-end relative w-full h-[300px] bg-white">
      <div className="flex-row-el shrink-0 relative w-full  bg-white overflow-hidden">
        <div
          ref={ref}
          onWheel={handleWheel}
          className="flex-row-el relative invisible-scrollbar"
        >
          {stampCategories.map((category, index) => (
            <div
              key={index}
              className={`flex-row-el flex-center relative cursor-pointer shrink-0 w-[50px] h-[50px] p-[10px] ${
                activeStep === index && "bg-gray2/10 rounded-[8px]"
              }`}
              onClick={handleSelectCategory(category, index)}
            >
              <img
                alt="スタンプ画像"
                src={`${Constants.assetHost}/${assets[category][0].fileKey}`}
                className="w-full h-full"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex-col-el relative w-full items-center overflow-hidden">
        <div className="flex-row-view w-full h-full invisible-scrollbar">
          <div className="grid grid-flow-row grid-cols-stamp-auto-fill justify-center w-full h-full">
            {assets[selectedCategory].map((asset, index) => (
              <div
                key={index}
                className="flex-col-el flex-center shrink-0 w-[70px] h-[70px] m-[10px] cursor-pointer"
              >
                <img
                  alt="スタンプ画像"
                  src={`${Constants.assetHost}/${asset.fileKey}`}
                  className="w-full h-full"
                  onClick={handleSendStamp(
                    `${Constants.assetHost}/${asset.fileKey}`
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <MobileStepper
        variant="dots"
        steps={stampCategories.length}
        position="static"
        sx={{ bgcolor: "#FEFDFE" }}
        activeStep={activeStep}
        nextButton={<></>}
        backButton={<></>}
      />
    </div>
  );
};
