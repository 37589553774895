import { SVGProps } from "react";

export const IconViewed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g fill="#A54D21" clipPath="url(#a)">
      <path
        fillRule="evenodd"
        d="M9.411 16.407a8.064 8.064 0 0 0-1.05 3.998v5.25H4.637c-1.235 0-2.236-1.038-2.236-2.318v-.772c0-3.413 2.669-6.18 5.962-6.18h.745c.103 0 .205.008.304.022ZM22.148 16.407a7.697 7.697 0 0 1 1.116 3.998v5.25h3.96c1.312 0 2.376-1.038 2.376-2.318v-.772c0-3.413-2.837-6.18-6.336-6.18h-.792c-.11 0-.218.008-.324.022Z"
        clipRule="evenodd"
      />
      <path d="M9.852 20.803a5.962 5.962 0 1 1 11.923 0V24a3.2 3.2 0 0 1-3.2 3.2h-5.523a3.2 3.2 0 0 1-3.2-3.2v-3.197Z" />
      <ellipse cx={6.872} cy={10.979} rx={3.726} ry={3.862} />
      <ellipse cx={24.756} cy={10.979} rx={3.726} ry={3.862} />
      <ellipse cx={15.814} cy={9.434} rx={4.471} ry={4.634} />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
