/* eslint-disable no-new-func */
import { useState } from "react";
import I18n from "i18n-js";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const [clicked, setClicked] = useState(false);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "anime",
    "sleep",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      play();
      setRunning(true);
      setClicked(true);
      handleEvent(anime, sleep, highlightBlock, setSuccess, setGameOver)();
    }
  };
  return (
    <div className="flex-col-el flex-center absolute top-44">
      <div className="flex-row-el flex-center absolute -top-16">
        <p className="text-body text-white text-[20px]">
          {I18n.t("MSG_COURSE_COORDINATE_X")}
        </p>
      </div>

      <div className="flex-col-el flex-center w-[450px] h-[5px]">
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
          <p className="text text-white !mb-[35px]">0</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
          <p className="text text-white !mb-[35px]">50</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
          <p className="text text-white !mb-[35px]">100</p>
        </div>
        <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

        <div
          id="course-program-image-1"
          className="flex-row-el flex-center absolute -left-10 top-4 z-50 cursor-pointer"
        >
          <img
            alt="*"
            onClick={handleClick}
            src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
            className="w-[60px] h-[60px] active:opacity-70"
          />
          <AnswerMark id={1} />
        </div>

        <div className="flex-row-el absolute -left-10 top-4 pointer-events-none">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${100 * 4.5}px)` }}
          />
        </div>
      </div>
    </div>
  );
};
