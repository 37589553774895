import i18n from "i18n-js";
import produce from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  RENDER_ORDER,
  ComponentManager,
  ComponentTypeIds,
} from "common/components";
import {
  CanvasEntity,
  ScreensEntity,
  CreatorInitialState,
  ComponentsEntityData,
} from "features/creator/types";
import {
  HISTORY_STACK_MAX,
  SCREEN_CANVAS_SCALE_MAX,
  SCREEN_CANVAS_SCALE_MIN,
  DEFAULT_SCREEN_ORIENTATION,
} from "features/creator/constants";
import {
  canvasAdapter,
  blocklyAdapter,
  screensAdapter,
  componentsAdapter,
  propertiesAdapter,
} from "features/creator/slice/reducers/adapter";
import { createScreen } from "features/creator/api";

const reducers = {
  updateSelectedScreenId(
    state: CreatorInitialState,
    action: PayloadAction<string>
  ) {
    state.focus = !state.focus;
    state.selectedScreenId = action.payload;
    state.selectedComponentId = action.payload;
  },
  updateDefaultScreenId(
    state: CreatorInitialState,
    action: PayloadAction<string>
  ) {
    const defaultScreenId = state.defaultScreenId;
    const newDefaultScreenId = action.payload;
    state.undoStacks.push({
      before: {
        defaultScreenId,
        properties: [],
      },
      after: {
        defaultScreenId: newDefaultScreenId,
        properties: [],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];
    state.defaultScreenId = newDefaultScreenId;
  },
  updateScreenScale(state: CreatorInitialState, action: PayloadAction<number>) {
    if (action.payload <= SCREEN_CANVAS_SCALE_MIN) {
      state.canvasScale = SCREEN_CANVAS_SCALE_MIN;
    } else if (action.payload >= SCREEN_CANVAS_SCALE_MAX) {
      state.canvasScale = SCREEN_CANVAS_SCALE_MAX;
    } else {
      state.canvasScale = action.payload;
    }
  },
  updateCanvas(
    state: CreatorInitialState,
    action: PayloadAction<{ id: string; newCanvas: CanvasEntity }>
  ) {
    const { id, newCanvas } = action.payload;
    const canvas = canvasAdapter.getSelectors().selectById(state.canvas, id);
    canvasAdapter.updateOne(state.canvas, { id, changes: newCanvas });
    state.undoStacks.push({
      before: {
        canvas,
        properties: [],
      },
      after: {
        canvas: newCanvas,
        properties: [],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];
  },
  addScreen(state: CreatorInitialState) {
    const selectedScreenId = state.selectedScreenId;
    const selectedComponentId = state.selectedComponentId;
    const total = componentsAdapter
      .getSelectors()
      .selectTotal(state.components);

    const screenIds = screensAdapter.getSelectors().selectIds(state.screens);
    const latestScreen = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenIds[screenIds.length - 1]);

    const { screen, screenProperty } = createScreen(
      DEFAULT_SCREEN_ORIENTATION,
      `${i18n.t("MSG_CREATOR_COMPONENT_NAME_SCREEN")}${total + 1}`,
      latestScreen.order + 1
    );

    const latestScreenCanvas = canvasAdapter
      .getSelectors()
      .selectById(state.canvas, screenIds[screenIds.length - 1]);

    const canvas = {
      id: screen.id,
      x: latestScreenCanvas.x + state.screenSize.width + 20,
      y: latestScreenCanvas.y,
    };
    canvasAdapter.addOne(state.canvas, canvas);
    screensAdapter.addOne(state.screens, screen);
    propertiesAdapter.addOne(state.properties, screenProperty);
    blocklyAdapter.addOne(state.blockly, {
      screenId: screen.id,
      xmlText: '<xml xmlns="https://developers.google.com/blockly/xml"></xml>',
    });

    const componentsData: ComponentsEntityData = Array.from(
      Object.values(ComponentManager)
    ).reduce(
      (acc, component) =>
        Object.assign(
          acc,
          component.id === ComponentTypeIds.SCREEN
            ? {
                [component.id]: [{ id: screen.id, deleted: false }],
              }
            : { [component.id]: [] }
        ),
      {}
    );
    const components = {
      screenId: screen.id,
      data: componentsData,
    };
    componentsAdapter.addOne(state.components, components);

    state.undoStacks.push({
      before: {
        selectedScreenId,
        selectedComponentId,
        properties: [],
      },
      after: {
        screen,
        components: components,
        selectedScreenId: screen.id,
        selectedComponentId: screen.id,
        canvas,
        blockly: { screenId: screen.id },
        properties: [screenProperty],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];

    state.selectedScreenId = screen.id;
    state.selectedComponentId = screen.id;
  },
  updateScreen(
    state: CreatorInitialState,
    action: PayloadAction<{ id: string; newScreen: ScreensEntity }>
  ) {
    const { id, newScreen } = action.payload;
    const screen = screensAdapter.getSelectors().selectById(state.screens, id);
    screensAdapter.updateOne(state.screens, { id, changes: newScreen });
    state.undoStacks.push({
      before: { screen, properties: [] },
      after: {
        screen: newScreen,
        properties: [],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];
  },
  removeScreen(state: CreatorInitialState, action: PayloadAction<string>) {
    const selectedScreenId = state.selectedScreenId;
    const selectedComponentId = state.selectedComponentId;
    const screenId = action.payload;

    const screen = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenId);

    const canvas = canvasAdapter
      .getSelectors()
      .selectById(state.canvas, screenId);
    canvasAdapter.removeOne(state.canvas, screenId);
    screensAdapter.removeOne(state.screens, screenId);
    blocklyAdapter.removeOne(state.blockly, screenId);

    const propertySelector = propertiesAdapter.getSelectors();
    const components = componentsAdapter
      .getSelectors()
      .selectById(state.components, screenId);
    componentsAdapter.removeOne(state.components, screenId);

    const propertiesEntities = [
      propertySelector.selectById(state.properties, screenId),
      ...screen.childrenOrder.map((componentId) =>
        propertySelector.selectById(state.properties, componentId)
      ),
    ];
    propertiesAdapter.removeMany(state.properties, [
      screenId,
      ...screen.childrenOrder,
    ]);

    const blockly = blocklyAdapter
      .getSelectors()
      .selectById(state.blockly, selectedScreenId);

    state.undoStacks.push({
      before: {
        selectedScreenId,
        selectedComponentId,
        screen,
        canvas,
        blockly,
        components,
        properties: propertiesEntities,
      },
      after: {
        selectedScreenId: state.defaultScreenId,
        selectedComponentId: state.defaultScreenId,
        properties: [],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];

    state.selectedScreenId = state.defaultScreenId;
    state.selectedComponentId = state.defaultScreenId;
    state.focus = !state.focus;
  },
  changeScreenComponentsOrder(
    state: CreatorInitialState,
    action: PayloadAction<{
      screenId: string;
      componentId: string;
      order: RENDER_ORDER; // 1: front, 0: back
    }>
  ) {
    const { screenId, componentId, order } = action.payload;

    const screen = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenId);

    const newScreen = produce(screen, (draft) => {
      draft.childrenOrder = screen.childrenOrder.filter(
        (id) => id !== componentId
      );
      draft.childrenOrder.splice(
        draft.childrenOrder.length * order,
        0,
        componentId
      );
    });

    screensAdapter.updateOne(state.screens, {
      id: screenId,
      changes: newScreen,
    });

    state.undoStacks.push({
      before: {
        screen,
        properties: [],
      },
      after: {
        screen: newScreen,
        properties: [],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];
  },
};

export default reducers;
