import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Blockly from "blockly";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import Constants from "common/constant";
import {
  BlockLimits,
  useInitWorkspace,
  useWorkspaceListener,
} from "features/courses/program/game/blockly";
import { CommandSvg } from "features/courses/assets";
import { ToolboxInfoType } from "features/courses/program/game/type";

export const BlockPanel = ({
  run,
  stage,
  step,
  offset,
  answer,
  workspace,
  setWorkspace,
  toolboxRef,
  toolboxTree,
  setToolboxInfo,
  setEvent,
}: {
  run: boolean;
  stage: number;
  step: number;
  offset: number;
  answer: string;
  workspace: Blockly.WorkspaceSvg;
  setWorkspace: (workspace: any) => void;
  toolboxTree: any;
  setToolboxInfo: (toolbox: any) => void;
  toolboxRef: React.MutableRefObject<ToolboxInfoType>;
  setEvent: (event: string[]) => void;
}) => {
  const BLOCK_LIMITS_WIDTH = 66;
  const COMMAND_PANEL_HEIGHT = 82;
  const { width, height, scale } = useScale();
  const [drag, setDrag] = useState(false);
  const [blocklyFlyoutWidth, setBlocklyFlyoutWidth] = useState(0);
  const [blocklyFlyoutHeight, setBlocklyFlyoutHeight] = useState(0);
  const blockPanelWidth = blocklyFlyoutWidth + BLOCK_LIMITS_WIDTH * scale;
  const config = useSelector((state: RootState) => state.config.userConfig);

  useInitWorkspace({
    answer,
    stage,
    step,
    scale,
    offset: offset + COMMAND_PANEL_HEIGHT,
    locale: config.locale,
    toolboxRef,
    setWorkspace,
  });
  useWorkspaceListener({
    answer,
    scale,
    offset: offset + COMMAND_PANEL_HEIGHT,
    locale: config.locale,
    workspace,
    toolboxRef,
    toolboxTree,
    setToolboxInfo,
    setEvent,
  });

  useEffect(() => {
    if (workspace) {
      const flyout = workspace.getFlyout() as Blockly.Flyout;
      if (flyout) {
        setBlocklyFlyoutWidth(flyout.getWidth());
        setBlocklyFlyoutHeight(flyout.getHeight());
        // @ts-ignore
        flyout.onDragOver = () => {
          setDrag(true);
        };
        // @ts-ignore
        flyout.onDrop = () => {
          setDrag(false);
        };
        // @ts-ignore
        flyout.onDragExit = () => {
          setDrag(false);
        };
      }
    }
  }, [config.locale, workspace, scale]);

  return (
    <>
      <div
        id="courses-program-block-panel"
        className="flex-col-el flex-center absolute left-0 px-1"
        style={{
          top: offset * scale,
          height: blocklyFlyoutHeight - offset * scale,
          width: width / height > 1.4 ? blockPanelWidth : 350 * scale,
        }}
      >
        <div className="flex-col-el w-full h-full bg-white/60 border-solid rounded-[10px] border-[2px] border-white backdrop-blur-[4px]" />

        <div
          className="flex-col-el flex-center h-full rounded-[10px] bg-white absolute top-0 left-0 z-[10001] opacity-70"
          style={{
            width: 300 * scale,
            display: drag ? "flex" : "none",
          }}
        >
          <div className="flex-col-center">
            <img
              alt="trash"
              src={`${Constants.assetHost}/assets/images/trash.png`}
            />
          </div>
        </div>

        <div
          className="flex-col-el flex-center w-full absolute top-0 left-0"
          style={{ height: COMMAND_PANEL_HEIGHT * scale }}
        >
          <div
            className="flex-col-center w-[180px] h-[32px] pointer-events-auto cursor-pointer"
            style={{
              transform: `scale(${scale})`,
              margin: `${25 * scale}px 0`,
            }}
          >
            <CommandSvg />
          </div>
        </div>
      </div>

      {run && (
        <div
          className="flex-col-el flex-center absolute left-0 px-1 z-[10001]"
          style={{
            top: offset * scale,
            height: blocklyFlyoutHeight - offset * scale,
            width: blockPanelWidth,
          }}
        >
          <div className="flex-col-el w-full h-full bg-textcolor/black/10 rounded-[10px] backdrop-blur-[4px]" />
        </div>
      )}

      <div className="flex-row-center !items-start !flex-1 w-full pointer-events-none">
        <BlockLimits
          width={BLOCK_LIMITS_WIDTH}
          height={blocklyFlyoutHeight}
          stage={stage}
          scale={scale}
          offset={offset + COMMAND_PANEL_HEIGHT}
          workspace={workspace}
          toolboxRef={toolboxRef}
        />
        <div
          id="blocklyDiv"
          className="flex-col-view !flex-1 w-full h-full pb-2"
        />
      </div>
    </>
  );
};
