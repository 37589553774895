import { useRef, useState, useEffect } from "react";
import I18n from "i18n-js";
import { usePlaySound } from "common/utils";
import { CloseBtnOuter } from "common/elements";
import { GuideMessageBalloonSvg } from "features/courses/assets";

export const Tips = (props: {
  scale: number;
  message: string[];
  setShowTips: (show: boolean) => void;
}) => {
  const play = usePlaySound();
  const [page, setPage] = useState(0);
  const ref = useRef<HTMLDivElement>();
  const { scale, message, setShowTips } = props;

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = message[page];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleBack = () => {
    play();
    setPage(page - 1);
  };

  const handleMove = () => {
    play();
    if (page === message.length - 1) {
      setShowTips(false);
    } else {
      setPage(page + 1);
    }
  };

  const handleCloseTips = () => {
    play();
    setShowTips(false);
  };

  return (
    <div
      className="flex-col-view items-center w-[500px] h-[300px]"
      style={{ transform: `scale(${scale})` }}
    >
      <div className="flex-col-el absolute top-4 right-4">
        <CloseBtnOuter close={handleCloseTips} />
      </div>

      <div className="flex-col-view !absolute top-0 left-0 w-[500px] h-[300px]">
        <GuideMessageBalloonSvg />
      </div>

      <div className="flex-col-center w-[452px] h-[158px] top-[45px]">
        <p ref={ref} className="text text-textcolor/black !leading-[30px]" />
      </div>

      <div className="flex-row-el flex-center gap-4 absolute bottom-[24px]">
        {message.length > 1 && (
          <div className="flex-row-el flex-center relative alpha">
            <button
              className="btn btn-blue"
              onClick={handleBack}
              disabled={page === 0}
            >
              <p className="text-body text-white text-[24px] leading-[35px] !mb-[3px]">
                {I18n.t("MSG_COURSE_GAME_TIPS_BACK_BTN")}
              </p>
            </button>
          </div>
        )}

        <div className="flex-row-el flex-center relative alpha">
          <button className="btn btn-primary" onClick={handleMove}>
            <p className="text-body text-white text-[24px] leading-[35px] !mb-[3px]">
              {page === message.length - 1
                ? I18n.t("MSG_CLOSE_BTN")
                : I18n.t("MSG_COURSE_GAME_TIPS_NEXT_BTN")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};
