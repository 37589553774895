import { PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { Vector2D } from "common/types";
import {
  Grouping,
  GroupingDefault,
  CreatorInitialState,
} from "features/creator/types";
import { HISTORY_STACK_MAX } from "features/creator/constants";
import { propertiesAdapter } from "features/creator/slice/reducers/adapter";

const reducers = {
  updateGrouping(state: CreatorInitialState, action: PayloadAction<Grouping>) {
    const before = state.grouping;
    const after = action.payload;
    state.grouping = action.payload;
    if (before.enable || after.enable) {
      state.undoStacks.push({
        before: {
          grouping: before,
          selectedComponentId: before.enable
            ? state.selectedScreenId
            : state.selectedComponentId,
          properties: [],
        },
        after: {
          grouping: after,
          selectedComponentId: after.enable
            ? state.selectedScreenId
            : state.selectedComponentId,
          properties: [],
        },
      });
      if (state.undoStacks.length > HISTORY_STACK_MAX) {
        state.undoStacks.shift();
      }
      state.redoStacks = [];
    }
  },
  updateGroupingMovingEnd(
    state: CreatorInitialState,
    action: PayloadAction<{
      enable: boolean;
      componentIds: string[];
      position: Vector2D;
      size: Vector2D;
      delta: Vector2D;
    }>
  ) {
    const grouping = state.grouping;

    const { enable, componentIds, position, size, delta } = action.payload;

    const oldProperties = state.grouping.componentIds.map((componentId) =>
      propertiesAdapter.getSelectors().selectById(state.properties, componentId)
    );

    const newProperties = state.grouping.componentIds.map((componentId) => {
      const propertyEntity = propertiesAdapter
        .getSelectors()
        .selectById(state.properties, componentId);
      if (propertyEntity !== undefined) {
        const property = produce(propertyEntity.property, (draft) => {
          draft.style.transform.translateX += delta.x;
          draft.style.transform.translateY += delta.y;
        });
        return { ...propertyEntity, property };
      } else {
        return null;
      }
    });

    if (!newProperties.includes(null)) {
      state.undoStacks.push({
        before: {
          grouping,
          selectedComponentId: state.selectedScreenId,
          properties: oldProperties,
        },
        after: {
          grouping: {
            enable,
            componentIds,
            position,
            size,
          },
          selectedComponentId: state.selectedScreenId,
          properties: newProperties,
        },
      });
      if (state.undoStacks.length > HISTORY_STACK_MAX) {
        state.undoStacks.shift();
      }
      state.redoStacks = [];
      state.grouping = {
        enable,
        componentIds,
        position,
        size,
      };
    } else {
      console.error(JSON.stringify(state.grouping));
      state.grouping = GroupingDefault;
    }
  },
};

export default reducers;
