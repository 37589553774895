import * as React from "react";

export const DiamondIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 33" {...props}>
      <defs>
        <style>{".prefix__cls-3{fill:#fefdfe;opacity:.2}"}</style>
      </defs>
      <g
        id="prefix__\u30EC\u30A4\u30E4\u30FC_2"
        data-name="\u30EC\u30A4\u30E4\u30FC 2"
      >
        <g id="prefix__\u6307\u793A">
          <path
            fill="#db3159"
            d="M36 13.1L29.13 0H6.88L0 13.1 18 33l18-19.9z"
          />
          <path
            fillOpacity={0.9}
            fill="#fefdfe"
            opacity={0.2}
            d="M18 33l12.17-19.9H5.83L18 33z"
          />
          <path
            className="prefix__cls-3"
            d="M36 13.1h-5.83L29.13 0 36 13.1zM5.83 13.1H0L6.88 0 5.83 13.1z"
          />
        </g>
      </g>
    </svg>
  );
};
