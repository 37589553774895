export const CheckMark = ({ id }: { id: number }) => {
  return (
    <div
      id={`course-program-check-mark-${id}`}
      className="flex-col-el flex-center z-50 absolute opacity-0 pointer-events-none"
    >
      <p
        className="text-body text-[90px] text-[#25af01]"
        style={{ WebkitTextStroke: "3px white" }}
      >
        ✔️
      </p>
    </div>
  );
};
