import $ from "jquery";
import anime from "animejs";
import {
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BORDER_RADIUS_MAX,
  BORDER_RADIUS_MIN,
} from "common/components";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const getDrawingBorderColor = (id: string) =>
  $(`#drawing-${id}`).css("border-color");
export const setDrawingBorderColor = (id: string, value: string) =>
  $(`#drawing-${id}`).css("border-color", `${value}`);

export const getDrawingBorderWidth = (id: string): number =>
  Number($(`#drawing-${id}`).css("border-width").split("px")[0]) * 2;
export const setDrawingBorderWidth = (id: string, value: number) => {
  const container = $(`#drawing-container-${id}`);
  const drawing = $(`#drawing-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(drawing.css("border-width").split("px")[0]) * 2;

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  drawing.css("border-width", `${target / 2}`);
};
export const setDrawingBorderWidthByStep = (id: string, value: number) => {
  const container = $(`#drawing-container-${id}`);
  const drawing = $(`#drawing-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(drawing.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  drawing.css("border-width", `${target / 2}`);
};
export const setDrawingBorderWidthByTime = (
  id: string,
  time: number,
  value: number
) => {
  const drawing = $(`#drawing-${id}`);
  const currentBorderWidth =
    Number(drawing.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  const animation1 = anime({
    targets: `#drawing-container-${id}`,
    width: `+=${delta}`,
    height: `+=${delta}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#drawing-${id}`,
    borderWidth: value / 2,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
  });
  return Promise.all([animation1.finished, animation2.finished]);
};

export const getDrawingBorderRadius = (id: string): number =>
  Number($(`#drawing-${id}`).data("borderRadius"));
export const setDrawingBorderRadius = (id: string, value: number) => {
  const drawing = $(`#drawing-${id}`);
  const currentShapeWidth = drawing.width();
  const currentShapeHeight = drawing.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  drawing.data("borderRadius", target);
  drawing.css("border-radius", size * (target / 200));
};

export const setDrawingBorderRadiusByStep = (id: string, value: number) => {
  const newBorderRadius =
    Number($(`#drawing-${id}`).data("borderRadius")) + value;
  setDrawingBorderRadius(id, newBorderRadius);
};
export const setDrawingBorderRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const drawing = $(`#drawing-${id}`);
  const currentShapeWidth = drawing.width();
  const currentShapeHeight = drawing.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  drawing.data("borderRadius", target);
  const animation = anime({
    targets: `#drawing-${id}`,
    borderRadius: size * (target / 200),
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation.play();
  previewRemovedObserver(() => animation.pause());
  return animation.finished;
};
