import I18n from "i18n-js";
import { Category, Block, Value, Shadow, Field, Label } from "common/blockly";

export const Color = () => {
  return (
    <Category name="いろ" categorystyle="colour_category" toolboxitemid="color">
      <Block type="component_property_colour_picker">
        <Field name="COLOUR">#3333ff</Field>
      </Block>
      <Block type="colour_random" />
      <Block type="colour_rgb">
        <Value name="RED">
          <Shadow type="math_number">
            <Field name="NUM">100</Field>
          </Shadow>
        </Value>
        <Value name="GREEN">
          <Shadow type="math_number">
            <Field name="NUM">50</Field>
          </Shadow>
        </Value>
        <Value name="BLUE">
          <Shadow type="math_number">
            <Field name="NUM">0</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="colour_blend">
        <Value name="COLOUR1">
          <Shadow type="component_property_colour_picker">
            <Field name="COLOUR">#ed1228</Field>
          </Shadow>
        </Value>
        <Value name="COLOUR2">
          <Shadow type="component_property_colour_picker">
            <Field name="COLOUR">#3333ff</Field>
          </Shadow>
        </Value>
        <Value name="RATIO">
          <Shadow type="math_number">
            <Field name="NUM">0.5</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_BACKGROUND_COLOR")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_background_color" />
      <Block type="component_property_setter_background_color">
        <Value name="value">
          <Shadow type="component_property_colour_picker">
            <Field name="COLOUR">#FFFFFF</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER_COLOR")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_border_color" />
      <Block type="component_property_setter_border_color">
        <Value name="value">
          <Shadow type="component_property_colour_picker">
            <Field name="COLOUR">#FFFFFF</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_SHADOW_COLOR")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_shadow_color" />
      <Block type="component_property_setter_shadow_color">
        <Value name="value">
          <Shadow type="component_property_colour_picker">
            <Field name="COLOUR">#FFFFFF</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_COLOR")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_text_color" />
      <Block type="component_property_setter_text_color">
        <Value name="value">
          <Shadow type="component_property_colour_picker">
            <Field name="COLOUR">#FFFFFF</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW_COLOR")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_text_shadow_color" />
      <Block type="component_property_setter_text_shadow_color">
        <Value name="value">
          <Shadow type="component_property_colour_picker">
            <Field name="COLOUR">#FFFFFF</Field>
          </Shadow>
        </Value>
      </Block>
    </Category>
  );
};
