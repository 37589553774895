import { useEffect } from "react";
import { useDispatch } from "react-redux";
import I18n from "i18n-js";
import { actions } from "features/creator/slice";
import { ProjectPanelType } from "features/creator/types";
import { TopLayout } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.updateProjectPanel(ProjectPanelType.NONE));
    dispatch(actions.updateSortFilterPanel(false));
    dispatch(actions.updateKeyword(""));
    dispatch(
      actions.handleUpdatePublishedProjectFilter({
        type: "onlyOfficial",
        value: false,
      })
    );
    dispatch(
      actions.handleUpdatePublishedProjectFilter({
        type: "userFavorited",
        value: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TopLayout next={next}>
      <div className="flex-col-el flex-center gap-6 ml-11 mt-10">
        <p className="text-body text-[80px] text-white leading-[70px]">3</p>

        <div className="flex-col-center gap-2">
          <div className="flex-row-el flex-center px-3 bg-blue/100 rounded-xl">
            <p className="text-body text-[20px] leading-9 text-white">
              {I18n.t("MSG_CREATOR_APP_SHARED_APPS")}
            </p>
          </div>

          <p className="text-body text-[18px] text-white">について</p>
        </div>
      </div>
    </TopLayout>
  );
};

export default Step;
