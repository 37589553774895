import { SVGProps } from "react";

export const IconFinishDrawing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 5h2-2Z" fill="#FC6560" />
    <path
      d="M4 5h2M5 4v2"
      stroke="#FC6560"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 4 11 6"
      stroke="#FFA03B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 5h2M19 4v2"
      stroke="#FC6560"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15 9-1 1M18 13l2-.5"
      stroke="#FFA03B"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 19h2M19 18v2"
      stroke="#FC6560"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 16.518 7.482 10l-4.39 9.58a1.003 1.003 0 0 0 1.329 1.329L14 16.519v-.001Z"
      fill="#F9E532"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
