import dayjs from "dayjs";
import { MessageContentType } from "features/user/types";
import { ChatMessageItemContent } from "./ChatMessageItemContent";

const Avatar = (props: { src: string }) => {
  return (
    <div
      className="flex-col-center w-[56px] h-[56px] bg-white rounded-full mx-[16px] mt-[10px]"
      style={{
        filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
        transform: "translateZ(0)", // for safari
      }}
    >
      <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
        <img
          alt="ユーザプロフィール画像"
          src={props.src}
          key={props.src}
          className="w-full h-full object-contain"
        />
      </div>
    </div>
  );
};

export const ChatMessageItem = (props: {
  isFromUser: boolean;
  name: string;
  icon: string;
  messageContent: any;
  messageContentType: MessageContentType;
  created_at: string;
}) => {
  const {
    isFromUser,
    name,
    icon,
    messageContent,
    messageContentType,
    created_at,
  } = props;

  return (
    <div className="flex-row-view my-4">
      {isFromUser ? (
        <>
          <div className="flex-col-el relative flex-1">
            <div className="flex-row-view justify-end">
              <p className="text text-textcolor/black !text-right">{name}</p>
            </div>

            <div className="flex-row-view items-end justify-end">
              <p className="text text-textcolor/black">
                {dayjs(created_at, "YYYY-MM-DDTHH:mm:ssZ").format("HH:mm")}
              </p>
              <ChatMessageItemContent
                isFromUser={true}
                messageContentType={messageContentType}
                messageContent={messageContent}
              />
            </div>
          </div>

          <Avatar src={icon} />
        </>
      ) : (
        <>
          <Avatar src={icon} />

          <div className="flex-col-el relative flex-1">
            <div className="flex-row-view">
              <p className="text text-textcolor/black">{name}</p>
            </div>

            <div className="flex-row-view items-end">
              <ChatMessageItemContent
                isFromUser={false}
                messageContentType={messageContentType}
                messageContent={messageContent}
              />

              <p className="text text-textcolor/black">
                {dayjs(created_at, "YYYY-MM-DDTHH:mm:ssZ").format("HH:mm")}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
