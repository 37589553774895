import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippetByComponent } from "features/courses/program/game/blockly/uitls";

export const InitButtonClickBlock = () => {
  Blockly.Blocks["program_button_click_event_block"] = {
    init: function () {
      const components = [["ボタン１", "button_1"]];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_button_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          let target;
          let answerMark;
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          const targetImageIdPrefix = "course-program-image-";
          const targetLabelIdPrefix = "course-program-label-";
          const targetEles = ["Pineapple", "あ"];
          const result = [false, false];
          if(${result}){
            ${logic}
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitImageSetterBlock = () => {
  Blockly.Blocks["image_setter_block"] = {
    init: function () {
      const input = this.appendDummyInput();
      const components = [
        ["ボックス1", "1"],
        ["ボックス2", "2"],
      ];
      const options = [
        [
          {
            src: `${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
            width: 30,
            height: 30,
            alt: "Fragaria",
          },
          `Fragaria#${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
            width: 30,
            height: 30,
            alt: "Pineapple",
          },
          `Pineapple#${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          `Grape#${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
        ],
      ];
      input
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("を")
        .appendField(new Blockly.FieldDropdown(options), "Image")
        .appendField("にする");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour("#FFAB19");
    },
  };
  BlocklyJs["image_setter_block"] = function (block: Blockly.Block) {
    const selectedComponentValue = block.getField("Component").getValue();
    const selectedValue = block.getField("Image").getValue().split("#");
    const selectedImage = selectedValue[0];
    const selectedImageSrc = selectedValue[1];
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById(targetImageIdPrefix.concat('', ${selectedComponentValue}));
      if(target instanceof HTMLImageElement){
        target.src = "${selectedImageSrc}";
        target.style.opacity = "1";
        target.style.transform = "scale(1)";
      };
      result[Number(${selectedComponentValue}) - 1] = targetEles[Number(${selectedComponentValue}) - 1] === "${selectedImage}";
      await sleep(700);
      ${showAnswerSnippetByComponent(selectedComponentValue)}
      await sleep(700);
      highlightBlock("${block.id}", false);
    `;
  };
};

export const InitLabelTextSetterBlock = () => {
  Blockly.Blocks["label_text_setter_block"] = {
    max: 1,
    init: function () {
      this.jsonInit({
        type: "label_text_setter_block",
        message0: "%1を%2にする",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [
              ["ボックス1", "1"],
              ["ボックス2", "2"],
            ],
          },
          {
            type: "input_value",
            name: "value",
            check: "String",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "text_blocks",
      });
    },
  };
  BlocklyJs["label_text_setter_block"] = function (block: Blockly.Block) {
    const selectedLabelValue = block.getField("label").getValue();
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        target = document.getElementById(targetLabelIdPrefix.concat('', ${selectedLabelValue}));
        if(target instanceof HTMLParagraphElement){
          target.innerText = ${value};
          target.style.opacity = "1";
          target.style.transform = "scale(1)";
        };
        result[Number(${selectedLabelValue}) - 1] = targetEles[Number(${selectedLabelValue}) - 1] === ${value};
        await sleep(700);
        ${showAnswerSnippetByComponent(selectedLabelValue)}
        await sleep(700);
        highlightBlock("${block.id}", false);
    `;
  };
};
