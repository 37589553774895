import { SVGProps } from "react";

export const IconFavoritedHart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={61}
    height={53}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 16.252c0-1.683.344-3.35 1.016-4.907a12.736 12.736 0 0 1 2.906-4.164 13.253 13.253 0 0 1 4.37-2.766 13.612 13.612 0 0 1 5.154-.913h.069c2.135-.01 4.245.42 6.19 1.26 1.945.84 3.678 2.07 5.086 3.603L30.5 10.77l2.21-2.405a14.958 14.958 0 0 1 5.085-3.603l-1.19-2.754 1.19 2.754a15.414 15.414 0 0 1 6.19-1.26h.069a13.612 13.612 0 0 1 5.155.913 13.253 13.253 0 0 1 4.37 2.766 12.736 12.736 0 0 1 2.905 4.164 12.371 12.371 0 0 1 1.016 4.906c0 2.271-.354 4.002-1.13 5.684-.803 1.738-2.132 3.586-4.333 5.988-2.48 2.708-5.772 5.823-10.19 10.004-3.165 2.996-6.91 6.54-11.347 10.872-4.44-4.334-8.184-7.878-11.35-10.874-4.417-4.18-7.708-7.294-10.188-10.002-2.2-2.402-3.53-4.25-4.332-5.988-.777-1.681-1.13-3.412-1.13-5.683Z"
      fill="#FC6560"
      stroke="#FEC1BF"
      strokeWidth={6}
    />
  </svg>
);

export const IconFavoritedBigHart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 81 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.973 21.003v-.001c0-2.243.459-4.466 1.354-6.541a16.983 16.983 0 0 1 3.875-5.553 17.67 17.67 0 0 1 5.825-3.689 18.148 18.148 0 0 1 6.874-1.216h.092c2.846-.014 5.66.56 8.253 1.68a19.944 19.944 0 0 1 6.781 4.804l2.946 3.206 2.945-3.206A19.945 19.945 0 0 1 50.7 5.683a20.551 20.551 0 0 1 8.253-1.68h.093a18.148 18.148 0 0 1 6.873 1.216 17.67 17.67 0 0 1 5.826 3.69 16.983 16.983 0 0 1 3.874 5.551 16.496 16.496 0 0 1 1.355 6.542c0 3.028-.472 5.336-1.508 7.578-1.07 2.317-2.842 4.78-5.776 7.984-3.307 3.61-7.696 7.765-13.587 13.339-4.22 3.995-9.212 8.719-15.13 14.496-5.918-5.779-10.911-10.504-15.133-14.5-5.889-5.572-10.277-9.725-13.583-13.335-2.935-3.203-4.707-5.667-5.777-7.984-1.035-2.242-1.506-4.55-1.506-7.577Z"
      fill="#FC6560"
      stroke="#FEC1BF"
      strokeWidth={8}
    />
  </svg>
);
