import { useState, useEffect, useCallback } from "react";
import { useScale } from "common/utils";
import { TutorialArrow } from "common/assets";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const { scale } = useScale();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        "creator-published-project-tutorial-sample-app"
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 45 * scale,
        y: rect.y - 20 * scale,
        width: rect.height + 30 * scale,
        height: rect.height + 30 * scale,
      });
    }, 500);
  }, [scale]);

  const handleClick = useCallback(() => {
    const panel = document.getElementById(
      "creator-published-project-tutorial-sample-app"
    );
    panel.click();
    next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Overlay area={panelRect} onClick={handleClick}>
      <div
        className="absolute z-50"
        style={{
          width: 108 * scale,
          height: 105 * scale,
          transform: `translate(${
            panelRect.x + panelRect.width / 2 - 60 * scale
          }px, ${panelRect.y - 90 * scale}px) rotate(45deg)`,
        }}
      >
        <TutorialArrow />
      </div>
    </Overlay>
  );
};

export default Step;
