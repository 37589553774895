import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { btnDelay } from "common/utils";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";
import { ParentsUserProfile } from "./ParentsUserProfile";

export const ParentsSignUpUserProfile = (props: RouteProps) => {
  const { navigate } = props;
  const appUser = useSelector((state: RootState) => state.user.appUser);

  const handleGoBack = () => navigate("/");

  return (
    <div className="flex-col-center w-full h-full py-4 pb-0">
      <div className="flex-col-el w-[400px] h-[510px] gap-10">
        <div className="flex-col-el gap-8">
          <ParentsUserProfile user={appUser.main} />
        </div>

        <div className="flex-row-el gap-2">
          <div className="flex-row-el w-[250px]">
            <p className="text-body text-left text-textcolor/black text-font-size-body2">
              {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_EMAIL")}
            </p>
          </div>

          <div className="flex-row-el w-[180px]">
            <p className="text-body text-left text-textcolor/black text-font-size-body2">
              {appUser.main.email}
            </p>
          </div>
        </div>
      </div>

      <div className="flex-row-el items-center justify-between my-8 gap-10">
        <GoBackBtn handleGoBack={handleGoBack} />

        <div className="flex-row-el flex-center alpha">
          <button
            className="btn btn-primary"
            onPointerDown={() => btnDelay(() => navigate("/parentsUser"))}
          >
            <p className="text-body text-white text-[24px] !mb-[3px]">次へ</p>
          </button>
        </div>
      </div>
    </div>
  );
};
