import { btnDelay } from "common/utils";
import { CloseBtnSvg } from "./CloseBtnSvg";

export const CloseBtn = (props: { close?: () => void }) => {
  const handleClose = () => {
    if (props.close) {
      btnDelay(() => props.close());
    }
  };
  return (
    <div className="flex-col-el z-[100] absolute -top-[44px] -right-[44px]">
      <div className="flex-col-el flex-center relative upsilon-circle border-solid overflow-visible rounded-full border-[8px] border-white bg-white">
        <button
          className="btn btn-danger !m-0 !rounded-full !border-0 overflow-visible"
          onClick={handleClose}
        >
          <div className="flex-col-el flex-center relative">
            <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
              <CloseBtnSvg />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export const CloseBtnOuter = (props: { close: () => void }) => {
  const handleClose = () => {
    btnDelay(() => props.close());
  };
  return (
    <div className="flex-col-el z-[100] absolute top-0 right-0">
      <div className="flex-col-el flex-center relative upsilon-circle overflow-visible rounded-full">
        <button
          className="btn btn-danger !m-0 !rounded-full !border-0 overflow-visible"
          onClick={handleClose}
        >
          <div className="flex-col-el flex-center relative">
            <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
              <CloseBtnSvg />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
