import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { Panel } from "features/creator/panel";
import { actions } from "features/creator/slice";
import { getAppProjects } from "features/creator/api";
import { GuideBtnIcon } from "features/creator/assets";
import { ProjectPanel } from "features/creator/project";
import { FreebuildTitleSvg } from "features/home/assets";
import { RecommendPanel } from "features/creator/recommend";
import { SortedOrder, ProjectPanelType } from "features/creator/types";
import { ProjectTrashcan } from "features/creator/project/ProjectTrashcan";
import { TutorialMenu, CreatorTopTutorial } from "features/creator/tutorial";

export const CreatorTop = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const [tutorialStage, setTutorialStage] = useState(0);
  const [showTutorial, setShowTutorial] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser);

  useEffect(() => {
    dispatch(getAppProjects({ uid: user.active.uid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(actions.updateKeyword(""));
      dispatch(actions.updateSortFilterPanel(false));
      dispatch(
        actions.handleUpdatePublishedProjectFilter({
          type: "onlyOfficial",
          value: false,
        })
      );
      dispatch(
        actions.handleUpdatePublishedProjectFilter({
          type: "userFavorited",
          value: false,
        })
      );
      dispatch(actions.handleUpdateProjectSortedOrder(SortedOrder.DATE));
      dispatch(actions.updateProjectPanel(ProjectPanelType.SHARED));
    };
  }, [user.active.uid]);

  const handleShowTutorialMenu = () => {
    play();
    btnDelay(() => {
      dispatch(
        actions.updateTutorialMenu({ key: "creator_top_menu", value: true })
      );
    });
  };

  return (
    <div className="flex-col-el relative w-full h-full">
      <img
        alt="じゆうにつくる背景"
        src={`${Constants.assetHost}/assets/images/bg_creator.png`}
        className="absolute inset-0 object-cover w-full h-full z-[-1]"
      />

      <TutorialMenu
        setShowTutorial={setShowTutorial}
        setTutorialStage={setTutorialStage}
      />
      <CreatorTopTutorial
        isTutorialApp={searchParams.get("is_tutorial_app") === "true"}
        showTutorial={showTutorial}
        setShowTutorial={setShowTutorial}
        tutorialStage={tutorialStage}
      />

      <div
        className="flex-row-el flex-center relative w-full pointer-events-none"
        style={{
          marginTop: 16 * scale,
          height: 64 * scale,
        }}
      >
        <div
          className="flex-col-el flex-center absolute z-50 pointer-events-auto"
          style={{
            width: 321 * scale,
            height: 64 * scale,
          }}
        >
          <FreebuildTitleSvg />
        </div>
      </div>

      <div
        className="flex-col-el flex-center absolute shrink-0 w-[120px] origin-top-right cursor-pointer z-50"
        style={{
          marginTop: 32 * scale,
          right: 156 * scale,
          transform: `scale(${scale})`,
        }}
      >
        <button
          id="creator-top-guide-btn"
          className="opacity-action-button flex-col-el flex-center bg-transparent cursor-pointer"
          onPointerDown={handleShowTutorialMenu}
        >
          <div className="flex-col-el flex-center">
            <GuideBtnIcon />
          </div>
          <p className="text-body text-[18px] text-blue/100">
            {I18n.t("MSG_GUIDE")}
          </p>
        </button>
      </div>

      <div
        className="flex-row-el relative justify-between w-full h-full flex-1"
        style={{ marginTop: 60 * scale }}
      >
        <RecommendPanel />
        <Panel />
        <ProjectPanel />
        <ProjectTrashcan />
      </div>
    </div>
  );
};
