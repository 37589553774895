import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { getAuth, Unsubscribe } from "firebase/auth";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { parentsFirebase } from "app/Auth";
import { getEnv, actions } from "app/configSlice";
import { DialogType, LocalStorageKey } from "app/types";
import { createParentsAccount } from "features/user/api";

export const useParentsAccount = () => {
  const dispatch = useDispatch();
  const handleDialog = useDialog();
  const kidsUser = useSelector((state: RootState) => state.user.appUser);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    const auth = getAuth(parentsFirebase);
    try {
      unsubscribe = auth.beforeAuthStateChanged(async (user) => {
        try {
          dispatch(actions.updateResign(true));
          let email: string;
          if (
            user.providerData[0].providerId === "google.com" ||
            user.providerData[0].providerId === "apple.com"
          ) {
            email = user.providerData[0].email;
          } else {
            email = user.email;
          }
          const response = await createParentsAccount({
            user: {
              uid: user.uid,
              email: email,
              provider: user.providerData[0].providerId,
              display_name: I18n.t("MSG_PARENTS_PROFILE_DEFAULT_NAME"),
              icon: user.photoURL,
              locale: I18n.locale,
              env: getEnv(),
              // 新規登録のアカウントは自動的に子供ユーザーを追加するが、すでに登録しているアカウントは子供ユーザーの追加をスキップする。バックエンドで処理を行なっている
              kids_main_uid: kidsUser.main.uid,
            },
          });

          if (response.data.is_sign_up) {
            console.log("parents account sign up.");
          } else {
            console.log("parents account sign in.");
            localStorage.setItem(
              LocalStorageKey.DEVICE_KIDS_MAIN_USER,
              JSON.stringify(kidsUser.main)
            );
          }

          localStorage.setItem(
            LocalStorageKey.LAST_SIGN_AT,
            dayjs().unix().toString()
          );

          unsubscribe();
        } catch (error) {
          console.log(error);
          unsubscribe();
          handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
          await auth.signOut();
        }
      });
    } catch (error) {
      handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);
};
