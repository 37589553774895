import { SVGProps } from "react";

export const ProgressStep6 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={88} height={88} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.206.003C57.36.184 67.902 10.005 76.001 20.876c7.974 10.703 14.24 23.68 11.243 36.894C84.272 70.87 73.32 79.886 61.452 85.025c-10.975 4.752-22.92 3.473-33.83-1.442-11.67-5.257-23.307-12.912-26.6-25.778-3.41-13.325 2.21-26.96 10.323-37.82C19.561 8.986 30.886-.182 44.206.002Z"
      fill={props.fill}
    />
  </svg>
);
