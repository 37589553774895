import I18n from "i18n-js";
import { LatestLayout } from "features/creator/tutorial/top/stages/Layout";
import { TabletCapture } from "features/creator/tutorial/top/TabletCapture";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  return (
    <LatestLayout next={next} close={close}>
      <div className="flex-row-el items-center justify-start w-full p-0 gap-3">
        <div className="flex-col-el flex-center bg-orange/100 rounded-full border-solid border-[4px] border-orange/100 px-1 w-[32px] h-[32px]">
          <p className="text-body text-[22px] text-white leading-[48px]">4</p>
        </div>

        <div className="flex-row-el flex-center gap-1">
          <TabletCapture scale={0.05} />
          <p className="text-body text-[20px] text-textcolor/black">
            {I18n.locale === "ja" ? "タブレットの そうさ" : "タブレットの操作"}
          </p>
        </div>
      </div>
    </LatestLayout>
  );
};

export default Step;
