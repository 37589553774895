import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";
import { useParentsAccount } from "./hook";
import { LinkedAccountSSO } from "./LinkedAccountSSO";
import { MailSignInLinkedAccount } from "./MailSignInLinkedAccount";

export const ParentsSignInLinkedAccount = (props: RouteProps) => {
  useParentsAccount();
  const { navigate, location } = props;
  const url = new URL(`http://localhost${location}`);

  const handleGoBack = () => navigate("/parentsSign");

  return (
    <div className="flex-col-el items-center justify-around w-full h-full">
      <div className="flex-col-center gap-3 h-[510px]">
        {url.searchParams.get("provider") === "password" && (
          <MailSignInLinkedAccount
            email={url.searchParams.get("email")}
            navigate={navigate}
          />
        )}

        {url.searchParams.get("provider") !== "password" && (
          <div className="flex-col-center gap-6 w-[375px]">
            <LinkedAccountSSO
              provider={url.searchParams.get("provider")}
              email={url.searchParams.get("email")}
              navigate={navigate}
            />
          </div>
        )}
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
