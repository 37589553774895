import { useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState, AppDispatch } from "app/store";
import { ComponentManager } from "common/components";
import { useScale, usePlaySound } from "common/utils";
import {
  actions,
  selectScreenById,
  selectScreenIds,
  selectPropertyById,
} from "features/creator/slice";
import {
  NewBtnSvg,
  IconPageList,
  IconTopPageMark,
} from "features/creator/assets";
import { MAX_SCREENS } from "features/creator/constants";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType, CreatorOptions } from "features/creator/types";

export const RenderScreenComponent = (props: {
  id: string;
  zIndex: number;
}) => {
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, props.id)
  );
  const { id, typeId, property } = propertyEntity;
  const ChildComponent = useMemo(
    () => ComponentManager[typeId].component.DesignComponent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className="absolute top-0 left-0 cursor-grab"
      style={{
        zIndex: props.zIndex,
        backfaceVisibility: "hidden",
        width:
          property.style.layout.width +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        height:
          property.style.layout.height +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        transform: `translate(${property.style.transform.translateX}px, ${property.style.transform.translateY}px) rotate(${property.style.transform.rotation}deg)`,
        willChange: "transform",
        opacity: property.style.view.opacity / 100,
        filter:
          property.style.shadow.shadowRadius !== 0 &&
          `drop-shadow(${property.style.shadow.shadowOffset.width}px ${property.style.shadow.shadowOffset.height}px ${property.style.shadow.shadowRadius}px ${property.style.shadow.shadowColor})`,
      }}
    >
      <ChildComponent property={property} id={`screen-list-component-${id}`} />
    </div>
  );
};

const RenderItem = ({ item, selectedScreenId, handleUpdateSelectedScreen }) => {
  const screen = useSelector((state: RootState) =>
    selectScreenById(state, item)
  );
  const defaultScreenId = useSelector(
    (state: RootState) => state.creator.defaultScreenId
  );
  const screenProperty = useSelector((state: RootState) =>
    selectPropertyById(state, item)
  );

  return (
    <div className="flex-col-view items-center my-[2px]">
      <div
        className="flex-col-view p-[1px] border-[2px] mx-[5px] box-content border-solid"
        style={{
          borderColor:
            selectedScreenId === screen.id ? "#94C4F7" : "rgba(0,0,0,0)",
        }}
      >
        <div
          className="flex-col-view w-[78px] h-[58px] bg-white border-[1px] box-border border-solid border-[#85929E] cursor-pointer"
          onClick={handleUpdateSelectedScreen(screen.id)}
        >
          <div
            id={`screen-list-capture-${screen.id}`}
            className="flex-col-view w-[1024px] h-[768px] overflow-hidden origin-top-left"
            style={{
              transform: `scale(${76 / 1024}, ${56 / 768})`,
              backgroundColor:
                screenProperty.property.style.view.backgroundColor,
            }}
          >
            {screen.children.map((component, index) => (
              <RenderScreenComponent
                key={component.id}
                id={component.id}
                zIndex={screen.childrenOrder.indexOf(component.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex-row-center w-[98px] overflow-x-visible">
        <p className="text text-gray/100 !text-[16px] text-body-ellipsis w-[80px]">
          {screenProperty.property.name}
        </p>
      </div>
      <div className="flex-row-center !absolute left-0 top-[-8px]">
        {defaultScreenId === screen.id && <IconTopPageMark />}
      </div>
    </div>
  );
};

export const ScreenList = (props: { options: CreatorOptions }) => {
  const ITEM_HEIGHT = 91;
  const NEW_BTN_HEIGHT = 99;
  const HEADER_HEIGHT = 32;
  const play = usePlaySound();
  const { scale } = useScale();
  const { margin } = props.options;
  const handleAction = useActionCommand();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const screenIds = useSelector((state: RootState) => selectScreenIds(state));

  useEffect(() => {
    ref.current.scrollTop = screenIds.indexOf(selectedScreenId) * ITEM_HEIGHT;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScreenId]);

  const handleUpdateSelectedScreen = (id: string) => () => {
    play();
    dispatch(actions.updateSelectedScreenId(id));
    dispatch(actions.handleMaterialPanelCollapse(true));
    dispatch(actions.handlePropertyCollapse(true));
    dispatch(actions.handleComponentListCollapse(true));
  };

  const createScreen = () => {
    play();
    handleAction({ type: ActionCommandType.ADD_SCREEN });
    dispatch(actions.updateFocus());
  };

  return (
    <div
      id="screen-list-container"
      className="flex-col-view z-[1] !absolute"
      style={{
        left: margin * scale,
        top: 134 * scale,
        height:
          (screenIds.length * ITEM_HEIGHT +
            (screenIds.length < MAX_SCREENS ? NEW_BTN_HEIGHT : 0) +
            HEADER_HEIGHT) *
          scale,
        width: 110 * scale,
        backfaceVisibility: "hidden",
      }}
    >
      <div
        className="flex-col-center !justify-start origin-top-left drop-shadow-normal_show"
        style={{
          width: 110,
          height:
            screenIds.length * ITEM_HEIGHT +
            (screenIds.length < MAX_SCREENS ? NEW_BTN_HEIGHT : 0) +
            HEADER_HEIGHT,
          transform: `scale(${scale})`,
        }}
      >
        {/* HEADER */}
        <div className="flex-col-center z-10 w-full bg-gray2/60 rounded-t-[16px]">
          <p className="flex-row-center text text-white !text-[16px] !leading-[24px] !my-[4px]">
            <span className="inline-block w-[20px] h-[20px] mr-[2px]">
              <IconPageList />
            </span>
            {I18n.t("MSG_CREATOR_SCREEN_LIST_HEADER")}
          </p>
        </div>

        <div
          className="flex-col-center w-full pt-[8px] bg-gray2/20 rounded-b-[16px] border-solid border-[4px] !border-t-0 border-white origin-top-left"
          style={{
            height:
              (screenIds.length + (screenIds.length < MAX_SCREENS ? 1 : 0)) *
                ITEM_HEIGHT +
              24,
          }}
        >
          {/* SCREEN LIST */}
          <div ref={ref} className="flex-col-view mt-[12px] !flex-1">
            {screenIds.map((screendId) => (
              <RenderItem
                key={screendId}
                item={screendId}
                selectedScreenId={selectedScreenId}
                handleUpdateSelectedScreen={handleUpdateSelectedScreen}
              />
            ))}
          </div>

          {/* NEW BTN */}
          {screenIds.length < MAX_SCREENS && (
            <div
              id="screen-list-create-btn"
              className="flex-col-center w-[94px] h-[99px]"
            >
              <button
                className="flex-col-el border-none w-[72px] h-[64px] rounded-none p-0 bg-transparent cursor-pointer group"
                onClick={createScreen}
              >
                <div className="flex-col-center w-[72px] h-[64px]">
                  <NewBtnSvg />
                  <div className="flex-col-view w-[72px] h-[64px] !absolute top-0 left-0 rounded-[10px] group-disabled:bg-[rgba(1,1,1,0.3)]" />
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
