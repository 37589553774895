import { useEffect } from "react";
import { useSelector } from "react-redux";
import anime from "animejs";
import { RootState } from "app/store";

export const useTemplateAnimation = () => {
  const template = useSelector((state: RootState) => state.creator.template);

  useEffect(() => {
    if (template.newComponentIds.length > 0) {
      template.newComponentIds.forEach((componentId) => {
        const target = document.getElementById(
          `component-move-panel-${componentId}`
        );

        anime({
          targets: target,
          scale: [1.5, 1],
          opacity: [0.7, 1],
          duration: 400,
          easing: "easeInOutQuad",
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);
};
