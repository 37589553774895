import { useState, useEffect, PointerEvent } from "react";
import I18n from "i18n-js";
import { IconRotate, IconPlayerPlay } from "common/assets";

export const StartBtn = (props: {
  scale: number;
  disabled: boolean;
  start: () => void;
}) => {
  const { scale, disabled, start } = props;
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(false);
  }, []);

  const handleStart = (e: PointerEvent) => {
    if (!disable && !disabled && e.isPrimary) {
      setDisable(true);
      start();
    }
  };

  return (
    <div
      id="game-scene-start-btn"
      className="flex-col-el flex-center relative origin-top  pointer-events-auto"
      style={{
        bottom: 120 * scale,
        transform: `scale(${scale})`,
      }}
    >
      <div className="flex-row-el flex-center w-[320px] relative alpha">
        <button
          className="btn btn-primary"
          onPointerDown={handleStart}
          disabled={disabled}
        >
          <div className="flex-row-center gap-1 mb-[3px]">
            <div className="flex-col-center w-[40px] h-[40px]">
              <IconPlayerPlay color="white" width={2} />
            </div>

            <div className="flex-col-center">
              <p className="text-body text-white text-[36px]">
                {I18n.t("MSG_COURSE_GAME_START_BTN")}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export const ResetBtn = (props: { scale: number; reset: () => void }) => {
  const { scale, reset } = props;
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(false);
  }, []);

  const handleReset = (e: PointerEvent) => {
    if (!disable && e.isPrimary) {
      setDisable(true);
      reset();
    }
  };

  return (
    <div
      className="flex-col-el flex-center relative origin-top  pointer-events-auto"
      style={{
        bottom: 120 * scale,
        transform: `scale(${scale})`,
      }}
    >
      <div className="flex-row-el flex-center w-[320px] relative alpha">
        <button className="btn btn-danger" onPointerDown={handleReset}>
          <div className="flex-row-center gap-1 mb-[3px]">
            <div className="flex-col-center w-[40px] h-[40px]">
              <IconRotate color="white" width={2} />
            </div>

            <div className="flex-col-center">
              <p className="text-body text-white text-[36px]">
                {I18n.t("MSG_COURSE_GAME_RESET_BTN")}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
