import { useState } from "react";
import i18n from "i18n-js";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { VerticalText } from "common/elements";
import { CourseMessageContent } from "features/courses/types";
import { HintIcon, HintKeyPoint } from "features/courses/assets";

export const GuidePage = ({
  stage,
  step,
  courseDisplay,
}: {
  stage: number;
  step: number;
  courseDisplay: CourseMessageContent;
}) => {
  const { height, scale } = useScale();
  const [collapse, setCollapse] = useState(true);
  const hint = courseDisplay ? courseDisplay.level[step - 1].hint : undefined;

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const handleOnTransitionEnd = () => {
    const stamp = document.getElementById("course-program-level-clear-stamp");
    if (stamp) {
      if (collapse) {
        stamp.style.zIndex = "10102";
      } else {
        stamp.style.zIndex = "0";
      }
    }
  };

  const COLLAPSE_BTN_WIDTH = 50;
  const GUIDE_CONTENTS_WIDTH = 844;
  const GUIDE_WIDTH = GUIDE_CONTENTS_WIDTH + 250;

  return (
    <div
      onTransitionEnd={handleOnTransitionEnd}
      className="flex-col-el absolute top-0 z-[9998] pointer-events-auto h-full transition-transform duration-500"
      style={{
        width: GUIDE_WIDTH * scale,
        right: -GUIDE_WIDTH * scale,
        transform: `translateX(${collapse ? 0 : -GUIDE_WIDTH * scale}px)`,
      }}
    >
      {/* button */}
      <div
        className="flex-col-view z-[100] cursor-pointer pointer-events-auto !absolute"
        style={{
          left: -COLLAPSE_BTN_WIDTH * scale,
          width: COLLAPSE_BTN_WIDTH * scale,
          top: 200 * scale,
          height: 200 * scale,
          filter: "drop-shadow(-1px 1px 2px rgba(63, 74, 97, 0.4))",
        }}
        onClick={handleCollapse}
      >
        <div
          id="guide-btn"
          className="flex-col-view h-[200px] items-center origin-top-left  bg-gray2/20 border-white rounded-l-[15px] border-solid border-[5px] border-r-0 p-[10px]"
          style={{
            width: COLLAPSE_BTN_WIDTH,
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-center w-[32px] h-[32px] mb-[10px]">
            <HintIcon />
          </div>

          <VerticalText
            text={i18n.t("MSG_COURSE_GAME_HINT_BTN")}
            style={{
              fontSize: 24,
              lineHeight: "24px",
              fontFamily: "OtomanopeeOne-Regular",
            }}
          />
        </div>
      </div>

      {/* コンテンツ */}
      <div
        className="flex-col-el items-center justify-start h-full"
        style={{ width: GUIDE_WIDTH * scale }}
      >
        <img
          alt="*"
          className="flex-col-el w-full h-full absolute top-0 left-0 z-[-1] object-cover"
          src={`${Constants.assetHost}/assets/images/bg_course_guide.png`}
        />

        <div
          className="flex-col-view items-center justify-start origin-top !flex-1 w-full"
          style={{
            marginTop: 24 * scale,
            height: height / scale,
            transform: `scale(${scale})`,
          }}
        >
          {/* だいじなところ */}
          <div className="flex-row-view items-center w-[844px] bg-yellow/alt rounded-[10px]">
            <div className="flex-col-center w-[168px] h-[78px] bg-green/alt2 rounded-l-[10px]">
              <HintKeyPoint />
            </div>
            <div className="flex-row-center w-[674px] px-[8px] flex-wrap">
              <p className="text-body text-[20px] text-textcolor/black text-left flex-1 w-full">
                {hint?.keypoints[0]}
              </p>
            </div>
          </div>

          {/* 説明文 */}
          <div
            className="flex-row-view items-center my-8"
            style={{ width: GUIDE_CONTENTS_WIDTH }}
          >
            <div className="flex-col-el items-start flex-1 w-full flex-wrap">
              {hint?.description.map((content, index) => (
                <p
                  key={index}
                  className="text-body text-[20px] text-textcolor/black w-full text-left leading-[30px] !mb-[12px]"
                >
                  {content}
                </p>
              ))}
            </div>
          </div>

          {/* キャプチャ */}
          <div className="flex-col-view w-[844px] h-[600px]">
            <img
              alt="*"
              className="flex-col-view object-contain w-full h-full"
              src={`${Constants.assetHost}/assets/program/guide/stage${stage}/step${step}.png`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
