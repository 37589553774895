import { PayloadAction } from "@reduxjs/toolkit";
import { CreatorInitialState } from "features/creator/types";
import { HISTORY_STACK_MAX } from "features/creator/constants";
import { blocklyAdapter } from "features/creator/slice/reducers/adapter";

const reducers = {
  updateBlockly(
    state: CreatorInitialState,
    action: PayloadAction<{
      screenId: string;
      xmlText: string;
    }>
  ) {
    const { screenId, xmlText } = action.payload;
    const blockly = blocklyAdapter
      .getSelectors()
      .selectById(state.blockly, action.payload.screenId);

    if (blockly) {
      blocklyAdapter.updateOne(state.blockly, {
        id: screenId,
        changes: { xmlText: xmlText },
      });
    } else {
      blocklyAdapter.addOne(state.blockly, {
        screenId: screenId,
        xmlText: xmlText,
      });
    }

    if (blockly?.xmlText !== xmlText) {
      state.undoStacks.push({
        before: {
          blockly: blockly,
          properties: [],
        },
        after: {
          blockly: { screenId: screenId, xmlText: xmlText },
          properties: [],
        },
      });
      if (state.undoStacks.length > HISTORY_STACK_MAX) {
        state.undoStacks.shift();
      }
      state.redoStacks = [];
    }
  },
};

export default reducers;
