import { SVGProps } from "react";

export const IconBgArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={82}
    height={80}
    fill="none"
    {...props}
  >
    <g filter="url(#icon-bg-arrow-a)">
      <path
        fill="#F2DA00"
        fillRule="evenodd"
        d="M55.199 22.195 40.006 7 8 39l32.006 32L55.2 55.805s-3.571-1.608-6.784-4.968L74 52V26l-25.585 1.163c3.213-3.36 6.784-4.968 6.784-4.968Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="icon-bg-arrow-a"
        width={82.001}
        height={80}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5167_84693"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
        <feBlend
          in2="effect1_dropShadow_5167_84693"
          result="effect2_dropShadow_5167_84693"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend
          in2="effect2_dropShadow_5167_84693"
          result="effect3_dropShadow_5167_84693"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect3_dropShadow_5167_84693"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
