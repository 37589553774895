import { useRef, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import { RootState } from "app/store";
import { getAllParentsKidsUserConfigs } from "app/api";
import { UserConfig, LocalStorageKey } from "app/types";
import { WarningMessageBox } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";
import { AppUser, AccountType, BillingType } from "features/user/types";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";
import { UserStatus } from "./UserStatus";
import { UserSetting } from "./UserSetting";
import { DeviceUsers } from "./DeviceUsers";
import { UserDisplayName } from "./UserDisplayName";
import { CreateParentsKidsUser } from "./CreateParentsKidsUser";

export type UserConfigs = {
  [uid: string]: UserConfig;
};

export const ParentsUser = (props: RouteProps) => {
  const { navigate } = props;
  const play = usePlaySound();
  const ref = useRef<HTMLDivElement>(null);
  const handleGoBack = () => navigate("/");
  const [device, setDevice] = useState(false);
  const [maxWarning, setMaxWarning] = useState(false);
  const [userConfigs, setUserConfigs] = useState<UserConfigs>({});
  const appUser = useSelector((state: RootState) => state.user.appUser);
  const [selectedUser, setSelectedUser] = useState<AppUser>(appUser.active);
  const sortedUsers = useMemo(
    () =>
      appUser.users
        .slice()
        .filter((u) => u.account_type !== AccountType.PARENTS)
        .sort((a, b) => (dayjs(a.created_at) < dayjs(b.created_at) ? -1 : 1)),
    [appUser]
  );
  const deviceKidsMainUser: AppUser = useMemo(() => {
    const deviceKidsMainUserStr = localStorage.getItem(
      LocalStorageKey.DEVICE_KIDS_MAIN_USER
    );
    return deviceKidsMainUserStr
      ? JSON.parse(deviceKidsMainUserStr)
      : undefined;
  }, [sortedUsers.length]);

  useEffect(() => {
    getAllParentsKidsUserConfigs({
      uid: appUser.main.uid,
    }).then((response) => setUserConfigs(response.data.data.user_configs));
  }, [appUser]);

  useEffect(() => {
    if (appUser.active) {
      setDevice(false);
      setSelectedUser(
        appUser.users.filter((u) => u.uid === selectedUser?.uid).shift() ??
          appUser.active
      );
    }
  }, [appUser]);

  const handleCloseWarning = () => {
    play();
    btnDelay(() => {
      setMaxWarning(false);
    });
  };

  return (
    <div className="flex-col-center w-full h-full p-4 pb-0">
      {maxWarning && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t(
            "MSG_SETTING_PARENTS_ADD_DEVICE_USER_MAX_WARNING_TITLE"
          )}
          message={I18n.t(
            "MSG_SETTING_PARENTS_ADD_DEVICE_USER_MAX_WARNING_MESSAGE"
          )}
          close={handleCloseWarning}
        />
      )}

      <div className="flex-row-el w-full flex-grow overflow-hidden gap-2">
        <div className="flex-col-el justify-start">
          <div className="flex-col-el items-center justify-start min-w-[362px] invisible-scrollbar">
            {sortedUsers.map((user) => (
              <div
                key={user.uid}
                onClick={() => {
                  play();
                  setDevice(false);
                  setSelectedUser(user);
                  if (ref.current) {
                    ref.current.scrollTop = 0;
                  }
                }}
                className={`flex-row-el items-center justify-start relative p-4 rounded-2xl cursor-pointer ${
                  selectedUser?.uid === user.uid
                    ? "bg-green/60"
                    : "bg-transparent"
                }`}
              >
                <div
                  className="flex-col-center bg-white rounded-full w-[80px] h-[80px]"
                  style={{
                    boxShadow:
                      "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                  }}
                >
                  <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                    <img
                      key={user.icon}
                      alt="ユーザプロフィール画像"
                      src={user.icon}
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>

                <div className="flex-col-el w-[250px] px-4">
                  <p className="text-body text-left text-textcolor/black text-font-size-body2-narrow w-full text-body-ellipsis">
                    {user.display_name}
                  </p>
                  <p className="text-body text-left text-gray/80 text-font-caption2-narrow">
                    {I18n.t("MSG_SETTING_PARENTS_USER_KIDS_PROFILE_CREATED_AT")}
                    :{dayjs(user.created_at).format("YYYY-MM-DD")}
                  </p>
                  <p className="text-body text-left text-gray/80 text-font-caption2-narrow">
                    {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_LATEST_AT")}:
                    {dayjs(user.last_sign_in_time).format("YYYY-MM-DD")}
                  </p>
                </div>
              </div>
            ))}

            {appUser.main.billing_type !== BillingType.EDUCATION &&
              deviceKidsMainUser &&
              appUser.users.filter((u) => u.uid === deviceKidsMainUser.uid)
                .length === 0 && (
                <div
                  onClick={() => {
                    play();
                    setDevice(true);
                    setSelectedUser(null);
                  }}
                  className={`flex-row-el items-center justify-start p-4 rounded-2xl cursor-pointer ${
                    device ? "bg-green/60" : "bg-transparent"
                  }`}
                >
                  <div
                    className="flex-col-center bg-white rounded-full w-[80px] h-[80px]"
                    style={{
                      boxShadow:
                        "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                    }}
                  >
                    <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                      <RememberMeIcon sx={{ fontSize: 50, color: "gray" }} />
                    </div>
                  </div>
                  <div className="flex-col-el w-[250px] px-4">
                    <p className="text-body text-left text-textcolor/black text-font-size-body2-narrow w-full text-body-ellipsis">
                      {I18n.t(
                        "MSG_SETTING_PARENTS_USER_PROFILE_ADD_DEVICE_USER"
                      )}
                    </p>
                  </div>
                </div>
              )}

            {appUser.main.billing_type !== BillingType.EDUCATION && (
              <CreateParentsKidsUser />
            )}
          </div>
        </div>

        {selectedUser && (
          <div className="flex-col-center flex-1 gap-5 bg-white/60 rounded-2xl">
            <div
              ref={ref}
              className="flex-col-el visible-scrollbar w-full py-14"
            >
              <UserDisplayName selectedUser={selectedUser} />

              <UserStatus selectedUser={selectedUser} />

              <UserSetting
                selectedUser={selectedUser}
                userConfigs={userConfigs}
                setUserConfigs={setUserConfigs}
              />
            </div>
          </div>
        )}

        {device && deviceKidsMainUser && (
          <div className="flex-col-center flex-1 gap-5 bg-white/60 rounded-2xl">
            <div className="flex-col-el visible-scrollbar w-full py-14">
              <DeviceUsers
                uid={deviceKidsMainUser.uid}
                setMaxWarning={setMaxWarning}
              />
            </div>
          </div>
        )}
      </div>

      <div className="flex-row-center my-2">
        <GoBackBtn handleGoBack={handleGoBack} />
      </div>
    </div>
  );
};
