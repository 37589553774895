import { SVGProps } from "react";

export const IconHart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#icon-hart-a)">
      <path
        d="M7 16.334c0-.794.16-1.58.471-2.314.312-.733.77-1.4 1.346-1.96a6.111 6.111 0 0 1 2.019-1.298 6.19 6.19 0 0 1 2.377-.428h.048a7.081 7.081 0 0 1 2.883.597c.906.398 1.715.98 2.373 1.708L20 14.279l1.483-1.64a6.983 6.983 0 0 1 2.373-1.708 7.081 7.081 0 0 1 2.883-.596h.048a6.19 6.19 0 0 1 2.377.427 6.111 6.111 0 0 1 2.018 1.298 5.995 5.995 0 0 1 1.347 1.96c.311.734.471 1.52.471 2.314 0 1.102-.168 1.914-.524 2.698-.373.82-1.002 1.717-2.087 2.92-1.232 1.367-2.858 2.93-5.05 5.037-1.497 1.44-3.258 3.132-5.339 5.19a495.045 495.045 0 0 0-5.34-5.19c-2.192-2.108-3.818-3.67-5.05-5.037-1.085-1.203-1.713-2.1-2.086-2.92C7.168 18.25 7 17.436 7 16.334Z"
        stroke="#FC6560"
        strokeWidth={4}
        fill={props.fill}
      />
    </g>
  </svg>
);
