import { useSelector } from "react-redux";
import Blockly from "blockly";
import { RootState } from "app/store";
import { useBlocklyInit } from "./initial";

export const BlocklyWorkspace = ({
  scale,
  workspace,
  setWorkspace,
}: {
  scale: number;
  workspace: Blockly.WorkspaceSvg;
  setWorkspace: (workspace: any) => void;
}) => {
  const config = useSelector((state: RootState) => state.config.userConfig);

  useBlocklyInit(scale, config.locale, workspace, setWorkspace);

  return (
    <div className="flex-row-center !items-start !flex-1 w-full">
      <div id="blocklyDiv" className="flex-col-view !flex-1 w-full h-full" />
    </div>
  );
};
