import I18n from "i18n-js";
import produce from "immer";
import { usePlaySound } from "common/utils";
import { OPACITY_MIN, OPACITY_MAX, ComponentTypeIds } from "common/components";
import {
  InputRotate,
  ImagePicker,
  InputOpacity,
  ItemContainer,
  InputBorderRadius,
} from "features/creator/property/components";
import { PropertyProps } from "features/creator/property/types";

export const AppearanceProperty = (props: PropertyProps) => {
  const play = usePlaySound();
  const { scale, updateProperty } = props;
  const { id, typeId, property } = props.propertyEntity;

  const handleChangeOpacity = (value: number) => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.opacity = value;
    });
    updateProperty(id, newProperty);
  };
  const handleOpacityReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.opacity = draft.style.defaultView.opacity;
    });
    updateProperty(id, newProperty);
    play();
  };

  const rotate =
    property.style.transform.radius < 0
      ? ((2 * Math.PI + property.style.transform.radius) * 180) / Math.PI
      : (property.style.transform.radius * 180) / Math.PI;
  const handleChangeRotate = (value: number) => {
    const newProperty = produce(property, (draft) => {
      const rotation = value > 180 ? value - 360 : value;
      draft.style.transform.radius = (rotation * Math.PI) / 180;
      draft.style.transform.rotation = `${rotation}`;
    });
    updateProperty(id, newProperty);
  };

  const handleRotateReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.transform.radius = 0;
      draft.style.transform.rotation = "0";
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeBorderRadius = (radius: number) => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.borderRadius = radius;
    });
    updateProperty(id, newProperty);
  };
  const handleBorderRadiusReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.borderRadius = draft.style.defaultView.borderRadius;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleImageRest = () => {
    play();
    const newProperty = produce(property, (draft) => {
      draft.style.image.name = draft.style.defaultImage.name;
      draft.style.image.size = draft.style.defaultImage.size;
      draft.style.image.uri = draft.style.defaultImage.uri;
      draft.style.image.width = draft.style.defaultImage.width;
      draft.style.image.height = draft.style.defaultImage.height;
      draft.style.defaultLayout.height = draft.style.defaultLayout.width;
      draft.style.layout.height = draft.style.defaultLayout.height;
    });
    updateProperty(id, newProperty);
  };

  const handlePickImage = ({
    name,
    size,
    width,
    height,
    uri,
  }: {
    name: string;
    size: number;
    uri: string;
    width: number;
    height: number;
  }) => {
    const newProperty = produce(property, (draft) => {
      const ratio = width / height;
      draft.style.image.name = name;
      draft.style.image.size = size;
      draft.style.image.uri = uri;
      draft.style.image.width = width;
      draft.style.image.height = height;
      draft.style.defaultLayout.height =
        draft.style.defaultLayout.width / ratio;
      draft.style.layout.height = draft.style.layout.width / ratio;
    });
    updateProperty(id, newProperty);
  };

  return (
    <div className="flex-row-center !flex-1 !justify-start">
      <ItemContainer
        name={I18n.t("MSG_CREATOR_MENU_PROPERTY_OPACITY")}
        scale={scale}
        width={256}
        height={308}
        onRest={handleOpacityReset}
      >
        <InputOpacity
          name={I18n.t("MSG_CREATOR_MENU_PROPERTY_OPACITY")}
          min={OPACITY_MIN}
          max={OPACITY_MAX}
          value={property.style.view.opacity}
          play={play}
          onChange={handleChangeOpacity}
        />
      </ItemContainer>
      <ItemContainer
        name={I18n.t("MSG_CREATOR_MENU_PROPERTY_ROTATION")}
        scale={scale}
        width={256}
        height={308}
        onRest={handleRotateReset}
      >
        <InputRotate
          name={I18n.t("MSG_CREATOR_MENU_PROPERTY_ROTATION")}
          show={true}
          play={play}
          scale={scale}
          max={360}
          value={rotate}
          onChange={handleChangeRotate}
        />
      </ItemContainer>

      {property.style.view.borderRadius !== undefined && (
        <ItemContainer
          name={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER_RADIUS")}
          scale={scale}
          width={256}
          height={308}
          onRest={handleBorderRadiusReset}
        >
          <InputBorderRadius
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER_RADIUS")}
            min={0}
            max={100}
            value={property.style.view.borderRadius}
            play={play}
            onChange={handleChangeBorderRadius}
          />
        </ItemContainer>
      )}

      {typeId === ComponentTypeIds.IMAGE && (
        <ItemContainer
          name={I18n.t("MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO")}
          scale={scale}
          width={256}
          height={308}
          right={0}
          onRest={handleImageRest}
        >
          <ImagePicker
            play={play}
            name={property.style.image.name}
            size={property.style.image.size}
            width={property.style.image.width}
            height={property.style.image.height}
            url={property.style.image.uri}
            onChange={handlePickImage}
          />
        </ItemContainer>
      )}
    </div>
  );
};
