import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { useScale } from "common/utils";
import { IconLeft, IconClose, TutorialArrow } from "common/assets";
import { CloseBtnOuter, FadeTransition } from "common/elements";
import {
  Overlay,
  GuideMessageBalloonNormal,
} from "features/creator/tutorial/top/stages/Layout";

export const TutorialHint = (props: {
  back: () => void;
  close: () => void;
}) => {
  const { back, close } = props;
  const { width, height, scale } = useScale();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-top-guide-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 10 * scale,
        y: rect.y - 20 * scale,
        width: rect.width + 20 * scale,
        height: rect.width + 20 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <div
          className="absolute z-50"
          style={{
            width: 108 * scale,
            height: 105 * scale,
            transform: `translate(${panelRect.x}px, ${
              panelRect.y + 100 * scale
            }px) rotate(-135deg)`,
          }}
        >
          <TutorialArrow />
        </div>
      </Overlay>

      <Dialog
        open
        fullScreen
        maxWidth={false}
        sx={{ zIndex: 10000 }}
        TransitionComponent={FadeTransition}
        componentsProps={{
          backdrop: {
            style: {
              opacity: 0.35,
              backgroundColor: "transparent",
              pointerEvents: "none",
            },
          },
        }}
        PaperProps={{
          style: {
            overflow: "visible",
            margin: 0,
            maxWidth: "none",
            maxHeight: "none",
            transform: `scale(${scale})`,
            backgroundColor: "transparent",
          },
          elevation: 0,
        }}
        transitionDuration={{ enter: 1000, exit: 500 }}
      >
        <div className="flex-col-center w-full h-full">
          <div
            className="flex-col-el flex-center absolute top-0 left-0"
            style={{
              transform: `translate(${width / 2 - 504 / 2 + 100}px, ${
                height / 2 - 320 / 2 - 110
              }px)`,
            }}
          >
            <div className="flex-col-el absolute top-4 right-4">
              <CloseBtnOuter close={close} />
            </div>

            <div className="flex-col-el">
              <GuideMessageBalloonNormal />
            </div>

            <div className="flex-col-el flex-center w-full h-full gap-8 absolute">
              <div className="flex-row-el flex-center mt-12">
                <div className="flex-col-el items-start justify-center gap-4 w-[322px]">
                  <div className="flex-row-center bg-orange/40 rounded-lg w-full py-1">
                    <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
                      {I18n.locale === "ja"
                        ? "ガイドを しゅうりょうします"
                        : "ガイドを終了します"}
                    </p>
                  </div>

                  <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
                    {I18n.locale === "ja"
                      ? "ガイドは いつでも\nここから みることが できます。"
                      : "ガイドはいつでも\nここから見ることができます。"}
                  </p>
                </div>
              </div>
              <div className="flex-row-el flex-center gap-4 w-full mb-6">
                <div className="flex-row-el flex-center upsilon">
                  <button onClick={back} className="btn btn-blue !m-0 !px-6">
                    <div className="flex-row-el flex-center relative mb-[3px] gap-1">
                      <div className="flex-col-center w-[32px] h-[32px]">
                        <IconLeft />
                      </div>
                      <p className="text-body text-white text-[20px]">
                        {I18n.t("MSG_BACK_BTN")}
                      </p>
                    </div>
                  </button>
                </div>
                <div className="flex-row-el flex-center upsilon">
                  <button
                    className="btn btn-primary !m-0 !px-6"
                    onClick={close}
                  >
                    <div className="flex-row-el flex-center relative mb-[3px] gap-1">
                      <div className="flex-col-center w-[32px] h-[32px]">
                        <IconClose />
                      </div>
                      <p className="text-body text-white text-[20px]">
                        {I18n.t("MSG_CREATOR_TOP_TUTORIAL_TIPS_CLOSE_BTN")}
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
