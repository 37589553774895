import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getAuth,
  OAuthProvider,
  UserCredential,
  GoogleAuthProvider,
  signInWithCredential,
  getAdditionalUserInfo,
} from "firebase/auth";
import I18n from "i18n-js";
import AppleIcon from "@mui/icons-material/Apple";
import { useDialog } from "app/hook";
import { parentsFirebase } from "app/Auth";
import { DialogType, AppPlatform } from "app/types";
import Constants from "common/constant";
import { ConfirmMessageBox } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";
import { GoogleSignNa } from "../assets";

export const SSO = ({
  signIn,
  navigate,
}: {
  signIn: boolean;
  navigate: (path: string) => void;
}) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [update, setUpdate] = useState(false);
  const nonce = Math.random().toString(36).substring(2, 10);

  const handleParentUser = (userCredential: UserCredential) => {
    handleDialog({ type: DialogType.LOADING, value: false });
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    if (additionalUserInfo.isNewUser) {
      toast(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_SIGN_UP_SUCCESS_CONTENT")}
          </p>
        </div>
      );
      navigate("/parentsSignUpUserProfile");
    } else {
      toast(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_SIGN_IN_SUCCESS_CONTENT")}
          </p>
        </div>
      );
      navigate("/parentsUser");
    }
  };

  const handleError = (error: any) => {
    handleDialog({ type: DialogType.LOADING, value: false });
    console.error(`sign error: ${JSON.stringify(error)}`);
  };

  const handleFirebaseGoogleSignIn = async (idToken: string) => {
    const firebaseAuth = getAuth(parentsFirebase);
    const credential = GoogleAuthProvider.credential(idToken);
    signInWithCredential(firebaseAuth, credential)
      .then(handleParentUser)
      .catch(handleError);
  };

  const handleGoogleCredentialResponse = (response) => {
    handleFirebaseGoogleSignIn(response.credential);
  };

  const handleFirebaseAppleSignIn = async (idToken: string) => {
    const firebaseAuth = getAuth(parentsFirebase);
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    const credential = provider.credential({
      idToken: idToken,
      rawNonce: nonce,
    });
    signInWithCredential(firebaseAuth, credential)
      .then(handleParentUser)
      .catch(handleError);
  };

  const handleWebAppleSignInSuccess = (event) => {
    console.log(`web apple sign in success: ${JSON.stringify(event)}`);
    handleFirebaseAppleSignIn(event.detail.authorization.id_token);
  };

  const handleWebAppleSignInFailure = (event) => {
    handleDialog({ type: DialogType.LOADING, value: false });
    console.log(`web apple sign in success: ${JSON.stringify(event)}`);
  };

  const handleMessage = (message) => {
    const { command, data } = JSON.parse(message.data);
    switch (command) {
      case "signIn":
        const { method } = data;
        switch (method) {
          case "apple":
            if (data.idToken) {
              handleFirebaseAppleSignIn(data.idToken);
            } else {
              handleError(data.result);
            }
            break;
          case "google":
            if (data.idToken) {
              handleFirebaseGoogleSignIn(data.idToken);
            } else {
              handleError(data.result);
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const init_apple_sign = async () => {
      const encoder = new TextEncoder();
      const encodedNonce = encoder.encode(nonce);
      const hashBuffer = await crypto.subtle.digest("SHA-256", encodedNonce);
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashedNonce = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

      //@ts-ignore
      AppleID.auth.init({
        clientId: "com.inexus.app.services.parents",
        scope: "name email",
        redirectURI: import.meta.env.VITE_PUBLIC_URL,
        state: "kids",
        nonce: hashedNonce,
        usePopup: true,
      });
    };

    if ("ReactNativeWebView" in window) {
      if (window.kidsAppPlatform === "ios") {
        window.addEventListener("message", handleMessage);
      } else {
        document.addEventListener("message", handleMessage);
      }
    } else {
      init_apple_sign();

      document.addEventListener(
        "AppleIDSignInOnSuccess",
        handleWebAppleSignInSuccess
      );

      document.addEventListener(
        "AppleIDSignInOnFailure",
        handleWebAppleSignInFailure
      );
      //@ts-ignore
      google.accounts.id.initialize({
        client_id: Constants.googleSignClientId,
        callback: handleGoogleCredentialResponse,
      });
      //@ts-ignore
      google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
        type: "standard",
        theme: "outline",
        size: "large",
        width: "375",
        logo_alignment: "left",
        locale: "ja",
        text: "continue_with",
      });
    }

    return () => {
      if ("ReactNativeWebView" in window) {
        if (window.kidsAppPlatform === "ios") {
          window.removeEventListener("message", handleMessage);
        } else {
          document.removeEventListener("message", handleMessage);
        }
      } else {
        document.removeEventListener(
          "AppleIDSignInOnSuccess",
          handleWebAppleSignInSuccess
        );

        document.removeEventListener(
          "AppleIDSignInOnFailure",
          handleWebAppleSignInFailure
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAppleSign = async () => {
    play();
    if (window.ReactNativeWebView) {
      if (Number(window.kidsBuildVersion) > 88) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "signIn",
            data: {
              method: "apple",
            },
          })
        );
      } else {
        setUpdate(true);
      }
    }
  };

  const handleGoogleSign = () => {
    play();
    if ("ReactNativeWebView" in window) {
      if (
        window.kidsAppPlatform === AppPlatform.iOS &&
        Number(window.kidsBuildVersion) > 90
      ) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "signIn",
            data: {
              method: "google",
            },
          })
        );
      } else if (
        window.kidsAppPlatform === AppPlatform.Android &&
        Number(window.kidsBuildVersion) > 70
      ) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "signIn",
            data: {
              method: "google",
            },
          })
        );
      } else if (
        window.kidsAppPlatform === AppPlatform.Amazon &&
        Number(window.kidsBuildVersion) > 64
      ) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "signIn",
            data: {
              method: "google",
            },
          })
        );
      } else {
        setUpdate(true);
      }
    }
  };

  const handleGotoUpgrade = () => {
    play();
    if (window.kidsAppPlatform === AppPlatform.iOS) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "openLink",
          data: {
            url: "https://apps.apple.com/jp/app/id1642655214",
          },
        })
      );
    } else if (window.kidsAppPlatform === AppPlatform.Android) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "openLink",
          data: {
            url:
              "https://play.google.com/store/apps/details?id=com.inexus.app.kids",
          },
        })
      );
    } else if (window.kidsAppPlatform === AppPlatform.Amazon) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "openLink",
          data: {
            url:
              "http://www.amazon.co.jp/gp/mas/dl/android?p=com.inexus.app.kids",
          },
        })
      );
    }
    setUpdate(false);
  };
  const handleCloseUpgrade = () => {
    play();
    btnDelay(() => setUpdate(false));
  };

  return (
    <>
      {update && (
        <ConfirmMessageBox
          title={I18n.t("MSG_APP_VERSION_UPDATE_WARNING_TITLE")}
          message={I18n.t("MSG_APP_VERSION_UPDATE_WARNING_MESSAGE")}
          close={handleCloseUpgrade}
          confirm={handleGotoUpgrade}
          is_parents
        />
      )}

      {window.ReactNativeWebView &&
      window.kidsAppPlatform === AppPlatform.iOS ? (
        <div
          onClick={handleAppleSign}
          className="flex-row-el flex-center w-full h-[40px] bg-[#000] rounded-lg cursor-pointer"
        >
          <AppleIcon sx={{ fontSize: 16, marginRight: 0.5, color: "white" }} />
          <p className="text-body text-[16px] text-white">
            {signIn
              ? I18n.t("MSG_SIGN_IN_WITH_APPLE")
              : I18n.t("MSG_SIGN_UP_WITH_APPLE")}
          </p>
        </div>
      ) : (
        <div
          className="w-full h-[40px] cursor-pointer"
          id="appleid-signin"
          data-color="black"
          data-border="true"
          data-mode="center-align"
          data-logo-size="large"
          data-type="continue"
        />
      )}

      {window.ReactNativeWebView ? (
        <div
          onClick={handleGoogleSign}
          className="flex-row-el flex-center relative w-full h-[42px] bg-[#fff] rounded-md border-solid border-[1px] border-[#dadce0] cursor-pointer"
        >
          <div className="flex-col-el absolute top-0 left-0">
            <GoogleSignNa />
          </div>
          <div className="flex-col-el w-[40px] h-[40px]" />
          <p className="text-body text-[16px] text-textcolor/black">
            {I18n.t("MSG_SIGN_IN_WITH_GOOGLE")}
          </p>
        </div>
      ) : (
        <div id="buttonDiv"></div>
      )}
    </>
  );
};
