/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Howl } from "howler";
import * as Blockly from "blockly";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { Loading } from "common/elements";
import {
  TabIndex,
  ProjectType,
  CreatorOptions,
  GroupingDefault,
  DisplayMode,
} from "features/creator/types";
import { ConfigContext } from "features/top";
import { actions } from "features/creator/slice";
import { Material } from "features/creator/material";
import { DragLayer } from "features/creator/dragLayer";
import { ScreenList } from "features/creator/screenList";
import { DrawingToolBar } from "features/creator/drawing";
import { BlocklyWorkspace } from "features/creator/blockly";
import { getUserProjectContent } from "features/creator/api";
import { AppMenu, TemplateMenu } from "features/creator/menu";
import { CreatorTutorialApp } from "features/creator/tutorial/top";
import { ComponentPreview } from "features/creator/componentPreview";
import { Property, TemplateProperty } from "features/creator/property";
import { DesignPanel, TemplateDesignPanel } from "features/creator/design";
import { useTemplateAnimation } from "features/creator/useTemplateAnimation";
import { ControlPanel, TemplateControlPanel } from "features/creator/control";

const Basic = (props: { projectId: string; onLoaded: () => void }) => {
  useTemplateAnimation();
  const dispatch = useDispatch();
  const { projectId, onLoaded } = props;
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);
  const options: CreatorOptions = {
    margin: 16,
    headerHeight: 155,
    componentListWidth: 300,
    tabHeight: 74,
    projectId: projectId,
  };
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.NONE);

  useEffect(() => {
    const root = document.body;

    const newNode = document.createElement("p");
    newNode.id = "compute-text-length";
    newNode.style.opacity = "0";

    const newDivNode = document.createElement("div");
    newDivNode.style.position = "absolute";
    newDivNode.style.top = "0";
    newDivNode.style.left = "0";
    newDivNode.style.zIndex = "-10000";
    newDivNode.appendChild(newNode);

    const computeComponentRectNode = document.createElement("div");
    computeComponentRectNode.id = "compute-component-rect";
    computeComponentRectNode.style.opacity = "0";
    computeComponentRectNode.style.position = "absolute";
    computeComponentRectNode.style.top = "0";
    computeComponentRectNode.style.left = "0";
    computeComponentRectNode.style.zIndex = "-10000";
    computeComponentRectNode.style.width = "1024px";
    computeComponentRectNode.style.height = "768px";

    root.appendChild(newDivNode);
    root.appendChild(computeComponentRectNode);

    onLoaded();

    return () => {
      root.removeChild(newDivNode);
      root.removeChild(computeComponentRectNode);
    };
  }, []);

  const handleHideChaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target && workspace) {
      var target = e.target as HTMLElement;
      while (target) {
        const className = target.getAttribute("class");
        if (className !== null && className.indexOf("blocklySelected") !== -1) {
          return;
        }
        target = target.parentElement;
      }
      workspace.hideChaff(true);
    }
    dispatch(actions.updateGrouping(GroupingDefault));
  };

  return (
    <div className="flex-col-el w-full h-full" onPointerDown={handleHideChaff}>
      <img
        alt="じゆうにつくる背景"
        src={`${Constants.assetHost}/assets/images/bg_creator.png`}
        className="absolute inset-0 object-cover w-full h-full z-[-1]"
      />

      <DragLayer />
      <ScreenList options={options} />
      <Material options={options} />
      <DrawingToolBar options={options} />
      <BlocklyWorkspace
        options={options}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        workspace={workspace}
        setWorkspace={setWorkspace}
      />
      <DesignPanel options={options} />
      <Property options={options} />
      <ComponentPreview options={options} />
      <ControlPanel options={options} />
      <AppMenu />
    </div>
  );
};

const Template = (props: { projectId: string; onLoaded: () => void }) => {
  const dispatch = useDispatch();
  const { projectId, onLoaded } = props;
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);
  const options: CreatorOptions = {
    margin: 16,
    headerHeight: 155,
    componentListWidth: 300,
    tabHeight: 74,
    projectId: projectId,
  };
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.NONE);

  useEffect(() => {
    const root = document.body;

    const newNode = document.createElement("p");
    newNode.id = "compute-text-length";
    newNode.style.opacity = "0";

    const newDivNode = document.createElement("div");
    newDivNode.style.position = "absolute";
    newDivNode.style.top = "0";
    newDivNode.style.left = "0";
    newDivNode.style.zIndex = "-10000";
    newDivNode.appendChild(newNode);

    const computeComponentRectNode = document.createElement("div");
    computeComponentRectNode.id = "compute-component-rect";
    computeComponentRectNode.style.opacity = "0";
    computeComponentRectNode.style.position = "absolute";
    computeComponentRectNode.style.top = "0";
    computeComponentRectNode.style.left = "0";
    computeComponentRectNode.style.zIndex = "-10000";
    computeComponentRectNode.style.width = "1024px";
    computeComponentRectNode.style.height = "768px";

    root.appendChild(newDivNode);
    root.appendChild(computeComponentRectNode);

    onLoaded();

    return () => {
      root.removeChild(newDivNode);
      root.removeChild(computeComponentRectNode);
    };
  }, []);

  const handleHideChaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target && workspace) {
      var target = e.target as HTMLElement;
      while (target) {
        const className = target.getAttribute("class");
        if (className !== null && className.indexOf("blocklySelected") !== -1) {
          return;
        }
        target = target.parentElement;
      }
      workspace.hideChaff(true);
    }
    dispatch(actions.updateGrouping(GroupingDefault));
  };

  return (
    <div className="flex-col-el w-full h-full" onPointerDown={handleHideChaff}>
      <img
        alt="じゆうにつくる背景"
        src={`${Constants.assetHost}/assets/images/bg_creator.png`}
        className="absolute inset-0 object-cover w-full h-full z-[-1]"
      />

      <DragLayer />
      <Material options={options} />
      <DrawingToolBar options={options} />
      <BlocklyWorkspace
        options={options}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        workspace={workspace}
        setWorkspace={setWorkspace}
      />
      <TemplateDesignPanel options={options} />
      <TemplateProperty options={options} />
      <ComponentPreview options={options} />
      <TemplateControlPanel options={options} />
      <TemplateMenu />
    </div>
  );
};

export const CreatorMain = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [isFirst, setIsFirst] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const configContext = useContext(ConfigContext);
  const [bgm, setBgm] = useState<Howl | null>(null);
  const { id: project_id } = useParams<{ id?: string }>();
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      dispatch(actions.updateSelectedProjectId(null));
      dispatch(actions.updateDisplayMode(DisplayMode.NONE));
      setTimeout(() => navigate(`/creator`), 300);
    }
  }, [user.active.uid]);

  useEffect(() => {
    dispatch(actions.updateCapture(false));
    dispatch(actions.updateEditingProjectId({ project_id: project_id }));
    dispatch(
      getUserProjectContent({
        project_id,
      })
    )
      .then(unwrapResult)
      .then(() => setLoadData(false));
  }, []);

  const onLoaded = () => {
    setLoading(false);
  };

  useEffect(() => {
    if ("AudioContext" in window) {
      const bgmSound = new Howl({
        mute: !config.bgm.enable,
        volume: config.bgm.volume,
        autoplay: false,
        loop: true,
        src: [`${Constants.assetHost}/assets/bgm/bgm_game.mp3`],
      });
      setBgm(bgmSound);
    }
  }, []);

  useEffect(() => {
    if (bgm) {
      bgm.mute(!config.bgm.enable);
      bgm.volume(config.bgm.volume);
    }
  }, [config]);

  useEffect(() => {
    if (configContext.bgmSound && bgm) {
      bgm.play();
      configContext.bgmSound.stop();
    }
    return () => {
      if (configContext.bgmSound && bgm) {
        bgm.stop();
        configContext.bgmSound.play();
      }
    };
  }, [configContext.bgmSound, bgm]);

  if (loadData) {
    return <Loading />;
  }

  switch (searchParams.get("type")) {
    case ProjectType.BASIC:
      return (
        <>
          {loading && <Loading />}
          {searchParams.get("is_tutorial_app") && <CreatorTutorialApp />}
          <Basic projectId={project_id} onLoaded={onLoaded} />
        </>
      );

    case ProjectType.TEMPLATE:
      return (
        <>
          {loading && <Loading />}
          <Template projectId={project_id} onLoaded={onLoaded} />
        </>
      );
    default:
      return (
        <>
          {loading && <Loading />}
          {searchParams.get("is_tutorial_app") && <CreatorTutorialApp />}
          <Basic projectId={project_id} onLoaded={onLoaded} />
        </>
      );
  }
};
