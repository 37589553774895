import {
  useRef,
  useMemo,
  useState,
  useEffect,
  MouseEventHandler,
  ChangeEventHandler,
} from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { userIconPrefixes } from "app/assets";
import { Asset, DialogType } from "app/types";
import { RootState, AppDispatch } from "app/store";
import { useDialog, usePickImageAsset } from "app/hook";
import Constants from "common/constant";
import { IconArrowLeft, IconArrowRight } from "common/assets";
import { DialogMessage, FadeTransition } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { actions } from "features/user/slice";
import { CheckMark } from "features/user/assets";
import { ASSET_LIMIT } from "features/creator/constants";
import { getUserAssets, updateUserProfile } from "features/user/api";

enum UserIconCategory {
  ALL = "ALL",
  BOY = "BOY",
  GIRL = "GIRL",
}

enum TabIndex {
  SYSTEM = "SYSTEM",
  ALBUM = "ALBUM",
  DRAWING = "DRAWING",
}

export const UserProfileIconStock = ({
  visible,
  icon,
  setIcon,
  close,
}: {
  visible: boolean;
  icon: string;
  setIcon: (icon: string) => void;
  close: (reset: boolean) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const ref = useRef<HTMLDivElement>();
  const pickImageAsset = usePickImageAsset();
  const dispatch = useDispatch<AppDispatch>();
  const inputRef = useRef<HTMLInputElement>();
  const [warning, setWarning] = useState(false);
  const [tab, setTab] = useState(TabIndex.SYSTEM);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [category, setCategory] = useState(UserIconCategory.ALL);
  const user = useSelector((state: RootState) => state.user.appUser);
  const { data: assets } = useQuery<Asset[]>({
    queryKey: ["creator/getUserAssets", user.active.uid],
    queryFn: () => getUserAssets({ uid: user.active.uid }),
    staleTime: Infinity,
    placeholderData: [],
  });
  const defaultAssets = useSelector((state: RootState) => state.config.assets);

  const target = useMemo(
    () =>
      assets.filter((a) =>
        tab === TabIndex.DRAWING ? /.svg/.test(a.url) : !/.svg/.test(a.url)
      ),
    [assets, tab]
  );

  useEffect(() => {
    setTimeout(() => {
      const iconEle = document.getElementById("user-page-profile-icon");
      if (iconEle) {
        const rect = iconEle.getBoundingClientRect();
        setPosition({
          x: rect.left - 120 * scale,
          y: rect.bottom,
        });
      }
    }, 300);
  }, [scale, visible]);

  const handleSelectCategory = (category: UserIconCategory) => () => {
    play();
    setCategory(category);
  };

  const handleChangeIcon = (icon: string) => () => {
    play();
    setIcon(icon);
  };

  const handleTab = (tab: TabIndex) => () => {
    play();
    setTab(tab);
  };

  const handleScroll = (direction: number) => () => {
    play();
    if (direction > 0) {
      // 右へ
      if (ref.current) {
        ref.current.scrollLeft += 120 * scale;
      }
    } else {
      // 左へ
      if (ref.current) {
        ref.current.scrollLeft -= 120 * scale;
      }
    }
  };

  const pickImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files[0];
    if (file) {
      await pickImageAsset(
        file,
        user.active.uid,
        async (asset: Asset) => {
          setIcon(asset.url);
          inputRef.current.value = "";
        },
        () => {
          inputRef.current.value = "";
        }
      );
    }
  };

  const handleSelectAsset = (
    asset: Asset
  ): MouseEventHandler<HTMLImageElement> => async (event) => {
    play();
    event.stopPropagation();
    setIcon(asset.url);
  };
  const handleClose = () => {
    setTimeout(() => close(true), 300);
  };

  const handleOpenLimitWarning = () => {
    btnDelay(() => {
      play();
      setWarning(true);
    });
  };
  const handleCloseLimitWarning = () => {
    btnDelay(() => {
      play();
      setWarning(false);
    });
  };

  const handleConfirm = () => {
    handleDialog({ type: DialogType.LOADING, value: true });
    btnDelay(() => {
      updateUserProfile({
        uid: user.active.uid,
        userInfo: { icon },
      })
        .then(() => {
          dispatch(
            actions.updateUserProfile({
              ...user.active,
              icon,
            })
          );
          toast(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_TOAST_EDIT_SUCCESS")}
              </p>
            </div>
          );
          handleDialog({ type: DialogType.LOADING, value: false });
        })
        .catch((error) => {
          console.error(error);
          handleDialog({ type: DialogType.LOADING, value: false });
        });
    });
  };

  return (
    <Dialog
      fullScreen
      open={visible && position.y !== 0}
      maxWidth={false}
      sx={{ zIndex: 99999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <DialogMessage
        open={warning}
        title={I18n.t("MSG_CREATOR_ASSET_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_ASSET_LIMIT_WARNING_MESSAGE")}
        close={handleCloseLimitWarning}
      />
      <div className="flex-col-el flex-center relative w-full h-full">
        <div
          className="flex-col-el flex-center absolute top-0 left-0 origin-top-left"
          style={{
            transform: `translateX(${position.x}px) translateY(${position.y}px) translateZ(0) scale(${scale})`,
          }}
        >
          <div
            className="flex-col-el flex-center relative m-h-[900px]"
            style={{
              filter:
                "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
            }}
          >
            <div className="flex-row-el items-start w-full h-full p-2 relative border-solid border-white border-8 rounded-lg bg-beige/60">
              <div
                className="absolute w-0 h-0 border-solid border-x-transparent border-b-white"
                style={{
                  top: -40,
                  left: 150,
                  borderRightWidth: 40,
                  borderLeftWidth: 40,
                  borderTopWidth: 0,
                  borderBottomWidth: 40,
                }}
              />
              <div
                className="absolute w-0 h-0 border-solid border-x-transparent border-b-beige/60"
                style={{
                  top: -25,
                  left: 163,
                  borderRightWidth: 26,
                  borderLeftWidth: 26,
                  borderTopWidth: 0,
                  borderBottomWidth: 26,
                }}
              />

              <div className="flex-col-el h-full">
                <div className="flex-col-el bg-gray2/20 rounded-l-2xl border-4 border-r-0 border-solid border-white overflow-hidden">
                  <div
                    onClick={handleTab(TabIndex.SYSTEM)}
                    className={`flex-col-el flex-center w-[120px] h-[80px] gap-1 overflow-hidden cursor-pointer ${
                      tab === TabIndex.SYSTEM
                        ? "bg-green/altcolor"
                        : "bg-transparent"
                    }`}
                  >
                    <span className="material-symbols-outlined text-[32px]">
                      account_circle
                    </span>
                    <p className="text-body text-textcolor/black text-font-caption2-narrow">
                      {I18n.t("MSG_CREATOR_MENU_ASSET_CATEGORY_SYSTEM_TITLE")}
                    </p>
                  </div>
                  <div
                    onClick={handleTab(TabIndex.ALBUM)}
                    className={`flex-col-el flex-center w-[120px] h-[80px] gap-1 overflow-hidden cursor-pointer ${
                      tab === TabIndex.ALBUM
                        ? "bg-green/altcolor"
                        : "bg-transparent"
                    }`}
                  >
                    <span className="material-symbols-outlined text-[24px]">
                      photo_library
                    </span>
                    <p className="text-body text-textcolor/black text-font-caption2-narrow">
                      {I18n.t("MSG_CREATOR_MENU_ASSET_CATEGORY_ALBUM_TITLE")}
                    </p>
                  </div>
                  <div
                    onClick={handleTab(TabIndex.DRAWING)}
                    className={`flex-col-el flex-center w-[120px] h-[80px] gap-1 overflow-hidden cursor-pointer ${
                      tab === TabIndex.DRAWING
                        ? "bg-green/altcolor"
                        : "bg-transparent"
                    }`}
                  >
                    <span className="material-symbols-outlined text-[24px]">
                      edit_square
                    </span>
                    <p className="text-body text-textcolor/black text-font-caption2-narrow">
                      {I18n.t("MSG_CREATOR_MENU_ASSET_CATEGORY_DRAWING_TITLE")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex-col-el w-[900px]">
                <div className="flex-col-el items-start w-full h-[248px] p-3 bg-white rounded-r-xl">
                  {tab === TabIndex.SYSTEM && (
                    <div className="flex-row-el flex-center">
                      <div className="flex-row-el gap-3 upsilon">
                        <button
                          className={`btn ${
                            category === UserIconCategory.ALL
                              ? "btn-primary-outlined-active"
                              : "btn-primary-outlined"
                          }`}
                          onClick={handleSelectCategory(UserIconCategory.ALL)}
                        >
                          <p className="text-body text-center text-[20px]">
                            {I18n.t("MSG_USER_PROFILE_FIELD_GENDER_ALL")}
                          </p>
                        </button>

                        <button
                          className={`btn ${
                            category === UserIconCategory.BOY
                              ? "btn-primary-outlined-active"
                              : "btn-primary-outlined"
                          }`}
                          onClick={handleSelectCategory(UserIconCategory.BOY)}
                        >
                          <p className="text-body text-center text-[20px]">
                            {I18n.t("MSG_USER_PROFILE_FIELD_GENDER_MALE")}
                          </p>
                        </button>

                        <button
                          className={`btn ${
                            category === UserIconCategory.GIRL
                              ? "btn-primary-outlined-active"
                              : "btn-primary-outlined"
                          }`}
                          onClick={handleSelectCategory(UserIconCategory.GIRL)}
                        >
                          <p className="text-body text-center text-[20px]">
                            {I18n.t("MSG_USER_PROFILE_FIELD_GENDER_FEMALE")}
                          </p>
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="flex-row-el flex-center relative w-full h-full">
                    <div className="flex-row-el flex-center upsilon">
                      <button
                        className="btn btn-primary w-[40px] h-[72px]"
                        onClick={handleScroll(-1)}
                      >
                        <div className="flex-col-center mb-[2px]">
                          <IconArrowLeft />
                        </div>
                      </button>
                    </div>

                    <div
                      ref={ref}
                      className="flex-row-el w-full bg-white items-center justify-start pt-4 gap-4 z-10 pointer-events-auto visible-scrollbar"
                    >
                      {tab === TabIndex.ALBUM ? (
                        <div className="flex-col-el flex-center shrink-0 upsilon">
                          <div className="btn btn-primary !p-0 !m-0 w-[160px] h-[160px] cursor-pointer bg-green/100 rounded-lg">
                            <div className="flex-col-center w-full h-full">
                              <input
                                ref={inputRef}
                                type="file"
                                onClick={() => play()}
                                onChange={pickImage}
                                accept="image/*"
                                className="absolute w-[160px] h-[160px] opacity-0 z-50 peer cursor-pointer"
                              />
                              <AddPhotoAlternateIcon
                                sx={{ fontSize: 84, color: "white" }}
                              />
                              <p className="text-body text-white text-font-size-body2">
                                {I18n.t("MSG_ASSET_ALBUM_ADD_TITLE")}
                              </p>
                            </div>
                          </div>
                          <div className="w-full h-[20px]" />
                        </div>
                      ) : (
                        <></>
                      )}
                      {tab === TabIndex.SYSTEM
                        ? userIconPrefixes.reduce(
                            (pre, cur, currentIndex) => [
                              ...pre,
                              ...defaultAssets[cur]
                                .filter((asset) =>
                                  category === UserIconCategory.ALL
                                    ? true
                                    : asset.category === category
                                )
                                .map(
                                  (originalIcon) =>
                                    originalIcon.fileSize > 0 && (
                                      <div
                                        key={
                                          originalIcon.fileKey + currentIndex
                                        }
                                        className="flex-col-center w-[120px] h-[120px] m-[10px] cursor-pointer"
                                        onClick={handleChangeIcon(
                                          `${Constants.assetHost}/${originalIcon.fileKey}`
                                        )}
                                      >
                                        {`${Constants.assetHost}/${originalIcon.fileKey}` ===
                                          user.active.icon && (
                                          <div className="flex-col-el absolute top-[-24px] left-[-24px]">
                                            <CheckMark />
                                          </div>
                                        )}

                                        <img
                                          key={`${Constants.assetHost}/${originalIcon.fileKey}`}
                                          alt="ユーザプロフィール画像"
                                          src={`${Constants.assetHost}/${originalIcon.fileKey}`}
                                          className={`w-full h-full object-contain border-solid ${
                                            `${Constants.assetHost}/${originalIcon.fileKey}` ===
                                            icon
                                              ? " border-8 border-green/100/40"
                                              : " border-4 border-gray/80/40"
                                          }`}
                                        />
                                      </div>
                                    )
                                ),
                            ],
                            []
                          )
                        : target
                            .sort((a, b) =>
                              dayjs(a.created_at) < dayjs(b.created_at) ? 1 : -1
                            )
                            .map((asset, index) => (
                              <div
                                key={asset.id}
                                className="flex-col-el flex-center relative w-[160px]"
                              >
                                {asset.url === user.active.icon && (
                                  <div className="flex-col-el absolute top-[-14px] left-[-14px] z-10">
                                    <CheckMark />
                                  </div>
                                )}
                                <div
                                  className={`flex-col-el relative flex-center w-[160px] h-[160px] bg-beige/20 p-2 border-solid ${
                                    index + 1 > ASSET_LIMIT
                                      ? "border-8 border-gray2/60"
                                      : asset.url === icon
                                      ? "border-8 border-green/100/40"
                                      : "border-8 border-transparent"
                                  }`}
                                >
                                  <img
                                    alt="*"
                                    loading={index > 16 ? "lazy" : "eager"}
                                    onClick={handleSelectAsset(asset)}
                                    className="w-full h-full object-contain bg-white cursor-pointer"
                                    src={asset.url}
                                  />
                                  {index + 1 > ASSET_LIMIT && (
                                    <div
                                      onClick={handleOpenLimitWarning}
                                      className="flex-col-el absolute w-full h-full z-10 bg-gray2/60 opacity-50 top-0 left-0 cursor-pointer"
                                    />
                                  )}
                                </div>
                                <div className="flex-col-center px-5 w-full">
                                  <p className="text-body text-textcolor/black text-font-caption2 h-[20px] w-full text-body-ellipsis text-center">
                                    {asset.name}
                                  </p>
                                </div>
                              </div>
                            ))}
                    </div>
                    {tab !== TabIndex.SYSTEM && target.length === 0 && (
                      <div className="flex-col-el flex-center w-full h-full absolute z-10 pointer-events-none">
                        <p className="text-body text-gray/80 text-font-caption2-narrow">
                          {tab === TabIndex.ALBUM
                            ? I18n.t("MSG_ASSET_ALBUM_EMPTY_MESSAGE")
                            : I18n.t("MSG_ASSET_DRAWING_EMPTY_MESSAGE")}
                        </p>
                      </div>
                    )}

                    <div className="flex-row-el flex-center upsilon">
                      <button
                        className="btn btn-primary w-[40px] h-[72px]"
                        onClick={handleScroll(1)}
                      >
                        <div className="flex-col-center mb-[2px]">
                          <IconArrowRight />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex-row-el flex-center alpha mt-2 gap-3">
                  <button onClick={handleClose} className="btn btn-blue">
                    <div className="flex-row-center gap-1 mb-[3px]">
                      <span className="material-symbols-outlined text-[36px]">
                        close
                      </span>
                      <p className="text-body text-white text-font-size-body1">
                        {I18n.t("MSG_CLOSE_BTN")}
                      </p>
                    </div>
                  </button>

                  <button
                    onClick={handleConfirm}
                    className="btn btn-primary"
                    disabled={user.active.icon === icon}
                  >
                    <div className="flex-row-center gap-1 mb-[3px]">
                      <span className="material-symbols-outlined text-[36px] text-white">
                        check
                      </span>
                      <p className="text-body text-white text-font-size-body1">
                        {I18n.t("MSG_CONFIRM_BTN")}
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
