import Constants from "common/constant";

export const Step1 = () => {
  return (
    <div className="flex-row-view w-full h-full">
      <div className="flex-row-view mt-[30px] w-full h-[400px]">
        <div className="flex-col-center w-[514px] h-full ml-[39px] mr-[48px]">
          <p className="text text-textcolor/black">
            「まなんでパズル」へようこそ！
            <br />
            <br />
            「まなんでパズル」では、
            <br />
            プログラミングの基礎を学習する
            <br />「<span className="text text-safe">コースでまなぶ</span>」と、
            <br />
            <br />
            自由にアプリを作ることができる
            <br />「<span className="text text-safe">じゆうにつくる</span>」の
            <br />
            <br />
            2つのコンテンツをお楽しみいただけます。
          </p>
        </div>
        <div className="flex-row-center w-[400px] h-full">
          <img
            alt="チュートリアルキャラクター"
            src={`${Constants.assetHost}/assets/images/duck_hello.png`}
            className="w-[237px] h-[400px]"
          />
        </div>
      </div>
    </div>
  );
};
