import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { TileMapType } from "./TileMapType";
import { TILE_TYPE_NOTHING } from "./tilesets";

export const checkMapTitleType = (
  map: TileMapType,
  offset: Vector3,
  position: Vector3,
  direction: Vector3
) => {
  const { width, height, tileSize, landscape } = map;
  const positionX = Math.floor(position.x);
  const positionY = Math.floor(position.y);
  const positionZ = Math.round(position.z);
  const currentPostion = new Vector3(
    positionX + 1 - (positionX % 2),
    positionY,
    positionZ + 1 - (positionZ % 2)
  );
  const l = Math.floor((currentPostion.y - offset.y) / tileSize);
  try {
    const currentTileType =
      landscape[l][(currentPostion.z - offset.z) / tileSize][
        width - (currentPostion.x - offset.x) / tileSize - 1
      ];

    const nextStepXIndex =
      width - (currentPostion.x - offset.x) / tileSize - 1 - direction.x;
    const nextStepZIndex =
      (currentPostion.z - offset.z) / tileSize + direction.z;
    if (
      nextStepXIndex < 0 ||
      nextStepXIndex > width - 1 ||
      nextStepZIndex < 0 ||
      nextStepZIndex > height - 1
    ) {
      return [currentTileType, null, null, null, null];
    }

    const nextStepSameLayerTileType =
      landscape[l][nextStepZIndex][nextStepXIndex];

    const nextStepLowLayerTileType =
      landscape[l - 1][nextStepZIndex][nextStepXIndex];

    let currentHightLayerTileType: number;
    let nextStepHightLayerTileType: number;
    if (l + 1 < landscape.length) {
      currentHightLayerTileType =
        landscape[l + 1][(currentPostion.z - offset.z) / tileSize][
          width - (currentPostion.x - offset.x) / tileSize - 1
        ];
      nextStepHightLayerTileType =
        landscape[l + 1][nextStepZIndex][nextStepXIndex];
    } else {
      currentHightLayerTileType = TILE_TYPE_NOTHING;
      nextStepHightLayerTileType = TILE_TYPE_NOTHING;
    }

    return [
      currentTileType,
      currentHightLayerTileType,
      nextStepSameLayerTileType,
      nextStepLowLayerTileType,
      nextStepHightLayerTileType,
    ];
  } catch (error) {
    console.warn({
      width,
      height,
      tileSize,
      layer: l,
      landscape: JSON.stringify(landscape),
      position: JSON.stringify({ x: position.x, y: position.y, z: position.z }),
      currentPostion: JSON.stringify({
        x: currentPostion.x,
        y: currentPostion.y,
        z: currentPostion.z,
      }),
      offset: JSON.stringify({ x: offset.x, y: offset.y, z: offset.z }),
      direction: JSON.stringify({
        x: direction.x,
        y: direction.y,
        z: direction.z,
      }),
    });
    console.error(JSON.stringify(error));
    return [null, null, null, null, null];
  }
};

export const checkBtnColor = (
  map: TileMapType,
  offset: Vector3,
  position: Vector3
) => {
  const { width, tileSize, landscape } = map;
  const positionX = Math.floor(position.x);
  const positionY = Math.floor(position.y);
  const positionZ = Math.floor(position.z);
  const currentPostion = new Vector3(
    positionX + 1 - (positionX % 2),
    positionY,
    positionZ + 1 - (positionZ % 2)
  );
  const l = Math.floor((currentPostion.y - offset.y) / tileSize);

  try {
    const currentTileType =
      landscape[l][(currentPostion.z - offset.z) / tileSize][
        width - (currentPostion.x - offset.x) / tileSize - 1
      ];

    switch (currentTileType) {
      case 23:
        return "#71cdfc";
      case 24:
        return "#fb3a69";
      case 25:
        return "#fdd73e";
      default:
        return null;
    }
  } catch (error) {
    console.warn({
      width,
      tileSize,
      layer: l,
      landscape: JSON.stringify(landscape),
      position: JSON.stringify({ x: position.x, y: position.y, z: position.z }),
      currentPostion: JSON.stringify({
        x: currentPostion.x,
        y: currentPostion.y,
        z: currentPostion.z,
      }),
      offset: JSON.stringify({ x: offset.x, y: offset.y, z: offset.z }),
    });
    console.error(JSON.stringify(error));
    return null;
  }
};
