import React from "react";
import { ResetBtn } from ".";

export const ItemContainer = (props: {
  name: string;
  scale: number;
  width: number;
  height: number;
  top?: number | undefined;
  left?: number | undefined;
  right?: number | undefined;
  bottom?: number | undefined;
  onRest?: () => void | undefined;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | null;
}) => {
  const {
    name,
    scale,
    width,
    height,
    top,
    left,
    right,
    bottom,
    onRest,
    children,
  } = props;
  const HEADER_HEIGHT = 32;

  return (
    <div
      className="flex-col-view"
      style={{
        width: width * scale,
        height: height * scale,
        marginTop: top !== undefined ? top * scale : 12 * scale,
        marginLeft: left !== undefined ? left * scale : 12 * scale,
        marginRight: right !== undefined ? right * scale : 12 * scale,
        marginBottom: bottom !== undefined ? bottom * scale : 12 * scale,
      }}
    >
      <div
        style={{
          width: width,
          height: height,
          transformOrigin: "top left",
          transform: `scale(${scale})`,
        }}
      >
        <div
          className="flex-row-view justify-start bg-beige/alt rounded-t-[12px]"
          style={{ width: width, height: HEADER_HEIGHT }}
        >
          <p className="text text-white !leading-[30px] !ml-[16px]">{name}</p>
        </div>
        {onRest && (
          <div
            className="flex-row-view items-center justify-end bg-white"
            style={{ width: width, height: HEADER_HEIGHT }}
          >
            <button
              className="opacity-button w-[24px] h-[24px] mr-[4px]"
              onClick={onRest}
            >
              <ResetBtn />
            </button>
          </div>
        )}
        <div
          className="flex-col-center rounded-b-[12px] bg-white"
          style={{
            width: width,
            height:
              height -
              (onRest === undefined ? HEADER_HEIGHT : HEADER_HEIGHT * 2),
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
