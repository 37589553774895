import { useRef } from "react";
import { useDrop } from "react-dnd";
import { Vector2D } from "common/types";
import { ComponentTypeIds, ComponentCategory } from "common/components";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType } from "features/creator/types";

export const DropLayer = (props: {
  scale: number;
  size: Vector2D;
  screenId: string;
}) => {
  const MARGIN = 15;
  const ref = useRef<HTMLDivElement>();
  const handleAction = useActionCommand();
  const { scale, size, screenId } = props;

  const [{ canDrop }, drop] = useDrop(
    () => ({
      accept: Object.values(ComponentTypeIds),
      drop: (
        item: { type: ComponentTypeIds; category: ComponentCategory },
        monitor
      ) => {
        if (ref.current) {
          const { x: mouseX, y: mouseY } = monitor.getClientOffset();
          const {
            x: screenX,
            y: screenY,
          } = ref.current.getBoundingClientRect();
          // 理論的にはマウスがスクリーン（アプリ画面）にある相対位置を計算しているため、
          // dropのサイズは必ずスクリーン（アプリ画面）サイズと一致しないといけない、そうしないと、相対座標は比率とならない
          // どうしてもdropのサイズをデカくしたい場合、計算する際に、下記のように、アプリ画面とのサイズの差分をなくして座標の比率を計算しないといけない。
          // しかし、こうすると、誤差はかなり出てくるんで、UXがよくない。
          // {
          //   x:
          //     (((mouseX - (screenX + アプリ画面との差分/2)) * 100) /
          //       ((screenWidth - アプリ画面との差分) * 100)) *
          //       1024 -
          //     RND_PADDING,
          //   y:
          //     ((mouseY - (screenY +  アプリ画面との差分/2)) / (screenHeight - アプリ画面との差分)) *
          //       768 -
          //     RND_PADDING,
          // };
          handleAction({
            type: ActionCommandType.ADD_COMPONENT,
            componentType: item.type,
            category: item.category,
            screenId: screenId,
            position: {
              x: (mouseX - screenX) / scale,
              y: (mouseY - screenY) / scale,
            },
          });
          return undefined;
        }
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [scale]
  );
  drop(ref);
  return (
    <div
      style={{
        zIndex: canDrop ? 100 : -1,
        position: "absolute",
        top: -MARGIN / 2,
        left: -MARGIN / 2,
        width: size.x + MARGIN,
        height: size.y + MARGIN,
        boxSizing: "border-box",
        border: canDrop && "2px solid red",
      }}
    >
      <div
        ref={ref}
        style={{
          position: "absolute",
          top: MARGIN / 2 - 2, // -2: border width
          left: MARGIN / 2 - 2, // -2: border width
          width: size.x,
          height: size.y,
        }}
      ></div>
    </div>
  );
};
