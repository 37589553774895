/* eslint-disable no-new-func */
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import {
  loadMap,
  loadMesh,
  TileMapType,
  CHARACTER_TYPE,
} from "features/courses/algorithm/game/scene/maps";
import { performAnimation } from "features/courses/algorithm/game/scene/animations";

export const offset = new Vector3(1, 0, 1);

interface SceneRenderConfigFunc {
  setLoading: (loading: boolean) => void;
  setScene?: (scene: Scene) => void;
  setCharacter: (character: AbstractMesh) => void;
  setTotalGem: (totalGem: number) => void;
  handlePlusGemScore: (scene: Scene, totalGem: number) => void;
}

export const GameScene = ({
  scale,
  stage,
  step,
  map,
  config,
  reload,
}: {
  scale: number;
  stage: number;
  step: number;
  map: TileMapType;
  config: SceneRenderConfigFunc;
  reload: boolean;
}) => {
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let scene: Scene;
    let engine: Engine;

    var canvas = document.getElementById("app-canvas") as HTMLCanvasElement;

    const onSceneReady = async (canvas: HTMLCanvasElement) => {
      engine = new Engine(
        canvas,
        true,
        { preserveDrawingBuffer: true, stencil: true },
        true
      );
      scene = new Scene(engine);

      let camera: ArcRotateCamera;
      if (stage === 2) {
        camera = new ArcRotateCamera(
          "camera",
          Math.PI / 4,
          Math.PI / 4,
          20 / scale,
          new Vector3(6.5 + offset.x, offset.y, 2 + offset.z),
          scene
        );
      } else if (stage === 4) {
        camera = new ArcRotateCamera(
          "camera",
          Math.PI / 4,
          Math.PI / 4,
          30 / scale,
          new Vector3(4.5 + offset.x, offset.y - 2, 2 + offset.z),
          scene
        );
      } else {
        camera = new ArcRotateCamera(
          "camera",
          Math.PI / 4,
          Math.PI / 4,
          25 / scale,
          new Vector3(4.5 + offset.x, offset.y, 4 + offset.z),
          scene
        );
      }
      camera.lowerBetaLimit = 0;
      camera.upperBetaLimit = Math.PI / 2;
      camera.lowerRadiusLimit = 10;
      camera.upperRadiusLimit = 40 / scale;
      camera.attachControl(canvas, true);

      const light = new HemisphericLight(
        "light",
        new Vector3(-3, 5, -3),
        scene
      );
      light.intensity = 1.5;

      scene.clearColor = new Color4(0, 0, 0, 0);

      const { character, gems } = await loadMap(
        scene,
        map,
        offset,
        CHARACTER_TYPE.CHARACTER_TYPE_DUCK
      );
      config.setCharacter(character);

      const mark = await loadMesh(scene, "lightning.gltf.glb");
      mark.name = "mark";
      mark.setEnabled(false);

      gems.forEach((gem) => {
        performAnimation(scene, gem);
      });
      config.setTotalGem(gems.length);
      config.setLoading(false);

      scene.onAfterRenderObservable.add(() => {
        if (!scene.isDisposed) {
          gems.forEach((gem) => {
            if (!gem.isDisposed() && character.intersectsMesh(gem)) {
              gem.dispose();
              config.handlePlusGemScore(scene, gems.length);
            }
          });
        }
      });

      config.setScene(scene);

      engine.runRenderLoop(() => {
        scene.render();
      });
    };

    if (canvasRef.current) {
      console.log("load scene.");
      try {
        if (canvas) {
          const target = canvasRef.current.getBoundingClientRect();
          canvas.style.top = `${target.top}px`;
          canvas.style.left = `${target.left}px`;
          canvas.style.width = `${target.width}px`;
          canvas.style.height = `${target.height}px`;
        } else {
          const root = document.body;
          const target = canvasRef.current.getBoundingClientRect();
          const newCanvasNode = document.createElement("canvas");
          newCanvasNode.id = "app-canvas";
          newCanvasNode.style.transition = "opacity 300ms";
          newCanvasNode.style.position = "absolute";
          newCanvasNode.style.pointerEvents = "auto";
          newCanvasNode.style.top = `${target.top}px`;
          newCanvasNode.style.left = `${target.left}px`;
          newCanvasNode.style.width = `${target.width}px`;
          newCanvasNode.style.height = `${target.height}px`;
          root.appendChild(newCanvasNode);
          canvas = newCanvasNode;
        }
        canvas.style.opacity = "0";
        onSceneReady(canvas);
        canvas.style.opacity = "1";
      } catch (error) {
        console.error(JSON.stringify(error));
        if (!scene.isDisposed) {
          navigate(`/courses/algorithm/stage/${stage}/step/${step}`);
        }
      }
    }
    return () => {
      canvas.style.width = "0";
      canvas.style.height = "0";
      canvas.width = 1;
      canvas.height = 1;
      if (engine) {
        scene.dispose();
        engine.dispose();
        scene = null;
        engine = null;
        canvas = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <div
      ref={canvasRef}
      className="absolute w-full h-full pointer-events-none"
    />
  );
};
