import { useEffect, MouseEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { actions } from "app/configSlice";
import { DialogType, LocalStorageKey } from "app/types";
import { usePlaySound, insideInstalledApp } from "common/utils";
import { Banner } from "./Banner";

export const PwaInstallBanner = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const pwa_install_prompt = useSelector(
    (state: RootState) => state.config.dialog.pwa_install_prompt
  );
  const before_install_prompt = useSelector(
    (state: RootState) => state.config.event.before_install_prompt
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    try {
      if (before_install_prompt.value) {
        const dismissBanner = localStorage.getItem(
          LocalStorageKey.DISMISS_BANNER
        );
        if (dismissBanner?.toLocaleLowerCase() !== "true") {
          setTimeout(
            () => {
              dispatch(
                actions.updateDialog({
                  type: DialogType.PWA_INSTALL_PROMPT,
                  value: true,
                })
              );
            },
            config.tutorial.top ? 8000 : 5000
          );
        }
      }
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [before_install_prompt]);

  useEffect(() => {
    window.addEventListener("appinstalled", () => {
      if (insideInstalledApp()) {
        window.resizeBy(1024 - window.innerWidth, 768 - window.innerHeight);
      }
    });
  }, []);

  const handleClose = () => {
    play();
    dispatch(
      actions.updateDialog({
        type: DialogType.PWA_INSTALL_PROMPT,
        value: false,
      })
    );
  };

  const handleDismiss: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    dispatch(
      actions.updateDialog({
        type: DialogType.PWA_INSTALL_PROMPT,
        value: false,
      })
    );
    localStorage.setItem(LocalStorageKey.DISMISS_BANNER, "true");
  };

  const handleConfirm = async () => {
    play();
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      return;
    }
    promptEvent.prompt();
    window.deferredPrompt = null;
    dispatch(
      actions.updateDialog({
        type: DialogType.PWA_INSTALL_PROMPT,
        value: false,
      })
    );
  };

  return (
    <Banner
      open={pwa_install_prompt.value && before_install_prompt.value}
      close={handleClose}
      dismiss={handleDismiss}
      confirm={handleConfirm}
      label={I18n.t("MSG_APP_BANNER_INSTALL_BTN")}
    />
  );
};
