import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import {
  PointerInfo,
  PointerEventTypes,
} from "@babylonjs/core/Events/pointerEvents";
import { Scene } from "@babylonjs/core/scene";
import { MeshBuilder } from "@babylonjs/core";
import { Mesh } from "@babylonjs/core/Meshes";
import { Color3 } from "@babylonjs/core/Maths/math.color";
import { Observer } from "@babylonjs/core/Misc/observable";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Button, AdvancedDynamicTexture } from "@babylonjs/gui";
import { PickingInfo } from "@babylonjs/core/Collisions/pickingInfo";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import {
  MapModel,
  MAP_MESH_SIZE,
  AssetModelType,
  ActionCommandType,
} from "features/builder/types";
import { actions } from "features/builder/slice";
import { useActionCommand } from "features/builder/hook";
import { loadMesh, createPreviewMesh } from "./loader";
import { createGround, createOuterMesh, createBottomGround } from "./util";

export const useScene = (scene: Scene | null) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const handleAction = useActionCommand();
  const actionMenu = useSelector(
    (state: RootState) => state.builder.actionMenu
  );
  const selectedAssetModel = useSelector(
    (state: RootState) => state.builder.selectedAssetModel
  );
  const size = useSelector((state: RootState) => state.builder.size);
  const [target, _setTarget] = useState<Mesh | null>(null);
  const targetRef = useRef(target);
  const setTarget = (target: Mesh | null) => {
    targetRef.current = target;
    _setTarget(target);
  };

  useEffect(() => {
    const handleClearTarget = () => {
      if (targetRef.current) {
        targetRef.current
          .getChildMeshes()
          .forEach((subMesh) => (subMesh.material.alpha = 1));
        setTarget(null);
      }
    };
    window.addEventListener("pointerdown", handleClearTarget);
    return () => {
      window.removeEventListener("pointerdown", handleClearTarget);
    };
  }, []);

  useEffect(() => {
    let rotateBtn: Button;
    if (target) {
      const turnMesh = () => {
        const lastForward = target.forward;
        target.addRotation(0, Math.PI / 2, 0);
        dispatch(
          actions.updateMapModel({
            id: target.id,
            changes: {
              direction: {
                x: Number(Math.round(lastForward.z)),
                y: 0,
                z: Number(-Math.round(lastForward.x)),
              },
            },
          })
        );
      };

      const boundingInfo = target.getBoundingInfo();
      const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
      advancedTexture.layer.layerMask = 2;

      rotateBtn = Button.CreateImageOnlyButton(
        "rotateBtn",
        `${Constants.assetHost}/assets/images/icon_reset_rotation.png`
      );
      rotateBtn.width = "80px";
      rotateBtn.height = "80px";
      rotateBtn.thickness = 0;
      rotateBtn.onPointerDownObservable.add((_, eventState) => {
        eventState.userInfo.event.stopPropagation();
        turnMesh();
      });
      advancedTexture.addControl(rotateBtn);
      rotateBtn.linkWithMesh(target);
      rotateBtn.linkOffsetY = -(boundingInfo.boundingBox.maximumWorld.y + 150);
    }
    return () => {
      if (rotateBtn) {
        rotateBtn.dispose();
      }
    };
  }, [target, actionMenu]);

  useEffect(() => {
    let clicked: boolean = false;
    let pointerUpFlag: boolean = false;
    let pointerDownFlag: boolean = false;

    let deleteMesh: Mesh;
    let selectedMesh: Mesh;
    let previewedMesh: Mesh;
    let observable: Observer<PointerInfo>;

    if (scene) {
      const camera = scene.getCameraByName("camera") as ArcRotateCamera;

      const pointerDown = async (pickInfo: PickingInfo) => {
        if (pointerDownFlag || pointerUpFlag) return;
        clicked = true;
        pointerDownFlag = true;

        if (pickInfo.pickedMesh) {
          if (selectedAssetModel) {
            // アセットを追加モード
            camera?.detachControl();

            const hitAssetMesh = scene.pick(
              scene.pointerX,
              scene.pointerY,
              (mesh) => mesh.name.includes("asset")
            );
            const hitGroundMesh = scene.pick(
              scene.pointerX,
              scene.pointerY,
              (mesh) => mesh.name.includes("ground")
            );

            if (
              !previewedMesh &&
              hitAssetMesh.hit &&
              hitGroundMesh.hit &&
              hitGroundMesh.pickedMesh?.name.split("_").pop() ===
                hitAssetMesh.pickedMesh?.name.split("_").pop() &&
              (selectedAssetModel.type !== AssetModelType.RIVER &&
              selectedAssetModel.type !== AssetModelType.GROUND
                ? hitGroundMesh.pickedMesh?.name.split("_").pop() !== "0"
                : true)
            ) {
              scene.getMaterialByName("ground_layer").alpha = 0.3;
              (selectedAssetModel.type === AssetModelType.RIVER ||
                selectedAssetModel.type === AssetModelType.GROUND) &&
                (scene.getMaterialByName("ground").alpha = 0.3);

              const mesh = await createPreviewMesh(
                scene,
                selectedAssetModel.filename,
                selectedAssetModel.offset
              );
              if (clicked) {
                mesh.getChildMeshes().forEach((subMesh) => {
                  subMesh.material.alpha = 0.8;
                  if (subMesh.name.indexOf("Cube") !== -1) {
                    subMesh.isVisible = false;
                  }
                });
                const boundingInfo = mesh.getBoundingInfo();
                const outer = createOuterMesh(
                  scene,
                  boundingInfo.boundingBox,
                  hitGroundMesh.pickedMesh.position
                );
                if (selectedAssetModel.type === AssetModelType.CHARACTER) {
                  outer.scaling = new Vector3(
                    selectedAssetModel.scaling.x,
                    selectedAssetModel.scaling.y,
                    selectedAssetModel.scaling.z
                  );
                  scene.animationGroups.forEach((group) => {
                    group.stop();
                  });
                }
                mesh.metadata = {
                  type: selectedAssetModel.type,
                  rotation: selectedAssetModel.rotation,
                };
                mesh.parent = outer;
                previewedMesh = mesh;
              } else {
                mesh.dispose();
                previewedMesh = null;
                scene.getMaterialByName("ground").alpha = 0;
                scene.getMaterialByName("ground_layer").alpha = 0;
              }
            }
          } else {
            if (!actionMenu.eraser) {
              dispatch(
                actions.updateActionMenu({
                  key: "tap",
                  value: true,
                  exclusive: true,
                })
              );
            }
            // メッシュをクリックした
            const hitAssetMesh = scene.pick(
              scene.pointerX,
              scene.pointerY,
              (mesh) => mesh.name.includes("asset_model")
            );
            if (hitAssetMesh.pickedMesh) {
              let hitMesh = hitAssetMesh.pickedMesh as Mesh;
              while (
                hitMesh.parent !== null &&
                hitMesh.parent.name !== "outer"
              ) {
                hitMesh = hitMesh.parent as Mesh;
              }
              const ground = scene.getMeshByName(
                hitMesh.name.replace("model", "ground")
              );
              if (hitMesh.metadata?.type !== "ground" || ground) {
                // 一番上のMeshをクリックしている
                camera?.detachControl();
                if (targetRef.current) {
                  targetRef.current
                    .getChildMeshes()
                    .forEach((subMesh) => (subMesh.material.alpha = 1));
                  setTarget(null);
                }
                hitMesh
                  .getChildMeshes()
                  .forEach((subMesh) => (subMesh.material.alpha = 0.8));
                if (actionMenu.eraser) {
                  deleteMesh = hitMesh;
                } else {
                  // メッシュを移動する
                  scene.getMaterialByName("ground_layer").alpha = 0.3;
                  (hitMesh.metadata?.type === AssetModelType.RIVER ||
                    hitMesh.metadata?.type === AssetModelType.GROUND) &&
                    (scene.getMaterialByName("ground").alpha = 0.3);

                  const outer = hitMesh.parent as Mesh;
                  const boundingInfo = outer.getBoundingInfo();
                  createBottomGround(
                    scene,
                    boundingInfo.boundingBox,
                    outer.position
                  );
                  selectedMesh = hitMesh;
                }
              }
            }
          }
        } else {
          // 何もないところをクリックした
          play();
          dispatch(
            actions.updateActionMenu({
              key: "tap",
              value: true,
              exclusive: true,
            })
          );
          dispatch(actions.selectedAssetMesh(null));
        }

        pointerDownFlag = false;
      };

      const pointerMove = async () => {
        if (!clicked || pointerDownFlag || pointerUpFlag) return;
        if ((selectedAssetModel && previewedMesh) || selectedMesh) {
          const hitGroundMesh = scene.pick(
            scene.pointerX,
            scene.pointerY,
            (mesh) => mesh.name.includes("ground")
          );

          if (hitGroundMesh.pickedPoint) {
            if (
              previewedMesh &&
              (selectedAssetModel.type !== AssetModelType.RIVER &&
              selectedAssetModel.type !== AssetModelType.GROUND
                ? hitGroundMesh.pickedMesh?.name.split("_").pop() !== "0"
                : true)
            ) {
              (previewedMesh.parent as Mesh).position =
                hitGroundMesh.pickedMesh.position;
            }
            if (selectedMesh) {
              (selectedMesh.parent as Mesh).position =
                hitGroundMesh.pickedMesh.position;
            }
          }
        }
      };

      const pointerUp = async (pickInfo: PickingInfo) => {
        clicked = false;

        if (pointerDownFlag || pointerUpFlag) {
          if (previewedMesh) {
            (previewedMesh.parent as Mesh).dispose();
            previewedMesh = null;
          }
          return;
        }

        pointerUpFlag = true;

        scene.getMaterialByName("ground").alpha = 0;
        scene.getMaterialByName("ground_layer").alpha = 0;
        if (selectedAssetModel && pickInfo.pickedMesh && previewedMesh) {
          // 新しいメッシュを追加する
          play();
          scene.animationGroups.forEach((group) => {
            group.stop();
            const index = scene.animationGroups.indexOf(group);
            if (index !== -1) {
              scene.animationGroups.splice(index, 1);
            }
            group.dispose();
          });

          const hitGroundMesh = scene.pick(
            scene.pointerX,
            scene.pointerY,
            (mesh) => mesh.name.includes("ground")
          );
          const outer = previewedMesh.parent as Mesh;
          if (
            hitGroundMesh.hit &&
            hitGroundMesh.pickedMesh.position.x === outer.position.x &&
            hitGroundMesh.pickedMesh.position.z === outer.position.z &&
            (selectedAssetModel.type !== AssetModelType.RIVER &&
            selectedAssetModel.type !== AssetModelType.GROUND
              ? hitGroundMesh.pickedMesh?.name.split("_").pop() !== "0"
              : true)
          ) {
            const meshId = nanoid();
            const boundingInfo = outer.getBoundingInfo();
            const name = `asset_model_layer_${
              hitGroundMesh.pickedMesh.position.x
            }_${hitGroundMesh.pickedMesh.position.z}_${Math.round(
              boundingInfo.boundingBox.maximumWorld.y
            )}`;
            outer.getChildMeshes().forEach((subMesh) => {
              if (subMesh.material) {
                subMesh.material.alpha = 1;
              }
              if (subMesh.name !== "outer") {
                subMesh.id = meshId;
                subMesh.name = name;
              }
            });
            previewedMesh.metadata["position"] = {
              x: hitGroundMesh.pickedMesh.position.x,
              y: hitGroundMesh.pickedMesh.position.y,
              z: hitGroundMesh.pickedMesh.position.z,
            };
            previewedMesh.metadata[
              "bottomLayer"
            ] = hitGroundMesh.pickedMesh?.name.split("_").pop();
            createGround(
              scene,
              boundingInfo.boundingBox,
              selectedAssetModel.type,
              hitGroundMesh.pickedMesh.position,
              hitGroundMesh.pickedMesh?.name.split("_").pop()
            );

            handleAction({
              type: ActionCommandType.ADD_MODE,
              model: {
                id: meshId,
                type: selectedAssetModel.type,
                name,
                filename: selectedAssetModel.filename,
                rotation: selectedAssetModel.rotation,
                offset: selectedAssetModel.offset,
                scaling: selectedAssetModel.scaling,
                metadata: selectedAssetModel.metadata,
                position: {
                  x: hitGroundMesh.pickedMesh.position.x,
                  y: hitGroundMesh.pickedMesh.position.y,
                  z: hitGroundMesh.pickedMesh.position.z,
                },
              },
            });

            previewedMesh = null;
          } else {
            if (previewedMesh) {
              (previewedMesh.parent as Mesh).dispose();
              previewedMesh = null;
            }
          }

          dispatch(actions.resetActionMenu());
          camera?.attachControl(scene.getEngine().getRenderingCanvas(), true);
        } else if (selectedMesh) {
          // 既存のメッシュを移動する
          const hitGroundMesh = scene.pick(
            scene.pointerX,
            scene.pointerY,
            (mesh) => mesh.name.includes("ground")
          );
          const outer = selectedMesh.parent as Mesh;
          const boundingInfo = outer.getBoundingInfo();
          if (
            hitGroundMesh.hit &&
            hitGroundMesh.pickedMesh.position.x === outer.position.x &&
            hitGroundMesh.pickedMesh.position.z === outer.position.z &&
            (selectedMesh.metadata.type !== AssetModelType.RIVER &&
            selectedMesh.metadata.type !== AssetModelType.GROUND
              ? hitGroundMesh.pickedMesh?.name.split("_").pop() !== "0"
              : true)
          ) {
            const name = `asset_model_layer_${
              hitGroundMesh.pickedMesh.position.x
            }_${hitGroundMesh.pickedMesh.position.z}_${Math.round(
              boundingInfo.boundingBox.maximumWorld.y
            )}`;
            outer.getChildMeshes().forEach((subMesh) => {
              if (subMesh.material && !selectedMesh.metadata?.rotation) {
                subMesh.material.alpha = 1;
              }
              if (subMesh.name !== "outer") {
                subMesh.name = name;
              }
            });
            selectedMesh.metadata["position"] = {
              x: hitGroundMesh.pickedMesh.position.x,
              y: hitGroundMesh.pickedMesh.position.y,
              z: hitGroundMesh.pickedMesh.position.z,
            };
            selectedMesh.metadata[
              "bottomLayer"
            ] = hitGroundMesh.pickedMesh?.name.split("_").pop();
            createGround(
              scene,
              boundingInfo.boundingBox,
              selectedMesh.metadata.type,
              hitGroundMesh.pickedMesh.position,
              hitGroundMesh.pickedMesh?.name.split("_").pop()
            );

            handleAction({
              type: ActionCommandType.CHANGE_MODE,
              id: selectedMesh.id,
              changes: {
                name,
                position: {
                  x: hitGroundMesh.pickedMesh.position.x,
                  y: hitGroundMesh.pickedMesh.position.y,
                  z: hitGroundMesh.pickedMesh.position.z,
                },
              },
            });
          } else {
            const position = new Vector3(
              selectedMesh.metadata.position.x,
              selectedMesh.metadata.position.y,
              selectedMesh.metadata.position.z
            );
            outer.position = position;
            outer.getChildMeshes().forEach((subMesh) => {
              if (subMesh.material && !selectedMesh.metadata?.rotation) {
                subMesh.material.alpha = 1;
              }
            });
            createGround(
              scene,
              boundingInfo.boundingBox,
              selectedMesh.metadata.type,
              position,
              selectedMesh.metadata.bottomLayer
            );
          }

          if (selectedMesh.metadata?.rotation) {
            setTarget(selectedMesh);
          } else {
            camera?.attachControl(scene.getEngine().getRenderingCanvas(), true);
          }

          selectedMesh = null;
        } else if (deleteMesh) {
          if (actionMenu.eraser) {
            // メッシュを削除する
            play();
            const ground = scene.getMeshByName(
              deleteMesh.name.replace("model", "ground")
            );
            if (deleteMesh.metadata?.type !== "ground" || ground) {
              const outer = deleteMesh.parent as Mesh;
              const boundingInfo = outer.getBoundingInfo();
              createBottomGround(
                scene,
                boundingInfo.boundingBox,
                outer.position
              );
              handleAction({
                type: ActionCommandType.REMOVE_MODE,
                meshId: deleteMesh.id,
              });
              outer.dispose();
            }
          }
          deleteMesh = null;
          camera?.attachControl(scene.getEngine().getRenderingCanvas(), true);
        } else {
          if (previewedMesh) {
            (previewedMesh.parent as Mesh).dispose();
            previewedMesh = null;
          }
          camera?.attachControl(scene.getEngine().getRenderingCanvas(), true);
        }

        pointerUpFlag = false;
      };

      observable = scene.onPointerObservable.add(async (pointerInfo) => {
        switch (pointerInfo.type) {
          case PointerEventTypes.POINTERDOWN:
            await pointerDown(pointerInfo.pickInfo);
            break;
          case PointerEventTypes.POINTERMOVE:
            await pointerMove();
            break;
          case PointerEventTypes.POINTERUP:
            await pointerUp(pointerInfo.pickInfo);
            break;
        }
      });
    }

    return () => {
      clicked = false;
      pointerUpFlag = false;
      pointerDownFlag = false;
      selectedMesh = null;
      previewedMesh = null;
      if (observable) {
        scene.onPointerObservable.remove(observable);
      }
    };
  }, [scene, actionMenu.eraser, selectedAssetModel]);

  const initGroundMesh = (scene: Scene) => {
    const width = size.x;
    const height = size.z;
    const groundMaterial = new StandardMaterial("ground", scene);
    groundMaterial.alpha = 0;
    groundMaterial.diffuseColor = new Color3(0, 1, 0);

    const groundLayerMaterial = new StandardMaterial("ground_layer", scene);
    groundLayerMaterial.alpha = 0;
    groundLayerMaterial.diffuseColor = new Color3(0, 1, 0);

    for (let z = 0; z < height; z++) {
      for (let x = 0; x < width; x++) {
        const ground = MeshBuilder.CreateGround(
          `asset_ground_layer_${x * MAP_MESH_SIZE + 1}_${
            z * MAP_MESH_SIZE + 1
          }_0`,
          { width: MAP_MESH_SIZE, height: MAP_MESH_SIZE },
          scene
        );
        ground.id = "ground";
        ground.material = groundMaterial;
        ground.showBoundingBox = true;
        ground.position = new Vector3(
          x * MAP_MESH_SIZE + MAP_MESH_SIZE / 2,
          0,
          z * MAP_MESH_SIZE + MAP_MESH_SIZE / 2
        );
      }
    }
  };

  const loadMapModel = (scene: Scene, mapModels: MapModel[]) => {
    if (scene) {
      mapModels.forEach(async (model) => {
        const position = new Vector3(
          model.position.x,
          model.position.y,
          model.position.z
        );

        const mesh = await loadMesh(scene, model.filename, model.offset);
        mesh.getChildMeshes().forEach((subMesh) => {
          if (subMesh.name.indexOf("Cube") !== -1) {
            subMesh.isVisible = false;
          }
          subMesh.name = model.id;
          subMesh.name = model.name;
        });
        mesh.id = model.id;
        mesh.name = model.name;
        mesh.metadata = {
          type: model.type,
          rotation: model.rotation,
          position: model.position,
          bottomLayer: Math.round(model.position.y),
        };
        const boundingInfo = mesh.getBoundingInfo();
        const outer = createOuterMesh(
          scene,
          boundingInfo.boundingBox,
          position
        );
        outer.scaling = new Vector3(
          model.scaling?.x ?? 1,
          model.scaling?.y ?? 1,
          model.scaling?.z ?? 1
        );
        outer.rotation = new Vector3(
          0,
          model.direction
            ? Math.atan2(Number(model.direction.x), Number(model.direction.z))
            : 0,
          0
        );
        mesh.parent = outer;

        createGround(
          scene,
          boundingInfo.boundingBox,
          model.type,
          new Vector3(model.position.x, model.position.y, model.position.z),
          Math.round(model.position.y)
        );

        scene.animationGroups.forEach((group) => {
          group.stop();
        });
      });
    }
  };

  return { initGroundMesh, loadMapModel };
};
