export const ProjectUser = ({ name, icon }: { name: string; icon: string }) => {
  return (
    <div className="flex-col-center w-[120px]">
      <div
        className="flex-col-center w-[72px] h-[72px] bg-white rounded-full border-solid border-[4px] overflow-hidden border-white"
        style={{
          boxShadow:
            "-1px 3px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
        }}
      >
        <img alt="*" src={icon} className="w-full h-full object-contain" />
      </div>

      <div className="flex-row-el flex-center flex-1 w-[130px] absolute -bottom-1">
        <p
          className="text-body text-center text-textcolor/black text-[20px] text-body-ellipsis w-full px-1"
          style={{
            WebkitTextStroke: "6px #FEFDFE",
          }}
        >
          {name}
        </p>
        <p
          className="absolute text-body text-center text-textcolor/black text-[20px] text-body-ellipsis w-full px-1 box-border"
          style={{
            WebkitTextStroke: "6px rgba(0,0,0,0)",
          }}
        >
          {name}
        </p>
      </div>
    </div>
  );
};
