/* eslint-disable no-new-func */
import { useState } from "react";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import {
  CheckMark,
  AnswerMark,
} from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const [clicked, setClicked] = useState(false);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "sleep",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      play();
      setRunning(true);
      setClicked(true);
      handleEvent(sleep, highlightBlock, setSuccess, setGameOver)();
    }
  };

  return (
    <div className="flex-row-center gap-8">
      <div
        id="course-program-image-1"
        className="flex-col-center cursor-pointer"
      >
        <img
          alt="*"
          onClick={handleClick}
          src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
          className="w-[100px] h-[100px] active:opacity-70"
        />
        <CheckMark id={1} />
      </div>

      <div
        id="course-program-border-1"
        className="flex-col-center w-[100px] h-[100px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
      >
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
          className="w-[100px] h-[100px] opacity-40 absolute"
          style={{
            filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
          }}
        />
        <img
          alt="*"
          id="course-program-image-2"
          className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
        />
        <AnswerMark id={1} />
      </div>
    </div>
  );
};
