import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippet } from "features/courses/program/game/blockly/uitls";

export const InitStartBlock = () => {
  const startBlock = () => {
    return {
      type: "start_answer_event",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    };
  };

  Blockly.Blocks["start_answer_event"] = {
    init: function () {
      this.appendDummyInput().appendField("%{BKY_COURSE_START_BLOCK}");
      this.jsonInit(startBlock());
      this.setCollapsed(false);
      this.setColour("#FFBF00");
    },
  };

  BlocklyJs["start_answer_event"] = function (block: Blockly.Block) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          var order = 1;
          let target;
          let border;
          let answerMark;
          const targetImageIdPrefix = "course-program-image-";
          const targetLabelIdPrefix = "course-program-label-";
          const targetEles = ["Pineapple", "あ", "Fragaria", "い"];
          const result = [false, false, false, false];
          if(${result}){
            ${logic}
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitShowImageBlock = () => {
  Blockly.Blocks["show_image_block"] = {
    init: function () {
      const input = this.appendDummyInput();
      const options = [
        [
          {
            src: `${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
            width: 30,
            height: 30,
            alt: "Fragaria",
          },
          `Fragaria#${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
            width: 30,
            height: 30,
            alt: "Pineapple",
          },
          `Pineapple#${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          `Grape#${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
        ],
      ];
      input
        .appendField(new Blockly.FieldDropdown(options), "Image")
        .appendField("%{BKY_DISPLAY_EVENT}");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour("#FFAB19");
    },
  };
  BlocklyJs["show_image_block"] = function (block: Blockly.Block) {
    const selectedValue = block.getField("Image").getValue().split("#");
    const selectedImage = selectedValue[0];
    const selectedImageSrc = selectedValue[1];
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById(targetImageIdPrefix.concat('', order));
      if(target instanceof HTMLImageElement){
        target.src = "${selectedImageSrc}";
        sound();
        target.style.opacity = "1";
        target.style.transform = "scale(1)";
      };
      result[order - 1] = targetEles[order - 1] === "${selectedImage}";
      await sleep(700);
      ${showAnswerSnippet()}
      await sleep(700);
      target = document.getElementById("course-program-arrow-".concat('', order));
      if(target){
        target.style.opacity = "1";
      };
      order += 1;
      highlightBlock("${block.id}", false);
    `;
  };
};

export const InitShowLabelTextBlock = () => {
  Blockly.Blocks["show_label_text_block"] = {
    max: 1,
    init: function () {
      this.jsonInit({
        type: "show_label_text_block",
        message0: "%1をひょうじする",
        args0: [
          {
            type: "input_value",
            name: "value",
            check: "String",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "text_blocks",
      });
    },
  };
  BlocklyJs["show_label_text_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById(targetLabelIdPrefix.concat('', order));
      if(target instanceof HTMLParagraphElement){
        target.innerText = ${value};
        sound();
        target.style.opacity = "1";
        target.style.transform = "scale(1)";
      };
      result[order - 1] = targetEles[order - 1] === ${value};
      await sleep(700);
      ${showAnswerSnippet()}
      await sleep(700);
      target = document.getElementById("course-program-arrow-".concat('', order));
      if(target){
        target.style.opacity = "1";
      };
      order += 1;
      highlightBlock("${block.id}", false);
    `;
  };
};
