import { useEffect } from "react";
import Constants from "common/constant";
import { InitStartBlock } from "features/courses/program/game/blockly";
import { InitImageSetterBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitStartBlock();
    InitImageSetterBlock();
  }, []);

  return (
    <div className="flex-col-center">
      <div className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
          className="w-[100px] h-[100px] opacity-40"
          style={{
            filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
          }}
        />
      </div>

      <p className="text text-white">ボックス1</p>
    </div>
  );
};
