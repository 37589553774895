import { PayloadAction } from "@reduxjs/toolkit";
import {
  SortedOrder,
  DisplayMode,
  KeyDirection,
  ProjectEntity,
  FreeDrawingTool,
  UserSortedOrder,
  ProjectPanelType,
  CreatorInitialState,
} from "features/creator/types";

const reducers = {
  updateTutorialMenu(
    state: CreatorInitialState,
    action: PayloadAction<{ key: string; value: boolean }>
  ) {
    const { key, value } = action.payload;
    state.tutorialMenu[key] = value;
  },
  updatePanelIndex(state: CreatorInitialState, action: PayloadAction<number>) {
    state.panelTabIndex = action.payload;
  },
  updateKeyword(state: CreatorInitialState, action: PayloadAction<string>) {
    state.keyword = action.payload;
  },
  updateProjectPanel(
    state: CreatorInitialState,
    action: PayloadAction<ProjectPanelType>
  ) {
    state.projectPanel = action.payload;
  },
  updateSortFilterPanel(
    state: CreatorInitialState,
    action: PayloadAction<boolean>
  ) {
    state.sortedOrderPanel = action.payload;
  },
  updateProjectPanelCursor(
    state: CreatorInitialState,
    action: PayloadAction<KeyDirection>
  ) {
    state.cursor = action.payload;
  },
  updateProjectPanelAction(
    state: CreatorInitialState,
    action: PayloadAction<{ back: boolean; confirm: boolean }>
  ) {
    state.action = action.payload;
  },
  updateDisplayMode(
    state: CreatorInitialState,
    action: PayloadAction<DisplayMode>
  ) {
    state.displayMode = action.payload;
  },
  updateSelectedProjectId(
    state: CreatorInitialState,
    action: PayloadAction<string>
  ) {
    state.selectedProjectId = action.payload;
  },
  updateRecommendReturnToHome(
    state: CreatorInitialState,
    action: PayloadAction<boolean>
  ) {
    state.recommendReturnToHome = action.payload;
  },
  updateRecommendProject(
    state: CreatorInitialState,
    action: PayloadAction<ProjectEntity>
  ) {
    state.recommendProject = action.payload;
  },
  updateFocus(state: CreatorInitialState) {
    state.focus = !state.focus;
  },
  updateCapture(state: CreatorInitialState, action: PayloadAction<boolean>) {
    state.capture = action.payload;
  },
  handleAllCollapse(state: CreatorInitialState) {
    state.panelCollapse.material = true;
    state.panelCollapse.property = true;
    state.panelCollapse.componentList = true;
    state.panelCollapse.blocklyWorkspace = true;
  },
  handleMaterialPanelCollapse(
    state: CreatorInitialState,
    action: PayloadAction<boolean>
  ) {
    state.panelCollapse.material = action.payload;
  },
  handleBlocklyWorkspaceCollapse(
    state: CreatorInitialState,
    action: PayloadAction<boolean>
  ) {
    state.panelCollapse.blocklyWorkspace = action.payload;
  },
  handlePropertyCollapse(
    state: CreatorInitialState,
    action: PayloadAction<boolean>
  ) {
    state.panelCollapse.property = action.payload;
  },
  handleComponentListCollapse(
    state: CreatorInitialState,
    action: PayloadAction<boolean>
  ) {
    state.panelCollapse.componentList = action.payload;
  },
  handleUpdateDrawing(
    state: CreatorInitialState,
    action: PayloadAction<FreeDrawingTool>
  ) {
    state.drawing = action.payload;
  },
  handleUpdateProjectSortedOrder(
    state: CreatorInitialState,
    action: PayloadAction<SortedOrder>
  ) {
    state.projectSortedOrder = action.payload;
  },
  handleUpdatePublishedProjectFilter(
    state: CreatorInitialState,
    action: PayloadAction<{
      type: string;
      value: boolean;
    }>
  ) {
    state.publishedProjectFilter[action.payload.type] = action.payload.value;
  },
  handleUpdateUserProjectSortedOrder(
    state: CreatorInitialState,
    action: PayloadAction<UserSortedOrder>
  ) {
    state.userProjectSortedOrder = action.payload;
  },
  handleUpdateUserProjectFilter(
    state: CreatorInitialState,
    action: PayloadAction<{
      type: string;
      value: boolean;
    }>
  ) {
    state.userProjectFilter[action.payload.type] = action.payload.value;
  },
  handleUpdateIsCopyProject(
    state: CreatorInitialState,
    action: PayloadAction<boolean>
  ) {
    state.isCopyProject = action.payload;
  },
};

export default reducers;
