import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippetByClickIndex } from "features/courses/program/game/blockly/uitls";

export const InitButtonClickBlock = () => {
  Blockly.Blocks["program_button_click_event_block"] = {
    init: function () {
      const components = [
        ["ボタン１", "1"],
        ["ボタン２", "2"],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "ComponentIndex")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_button_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
      return async () => {
        setRunning(true);
        let target;
        let answerMark;
        highlightBlock("${block.id}", true);
        await sleep(100);
        highlightBlock("${block.id}", false);
        if(${result}){
          ${logic}
          setDisabled(false);
          setClickedIndex(clickedIndex + 1);
          if(clickedIndex > 0){
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setRunning(false);
          };
        } else {
          setSuccess(false);
          setGameOver(true);
        };
      }
    `;
    return code;
  };
};

export const InitLabelTextSetterBlock = () => {
  Blockly.Blocks["label_text_setter_block"] = {
    max: 1,
    init: function () {
      this.jsonInit({
        type: "label_text_setter_block",
        message0: "%1を%2にする",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [["ボックス1", "1"]],
          },
          {
            type: "input_value",
            name: "value",
            check: "String",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "text_blocks",
      });
    },
  };
  BlocklyJs["label_text_setter_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        target = document.getElementById("course-program-label-1");
        if(target instanceof HTMLParagraphElement){
          target.innerText = ${value};
          target.style.opacity = "1";
          await anime({
            targets: target,
            scale: [1.5, 1],
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        };
        result[clickedIndex] = targetEles[clickedIndex] === ${value};
        ${showAnswerSnippetByClickIndex()}
        await sleep(700);
        if(!result[clickedIndex]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        if(clickedIndex === 0){
          if(target instanceof HTMLParagraphElement){
            target.style.opacity = "0";
            target.style.transform = "scale(1.5)";
          };
          target = document.getElementById("course-program-answer-label-1");
          if(target instanceof HTMLParagraphElement){
            target.style.opacity = "0";
          };
          target = document.getElementById("course-program-answer-label-2");
          if(target instanceof HTMLParagraphElement){
            target.style.opacity = "0.3";
          };
          answerMark = document.getElementById("course-program-answer-mark-right-1");
          answerMark.style.opacity = "0";
          answerMark = document.getElementById("course-program-answer-mark-wrong-1");
          answerMark.style.opacity = "0";  
        };
        highlightBlock("${block.id}", false);
    `;
  };
};
