import {
  useState,
  useEffect,
  useCallback,
  ChangeEventHandler,
  KeyboardEventHandler,
} from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import isLeapYear from "dayjs/plugin/isLeapYear";
import { RootState, AppDispatch } from "app/store";
import { btnDelay, usePlaySound } from "common/utils";
import { SpinModal, RadioButton, DialogMessage } from "common/elements";
import { actions } from "features/user/slice";
import { updateUserProfile } from "features/user/api";
import { UserProfileIconStock } from "features/user/UserProfileIconStock";

export const UserProfile = ({ selected }: { selected: boolean }) => {
  dayjs.extend(isLeapYear);
  const play = usePlaySound();
  const [error, setError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [selectIcon, setSelectIcon] = useState(false);
  const bannedWords = useSelector(
    (state: RootState) => state.config.banned.words
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const [icon, setIcon] = useState(user.active.icon);
  const [name, setName] = useState(user.active.display_name);
  const [gender, setGender] = useState(user.active.gender);

  const today = dayjs();
  const [age, setAge] = useState<number | string>("");
  const [month, setMonth] = useState<number | string>("");
  const [date, setDate] = useState<number | string>("");
  const [dateError, setDateError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [dayError, setDayError] = useState(false);
  const [monthError, setMonthError] = useState(false);

  const validateDate = useCallback(
    (age: number | string, month: number, date: number) => {
      if (month === 2) {
        const birthdayYear =
          today.subtract(Number(age), "year").year() -
          (today.isAfter(dayjs(`${today.year()}-${month}-${date}`)) ? 0 : 1);
        return dayjs(`${birthdayYear}-01-01`).isLeapYear()
          ? date < 30
          : date < 29;
      } else {
        return month % 2 === (month < 7 ? 1 : 0) ? date < 32 : date < 30;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setAgeError(false);
    setDayError(false);
    setMonthError(false);
    setIcon(user.active.icon);
    setName(user.active.display_name);
    const birthday = dayjs(user.active.birthday);
    const birthdayStr = user.active.birthday.split("-");
    setAge(birthdayStr[0] === "0000" ? "" : today.diff(birthday, "year"));
    setMonth(birthdayStr[1] === "00" ? "" : birthday.month() + 1);
    setDate(birthdayStr[2] === "00" ? "" : birthday.date());
    setGender(user.active.gender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.active.uid]);

  useEffect(() => {
    if (dateError) {
      setDateError(false);
    }

    if (date !== "" && date !== "00" && month !== "" && month !== "00") {
      if (!validateDate(age, Number(month), Number(date))) {
        setDateError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [age, month, date]);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
  };

  const handleChangeGender = (gender: string) => () => {
    play();
    setGender(gender);
  };

  const handleChangeAge: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (
      isNaN(Number(e.target.value)) ||
      e.target.value.length > 2 ||
      !e.target.value.match(/^\d+$/)
    ) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
    setAge(e.target.value);
  };

  const handleChangeMonth: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "") {
      setMonth("");
      setMonthError(false);
    } else {
      const month = Number(e.target.value);
      if (
        isNaN(month) ||
        month === 0 ||
        month > 12 ||
        !e.target.value.match(/^\d+$/)
      ) {
        setMonthError(true);
      } else {
        setMonthError(false);
      }
      if (validateDate(Number(age), month, Number(date))) {
        setDayError(false);
      } else {
        setDayError(true);
      }
      setMonth(e.target.value);
    }
  };

  const handleChangeDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === "") {
      setDate("");
      setDayError(false);
    } else {
      const date = Number(e.target.value);
      if (isNaN(date) || !e.target.value.match(/^\d+$/)) {
        setDayError(true);
      } else {
        if (validateDate(Number(age), Number(month), date)) {
          setDayError(false);
        } else {
          setDayError(true);
        }
      }
      setDate(e.target.value);
    }
  };

  const handleConfirm = () => {
    if (name.length === 0) return;
    setLoading(true);
    btnDelay(() => {
      play();
      if (bannedWords.filter((word) => name.includes(word.word)).length > 0) {
        setError(true);
      } else {
        const year =
          today.subtract(Number(age), "year").year() -
          (today.isAfter(dayjs(`${today.year()}-${month}-${date}`)) ? 0 : 1);
        const birthday = dayjs(`${year}-${month}-${date}`);
        if (birthday.isValid()) {
          updateUserProfile({
            uid: user.active.uid,
            userInfo: {
              name,
              birthday: birthday.format(
                age === "" || age === "00" ? "0000-MM-DD" : "YYYY-MM-DD"
              ),
              gender: gender,
            },
          })
            .then(() => {
              dispatch(
                actions.updateUserProfile({
                  ...user.active,
                  display_name: name,
                  birthday: birthday.format(
                    age === "" || age === "00" ? "0000-MM-DD" : "YYYY-MM-DD"
                  ),
                  gender: gender,
                })
              );
              toast(
                <div>
                  <p className="text text-textcolor/black">
                    {I18n.t("MSG_TOAST_EDIT_SUCCESS")}
                  </p>
                </div>
              );
            })
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
          console.error(`Invalid Birthday: ${year}-${month}-${date}`);
          toast(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_TOAST_FAILED")}
              </p>
            </div>
          );
        }
      }
    });
  };

  const handleWarningClose = () => {
    play();
    setError(false);
  };

  const handleSelectIcon = (show: boolean) => () => {
    play();
    btnDelay(() => setSelectIcon(show));
  };

  const handleCloseSelectIcon = (reset: boolean) => {
    handleSelectIcon(false)();
    if (reset) {
      setIcon(user.active.icon);
    }
  };

  const handleEnterKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (
      e.keyCode === 13 &&
      !(
        name.length === 0 ||
        name.length > 10 ||
        ageError ||
        monthError ||
        dayError
      )
    ) {
      handleConfirm();
    }
  };

  return (
    <>
      {loading && <SpinModal />}

      <DialogMessage
        open={error}
        title={I18n.t("MSG_USER_PROFILE_NAME_ERROR_TITLE")}
        message={I18n.t("MSG_USER_PROFILE_NAME_ERROR_MESSAGE")}
        close={handleWarningClose}
      />

      <UserProfileIconStock
        visible={selectIcon}
        icon={icon}
        setIcon={setIcon}
        close={handleCloseSelectIcon}
      />

      <div
        onKeyDown={handleEnterKeyDown}
        style={{ display: !selected && "none" }}
        className="flex-col-el items-center justify-start mt-8 w-full h-full gap-4"
      >
        <div className="flex-row-el flex-center gap-24">
          <div className="flex-col-el flex-center gap-4 mb-20">
            <div
              id="user-page-profile-icon"
              className="flex-col-center cursor-pointer bg-white rounded-full w-[160px] h-[160px]"
              onClick={handleSelectIcon(true)}
              style={{
                boxShadow:
                  "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
              }}
            >
              <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                <img
                  alt="ユーザプロフィール画像"
                  src={icon}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            <div className="flex-row-el flex-center upsilon">
              <button
                className="btn btn-primary"
                onClick={handleSelectIcon(true)}
              >
                <div className="flex-row-center gap-1 mb-[3px]">
                  <span className="material-symbols-outlined text-[30px] text-white">
                    edit_note
                  </span>
                  <p className="text-body text-white text-font-size-body2">
                    {I18n.t("MSG_USER_PROFILE_ICON_BTN")}
                  </p>
                </div>
              </button>
            </div>
          </div>

          <div className="flex-col-el relative items-start justify-center gap-6 w-[600px]">
            <div className="flex-row-el items-center justify-start relative w-full mb-2">
              <div className="flex-col-el w-[160px] relative shrink-0">
                <label className="text-body text-textcolor/black text-[20px]">
                  {I18n.t("MSG_USER_PROFILE_FIELD_NAME")}
                </label>
              </div>

              <div className="flex-col-el flex-center relative flex-1">
                <input
                  required
                  type="text"
                  className="peer input text-body text-center text-textcolor/black text-[26px]"
                  placeholder={I18n.t(
                    "MSG_USER_PROFILE_FIELD_NAME_PLACEHOLDER"
                  )}
                  pattern={".{1,10}"}
                  autoFocus={false}
                  value={name}
                  onChange={handleChangeName}
                />
                <label className="text-body text-left text-[14px] h-5 text-danger invisible peer-invalid:visible">
                  {I18n.t("MSG_BLOCKLY_INPUT_LENGTH_LIMIT", {
                    limit: 10,
                  })}
                </label>
              </div>
            </div>

            <div className="flex-row-el items-center justify-start relative w-full">
              <div className="flex-col-el w-[160px] relative shrink-0">
                <label className="text-body text-textcolor/black text-[20px]">
                  {I18n.t("MSG_USER_PROFILE_FIELD_GENDER")}
                </label>
              </div>

              <div className="flex-row-el items-center justify-between w-full">
                <div className="flex-row-center">
                  <RadioButton
                    size={30}
                    value={gender === "male"}
                    label={I18n.t("MSG_USER_PROFILE_FIELD_GENDER_MALE")}
                    onChange={handleChangeGender("male")}
                  />
                </div>

                <div className="flex-row-center">
                  <RadioButton
                    size={30}
                    value={gender === "female"}
                    label={I18n.t("MSG_USER_PROFILE_FIELD_GENDER_FEMALE")}
                    onChange={handleChangeGender("female")}
                  />
                </div>

                <div className="flex-row-center">
                  <RadioButton
                    size={30}
                    value={gender === "none"}
                    label={I18n.t("MSG_USER_PROFILE_FIELD_GENDER_NONE")}
                    onChange={handleChangeGender("none")}
                  />
                </div>
              </div>
            </div>

            <div className="flex-row-el items-center justify-start relative w-full">
              <div className="flex-col-el w-[160px] relative shrink-0">
                <label className="text-body text-textcolor/black text-[20px]">
                  {I18n.t("MSG_USER_PROFILE_FIELD_AGE")}
                </label>
              </div>

              <div className="flex-col-el w-full items-start">
                <div className="flex-row-el items-end relative w-[60%]">
                  <div className="flex-col-el w-full flex-center">
                    <input
                      type="text"
                      value={age}
                      autoFocus={false}
                      pattern={"[0-9]*"}
                      onChange={handleChangeAge}
                      placeholder={I18n.t(
                        "MSG_USER_PROFILE_FIELD_BIRTHDAY_AGE_PLACEHOLDER"
                      )}
                      className={`input text-body text-center text-textcolor/black text-[26px] ${
                        ageError && "!border-danger"
                      }`}
                    />
                  </div>
                  <label className="text-body text-textcolor/black text-[16px] shrink-0 !mx-2">
                    {I18n.t("MSG_USER_PROFILE_FIELD_BIRTHDAY_AGE")}
                  </label>
                </div>

                <label
                  className={`text-body text-left text-[14px] h-5 text-danger ${
                    ageError ? "visible" : "invisible"
                  }`}
                >
                  {I18n.t("MSG_USER_PROFILE_FIELD_BIRTHDAY_AGE_ERROR")}
                </label>
              </div>
            </div>

            <div className="flex-row-el items-center justify-start relative w-full">
              <div className="flex-col-el w-[160px] relative shrink-0">
                <label className="text-body text-textcolor/black text-[20px]">
                  {I18n.t("MSG_USER_PROFILE_FIELD_BIRTHDAY")}
                </label>
              </div>

              <div className="flex-row-el relative w-full">
                <div className="flex-col-el w-full items-start">
                  <div className="flex-row-el items-end">
                    <input
                      type="text"
                      autoFocus={false}
                      pattern={"[0-9]*"}
                      onChange={handleChangeMonth}
                      placeholder={I18n.t(
                        "MSG_USER_PROFILE_FIELD_BIRTHDAY_MONTH_PLACEHOLDER"
                      )}
                      value={month === "00" ? "" : month === "" ? "" : month}
                      className={`input text-body text-center text-textcolor/black text-[26px] ${
                        monthError && "!border-danger"
                      }`}
                    />
                    <label className="text-body text-textcolor/black text-[16px] shrink-0 !mx-2">
                      {I18n.t("MSG_USER_PROFILE_FIELD_BIRTHDAY_MONTH")}
                    </label>
                  </div>

                  <label
                    className={`text-body text-left text-[14px] h-5 text-danger ${
                      monthError ? "visible" : "invisible"
                    }`}
                  >
                    {I18n.t("MSG_USER_PROFILE_FIELD_BIRTHDAY_MONTH_ERROR")}
                  </label>
                </div>

                <div className="flex-col-el w-full items-start">
                  <div className="flex-row-el items-end">
                    <input
                      type="text"
                      value={date}
                      autoFocus={false}
                      pattern={"[0-9]*"}
                      onChange={handleChangeDate}
                      placeholder={I18n.t(
                        "MSG_USER_PROFILE_FIELD_BIRTHDAY_DATE_PLACEHOLDER"
                      )}
                      className={`input text-body text-center text-textcolor/black text-[26px] ${
                        dayError && "!border-danger"
                      }`}
                    />
                    <label className="text-body text-textcolor/black text-[16px] shrink-0 !ml-2">
                      {I18n.t("MSG_USER_PROFILE_FIELD_BIRTHDAY_DAY")}
                    </label>
                  </div>
                  <label
                    className={`text-body text-left text-[14px] h-5 text-danger ${
                      dayError ? "visible" : "invisible"
                    }`}
                  >
                    {I18n.t("MSG_USER_PROFILE_FIELD_BIRTHDAY_DAY_ERROR")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-row-el flex-center alpha">
          <button
            className="btn btn-primary"
            onClick={handleConfirm}
            disabled={
              name.length === 0 ||
              name.length > 10 ||
              ageError ||
              monthError ||
              dayError
            }
          >
            <div className="flex-row-center gap-1 mb-[3px]">
              <span className="material-symbols-outlined text-[36px] text-white">
                check
              </span>
              <p className="text-body text-white text-font-size-body1">
                {I18n.t("MSG_CONFIRM_BTN")}
              </p>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};
