import { SVGProps } from "react";

export const IconStamp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 15.9c0-.239.094-.468.262-.636A.892.892 0 0 1 4.895 15h15.21c.238 0 .465.095.633.264A.903.903 0 0 1 21 15.9v4.95a.903.903 0 0 1-.262.636.892.892 0 0 1-.633.264H4.895a.892.892 0 0 1-.633-.264A.903.903 0 0 1 4 20.85V15.9Z"
      fill="#C9947A"
      stroke="#3F4A61"
      strokeWidth={2}
    />
    <path
      d="M11.19 7.38a.754.754 0 0 1 .092-.276c.042-.068.093-.104.144-.104h2.148c.051 0 .102.036.144.104a.754.754 0 0 1 .091.275L15 15h-5l1.19-7.62Z"
      fill="#C9947A"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9 3h-2.8C9.94 3 9 3.895 9 5s.94 2 2.1 2h2.8c1.16 0 2.1-.895 2.1-2s-.94-2-2.1-2Z"
      fill="#FFA03B"
      stroke="#3F4A61"
      strokeWidth={2}
    />
  </svg>
);
