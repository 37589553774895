import {
  useRef,
  useMemo,
  useState,
  MouseEventHandler,
  ChangeEventHandler,
} from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { RootState } from "app/store";
import { Asset, DialogType } from "app/types";
import { useDialog, usePickImageAsset } from "app/hook";
import { btnDelay, usePlaySound } from "common/utils";
import { ImagePreview, DialogMessage } from "common/elements";
import { ComponentTypeIds, ComponentCategory } from "common/components";
import { getUserAssets } from "features/user/api";
import { ASSET_LIMIT } from "features/creator/constants";
import { useActionCommand } from "features/creator/utils";
import { StockTabIndex, ActionCommandType } from "features/creator/types";

export const StockPanel = () => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const queryClient = useQueryClient();
  const ref = useRef<HTMLInputElement>();
  const handleAction = useActionCommand();
  const pickImageAsset = usePickImageAsset();
  const inputRef = useRef<HTMLInputElement>();
  const [warning, setWarning] = useState(false);
  const [preview, setPreview] = useState(false);
  const [tab, setTab] = useState(StockTabIndex.ALBUM);
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const [selectedAsset, setSelectAsset] = useState<Asset>(null);
  const [selectedAssetIndex, setSelectAssetIndex] = useState<number>(null);
  const user = useSelector((state: RootState) => state.user.appUser);
  const { data: assets } = useQuery<Asset[]>({
    queryKey: ["creator/getUserAssets", user.active.uid],
    queryFn: () => getUserAssets({ uid: user.active.uid }),
    staleTime: Infinity,
    placeholderData: [],
  });

  const target = useMemo(
    () =>
      assets.filter((a) =>
        tab === StockTabIndex.DRAWING ? /.svg/.test(a.url) : !/.svg/.test(a.url)
      ),
    [assets, tab]
  );

  const handleTab = (tab: StockTabIndex) => () => {
    play();
    setTab(tab);
    setSelectAsset(null);
  };

  const pickImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files[0];
    if (file) {
      await pickImageAsset(
        file,
        user.active.uid,
        async (asset: Asset) => {
          inputRef.current.value = "";
          handleAction({
            type: ActionCommandType.ADD_COMPONENT,
            componentType: ComponentTypeIds.IMAGE,
            category: ComponentCategory.ACTION,
            screenId: selectedScreenId,
            image: {
              url: asset.url,
              size: asset.file_size,
              width: Number(asset.metadata.width),
              height: Number(asset.metadata.height),
            },
          });
          toast.info(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_TOAST_INSERT_ASSET_IMAGE_SUCCESS")}
              </p>
            </div>
          );
        },
        () => {
          inputRef.current.value = "";
        }
      );
    }
  };

  const handleSelectAsset = (
    asset: Asset,
    index: number
  ): MouseEventHandler<HTMLImageElement> => async (event) => {
    play();
    event.stopPropagation();
    setSelectAsset(asset);
    setSelectAssetIndex(index);
    setPreview(true);
  };

  const handleUpdateAsset = (asset: Asset) => {
    setSelectAsset(asset);
    if (queryClient.getQueryData(["creator/getUserAssets", user.active.uid])) {
      queryClient.setQueryData(
        ["creator/getUserAssets", user.active.uid],
        (old: Asset[]) =>
          old.map((a) => {
            if (a.id === asset.id) {
              return asset;
            } else {
              return a;
            }
          })
      );
    }
  };

  const handleAddAssetToScreen: MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    if (selectedAsset) {
      btnDelay(async () => {
        play();
        event.stopPropagation();
        switch (true) {
          case /.svg/.test(selectedAsset.url):
            try {
              handleDialog({ type: DialogType.LOADING, value: true });
              const response = await fetch(selectedAsset.url);
              const svg = await response.text();
              handleAction({
                type: ActionCommandType.ADD_COMPONENT,
                componentType: ComponentTypeIds.DRAWING,
                category: ComponentCategory.ACTION,
                screenId: selectedScreenId,
                drawing: {
                  svg,
                  width: Number(selectedAsset.metadata.width),
                  height: Number(selectedAsset.metadata.height),
                },
              });
              handleDialog({ type: DialogType.LOADING, value: false });
              toast.info(
                <div>
                  <p className="text text-textcolor/black">
                    {I18n.t("MSG_TOAST_INSERT_ASSET_IMAGE_SUCCESS")}
                  </p>
                </div>
              );
              setPreview(false);
              setTimeout(() => {
                setSelectAsset(null);
                setSelectAssetIndex(null);
              }, 500);
            } catch (error) {
              console.error(error);
              handleDialog({ type: DialogType.LOADING, value: false });
            }
            break;
          default:
            handleAction({
              type: ActionCommandType.ADD_COMPONENT,
              componentType: ComponentTypeIds.IMAGE,
              category: ComponentCategory.ACTION,
              screenId: selectedScreenId,
              image: {
                url: selectedAsset.url,
                size: selectedAsset.file_size,
                width: Number(selectedAsset.metadata.width),
                height: Number(selectedAsset.metadata.height),
              },
            });
            toast.info(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_TOAST_INSERT_ASSET_IMAGE_SUCCESS")}
                </p>
              </div>
            );
            setPreview(false);
            setTimeout(() => {
              setSelectAsset(null);
              setSelectAssetIndex(null);
            }, 500);
            break;
        }
      });
    }
  };

  const handleClose = () => {
    btnDelay(() => {
      play();
      setPreview(false);
      setTimeout(() => {
        setSelectAsset(null);
        setSelectAssetIndex(null);
      }, 500);
    });
  };

  const handleOpenLimitWarning = () => {
    btnDelay(() => {
      play();
      setWarning(true);
    });
  };
  const handleCloseLimitWarning = () => {
    btnDelay(() => {
      play();
      setWarning(false);
    });
  };

  const TitleComponent = ({ title }) => (
    <div className="w-full h-[32px] bg-beige/alt flex-col-el justify-center items-start rounded-t-[12px]">
      <p className="text-body leading-[30px] text-white !ml-[16px]">
        {title}
        <span
          className={`${
            target.length > ASSET_LIMIT ? "text-red/100" : "text-white"
          } ml-2`}
        >
          {target.length}/{ASSET_LIMIT}
        </span>
      </p>
    </div>
  );

  return (
    <div className="flex-row-el relative items-start bg-beige/60 p-[20px] rounded-[16px] border-solid border-white border-[4px] left-[-380px]">
      <DialogMessage
        open={warning}
        title={I18n.t("MSG_CREATOR_ASSET_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_ASSET_LIMIT_WARNING_MESSAGE")}
        close={handleCloseLimitWarning}
      />
      <ImagePreview
        asset={selectedAsset}
        limit={selectedAssetIndex + 1 > ASSET_LIMIT}
        visible={preview}
        readOnly={false}
        message={I18n.t("MSG_ASSET_ADD_TO_SCREEN_BTN")}
        update={handleUpdateAsset}
        confirm={handleAddAssetToScreen}
        close={handleClose}
      />
      <div className="flex-col-el bg-gray2/20 mt-8 rounded-l-2xl border-4 border-r-0 border-solid border-white overflow-hidden">
        <div
          onClick={handleTab(StockTabIndex.ALBUM)}
          className={`flex-col-el flex-center w-[120px] h-[80px] overflow-hidden cursor-pointer ${
            tab === StockTabIndex.ALBUM ? "bg-green/altcolor" : "bg-transparent"
          }`}
        >
          <span className="material-symbols-outlined text-[24px]">
            photo_library
          </span>
          <p className="text-body text-textcolor/black text-font-caption2-narrow">
            {I18n.t("MSG_CREATOR_MENU_ASSET_CATEGORY_ALBUM_TITLE")}
          </p>
        </div>
        <div
          onClick={handleTab(StockTabIndex.DRAWING)}
          className={`flex-col-el flex-center w-[120px] h-[80px] overflow-hidden cursor-pointer ${
            tab === StockTabIndex.DRAWING
              ? "bg-green/altcolor"
              : "bg-transparent"
          }`}
        >
          <span className="material-symbols-outlined text-[24px]">
            edit_square
          </span>
          <p className="text-body text-textcolor/black text-font-caption2-narrow">
            {I18n.t("MSG_CREATOR_MENU_ASSET_CATEGORY_DRAWING_TITLE")}
          </p>
        </div>
      </div>

      <div className="flex-col-el flex-center">
        <TitleComponent
          title={
            tab === StockTabIndex.ALBUM
              ? I18n.t("MSG_ASSET_ALBUM_TITLE")
              : I18n.t("MSG_ASSET_DRAWING_TITLE")
          }
        />

        <div
          ref={ref}
          className="flex-col-el relative w-[700px] h-[680px] bg-white rounded-b-xl visible-scrollbar"
        >
          <div className="flex-row-el flex-center shrink-0 w-full h-[90px]">
            <div className="flex-row-el flex-center p-2 bg-orange/alt rounded-3xl">
              <p className="text-body text-textcolor/black text-font-caption2">
                {tab === StockTabIndex.ALBUM
                  ? I18n.t("MSG_ASSET_DRAWING_ADD_ALBUM_TITLE")
                  : I18n.t("MSG_ASSET_DRAWING_ADD_WARNING_TITLE")}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-asset-auto-fill w-full bg-white items-start py-3 justify-center gap-2 z-10">
            {tab === StockTabIndex.ALBUM && (
              <div className="flex-col-el flex-center shrink-0 w-full h-full upsilon gap-3">
                <div className="btn btn-primary !p-0 !m-0 w-[160px] h-[160px] cursor-pointer bg-green/100 rounded-lg">
                  <div className="flex-col-center w-full h-full">
                    <input
                      ref={inputRef}
                      type="file"
                      onClick={() => play()}
                      onChange={pickImage}
                      accept="image/*"
                      className="absolute w-[160px] h-[160px] opacity-0 z-50 peer cursor-pointer"
                    />
                    <AddPhotoAlternateIcon
                      sx={{ fontSize: 84, color: "white" }}
                    />
                    <p className="text-body text-white text-font-size-body2">
                      {I18n.t("MSG_ASSET_ALBUM_ADD_TITLE")}
                    </p>
                  </div>
                </div>
                <div className="w-full h-[20px]" />
              </div>
            )}

            {target
              .sort((a, b) =>
                dayjs(a.created_at) < dayjs(b.created_at) ? 1 : -1
              )
              .map((asset, index) => (
                <div key={asset.id} className="flex-col-el relative">
                  <div
                    className={`flex-col-el relative flex-center w-[160px] h-[160px] bg-beige/20 p-2 border-solid ${
                      index + 1 > ASSET_LIMIT
                        ? "border-8 border-gray2/60"
                        : "frame"
                    }`}
                  >
                    <img
                      alt="*"
                      loading={index > 16 ? "lazy" : "eager"}
                      onClick={handleSelectAsset(asset, index)}
                      className="w-full h-full object-contain bg-white"
                      src={asset.url}
                    />
                    {index + 1 > ASSET_LIMIT && (
                      <div
                        onClick={handleOpenLimitWarning}
                        className="flex-col-el absolute w-full h-full z-10 bg-gray2/60 opacity-50 top-0 left-0 cursor-pointer"
                      />
                    )}
                  </div>
                  <p className="text-body text-textcolor/black text-font-caption2 h-[20px] text-body-ellipsis text-center !px-5">
                    {asset.name}
                  </p>
                </div>
              ))}
          </div>

          {target.length === 0 && (
            <div className="flex-col-el flex-center w-full h-full absolute">
              <p className="text-body text-gray/80 text-font-caption2-narrow">
                {tab === StockTabIndex.ALBUM
                  ? I18n.t("MSG_ASSET_ALBUM_EMPTY_MESSAGE")
                  : I18n.t("MSG_ASSET_DRAWING_EMPTY_MESSAGE")}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
