import { useMemo, useState, MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animated, useSpring } from "@react-spring/web";
import throttle from "lodash-es/throttle";
import { RootState, AppDispatch } from "app/store";
import { usePlaySound } from "common/utils";
import {
  IconProjectCardFavorited,
  IconProjectCardFavoritedReverse,
} from "features/creator/assets";
import { actions } from "features/creator/slice";
import { ProjectEntity } from "features/creator/types";
import { favoriteProject, stopFavoriteProject } from "features/creator/api";

export const FavoritedMark = ({
  project,
  handlePanelIndex,
}: {
  project: ProjectEntity;
  handlePanelIndex?: () => void;
}) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.appUser);
  const [favoritedProjectId, setFavoritedProjectId] = useState(null);
  const [favoritedStyles, favoritedApi] = useSpring(() => ({ from: { x: 1 } }));

  const handleFavoriteProject = useMemo(
    () =>
      throttle(
        (project: ProjectEntity) => {
          setFavoritedProjectId(project.project_id);
          favoritedApi.start({
            to: async (next) => {
              await next({ x: 1 });
              await next({ x: 1.2 });
              await next({ x: 1 });
            },
            config: { duration: 200 },
          });
          dispatch(
            actions.updateProjectFavorited({
              project_id: project.project_id,
              favorited: project.is_favorited
                ? project.favorited - 1
                : project.favorited + 1,
              is_favorited: !project.is_favorited,
            })
          );
          if (project.is_favorited) {
            stopFavoriteProject({
              uid: user.active.uid,
              project_id: project.project_id,
            });
          } else {
            favoriteProject({
              uid: user.active.uid,
              project_id: project.project_id,
            });
          }
        },
        1000,
        { trailing: false }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleFavorite = (project: ProjectEntity) => (e: MouseEvent) => {
    if (project.deleted_at) return;

    play();
    e.stopPropagation();
    if (handlePanelIndex) {
      handlePanelIndex();
    }
    handleFavoriteProject(project);
  };

  return (
    <animated.div
      id="creator-display-favorited-mark-btn"
      onClick={handleFavorite(project)}
      className="flex-row-el items-center pointer-events-auto"
      style={{
        transform:
          favoritedProjectId === project.project_id
            ? favoritedStyles.x.to((value) => `scale(${value})`)
            : "",
      }}
    >
      <div className="flex-row-el flex-center">
        {project.is_favorited ? (
          <div className="flex-col-center w-[32px] h-[32px]">
            <IconProjectCardFavorited />
          </div>
        ) : (
          <div className="flex-col-center w-[32px] h-[32px]">
            <IconProjectCardFavoritedReverse />
          </div>
        )}

        <div className="flex-col-center h-[30px]">
          <p
            className="text-body text-red/100 text-[25px] leading-8"
            style={{
              WebkitTextStroke: "6px #FEFDFE",
            }}
          >
            <span
              className="inline-block leading-8"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {project.favorited}
            </span>
            {project.favorited}
          </p>
        </div>
      </div>
    </animated.div>
  );
};
