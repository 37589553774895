import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/electronic/89ac81d0297168e63110e5778eff4617.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          "button_1",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          const targetEles = [9, 9];
          const result = [false, false];
          if(${result}){
            ${logic}
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitMathPlusBlock = () => {
  Blockly.Blocks["program_math_plus_block"] = {
    init: function () {
      this.jsonInit({
        type: "program_math_plus_block",
        message0: "%1+%2",
        args0: [
          {
            type: "input_value",
            name: "A",
            check: "Number",
          },
          {
            type: "input_value",
            name: "B",
            check: "Number",
          },
        ],
        inputsInline: true,
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["program_math_plus_block"] = function (block: Blockly.Block) {
    const A = BlocklyJs.valueToCode(block, "A", BlocklyJsOperator.ORDER_NONE);
    const B = BlocklyJs.valueToCode(block, "B", BlocklyJsOperator.ORDER_NONE);
    return [`(Number(${A}) + Number(${B}))`, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitHandGetterRotateBlock = () => {
  Blockly.Blocks["hand_getter_rotate_block"] = {
    init: function () {
      this.jsonInit({
        type: "hand_getter_rotate_block",
        message0: "%{BKY_GETTER_VALUE}",
        args0: [
          {
            type: "field_dropdown",
            name: "time",
            options: [
              ["%{BKY_COURSE_PROGRAM_CLOCK_SHOT_HAND}", "hour"],
              ["%{BKY_COURSE_PROGRAM_CLOCK_LONG_HAND}", "minute"],
            ],
          },
        ],
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["hand_getter_rotate_block"] = function (block: Blockly.Block) {
    const target = block.getField("time").getValue();
    const code = `document.getElementById("course-program-clock-${target}-hand").dataset.value`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitHandSetterRotateBlock = () => {
  Blockly.Blocks["hand_setter_rotate_block"] = {
    init: function () {
      this.jsonInit({
        type: "hand_setter_rotate_block",
        message0: "%{BKY_COURSE_PROGRAM_CLOCK_HOUR}",
        args0: [
          {
            type: "input_value",
            name: "hour",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["hand_setter_rotate_block"] = function (block: Blockly.Block) {
    const hour = BlocklyJs.valueToCode(
      block,
      "hour",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        const hour = document.getElementById("course-program-clock-hour-hand");
        const hour_value = Number(${hour});
        if(hour){
          result[0] = targetEles[0] === hour_value;
          hour.dataset.value = hour_value;
          await anime({
            targets: hour,
            rotate: 30 * hour_value,
            duration: 1000,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        };
        if(!result[0]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        highlightBlock("${block.id}", false);
    `;
  };
};

export const InitLabelTextShowSetterBlock = () => {
  Blockly.Blocks["label_text_show_setter_block"] = {
    init: function () {
      this.jsonInit({
        type: "label_text_show_setter_block",
        message0: "%1に%2%{BKY_DISPLAY_EVENT}",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [["ボックス1", "1"]],
          },
          {
            type: "input_value",
            name: "value",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "logic_blocks",
      });
    },
  };
  BlocklyJs["label_text_show_setter_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
      highlightBlock("${block.id}", true);
      const target = document.getElementById("course-program-label-1");
      const value = Number(${value});
      if(target){
        target.innerText = value;
        target.style.opacity = "1";
        target.style.transform = "scale(1)";
      };
      result[1] = targetEles[1] === value;
      await sleep(700);
      if(result[1]){
        answerMark = document.getElementById("course-program-answer-mark-wrong-1");
        if(answerMark){
          answerMark.style.opacity = "0";
        };
        answerMark = document.getElementById("course-program-answer-mark-right-1");
        if(answerMark){
          answerMark.style.opacity = "1";
        };
      } else {
        answerMark = document.getElementById("course-program-answer-mark-right-1");
        if(answerMark){
          answerMark.style.opacity = "0";
        };
        answerMark = document.getElementById("course-program-answer-mark-wrong-1");
        if(answerMark){
          answerMark.style.opacity = "1";
        };

      };
      await sleep(700);
      highlightBlock("${block.id}", false);
    `;
  };
};
