import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Blockly from "blockly";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import BlocklyJs from "blockly/javascript";
import { DisableTopBlocks } from "@blockly/disable-top-blocks";
import { uploadImageFile } from "app/api";
import { RootState, AppDispatch } from "app/store";
import {
  InitMsg,
  InitColorBlock,
  InitVariableBlock,
  InitMathInitBlock,
  InitProceduresCallBlock,
} from "common/blockly";
import {
  InitSoundBlock,
  InitMediaBlock,
  InitAngleBlock,
  ComponentTypeIds,
  InitTextFontBlock,
} from "common/components";
import Constants from "common/constant";
import { SpinModal } from "common/elements";
import { dataURLToBlob } from "common/utils";
import {
  actions,
  selectScreenById,
  selectAllScreens,
  selectBlocklyById,
  selectProjectById,
} from "features/creator/slice";
import { ProjectType } from "features/creator/types";
import { updateProjectCapture } from "features/creator/api";
import { ToolboxXml } from "features/creator/blockly/toolboxXml";
import { RenderScreenComponent } from "features/creator/screenList";
import { InitComponentBlockly } from "features/creator/blockly/InitBlockly";
import { creatorBlocklyOptionsForCapture } from "features/creator/blockly/creatorBlocklyOptions";

const CaptureMain = () => {
  const CAPTURE_PIXEL_RATIO = 4;
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const blocklyRef = useRef<HTMLDivElement>(null);
  const defaultScreenId = useSelector(
    (state: RootState) => state.creator.defaultScreenId
  );
  const defaultScreen = useSelector((state: RootState) =>
    selectScreenById(state, defaultScreenId)
  );
  const editingProjectId = useSelector(
    (state: RootState) => state.creator.editingProjectId
  );
  const project = useSelector((state: RootState) =>
    selectProjectById(state, editingProjectId)
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);

  const [screenCaptured, setScreenCaptured] = useState(false);
  const [blocklyCaptured, setBlocklyCaptured] = useState(
    project?.type !== ProjectType.TEMPLATE
  );
  const [screenCaptureImage, setScreenCaptureImage] = useState<string | null>(
    null
  );
  const [blocklyCaptureImage, setBlocklyCaptureImage] = useState<string | null>(
    null
  );
  const blockly = useSelector((state: RootState) =>
    selectBlocklyById(state, defaultScreenId)
  );
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);
  const allScreens = useSelector((state: RootState) => selectAllScreens(state));

  const uploadCapture = useCallback(async (targetImgUri: string) => {
    const uuid = uuidv4();
    const fileBlob = dataURLToBlob(targetImgUri);
    const filename = `users/${user.active.uid}/capture/${uuid}.png`;

    await uploadImageFile({
      is_sync: false,
      fileBlob,
      filename,
    });

    return `${Constants.assetHost}/${filename}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project?.type === ProjectType.TEMPLATE) {
      InitSoundBlock();
      InitMediaBlock();
      InitAngleBlock();
      InitColorBlock();
      InitVariableBlock();
      InitMathInitBlock();
      InitTextFontBlock();
      InitMsg(config.locale);
      InitProceduresCallBlock();

      const workspace = Blockly.inject(
        "capture-blockly-div",
        creatorBlocklyOptionsForCapture(
          document.getElementById("capture-toolbox")
        )
      );

      workspace.addChangeListener(Blockly.Events.disableOrphans);
      setWorkspace(workspace);

      const disableTopBlocksPlugin = new DisableTopBlocks();
      disableTopBlocksPlugin.init();

      BlocklyJs.init(workspace);

      InitComponentBlockly(
        allScreens.map((screen) => [
          screen.name,
          `${ComponentTypeIds.SCREEN}_${screen.id}`,
        ]),
        defaultScreen,
        selectedComponentId
      );

      if (blockly && blockly.xmlText) {
        try {
          const dom = Blockly.Xml.textToDom(blockly.xmlText);
          Blockly.Xml.domToWorkspace(dom, workspace);
        } catch (error) {
          console.log(error);
          console.log(blockly.xmlText);
        }
      }
    }

    return () => {
      if (workspace) {
        workspace.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (screenCaptured && blocklyCaptured) {
      updateProjectCapture({
        uid: user.active.uid,
        project_id: editingProjectId,
        project: {
          ...project,
          capture: screenCaptureImage,
          blockly_capture: blocklyCaptureImage,
        },
      }).finally(() => {
        navigate("/creator?is_backward=true");
      });
    }
    return () => {
      if (screenCaptured && blocklyCaptured) {
        dispatch(actions.updateCapture(false));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenCaptured, blocklyCaptured]);

  useEffect(() => {
    if (
      project?.type === ProjectType.TEMPLATE &&
      workspace &&
      blocklyRef.current
    ) {
      let captureOptions: object;
      if (workspace.getTopBlocks(true).length > 0) {
        workspace.zoomToFit();
        const blocksBoundingBox = workspace.getBlocksBoundingBox();
        workspace.scroll(
          -blocksBoundingBox.left * workspace.scale + 10,
          -blocksBoundingBox.top * workspace.scale + 10
        );
        captureOptions = {
          width:
            (blocksBoundingBox.right - blocksBoundingBox.left) *
              workspace.scale +
            20,
          height:
            (blocksBoundingBox.bottom - blocksBoundingBox.top) *
              workspace.scale +
            20,
          proxy: `${Constants.apiHost}/capture/proxy`,
        };
      } else {
        captureOptions = { proxy: `${Constants.apiHost}/capture/proxy` };
      }

      html2canvas(blocklyRef.current, captureOptions).then(async (canvas) => {
        const targetImgUri = canvas.toDataURL("img/png");
        const imageUrl = await uploadCapture(targetImgUri);
        setBlocklyCaptureImage(imageUrl);
        setBlocklyCaptured(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, blocklyRef.current]);

  useEffect(() => {
    if (project.cover) {
      navigate("/creator?is_backward=true");
      dispatch(actions.updateCapture(false));
    } else if (ref.current) {
      let captureOptions: object;
      if (project?.type === ProjectType.TEMPLATE) {
        const components = ref.current.children;
        if (components.length > 0) {
          const pointX = [];
          const pointY = [];
          for (let i = 0; i < components.length; i++) {
            const element = components.item(i);
            const elementRect = element.getBoundingClientRect();

            if (elementRect.x < 0) {
              pointX.push(0);
            } else {
              pointX.push(elementRect.x);
            }

            if (elementRect.right > 1024 * CAPTURE_PIXEL_RATIO) {
              pointX.push(1024 * CAPTURE_PIXEL_RATIO);
            } else {
              pointX.push(elementRect.right);
            }

            if (elementRect.y < 0) {
              pointY.push(0);
            } else {
              pointY.push(elementRect.y);
            }

            if (elementRect.bottom > 768 * CAPTURE_PIXEL_RATIO) {
              pointY.push(768 * CAPTURE_PIXEL_RATIO);
            } else {
              pointY.push(elementRect.bottom);
            }
          }

          const start = {
            x: Math.min(...pointX),
            y: Math.min(...pointY),
          };
          const end = {
            x: Math.max(...pointX),
            y: Math.max(...pointY),
          };

          captureOptions = {
            x: start.x,
            y: start.y,
            width: end.x - start.x,
            height: end.y - start.y,
            proxy: `${Constants.apiHost}/capture/proxy`,
          };
        } else {
          captureOptions = { proxy: `${Constants.apiHost}/capture/proxy` };
        }
      } else {
        captureOptions = { proxy: `${Constants.apiHost}/capture/proxy` };
      }

      html2canvas(ref.current, captureOptions)
        .then(async (canvas) => {
          const targetImgUri = canvas.toDataURL("img/png");
          const imageUrl = await uploadCapture(targetImgUri);
          setScreenCaptureImage(imageUrl);
          setScreenCaptured(true);
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          setScreenCaptured(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        ref={ref}
        className="flex-col-view w-[1024px] h-[768px] !absolute top-0 left-0 z-[-1000] origin-top-left"
        style={{
          transform: `scale(${
            project?.type === ProjectType.TEMPLATE ? CAPTURE_PIXEL_RATIO : 1
          })`,
        }}
      >
        {defaultScreen.children.map((component) => (
          <RenderScreenComponent
            key={component.id}
            id={component.id}
            zIndex={defaultScreen.childrenOrder.indexOf(component.id)}
          />
        ))}
      </div>

      {!project?.cover && project?.type === ProjectType.TEMPLATE && (
        <div className="flex-col-view w-[1024px] h-[768px] overflow-hidden !absolute top-0 left-0 z-[-1000]">
          <div
            id="capture-blockly-div"
            ref={blocklyRef}
            className="flex-col-view w-full h-full"
          />

          <ToolboxXml toolboxId="capture-toolbox" />
        </div>
      )}
    </>
  );
};

export const Capture = () => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <SpinModal
        onEntered={() => {
          setLoading(true);
        }}
      />
      {loading && <CaptureMain />}
    </>
  );
};
