import { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import * as Blockly from "blockly";
import { v4 as uuidv4 } from "uuid";
import BlocklyJs from "blockly/javascript";
import { getRandomArbitrary } from "common/utils";
import { ComponentTypeIds } from "common/components";
import {
  Grouping,
  BlocklyEntity,
  ScreensEntity,
  ComponentsEntity,
  PropertiesEntity,
  CreatorInitialState,
} from "features/creator/types";
import {
  blocklyAdapter,
  screensAdapter,
  componentsAdapter,
  propertiesAdapter,
} from "features/creator/slice/reducers/adapter";
import { HISTORY_STACK_MAX } from "features/creator/constants";
import { InitComponentBlockly } from "features/creator/blockly/InitBlockly";

const reducers = {
  handleAddTemplate(
    state: CreatorInitialState,
    action: PayloadAction<{
      content: {
        screens: Dictionary<ScreensEntity>;
        blockly: Dictionary<BlocklyEntity>;
        components: Dictionary<ComponentsEntity>;
        properties: Dictionary<PropertiesEntity>;
      };
    }>
  ) {
    const grouping = state.grouping;
    const { screens, blockly, properties } = action.payload.content;
    const screenId = Object.keys(screens)[0];
    const screen = screens[screenId] as ScreensEntity;

    const selectedScreenId = state.selectedScreenId;

    const newPropertyEntities = [];
    const newComponentIds: string[] = [];
    const randomOffset = {
      x: getRandomArbitrary(-100, 100),
      y: getRandomArbitrary(-100, 100),
    };

    const componentIdMapping = Array.from(screen.childrenOrder).reduce(
      (acc, componentId) => {
        const newComponentId = uuidv4();

        const newPropertyEntity = produce(properties[componentId], (draft) => {
          draft.id = newComponentId;
          draft.screenId = selectedScreenId;
          draft.property.style.transform.translateX += randomOffset.x;
          draft.property.style.transform.translateY += randomOffset.y;
        });

        propertiesAdapter.addOne(state.properties, newPropertyEntity);
        newPropertyEntities.push(newPropertyEntity);
        newComponentIds.push(newComponentId);
        return Object.assign(acc, { [componentId]: newComponentId });
      },
      {}
    );

    const selectedScreen: ScreensEntity = screensAdapter
      .getSelectors()
      .selectById(state.screens, selectedScreenId);

    const newScreenChildrenComponents = screen.children.map((component) => ({
      ...component,
      id: componentIdMapping[component.id],
    }));
    const newScreenChildrenComponentIds = screen.childrenOrder.map(
      (componentId) => componentIdMapping[componentId]
    );
    const newScreen = produce(selectedScreen, (draft) => {
      draft.children = draft.children.concat(newScreenChildrenComponents);
      draft.childrenOrder = draft.childrenOrder.concat(
        newScreenChildrenComponentIds
      );
    });
    screensAdapter.updateOne(state.screens, {
      id: selectedScreenId,
      changes: newScreen,
    });

    const selectedScreenComponents: ComponentsEntity = componentsAdapter
      .getSelectors()
      .selectById(state.components, selectedScreenId);

    const newComponents = produce(selectedScreenComponents, (draft) => {
      newScreenChildrenComponents.forEach((component) => {
        if (draft.data[component.typeId]) {
          draft.data[component.typeId].push({
            id: component.id,
            deleted: false,
          });
        } else {
          draft.data[component.typeId] = [{ id: component.id, deleted: false }];
        }
      });
    });
    componentsAdapter.updateOne(state.components, {
      id: selectedScreenId,
      changes: newComponents,
    });

    const selectedScreenBlockly = blocklyAdapter
      .getSelectors()
      .selectById(state.blockly, selectedScreenId);

    const blocklyXmlText = blockly[screenId].xmlText as string;
    const xmlTagPrefix =
      '<xml xmlns="https://developers.google.com/blockly/xml">';
    const xmlTagSuffix = "</xml>";

    let newScreenBlocklyXmlText: string;
    let newBlockIds: string[] = [];

    if (blocklyXmlText) {
      const allScreens: ScreensEntity[] = screensAdapter
        .getSelectors()
        .selectAll(state.screens);

      InitComponentBlockly(
        allScreens.map((screen) => [
          screen.name,
          `${ComponentTypeIds.SCREEN}_${screen.id}`,
        ]),
        newScreen,
        state.selectedComponentId
      );

      var newTemplateBlocklyXmlText = blocklyXmlText;
      screen.childrenOrder.forEach((componentId) => {
        newTemplateBlocklyXmlText = newTemplateBlocklyXmlText.replaceAll(
          `${componentId}`,
          `${componentIdMapping[componentId]}`
        );
      });
      newTemplateBlocklyXmlText = newTemplateBlocklyXmlText.replaceAll(
        `${screenId}`,
        `${selectedScreenId}`
      );

      // replace block id
      const workspace = new Blockly.Workspace();
      BlocklyJs.init(workspace);
      const dom = Blockly.Xml.textToDom(newTemplateBlocklyXmlText);
      newBlockIds = Blockly.Xml.domToWorkspace(dom, workspace);
      workspace
        .getTopBlocks(false)
        .forEach((block) => block.moveBy(randomOffset.x, randomOffset.y));
      BlocklyJs.init(workspace);
      const xml = Blockly.Xml.workspaceToDom(workspace);
      const template_xml_text = Blockly.Xml.domToText(xml);

      const newBlocklyBlockXmlText = template_xml_text
        .replace(xmlTagPrefix, "")
        .replace(xmlTagSuffix, "");
      var newBlocklyXmlText = newBlocklyBlockXmlText;

      if (selectedScreenBlockly.xmlText) {
        const selectedBlocklyBlockXmlText = selectedScreenBlockly.xmlText
          .replace(xmlTagPrefix, "")
          .replace(xmlTagSuffix, "");

        newScreenBlocklyXmlText = xmlTagPrefix
          .concat(selectedBlocklyBlockXmlText)
          .concat(newBlocklyXmlText)
          .concat(xmlTagSuffix);
      } else {
        newScreenBlocklyXmlText = xmlTagPrefix
          .concat(newBlocklyXmlText)
          .concat(xmlTagSuffix);
      }

      workspace.clear();

      const newDom = Blockly.Xml.textToDom(newScreenBlocklyXmlText);
      Blockly.Xml.domToWorkspace(newDom, workspace);
      const newXml = Blockly.Xml.workspaceToDom(workspace);
      newScreenBlocklyXmlText = Blockly.Xml.domToText(newXml);
      blocklyAdapter.upsertOne(state.blockly, {
        screenId: selectedScreenId,
        xmlText: newScreenBlocklyXmlText,
      });

      workspace.clear();
      workspace.dispose();
    }

    let newGrouping: Grouping;
    let selectedComponentId: string;
    let computeComponentRectNode: Element;
    if (screen.childrenOrder.length === 0) {
      selectedComponentId = state.selectedScreenId;
    } else if (screen.childrenOrder.length === 1) {
      selectedComponentId = componentIdMapping[screen.childrenOrder[0]];
    } else {
      try {
        computeComponentRectNode = document.getElementById(
          "compute-component-rect"
        );

        if (computeComponentRectNode.children.length > 0) {
          const childrenLength = computeComponentRectNode.children.length;
          for (let i = 0; i < childrenLength; i++) {
            const element = computeComponentRectNode.children.item(0);
            computeComponentRectNode.removeChild(element);
          }
        }

        screen.childrenOrder.forEach((componentId) => {
          const componentNode = document.createElement("div");
          componentNode.id = `compute-component-rect-node-${componentId}`;
          componentNode.style.position = "absolute";
          componentNode.style.left = "0px";
          componentNode.style.top = "0px";
          componentNode.style.transform = `translate(${
            properties[componentId].property.style.transform.translateX +
            randomOffset.x -
            24
          }px, ${
            properties[componentId].property.style.transform.translateY +
            randomOffset.y -
            24
          }px) rotate(${
            properties[componentId].property.style.transform.rotation
          }deg)`;
          componentNode.style.width = `${
            properties[componentId].property.style.layout.width +
            properties[componentId].property.style.view.borderWidth +
            properties[componentId].property.style.shadow.shadowRadius
          }px`;
          componentNode.style.height = `${
            properties[componentId].property.style.layout.height +
            properties[componentId].property.style.view.borderWidth +
            properties[componentId].property.style.shadow.shadowRadius
          }px`;
          componentNode.style.padding = `${24}px`;

          computeComponentRectNode.append(componentNode);
        });

        const computeComponentRectNodeChildren =
          computeComponentRectNode.children;

        const pointX = [];
        const pointY = [];

        for (let i = 0; i < computeComponentRectNodeChildren.length; i++) {
          const element = computeComponentRectNodeChildren.item(i);

          const elementId = element
            .getAttribute("id")
            .split("compute-component-rect-node-")[1];
          const elementRect = element.getBoundingClientRect();

          if (!elementId) continue;

          pointX.push(elementRect.x);
          pointX.push(elementRect.right);
          pointY.push(elementRect.y);
          pointY.push(elementRect.bottom);
        }

        const start = {
          x: Math.min(...pointX),
          y: Math.min(...pointY),
        };
        const end = {
          x: Math.max(...pointX),
          y: Math.max(...pointY),
        };

        newGrouping = {
          enable: true,
          componentIds: newScreenChildrenComponentIds,
          position: start,
          size: { x: end.x - start.x, y: end.y - start.y },
        };
        state.grouping = newGrouping;
        selectedComponentId = state.selectedScreenId;
      } catch (error) {
        console.log(error);
      } finally {
        if (
          computeComponentRectNode &&
          computeComponentRectNode.children.length > 0
        ) {
          const childrenLength = computeComponentRectNode.children.length;
          for (let i = 0; i < childrenLength; i++) {
            const element = computeComponentRectNode.children.item(0);
            computeComponentRectNode.removeChild(element);
          }
        }
      }
    }

    state.undoStacks.push({
      before: {
        grouping,
        selectedComponentId: state.selectedComponentId,
        screen: selectedScreen,
        components: selectedScreenComponents,
        properties: [],
        blockly: newScreenBlocklyXmlText ? selectedScreenBlockly : undefined,
      },
      after: {
        selectedComponentId: selectedComponentId,
        screen: newScreen,
        grouping: newGrouping,
        components: newComponents,
        properties: newPropertyEntities,
        blockly: newScreenBlocklyXmlText
          ? {
              screenId: selectedScreenId,
              xmlText: newScreenBlocklyXmlText,
            }
          : undefined,
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];
    state.selectedComponentId = selectedComponentId;
    state.template = { newComponentIds, newBlockIds };
  },
  handleClearTemplate(state: CreatorInitialState) {
    state.template = { newComponentIds: [], newBlockIds: [] };
  },
};

export default reducers;
