import { SVGProps } from "react";

export const AllStageClearHouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={156}
    height={184}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M51.883 172.806s-1.616-22.651-4.369-37.825c-2.198 1.46-4.907 1.314-7.717-2.395-.524 5.652-7.077 10.048-9.567 2.702-.291 5.505-7.602 10.924-10.892 3.359-.874 5.053-8.126 8.762-10.529 1.664-.64 2.279-2.01 5.682-5.373 5.842a4.838 4.838 0 0 1-3.014-1.11c1.34 14.429 5.417 37.972 5.417 37.972l46.044-10.209Z"
      fill="#C7ACA2"
    />
    <path
      d="M40.642 118.186a17.485 17.485 0 0 0-6.028-4.382 60.983 60.983 0 0 1-24.071 2.016 18.752 18.752 0 0 0-6.728 5.842h.525c12.207.762 24.459-.411 36.302-3.476Z"
      fill="#733070"
    />
    <path
      d="M18.654 112.987a31.927 31.927 0 0 0-8.111 2.921c8.077.939 16.26.254 24.07-2.016-4.28-1.942-9.581-2.307-15.96-.905Z"
      fill="#306B4A"
    />
    <path
      d="M5.3 129.592c-1.456 0-3 .131-4.66.219a41.003 41.003 0 0 0-.64 7.433c2.46-.102 4.98-.525 6.582-.613 13.455-.73 26.706-3.68 39.768-7.112a32.983 32.983 0 0 0-1.675-4.878A168.137 168.137 0 0 1 5.3 129.592Z"
      fill="#733070"
    />
    <path
      d="M0 137.244v.526c0 1.884.16 4.381.437 7.229a4.839 4.839 0 0 0 3.014 1.11c3.364-.117 4.733-3.519 5.373-5.841 2.403 7.141 9.655 3.388 10.529-1.665 3.29 7.565 10.6 2.146 10.892-3.359 2.49 7.302 9.043 2.92 9.567-2.702 2.81 3.709 5.519 3.87 7.718 2.395a412.357 412.357 0 0 0-.656-3.33c-.16-.73-.335-1.46-.524-2.132-13.105 3.432-26.313 6.382-39.768 7.112-1.558.132-4.121.555-6.582.657ZM44.675 124.641a23.371 23.371 0 0 0-4.033-6.455 115.985 115.985 0 0 1-36.303 3.592h-.524a20.987 20.987 0 0 0-3.16 7.989c1.66-.088 3.262-.204 4.66-.219a168.151 168.151 0 0 0 39.36-4.907Z"
      fill="#306B4A"
    />
    <path
      d="m16.513 167.432-2.796-19.745 21.041-3.52 3.801 19.906-22.046 3.359Z"
      fill="#fff"
    />
    <path
      d="m18.231 165.431-2.024-15.963 17.27-3.607 2.913 16.678-18.159 2.892Z"
      fill="#5776AD"
    />
    <path
      d="m23.692 147.044 3.553 18.139 1.806-.102-3.233-18.884-2.126.847Z"
      fill="#fff"
    />
    <path
      d="m15.712 154.01 19.367-3.184.291 1.081-19.512 3.198-.146-1.095ZM17.066 159.984l19.382-3.184.291 1.081-19.527 3.198-.146-1.095Z"
      fill="#fff"
    />
    <path
      d="M148.486 174.909s6.247-42.44 7.281-71.561c-4.645 1.811-9.567.409-13.236-7.55-3.277 10.222-17.14 15.611-18.756 1.05-2.781 10.048-18.435 17.029-21.464 1.739-3.64 8.981-18.552 12.91-20.08-1.227-2.097 3.928-6.015 9.653-12.262 8.485a9.085 9.085 0 0 1-5.125-3.286c-3.379 27.193-5.432 72.306-5.432 72.306l89.074.044Z"
      fill="#D3CDC8"
    />
    <path
      d="m85.434 150.505 2.868-37.548 40.249 2.103-1.078 38.278-42.04-2.833Z"
      fill="#fff"
    />
    <path
      d="m89.439 147.555 2.752-30.304 33.317.424-1.427 31.954-34.642-2.074Z"
      fill="#73AACC"
    />
    <path
      d="m106.985 115.864-.815 34.89 3.364.54 1.703-36.131-4.252.701Z"
      fill="#fff"
    />
    <path
      d="m89.41 125.429 37.044 2.06.088 2.103-37.308-2.074.175-2.089ZM89.496 136.996l37.045 2.074.087 2.103-37.307-2.088.175-2.089Z"
      fill="#fff"
    />
    <path
      d="M92.919 28.179c-.248 4.06-.554 28.45-.554 28.45l44.239.934s1.005-19.41 1.296-28.128l-44.981-1.256Z"
      fill="#D3CDC8"
    />
    <path
      d="m113.757 36.138-.335 5.871 4.674-.044.335-5.476-4.674-.35ZM126.804 36.678l-.335 5.857 4.674-.044.335-5.477-4.674-.336ZM100.316 35.612l-.35 5.871 4.675-.043.35-5.477-4.675-.35ZM116.188 1c-.874 0-1.704.088-2.519.16a70.37 70.37 0 0 0-8.854 27.355l11.286.321a7293 7293 0 0 0 2.097-27.821c-.656-.015-1.311-.03-2.01-.015Z"
      fill="#2E3B74"
    />
    <path
      d="M92.919 28.062v.117l11.897.336a70.368 70.368 0 0 1 8.853-27.354c-20.634 2.03-20.502 23.352-20.75 26.9ZM124.43 1.862a28.139 28.139 0 0 0-6.159-.847c-.539 7.462-1.456 19.876-2.097 27.821l11.649.321a78.418 78.418 0 0 0-3.393-27.295Z"
      fill="#265881"
    />
    <path
      d="M137.9 29.435v-2.41c.087-5.842 1.077-21.337-13.601-25.163a78.446 78.446 0 0 1 3.335 27.295l10.266.278Z"
      fill="#2E3B74"
    />
    <path
      d="M149.972 69.568a33.479 33.479 0 0 0-9.363-10.486 115.032 115.032 0 0 1-45.215-6.105 34.917 34.917 0 0 0-14.794 8.15l.946.262a219.784 219.784 0 0 0 68.426 8.179Z"
      fill="#3E7998"
    />
    <path
      d="M111.513 50.976a59.088 59.088 0 0 0-16.12 2 115.03 115.03 0 0 0 45.215 6.106c-7.135-5.258-16.746-8.091-29.095-8.106Z"
      fill="#155287"
    />
    <path
      d="M80.148 76.14c-2.578-.555-5.578-.979-8.737-1.46a78.389 78.389 0 0 0-4.194 13.45c4.587.803 9.421 1.051 12.377 1.548 25.119 4.162 50.763 4.133 76.26 3.154a64.415 64.415 0 0 0-1.107-9.682 318.349 318.349 0 0 1-74.6-7.01Z"
      fill="#3E7998"
    />
    <path
      d="m67.26 88.1-.218.95c-.757 3.49-1.456 8.164-2.14 13.509a9.085 9.085 0 0 0 5.125 3.286c6.247 1.168 10.193-4.557 12.261-8.485 1.53 14.137 16.44 10.223 20.081 1.227 3.029 15.29 18.683 8.309 21.464-1.738 1.616 14.604 15.479 9.157 18.755-1.052 3.67 7.959 8.592 9.362 13.237 7.551.087-2.206.146-4.382.175-6.397 0-1.46 0-2.79-.102-4.148-25.498.979-51.141 1.008-76.26-3.154-2.956-.497-7.79-.774-12.378-1.548ZM154.791 83.12a44.56 44.56 0 0 0-4.82-13.552 219.785 219.785 0 0 1-68.44-8.237l-.947-.263A39.319 39.319 0 0 0 71.44 74.65c3.117.511 6.116.935 8.738 1.46a318.344 318.344 0 0 0 74.614 7.01Z"
      fill="#155287"
    />
  </svg>
);
