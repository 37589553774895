import { SVGProps } from "react";

export const IconCircleMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 24"
    {...props}
  >
    <path
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      fill="#94C4F7"
    />
    <path
      d="M6 12h12"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48 7.831c-10.044 0-14.949 5.784-14.949 5.784"
      stroke="#94C4F7"
      strokeWidth={4}
    />
    <path d="M39.944 15 32 13.928 33.074 6" stroke="#94C4F7" strokeWidth={4} />
  </svg>
);
