import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "app/store";
import { useScale } from "common/utils";
import { TutorialArrow } from "common/assets";
import { btnDelay, usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { ProjectPanelType } from "features/creator/types";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("user-project-panel-drawer");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 25 * scale,
        y: rect.y - 25 * scale,
        width: rect.width + 50 * scale,
        height: rect.height + 50 * scale,
      });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  const handleClick = () => {
    play();
    btnDelay(() => {
      next();
      dispatch(actions.updateProjectPanel(ProjectPanelType.USER));
    });
  };

  return (
    <Overlay area={panelRect} onClick={handleClick}>
      <div
        className="absolute z-50"
        style={{
          width: 108 * scale,
          height: 105 * scale,
          transform: `translate(${panelRect.x - 100 * scale}px, ${
            panelRect.y + 100 * scale
          }px) rotate(-45deg)`,
        }}
      >
        <TutorialArrow />
      </div>
    </Overlay>
  );
};

export default Step;
