import { Subscription } from "common/elements";
import { ProjectPreview } from "features/preview";
import { UserPage } from "features/user/UserPage";
import { Rating, Feedback } from "features/review";
import { UniversalPanel } from "features/universal";
import { AppLoading } from "features/top/AppLoading";
import { Contact } from "features/universal/Contact";
import { SmartAppBanner } from "features/install/SmartAppBanner";
import { PwaInstallBanner } from "features/install/PwaInstallBanner";

export const DialogPage = () => {
  return (
    <>
      <Rating />
      <Contact />
      <Feedback />
      <UserPage />
      <AppLoading />
      <Subscription />
      <UniversalPanel />
      <ProjectPreview />
      <SmartAppBanner />
      <PwaInstallBanner />
    </>
  );
};
