/* eslint-disable no-new-func */
import { useState } from "react";
import Constants from "common/constant";
import { sleep, btnDelay, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const sound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/itemget.mp3`
  );
  const [clicked, setClicked] = useState(false);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "sleep",
    "sound",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      btnDelay(() => {
        play();
        setRunning(true);
        setClicked(true);
        handleEvent(sleep, sound, highlightBlock, setSuccess, setGameOver)();
      });
    }
  };
  return (
    <div className="flex-col-el flex-center gap-2 mt-8">
      <div className="flex-row-view flex-center w-full gap-2">
        <div
          id="course-program-border-1"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-1"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={1} />
        </div>
        <p
          id="course-program-arrow-1"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-2"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-2"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={2} />
        </div>
        <p
          id="course-program-arrow-2"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-3"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-3"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={3} />
        </div>
      </div>

      <div className="flex-row-el flex-center w-full h-[50px] relative shrink-0">
        <div className="flex-row-el flex-center upsilon absolute bottom-0 pointer-events-auto cursor-pointer z-50">
          <button
            disabled={clicked}
            onClick={handleClick}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン１</p>
          </button>
        </div>
      </div>
    </div>
  );
};
