import React, {
  useRef,
  useState,
  CSSProperties,
  PointerEvent,
  PointerEventHandler,
  useEffect,
} from "react";
import { Vector2D } from "common/types";
import { Mask } from ".";

export const MovePanel = (props: {
  scale?: number;
  size: Vector2D;
  style?: CSSProperties;
  offset?: Vector2D | undefined;
  position: Vector2D;
  reset?: boolean | undefined;
  disable?: boolean;
  onClickStart?: (e: PointerEvent) => void | undefined;
  onClickMove?: (e: PointerEvent, delta: Vector2D) => void | undefined;
  onClickEnd?: (e: PointerEvent, position?: Vector2D) => void | undefined;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | null;
}) => {
  const {
    size,
    reset,
    style,
    disable,
    onClickStart,
    onClickMove,
    onClickEnd,
    children,
  } = props;
  const scale = props.scale ? props.scale : 1;
  const [clicked, setClicked] = useState(false);
  const [position, _setPosition] = useState(props.position);
  const positionRef = useRef(position);
  const setPosition = (data: Vector2D) => {
    positionRef.current = data;
    _setPosition(data);
  };
  const offset = props.offset ? props.offset : { x: 0, y: 0 };
  const [preClientXY, _setPreClientXY] = useState<Vector2D>({ x: 0, y: 0 });
  const preClientRef = useRef(preClientXY);
  const setPreClientXY = (data: Vector2D) => {
    preClientRef.current = data;
    _setPreClientXY(data);
  };

  useEffect(() => {
    setPosition(props.position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.position]);

  const handleStart = (e: PointerEvent, xy: Vector2D) => {
    setPreClientXY(xy);
    if (typeof onClickStart !== "undefined") {
      onClickStart(e);
    }
  };

  const handleMove = (e: PointerEvent<HTMLDivElement>, xy: Vector2D) => {
    const dx = (xy.x - preClientRef.current.x) / scale;
    const dy = (xy.y - preClientRef.current.y) / scale;
    setPreClientXY(xy);
    setPosition({
      x: positionRef.current.x + dx,
      y: positionRef.current.y + dy,
    });
    if (typeof onClickMove !== "undefined") {
      onClickMove(e, { x: dx, y: dy });
    }
  };

  const handleEnd: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onClickEnd !== "undefined") {
      onClickEnd(e, positionRef.current);
    }
    if (reset) {
      setPosition({ x: 0, y: 0 });
    }
  };

  const onPointerStart: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setClicked(true);
    }
    handleStart(e, { x: e.clientX, y: e.clientY });
  };

  const onPointerMove: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "touch" || clicked) {
      handleMove(e, { x: e.clientX, y: e.clientY });
    }
  };

  const onPointerEnd: PointerEventHandler<HTMLDivElement> = (e) => {
    if (e.pointerType === "mouse") {
      setClicked(false);
    }
    handleEnd(e);
  };

  return (
    <>
      {clicked && (
        <Mask onPointerMove={onPointerMove} onPointerUp={onPointerEnd} />
      )}
      <div
        className="z-[10000] absolute top-0 left-0 cursor-grab"
        style={{
          pointerEvents: disable ? "none" : "auto",
          backfaceVisibility: "hidden",
          width: size.x,
          height: size.y,
          transform: `translate(${positionRef.current.x + offset.x}px, ${
            positionRef.current.y + offset.y
          }px)`,
          willChange: "transform",
          ...style,
        }}
        onPointerDown={onPointerStart}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerEnd}
      >
        {children}
      </div>
    </>
  );
};
