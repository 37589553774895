import $ from "jquery";
import anime from "animejs";
import {
  setSize,
  calcOffset,
  setSizeByTime,
  getTransformPropertyValue,
} from "common/blockly/codeGen";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const setLabelTextFontSize = (id: string, value: number) => {
  const container = $(`#label-container-${id}`);
  const text = $(`#label-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const fontSizeScale = value / currentFontSize;

  const [newContainerSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth * fontSizeScale,
    currentContainerHeight * fontSizeScale
  );

  text.css("font-size", `${value}px`);
  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);
};
export const setLabelTextFontSizeByStep = (id: string, value: number) => {
  const text = $(`#label-text-${id}`);
  const currentFontSize = Number(text.css("font-size").split("px")[0]);
  setLabelTextFontSize(id, currentFontSize + value);
};
export const setLabelTextFontSizeByTime = (
  id: string,
  time: number,
  value: number
) => {
  const container = $(`#label-container-${id}`);
  const text = $(`#label-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const fontSizeScale = value / currentFontSize;

  const [, , offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth * fontSizeScale,
    currentContainerHeight * fontSizeScale
  );

  const animation1 = anime({
    targets: `#label-container-${id}`,
    translateX: `-=${offset.x}`,
    translateY: `+=${offset.y}`,
    width: `+=${currentContainerWidth * (fontSizeScale - 1)}`,
    height: `+=${currentContainerHeight * (fontSizeScale - 1)}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#label-text-${id}`,
    fontSize: value,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
  });
  return Promise.all([animation1.finished, animation2.finished]);
};

export const getLabelText = (id: string): string =>
  String($(`#label-text-${id}`).text());
export const setLabelText = (id: string, value?: string) => {
  const obj = $(`#label-container-${id}`);
  const text = $(`#label-text-${id}`);
  const currentWidth = obj.width();
  const currentHeight = obj.height();
  const fontFamily = text.css("font-family");
  const fontSize = Number(text.css("font-size").split("px")[0]);
  const letterSpacing = Number(text.css("letter-spacing").split("px")[0]);

  const [translateX, translateY, rotate, scale] = getTransformPropertyValue(
    obj
  );
  const textValue = value ?? "";

  const calc = $("#compute-text-length");
  calc.css("font-family", fontFamily);
  calc.css("font-size", fontSize);
  calc.css("letter-spacing", letterSpacing);
  calc.text(textValue);
  const width = calc.width();
  const delta = width - text.width();

  const offset = calcOffset(
    currentWidth,
    currentHeight,
    (rotate * Math.PI) / 180,
    {
      x: delta,
      y: 0,
    }
  );

  obj.css(
    "transform",
    `translateX(${translateX - offset.x}px) translateY(${
      translateY + offset.y
    }px) rotate(${rotate}deg) scale(${scale})`
  );
  obj.css("width", `${currentWidth + delta}`);
  text.text(textValue);
};
