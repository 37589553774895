import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import {
  AppIcon,
  IconFavorited,
  IconFavoritedReverse,
} from "features/creator/assets";
import { actions } from "features/creator/slice";

export const ProjectFilter = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const { onlyOfficial, userFavorited } = useSelector(
    (state: RootState) => state.creator.publishedProjectFilter
  );
  const handleFilterOfficial = () => {
    play();
    dispatch(
      actions.handleUpdatePublishedProjectFilter({
        type: "onlyOfficial",
        value: !onlyOfficial,
      })
    );
  };

  const handleFilterUserFavorited = () => {
    play();
    dispatch(
      actions.handleUpdatePublishedProjectFilter({
        type: "userFavorited",
        value: !userFavorited,
      })
    );
  };

  return (
    <div
      id="shared-project-panel-filter-panel"
      className="flex-col-center gap-2 mx-8"
    >
      <div className="flex-row-center bg-green/100 rounded-xl px-8 h-[22px]">
        <p className="text-body text-white text-font-size-caption1">
          {I18n.t("MSG_CREATOR_APP_FILTER_TITLE")}
        </p>
      </div>

      <div className="flex-row-center gap-6 upsilon">
        <button
          id="shared-project-panel-filter-official-btn"
          onClick={handleFilterOfficial}
          className={`shrink-0 !px-2 !m-0 btn ${
            onlyOfficial ? "btn-blue-outlined-active" : "btn-blue-outlined"
          }`}
        >
          <div className="flex-row-center">
            <AppIcon />
            <p className="text-body text-[20px] !mb-[2px]">
              {I18n.t("MSG_CREATOR_APP_FILTER_OFFICIAL")}
            </p>
          </div>
        </button>
        <button
          onClick={handleFilterUserFavorited}
          className={`shrink-0 !px-2 !m-0 btn ${
            userFavorited ? "btn-danger-outlined-active" : "btn-danger-outlined"
          }`}
        >
          <div className="flex-row-center">
            <div className="flex-col-center w-[32px] h-[32px]">
              {userFavorited ? <IconFavoritedReverse /> : <IconFavorited />}
            </div>
            <p className="text-body text-[20px] !mb-[2px]">
              {I18n.t("MSG_CREATOR_APP_FILTER_FAVORITED")}
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};
