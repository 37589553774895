import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { useScale, usePlaySound } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";

export const NotificationDetail = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: RootState) => state.config.notification.kids
  );
  const notificationDialog = useSelector(
    (state: RootState) => state.config.dialog.notification
  );
  const maintenances = useSelector(
    (state: RootState) => state.config.notification.maintenances
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  const handleClose = () => {
    play();
    dispatch(
      actions.updateDialog({
        type: DialogType.NOTIFICATION,
        value: false,
      })
    );
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={notificationDialog.value}
      sx={{ zIndex: 100000 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      onClose={handleClose}
    >
      <div className="flex-col-center !absolute inset-0 bg-textcolor/black/50 w-full h-full">
        <div
          className="flex-col-view items-center w-[1000px] h-[700px] rounded-[8px] border-[8px] border-solid border-white bg-white"
          style={{ transform: `scale(${scale})` }}
        >
          <CloseBtn close={handleClose} />
          <div className="flex-col-el w-full h-full p-4 rounded-[4px] bg-beige/60">
            <div className="flex-col-view items-center w-full h-[670px] pointer-events-auto rounded-[4px] bg-beige/60">
              <div className="flex-col-view justify-center w-[568px] h-[100px]">
                <div className="w-full bg-white border-solid border-x-0 border-y-[4px] border-brown/80">
                  <p className="text !text-[28px] text-textcolor/black !leading-[42px] !mb-[3px]">
                    {I18n.t("MSG_NOTIFICATION_DEFAULT_TITLE")}
                  </p>
                </div>
              </div>

              <div className="flex-col-center w-full h-[550px] overflow-hidden">
                <div className="flex-col-view h-full visible-scrollbar !overflow-x-hidden">
                  <>
                    {maintenances.map((content, index) => (
                      <div key={index} className="w-[880px] py-[20px]">
                        <div className="flex-row-view !flex-1">
                          <p className="text text-white !text-left !mb-[16px] !leading-[30px] bg-red/100 rounded-[4px] !px-[8px]">
                            {content[config.locale]?.date}
                          </p>
                        </div>
                        <div className="flex-row-view !mb-[12px] flex-wrap">
                          <div>
                            <p className="w-full text text-red/100 !text-left !text-[28px] !leading-[42px] border-solid border-red/100 border-0 !border-b-[3px]">
                              {content[config.locale]?.title}
                            </p>
                          </div>
                        </div>
                        <div>
                          {content[config.locale].content
                            .split("\n")
                            .map((p: string, index: number) => (
                              <p
                                key={index}
                                className="text text-textcolor/black !text-left !mb-[12px]"
                              >
                                {p}
                              </p>
                            ))}
                        </div>
                      </div>
                    ))}
                    {notification.map((content, index) => (
                      <div key={index} className="w-[880px] py-[20px]">
                        <div className="flex-row-view !flex-1">
                          <p className="text text-white !text-left !mb-[16px] !leading-[30px] bg-brown/80 rounded-[4px] !px-[8px]">
                            {content[config.locale]?.date}
                          </p>
                        </div>
                        <div className="flex-row-view !mb-[12px] flex-wrap">
                          <div>
                            <p className="w-full text text-brown/80 !text-left !text-[28px] !leading-[42px] border-solid border-brown/80 border-0 !border-b-[3px]">
                              {content[config.locale]?.title}
                            </p>
                          </div>
                        </div>
                        <div className="flex-col-el">
                          {content[config.locale]?.content
                            .split("\n")
                            .map((line: string, index: number) => (
                              <div key={index}>
                                {/\[image\]\((https:\/\/[^\)]+)\)/.test(
                                  line
                                ) ? (
                                  <div className="flex-col-center w-full mb-3">
                                    <img
                                      alt="notification image"
                                      onLoad={(e) => {
                                        const el = e.target as HTMLImageElement;
                                        el.width = el.naturalWidth / 2;
                                        el.height = el.naturalHeight / 2;
                                      }}
                                      src={line.split("(")[1].split(")")[0]}
                                      className="object-contain"
                                    />
                                  </div>
                                ) : (
                                  <p className="text-body text-font-size-body2 text-textcolor/black text-left !mb-3 break-all whitespace-pre-line">
                                    {line}
                                  </p>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
