import "@babylonjs/loaders/glTF/2.0/index";
import {
  SceneLoader,
  ISceneLoaderAsyncResult,
} from "@babylonjs/core/Loading/sceneLoader";
import { Scene } from "@babylonjs/core/scene";
import "@babylonjs/core/Animations/animatable";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";
import Constants from "common/constant";
import { Vector3D } from "common/types";

export const createPreviewMesh = (
  scene: Scene,
  filename: string,
  offset?: Vector3D
): Promise<Mesh> =>
  new Promise(async (resolve, reject) => {
    const handleImportMesh = (result: ISceneLoaderAsyncResult) => {
      const mesh = result.meshes[0] as Mesh;
      mesh.name = `preview_asset`;
      const boundingInfo = mesh.getHierarchyBoundingVectors();
      mesh.position = new Vector3(
        0,
        (boundingInfo.min.y < 0 ? -boundingInfo.min.y : 0) + (offset?.y ?? 0),
        0
      );
      mesh.setBoundingInfo(
        new BoundingInfo(
          boundingInfo.min.y < 0
            ? new Vector3(boundingInfo.min.x, 0, boundingInfo.min.z)
            : boundingInfo.min,
          boundingInfo.min.y < 0
            ? new Vector3(
                boundingInfo.max.x,
                boundingInfo.max.y - boundingInfo.min.y + (offset?.y ?? 0),
                boundingInfo.max.z
              )
            : boundingInfo.max
        )
      );

      for (let i = 0; i < result.meshes.length; i++) {
        const subMesh = result.meshes[i];
        if (subMesh.material) {
          // @ts-ignore
          subMesh.material.specularIntensity = 0.3;
        }
      }

      resolve(mesh);
    };

    SceneLoader.ImportMeshAsync(
      "",
      `${Constants.assetHost}/assets/models/`,
      filename,
      scene
    )
      .then((result) => {
        handleImportMesh(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const loadMesh = (
  scene: Scene,
  filename: string,
  offset?: Vector3D
): Promise<Mesh> =>
  new Promise(async (resolve, reject) => {
    const handleImportMesh = (result: ISceneLoaderAsyncResult) => {
      const mesh = result.meshes[0] as Mesh;
      const boundingInfo = mesh.getHierarchyBoundingVectors();
      mesh.position = new Vector3(
        0,
        (boundingInfo.min.y < 0 ? -boundingInfo.min.y : 0) + (offset?.y ?? 0),
        0
      );
      mesh.setBoundingInfo(
        new BoundingInfo(
          boundingInfo.min.y < 0
            ? new Vector3(boundingInfo.min.x, 0, boundingInfo.min.z)
            : boundingInfo.min,
          boundingInfo.min.y < 0
            ? new Vector3(
                boundingInfo.max.x,
                boundingInfo.max.y - boundingInfo.min.y + (offset?.y ?? 0),
                boundingInfo.max.z
              )
            : boundingInfo.max
        )
      );

      for (let i = 0; i < result.meshes.length; i++) {
        const subMesh = result.meshes[i];
        if (subMesh.material) {
          // @ts-ignore
          subMesh.material.specularIntensity = 0.3;
        }
      }

      resolve(mesh);
    };

    SceneLoader.ImportMeshAsync(
      "",
      `${Constants.assetHost}/assets/models/`,
      filename,
      scene
    )
      .then((result) => {
        handleImportMesh(result);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const copyMesh = (mesh: Mesh, name: string) => {
  const newMesh = mesh.clone(name);

  const boundingInfo = newMesh.getHierarchyBoundingVectors();
  newMesh.setBoundingInfo(new BoundingInfo(boundingInfo.min, boundingInfo.max));
  newMesh.position = new Vector3(0, 0, 0);

  return newMesh;
};
