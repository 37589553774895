import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { AppDispatch, RootState } from "app/store";
import { IconPlayerPlay } from "common/assets";
import { useScale, usePlaySound, btnDelay } from "common/utils";
import {
  BandageIcon,
  IconPublish,
  IconStopPublish,
} from "features/creator/assets";
import { selectProjectById } from "features/creator/slice";
import { PublishProjectDialogMessage } from "features/creator/elements";
import { publishProject, stopPublishProject } from "features/creator/api";

export const TemplateMenu = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [publish, setPublish] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const project_id = useSelector(
    (state: RootState) => state.creator.editingProjectId
  );
  const project = useSelector((state: RootState) =>
    selectProjectById(state, project_id)
  );
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleCollapse = () => {
    play();
    setCollapse(!collapse);
  };

  const handleRunPreview = () => {
    play();
    dispatch(
      actions.updateDialog({
        type: DialogType.PROJECT_PREVIEW,
        value: true,
        args: { projectId: project_id, isUserProject: "true" },
      })
    );
  };

  const handlePublish = () => {
    play();
    setPublish(true);
  };

  const handlePublishConfirm = () => {
    play();
    btnDelay(() => {
      if (project.published) {
        dispatch(
          stopPublishProject({
            uid: user.active.uid,
            project_id: project.project_id,
          })
        ).unwrap();
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_TOAST_DISABLE_PUBLISHED_SUCCESS")}
            </p>
          </div>
        );
      } else {
        dispatch(
          publishProject({
            uid: user.active.uid,
            project_id: project.project_id,
            enable_copied: project.enable_copied,
          })
        ).unwrap();
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_TOAST_PUBLISHED_SUCCESS")}
            </p>
          </div>
        );
      }
      setPublish(false);
    });
  };

  const handlePublishClose = () => {
    play();
    setPublish(false);
  };

  return (
    <div
      className="flex-row-cener !absolute top-[85%] z-[1000] origin-top-right"
      style={{ right: -255 * scale, transform: `scale(${scale})` }}
    >
      <PublishProjectDialogMessage
        name={project?.name}
        visible={publish}
        published={project?.published}
        enableCopied={project?.enable_copied}
        publish={handlePublishConfirm}
        close={handlePublishClose}
      />

      <div
        className="flex-row-view h-[126px] bg-white rounded-l-[16px] p-[16px] transition-transform duration-500"
        style={{
          transform: `translate(${collapse ? 0 : -255}px, 0)`,
          filter: "drop-shadow(3px 3px 3px rgba(63, 74, 97, 0.4))",
        }}
      >
        <div
          className="flex-col-center w-[48px] h-[48px] !absolute top-[39px] left-[-48px] cursor-pointer"
          onClick={handleCollapse}
        >
          <BandageIcon />
        </div>

        <div className="flex-col-el flex-center h-full relative upsilon">
          <button className="btn btn-secondary" onClick={handleRunPreview}>
            <div className="flex-col-el flex-center relative mb-[3px]">
              <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                <IconPlayerPlay color="white" width={2} />
              </div>
              <p className="text-body text-white text-[16px] whitespace-pre">
                {I18n.t("MSG_CREATOR_MENU_RUN_APP_BTN")}
              </p>
            </div>
          </button>
        </div>

        <div className="flex-col-el flex-center h-full relative upsilon">
          <button
            className={`btn ${project?.published ? "btn-gray" : "btn-blue"}`}
            onClick={handlePublish}
          >
            <div className="flex-col-el flex-center relative mb-[3px]">
              <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                {project?.published ? <IconStopPublish /> : <IconPublish />}
              </div>
              <p className="text-body text-white text-[16px] whitespace-pre">
                {project?.published
                  ? I18n.t("MSG_CREATOR_MENU_STOP_PUBLISH_TEMPLATE_BTN")
                  : I18n.t("MSG_CREATOR_MENU_PUBLISH_TEMPLATE_BTN")}
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
