import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useScale } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";

export const DialogMessage = ({
  open,
  zIndex,
  title,
  message,
  close,
  confirm,
}: {
  open: boolean;
  zIndex?: number;
  title: string;
  message: string;
  close: () => void;
  confirm?: () => void;
}) => {
  const { scale } = useScale();
  return (
    <Dialog
      open={open}
      maxWidth={false}
      sx={{ zIndex: zIndex ?? 20010 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{
          filter:
            "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
        }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{ transform: `scale(${scale})` }}
        >
          <div className="flex-col-el justify-start w-[510px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
            <CloseBtn close={close} />

            <div className="flex-col-view justify-start w-full h-full  relative p-4 rounded-[4px] bg-beige/60">
              <div className="flex-row-view items-center justify-start py-4">
                <div className="flex-col-center mr-2">
                  <InfoOutlinedIcon sx={{ fontSize: 40, color: "#4C9DF1" }} />
                </div>
                <p className="text-body text-textcolor/black text-[28px]">
                  {title}
                </p>
              </div>
              <div className="flex-col-view items-start py-4">
                {message?.split("\n").map((line, index) => {
                  return (
                    <div key={index} className="flex-row-view flex-wrap mb-3">
                      <p className="text-body text-textcolor/black leading-[30px] text-[20px]">
                        ・
                      </p>
                      <p className="text-body text-textcolor/black leading-[30px] text-[20px] flex-1">
                        {line}
                      </p>
                    </div>
                  );
                })}
              </div>

              {confirm && (
                <div className="flex-row-el flex-center w-full alpha">
                  <button className="btn btn-primary" onClick={confirm}>
                    <div className="flex-row-center gap-1 mb-[3px]">
                      <span className="material-symbols-outlined text-[36px] text-white">
                        check
                      </span>
                      <p className="text-body text-white text-font-size-body1">
                        {I18n.t("MSG_CONFIRM_BTN")}
                      </p>
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
