export const SelectTextFont = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const { value, onChange } = props;
  const fonts = value.split("-");

  return (
    <div className="flex-col-center w-full px-[8px]">
      <select
        value={fonts[0]}
        onChange={(e) => onChange(`${e.target.value}-${fonts[1]}`)}
        name="fontFamily"
        className="text text-textcolor/black !text-left w-full h-[30px] border-gray/40 border-solid border-[1px]"
        style={{
          fontFamily: value,
        }}
      >
        <option value="MPLUSRounded1c">M PLUS Rounded 1c</option>
        <option value="NotoSansJP">Noto Sans JP</option>
        <option value="NotoSerifJP">Noto Serif JP</option>
      </select>
      <select
        value={fonts[1]}
        onChange={(e) => onChange(`${fonts[0]}-${e.target.value}`)}
        name="fontWeight"
        className="text text-textcolor/black !text-left w-full h-[30px] border-gray/40 border-solid border-[1px] !mt-[12px]"
        style={{
          fontFamily: value,
        }}
      >
        <option value="Bold">ふとい</option>
        <option value="Regular">ふつう</option>
        <option value="Light">ほそい</option>
      </select>
    </div>
  );
};
