/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback } from "react";
import anime from "animejs";
import { Dialog } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { Spin, SlideUpTransition } from "common/elements";
import { useScale, isDebugMode, dataURLToBlob } from "common/utils";
import { Label } from "./type";
import { useExecutableBlockListGenerator } from "./useBlockGenerator";

const SHOW_RECTANGLE = true;

const extractValidText = (text: string) => {
  switch (true) {
    case /じっこう/.test(text):
    case /おしまい/.test(text):
    case /まえにすすむ/.test(text):
    case /ジャンプ/.test(text):
    case /ひだりをむく/.test(text):
    case /みぎをむく/.test(text):
    case /もし/.test(text):
    case /くりかえす/.test(text):
    case /ここまで/.test(text):
    case /へんすうにセット/.test(text):
    case /かんすうよびだし/.test(text.replace(" ", "")):
    case /かんすう/.test(text):
      return true;
    default:
      return false;
  }
};

export const Camera = ({
  open,
  remote,
  setOpen,
  success,
}: {
  open: boolean;
  remote: boolean;
  setOpen: (open: boolean) => void;
  success: (result: string) => void;
}) => {
  const { width, height } = useScale();
  const [start, setStart] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [captured, _setCaptured] = useState(false);
  const capturedRef = useRef(captured);
  const setCaptured = (captured: boolean) => {
    capturedRef.current = captured;
    _setCaptured(captured);
  };
  const [cameraIds, setCameraIds] = useState([]);
  const [cameraIndex, setCameraIndex] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamCanvas = useRef<HTMLCanvasElement>(null);
  const { handleGenerateBlock } = useExecutableBlockListGenerator();

  const handleOpenCamera = useCallback(
    async (
      deviceId: string,
      size: { width: number; height: number },
      success?: () => void,
      error?: (err: any) => void
    ) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            deviceId,
            aspectRatio:
              size.width > size.height
                ? size.width / size.height
                : size.height / size.width,
            width: { ideal: 1920 },
          },
        })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = function () {
            videoRef.current.play();
            videoRef.current.width = videoRef.current.videoWidth; // width, heightを設定しないとcap.read(src)で失敗する。
            videoRef.current.height = videoRef.current.videoHeight;
            if (success) {
              success();
            }
          };
        })
        .catch(error);
    },
    []
  );

  const handleInitCamera = useCallback(
    async (
      size: { width: number; height: number },
      success?: () => void,
      error?: (err: any) => void
    ) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        stream.getVideoTracks().forEach((track) => track.stop());
        const devices = await navigator.mediaDevices.enumerateDevices();
        const deviceIds = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => device.deviceId);
        setCameraIds(deviceIds);
        setCameraIndex(deviceIds.length - 1);
        handleOpenCamera(deviceIds[deviceIds.length - 1], size, success, error);
      } catch (err) {
        error(err);
      }
    },
    []
  );

  const handleCaptureStream = useCallback(() => {
    // @ts-ignore
    const cap = new cv.VideoCapture(videoRef.current);
    // @ts-ignore
    const cameraFrame = new cv.Mat(
      videoRef.current.height,
      videoRef.current.width,
      // @ts-ignore
      cv.CV_8UC4
    );
    cap.read(cameraFrame);
    // @ts-ignore
    cv.imshow(streamCanvas.current, cameraFrame);
    cameraFrame.delete();
  }, []);

  const handleShowRectangle = (labels: Label[]) => {
    let canvas = document.getElementById(
      "recognize-canvas"
    ) as HTMLCanvasElement;
    if (!canvas && !videoRef.current) return;
    canvas.width = videoRef.current.width;
    canvas.height = videoRef.current.height;
    let context = canvas.getContext("2d");
    context.clearRect(0, 0, videoRef.current.width, videoRef.current.height);
    if (labels.length > 0) {
      for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if (extractValidText(label.text)) {
          context.lineWidth = 5;
          context.strokeStyle = "#00FF00";
          let w = label.boundingPolygon[1].x - label.boundingPolygon[0].x;
          let h = label.boundingPolygon[2].y - label.boundingPolygon[0].y;
          context.strokeRect(
            label.boundingPolygon[0].x - (w * 0.2) / 2,
            label.boundingPolygon[0].y - h / 2,
            label.boundingPolygon[1].x - label.boundingPolygon[0].x + w * 0.2,
            label.boundingPolygon[2].y - label.boundingPolygon[0].y + h
          );
          if (isDebugMode) {
            console.log(label.text);
            context.font = "40px Arial";
            context.fillStyle = "red";
            context.fillText(
              label.text,
              label.boundingPolygon[0].x,
              label.boundingPolygon[0].y
            );
          }
        }
      }
    }
  };

  const handleRecognize = useCallback(
    () =>
      new Promise(async (resolve, reject) => {
        if (streamCanvas.current) {
          if (capturedRef.current) {
            setLoading(true);
          } else {
            handleCaptureStream();
          }

          const dataUrl = streamCanvas.current.toDataURL("image/jpeg", 0.5);
          const fileBlob = dataURLToBlob(dataUrl);
          handleGenerateBlock(
            fileBlob,
            (validLabels, blocks, isAiRobotTeacher) => {
              if (SHOW_RECTANGLE) {
                handleShowRectangle(validLabels);
              }
              success(JSON.stringify({ blocks, isAiRobotTeacher }));
              resolve(0);
            },
            reject,
            () => {
              if (capturedRef.current) {
                setStart(false);
                setLoading(false);
                setClicked(false);
                if (remote) {
                  setCaptured(false);
                  setOpen(false);
                }
              }
            }
          );
        }
      }),
    []
  );

  useEffect(() => {
    if (open) {
      setStart(true);
    }
  }, [open]);

  useEffect(() => {
    let recognizeTimer: NodeJS.Timeout;
    if (start) {
      setLoading(true);
      handleInitCamera(
        { width, height },
        () => {
          setLoading(false);

          const processRecognize = () => {
            handleRecognize().then(() => {
              if (!capturedRef.current) {
                recognizeTimer = setTimeout(processRecognize, 10);
              }
            });
          };

          processRecognize();
        },
        (error) => {
          console.log(error);
          setTimeout(() => {
            setLoading(false);
            setStart(false);
            setCaptured(false);
            setOpen(false);
          }, 500);
        }
      );
    }
    return () => {
      if (recognizeTimer) {
        clearTimeout(recognizeTimer);
      }
    };
  }, [start]);

  useEffect(() => {
    if (start && videoRef.current?.srcObject) {
      (videoRef.current.srcObject as MediaStream)
        .getVideoTracks()
        .forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setTimeout(
        () => handleOpenCamera(cameraIds[cameraIndex], { width, height }),
        200
      );
    }
  }, [width, height]);

  const handleCancel = () => {
    if (!remote) {
      success(JSON.stringify({ blocks: [] }));
    }
    (videoRef.current.srcObject as MediaStream)
      .getVideoTracks()
      .forEach((track) => track.stop());
    videoRef.current.srcObject = null;
    setStart(false);
    setCaptured(false);
    setOpen(false);
  };

  const handleCapture = () => {
    if (clicked) return;
    setClicked(true);
    handleCaptureStream();
    anime({
      targets: document.getElementById("camera-container"),
      opacity: [1, 0, 1],
      duration: 300,
      easing: "easeInOutQuad",
    });
    setCaptured(true);
    (videoRef.current.srcObject as MediaStream)
      .getVideoTracks()
      .forEach((track) => track.stop());
    videoRef.current.srcObject = null;
    setClicked(false);
  };

  const handleSwitchCamera = () => {
    if (clicked) return;
    setClicked(true);
    if (cameraIndex === cameraIds.length - 1) {
      setCameraIndex(0);
      handleOpenCamera(cameraIds[0], { width, height }, () => {
        setClicked(false);
      });
    } else {
      handleOpenCamera(cameraIds[cameraIndex + 1], { width, height }, () => {
        setClicked(false);
      });
      setCameraIndex(cameraIndex + 1);
    }
  };

  const handleReset = () => {
    if (clicked) return;
    setLoading(true);
    setClicked(true);
    handleOpenCamera(cameraIds[cameraIndex], { width, height }, () => {
      setStart(true);
      setCaptured(false);
      setClicked(false);
      setLoading(false);
    });
  };

  const handleConfirm = () => {
    setCaptured(false);
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      maxWidth={false}
      sx={{ zIndex: 200000 }}
      TransitionComponent={SlideUpTransition}
      componentsProps={{
        backdrop: {
          style: { backgroundColor: "transparent" },
        },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      {loading && (
        <div className="flex-col-el absolute inset-0 bg-textcolor/black/90 z-[200000]">
          <Spin />
        </div>
      )}

      <div className="flex-col-el flex-center w-full h-full bg-[#000000]">
        <div
          className={`flex-col-el flex-center absolute ${
            width > height ? "h-full" : "w-full"
          }`}
          style={{
            aspectRatio: width / height,
          }}
        >
          <div
            id="camera-container"
            className="flex-row-el flex-center w-full h-full z-50"
          >
            <video
              muted
              playsInline
              ref={videoRef}
              className={`w-full h-full ${captured && "hidden"}`}
            />

            <canvas
              ref={streamCanvas}
              className={`flex-co-el flex-center absolute top-0 left-0 w-full h-full z-10 ${
                !captured && "hidden"
              }`}
            />

            <canvas
              id="recognize-canvas"
              className="flex-co-el flex-center absolute top-0 left-0 w-full h-full z-40"
            />

            {captured ? (
              <div
                style={{ bottom: 60 }}
                className="flex-row-el w-full justify-evenly z-50 absolute bottom-5 pointer-events-auto"
              >
                <div
                  className="flex-row-center cursor-pointer"
                  style={{ width: 200, height: 50 }}
                >
                  <div
                    onClick={handleReset}
                    className="flex-row-center active:opacity-70"
                  >
                    <p className="text text-white">リセット</p>
                  </div>
                </div>

                <div
                  className="flex-row-center cursor-pointer"
                  style={{ width: 200, height: 50 }}
                >
                  <div
                    onClick={handleConfirm}
                    className="flex-row-center active:opacity-70"
                  >
                    <p className="text text-white">確定</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ bottom: 60 }}
                className="flex-row-el w-full items-center justify-evenly z-50 absolute pointer-events-auto"
              >
                <div
                  className="flex-row-center"
                  style={{ width: 150, height: 50 }}
                >
                  <div
                    onClick={handleCancel}
                    className="flex-row-center cursor-pointer active:opacity-70"
                  >
                    <p className="text text-white">キャンセル</p>
                  </div>
                </div>

                {!remote && (
                  <div
                    className="flex-row-center"
                    style={{ width: 150, height: 65 }}
                  >
                    <div className="flex-row-center bg-white w-[65px] h-[65px] rounded-[40px] pointer-events-auto cursor-pointer">
                      <div
                        onClick={handleCapture}
                        className="w-[50px] h-[50px] bg-white rounded-[30px] ring-offset-0 ring-[2px] ring-[#000000]/80 active:opacity-70"
                      />
                    </div>
                  </div>
                )}

                <div
                  className="flex-row-center z-50"
                  style={{ width: 150, height: 50 }}
                >
                  <div
                    onClick={handleSwitchCamera}
                    className="flex-row-center bg-textcolor/black/60 w-[50px] h-[50px] rounded-[40px] pointer-events-auto cursor-pointer active:opacity-70"
                  >
                    <div className="flex-row-center w-full h-full">
                      <CachedIcon sx={{ fontSize: 32, color: "white" }} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
