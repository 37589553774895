import { PointerEvent } from "react";
import { useDialog, useRecordEvent } from "app/hook";
import { DialogType, AnalyticsEventType } from "app/types";
import Constants from "common/constant";
import { useScale, btnDelay, usePlaySound } from "common/utils";
import { LogoPlate } from "features/universal/LogoPlate";

export const UniversalBtn = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const handleRecordEvent = useRecordEvent();

  const handleClick = (e: PointerEvent) => {
    e.stopPropagation();
    btnDelay(() => {
      play();
      handleDialog({
        type: DialogType.UNIVERSAL,
        value: true,
      });
      handleRecordEvent(AnalyticsEventType.UNIVERSAL_BTN_CLICK);
    });
  };

  return (
    <div
      id="universal-btn-container"
      className="flex-row-view !absolute right-0 justify-end items-center z-[9999]"
    >
      <div
        className="pointer-events-none"
        style={{
          width: 156 * scale,
          height: 156 * scale,
        }}
      >
        <LogoPlate />
      </div>

      <div
        className="flex-col-center !absolute top-0 right-0 z-[10] cursor-pointer group"
        style={{ width: 112 * scale, height: 112 * scale }}
        onPointerDown={(e) => e.stopPropagation()}
        onPointerUp={handleClick}
      >
        <div
          className="flex-col-center !absolute top-0 right-0 group-hover:opacity-0 group-active:opacity-0"
          style={{
            width: 112 * scale,
            height: 112 * scale,
            top: 2 * scale,
          }}
        >
          <img
            alt="ヘルプボタン"
            src={`${Constants.assetHost}/assets/images/help_btn_normal.png`}
            className="w-[112px] h-[112px]"
            style={{ transform: `scale(${scale})` }}
          />
        </div>

        <div
          className="flex-col-center !absolute top-0 right-0 opacity-0 group-active:opacity-100 pointer-events-none"
          style={{
            width: 102 * scale,
            height: 103 * scale,
            top: 4 * scale,
            right: 4 * scale,
          }}
        >
          <img
            alt="ヘルプボタンタップされた画像"
            src={`${Constants.assetHost}/assets/images/help_btn_tap.png`}
            className="w-[102px] h-[103px]"
            style={{ transform: `scale(${scale})` }}
          />
        </div>

        <div
          className="flex-col-center !absolute top-0 right-0 opacity-0 group-hover:opacity-100 group-active:opacity-0"
          style={{
            width: 112 * scale,
            height: 112 * scale,
            top: 2 * scale,
          }}
        >
          <img
            alt="ヘルプボタンタップされた画像"
            src={`${Constants.assetHost}/assets/images/help_btn_hover.png`}
            className="w-[112px] h-[112px]"
            style={{ transform: `scale(${scale})` }}
          />
        </div>
      </div>
    </div>
  );
};
