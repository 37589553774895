import { SVGProps } from "react";

export const IconCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 10.667H13.333a2.667 2.667 0 0 0-2.666 2.666V24a2.667 2.667 0 0 0 2.666 2.667H24A2.667 2.667 0 0 0 26.667 24V13.333A2.667 2.667 0 0 0 24 10.667Z"
      stroke={props.stroke}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.333 10.667V8a2.667 2.667 0 0 0-2.666-2.667H8A2.667 2.667 0 0 0 5.333 8v10.667A2.667 2.667 0 0 0 8 21.333h2.667"
      stroke={props.stroke}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
