import I18n from "i18n-js";
import { Fade } from "@mui/material";
import { useScale } from "common/utils";
import Constants from "common/constant";
import { CloseBtn } from "common/elements";
import { IconBubble } from "./assets";

export const ThanksToFeedback = (props: {
  visible: boolean;
  close: () => void;
}) => {
  const { scale } = useScale();
  return (
    <Fade in={props.visible}>
      <div className="flex-col-center w-full h-full !absolute">
        <div
          className="flex-col-view"
          style={{ width: 464 * scale, height: 429 * scale }}
        >
          <div
            className="flex-col-view items-center w-[464px] h-[429px] border-solid border-[12px] rounded-[8px] border-white bg-beige/60 origin-top-left"
            style={{ transform: `scale(${scale})` }}
          >
            <CloseBtn close={props.close} />

            <div className="flex-row-view items-start mt-[70px]">
              <div className="flex-col-center mt-[32px] mr-[-20px]">
                <img
                  alt="duck_attention_2"
                  src={`${Constants.assetHost}/assets/images/duck_attention_2.png`}
                  className="w-[150px] h-[153px]"
                />
              </div>
              <div className="flex-col-center">
                <IconBubble />
                <div className="flex-col-center !absolute top-[40px] left-[21px]">
                  <p className="text-subtitle-1 text-center text-textcolor/black">
                    {I18n.t("MSG_REVIEW_THANKS_BUBBLE_TEXT")}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex-col-center mt-[32px]">
              <p className="text text-textcolor/black">
                {I18n.t("MSG_REVIEW_FEEDBACK_THANKS_TEXT")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};
