import { SVGProps } from "react";

export const IconClipboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 21c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 19V5c0-.55.196-1.021.587-1.413A1.928 1.928 0 0 1 5 3h4.2c.217-.6.58-1.083 1.088-1.45A2.855 2.855 0 0 1 12 1c.633 0 1.208.183 1.725.55.517.367.875.85 1.075 1.45H19c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 21H5Zm0-2h14V5h-2v3H7V5H5v14Zm7-14a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 4a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 3a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 4c0 .283.096.52.288.712A.965.965 0 0 0 12 5Z"
      fill="#FEFDFE"
    />
  </svg>
);
