import { useState, ChangeEvent, PointerEvent } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { TextField, IconButton } from "@mui/material";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import {
  MessageType,
  ChatRoomMemberType,
  MessageContentType,
  AccountType,
} from "features/user/types";
import { sendChatRoomMessage } from "features/user/api";
import { Stamp } from "./Stamp";

export const InputBar = (props: { visible: boolean }) => {
  const { visible } = props;
  const play = usePlaySound();
  const [text, setText] = useState("");
  const [stamp, setStamp] = useState(false);
  const selectedChatRoomId = useSelector(
    (state: RootState) => state.user.selectedChatRoomId
  );
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleShowStamp = (show: boolean) => () => {
    setStamp(show);
  };

  const handleInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleSendMessage = (e: PointerEvent) => {
    if (text === "") return;
    play();
    const message = {
      user_uid: user.active.uid,
      user_type:
        user.active.account_type === AccountType.PARENTS
          ? ChatRoomMemberType.PARENTS
          : ChatRoomMemberType.KIDS,
      message_type: MessageType.NORMAL,
      message_content_type: MessageContentType.TEXT,
      message_content: { text: text },
      created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    sendChatRoomMessage({
      roomId: selectedChatRoomId,
      message,
    })
      .then(() => {
        setText("");
        setStamp(false);
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_TOAST_SEND_FAILED")}
            </p>
          </div>
        );
      });
  };

  const handleSendStamp = (url: string) => () => {
    play();
    const message = {
      user_uid: user.active.uid,
      user_type:
        user.active.account_type === AccountType.PARENTS
          ? ChatRoomMemberType.PARENTS
          : ChatRoomMemberType.KIDS,
      message_content_type: MessageContentType.IMAGE,
      message_content: { url },
      created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    sendChatRoomMessage({
      roomId: selectedChatRoomId,
      message,
    }).then(() => setStamp(false));
  };

  return (
    <div
      className="flex-col-view justify-start items-center w-full z-50 bg-white"
      style={{ display: !visible && "none" }}
    >
      <div className="flex-row-view justify-center items-end w-full my-1">
        <div className="flex-col-center mx-2">
          <IconButton
            onClick={handleShowStamp(!stamp)}
            disabled={selectedChatRoomId === null}
          >
            <SentimentSatisfiedOutlinedIcon sx={{ fontSize: 36 }} />
          </IconButton>
        </div>

        <div className="flex-col-el relative flex-1">
          <TextField
            placeholder={I18n.t("MSG_USER_PROFILE_MESSAGE_CHAT_PLACEHOLDER")}
            fullWidth
            InputProps={{
              sx: {
                borderRadius: 4,
                paddingX: 3,
                paddingY: 1,
                fontSize: 24,
                fontFamily: "MPLUSRounded1c-Bold",
                color: "#3F4A61",
              },
            }}
            variant="outlined"
            multiline
            maxRows={10}
            disabled={selectedChatRoomId === null}
            onFocus={handleShowStamp(false)}
            value={text}
            onChange={handleInput}
          />
        </div>

        <button
          onPointerDown={handleSendMessage}
          disabled={selectedChatRoomId === null || text.length === 0}
          className="flex-col-el flex-center cursor-pointer border-none active:opacity-70 disabled:bg-gray/20 bg-safe rounded-[10px] p-3 mx-4"
        >
          <p className="text text-white">
            {I18n.t("MSG_USER_PROFILE_MESSAGE_CHAT_SEND_BTN")}
          </p>
        </button>
      </div>

      {stamp && <Stamp handleSendStamp={handleSendStamp} />}
    </div>
  );
};
