import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { Modal, Collapse, Paper } from "@mui/material";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { useScale, usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { FreebuildTitleSmallSvg } from "features/home/assets";
import { TabletCapture } from "features/creator/tutorial/top/TabletCapture";

const menus = [
  () => (
    <div className="flex-row-el flex-center gap-1">
      <div className="flex-col-center w-[106px] h-[24px]">
        <FreebuildTitleSmallSvg />
      </div>
      <p className="text-body text-[20px] text-textcolor/black">について</p>
    </div>
  ),
  () => (
    <div className="flex-row-el flex-center gap-1">
      <div className="flex-row-el flex-center px-2 bg-orange/100 rounded-lg">
        <p className="text-body text-[14px] leading-6 text-white">
          {I18n.t("MSG_CREATOR_APP_MY_APPS")}
        </p>
      </div>

      <p className="text-body text-[20px] text-textcolor/black">について</p>
    </div>
  ),
  () => (
    <div className="flex-row-el flex-center gap-1">
      <div className="flex-row-el flex-center px-2 bg-blue/100 rounded-[8px]">
        <p className="text-body text-[14px] leading-6 text-white">
          {I18n.t("MSG_CREATOR_APP_SHARED_APPS")}
        </p>
      </div>

      <p className="text-body text-[20px] text-textcolor/black">について</p>
    </div>
  ),
  () => (
    <div className="flex-row-el flex-center gap-1">
      <TabletCapture scale={0.048} />
      <p className="text-body text-[20px] text-textcolor/black">
        {I18n.locale === "ja" ? "タブレットのそうさ" : "タブレットの操作"}
      </p>
    </div>
  ),
  () => (
    <div className="flex-row-center gap-1">
      <div className="flex-col-center">
        <img
          alt="app card"
          src={`${Constants.assetHost}/assets/images/app_card_user.png`}
          className="w-[20px] h-[24px]"
        />

        <div className="flex-col-el flex-center absolute top-[5px] w-full h-[16px] px-[1px]">
          <div className="w-full h-full bg-white" />
        </div>
      </div>
      <div className="flex-row-el flex-center">
        <p className="text-body text-[20px] text-textcolor/black text-left">
          {I18n.locale === "ja" ? "アプリのつくりかた" : "アプリの作り方"}
        </p>
      </div>
    </div>
  ),
];

export const TutorialMenu = (props: {
  setShowTutorial: (show: boolean) => void;
  setTutorialStage: (stage: number) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const tutorial = useSelector(
    (state: RootState) => state.creator.tutorialMenu
  );
  const { setShowTutorial, setTutorialStage } = props;
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (tutorial.creator_top_menu) {
      const anchor = document.getElementById("creator-top-guide-btn");
      if (anchor) {
        const anchorRect = anchor.getBoundingClientRect();
        setPosition({
          x: anchorRect.x - 270 * scale,
          y: anchorRect.bottom + 20 * scale,
        });
        setTimeout(() => setShow(true), 100);
      }
    } else {
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorial.creator_top_menu]);

  useEffect(() => {
    if (tutorial.creator_top_menu) {
      const anchor = document.getElementById("creator-top-guide-btn");
      if (anchor) {
        // creator-top-guide-btnもscaleの計算し直すため、ちょっと時間を経ってからRectをとる
        setTimeout(() => {
          const anchorRect = anchor.getBoundingClientRect();
          setPosition({
            x: anchorRect.x - 270 * scale,
            y: anchorRect.bottom + 20 * scale,
          });
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  const handleClose = () => {
    play();
    dispatch(
      actions.updateTutorialMenu({ key: "creator_top_menu", value: false })
    );
  };

  const handleSelectStage = (stage: number) => () => {
    dispatch(
      actions.updateTutorialMenu({ key: "creator_top_menu", value: false })
    );
    setShowTutorial(true);
    setTutorialStage(stage);
  };

  return (
    <Modal
      open={tutorial.creator_top_menu}
      onClose={handleClose}
      componentsProps={{
        backdrop: {
          style: { backgroundColor: "transparent" },
        },
      }}
      sx={{ zIndex: 10000, backgroundColor: "transparent" }}
    >
      <div
        className="flex-col-el flex-center absolute inset-0 cursor-pointer z-[10000]"
        onClick={handleClose}
      >
        <div
          className="flex-col-el items-center justify-start w-[354px] absolute top-0 left-0 origin-top-left"
          style={{
            transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
          }}
        >
          <Collapse in={show}>
            <div
              className="absolute w-0 h-0 border-solid border-x-transparent border-b-gray/100"
              style={{
                top: -20,
                right: 30,
                borderWidth: "0 14.1px 22px 14.1px",
              }}
            >
              <div
                className="absolute w-0 h-0 border-solid !border-t-0 border-x-transparent border-b-white"
                style={{
                  right: -12,
                  top: 5,
                  borderWidth: "0 12.1px 18px 12.1px",
                }}
              />
            </div>
            <Paper
              elevation={0}
              className="flex-col-el items-center justify-start w-[354px] !rounded-[16px] border-[2px] border-solid border-gray/100 !bg-white gap-5 px-[30px] py-6"
            >
              {menus.map((Item, index) => (
                <div
                  key={index}
                  className="opacity-action-button flex-row-el items-center justify-start w-full p-0 bg-transparent gap-3"
                  onClick={handleSelectStage(index)}
                >
                  <div className="flex-col-el flex-center bg-orange/100 rounded-full w-[32px] h-[32px]">
                    <p className="text-body text-[20px] text-white leading-[48px]">
                      {index + 1}
                    </p>
                  </div>

                  <Item />

                  <div className="flex-col-el flex-center w-[18px] h-[18px] rounded-full bg-safe">
                    <KeyboardArrowRightRoundedIcon
                      sx={{ color: "white", fontSize: 18 }}
                    />
                  </div>
                </div>
              ))}
            </Paper>
          </Collapse>
        </div>
      </div>
    </Modal>
  );
};
