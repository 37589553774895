export const Header = (props: { title: string }) => {
  return (
    <div className="flex-col-view justify-end w-[568px] h-[100px]">
      <div className="w-full bg-white border-solid border-x-0 border-y-[4px] border-brown/80">
        <p className="text !text-[28px] text-textcolor/black !leading-[42px] !mb-[3px]">
          {props.title}
        </p>
      </div>
    </div>
  );
};
