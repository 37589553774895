import others from "./others";
import screen from "./screen";
import blockly from "./blockly";
import project from "./project";
import history from "./history";
import property from "./property";
import grouping from "./grouping";
import template from "./template";
import component from "./component";
import clipboard from "./clipboard";

export const reducers = {
  ...others,
  ...screen,
  ...project,
  ...blockly,
  ...history,
  ...property,
  ...grouping,
  ...template,
  ...component,
  ...clipboard,
};

export {
  canvasAdapter,
  screensAdapter,
  blocklyAdapter,
  projectsAdapter,
  componentsAdapter,
  propertiesAdapter,
} from "./adapter";
