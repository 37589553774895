import { useRef, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import I18n from "i18n-js";
import produce from "immer";
import * as echarts from "echarts";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { CreatorGameCaseManager } from "common/elements";
import {
  IconViewed,
  IconBlueBird,
  IconFavoritedHart,
  IconFavoritedBigHart,
} from "features/user/assets";
import { selectAllProjects } from "features/creator/slice";
import { ProjectEntity, ProjectType } from "features/creator/types";

enum ChartType {
  TOTAL_FAVORITED = "TOTAL_FAVORITED",
  NEW_FAVORITED = "NEW_FAVORITED",
  TOTAL_VIEWED = "TOTAL_VIEWED",
  AVG_TOTAL_FAVORITED = "AVG_TOTAL_FAVORITED",
}

const ProjectCard = ({
  selected,
  handleSelectProject,
  favorited,
  children,
}: {
  selected: boolean;
  handleSelectProject: () => void;
  favorited: number;
  children: any;
}) => (
  <div
    className={`flex-col-el flex-center relative shrink-0 !box-content cursor-pointer rounded-full border-solid border-8 ${
      selected
        ? "scale-125 border-green/100/40"
        : "scale-100 border-transparent"
    }`}
  >
    <div
      onClick={handleSelectProject}
      className="flex-col-el flex-center relative shrink-0 w-[135px] h-[129px] rounded-full bg-white"
      style={{
        filter:
          "drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12))",
      }}
    >
      {children}

      <div className="flex-row-el absolute left-2 bottom-4 gap-6">
        <div className="flex-col-el flex-center rounded-full z-10">
          <div className="flex-col-el">
            <IconFavoritedHart />
          </div>

          <div className="flex-row-el items-baseline absolute">
            <p className="text-body text-white text-center text-[18px] leading-4">
              {favorited}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const generateChartOption = (
  periodSeries: string[], // MM-DD
  totalFavoritedSeries: number[],
  totalViewedSeries: number[],
  newFavoritedSeries: number[],
  newFavoritedAvgValue?: number
) => {
  return {
    animation: false,
    grid: {
      left: "3%",
      right: "4%",
      bottom: "120px",
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: true,
        data: periodSeries,
        splitArea: {
          show: true,
          areaStyle: {
            color: ["rgba(148, 196, 247, 0)", "rgba(112, 177, 244, 0.15)"],
          },
        },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          interval: 0, // https://stackoverflow.com/questions/44076557/echarts-how-to-show-all-axis-labels
          formatter: (value: string) => {
            const date = value.split("-");
            return `{month|${Number(date[0])}/}{day|${date[1]}}`;
          },
          rich: {
            month: {
              fontSize: 12,
              color: "#3F4A61",
              verticalAlign: "top",
              fontFamily: "MPLUSRounded1c-Bold",
            },
            day: {
              fontSize: 24,
              color: "#3F4A61",
              fontFamily: "MPLUSRounded1c-Bold",
            },
          },
          show: true,
          margin: 45,
          padding: [18, 0, 0, 0],
          width: 56,
          height: 32,
          backgroundColor: {
            image: `${Constants.assetHost}/assets/images/user_page_axis_label_bg.png`,
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        max: function (value) {
          return value.max / 0.7;
        },
        axisTick: { show: false },
      },
      {
        show: false,
        max: function (value) {
          return value.max / 0.9;
        },
        axisTick: { show: false },
      },
      {
        show: false,
        max: function (value) {
          return value.max / 0.7;
        },
        axisTick: { show: false },
      },
      {
        show: false,
        max: function (value) {
          return value.max / 0.2;
        },
        axisTick: { show: false },
      },
    ],
    series: [
      {
        type: "bar",
        barWidth: "98%",
        itemStyle: { color: "#36c330" },
        data: totalFavoritedSeries,
      },
      {
        type: "line",
        data: totalViewedSeries,
        yAxisIndex: 1,
        lineStyle: { color: "white", width: 4, type: "dashed" },
        showSymbol: true,
        symbolSize: [32, 32],
        symbol: `image://${Constants.assetHost}/assets/images/user_page_chart_creator_viewed_icon.png`,
      },
      {
        type: "line",
        data: newFavoritedSeries,
        yAxisIndex: 2,
        lineStyle: { color: "#FEC1BF", width: 8 },
        label: {
          show: true,
          position: "inside",
          formatter: (params: Object) =>
            params["dataIndex"] === newFavoritedSeries.length - 1
              ? `{latest|${params["value"]}}`
              : `{normal|${params["value"]}}`,
          rich: {
            latest: {
              fontSize: 32,
              color: "white",
              fontFamily: "MPLUSRounded1c-Bold",
            },
            normal: {
              fontSize: 24,
              color: "white",
              fontFamily: "MPLUSRounded1c-Bold",
            },
          },
        },
        labelLayout(params: Object) {
          if (params["dataIndex"] === newFavoritedSeries.length - 1) {
            return {
              x: params["labelRect"].x,
              y: params["rect"].y + params["rect"].height / 2 - 6,
            };
          } else {
            return {
              x: params["labelRect"].x,
              y: params["labelRect"].y - 3,
            };
          }
        },
        showSymbol: true,
        symbolSize: (value: number, params: Object) => {
          if (params["dataIndex"] === newFavoritedSeries.length - 1) {
            return [100, 100];
          } else {
            return [56, 48];
          }
        },
        symbol: (value: number, params: Object) => {
          if (params["dataIndex"] === newFavoritedSeries.length - 1) {
            return `image://${Constants.assetHost}/assets/images/user_page_creator_favorited_icon_latest.png`;
          } else {
            return `image://${Constants.assetHost}/assets/images/user_page_creator_favorited_icon.png`;
          }
        },
      },
      {
        type: "line",
        yAxisIndex: 3,
        showSymbol: false,
        lineStyle: { opacity: 0 },
        markLine: {
          symbol: "none",
          label: { show: false },
          data: [{ type: "max" }],
          lineStyle: {
            width: 12,
            cap: "round",
            type: [0, 20],
            color: "#F9E532",
          },
        },
        data: periodSeries.map(() => newFavoritedAvgValue),
      },
    ],
  };
};

export const UserProgressCreator = ({
  selected,
  close,
}: {
  selected: boolean;
  close: () => void;
}) => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const projects = useSelector(selectAllProjects);
  const [chartTypes, setChartTypes] = useState<ChartType[]>([
    ChartType.TOTAL_FAVORITED,
    ChartType.NEW_FAVORITED,
    ChartType.TOTAL_VIEWED,
  ]);
  const [chart, _setChart] = useState<echarts.ECharts | null>(null);
  const chartRef = useRef(chart);
  const setChart = (chart: echarts.ECharts) => {
    _setChart(chart);
    chartRef.current = chart;
  };
  const status = useSelector((state: RootState) => state.user.status);
  const history = useSelector((state: RootState) => state.user.history);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const GameCaseComponent = CreatorGameCaseManager[config.game_case];
  const user = useSelector((state: RootState) => state.user.appUser.active);
  const [selectedProject, setSelectedProject] = useState<ProjectEntity | null>(
    null
  );

  const userProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.author_uid === user.uid &&
          !project.deleted_at &&
          project.type === ProjectType.BASIC
      ),
    [projects, user.uid]
  );

  useEffect(() => {
    if (chartRef.current) {
      const option = generateChartOption(
        history.period,
        chartTypes.includes(ChartType.TOTAL_FAVORITED)
          ? history.project.favorited.total.day
              .map((value) => value.favorited)
              .concat([status.project.favorited])
          : [],
        chartTypes.includes(ChartType.TOTAL_VIEWED)
          ? history.project.viewed.total.day
              .map((value) => value.viewed)
              .concat([status.project.viewed])
          : [],
        chartTypes.includes(ChartType.NEW_FAVORITED)
          ? history.project.favorited.new.day
              .map((value) => value.favorited)
              .concat([
                status.project.favorited -
                  history.project.favorited.total.day[
                    history.project.favorited.total.day.length - 1
                  ].favorited,
              ])
          : []
      );
      chartRef.current.setOption(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTypes.length]);

  useEffect(() => {
    if (ref.current && history && !chartRef.current && selected) {
      const chart = echarts.init(ref.current, null, { renderer: "svg" });
      const option = generateChartOption(
        history.period,
        chartTypes.includes(ChartType.TOTAL_FAVORITED)
          ? history.project.favorited.total.day
              .map((value) => value.favorited)
              .concat([status.project.favorited])
          : [],
        chartTypes.includes(ChartType.TOTAL_VIEWED)
          ? history.project.viewed.total.day
              .map((value) => value.viewed)
              .concat([status.project.viewed])
          : [],
        chartTypes.includes(ChartType.NEW_FAVORITED)
          ? history.project.favorited.new.day
              .map((value) => value.favorited)
              .concat([
                status.project.favorited -
                  history.project.favorited.total.day[
                    history.project.favorited.total.day.length - 1
                  ].favorited,
              ])
          : []
      );
      chart.setOption(option);
      setChart(chart);
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
      setChart(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, history, selected]);

  useEffect(() => {
    if (userProjects.length > 0) {
      setSelectedProject(userProjects[0]);
    }
  }, [userProjects]);

  const handleSelectChartType = (chartType: ChartType) => () => {
    play();
    setChartTypes(
      produce((draft) => {
        const targetIndex = draft.findIndex((type) => type === chartType);
        if (targetIndex !== -1) {
          draft.splice(targetIndex, 1);
        } else {
          draft.push(chartType);
        }
      })
    );
  };

  const handleGoToCreator = () => {
    close();
    navigate("/creator");
  };

  const handleSelectProject = (project: ProjectEntity) => () => {
    play();
    setSelectedProject(project);
  };

  return (
    <div
      className="flex-col-el flex-1 w-full h-full items-start justify-start overflow-hidden z-10 mt-3"
      style={{ display: !selected && "none" }}
    >
      <div className="flex-row-el flex-center relative w-full h-full overflow-hidden">
        <img
          alt="ゲームの背景"
          src={`${Constants.assetHost}/assets/images/bg_user_page_history.png`}
          className="w-full h-full absolute top-0 left-0"
        />

        <div className="flex-row-el flex-center w-[530px] h-[600px] z-10">
          <div ref={ref} className="flex-row-el w-full h-full" />
        </div>

        <div className="flex-col-el flex-center relative gap-4 w-[104px] mb-28">
          <div
            className="flex-col-el flex-center relative cursor-pointer transition mt-4"
            style={{
              transform: `translateY(${
                chartTypes.includes(ChartType.TOTAL_VIEWED) ? 3 : 0
              }px)`,
            }}
            onClick={handleSelectChartType(ChartType.TOTAL_VIEWED)}
          >
            <div className="flex-col-el z-10 w-[56px] h-[56px]">
              <IconViewed />
            </div>
            <div
              className={`flex-col-el flex-center rounded-full w-[96px] h-[96px] -mt-4 pt-2 transition ${
                chartTypes.includes(ChartType.TOTAL_VIEWED)
                  ? "text-white bg-brown/80"
                  : "text-textcolor/black bg-white"
              }`}
              style={{
                boxShadow: chartTypes.includes(ChartType.TOTAL_VIEWED)
                  ? "0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 4px 1px rgba(0, 0, 0, 0.14), 0px 1px 8px 3px rgba(0, 0, 0, 0.12)"
                  : "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
              }}
            >
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t(
                  "MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_VIEW_TITLE_START"
                )}
              </p>
              <p className="text-body whitespace-pre text-[32px] leading-8">
                {status.project.viewed}
              </p>
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t(
                  "MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_VIEW_TITLE_END"
                )}
              </p>
            </div>
          </div>

          <div
            className="flex-col-el flex-center relative cursor-pointer transition"
            style={{
              transform: `translateY(${
                chartTypes.includes(ChartType.TOTAL_FAVORITED) ? 3 : 0
              }px)`,
            }}
            onClick={handleSelectChartType(ChartType.TOTAL_FAVORITED)}
          >
            <div className="flex-col-el z-10">
              <img
                alt="*"
                src={`${Constants.assetHost}/assets/images/user_page_creator_favorited_icon.png`}
              />
            </div>
            <div
              className={`flex-col-el flex-center rounded-full w-[96px] h-[96px] -mt-3 pt-2 transition ${
                chartTypes.includes(ChartType.TOTAL_FAVORITED)
                  ? "text-white bg-red/100"
                  : "text-textcolor/black bg-white"
              }`}
              style={{
                boxShadow: chartTypes.includes(ChartType.TOTAL_FAVORITED)
                  ? "0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 4px 1px rgba(0, 0, 0, 0.14), 0px 1px 8px 3px rgba(0, 0, 0, 0.12)"
                  : "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
              }}
            >
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t(
                  "MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_FAVORITED_TITLE_START"
                )}
              </p>
              <p className="text-body whitespace-pre text-[32px] leading-8">
                {status.project.favorited}
              </p>
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t(
                  "MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_FAVORITED_TITLE_END"
                )}
              </p>
            </div>
          </div>

          <div
            className="flex-col-el flex-center relative cursor-pointer transition"
            style={{
              transform: `translateY(${
                chartTypes.includes(ChartType.NEW_FAVORITED) ? 3 : 0
              }px)`,
            }}
            onClick={handleSelectChartType(ChartType.NEW_FAVORITED)}
          >
            <div className="flex-col-el z-10">
              <IconBlueBird />
            </div>
            <div
              className={`flex-col-el flex-center rounded-full w-[96px] h-[96px] -mt-3 pt-2 transition ${
                chartTypes.includes(ChartType.NEW_FAVORITED)
                  ? "text-white bg-red/100"
                  : "text-textcolor/black bg-white"
              }`}
              style={{
                boxShadow: chartTypes.includes(ChartType.NEW_FAVORITED)
                  ? "0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 4px 1px rgba(0, 0, 0, 0.14), 0px 1px 8px 3px rgba(0, 0, 0, 0.12)"
                  : "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
              }}
            >
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t("MSG_USER_PROFILE_PROGRESS_NEW_TITLE")}
              </p>
              <p className="text-body whitespace-pre text-[32px] leading-8">
                {status.project.favorited -
                  history.project.favorited.total.day[
                    history.project.favorited.total.day.length - 1
                  ].favorited}
              </p>
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t(
                  "MSG_USER_PROFILE_PROGRESS_CREATOR_CHART_FAVORITED_TITLE_END"
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="flex-col-el flex-center w-[530px] gap-6 mt-10">
          {userProjects.length > 0 ? (
            <div
              className="flex-col-el relative items-center px-10 bg-white shrink-0 rounded-[56px]"
              style={{
                filter:
                  "drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12))",
              }}
            >
              <div
                className="absolute w-0 h-0 border-solid border-x-transparent border-t-white"
                style={{
                  bottom: -6,
                  borderRightWidth: "8px",
                  borderLeftWidth: "8px",
                  borderTopWidth: "8px",
                  borderBottomWidth: 0,
                }}
              />

              <div className="flex-row-el flex-center relative gap-8 pt-6 pb-3">
                <div className="flex-col-el flex-center">
                  <div
                    className="flex-col-center w-[200px] h-[165px]"
                    style={{
                      filter: "drop-shadow(0px 0px 3px rgba(63, 74, 97, 0.3))",
                    }}
                  >
                    <img
                      alt="game case"
                      src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
                      className="w-full h-full object-fill"
                    />
                    {selectedProject && (
                      <div className="flex-col-center !absolute top-[-5px] left-[14px] z-[-1]">
                        <img
                          alt="officail app card"
                          src={`${Constants.assetHost}/assets/images/app_card_user.png`}
                          className="w-[25px] h-[40px]"
                        />
                      </div>
                    )}
                    <div className="flex-col-el flex-center w-[170px] h-[135px] absolute top-[15px] left-[13px] !box-content border-solid border-gray/100 border-[2px] rounded-[16px] overflow-hidden">
                      {selectedProject?.capture ? (
                        <div className="flex-col-center w-full h-full overflow-hidden">
                          <img
                            alt="アプリのキャプチャー"
                            className="w-full h-full object-cover"
                            src={selectedProject?.capture}
                            onError={(e) =>
                              // @ts-ignore
                              (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                            }
                          />
                        </div>
                      ) : (
                        <div className="w-full h-full bg-white" />
                      )}
                    </div>
                  </div>
                  <div className="flex-row-el flex-center w-[150px]">
                    <p className="text-body text-textcolor/black text-[20px] text-body-ellipsis">
                      {selectedProject?.name}
                    </p>
                  </div>
                </div>

                <div className="flex-col-el items-start gap-2">
                  <div
                    className="flex-row-el flex-center shrink-0"
                    style={{
                      filter:
                        "drop-shadow(-1px 1px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 3px rgba(63, 74, 97, 0.25))",
                    }}
                  >
                    <div className="flex-col-el flex-center relative rounded-full z-10 -ml-3">
                      <div className="flex-col-el absolute -top-7 w-[56px] h-[56px]">
                        <IconViewed />
                      </div>
                      <div className="flex-col-el flex-center bg-brown/80 rounded-full border-solid border-[8px] border-brown/40 w-[70px] h-[70px]">
                        <p className="text-body text-white whitespace-pre text-[18px] leading-9">
                          {selectedProject?.viewed}
                        </p>
                      </div>
                    </div>

                    <div className="flex-col-el flex-center">
                      <p className="text-body text-textcolor/black text-[20px]">
                        {I18n.t(
                          "MSG_USER_PROFILE_PROGRESS_CREATOR_PROJECT_VIEWED_TITLE"
                        )}
                      </p>
                      <div
                        className="flex-row-el items-center w-[170px] h-[24px] rounded-[12px] overflow-hidden -ml-8"
                        style={{
                          background:
                            "linear-gradient(180deg, #8E979E 0%, #B3BEC8 100%)",
                          boxShadow:
                            "inset 3.03894e-16px 4.96296px 4.96296px rgba(137, 145, 152, 0.5), inset -3.03894e-16px -4.96296px 4.96296px rgba(184, 195, 206, 0.5)",
                        }}
                      >
                        <div
                          className="flex-row-el w-full h-full rounded-r-[12px]"
                          style={{
                            transform: `translateX(${
                              100 - selectedProject?.viewed > 30
                                ? 100
                                : selectedProject?.viewed / 30
                            }%)`,
                            background:
                              "linear-gradient(90deg, #F9E532 0%, #FAAF85 94.33%)",
                            boxShadow:
                              "inset -1.33096e-16px -2.17363px 2.17363px #CABA29, inset 1.33096e-16px 2.17363px 2.17363px rgba(255, 255, 58, 0.5)",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex-row-el flex-center shrink-0"
                    style={{
                      filter:
                        "drop-shadow(-1px 1px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 3px rgba(63, 74, 97, 0.25))",
                    }}
                  >
                    <div className="flex-col-el flex-center rounded-full z-10 -ml-3">
                      <div className="flex-col-el flex-center w-[75px] h-[75px]">
                        <IconFavoritedBigHart />
                      </div>

                      <div className="flex-row-el flex-center absolute mb-1">
                        <p className="text-body text-white text-center text-[18px]">
                          {selectedProject?.favorited}
                        </p>
                      </div>
                    </div>

                    <div className="flex-col-el flex-center">
                      <p className="text-body text-textcolor/black text-[20px]">
                        {I18n.t(
                          "MSG_USER_PROFILE_PROGRESS_CREATOR_PROJECT_FAVORITED_TITLE"
                        )}
                      </p>
                      <div
                        className="flex-row-el items-center w-[165px] h-[24px] rounded-[12px] overflow-hidden -ml-8"
                        style={{
                          background:
                            "linear-gradient(180deg, #8E979E 0%, #B3BEC8 100%)",
                          boxShadow:
                            "inset 3.03894e-16px 4.96296px 4.96296px rgba(137, 145, 152, 0.5), inset -3.03894e-16px -4.96296px 4.96296px rgba(184, 195, 206, 0.5)",
                        }}
                      >
                        <div
                          className="flex-row-el w-full h-full rounded-r-[12px]"
                          style={{
                            transform: `translateX(${
                              100 - selectedProject?.favorited > 30
                                ? 100
                                : selectedProject?.favorited / 30
                            }%)`,
                            background:
                              "linear-gradient(90deg, #F9E532 0%, #FAAF85 94.33%)",
                            boxShadow:
                              "inset -1.33096e-16px -2.17363px 2.17363px #CABA29, inset 1.33096e-16px 2.17363px 2.17363px rgba(255, 255, 58, 0.5)",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flex-col-el flex-center relative px-5 bg-white shrink-0 w-[300px] h-[200px] rounded-[56px] gap-3"
              style={{
                filter:
                  "drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12))",
              }}
            >
              <p className="text-body text-textcolor/black text-[20px]">
                {I18n.t("MSG_USER_PROFILE_PROGRESS_CREATOR_PROJECT_EMPTY")}
              </p>
              <div className="flex-row-el flex-center upsilon">
                <button className="btn btn-primary" onClick={handleGoToCreator}>
                  <div className="flex-row-el flex-center relative mb-[3px]">
                    <p className="text-body text-white text-[20px]">
                      {I18n.t("MSG_USER_PROFILE_PROGRESS_CREATOR_GOTO_BTN")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          )}

          <div className="flex-col-el relative flex-center z-10">
            <div className="flex-row-el relative flex-center gap-4 flex-wrap">
              {userProjects.map((project) => (
                <ProjectCard
                  key={project.project_id}
                  selected={selectedProject?.project_id === project.project_id}
                  handleSelectProject={handleSelectProject(project)}
                  favorited={project.favorited}
                >
                  <div className="flex-col-el flex-center absolute right-4">
                    <img
                      alt="officail app card"
                      src={`${Constants.assetHost}/assets/images/app_card_user.png`}
                      className="w-[77px] h-[95px]"
                    />
                    <div className="flex-col-el flex-center w-[67px] h-[64px] absolute top-[20px] left-[5px]">
                      {project.capture ? (
                        <div className="flex-col-center w-full h-full overflow-hidden">
                          <img
                            alt="アプリのキャプチャー"
                            className="w-[149px] h-[112px] object-cover"
                            src={project?.capture}
                            onError={(e) =>
                              // @ts-ignore
                              (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                            }
                          />
                        </div>
                      ) : (
                        <div className="w-full h-full bg-white" />
                      )}
                    </div>
                  </div>
                </ProjectCard>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
