import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { TutorialArrow } from "common/assets";
import { ProjectType } from "features/creator/types";
import { selectAllProjects } from "features/creator/slice";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: (hint?: boolean) => void;
}) => {
  const { close } = props;
  const { scale } = useScale();
  const navigate = useNavigate();
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-display-edit-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 10 * scale,
        y: rect.y - 10 * scale,
        width: rect.width + 20 * scale,
        height: rect.height + 20 * scale,
      });
    }, 300);
  }, [scale]);

  const handleClick = useCallback(() => {
    close(true);
    if (!userProjects || userProjects.length === 0) {
      console.error(
        `failed to get user projects: projects length - ${projects.length}.`
      );
    } else {
      navigate(
        `${
          userProjects[userProjects.length - 1].project_id
        }?type=basic&is_tutorial_app=true`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Overlay area={panelRect} onClick={handleClick}>
      <div
        className="absolute z-50"
        style={{
          width: 108 * scale,
          height: 105 * scale,
          transform: `translate(${panelRect.x - 90 * scale}px, ${
            panelRect.y - 10 * scale
          }px) rotate(-45deg)`,
        }}
      >
        <TutorialArrow />
      </div>
    </Overlay>
  );
};

export default Step;
