import { SVGProps } from "react";

export const TopMessageBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={224}
    height={174}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.157 137.23C189.15 130.131 224 102.501 224 69.5 224 31.116 183.5 0 112 0 39.644 0 0 31.116 0 69.5c0 36.257 37.028 66.029 100.549 69.217-3.592 13.859-7.272 27.878-8.691 32.769-.288.465-.469.879-.533 1.234-.205 1.128.011.563.533-1.234 4.13-6.685 30.167-24.097 46.299-34.256Z"
      fill="#FEFDFE"
    />
  </svg>
);
