import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import anime from "animejs";
import { RootState } from "app/store";
import { actions } from "features/creator/slice";
import { DisplayMode, ProjectPanelType } from "features/creator/types";
import { TopLayout } from "features/creator/tutorial/top/stages/Layout";
import { TabletCapture } from "features/creator/tutorial/top/TabletCapture";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const dispatch = useDispatch();
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );

  useEffect(() => {
    anime({
      targets: document.getElementsByClassName(
        `app-project-card-${selectedProjectId}`
      ),
      opacity: [0, 1],
      duration: 800,
      easing: "easeInOutQuad",
    });
    dispatch(actions.updateSelectedProjectId(null));
    dispatch(actions.updateDisplayMode(DisplayMode.NONE));
    dispatch(actions.updateProjectPanel(ProjectPanelType.SHARED));
    dispatch(actions.updateSortFilterPanel(true));
    dispatch(actions.updateKeyword(""));
    dispatch(
      actions.handleUpdatePublishedProjectFilter({
        type: "onlyOfficial",
        value: true,
      })
    );
    dispatch(
      actions.handleUpdatePublishedProjectFilter({
        type: "userFavorited",
        value: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TopLayout next={next}>
      <div className="flex-col-el flex-center gap-6 ml-11 mt-10">
        <p className="text-body text-[80px] text-white leading-[70px]">4</p>

        <div className="flex-col-center gap-2">
          <TabletCapture scale={0.08} />

          <p className="text-body text-center whitespace-pre text-[18px] text-white">
            {I18n.locale === "ja"
              ? "タブレットの\nそうさ"
              : "タブレットの\n操作"}
          </p>
        </div>
      </div>
    </TopLayout>
  );
};

export default Step;
