import { useState, PointerEvent } from "react";
import { useSelector } from "react-redux";
import i18n from "i18n-js";
import { RootState } from "app/store";
import { stampCategories } from "app/assets";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { ComponentTypeIds, ComponentCategory } from "common/components";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType } from "features/creator/types";

export const StampPanel = () => {
  const play = usePlaySound();
  const handleAction = useActionCommand();
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );

  const assets = useSelector((state: RootState) => state.config.assets);
  const [selectedCategory, setSelectedCategory] = useState("stamps/character");

  const handleTabClick = (category: string) => () => {
    play();
    setSelectedCategory(category);
  };

  // onPointerUp handlerで対応しないとRenderDesignScreenのhandleSelectionEndが反応しちゃう
  const handleStampClick = (url: string, size: number) => (e: PointerEvent) => {
    play();
    e.stopPropagation();
    handleAction({
      type: ActionCommandType.ADD_COMPONENT,
      componentType: ComponentTypeIds.STAMP,
      category: ComponentCategory.ACTION,
      screenId: selectedScreenId,
      image: {
        url: url,
        size: size,
        width: 100,
        height: 100,
      },
    });
  };

  const TitleComponent = ({ title }) => (
    <div className="w-full h-[32px] bg-beige/alt flex-col-view justify-center items-start rounded-t-[12px]">
      <p className="text !leading-[30px] text-white !ml-[16px]">{title}</p>
    </div>
  );

  return (
    <div className="flex-row-view items-start bg-beige/60 p-[24px] rounded-[12px] border-solid border-white border-[4px] left-[-330px]">
      <div className="flex-col-view bg-gray2/20 mt-[32px] rounded-l-[16px] border-[4px] border-r-0 border-solid border-white overflow-hidden">
        {stampCategories.map((category, index) => (
          <div
            key={index}
            onPointerUp={handleTabClick(category)}
            className={`flex-row-view items-center w-[160px] h-[40px] pl-2 overflow-hidden cursor-pointer ${
              selectedCategory === category
                ? "bg-green/altcolor"
                : "bg-gray2/20"
            }`}
          >
            <p className="text text-textcolor/black !text-[16px]">
              {i18n.t(
                `MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP_${
                  category.toUpperCase().split("/")[1]
                }`
              )}
            </p>
          </div>
        ))}
      </div>

      <div className="flex-col-view w-[600px]">
        <TitleComponent
          title={i18n.t("MSG_CREATOR_MENU_COMPONENT_PIECE_STAMP")}
        />
        <div className="flex-row-view items-start justify-center w-full h-[680px] bg-white rounded-b-[12px] visible-scrollbar">
          <div
            className="flex-row-view items-start flex-wrap"
            style={{ width: Math.floor(600 / 122) * 122 }}
          >
            {assets[selectedCategory].map((asset, index) => (
              <div
                key={index}
                className="flex-col-center w-[102px] h-[104px] m-[10px] bg-beige/alt2 rounded-[12px] drop-shadow-[2px_2px_0px_rgba(63,74,97,0.1)]"
              >
                <img
                  alt="スタンプ画像"
                  src={`${Constants.assetHost}/${asset.fileKey}`}
                  className="w-[100px] h-[100px] cursor-pointer"
                  onPointerUp={handleStampClick(
                    `${Constants.assetHost}/${asset.fileKey}`,
                    asset.fileSize
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
