/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Blockly from "blockly";
import throttle from "lodash-es/throttle";
import { Scene } from "@babylonjs/core/scene";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { useOpenCv } from "common/hook";
import { Loading } from "common/elements";
import { useInitGame } from "features/courses/hook";
import { Course, CourseMessageContent } from "features/courses/types";
import { BackToCourseTop, GameSceneTitle } from "features/courses/components";
import { MainScenePage } from "./scene";
import { Control } from "./recognition";
import { BlocklyWorkspace } from "./blockly";

export const TangibleGameMainPage = ({
  stage,
  step,
}: {
  stage: number;
  step: number;
}) => {
  const { scale, width } = useScale();
  const [event, setEvent] = useState(null);
  const [reload, _setReload] = useState(false);
  const reloadRef = useRef(reload);
  const setReload = (reload: boolean) => {
    reloadRef.current = reload;
    _setReload(reload);
  };
  const handleResize = useMemo(
    () =>
      throttle(
        () => {
          setReload(!reloadRef.current);
        },
        1000,
        { leading: false, trailing: true }
      ),
    []
  );
  const [opencv, setOpencv] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gameStart, setGameStart] = useState(false);
  const { gameBgm } = useInitGame(1, 1, handleResize);
  useOpenCv({ setInitialized: () => setOpencv(true) });
  const [scene, setScene] = useState<Scene | null>(null);
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);
  const config = useSelector((state: RootState) => state.config.userConfig);

  const courseDisplay = useSelector(
    (state: RootState) => state.courses.message[Course.TANGIBLE][stage - 1]
  );

  const handleHideChaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target && workspace) {
      var target = e.target as HTMLElement;

      while (target) {
        const className = target.getAttribute("class");
        if (className !== null && className.indexOf("blocklySelected") !== -1) {
          return;
        }
        target = target.parentElement;
      }
      workspace.hideChaff(true);
    }
  };

  return (
    <div
      onClick={handleHideChaff}
      className="flex-col-view w-full h-full bg-transparent"
    >
      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_scene.jpg`}
        className="absolute top-0 left-0 w-full h-full"
      />

      <div className="flex-col-view items-start w-full h-full z-[1] opacity-100 pointer-events-none">
        <div className="flex-col-el w-full z-10" style={{ height: 80 * scale }}>
          <GameSceneTitle
            level={step}
            title={
              courseDisplay
                ? (courseDisplay[config.locale] as CourseMessageContent).level[
                    step - 1
                  ].name.replace("\n", "")
                : ""
            }
          />
        </div>

        <div
          className="flex-col-view !flex-1 w-full h-full pointer-events-none"
          style={{
            marginTop: 32 * scale,
          }}
        >
          <div
            id="game-main-scene-canvas"
            className="flex-col-view !absolute left-0 h-full rounded-[10px] pointer-events-none"
            style={{
              width: width,
              padding: `0px ${10 * scale}px ${10 * scale}px`,
            }}
          >
            <MainScenePage
              stage={1}
              step={1}
              reload={reload}
              setReload={setReload}
              scene={scene}
              gameBgm={gameBgm}
              gameStart={gameStart}
              setGameStart={setGameStart}
              setScene={setScene}
              workspace={workspace}
              event={event}
              setLoading={setLoading}
            />
          </div>
        </div>

        <div
          className="flex-col-view !absolute top-0 left-0 !flex-1 h-full w-full rounded-[10px] z-20 pointer-events-none"
          style={{
            padding: `0px ${10 * scale}px ${17 * scale}px`,
          }}
        >
          <Control workspace={workspace} setEvent={setEvent} />

          <BlocklyWorkspace workspace={workspace} setWorkspace={setWorkspace} />
        </div>
      </div>

      {(loading || !opencv) && <Loading />}
    </div>
  );
};

export const TangibleGamePage = () => {
  const { stageId, stepId } = useParams<{
    stageId?: string;
    stepId?: string;
  }>();
  const step = Number(stepId);
  const stage = Number(stageId);
  return isNaN(stage) || isNaN(step) || stage > 3 || step > 3 ? (
    <BackToCourseTop />
  ) : (
    <TangibleGameMainPage stage={stage} step={step} />
  );
};
