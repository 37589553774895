import Constants from "common/constant";

export const Step4 = () => {
  return (
    <div className="flex-row-view w-full h-full">
      <div className="flex-row-view mt-[30px] w-full h-[400px]">
        <div className="flex-col-center w-[514px] h-full ml-[39px] mr-[48px]">
          <div className="flex-row-view items-end">
            <p className="text text-textcolor/black">右上にある</p>
            <div className="flex-row-center mx-2">
              <img
                alt="ロゴ"
                src="/logo.png"
                className="w-[56px] h-[56px] z-10"
              />
            </div>
            <p className="text text-textcolor/black">マークを押すと、</p>
            <br />
          </div>
          <p className="text text-textcolor/black">
            アプリの設定のほか、
            <br />
            「トップページへもどる」や
            <br />
            「お問い合わせ」
            <br />
            などのボタンがあります。
            <br />
            <br />
            左上にある自分のアイコン画像を押すと、
            <br />
            自分の成績や設定が確認できる
            <br />
            「マイページ」を開くことが
            <br />
            できます。
          </p>
        </div>
        <div className="flex-row-center">
          <img
            alt="設定画面のキャプチャー"
            src={`${Constants.assetHost}/assets/images/app_setting_capture.png`}
            className="w-[400px] h-[386px]"
          />
        </div>
      </div>
    </div>
  );
};
