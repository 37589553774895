import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Typography } from "@mui/material";
import { useRecordEvent } from "app/hook";
import { AnalyticsEventType } from "app/types";
import { btnDelay } from "common/utils";
import { selectAllGroupUsers } from "features/user/slice";
import { AppUser, AccountType } from "features/user/types";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";
import { ParentsSignTop } from "./ParentsSignTop";

export const ParentsLinkedAccount = ({
  parentsUsers,
  navigate,
}: {
  parentsUsers: AppUser[];
  navigate: (path: string) => void;
}) => {
  const handleRecordEvent = useRecordEvent();
  const handleGotoSign = (provider: string, email: string) => () =>
    btnDelay(() => {
      handleRecordEvent(
        AnalyticsEventType.PARENT_GATE_MENU_PARENT_USER_ACCOUNT_SING_LINKED_ACCOUNT_CLICK,
        {
          email,
          provider,
        }
      );
      navigate(
        `/parentsSignInLinkedAccount?email=${email}&provider=${provider}`
      );
    });

  return (
    <div className="flex-col-el gap-5">
      <Typography variant="subtitle1">
        {I18n.t("MSG_SIGN_WITH_LINKED_PARENTS_ACCOUNT_NOTICE")}
      </Typography>
      <div className="flex-row-el justify-center gap-5">
        {parentsUsers.map((user) => (
          <div key={user.uid} className="flex-col-center gap-3">
            <div className="flex-col-center w-[92px] pointer-events-auto">
              <div
                className="flex-col-center cursor-pointer bg-white rounded-full w-[80px] h-[80px]"
                style={{
                  boxShadow:
                    "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                }}
              >
                <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                  <img
                    key={user.icon}
                    alt="ユーザプロフィール画像"
                    src={user.icon}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>

              <div className="flex-col-center w-[84px] h-[24px] text-gray2/100">
                <p className="text-body text-center text-textcolor/black text-font-size-body2-narrow w-full text-body-ellipsis">
                  {user.display_name}
                </p>
              </div>
            </div>

            <div className="flex-col-el flex-center relative upsilon w-[200px]">
              <button
                onClick={handleGotoSign(user.provider, user.email)}
                className="btn btn-primary w-full"
              >
                <p className="text-body text-[24px] text-white !mb-[3px]">
                  {I18n.t("MSG_SIGN_IN_BTN")}
                </p>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const ParentsSign = (props: RouteProps) => {
  const { navigate } = props;
  const groupUsers = useSelector(selectAllGroupUsers);

  const handleGoBack = () => navigate("/");

  return (
    <div className="flex-col-el items-center justify-around w-full h-full">
      {groupUsers.filter((u) => u.account_type === AccountType.PARENTS).length >
      0 ? (
        <div className="flex-col-center gap-10 w-[500px] h-[510px]">
          <ParentsLinkedAccount
            parentsUsers={groupUsers.filter(
              (u) => u.account_type === AccountType.PARENTS
            )}
            navigate={navigate}
          />
        </div>
      ) : (
        <div className="flex-col-center gap-10 w-[450px] h-[510px]">
          <ParentsSignTop navigate={navigate} />
        </div>
      )}

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
