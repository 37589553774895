import I18n from "i18n-js";
import { Category, Block, Value, Shadow, Field, Label } from "common/blockly";

export const Animation = () => {
  return (
    <Category
      name="shape"
      categorystyle="text_category"
      toolboxitemid="animation"
    >
      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_POSITION")}
        web-class="toolbox-label"
      />
      <Block type="component_property_setter_x_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">0</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_x_by_step_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_y_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">0</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_y_by_step_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_x_y_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="x">
          <Shadow type="math_number">
            <Field name="NUM">0</Field>
          </Shadow>
        </Value>
        <Value name="y">
          <Shadow type="math_number">
            <Field name="NUM">0</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_x_y_by_step_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="x">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
        <Value name="y">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_SIZE")}
        web-class="toolbox-label"
      />
      <Block type="component_property_setter_width_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">110</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_height_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">110</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_width_height_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="width">
          <Shadow type="math_number">
            <Field name="NUM">110</Field>
          </Shadow>
        </Value>
        <Value name="height">
          <Shadow type="math_number">
            <Field name="NUM">110</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_scale_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">0.1</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_border_width_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_shadow_radius_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_text_font_size_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">14</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_text_shadow_radius_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_APPEARANCE")}
        web-class="toolbox-label"
      />
      <Block type="component_property_setter_opacity_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_left_rotation_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_angle">
            <Field name="value">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_right_rotation_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_angle">
            <Field name="value">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_border_radius_by_time">
        <Value name="time">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
    </Category>
  );
};
