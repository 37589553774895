import * as React from "react";

export const MainTitleTag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 364 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m36.918 78.208-17.47 44.622 27.1 27.603 304.582-41.927.543-66.342-46.464-24.971-268.29 61.015Z"
      fill="#3F4A61"
    />
    <path
      d="M26.918 68.208 9.448 112.83l27.1 27.603L341.13 98.506l.543-66.342-46.464-24.971-268.29 61.015Z"
      fill="#F9E532"
    />
  </svg>
);
