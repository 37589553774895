import { SVGProps } from "react";

export const IconBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={205}
    height={152}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105 152c78 0 100-34.026 100-76S176.5 0 105 0 5 34.026 5 76c0 15.782 3.11 30.441 11.138 42.59L0 146.025l33.036-10.627C48.568 145.788 71.672 152 105 152Z"
      fill="#FEFDFE"
    />
  </svg>
);
