/* eslint-disable no-new-func */
import { useRef, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { useScale } from "common/utils";
import { useCanvas } from "features/courses/hook";
import { AssetModelType } from "features/builder/types";
import { actions, selectAllMapModel } from "features/builder/slice";
import { loadMesh } from "./loader";
import { createOuterMesh } from "./util";
import { performAnimation } from "./animations";

interface SceneRenderConfigFunc {
  setScene?: (scene: Scene) => void;
  setCharacter: (character: AbstractMesh) => void;
  setTotalGem: (totalGem: number) => void;
  handlePlusGemScore: (totalGem: number) => void;
}

export const GameScene = ({
  config,
  reload,
}: {
  config: SceneRenderConfigFunc;
  reload: boolean;
}) => {
  const dispatch = useDispatch();
  const { scale, ratio } = useScale();
  const elementRef = useRef<HTMLDivElement>(null);
  const mapModels = useSelector(selectAllMapModel);
  const radius = useMemo(
    () => (ratio > 1 ? (scale > 0.7 ? 20 / scale : 40 * scale) : 20 / scale),
    [scale]
  );

  const onSceneReady = useCallback(
    async (canvas: HTMLCanvasElement, engine: Engine, scene: Scene) => {
      const camera = new ArcRotateCamera(
        "camera",
        Math.PI / 4,
        Math.PI / 4,
        radius,
        new Vector3(9, 0, 9),
        scene
      );

      camera.lowerBetaLimit = 0;
      camera.upperBetaLimit = Math.PI / 2;
      camera.lowerRadiusLimit = radius - 10 / scale;
      camera.upperRadiusLimit = radius + 10 / scale;
      camera.attachControl(canvas, true);

      const light = new HemisphericLight(
        "light",
        new Vector3(-3, 5, -3),
        scene
      );
      light.intensity = 1.5;

      scene.clearColor = new Color4(0, 0, 0, 0);

      const gems = [];
      let character: AbstractMesh;
      for (let i = 0; i < mapModels.length; i++) {
        const model = mapModels[i];
        const position = new Vector3(
          model.position.x,
          model.position.y,
          model.position.z
        );
        const mesh = await loadMesh(scene, model.filename, model.offset);
        mesh.getChildMeshes().forEach((subMesh) => {
          if (subMesh.name.indexOf("Cube") !== -1) {
            subMesh.isVisible = false;
          }
        });
        const boundingInfo = mesh.getBoundingInfo();
        const outer = createOuterMesh(
          scene,
          boundingInfo.boundingBox,
          position
        );
        outer.scaling = new Vector3(
          model.scaling?.x ?? 1,
          model.scaling?.y ?? 1,
          model.scaling?.z ?? 1
        );
        outer.rotation = new Vector3(
          0,
          model.direction
            ? Math.atan2(Number(model.direction.x), Number(model.direction.z))
            : 0,
          0
        );
        mesh.parent = outer;
        if (model.type === AssetModelType.CHARACTER) {
          outer.name = AssetModelType.CHARACTER;
          character = outer;
          config.setCharacter(character);
          scene.getAnimationGroupByName("ArmLeftAction")?.stop();
          scene.getAnimationGroupByName("ArmRightAction")?.stop();
          scene.getAnimationGroupByName("BodyAction")?.play(true);
        } else if (model.type === AssetModelType.GEM) {
          outer.name = AssetModelType.GEM;
          gems.push(outer);
        } else if (model.type === AssetModelType.CONDITION) {
          outer.name = AssetModelType.CONDITION;
          outer.metadata = model.metadata;
        } else {
          outer.name = `${model.type}_asset`;
        }
      }

      const mark = await loadMesh(scene, "lightning.gltf.glb");
      mark.name = "mark";
      mark.setEnabled(false);

      gems.forEach((gem) => {
        performAnimation(scene, gem);
      });
      setTimeout(() => {
        dispatch(actions.updateLoading(false));
      }, 300);

      scene.onAfterRenderObservable.add(() => {
        if (!scene.isDisposed) {
          gems.forEach((gem) => {
            if (
              !gem.isDisposed() &&
              character &&
              character.intersectsMesh(gem)
            ) {
              gem.dispose();
              config.handlePlusGemScore(gems.length);
            }
          });
        }
      });

      config.setScene(scene);

      engine.runRenderLoop(() => {
        scene.render();
      });
    },
    []
  );

  useCanvas(
    elementRef,
    onSceneReady,
    (error) => {
      console.log(error);
    },
    reload
  );

  return (
    <div
      ref={elementRef}
      className="absolute w-full h-full pointer-events-none"
    />
  );
};
