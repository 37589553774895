import {
  characterDirection,
  CHARACTER_DIRECTION,
} from "features/courses/algorithm/game/scene/maps/tilesets";
import { TileMapType } from "features/courses/algorithm/game/scene/maps/TileMapType";

const character = [
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gemLayer1 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gemLayer2 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 29, 0, 0],
  [0, 0, 0, 0, 0],
];
const gemLayer3 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gem = [gemLayer1, gemLayer2, gemLayer3];

const landscapeLayer1 = [
  [88, 99, 99, 99, 88],
  [87, 175, 87, 175, 87],
  [88, 99, 99, 99, 87],
  [88, 99, 87, 87, 87],
  [88, 88, 88, 99, 99],
];
const landscapeLayer2 = [
  [67, 0, 0, 0, 127],
  [0, 97, 0, 97, 25],
  [238, 0, 0, 0, 0],
  [0, 0, 24, 0, 0],
  [27, 0, 238, 0, 0],
];
const landscapeLayer3 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];
const landscape = [landscapeLayer1, landscapeLayer2, landscapeLayer3];

const map: TileMapType = {
  layers: 3,
  width: 5,
  height: 5,
  tileSize: 2,
  character: {
    layer: 2,
    data: character,
    direction:
      characterDirection[CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X],
  },
  gem: gem,
  landscape: landscape,
};

export default map;
