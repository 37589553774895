import { SVGProps } from "react";

export const IconMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={5.5}
      y={9.5}
      width={37}
      height={29}
      rx={1.5}
      fill="#FEFDFE"
      stroke="#667786"
      strokeWidth={3}
    />
    <path d="m6 10 18 16 18-16" stroke="#667786" strokeWidth={3} />
  </svg>
);
