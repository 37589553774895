import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippet } from "features/courses/program/game/blockly/uitls";

export const InitButtonClickBlock = () => {
  Blockly.Blocks["program_button_click_event_block"] = {
    init: function () {
      const components = [["ボタン１", "button_1"]];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_button_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          let targetImage;
          let targetLabel;
          let border;
          let answerMark;
          var order = 1;
          const targetImageIdPrefix = "course-program-image-";
          const targetLabelIdPrefix = "course-program-label-";
          const targetEles = ["Pineapple", "Pineapple", "い", "Grape", "Grape"];
          const result = [false, false, false, false, false];
          window.LoopTrap = 10;
          if(${result}){
            ${logic}
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitShowImageBlock = () => {
  Blockly.Blocks["show_image_block"] = {
    init: function () {
      const input = this.appendDummyInput();
      const options = [
        [
          {
            src: `${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
            width: 30,
            height: 30,
            alt: "Fragaria",
          },
          `Fragaria#${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
            width: 30,
            height: 30,
            alt: "Pineapple",
          },
          `Pineapple#${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          `Grape#${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
        ],
      ];
      input
        .appendField(new Blockly.FieldDropdown(options), "Image")
        .appendField("%{BKY_DISPLAY_EVENT}");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour("#FFAB19");
    },
  };
  BlocklyJs["show_image_block"] = function (block: Blockly.Block) {
    const selectedValue = block.getField("Image").getValue().split("#");
    const selectedImage = selectedValue[0];
    const selectedImageSrc = selectedValue[1];
    return `
        highlightBlock("${block.id}", true);
        targetImage = document.getElementById(targetImageIdPrefix.concat('', order));
        targetLabel = document.getElementById(targetLabelIdPrefix.concat('', order));
        if(targetLabel){
          targetLabel.style.opacity = "0";
        };
        if(targetImage instanceof HTMLImageElement){
          targetImage.src = "${selectedImageSrc}";
          sound();
          targetImage.style.opacity = "1";
          targetImage.style.transform = "scale(1)";
        };
        result[order - 1] = targetEles[order - 1] === "${selectedImage}";
        await sleep(700);
        ${showAnswerSnippet()}
        if(!result[order - 1]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        await sleep(700);
        targetLabel = document.getElementById("course-program-arrow-".concat('', order));
        if(targetLabel){
          targetLabel.style.opacity = "1";
        };
        order += 1;
        highlightBlock("${block.id}", false);
    `;
  };
};

export const InitShowLabelTextBlock = () => {
  Blockly.Blocks["show_label_text_block"] = {
    max: 1,
    init: function () {
      this.jsonInit({
        type: "show_label_text_block",
        message0: "%1%{BKY_DISPLAY_EVENT}",
        args0: [
          {
            type: "input_value",
            name: "value",
            check: "String",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "text_blocks",
      });
    },
  };
  BlocklyJs["show_label_text_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        targetImage = document.getElementById(targetImageIdPrefix.concat('', order));
        targetLabel = document.getElementById(targetLabelIdPrefix.concat('', order));
        if(targetImage){
          targetImage.style.opacity = "0";
        };
        if(targetLabel instanceof HTMLParagraphElement){
          targetLabel.innerText = ${value};
          sound();
          targetLabel.style.opacity = "1";
          targetLabel.style.transform = "scale(1)";
        };
        result[order - 1] = targetEles[order - 1] === ${value};
        await sleep(700);
        ${showAnswerSnippet()}
        if(!result[order - 1]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        await sleep(700);
        targetLabel = document.getElementById("course-program-arrow-".concat('', order));
        if(targetLabel){
          targetLabel.style.opacity = "1";
        };
        order += 1;
        highlightBlock("${block.id}", false);
    `;
  };
};
