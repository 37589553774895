import { SVGProps } from "react";

export const IconRunPreview = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={124}
    height={95}
    viewBox="0 0 124 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M121 61c0 33.137-26.863 60-60 60S1 94.137 1 61 27.863 1 61 1s60 26.863 60 60Z"
      fill="#FFA03B"
    />
    <path
      d="M121 61c0 33.137-26.863 60-60 60S1 94.137 1 61 27.863 1 61 1s60 26.863 60 60Z"
      fill="url(#project-preview-run-btn-a)"
      fillOpacity={0.8}
    />
    <mask
      id="project-preview-run-btn-b"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={1}
      y={1}
      width={120}
      height={120}
    >
      <path
        d="M121 61c0 33.137-26.863 60-60 60S1 94.137 1 61 27.863 1 61 1s60 26.863 60 60Z"
        fill="#FFA03B"
      />
    </mask>
    <g mask="url(#project-preview-run-btn-b)">
      <path
        d="M114 26c0 13.807-23.729 25-53 25S8 39.807 8 26 31.729 1 61 1s53 11.193 53 25Z"
        fill="url(#project-preview-run-btn-c)"
        fillOpacity={0.5}
      />
    </g>
    <g filter="url(#project-preview-run-btn-d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61 115c29.823 0 54-24.177 54-54S90.823 7 61 7 7 31.177 7 61s24.177 54 54 54Zm0 6c33.137 0 60-26.863 60-60S94.137 1 61 1 1 27.863 1 61s26.863 60 60 60Z"
        fill="#FEFDFE"
      />
    </g>
    <defs>
      <radialGradient
        id="project-preview-run-btn-a"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -43 88.9668 0 61 33.5)"
      >
        <stop stopColor="#FEFDFE" stopOpacity={0.7} />
        <stop offset={0.276} stopColor="#FEFDFE" stopOpacity={0.3} />
        <stop offset={1} stopColor="#FEFDFE" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="project-preview-run-btn-c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(0 -51.0417 233.611 0 61 51)"
      >
        <stop stopColor="#FEFDFE" stopOpacity={0.6} />
        <stop offset={1} stopColor="#FEFDFE" stopOpacity={0} />
      </radialGradient>
      <filter
        id="project-preview-run-btn-d"
        x={0}
        y={0}
        width={124}
        height={124}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.290196 0 0 0 0 0.380392 0 0 0 0.15 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2727_17449"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2727_17449"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-1} dy={-1} />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.290196 0 0 0 0 0.380392 0 0 0 0.1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_2727_17449" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={-1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.247059 0 0 0 0 0.290196 0 0 0 0 0.380392 0 0 0 0.1 0" />
        <feBlend
          in2="effect2_innerShadow_2727_17449"
          result="effect3_innerShadow_2727_17449"
        />
      </filter>
    </defs>
  </svg>
);
