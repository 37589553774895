import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import Constants from "common/constant";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-pickup-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x,
        y: rect.y - 40 * scale,
        width: rect.width + 20 * scale,
        height: rect.width + 20 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={7} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <img
            alt="pick up title"
            src={`${Constants.assetHost}/assets/images/title_text_pickup.png`}
            className="w-[150px] h-[42px]"
          />

          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "「みんなのアプリ」から\nとくに すてきなアプリを\nまいつき しょうかい\nしています。"
              : "「みんなのアプリ」から\nとくにすてきなアプリを\n毎月紹介\nしています。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
