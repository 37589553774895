import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import { actions } from "features/builder/slice";
import { updateMapProjectContent } from "features/builder/api";
import { ActionCommand, ActionCommandType } from "features/builder/types";

export const useActionCommand = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.appUser);

  return (command: ActionCommand) => {
    switch (command.type) {
      case ActionCommandType.ADD_MODE:
        dispatch(actions.addModelToMap(command.model));
        dispatch(updateMapProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.REMOVE_MODE:
        dispatch(actions.removeModelFromMap(command.meshId));
        dispatch(updateMapProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.CHANGE_MODE:
        dispatch(
          actions.updateMapModel({
            id: command.id,
            changes: command.changes,
          })
        );
        dispatch(updateMapProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.CHANGE_BLOCKLY:
        dispatch(actions.updateBlocks(command.block));
        dispatch(updateMapProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.UNDO:
        dispatch(updateMapProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.REDO:
        dispatch(updateMapProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.CLEAR:
        dispatch(updateMapProjectContent({ uid: user.active.uid }));
        break;
      default:
        break;
    }
  };
};
