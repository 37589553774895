import { useMemo, useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { AppDispatch, RootState } from "app/store";
import { TutorialArrow } from "common/assets";
import { SpinModal, DialogMessage } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { copyProject } from "features/creator/api";
import { MAX_PROJECTS } from "features/creator/constants";
import { CreateProjectModal } from "features/creator/elements";
import { actions, selectAllProjects } from "features/creator/slice";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";
import { ProjectType, ProjectPanelType } from "features/creator/types";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  const play = usePlaySound();
  const { scale } = useScale();
  const [copy, setCopy] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [guidance, setGuidance] = useState(false);
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.author_uid === user.active.uid &&
          !project.deleted_at &&
          project.type === ProjectType.BASIC
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projects]
  );
  const officialProjects = useMemo(
    () =>
      projects
        .filter((project) => project.published)
        .filter(
          (project) =>
            project.name === "チュートリアル" &&
            project.author_name === "まなパズ公式"
        ),
    [projects]
  );
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-display-copy-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 10 * scale,
        y: rect.y - 20 * scale,
        width: rect.width + 20 * scale,
        height: rect.width + 20 * scale,
      });
    }, 500);
  }, [scale]);

  const handleClick = () => {
    play();
    btnDelay(() => {
      if (userProjects.length > MAX_PROJECTS - 1) {
        setGuidance(true);
      } else {
        setCopy(true);
      }
    });
  };
  const handleCopyProject = (name: string) => {
    setLoading(true);
    btnDelay(() => {
      dispatch(
        copyProject({
          uid: user.active.uid,
          copied_project_id: officialProjects[0].project_id,
          name,
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(
            actions.updateProjectFavorited({
              project_id: officialProjects[0].project_id,
              favorited: officialProjects[0].favorited + 1,
              is_favorited: true,
            })
          );
          dispatch(actions.updateProjectPanel(ProjectPanelType.USER));
          dispatch(actions.updateSortFilterPanel(false));
          dispatch(
            actions.handleUpdatePublishedProjectFilter({
              type: "onlyOfficial",
              value: false,
            })
          );
          next();
        });
    });
  };
  const handleCopyProjectCancel = () => {
    play();
    btnDelay(() => setCopy(false));
  };

  return (
    <>
      {loading && <SpinModal />}

      <DialogMessage
        open={guidance}
        title={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_MESSAGE")}
        close={close}
      />

      <CreateProjectModal
        zIndex={20000}
        visible={copy}
        name="チュートリアル"
        type={ProjectType.BASIC}
        create={handleCopyProject}
        close={handleCopyProjectCancel}
      />

      <Overlay area={panelRect} onClick={handleClick}>
        <div
          className="absolute z-50"
          style={{
            width: 108 * scale,
            height: 105 * scale,
            transform: `translate(${panelRect.x - 90 * scale}px, ${
              panelRect.y + 20 * scale
            }px) rotate(-45deg)`,
          }}
        >
          <TutorialArrow />
        </div>
      </Overlay>
    </>
  );
};

export default Step;
