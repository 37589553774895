import { SVGProps } from "react";

export const IconDrawer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      style={{
        mixBlendMode: "color-burn",
      }}
      opacity={0.25}
    >
      <path fill="#3F4A61" d="M0 24V0h4v24z" />
    </g>
    <g
      style={{
        mixBlendMode: "color-burn",
      }}
      opacity={0.25}
    >
      <path fill="#3F4A61" d="M8 24V0h4v24z" />
    </g>
    <g
      style={{
        mixBlendMode: "color-burn",
      }}
      opacity={0.25}
    >
      <path fill="#3F4A61" d="M16 24V0h4v24z" />
    </g>
  </svg>
);
