import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { RootState } from "app/store";
import {
  ChatRoom,
  AccountType,
  ChatRoomMessage,
  ChatRoomMemberType,
} from "features/user/types";
import { AppUser } from "features/user/types";
import { selectGroupUserById } from "features/user/slice";
import { MessageDate } from "./MessageDate";
import { ChatMessageItem, OfficialChatMessageItem } from "./item";

export const TalkChat = (props: {
  user: AppUser;
  chatRoom: ChatRoom;
  chatRoomMessages: ChatRoomMessage[];
}) => {
  const { user, chatRoom, chatRoomMessages } = props;

  const groupUser = useSelector((state: RootState) =>
    selectGroupUserById(
      state,
      chatRoom.members
        .filter(
          (member) =>
            member.type ===
            (user.account_type === AccountType.PARENTS
              ? ChatRoomMemberType.KIDS
              : ChatRoomMemberType.PARENTS)
        )
        .shift().uid
    )
  );

  return (
    <>
      {chatRoomMessages.map((message, index) => {
        const isFromUser =
          message.user_type ===
          (user.account_type === AccountType.PARENTS
            ? ChatRoomMemberType.PARENTS
            : ChatRoomMemberType.KIDS);
        const isOfficial = message.user_type === ChatRoomMemberType.OFFICIAL;

        return (
          <div key={message.id} className="flex-col-el relative">
            {index === 0 ? (
              <MessageDate timestamp={message.created_at} />
            ) : (
              dayjs(
                dayjs(message.created_at, "YYYY-MM-DDTHH:mm:ssZ").format(
                  "YYYY-MM-DD"
                )
              ).diff(
                dayjs(
                  dayjs(
                    chatRoomMessages[index - 1].created_at,
                    "YYYY-MM-DDTHH:mm:ssZ"
                  ).format("YYYY-MM-DD")
                ),
                "days"
              ) > 0 && <MessageDate timestamp={message.created_at} />
            )}

            {isOfficial ? (
              <OfficialChatMessageItem message={message} />
            ) : (
              <ChatMessageItem
                isFromUser={isFromUser}
                name={isFromUser ? user.display_name : groupUser.display_name}
                icon={isFromUser ? user.icon : groupUser.icon}
                messageContentType={message.message_content_type}
                messageContent={message.message_content}
                created_at={message.created_at}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
