import { useState } from "react";
import { Dialog } from "@mui/material";
import { useUserConfig } from "app/hook";
import { FadeTransition } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import Stages from "features/creator/tutorial/top/stages";

export const CreatorTutorialApp = () => {
  const stage = 4;
  const play = usePlaySound();
  const { scale } = useScale();
  const [step, setStep] = useState(12);
  const [show, setShow] = useState(true);
  const handleChangeUserConfig = useUserConfig();

  const handleBack = () => {
    play();
    btnDelay(() => {
      setStep(step - 1);
    });
  };

  const handleNext = () => {
    play();
    btnDelay(() => {
      if (step === Stages[stage].length - 1) {
        setShow(false);
      } else {
        setStep(step + 1);
      }
    });
  };

  const handleClose = () => {
    setShow(false);
    handleChangeUserConfig("tutorial.creator_top", false);
  };

  const Contents = Stages[stage][step];
  return (
    <Dialog
      fullScreen
      open={show}
      maxWidth={false}
      sx={{ zIndex: 10000 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: {
          style: { backgroundColor: "transparent", pointerEvents: "none" },
        },
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          margin: 0,
          maxWidth: "none",
          maxHeight: "none",
          transform: `scale(${scale})`,
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-el flex-center w-full h-full">
        <Contents back={handleBack} next={handleNext} close={handleClose} />
      </div>
    </Dialog>
  );
};
