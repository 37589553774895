/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useScale } from "common/utils";
import Constants, { BASE_WIDTH, BASE_HEIGHT } from "common/constant";

const LoadingImage = React.memo(() => {
  const { scale } = useScale();
  return (
    <img
      alt="ローディング画像"
      src={`${Constants.assetHost}/assets/images/loading.gif`}
      style={{
        width: BASE_WIDTH,
        height: BASE_HEIGHT,
        transform: `translateZ(0) scale(${scale})`,
      }}
    />
  );
});

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: "100%",
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#F0DCCC",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    transition: "none",
    backgroundColor: "#DCA082",
  },
}));

export const AssetLoading = ({
  open,
  progress,
}: {
  open: boolean;
  progress: number;
}) => {
  const { scale, height } = useScale();
  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={open}
      sx={{ zIndex: 9999 }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-col-el absolute top-0 left-0">
        {/* preload material symbols fonts */}
        <span className="material-symbols-outlined text-transparent">
          close
        </span>
      </div>

      <div className="h-full flex-row-center bg-[#f8f1e8]">
        <LoadingImage />
        <div
          className="!absolute flex-col-center origin-top"
          style={{
            top: 673 * (height / BASE_HEIGHT),
            transform: `scale(${scale})`,
          }}
        >
          <div className="w-[360px] h-[16px]">
            <BorderLinearProgress variant="determinate" value={progress} />
            <p className="text !text-[36px] text-[#ffa03b] tracking-[.2em]">
              {progress > 99 ? 99 : progress}%
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
