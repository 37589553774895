export const Block = ({ color }: { color: string }) => (
  <div
    className={`flex-col-center w-[70px] h-[70px] rounded-md border-solid border-textcolor/black/20 ${color}`}
  />
);

const convertColor = (color: string) => {
  switch (color) {
    case "yellow":
      return "bg-yellow/100";
    case "green":
      return "bg-safe";
    case "purple":
      return "bg-[#9370DB]";
    case "aqua":
      return "bg-blue/40";
    case "blue":
      return "bg-blue/100";
    default:
      break;
  }
};

export const Blocks = ({ blocks }: { blocks: any[] }) => {
  return (
    <div className="flex-col-el absolute bottom-[20%] left-[10%] z-50">
      {blocks.map((block, index) =>
        block.type === "anchor" ? (
          <div key={index} />
        ) : (
          <Block key={index} color={convertColor(block.type)} />
        )
      )}
    </div>
  );
};
