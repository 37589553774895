import { useDispatch } from "react-redux";
import I18n from "i18n-js";
import produce from "immer";
import { AppDispatch } from "app/store";
import { Vector2D } from "common/types";
import { usePlaySound } from "common/utils";
import { RENDER_ORDER } from "common/components";
import {
  InputPosition,
  ItemContainer,
  RenderOrderBackIcon,
  RenderOrderFrontIcon,
} from "features/creator/property/components";
import { actions } from "features/creator/slice";
import { PropertyProps } from "features/creator/property/types";

export const PositionProperty = (props: PropertyProps) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const { scale, updateProperty } = props;
  const { id, screenId, property } = props.propertyEntity;

  const handleRenderComponentToFront = () => {
    dispatch(
      actions.changeScreenComponentsOrder({
        screenId: screenId,
        componentId: id,
        order: RENDER_ORDER.FRONT,
      })
    );
    updateProperty();
    play();
  };
  const handleRenderComponentToBack = () => {
    dispatch(
      actions.changeScreenComponentsOrder({
        screenId: screenId,
        componentId: id,
        order: RENDER_ORDER.BACK,
      })
    );
    updateProperty();
    play();
  };

  const handleChange = (value: Vector2D) => {
    const newProperty = produce(property, (draft) => {
      draft.style.transform.translateX = value.x;
      draft.style.transform.translateY = value.y;
    });
    updateProperty(id, newProperty);
  };

  return (
    <div className="flex-row-center !flex-1 !items-end">
      <ItemContainer
        name={I18n.t("MSG_CREATOR_MENU_PROPERTY_ZINDEX")}
        scale={scale}
        width={256}
        height={198}
      >
        <div className="flex-col-center !flex-1 !justify-around">
          <button
            className="opacity-button w-[178px]"
            onClick={handleRenderComponentToFront}
          >
            <div className="flex-row-center">
              <RenderOrderFrontIcon />
              <p className="text text-textcolor/black !text-[16px] !ml-[9px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_ZINDEX_FRONT")}
              </p>
            </div>
          </button>
          <button
            className="opacity-button w-[178px]"
            onClick={handleRenderComponentToBack}
          >
            <div className="flex-row-center">
              <RenderOrderBackIcon />
              <p className="text text-textcolor/black !text-[16px] !ml-[9px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_ZINDEX_BACK")}
              </p>
            </div>
          </button>
        </div>
      </ItemContainer>
      <ItemContainer
        name={I18n.t("MSG_CREATOR_MENU_PROPERTY_POSITION_X_Y")}
        scale={scale}
        width={350}
        height={308}
      >
        <InputPosition
          value={{
            x: property.style.transform.translateX,
            y: property.style.transform.translateY,
          }}
          play={play}
          onChange={handleChange}
        />
      </ItemContainer>
    </div>
  );
};
