import { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import GroupIcon from "@mui/icons-material/Group";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AppsOutageOutlinedIcon from "@mui/icons-material/AppsOutageOutlined";
import { RootState } from "app/store";
import { useRecordEvent } from "app/hook";
import { LocalStorageKey, AnalyticsEventType } from "app/types";
import { WarningMessageBox } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";
import { AccountType, BillingType } from "features/user/types";
import { TutorialInApp } from "features/tutorial/TutorialInApp";
import { Resign } from "./sign";

export const MenuTop = ({ navigate }: { navigate: (path: string) => void }) => {
  const play = usePlaySound();
  const [sign, setSign] = useState(false);
  const handleRecordEvent = useRecordEvent();
  const [resign, setResign] = useState(false);
  const [redirect, setRedirect] = useState("/");
  const [tutorialShow, setTutorialShow] = useState(false);
  const notification = useSelector(
    (state: RootState) => state.config.notification.parents
  );
  const user = useSelector((state: RootState) => state.user?.appUser.main);

  const delay = (path: string) => setTimeout(() => navigate(path), 500);

  const handleOpenNotification = (index: number) => () => {
    handleRecordEvent(AnalyticsEventType.PARENT_GATE_MENU_NOTIFICATION_CLICK);
    delay(`/notification?index=${index}`);
  };

  const handleOpenParentsUserAccount = () => {
    btnDelay(() => {
      if (user?.account_type !== AccountType.PARENTS) {
        handleRecordEvent(
          AnalyticsEventType.PARENT_GATE_MENU_PARENT_USER_ACCOUNT_SING_CLICK
        );
        navigate("/parentsSign");
      } else {
        const lastSignAt = localStorage.getItem(LocalStorageKey.LAST_SIGN_AT);
        if (
          user.billing_type === BillingType.EDUCATION &&
          (!lastSignAt ||
            (lastSignAt &&
              dayjs().diff(dayjs.unix(Number(lastSignAt)), "seconds") >
                10 * 60))
        ) {
          setResign(true);
          setRedirect("/parentsUserAccount");
        } else {
          // Education以外のアカウントとログインしてから10分以内は再度認証はいらない
          handleRecordEvent(
            AnalyticsEventType.PARENT_GATE_MENU_PARENT_USER_ACCOUNT_CLICK
          );
          navigate("/parentsUserAccount");
        }
      }
    });
  };
  const handleOpenParentsUser = () => {
    btnDelay(() => {
      if (user?.account_type !== AccountType.PARENTS) {
        play();
        handleRecordEvent(
          AnalyticsEventType.PARENT_GATE_MENU_PARENT_USER_NOT_SIGN
        );
        setSign(true);
      } else {
        const lastSignAt = localStorage.getItem(LocalStorageKey.LAST_SIGN_AT);
        if (
          user.billing_type === BillingType.EDUCATION &&
          (!lastSignAt ||
            (lastSignAt &&
              dayjs().diff(dayjs.unix(Number(lastSignAt)), "seconds") >
                10 * 60))
        ) {
          setResign(true);
          setRedirect("/parentsUser");
        } else {
          // Education以外のアカウントとログインしてから10分以内は再度認証はいらない
          handleRecordEvent(
            AnalyticsEventType.PARENT_GATE_MENU_PARENT_USER_CLICK
          );
          navigate("/parentsUser");
        }
      }
    });
  };
  const handleOpenSubscription = () => {
    btnDelay(() => {
      if (user?.account_type !== AccountType.PARENTS) {
        play();
        setSign(true);
      } else {
        navigate("/subscription");
      }
    });
  };
  const handleLinkParents = () => {
    btnDelay(() => {
      if (user?.account_type !== AccountType.PARENTS) {
        play();
        handleRecordEvent(
          AnalyticsEventType.PARENT_GATE_MENU_PARENT_USER_NOT_SIGN
        );
        setSign(true);
      } else {
        handleRecordEvent(AnalyticsEventType.PARENT_GATE_MENU_PARENT_APP_CLICK);
        navigate("/parentsApp");
      }
    });
  };
  const handleEnableLab = () => {
    btnDelay(() => {
      if (user?.account_type !== AccountType.PARENTS) {
        play();
        handleRecordEvent(
          AnalyticsEventType.PARENT_GATE_MENU_PARENT_USER_NOT_SIGN
        );
        setSign(true);
      } else {
        handleRecordEvent(AnalyticsEventType.PARENT_GATE_MENU_ENABLE_LAB_CLICK);
        navigate("/enableLab");
      }
    });
  };
  const handleCloseSign = () => {
    play();
    setSign(false);
  };
  const handleCloseResign = () => {
    play();
    setResign(false);
  };

  const handleCourseAnswer = () => delay("/courseAnswer");
  const handleOpenTutorialShow = () => {
    play();
    btnDelay(() => {
      setTutorialShow(true);
    });
  };
  const handleCloseTutorialShow = () => {
    play();
    btnDelay(() => setTutorialShow(false));
  };

  return (
    <>
      {resign && (
        <Resign
          redirect={redirect}
          navigate={navigate}
          close={handleCloseResign}
        />
      )}
      {tutorialShow && <TutorialInApp close={handleCloseTutorialShow} />}
      {sign && (
        <WarningMessageBox
          zIndex={999999}
          title={I18n.t("MSG_SETTING_MENU_SIGN_WARNING_TITLE")}
          message={I18n.t("MSG_SETTING_MENU_SIGN_WARNING_MESSAGE")}
          close={handleCloseSign}
        />
      )}
      <div className="flex-col-el items-center gap-8 w-full h-full px-4">
        {notification.length > 0 && (
          <div className="flex-row-el justify-center items-end w-full mt-8">
            <p className="text-body text-brown/80 text-[20px] leading-[42px] text-center">
              {I18n.t("MSG_SETTING_MENU_NOTIFICATION_TITLE")}
            </p>

            <div
              className="cursor-pointer mx-4"
              onClick={handleOpenNotification(0)}
            >
              <p className="text-body text-brown/80 text-[20px] text-left">
                {notification[0]["ja_kanji"].date}
              </p>
              <div className="flex-col-view border-solid border-[2px] rounded-[8px] border-beige/alt bg-beige/alt2 p-[6px]">
                <p className="text-body text-textcolor/black text-[20px] text-left">
                  {notification[0]["ja_kanji"].title}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="relative flex-row-el items-center justify-start w-full px-2 py-6 bg-white/40 rounded-[8px]">
          <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
            <GroupIcon />
            <p className="text text-textcolor/black !text-[16px] !px-[8px]">
              {I18n.t("MSG_SETTING_CATEGORY_TITLE_APP_ACCOUNT")}
            </p>
          </div>
          <div className="flex-col-el flex-center h-full relative upsilon">
            <button
              onClick={handleOpenParentsUserAccount}
              className="btn btn-primary h-[110px] relative"
            >
              {user.billing_type === BillingType.EDUCATION && (
                <div className="flex-col-el absolute right-2 top-2 z-50">
                  <span className="material-symbols-outlined text-[24px] text-white">
                    lock
                  </span>
                </div>
              )}
              <p className="text-body text-[24px] text-white !mb-[3px]">
                {I18n.t("MSG_SETTING_TITLE_PARENTS_USER_ACCOUNT")}
              </p>
            </button>
          </div>
          <div className="flex-col-el flex-center h-full relative upsilon">
            <button
              onClick={handleOpenParentsUser}
              className="btn btn-primary h-[110px] relative"
            >
              {user.billing_type === BillingType.EDUCATION && (
                <div className="flex-col-el absolute right-2 top-2 z-50">
                  <span className="material-symbols-outlined text-[24px] text-white">
                    lock
                  </span>
                </div>
              )}
              <p className="text-body text-[24px] text-white !mb-[3px]">
                {I18n.t("MSG_SETTING_MENU_PARENTS_USER_BTN")}
              </p>
            </button>
          </div>
        </div>

        <div className="flex-row-el items-center justify-start w-full gap-2">
          <div className="relative flex-row-el items-center justify-start px-2 py-6 flex-1 bg-white/40 rounded-[8px]">
            <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
              <InsertDriveFileIcon />
              <p className="text text-textcolor/black !text-[16px] !px-[8px]">
                {I18n.t("MSG_SETTING_CATEGORY_TITLE_APP_CONTENT")}
              </p>
            </div>

            <div className="flex-col-el flex-center h-full relative upsilon">
              <button
                onClick={handleLinkParents}
                className="btn btn-primary h-[110px]"
              >
                <p className="text-body text-[24px] text-white !mb-[3px]">
                  {I18n.t("MSG_SETTING_GO_TO_PARENTS_LINK")}
                </p>
              </button>
            </div>
            <div className="flex-col-el flex-center h-full relative upsilon">
              <button
                onClick={handleEnableLab}
                className="btn btn-primary h-[110px]"
              >
                <p className="text-body text-[24px] text-white !mb-[3px]">
                  {I18n.t("MSG_SETTING_GO_TO_LAB")}
                </p>
              </button>
            </div>
          </div>

          <div className="flex-row-el relative gap-8">
            <div className="relative flex-row-el items-center justify-start w-full px-2 py-6 bg-white/40 rounded-[8px]">
              <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
                <AppsOutageOutlinedIcon />
                <p className="text text-textcolor/black !text-[16px] !px-[8px]">
                  {I18n.t("MSG_SETTING_CATEGORY_TITLE_APP_INFO")}
                </p>
              </div>

              <div className="flex-col-el flex-center h-full relative upsilon">
                <button
                  onClick={handleCourseAnswer}
                  className="btn btn-blue h-[110px]"
                >
                  <p className="text-body text-[24px] text-white !mb-[3px]">
                    {I18n.t("MSG_SETTING_MENU_COURSE_ANSWER_BTN")}
                  </p>
                </button>
              </div>

              {user && (
                <div className="flex-col-el flex-center h-full relative upsilon">
                  <button
                    onClick={handleOpenTutorialShow}
                    className="btn btn-blue h-[110px]"
                  >
                    <p className="text-body text-[24px] text-white !mb-[3px]">
                      {I18n.t("MSG_SETTING_GO_TO_TUTORIAL")}
                    </p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
