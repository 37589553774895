import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Blockly from "blockly";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { CommandSvg } from "features/courses/assets";
import { BlockLimits } from "features/courses/algorithm/game/blockly/BlockLimits";
import { useBlocklyInit } from "./initial";

export const PreviewBlocklyWorkspace = ({
  gameStart,
  topOffset,
  commandPanelHeight,
  workspace,
  setWorkspace,
  flyoutWidth,
  flyoutHeight,
  setEvent,
}: {
  gameStart: boolean;
  topOffset: number;
  commandPanelHeight: number;
  workspace: Blockly.WorkspaceSvg;
  setWorkspace: (workspace: any) => void;
  flyoutWidth: number;
  flyoutHeight: number;
  setEvent: (code: string) => void;
}) => {
  const { scale } = useScale();
  const BLOCK_LIMITS_WIDTH = 60;
  const [drag, setDrag] = useState(false);
  const blocks = useSelector((state: RootState) => state.builder.blocks);
  const toolboxDef = {
    maxInstances: Object.fromEntries(
      Object.keys(blocks)
        .filter((key) => blocks[key].count > 0)
        .map((key) => [blocks[key].type, blocks[key].count])
    ),
    json: {
      kind: "categoryToolbox",
      contents: [
        {
          kind: "category",
          toolboxitemid: "category",
          contents: Object.keys(blocks)
            .filter((key) => blocks[key].count > 0)
            .map((key) => ({
              ...blocks[key],
            })),
        },
      ],
    },
  };
  const [toolbox, _setToolboxInfo] = useState(
    JSON.parse(JSON.stringify(toolboxDef))
  );
  const toolboxRef = useRef(toolbox);
  const setToolbox = (toolboxInfo: any) => {
    _setToolboxInfo(JSON.parse(JSON.stringify(toolboxInfo)));
  };
  const config = useSelector((state: RootState) => state.config.userConfig);

  useBlocklyInit(
    scale,
    topOffset + commandPanelHeight,
    config.locale,
    toolboxRef,
    setToolbox,
    toolboxDef["maxInstances"],
    null,
    workspace,
    setWorkspace,
    setEvent
  );

  useEffect(() => {
    if (workspace) {
      const flyout = workspace.getFlyout();
      // @ts-ignore
      flyout.onDragOver = () => {
        setDrag(true);
      };
      // @ts-ignore
      flyout.onDrop = () => {
        setDrag(false);
      };
      // @ts-ignore
      flyout.onDragExit = () => {
        setDrag(false);
      };
    }
  }, [workspace]);

  return (
    <div className="flex-col-view !flex-1 w-full h-full">
      <div
        className="flex-col-view !absolute overflow-hidden"
        style={{
          zIndex: gameStart ? 50 : 0,
          top: topOffset * scale,
          width: flyoutWidth + BLOCK_LIMITS_WIDTH * scale,
          height: flyoutHeight - topOffset * scale,
        }}
      >
        <div
          className="flex-col-view h-full bg-white/60 border-solid rounded-[10px] border-[2px] border-white backdrop-blur-[4px]"
          style={{
            width: flyoutWidth + BLOCK_LIMITS_WIDTH * scale,
          }}
        />
      </div>

      <div
        className="flex-col-center rounded-[10px] bg-white !absolute z-[100] opacity-70"
        style={{
          display: drag ? "flex" : "none",
          top: topOffset * scale,
          width: flyoutWidth + BLOCK_LIMITS_WIDTH * scale,
          height: flyoutHeight - topOffset * scale,
        }}
      >
        <div className="flex-col-center">
          <img
            alt="trash"
            src={`${Constants.assetHost}/assets/images/trash.png`}
          />
        </div>
      </div>

      <div
        className="flex-col-center !absolute pointer-events-auto"
        style={{
          top: topOffset * scale,
          width: flyoutWidth + BLOCK_LIMITS_WIDTH * scale,
          height: commandPanelHeight * scale,
        }}
      >
        <div
          className="flex-col-center w-[180px] h-[32px]"
          style={{
            transform: `scale(${scale})`,
            margin: `${25 * scale}px 0`,
          }}
        >
          <CommandSvg />
        </div>
      </div>

      <div className="flex-row-center !items-start !flex-1 w-full">
        <BlockLimits
          offset={topOffset + commandPanelHeight}
          width={BLOCK_LIMITS_WIDTH}
          height={flyoutHeight}
          scale={scale}
          workspace={workspace}
          toolboxRef={toolboxRef}
        />
        <div id="blocklyDiv" className="flex-col-view !flex-1 w-full h-full" />
      </div>
    </div>
  );
};
