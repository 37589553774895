import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { ProjectType } from "features/creator/types";
import { selectAllProjects } from "features/creator/slice";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  const { width, height, scale } = useScale();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );
  const sortedProjects = userProjects.sort((a, b) =>
    dayjs(a.created_time) < dayjs(b.created_time) ? -1 : 1
  );

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        `user-app-project-container-${
          sortedProjects[sortedProjects.length - 1].project_id
        }`
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 30 * scale,
        y: rect.y - 30 * scale,
        width: rect.width + 60 * scale,
        height: rect.height + 60 * scale,
      });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout
        transparent
        position={{
          x: width / 2 - 560 / 2 - 60,
          y: height / 2 - 336 / 2 + 250,
        }}
        next={next}
        close={close}
      >
        <Title activeStep={5} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "つぎは \nコピーした 「チュートリアル」\nを きどう してみましょう！"
              : "次は\nコピーした「チュートリアル」\nを起動してみましょう！"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
