import { SVGProps } from "react";

export const ProgressStep1 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={88} height={88} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.23.012c13.345.276 25.543 6.723 34.11 17.07 8.852 10.69 15.423 24.65 11.499 38.014-3.735 12.719-17.62 17.642-29.502 23.286-11.663 5.54-24.183 13.152-35.93 7.793C9.46 80.725 5.653 66.372 2.71 53.46-.247 40.501-2.426 26.381 5.32 15.632 13.393 4.432 27.522-.272 41.23.012Z"
      fill={props.fill}
    />
  </svg>
);
