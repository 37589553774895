import I18n from "i18n-js";
import { usePlaySound } from "common/utils";
import { IconNew } from "features/creator/assets";
import { PERIOD } from "./type";

export const Period = ({
  period,
  setPeriod,
}: {
  period: PERIOD;
  setPeriod: (period: PERIOD) => void;
}) => {
  const play = usePlaySound();
  const handleChangePeriod = (period: PERIOD) => () => {
    play();
    setPeriod(period);
  };
  return (
    <div
      className="flex-row-el items-center justify-between h-[48px] mt-2 bg-gray2/10 overflow-hidden rounded-xl border-solid border-4 border-white"
      style={{
        boxShadow:
          "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
      }}
    >
      <div className="flex-row-center bg-beige/alt3 w-[68px] h-full">
        <p className="text-body text-textcolor/black">
          {I18n.t("MSG_DATETIME_PERIOD")}
        </p>
      </div>
      <div
        onClick={handleChangePeriod(PERIOD.RECENTLY)}
        className={`flex-row-el flex-center px-8 gap-1 cursor-pointer h-full transition-all duration-300 ${
          period === PERIOD.RECENTLY ? "bg-green/altcolor" : "bg-transparent"
        }`}
      >
        <div className="flex-col-center">
          <IconNew color="#FC6560" />
        </div>
        <p className="text-body text-textcolor/black text-[20px]">
          {I18n.t("MSG_DATETIME_PERIOD_RECENTLY")}
        </p>
      </div>
      <div
        onClick={handleChangePeriod(PERIOD.ALL)}
        className={`flex-row-el flex-center px-8 cursor-pointer h-full transition-all duration-300 ${
          period === PERIOD.ALL ? "bg-green/altcolor" : "bg-transparent"
        }`}
      >
        <p className="text-body text-textcolor/black text-[20px]">
          {I18n.t("MSG_APP_ALL")}
        </p>
      </div>
    </div>
  );
};
