export const fonts = [
  { "MPLUSRounded1c-Light": "/assets/fonts/MPLUSRounded1c-Light.ttf" },
  { "MPLUSRounded1c-Regular": "/assets/fonts/MPLUSRounded1c-Regular.ttf" },

  { "NotoSansJP-Light": "/assets/fonts/NotoSansJP-Light.otf" },
  { "NotoSansJP-Regular": "/assets/fonts/NotoSansJP-Regular.otf" },
  { "NotoSansJP-Bold": "/assets/fonts/NotoSansJP-Bold.otf" },

  { "NotoSerifJP-Light": "/assets/fonts/NotoSerifJP-Light.otf" },
  { "NotoSerifJP-Regular": "/assets/fonts/NotoSerifJP-Regular.otf" },
  { "NotoSerifJP-Bold": "/assets/fonts/NotoSerifJP-Bold.otf" },
];

export const images = [
  "/assets/images/normal_blue_case.png",
  "/assets/images/normal_cyan_case.png",
  "/assets/images/normal_gray_case.png",
  "/assets/images/normal_green_case.png",
  "/assets/images/normal_milk_case.png",
  "/assets/images/normal_orange_case.png",
  "/assets/images/normal_pink_case.png",
  "/assets/images/normal_soft_pink_case.png",
  "/assets/images/normal_soft_yellow_case.png",
  "/assets/images/mini_blue_case.png",
  "/assets/images/mini_cyan_case.png",
  "/assets/images/mini_gray_case.png",
  "/assets/images/mini_green_case.png",
  "/assets/images/mini_milk_case.png",
  "/assets/images/mini_orange_case.png",
  "/assets/images/mini_pink_case.png",
  "/assets/images/mini_soft_pink_case.png",
  "/assets/images/mini_soft_yellow_case.png",
  "/assets/images/small_blue_case.png",
  "/assets/images/small_cyan_case.png",
  "/assets/images/small_gray_case.png",
  "/assets/images/small_green_case.png",
  "/assets/images/small_milk_case.png",
  "/assets/images/small_orange_case.png",
  "/assets/images/small_pink_case.png",
  "/assets/images/small_soft_pink_case.png",
  "/assets/images/small_soft_yellow_case.png",
  "/assets/images/middle_blue_case.png",
  "/assets/images/middle_cyan_case.png",
  "/assets/images/middle_gray_case.png",
  "/assets/images/middle_green_case.png",
  "/assets/images/middle_milk_case.png",
  "/assets/images/middle_orange_case.png",
  "/assets/images/middle_pink_case.png",
  "/assets/images/middle_soft_pink_case.png",
  "/assets/images/middle_soft_yellow_case.png",
  "/assets/images/big_blue_case.png",
  "/assets/images/big_cyan_case.png",
  "/assets/images/big_gray_case.png",
  "/assets/images/big_green_case.png",
  "/assets/images/big_milk_case.png",
  "/assets/images/big_orange_case.png",
  "/assets/images/big_pink_case.png",
  "/assets/images/big_soft_pink_case.png",
  "/assets/images/big_soft_yellow_case.png",
  "/assets/images/all_stage_clear_title.png",
  "/assets/images/app-icon.png",
  "/assets/images/app_pluzze_icon.png",
  "/assets/images/app_official_icon.png",
  "/assets/images/app_top_capture.webp",
  "/assets/images/trophy.png",
  "/assets/images/trophy_silver.png",
  "/assets/images/trophy_bronze.png",
  "/assets/images/creator_pick_up_fun.png",
  "/assets/images/creator_pick_up_nice.png",
  "/assets/images/creator_ranking_top_title.png",
  "/assets/images/creator_ranking_app_title.png",
  "/assets/images/creator_ranking_user_title.png",
  "/assets/images/creator_ranking_favorited_title.png",
  "/assets/images/creator_ranking_viewed_title.png",
  "/assets/images/arrow_forward.png",
  "/assets/images/arrow.png",
  "/assets/images/app_card_user.png",
  "/assets/images/app_card_sample.png",
  "/assets/images/app_card_shared.png",
  "/assets/images/app_card_official.png",
  "/assets/images/publish_on.png",
  "/assets/images/publish_off.png",
  "/assets/images/creator_panel.png",
  "/assets/images/creator_display_bg.webp",
  "/assets/images/creator_pickup_title.png",
  "/assets/images/creator_preview_run_btn.png",
  "/assets/images/course_algorithm_start.png",
  "/assets/images/course_program_start.png",
  "/assets/images/bg_user_page_history.png",
  "/assets/images/user_page_axis_label_bg.png",
  "/assets/images/user_page_course_clear_icon.png",
  "/assets/images/user_page_course_clear_icon_latest.png",
  "/assets/images/user_page_creator_viewed_icon.png",
  "/assets/images/user_page_creator_favorited_icon.png",
  "/assets/images/user_page_creator_favorited_icon_latest.png",
  "/assets/images/bg_course_algorithm.jpg",
  "/assets/images/bg_course_algorithm_stage_1.webp",
  "/assets/images/bg_course_algorithm_stage_2.webp",
  "/assets/images/bg_course_algorithm_stage_3.webp",
  "/assets/images/bg_course_algorithm_stage_4.webp",
  "/assets/images/bg_course_algorithm_stage_5.webp",
  "/assets/images/bg_course_algorithm_stage_6.webp",
  "/assets/images/bg_course_program_stage_1.webp",
  "/assets/images/bg_course_program_stage_2.webp",
  "/assets/images/bg_course_program_stage_3.webp",
  "/assets/images/bg_course_program_stage_4.webp",
  "/assets/images/bg_course_program_stage_5.webp",
  "/assets/images/bg_course_program_stage_6.webp",
  "/assets/images/bg_course_program_stage_7.webp",
  "/assets/images/bg_course_program_stage_8.webp",
  "/assets/images/bg_course_program_scene.png",
  "/assets/images/bg_course_guide.png",
  "/assets/images/bg_creator.png",
  "/assets/images/title_and_logo.png",
  "/assets/images/new_bg_puzzle.png",
  "/assets/images/bg_scene.jpg",
  "/assets/images/new_freebuild_title.png",
  "/assets/images/creator_project_title_bg.png",
  "/assets/images/creator_project_description_bg_small.png",
  "/assets/images/cursor_arrow_key.png",
  "/assets/images/cursor_arrow_key_up.png",
  "/assets/images/cursor_arrow_key_right.png",
  "/assets/images/cursor_arrow_key_down.png",
  "/assets/images/cursor_arrow_key_left.png",
  "/assets/images/controller_close_on.png",
  "/assets/images/controller_close_default.png",
  "/assets/images/controller_confirm_on.png",
  "/assets/images/controller_confirm_default.png",
  "/assets/images/contact_bug.png",
  "/assets/images/contact_how_to.png",
  "/assets/images/contact_request.png",
  "/assets/images/contact_other.png",
  "/assets/images/course_top_bg@2x.png",
  "/assets/images/course_top_icon_capture.png",
  "/assets/images/course_top_icon@2x.png",
  "/assets/images/course_soft_algorithm.png",
  "/assets/images/course_soft_program.png",
  "/assets/images/course_soft_kogakuin.png",
  "/assets/images/course_soft_comingsoon_1.png",
  "/assets/images/course_soft_comingsoon_2.png",
  "/assets/images/course_select_decoration.png",
  "/assets/images/course_algorithm_title_only.png",
  "/assets/images/duck_attention.png",
  "/assets/images/duck_attention_2.png",
  "/assets/images/duck_hello.png",
  "/assets/images/duck_puzzle.png",
  "/assets/images/duck_yeah.png",
  "/assets/images/freebuild_top_bg@2x.png",
  "/assets/images/freebuild_top_icon_capture.png",
  "/assets/images/freebuild_top_icon@2x.png",
  "/assets/images/help_btn_hover.png",
  "/assets/images/help_btn_normal.png",
  "/assets/images/help_btn_tap.png",
  "/assets/images/levelcrear_s@2x.png",
  "/assets/images/new_screen_btn.png",
  "/assets/images/overview_character.png",
  "/assets/images/app_setting_capture.png",
  "/assets/images/app_parent_gate_capture.png",
  "/assets/images/project_card_switch.png",
  "/assets/images/stage_description_bg.png",
  "/assets/images/stamp_cleared.png",
  "/assets/images/title_logo.png",
  "/assets/images/trash.png",
  "/assets/images/tutorial_01.gif",
  "/assets/images/tutorial_02.gif",
  "/assets/images/tutorial_arrow_forward.png",
  "/assets/algorithm/shotcut/stage1/step1.webp",
  "/assets/algorithm/shotcut/stage1/step2.webp",
  "/assets/algorithm/shotcut/stage1/step3.webp",
  "/assets/algorithm/shotcut/stage1/step4.webp",
  "/assets/algorithm/shotcut/stage1/step5.webp",
  "/assets/algorithm/shotcut/stage1/step6.webp",
  "/assets/algorithm/shotcut/stage1/step7.webp",
  "/assets/algorithm/shotcut/stage2/step1.webp",
  "/assets/algorithm/shotcut/stage2/step2.webp",
  "/assets/algorithm/shotcut/stage2/step3.webp",
  "/assets/algorithm/shotcut/stage2/step4.webp",
  "/assets/algorithm/shotcut/stage2/step5.webp",
  "/assets/algorithm/shotcut/stage2/step6.webp",
  "/assets/algorithm/shotcut/stage2/step7.webp",
  "/assets/algorithm/shotcut/stage3/step1.webp",
  "/assets/algorithm/shotcut/stage3/step2.webp",
  "/assets/algorithm/shotcut/stage3/step3.webp",
  "/assets/algorithm/shotcut/stage3/step4.webp",
  "/assets/algorithm/shotcut/stage3/step5.webp",
  "/assets/algorithm/shotcut/stage3/step6.webp",
  "/assets/algorithm/shotcut/stage3/step7.webp",
  "/assets/algorithm/shotcut/stage4/step1.webp",
  "/assets/algorithm/shotcut/stage4/step2.webp",
  "/assets/algorithm/shotcut/stage4/step3.webp",
  "/assets/algorithm/shotcut/stage4/step4.webp",
  "/assets/algorithm/shotcut/stage4/step5.webp",
  "/assets/algorithm/shotcut/stage4/step6.webp",
  "/assets/algorithm/shotcut/stage4/step7.webp",
  "/assets/algorithm/shotcut/stage5/step1.webp",
  "/assets/algorithm/shotcut/stage5/step2.webp",
  "/assets/algorithm/shotcut/stage5/step3.webp",
  "/assets/algorithm/shotcut/stage5/step4.webp",
  "/assets/algorithm/shotcut/stage5/step5.webp",
  "/assets/algorithm/shotcut/stage5/step6.webp",
  "/assets/algorithm/shotcut/stage5/step7.webp",
  "/assets/algorithm/shotcut/stage6/step1.webp",
  "/assets/algorithm/shotcut/stage6/step2.webp",
  "/assets/algorithm/shotcut/stage6/step3.webp",
  "/assets/algorithm/shotcut/stage6/step4.webp",
  "/assets/algorithm/shotcut/stage6/step5.webp",
  "/assets/algorithm/shotcut/stage6/step6.webp",
  "/assets/algorithm/shotcut/stage6/step7.webp",
  "/assets/program/shotcut/stage1/step1.webp",
  "/assets/program/shotcut/stage1/step2.webp",
  "/assets/program/shotcut/stage1/step3.webp",
  "/assets/program/shotcut/stage1/step4.webp",
  "/assets/program/shotcut/stage1/step5.webp",
  "/assets/program/shotcut/stage1/step6.webp",
  "/assets/program/shotcut/stage1/step7.webp",
  "/assets/program/shotcut/stage2/step1.webp",
  "/assets/program/shotcut/stage2/step2.webp",
  "/assets/program/shotcut/stage2/step3.webp",
  "/assets/program/shotcut/stage2/step4.webp",
  "/assets/program/shotcut/stage2/step5.webp",
  "/assets/program/shotcut/stage2/step6.webp",
  "/assets/program/shotcut/stage2/step7.webp",
  "/assets/program/shotcut/stage3/step1.webp",
  "/assets/program/shotcut/stage3/step2.webp",
  "/assets/program/shotcut/stage3/step3.webp",
  "/assets/program/shotcut/stage3/step4.webp",
  "/assets/program/shotcut/stage3/step5.webp",
  "/assets/program/shotcut/stage3/step6.webp",
  "/assets/program/shotcut/stage3/step7.webp",
  "/assets/program/shotcut/stage4/step1.webp",
  "/assets/program/shotcut/stage4/step2.webp",
  "/assets/program/shotcut/stage4/step3.webp",
  "/assets/program/shotcut/stage4/step4.webp",
  "/assets/program/shotcut/stage4/step5.webp",
  "/assets/program/shotcut/stage4/step6.webp",
  "/assets/program/shotcut/stage4/step7.webp",
  "/assets/program/shotcut/stage5/step1.webp",
  "/assets/program/shotcut/stage5/step2.webp",
  "/assets/program/shotcut/stage5/step3.webp",
  "/assets/program/shotcut/stage5/step4.webp",
  "/assets/program/shotcut/stage5/step5.webp",
  "/assets/program/shotcut/stage5/step6.webp",
  "/assets/program/shotcut/stage5/step7.webp",
  "/assets/program/shotcut/stage6/step1.webp",
  "/assets/program/shotcut/stage6/step2.webp",
  "/assets/program/shotcut/stage6/step3.webp",
  "/assets/program/shotcut/stage6/step4.webp",
  "/assets/program/shotcut/stage6/step5.webp",
  "/assets/program/shotcut/stage6/step6.webp",
  "/assets/program/shotcut/stage6/step7.webp",
  "/assets/program/shotcut/stage7/step1.webp",
  "/assets/program/shotcut/stage7/step2.webp",
  "/assets/program/shotcut/stage7/step3.webp",
  "/assets/program/shotcut/stage7/step4.webp",
  "/assets/program/shotcut/stage7/step5.webp",
  "/assets/program/shotcut/stage7/step6.webp",
  "/assets/program/shotcut/stage7/step7.webp",
  "/assets/program/shotcut/stage1/step1_answer.webp",
  "/assets/program/shotcut/stage1/step2_answer.webp",
  "/assets/program/shotcut/stage1/step3_answer.webp",
  "/assets/program/shotcut/stage1/step4_answer.webp",
  "/assets/program/shotcut/stage1/step5_answer.webp",
  "/assets/program/shotcut/stage1/step6_answer.webp",
  "/assets/program/shotcut/stage1/step7_answer.webp",
  "/assets/program/shotcut/stage2/step1_answer.webp",
  "/assets/program/shotcut/stage2/step2_answer.webp",
  "/assets/program/shotcut/stage2/step3_answer.webp",
  "/assets/program/shotcut/stage2/step4_answer.webp",
  "/assets/program/shotcut/stage2/step5_answer.webp",
  "/assets/program/shotcut/stage2/step6_answer.webp",
  "/assets/program/shotcut/stage2/step7_answer.webp",
  "/assets/program/shotcut/stage3/step1_answer.webp",
  "/assets/program/shotcut/stage3/step2_answer.webp",
  "/assets/program/shotcut/stage3/step3_answer.webp",
  "/assets/program/shotcut/stage3/step4_answer.webp",
  "/assets/program/shotcut/stage3/step5_answer.webp",
  "/assets/program/shotcut/stage3/step6_answer.webp",
  "/assets/program/shotcut/stage3/step7_answer.webp",
  "/assets/program/shotcut/stage4/step1_answer.webp",
  "/assets/program/shotcut/stage4/step2_answer.webp",
  "/assets/program/shotcut/stage4/step3_answer.webp",
  "/assets/program/shotcut/stage4/step4_answer.webp",
  "/assets/program/shotcut/stage4/step5_answer.webp",
  "/assets/program/shotcut/stage4/step6_answer.webp",
  "/assets/program/shotcut/stage4/step7_answer.webp",
  "/assets/program/shotcut/stage5/step1_answer.webp",
  "/assets/program/shotcut/stage5/step2_answer.webp",
  "/assets/program/shotcut/stage5/step3_answer.webp",
  "/assets/program/shotcut/stage5/step4_answer.webp",
  "/assets/program/shotcut/stage5/step5_answer.webp",
  "/assets/program/shotcut/stage5/step6_answer.webp",
  "/assets/program/shotcut/stage5/step7_answer.webp",
  "/assets/program/shotcut/stage6/step1_answer.webp",
  "/assets/program/shotcut/stage6/step2_answer.webp",
  "/assets/program/shotcut/stage6/step3_answer.webp",
  "/assets/program/shotcut/stage6/step4_answer.webp",
  "/assets/program/shotcut/stage6/step5_answer.webp",
  "/assets/program/shotcut/stage6/step6_answer.webp",
  "/assets/program/shotcut/stage6/step7_answer.webp",
  "/assets/program/shotcut/stage7/step1_answer.webp",
  "/assets/program/shotcut/stage7/step2_answer.webp",
  "/assets/program/shotcut/stage7/step3_answer.webp",
  "/assets/program/shotcut/stage7/step4_answer.webp",
  "/assets/program/shotcut/stage7/step5_answer.webp",
  "/assets/program/shotcut/stage7/step6_answer.webp",
  "/assets/program/shotcut/stage7/step7_answer.webp",
];

export const sounds = [
  "/assets/bgm/bgm_normal.mp3",
  "/assets/sounds/delete.mp3",
  "/assets/sounds/switch-on.mp3",
  "/assets/sounds/pop-up-off.mp3",
  "/assets/blockly/jump.mp3",
  "/assets/blockly/turn.mp3",
  "/assets/blockly/start.mp3",
  "/assets/blockly/itemget.mp3",
  "/assets/blockly/failure.mp3",
  "/assets/blockly/footstep.mp3",
  "/assets/blockly/levelclear.mp3",
  "/assets/blockly/stageclear.mp3",
  "/assets/blockly/gotonextstage.mp3",
  "/assets/blockly/levelclear_cheers.mp3",
];

export const stampCategories = [
  "stamps/character",
  "stamps/game",
  "stamps/animal",
  "stamps/event",
  "stamps/internet",
  "stamps/money",
  "stamps/goods",
  "stamps/nature",
  "stamps/person",
  "stamps/building",
  "stamps/food",
  "stamps/electronic",
  "stamps/smile",
  "stamps/vehicle",
  "stamps/stationary",
  "stamps/others",
];
export const userIconPrefixes = ["assets/users/icons"];

export const models = [
  "/assets/models/character_duck.glb",
  "/assets/models/tileHigh_forest.gltf.glb",
  "/assets/models/tileLow_teamBlue.gltf.glb",
  "/assets/models/tileHigh_desert.gltf.glb",
  "/assets/models/tileLow_forest_ground.glb",
  "/assets/models/lightning.gltf.glb",
  "/assets/models/rocksB_forest.gltf.glb",
  "/assets/models/tree_forest.gltf.glb",
  "/assets/models/tree_desert.gltf.glb",
  "/assets/models/tileMedium_forest.gltf.glb",
  "/assets/models/tileSlopeLowMedium_forest.gltf.glb",
  "/assets/models/tileLow_forest.gltf.glb",
  "/assets/models/detail_forest.gltf.glb",
  "/assets/models/diamond_teamRed.gltf.glb",
];
