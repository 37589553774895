import { useRef, useEffect, ChangeEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import I18n from "i18n-js";
import produce from "immer";
import { RootState, AppDispatch } from "app/store";
import { usePlaySound } from "common/utils";
import { ComponentTypeIds } from "common/components";
import {
  ItemContainer,
  SelectTextFont,
} from "features/creator/property/components";
import { PropertyProps } from "features/creator/property/types";
import { actions, selectScreenById } from "features/creator/slice";
import { calcOffset } from "features/creator/property/panels/utils";

export const BasicProperty = (props: PropertyProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const play = usePlaySound();
  const obj = $("#compute-text-length");
  const dispatch = useDispatch<AppDispatch>();
  const { scale, updateProperty } = props;
  const { id, screenId, typeId, property } = props.propertyEntity;
  const screen = useSelector((state: RootState) =>
    selectScreenById(state, screenId)
  );

  const defaultScreenId = useSelector(
    (state: RootState) => state.creator.defaultScreenId
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = property.name;
    }
  }, [property.name]);

  const handleTopPage = () => {
    play();
    dispatch(actions.updateDefaultScreenId(id));
  };

  const handleUpdateComponentName = (value: string) => {
    const newProperty = produce(property, (draft) => {
      draft.name = value;
    });
    const newScreen = produce(screen, (draft) => {
      if (typeId === ComponentTypeIds.SCREEN) {
        draft.name = value;
      } else {
        draft.children = draft.children.map((component) =>
          component.id === id ? { ...component, name: value } : component
        );
      }
    });

    dispatch(
      actions.updateScreen({
        id: screenId,
        newScreen: newScreen,
      })
    );
    updateProperty(id, newProperty);
  };

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value !== "") {
      handleUpdateComponentName(e.target.value);
    }
  };

  const handleOnBlur: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (inputRef.current) {
      inputRef.current.value = property.name;
    }
  };

  const handleChangeText: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    obj.css("font-size", property.style.text.fontSize);
    obj.css("font-family", property.style.text.fontFamily);
    obj.css("letter-spacing", property.style.text.letterSpacing);
    obj.text(property.text);
    const preWidth = obj.width();

    obj.text(e.target.value);
    const newWidth = obj.width();
    const delta = {
      x: newWidth - preWidth,
      y: 0,
    };
    const newOffset = calcOffset(property, delta);
    const newProperty = produce(property, (draft) => {
      if (typeId !== ComponentTypeIds.TEXTINPUT) {
        draft.style.layout.width += delta.x;
        draft.style.transform.translateX -= newOffset.x;
        draft.style.transform.translateY += newOffset.y;
        draft.style.defaultLayout.width += delta.x;
      }
      draft.text = e.target.value;
    });
    updateProperty(id, newProperty);
  };
  const handleTextReset = () => {
    obj.css("font-size", property.style.text.fontSize);
    obj.css("font-family", property.style.text.fontFamily);
    obj.css("letter-spacing", property.style.text.letterSpacing);
    obj.text(property.text);
    const preWidth = obj.width();
    obj.text(property.defaultText);
    const newWidth = obj.width();
    const delta = {
      x: newWidth - preWidth,
      y: 0,
    };
    const newOffset = calcOffset(property, delta);
    const newProperty = produce(property, (draft) => {
      if (typeId !== ComponentTypeIds.TEXTINPUT) {
        draft.text = draft.defaultText;
        draft.style.layout.width += delta.x;
        draft.style.transform.translateX -= newOffset.x;
        draft.style.transform.translateY += newOffset.y;
        draft.style.defaultLayout.width += delta.x;
      }
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeTextFont = (value: string) => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.fontFamily = value;
    });
    updateProperty(id, newProperty);
    play();
  };
  const handleTextFontReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.fontFamily = draft.style.defaultText.fontFamily;
    });
    updateProperty(id, newProperty);
    play();
  };

  return (
    <div className="flex-row-center !flex-1 !items-start">
      {typeId === ComponentTypeIds.SCREEN && (
        <ItemContainer
          name={I18n.t("MSG_CREATOR_MENU_PROPERTY_SET_TOP_PAGE_TITLE")}
          scale={scale}
          width={256}
          height={100}
          right={16}
          left={0}
          bottom={0}
        >
          <div className="flex-row-center" onClick={handleTopPage}>
            <div
              className="flex-row-center rounded-full border-[#B8B1B5] border-solid overflow-hidden mx-[5px]"
              style={{
                width: 20,
                height: 20,
                borderWidth: 2,
              }}
            >
              {defaultScreenId === id && (
                <div
                  className="flex-col-center rounded-full bg-[#94C4F7]"
                  style={{
                    width: 20 * 0.6,
                    height: 20 * 0.6,
                  }}
                />
              )}
            </div>
            <p
              className="text text-textcolor/black"
              style={{ fontSize: 16 ?? 20 }}
            >
              {I18n.t("MSG_CREATOR_MENU_PROPERTY_SET_TOP_PAGE")}
            </p>
          </div>
        </ItemContainer>
      )}

      <div className="flex-col-view">
        <div className="flex-row-view">
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_NAME")}
            scale={scale}
            width={256}
            height={100}
            left={0}
            right={0}
            bottom={0}
          >
            <div className="flex-col-view w-full px-[12px]">
              <input
                ref={inputRef}
                className="!select-text border-0 border-b-[1px] border-solid border-gray/40 outline-none text !text-left text-textcolor/black placeholder:text-gray/20"
                type="text"
                required
                placeholder={I18n.t(
                  "MSG_CREATOR_MENU_PROPERTY_NAME_PLACEHOLDER"
                )}
                minLength={1}
                maxLength={20}
                defaultValue={property.name}
                onBlur={handleOnBlur}
                onChange={handleChangeName}
              />
            </div>
          </ItemContainer>
        </div>
        {property.style.text && (
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_FONT_FAMILY")}
            scale={scale}
            width={256}
            height={160}
            top={40}
            left={0}
            onRest={handleTextFontReset}
          >
            <SelectTextFont
              value={property.style.text.fontFamily}
              onChange={handleChangeTextFont}
            />
          </ItemContainer>
        )}
      </div>

      {property.style.text && (
        <div className="flex-col-view justify-start">
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT")}
            scale={scale}
            width={256}
            height={308}
            right={0}
            onRest={handleTextReset}
          >
            <div className="flex-col-view items-center justify-start w-full h-full px-[8px]">
              <textarea
                className="resize-none !select-text text text-textcolor/black !text-left w-full p-[6px] border-solid border-gray/40 broder-[2px]"
                rows={7}
                value={property.text}
                minLength={1}
                onChange={handleChangeText}
                style={{
                  fontFamily: property.style.text.fontFamily,
                }}
              />
            </div>
          </ItemContainer>
        </div>
      )}
    </div>
  );
};
