import Blockly from "blockly";
import { useBlocklyInit } from "./initial";

export const BlocklyWorkspace = ({
  workspace,
  setWorkspace,
}: {
  workspace: Blockly.WorkspaceSvg;
  setWorkspace: (workspace: any) => void;
}) => {
  useBlocklyInit(workspace, setWorkspace);

  return (
    <div className="flex-row-center !items-start !flex-1 w-full">
      <div id="blocklyDiv" className="flex-col-view !flex-1 w-full h-full" />
    </div>
  );
};
