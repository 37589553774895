export const AnswerMark = ({ id }: { id: number }) => {
  return (
    <>
      <div
        id={`course-program-answer-mark-right-${id}`}
        className="flex-col-el flex-center z-50 absolute opacity-0 pointer-events-none"
      >
        <p
          className="text-body text-[90px] text-danger"
          style={{ WebkitTextStroke: "3px white" }}
        >
          〇
        </p>
      </div>
      <div
        id={`course-program-answer-mark-wrong-${id}`}
        className="flex-col-el flex-center z-50 absolute opacity-0 pointer-events-none"
        style={{ WebkitTextStroke: "3px white" }}
      >
        <p className="text-body text-[120px] leading-[0px] text-[#285eb0] !mb-4">
          ×
        </p>
      </div>
    </>
  );
};
