/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new-func */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import {
  IconCircleZoomIn,
  IconCircleZoomOut,
  IconCircleZoomReset,
} from "common/assets";
import { btnDelay, useScale, usePlaySound, sleep } from "common/utils";
import { Course } from "features/courses/types";
import { useGameSound } from "features/courses/hook";
import {
  Failed,
  Success,
} from "features/courses/algorithm/game/scene/components";
import StageMaps from "./maps";
import * as action from "./animations";
import { GameScene } from "./GameScene";
import { Connection } from "./connection";
import { Vector3 } from "@babylonjs/core";

export const MainScenePage = ({
  stage,
  step,
  reload,
  setReload,
  loading,
  setLoading,
}: {
  stage: number;
  step: number;
  reload: boolean;
  setReload: (reload: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const navigate = useNavigate();
  const gameSound = useGameSound();
  const [success, setSuccess] = useState(false);
  const stepMap = StageMaps[stage - 1][step - 1];
  const [gameOver, setGameOver] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [scene, setScene] = useState<Scene | null>(null);
  const [character, setCharacter] = useState<AbstractMesh | null>(null);

  useEffect(() => {
    if (gameOver) {
      if (success) {
        setTimeout(() => {
          setSuccessShow(true);
        }, 500);

        setTimeout(() => {
          if (step < 7) {
            navigate(`/courses/${Course.OCHA}/stage/${stage}/step/${step + 1}`);
          } else {
            navigate(`/courses/${Course.OCHA}/stage/${stage}/step/1`);
          }
        }, 3000);

        gameSound.levelclearSound();
        gameSound.levelclearCheersSound();
      } else {
        gameSound.failureSound();
        setTimeout(() => {
          handleReset();
        }, 500);
      }
    }
  }, [gameOver]);

  const handleGetGem = () => {
    gameSound.itemGetSound();
    setSuccess(true);
  };

  const util = {
    setSuccess: setSuccess,
    setGameOver: setGameOver,
    action: {
      performAppearance: action.performAppearance,
      performMove: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          console.log("move.");
          gameSound.moveSound();
          await action.performMove(scene, character, setSuccess, setGameOver);
        }
      },
      performJump: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          console.log("jump.");
          gameSound.jumpSound();
          if (stage === 1) {
            await action.performFreeJump(
              scene,
              character,
              setSuccess,
              setGameOver
            );
          } else {
            await action.performJump(scene, character, setSuccess, setGameOver);
          }
        }
      },
      performTurnToLeft: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          console.log("turn to left.");
          gameSound.turnSound();
          await action.performTurnToLeft(scene, character);
        }
      },
      performTurnToRight: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          console.log("turn to right.");
          gameSound.turnSound();
          await action.performTurnToRight(scene, character);
        }
      },
    },
  };

  const handleReset = () => {
    btnDelay(() => {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setLoading(true);
      setSuccess(false);
      setGameOver(false);
      setReload(!reload);
      setSuccessShow(false);
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      setSuccessShow(false);
      navigate(`/courses/${Course.OCHA}/stage/${stage}`);
    });
  };

  const handleSceneZoom = (zoom: number) => () => {
    const camera = scene.getCameraByName("camera") as ArcRotateCamera;
    if (camera) {
      if (zoom > 0) {
        camera.radius -= 0.5;
      } else if (zoom < 0) {
        camera.radius += 0.5;
      } else {
        camera.alpha = stage === 1 ? Math.PI / 2 + 0.5 : Math.PI / 4;
        camera.beta = stage === 1 ? Math.PI / 3 + 0.2 : Math.PI / 4;
        camera.radius = stage === 1 ? 3 / scale : 10 / scale;
      }
    }
  };

  return (
    <>
      {!loading && !gameOver && (
        <Connection
          stage={stage}
          util={util}
          character={character}
          scene={scene}
        />
      )}

      {gameOver && !success && <Failed scale={scale} reset={handleReset} />}

      {successShow && (
        <Success scale={scale} back={handleBack} reset={handleReset} />
      )}

      <div className="flex-col-view items-center w-full h-full pointer-events-none">
        <div className="flex-col-center w-full h-full pointer-events-none">
          <GameScene
            stage={stage}
            scale={scale}
            map={stepMap}
            reload={reload}
            config={{
              util,
              setLoading,
              setScene,
              setCharacter,
              handleGetGem,
            }}
          />
        </div>
      </div>

      <div className="flex-col-el absolute right-4 bottom-[25%] gap-8 z-[9997] pointer-events-auto">
        <div
          onClick={handleSceneZoom(1)}
          className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomIn color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(0)}
          className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomReset color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(-1)}
          className="flex-col-center w-[60px] h-[60px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomOut color="#FEFDFE" />
        </div>
      </div>
    </>
  );
};

export default MainScenePage;
