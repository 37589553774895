import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("project-preview-panel");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 20 * scale,
        y: rect.y - 50 * scale,
        width: rect.height + 80 * scale,
        height: rect.height + 80 * scale,
      });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout
        transparent
        position={{
          x: 120,
          y: 100,
        }}
        next={next}
        close={close}
      >
        <Title activeStep={12} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "マイアプリから \nアプリを さくじょ することが \nできました！"
              : "マイアプリから\nアプリを削除することが\nできました！"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
