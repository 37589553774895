import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippetByOnly } from "features/courses/program/game/blockly/uitls";

export const InitLabelTextSetterBlock = () => {
  Blockly.Blocks["label_text_setter_block"] = {
    max: 5,
    init: function () {
      this.jsonInit({
        type: "label_text_setter_block",
        message0: "%1を%2にする",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [["ボックス1", "label_1"]],
          },
          {
            type: "input_value",
            name: "value",
            check: "String",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "text_blocks",
      });
    },
  };
  BlocklyJs["label_text_setter_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const result = value === "'あいうえお'";
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById("course-program-label-1");
      target.innerText = ${value};
      sound();
      target.style.opacity = "1";
      target.style.transform = "scale(1)";
      await sleep(700);
      ${showAnswerSnippetByOnly(result)}
      highlightBlock("${block.id}", false);
      setSuccess(${result});
      setGameOver(true);
    `;
  };
};
