import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { ComponentManager, ComponentCategory } from "common/components";
import { selectScreenById, selectPropertyById } from "features/creator/slice";

export const RenderScreenComponent = (props: {
  id: string;
  itemWidth: number;
  itemHeight: number;
  itemMargin: number;
  nameWidth: number;
  onClick: (value: string) => (e) => void;
}) => {
  const { itemWidth, itemHeight, itemMargin, nameWidth, onClick } = props;
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, props.id)
  );
  const { id, typeId, categoryId, property } = propertyEntity;
  const ChildComponent = useMemo(
    () => ComponentManager[typeId].component.DesignComponent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const width =
    property.style.layout.width +
    property.style.view.borderWidth +
    property.style.shadow.shadowRadius;
  const height =
    property.style.layout.height +
    property.style.view.borderWidth +
    property.style.shadow.shadowRadius;

  const COMPONENT_WIDTH = itemWidth;
  const COMPONENT_HEIGHT = itemHeight - 15;
  const componentScale =
    COMPONENT_WIDTH / width < COMPONENT_HEIGHT / height
      ? COMPONENT_WIDTH / width
      : COMPONENT_HEIGHT / height;

  return (
    <div
      className="flex-row-center cursor-pointer overflow-hidden"
      onPointerUp={onClick(
        categoryId === ComponentCategory.SHAPE
          ? `${categoryId}_${id}`
          : `${typeId}_${id}`
      )}
      style={{
        marginTop: itemMargin,
        marginBottom: itemMargin,
        width: itemWidth + nameWidth,
        height: itemHeight,
      }}
    >
      <div className="flex-row-center" style={{ width: nameWidth }}>
        <p
          className="text text-gray/100 !text-[16px] text-body-ellipsis w-full"
          style={{
            backfaceVisibility: "hidden",
            WebkitBackfaceVisibility: "hidden",
            transform: `translateZ(0)`,
          }}
        >
          {property.name}
        </p>
      </div>

      <div
        className="flex-col-center !box-content cursor-pointer overflow-hidden rounded-[12px]"
        style={{
          width: itemWidth,
          height: itemHeight,
        }}
      >
        <div
          style={{
            flexShrink: 0,
            backfaceVisibility: "hidden",
            width: width,
            height: height,
            pointerEvents: "none",
            transform: `scale(${componentScale}) rotate(${property.style.transform.rotation}deg)`,
            willChange: "transform",
            opacity: property.style.view.opacity / 100,
            filter:
              property.style.shadow.shadowRadius !== 0 &&
              `drop-shadow(${property.style.shadow.shadowOffset.width}px ${property.style.shadow.shadowOffset.height}px ${property.style.shadow.shadowRadius}px ${property.style.shadow.shadowColor})`,
          }}
        >
          <ChildComponent
            property={property}
            id={`dialog-component-list-preview-component-${id}`}
          />
        </div>
      </div>
    </div>
  );
};

const RenderScreenChildComponent = (props: { id: string; zIndex: number }) => {
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, props.id)
  );
  const { id, typeId, property } = propertyEntity;
  const ChildComponent = useMemo(
    () => ComponentManager[typeId].component.DesignComponent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className="absolute top-0 left-0 cursor-grab"
      style={{
        zIndex: props.zIndex,
        backfaceVisibility: "hidden",
        width:
          property.style.layout.width +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        height:
          property.style.layout.height +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        transform: `translate(${property.style.transform.translateX}px, ${property.style.transform.translateY}px) rotate(${property.style.transform.rotation}deg)`,
        willChange: "transform",
        opacity: property.style.view.opacity / 100,
        filter:
          property.style.shadow.shadowRadius !== 0 &&
          `drop-shadow(${property.style.shadow.shadowOffset.width}px ${property.style.shadow.shadowOffset.height}px ${property.style.shadow.shadowRadius}px ${property.style.shadow.shadowColor})`,
      }}
    >
      <ChildComponent
        property={property}
        id={`component-preview-component-${id}`}
      />
    </div>
  );
};

export const RenderScreen = (props: {
  id: string;
  itemWidth: number;
  itemHeight: number;
  itemMargin: number;
  nameWidth: number;
  onClick: (value: string) => (e) => void;
}) => {
  const { id, itemWidth, itemHeight, itemMargin, nameWidth, onClick } = props;

  const { property, typeId } = useSelector((state: RootState) =>
    selectPropertyById(state, id)
  );
  const screen = useSelector((state: RootState) => selectScreenById(state, id));

  return (
    <div
      className="flex-row-center cursor-pointer overflow-hidden"
      onPointerUp={onClick(`${typeId}_${id}`)}
      style={{
        marginTop: itemMargin,
        marginBottom: itemMargin,
        width: itemWidth + nameWidth,
        height: itemHeight,
      }}
    >
      <div className="flex-row-center" style={{ width: nameWidth }}>
        <p className="text text-gray/100 !text-[16px] text-body-ellipsis w-full">
          {property.name}
        </p>
      </div>

      <div
        className="flex-col-view !box-content rounded-[12px] cursor-pointer overflow-hidden "
        style={{
          width: itemWidth,
          height: itemHeight,
          backgroundColor: property.style.view.backgroundColor,
        }}
      >
        <div
          className="flex-col-view w-[1024px] h-[768px] overflow-hidden origin-top-left rounded-[12px]"
          style={{
            transform: `scale(${itemWidth / 1024}, ${itemHeight / 768})`,
            backgroundColor: property.style.view.backgroundColor,
          }}
        >
          {screen.children.map((component, index) => (
            <RenderScreenChildComponent
              key={component.id}
              id={component.id}
              zIndex={screen.childrenOrder.indexOf(component.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
