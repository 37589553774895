import { SVGProps } from "react";

export const IconHart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 19.601c0-1.074.216-2.137.637-3.128a8.094 8.094 0 0 1 1.817-2.646 8.235 8.235 0 0 1 2.72-1.75 8.329 8.329 0 0 1 3.199-.576h.035a9.4 9.4 0 0 1 3.826.792 9.278 9.278 0 0 1 3.153 2.27L24 15.793l1.113-1.23a9.278 9.278 0 0 1 3.153-2.27 9.4 9.4 0 0 1 3.826-.791h.035a8.33 8.33 0 0 1 3.2.575c1.016.4 1.94.995 2.718 1.75a8.095 8.095 0 0 1 1.818 2.646c.42.99.637 2.054.637 3.128 0 1.426-.22 2.533-.71 3.61-.502 1.105-1.325 2.26-2.654 3.735-1.494 1.656-3.482 3.568-6.143 6.126A573.674 573.674 0 0 0 24 39.882a573.032 573.032 0 0 0-6.995-6.811c-2.66-2.558-4.648-4.47-6.141-6.125-1.33-1.475-2.152-2.63-2.655-3.735-.49-1.077-.709-2.184-.709-3.61Z"
      fill="#FEC1BF"
      stroke="#667786"
      strokeWidth={3}
    />
  </svg>
);
