import { useRef, useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { CloseBtnOuter } from "common/elements";
import { GuideMessageBalloonSvg } from "features/courses/assets";
import { Button } from "features/courses/algorithm/game/tips/Button";
import { StepProps } from "./type";

const Step = (props: StepProps) => {
  const { scale } = useScale();
  const ref = useRef<HTMLDivElement>();
  const {
    message,
    characterCoordinates,
    handleBack,
    handleMove,
    close,
  } = props;
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [arrowOffset, setArrowOffset] = useState({ x: 0, y: 0 });
  const [animationStyles, animationApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const handleArrowAnimation = () =>
    animationApi.start({
      to: [
        { opacity: 1 },
        { opacity: 0 },
        { opacity: 1 },
        { opacity: 0 },
        { opacity: 1 },
      ],
      config: { duration: 1000 },
    });

  useEffect(() => {
    if (!characterCoordinates) {
      handleMove();
    }

    if (ref.current) {
      ref.current.innerHTML = message;

      setOffset({
        x: characterCoordinates.x - 500 * scale,
        y: characterCoordinates.y,
      });

      setArrowOffset({
        x: characterCoordinates.x,
        y: characterCoordinates.y - (155 * scale) / 2,
      });
      handleArrowAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <>
      <div
        className="flex-col-view !absolute top-0 left-0 w-[137px] h-[88px] z-[100]"
        style={{
          transform: `translate(${arrowOffset.x + 20}px, ${arrowOffset.y}px)`,
        }}
      >
        <div
          className="flex-col-view w-[137px] h-[88px] origin-top-left"
          style={{ transform: `scale(${scale})` }}
        >
          <animated.div
            className="w-full h-full"
            style={{ ...animationStyles }}
          >
            <img
              alt="*"
              className="w-full h-full"
              src={`${Constants.assetHost}/assets/images/tutorial_arrow_forward.png`}
            />
          </animated.div>
        </div>
      </div>

      <div
        className="flex-col-view !absolute top-0 left-0"
        style={{
          opacity: offset.x * offset.y === 0 ? 0 : 1,
          width: 500 * scale,
          height: 300 * scale,
          transform: `translate(${offset.x}px, ${offset.y}px)`,
        }}
      >
        <div
          className="flex-col-view items-center origin-top-left w-[500px] h-[300px]"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-el absolute top-4 right-4">
            <CloseBtnOuter close={close} />
          </div>

          <div className="flex-col-view !absolute top-0 left-0 w-[500px] h-[300px]">
            <GuideMessageBalloonSvg />
          </div>

          <div className="flex-col-center top-[45px] w-[500px] h-[158px]">
            <p
              ref={ref}
              className="text !leading-[30px] text-textcolor/black"
            ></p>
          </div>
          <Button handleBack={handleBack} handleMove={handleMove} />
        </div>
      </div>
    </>
  );
};

export default Step;
