import * as React from "react";

export const MaterialCheckboxSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={36}
      height={35}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.405 1.99H4.595a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h26.81a2 2 0 0 0 2-2v-26a2 2 0 0 0-2-2Z"
        fill="#FEFDFE"
      />
      <path
        d="m12.225 15.12 5.78 5.62 15.4-15"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.405 16.99v11.27a3.79 3.79 0 0 1-3.85 3.75H6.465a3.8 3.8 0 0 1-3.85-3.75V5.76a3.81 3.81 0 0 1 3.85-3.75h17.33"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
