import { useState, ChangeEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import produce from "immer";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useDialog } from "app/hook";
import { actions } from "app/configSlice";
import { parentsFirebase } from "app/Auth";
import { AppDispatch, RootState } from "app/store";
import { DialogType, LocalStorageKey } from "app/types";
import { btnDelay, usePlaySound } from "common/utils";
import { DeleteConfirmMessageBox } from "common/elements";
import { AppUser } from "features/user/types";
import { deleteParentsUser } from "features/user/api";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";

const options = [
  { id: 1, message: "今は（定期的）に使わなくなった" },
  { id: 2, message: "コンテンツが不足してるから" },
  { id: 3, message: "アプリが操作しにくかった" },
  { id: 4, message: "起動やプレイの動作速度が遅かった" },
  { id: 5, message: "通知などが面倒に感じた" },
  { id: 6, message: "その他" },
];

export const ParentsUserUnsubscribeForm = (props: RouteProps) => {
  const { navigate } = props;
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [detail, setDetail] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const [unsubscribe, setUnsubscribe] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleGoBack = () => navigate("/parentsUserUnsubscribe");
  const appUser = useSelector((state: RootState) => state.user.appUser);

  const handleSelect = (id: number): ChangeEventHandler<HTMLInputElement> => (
    e
  ) => {
    play();
    setSelectedOptions(
      produce((draft) => {
        if (e.target.checked) {
          draft.push(id);
        } else {
          return draft.filter((v) => v !== id);
        }
      })
    );
  };
  const handleChangeDetail: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setDetail(e.target.value);
  };
  const handleOpenUnsubscribe = () => {
    if (selectedOptions.length > 0) {
      play();
      btnDelay(async () => setUnsubscribe(true));
    }
  };
  const handleUnsubscribe = () => {
    play();
    dispatch(actions.updateResign(true));
    btnDelay(async () => {
      try {
        await deleteParentsUser({
          parents_uid: appUser.main.uid,
          options: options
            .filter((value) => selectedOptions.includes(value.id))
            .map((v) => v.message),
          detail,
        });
        const parentsAuth = getAuth(parentsFirebase);
        await parentsAuth.signOut();

        const auth = getAuth();
        const device_kids_main_user_str = localStorage.getItem(
          LocalStorageKey.DEVICE_KIDS_MAIN_USER
        );
        if (device_kids_main_user_str) {
          try {
            const device_kids_main_user: AppUser = JSON.parse(
              device_kids_main_user_str
            );
            localStorage.removeItem(LocalStorageKey.DEVICE_KIDS_MAIN_USER);
            await signInWithEmailAndPassword(
              auth,
              device_kids_main_user.email,
              "000000"
            );
          } catch (error) {
            await auth.signOut();
          }
        } else {
          await auth.signOut();
        }
        handleDialog({ type: DialogType.PARENT_GATE, value: false });
      } catch (error) {
        console.error(error);
        dispatch(actions.updateResign(false));
        handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
      }
    });
  };
  const handleCloseDialog = () => {
    play();
    btnDelay(() => {
      setUnsubscribe(false);
    });
  };

  return (
    <div className="flex-col-el items-center justify-around w-full h-full py-4">
      {unsubscribe && (
        <DeleteConfirmMessageBox
          title={I18n.t("MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_TITLE")}
          message={I18n.t("MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_MESSAGE")}
          close={handleCloseDialog}
          confirm={handleUnsubscribe}
          is_parents
          list_style_type="※"
        />
      )}

      <div className="flex-col-center w-full flex-grow gap-3">
        <p className="text-body text-textcolor/black text-[24px]">
          {I18n.t("MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_FORM_TITLE")}
        </p>
        {options.map((option) => (
          <div key={option.id} className="flex-row-center gap-2">
            <input
              id={`unsubscribe-form-${option.id}`}
              type="checkbox"
              className="w-[24px] h-[24px] mr-[8px]"
              checked={selectedOptions.includes(option.id)}
              onChange={handleSelect(option.id)}
            />
            <label
              htmlFor={`unsubscribe-form-${option.id}`}
              className="text-body text-left text-font-size-body2 text-textcolor/black cursor-pointer w-[350px]"
            >
              {option.message}
            </label>
          </div>
        ))}

        <div className="flex-row-center w-full mt-4">
          <div className="flex-col-center w-[600px] h-[150px]">
            <textarea
              className="resize-none input text-body text-textcolor/black text-[26px]"
              rows={4}
              maxLength={256}
              value={detail}
              onChange={handleChangeDetail}
            />
          </div>
        </div>
      </div>

      <div className="flex-row-el items-center justify-between my-8 gap-10">
        <GoBackBtn handleGoBack={handleGoBack} />

        <div className="flex-row-el flex-center alpha">
          <button
            disabled={selectedOptions.length === 0}
            className="btn btn-danger"
            onPointerDown={handleOpenUnsubscribe}
          >
            <p className="text-body text-white text-[24px] !mb-[3px]">
              {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_UNSUBSCRIBE_BTN")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};
