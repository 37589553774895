import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import { WarningMessageBox } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import {
  IconClose,
  IconPublish,
  BandageIcon,
  CloseBtnIcon,
  IconStopPublish,
} from "features/creator/assets";
import { selectProjectById } from "features/creator/slice";
import { PublishProjectDialogMessage } from "features/creator/elements";
import { publishProject, stopPublishProject } from "features/creator/api";

export const Menu = ({
  projectId,
  isUserProject,
}: {
  projectId: string;
  isUserProject: boolean;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [warning, setWarning] = useState(false);
  const [publish, setPublish] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const project = useSelector((state: RootState) =>
    selectProjectById(state, projectId)
  );
  const project_publish = useSelector(
    (state: RootState) => state.config.userConfig.project_publish
  );
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleClose = () => {
    play();
    btnDelay(() =>
      dispatch(
        actions.updateDialog({ type: DialogType.PROJECT_PREVIEW, value: false })
      )
    );
  };

  const handleCollapse = () => {
    play();
    setCollapse(!collapse);
  };

  const handleOpenPublish = () => {
    play();
    btnDelay(() => {
      if (project_publish || project.published) {
        setPublish(true);
      } else {
        setWarning(true);
      }
    });
  };

  const handlePublish = () => {
    play();
    btnDelay(async () => {
      if (project.published) {
        await dispatch(
          stopPublishProject({
            uid: user.active.uid,
            project_id: project.project_id,
          })
        ).unwrap();
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_TOAST_DISABLE_PUBLISHED_SUCCESS")}
            </p>
          </div>
        );
      } else {
        await dispatch(
          publishProject({
            uid: user.active.uid,
            project_id: project.project_id,
            enable_copied: project.enable_copied,
          })
        ).unwrap();
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_TOAST_PUBLISHED_SUCCESS")}
            </p>
          </div>
        );
      }
      setPublish(false);
    });
  };

  const handleClosePublish = () => {
    play();
    setPublish(false);
  };

  const handleCloseWarning = () => {
    play();
    btnDelay(() => setWarning(false));
  };

  return (
    <div
      className="flex-row-el absolute z-[10000] origin-top-right pointer-events-auto"
      style={{
        top: "77%",
        right: isUserProject ? -264 * scale : -152 * scale,
        transform: `scale(${scale})`,
      }}
    >
      {warning && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t("MSG_CREATOR_APP_PUBLISH_DISABLED_TITLE")}
          message={I18n.t("MSG_CREATOR_APP_PUBLISH_DISABLED_MESSAGE")}
          close={handleCloseWarning}
        />
      )}

      <PublishProjectDialogMessage
        name={project?.name}
        visible={publish}
        published={project?.published}
        enableCopied={project?.enable_copied}
        publish={handlePublish}
        close={handleClosePublish}
      />

      <div
        className="flex-row-view h-[126px] bg-white rounded-l-[16px] p-[16px]"
        style={{
          filter: "drop-shadow(3px 3px 3px rgba(63, 74, 97, 0.4))",
          transition: "transform 0.3s ease-out",
          transform: `translate(${
            collapse ? 15 : isUserProject ? -264 : -152
          }px, 0)`,
        }}
      >
        <div
          onClick={handleCollapse}
          className="flex-col-center w-[48px] h-[48px] !absolute top-[39px] left-[-48px] cursor-pointer"
        >
          <BandageIcon />
        </div>

        <div className="flex-col-el flex-center h-full relative upsilon">
          <button className="btn btn-danger" onClick={handleClose}>
            <div className="flex-col-el flex-center relative mb-[3px]">
              <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                <CloseBtnIcon />
              </div>
              <p className="text-body text-white text-[16px] whitespace-pre">
                {I18n.t("MSG_CREATOR_MENU_CLOSE_BTN")}
              </p>
            </div>
          </button>
        </div>

        {isUserProject && (
          <div className="flex-col-el flex-center h-full relative upsilon">
            <button
              disabled={!!project.deleted_at}
              onClick={handleOpenPublish}
              className={`btn ${
                project?.published || project.deleted_at
                  ? "btn-gray"
                  : "btn-blue"
              }`}
            >
              <div className="flex-col-el flex-center relative mb-[3px]">
                {project.deleted_at && (
                  <div className="flex-col-el flex-center absolute z-10 w-[80px] h-[80px]">
                    <IconClose fill="#A3ADB6" />
                  </div>
                )}

                <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                  {project?.published ? (
                    <IconStopPublish fill="none" stroke="#FFF" />
                  ) : (
                    <IconPublish fill="none" stroke="#FFF" />
                  )}
                </div>
                <p className="text-body text-white text-[16px] whitespace-pre">
                  {project?.published
                    ? I18n.t("MSG_CREATOR_MENU_STOP_PUBLISH_APP_BTN")
                    : I18n.t("MSG_CREATOR_MENU_PUBLISH_APP_BTN")}
                </p>
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
