import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { btnDelay, useScale } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";

export const CompleteDeleteMessageBox = ({
  visible,
  name,
  close,
  confirm,
}: {
  visible: boolean;
  name: string;
  close: () => void;
  confirm: () => void;
}) => {
  const { scale } = useScale();
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    setDisabled(true);
    btnDelay(() => close());
  };

  const handleConfirm = () => {
    if (!disabled) {
      setDisabled(true);
      confirm();
    }
  };

  useEffect(() => {
    if (visible) {
      setDisabled(false);
    }
  }, [visible]);

  return (
    <Dialog
      open={visible}
      maxWidth={false}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            filter:
              "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
          }}
        >
          <div className="flex-col-el justify-start w-[716px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
            <CloseBtn close={handleClose} />

            <div className="flex-col-view justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
              <div className="flex-row-view items-center justify-start flex-wrap py-4">
                <div className="flex-col-center mr-2">
                  <WarningAmberIcon sx={{ fontSize: 40, color: "#FFA03B" }} />
                </div>
                <p className="text-body text-textcolor/black text-[28px]">
                  {I18n.t("MSG_CREATOR_APP_COMPLETE_DELETE_CONFIRM_TITLE")}
                </p>
              </div>
              <div className="flex-col-view items-start py-4">
                <p className="text-body text-textcolor/black text-font-size-body2 whitespace-pre-wrap">
                  {I18n.t("MSG_CREATOR_APP_COMPLETE_DELETE_CONFIRM_SUBTITLE", {
                    name,
                  })}
                </p>
              </div>

              <div className="flex-row-el flex-center w-full gap-6 alpha">
                <button className="btn btn-blue" onClick={handleClose}>
                  <div className="flex-row-center gap-1 mb-[3px]">
                    <span className="material-symbols-outlined text-[30px]">
                      close
                    </span>
                    <p className="text-body text-white text-font-size-body1">
                      {I18n.t("MSG_STOP_BTN")}
                    </p>
                  </div>
                </button>

                <button className="btn btn-danger" onClick={handleConfirm}>
                  <div className="flex-row-center gap-1 mb-[3px]">
                    <span className="material-symbols-outlined text-[36px]">
                      delete_forever
                    </span>
                    <p className="text-body text-white text-font-size-body1">
                      {I18n.t("MSG_CREATOR_APP_COMPLETELY_BTN")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
