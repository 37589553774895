import { Scene } from "@babylonjs/core/scene";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { BoundingBox } from "@babylonjs/core/Culling/boundingBox";
import { Matrix, Vector3 } from "@babylonjs/core/Maths/math.vector";
import { CHARACTER_DIRECTION } from "./types";

export const createOuterMesh = (
  scene: Scene,
  boundingBox: BoundingBox,
  position: Vector3
) => {
  const outer = MeshBuilder.CreateBox(
    "outer",
    {
      width: boundingBox.maximum.x - boundingBox.minimum.x,
      height: boundingBox.maximum.y - boundingBox.minimum.y,
      depth: boundingBox.maximum.z - boundingBox.minimum.z,
    },
    scene
  );
  outer.isVisible = false;
  outer.isPickable = true;
  outer.checkCollisions = false;
  outer.showBoundingBox = false;
  outer.bakeTransformIntoVertices(
    Matrix.Translation(
      0,
      (boundingBox.maximum.y - boundingBox.minimum.y) / 2,
      0
    )
  );
  outer.position = new Vector3().copyFrom(position);
  return outer;
};

export const calCharacterDirection = {
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_X]: { x: Math.PI / 2, y: 0, z: 0 },
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X]: {
    x: -Math.PI / 2,
    y: 0,
    z: 0,
  },
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_Z]: { x: 0, y: 0, z: Math.PI / 2 },
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_Z]: {
    x: 0,
    y: 0,
    z: -Math.PI / 2,
  },
};
