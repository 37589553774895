import { SVGProps } from "react";

export const IconMedal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={45}
    height={47}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#user-page-icon-medal-a)">
      <g filter="url(#user-page-icon-medal-b)">
        <path
          d="M12.334 30.15 4.5 28.147l10.342-10.801s2.555 3.36 4.21 4.942c1.654 1.583 6.022 3.473 6.022 3.473l-10.51 11.403-2.23-7.014Z"
          fill="#FC6560"
        />
      </g>
      <g filter="url(#user-page-icon-medal-c)">
        <path
          d="M31.938 35.753 26.267 41 22.54 27.279s4.851.022 7.135-.489c2.283-.51 6.235-2.272 6.235-2.272l3.674 13.064-7.646-1.829Z"
          fill="#FC6560"
        />
      </g>
      <g filter="url(#user-page-icon-medal-d)">
        <path
          d="M40.067 18.821c-1.864 6.641-9.222 10.53-16.433 8.687-7.212-1.844-11.546-8.723-9.68-15.364 1.864-6.64 9.221-10.53 16.433-8.686 7.211 1.844 11.545 8.722 9.68 15.363Z"
          fill="url(#user-page-icon-medal-e)"
        />
      </g>
      <g filter="url(#user-page-icon-medal-f)">
        <path
          d="M36.195 17.907c-1.313 4.788-6.494 7.592-11.573 6.263-5.078-1.33-8.13-6.289-6.817-11.077 1.313-4.788 6.494-7.592 11.573-6.263 5.078 1.33 8.13 6.289 6.817 11.077Z"
          fill="url(#user-page-icon-medal-g)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="user-page-icon-medal-a"
        x={0}
        y={0}
        width={45}
        height={47}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.5} />
        <feGaussianBlur stdDeviation={2.25} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3770_4671"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.5} />
        <feGaussianBlur stdDeviation={0.75} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
        <feBlend
          in2="effect1_dropShadow_3770_4671"
          result="effect2_dropShadow_3770_4671"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={0.75} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend
          in2="effect2_dropShadow_3770_4671"
          result="effect3_dropShadow_3770_4671"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect3_dropShadow_3770_4671"
          result="shape"
        />
      </filter>
      <filter
        id="user-page-icon-medal-b"
        x={4.5}
        y={16.197}
        width={20.574}
        height={22.116}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.149} />
        <feGaussianBlur stdDeviation={0.574} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.434102 0 0 0 0 0.412612 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3770_4671" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={-1.149} />
        <feGaussianBlur stdDeviation={0.574} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.869647 0 0 0 0 0.348549 0 0 0 0 0.331294 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3770_4671"
          result="effect2_innerShadow_3770_4671"
        />
      </filter>
      <filter
        id="user-page-icon-medal-c"
        x={22.54}
        y={23.565}
        width={17.044}
        height={18.389}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={0.954} />
        <feGaussianBlur stdDeviation={0.477} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.434102 0 0 0 0 0.412612 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3770_4671" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={-0.954} />
        <feGaussianBlur stdDeviation={0.477} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.869647 0 0 0 0 0.348549 0 0 0 0 0.331294 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3770_4671"
          result="effect2_innerShadow_3770_4671"
        />
      </filter>
      <filter
        id="user-page-icon-medal-d"
        x={13.52}
        y={1.555}
        width={26.98}
        height={27.856}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.445} />
        <feGaussianBlur stdDeviation={0.723} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.981557 0 0 0 0 0.214314 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3770_4671" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={-1.445} />
        <feGaussianBlur stdDeviation={0.723} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.862956 0 0 0 0 0.793642 0 0 0 0 0.173284 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3770_4671"
          result="effect2_innerShadow_3770_4671"
        />
      </filter>
      <filter
        id="user-page-icon-medal-f"
        x={16.5}
        y={5.055}
        width={21}
        height={21.445}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3770_4671"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3770_4671"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1.445} />
        <feGaussianBlur stdDeviation={0.723} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.981557 0 0 0 0 0.214314 0 0 0 1 0" />
        <feBlend in2="shape" result="effect2_innerShadow_3770_4671" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={-1.445} />
        <feGaussianBlur stdDeviation={0.723} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.862956 0 0 0 0 0.793642 0 0 0 0 0.173284 0 0 0 1 0" />
        <feBlend
          in2="effect2_innerShadow_3770_4671"
          result="effect3_innerShadow_3770_4671"
        />
      </filter>
      <radialGradient
        id="user-page-icon-medal-e"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(62.835 7.446 19.055) scale(26.2838 48.1228)"
      >
        <stop offset={0.034} stopColor="#F9E532" />
        <stop offset={0.084} stopColor="#fff" />
        <stop offset={0.132} stopColor="#F9E534" />
        <stop offset={0.478} stopColor="#FFD634" />
        <stop offset={0.927} stopColor="#FFF7B3" />
        <stop offset={1} stopColor="#F9E532" />
      </radialGradient>
      <radialGradient
        id="user-page-icon-medal-g"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(63.379 5.693 21.953) scale(18.8594 34.053)"
      >
        <stop offset={0.034} stopColor="#F9E532" />
        <stop offset={0.084} stopColor="#fff" />
        <stop offset={0.132} stopColor="#F9E534" />
        <stop offset={0.478} stopColor="#FFD634" />
        <stop offset={0.927} stopColor="#FFF7B3" />
        <stop offset={1} stopColor="#F9E532" />
      </radialGradient>
    </defs>
  </svg>
);
