/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { Vector2D } from "common/types";
import { useScale, usePlaySound } from "common/utils";
import {
  actions,
  selectScreenById,
  selectCanvasById,
} from "features/creator/slice";
import { Capture } from "features/creator/design/Capture";
import { CreatorOptions, GroupingDefault } from "features/creator/types";
import { RenderTemplateDesignScreen } from "features/creator/design/RenderTemplateDesignScreen";

export const TemplateDesignPanel = (props: { options: CreatorOptions }) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const { width, height, scale } = useScale();
  const [isFirst, setIsFirst] = useState(true);
  const [animated, setAnimated] = useState<boolean>(false);
  const [offset, setOffset] = useState<Vector2D>({ x: 0, y: 0 });

  const screenSize = useSelector(
    (state: RootState) => state.creator.screenSize
  );
  const canvasScale = useSelector(
    (state: RootState) => state.creator.canvasScale
  );
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const screen = useSelector((state: RootState) =>
    selectScreenById(state, selectedScreenId)
  );
  const selectedCanvas = useSelector((state: RootState) =>
    selectCanvasById(state, selectedScreenId)
  );
  const focus = useSelector((state: RootState) => state.creator.focus);
  const drawing = useSelector((state: RootState) => state.creator.drawing);
  const capture = useSelector((state: RootState) => state.creator.capture);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
  }, [isFirst]);

  useEffect(() => {
    // キャンパスでスクリーンを切り替えされた
    setAnimated(!isFirst);
    setOffset({
      x: width / 2 - (selectedCanvas.x + screenSize.width / 2 - 20),
      y: -selectedCanvas.y + (height / 2 - screenSize.height / 2),
    });
  }, [focus, scale]);

  const onDragStop = () => {
    play();
    dispatch(actions.handleAllCollapse());
    dispatch(actions.updateGrouping(GroupingDefault));
  };

  return (
    <>
      {capture && <Capture />}

      <div
        className="will-change-transform"
        style={{ zIndex: drawing.enable ? 9999 : 0 }}
        onContextMenu={(e) => e.preventDefault()}
      >
        {drawing.enable && <div style={{ width, height }} />}

        <div onClick={onDragStop}>
          <div
            className="!absolute w-0 h-0 top-0 left-0"
            style={{
              transform: `scale(${scale * canvasScale})`,
              transformOrigin: `${width / 2}px ${height / 2}px`,
            }}
          >
            <div
              id="design-panel-transform-container"
              className="w-0 h-0"
              style={{
                transform: `translate(${offset.x}px, ${offset.y}px) `,
                transition: !isFirst && animated && "transform 500ms",
              }}
            >
              {drawing.enable && (
                <div
                  className="flex-row-center !absolute top-0 left-0 bg-textcolor/black/50 backdrop-blur-[8px] z-[10001] pointer-events-none"
                  style={{
                    width: width,
                    height: height,
                    transform: `translate(${-offset.x}px, ${-offset.y}px) scale(${
                      1 / (scale * canvasScale)
                    })`,
                  }}
                />
              )}
              <RenderTemplateDesignScreen
                scale={scale * canvasScale}
                screen={screen}
                options={props.options}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
