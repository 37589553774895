import { useState, FormEvent, PointerEvent } from "react";
import { toast } from "react-toastify";
import I18n from "i18n-js";
import { Button, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { parentsFirebase } from "app/Auth";
import { WarningMessageBox } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";

export const MailSignInLinkedAccount = ({
  email,
  navigate,
}: {
  email: string;
  navigate: (path: string) => void;
}) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [required, setRequired] = useState(false);
  const [signError, setSignError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const email = data.get("email").toString();
      const password = data.get("password").toString();
      if (email && password) {
        handleDialog({ type: DialogType.LOADING, value: true });
        const auth = getAuth(parentsFirebase);
        signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            toast(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_SIGN_IN_SUCCESS_CONTENT")}
                </p>
              </div>
            );
            navigate("/parentsUser");
          })
          .catch((error) => {
            setSignError(true);
            console.error(error);
            console.error(
              `different account error: linked account email: ${email}, mail/password sign in email: ${email}`
            );
          })
          .finally(() =>
            handleDialog({ type: DialogType.LOADING, value: false })
          );
      }
    } catch (error) {
      console.error(error);
      console.error(
        `different account error: linked account email: ${email}, mail/password sign in email: ${email}`
      );
      handleDialog({ type: DialogType.LOADING, value: false });
    }
  };

  const handleGotoResetPassword = (e: PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    btnDelay(() => navigate("/mailResetPassword"));
  };

  const handleClickShowPassword = () => {
    play();
    setShowPassword(!showPassword);
  };

  const handleCloseNotFound = () => {
    play();
    setSignError(false);
  };

  return (
    <div className="flex-col-el items-center justify-around">
      {signError && (
        <WarningMessageBox
          zIndex={999999}
          title={I18n.t("MSG_SIGN_IN_FAILED_TITLE")}
          message={I18n.t("MSG_SIGN_IN_FAILED_MESSAGE")}
          close={handleCloseNotFound}
        />
      )}

      <div className="flex-col-center w-[375px] h-[300px]">
        <form className="flex-col-el w-full gap-5" onSubmit={handleSubmit}>
          <div className="flex-col-el">
            <input
              id="email"
              name="email"
              type="email"
              required={required}
              defaultValue={email}
              readOnly={true}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              autoFocus={false}
            />
          </div>

          <div className="flex-row-el relative items-center">
            <input
              id="password"
              name="password"
              required={required}
              type={showPassword ? "text" : "password"}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              placeholder={I18n.t("MSG_SIGN_USER_PASSWORD")}
              autoFocus={false}
              onChange={() => setRequired(false)}
            />
            <div className="flex-col-el absolute right-0 pr-3">
              <IconButton
                onClick={handleClickShowPassword}
                aria-label="toggle password visibility"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ height: 40, borderRadius: 2 }}
            onClick={() => setRequired(true)}
          >
            <p className="text-body text-[16px] text-white">
              {I18n.t("MSG_SIGN_IN_BTN")}
            </p>
          </Button>

          <div className="flex-row-el justify-end">
            <Button color="primary" onPointerDown={handleGotoResetPassword}>
              {I18n.t("MSG_SIGN_IN_FORGOT_PASSWORD")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
