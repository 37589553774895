import { useState, FormEvent } from "react";
import I18n from "i18n-js";
import { Button, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { usePlaySound } from "common/utils";
import { WarningMessageBox } from "common/elements";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";

export const InstitutionSign = (props: RouteProps) => {
  const { navigate } = props;
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [required, setRequired] = useState(false);
  const [signError, setSignError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleGoBack = () => navigate("/parentsSign");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      handleDialog({ type: DialogType.LOADING, value: true });
      setSignError(true);
      handleDialog({ type: DialogType.LOADING, value: false });
    } catch (error) {
      setSignError(true);
      console.error(error);
      handleDialog({ type: DialogType.LOADING, value: false });
    }
  };

  const handleClickShowPassword = () => {
    play();
    setShowPassword(!showPassword);
  };

  const handleCloseNotFound = () => {
    play();
    setSignError(false);
  };

  return (
    <div className="flex-col-el items-center justify-around w-full h-full">
      {signError && (
        <WarningMessageBox
          zIndex={999999}
          title={I18n.t("MSG_SIGN_IN_FAILED_TITLE")}
          message={I18n.t("MSG_SIGN_IN_FAILED_MESSAGE")}
          close={handleCloseNotFound}
        />
      )}

      <div className="flex-col-center w-[375px] h-[510px]">
        <div className="flex-col-center w-[375px] h-[300px]">
          <form className="flex-col-el w-full gap-5" onSubmit={handleSubmit}>
            <div className="flex-row-el relative items-center">
              <input
                id="code"
                name="code"
                required={required}
                type={"text"}
                className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
                placeholder={I18n.t("MSG_SIGN_USER_INSTITUTION_CODE")}
                autoFocus={false}
                onChange={() => setRequired(false)}
              />
            </div>

            <div className="flex-col-el">
              <input
                id="email"
                name="email"
                type="email"
                required={required}
                className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
                placeholder={I18n.t("MSG_SIGN_USER_MAIL")}
                autoFocus={false}
              />
              <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
                {I18n.t("MSG_APP_ERROR_EMAIL_NOT_VALID")}
              </label>
            </div>

            <div className="flex-row-el relative items-center">
              <input
                id="password"
                name="password"
                required={required}
                type={showPassword ? "text" : "password"}
                className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
                placeholder={I18n.t("MSG_SIGN_USER_PASSWORD")}
                autoFocus={false}
                onChange={() => setRequired(false)}
              />
              <div className="flex-col-el absolute right-0 pr-3">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
            </div>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => setRequired(true)}
            >
              {I18n.t("MSG_SIGN_IN_BTN")}
            </Button>
          </form>
        </div>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
