/* eslint-disable no-new-func */
import { useState } from "react";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, btnDelay, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const {
    event,
    highlightBlock,
    setRunning,
    setSuccess,
    gameOver,
    setGameOver,
  } = props;
  const play = usePlaySound();
  let target: HTMLImageElement;
  const [disabled, setDisabled] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [result, setResult] = useState([false, false]);
  const [clicked, setClicked] = useState([false, false]);
  const targetEles = ["Pineapple", "Grape"];

  const handleEvent = (index: number) =>
    new Function(
      "anime",
      "sleep",
      "highlightBlock",
      "setRunning",
      "setSuccess",
      "setGameOver",
      "setDisabled",
      "clickedIndex",
      "setClickedIndex",
      "target",
      "targetEles",
      "result",
      "setResult",
      event[index]
        ? event[index]
        : `return () => {
              setSuccess(false);
              setGameOver(true);
          }
          `
    );

  const handleButton1Click = () => {
    if (!clicked[0] && !disabled) {
      btnDelay(() => {
        play();
        setDisabled(true);
        setClicked([true, clicked[1]]);
        handleEvent(0)(
          anime,
          sleep,
          highlightBlock,
          setRunning,
          setSuccess,
          setGameOver,
          setDisabled,
          clickedIndex,
          setClickedIndex,
          target,
          targetEles,
          result,
          setResult
        )();
      });
    }
  };
  const handleButton2Click = () => {
    if (!clicked[1] && !disabled) {
      btnDelay(() => {
        play();
        setDisabled(true);
        setClicked([clicked[0], true]);
        handleEvent(1)(
          anime,
          sleep,
          highlightBlock,
          setRunning,
          setSuccess,
          setGameOver,
          setDisabled,
          clickedIndex,
          setClickedIndex,
          target,
          targetEles,
          result,
          setResult
        )();
      });
    }
  };

  return (
    <div className="flex-col-center gap-2 mt-8">
      <div className="flex-row-center gap-2">
        <div className="flex-col-center">
          <div
            id="course-program-border-1"
            className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20"
          >
            <img
              alt="*"
              id="course-program-answer-image-1"
              src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
              className="w-[100px] h-[100px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-answer-image-2"
              src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
              className="w-[100px] h-[100px] opacity-0 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />

            <img
              alt="*"
              id="course-program-image-1"
              className="w-[100px] h-[100px] opacity-0 z-10"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <AnswerMark id={1} />
          </div>
          <p className="text text-white">ボックス1</p>
        </div>
      </div>

      <div className="flex-row-el flex-center h-[50px] relative">
        <div className="flex-row-el flex-center gap-24 upsilon z-50">
          <button
            disabled={clicked[0] || gameOver}
            onClick={handleButton1Click}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン１</p>
          </button>
          <button
            disabled={clicked[1] || gameOver}
            onClick={handleButton2Click}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン２</p>
          </button>
        </div>
      </div>
    </div>
  );
};
