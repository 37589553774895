import { useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import anime from "animejs";
import { AppDispatch, RootState } from "app/store";
import { TutorialArrow } from "common/assets";
import { SpinModal, DialogMessage } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { ProjectType } from "features/creator/types";
import { createProject } from "features/creator/api";
import { MAX_PROJECTS } from "features/creator/constants";
import { selectAllProjects } from "features/creator/slice";
import { CreateProjectModal } from "features/creator/elements";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [create, setCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guidance, setGuidance] = useState(false);
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("user-project-panel-new-app-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 25 * scale,
        y: rect.y - 25 * scale,
        width: rect.width + 50 * scale,
        height: rect.height + 50 * scale,
      });
    }, 300);
  }, [scale]);

  const handleClick = () => {
    play();
    btnDelay(() => {
      if (userProjects.length > MAX_PROJECTS - 1) {
        setGuidance(true);
      } else {
        setCreate(true);
      }
    });
  };
  const handleCreateProject = (name: string, type: ProjectType) => {
    play();
    setLoading(true);
    btnDelay(() => {
      dispatch(
        createProject({
          uid: user.active.uid,
          name,
          type,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          anime({
            targets: document.getElementById(
              `user-app-project-container-${result.project.project_id}`
            ),
            scale: [1.3, 1],
            opacity: [0, 1],
            duration: 400,
            easing: "easeInOutQuad",
          });
          next();
        });
    });
  };
  const handleCreateProjectCancel = () => {
    play();
    btnDelay(() => setCreate(false));
  };

  return (
    <>
      {loading && <SpinModal />}

      <DialogMessage
        open={guidance}
        title={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_MESSAGE")}
        close={close}
      />

      <CreateProjectModal
        zIndex={20000}
        visible={create}
        name={`${I18n.t("MSG_CREATOR_CREATE_APP_NAME_PREFIX")}${
          userProjects.length + 1
        }`}
        type={ProjectType.BASIC}
        create={handleCreateProject}
        close={handleCreateProjectCancel}
      />

      <Overlay area={panelRect} onClick={handleClick}>
        <div
          className="absolute z-50"
          style={{
            width: 108 * scale,
            height: 105 * scale,
            transform: `translate(${panelRect.x - 100 * scale}px, ${
              panelRect.y + 40 * scale
            }px) rotate(-45deg)`,
          }}
        >
          <TutorialArrow />
        </div>
      </Overlay>
    </>
  );
};

export default Step;
