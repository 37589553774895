import { useState, useEffect } from "react";
import {
  unlink,
  getAuth,
  OAuthProvider,
  linkWithPopup,
  GoogleAuthProvider,
  linkWithCredential,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import I18n from "i18n-js";
import AppleIcon from "@mui/icons-material/Apple";
import { useDialog } from "app/hook";
import { parentsFirebase } from "app/Auth";
import { AppPlatform, DialogType } from "app/types";
import { usePlaySound } from "common/utils";
import { WarningMessageBox } from "common/elements";
import { GoogleSignNa } from "../assets";

export const ParentsUserAccountLink = () => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const auth = getAuth(parentsFirebase);
  const [error, setError] = useState(false);
  const [apple, setApple] = useState(false);
  const [google, setGoogle] = useState(false);
  const nonce = Math.random().toString(36).substring(2, 10);

  useEffect(() => {
    auth.currentUser?.providerData.map((provider) => {
      fetchSignInMethodsForEmail(auth, provider.email).then((methods) => {
        if (provider.providerId === "apple.com" && methods.length > 0) {
          setApple(true);
        }
        if (provider.providerId === "google.com" && methods.length > 0) {
          setGoogle(true);
        }
      });
    });
  }, []);

  const handleError = (error: any) => {
    if (
      error.code === "auth/credential-already-in-use" ||
      error.code === "auth/provider-already-linked"
    ) {
      setError(true);
    }
    handleDialog({ type: DialogType.LOADING, value: false });
    console.error(`sign error: ${JSON.stringify(error)}`);
  };

  const handleFirebaseGoogleSignIn = async (idToken: string) => {
    const firebaseAuth = getAuth(parentsFirebase);
    const credential = GoogleAuthProvider.credential(idToken);
    linkWithCredential(firebaseAuth.currentUser, credential)
      .then(() => {
        setGoogle(true);
        handleDialog({ type: DialogType.LOADING, value: false });
      })
      .catch(handleError);
  };

  const handleFirebaseAppleSignIn = async (idToken: string) => {
    const firebaseAuth = getAuth(parentsFirebase);
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    const credential = provider.credential({
      idToken: idToken,
      rawNonce: nonce,
    });
    linkWithCredential(firebaseAuth.currentUser, credential)
      .then(() => {
        setApple(true);
        handleDialog({ type: DialogType.LOADING, value: false });
      })
      .catch(handleError);
  };

  const handleWebAppleSignInSuccess = (event) => {
    console.log(`web apple sign in success: ${JSON.stringify(event)}`);
    handleFirebaseAppleSignIn(event.detail.authorization.id_token);
  };

  const handleWebAppleSignInFailure = (event) => {
    handleDialog({ type: DialogType.LOADING, value: false });
    console.log(`web apple sign in success: ${JSON.stringify(event)}`);
  };

  useEffect(() => {
    const init_apple_sign = async () => {
      const encoder = new TextEncoder();
      const encodedNonce = encoder.encode(nonce);
      const hashBuffer = await crypto.subtle.digest("SHA-256", encodedNonce);
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      const hashedNonce = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");

      //@ts-ignore
      AppleID.auth.init({
        clientId: "com.inexus.app.services.parents",
        scope: "email",
        redirectURI: import.meta.env.VITE_PUBLIC_URL,
        state: "kids",
        nonce: hashedNonce,
        usePopup: true,
      });
    };

    if ("ReactNativeWebView" in window) {
      if (window.kidsAppPlatform === "ios") {
        window.addEventListener("message", handleMessage);
      } else {
        document.addEventListener("message", handleMessage);
      }
    } else {
      init_apple_sign();

      document.addEventListener(
        "AppleIDSignInOnSuccess",
        handleWebAppleSignInSuccess
      );

      document.addEventListener(
        "AppleIDSignInOnFailure",
        handleWebAppleSignInFailure
      );
    }

    return () => {
      if ("ReactNativeWebView" in window) {
        if (window.kidsAppPlatform === "ios") {
          window.removeEventListener("message", handleMessage);
        } else {
          document.removeEventListener("message", handleMessage);
        }
      } else {
        document.removeEventListener(
          "AppleIDSignInOnSuccess",
          handleWebAppleSignInSuccess
        );

        document.removeEventListener(
          "AppleIDSignInOnFailure",
          handleWebAppleSignInFailure
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMessage = (message) => {
    const { command, data } = JSON.parse(message.data);
    switch (command) {
      case "signIn":
        const { method } = data;
        switch (method) {
          case "apple":
            if (data.idToken) {
              handleFirebaseAppleSignIn(data.idToken);
            } else {
              handleError(data.result);
            }
            break;
          case "google":
            if (data.idToken) {
              handleFirebaseGoogleSignIn(data.idToken);
            } else {
              handleError(data.result);
            }
            break;

          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handleLinkApple = async () => {
    play();
    if (apple) {
      handleDialog({ type: DialogType.LOADING, value: true });
      unlink(auth.currentUser, "apple.com")
        .then((result) => {
          setApple(false);
        })
        .catch((error) => {
          console.error(error);
          setError(true);
        })
        .finally(() =>
          handleDialog({ type: DialogType.LOADING, value: false })
        );
    } else {
      if (Number(window.kidsBuildVersion) > 88) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "signIn",
            data: {
              method: "apple",
            },
          })
        );
      } else {
        //@ts-ignore
        await AppleID.auth.signIn();
      }
    }
  };

  const handleLinkGoogle = () => {
    play();
    if (google) {
      handleDialog({ type: DialogType.LOADING, value: true });
      unlink(auth.currentUser, "google.com")
        .then(async (result) => {
          setGoogle(false);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() =>
          handleDialog({ type: DialogType.LOADING, value: false })
        );
    } else {
      if ("ReactNativeWebView" in window) {
        if (
          window.kidsAppPlatform === AppPlatform.iOS &&
          Number(window.kidsBuildVersion) > 90
        ) {
          handleDialog({ type: DialogType.LOADING, value: true });
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              command: "signIn",
              data: {
                method: "google",
              },
            })
          );
        } else if (
          window.kidsAppPlatform === AppPlatform.Android &&
          Number(window.kidsBuildVersion) > 70
        ) {
          handleDialog({ type: DialogType.LOADING, value: true });
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              command: "signIn",
              data: {
                method: "google",
              },
            })
          );
        } else if (
          window.kidsAppPlatform === AppPlatform.Amazon &&
          Number(window.kidsBuildVersion) > 64
        ) {
          handleDialog({ type: DialogType.LOADING, value: true });
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              command: "signIn",
              data: {
                method: "google",
              },
            })
          );
        }
      } else {
        handleDialog({ type: DialogType.LOADING, value: true });
        const firebaseAuth = getAuth(parentsFirebase);
        firebaseAuth.languageCode = "ja";
        const auth = getAuth(parentsFirebase);
        const provider = new GoogleAuthProvider();
        provider.addScope("email");
        linkWithPopup(auth.currentUser, provider)
          .then(async (result) => {
            setGoogle(true);
          })
          .catch((error) => {
            console.error(error);
            if (
              error.code === "auth/credential-already-in-use" ||
              error.code === "auth/provider-already-linked"
            ) {
              setError(true);
            }
          })
          .finally(() =>
            handleDialog({ type: DialogType.LOADING, value: false })
          );
      }
    }
  };

  const handleCloseError = () => {
    play();
    setError(false);
  };

  return (
    <div className="flex-col-el w-[280px]">
      {error && (
        <WarningMessageBox
          zIndex={999999}
          title={I18n.t(
            "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_ALREADY_ERROR_TITLE"
          )}
          message={I18n.t(
            "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_ALREADY_ERROR_MESSAGE"
          )}
          close={handleCloseError}
        />
      )}

      <div className="flex-col-el gap-6">
        <div className="flex-col-el gap-3">
          {apple && (
            <p className="text-body text-font-caption2 text-textcolor/black">
              {
                auth.currentUser?.providerData
                  .filter((provider) => provider.providerId === "apple.com")
                  .shift().email
              }
            </p>
          )}
          <div className="flex-col-el gap-3">
            <div
              onClick={handleLinkApple}
              className="flex-row-el flex-center w-full h-[40px] bg-[#000] rounded-lg cursor-pointer"
            >
              <AppleIcon
                sx={{ fontSize: 16, marginRight: 0.5, color: "white" }}
              />
              <p className="text-body text-font-caption2 text-white">
                {apple
                  ? I18n.t(
                      "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_UNLINK_WITH_APPLE"
                    )
                  : I18n.t(
                      "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_WITH_APPLE"
                    )}
              </p>
            </div>
          </div>
        </div>

        <div className="flex-col-el gap-3">
          {google && (
            <p className="text-body text-font-caption2 text-textcolor/black">
              {
                auth.currentUser?.providerData
                  .filter((provider) => provider.providerId === "google.com")
                  .shift().email
              }
            </p>
          )}

          <div
            onClick={handleLinkGoogle}
            className="flex-row-el flex-center relative w-full h-[42px] bg-[#fff] rounded-md border-solid border-[1px] border-[#dadce0] cursor-pointer"
          >
            <div className="flex-col-el absolute top-0 left-0">
              <GoogleSignNa />
            </div>
            <p className="text-body text-[16px] text-textcolor/black">
              {google
                ? I18n.t(
                    "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_UNLINK_WITH_GOOGLE"
                  )
                : I18n.t(
                    "MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT_LINK_WITH_GOOGLE"
                  )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
