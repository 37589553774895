import { useEffect, PointerEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { useScale, btnDelay, usePlaySound } from "common/utils";
import {
  IconBack,
  ProfileBg,
  IconMapColor,
  IconHartColor,
  IconProjectColor,
} from "features/user/assets";
import {
  selectAllProjects,
  actions as creatorActions,
} from "features/creator/slice";
import { getUserStatus } from "features/user/api";
import { ProjectType } from "features/creator/types";
import { string2Course } from "features/courses/utils";
import { selectAllChatRoomMessageSync } from "features/user/slice";
import { actions as courseActions } from "features/courses/coursesSlice";

export const User = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const updateStatus = useSelector(
    (state: RootState) => state.user.updateStatus
  );
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const courseAlgorithmSummary = useSelector(
    (state: RootState) => state.courses.progresses.algorithm.summary
  );
  const courseProgramSummary = useSelector(
    (state: RootState) => state.courses.progresses.program.summary
  );
  const status = useSelector((state: RootState) => state.user.status);

  const chatRoomMessageSync = useSelector(selectAllChatRoomMessageSync);
  const hasUnreadMessage =
    chatRoomMessageSync.filter(
      (sync) =>
        dayjs(sync.latestMessageCreatedAt, "YYYY-MM-DDTHH:mm:ssZ").diff(
          dayjs(sync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")
        ) > 0 ||
        (sync.latestReadAt === null && sync.latestMessageCreatedAt !== null)
    ).length > 0;

  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );

  useEffect(() => {
    dispatch(getUserStatus({ uid: user.active.uid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, updateStatus]);

  const handleClick = (e: PointerEvent) => {
    e.stopPropagation();
    play();
    btnDelay(() => {
      dispatch(
        actions.updateDialog({
          type: DialogType.USER_PROFILE,
          value: true,
        })
      );
    });
  };

  const handleBack = () => {
    play();
    if (location.pathname !== "/") {
      const pathArray = location.pathname.split("/");
      switch (true) {
        case /\/creator$/.test(location.pathname):
          navigate("/");
          break;
        case /\/creator\//.test(location.pathname):
          btnDelay(() => dispatch(creatorActions.updateCapture(true)));
          break;
        case /\/courses$/.test(location.pathname):
          navigate("/");
          break;
        case /\/courses\/[a-zA-Z0-9]+\/stage\/\d$/.test(location.pathname):
          dispatch(
            courseActions.updateSelectedCourse({
              course: string2Course(pathArray[2]),
            })
          );
          dispatch(
            courseActions.updateSelectedStage({
              stage: Number(pathArray[4]),
            })
          );
          navigate("/courses");
          break;
        case /\/courses\/[a-zA-Z0-9_]+\/stage\/\d$/.test(location.pathname):
          dispatch(
            courseActions.updateSelectedCourse({
              course: string2Course(pathArray[2]),
            })
          );
          dispatch(
            courseActions.updateSelectedStage({
              stage: Number(pathArray[4]),
            })
          );
          navigate(`/courses`);
          break;
        case /\/courses\/[a-zA-Z0-9_]+\/stage\/\d\/step\/\d$/.test(
          location.pathname
        ):
          navigate(`/courses/${pathArray[2]}/stage/${pathArray[4]}`);
          break;
        case /\/courses\/tangible/.test(location.pathname):
          btnDelay(() => navigate("/courses"));
          break;
        case /\/courses\/builder/.test(location.pathname):
          btnDelay(() => navigate("/courses"));
          break;
        case /\/courses\/ocha\/recognition/.test(location.pathname):
          btnDelay(() => navigate("/courses"));
          break;
        default:
          break;
      }
    }
  };

  return (
    <div
      className="flex-row-view !absolute left-0 z-[9998]"
      style={{ top: 4 * scale }}
    >
      <div
        className="flex-row-view items-center justify-start"
        style={{ width: 300 * scale, height: 102 * scale }}
      >
        <div
          className="flex-row-view items-center justify-start w-[300px] h-[102px] origin-left"
          style={{ transform: `scale(${scale})` }}
        >
          <div
            id="app-back-btn"
            className={`flex-col-view items-start justify-center w-[96px] h-[72px] pl-[8px] cursor-pointer active:opacity-70 ${
              location.pathname === "/" ? "bg-gray2/60" : "bg-brown/100"
            }`}
            onClick={handleBack}
          >
            <div className="flex-col-center">
              <div className="flex-col-center w-[32px] h-[32px] mt-1 mb-1">
                <IconBack />
              </div>

              <p className="text text-white !text-[16px]">
                {I18n.t("MSG_BACK_BTN")}
              </p>
            </div>
          </div>

          <div
            className="flex-col-center !absolute left-[64px] cursor-pointer bg-white rounded-full z-10 active:shadow-active_show"
            style={{
              width: 104,
              height: 104,
              filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
            }}
          >
            {hasUnreadMessage && (
              <div className="flex-col-center !absolute right-0 top-0 bg-danger rounded-full w-[30px] h-[30px] z-50" />
            )}

            <div
              className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden"
              onPointerDown={handleClick}
            >
              <img
                key={user?.active.icon}
                alt="ユーザプロフィール画像"
                src={user?.active.icon}
                className="w-full h-full object-contain"
              />
            </div>
          </div>

          <div className="flex-col-view items-start justify-center w-[314px] h-[90px] ml-[22px] drop-shadow-normal_show">
            <div className="flex-col-center w-full h-full !absolute top-0 left-0">
              <ProfileBg />
            </div>
            <div className="flex-col-view items-start justify-center w-[300px] h-[58px] ml-[58px]">
              <div className="flex-row-center gap-5">
                <p className="text text-textcolor/black !leading-[30px] !ml-[4px]">
                  {user?.active.display_name}
                </p>
              </div>

              <div className="flex-row-view w-full mt-[4px]">
                <div className="flex-row-center h-[32px] bg-beige/10 px-[8px] rounded-[12px]">
                  <div className="flex-col-center w-[24px] h-[24px]">
                    <IconMapColor />
                  </div>

                  <p className="text text-textcolor/black !text-[18px] !ml-[4px]">
                    {isNaN(courseAlgorithmSummary?.finished)
                      ? 0
                      : courseAlgorithmSummary?.finished +
                        (isNaN(courseProgramSummary?.finished)
                          ? 0
                          : courseProgramSummary?.finished)}
                  </p>
                </div>

                <div className="flex-row-center h-[32px] bg-beige/10 px-[8px] rounded-[12px] ml-[8px]">
                  <div className="flex-col-center w-[24px] h-[24px]">
                    <IconProjectColor />
                  </div>

                  <p className="text text-textcolor/black !text-[18px] !ml-[6px]">
                    {userProjects.length}
                  </p>
                </div>

                <div className="flex-row-center h-[32px] bg-beige/10 px-[8px] rounded-[12px] ml-[8px]">
                  <div className="flex-col-center w-[24px] h-[24px]">
                    <IconHartColor />
                  </div>

                  <p className="text text-textcolor/black !text-[18px] !ml-[6px]">
                    {status?.project.favorited}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
