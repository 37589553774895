import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitCharacterBlock = () => {
  const startBlock = () => {
    return {
      type: "character_event",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    };
  };

  Blockly.Blocks["character_event"] = {
    init: function () {
      this.appendDummyInput().appendField("%{BKY_COURSE_START_BLOCK}");
      this.jsonInit(startBlock());
      this.setCollapsed(false);
      this.setColour("#FFBF00");
      this.contextMenu = false;
    },
  };

  Blockly.Blocks["character_motion_move"] = {
    init: function () {
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.appendDummyInput().appendField(
        "%{BKY_COURSE_ALGORITHM_DIRECTION_MOVE}"
      );
      this.setColour("#4C97FF");
      this.contextMenu = false;
    },
  };

  Blockly.Blocks["character_motion_jump"] = {
    init: function () {
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.appendDummyInput().appendField("    ジャンプ    ");
      this.setColour("#4C97FF");
      this.contextMenu = false;
    },
  };

  Blockly.Blocks["character_motion_turn_left"] = {
    init: function () {
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.appendDummyInput().appendField(
        "%{BKY_COURSE_ALGORITHM_DIRECTION_LEFT}"
      );
      this.setColour("#4C97FF");
      this.contextMenu = false;
    },
  };

  Blockly.Blocks["character_motion_turn_right"] = {
    init: function () {
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.appendDummyInput().appendField(
        "%{BKY_COURSE_ALGORITHM_DIRECTION_RIGHT}"
      );
      this.setColour("#4C97FF");
      this.contextMenu = false;
    },
  };

  BlocklyJs["character_event"] = function (block: Blockly.Block) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const code = `
    return async () => {
      window.LoopTrap = 20;
      highlightBlock("${block.id}", true);
      await sleep(1000);
      highlightBlock("${block.id}", false);
      ${logic}

      if(!scene.isDisposed){
        setGameOver(true);
      } else {
        console.log("scene has been disposed.");
      };
    }
    `;
    return code;
  };

  BlocklyJs["character_motion_move"] = function (block: Blockly.BlockSvg) {
    return `
    highlightBlock("${block.id}", true);
    if(!scene.isDisposed){moveSound();await performMove(scene, character, stepMap, offset, setSuccess, setGameOver);};
    highlightBlock("${block.id}", false);
    `;
  };

  BlocklyJs["character_motion_jump"] = function (block: Blockly.BlockSvg) {
    return `
    highlightBlock("${block.id}", true);
    if(!scene.isDisposed){jumpSound();await performJump(scene, character, stepMap, offset, setSuccess, setGameOver);};
    highlightBlock("${block.id}", false);
    `;
  };

  BlocklyJs["character_motion_turn_left"] = function (block: Blockly.BlockSvg) {
    return `
    highlightBlock("${block.id}", true);
    if(!scene.isDisposed){turnSound();await performTurnToLeft(scene, character);};
    highlightBlock("${block.id}", false);
    `;
  };

  BlocklyJs["character_motion_turn_right"] = function (
    block: Blockly.BlockSvg
  ) {
    return `
    highlightBlock("${block.id}", true);
    if(!scene.isDisposed){turnSound();await performTurnToRight(scene, character);};
    highlightBlock("${block.id}", false);
    `;
  };
};
