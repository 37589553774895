import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippetByOnly } from "features/courses/program/game/blockly/uitls";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`,
            width: 30,
            height: 30,
            alt: "Vehicle",
          },
          "button_1",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          let target;
          if(${result}){
            ${logic}
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitImageSetterXBlock = () => {
  Blockly.Blocks["image_setter_x_block"] = {
    init: function () {
      this.jsonInit({
        type: "image_setter_x_block",
        message0: "%{BKY_COURSE_PROGRAM_SETTER_X_COORDINATE}",
        args0: [
          {
            type: "field_dropdown",
            name: "vehicle",
            options: [
              [
                {
                  src: `${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`,
                  width: 30,
                  height: 30,
                  alt: "Vehicle",
                },
                "Vehicle",
              ],
            ],
          },
          {
            type: "input_value",
            name: "value",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["image_setter_x_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    const result = value === "100";
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById("course-program-image-1");
      await anime({
        targets: target,
        scale: 1.5,
        duration: 700,
        autoplay: true,
        easing: "easeInOutQuad",
      }).finished;
      await anime({
        targets: target,
        left: ${Number(value) * 4.5 - 40},
        duration: 1000,
        autoplay: true,
        easing: "easeInOutQuad",
      }).finished;
      await anime({
        targets: target,
        scale: 1,
        duration: 700,
        autoplay: true,
        easing: "easeInOutQuad",
      }).finished;
      ${showAnswerSnippetByOnly(result)}
      await sleep(700);
      highlightBlock("${block.id}", false);
      setSuccess(${result});
      setGameOver(true);
    `;
  };
};
