import { AssetModel, AssetModelType } from "./types";

export const assets: { name: string; models: AssetModel[] }[] = [
  {
    name: "decoration",
    models: [
      {
        type: AssetModelType.DECORATION,
        name: "arrow_teamBlue",
        filename: "arrow_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "arrow_teamRed",
        filename: "arrow_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "arrow_teamYellow",
        filename: "arrow_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "ball",
        filename: "ball.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "ball_teamBlue",
        filename: "ball_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "ball_teamRed",
        filename: "ball_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "ball_teamYellow",
        filename: "ball_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "barrierFloor",
        filename: "barrierFloor.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "barrierLadder",
        filename: "barrierLadder.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "barrierSmall",
        filename: "barrierSmall.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "barrierStrut",
        filename: "barrierStrut.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "blaster_teamBlue",
        filename: "blaster_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "blaster_teamRed",
        filename: "blaster_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "blaster_teamYellow",
        filename: "blaster_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "bomb_teamBlue",
        filename: "bomb_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "bomb_teamRed",
        filename: "bomb_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "bomb_teamYellow",
        filename: "bomb_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "bow_teamBlue",
        filename: "bow_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "bow_teamRed",
        filename: "bow_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "bow_teamYellow",
        filename: "bow_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "detail_desert",
        filename: "detail_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "detail_forest",
        filename: "detail_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "flag_teamBlue",
        filename: "flag_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "flag_teamRed",
        filename: "flag_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "flag_teamYellow",
        filename: "flag_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateLarge_teamBlue",
        filename: "gateLarge_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateLarge_teamRed",
        filename: "gateLarge_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateLarge_teamYellow",
        filename: "gateLarge_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateSmallWide_teamBlue",
        filename: "gateSmallWide_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateSmallWide_teamRed",
        filename: "gateSmallWide_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateSmallWide_teamYellow",
        filename: "gateSmallWide_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateSmall_teamBlue",
        filename: "gateSmall_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateSmall_teamRed",
        filename: "gateSmall_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "gateSmall_teamYellow",
        filename: "gateSmall_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "heart_teamBlue",
        filename: "heart_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "heart_teamRed",
        filename: "heart_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "heart_teamYellow",
        filename: "heart_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "hoop_teamBlue",
        filename: "hoop_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "hoop_teamRed",
        filename: "hoop_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "hoop_teamYellow",
        filename: "hoop_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "lightning",
        filename: "lightning.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "plantA_desert",
        filename: "plantA_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "plantA_forest",
        filename: "plantA_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "plantB_desert",
        filename: "plantB_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "plantB_forest",
        filename: "plantB_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "powerupBlock_teamBlue",
        filename: "powerupBlock_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "powerupBlock_teamRed",
        filename: "powerupBlock_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "powerupBlock_teamYellow",
        filename: "powerupBlock_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "powerupBomb",
        filename: "powerupBomb.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "ring_teamBlue",
        filename: "ring_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "ring_teamRed",
        filename: "ring_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "ring_teamYellow",
        filename: "ring_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "rocksA_desert",
        filename: "rocksA_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "rocksA_forest",
        filename: "rocksA_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "rocksB_desert",
        filename: "rocksB_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "rocksB_forest",
        filename: "rocksB_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "slingshot_teamBlue",
        filename: "slingshot_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "slingshot_teamRed",
        filename: "slingshot_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "slingshot_teamYellow",
        filename: "slingshot_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "spikeRoller",
        filename: "spikeRoller.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "star",
        filename: "star.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "swiper_teamBlue",
        filename: "swiper_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "swiper_teamRed",
        filename: "swiper_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "swiper_teamYellow",
        filename: "swiper_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "sword_teamBlue",
        filename: "sword_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "sword_teamRed",
        filename: "sword_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "sword_teamYellow",
        filename: "sword_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "target",
        filename: "target.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "targetStand",
        filename: "targetStand.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tree_desert",
        filename: "tree_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tree_forest",
        filename: "tree_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowHigh_desert",
        filename: "tileSlopeLowHigh_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowHigh_forest",
        filename: "tileSlopeLowHigh_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowHigh_teamBlue",
        filename: "tileSlopeLowHigh_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowHigh_teamRed",
        filename: "tileSlopeLowHigh_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowHigh_teamYellow",
        filename: "tileSlopeLowHigh_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowMedium_teamRed",
        filename: "tileSlopeLowMedium_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowMedium_desert",
        filename: "tileSlopeLowMedium_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowMedium_forest",
        filename: "tileSlopeLowMedium_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowMedium_teamBlue",
        filename: "tileSlopeLowMedium_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeLowMedium_teamYellow",
        filename: "tileSlopeLowMedium_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeMediumHigh_desert",
        filename: "tileSlopeMediumHigh_desert.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeMediumHigh_forest",
        filename: "tileSlopeMediumHigh_forest.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeMediumHigh_teamBlue",
        filename: "tileSlopeMediumHigh_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeMediumHigh_teamRed",
        filename: "tileSlopeMediumHigh_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.DECORATION,
        name: "tileSlopeMediumHigh_teamYellow",
        filename: "tileSlopeMediumHigh_teamYellow.gltf.glb",
      },
    ],
  },
  {
    name: "ground",
    models: [
      {
        type: AssetModelType.GROUND,
        name: "tileLow_desert",
        filename: "tileLow_desert.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileLow_forest",
        filename: "tileLow_forest.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileLow_teamRed",
        filename: "tileLow_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileLow_teamYellow",
        filename: "tileLow_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileLow_forest_ground",
        filename: "tileLow_forest_ground.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileMedium_desert",
        filename: "tileMedium_desert.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileMedium_forest",
        filename: "tileMedium_forest.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileMedium_teamRed",
        filename: "tileMedium_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileMedium_teamYellow",
        filename: "tileMedium_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileHigh_desert",
        filename: "tileHigh_desert.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileHigh_forest",
        filename: "tileHigh_forest.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileHigh_teamRed",
        filename: "tileHigh_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileHigh_teamYellow",
        filename: "tileHigh_teamYellow.gltf.glb",
      },
      {
        type: AssetModelType.GROUND,
        name: "tileHigh_desert_ground",
        filename: "tileHigh_desert_ground.glb",
      },
      // {
      //   type: AssetModelType.GROUND,
      //   name: "tileLarge_desert",
      //   filename: "tileLarge_desert.gltf.glb",
      // },
      // {
      //   type: AssetModelType.GROUND,
      //   name: "tileLarge_forest",
      //   filename: "tileLarge_forest.gltf.glb",
      // },
      // {
      //   type: AssetModelType.GROUND,
      //   name: "tileLarge_teamRed",
      //   filename: "tileLarge_teamRed.gltf.glb",
      // },
      // {
      //   type: AssetModelType.GROUND,
      //   name: "tileLarge_teamYellow",
      //   filename: "tileLarge_teamYellow.gltf.glb",
      // },
    ],
  },
  {
    name: "river",
    models: [
      {
        type: AssetModelType.RIVER,
        name: "tileLow_teamBlue",
        filename: "tileLow_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.RIVER,
        name: "tileMedium_teamBlue",
        filename: "tileMedium_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.RIVER,
        name: "tileHigh_teamBlue",
        filename: "tileHigh_teamBlue.gltf.glb",
      },
      // {
      //   type: AssetModelType.RIVER,
      //   name: "tileLarge_teamBlue",
      //   filename: "tileLarge_teamBlue.gltf.glb",
      // },
    ],
  },
  {
    name: "gem",
    models: [
      {
        type: AssetModelType.GEM,
        name: "diamond_teamBlue",
        filename: "diamond_teamBlue.gltf.glb",
      },
      {
        type: AssetModelType.GEM,
        name: "diamond_teamRed",
        filename: "diamond_teamRed.gltf.glb",
      },
      {
        type: AssetModelType.GEM,
        name: "diamond_teamYellow",
        filename: "diamond_teamYellow.gltf.glb",
      },
    ],
  },
  {
    name: "condition",
    models: [
      {
        type: AssetModelType.CONDITION,
        name: "button_teamBlue",
        filename: "button_teamBlue.gltf.glb",
        metadata: {
          color: "#71cdfc",
        },
      },
      {
        type: AssetModelType.CONDITION,
        name: "button_teamRed",
        filename: "button_teamRed.gltf.glb",
        metadata: {
          color: "#fb3a69",
        },
      },
      {
        type: AssetModelType.CONDITION,
        name: "button_teamYellow",
        filename: "button_teamYellow.gltf.glb",
        metadata: {
          color: "#fdd73e",
        },
      },
    ],
  },
  {
    name: "character",
    models: [
      {
        type: AssetModelType.CHARACTER,
        name: "character_duck",
        filename: "character_duck.glb",
        rotation: true,
        offset: { x: 0, y: -0.4, z: 0 },
        scaling: { x: 1.2, y: 1.2, z: 1.2 },
      },
      {
        type: AssetModelType.CHARACTER,
        name: "character_dog",
        filename: "character_dog.gltf",
        rotation: true,
        offset: { x: 0, y: 0, z: 0 },
        scaling: { x: 1.3, y: 1.3, z: 1.3 },
      },
      {
        type: AssetModelType.CHARACTER,
        name: "character_bear",
        filename: "character_bear.gltf",
        rotation: true,
        offset: { x: 0, y: 0, z: 0 },
        scaling: { x: 1.3, y: 1.3, z: 1.3 },
      },
    ],
  },
];

export default assets;
