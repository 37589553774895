import { SVGProps } from "react";

export const Icon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={176}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#feedback-icon-2)">
      <path
        d="M54.5 78.5 35 38l31.5 12 9-36.5 21.5 31L130 19l-7 40h21.5l-12 22 6.5 12.5H35l19.5-15Z"
        fill="#F9E532"
      />
      <path
        d="m153.285 53.391 1.613-2.675-3.097.414-23.913 3.193 10.284-44.605.968-4.201-3.367 2.693-37.587 30.06L75.14 5.142 73.2 2.353l-.753 3.315-8.48 37.377-37.384-16.076-3.889-1.672 1.968 3.748 24.932 47.48-26.446 18.24-3.965 2.735h129.641l-1.556-2.332-10.072-15.099 16.089-26.678Zm-44.351 17.866 4.529-19.925 1.143-5.032-3.663 3.635-15.12 15.006-11.75-16.926-2.211-3.183-.509 3.842-2.687 20.28-16.557-7.358-3.738-1.66 1.778 3.683 8.912 18.462-12.96 6.593h-8.587l12.478-8.611 1.094-.755-.618-1.177-18.312-34.865 26.472 11.38 1.656.711.399-1.758 6.728-29.667 17.978 25.845.915 1.316 1.253-1.002 27.203-21.754-7.902 34.293-.486 2.11 2.146-.286 19.295-2.565-10.617 17.604-.492.815.528.791 5.065 7.595h-8.32l-4.689-4.984 4.613-12.034.89-2.323-2.47.297-13.387 1.607Z"
        fill="#FC6560"
        stroke="#667786"
        strokeWidth={3}
      />
    </g>
    <defs>
      <clipPath id="feedback-icon-2">
        <path fill="#fff" d="M0 0h176v120H0z" />
      </clipPath>
    </defs>
  </svg>
);
