import { useEffect, MutableRefObject } from "react";
import Blockly from "blockly";
import { ToolboxInfoType } from "features/courses/program/game/type";

const blockStyleMapping = (
  blockType: string
): { height: number; color: string; borderColor: string } => {
  switch (blockType) {
    case "program_button_click_event_block":
      return { height: 130, color: "#ffbf00", borderColor: "#cc9900" };
    case "program_image_click_event_block":
      return { height: 135, color: "#ffbf00", borderColor: "#cc9900" };
    case "show_image_block":
    case "move_image_block":
      return { height: 64, color: "#FFAB19", borderColor: "#bf8013" };
    case "show_label_text_block":
      return { height: 64, color: "#ffbf00", borderColor: "#cc9900" };
    case "image_setter_block":
      return { height: 64, color: "#FFAB19", borderColor: "#bf8013" };
    case "label_text_getter_block":
      return { height: 44, color: "#ffbf00", borderColor: "#cc9900" };
    case "label_text_setter_block":
      return { height: 60, color: "#ffbf00", borderColor: "#cc9900" };
    case "label_text_show_setter_block":
      return { height: 60, color: "#4C97FF", borderColor: "#4280D7" };
    case "image_count_getter_block":
      return { height: 50, color: "#59c059", borderColor: "#389438" };
    case "image_getter_x_block":
      return { height: 50, color: "#59c059", borderColor: "#389438" };
    case "image_setter_x_block":
      return { height: 60, color: "#59c059", borderColor: "#389438" };
    case "program_math_plus_block":
      return { height: 40, color: "#59c059", borderColor: "#389438" };
    case "program_coord_math_plus_block":
      return { height: 60, color: "#59c059", borderColor: "#389438" };
    case "program_coord_math_plus_small_block":
      return { height: 55, color: "#59c059", borderColor: "#389438" };
    case "hand_getter_rotate_block":
      return { height: 44, color: "#59c059", borderColor: "#389438" };
    case "hand_getter_rotate_inline_block":
      return { height: 44, color: "#59c059", borderColor: "#389438" };
    case "hand_setter_rotate_block":
      return { height: 60, color: "#59c059", borderColor: "#389438" };
    case "math_number":
      return { height: 40, color: "#59c059", borderColor: "#389438" };
    case "controls_repeat_ext":
      return { height: 112, color: "#0fbd8c", borderColor: "#0b8e69" };
    default:
      return { height: 0, color: "", borderColor: "" };
  }
};

export const BlockLimits = (props: {
  width: number;
  height: number;
  stage: number;
  scale: number;
  offset: number;
  workspace: Blockly.WorkspaceSvg;
  toolboxRef: MutableRefObject<ToolboxInfoType>;
}) => {
  const MARGIN_LEFT = 8;

  const { width, height, stage, scale, offset, workspace, toolboxRef } = props;
  const PADDING_TOP = stage === 1 || stage === 2 ? 6 : 8;

  useEffect(() => {
    if (workspace) {
      const ws = (workspace.getFlyout() as Blockly.Flyout).getWorkspace();
      const onEvent = (event) => {
        switch (event.type) {
          case Blockly.Events.BLOCK_CREATE:
            const block = ws.getBlockById(event.blockId);
            if (block) {
              block.contextMenu = false;
              block.setEditable(false);
              block
                .getChildren(false)
                .forEach((block) => block.setEditable(false));
            }
            break;
          default:
            break;
        }
      };
      ws.addChangeListener(onEvent);
      return () => {
        if (workspace) {
          const flyout = workspace.getFlyout() as Blockly.Flyout;
          if (flyout) {
            const ws = flyout.getWorkspace();
            ws.removeChangeListener(onEvent);
          }
        }
      };
    }
  }, [workspace]);

  return (
    <div className="flex-col-view h-full">
      <div
        className="flex-col-view"
        style={{
          marginLeft: MARGIN_LEFT * scale,
          top: (offset + PADDING_TOP) * scale,
          width: width * scale,
          height: height - offset * scale - 75 * scale,
        }}
      >
        <div className="flex-col-center !justify-start w-full h-full overflow-x-clip overflow-y-hidden">
          {toolboxRef.current.json.contents[0].contents.map(
            (content, index) => {
              const style = blockStyleMapping(content.type);
              return (
                <div
                  key={index}
                  className="flex-col-center"
                  style={{
                    width: 56 * scale,
                    height: style.height * scale,
                    marginBottom: 24 * scale,
                  }}
                >
                  <div
                    key={index}
                    className="flex-col-center w-[56px] rounded-[6px] border-solid border-[1px]"
                    style={{
                      transform: `scale(${scale})`,
                      borderColor: style.borderColor,
                      backgroundColor: style.color,
                      height: style.height,
                    }}
                  >
                    <p className="text text-white !text-[14px] !leading-[14px]">
                      のこり
                    </p>
                    <p className="text text-white !text-[20px] !leading-[20px]">
                      {toolboxRef.current.maxInstances[content.type]}
                    </p>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
