const ToolboxInfo = {
  maxInstances: {
    program_button_click_event_block: 1,
    image_setter_block: 1,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "program_button_click_event_block",
          },
          {
            kind: "block",
            type: "image_setter_block",
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
