import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { IconTrash } from "common/assets";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-display-delete-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 10 * scale,
        y: rect.y - 20 * scale,
        width: rect.width + 20 * scale,
        height: rect.width + 20 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={11} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "アプリをさくじょ\nしたいときは、\nタブレットの"
              : "アプリを削除\nしたいときは、\nタブレットの"}
          </p>
          <div className="flex-row-el flex-center gap-2 upsilon">
            <div className="btn btn-white !rounded-[8px] !px-3 !py-0 !m-0">
              <div className="flex-col-el flex-center">
                <div className="flex-col-center w-[40px] h-[40px]">
                  <IconTrash color="#FC6560" width={2} />
                </div>
                <div className="flex-col-center -mt-1">
                  <p className="text-body text-[20px] text-red/100">
                    {I18n.t("MSG_CREATOR_MENU_CONTEXT_DELETE")}
                  </p>
                </div>
              </div>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              {I18n.locale === "ja" ? " を おします。" : " を押します。"}
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Step;
