import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useDialog } from "app/hook";
import { parentsFirebase } from "app/Auth";
import { AppDispatch, RootState } from "app/store";
import { actions as appActions } from "app/configSlice";
import { DialogType, LocalStorageKey } from "app/types";
import { ConfirmMessageBox } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";
import { AppUser } from "features/user/types";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";
import { ParentsUserProfile } from "./ParentsUserProfile";
import { ParentsUserAccountLink } from "./ParentsUserAccountLink";

export const ParentsUserAccount = (props: RouteProps) => {
  const auth = getAuth();
  const { navigate } = props;
  const play = usePlaySound();
  const handleDialog = useDialog();
  const handleGoBack = () => navigate("/");
  const dispatch = useDispatch<AppDispatch>();
  const parentsAuth = getAuth(parentsFirebase);
  const [signOut, setSignOut] = useState(false);
  const appUser = useSelector((state: RootState) => state.user.appUser);

  const handleSendSignInLinkToEmail = () => {
    const actionCodeSettings = {
      url: import.meta.env.VITE_PUBLIC_URL,
      handleCodeInApp: false,
    };
    handleDialog({ type: DialogType.LOADING, value: true });
    sendEmailVerification(parentsAuth.currentUser, actionCodeSettings)
      .then(() =>
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_SIGN_IN_FORGOT_PASSWORD_SUCCESS_CONTENT")}
            </p>
          </div>
        )
      )
      .catch((error) => {
        console.error(error);
      })
      .finally(() => handleDialog({ type: DialogType.LOADING, value: false }));
  };

  const handleOpenSignOut = () => {
    play();
    btnDelay(() => setSignOut(true));
  };
  const handleSignOut = async () => {
    play();
    dispatch(appActions.updateResign(true));
    btnDelay(async () => {
      try {
        await parentsAuth.signOut();
        const device_kids_main_user_str = localStorage.getItem(
          LocalStorageKey.DEVICE_KIDS_MAIN_USER
        );
        if (device_kids_main_user_str) {
          try {
            const device_kids_main_user: AppUser = JSON.parse(
              device_kids_main_user_str
            );
            localStorage.removeItem(LocalStorageKey.DEVICE_KIDS_MAIN_USER);
            await signInWithEmailAndPassword(
              auth,
              device_kids_main_user.email,
              "000000"
            );
          } catch (error) {
            await auth.signOut();
          }
        } else {
          await auth.signOut();
        }
        handleDialog({ type: DialogType.PARENT_GATE, value: false });
      } catch (error) {
        console.error(error);
        dispatch(appActions.updateResign(false));
        handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
      }
    });
  };

  const handleCloseDialog = () => {
    play();
    btnDelay(() => {
      setSignOut(false);
    });
  };

  return (
    <div className="flex-col-center w-full h-full py-4 pb-0">
      {signOut && (
        <ConfirmMessageBox
          title={I18n.t("MSG_SETTING_PARENTS_USER_SIGN_OUT_TITLE")}
          message={I18n.t("MSG_SETTING_PARENTS_USER_SIGN_OUT_MESSAGE")}
          close={handleCloseDialog}
          confirm={handleSignOut}
          is_parents
        />
      )}

      <div className="flex-col-el w-full flex-1 overflow-hidden gap-10">
        <div className="flex-col-el items-center justify-start visible-scrollbar">
          <div className="flex-col-el gap-8">
            <ParentsUserProfile user={appUser.main} />

            <div className="flex-row-el gap-2">
              <div className="flex-row-el w-[250px]">
                <p className="text-body text-left text-textcolor/black text-font-size-body2">
                  {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_EMAIL")}
                </p>
              </div>

              <div className="flex-row-el w-[180px]">
                <p className="text-body text-left text-textcolor/black text-font-size-body2">
                  {appUser.main.email}
                </p>
              </div>
            </div>

            <div className="flex-row-el gap-2">
              <div className="flex-row-el items-center w-[250px]">
                <p className="text-body text-left text-textcolor/black text-font-size-body2">
                  {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_EMAIL_VERIFIED")}
                </p>
              </div>

              {appUser.main.provider !== "password" ||
              parentsAuth.currentUser?.emailVerified ? (
                <div className="flex-row-el w-[180px]">
                  <p className="text-body text-left text-textcolor/black text-font-size-body2">
                    {I18n.t(
                      "MSG_SETTING_PARENTS_USER_PROFILE_EMAIL_VERIFIED_DONE"
                    )}
                  </p>
                </div>
              ) : (
                <div className="flex-col-el flex-center relative upsilon w-[280px]">
                  <button
                    onClick={handleSendSignInLinkToEmail}
                    className="btn btn-primary w-full"
                  >
                    <p className="text-body text-[24px] text-white !mb-[3px]">
                      {I18n.t(
                        "MSG_SETTING_PARENTS_USER_PROFILE_EMAIL_VERIFIED_NOT_YET"
                      )}
                    </p>
                  </button>
                </div>
              )}
            </div>

            {appUser.main.provider === "password" && (
              <div className="flex-row-el gap-2">
                <div className="flex-row-el items-center w-[250px]">
                  <p className="text-body text-left text-textcolor/black text-font-size-body2 whitespace-pre">
                    {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_ACCOUNT")}
                  </p>
                </div>

                <ParentsUserAccountLink />
              </div>
            )}

            <div className="flex-col-el w-full gap-8 items-center">
              <div className="flex-row-el gap-2 w-full">
                <div className="flex-row-el w-[250px]">
                  <p className="text-body text-left text-textcolor/black text-font-size-body2">
                    {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_CREATED_AT")}
                  </p>
                </div>

                <p className="text-body text-left text-textcolor/black text-font-size-body2">
                  {dayjs(appUser.main.created_at).format("YYYY-MM-DD")}
                </p>
              </div>

              <div className="flex-col-el gap-10 w-[50%]">
                {appUser.main.provider === "password" && (
                  <div className="flex-row-center w-full upsilon">
                    <button
                      onClick={() => navigate("/changePassword")}
                      className="btn btn-blue w-full"
                    >
                      <p className="text-body text-[24px] text-white !mb-[3px]">
                        {I18n.t(
                          "MSG_SETTING_PARENTS_USER_PROFILE_GOTO_CHANGE_PASSWORD_BTN"
                        )}
                      </p>
                    </button>
                  </div>
                )}

                <div className="flex-row-center w-full upsilon">
                  <button
                    onClick={handleOpenSignOut}
                    className="btn btn-danger w-full"
                  >
                    <p className="text-body text-[24px] text-white !mb-[3px]">
                      {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_SIGN_OUT_BTN")}
                    </p>
                  </button>
                </div>

                <div className="flex-col-el justify-end items-center w-full h-[400px] py-20 upsilon">
                  <button
                    className="btn btn-danger w-full"
                    onClick={() => navigate("/parentsUserUnsubscribe")}
                  >
                    <p className="text-body text-[24px] text-white !mb-[3px]">
                      {I18n.t(
                        "MSG_SETTING_PARENTS_USER_PROFILE_UNSUBSCRIBE_BTN"
                      )}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row-center my-2">
        <GoBackBtn handleGoBack={handleGoBack} />
      </div>
    </div>
  );
};
