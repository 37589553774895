import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useScale } from "common/utils";
import { IconRotate } from "common/assets";
import { ActionButton } from "common/elements/button";
import { CloseBtn, FadeTransition } from "common/elements";

export const RestoreDialogMessage = ({
  name,
  visible,
  confirm,
  close,
}: {
  name: string;
  visible: boolean;
  confirm: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (visible) {
      setDisable(false);
    }
  }, [visible]);

  const handleConfirm = () => {
    if (disable) return;
    setDisable(true);
    confirm();
  };

  return (
    <Dialog
      maxWidth={false}
      open={visible}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{
          filter:
            "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
        }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            width: 548 * scale,
            height: 330 * scale,
          }}
        >
          <div
            className="flex-col-el flex-center relative"
            style={{ transform: `scale(${scale})` }}
          >
            <div className="flex-col-el justify-start w-[548px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
              <CloseBtn close={close} />

              <div className="flex-col-view justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
                <div className="flex-row-view items-center justify-start py-4">
                  <div className="flex-col-center mr-2">
                    <InfoOutlinedIcon sx={{ fontSize: 40, color: "#4C9DF1" }} />
                  </div>
                  <p className="text-body text-textcolor/black text-[28px]">
                    {I18n.t("MSG_CREATOR_APP_RESTORE_CONFIRM_TITLE")}
                  </p>
                </div>
                <div className="flex-col-view items-start py-10">
                  <div className="flex-row-view flex-wrap">
                    <p className="text-body text-textcolor/black leading-[30px] text-[20px] flex-1">
                      {I18n.t("MSG_CREATOR_APP_RESTORE_CONFIRM_SUBTITLE", {
                        name,
                      })}
                    </p>
                  </div>
                </div>

                <div className="flex-col-center gap-4">
                  <ActionButton
                    type="primary"
                    text={I18n.t("MSG_CREATOR_APP_RESTORE_BTN")}
                    Icon={<IconRotate color="white" width={2} />}
                    onClick={handleConfirm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
