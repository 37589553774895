import { SVGProps } from "react";

export const Icon1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={130}
    height={82}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.285 35.062c.508-.746 1.156-1.416 1.86-2.003l.083-.064c.374-.29.666-.515.974-.705l.025-.015.024-.016a11.1 11.1 0 0 1 1.22-.723c3.454-1.633 6.388-1.735 8.219-1.48l.076.014-.331.154c-.619.291-1.224.632-1.89 1.054l-.015.009-.014.01c-.302.2-.683.464-1.039.748-.324.26-.752.633-1.038 1.06l-.03.044-.026.047-.204.357-.283.476-.055.093-.042.1c-.162.392-.332.883-.37 1.429-.085 1.096.323 2.027.968 2.873.684.922 1.83 1.796 3.211 2.545 1.416.767 3.186 1.46 5.227 1.96 4.024 1.004 9.01 1.295 13.833.388 4.758-.86 9.257-2.908 12.864-6.02a25.34 25.34 0 0 0 2.452-2.429l.007-.008.008-.008.11-.126c.305-.35.592-.68.854-1.074.293-.416.528-.84.727-1.247.87-1.738 1.174-3.73.957-5.721-.403-3.89-2.807-7.77-6.496-10.873-3.61-3.035-8.37-5.323-13.474-6.895h-.002C69.576 7.454 64.028 6.544 58.543 6.04h-.012c-1.287-.11-2.893-.242-4.016-.284a48.3 48.3 0 0 0-3.653-.02c-2.483.062-4.992.318-7.398.661h-.002c-4.166.6-8.115 1.538-11.707 2.618v-.043l-1.97.65c-8.59 2.838-14.848 6.364-19.28 9.016l-.722.434c-1.987 1.192-3.565 2.14-4.863 2.787-1.212.605-1.947.822-2.385.855a2.08 2.08 0 0 1 .011-.047c.193-.745.928-2.074 2.635-3.938 3.12-3.394 9.444-8.064 19.473-11.881a85.801 85.801 0 0 1 16.33-4.313c3.006-.48 6.154-.85 9.479-.977h.005a67.476 67.476 0 0 1 5.067-.02h.002c1.794.059 3.137.156 4.79.285 6.23.502 12.782 1.494 19.115 3.358h.001c6.356 1.865 12.565 4.707 17.35 8.505h.002c4.975 3.937 8.211 8.703 9.062 13.607.427 2.568.223 5.2-.862 7.752l-.004.01-.005.01a12.762 12.762 0 0 1-1.004 1.933l-.006.01-.007.011c-.368.614-.876 1.257-1.322 1.8l-.001.001a30.838 30.838 0 0 1-3.138 3.314l-.01.009c-4.807 4.434-12.143 7.96-20.14 9.185l-.01.001c-8.264 1.324-16.266.39-22.442-1.83-6.21-2.246-10.436-5.586-11.539-9.395-.485-1.701-.124-3.555.907-5.025l.006-.008.005-.009Zm13.802-4.546-.004-.003a.033.033 0 0 1 .004.003Z"
      fill="#94C4F7"
      stroke="#667786"
      strokeWidth={3}
    />
    <path
      d="m91.136 65.247.007-.002c5.927-1.63 11.072-3.696 15.313-5.863 8.039-4.082 12.595-8.43 15.561-11.66.605-.659 1.116-1.238 1.566-1.747.692-.784 1.238-1.403 1.759-1.896.632-.597.929-.707 1.063-.72l.123.025c.004 0 .024.005.061.028a.804.804 0 0 1 .17.15c.148.168.33.467.479.946.298.962.408 2.493-.013 4.594l-.001.003c-.846 4.263-4.577 10.831-13.22 17.006l-.006.004c-4.561 3.289-10.399 6.481-17.57 9.135-7.435 2.709-16.434 5.047-26.677 5.213h-.012c-9.762.237-20.327-.632-30.474-3.66l-.005-.001c-10.039-2.963-19.025-8.13-24.313-15.196l-.003-.004c-4.981-6.616-6.196-14.16-3.78-21.17 1.157-3.334 3.09-6.57 5.949-9.52l.01-.01c1.355-1.424 2.982-2.782 4.784-4.039a64.514 64.514 0 0 1 4.929-3.06l.008-.004c6.51-3.68 14.447-6.212 21.9-6.899h.01c7.256-.718 13.94.11 19.096 1.747 5.266 1.686 9.219 4.205 10.857 7.209.762 1.408.794 2.933.245 4.135l-.006.012-.005.011a6.007 6.007 0 0 1-1.22 1.784l-.088.085c-.303.293-.52.503-.76.687l-.013.01-.013.01c-.36.288-.687.538-.993.712l-.007.005-.008.004c-1.587.927-2.982 1.31-4.43 1.569-1.4.234-2.65.226-3.524.087a8.02 8.02 0 0 1-.379-.071c.158-.075.328-.156.498-.24l.01-.006c.58-.294 1.174-.633 1.82-1.07l.007-.004c.299-.204.663-.47.997-.759.288-.248.695-.633.95-1.09.132-.224.275-.538.378-.763l.077-.168.022-.047.019-.048c.21-.542.252-1.042.252-1.43v-.053l-.004-.052c-.07-.997-.512-1.817-1.22-2.6-1.372-1.538-4.361-3.076-8.064-3.93-3.746-.89-8.389-1.167-13.06-.44-4.788.728-9.718 2.535-14.246 5.487a41.69 41.69 0 0 0-3.408 2.453l-.009.007-.009.007c-.896.734-1.776 1.572-2.538 2.529a15.972 15.972 0 0 0-3.195 6.56c-1.158 5.049.285 10.52 4.221 15.122 4.042 4.764 10.348 8.227 17.488 10.376 7.201 2.191 15.105 3.077 22.729 3.209 6.987.203 13.82-1.057 19.915-2.7Zm-24.77-30.773s.002 0 .003.003a.024.024 0 0 1-.002-.003Z"
      fill="#94C4F7"
      stroke="#667786"
      strokeWidth={3}
    />
  </svg>
);
