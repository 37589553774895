import dayjs from "dayjs";

export const MessageDate = (props: { timestamp: string }) => {
  const { timestamp } = props;
  return (
    <div className="w-full flex flex-row items-center justify-center">
      <p className="text text-gray/100 bg-gray/20 px-3 py-1 rounded-[12px]">
        {dayjs(timestamp, "YYYY-MM-DDTHH:mm:ssZ").format("MM/DD")}
      </p>
    </div>
  );
};
