import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { DisplayMode } from "features/creator/types";
import { PickBtnBg, PickBtnClickedBg } from "features/creator/assets";

export const PickUpBtn = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [click, setClick] = useState(false);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );

  const handleClickDown = () => {
    play();
    setClick(true);
  };
  const handleClickUp = () => {
    setClick(false);
    if (displayMode === DisplayMode.PICKUP) {
      dispatch(actions.updateRecommendReturnToHome(true));
    } else {
      anime({
        targets: document.getElementsByClassName(
          `app-project-card-${selectedProjectId}`
        ),
        opacity: [0, 1],
        duration: 800,
        easing: "easeInOutQuad",
      });
      dispatch(actions.updateDisplayMode(DisplayMode.PICKUP));
    }
  };

  return (
    <div
      id="creator-pickup-btn"
      onPointerDown={handleClickDown}
      onPointerUp={handleClickUp}
      style={{
        transform: `translateY(${click ? 2 : 0}px)`,
      }}
      className="flex-col-el flex-center relative cursor-pointer"
    >
      <div className="-z-[1] w-[191px] h-[199px]">
        {click ? <PickBtnClickedBg /> : <PickBtnBg />}
      </div>

      <div className="flex-col-el flex-center w-[204px] h-[155px] absolute -top-4 ml-4">
        <img
          alt="pick up"
          draggable="false"
          src={`${Constants.assetHost}/assets/images/pickup_card.png`}
          className="absolute inset-0 object-cover w-full h-full z-[-1]"
        />
      </div>

      <div className="flex-col-el flex-center absolute bottom-3">
        <img
          alt="pick up title"
          draggable="false"
          src={`${Constants.assetHost}/assets/images/title_text_pickup.png`}
          className="w-[222px] h-[62px]"
        />
      </div>

      <div className="flex-col-el flex-center absolute bottom-14">
        <img
          alt="*"
          draggable="false"
          src={`${Constants.assetHost}/assets/images/overview_character.png`}
          className="w-[116px] h-[116px] -scale-x-100"
        />
      </div>
    </div>
  );
};
