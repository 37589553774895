import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import Constants from "common/constant";
import { Loading } from "common/elements";
import { actions } from "./slice";
import { Builder, MapPreview } from "./canvas";

export const BuilderMain = () => {
  const dispatch = useDispatch();
  const preview = useSelector(
    (state: RootState) => state.builder.actionMenu.preview
  );
  const loading = useSelector((state: RootState) => state.builder.loading);

  useEffect(() => {
    dispatch(actions.initial());
  }, []);

  return (
    <div className="flex-col-center w-full h-full bg-transparent">
      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_scene.jpg`}
        className="absolute top-0 left-0 w-full h-full"
      />
      {loading && <Loading />}
      {preview ? <MapPreview /> : <Builder />}
    </div>
  );
};
