import * as React from "react";

export const MaterialTextInputSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={75}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.922 2h69.156c.032 0 .112.01.215.127.107.12.207.33.207.608v34.53c0 .278-.1.487-.207.608-.103.117-.183.127-.215.127H2.922c-.032 0-.112-.01-.215-.127a.918.918 0 0 1-.207-.608V2.735c0-.278.1-.487.207-.608C2.81 2.01 2.89 2 2.922 2Z"
        fill="#FEFDFE"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeMiterlimit={10}
      />
      <path
        d="M13.84 29.84a4.672 4.672 0 0 1-5-5 7.14 7.14 0 0 1 1.6-4.58 9.77 9.77 0 0 1 4.49-3.07.28.28 0 0 0 .18-.28v-2.56a.21.21 0 0 0-.23-.24h-3.54a1.34 1.34 0 0 1-1.297-.832 1.36 1.36 0 0 1 .297-1.488 1.31 1.31 0 0 1 1-.4h3.55a.211.211 0 0 0 .219-.138.21.21 0 0 0 .011-.092V9.93a1.43 1.43 0 0 1 .42-1 1.39 1.39 0 0 1 1-.44h.3a1.5 1.5 0 0 1 1.46 1.47v1.23c0 .15.07.23.2.23h9.3a1.31 1.31 0 0 1 1 .4 1.36 1.36 0 0 1-1 2.32h-9.3c-.13 0-.2.08-.2.24v1.87c0 .15.07.21.2.18a19.897 19.897 0 0 1 2.55-.13c2.94 0 5.217.643 6.83 1.93a6.36 6.36 0 0 1 2.41 5.26 6.83 6.83 0 0 1-1.6 4.52 9.23 9.23 0 0 1-4.46 2.83 1.278 1.278 0 0 1-1.09-.14 1.61 1.61 0 0 1-.71-.91l-.05-.11a1.25 1.25 0 0 1 .12-1.06 1.57 1.57 0 0 1 .86-.74 6.54 6.54 0 0 0 2.7-1.82 3.89 3.89 0 0 0 1-2.57 3.64 3.64 0 0 0-1.69-3.31c-.11-.05-.19 0-.26.1a17.12 17.12 0 0 1-4.87 7.09 9.82 9.82 0 0 1-6.4 2.47Zm1.1-9.34c-1.88 1.04-2.82 2.373-2.82 4a2.65 2.65 0 0 0 .53 1.77 1.92 1.92 0 0 0 1.52.62c.242.006.483-.014.72-.06a.26.26 0 0 0 .23-.28v-6a.11.11 0 0 0-.06-.11.11.11 0 0 0-.12.06Zm6.09-1.37c-.844-.001-1.687.07-2.52.21a.25.25 0 0 0-.23.26v5.18s0 .07.08.09c.08.02.08 0 .1 0a15.689 15.689 0 0 0 3.65-5.44.26.26 0 0 0 0-.17.11.11 0 0 0-.11-.09c-.23-.03-.54-.04-.97-.04ZM38.56 21.05h-4.14a1.25 1.25 0 1 1 0-2.49h4.14a1.248 1.248 0 0 1 1.256 1.75 1.25 1.25 0 0 1-1.256.74Z"
        fill="#E6CCA1"
      />
      <path
        d="M33.39 29.66a1.248 1.248 0 0 1-.955-2.168 1.25 1.25 0 0 1 .955-.322 1.921 1.921 0 0 0 1.84-2V14.43a1.919 1.919 0 0 0-1.84-2 1.25 1.25 0 1 1 0-2.49 4.42 4.42 0 0 1 4.35 4.48v10.75a4.421 4.421 0 0 1-4.35 4.49Z"
        fill="#E6CCA1"
      />
      <path
        d="M39.59 29.66a4.43 4.43 0 0 1-4.36-4.48V14.43a4.43 4.43 0 0 1 4.36-4.48 1.25 1.25 0 1 1 0 2.49 1.918 1.918 0 0 0-1.85 2v10.75a1.919 1.919 0 0 0 1.85 2 1.248 1.248 0 0 1 .955 2.168 1.247 1.247 0 0 1-.955.322v-.02Z"
        fill="#E6CCA1"
      />
    </svg>
  );
};
