/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new-func */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Blockly from "blockly";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { sleep, useScale, btnDelay, usePlaySound } from "common/utils";
import { actions } from "features/builder/slice";
import { useGameSound } from "features/courses/hook";
import { ResetBtnIcon } from "features/courses/assets";
import {
  Score,
  Failed,
  Success,
  StartBtn,
  ResetBtn,
} from "features/courses/algorithm/game/scene/components";
import * as action from "./animations";
import { GameScene } from "./GameScene";
import { ReturnBtn } from "./ReturnBtn";

export const MainScene = ({
  reload,
  setReload,
  scene,
  gameStart,
  setGameStart,
  setScene,
  event,
  workspace,
  flyoutWidth,
  blocklyTopOffset,
}: {
  reload: boolean;
  setReload: (reload: boolean) => void;
  scene: Scene;
  gameStart: boolean;
  setGameStart: (gameStart: boolean) => void;
  setScene: (scene: Scene) => void;
  event: string;
  workspace: Blockly.WorkspaceSvg;
  flyoutWidth: number;
  blocklyTopOffset: number;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const gameSound = useGameSound();
  const [totalGem, setTotalGem] = useState(0);
  const [gemScore, setGemScore] = useState(0);
  const [success, setSuccess] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [character, setCharacter] = useState<AbstractMesh | null>(null);

  useEffect(() => {
    if (gameOver) {
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(true);
        block.setEditable(true);
      });
      if (success) {
        setSuccessShow(true);
        gameSound.levelclearSound();
        gameSound.levelclearCheersSound();
      } else {
        gameSound.failureSound();
      }
    }
  }, [gameOver]);

  useEffect(() => {
    gameSound.itemGetSound();
  }, [gemScore]);

  const handlePlusGemScore = (totalGem: number) => {
    setGemScore((prevScore) => {
      const newScore = prevScore + 1;
      if (newScore === totalGem) {
        setSuccess(true);
      }
      return newScore;
    });
  };

  const highlightBlock = (id: string, opt_state: boolean) => {
    if (!scene.isDisposed) {
      if (!workspace.getBlockById(id)) {
        const xml = Blockly.Xml.workspaceToDom(workspace);
        const xml_text = Blockly.Xml.domToText(xml);
        console.error(`failed to get block id: ${id}, xml_text: ${xml_text}`);
      } else {
        workspace.getBlockById(id).setHighlighted(opt_state);
      }
    }
  };

  const util = {
    sleep: sleep,
    setSuccess: setSuccess,
    setGameOver: setGameOver,
    highlightBlock: highlightBlock,
    action: {
      checkBtnColor: () => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        action.checkBtnColor(scene, character);
      },
      performMove: async (id: string) => {
        highlightBlock(id, true);
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed) {
          gameSound.moveSound();
          await action.performMove(scene, character, setSuccess, setGameOver);
        }
        highlightBlock(id, false);
      },
      performJump: async (id: string) => {
        highlightBlock(id, true);
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed) {
          gameSound.jumpSound();
          await action.performJump(scene, character, setSuccess, setGameOver);
        }
        highlightBlock(id, false);
      },
      performTurnToLeft: async (id: string) => {
        highlightBlock(id, true);
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed) {
          gameSound.turnSound();
          await action.performTurnToLeft(scene, character);
        }
        highlightBlock(id, false);
      },
      performTurnToRight: async (id: string) => {
        highlightBlock(id, true);
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed) {
          gameSound.turnSound();
          await action.performTurnToRight(scene, character);
        }
        highlightBlock(id, false);
      },
    },
  };

  const handleStart = () => {
    btnDelay(() => {
      gameSound.startSound();
      setGameStart(true);
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(false);
        block.setEditable(false);
      });

      try {
        const handleEvent = new Function(
          "util",
          "scene",
          event
            ? event
            : `return () => {
                util.setGameOver(true);
              };`
        );
        handleEvent(util, scene)();
      } catch (error) {
        console.error(error);
      }
    });
  };

  const handleReset = () => {
    btnDelay(() => {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGemScore(0);
      setSuccess(false);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setSuccessShow(false);
      dispatch(actions.updateLoading(true));
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setHighlighted(false);
        block.setMovable(true);
        block.setEditable(true);
      });
    });
  };

  const handleClearBlock = () => {
    play();
    if (gameStart) {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGemScore(0);
      setSuccess(false);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setSuccessShow(false);
      dispatch(actions.updateLoading(true));
    }
    workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
      if (block.type === "character_event") {
        block.setHighlighted(false);
        block.moveTo(
          new Blockly.utils.Coordinate(
            flyoutWidth / scale + 20,
            blocklyTopOffset
          )
        );
      } else {
        block.dispose();
      }
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      setSuccessShow(false);
    });
  };

  const handleReturnToBuilder = () => {
    play();
    dispatch(actions.updateLoading(true));
    btnDelay(() => {
      dispatch(actions.updateActionMenu({ key: "preview", value: false }));
    });
  };

  return (
    <>
      {gameOver && !success && <Failed scale={scale} reset={handleReset} />}

      {successShow && (
        <Success scale={scale} back={handleBack} reset={handleReset} />
      )}

      <div className="flex-col-view items-center w-full h-full pointer-events-none">
        <div className="flex-col-center w-full h-full pointer-events-none">
          <GameScene
            reload={reload}
            config={{
              setScene,
              setCharacter,
              setTotalGem,
              handlePlusGemScore,
            }}
          />

          {/* てにいれたアイテム */}
          <div
            className="flex-row-center !items-start w-full !absolute top-0"
            style={{
              height: 56 * scale,
              left: -(flyoutWidth + 10 * scale) / 2,
            }}
          >
            <div
              className="flex-row-view h-[56px] origin-top"
              style={{ transform: `scale(${scale})` }}
            >
              <Score totalGem={totalGem} gemScore={gemScore} />
            </div>
          </div>
        </div>

        {!gameStart && (
          <div
            className="flex-col-center !absolute z-50 origin-bottom-left pointer-events-auto"
            style={{
              bottom: 24 * scale,
              left: -flyoutWidth - 88 * scale,
              transform: `scale(${scale})`,
            }}
          >
            <button
              className="opacity-button pointer-events-auto bg-gray/80"
              onClick={handleClearBlock}
            >
              <div className="flex-col-center w-[48px] h-[48px]">
                <ResetBtnIcon />
              </div>
            </button>
          </div>
        )}

        {!gameStart ? (
          <div className="flex-row-el gap-3">
            <ReturnBtn scale={scale} click={handleReturnToBuilder} />
            <StartBtn
              scale={scale}
              disabled={
                workspace.getBlocksByType("character_event", false).length <
                  1 ||
                workspace
                  .getBlocksByType("character_event", false)
                  .shift()
                  .getChildren(false).length < 1
              }
              start={handleStart}
            />
          </div>
        ) : (
          <ResetBtn scale={scale} reset={handleReset} />
        )}
      </div>
    </>
  );
};
