/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import { View, StyleSheet, PanResponder, TouchableOpacity } from "react-native";
import I18n from "i18n-js";
import { PromptInputNumber } from "common/elements";
import style from "./rotate.module.scss";
import { IconCirclePlus, IconCircleMinus, AnimationContainer } from ".";

export const InputRotate = (props: {
  name: string;
  show: boolean;
  play: () => void;
  scale: number;
  max: number;
  value: number;
  onChange?: (value: number) => void;
}) => {
  const ref = useRef(null);
  const rotateRef = useRef<HTMLDivElement>();
  const [prompt, setPrompt] = useState(false);
  const [center, setCenter] = useState({ x: 0, y: 0 });
  const { name, show, play, scale, max, value, onChange } = props;

  const RADIUS = Number(style.RADIUS);
  const ROTATE_RECT_WIDTH = Number(style.ROTATE_RECT_WIDTH);
  const ROTATE_RECT_HEIGHT = Number(style.ROTATE_RECT_HEIGHT);
  const CIRCLE_BORDER_SIZE = Number(style.CIRCLE_BORDER_SIZE);
  const ROTATE_BTN_SIZE = Number(style.ROTATE_INNER_BTN_SIZE) + 24;

  useEffect(() => {
    if (rotateRef.current) {
      const rect = rotateRef.current.getBoundingClientRect();
      setCenter({
        x: rect.x + (rect.width * scale) / 2,
        y: rect.y + (rect.height * scale) / 2,
      });
    }
  }, [show]);

  const handlePlusLongPress = () => {
    if (ref.current) {
      play();
      ref.current.handlePlusLongPress();
    }
  };

  const handleMinusLongPress = () => {
    if (ref.current) {
      play();
      ref.current.handleMinusLongPress();
    }
  };

  const handleLongPressOut = () => {
    if (ref.current) {
      ref.current.handleLongPressOut();
    }
  };

  const handlePlus = (step: number) => {
    if (ref.current) {
      play();
      ref.current.handlePlus(step);
    }
  };

  const handleMinus = (step: number) => {
    if (ref.current) {
      play();
      ref.current.handleMinus(step);
    }
  };

  const clickPanResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onPanResponderTerminationRequest: () => false,
    onPanResponderRelease: (e, g) => {
      play();
      const radius = Math.atan2(
        center.y - e.nativeEvent.pageY,
        e.nativeEvent.pageX - center.x
      );
      onChange(Math.floor(((-radius * 180) / Math.PI + 90) * 100) / 100);
    },
  });

  const rotatePanResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onPanResponderTerminationRequest: () => false,
    onPanResponderStart: () => play(),
    onPanResponderMove: (e, g) => {
      const radius = Math.atan2(
        center.y - e.nativeEvent.pageY,
        e.nativeEvent.pageX - center.x
      );
      onChange(Math.floor(((-radius * 180) / Math.PI + 90) * 100) / 100);
    },
    onPanResponderRelease: () => play(),
  });

  const handleShowPromptInput = () => {
    play();
    setPrompt(true);
  };

  const handleInputValueChange = (inputValue: number) => {
    play();
    if (inputValue < 0) {
      onChange(0);
    } else if (inputValue > max) {
      onChange(max);
    } else {
      onChange(inputValue);
    }
    setPrompt(false);
  };

  const handleCloseInput = () => {
    play();
    setPrompt(false);
  };

  const styles = StyleSheet.create({
    btn: {
      marginHorizontal: 18,
      width: 54,
      height: 24,
    },
    circle: {
      zIndex: 1,
      cursor: "pointer",
      width: RADIUS * 2,
      height: RADIUS * 2,
    },
    rotateBtn: {
      zIndex: 3,
      width: ROTATE_BTN_SIZE,
      height: ROTATE_BTN_SIZE,
      cursor: "grab",
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      transform: [
        { translateX: RADIUS - ROTATE_BTN_SIZE / 2 - CIRCLE_BORDER_SIZE / 2 },
        { translateY: -ROTATE_BTN_SIZE / 2 },
      ],
    },
  });

  return (
    <>
      {prompt && (
        <PromptInputNumber
          defaultValue={Math.floor(value)}
          message={I18n.t("MSG_CREATOR_MENU_BLOCKLY_PROMPT_INPUT_MESSAGE", {
            placeholder: name,
          })}
          confirm={handleInputValueChange}
          cancel={handleCloseInput}
        />
      )}

      <div className="flex-col-center">
        <div className="flex-row-view">
          <TouchableOpacity
            style={styles.btn}
            onPress={() => handleMinus(1)}
            onLongPress={handleMinusLongPress}
            onPressOut={handleLongPressOut}
          >
            <IconCircleMinus />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => handlePlus(1)}
            onLongPress={handlePlusLongPress}
            onPressOut={handleLongPressOut}
          >
            <IconCirclePlus />
          </TouchableOpacity>
        </div>

        <div className="flex-col-center mt-[10px] mb-[24px]">
          <AnimationContainer
            ref={ref}
            value={value}
            min={0}
            max={max}
            onChange={onChange}
          >
            <div>
              <div
                className={`${style["property-input-rotate__rotate-rect"]} absolute left-0 top-0`}
                style={{
                  transform: `translateX(${
                    RADIUS - ROTATE_RECT_WIDTH / 2
                  }px) translateY(${
                    RADIUS - ROTATE_RECT_HEIGHT / 2
                  }px) translateZ(0) rotate(${value}deg)`,
                }}
              />
              <View style={styles.circle} {...clickPanResponder.panHandlers}>
                <div className={style["property-input-rotate__zero-btn"]} />

                <div
                  ref={rotateRef}
                  className={
                    style["property-input-rotate__rotate-btn-container"]
                  }
                  style={{ transform: `rotate(${value - 90}deg)` }}
                >
                  <View
                    style={styles.rotateBtn}
                    {...rotatePanResponder.panHandlers}
                  >
                    <div
                      className={style["property-input-rotate__rotate-btn"]}
                    />
                  </View>
                </div>

                <div className="flex-col-center !absolute top-0 left-0 w-[114px] h-[114px] border-dashed border-green/60 border-[2px] rounded-full" />

                <div
                  className="flex-col-center !absolute top-0 left-0 w-[80px] h-[80px] bg-gray2/10 border-solid border-[4px] border-gray2/60 rounded-[8px] z-[-1]"
                  style={{
                    transform: `translateX(${RADIUS - 40}px) translateY(${
                      RADIUS - 40
                    }px) rotate(${value - 90}deg)`,
                  }}
                />

                <svg
                  display="block"
                  viewBox={`0 0 ${RADIUS * 2} ${RADIUS * 2}`}
                >
                  <path
                    d={`M ${RADIUS} ${RADIUS} V 0 A ${RADIUS} ${RADIUS} 0 ${
                      value > 180 ? 1 : 0
                    } 1 ${RADIUS * (1 + Math.sin((value * Math.PI) / 180))} ${
                      RADIUS * (1 - Math.cos((value * Math.PI) / 180))
                    } Z`}
                    opacity="50%"
                    fill="#DDF9EF"
                  />
                  <circle
                    cx={RADIUS}
                    cy={RADIUS}
                    r={RADIUS - CIRCLE_BORDER_SIZE / 2}
                    fill="none"
                    transform={`rotate(-90, ${RADIUS}, ${RADIUS})`}
                    stroke="#80EAC1"
                    strokeWidth={CIRCLE_BORDER_SIZE}
                    strokeDasharray={
                      (RADIUS - CIRCLE_BORDER_SIZE / 2) * 2 * Math.PI
                    }
                    strokeDashoffset={
                      (RADIUS - CIRCLE_BORDER_SIZE / 2) *
                      2 *
                      Math.PI *
                      (1 - value / max)
                    }
                  />
                </svg>
              </View>
            </div>
          </AnimationContainer>
        </div>

        <div className="flex-row-center w-full">
          <input
            type="text"
            className="property-input-value_input"
            readOnly
            value={Math.floor(value)}
            onClick={handleShowPromptInput}
          />
          <p className="text text-textcolor/black !text-[16px]">°</p>
        </div>
      </div>
    </>
  );
};
