import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { useQuery } from "@tanstack/react-query";
import { actions } from "app/configSlice";
import { useFetchUserData } from "app/hook";
import { LocalStorageKey } from "app/types";
import { RootState, AppDispatch } from "app/store";
import { ConfirmMessageBox } from "common/elements";
import { btnDelay, handleError, usePlaySound } from "common/utils";
import { AccountType } from "features/user/types";
import { getDeviceUsers, addKidsToParentsAccount } from "features/user/api";

export const DeviceUsers = ({
  uid,
  setMaxWarning,
}: {
  uid: string;
  setMaxWarning: (warning: boolean) => void;
}) => {
  const play = usePlaySound();
  const [add, setAdd] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { data } = useQuery({
    queryKey: ["user/getDeviceUsers", { uid: uid }],
    queryFn: () => getDeviceUsers({ uid: uid }),
    staleTime: Infinity,
    placeholderData: { users: [] },
  });
  const { handleFetchParentsUser } = useFetchUserData();
  const appUser = useSelector((state: RootState) => state.user.appUser);

  const handleOpenAdditionUser = () => {
    play();
    btnDelay(() => {
      setAdd(true);
    });
  };

  const handleAdditionUser = () => {
    play();
    dispatch(actions.updateResign(true));
    btnDelay(async () => {
      try {
        setAdd(false);
        await addKidsToParentsAccount({
          parents_uid: appUser.main.uid,
          kids_main_uid: uid,
        });
        localStorage.removeItem(LocalStorageKey.DEVICE_KIDS_MAIN_USER);
        if (
          appUser.users.filter((u) => u.account_type === AccountType.KIDS)
            .length > 3
        ) {
          setMaxWarning(true);
        }
        await handleFetchParentsUser({ uid: appUser.main.uid });
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_USER_PROFILE_ADD_USER_SUCCESS")}
            </p>
          </div>
        );
      } catch (error) {
        console.log(error);
        handleError(error, I18n.t("MSG_USER_PROFILE_ADD_USER_FAILED"), () =>
          dispatch(actions.updateResign(false))
        );
      }
    });
  };

  const handleCloseAdditionUser = () => {
    play();
    btnDelay(() => setAdd(false));
  };

  return (
    <div className="flex-col-center w-full h-full">
      {add && (
        <ConfirmMessageBox
          title={I18n.t("MSG_SETTING_PARENTS_ADD_KIDS_USER_TITLE")}
          message={I18n.t("MSG_SETTING_PARENTS_ADD_KIDS_USER_MESSAGE")}
          close={handleCloseAdditionUser}
          confirm={handleAdditionUser}
          is_parents
        />
      )}

      <div className="flex-col-el items-center justify-start gap-6 py-4">
        {data.users.map((user) => (
          <div
            key={user.uid}
            className="flex-row-el items-center justify-start"
          >
            <div
              className="flex-col-center bg-white rounded-full w-[80px] h-[80px]"
              style={{
                boxShadow:
                  "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
              }}
            >
              <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                <img
                  key={user.icon}
                  alt="ユーザプロフィール画像"
                  src={user.icon}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>

            <div className="flex-col-el w-[250px] px-4">
              <p className="text-body text-left text-textcolor/black text-font-size-body2-narrow w-full text-body-ellipsis">
                {user.display_name}
              </p>
              <p className="text-body text-left text-gray/80 text-font-caption2-narrow">
                {I18n.t("MSG_SETTING_PARENTS_USER_KIDS_PROFILE_CREATED_AT")}:
                {dayjs(user.created_at).format("YYYY-MM-DD")}
              </p>
              <p className="text-body text-left text-gray/80 text-font-caption2-narrow">
                {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_LATEST_AT")}:
                {dayjs(user.last_sign_in_time).format("YYYY-MM-DD")}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex-row-el flex-center w-full alpha">
        <button className="btn btn-primary" onClick={handleOpenAdditionUser}>
          <p className="text-body text-white text-[24px] !mb-[3px]">
            {I18n.t(
              "MSG_SETTING_PARENTS_USER_PROFILE_ADD_DEVICE_USER_TO_ACCOUNT"
            )}
          </p>
        </button>
      </div>
    </div>
  );
};
