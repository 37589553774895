import { SVGProps } from "react";

export const RotationSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path stroke="#70B1F4" d="M11.5 57V16" />
    <circle
      cx={12}
      cy={15}
      r={12}
      transform="rotate(-90 12 15)"
      fill="#94C4F7"
    />
    <path
      d="M8.05 19.516a6 6 0 1 0-1.624-6.736M6.05 9.03v3.75H9.8"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
