import { SVGProps } from "react";

export const IconProperty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 6h8M16 6h4M8 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 12h2M10 12h10M17 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 18h11M19 18h1"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
