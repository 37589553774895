import { PointerEvent } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { IconCopy } from "common/assets";
import { usePlaySound } from "common/utils";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType, ClipboardTargetType } from "features/creator/types";

export const TemplateCopyBtn = () => {
  const play = usePlaySound();
  const handleAction = useActionCommand();
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const grouping = useSelector((state: RootState) => state.creator.grouping);
  const disabled = selectedComponentId === selectedScreenId;

  const handleCopy = (e: PointerEvent) => {
    play();
    if (disabled) return;

    e.stopPropagation();
    if (grouping.enable) {
      handleAction({
        type: ActionCommandType.COPY,
        targetType: ClipboardTargetType.GROUPING,
      });
    } else if (selectedScreenId === selectedComponentId) {
      handleAction({
        type: ActionCommandType.COPY,
        targetType: ClipboardTargetType.SCREEN,
      });
    } else {
      handleAction({
        type: ActionCommandType.COPY,
        targetType: ClipboardTargetType.COMPONENT,
      });
    }
    toast(
      <div>
        <p className="text text-textcolor/black">{I18n.t("MSG_TOAST_COPY")}</p>
      </div>
    );
  };

  return (
    <button
      id="creator-copy-btn"
      className="opacity-button w-[64px] h-[56px] px-[12px] py-[8px] disabled:!bg-transparent"
      onPointerDown={handleCopy}
      disabled={disabled}
    >
      <div
        className={`flex-col-center w-[40px] h-[40px] rounded-[10px]  ${
          disabled ? "bg-gray2/20" : "bg-orange/100"
        }`}
      >
        <div className="flex-col-center w-[24px] h-[24px]">
          <IconCopy width={2} />
        </div>
      </div>
    </button>
  );
};
