/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import { useUserConfig } from "app/hook";
import { useScale, btnDelay, usePlaySound } from "common/utils";
import { FadeTransition, WarningMessageBox } from "common/elements";
import { ProjectType } from "features/creator/types";
import Stages from "features/creator/tutorial/top/stages";
import { selectAllProjects } from "features/creator/slice";
import { TutorialHint } from "features/creator/tutorial/top/TutorialHint";

export const CreatorTopTutorial = ({
  isTutorialApp,
  showTutorial,
  tutorialStage,
  setShowTutorial,
}: {
  isTutorialApp: boolean;
  showTutorial: boolean;
  tutorialStage: number;
  setShowTutorial: (show: boolean) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const [hint, setHint] = useState(false);
  const [show, setShow] = useState(false);
  const handleChangeUserConfig = useUserConfig();
  const [guidance, setGuidance] = useState(false);
  const projects = useSelector(selectAllProjects);
  const [step, setStep] = useState<number | null>(null);
  const [stage, setStage] = useState<number | null>(null);
  const tutorial = useSelector(
    (state: RootState) => state.config.userConfig.tutorial.creator_top
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.author_uid === user.active.uid &&
          !project.deleted_at &&
          project.type === ProjectType.BASIC
      ),
    [projects, user]
  );

  useEffect(() => {
    if (isTutorialApp) {
      setStage(4);
      setStep(20);
      setShow(true);
    }
  }, [isTutorialApp]);

  useEffect(() => {
    if (tutorial) {
      setShow(true);
    }
  }, [tutorial]);

  useEffect(() => {
    if (showTutorial) {
      if (
        (tutorialStage === 1 || tutorialStage === 3 || tutorialStage === 4) &&
        userProjects.length === 5
      ) {
        setGuidance(true);
      } else {
        setStep(0);
        setStage(tutorialStage);
        setShow(true);
      }
    }
  }, [showTutorial]);

  useEffect(() => {
    if (
      (stage === 1 || stage === 3 || stage === 4) &&
      userProjects.length === 5
    ) {
      setShow(false);
      setGuidance(true);
    }
  }, [stage]);

  const handleBackFromHint = () => {
    play();
    btnDelay(() => {
      setHint(false);
      setShow(true);
    });
  };
  const handleCloseHint = () => {
    play();
    btnDelay(() => {
      setHint(false);
      setStep(null);
      setStage(null);
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      setStep(step - 1);
    });
  };

  const handleNext = () => {
    play();
    btnDelay(() => {
      if (step === Stages[stage].length - 1) {
        if (stage === Stages.length - 1) {
          setStep(null);
          setStage(null);
          setShow(false);
          handleChangeUserConfig("tutorial.creator_top", false, false);
        } else {
          setStep(0);
          setStage(stage + 1);
        }
      } else {
        setStep(step + 1);
      }
    });
  };

  const handleClose = (nohint?: boolean) => {
    play();
    setHint(!nohint);
    setShow(false);
    handleChangeUserConfig("tutorial.creator_top", false, false);
  };

  const handleExited = () => {
    if (showTutorial) {
      setShowTutorial(false);
      return;
    }
    setHint(true);
  };

  const handleCloseGuidance = () => {
    play();
    setGuidance(false);
    setShowTutorial(false);
  };

  if (guidance)
    return (
      <WarningMessageBox
        title={I18n.t("MSG_CREATOR_TOP_TUTORIAL_APP_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_TOP_TUTORIAL_APP_LIMIT_WARNING_MESSAGE")}
        close={handleCloseGuidance}
      />
    );

  if (stage === null || step === null) return <></>;

  const Contents = Stages[stage][step];
  return (
    <>
      {hint && (
        <TutorialHint back={handleBackFromHint} close={handleCloseHint} />
      )}

      <Dialog
        id="tutorial-dialog"
        fullScreen
        open={show}
        maxWidth={false}
        sx={{ zIndex: 10000 }}
        TransitionComponent={FadeTransition}
        componentsProps={{
          backdrop: {
            style: { backgroundColor: "transparent", pointerEvents: "none" },
          },
        }}
        PaperProps={{
          style: {
            overflow: "visible",
            margin: 0,
            maxWidth: "none",
            maxHeight: "none",
            transform: `scale(${scale})`,
            backgroundColor: "transparent",
          },
          elevation: 0,
        }}
        transitionDuration={{ enter: 1000, exit: 500 }}
        TransitionProps={{
          onExited: handleExited,
        }}
      >
        <div className="flex-col-center w-full h-full">
          <Contents back={handleBack} next={handleNext} close={handleClose} />
        </div>
      </Dialog>
    </>
  );
};
