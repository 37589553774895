import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";

export const useStoreReview = () => {
  const user = useSelector((state: RootState) => state.user.appUser);
  const tutorial = useSelector((state: RootState) => state.config.tutorial);
  const userConfig = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (
      "ReactNativeWebView" in window &&
      user.active.login_count > 10 &&
      ((userConfig.tutorial.top && !tutorial.top) || !userConfig.tutorial.top)
    ) {
      setTimeout(async () => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "storeReview",
            data: { period: 30 },
          })
        );
      }, 1000);
    }
  }, [tutorial.top]);
};
