import { useRef, useState, FormEventHandler, ChangeEventHandler } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  Badge,
  Stack,
  Avatar,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import I18n from "i18n-js";
import { v4 as uuidv4 } from "uuid";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { AppDispatch } from "app/store";
import { uploadImageFile } from "app/api";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { actions } from "features/user/slice";
import { AppUser } from "features/user/types";
import { updateUserProfile } from "features/user/api";

export const ParentsUserProfile = ({ user }: { user: AppUser }) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const ref = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [canSave, setCavSave] = useState(false);
  const [icon, setIcon] = useState(user.icon);

  const pickImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    play();
    setCavSave(true);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ({ target }) => {
        setIcon(target.result as string);
      };
    }
  };

  const handleSave: FormEventHandler<HTMLFormElement> = (e) => {
    play();
    e.preventDefault();
    handleDialog({ type: DialogType.LOADING, value: true });
    const data = new FormData(e.currentTarget);
    const name = data.get("name").toString();
    const uuid = uuidv4();
    const file = ref.current.files[0];
    if (file) {
      const filename = `parents/${user.uid}/assets/icons/${uuid}.png`;
      const url = `${Constants.assetHost}/${filename}`;
      const image = new Image();
      const objectUrl = URL.createObjectURL(file);
      image.src = objectUrl;
      image.onload = function () {
        uploadImageFile({
          filename,
          fileBlob: file,
          metadata: {
            filename: encodeURIComponent(file.name),
            size: String(file.size),
            width: String(image.width),
            height: String(image.height),
          },
          contentType: file.type,
        }).then(() => {
          updateUserProfile({
            uid: user.uid,
            userInfo: { name, icon: url },
          })
            .then(() => {
              ref.current.value = "";
              dispatch(
                actions.updateParentsUserProfile({
                  ...user,
                  display_name: name,
                  icon: url,
                })
              );
              toast(
                <div>
                  <p className="text text-textcolor/black">
                    {I18n.t("MSG_TOAST_EDIT_SUCCESS")}
                  </p>
                </div>
              );
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() =>
              handleDialog({ type: DialogType.LOADING, value: false })
            );
        });
        URL.revokeObjectURL(objectUrl);
      };
    } else {
      updateUserProfile({
        uid: user.uid,
        userInfo: {
          name,
        },
      })
        .then(() => {
          dispatch(
            actions.updateParentsUserProfile({
              ...user,
              display_name: name,
            })
          );
          toast(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_TOAST_EDIT_SUCCESS")}
              </p>
            </div>
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() =>
          handleDialog({ type: DialogType.LOADING, value: false })
        );
    }
  };

  return (
    <Stack
      width="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        mt={8}
        width="100%"
        maxWidth="sm"
        component="form"
        onSubmit={handleSave}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <Avatar sx={{ width: 24, height: 24 }}>
              <CameraAltIcon sx={{ width: 16, height: 16 }} />
            </Avatar>
          }
        >
          <IconButton component="label" sx={{ padding: 0 }}>
            <input
              hidden
              ref={ref}
              type="file"
              accept="image/*"
              onChange={pickImage}
            />
            <div className="flex-col-center">
              <div
                className="flex-col-center cursor-pointer bg-white rounded-full w-[80px] h-[80px]"
                style={{
                  boxShadow:
                    "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                }}
              >
                <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                  <img
                    key={icon}
                    alt="ユーザプロフィール画像"
                    src={icon}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </IconButton>
        </Badge>

        <TextField
          id="name"
          name="name"
          key={user.uid}
          margin="normal"
          variant="standard"
          fullWidth
          onChange={() => setCavSave(true)}
          defaultValue={user.display_name}
          inputProps={{ style: { fontSize: 20 } }}
          InputLabelProps={{ style: { fontSize: 20 } }}
          label={I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_NAME")}
          placeholder={I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_NAME")}
        />

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ mt: 3 }}
          disabled={!canSave}
        >
          {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_SAVE_BTN")}
        </Button>
      </Stack>
    </Stack>
  );
};
