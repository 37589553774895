import { SVGProps } from "react";

export const IconCornerUpRight = (props: SVGProps<SVGSVGElement>) => {
  const width = props.width ? props.width : 1.5;
  const color = props.color ? props.color : "#ffffff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-corner-up-right"
      viewBox="0 0 24 24"
      strokeWidth={width}
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M6 18v-6a3 3 0 0 1 3 -3h10l-4 -4m0 8l4 -4" />
    </svg>
  );
};
