import { SVGProps } from "react";

export const IconDetail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-detail-a)">
      <path
        fill="#2BDC98"
        fillRule="evenodd"
        d="M7.9 6.667c0-.605.516-1.167 1.243-1.167h13.714c.727 0 1.243.562 1.243 1.167v6.018a9.122 9.122 0 0 1 3 2.16V6.667c0-2.341-1.94-4.167-4.243-4.167H9.143C6.84 2.5 4.9 4.326 4.9 6.667v18.666c0 2.341 1.94 4.167 4.243 4.167h8.01a9.123 9.123 0 0 1-4.001-3h-4.01c-.726 0-1.242-.562-1.242-1.167V6.667ZM24.055 29.33c.11-.031.218-.067.325-.108l-.029-.028a9.131 9.131 0 0 1-.296.137Z"
        clipRule="evenodd"
      />
      <path
        fill="#2BDC98"
        fillRule="evenodd"
        d="M15.764 13.167h-3.878a1.5 1.5 0 0 0 0 3h.801a9.144 9.144 0 0 1 3.077-3ZM10.386 9.333a1.5 1.5 0 0 1 1.5-1.5h8.228a1.5 1.5 0 0 1 0 3h-8.228a1.5 1.5 0 0 1-1.5-1.5Z"
        clipRule="evenodd"
      />
      <path
        fill="#FEFDFE"
        stroke="#2BDC98"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M20.398 26.596a5.598 5.598 0 1 0 0-11.196 5.598 5.598 0 0 0 0 11.196Z"
      />
      <path
        stroke="#2BDC98"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.5}
        d="M29.2 29.8 24.4 25"
      />
    </g>
    <defs>
      <clipPath id="icon-detail-a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
