import I18n from "i18n-js";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { btnDelay } from "common/utils";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";

export const ParentsUserUnsubscribe = (props: RouteProps) => {
  const { navigate } = props;
  const handleGoBack = () => navigate("/parentsUserAccount");

  return (
    <div className="flex-col-el items-center justify-around w-full h-full py-4">
      <div className="flex-col-center w-[700px] flex-grow gap-8">
        <div className="flex-row-center gap-4">
          <WarningAmberIcon sx={{ fontSize: 40, color: "#FFA03B" }} />
          <p className="text-body text-font-size-body2 whitespace-pre-wrap">
            {I18n.t("MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_WARNING_TITLE")}
          </p>
        </div>

        <div className="flex-col-el gap-3">
          {I18n.t("MSG_SETTING_PARENTS_USER_UNSUBSCRIBE_WARNING_MESSAGE")
            .split("\n")
            .map((line, index) => (
              <p
                key={index}
                className="text-body text-font-size-body2 whitespace-pre-wrap"
              >
                {line}
              </p>
            ))}
        </div>
      </div>

      <div className="flex-row-el items-center justify-between my-8 gap-10">
        <GoBackBtn handleGoBack={handleGoBack} />

        <div className="flex-row-el flex-center alpha">
          <button
            className="btn btn-primary"
            onPointerDown={() =>
              btnDelay(() => navigate("/parentsUserUnsubscribeForm"))
            }
          >
            <p className="text-body text-white text-[24px] !mb-[3px]">次へ</p>
          </button>
        </div>
      </div>
    </div>
  );
};
