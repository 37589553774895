/* eslint-disable no-new-func */
import { useState } from "react";
import I18n from "i18n-js";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  let target: HTMLImageElement;
  const targetEles = [35, 35, 70, 70];
  const [clickedIndex, setClickedIndex] = useState(0);
  const [clicked, setClicked] = useState([false, false]);
  const [result, setResult] = useState([false, false, false, false]);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;

  const handleEvent = (index: number) =>
    new Function(
      "anime",
      "sleep",
      "highlightBlock",
      "setRunning",
      "setSuccess",
      "setGameOver",
      "clickedIndex",
      "setClickedIndex",
      "target",
      "targetEles",
      "result",
      "setResult",
      event[index]
        ? event[index]
        : `return () => {
            setSuccess(false);
            setGameOver(true);
          }
          `
    );

  const handleButton1Click = () => {
    if (!clicked[0] && clickedIndex === 0) {
      play();
      setClicked([true, clicked[1]]);
      handleEvent(0)(
        anime,
        sleep,
        highlightBlock,
        setRunning,
        setSuccess,
        setGameOver,
        clickedIndex,
        setClickedIndex,
        target,
        targetEles,
        result,
        setResult
      )();
    }
  };
  const handleButton2Click = () => {
    if (!clicked[1] && clickedIndex === 1) {
      play();
      setClicked([clicked[0], true]);
      handleEvent(1)(
        anime,
        sleep,
        highlightBlock,
        setRunning,
        setSuccess,
        setGameOver,
        clickedIndex,
        setClickedIndex,
        target,
        targetEles,
        result,
        setResult
      )();
    }
  };

  return (
    <div className="flex-row-center w-full gap-16">
      <div className="flex-col-el flex-center">
        <div className="flex-row-el w-full justify-between mb-6">
          <div className="flex-row-el flex-center absolute left-20 top-4">
            <p className="text-body text-white text-[20px]">
              {I18n.t("MSG_COURSE_COORDINATE_X")}
            </p>
          </div>
          <div className="flex-row-view">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/bc572b5812e0937f8ed15e88321cc54c.png`}
              className="w-[60px] h-[60px] opacity-0 -ml-7"
            />
          </div>

          <div className="flex-row-view cursor-pointer">
            <img
              alt="*"
              onClick={handleButton1Click}
              src={`${Constants.assetHost}/stamps/vehicle/bc572b5812e0937f8ed15e88321cc54c.png`}
              className="w-[60px] h-[60px] active:opacity-70"
            />
          </div>

          <div className="flex-row-view cursor-pointer">
            <img
              alt="*"
              onClick={handleButton2Click}
              src={`${Constants.assetHost}/stamps/vehicle/869a1175dc52418410e65a2e056c6ac7.png`}
              className="w-[60px] h-[60px] active:opacity-70 -mr-7"
            />
          </div>
        </div>

        <div className="flex-col-center w-[300px] h-[5px]">
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
            <p className="text text-white !mb-[35px]">0</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
            <p className="text text-white !mb-[35px]">35</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
            <p className="text text-white !mb-[35px]">70</p>
          </div>
          <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

          <div
            data-value="0"
            id="course-program-image-1"
            className="flex-row-el flex-center absolute top-4 -left-8 z-50 cursor-pointer"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
              className="w-[60px] h-[60px]"
            />
            <AnswerMark id={1} />
          </div>

          <div className="flex-row-el absolute top-4 -left-8">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${35 * 4.3}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-8">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${70 * 4.3}px)` }}
            />
          </div>
        </div>
      </div>

      <div className="flex-col-center w-[380px] mt-24">
        <div className="flex-row-center gap-2 p-2 border-solid border-[4px] rounded-[6px] border-white/20">
          <p
            id="course-program-answer-label-1"
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            35
          </p>
          <p
            id="course-program-answer-label-2"
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-0"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            70
          </p>
          <p
            id="course-program-label-1"
            className="text-body text-white text-[100px] leading-[100px] opacity-0 absolute"
          />
          <AnswerMark id={2} />
        </div>

        <p className="text text-white">ボックス1</p>
      </div>
    </div>
  );
};
