import { useEffect } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { Vector2D } from "common/types";
import { useScale } from "common/utils";
import { IconLeft, IconRight, IconClose } from "common/assets";
import { CloseBtnOuter, FadeTransition } from "common/elements";
import { GuideMessageBalloon } from "features/creator/assets";

export const GuideMessageBalloonLarge = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={814} height={632} fill="none">
    <path
      fill="#FEFDFE"
      stroke="#3F4A61"
      strokeWidth={1.5}
      d="m486.513 5.688.014.002c39.461 3.369 78.624 11.03 117.061 22.902h.003l.095.03c50.94 16.033 93.731 29.5 131.503 66.094 39.817 38.582 59.427 88.685 69.025 133.747 9.597 45.058 9.171 85.015 8.96 103.261-.627 59.68-17.837 105.849-26.644 129.473l-.699 1.877v.001c-11.872 32.081-23.338 62.89-50.704 93.171-33.996 37.58-71.957 51.568-93.596 59.239-18.323 6.497-46.709 14.281-132.729 12.71h-6.159l-1.712-.049c-35.454-1.021-108.053-3.11-141.491.41-9.804 1.034-83.153 8.525-159.734-7.133l-.193-.04c-52.199-10.699-78.103-16.009-103.333-35.541h-.001c-36.032-27.84-60.7-72.589-75.917-121.911C5.047 414.619-.698 360.817 1.054 314.972v-.004l.06-1.847c.428-13.493 1.606-50.555 11.644-93.418C23.301 174.685 43.6 123.354 82.99 86.221c35.842-33.747 73.218-43.641 148.414-63.514C347.117-7.908 440.797.94 486.513 5.688Z"
    />
  </svg>
);

export const GuideMessageBalloonMiddle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 590 344" fill="none">
    <path
      fill="#FEFDFE"
      stroke="#3F4A61"
      strokeWidth={1.5}
      d="M352.63 3.438h.01a523.93 523.93 0 0 1 84.841 12.465h.002l.039.01c36.948 8.732 67.944 16.058 95.292 35.954 57.595 41.91 56.686 108.81 56.381 128.685v.001c-.453 32.383-12.891 57.441-19.272 70.295l-.503 1.014v.002c-8.6 17.451-16.883 34.162-36.665 50.6-24.599 20.42-52.082 28.029-67.774 32.207-13.273 3.534-33.845 7.771-96.193 6.916h-4.465l-1.239-.027c-25.697-.555-78.32-1.692-102.559.224l.06.748-.06-.748c-7.105.563-60.267 4.64-115.77-3.883l-.1-.015c-37.873-5.83-56.634-8.718-74.898-19.336C17.617 288.299-1.567 221.309.97 171.463v-.005l.043-.995c.638-15.051 3.553-83.823 59.2-123.218 25.94-18.34 53-23.724 107.519-34.544C251.597-3.963 319.495.852 352.63 3.438Z"
    />
  </svg>
);

export const GuideMessageBalloonNormal = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={504} height={320} fill="none">
    <path
      fill="#FEFDFE"
      stroke="#3F4A61"
      strokeWidth={1.5}
      d="M301.216 3.25h.011a413.384 413.384 0 0 1 72.448 11.592l.022.005c31.57 8.125 58.022 14.933 81.363 33.426 49.172 38.964 48.404 101.181 48.143 119.682v.001c-.387 30.125-11.012 53.434-16.462 65.389l-.43.944v.002c-7.348 16.237-14.42 31.772-31.305 47.051-20.994 18.978-44.447 26.049-57.846 29.934-11.318 3.281-28.874 7.223-82.125 6.427H311.219l-1.062-.025c-21.95-.517-66.906-1.575-87.62.209-6.067.523-51.463 4.314-98.855-3.61l-.059-.01c-32.378-5.427-48.376-8.109-63.955-17.972-22.238-14.05-37.465-36.635-46.864-61.546C3.405 209.839-.145 182.657.938 159.5v-.004l.037-.935c.265-6.825.993-25.547 7.193-47.199C14.68 88.625 27.21 62.717 51.523 43.974c22.133-17.041 45.214-22.044 91.793-32.11h.001C214.934-3.633 272.915.845 301.216 3.25Z"
    />
  </svg>
);

export const TopLayout = ({
  children,
  next,
}: {
  children: any;
  next: () => void;
}) => {
  const { width, height } = useScale();
  useEffect(() => {
    setTimeout(() => {
      next();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackgroundOverlay />

      <div
        className="flex-col-el flex-center absolute top-0 left-0"
        style={{
          transform: `translate(${width / 2 - 324 / 2}px, ${
            height / 2 - 282 / 2
          }px)`,
        }}
      >
        <div className="flex-col-el">
          <GuideMessageBalloon />
        </div>

        <div className="flex-col-el w-full h-full absolute">
          <div className="flex-col-el items-start justify-start flex-1">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export const Layout = ({
  transparent,
  position,
  children,
  back,
  next,
  close,
}: {
  transparent?: boolean;
  position?: Vector2D;
  children: any;
  back?: () => void;
  next?: () => void;
  close: () => void;
}) => {
  const { width, height, scale } = useScale();
  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 10000 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: {
          style: {
            opacity: 0.35,
            backgroundColor: transparent ? "transparent" : "black",
            pointerEvents: "none",
          },
        },
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          margin: 0,
          maxWidth: "none",
          maxHeight: "none",
          transform: `scale(${scale})`,
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-center w-full h-full">
        <div
          className="flex-col-el flex-center absolute top-0 left-0"
          style={{
            transform: `translate(${
              position ? position.x : width / 2 - 504 / 2
            }px, ${position ? position.y : height / 2 - 320 / 2 - 100}px)`,
          }}
        >
          <div className="flex-col-el absolute top-4 right-4">
            <CloseBtnOuter close={close} />
          </div>

          <div className="flex-col-el">
            <GuideMessageBalloonNormal />
          </div>

          <div className="flex-col-el w-full h-full absolute">
            <div className="flex-row-el flex-center flex-1 mt-10">
              {children}
            </div>
            <div className="flex-row-el flex-center gap-4 w-full mb-4">
              <div className="flex-row-el flex-center upsilon">
                <button
                  className="btn btn-blue !px-6 !py-0"
                  disabled={back === undefined}
                  onClick={back}
                >
                  <div className="flex-row-el flex-center relative mb-[3px]">
                    <div className="flex-col-center w-[32px] h-[32px]">
                      <IconLeft />
                    </div>
                    <p className="text-body text-white text-[20px] !mr-2">
                      {I18n.t("MSG_BACK_BTN")}
                    </p>
                  </div>
                </button>
              </div>
              {next ? (
                <div className="flex-row-el flex-center upsilon">
                  <button
                    className="btn btn-primary !px-6 !py-0"
                    onClick={next}
                  >
                    <div className="flex-row-el flex-center relative mb-[3px]">
                      <p className="text-body text-white text-[20px] !ml-2">
                        {I18n.t("MSG_CREATOR_TOP_TUTORIAL_TIPS_NEXT_BTN")}
                      </p>
                      <div className="flex-col-center w-[32px] h-[32px]">
                        <IconRight />
                      </div>
                    </div>
                  </button>
                </div>
              ) : (
                <div className="flex-row-el flex-center upsilon">
                  <button className="btn btn-primary !px-6" onClick={close}>
                    <p className="text-body text-white text-[20px] !mb-[3px]">
                      {I18n.t("MSG_CREATOR_TOP_TUTORIAL_TIPS_ACTION_BTN")}
                    </p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const LatestLayout = ({
  next,
  close,
  children,
}: {
  next: () => void;
  close: () => void;
  children: any;
}) => {
  const { width, height, scale } = useScale();
  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 10000 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: {
          style: {
            opacity: 0.35,
            backgroundColor: "black",
            pointerEvents: "none",
          },
        },
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          margin: 0,
          maxWidth: "none",
          maxHeight: "none",
          transform: `scale(${scale})`,
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-center w-full h-full">
        <div
          className="flex-col-el flex-center absolute top-0 left-0"
          style={{
            transform: `translate(${width / 2 - 708 / 2}px, ${
              height / 2 - 413 / 2
            }px)`,
          }}
        >
          <div className="flex-col-el absolute top-8 right-8">
            <CloseBtnOuter close={close} />
          </div>

          <div className="flex-col-el w-[590px] h-[344px]">
            <GuideMessageBalloonMiddle />
          </div>

          <div className="flex-col-el flex-center w-full h-full gap-4 absolute">
            <div className="flex-col-el flex-center gap-4">
              <p className="text-body text-textcolor/black text-[22px]">
                つぎは、
              </p>
              <div className="flex-col-center border-[4px] border-solid border-blue/60 px-8 py-4 gap-4 rounded-2xl">
                {children}
              </div>
              <p className="text-body text-center whitespace-pre text-textcolor/black text-[22px]">
                {I18n.locale === "ja"
                  ? "のガイドにすすみます。"
                  : "のガイドに進みます。"}
              </p>
            </div>

            <div className="flex-row-el flex-center gap-4 w-full">
              <div className="flex-row-el flex-center upsilon">
                <button className="btn btn-blue !px-6" onClick={close}>
                  <div className="flex-row-el flex-center relative mb-[3px]">
                    <div className="flex-col-center w-[32px] h-[32px]">
                      <IconClose />
                    </div>
                    <p className="text-body text-white text-[20px] !mr-2">
                      {I18n.t("MSG_CREATOR_TOP_TUTORIAL_TIPS_LATER_BTN")}
                    </p>
                  </div>
                </button>
              </div>
              <div className="flex-row-el flex-center upsilon">
                <button className="btn btn-primary !px-6" onClick={next}>
                  <div className="flex-row-el flex-center relative mb-[3px]">
                    <div className="flex-col-center w-[32px] h-[32px]">
                      <IconRight />
                    </div>
                    <p className="text-body text-white text-[20px] !ml-2">
                      {I18n.t("MSG_CREATOR_TOP_TUTORIAL_TIPS_CONTINUE_BTN")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const Overlay = ({
  area,
  borderRadius,
  onClick,
  children,
}: {
  area?: { x: number; y: number; width: number; height: number };
  borderRadius?: string;
  onClick?: () => void;
  children: any;
}) => {
  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: onClick ? 10000 : 9999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: {
          style: { backgroundColor: "transparent", pointerEvents: "none" },
        },
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-el flex-center w-full h-full">
        <div className="w-full h-full flex-col-el">
          {area && (
            <div
              onClick={onClick}
              className="absolute pointer-events-auto z-0 cursor-pointer"
              style={{
                borderRadius: borderRadius ?? "100%",
                width: area.width,
                height: area.height,
                transform: `translate(${area.x}px, ${area.y}px)`,
                boxShadow: "0px 0px 9999px 9999px rgba(0, 0, 0, 0.35)",
              }}
            />
          )}
          {children}
        </div>
      </div>
    </Dialog>
  );
};

export const BackgroundOverlay = () => {
  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: {
          style: {
            opacity: 0.35,
            backgroundColor: "black",
            pointerEvents: "none",
          },
        },
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    />
  );
};

export const TitleLayout = ({
  margin,
  steps,
  activeStep,
  children,
}: {
  margin?: number;
  steps: number;
  activeStep: number;
  children: any;
}) => (
  <div
    style={{ padding: `${margin ?? 14}px 0` }}
    className="flex-col-el flex-center border-solid border-orange/100 border-0 border-r-4 mr-6 w-[180px] gap-6"
  >
    {children}
    <div className="flex-row-el items-baseline">
      <p className="text-body text-[56px] text-orange/100 leading-10">
        {activeStep}
      </p>
      <p className="text-body text-[24px] text-orange/100 leading-6">
        /{steps}
      </p>
    </div>
  </div>
);
