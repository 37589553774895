import { CSSProperties } from "react";

const isRotateChar = (char: string) => {
  switch (char) {
    case "ー":
    case "(":
    case ")":
    case "（":
    case "）":
    case "{":
    case "}":
    case "『":
    case "』":
    case "<":
    case ">":
    case "＜":
    case "＞":
      return true;
    default:
      return false;
  }
};

export const VerticalText = (props: {
  text: string;
  style?: CSSProperties;
}) => {
  const { text, style } = props;
  const charList = text.split("");

  return (
    <div className="flex-col-view items-center justify-start flex-wrap">
      {charList.map((char, index) => {
        const isRotate = isRotateChar(char);
        return (
          <p
            key={index}
            className="text"
            style={{ ...style, transform: `rotate(${isRotate ? 90 : 0}deg)` }}
          >
            {char}
          </p>
        );
      })}
    </div>
  );
};
