import { SVGProps } from "react";

export const IconColorDisable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 21a9 9 0 0 1 0-18c2.387 0 4.676.843 6.364 2.343C20.052 6.843 21 8.878 21 11c0 1.06-.474 2.078-1.318 2.828-.844.75-1.988 1.172-3.182 1.172H14a2 2 0 0 0-1 3.75A1.3 1.3 0 0 1 12 21Z"
      fill="#E6CCA1"
    />
    <path
      d="M12 21a9 9 0 0 1 0-18c2.387 0 4.676.843 6.364 2.343C20.052 6.843 21 8.878 21 11c0 1.06-.474 2.078-1.318 2.828-.844.75-1.988 1.172-3.182 1.172H14a2 2 0 0 0-1 3.75A1.3 1.3 0 0 1 12 21"
      stroke="#B8B1B5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
      fill="#FC6560"
      stroke="#B8B1B5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
      fill="#2BDC98"
      stroke="#B8B1B5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 11a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"
      fill="#4C9DF1"
      stroke="#B8B1B5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
