import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

export const calcDirection = (character: AbstractMesh) => {
  // direction:
  // z: Vector3(0, Math.PI, 0)
  // -z: Vector3(0, 0, 0)
  // x: Vector3(0, -Math.PI / 2, 0)
  // -x: Vector3(0, Math.PI / 2, 0)
  // forward(-z): 0
  // backward(z): +- 2
  // left(x):     - 1
  // right(-x):   + 1
  switch (Math.round(character.rotation.y / (Math.PI / 2.0))) {
    case 0:
      // forward
      return new Vector3(0, 0, -1);
    case 2:
    case -2:
      // backward
      return new Vector3(0, 0, 1);
    case -1:
      // left
      return new Vector3(1, 0, 0);
    case 1:
      // right
      return new Vector3(-1, 0, 0);
    default:
      return;
  }
};
