const ToolboxInfo = {
  maxInstances: {
    character_motion_move: 1,
    procedures_defnoreturn: 1,
    procedures_callnoreturn_1: 3,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "character_motion_move",
          },
          {
            kind: "block",
            type: "procedures_defnoreturn",
            fields: {
              NAME: "なにかする",
            },
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
