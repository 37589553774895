import { Course } from "./types";

export const string2Course = (course: string): Course => {
  switch (course) {
    case "algorithm":
      return Course.ALGORITHM;
    case "program":
      return Course.PROGRAM;
    case "tangible":
      return Course.TANGIBLE;
    case "builder":
      return Course.BUILDER;
    case "nishishinjuku":
      return Course.NISHISHINJUKU;
    case "takushoku_basic":
      return Course.TAKUSHOKU_BASIC;
    case "takushoku_advance":
      return Course.TAKUSHOKU_ADVANCE;
    case "ocha":
      return Course.OCHA;
    default:
      return Course.ALGORITHM;
  }
};
