import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { useActionCommand } from "features/builder/hook";
import { ActionCommandType, BlockType } from "features/builder/types";
import { ToolboxDefinition } from "./type";

const blockStyleMapping = (
  blockType: BlockType
): { height: number; color: string; borderColor: string } => {
  switch (blockType) {
    case BlockType.MOTION_MOVE:
    case BlockType.MOTION_JUMP:
    case BlockType.MOTION_TURN_LEFT:
    case BlockType.MOTION_TURN_RIGHT:
      return { height: 56, color: "#4C97FF", borderColor: "#3971bf" };

    case BlockType.CONTROLS_REPEAT_INTERNAL:
      return { height: 125, color: "#FFAB19", borderColor: "#bf8013" };

    case BlockType.CONTROLS_REPEAT_VAR_INTERNAL:
    case BlockType.CONTROLS_WHILEUNTIL_COLOUR_INTERNAL:
    case BlockType.CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL:
      return { height: 112, color: "#FFAB19", borderColor: "#bf8013" };

    case BlockType.CONTROLS_IF_COLOUR_INTERNAL:
    case BlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL:
      return { height: 112, color: "#FFAB19", borderColor: "#bf8013" };

    case BlockType.MATH_NUMBER:
      return { height: 40, color: "#389438", borderColor: "#389438" };

    case BlockType.COLOUR_PICKER_INTERNAL:
      return { height: 40, color: "#71cdfc", borderColor: "#fff" };

    case BlockType.VARIABLES_SET:
      return { height: 69, color: "#ff8c1a", borderColor: "#db6e00" };

    case BlockType.VARIABLES_GET:
      return { height: 40, color: "#ff8c1a", borderColor: "#db6e00" };

    case BlockType.PROCEDURES_CALLNORETURN_DEFINITION:
      return { height: 139, color: "#ff6680", borderColor: "#ff3355" };

    default:
      return { height: 0, color: "", borderColor: "" };
  }
};

export const BlockCount = ({ toolbox }: { toolbox: ToolboxDefinition }) => {
  const play = usePlaySound();
  const handleAction = useActionCommand();
  const blocks = useSelector((state: RootState) => state.builder.blocks);
  const handleClick = (
    block: { kind: string; type: BlockType; [key: string]: any },
    step: number
  ) => () => {
    play();
    const currentCount = blocks[block.type]?.count;
    if (currentCount) {
      handleAction({
        type: ActionCommandType.CHANGE_BLOCKLY,
        block: {
          ...block,
          count: currentCount > 0 || step > 0 ? currentCount + step : 0,
        },
      });
    } else {
      handleAction({
        type: ActionCommandType.CHANGE_BLOCKLY,
        block: {
          ...block,
          count: step > 0 ? 1 : 0,
        },
      });
    }
  };
  return (
    <div className="flex-col-el justify-start ml-4 mb-2 pt-2 gap-6">
      {toolbox.contents[0].contents.map((content, index) => {
        const style = blockStyleMapping(content.type);
        return (
          <div key={index} className="flex-row-center gap-2">
            <div
              style={{
                width: style.height > 32 ? 56 : style.height,
                height: style.height,
                borderColor: style.borderColor,
                backgroundColor: style.color,
              }}
              onClick={handleClick(content, 1)}
              className="flex-col-center rounded-[6px] border-solid border-[1px] clickable"
            >
              <p className="text-body text-white text-font-size-body2 leading-6">
                +
              </p>
            </div>

            <div
              className="flex-col-center w-[56px] rounded-[6px] border-solid border-[1px]"
              style={{
                borderColor: style.borderColor,
                backgroundColor: style.color,
                height: style.height,
              }}
            >
              <p className="text-body text-white text-font-caption2-narrow">
                かず
              </p>
              <p className="text-body text-white text-font-size-body2 leading-4">
                {blocks[content.type]?.count ?? 0}
              </p>
            </div>

            <div
              style={{
                width: style.height > 32 ? 56 : style.height,
                height: style.height,
                borderColor: style.borderColor,
                backgroundColor: style.color,
              }}
              onClick={handleClick(content, -1)}
              className="flex-col-center rounded-[6px] border-solid border-[1px] clickable"
            >
              <p className="text-body text-white text-font-size-body2 leading-6">
                -
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
