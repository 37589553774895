import { Scene } from "@babylonjs/core";
import { Ray } from "@babylonjs/core/Culling/ray";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { AssetModelType } from "features/builder/types";

export const checkBtnColor = (scene: Scene, character: AbstractMesh) => {
  const characterPos = new Vector3(
    character.position.x,
    character.position.y,
    character.position.z
  );

  const conditionRay = new Ray(characterPos, new Vector3(0, -1, 0), 1);
  const pickCondition = scene.pickWithRay(conditionRay, (mesh) => {
    return mesh.name.includes(AssetModelType.CONDITION);
  });
  if (pickCondition.hit) {
    return pickCondition.pickedMesh.metadata?.color;
  } else return null;
};
