import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { IconSettings } from "common/assets";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: { next: () => void; close: () => void }) => {
  const { scale } = useScale();
  const { next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-display-edit-btn");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 10 * scale,
        y: rect.y - 10 * scale,
        width: rect.width + 20 * scale,
        height: rect.height + 20 * scale,
      });
    }, 1000);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent next={next} close={close}>
        <Title activeStep={6} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <div className="flex-row-el gap-2">
            <div className="flex-row-center w-[106px] h-[32px]">
              <div className="flex-row-center upsilon w-[212px] h-[64px] scale-50">
                <div className="btn btn-primary !rounded-full !px-8 !py-1 !m-0">
                  <div className="flex-row-el flex-center relative gap-2">
                    <div className="flex-col-el flex-center relative w-[56px] h-[56px]">
                      <IconSettings color="white" width={2} />
                    </div>
                    <p className="text-body text-white text-[28px]">
                      {I18n.t("MSG_CREATOR_APP_EDITOR_BTN")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              {I18n.locale === "ja" ? "を おすと、" : "を押すと、"}
            </p>
          </div>
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "アプリの へんしゅうを \nすることが できます"
              : "アプリの編集を\nすることができます。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
