import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { IconPublish } from "features/creator/assets";
import { selectAllProjects } from "features/creator/slice";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";
import { ProjectType } from "../../../../../types";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { back, next, close } = props;
  const { width, height, scale } = useScale();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );
  const sortedProjects = userProjects.sort((a, b) =>
    dayjs(a.created_time) < dayjs(b.created_time) ? -1 : 1
  );

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        `user-app-project-publish-icon-${
          sortedProjects[sortedProjects.length - 1].project_id
        }`
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 15 * scale,
        y: rect.y - 15 * scale,
        width: rect.width + 30 * scale,
        height: rect.height + 30 * scale,
      });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout
        transparent
        position={{
          x: width / 2 - 560 / 2 - 60,
          y: height / 2 - 336 / 2 - 50,
        }}
        back={back}
        next={next}
        close={close}
      >
        <Title activeStep={6} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "「こうかい」にすると\nこのマークが"
              : "「公開」にすると\nこのマークが"}
          </p>
          <div className="flex-row-el flex-center gap-1">
            <div className="flex-col-center w-[40px] h-[40px]">
              <IconPublish fill="#4C9DF1" />
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre text-center">
              {I18n.locale === "ja" ? "に かわります。" : "に変わります。"}
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Step;
