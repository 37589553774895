import * as React from "react";
import { SVGProps } from "react";
export const IconTrashcan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
    fill="none"
    {...props}
  >
    <mask id="icon-trashcan-a" fill="#fff">
      <path d="M10.4 9.389V5.122c0-.377.196-.739.546-1.005.35-.267.825-.417 1.32-.417h7.467c.495 0 .97.15 1.32.417.35.266.546.628.546 1.005V9.39" />
    </mask>
    <path
      fill="#C9947A"
      d="M10.4 9.389V5.122c0-.377.196-.739.546-1.005.35-.267.825-.417 1.32-.417h7.467c.495 0 .97.15 1.32.417.35.266.546.628.546 1.005V9.39"
    />
    <path
      fill="#3F4A61"
      d="M7.9 9.389a2.5 2.5 0 1 0 5 0h-5ZM12.265 3.7V1.2v2.5Zm7.467 0V1.2v2.5Zm-.634 5.689a2.5 2.5 0 1 0 5 0h-5Zm-6.2 0V5.122h-5V9.39h5Zm0-4.267c0 .54-.284.866-.438.983l-3.03-3.977c-.854.65-1.532 1.7-1.532 2.994h5Zm-.438.983a.53.53 0 0 1-.157.087c-.024.008-.036.008-.038.008v-5c-.97 0-1.996.288-2.835.928l3.03 3.977Zm-.195.095h7.467v-5h-7.467v5Zm7.467 0c-.002 0-.014 0-.038-.008a.53.53 0 0 1-.157-.087l3.03-3.977c-.84-.64-1.865-.928-2.835-.928v5Zm-.195-.095c-.154-.117-.439-.444-.439-.983h5c0-1.293-.678-2.344-1.531-2.994l-3.03 3.977Zm-.439-.983V9.39h5V5.122h-5Z"
      mask="url(#icon-trashcan-a)"
    />
    <mask id="icon-trashcan-b" fill="#fff">
      <path d="M5.5 9.389 7 26.456c0 .754.316 1.477.879 2.01A3.085 3.085 0 0 0 10 29.3h12c.796 0 1.559-.3 2.121-.833A2.772 2.772 0 0 0 25 26.456l1.5-17.067" />
    </mask>
    <path
      fill="#C9947A"
      d="M5.5 9.389 7 26.456c0 .754.316 1.477.879 2.01A3.085 3.085 0 0 0 10 29.3h12c.796 0 1.559-.3 2.121-.833A2.772 2.772 0 0 0 25 26.456l1.5-17.067"
    />
    <path
      fill="#3F4A61"
      d="M7.99 9.17a2.5 2.5 0 1 0-4.98.438l4.98-.438ZM7 26.456h2.5c0-.073-.003-.146-.01-.22l-2.49.22Zm18 0-2.49-.22c-.007.073-.01.146-.01.22H25Zm3.99-16.848a2.5 2.5 0 1 0-4.98-.438l4.98.438Zm-25.98 0 1.5 17.066 4.98-.437L7.99 9.17l-4.98.438ZM4.5 26.456c0 1.461.614 2.834 1.659 3.825l3.44-3.628c-.08-.076-.099-.15-.099-.197h-5Zm1.659 3.825A5.585 5.585 0 0 0 10 31.8v-5a.587.587 0 0 1-.401-.147l-3.44 3.628ZM10 31.8h12v-5H10v5Zm12 0a5.585 5.585 0 0 0 3.841-1.519l-3.44-3.628A.587.587 0 0 1 22 26.8v5Zm3.841-1.519a5.271 5.271 0 0 0 1.659-3.825h-5c0 .047-.018.12-.099.197l3.44 3.628Zm1.65-3.607 1.5-17.066-4.981-.438-1.5 17.067 4.98.438Z"
      mask="url(#icon-trashcan-b)"
    />
    <path
      stroke="#3F4A61"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M4.8 10.1h22.4"
    />
    <path
      stroke="#3F4A61"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.2 15.078v8.533M18.8 15.078v8.533"
    />
  </svg>
);
