import I18n from "i18n-js";

export const GoBackBtn = ({ handleGoBack }: { handleGoBack: () => void }) => {
  const handleClick = () =>
    setTimeout(() => {
      handleGoBack();
    }, 500);
  return (
    <div className="flex-col-el flex-center relative alpha">
      <button className="btn btn-blue" onClick={handleClick}>
        <p className="text-body text-[24px] text-white !mb-[3px]">
          {I18n.t("MSG_SETTING_BACK_TO_BTN")}
        </p>
      </button>
    </div>
  );
};
