import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import tilesets, {
  characters,
  CHARACTER_TYPE,
  TILE_TYPE_NOTHING,
} from "../tilesets";
import { TileMapType } from "..";
import { loadMesh, copyMesh } from "./mesh";

const loadTilesMesh = (scene: Scene, map: TileMapType) => {
  // 地図に置かれるtilesだけをロードする
  const { layers, width, height, landscape } = map;
  const tileIds = new Set();
  for (let l = 0; l < layers; l++) {
    for (let z = 0; z < height; z++) {
      for (let x = 0; x < width; x++) {
        const tileId: number = landscape[l][z][x];
        if (tileId !== TILE_TYPE_NOTHING) {
          tileIds.add(tileId);
        }
      }
    }
  }

  return new Promise((resovle, reject) => {
    Promise.all(
      Array.from(tileIds).map(async (tileId: number) => {
        if (tilesets[tileId].filename) {
          if (!scene.isDisposed) {
            const mesh = await loadMesh(scene, tilesets[tileId].filename);
            return { id: tileId, mesh: mesh };
          } else {
            return null;
          }
        } else {
          return null;
        }
      })
    )
      .then((meshes) => {
        resovle(
          Array.from(meshes).reduce(
            (acc, mesh) => Object.assign(acc, { [mesh.id]: mesh.mesh }),
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const loadCharacterMesh = async (
  scene: Scene,
  map: TileMapType,
  mapOffset: Vector3,
  characterType: CHARACTER_TYPE
) => {
  const characterMeta = characters[characterType];
  const {
    width,
    height,
    tileSize,
    character: characterConfig,
    landscape,
  } = map;

  let character: AbstractMesh;
  for (let z = 0; z < height; z++) {
    for (let x = 0; x < width; x++) {
      const tileTypeId = characterConfig.data[z][x];
      if (tileTypeId !== TILE_TYPE_NOTHING) {
        var landscapeRelativeOffset = Vector3.Zero();
        for (let l = 0; l < characterConfig.layer; l++) {
          // キャラクターがいる座標における、0からキャラクターがいるレイヤーまでのtileのoffsetを計算して合計してoffsetとして使われる
          const mapTileTypeId = landscape[l][z][x];
          if (mapTileTypeId !== TILE_TYPE_NOTHING) {
            const mapTileSize = tilesets[mapTileTypeId].size;
            let relativeOffset: Vector3;
            if (l === characterConfig.layer - 1) {
              // 同じレイヤーのマップのtileの場合はキャラクターをあげるので、offsetは+となる
              relativeOffset =
                mapTileSize.y < tileSize
                  ? new Vector3(0, mapTileSize.y, 0)
                  : Vector3.Zero();
            } else {
              relativeOffset =
                mapTileSize.y < tileSize
                  ? new Vector3(0, -(tileSize - mapTileSize.y), 0)
                  : Vector3.Zero();
            }
            landscapeRelativeOffset = landscapeRelativeOffset.add(
              relativeOffset
            );
          }
        }

        const modelOffset = characterMeta.offset;
        const postion = new Vector3(
          (width - x - 1) * tileSize,
          (characterConfig.layer - 1) * tileSize,
          z * tileSize
        )
          .add(modelOffset)
          .add(landscapeRelativeOffset)
          .add(mapOffset);

        character = await loadMesh(scene, characterMeta.filename, postion);
        character.name = "character";
        character.getChildMeshes().forEach((mesh) => {
          if (mesh.name.indexOf("Cube") !== -1) {
            mesh.isVisible = false;
          }
        });
        character.scaling = characterMeta.scaling;
        character.rotation = new Vector3().copyFrom(map.character.direction); // deep copy
        scene.getAnimationGroupByName("ArmLeftAction")?.stop();
        scene.getAnimationGroupByName("ArmRightAction")?.stop();
        scene.getAnimationGroupByName("BodyAction")?.play(true);
      }
    }
  }

  return character;
};

const loadMap = async (
  scene: Scene,
  map: TileMapType,
  mapOffset: Vector3,
  characterType: CHARACTER_TYPE
): Promise<{
  character: AbstractMesh;
  gems: AbstractMesh[];
}> => {
  const meshes = await loadTilesMesh(scene, map);
  const { layers, width, height, tileSize, gem, landscape } = map;
  const gems = Array<AbstractMesh>();

  for (let l = 0; l < layers; l++) {
    for (let z = 0; z < height; z++) {
      for (let x = 0; x < width; x++) {
        const tileTypeId = landscape[l][z][x];

        var landscapeRelativeOffset: Vector3 = Vector3.Zero();
        if (l !== 0 && tileTypeId !== TILE_TYPE_NOTHING) {
          for (let i = 0; i < l; i++) {
            // キャラクターがいる座標における、0からキャラクターがいるレイヤーまでのtileのoffsetを計算して合計してoffsetとして使われる
            const mapTileTypeId = landscape[i][z][x];
            const mapTileSize = tilesets[mapTileTypeId].size;
            const relativeOffset =
              mapTileSize.y < tileSize
                ? new Vector3(0, -(tileSize - mapTileSize.y), 0)
                : Vector3.Zero();
            landscapeRelativeOffset = landscapeRelativeOffset.add(
              relativeOffset
            );
          }
        }

        if (tileTypeId !== TILE_TYPE_NOTHING) {
          const landscapeOffset = tilesets[tileTypeId].offset;
          const landscapeScaling = tilesets[tileTypeId].scaling;
          const landscapeDirection = tilesets[tileTypeId].direction;
          const landscapePostion = new Vector3(
            (width - x - 1) * tileSize,
            l * tileSize,
            z * tileSize
          )
            .add(landscapeOffset)
            .add(landscapeRelativeOffset)
            .add(mapOffset);

          const landscapeModel = copyMesh(
            meshes[tileTypeId],
            "",
            landscapePostion
          );
          if (landscapeScaling) {
            landscapeModel.scaling = landscapeScaling;
          }
          if (landscapeDirection) {
            landscapeModel.rotation = landscapeDirection;
          }
        }

        const gemTypeId = gem[l][z][x];
        if (gemTypeId !== TILE_TYPE_NOTHING) {
          const gemOffset = tilesets[gemTypeId].offset;
          const gemScaling = tilesets[gemTypeId].scaling;
          const gemDirection = tilesets[gemTypeId].direction;
          const gemPostion = new Vector3(
            (width - x - 1) * tileSize,
            l * tileSize,
            z * tileSize
          )
            .add(gemOffset)
            .add(landscapeRelativeOffset)
            .add(mapOffset);

          const gemModel = await loadMesh(
            scene,
            tilesets[gemTypeId].filename,
            gemPostion
          );
          gemModel.name = "gem";
          gemModel.rotation = Vector3.Zero();
          if (gemScaling) {
            gemModel.scaling = gemScaling;
          }
          if (gemDirection) {
            gemModel.rotation = gemDirection;
          }
          gems.push(gemModel);
        }
      }
    }
  }

  const character = await loadCharacterMesh(
    scene,
    map,
    mapOffset,
    characterType
  );

  Object.values(meshes).forEach((mesh) => {
    mesh.dispose();
  });

  return { character, gems };
};

export default loadMap;
