import { useEffect } from "react";
import I18n from "i18n-js";
import Constants from "common/constant";
import {
  InitMathPlusBlock,
  InitImageClickBlock,
  InitImageGetterXBlock,
  InitImageSetterXBlock,
} from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitMathPlusBlock();
    InitImageClickBlock();
    InitImageGetterXBlock();
    InitImageSetterXBlock();
  }, []);

  return (
    <div className="flex-col-el items-center w-full h-full absolute top-36">
      <div className="flex-row-el flex-center absolute -top-16">
        <p className="text-body text-white text-[20px]">
          {I18n.t("MSG_COURSE_COORDINATE_X")}
        </p>
      </div>

      <div className="flex-col-center w-[450px] h-[5px]">
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
          <p className="text text-white !mb-[35px]">0</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
          <p className="text text-white !mb-[35px]">50</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
          <p className="text text-white !mb-[35px]">100</p>
        </div>
        <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

        <div className="flex-row-el absolute top-4 left-[193px]">
          <img
            alt="*"
            className="w-[60px] h-[60px]"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
          />
        </div>

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${100 * 4.5}px)` }}
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
          />
        </div>
      </div>

      <div className="flex-row-el flex-center mt-20 cursor-pointer">
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/internet/1b69d8850494bfd07a6c05a64a721633.png`}
          className="w-[100px] h-[100px]"
        />
      </div>
    </div>
  );
};
