import { useState } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useScale } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";

export const DeleteConfirmMessageBox = ({
  title,
  message,
  close,
  confirm,
  is_parents,
  list_style_type,
}: {
  title: string;
  message: string;
  close: () => void;
  confirm: () => void;
  is_parents?: boolean;
  list_style_type?: string;
}) => {
  const { scale } = useScale();
  const [disable, setDisable] = useState(false);

  const handleClose = () => setTimeout(() => close(), 200);
  const handleConfirm = () => {
    if (disable) return;
    setDisable(true);
    setTimeout(() => confirm(), 200);
  };

  return (
    <Dialog
      open
      maxWidth={false}
      sx={{ zIndex: 999999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{ transform: `scale(${scale})` }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            filter:
              "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
          }}
        >
          <div className="flex-col-el justify-start w-[510px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
            <CloseBtn close={handleClose} />

            <div className="flex-col-view justify-start w-full h-full  relative p-4 rounded-[4px] bg-beige/60">
              <div className="flex-row-view items-center justify-start py-4">
                <div className="flex-col-center mr-2">
                  <InfoOutlinedIcon sx={{ fontSize: 40, color: "#4C9DF1" }} />
                </div>
                <p className="text-body text-textcolor/black text-[28px]">
                  {title}
                </p>
              </div>
              <div className="flex-col-view items-start py-4">
                {message?.split("\n").map((line, index) => {
                  return (
                    <div key={index} className="flex-row-view flex-wrap mb-3">
                      <p className="text-body text-textcolor/black leading-[30px] text-[20px]">
                        {list_style_type ?? "・"}
                      </p>
                      <p className="text-body text-textcolor/black leading-[30px] text-[20px] flex-1">
                        {line}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className="flex-row-el flex-center w-full gap-6 alpha">
                <button className="btn btn-blue" onClick={handleClose}>
                  <div className="flex-row-center gap-1 mb-[3px]">
                    <span className="material-symbols-outlined text-[36px]">
                      close
                    </span>
                    <p className="text-body text-white text-font-size-body1">
                      {is_parents
                        ? I18n.t("MSG_PARENTS_CANCEL_BTN")
                        : I18n.t("MSG_CANCEL_BTN")}
                    </p>
                  </div>
                </button>

                <button className="btn btn-danger" onClick={handleConfirm}>
                  <div className="flex-row-center gap-1 mb-[3px]">
                    <span className="material-symbols-outlined text-[36px]">
                      delete_forever
                    </span>
                    <p className="text-body text-white text-font-size-body1">
                      {is_parents
                        ? I18n.t("MSG_PARENTS_DELETE_BTN")
                        : I18n.t("MSG_CONFIRM_BTN")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
