import { useEffect } from "react";
import I18n from "i18n-js";
import Constants from "common/constant";
import { InitImageClickBlock, InitImageSetterXBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitImageClickBlock();
    InitImageSetterXBlock();
  }, []);

  return (
    <div className="flex-col-el flex-center absolute top-44">
      <div className="flex-row-el flex-center absolute -top-16">
        <p className="text-body text-white text-[20px]">
          {I18n.t("MSG_COURSE_COORDINATE_X")}
        </p>
      </div>

      <div className="flex-col-el flex-center w-[450px] h-[5px]">
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
          <p className="text text-white !mb-[35px]">0</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
          <p className="text text-white !mb-[35px]">50</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
          <p className="text text-white !mb-[35px]">100</p>
        </div>
        <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

        <div className="flex-row-el absolute -left-10 top-4 cursor-pointer">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
            className="w-[60px] h-[60px]"
          />
        </div>

        <div
          className="flex-row-el absolute -left-10 top-4"
          style={{ transform: `translateX(${100 * 4.5}px)` }}
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
            className="w-[60px] h-[60px] opacity-30"
          />
        </div>
      </div>
    </div>
  );
};
