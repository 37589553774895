import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  query,
  where,
  limit,
  orderBy,
  getDocs,
  collection,
  getFirestore,
} from "firebase/firestore";
import throttle from "lodash-es/throttle";
import { RootState } from "app/store";
import { getEnv } from "app/configSlice";
import { fetchAuthToken, parentsFirebase } from "app/Auth";
import { client } from "common/apiClient";
import { isDebugMode } from "common/utils";
import { UserType, AccountType } from "./types";

export const updateKidsLogin = async ({ kids_uid }: { kids_uid: string }) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/user/kids/${kids_uid}?env=${getEnv()}`
  );
};

export const createKidsAccount = async ({
  user,
}: {
  user: {
    account_type: AccountType;
    display_name: string;
    locale: string;
    env: string;
    parents_user_uid?: string;
  };
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post(
    `/v1/user/kids/account?debug=${isDebugMode}`,
    user
  );
};

export const createParentsAccount = async ({
  user,
}: {
  user: {
    uid?: string;
    email: string;
    password?: string;
    provider: string;
    display_name: string;
    icon?: string;
    locale: string;
    env: string;
    kids_main_uid: string;
  };
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post("/v1/user/parents/account", user);
};

export const addKidsToParentsAccount = async ({
  parents_uid,
  kids_main_uid,
}: {
  parents_uid: string;
  kids_main_uid: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post(
    `/v1/user/parents/${parents_uid}/kids/${kids_main_uid}`
  );
};

export const changeKidsUserType = async ({
  parents_uid,
  kids_uid,
  type,
  is_user_sign,
}: {
  parents_uid: string;
  kids_uid: string;
  type: UserType;
  is_user_sign: boolean;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/user/parents/${parents_uid}/kids/${kids_uid}/type/${type}?is_user_sign=${is_user_sign}`
  );
};

export const activeKidsUser = async ({ uid }: { uid: string }) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(`/v1/user/active/${uid}`);
};

export const activeParentsKidsUser = async ({
  parents_uid,
  kids_uid,
}: {
  parents_uid: string;
  kids_uid: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/user/parents/${parents_uid}/active/kids/${kids_uid}`
  );
};

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async ({ uid, env }: { uid: string; env: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/${uid}?env=${env}`
    );
    return response.data.data;
  }
);

export const getGroupUsers = createAsyncThunk(
  "user/getGroupUsers",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/${uid}/group/member`
    );
    return response.data;
  }
);

export const getDeviceUsers = async ({ uid }: { uid: string }) => {
  const token = await fetchAuthToken();
  return client({ token: token })
    .get(`/v1/user/device/${uid}`)
    .then((res) => res.data);
};

export const updateUserProfile = throttle(
  async ({
    uid,
    userInfo,
  }: {
    uid: string;
    userInfo: {
      icon?: string;
      name?: string;
      birthday?: string;
      gender?: string;
    };
  }) => {
    const token = await fetchAuthToken();
    return client({ token: token }).put(`/v1/user/profile/${uid}`, userInfo);
  },
  1000
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ main_uid, uid }: { main_uid: string; uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).delete(
      `/v1/user/${main_uid}/${uid}`
    );
    return response.data;
  }
);

export const deleteParentsKidsUser = async ({
  parents_uid,
  kids_uid,
  is_user_sign,
}: {
  parents_uid: string;
  kids_uid: string;
  is_user_sign: boolean;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).delete(
    `/v1/user/parents/${parents_uid}/kids/${kids_uid}?is_user_sign=${is_user_sign}`
  );
};

export const deleteParentsUser = async ({
  parents_uid,
  options,
  detail,
}: {
  parents_uid: string;
  options: string[];
  detail: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).delete(`/v1/user/parents/${parents_uid}`, {
    data: {
      options,
      detail,
    },
  });
};

export const fetchUserStatus = async ({ uid }: { uid: string }) => {
  const token = await fetchAuthToken();
  return client({ token: token }).get(`/v1/user/status/${uid}`);
};

export const getUserStatus = createAsyncThunk(
  "user/getUserStatus",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/status/${uid}`
    );
    return response.data.data;
  }
);

export const getUserHistory = createAsyncThunk(
  "user/getUserHistory",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/user/history/${uid}`
    );
    return response.data.data;
  }
);

export const markChatRoomMessage = createAsyncThunk<any, void>(
  "user/markChatRoomMessage",
  async (_, thunkAPI) => {
    const token = await fetchAuthToken();
    const { user } = thunkAPI.getState() as RootState;
    const chatRoomsReadAt = user.chatRoomMessageSync.ids
      .map((id) => user.chatRoomMessageSync.entities[id])
      .filter(
        (sync) =>
          sync.lastReadAt !== sync.latestReadAt ||
          (sync.lastReadAt === null && sync.latestReadAt !== null)
      )
      .map((sync) => ({
        chat_room_id: sync.id,
        uid: sync.uid,
        last_read_at: sync.latestReadAt,
      }));
    const response = await client({ token: token }).put(
      `/v1/chat/room/marked`,
      {
        chatRoomsReadAt,
      }
    );
    return response.data.data;
  }
);

export const getUserChatRooms = createAsyncThunk(
  "user/getUserChatRooms",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(`/v1/chat/room/${uid}`);
    return response.data.data;
  }
);

export const getChatRoomMessages = createAsyncThunk(
  "user/getChatRoomMessages",
  async ({
    roomId,
    lastMessageSyncAt,
    messageCount,
  }: {
    roomId: string;
    lastMessageSyncAt: string;
    messageCount: number;
  }) => {
    const db = getFirestore(parentsFirebase);
    const queryMessage = query(
      collection(db, "rooms", roomId, "messages"),
      where("created_at", "<", lastMessageSyncAt),
      orderBy("created_at", "desc"),
      limit(messageCount)
    );
    const querySnapshot = await getDocs(queryMessage);
    const messages = querySnapshot.docs.map((doc) => doc.data());
    return { messages };
  }
);

export const sendChatRoomMessage = async ({
  roomId,
  message,
}: {
  roomId: string;
  message: {};
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post(
    `/v1/chat/room/message/${roomId}`,
    message
  );
};

export const sendContactChatRoomMessage = async ({
  uid,
  roomId,
  message,
}: {
  uid: string;
  roomId: string;
  message: {};
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post(
    `/v1/contacts/message/${uid}/${roomId}`,
    message
  );
};

export const getUserAssets = async ({ uid }: { uid: string }) => {
  const token = await fetchAuthToken();
  const response = await client({ token: token }).get(
    `/v1/asset/user/${uid}/files`
  );
  return response.data;
};
