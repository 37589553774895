import { SVGProps } from "react";

export const IconAppName = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g
      stroke="#3F4A61"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      clipPath="url(#icon-app-name-a)"
    >
      <path fill="#55E3AD" d="M28.5 5.032 21 17.42h15L28.5 5.032Z" />
      <path fill="#FD8480" d="M29 36a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z" />
      <path fill="#70B1F4" d="M11 18a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z" />
      <path
        fill="#F9E532"
        d="M14.833 22.58H6.167C4.97 22.58 4 23.583 4 24.818v8.946C4 35 4.97 36 6.167 36h8.666C16.03 36 17 34.999 17 33.763v-8.946c0-1.235-.97-2.236-2.167-2.236Z"
      />
    </g>
    <defs>
      <clipPath id="icon-app-name-a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
