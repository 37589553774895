import { useMemo, useState, ReactNode } from "react";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import anime from "animejs";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { AppDispatch, RootState } from "app/store";
import { IconTrash } from "common/assets";
import { btnDelay, usePlaySound } from "common/utils";
import { SpinModal, DialogMessage, DeleteMessageBox } from "common/elements";
import {
  actions,
  selectAllProjects,
  selectProjectById,
} from "features/creator/slice";
import { CreateProjectModal } from "features/creator/elements";
import { copyProject, removeProject } from "features/creator/api";
import { DisplayMode, ProjectType } from "features/creator/types";
import { IconClose, IconCopy, AppUnselected } from "features/creator/assets";

const Background = () => (
  <div className="flex-col-el w-[250px] h-[194px] absolute z-50 pointer-events-none">
    <div className="flex-col-el w-full h-full rounded-[13px] border-solid border-[1px] border-[#000000]/40 p-1">
      <div className="flex-col-el w-full h-full rounded-lg border-solid border-[2px] border-[#000000]/30 overflow-hidden">
        <div className="flex-col-el w-full h-full rounded-[6px] border-solid border-[4px] border-[#FFFFFF]/40" />
      </div>
    </div>
  </div>
);

const Unselect = () => (
  <div className="flex-col-center gap-2">
    <AppUnselected />
    <p className="text-body text-[20px] text-gray/80">select app</p>
  </div>
);

const Enable = ({
  title,
  icon,
  color,
}: {
  title: string;
  icon: ReactNode;
  color: string;
}) => (
  <div className="flex-col-el flex-center">
    <div className="flex-col-center w-[40px] h-[40px]">{icon}</div>

    <div className="flex-col-center w-[84px] -mt-1">
      <p className={`text-body text-[20px] ${color}`}>{title}</p>
    </div>
  </div>
);

const Disable = ({ title, icon }: { title: string; icon: ReactNode }) => (
  <>
    <div
      className="flex-col-el flex-center w-[104px] h-[88px] bg-gray/30 rounded-lg"
      style={{
        filter:
          "drop-shadow(0px 2px 2px rgba(51, 51, 51, 0.15)) drop-shadow(-1px -1px 1px rgba(0, 0, 0, 0.1)) drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1))",
      }}
    >
      <div className="flex-col-el flex-center absolute z-10 w-[80px] h-[80px]">
        <IconClose fill="#A3ADB6" />
      </div>

      <div className="flex-col-center w-[40px] h-[40px]">{icon}</div>

      <div className="flex-col-center w-[84px] -mt-1">
        <p className="text-body text-[20px] text-white">{title}</p>
      </div>
    </div>

    <div className="flex-col-el absolute bottom-5">
      <p className="text-body text-font-caption2-narrow text-gray2/60">
        {I18n.t("MSG_CREATOR_APP_DISABLE_BTN")}
      </p>
    </div>
  </>
);

export const SubDisplay = ({ isUserProject }: { isUserProject: boolean }) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [copy, setCopy] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(false);
  const [published, setPublished] = useState(false);
  const projects = useSelector(selectAllProjects);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );
  const recommendProject = useSelector(
    (state: RootState) => state.creator.recommendProject
  );
  const selectedProject = useSelector((state: RootState) =>
    selectProjectById(state, selectedProjectId)
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.author_uid === user.active.uid &&
          !project.deleted_at &&
          project.type === ProjectType.BASIC
      ),
    [projects, user]
  );

  const handleDeleteProject = () => {
    if (isUserProject) {
      play();
      btnDelay(() => {
        if (selectedProject.published) {
          setPublished(true);
        } else {
          setDeleted(true);
        }
      });
    }
  };
  const handleDeleteProjectConfirm = () => {
    play();
    setDeleted(false);
    handleDialog({ type: DialogType.LOADING, value: true });
    btnDelay(() => {
      anime({
        targets: document.getElementById(
          `user-app-project-container-${selectedProjectId}`
        ),
        scale: 0.8,
        opacity: 0,
        duration: 400,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        dispatch(actions.updateDisplayMode(DisplayMode.NONE));
        dispatch(
          removeProject({
            uid: user.active.uid,
            project_id: selectedProjectId,
          })
        )
          .then(unwrapResult)
          .then(() =>
            toast.success(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_TOAST_DELETED_SUCCESS")}
                </p>
              </div>
            )
          )
          .catch((error) => {
            console.error(error);
            anime({
              targets: document.getElementById(
                `user-app-project-container-${selectedProjectId}`
              ),
              scale: 1,
              opacity: 1,
              duration: 400,
              easing: "easeInOutQuad",
            });
            toast.success(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_TOAST_FAILED")}
                </p>
              </div>
            );
          })
          .finally(() =>
            handleDialog({ type: DialogType.LOADING, value: false })
          );
      });
    });
  };
  const handleDeleteProjectCancel = () => {
    play();
    btnDelay(() => {
      setDeleted(false);
    });
  };

  const handleCopyProject = () => {
    play();
    btnDelay(() => {
      if (userProjects.length > 4) {
        setLimit(true);
      } else {
        setCopy(true);
      }
    });
  };
  const handleCloseGuidance = () => {
    play();
    btnDelay(() => {
      setLimit(false);
      setPublished(false);
    });
  };
  const handleCopyProjectConfirm = (name: string) => {
    play();
    setLoading(true);
    btnDelay(() => {
      setCopy(false);
      dispatch(
        copyProject({
          uid: user.active.uid,
          copied_project_id:
            recommendProject?.project_id ?? selectedProject?.project_id,
          name,
        })
      )
        .then(unwrapResult)
        .then(() => {
          if (!recommendProject) {
            dispatch(
              actions.updateProjectFavorited({
                project_id: selectedProject?.project_id,
                favorited: selectedProject?.favorited + 1,
                is_favorited: true,
              })
            );
            dispatch(actions.handleUpdateIsCopyProject(true));
          }
        })
        .finally(() => setLoading(false));
    });
  };
  const handleCopyProjectCancel = () => {
    play();
    btnDelay(() => {
      setCopy(false);
    });
  };

  return (
    <>
      {loading && <SpinModal />}

      <DialogMessage
        open={limit}
        title={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_MESSAGE")}
        close={handleCloseGuidance}
      />

      <DialogMessage
        open={published}
        title={I18n.t("MSG_CREATOR_APP_DELETE_PUBLISHED_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_DELETE_PUBLISHED_WARNING_MESSAGE")}
        close={handleCloseGuidance}
      />

      <CreateProjectModal
        visible={copy}
        name={recommendProject?.name ?? selectedProject?.name}
        type={recommendProject?.type ?? selectedProject?.type}
        create={handleCopyProjectConfirm}
        close={handleCopyProjectCancel}
      />

      <DeleteMessageBox
        visible={deleted}
        title={I18n.t("MSG_CREATOR_APP_DELETE_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_DELETE_WARNING_MESSAGE", {
          name: selectedProject?.name,
        })}
        close={handleDeleteProjectCancel}
        handleDelete={handleDeleteProjectConfirm}
      />

      <div className="flex-col-el flex-center relative w-[248px] h-[192px] bg-beige/60 border-solid border-gray/100 border-[6px] rounded-xl">
        <Background />

        {displayMode === DisplayMode.CAPTURE ||
        displayMode === DisplayMode.DETAIL ||
        Boolean(recommendProject) ? (
          <div className="flex-col-el flex-center w-full h-full">
            <div className="flex-col-el flex-center absolute top-0 w-[216px] h-[26px] bg-white rounded-b-3xl border-solid border-[2px] border-t-0 border-gray2/60">
              <p className="text-body text-gray/80 text-[18px]">
                カセットそうさ
              </p>
            </div>

            <div
              id="project-panel-delete-btn"
              className="flex-row-el flex-center h-full gap-4 physical"
            >
              {isUserProject && !selectedProject?.deleted_at ? (
                <button
                  id="creator-display-delete-btn"
                  onClick={handleDeleteProject}
                  className="btn w-[104px] h-[88px]"
                >
                  <Enable
                    title={I18n.t("MSG_CREATOR_MENU_CONTEXT_DELETE")}
                    icon={<IconTrash color="#FC6560" width={2} />}
                    color="text-red/100"
                  />
                </button>
              ) : (
                <div
                  id="creator-display-delete-btn"
                  className="flex-col-el items-center"
                >
                  <Disable
                    title={I18n.t("MSG_CREATOR_MENU_CONTEXT_DELETE")}
                    icon={<IconTrash color="#FFFFFF" width={2} />}
                  />
                </div>
              )}

              {(recommendProject?.enable_copied ??
                (selectedProject?.enable_copied &&
                  !selectedProject.deleted_at)) ||
              (isUserProject && !selectedProject.deleted_at) ? (
                <button
                  id="creator-display-copy-btn"
                  onClick={handleCopyProject}
                  className="btn w-[104px] h-[88px]"
                >
                  <Enable
                    title={I18n.t("MSG_CREATOR_MENU_CONTEXT_COPY")}
                    icon={<IconCopy stroke="#A54D21" />}
                    color="text-brown/100"
                  />
                </button>
              ) : (
                <div
                  id="creator-display-copy-btn"
                  className="flex-col-el items-center"
                >
                  <Disable
                    title={I18n.t("MSG_CREATOR_MENU_CONTEXT_COPY")}
                    icon={<IconCopy stroke="#FFFFFF" />}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <Unselect />
        )}
      </div>
    </>
  );
};
