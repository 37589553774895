import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/vehicle/bc572b5812e0937f8ed15e88321cc54c.png`,
            width: 30,
            height: 30,
            alt: "Mark",
          },
          "1",
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/vehicle/869a1175dc52418410e65a2e056c6ac7.png`,
            width: 30,
            height: 30,
            alt: "Mark",
          },
          "2",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "ComponentIndex")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          setRunning(true);
          let answerMark;
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          if(${result}){
            ${logic}
            setClickedIndex(clickedIndex + 1);
            if(clickedIndex > 0){
              setSuccess(!result.includes(false));
              setGameOver(true);
            } else {
              setRunning(false);
            };
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitLabelTextShowSetterBlock = () => {
  Blockly.Blocks["label_text_show_setter_block"] = {
    init: function () {
      this.jsonInit({
        type: "label_text_show_setter_block",
        message0: "%1に%2%{BKY_DISPLAY_EVENT}",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [["ボックス1", "1"]],
          },
          {
            type: "input_value",
            name: "value",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "logic_blocks",
      });
    },
  };
  BlocklyJs["label_text_show_setter_block"] = function (block: Blockly.Block) {
    const selectedLabelValue = block.getField("label").getValue();
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        target = document.getElementById("course-program-label-".concat('', ${selectedLabelValue}));
        target.innerText = ${value === "" ? "''" : value};
        target.style.opacity = "1";
        await anime({
          targets: target,
          scale: [1.5, 1],
          duration: 700,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        result[clickedIndex * 2 + Number(${selectedLabelValue})] = targetEles[clickedIndex * 2 + Number(${selectedLabelValue})] === Number(${value});
        if(result[clickedIndex * 2 + Number(${selectedLabelValue})]){
          answerMark = document.getElementById("course-program-answer-mark-wrong-2");
          if(answerMark){
            answerMark.style.opacity = "0";
          };
          answerMark = document.getElementById("course-program-answer-mark-right-2");
          if(answerMark){
            answerMark.style.opacity = "1";
          };
        } else {
          answerMark = document.getElementById("course-program-answer-mark-right-2");
          if(answerMark){
            answerMark.style.opacity = "0";
          };
          answerMark = document.getElementById("course-program-answer-mark-wrong-2");
          if(answerMark){
            answerMark.style.opacity = "1";
          };
          setSuccess(false);
          setGameOver(true);
          return;
        };
        setResult(result);
        await sleep(700);
        if(clickedIndex === 0){
          if(target instanceof HTMLParagraphElement){
            target.innerText = "";
            target.style.opacity = "0";
          };
          target = document.getElementById("course-program-answer-label-1");
          if(target instanceof HTMLParagraphElement){
            target.style.opacity = "0";
          };
          target = document.getElementById("course-program-answer-label-2");
          if(target instanceof HTMLParagraphElement){
            target.style.opacity = "0.3";
          };
          answerMark = document.getElementById("course-program-answer-mark-right-2");
          answerMark.style.opacity = "0";
          answerMark = document.getElementById("course-program-answer-mark-wrong-2");
          answerMark.style.opacity = "0";
        };
        highlightBlock("${block.id}", false);
    `;
  };
};

export const InitImageGetterXBlock = () => {
  Blockly.Blocks["image_getter_x_block"] = {
    init: function () {
      this.jsonInit({
        type: "image_getter_x_block",
        message0: "%{BKY_GETTER_X_COORDINATE}",
        args0: [
          {
            type: "field_dropdown",
            name: "vehicle",
            options: [
              [
                {
                  src: `${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`,
                  width: 30,
                  height: 30,
                  alt: "Vehicle",
                },
                "Vehicle",
              ],
            ],
          },
        ],
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["image_getter_x_block"] = function (block: Blockly.Block) {
    const code = `document.getElementById("course-program-image-1").dataset.value`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitImageSetterXBlock = () => {
  Blockly.Blocks["image_setter_x_block"] = {
    init: function () {
      this.jsonInit({
        type: "image_setter_x_block",
        message0: "%{BKY_COURSE_PROGRAM_SETTER_X_COORDINATE}",
        args0: [
          {
            type: "field_dropdown",
            name: "ball",
            options: [
              [
                {
                  src: `${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`,
                  width: 30,
                  height: 30,
                  alt: "Vehicle",
                },
                "Vehicle",
              ],
            ],
          },
          {
            type: "input_value",
            name: "value",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["image_setter_x_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
      highlightBlock("${block.id}", true);
      answerMark = document.getElementById("course-program-answer-mark-wrong-1");
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-right-1");
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      target = document.getElementById("course-program-image-1");
      if(target instanceof HTMLDivElement){
        target.dataset.value = ${Number(value)}
        await anime({
          targets: target,
          scale: 1.5,
          duration: 700,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        await anime({
          targets: target,
          left: ${Number(value) * 4.3 - 32},
          duration: 1000,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        await anime({
          targets: target,
          scale: 1,
          duration: 700,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
      }
      result[clickedIndex * 2] = targetEles[clickedIndex * 2] === ${value};
      if(result[clickedIndex * 2]){
        answerMark = document.getElementById("course-program-answer-mark-wrong-1");
        if(answerMark){
          answerMark.style.opacity = "0";
        };
        answerMark = document.getElementById("course-program-answer-mark-right-1");
        if(answerMark){
          answerMark.style.opacity = "1";
        };
        await sleep(700);
      } else {
        answerMark = document.getElementById("course-program-answer-mark-right-1");
        if(answerMark){
          answerMark.style.opacity = "0";
        };
        answerMark = document.getElementById("course-program-answer-mark-wrong-1");
        if(answerMark){
          answerMark.style.opacity = "1";
        };
        await sleep(700);
        setSuccess(false);
        setGameOver(true);
        return;
      };
      setResult(result);
      highlightBlock("${block.id}", false);
    `;
  };
};
