const ToolboxInfo = {
  maxInstances: {
    show_label_text_block: 2,
    show_image_block: 2,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "show_image_block",
          },
          {
            kind: "block",
            type: "show_label_text_block",
            inputs: {
              value: {
                shadow: {
                  type: "text",
                },
              },
            },
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
