import { sleep } from "common/utils";
import {
  tilesets,
  TILE_TYPE_NOTHING,
  TileMapType,
  isGround,
  isStep,
  isButton,
  checkMapTitleType,
} from "../../maps";
import { performMarkJitter } from "./mark";
import { calcDirection } from "./direction";
import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

const checkNextStep = (
  map: TileMapType,
  offset: Vector3,
  position: Vector3,
  direction: Vector3
): [boolean, number] => {
  if (!position) {
    return [false, 0];
  }

  const [
    currentTileType,
    ,
    nextStepSameLayerTileType,
    nextStepLowLayerTileType,
    ,
  ] = checkMapTitleType(map, offset, position, direction);

  if (
    (isStep(currentTileType) && isStep(nextStepSameLayerTileType)) ||
    (!isStep(currentTileType) &&
      (nextStepSameLayerTileType === TILE_TYPE_NOTHING ||
        isButton(nextStepSameLayerTileType)) &&
      isGround(nextStepLowLayerTileType))
  ) {
    // 次へ移動できる条件：
    // ・今階段にいない
    // ・次のステップの同じレイヤーには何もない、あるいは次のステップの同じレイヤーが地図範囲内、しかも次のステップの同じのレイヤはぶつかるものではない
    // ・次のステップの下のレイヤーはground

    let relativeOffset: number;

    if (!isButton(currentTileType) && isButton(nextStepSameLayerTileType)) {
      relativeOffset = tilesets[nextStepSameLayerTileType].size.y;
    } else if (
      isButton(currentTileType) &&
      !isButton(nextStepSameLayerTileType)
    ) {
      relativeOffset = -tilesets[currentTileType].size.y;
    } else {
      relativeOffset = 0;
    }

    return [true, relativeOffset];
  } else {
    return [false, 0];
  }
};

export const performJitter = async (
  scene: Scene,
  character: AbstractMesh,
  direction: Vector3,
  frames: number
) => {
  performMarkJitter(scene, character, frames);
  character.rotation.x -= 0.11;
  const characterMove = new Animation(
    "move",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );
  const initailPoisiton = character.position;
  const animationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: initailPoisiton
        .multiply(new Vector3(10, 10, 10))
        .add(
          new Vector3(
            (i % 2 === 0 ? 0.1 : -0.1) * 10 * direction.x,
            0,
            (i % 2 === 0 ? 0.1 : -0.1) * 10 * direction.z
          )
        )
        .divide(new Vector3(10, 10, 10)),
    });
  }
  characterMove.setKeys(animationKeys);
  scene.beginDirectAnimation(character, [characterMove], 0, frames, false);
  await sleep(1500);
};

export const performMove = async (
  scene: Scene,
  character: AbstractMesh,
  map: TileMapType,
  offset: Vector3,
  setSuccess: (success: boolean) => void,
  setGameOver: (over: boolean) => void
) => {
  if (scene.metadata["result"]) {
    if (scene && !scene.isDisposed) {
      scene.dispose();
    }
    await sleep(1500);
    setSuccess(false);
    setGameOver(true);
    return;
  }

  const jumpStep = 3;
  const jumpHeight = 0.3;
  const frames = jumpStep * 2;

  const direction = calcDirection(character);
  if (!direction) return;
  const [canNextStepMove, nextStepOffset] = checkNextStep(
    map,
    offset,
    character.position,
    direction
  );

  if (!canNextStepMove) {
    await performJitter(scene, character, direction, frames);
    if (scene && !scene.isDisposed) {
      scene.dispose();
    }
    setSuccess(false);
    setGameOver(true);
    return;
  }

  scene.getAnimationGroupByName("ArmLeftAction")?.start();
  scene.getAnimationGroupByName("ArmRightAction")?.start();

  const characterMove = new Animation(
    "move",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const moveStep = map.tileSize;
  const initailPoisiton = character.position;

  const animationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: initailPoisiton
        .multiply(new Vector3(10, 10, 10))
        .add(
          new Vector3(
            ((i * (moveStep * direction.x)) / (jumpStep * 2)) * 10,
            (jumpHeight * (i % 2) + (i > frames / 2 ? nextStepOffset : 0)) * 10,
            ((i * (moveStep * direction.z)) / (jumpStep * 2)) * 10
          )
        )
        .divide(new Vector3(10, 10, 10)),
    });
  }
  characterMove.setKeys(animationKeys);
  scene.beginDirectAnimation(character, [characterMove], 0, frames, false);
  await sleep(2000);
};
