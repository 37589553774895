import { useState, ChangeEventHandler } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { isIos, btnDelay, useScale } from "common/utils";
import { CloseBtn } from "./closeBtn";
import { FadeTransition } from "./Transition";

export const PromptInputNumber = (props: {
  defaultValue: number;
  message: string;
  confirm: (value: number) => void;
  cancel: () => void;
}) => {
  const { scale } = useScale();
  const { defaultValue, message, confirm, cancel } = props;
  const [value, setValue] = useState<number | string>(defaultValue);

  const handleConfirm = (value: number | string) => () =>
    btnDelay(() => {
      if (value === "") {
        confirm(defaultValue);
      } else {
        confirm(Number(value));
      }
    });

  const handleCancel = () => btnDelay(cancel);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length > 15) return;
    setValue(e.target.value);
  };

  return (
    <Dialog
      open
      sx={{ zIndex: 10001, pointerEvents: "auto" }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{
          filter:
            "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
        }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-el justify-start w-[510px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
            <CloseBtn close={handleCancel} />

            <div className="flex-col-view justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
              <div className="flex-row-view justify-start py-4">
                <p className="text-body text-textcolor/black text-[28px]">
                  {message}
                </p>
              </div>

              <div className="flex-col-el w-full relative my-4">
                <input
                  type="text"
                  value={value}
                  pattern={"[0-9]*"}
                  autoFocus={!isIos()}
                  onChange={handleOnChange}
                  className={`peer input-modal text-body text-textcolor/black text-[26px] invalid:border-danger`}
                />

                <label className="text-body text-left text-[14px] h-5 text-danger invisible peer-invalid:visible">
                  {I18n.t("MSG_BLOCKLY_INPUT_NUMBER_LIMIT")}
                </label>
              </div>

              <div className="flex-row-el flex-center alpha">
                <button
                  className="btn btn-primary"
                  onClick={handleConfirm(value)}
                  disabled={isNaN(Number(value))}
                >
                  <p className="text-body text-white text-[24px] !mb-[3px]">
                    {I18n.t("MSG_CONFIRM_BTN")}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
