import { useEffect } from "react";
import { InitStartBlock } from "features/courses/program/game/blockly";
import { InitShowLabelTextBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitStartBlock();
    InitShowLabelTextBlock();
  }, []);

  return (
    <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
      <p
        className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
        style={{
          filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
        }}
      >
        あ
      </p>
    </div>
  );
};
