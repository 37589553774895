import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Blockly from "blockly";
import { Dialog } from "@mui/material";
import { Scene } from "@babylonjs/core/scene";
import { Vector3, Matrix } from "@babylonjs/core/Maths/math.vector";
import { RootState, AppDispatch } from "app/store";
import { FadeTransition } from "common/elements";
import { useScale, usePlaySound, btnDelay } from "common/utils";
import { Course } from "features/courses/types";
import { actions } from "features/courses/coursesSlice";
import Steps from "features/courses/algorithm/game/tips/steps";
import { updateAlgorithmLevelTips } from "features/courses/api";

export const TipsPage = (props: {
  stage: number;
  step: number;
  scene: Scene;
  blocklyFlyoutWidth: number;
  workspace: Blockly.WorkspaceSvg;
}) => {
  const { stage, step, scene, blocklyFlyoutWidth, workspace } = props;
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const [tipsStep, setTipsStep] = useState(0);
  const [showTips, setShowTips] = useState(true);
  const courseDisplay = useSelector(
    (state: RootState) => state.courses.message[Course.ALGORITHM][stage - 1]
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const message = courseDisplay
    ? courseDisplay[config.locale]?.level[step - 1].tips[tipsStep]
    : "";
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  // const blocks = workspace ? workspace.getAllBlocks(false).length : 0;
  const blocks = workspace
    ? workspace.getAllBlocks(true).filter((block) => block.isEnabled()).length
    : 0;

  useEffect(() => {
    if (tipsStep < 6 && blocks === 2) {
      setTipsStep(6);
      setShowTips(true);
    } else if (tipsStep < 9 && blocks === 4) {
      setTipsStep(9);
      setShowTips(true);
    }
  }, [blocks, tipsStep]);

  useEffect(() => {
    if (scene) {
      setTimeout(() => {
        const canvas = document.getElementById("app-canvas");
        const transformMatrix = scene.getTransformMatrix();
        const camera = scene.getCameraByName("camera");
        const character = scene.getMeshByName("character");
        if (camera && character) {
          const viewport = camera.viewport.toGlobal(
            canvas.clientWidth,
            canvas.clientHeight
          );
          const coordinates = Vector3.Project(
            character.position,
            Matrix.Identity(),
            transformMatrix,
            viewport
          );

          setCoordinates({
            x: coordinates.x + blocklyFlyoutWidth + 120 * scale,
            y: coordinates.y + 120 * scale,
          });
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene]);

  const handleBack = () => {
    play();
    btnDelay(() => setTipsStep(tipsStep - 1));
  };

  const handleMove = () => {
    play();
    btnDelay(() => setTipsStep(tipsStep + 1));
  };

  const handleCloseTips = () => {
    play();
    setShowTips(false);
    const tipsLength = courseDisplay
      ? courseDisplay[config.locale]?.level[step - 1].tips.length - 1
      : 0;
    if (tipsStep === tipsLength) {
      dispatch(actions.updateAlgorithmLevelTips({ stageId: 1, stepId: 1 }));
      updateAlgorithmLevelTips({
        uid: user.active.uid,
        stageId: 1,
        stepId: 1,
      });
    }
  };

  const Component = Steps[tipsStep];
  return (
    <Dialog
      fullScreen
      open={showTips}
      maxWidth={false}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: { backgroundColor: "transparent", overflow: "hidden" },
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-view w-full h-full">
        <Component
          message={message}
          handleBack={handleBack}
          handleMove={handleMove}
          close={handleCloseTips}
          characterCoordinates={coordinates}
        />
      </div>
    </Dialog>
  );
};
