import { PayloadAction } from "@reduxjs/toolkit";
import { ProjectReaction } from "features/reaction/types";
import { CreatorInitialState } from "features/creator/types";
import { projectsAdapter } from "./adapter";

const reducers = {
  updateProjectFavorited(
    state: CreatorInitialState,
    action: PayloadAction<{
      project_id: string;
      favorited: number;
      is_favorited: boolean;
    }>
  ) {
    projectsAdapter.updateOne(state.projects, {
      id: action.payload.project_id,
      changes: {
        favorited: action.payload.favorited,
        is_favorited: action.payload.is_favorited,
      },
    });
  },
  updateProjectReaction(
    state: CreatorInitialState,
    action: PayloadAction<{
      project_id: string;
      reaction: ProjectReaction;
    }>
  ) {
    projectsAdapter.updateOne(state.projects, {
      id: action.payload.project_id,
      changes: {
        reaction: action.payload.reaction,
      },
    });
  },
  updateEditingProjectId(
    state: CreatorInitialState,
    action: PayloadAction<{ project_id: string }>
  ) {
    state.editingProjectId = action.payload.project_id;
  },
};

export default reducers;
