import { useSelector } from "react-redux";
import i18n from "i18n-js";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { IconBrush } from "common/assets/icons";
import { CreatorOptions } from "features/creator/types";
import { ToolPanel } from "features/creator/drawing/ToolPanel";

export const DrawingToolBar = (props: { options: CreatorOptions }) => {
  const { scale } = useScale();
  const { margin, tabHeight } = props.options;
  const drawing = useSelector((state: RootState) => state.creator.drawing);

  return (
    <div
      className="flex-row-view !absolute top-0 left-0"
      style={{
        zIndex: drawing.enable ? 10002 : 1,
      }}
    >
      <div
        className="flex-row-view items-start origin-top-left !absolute top-0 left-0 drop-shadow-normal_show"
        style={{
          height: tabHeight,
          top: margin * scale,
          left: 450 * scale,
          transform: `scale(${scale})`,
        }}
      >
        <div className="flex-col-center w-[80px] h-[74px] p-[4px] rounded-l-[12px] bg-beige/alt">
          <div className="flex-col-center w-[24px] h-[24px] mb-[4px]">
            <IconBrush color="#FEFDFE" />
          </div>
          <p className="text w-full text-white !text-[16px] !leading-[18px]">
            {i18n.t("MSG_CREATOR_MENU_DRAWING_BTN")}
          </p>
        </div>
        <ToolPanel />
      </div>
    </div>
  );
};
