import { SVGProps } from "react";

export const IconCopiedEnable = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
    <g clipPath="url(#icon-copied-enable-a)">
      <path
        fill={props.fill ?? "#FEFDFE"}
        d="M27 12V9.5A3.5 3.5 0 0 0 23.5 6h-14A3.5 3.5 0 0 0 6 9.5v14A3.5 3.5 0 0 0 9.5 27H13"
      />
      <path
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M27 12V9.5A3.5 3.5 0 0 0 23.5 6h-14A3.5 3.5 0 0 0 6 9.5v14A3.5 3.5 0 0 0 9.5 27H13"
      />
      <path
        fill={props.fill ?? "#FEFDFE"}
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M30.5 12h-14c-1.933 0-3.5 1.642-3.5 3.667v14.666C13 32.358 14.567 34 16.5 34h14c1.933 0 3.5-1.642 3.5-3.667V15.667C34 13.642 32.433 12 30.5 12Z"
      />
      <path
        fill="#3F4A61"
        d="M21.248 22.89c0-2.427-.887-3.64-2.66-3.64-1.773 0-2.66 1.213-2.66 3.64s.887 3.64 2.66 3.64c1.773 0 2.66-1.213 2.66-3.64Zm.742 3.864c-.83.924-1.964 1.386-3.402 1.386-1.437 0-2.576-.462-3.416-1.386-.83-.924-1.246-2.212-1.246-3.864 0-1.652.415-2.94 1.246-3.864.84-.924 1.979-1.386 3.416-1.386 1.438 0 2.572.462 3.402 1.386.84.924 1.26 2.212 1.26 3.864 0 1.652-.42 2.94-1.26 3.864ZM25.621 28a.79.79 0 0 1-.784-.798v-8.624a.79.79 0 0 1 .784-.798H26c.215 0 .402.08.56.238a.766.766 0 0 1 .238.56v3.626c0 .01.005.014.014.014.01 0 .014-.005.014-.014l2.814-3.78a1.47 1.47 0 0 1 1.26-.644h.7c.16 0 .276.075.35.224.075.15.061.285-.042.406l-3.22 4.172a.155.155 0 0 0 0 .196l3.374 4.578c.103.13.117.27.042.42a.379.379 0 0 1-.364.224h-.7c-.532 0-.947-.215-1.246-.644l-2.968-4.2c0-.01-.004-.014-.014-.014-.009 0-.014.005-.014.014v4.046c0 .215-.079.401-.238.56A.766.766 0 0 1 26 28h-.378Z"
      />
    </g>
    <defs>
      <clipPath id="icon-copied-enable-a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconCopiedDisable = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
    <g clipPath="url(#icon-copied-disable-a)">
      <path
        fill={props.fill ?? "#FEFDFE"}
        d="M27 12V9.5A3.5 3.5 0 0 0 23.5 6h-14A3.5 3.5 0 0 0 6 9.5v14A3.5 3.5 0 0 0 9.5 27H13"
      />
      <path
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M27 12V9.5A3.5 3.5 0 0 0 23.5 6h-14A3.5 3.5 0 0 0 6 9.5v14A3.5 3.5 0 0 0 9.5 27H13"
      />
      <path
        fill={props.fill ?? "#FEFDFE"}
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M30.5 12h-14c-1.933 0-3.5 1.642-3.5 3.667v14.666C13 32.358 14.567 34 16.5 34h14c1.933 0 3.5-1.642 3.5-3.667V15.667C34 13.642 32.433 12 30.5 12Z"
      />
      <path
        fill={props.stroke ?? "#FEFDFE"}
        d="M1.57 5.803 5.802 1.57 38.36 34.129l-4.233 4.233z"
      />
      <path
        fill="#3F4A61"
        d="m2.449 4.923 2.474-2.475 32.56 32.559-2.476 2.475z"
      />
    </g>
    <defs>
      <clipPath id="icon-copied-disable-a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
