import { useState, useEffect, TouchEventHandler } from "react";
import I18n from "i18n-js";
import { Fade } from "@mui/material";
import { useRecordEvent } from "app/hook";
import { AnalyticsEventType } from "app/types";
import Constants from "common/constant";
import { CloseBtn } from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";
import { Shape } from "./shape";

export const ParentGate = (props: { visible: boolean; close: () => void }) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const { visible, close } = props;
  const [show, setShow] = useState(true);
  const handleRecordEvent = useRecordEvent();
  const [success, setSuccess] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const [showVersion, setShowVersion] = useState(false);

  useEffect(() => {
    setShow(true);
    setShowVersion(false);
  }, [visible]);

  const handleTouchStart: TouchEventHandler<HTMLImageElement> = (e) =>
    setTimestamp(e.timeStamp);

  const handleTouchEnd: TouchEventHandler<HTMLImageElement> = (e) => {
    const elapsed = (e.timeStamp - timestamp) / 1000;
    if (elapsed > 3) {
      setShowVersion(true);
    }
    setTimestamp(0);
  };

  const handleClose = () => {
    btnDelay(() => close());
  };

  const handleSuccess = () => {
    play();
    setShow(false);
    setSuccess(false);
    handleRecordEvent(AnalyticsEventType.PARENT_GATE_SUCCESS_BTN_CLICK);
  };

  return (
    <Fade in={visible && show} timeout={{ exit: 600 }}>
      <div className="flex-col-center !absolute inset-0 bg-textcolor/black/50 w-full h-full ">
        <div
          className="flex-col-center w-[1180px] h-[800px] rounded-[8px] border-[8px] border-solid border-white bg-white"
          style={{ transform: `scale(${scale})` }}
        >
          <CloseBtn close={handleClose} />
          <div className="flex-col-center w-full h-full rounded-[4px] bg-beige/60">
            <div className="flex-row-center">
              <div className="flex-col-view">
                <p className="text !text-[25px] !leading-[37px] text-textcolor/black text-left">
                  {I18n.t("MSG_PARENT_GATE_WARNING")}
                </p>
              </div>
              <img
                alt="*"
                src={`${Constants.assetHost}/assets/images/duck_attention.png`}
                className="w-[208px] h-[240px] pointer-events-auto"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              />
            </div>

            <div className="flex-col-center">
              <div className="flex-col-center w-[568px] h-[58px] bg-white border-[#B7714D] mb-[20px] border-y-[4px]">
                <p className="text !text-[28px] !leading-[42px] text-textcolor/black text-left">
                  {I18n.t("MSG_PARENT_GATE_TITLE")}
                </p>
              </div>
              <div className="flex-col-view">
                <p className="text !leading-[30px] text-textcolor/black text-left">
                  {I18n.t("MSG_PARENT_GATE_DESCRIPTION")}
                </p>
              </div>

              <div className="flex-col-view w-[494px] h-[167px] mt-[20px] mb-[32px]">
                <Shape setSuccess={setSuccess} />
              </div>

              <div className="flex-row-view items-center justify-around w-full">
                <div className="flex-col-el flex-center relative alpha">
                  <button className="btn btn-blue" onClick={handleClose}>
                    <p className="text-body text-[24px] text-white !mb-[3px]">
                      {I18n.t("MSG_PARENT_GATE_CLOSE_BTN")}
                    </p>
                  </button>
                </div>

                <div className="flex-col-el flex-center relative alpha">
                  <button
                    className="btn btn-primary"
                    onClick={handleSuccess}
                    disabled={!success}
                  >
                    <p className="text-body text-[24px] text-white !mb-[3px]">
                      {I18n.t("MSG_PARENT_GATE_CONFIRM_BTN")}
                    </p>
                  </button>
                </div>
              </div>
            </div>

            {/* Easter egg message */}
            {showVersion && (
              <div className="flex-row-el absolute bottom-0">
                <p className="text text-textcolor/black !leading-[30px]">
                  app version{window.kidsAppVersion}({window.kidsBuildVersion})
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
};
