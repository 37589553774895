import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { useScale } from "common/utils";
import Constants from "common/constant";
import { IconRight, IconClose } from "common/assets";
import { CloseBtnOuter, FadeTransition } from "common/elements";
import { TabletCapture } from "features/creator/tutorial/top/TabletCapture";
import { GuideMessageBalloonLarge } from "features/creator/tutorial/top/stages/Layout";

const menus = [
  () => (
    <div className="flex-row-el flex-center gap-1">
      <div className="flex-row-el flex-center px-2 bg-orange/100 rounded-[8px]">
        <p className="text-body text-[20px] leading-8 text-white">
          {I18n.t("MSG_CREATOR_APP_MY_APPS")}
        </p>
      </div>

      <p className="text-body text-[20px] text-textcolor/black">について</p>
    </div>
  ),
  () => (
    <div className="flex-row-el flex-center gap-1">
      <div className="flex-row-el flex-center px-2 bg-blue/100 rounded-[8px]">
        <p className="text-body text-[20px] leading-8 text-white">
          {I18n.t("MSG_CREATOR_APP_SHARED_APPS")}
        </p>
      </div>

      <p className="text-body text-[20px] text-textcolor/black">について</p>
    </div>
  ),
  () => (
    <div className="flex-row-el flex-center gap-1">
      <TabletCapture scale={0.05} />
      <p className="text-body text-[20px] text-textcolor/black">
        {I18n.locale === "ja" ? "タブレットの そうさ" : "タブレットの操作"}
      </p>
    </div>
  ),
  () => (
    <div className="flex-row-center gap-1">
      <div className="flex-col-center">
        <img
          alt="app card"
          src={`${Constants.assetHost}/assets/images/app_card_user.png`}
          className="w-[30px] h-[36px]"
        />

        <div className="flex-col-el flex-center absolute top-[8px] w-full px-[2px] h-[24px]">
          <div className="w-full h-full bg-white" />
        </div>
      </div>
      <div className="flex-row-el flex-center gap-1">
        <p className="text-body text-[20px] text-textcolor/black text-left">
          {I18n.locale === "ja" ? "アプリの つくりかた" : "アプリの作り方"}
        </p>
      </div>
    </div>
  ),
];

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  const { width, height, scale } = useScale();

  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 10000 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: {
          style: {
            opacity: 0.35,
            backgroundColor: "black",
            pointerEvents: "none",
          },
        },
      }}
      PaperProps={{
        style: {
          overflow: "visible",
          margin: 0,
          maxWidth: "none",
          maxHeight: "none",
          transform: `scale(${scale})`,
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-center w-full h-full">
        <div
          className="flex-col-el flex-center absolute top-0 left-0"
          style={{
            transform: `translate(${width / 2 - 814 / 2}px, ${
              height / 2 - 632 / 2
            }px)`,
          }}
        >
          <div className="flex-col-el absolute top-14 right-14">
            <CloseBtnOuter close={close} />
          </div>

          <div className="flex-col-el">
            <GuideMessageBalloonLarge />
          </div>

          <div className="flex-col-el flex-center w-full h-full gap-8 absolute">
            <div className="flex-col-el flex-center gap-4 mt-9">
              <p className="text-body text-textcolor/black text-[22px]">
                このガイドでは、このあと
              </p>
              <div className="flex-col-center border-[4px] border-solid border-blue/60 px-8 py-4 gap-4 rounded-2xl">
                {menus.map((Item, index) => (
                  <div
                    key={index}
                    className="flex-row-el items-center justify-start w-full p-0 gap-3"
                  >
                    <div className="flex-col-el flex-center bg-orange/100 rounded-full border-solid border-[4px] border-orange/100 px-1 w-[32px] h-[32px]">
                      <p className="text-body text-[22px] text-white leading-[48px]">
                        {index + 2}
                      </p>
                    </div>

                    <Item />
                  </div>
                ))}
              </div>
              <p className="text-body text-center whitespace-pre text-textcolor/black text-[22px]">
                {I18n.locale === "ja"
                  ? "について じゅんばんに しょうかい します。\nこのまま ガイドをつづけますか？\n（あとで ガイドを みることも できます）"
                  : "について順番に紹介します。\nこのままガイドを続けますか？\n（あとでガイドを見ることもできます）"}
              </p>
            </div>

            <div className="flex-row-el flex-center gap-4 w-full">
              <div className="flex-row-el flex-center upsilon">
                <button className="btn btn-blue !px-6" onClick={close}>
                  <div className="flex-row-el flex-center relative mb-[3px]">
                    <div className="flex-col-center w-[32px] h-[32px]">
                      <IconClose />
                    </div>
                    <p className="text-body text-white text-[20px] !mr-2">
                      {I18n.t("MSG_CREATOR_TOP_TUTORIAL_TIPS_LATER_BTN")}
                    </p>
                  </div>
                </button>
              </div>
              <div className="flex-row-el flex-center upsilon">
                <button className="btn btn-primary !px-6" onClick={next}>
                  <div className="flex-row-el flex-center relative mb-[3px]">
                    <p className="text-body text-white text-[20px] !ml-2">
                      {I18n.t("MSG_CREATOR_TOP_TUTORIAL_TIPS_CONTINUE_BTN")}
                    </p>
                    <div className="flex-col-center w-[32px] h-[32px]">
                      <IconRight />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Step;
