import { SVGProps } from "react";

export const IconProgress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.75 6h-4.5a2.4 2.4 0 0 0-1.591.586A1.896 1.896 0 0 0 33 8.001V42h9V8c0-.53-.237-1.039-.659-1.414A2.4 2.4 0 0 0 39.75 6Zm-13.5 12.005h-4.5a2.4 2.4 0 0 0-1.591.586 1.896 1.896 0 0 0-.659 1.415V42h9V20.006c0-.53-.237-1.04-.659-1.415a2.4 2.4 0 0 0-1.591-.586Zm-13.5 12.006h-4.5a2.4 2.4 0 0 0-1.591.586A1.896 1.896 0 0 0 6 32.012V42h9v-9.988c0-.531-.237-1.04-.659-1.415a2.4 2.4 0 0 0-1.591-.586Z"
      fill="#94C4F7"
      stroke="#667786"
      strokeWidth={2.5}
    />
  </svg>
);
