import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  User,
  getAuth,
  Unsubscribe,
  signInAnonymously,
  onAuthStateChanged,
  signInWithCustomToken,
} from "firebase/auth";
import I18n from "i18n-js";
import { customAlphabet } from "nanoid";
import { RootState } from "app/store";
import { LoadAsset } from "app/LoadAsset";
import { useDialog, useFetchUserData } from "app/hook";
import { isDev } from "common/utils";
import { SpinModal } from "common/elements";
import { AccountType } from "features/user/types";
import { createKidsAccount } from "features/user/api";
import { DialogType } from "./types";
import { getEnv } from "./configSlice";
import { connectEmulator } from "./api";
import { parentsFirebase } from "./Auth";

export const AuthProvider = (props: { children: React.ReactNode | null }) => {
  const handleDialog = useDialog();
  const [loading, setLoading] = useState(true);
  const [kidsAuthing, setKidsAuthing] = useState(true);
  const [latestVersion, setLatestVersion] = useState(false);
  const [parentsAuthing, setParentsAuthing] = useState(true);
  const [parentsUser, setParentsUser] = useState<User>(null);
  const resign = useSelector((state: RootState) => state.config.resign);
  const { handleFetchParentsUser, handleFetchKidsUser } = useFetchUserData();

  useEffect(() => {
    if (isDev) {
      const auth = getAuth();
      const parentsAuth = getAuth(parentsFirebase);
      connectEmulator(auth, parentsAuth);
    }
  }, []);

  useEffect(() => {
    let parentsUnsubscribe: Unsubscribe;
    if (latestVersion) {
      const auth = getAuth(parentsFirebase);
      parentsUnsubscribe = onAuthStateChanged(
        auth,
        async (user: User) => {
          setParentsUser(user);
          if (user && !user?.isAnonymous) {
            await handleFetchParentsUser({
              uid: user.uid,
              callback: () => setParentsAuthing(false),
            });
          } else if (user?.isAnonymous) {
            setParentsAuthing(false);
          } else {
            await signInAnonymously(auth);
          }
        },
        (error) => console.error(JSON.stringify(error))
      );
    }

    return () => {
      if (parentsUnsubscribe) {
        parentsUnsubscribe();
      }
    };
  }, [latestVersion]);

  useEffect(() => {
    let kidsUnsubscribe: Unsubscribe;
    if (latestVersion) {
      const auth = getAuth();
      kidsUnsubscribe = onAuthStateChanged(
        auth,
        async (user: User) => {
          if (parentsUser?.isAnonymous) {
            if (user) {
              console.log("fetch kids user.");
              try {
                await handleFetchKidsUser({
                  uid: user.uid,
                  callback: () => setKidsAuthing(false),
                  fallback: async () => {
                    await auth.signOut();
                  },
                });
              } catch (error) {
                console.log(error);
              }
            } else {
              try {
                console.log("create kids account.");
                const random = customAlphabet("1234567890", 7)();
                const response = await createKidsAccount({
                  user: {
                    account_type: AccountType.GUEST,
                    display_name: I18n.t("MSG_USER_PROFILE_DEFAULT_NAME", {
                      random: random,
                    }),
                    locale: I18n.locale,
                    env: getEnv(),
                  },
                });
                await signInWithCustomToken(auth, response.data.idToken);
              } catch (error) {
                console.error(error);
                handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
              }
            }
          } else if (parentsUser) {
            console.log("go to parents user.");
            setKidsAuthing(false);
          } else {
            console.log("feel free.");
          }
        },
        (error) => console.error(JSON.stringify(error))
      );
    }

    return () => {
      if (kidsUnsubscribe) {
        kidsUnsubscribe();
      }
    };
  }, [latestVersion, parentsUser]);

  return (
    <>
      <LoadAsset
        authing={kidsAuthing || parentsAuthing}
        loading={loading}
        setLoading={setLoading}
        setLatestVersion={setLatestVersion}
      />
      {resign && <SpinModal />}
      {loading || kidsAuthing || parentsAuthing ? <></> : props.children}
    </>
  );
};
