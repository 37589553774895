/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n-js";
import { RootState } from "app/store";
import { useScale, usePlaySound } from "common/utils";
import {
  IconSize,
  IconColor,
  IconLogic,
  IconBasic,
  IconPosition,
  IconProperty,
  IconAppearance,
  IconSizeDisable,
  IconPositionDisable,
  IconAppearanceDisable,
} from "features/creator/property/components";
import { actions } from "features/creator/slice";
import { Panel } from "features/creator/property/Panel";
import { CreatorOptions } from "features/creator/types";
import { TabIndex } from "features/creator/property/types";

export const Property = (props: { options: CreatorOptions }) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const { margin, tabHeight } = props.options;
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const collapse = useSelector(
    (state: RootState) => state.creator.panelCollapse.property
  );
  const blocklyCollapse = useSelector(
    (state: RootState) => state.creator.panelCollapse.blocklyWorkspace
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<TabIndex>(
    TabIndex.NONE
  );
  const template = useSelector((state: RootState) => state.creator.template);

  useEffect(() => {
    if (collapse && blocklyCollapse) {
      setSelectedTabIndex(TabIndex.NONE);
    }
  }, [collapse, blocklyCollapse]);

  useEffect(() => {
    if (!blocklyCollapse) {
      setSelectedTabIndex(TabIndex.LOGIC);
    } else {
      setSelectedTabIndex(TabIndex.NONE);
      dispatch(actions.handlePropertyCollapse(true));
    }
  }, [selectedComponentId]);

  const handleTabClick = (index: TabIndex) => () => {
    play();
    dispatch(actions.handleComponentListCollapse(true));
    dispatch(actions.handleMaterialPanelCollapse(true));
    if (index === selectedTabIndex) {
      if (index === TabIndex.LOGIC) {
        dispatch(actions.handleBlocklyWorkspaceCollapse(true));
      }
      dispatch(actions.handlePropertyCollapse(true));
      setSelectedTabIndex(TabIndex.NONE);
    } else {
      if (index === TabIndex.LOGIC) {
        dispatch(actions.handleBlocklyWorkspaceCollapse(false));
        dispatch(actions.handlePropertyCollapse(true));
      } else {
        dispatch(actions.handleBlocklyWorkspaceCollapse(true));
        dispatch(actions.handlePropertyCollapse(false));
      }
      setSelectedTabIndex(index);
    }
  };

  return (
    <div
      id="property-tab-panel"
      className="flex-col-center w-full z-[1] !absolute left-0 bottom-0"
      style={{
        height: tabHeight * scale,
      }}
    >
      <div
        className="flex-col-view !absolute"
        style={{
          bottom: (tabHeight + margin + 10) * scale,
          visibility: collapse ? "hidden" : "visible",
        }}
      >
        <Panel selectedTabIndex={selectedTabIndex} />
      </div>

      <div
        className="flex-row-view origin-bottom !absolute items-end justify-start drop-shadow-normal_show"
        style={{
          bottom: margin * scale,
          height: tabHeight,
          transform: `scale(${scale})`,
        }}
      >
        <div className="flex-col-center w-[80px] h-[74px] p-[4px] rounded-l-[12px] bg-beige/alt">
          <div className="flex-col-center w-[24px] h-[24px] mb-[4px]">
            <IconProperty />
          </div>
          <p className="text w-full text-white !text-[16px] !leading-[18px]">
            {i18n.t("MSG_CREATOR_MENU_PROPERTY_BTN")}
          </p>
        </div>

        <div className="flex-row-center h-full bg-beige/60 rounded-r-[12px] border-[4px] !border-l-0 border-solid border-white">
          <div
            className={`flex-col-center property__panel__tab-button ${
              selectedTabIndex === TabIndex.BASIC
                ? "property__panel__tab-button__selected"
                : ""
            }`}
            onClick={handleTabClick(TabIndex.BASIC)}
          >
            <div className="flex-col-view property__panel__tab-icon">
              <IconBasic />
            </div>
            <p className="text text-textcolor/black !text-[16px] !leading-[18px]">
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_BASIC")}
            </p>
          </div>

          <button
            className={`flex-col-el flex-center px-0 border-none property__panel__tab-button bg-transparent ${
              selectedTabIndex === TabIndex.POSITION &&
              "property__panel__tab-button__selected"
            }`}
            disabled={selectedComponentId === selectedScreenId}
            onClick={handleTabClick(TabIndex.POSITION)}
          >
            <div className="flex-col-view property__panel__tab-icon">
              {selectedComponentId === selectedScreenId ? (
                <IconPositionDisable />
              ) : (
                <IconPosition />
              )}
            </div>
            <p
              className={`text !text-[16px] !leading-[18px] ${
                selectedComponentId === selectedScreenId
                  ? "text-gray2/80"
                  : "text-textcolor/black"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_POSITION")}
            </p>
          </button>

          <button
            className={`flex-col-el flex-center px-0 border-none property__panel__tab-button bg-transparent ${
              selectedTabIndex === TabIndex.SIZE &&
              "property__panel__tab-button__selected"
            }`}
            disabled={selectedComponentId === selectedScreenId}
            onClick={handleTabClick(TabIndex.SIZE)}
          >
            <div className="flex-col-view property__panel__tab-icon">
              {selectedComponentId === selectedScreenId ? (
                <IconSizeDisable />
              ) : (
                <IconSize />
              )}
            </div>
            <p
              className={`text !text-[16px] !leading-[18px] ${
                selectedComponentId === selectedScreenId
                  ? "text-gray2/80"
                  : "text-textcolor/black"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_SIZE")}
            </p>
          </button>

          <button
            className={`flex-col-el flex-center px-0 border-none property__panel__tab-button bg-transparent ${
              selectedTabIndex === TabIndex.APPEARANCE &&
              "property__panel__tab-button__selected"
            }`}
            disabled={selectedComponentId === selectedScreenId}
            onClick={handleTabClick(TabIndex.APPEARANCE)}
          >
            <div className="flex-col-view property__panel__tab-icon">
              {selectedComponentId === selectedScreenId ? (
                <IconAppearanceDisable />
              ) : (
                <IconAppearance />
              )}
            </div>
            <p
              className={`text !text-[16px] !leading-[18px] ${
                selectedComponentId === selectedScreenId
                  ? "text-gray2/80"
                  : "text-textcolor/black"
              }`}
            >
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_APPEARANCE")}
            </p>
          </button>

          <div
            className={`flex-col-center property__panel__tab-button ${
              selectedTabIndex === TabIndex.COLOR
                ? "property__panel__tab-button__selected"
                : ""
            }`}
            onClick={handleTabClick(TabIndex.COLOR)}
          >
            <div className="flex-col-view property__panel__tab-icon">
              <IconColor />
            </div>
            <p className="text text-textcolor/black !text-[16px] !leading-[18px]">
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_COLOR")}
            </p>
          </div>

          <div className="w-[2px] h-full bg-beige/alt" />
          <div className="w-[2px] h-full bg-white" />

          <div
            className={`flex-col-center rounded-r-[8px] property__panel__tab-button !w-[100px] ${
              selectedTabIndex === TabIndex.LOGIC
                ? "property__panel__tab-button__selected"
                : ""
            }`}
            onPointerDown={handleTabClick(TabIndex.LOGIC)}
          >
            {template.newBlockIds.length > 0 && (
              <div className="w-[20px] h-[20px] bg-green/altcolor rounded-full absolute right-4 top-px z-10" />
            )}

            <div className="flex-col-view property__panel__tab-icon">
              <IconLogic />
            </div>
            <p className="text text-textcolor/black !text-[16px] !leading-[18px]">
              {i18n.t("MSG_CREATOR_MENU_PROPERTY_LOGIC")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
