import { Event } from "./Event";
import { Basic } from "./Basic";
import { Size } from "./Size";
import { Position } from "./Position";
import { Color } from "./Color";
import { Appearance } from "./Appearance";
import { Animation } from "./Animation";

export const Components = () => {
  return (
    <>
      <Event />
      <Basic />
      <Size />
      <Position />
      <Appearance />
      <Color />
      <Animation />
    </>
  );
};
