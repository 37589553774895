import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/electronic/89ac81d0297168e63110e5778eff4617.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          "button_1",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          if(${result}){
            ${logic}
          } else {
            setSuccess(false);
            setGameOver(true);
          }
        }
      `;
    return code;
  };
};

export const InitHandSetterRotateBlock = () => {
  Blockly.Blocks["hand_setter_rotate_block"] = {
    init: function () {
      this.jsonInit({
        type: "hand_setter_rotate_block",
        message0: "%{BKY_COURSE_PROGRAM_CLOCK_HOUR}",
        args0: [
          {
            type: "input_value",
            name: "hour",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["hand_setter_rotate_block"] = function (block: Blockly.Block) {
    const hour = BlocklyJs.valueToCode(
      block,
      "hour",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        const hour = document.getElementById("course-program-clock-hour-hand");
        if(hour){
          await anime({
            targets: hour,
            rotate: ${30 * Number(hour) + 30 * (Number(0) / 60)},
            duration: 1000,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        }
        highlightBlock("${block.id}", false);
        setSuccess(${hour} === 3);
        setGameOver(true);
    `;
  };
};
