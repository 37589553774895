/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, PointerEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { Vector2D } from "common/types";
import { MovePanel } from "common/elements";
import { useScale, usePlaySound } from "common/utils";
import {
  actions,
  selectCanvasById,
  selectAllScreens,
} from "features/creator/slice";
import { Capture } from "features/creator/design/Capture";
import { CreatorOptions, GroupingDefault } from "features/creator/types";
import { RenderDesignScreen } from "features/creator/design/RenderDesignScreen";

export const DesignPanel = (props: { options: CreatorOptions }) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const { width, height, scale } = useScale();
  const [isFirst, setIsFirst] = useState(true);
  const allScreens = useSelector(selectAllScreens);
  const [animated, setAnimated] = useState<boolean>(false);
  const [offset, setOffset] = useState<Vector2D>({ x: 0, y: 0 });

  const screenSize = useSelector(
    (state: RootState) => state.creator.screenSize
  );
  const canvasScale = useSelector(
    (state: RootState) => state.creator.canvasScale
  );
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedCanvas = useSelector((state: RootState) =>
    selectCanvasById(state, selectedScreenId)
  );
  const focus = useSelector((state: RootState) => state.creator.focus);
  const drawing = useSelector((state: RootState) => state.creator.drawing);
  const capture = useSelector((state: RootState) => state.creator.capture);

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
  }, [isFirst]);

  useEffect(() => {
    // キャンパスでスクリーンを切り替えされた
    setAnimated(!isFirst);
    setOffset({
      x: width / 2 - (selectedCanvas.x + screenSize.width / 2 - 20),
      y:
        -((height / 2) * (1 - canvasScale * scale)) / (scale * canvasScale) -
        selectedCanvas.y +
        (props.options.headerHeight - 33) / canvasScale, //props.options.headerHeightはデフォルトサイズである、つまりscale=1です。
    });
  }, [focus, scale]);

  const onDragStop = (e: PointerEvent, position: Vector2D) => {
    play();
    setAnimated(false);
    setOffset({
      x: offset.x + position.x / (scale * canvasScale),
      y: offset.y + position.y / (scale * canvasScale),
    });
    dispatch(actions.handleAllCollapse());
    dispatch(actions.updateGrouping(GroupingDefault));
  };

  return (
    <>
      {capture && <Capture />}

      <div
        className="will-change-transform"
        style={{ zIndex: drawing.enable ? 9999 : 0 }}
        onContextMenu={(e) => e.preventDefault()}
      >
        {drawing.enable && <div style={{ width, height }} />}

        <MovePanel
          reset
          size={{ x: width, y: height }}
          position={{ x: 0, y: 0 }}
          disable={drawing.enable}
          onClickEnd={onDragStop}
        >
          <div
            className="!absolute w-0 h-0 top-0 left-0"
            style={{
              transform: `scale(${scale * canvasScale})`,
              transformOrigin: `${width / 2}px ${height / 2}px`,
            }}
          >
            <div
              id="design-panel-transform-container"
              className="w-0 h-0"
              style={{
                transform: `translate(${offset.x}px, ${offset.y}px) `,
                transition: !isFirst && animated && "transform 500ms",
              }}
            >
              {drawing.enable && (
                <div
                  className="flex-row-center !absolute top-0 left-0 bg-textcolor/black/50 backdrop-blur-[8px] z-[10001] pointer-events-none"
                  style={{
                    width: width,
                    height: height,
                    transform: `translate(${-offset.x}px, ${-offset.y}px) scale(${
                      1 / (scale * canvasScale)
                    })`,
                  }}
                />
              )}
              {allScreens.map((screen) => (
                <RenderDesignScreen
                  key={screen.id}
                  scale={scale * canvasScale}
                  screen={screen}
                  options={props.options}
                />
              ))}
            </div>
          </div>
        </MovePanel>
      </div>
    </>
  );
};
