import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Vector3D } from "common/types";
import {
  getMapProjects,
  copyMapProject,
  restoreProject,
  createMapProject,
  removeMapProject,
  deleteMapProject,
  publishMapProject,
  getMapProjectContent,
  stopPublishMapProject,
} from "features/builder/api";
import {
  MapType,
  MapModel,
  BlockType,
  AssetModel,
  BuilderState,
  AssetModelType,
} from "features/builder/types";
import { ProjectEntity } from "features/creator/types";

export const projectsAdapter = createEntityAdapter<ProjectEntity>({
  selectId: (project) => project.project_id,
});

export const {
  selectAll: selectAllMapProjects,
  selectById: selectMapProjectById,
  selectIds: selectMapProjectIds,
} = projectsAdapter.getSelectors((state: RootState) => state.builder.projects);

export const mapModelAdapter = createEntityAdapter<MapModel>();
export const {
  selectAll: selectAllMapModel,
  selectById: selectMapModelById,
  selectIds: selectMapModelIds,
} = mapModelAdapter.getSelectors((state: RootState) => state.builder.map);

const initialState: BuilderState = {
  loading: true,
  editingProjectId: null,
  projects: projectsAdapter.getInitialState(),
  size: { x: 8, y: 8, z: 8 },
  type: MapType.ALGORITHM,
  map: mapModelAdapter.getInitialState(),
  slot: Array(5).fill(null),
  blocks: {},
  undoStacks: [],
  redoStacks: [],
  actionMenu: {
    tap: true,
    asset: false,
    eraser: false,
    preview: false,
    assetList: false,
  },
  collapse: { gem: true, condition: true, character: true },
  predefined: {
    gem: "diamond_teamRed",
    condition: "button_teamBlue",
    character: "character_duck",
  },
  selectedSlotIndex: null,
  selectedAssetModel: null,
};

const builderSlice = createSlice({
  name: "builder",
  initialState,
  reducers: {
    initial(state: BuilderState) {
      state.loading = true;
      state.slot = [
        {
          type: AssetModelType.GROUND,
          name: "tileLow_teamRed",
          filename: "tileLow_teamRed.gltf.glb",
        },
        {
          type: AssetModelType.RIVER,
          name: "tileMedium_teamBlue",
          filename: "tileMedium_teamBlue.gltf.glb",
        },
        {
          type: AssetModelType.GROUND,
          name: "tileHigh_forest",
          filename: "tileHigh_forest.gltf.glb",
        },
        null,
        null,
      ];
      state.blocks = {
        [BlockType.MOTION_MOVE]: {
          kind: "block",
          type: BlockType.MOTION_MOVE,
          count: 3,
        },
        [BlockType.MOTION_JUMP]: {
          kind: "block",
          type: BlockType.MOTION_JUMP,
          count: 3,
        },
        [BlockType.MOTION_TURN_LEFT]: {
          kind: "block",
          type: BlockType.MOTION_TURN_LEFT,
          count: 3,
        },
        [BlockType.MOTION_TURN_RIGHT]: {
          kind: "block",
          type: BlockType.MOTION_TURN_RIGHT,
          count: 3,
        },
        [BlockType.CONTROLS_WHILEUNTIL_COLOUR_INTERNAL]: {
          kind: "block",
          type: BlockType.CONTROLS_WHILEUNTIL_COLOUR_INTERNAL,
          count: 3,
          inputs: {
            COLOUR: {
              shadow: {
                type: "colour_picker_internal",
                fields: {
                  COLOUR: "#71cdfc",
                },
              },
            },
          },
        },
      };
      state.undoStacks = [];
      state.redoStacks = [];
      state.selectedSlotIndex = null;
      state.actionMenu = {
        tap: true,
        asset: false,
        eraser: false,
        preview: false,
        assetList: false,
      };
      state.collapse = { gem: true, condition: true, character: true };
      state.predefined = {
        gem: "diamond_teamRed",
        condition: "button_teamBlue",
        character: "character_duck",
      };
      state.selectedAssetModel = null;
    },
    updateLoading(state: BuilderState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    resetActionMenu(state: BuilderState) {
      Object.keys(state.actionMenu).forEach(
        (k) => (state.actionMenu[k] = false)
      );
      state.selectedSlotIndex = null;
      state.selectedAssetModel = null;
    },
    updateActionMenu(
      state: BuilderState,
      action: PayloadAction<{
        key: string;
        value: boolean;
        exclusive?: boolean;
      }>
    ) {
      const { key, value, exclusive } = action.payload;
      state.actionMenu[key] = value;
      if (exclusive) {
        Object.keys(state.actionMenu)
          .filter((k) => k != key)
          .forEach((k) => (state.actionMenu[k] = false));
      }
      if (key !== "eraser") {
        //　消しゴムが危険なので、絶対ずっと選択させる状態を避けるべき
        state.actionMenu.eraser = false;
      }
    },
    addModelToMap(state: BuilderState, action: PayloadAction<MapModel>) {
      mapModelAdapter.addOne(state.map, action.payload);
    },
    updateMapModel(
      state: BuilderState,
      action: PayloadAction<{
        id: string;
        changes: { [item: string]: string | Vector3D };
      }>
    ) {
      mapModelAdapter.updateOne(state.map, {
        id: action.payload.id,
        changes: action.payload.changes,
      });
    },
    removeModelFromMap(state: BuilderState, action: PayloadAction<string>) {
      mapModelAdapter.removeOne(state.map, action.payload);
    },
    updateCollapse(
      state: BuilderState,
      action: PayloadAction<{ key: string; value: boolean }>
    ) {
      const { key, value } = action.payload;
      state.collapse = {
        gem: true,
        condition: true,
        character: true,
        [key]: value,
      };
    },
    updatePredefined(
      state: BuilderState,
      action: PayloadAction<{ key: string; value: string }>
    ) {
      const { key, value } = action.payload;
      state.predefined[key] = value;
    },
    updateSlot(state: BuilderState, action: PayloadAction<AssetModel[]>) {
      state.slot = action.payload;
    },
    selectSlot(state: BuilderState, action: PayloadAction<number | null>) {
      state.selectedSlotIndex = action.payload;
    },
    selectedAssetMesh(
      state: BuilderState,
      action: PayloadAction<AssetModel | null>
    ) {
      state.selectedAssetModel = action.payload;
    },
    updateBlocks(
      state: BuilderState,
      action: PayloadAction<{
        kind: string;
        type: BlockType;
        count: number;
        [key: string]: any;
      }>
    ) {
      state.blocks[action.payload.type] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMapProjects.fulfilled,
        (state: BuilderState, action: PayloadAction<ProjectEntity[]>) => {
          projectsAdapter.setAll(state.projects, action.payload);
        }
      )
      .addCase(
        createMapProject.fulfilled,
        (
          state: BuilderState,
          action: PayloadAction<{ project: ProjectEntity }>
        ) => {
          projectsAdapter.addOne(state.projects, action.payload.project);
        }
      )
      .addCase(
        copyMapProject.fulfilled,
        (
          state: BuilderState,
          action: PayloadAction<{ project: ProjectEntity }>
        ) => {
          projectsAdapter.addOne(state.projects, action.payload.project);
        }
      )
      .addCase(
        getMapProjectContent.fulfilled,
        (state: BuilderState, action: PayloadAction<any>) => {
          state.loading = true;
          state.slot = [null, null, null, null, null];
          state.blocks = {};
          state.undoStacks = [];
          state.redoStacks = [];
          state.actionMenu = {
            tap: true,
            asset: false,
            eraser: false,
            preview: false,
            assetList: false,
          };
          state.collapse = { gem: true, condition: true, character: true };
          state.predefined = {
            gem: "diamond_teamRed",
            condition: "button_teamBlue",
            character: "character_duck",
          };
          state.selectedSlotIndex = null;
          state.selectedAssetModel = null;

          const { content } = action.payload;
          state.size = content.defaultScreenId;
          state.blocks = content.screenSize;
          mapModelAdapter.setAll(state.map, content.map);
        }
      )
      .addCase(
        removeMapProject.fulfilled,
        (
          state: BuilderState,
          action: PayloadAction<{ project_id: string; deleted_at: string }>
        ) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload.project_id,
            changes: { deleted_at: action.payload.deleted_at },
          });
        }
      )
      .addCase(
        deleteMapProject.fulfilled,
        (state: BuilderState, action: PayloadAction<string>) => {
          projectsAdapter.removeOne(state.projects, action.payload);
        }
      )
      .addCase(
        publishMapProject.fulfilled,
        (
          state: BuilderState,
          action: PayloadAction<{ project_id: string; enable_copied: boolean }>
        ) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload.project_id,
            changes: {
              published: true,
              enable_copied: action.payload.enable_copied,
            },
          });
        }
      )
      .addCase(
        stopPublishMapProject.fulfilled,
        (state: BuilderState, action: PayloadAction<string>) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload,
            changes: { published: false },
          });
        }
      )
      .addCase(
        restoreProject.fulfilled,
        (state: BuilderState, action: PayloadAction<string>) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload,
            changes: { deleted_at: null },
          });
        }
      );
  },
});

export const actions = { ...builderSlice.actions };

export default builderSlice.reducer;
