import I18n from "i18n-js";
import { MessageType, ChatRoomMessage } from "features/user/types";

export const OfficialChatMessageItem = (props: {
  message: ChatRoomMessage;
}) => {
  const { message } = props;

  switch (message.message_type) {
    case MessageType.USER_INITIAL:
      if (message.message_content.users) {
        return (
          <div className="flex-row-view my-4">
            <div className="w-full flex flex-row items-center justify-center">
              <p className="text text-gray/100 bg-gray/20 px-3 py-1 rounded-[12px]">
                {`${message.message_content.users?.map(
                  (user) => user
                )} ${I18n.t(
                  "MSG_USER_PROFILE_MESSAGE_PARENTS_TALK_WELCOME_TEXT"
                )}`}
              </p>
            </div>
          </div>
        );
      } else {
        return <></>;
      }
  }
};
