import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useScale } from "common/utils";
import { Level } from "features/courses/ocha/level/Level";
import { CourseMessageContent } from "features/courses/types";
import { LevelAnimation } from "features/courses/ocha/level/LevelAnimation";
import { StageLevelRect } from "features/courses/ocha/level/StageLevelPaths";

export const Levels = (props: {
  scale: number;
  stage: number;
  display: CourseMessageContent;
  handleLevelPress: (stepId: number) => void;
}) => {
  const { width } = useScale();
  const navigate = useNavigate();
  const { scale, stage, display, handleLevelPress } = props;

  useEffect(() => {
    if (stage === 1) {
      navigate("/courses");
    }
  }, []);

  const LEVEL_RECT_CIRCLE_RADIUS = 96;
  const LEVEL_RECT_CIRCLE_STROKE = 20;

  const LEVEL_RECT_LEFTS = StageLevelRect.map((r) => r.left);
  const MIN_LEFT =
    Math.min(...LEVEL_RECT_LEFTS) -
    (282 / 2 - LEVEL_RECT_CIRCLE_RADIUS - LEVEL_RECT_CIRCLE_STROKE / 2);

  const MAX_LEFT =
    Math.max(...LEVEL_RECT_LEFTS) +
    282 / 2 +
    LEVEL_RECT_CIRCLE_RADIUS +
    LEVEL_RECT_CIRCLE_STROKE / 2;

  const offsetX =
    (width - (MAX_LEFT - MIN_LEFT) * scale) / 2 - MIN_LEFT * scale + 20 * scale;

  return (
    <div className="flex-col-el flex-1 flex-center w-full h-full">
      {stage === 2 && (
        <div className="flex-col-view w-full" style={{ height: 800 * scale }}>
          {Array(7)
            .fill(0)
            .map((_, index) => (
              <Level
                key={index}
                stage={stage}
                offset={offsetX}
                scale={scale}
                radius={LEVEL_RECT_CIRCLE_RADIUS}
                step={index}
                display={display}
                handleLevelPress={handleLevelPress}
              />
            ))}
          <LevelAnimation
            offset={offsetX}
            scale={scale}
            stroke={LEVEL_RECT_CIRCLE_STROKE}
            radius={LEVEL_RECT_CIRCLE_RADIUS}
          />
        </div>
      )}
    </div>
  );
};
