import I18n from "i18n-js";
import { Layout } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { back, next, close } = props;

  return (
    <Layout back={back} next={next} close={close}>
      <Title activeStep={9} />

      <div className="flex-col-el items-start justify-center w-[312px]">
        <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
          {I18n.locale === "ja"
            ? "まわりの メニューの\nつかいかたが\n「ページ」の なかに\nそれぞれかいて あります。"
            : "まわりのメニューの\n使い方が\n「ページ」の中に\nそれぞれ書いてあります。"}
        </p>
      </div>
    </Layout>
  );
};

export default Step;
