import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "app/store";
import {
  ActionCommand,
  ActionCommandType,
  ClipboardTargetType,
} from "features/creator/types";
import { actions } from "features/creator/slice";
import { updateProjectContent } from "features/creator/api";

export const useActionCommand = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleAction = (command: ActionCommand) => {
    switch (command.type) {
      case ActionCommandType.ADD_COMPONENT:
        dispatch(
          actions.addComponent({
            type: command.componentType,
            category: command.category,
            screenId: command.screenId,
            position: command.position,
            image: command.image,
            drawing: command.drawing,
          })
        );
        dispatch(actions.handleMaterialPanelCollapse(true));
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.REMOVE_COMPONENT:
        dispatch(
          actions.removeComponent({
            screenId: command.screenId,
            componentId: command.componentId,
          })
        );
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.ADD_SCREEN:
        dispatch(actions.addScreen());
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.REMOVE_SCREEN:
        dispatch(actions.removeScreen(command.screenId));
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.REMOVE_GROUPING:
        dispatch(
          actions.removeGroupComponents({
            screenId: command.screenId,
            componentIds: command.componentIds,
          })
        );
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.CHANGE_CANVAS:
        dispatch(
          actions.updateCanvas({
            id: command.screenId,
            newCanvas: command.canvas,
          })
        );
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.CHANGE_BLOCKLY:
        dispatch(
          actions.updateBlockly({
            screenId: command.screenId,
            xmlText: command.text,
          })
        );
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.CHANGE_PROPERTY:
        dispatch(
          actions.updateProperty({
            id: command.componentId,
            newProperty: command.property,
            record: command.record,
          })
        );
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.COPY:
        switch (command.targetType) {
          case ClipboardTargetType.SCREEN:
            dispatch(actions.handleCopyScreen());
            break;
          case ClipboardTargetType.COMPONENT:
            dispatch(actions.handleCopyComponent());
            break;
          case ClipboardTargetType.GROUPING:
            dispatch(actions.handleCopyGrouping());
            break;
          default:
            break;
        }
        break;
      case ActionCommandType.PASTE:
        switch (command.targetType) {
          case ClipboardTargetType.SCREEN:
            dispatch(actions.handlePasteScreen());
            break;
          case ClipboardTargetType.COMPONENT:
            dispatch(actions.handlePasteComponent());
            break;
          case ClipboardTargetType.GROUPING:
            dispatch(actions.handlePasteGrouping());
            break;
          default:
            break;
        }
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.UNDO:
        dispatch(actions.handleUndo());
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      case ActionCommandType.REDO:
        dispatch(actions.handleRedo());
        dispatch(updateProjectContent({ uid: user.active.uid }));
        break;
      default:
        break;
    }
  };
  return handleAction;
};
