import { SVGProps } from "react";

export const IconMap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m6 8 12 6 12-6 12 6v25l-12-5-12 5-12-5V8Z" fill="#B7D8F9" />
    <path
      d="M6 33.691V7.871a.5.5 0 0 1 .752-.432l11.014 6.425a.5.5 0 0 0 .476.015l11.534-5.767a.5.5 0 0 1 .448 0l11.5 5.75a.5.5 0 0 1 .276.447v24.882a.5.5 0 0 1-.724.447l-11.052-5.526a.5.5 0 0 0-.448 0l-11.552 5.776a.5.5 0 0 1-.448 0l-11.5-5.75A.5.5 0 0 1 6 33.691ZM18 14v25M30 8v25"
      stroke="#667786"
      strokeWidth={3}
    />
  </svg>
);
