import { useEffect } from "react";
import { InitStartBlock, InitLabelTextSetterBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitStartBlock();
    InitLabelTextSetterBlock();
  }, []);

  return (
    <div className="flex-row-center gap-20">
      <div className="flex-col-center">
        <div className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            あ
          </p>
        </div>
        <p className="text text-white">ボックス1</p>
      </div>
      <div className="flex-col-center">
        <div className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            い
          </p>
        </div>
        <p className="text text-white">ボックス2</p>
      </div>
      <div className="flex-col-center">
        <div className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            う
          </p>
        </div>
        <p className="text text-white">ボックス3</p>
      </div>
    </div>
  );
};
