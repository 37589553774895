import { useState, FormEvent } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getAuth,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import I18n from "i18n-js";
import { Button, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { parentsFirebase } from "app/Auth";
import { usePlaySound } from "common/utils";
import { WarningMessageBox } from "common/elements";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";
import { useParentsAccount } from "./hook";

export const MailSignUp = (props: RouteProps) => {
  useParentsAccount();
  const { navigate } = props;
  const play = usePlaySound();
  const dispatch = useDispatch();
  const handleDialog = useDialog();
  const [exists, setExists] = useState(false);
  const [required, setRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);

  const handlePasswordConfirmChange = () => {
    setPasswordConfirmError(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    try {
      const auth = getAuth(parentsFirebase);
      const data = new FormData(event.currentTarget);
      const email = data.get("email").toString();
      const password = data.get("password").toString();
      const passwordConfirm = data.get("password-confirm").toString();
      if (
        email &&
        password &&
        passwordConfirm &&
        password === passwordConfirm
      ) {
        handleDialog({ type: DialogType.LOADING, value: true });
        const actionCodeSettings = {
          url: import.meta.env.VITE_PUBLIC_URL,
          handleCodeInApp: false,
        };
        createUserWithEmailAndPassword(auth, email, password)
          .then(() => {
            sendEmailVerification(auth.currentUser, actionCodeSettings);
            toast(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_SIGN_UP_SUCCESS_CONTENT")}
                </p>
              </div>
            );
            navigate("/parentsSignUpUserProfile");
          })
          .catch((error) => {
            console.error(error);
            if (error.code === "auth/email-already-in-use") {
              setExists(true);
            }
          })
          .finally(() => {
            handleDialog({ type: DialogType.LOADING, value: false });
          });
      } else {
        setPasswordConfirmError(true);
      }
    } catch (error) {
      console.error(error);
      dispatch(actions.updateResign(false));
    }
  };

  const handleClickShowPassword = () => {
    play();
    setShowPassword(!showPassword);
  };

  const handleCloseExists = () => {
    play();
    setExists(false);
  };

  const handleGoBack = () => navigate("/parentsSignUp");

  return (
    <div className="flex-col-el items-center justify-around w-full h-full">
      <div className="flex-col-el items-center justify-around h-[510px]">
        {exists && (
          <WarningMessageBox
            zIndex={999999}
            title={I18n.t("MSG_SIGN_UP_EMAIL_ALREADY_EXISTS_FAILED_TITLE")}
            message={I18n.t("MSG_SIGN_UP_EMAIL_ALREADY_EXISTS_FAILED_MESSAGE")}
            close={handleCloseExists}
          />
        )}

        <div className="flex-col-center w-[375px] h-[300px]">
          <form className="flex-col-el w-full gap-4" onSubmit={handleSubmit}>
            <div className="flex-col-el">
              <input
                id="email"
                name="email"
                type="email"
                required={required}
                className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
                placeholder={I18n.t("MSG_SIGN_USER_MAIL")}
                autoFocus={false}
              />
              <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
                {I18n.t("MSG_APP_ERROR_EMAIL_NOT_VALID")}
              </label>
            </div>

            <div className="flex-col-el relative">
              <input
                id="password"
                name="password"
                minLength={6}
                required={required}
                type={showPassword ? "text" : "password"}
                className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
                placeholder={I18n.t("MSG_SIGN_USER_PASSWORD")}
                autoFocus={false}
              />
              <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
                {I18n.t("MSG_APP_ERROR_PASSWORD_TOO_SHORT")}
              </label>
              <div className="flex-col-el absolute right-0 pt-3 pr-3">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
            </div>

            <div className="flex-col-el relative">
              <input
                id="password-confirm"
                name="password-confirm"
                required={required}
                type={showPassword ? "text" : "password"}
                className={`peer input-form text-body text-textcolor/black text-font-size-body2-narrow ${
                  passwordConfirmError && "!border-danger"
                }`}
                placeholder={I18n.t("MSG_SIGN_USER_PASSWORD_CONFIRM")}
                autoFocus={false}
                onChange={handlePasswordConfirmChange}
              />
              <label
                className={`text-body text-left text-[14px] h-5 text-danger hidden ${
                  passwordConfirmError && "!block"
                }`}
              >
                {I18n.t("MSG_APP_ERROR_PASSWORD_CONFIRM_NOT_VALID")}
              </label>
              <div className="flex-col-el absolute right-0 pt-3 pr-3">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
            </div>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ height: 64, borderRadius: 2 }}
              onClick={() => setRequired(true)}
            >
              <p className="text-body text-[28px] text-white">
                {I18n.t("MSG_SIGN_UP_BTN")}
              </p>
            </Button>
          </form>
        </div>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
