import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitStartBlock = () => {
  const startBlock = () => {
    return {
      type: "start_answer_event",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    };
  };

  Blockly.Blocks["start_answer_event"] = {
    init: function () {
      this.appendDummyInput().appendField("%{BKY_COURSE_START_BLOCK}");
      this.jsonInit(startBlock());
      this.setCollapsed(false);
      this.setColour("#FFBF00");
    },
  };

  BlocklyJs["start_answer_event"] = function (block: Blockly.Block) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          if(${result}){
            ${logic}
          } else {
            setSuccess(false);
            setGameOver(true);
          }
        }
      `;
    return code;
  };
};
