import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useScale } from "common/utils";
import { TutorialArrow } from "common/assets";
import { actions } from "features/creator/slice";
import { ProjectPanelType } from "features/creator/types";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const { scale } = useScale();
  const dispatch = useDispatch();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("shared-project-panel-drawer");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 25 * scale,
        y: rect.y - 25 * scale,
        width: rect.width + 50 * scale,
        height: rect.height + 50 * scale,
      });
    }, 300);
  }, [scale]);

  const handleClick = () => {
    dispatch(actions.updateProjectPanel(ProjectPanelType.NONE));
    next();
  };

  return (
    <Overlay area={panelRect} onClick={handleClick}>
      <div
        className="absolute z-50 pointer-events-none"
        style={{
          width: 108 * scale,
          height: 105 * scale,
          transform: `translate(${panelRect.x - 70 * scale}px, ${
            panelRect.y + 100 * scale
          }px) rotate(-45deg)`,
        }}
      >
        <TutorialArrow />
      </div>
    </Overlay>
  );
};

export default Step;
