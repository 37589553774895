import { useEffect } from "react";
import Constants from "common/constant";
import { Clock } from "features/courses/program/game/components";
import { InitImageClickBlock, InitHandSetterRotateBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitImageClickBlock();
    InitHandSetterRotateBlock();
  }, []);

  return (
    <div className="flex-row-el items-end justify-center gap-2 mt-8">
      <Clock question={{ hour: 1, minutes: 0 }} />
      <img
        alt="*"
        src={`${Constants.assetHost}/stamps/electronic/89ac81d0297168e63110e5778eff4617.png`}
        className="w-[80px] h-[80px] z-50"
      />
    </div>
  );
};
