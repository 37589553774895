import I18n from "i18n-js";
import { Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { btnDelay } from "common/utils";
import { SSO } from "./SSO";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";
import { MailSignIn } from "./MailSignIn";
import { useParentsAccount } from "./hook";

export const ParentsSignAccount = (props: RouteProps) => {
  useParentsAccount();
  const { navigate, location } = props;
  const signIn = location === "/parentsSignIn";

  const handleGoBack = () => navigate("/parentsSign");

  return (
    <div className="flex-col-el items-center justify-around w-full h-full">
      <div className="flex-col-center gap-3 h-[510px]">
        {signIn && <MailSignIn navigate={navigate} />}

        <div className="flex-col-center gap-6 w-[375px]">
          {signIn ? (
            <div>
              <p className="text-body text-font-size-body2">
                {I18n.t("MSG_SIGN_IN_OTHER_ACCOUNT")}
              </p>
            </div>
          ) : (
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{ height: 40, borderRadius: 2 }}
              onClick={() => btnDelay(() => navigate("/MailSignUp"))}
            >
              <EmailIcon
                sx={{ fontSize: 16, marginRight: 0.5, color: "white" }}
              />
              <p className="text-body text-[16px] text-white">
                {I18n.t("MSG_SIGN_UP_WITH_MAIL")}
              </p>
            </Button>
          )}

          <SSO signIn={signIn} navigate={navigate} />
        </div>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
