import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { RootState } from "app/store";
import { useFetchUserData } from "app/hook";
import { getEnv, actions } from "app/configSlice";
import { WarningMessageBox } from "common/elements";
import { btnDelay, handleError, usePlaySound } from "common/utils";
import {
  AccountType,
  BillingType,
  BILLING_LIMIT_ACCOUNT,
} from "features/user/types";
import { createKidsAccount } from "features/user/api";
import { CreateKidsAccount } from "features/user/CreateKidsAccount";
import { IconUserAdd, IconUserAddInvalid } from "features/user/assets";

export const CreateParentsKidsUser = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [newUser, setNewUser] = useState(false);
  const [maxUser, setMaxUser] = useState(false);
  const { handleFetchParentsUser } = useFetchUserData();
  const appUser = useSelector((state: RootState) => state.user.appUser);

  const handleOpenAddNewUser = () => {
    play();
    if (appUser.main.billing_type === BillingType.EDUCATION) {
      if (appUser.users.length > BILLING_LIMIT_ACCOUNT.EDUCATION) {
        setMaxUser(true);
      } else {
        setNewUser(true);
      }
    } else {
      if (appUser.users.length > 4) {
        setMaxUser(true);
      } else {
        setNewUser(true);
      }
    }
  };
  const handleCreateKidsAccount = (name: string) => {
    dispatch(actions.updateResign(true));
    btnDelay(async () => {
      try {
        play();
        setNewUser(false);
        const auth = getAuth();
        const response = await createKidsAccount({
          user: {
            account_type: AccountType.KIDS,
            display_name: name,
            locale: I18n.locale,
            env: getEnv(),
            parents_user_uid: appUser.main.uid,
          },
        });
        await signInWithCustomToken(auth, response.data.idToken);
        await handleFetchParentsUser({ uid: appUser.main.uid });
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_USER_PROFILE_ADD_USER_SUCCESS")}
            </p>
          </div>
        );
      } catch (error) {
        console.log(error);
        handleError(error, I18n.t("MSG_USER_PROFILE_ADD_USER_FAILED"), () =>
          dispatch(actions.updateResign(false))
        );
      }
    });
  };
  const handleAddNewUserCancel = () => {
    play();
    btnDelay(() => setNewUser(false));
  };

  const handleCloseWarning = () => {
    play();
    setMaxUser(false);
  };

  return (
    <>
      {newUser && (
        <CreateKidsAccount
          close={handleAddNewUserCancel}
          confirm={handleCreateKidsAccount}
        />
      )}
      {maxUser && (
        <WarningMessageBox
          zIndex={999999}
          title={I18n.t("MSG_SETTING_PARENTS_MAX_USER_TITLE")}
          message={I18n.t("MSG_SETTING_PARENTS_MAX_USER_MESSAGE")}
          close={handleCloseWarning}
        />
      )}

      {appUser.active && (
        <div className="flex-row-el w-full h-0.5 shrink-0 px-[10%] my-4">
          <div className="flex-row-el w-full h-full bg-gray2/60" />
        </div>
      )}

      <div className="flex-row-center">
        <button
          onClick={handleOpenAddNewUser}
          className="opacity-button w-[92px]"
        >
          <div className="flex-row-el items-center justify-start">
            <div className="flex-col-center w-[80px] h-[80px]">
              {appUser.users.length < 5 ? (
                <IconUserAdd />
              ) : (
                <IconUserAddInvalid />
              )}
            </div>
            <div className="flex-col-el w-[250px] px-4">
              <p className="text-body text-left text-textcolor/black text-font-size-body2-narrow w-full text-body-ellipsis">
                {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_ADD_USER")}
              </p>
            </div>
          </div>
        </button>
      </div>
    </>
  );
};
