import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "features/creator/slice";
import { ProjectPanelType } from "features/creator/types";
import { Layout } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.updateProjectPanel(ProjectPanelType.SHARED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout next={next} close={close}>
      <Title activeStep={1} />

      <div className="flex-col-el items-start justify-center w-[312px]">
        <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
          {"「じゆうにつくる」へ \nようこそ！"}
        </p>
      </div>
    </Layout>
  );
};

export default Step;
