import { useState, FormEvent } from "react";
import { toast } from "react-toastify";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import I18n from "i18n-js";
import { Button, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { parentsFirebase } from "app/Auth";
import { usePlaySound } from "common/utils";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";

export const ChangePassword = (props: RouteProps) => {
  const { navigate } = props;
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [required, setRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    try {
      const currentUser = getAuth(parentsFirebase).currentUser;
      const data = new FormData(event.currentTarget);
      const currentPassword = data.get("current-password").toString();
      const newPassword = data.get("new-password").toString();
      const newPasswordConfirm = data.get("new-password-confirm").toString();
      if (currentPassword && newPassword && newPasswordConfirm) {
        if (newPassword === newPasswordConfirm) {
          const credential = EmailAuthProvider.credential(
            currentUser.email,
            currentPassword
          );
          handleDialog({ type: DialogType.LOADING, value: true });
          await reauthenticateWithCredential(currentUser, credential);
          await updatePassword(currentUser, newPassword);
          toast(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_SIGN_IN_CHANGE_PASSWORD_SUCCESS_CONTENT")}
              </p>
            </div>
          );
          handleDialog({ type: DialogType.LOADING, value: false });
          navigate("/parentsUserAccount");
        } else {
          setNewPasswordConfirmError(true);
        }
      } else {
        setNewPasswordConfirmError(true);
      }
    } catch (error) {
      console.error(error);
      handleDialog({ type: DialogType.LOADING, value: false });
      if (error?.code === "auth/wrong-password") {
        setCurrentPasswordError(true);
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleGoBack = () => navigate("/parentsUserAccount");

  return (
    <div className="flex-col-el items-center justify-around w-full h-full py-4">
      <div className="flex-col-center w-[375px] h-[400px]">
        <form className="flex-col-el w-full gap-4" onSubmit={handleSubmit}>
          <div className="flex-col-el relative">
            <input
              id="current-password"
              name="current-password"
              required={required}
              type={showPassword ? "text" : "password"}
              className={`peer input-form text-body text-textcolor/black text-font-size-body2-narrow ${
                currentPasswordError && "!border-danger"
              }`}
              placeholder={I18n.t(
                "MSG_SIGN_CHANGE_PASSWORD_LABEL_CURRENT_PASSWORD"
              )}
              autoFocus={false}
              onChange={() => setCurrentPasswordError(false)}
            />
            <label
              className={`text-body text-left text-[14px] h-5 text-danger hidden ${
                currentPasswordError && "!block"
              }`}
            >
              {I18n.t("MSG_SIGN_IN_CHANGE_PASSWORD_CURRENT_PASSWORD_WRONG")}
            </label>
            <div className="flex-col-el absolute right-0 pt-3 pr-3">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <div className="flex-col-el relative">
            <input
              id="new-password"
              name="new-password"
              required={required}
              type={showPassword ? "text" : "password"}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              placeholder={I18n.t(
                "MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD"
              )}
              autoFocus={false}
            />
            <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
              {I18n.t("MSG_APP_ERROR_PASSWORD_TOO_SHORT")}
            </label>
            <div className="flex-col-el absolute right-0 pt-3 pr-3">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <div className="flex-col-el relative">
            <input
              id="new-password-confirm"
              name="new-password-confirm"
              required={required}
              type={showPassword ? "text" : "password"}
              className={`peer input-form text-body text-textcolor/black text-font-size-body2-narrow ${
                newPasswordConfirmError && "!border-danger"
              }`}
              placeholder={I18n.t(
                "MSG_SIGN_CHANGE_PASSWORD_LABEL_NEW_PASSWORD_CONFIRM"
              )}
              autoFocus={false}
              onChange={() => setNewPasswordConfirmError(false)}
            />
            <label
              className={`text-body text-left text-[14px] h-5 text-danger hidden ${
                newPasswordConfirmError && "!block"
              }`}
            >
              {I18n.t("MSG_APP_ERROR_PASSWORD_CONFIRM_NOT_VALID")}
            </label>
            <div className="flex-col-el absolute right-0 pt-3 pr-3">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </div>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={() => setRequired(true)}
          >
            {I18n.t("MSG_SIGN_IN_CHANGE_PASSWORD_SEND_BTN")}
          </Button>
        </form>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
