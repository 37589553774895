import { useEffect } from "react";
import Constants from "common/constant";
import { InitImageSetterBlock, InitButtonClickBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitImageSetterBlock();
    InitButtonClickBlock();
  }, []);

  return (
    <div className="flex-col-el flex-center gap-2 mt-8">
      <div className="flex-row-center w-full gap-20">
        <div className="flex-col-center">
          <div className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
              className="w-[100px] h-[100px] opacity-40"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
          </div>
          <p className="text text-white">ボックス1</p>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
              className="w-[100px] h-[100px] opacity-40"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
          </div>
          <p className="text text-white">ボックス2</p>
        </div>

        <div className="flex-col-center">
          <div className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
              className="w-[100px] h-[100px] opacity-40"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
          </div>
          <p className="text text-white">ボックス3</p>
        </div>
      </div>

      <div className="flex-row-center w-full h-[100px] will-change-contents">
        <div className="flex-row-el flex-center upsilon pointer-events-auto cursor-pointer z-50">
          <button disabled className="btn btn-blue">
            <div className="flex-row-el flex-center relative">
              <p className="text-body text-[20px]">ボタン１</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
