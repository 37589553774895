import { SVGProps } from "react";

export const IconArrowMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 45"
    {...props}
  >
    <path
      d="M12 24.1c6.627 0 12-5.373 12-12S18.627.1 12 .1 0 5.473 0 12.1s5.373 12 12 12Z"
      fill="#94C4F7"
    />
    <path
      d="M6 12.1h12"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.314 38h-16M9.657 32 4 37.657l5.657 5.657"
      stroke="#94C4F7"
      strokeWidth={4}
    />
  </svg>
);
