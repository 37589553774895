/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, ChangeEventHandler } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { uploadImageAsset } from "app/api";
import { Asset, DialogType } from "app/types";
import { WarningMessageBox } from "common/elements";
import { CameraIcon } from "./icon";

export const ImagePicker = (props: {
  play: () => void;
  name: string;
  size: number;
  url: string;
  width?: number;
  height?: number;
  onChange: (props: {
    name: string;
    size: number;
    width: number;
    height: number;
    uri: string;
  }) => void;
}) => {
  const handleDialog = useDialog();
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const { play, name, size, width, height, url, onChange } = props;
  const user = useSelector((state: RootState) => state.user.appUser);

  const pickImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);

      const image = new Image();
      image.src = objectUrl;
      image.onload = () => {
        URL.revokeObjectURL(objectUrl);
        handleDialog({ type: DialogType.LOADING, value: true });
        const uuid = uuidv4();
        const object_key = `users/${user.active.uid}/assets/images/${uuid}.png`;
        const filename = `${I18n.t(
          "MSG_CREATOR_COMPONENT_NAME_IMAGE"
        )}_${dayjs().format("YYYY_MM_DD_HH_mm_ss")}`;

        const width = image.width;
        const height = image.height;

        uploadImageAsset({
          uid: user.active.uid,
          fileBlob: file,
          object_key,
          filename,
          tags: `${I18n.t("MSG_CREATOR_COMPONENT_NAME_IMAGE")}`,
          metadata: {
            filename: encodeURIComponent(file.name),
            size: String(file.size),
            width: String(width),
            height: String(height),
          },
          contentType: file.type,
        })
          .then((response) => {
            if (queryClient.getQueryData(["creator/getUserAssets"])) {
              queryClient.setQueryData(
                ["creator/getUserAssets"],
                (old: Asset[]) => [...old, response.data]
              );
            }
            handleDialog({ type: DialogType.LOADING, value: false });
            onChange({
              name: file.name,
              size: file.size,
              width,
              height,
              uri: response.data.url,
            });
          })
          .catch((e) => {
            console.error(e);
            handleDialog({ type: DialogType.LOADING, value: false });
            setError(true);
          });

        ref.current.value = "";
      };

      image.onerror = () => {
        handleDialog({ type: DialogType.LOADING, value: false });
        setError(true);
        ref.current.value = "";
        console.log("image error");
      };
    }
  };

  const handleCloseWarning = () => {
    play();
    setError(false);
  };

  return (
    <>
      {error && (
        <WarningMessageBox
          title={I18n.t("MSG_ERROR_TITLE")}
          message={I18n.t("MSG_ERROR_MESSAGE")}
          close={handleCloseWarning}
        />
      )}
      <div className="flex-col-center w-full px-[16px]">
        <img
          key={url}
          alt=""
          className="w-[96px] h-[96px] object-contain"
          src={url}
        />

        <div className="flex-col-view mt-[8px] w-full">
          <p className="text text-textcolor/black !text-[16px] !text-left text-body-ellipsis">
            {I18n.t("MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_NAME")}：{name}
          </p>
          <p className="text text-textcolor/black !text-[16px] !text-left">
            {I18n.t("MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_SIZE")}：{width}x
            {height}px
          </p>
          <p className="text text-textcolor/black !text-[16px] !text-left">
            {I18n.t("MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_FILE_SIZE")}：
            {Math.round(size / 1024)}KB
          </p>
        </div>

        <div className="flex-row-center h-[32px] mt-[12px]">
          <input
            ref={ref}
            type="file"
            onClick={() => play()}
            onChange={pickImage}
            accept="image/*"
            className="absolute w-[150px] h-[32px] opacity-0 z-50 peer"
          />
          <div className="flex-row-center w-full h-full px-[8px] rounded-[8px] bg-green/60 peer-active:opacity-75">
            <div className="flex-row-center mr-[4px]">
              <CameraIcon width={24} height={24} />
            </div>

            <p className="text text-white !text-[18px] !tracking-[.04em]">
              {I18n.t("MSG_CREATOR_MENU_PROPERTY_IMAGE_INFO_BTN")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
