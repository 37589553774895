import { SVGProps } from "react";

export const Icon3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={96}
    height={96}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={48}
      cy={48}
      r={46}
      fill="#94C4F7"
      stroke="#667786"
      strokeWidth={4}
    />
    <path
      d="m24 32 13 8.667L24 48M39 74l9.35-8L57 74M73 48l-13-8.667L73 32"
      stroke="#667786"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
