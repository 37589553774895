import { Route } from "react-router-dom";
import { BuilderMain } from "features/builder";
import { CourseTopPage } from "features/courses/Top";
import { FreeMapGamePage } from "features/courses/free";
import { ProgramGamePage } from "features/courses/program/game";
import { OchakuLevelMapPage } from "features/courses/ocha/level";
import { TangibleGamePage } from "features/courses/tangible/game";
import { Control, OchaGamePage } from "features/courses/ocha/game";
import { AlgorithmGamePage } from "features/courses/algorithm/game";
import { ProgramLevelMapPage } from "features/courses/program/level";
import { TangibleLevelMapPage } from "features/courses/tangible/level";
import { AlgorithmLevelMapPage } from "features/courses/algorithm/level";
import { Recognition } from "features/courses/tangible/game/recognition";
import { NishishinjukuGamePage } from "features/courses/nishishinjuku/game";
import { TakushokuBasicGamePage } from "features/courses/takushoku_basic/game";
import { NishishinjukuLevelMapPage } from "features/courses/nishishinjuku/level";
import { TakushokuAdvanceGamePage } from "features/courses/takushoku_advance/game";
import { TakushokuBasicLevelMapPage } from "features/courses/takushoku_basic/level";
import { TakushokuAdvanceLevelMapPage } from "features/courses/takushoku_advance/level";

export const CoursePageRoutes = (
  <>
    <Route index element={<CourseTopPage />} />
    <Route path="algorithm">
      <Route path="stage/:stageId" element={<AlgorithmLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<AlgorithmGamePage />}
      />
    </Route>
    <Route path="program">
      <Route path="stage/:stageId" element={<ProgramLevelMapPage />} />
      <Route path="stage/:stageId/step/:stepId" element={<ProgramGamePage />} />
    </Route>
    <Route path="tangible">
      <Route path="stage/:stageId" element={<TangibleLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<TangibleGamePage />}
      />
      <Route path="recognition" element={<Recognition />} />
    </Route>
    <Route path="builder">
      <Route path="top" element={<BuilderMain />} />
      <Route path="map" element={<FreeMapGamePage />} />
    </Route>
    <Route path="nishishinjuku">
      <Route path="stage/:stageId" element={<NishishinjukuLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<NishishinjukuGamePage />}
      />
    </Route>
    <Route path="takushoku_basic">
      <Route path="stage/:stageId" element={<TakushokuBasicLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<TakushokuBasicGamePage />}
      />
    </Route>
    <Route path="takushoku_advance">
      <Route path="stage/:stageId" element={<TakushokuAdvanceLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<TakushokuAdvanceGamePage />}
      />
    </Route>
    <Route path="ocha">
      <Route path="recognition" element={<Control />} />
      <Route path="stage/:stageId" element={<OchakuLevelMapPage />} />
      <Route path="stage/:stageId/step/:stepId" element={<OchaGamePage />} />
    </Route>
  </>
);
