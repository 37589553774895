import "@babylonjs/loaders/glTF/2.0/index";
import "@babylonjs/core/Animations/animatable";
import {
  SceneLoader,
  ISceneLoaderAsyncResult,
} from "@babylonjs/core/Loading/sceneLoader";
import { Scene } from "@babylonjs/core/scene";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { BoundingInfo } from "@babylonjs/core/Culling/boundingInfo";
import Constants from "common/constant";

export const loadMesh = (
  scene: Scene,
  name: string,
  initialPosition?: Vector3
): Promise<AbstractMesh> => {
  return new Promise(async (resolve, reject) => {
    const handleImportMesh = (result: ISceneLoaderAsyncResult) => {
      const mesh = result.meshes[0];
      const boundingInfo = mesh.getHierarchyBoundingVectors();
      mesh.setBoundingInfo(
        new BoundingInfo(boundingInfo.min, boundingInfo.max)
      );

      for (let i = 0; i < result.meshes.length; i++) {
        const subMesh = result.meshes[i];
        if (subMesh.material) {
          // @ts-ignore
          subMesh.material.specularIntensity = 0.3;
        }
      }

      if (initialPosition) {
        mesh.position = initialPosition;
      }

      resolve(mesh);
    };

    SceneLoader.ImportMeshAsync(
      "",
      `${Constants.assetHost}/assets/models/`,
      name,
      scene
    )
      .then((result) => {
        handleImportMesh(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const copyMesh = (
  mesh: Mesh,
  name: string,
  initialPosition?: Vector3
) => {
  const newMesh = mesh.clone(name);

  newMesh.position = new Vector3(0, 0, 0);
  const boundingInfo = newMesh.getHierarchyBoundingVectors();
  newMesh.setBoundingInfo(new BoundingInfo(boundingInfo.min, boundingInfo.max));

  if (initialPosition) {
    newMesh.position = initialPosition;
  }

  return newMesh;
};
