import { useState, FormEventHandler, MouseEventHandler } from "react";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { Chip, Dialog } from "@mui/material";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { AppDispatch, RootState } from "app/store";
import {
  CloseBtn,
  FadeTransition,
  ConfirmMessageBox,
  WarningMessageBox,
} from "common/elements";
import Constants from "common/constant";
import { useScale, btnDelay, usePlaySound } from "common/utils";
import {
  updateProject,
  publishProject,
  stopPublishProject,
} from "features/creator/api";
import {
  IconAppName,
  IconPublish,
  IconCopiedEnable,
  IconCopiedDisable,
  IconPublishCopiedOn,
  IconPublishCopiedOff,
} from "features/creator/assets";
import { ProjectEntity } from "features/creator/types";
import { IconBasic } from "features/creator/property/components";
import { CoverPageStock } from "./CoverPageStock";
import { ToggleSwitch, PublishProjectDialogMessage } from ".";

const Title = () => (
  <div className="flex-row-el justify-start items-center gap-1 py-4">
    <span className="material-symbols-outlined text-[42px] text-textcolor/black">
      edit_calendar
    </span>
    <p className="text-body text-textcolor/black text-font-size-h3">
      {I18n.t("MSG_CREATOR_APP_SETTING_TITLE")}
    </p>
  </div>
);

const ProjectCard = ({ project }: { project: ProjectEntity }) => {
  const play = usePlaySound();
  const [cover, setCover] = useState(false);
  const [coverPage, setCoverPage] = useState(project.cover ?? project?.capture);

  const handleOpenCoverPage: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    btnDelay(() => {
      play();
      setCover(true);
    });
  };
  const handleCloseCoverPage = () => {
    btnDelay(() => {
      play();
      setCover(false);
    });
  };

  return (
    <div className="flex-col-center">
      <CoverPageStock
        visible={cover}
        coverPage={coverPage}
        selectCoverPage={setCoverPage}
        project={project}
        close={handleCloseCoverPage}
      />

      <div
        id="project-setting-project-card"
        className="flex-col-center w-[188px] h-[222px]"
      >
        <img
          alt="app card"
          src={`${Constants.assetHost}/assets/images/app_card_user.png`}
          className="w-full h-full"
        />

        <div className="flex-col-el flex-center absolute top-[47px] w-full px-[11px] h-[148px]">
          {coverPage || project?.capture ? (
            <div className="flex-col-center w-full h-full overflow-hidden bg-white">
              <img
                alt="アプリのキャプチャー"
                className="w-full h-full object-cover"
                src={coverPage ?? project?.capture}
                onError={(e) =>
                  // @ts-ignore
                  (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                }
              />
            </div>
          ) : (
            <div className="w-full h-full bg-white" />
          )}
        </div>
      </div>

      <div className="flex-row-el flex-center upsilon">
        <button className="btn btn-primary" onClick={handleOpenCoverPage}>
          <div className="flex-row-center gap-1 mb-[3px]">
            <span className="material-symbols-outlined text-[30px] text-white">
              edit_note
            </span>
            <p className="text-body text-white text-font-size-body2">
              {I18n.t("MSG_CREATOR_APP_SETTING_APP_CHANGE_COVER_PAGE_BTN")}
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

const ProjectPublish = ({ project }: { project: ProjectEntity }) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const [warning, setWarning] = useState(false);
  const [publish, setPublish] = useState(false);
  const project_publish = useSelector(
    (state: RootState) => state.config.userConfig.project_publish
  );
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleOpenPublish = () => {
    play();
    btnDelay(() => {
      if (project_publish || project.published) {
        setPublish(true);
      } else {
        setWarning(true);
      }
    });
  };
  const handlePublish = async (enable_copied?: boolean) => {
    if (project.published) {
      await dispatch(
        stopPublishProject({
          uid: user.active.uid,
          project_id: project.project_id,
        })
      ).unwrap();
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_DISABLE_PUBLISHED_SUCCESS")}
          </p>
        </div>
      );
    } else {
      await dispatch(
        publishProject({
          uid: user.active.uid,
          project_id: project.project_id,
          enable_copied,
        })
      ).unwrap();
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_PUBLISHED_SUCCESS")}
          </p>
        </div>
      );
    }
    setPublish(false);
  };
  const handlePublishClose = () => {
    play();
    btnDelay(() => setPublish(false));
  };

  const handleCloseWarning = () => {
    play();
    btnDelay(() => setWarning(false));
  };

  return (
    <>
      {warning && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t("MSG_CREATOR_APP_PUBLISH_DISABLED_TITLE")}
          message={I18n.t("MSG_CREATOR_APP_PUBLISH_DISABLED_MESSAGE")}
          close={handleCloseWarning}
        />
      )}

      <PublishProjectDialogMessage
        name={project?.name}
        visible={publish}
        published={project?.published}
        enableCopied={project?.enable_copied}
        publish={handlePublish}
        close={handlePublishClose}
      />

      <div className="flex-col-el flex-center">
        <p className="text-body text-textcolor/black text-font-size-caption1 bg-white px-4 rounded-full leading-[22px]">
          {I18n.t("MSG_CREATOR_APP_SETTING_PUBLISH_TITLE")}
        </p>

        <div className="flex-row-center gap-1">
          <div className="flex-row-center">
            <p className="text-body text-textcolor/black text-font-size-caption1">
              {I18n.t("MSG_CREATOR_APP_SETTING_PUBLISH_STATUS_OFF")}
            </p>
            <div className="flex-col-center w-[60px] h-[64px]">
              <img
                alt="publish btn"
                src={
                  project?.published
                    ? `${Constants.assetHost}/assets/images/publish_off.png`
                    : `${Constants.assetHost}/assets/images/publish_on.png`
                }
                className="w-[48px] h-[48px]"
              />
            </div>
          </div>

          <div className="flex-col-el flex-center w-[72px] -mt-2">
            <ToggleSwitch
              checked={project?.published}
              onChange={handleOpenPublish}
            />
          </div>

          <div className="flex-row-center">
            <div className="flex-col-center w-[80px] h-[64px]">
              <div className="flex-col-center w-[60px] h-[64px]">
                {project?.published ? (
                  project?.enable_copied ? (
                    <IconPublishCopiedOn />
                  ) : (
                    <IconPublishCopiedOff />
                  )
                ) : (
                  <IconPublish />
                )}
              </div>
            </div>
            <p className="text-body text-textcolor/black text-font-size-caption1">
              {I18n.t("MSG_CREATOR_APP_SETTING_PUBLISH_STATUS_ON")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const ProjectCopied = ({ project }: { project: ProjectEntity }) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const [copied, setCopied] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleOpenChangeCopied = () => {
    btnDelay(() => {
      play();
      setCopied(true);
    });
  };
  const handleChangeCopied = async () => {
    if (project.enable_copied) {
      await dispatch(
        updateProject({
          uid: user.active.uid,
          project_id: project.project_id,
          project: { ...project, enable_copied: false },
        })
      ).unwrap();
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_DISABLE_COPIED_SUCCESS")}
          </p>
        </div>
      );
    } else {
      await dispatch(
        updateProject({
          uid: user.active.uid,
          project_id: project.project_id,
          project: { ...project, enable_copied: true },
        })
      ).unwrap();
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_ENABLE_COPIED_SUCCESS")}
          </p>
        </div>
      );
    }
    setCopied(false);
  };
  const handleCloseChangeCopied = () => {
    btnDelay(() => {
      play();
      setCopied(false);
    });
  };

  return (
    <>
      {copied && (
        <ConfirmMessageBox
          title={
            project.enable_copied
              ? I18n.t("MSG_CREATOR_APP_DISABLE_COPIED_TITLE")
              : I18n.t("MSG_CREATOR_APP_ENABLE_COPIED_TITLE")
          }
          message={I18n.t(
            project.enable_copied
              ? "MSG_CREATOR_APP_DISABLE_COPIED_MESSAGE"
              : "MSG_CREATOR_APP_ENABLE_COPIED_MESSAGE",
            {
              name: project.name,
            }
          )}
          confirm={handleChangeCopied}
          close={handleCloseChangeCopied}
        />
      )}
      <div className="flex-col-el flex-center">
        <p className="text-body text-textcolor/black text-[18px] bg-white px-4 rounded-full leading-[22px]">
          {I18n.t("MSG_CREATOR_APP_SETTING_ENABLE_COPIED_TITLE")}
        </p>

        <div className="flex-row-center gap-1">
          <div className="flex-row-center">
            <p className="text-body text-right text-textcolor/black text-font-size-caption1 whitespace-pre">
              {I18n.t("MSG_CREATOR_APP_SETTING_ENABLE_COPIED_STATUS_OFF")}
            </p>
            <div className="flex-col-center w-[60px] h-[64px]">
              <IconCopiedDisable />
            </div>
          </div>

          <div className="flex-col-el flex-center w-[72px] -mt-2">
            <ToggleSwitch
              checked={project?.enable_copied}
              onChange={handleOpenChangeCopied}
            />
          </div>

          <div className="flex-row-center">
            <div className="flex-col-center w-[64px] h-[64px]">
              <IconCopiedEnable />
            </div>
            <p className="text-body text-textcolor/black text-font-size-caption1 whitespace-pre">
              {I18n.t("MSG_CREATOR_APP_SETTING_ENABLE_COPIED_STATUS_ON")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const AppName = ({ name }: { name: string }) => (
  <div className="flex-col-el gap-2">
    <div className="flex-row-el items-center gap-1">
      <div className="flex-col-center w-[27px] h-[27px]">
        <IconAppName />
      </div>
      <p className="text-body text-textcolor/black text-font-size-caption1">
        {I18n.t("MSG_CREATOR_APP_SETTING_APP_NAME")}
      </p>
    </div>

    <div className="flex-col-el flex-center relative">
      <div className="flex-col-center h-[62px]">
        <img
          alt="description"
          src={`${Constants.assetHost}/assets/images/creator_project_title_bg.png`}
          className="w-full h-full"
        />
      </div>

      <div className="flex-col-el w-full p-4 absolute">
        <input
          required
          type="text"
          name="name"
          defaultValue={name}
          autoFocus={false}
          size={20}
          maxLength={20}
          className="peer input-modal !p-0 !px-2 text-body text-textcolor/black text-font-size-body1 invalid:border-red/100"
        />
      </div>
    </div>
  </div>
);

const Description = ({ description }: { description: string }) => (
  <div className="flex-col-el gap-2">
    <div className="flex-row-el items-center gap-1">
      <div className="flex-col-center w-[27px] h-[27px]">
        <IconBasic />
      </div>
      <p className="text-body text-textcolor/black text-font-size-caption1">
        {I18n.t("MSG_CREATOR_APP_DETAIL_DESCRIPTION_TITLE")}
      </p>
    </div>

    <div className="flex-col-center w-full h-[200px]">
      <img
        alt="description"
        src={`${Constants.assetHost}/assets/images/creator_project_description_bg_small.png`}
        className="w-full h-full"
      />

      <div className="flex-row-el w-full h-full px-5 pt-5 pb-6 absolute top-0">
        <textarea
          name="description"
          maxLength={100}
          defaultValue={description}
          className="w-full h-full text-body text-left placeholder:text-gray2/60 resize-none text-textcolor/black text-[20px] px-2 m-0 border-none bg-white rounded-lg outline outline-gray2/40 outline-4 focus:outline-blue/80/40"
          placeholder={I18n.t("MSG_CREATOR_APP_DETAIL_DESCRIPTION_PLACEHOLDER")}
        />
      </div>
    </div>
  </div>
);

const Tag = ({ project }: { project: ProjectEntity }) => (
  <div className="flex-col-el flex-1 h-full gap-2">
    <div className="flex-row-el items-center gap-1">
      <span className="material-symbols-outlined text-[27px] text-textcolor/black">
        sell
      </span>
      <p className="text-body text-textcolor/black text-font-size-caption1">
        {I18n.t("MSG_CREATOR_APP_SETTING_APP_TAG")}
      </p>
    </div>

    <div className="flex-row-el flex-wrap gap-2 w-full">
      {project.tags
        ?.split(",")
        .filter((c) => c !== "")
        .map((tag, index) => (
          <Chip key={index} label={tag} sx={{ fontSize: 20 }} />
        ))}
    </div>
  </div>
);

const SubmitButton = ({ disable }: { disable: boolean }) => (
  <div className="flex-row-el flex-center alpha">
    <button disabled={disable} type="submit" className="btn btn-primary">
      <div className="flex-row-center gap-1 mb-[3px]">
        <span className="material-symbols-outlined text-[36px] text-white">
          check
        </span>
        <p className="text-body text-white text-font-size-body1">
          {I18n.t("MSG_CONFIRM_BTN")}
        </p>
      </div>
    </button>
  </div>
);

export const EditProjectModal = ({
  visible,
  project,
  close,
}: {
  visible: boolean;
  project: ProjectEntity;
  close: () => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const dispatch = useDispatch<AppDispatch>();
  const [disable, setDisable] = useState(true);
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleFormChange: FormEventHandler<HTMLFormElement> = (event) => {
    const data = new FormData(event.currentTarget);
    const name = data.get("name").toString();
    const description = data.get("description").toString();
    if (name !== project.name || description !== project.description) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const data = new FormData(event.currentTarget);
    const name = data.get("name").toString();
    const description = data.get("description").toString();
    if (name !== project.name || description !== project.description) {
      play();
      handleDialog({ type: DialogType.LOADING, value: true });
      dispatch(
        updateProject({
          uid: user.active.uid,
          project_id: project.project_id,
          project: { ...project, name, description },
        })
      )
        .then(unwrapResult)
        .then(() => {
          close();
          handleDialog({ type: DialogType.LOADING, value: false });
        })
        .catch((error) => {
          console.error(error);
          handleDialog({ type: DialogType.LOADING, value: false });
        });
    }
  };

  return (
    <Dialog
      fullScreen
      open={visible}
      maxWidth={false}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div className="flex-col-el flex-center w-full h-full">
        <div
          className="flex-col-el flex-center relative"
          style={{ transform: `scale(${scale})` }}
        >
          <div
            className="flex-col-el flex-center relative w-[1000px] m-h-[900px]"
            style={{
              filter:
                "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
            }}
          >
            <div className="flex-col-el justify-start w-full h-full relative border-solid border-white border-[8px] rounded-[8px] bg-white">
              <CloseBtn close={close} />

              <div className="flex-col-el justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
                <Title />

                <form
                  onSubmit={handleSubmit}
                  onChange={handleFormChange}
                  className="flex-col-el relative gap-5"
                >
                  {project && (
                    <div className="flex-row-el gap-5">
                      <div className="flex-col-el flex-1 w-full h-full gap-3">
                        <AppName name={project.name} />
                        <Description description={project.description} />
                        <Tag project={project} />
                      </div>

                      <div className="flex-col-el flex-center gap-5">
                        <ProjectCard project={project} />
                        <div className="flex-col-el justify-start gap-3 mx-4">
                          <ProjectPublish project={project} />
                          {project.published && (
                            <ProjectCopied project={project} />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <SubmitButton disable={disable} />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
