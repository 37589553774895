import { characterDirection, CHARACTER_DIRECTION } from "../../tilesets";
import { TileMapType } from "features/courses/algorithm/game/scene/maps/TileMapType";

const character = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gemLayer1 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gemLayer2 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 29, 0, 0],
];

const gem = [gemLayer1, gemLayer2];

const landscapeLayer1 = [
  [88, 88, 88, 88, 88],
  [99, 99, 99, 99, 88],
  [87, 87, 87, 99, 88],
  [99, 99, 87, 99, 88],
  [99, 99, 87, 88, 88],
];
const landscapeLayer2 = [
  [67, 0, 128, 0, 127],
  [0, 0, 0, 0, 0],
  [0, 0, 25, 0, 65],
  [0, 0, 0, 0, 0],
  [0, 0, 24, 67, 0],
];

const landscape = [landscapeLayer1, landscapeLayer2];

const map: TileMapType = {
  layers: 2,
  width: 5,
  height: 5,
  tileSize: 2,
  character: {
    layer: 2,
    data: character,
    direction:
      characterDirection[CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X],
  },
  gem: gem,
  landscape: landscape,
};

export default map;
