/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { DisableTopBlocks } from "@blockly/disable-top-blocks";
import { RootState } from "app/store";
import {
  InitMsg,
  InitColorBlock,
  InitVariableBlock,
  InitMathInitBlock,
  InitProceduresCallBlock,
} from "common/blockly";
import {
  InitSoundBlock,
  InitMediaBlock,
  InitAngleBlock,
  InitTextFontBlock,
  ComponentTypeIds,
} from "common/components";
import {
  actions,
  selectScreenById,
  selectAllScreens,
  selectBlocklyById,
} from "features/creator/slice";
import { TabIndex } from "features/creator/types";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType } from "features/creator/types";
import { InitComponentBlockly } from "features/creator/blockly/InitBlockly";
import { creatorBlocklyOptions } from "features/creator/blockly/creatorBlocklyOptions";

const useInitWorkspace = (
  scale: number,
  locale: string,
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void
) => {
  useEffect(() => {
    InitMsg(locale);
  }, [locale]);

  useEffect(() => {
    InitSoundBlock();
    InitMediaBlock();
    InitAngleBlock();
    InitColorBlock();
    InitVariableBlock();
    InitMathInitBlock();
    InitTextFontBlock();
    InitProceduresCallBlock();

    //@ts-ignore
    Blockly.FieldAngle.CLOCKWISE = true;
    //@ts-ignore
    Blockly.FieldAngle.OFFSET = 90;

    const workspace = Blockly.inject(
      "blocklyDiv",
      creatorBlocklyOptions(scale, document.getElementById("toolbox"))
    );

    const toolbox = workspace.getToolbox() as Blockly.Toolbox;
    const div = toolbox.HtmlDiv as HTMLDivElement;
    div.style.width = "0px";
    div.style.display = "none";
    workspace.updateToolbox(document.getElementById("toolbox"));

    const blocklySvg = document.getElementsByClassName(
      "blocklySvg"
    )[0] as HTMLDivElement;
    blocklySvg.setAttribute(
      "style",
      "background-color: rgba(255, 255, 255, 1) !important;z-index: 0;"
    );

    const blocklyFlyouts = document.getElementsByClassName("blocklyFlyout");
    const blocklyFlyout = blocklyFlyouts[1];
    blocklyFlyout.setAttribute("style", "backdrop-filter: blur(10px);");

    const blocklyFlyoutBackground = document.getElementsByClassName(
      "blocklyFlyoutBackground"
    );
    for (let i: number = 0; i < blocklyFlyouts.length; i++) {
      let e = blocklyFlyoutBackground[i];
      e.setAttribute("style", "fill: rgba(242, 239, 241, 0.5) !important");
    }

    workspace.addChangeListener(Blockly.Events.disableOrphans);
    setWorkspace(workspace);

    const disableTopBlocksPlugin = new DisableTopBlocks();
    disableTopBlocksPlugin.init();

    BlocklyJs.init(workspace);

    return () => {
      setWorkspace(null);
    };
  }, []);
};

const useWorkspaceListener = (
  workspace: Blockly.WorkspaceSvg,
  locale: string,
  setTabIndex: (index: TabIndex) => void
) => {
  const dispatch = useDispatch();
  const handleAction = useActionCommand();
  const allScreens = useSelector(selectAllScreens);
  const [updateBlockly, setUpdateBlockly] = useState(false);
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const blockly = useSelector((state: RootState) =>
    selectBlocklyById(state, selectedScreenId)
  );
  const selectedScreen = useSelector((state: RootState) =>
    selectScreenById(state, selectedScreenId)
  );
  const collapse = useSelector(
    (state: RootState) => state.creator.panelCollapse.blocklyWorkspace
  );
  const template = useSelector((state: RootState) => state.creator.template);

  useEffect(() => {
    InitComponentBlockly(
      allScreens.map((screen) => [
        screen.name,
        `${ComponentTypeIds.SCREEN}_${screen.id}`,
      ]),
      selectedScreen,
      selectedComponentId
    );

    if (workspace && !collapse) {
      if (template.newBlockIds.length > 0) {
        dispatch(actions.handleClearTemplate());
      } else {
        const centerBlock = workspace
          .getTopBlocks(true)
          .filter((block) => {
            if (block.isEnabled()) {
              const componentId = (block.getField(
                "component_id"
              ) as Blockly.FieldDropdown)?.getValue();
              return componentId !== "" && componentId !== undefined
                ? selectedComponentId === componentId.split("_")[1]
                : false;
            } else {
              return false;
            }
          })
          .shift();

        if (centerBlock) {
          centerBlock.select();
          workspace.centerOnBlock(centerBlock.id);
        }

        const toolbox = workspace.getToolbox() as Blockly.Toolbox;
        const item = toolbox.getToolboxItemById(
          "event"
        ) as Blockly.ToolboxCategory;
        toolbox.setSelectedItem(item);
        toolbox.setSelectedItem(item);
        setTabIndex(TabIndex.NONE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse, workspace, selectedComponentId, selectedScreen]);

  useEffect(() => {
    if (template.newBlockIds.length === 0) {
      if (workspace) {
        const xml = Blockly.Xml.workspaceToDom(workspace);
        const xml_text = Blockly.Xml.domToText(xml);
        if (xml_text !== blockly?.xmlText) {
          setUpdateBlockly(!updateBlockly);
        }
      } else if (blockly.xmlText) {
        setUpdateBlockly(!updateBlockly);
      }
    }
  }, [blockly?.xmlText]);

  useEffect(() => {
    if (workspace) {
      if (blockly && blockly?.xmlText) {
        try {
          const dom = Blockly.Xml.textToDom(blockly.xmlText);
          Blockly.Xml.domToWorkspace(dom, workspace);
        } catch (error) {
          console.error(JSON.stringify(error));
        }
      }

      const onWorkspaceChange = (
        event:
          | Blockly.Events.BlockChange
          | Blockly.Events.BlockCreate
          | Blockly.Events.BlockDelete
          | Blockly.Events.BlockMove
      ) => {
        switch (event.type) {
          case Blockly.Events.TOOLBOX_ITEM_SELECT:
            //@ts-ignore
            if (!event.newItem) {
              setTabIndex(TabIndex.NONE);
            }
            break;
          case Blockly.Events.BLOCK_CHANGE:
          case Blockly.Events.BLOCK_MOVE:
            if (!workspace.isDragging()) {
              BlocklyJs.init(workspace);
              const xml = Blockly.Xml.workspaceToDom(workspace);
              const xml_text = Blockly.Xml.domToText(xml);
              handleAction({
                type: ActionCommandType.CHANGE_BLOCKLY,
                screenId: selectedScreenId,
                text: xml_text,
              });
            }
            break;
          case Blockly.Events.BLOCK_DELETE:
            BlocklyJs.init(workspace);
            const xml = Blockly.Xml.workspaceToDom(workspace);
            const xml_text = Blockly.Xml.domToText(xml);
            handleAction({
              type: ActionCommandType.CHANGE_BLOCKLY,
              screenId: selectedScreenId,
              text: xml_text,
            });
            break;
          default:
            break;
        }
      };
      workspace.addChangeListener(onWorkspaceChange);

      return () => {
        if (workspace) {
          workspace.clear();
        }
        workspace.removeChangeListener(onWorkspaceChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, updateBlockly, workspace, selectedScreenId, selectedScreen]);
};

export const useBlocklyInit = (
  scale: number,
  locale: string,
  workspace: Blockly.WorkspaceSvg,
  setWorkspace: (workspace: any) => void,
  setTabIndex: (index: TabIndex) => void
) => {
  useInitWorkspace(scale, locale, setWorkspace);
  useWorkspaceListener(workspace, locale, setTabIndex);
};
