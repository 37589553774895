import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { LocalStorageKey } from "app/types";
import * as utils from "common/utils";
import { Banner } from "./Banner";

export const SmartAppBanner = () => {
  const play = utils.usePlaySound();
  const [show, setShow] = useState(false);
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (!window.ReactNativeWebView) {
      try {
        const dismissBanner = localStorage.getItem(
          LocalStorageKey.DISMISS_BANNER
        );
        if (dismissBanner?.toLocaleLowerCase() !== "true") {
          if ((utils.isIos() && utils.isChrome()) || utils.isFire()) {
            setTimeout(
              () => {
                setShow(true);
              },
              config.tutorial.top ? 10000 : 8000
            );
          }
        }
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    play();
    setShow(false);
  };

  const handleDismiss = () => {
    play();
    setShow(false);
    localStorage.setItem(LocalStorageKey.DISMISS_BANNER, "true");
  };

  const handleOpenIos = () => {
    play();
    setShow(false);
    window.location.href = "https://apps.apple.com/jp/app/id1590656071";
  };

  const handleOpenAndroid = () => {
    play();
    setShow(false);
    if (utils.isFire()) {
      window.location.href = "amzn://apps/android?p=com.inexus.app.kids";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.inexus.app.kids";
    }
  };

  return (
    <Banner
      open={show}
      close={handleClose}
      dismiss={handleDismiss}
      confirm={utils.isIos() ? handleOpenIos : handleOpenAndroid}
      label={I18n.t("MSG_APP_BANNER_SHOW_BTN")}
    />
  );
};
