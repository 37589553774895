import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { TutorialArrow } from "common/assets";
import {
  ProjectType,
  DisplayMode,
  ProjectPanelType,
} from "features/creator/types";
import { projectAnimation } from "features/creator/project/util";
import { actions, selectAllProjects } from "features/creator/slice";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const { scale } = useScale();
  const dispatch = useDispatch();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const projects = useSelector(selectAllProjects);
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );
  const sortedProjects = userProjects.sort((a, b) =>
    dayjs(a.created_time) < dayjs(b.created_time) ? -1 : 1
  );

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementsByClassName(
        `app-project-card-${
          sortedProjects[sortedProjects.length - 1].project_id
        }`
      );
      const rect = panel[0].getBoundingClientRect();
      setPanelRect({
        x: rect.x - 55 * scale,
        y: rect.y - 50 * scale,
        width: rect.height + 80 * scale,
        height: rect.height + 80 * scale,
      });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  const handleClick = () => {
    projectAnimation(
      selectedProjectId,
      sortedProjects[sortedProjects.length - 1].project_id,
      () => {
        dispatch(actions.updateProjectPanel(ProjectPanelType.NONE));

        dispatch(actions.updateDisplayMode(DisplayMode.CAPTURE));
        dispatch(
          actions.updateSelectedProjectId(
            sortedProjects[sortedProjects.length - 1].project_id
          )
        );

        next();
      }
    );
  };

  return (
    <>
      <Overlay area={panelRect} onClick={handleClick}>
        <div
          className="absolute z-50"
          style={{
            width: 108 * scale,
            height: 105 * scale,
            transform: `translate(${panelRect.x + panelRect.width}px, ${
              panelRect.y + 80 * scale
            }px) rotate(135deg)`,
          }}
        >
          <TutorialArrow />
        </div>
      </Overlay>
    </>
  );
};

export default Step;
