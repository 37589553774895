import { SVGProps } from "react";

export const ResetBtn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 6a6 6 0 0 1 6-6h12a6 6 0 0 1 6 6v12a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z"
      fill="#FD8480"
    />
    <path
      d="M18 10c-.377-.892-.904-1.555-2.007-2.516a6.04 6.04 0 0 0-7.962.024 5.98 5.98 0 0 0-.988 7.867 6.025 6.025 0 0 0 3.482 2.436c1.42.363 2.923.2 4.23-.462a6.01 6.01 0 0 0 2.868-3.129M18 17.97v-3.75h-3.766"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
