import { Dialog } from "@mui/material";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { BASE_WIDTH, BASE_HEIGHT } from "common/constant";

export const Loading = () => {
  const { scale } = useScale();
  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 999999 }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-row-center w-full h-full bg-beige/60">
        <img
          alt=""
          src={`${Constants.assetHost}/assets/images/loading.gif`}
          className="flex-col-view"
          style={{
            width: BASE_WIDTH,
            height: BASE_HEIGHT,
            transform: `scale(${scale})`,
          }}
        />
      </div>
    </Dialog>
  );
};
