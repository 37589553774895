import Blockly from "blockly";
import { v4 as uuidv4 } from "uuid";
import BlocklyJs from "blockly/javascript";
import { handleImageAnalysis } from "features/courses/api";
import {
  Block,
  Label,
  BlockType,
  ExecutableBlock,
  WorkspaceBlockList,
} from "./type";
import { findConditionLabel } from "./util";
import { isDev } from "common/utils";

const convertObjectToBlockByLabel = (label: Label, labels: Label[]) => {
  const id = uuidv4();
  switch (true) {
    case /じっこう/.test(label.text):
      return {
        id,
        label,
        type: BlockType.EVENT_START,
        args: [],
      };
    case /おしまい/.test(label.text):
      return {
        id,
        label,
        type: BlockType.FUNCTION_END,
      };
    case /まえにすすむ/.test(label.text):
      return {
        id,
        label,
        type: BlockType.MOTION_MOVE,
      };
    case /ジャンプ/.test(label.text):
      return {
        id,
        label,
        type: BlockType.MOTION_JUMP,
      };
    case /ひだりをむく/.test(label.text):
      return {
        id,
        label,
        type: BlockType.MOTION_TURN_LEFT,
      };
    case /みぎをむく/.test(label.text):
      return {
        id,
        label,
        type: BlockType.MOTION_TURN_RIGHT,
      };
    case /もし/.test(label.text):
      // IF block
      const if_condition = findConditionLabel(label, labels).shift();
      if (if_condition.text.includes("へんすう")) {
        return {
          id,
          label,
          type: BlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL_START,
          args: ["へんすう"],
        };
      } else {
        let color: string; //#71cdfc, #fb3a69, #fdd73e
        if (if_condition.text.includes("あおいろ")) {
          color = "#71cdfc";
        } else if (if_condition.text.includes("あかいろ")) {
          color = "#fb3a69";
        } else if (if_condition.text.includes("きいろ")) {
          color = "#fdd73e";
        } else {
          return {
            id,
            label,
            type: BlockType.UNKNOWN,
          };
        }
        console.log("if block. color is: ", color);
        return {
          id,
          label,
          type: BlockType.CONTROLS_IF_COLOUR_INTERNAL_START,
          args: [color],
        };
      }
    case /くりかえす/.test(label.text):
      const loop_condition = findConditionLabel(label, labels).shift();
      if (loop_condition.text.includes("へんすう")) {
        return {
          id,
          label,
          type: BlockType.CONTROLS_LOOP_VAR_INTERNAL_START,
          args: ["へんすう"],
        };
      } else {
        const number = loop_condition.text.match(/\d+/);
        if (number) {
          // FOR Block
          console.log("for block. number is: ", number ? [number[0]] : "");
          return {
            id,
            label,
            type: BlockType.CONTROLS_REPEAT_INTERNAL_START,
            args: number ? [number[0]] : [],
          };
        } else {
          // WHILE Block
          let color: string; //#71cdfc, #fb3a69, #fdd73e
          if (loop_condition.text.includes("あおいろ")) {
            color = "#71cdfc";
          } else if (loop_condition.text.includes("あかいろ")) {
            color = "#fb3a69";
          } else if (loop_condition.text.includes("きいろ")) {
            color = "#fdd73e";
          } else {
            return {
              id,
              label,
              type: BlockType.UNKNOWN,
            };
          }
          console.log("while block. color is: ", color);
          return {
            id,
            label,
            type: BlockType.CONTROLS_WHILEUNTIL_COLOUR_INTERNAL_START,
            args: [color],
          };
        }
      }
    case /ここまで/.test(label.text):
      return {
        id,
        label,
        type: BlockType.CONTROLS_END,
      };
    case /へんすうにセット/.test(label.text):
      const variable_set_condition = findConditionLabel(label, labels).shift();
      const variable_number = variable_set_condition.text.match(/\d+/);
      if (variable_number) {
        console.log("variables set block. value is: ", variable_number);
        return {
          id,
          label,
          type: BlockType.VARIABLES_SET,
          args: ["へんすう", variable_number[0]],
        };
      } else {
        let color: string; //#71cdfc, #fb3a69, #fdd73e
        if (variable_set_condition.text.includes("あおいろ")) {
          color = "#71cdfc";
        } else if (variable_set_condition.text.includes("あかいろ")) {
          color = "#fb3a69";
        } else if (variable_set_condition.text.includes("きいろ")) {
          color = "#fdd73e";
        } else {
          return {
            id,
            label,
            type: BlockType.UNKNOWN,
          };
        }
        console.log("variables set block. value is: ", color);
        return {
          id,
          label,
          type: BlockType.VARIABLES_SET,
          args: ["へんすう", color],
        };
      }
    case /かんすうよびだし/.test(label.text.replace(" ", "")):
      const procedure_call_condition = findConditionLabel(
        label,
        labels
      ).shift();
      const number = procedure_call_condition.text.match(/\d+/);
      console.log(
        `procedure call definition block. name is: ${
          procedure_call_condition.text
        }, number is ${number ? [number[0]] : ""}`
      );
      return {
        id,
        label,
        type: BlockType.PROCEDURES_CALLNORETURN_CALL,
        args: [number ? [number[0]] : [], procedure_call_condition.text],
      };
    case /かんすう/.test(label.text):
      const procedure_definition_condition = findConditionLabel(
        label,
        labels
      ).shift();
      console.log(
        `procedure definition block. name is: ${procedure_definition_condition?.text}`
      );
      return {
        id,
        label,
        type: BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START,
        args: [procedure_definition_condition?.text],
      };
    default:
      return {
        id,
        label,
        type: BlockType.UNKNOWN,
      };
  }
};

export const useExecutableBlockListGenerator = () => {
  const handleGenerateBlock = (
    fileBlob: string | Blob | ArrayBuffer,
    success: (
      labels: Label[],
      blocks: ExecutableBlock[][],
      isAiRobotTeacher: boolean
    ) => void,
    error: (err: any) => void,
    completely: () => void
  ) =>
    handleImageAnalysis({ data: fileBlob })
      .then((response) => {
        var validLabels: Label[] = [];
        var blocks: ExecutableBlock[][] = [];
        var isAiRobotTeacher = false;
        console.log(response.data);
        if (response.data.readResult.blocks.length > 0) {
          validLabels = (response.data.readResult.blocks[0].lines as Label[])
            .filter((label) => {
              if (label.text.includes("AIロボットせんせい")) {
                isAiRobotTeacher = true;
                return true;
              }
              return !label.text.includes("じょうけん");
            })
            .sort((a, b) => {
              if (a.boundingPolygon[0].y < b.boundingPolygon[0].y) {
                return -1;
              } else {
                return 1;
              }
            });
          try {
            blocks = generateExecutableBlockList(validLabels);
          } catch (error) {
            console.log(error);
          }
        }
        console.log(isAiRobotTeacher);
        success(validLabels, blocks, isAiRobotTeacher);
      })
      .catch(error)
      .finally(completely);

  const generateExecutableBlockList = (labels: Label[]) => {
    const blocks = (labels.map((label) =>
      convertObjectToBlockByLabel(label, labels)
    ) as Block[]).filter((block) => block.type !== BlockType.UNKNOWN);
    if (isDev) {
      console.log("all blocks sorted by the y axis: ", blocks);
    }

    blocks.forEach((block, index) => {
      if (isDev) {
        console.log(block);
      }

      if (
        block.type === BlockType.EVENT_START ||
        block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
      ) {
        blocks[index].rootId = block.id;
      } else {
        const parents = blocks.slice(0, index);
        const parentDistances = parents.map((parent) =>
          Math.sqrt(
            Math.pow(
              parent.label.boundingPolygon[0].x -
                block.label.boundingPolygon[0].x,
              2
            ) +
              Math.pow(
                parent.label.boundingPolygon[0].y -
                  block.label.boundingPolygon[0].y,
                2
              )
          )
        );

        const children = blocks.slice(index + 1, blocks.length);
        const childrenDistances = children.map((parent) =>
          Math.sqrt(
            Math.pow(
              parent.label.boundingPolygon[0].x -
                block.label.boundingPolygon[0].x,
              2
            ) +
              Math.pow(
                parent.label.boundingPolygon[0].y -
                  block.label.boundingPolygon[0].y,
                2
              )
          )
        );

        const minParentDistance = Math.min(...parentDistances);
        const minParentDistanceIndex = parentDistances.indexOf(
          minParentDistance
        );

        const minChildDistance = Math.min(...childrenDistances);

        if (isDev) {
          console.log("parents blocks: ", parents);
          console.log("children blocks: ", children);
          console.log(
            `min parent distance index: ${minParentDistanceIndex}, min parent distance: ${minParentDistance}`
          );
          console.log(`min children distance: ${minChildDistance}`);
        }

        if (
          minParentDistance < 800 &&
          blocks[minParentDistanceIndex].type !== BlockType.FUNCTION_END &&
          (minChildDistance < 800 || block.type === BlockType.FUNCTION_END)
        ) {
          blocks[index].rootId = blocks[minParentDistanceIndex].rootId;
        }
      }
    });

    const executableBlockList: ExecutableBlock[][] = blocks
      .filter(
        (block) =>
          block.type === BlockType.EVENT_START ||
          block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
      )
      .map((startBlock) =>
        blocks.filter((block) => block.rootId === startBlock.id)
      )
      .map((eventBlocks) => {
        const blockList: ExecutableBlock[] = [];
        var controlBlockStack = eventBlocks
          .filter((block) => block.type.includes("start"))
          .reverse();
        eventBlocks.forEach((block, index) => {
          if (block.type.includes("end")) {
            let parentBlock: Block;
            switch (block.type) {
              case BlockType.FUNCTION_END:
                console.log(eventBlocks[0].type);
                parentBlock = controlBlockStack
                  .filter(
                    (controlBlock) => controlBlock.type === eventBlocks[0].type
                  )
                  .shift();
                break;
              case BlockType.CONTROLS_END:
                parentBlock = controlBlockStack
                  .filter((controlBlock) =>
                    controlBlock.type.includes("controls")
                  )
                  .shift();
                break;
              default:
                break;
            }

            console.log(parentBlock);
            if (parentBlock) {
              const parentBlockIndex = eventBlocks.findIndex(
                (block) => block.id === parentBlock.id
              );
              if (parentBlockIndex < index) {
                blockList.push({
                  id: block.id,
                  parentId: parentBlock.id,
                  type: block.type,
                  args: block.args,
                });
                controlBlockStack = controlBlockStack.filter(
                  (block) => block.id !== parentBlock.id
                );
              }
            }
          } else {
            blockList.push({
              id: block.id,
              type: block.type,
              args: block.args,
            });
          }
        });
        return blockList;
      });

    if (isDev) {
      console.log("executable block list: ", executableBlockList);
    }

    return executableBlockList;
  };

  return { handleGenerateBlock };
};

export const useWorkspaceBlockGenerator = (
  workspace: Blockly.WorkspaceSvg,
  scale: number,
  offset: number,
  setEvent: (code: string) => void
) => {
  const generateWorkspaceBlock = (blocks: ExecutableBlock[][]) => {
    workspace.clear();

    for (let i = 0; i < blocks.length; i++) {
      const workspaceBlockList: WorkspaceBlockList[] = [];
      for (let j = 0; j < blocks[i].length; j++) {
        const block = blocks[i][j];
        if (
          block.type === BlockType.EVENT_START ||
          block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
        ) {
          const workspaceBlock = new Blockly.BlockSvg(
            workspace,
            block.type.replace("_start", "")
          );
          workspaceBlock.initSvg();
          workspaceBlock.render();
          workspaceBlock.moveTo(
            new Blockly.utils.Coordinate(200 / scale + i * 400, offset)
          );

          if (
            block.type === BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
          ) {
            workspaceBlock.getField("NAME")?.setValue(block.args[0]);
          }

          workspaceBlockList.push({ block, workspaceBlock });
        } else if (block.type.includes("end")) {
          workspaceBlockList.push({ block, workspaceBlock: null });
        } else {
          let workspaceBlock: Blockly.BlockSvg;
          if (block.type === BlockType.PROCEDURES_CALLNORETURN_CALL) {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type + `_${block.args[0]}`
            );
            workspaceBlock.getField("NAME")?.setValue(block.args[1]);
          } else if (block.type === BlockType.VARIABLES_SET) {
            workspaceBlock = new Blockly.BlockSvg(workspace, block.type);
            const variableSetBlock = workspace.getVariableById(
              workspaceBlock.getFieldValue("VAR")
            );
            variableSetBlock.name = block.args[0];
            if (isNaN(Number(block.args[1]))) {
              variableSetBlock.type = "Colour";
              const colourPickerBlock = new Blockly.BlockSvg(
                workspace,
                "colour_picker_internal"
              );
              colourPickerBlock.initSvg();
              colourPickerBlock.render();
              colourPickerBlock.getField("COLOUR")?.setValue(block.args[1]);
              workspaceBlock
                .getInput("VALUE")
                .connection.connect(colourPickerBlock.outputConnection);
            } else {
              variableSetBlock.type = "Number";
              const mathNumberBlock = new Blockly.BlockSvg(
                workspace,
                "math_number"
              );
              mathNumberBlock.initSvg();
              mathNumberBlock.render();
              mathNumberBlock.getField("NUM")?.setValue(block.args[1]);
              workspaceBlock
                .getInput("VALUE")
                .connection.connect(mathNumberBlock.outputConnection);
            }
            console.log(variableSetBlock);
          } else if (block.type.includes("var_internal")) {
            const variableSetBlock = workspace.getVariable(block.args[0]);

            const variableGetBlock = new Blockly.BlockSvg(
              workspace,
              BlockType.VARIABLES_GET
            );
            variableGetBlock.initSvg();
            variableGetBlock.render();
            (variableGetBlock.getField(
              "VAR"
            ) as Blockly.FieldVariable).variableTypes = [variableSetBlock.type];
            variableGetBlock.setFieldValue(variableSetBlock.getId(), "VAR");

            if (block.type === BlockType.CONTROLS_LOOP_VAR_INTERNAL_START) {
              if (variableSetBlock.type === "Colour") {
                workspaceBlock = new Blockly.BlockSvg(
                  workspace,
                  BlockType.CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL_START.replace(
                    "_start",
                    ""
                  )
                );
                workspaceBlock
                  .getInput("COLOUR")
                  .connection.connect(variableGetBlock.outputConnection);
              } else {
                workspaceBlock = new Blockly.BlockSvg(
                  workspace,
                  BlockType.CONTROLS_REPEAT_VAR_INTERNAL_START.replace(
                    "_start",
                    ""
                  )
                );
                workspaceBlock
                  .getInput("TIMES")
                  .connection.connect(variableGetBlock.outputConnection);
              }
            } else if (
              block.type === BlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL_START
            ) {
              workspaceBlock = new Blockly.BlockSvg(
                workspace,
                block.type.replace("_start", "")
              );
              workspaceBlock
                .getInput("COLOUR")
                .connection.connect(variableGetBlock.outputConnection);
            }
          } else {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type.includes("start")
                ? block.type.replace("_start", "")
                : block.type
            );
            if (workspaceBlock.loadExtraState) {
              workspaceBlock.loadExtraState({ max: Number(block.args[0]) });
            }
            workspaceBlock.getField("TIMES")?.setValue(block.args[0]);
            if (workspaceBlock.getInput("COLOUR")) {
              const colourPickerBlock = new Blockly.BlockSvg(
                workspace,
                "colour_picker_internal"
              );
              colourPickerBlock.initSvg();
              colourPickerBlock.render();
              colourPickerBlock.getField("COLOUR")?.setValue(block.args[0]);
              workspaceBlock
                .getInput("COLOUR")
                .connection.connect(colourPickerBlock.outputConnection);
            }
          }

          workspaceBlock.initSvg();
          workspaceBlock.render();

          const previousBlock =
            workspaceBlockList[workspaceBlockList.length - 1];
          if (previousBlock.block.type.includes("start")) {
            previousBlock.workspaceBlock
              .getInput(
                previousBlock.block.type ===
                  BlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
                  ? "STACK"
                  : "DO"
              )
              .connection.connect(workspaceBlock.previousConnection);
          } else if (previousBlock.block.type.includes("end")) {
            const parentBlock = workspaceBlockList
              .filter((item) => item.block.id === previousBlock.block.parentId)
              .shift();
            if (parentBlock && parentBlock.workspaceBlock.nextConnection) {
              parentBlock.workspaceBlock.nextConnection.connect(
                workspaceBlock.previousConnection
              );
            }
          } else {
            if (previousBlock.workspaceBlock.nextConnection) {
              previousBlock.workspaceBlock.nextConnection.connect(
                workspaceBlock.previousConnection
              );
            }
          }

          workspaceBlockList.push({ block, workspaceBlock });
        }
      }
    }

    if (!workspace.isDragging()) {
      const code = BlocklyJs.workspaceToCode(workspace).replace(
        /function /g,
        "async $&"
      ); // safari maybe has some problems.
      setEvent(code);
    }
  };

  return { generateWorkspaceBlock };
};
