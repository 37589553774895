import { SVGProps } from "react";

export const IconSetting = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.65 8.634c.852-3.512 5.848-3.512 6.7 0a3.447 3.447 0 0 0 5.146 2.132c3.086-1.88 6.62 1.652 4.74 4.74a3.449 3.449 0 0 0 2.13 5.144c3.512.852 3.512 5.848 0 6.7a3.447 3.447 0 0 0-2.132 5.146c1.88 3.086-1.652 6.62-4.74 4.74a3.449 3.449 0 0 0-5.144 2.13c-.852 3.512-5.848 3.512-6.7 0a3.447 3.447 0 0 0-5.146-2.132c-3.086 1.88-6.62-1.652-4.74-4.74a3.449 3.449 0 0 0-2.13-5.144c-3.512-.852-3.512-5.848 0-6.7a3.447 3.447 0 0 0 2.132-5.146c-1.88-3.086 1.652-6.62 4.74-4.74 2 1.216 4.592.14 5.144-2.13Z"
      fill="#A3ADB6"
      stroke="#667786"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 30a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      stroke="#F6F4F6"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
