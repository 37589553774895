import { useMemo, useState, useEffect, PointerEvent } from "react";
import { useSelector } from "react-redux";
import i18n from "i18n-js";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import * as Blockly from "blockly";
import throttle from "lodash-es/throttle";
import { Dialog as Modal } from "@mui/material";
import { RootState } from "app/store";
import { Vector2D } from "common/types";
import { ComponentManager } from "common/components";
import { useScale, usePlaySound, btnDelay } from "common/utils";
import { FadeTransition, PromptInputNumber } from "common/elements";
import { selectScreenById, selectPropertyById } from "features/creator/slice";

const RenderScreenChildComponent = (props: { id: string; zIndex: number }) => {
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, props.id)
  );
  const { id, typeId, property } = propertyEntity;
  const ChildComponent = useMemo(
    () => ComponentManager[typeId].component.DesignComponent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className="absolute top-0 left-0 pointer-events-none"
      style={{
        zIndex: props.zIndex,
        backfaceVisibility: "hidden",
        width:
          property.style.layout.width +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        height:
          property.style.layout.height +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        transform: `translate(${property.style.transform.translateX}px, ${property.style.transform.translateY}px) rotate(${property.style.transform.rotation}deg)`,
        willChange: "transform",
        opacity: property.style.view.opacity / 100,
        filter:
          property.style.shadow.shadowRadius !== 0 &&
          `drop-shadow(${property.style.shadow.shadowOffset.width}px ${property.style.shadow.shadowOffset.height}px ${property.style.shadow.shadowRadius}px ${property.style.shadow.shadowColor})`,
      }}
    >
      <ChildComponent
        property={property}
        id={`component-preview-component-${id}`}
      />
    </div>
  );
};

enum INPUT_VALUE {
  X = "x",
  Y = "y",
}
export const DialogPositionPicker = (props: {
  workspace: Blockly.WorkspaceSvg;
}) => {
  const PANEL_WIDTH = 550;
  const PANEL_HEIGHT = 420;
  const play = usePlaySound();
  const { scale } = useScale();
  const [input, setInput] = useState(false);
  const [inputValue, setInputValue] = useState<INPUT_VALUE>(INPUT_VALUE.X);
  const [dialog, setDialog] = useState(false);
  const [onCallback, setOnCallback] = useState({
    onOkay: null,
    onCancel: null,
  });
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedScreen = useSelector((state: RootState) =>
    selectScreenById(state, selectedScreenId)
  );
  const screenProperty = useSelector((state: RootState) =>
    selectPropertyById(state, selectedScreenId)
  );
  const [value, setValue] = useState<{ x?: number; y?: number }>();
  const [position, setPosition] = useState<Vector2D>({ x: 0, y: 0 });

  useEffect(() => {
    if (dialog) {
      const workspaceDiv = document.getElementById("blocklyDiv");
      const workspackRect = workspaceDiv.getBoundingClientRect();
      const newX =
        position.x + (PANEL_WIDTH * scale) / 2 > workspackRect.right
          ? workspackRect.right - PANEL_WIDTH * scale
          : position.x - (PANEL_WIDTH * scale) / 2;
      const newY =
        position.y + PANEL_HEIGHT * scale > workspackRect.bottom
          ? position.y - (PANEL_HEIGHT + 20) * scale
          : position.y + 20 * scale;
      setPosition({
        x: newX,
        y: newY,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  Blockly.dialog.position = (
    defaultX: number | undefined,
    defaultY: number | undefined,
    position: Vector2D,
    callback
  ) => {
    setValue({ x: defaultX, y: defaultY });
    setPosition(position);
    setOnCallback({
      onOkay: throttle(callback, 500, { leading: true, trailing: true }),
      onCancel: callback,
    });
    setDialog(true);
  };

  const handleCancel = () => {
    play();
    btnDelay(() => setDialog(false));
  };

  const handleChangeValue = (value: { x?: number; y?: number }) => {
    setValue(value);
    onCallback.onOkay(value);
  };

  const handlePointerStart = (e: PointerEvent) => {
    play();
    handleChangeValue({
      x: value.x === undefined ? undefined : Math.floor(e.nativeEvent.offsetX),
      y: value.y === undefined ? undefined : Math.floor(e.nativeEvent.offsetY),
    });
  };

  const handlePointerEnd = (e: PointerEvent) => {
    handleChangeValue({
      x: value.x === undefined ? undefined : Math.floor(e.nativeEvent.offsetX),
      y: value.y === undefined ? undefined : Math.floor(e.nativeEvent.offsetY),
    });
  };

  const handleShowInput = (inputValue: INPUT_VALUE) => () => {
    play();
    setInput(true);
    setInputValue(inputValue);
  };

  const handleInputValueChange = (v: number) => {
    play();
    if (inputValue === INPUT_VALUE.X) {
      setValue({ x: v, y: value.y });
    } else {
      setValue({ x: value.x, y: v });
    }
    setInput(false);
  };

  const handleCloseInput = () => {
    play();
    btnDelay(() => setInput(false));
  };

  return (
    <>
      {dialog && (
        <Modal
          open
          fullScreen
          maxWidth={false}
          sx={{ zIndex: 10000 }}
          TransitionComponent={FadeTransition}
          componentsProps={{
            backdrop: { style: { backgroundColor: "transparent" } },
          }}
          PaperProps={{ style: { backgroundColor: "transparent" } }}
        >
          <div
            className="flex-col-view w-full h-full"
            onPointerDown={handleCancel}
          >
            <div
              className="flex-col-view !absolute top-0 left-0 drop-shadow-[2px_2px_0px_rgba(63,74,97,0.1)] backdrop-blur-[8px]"
              style={{
                width: PANEL_WIDTH * scale,
                height: PANEL_HEIGHT * scale,
                transform: `translate(${position.x}px,${position.y}px)`,
              }}
              onPointerDown={(e) => e.stopPropagation()}
            >
              <div
                className="flex-col-center origin-top-left bg-gray2/20/50 border-solid border-[4px] border-white rounded-[8px]"
                style={{
                  width: PANEL_WIDTH,
                  height: PANEL_HEIGHT,
                  transform: `scale(${scale})`,
                }}
              >
                {value?.x !== undefined && (
                  <div className="flex-row-center w-full h-[100px] z-10">
                    {value?.y !== undefined && (
                      <div className="w-[140px] h-full" />
                    )}
                    <div className="flex-col-view">
                      <div className="flex-row-center mb-[13px]">
                        <p className="text text-textcolor/black">
                          {i18n.t("MSG_CREATOR_MENU_PROPERTY_X_COORDINATE")}(X):
                        </p>
                        <input
                          type="text"
                          className="text text-textcolor/black w-[64px] h-[40px] !ml-[8px] rounded-[32px] cursor-pointer border-0"
                          value={value.x}
                          readOnly
                          onClick={handleShowInput(INPUT_VALUE.X)}
                        />
                      </div>

                      <div className="flex-col-view w-[372px]">
                        <div
                          className="bg-gray/100 w-[1px] h-[313px] absolute top-0 left-0 pointer-events-none"
                          style={{
                            transform: `translate(${
                              372 * (value.x / 1024)
                            }px, 0px)`,
                          }}
                        />
                        <Slider
                          min={0}
                          max={1024}
                          step={1}
                          value={value.x}
                          onChange={(valueX) =>
                            handleChangeValue({
                              x: Math.floor(valueX as number),
                              y: value.y,
                            })
                          }
                          trackStyle={{
                            height: 32 * 0.3,
                            backgroundColor: "#85929E",
                          }}
                          handleStyle={{
                            height: 32,
                            width: 32,
                            borderColor: "transparent",
                            opacity: 1,
                            marginTop: -11, // - height / 2 + 5 (slider default marginTop is -5)
                            backgroundColor: "#4C9DF1",
                          }}
                          railStyle={{ height: 32 * 0.3 }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex-row-center">
                  {value?.y !== undefined && (
                    <div className="flex-row-center w-[140px] h-full z-10">
                      <div className="flex-row-center">
                        <div className="flex-col-center mr-[16px]">
                          <p className="text text-textcolor/black">
                            {i18n.t("MSG_CREATOR_MENU_PROPERTY_Y_COORDINATE")}
                            <br />
                            (Y):
                          </p>
                          <input
                            type="text"
                            className="text text-textcolor/black w-[64px] h-[40px] rounded-[32px] cursor-pointer border-0"
                            value={value.y}
                            readOnly
                            onClick={handleShowInput(INPUT_VALUE.Y)}
                          />
                        </div>

                        <div className="flex-row-view h-[278px]">
                          <div
                            className="bg-gray/100 w-[403px] h-[1px] absolute top-0 left-0 pointer-events-none"
                            style={{
                              transform: `translate(0px, ${
                                278 * (value.y / 768)
                              }px)`,
                            }}
                          />
                          <Slider
                            vertical
                            reverse
                            min={0}
                            max={768}
                            step={1}
                            value={value.y}
                            onChange={(valueY) =>
                              handleChangeValue({
                                x: value.x,
                                y: valueY as number,
                              })
                            }
                            trackStyle={{
                              width: 32 * 0.3,
                              backgroundColor: "#85929E",
                            }}
                            handleStyle={{
                              height: 32,
                              width: 32,
                              borderColor: "transparent",
                              opacity: 1,
                              marginLeft: -11,
                              backgroundColor: "#4C9DF1",
                            }}
                            railStyle={{ width: 32 * 0.3 }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="w-[372px] h-[278px]">
                    <div
                      className="flex-col-view w-[1024px] h-[768px] overflow-hidden origin-top-left border-solid border-[4px] border-beige/alt"
                      style={{
                        transform: `scale(${372 / 1024}, ${278 / 768})`,
                        backgroundColor:
                          screenProperty.property.style.view.backgroundColor,
                      }}
                      onPointerDown={handlePointerStart}
                      onPointerUp={handlePointerEnd}
                    >
                      {selectedScreen.children.map((component, index) => (
                        <RenderScreenChildComponent
                          key={component.id}
                          id={component.id}
                          zIndex={selectedScreen.childrenOrder.indexOf(
                            component.id
                          )}
                        />
                      ))}

                      {value?.x !== undefined && value?.y !== undefined && (
                        <div
                          className="flex-col-center w-[24px] h-[24px] bg-danger !absolute top-0 left-0 pointer-events-none"
                          style={{
                            transform: `translate(${value.x - 12}px, ${
                              value.y - 12
                            }px)`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {input && (
        <PromptInputNumber
          defaultValue={inputValue === INPUT_VALUE.X ? value.x : value.y}
          message={i18n.t("MSG_CREATOR_MENU_BLOCKLY_PROMPT_INPUT_MESSAGE", {
            placeholder:
              inputValue === INPUT_VALUE.X
                ? i18n.t("MSG_CREATOR_MENU_PROPERTY_X_COORDINATE")
                : i18n.t("MSG_CREATOR_MENU_PROPERTY_Y_COORDINATE"),
          })}
          confirm={handleInputValueChange}
          cancel={handleCloseInput}
        />
      )}
    </>
  );
};
