import { useState } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Slider } from "@mui/material";
import { GameCase } from "app/types";
import { RootState } from "app/store";
import { useUserConfig } from "app/hook";
import Constants from "common/constant";
import { ClickEvent } from "common/types";
import { usePlaySound } from "common/utils";
import { Switch, RadioButton, GameMiniCaseManager } from "common/elements";
import { GameCaseStock } from "./GameCaseStock";

export const UserSetting = ({ selected }: { selected: boolean }) => {
  const languageMapping = {
    ja: " ひらがな",
    ja_kanji: "漢字",
    zh_tw: "中文(繁体字)",
    en: "English",
  };
  const play = usePlaySound();
  const handleChangeUserConfig = useUserConfig();
  const [gameCase, setGameCase] = useState(false);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const [selectedGameCase, setSelectedGameCase] = useState<GameCase>(
    config.game_case
  );
  const SelectedGameCaseIcon = GameMiniCaseManager[selectedGameCase];

  const handleSelectGameCase = () => {
    play();
    setGameCase(true);
  };
  const handleCloseGameCase = () => {
    play();
    setGameCase(false);
  };

  const handleChangeLocale = (locale: string) => () => {
    handleChangeUserConfig("locale", locale);
    I18n.locale = locale;
  };

  const handleChangeTutorial = (value: boolean) => () =>
    handleChangeUserConfig("tutorial.top", value);

  const handleSoundVolume = (
    event: Event,
    value: number | Array<number>,
    activeThumb: number
  ) => handleChangeUserConfig("sound.volume", value as number);

  const handleBgmVolume = (
    event: Event,
    value: number | Array<number>,
    activeThumb: number
  ) => handleChangeUserConfig("bgm.volume", value as number);

  const handleSoundEnable = (e: ClickEvent) => {
    e.stopPropagation();
    handleChangeUserConfig("sound.enable", !config.sound.enable);
  };

  const handleBgmEnable = (e: ClickEvent) => {
    e.stopPropagation();
    handleChangeUserConfig("bgm.enable", !config.bgm.enable);
  };

  return (
    <div
      className="flex-row-center w-full h-full"
      style={{ display: !selected && "none" }}
    >
      <GameCaseStock
        visible={gameCase}
        gameCase={selectedGameCase}
        selectGameCase={setSelectedGameCase}
        close={handleCloseGameCase}
      />
      <div className="flex-row-el items-start w-full">
        <div className="flex-col-el flex-center w-full">
          <div
            id="user-page-setting-case"
            className="flex-row-center w-[353px] h-[186px]"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/assets/images/${SelectedGameCaseIcon}`}
              className="w-full h-full"
            />
          </div>
          <div className="flex-row-el flex-center upsilon">
            <button onClick={handleSelectGameCase} className="btn btn-primary">
              <div className="flex-row-center gap-1 mb-[3px]">
                <span className="material-symbols-outlined text-[30px] text-white">
                  edit_note
                </span>
                <p className="text-body text-white text-font-size-body2">
                  {I18n.t("MSG_USER_PROFILE_CASE_BTN")}
                </p>
              </div>
            </button>
          </div>
        </div>

        <div className="flex-col-el mr-10 gap-10">
          {/* 言語 */}
          <div className="flex-row-el">
            <div className="flex-row-el items-center justify-end w-[220px]">
              <p className="text text-textcolor/black">
                {I18n.t("MSG_SETTING_APP_LANGUAGE")}
              </p>
            </div>

            <div className="flex-row-el">
              <div className="flex-row-el justify-start w-[220px] mx-4 cursor-pointer">
                <RadioButton
                  size={30}
                  value={config.locale === "ja"}
                  label={languageMapping["ja"]}
                  onChange={handleChangeLocale("ja")}
                />
              </div>
              <div className="flex-row-el justify-start cursor-pointer">
                <RadioButton
                  size={30}
                  value={config.locale === "ja_kanji"}
                  label={languageMapping["ja_kanji"]}
                  onChange={handleChangeLocale("ja_kanji")}
                />
              </div>
            </div>
          </div>

          {/* 起動画面 */}
          <div className="flex-row-el">
            <div className="flex-row-el items-center justify-end w-[220px]">
              <p className="text text-textcolor/black">
                {I18n.t("MSG_SETTING_TOP_TUTORIAL")}
              </p>
            </div>

            <div className="flex-row-el">
              <div className="flex-row-el justify-start w-[220px] mx-4 cursor-pointer">
                <RadioButton
                  size={30}
                  value={config.tutorial.top}
                  label={I18n.t("MSG_SETTING_TOP_TUTORIAL_SHOW")}
                  onChange={handleChangeTutorial(true)}
                />
              </div>
              <div className="flex-row-el justify-start w-[180px] cursor-pointer">
                <RadioButton
                  size={30}
                  value={!config.tutorial.top}
                  label={I18n.t("MSG_SETTING_TOP_TUTORIAL_NOT_SHOW")}
                  onChange={handleChangeTutorial(false)}
                />
              </div>
            </div>
          </div>

          {/* 効果音 */}
          <div className="flex-row-el">
            <div className="flex-row-el items-center justify-end w-[220px]">
              <label className="text text-textcolor/black">
                {I18n.t("MSG_SETTING_SOUND")}
              </label>
            </div>
            <div className="flex-row-center mx-4 pointer-events-auto">
              <div className="w-[220px]">
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  componentsProps={{
                    rail: {
                      style: {
                        height: 9,
                        backgroundColor: "#DBD8DA",
                        opacity: 1,
                      },
                    },
                    track: {
                      style: {
                        height: 9,
                        backgroundColor: "#94C4F7",
                        borderColor: "#94C4F7",
                      },
                    },
                    thumb: {
                      style: {
                        width: 32,
                        height: 32,
                        backgroundColor: "#94C4F7",
                      },
                    },
                  }}
                  value={config.sound.volume}
                  onChange={handleSoundVolume}
                  aria-label="sound volume slider"
                />
              </div>
            </div>
            <div className="flex-row-view justify-start w-[160px]">
              <Switch
                color="#B8B1B5"
                background="#DBEBFC"
                thumb="#94C4F7"
                margin={3}
                width={96}
                height={40}
                value={config.sound.enable}
                onClick={handleSoundEnable}
              />
            </div>
          </div>

          {/* BGM */}
          <div className="flex-row-el">
            <div className="flex-row-el items-center justify-end w-[220px]">
              <label className="text text-textcolor/black">
                {I18n.t("MSG_SETTING_BGM")}
              </label>
            </div>
            <div className="flex-row-center mx-4 pointer-events-auto">
              <div className="w-[220px]">
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  componentsProps={{
                    rail: {
                      style: {
                        height: 9,
                        backgroundColor: "#DBD8DA",
                        opacity: 1,
                      },
                    },
                    track: {
                      style: {
                        height: 9,
                        backgroundColor: "#94C4F7",
                        borderColor: "#94C4F7",
                      },
                    },
                    thumb: {
                      style: {
                        width: 32,
                        height: 32,
                        backgroundColor: "#94C4F7",
                      },
                    },
                  }}
                  value={config.bgm.volume}
                  onChange={handleBgmVolume}
                  aria-label="bgm volume slider"
                />
              </div>
            </div>
            <div className="flex-row-view justify-start w-[160px]">
              <Switch
                color="#B8B1B5"
                background="#DBEBFC"
                thumb="#94C4F7"
                margin={3}
                width={96}
                height={40}
                value={config.bgm.enable}
                onClick={handleBgmEnable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
