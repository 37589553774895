import { SVGProps } from "react";

export const IconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 48"
    fill="none"
    {...props}
  >
    <path
      fill="#FFB96E"
      d="M54 24 30 0v48l24-24ZM14 12h16v24H14zM6 12h6v24H6zM0 12h4v24H0z"
    />
  </svg>
);
