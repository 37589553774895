import Blockly from "blockly";
import Constants from "common/constant";

const componentStyles = { workspaceBackgroundColour: "rgba(255,255,255,0.2)" };
export const theme = Blockly.Theme.defineTheme("inexus-courses", {
  base: Blockly.Themes.Zelos,
  componentStyles: componentStyles,
  fontStyle: { family: "MPLUSRounded1c-Bold", weight: "normal", size: 16 },
  startHats: true,
});

export const BlocklyOptions = (
  scale: number,
  maxInstances: { [type: string]: number },
  toolbox: Blockly.utils.toolbox.ToolboxDefinition
) => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: true,
  horizontalLayout: false,
  maxInstances: JSON.parse(JSON.stringify(maxInstances)),
  media: `${Constants.assetHost}/assets/blockly/game/`,
  move: {
    scrollbars: false,
    drag: true,
    wheel: false,
  },
  zoom: {
    startScale: scale,
  },
  oneBasedIndex: true,
  readOnly: false,
  rtl: false,
  toolbox: toolbox,
  toolboxPosition: "start",
  trashcan: true,
  maxTrashcanContents: 32,
  renderer: "zelos",
  theme: theme,
});
