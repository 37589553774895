import React, { SVGProps } from "react";

export const ColorTransparentBtn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 26 27 5" stroke="#FC6560" strokeWidth={2} />
    <rect
      x={1}
      y={1}
      width={30}
      height={30}
      rx={15}
      stroke="#A3ADB6"
      strokeWidth={2}
    />
  </svg>
);
