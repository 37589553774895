export enum Course {
  ALGORITHM = "algorithm",
  PROGRAM = "program",
  TANGIBLE = "tangible",
  BUILDER = "builder",
  NISHISHINJUKU = "nishishinjuku",
  TAKUSHOKU_BASIC = "takushoku_basic",
  TAKUSHOKU_ADVANCE = "takushoku_advance",
  OCHA = "ocha",
}

export enum CourseTrackAction {
  BEGIN = "BEGIN",
  START = "START",
  OVER = "OVER",
  END = "END",
  RESET = "RESET",
  GUIDE = "GUIDE",
  GAME_SCENE_CLICK = "GAME_SCENE_CLICK",
}

export type HintDescription = {
  contents: string;
  image: string[];
};

export interface AlgorithmSummary {
  id: number;
  uid: string;
  finished: number;
  total: number;
  all_stage_clear_animation: boolean;
}

export interface AlgorithmProgress {
  id: number;
  stage: {
    id: number;
    uid: string;
    stage_id: number;
    finished: number;
    total: number;
    animation: number;
  };
  level: {
    id: number;
    uid: string;
    stage_id: number;
    level_id: number;
    answer: string;
    is_finished: boolean;
    finished_at: string;
    tips: boolean;
  }[];
}

export interface ProgramSummary {
  id: number;
  uid: string;
  finished: number;
  total: number;
  all_stage_clear_animation: boolean;
}

export interface ProgramProgress {
  id: number;
  stage: {
    id: number;
    uid: string;
    stage_id: number;
    finished: number;
    total: number;
    animation: number;
  };
  level: {
    id: number;
    uid: string;
    stage_id: number;
    level_id: number;
    answer: string;
    is_finished: boolean;
    finished_at: string;
    tips: boolean;
  }[];
}

export interface LevelRect {
  width: number;
  height: number;
  left: number;
  top: number;
}

export interface CourseMessageContent {
  name: string;
  description: string;
  tips?: string[];
  level: {
    name: string;
    description: string;
    purpose: string[];
    goal: string;
    tips?: string[];
    hint: {
      keypoints: string[];
      title: string;
      description: string[];
      blocks: string[];
    };
  }[];
}

export interface CourseMessage {
  id: number;
  ja: CourseMessageContent;
  ja_kanji: CourseMessageContent;
}

export interface CourseSummary {
  id: number;
  type: string;
  uid: string;
  finished: number;
  total: number;
  all_stage_clear_animation: boolean;
}

export interface CourseProgress {
  stage: {
    id: number;
    uid: string;
    course_id: number;
    stage_id: number;
    finished: number;
    total: number;
    animation: number;
  };
  level: {
    id: number;
    uid: string;
    stage_progress_id: number;
    stage_id: number;
    level_id: number;
    answer: string;
    is_finished: boolean;
    finished_at: string;
    tips: boolean;
  }[];
}
