import axios from "axios";
import axiosRetry from "axios-retry";
import Constants from "common/constant";

export const client = ({ token }: { token: string }) => {
  const client = axios.create({ baseURL: Constants.apiHost });
  client.defaults.headers.common["Authorization"] = token;
  client.defaults.headers.common["Content-Type"] = "application/json";
  axiosRetry(client, {
    retries: 3,
    retryCondition: () => true,
    retryDelay: function (retryCount, error) {
      return 1000;
    },
  });
  return client;
};
