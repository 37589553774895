import $ from "jquery";
import anime from "animejs";
import chroma from "chroma-js";
import { SHADOW_SIZE_MIN, SHADOW_SIZE_MAX } from "common/components";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const getShadowRadiusFromFilter = (
  filter: string | undefined
): number => {
  if (filter) {
    return Number(filter.split(")")[1].split("px")[2].trim());
  } else {
    return 0;
  }
};

export const getShadowRgbaColorFromFilter = (
  filter: string | undefined
): Number[] => {
  if (filter) {
    if (filter.includes("rgba")) {
      const rgba = filter
        .split("rgba(")[1]
        .split(")")[0]
        .split(",")
        .map((v) => Number(v));
      return rgba;
    } else {
      const rgb = filter
        .split("rgb(")[1]
        .split(")")[0]
        .split(",")
        .map((v) => Number(v));
      rgb.push(0);
      return rgb;
    }
  } else {
    return undefined;
  }
};

export const getShadowColorFromFilter = (
  filter: string | undefined
): string => {
  if (filter) {
    const colorValue = filter.includes("rgba")
      ? filter.split("rgba(")[1]
      : filter.split("rgb(")[1];
    const rgb = colorValue
      .split(")")[0]
      .split(",")
      .map((v) => Number(v));
    return chroma(rgb).hex();
  } else {
    return undefined;
  }
};

export const getShadowColor = (id: string): string => {
  const obj = $(`#${id}`);
  if (obj.length === 0) return;
  const filter = obj.css("filter");
  return getShadowColorFromFilter(filter === "none" ? undefined : filter);
};
export const setShadowColor = (id: string, value: string) => {
  const obj = $(`#${id}`);
  if (obj.length === 0) return;
  const filter = obj.css("filter");
  const shadowRadius = getShadowRadiusFromFilter(
    filter === "none" ? undefined : filter
  );
  const color = chroma(value).rgba();
  obj.css(
    "filter",
    `drop-shadow(0px 0px ${shadowRadius}px rgba(${color[0]}, ${color[1]}, ${
      color[2]
    }, ${shadowRadius === 0 ? 0 : color[3]}))`
  );
};

export const getShadowRadius = (id: string): number => {
  const obj = $(`#${id}`);
  if (obj.length === 0) return;
  const filter = obj.css("filter");
  const shadowRadius = getShadowRadiusFromFilter(filter);
  return shadowRadius;
};

export const setShadowRadius = (id: string, value: number) => {
  const obj = $(`#${id}`);
  if (obj.length === 0) return;
  const filter = obj.css("filter");
  const color = getShadowRgbaColorFromFilter(
    filter === "none" ? undefined : filter
  );

  let target: number;
  if (value > SHADOW_SIZE_MAX) {
    target = SHADOW_SIZE_MAX;
  } else if (value < SHADOW_SIZE_MIN) {
    target = SHADOW_SIZE_MIN;
  } else {
    target = value;
  }

  obj.css(
    "filter",
    `drop-shadow(0px 0px ${target}px rgba(${color[0]}, ${color[1]}, ${
      color[2]
    }, ${target === 0 ? 0 : 1}))`
  );
};

export const setShadowRadiusByStep = (id: string, value: number) => {
  const obj = $(`#${id}`);
  if (obj.length === 0) return;
  const filter = obj.css("filter");
  const color = getShadowRgbaColorFromFilter(filter);
  const shadowRadius = getShadowRadiusFromFilter(filter);
  const newShadowRadius = value + shadowRadius;

  let target: number;
  if (newShadowRadius > SHADOW_SIZE_MAX) {
    target = SHADOW_SIZE_MAX;
  } else if (newShadowRadius < SHADOW_SIZE_MIN) {
    target = SHADOW_SIZE_MIN;
  } else {
    target = newShadowRadius;
  }

  obj.css(
    "filter",
    `drop-shadow(0px 0px ${target}px rgba(${color[0]}, ${color[1]}, ${
      color[2]
    }, ${target === 0 ? 0 : 1}))`
  );
};

export const setShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const obj = $(`#${id}`);
  if (obj.length === 0) return;
  const filter = obj.css("filter");
  const shadowRadius = getShadowRadiusFromFilter(filter);
  const color = getShadowRgbaColorFromFilter(filter);
  if (color === undefined) return;

  let target: number;
  if (value > SHADOW_SIZE_MAX) {
    target = SHADOW_SIZE_MAX;
  } else if (value < SHADOW_SIZE_MIN) {
    target = SHADOW_SIZE_MIN;
  } else {
    target = value;
  }
  const animation = anime({
    targets: `#${id}`,
    filter: [
      `drop-shadow(0px 0px ${shadowRadius}px rgba(${color[0]}, ${color[1]}, ${color[2]},${color[3]}))`,
      `drop-shadow(0px 0px ${target}px rgba(${color[0]}, ${color[1]}, ${
        color[2]
      }, ${target === 0 ? 0 : 1}))`,
    ],
    autoplay: false,
    duration: time * 1000,
    easing: "easeInOutQuad",
  });
  animation.play();
  previewRemovedObserver(() => animation.pause());
  return animation.finished;
};
