import { SVGProps } from "react";

export const IconDrawingPen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 17V5c0-1.121-.879-2-2-2s-2 .879-2 2v12l2 2 2-2Z"
      fill="#94C4F7"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 7h4M18 19H5a2 2 0 1 1 0-4h4a2 2 0 1 0 0-4H6"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
