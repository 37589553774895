import { SVGProps } from "react";

export const PickBtnBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={191}
    height={199}
    fill="none"
    {...props}
  >
    <path
      fill="#DEC6BA"
      fillRule="evenodd"
      d="M95.566 13.007c40.133-.498 75.98 24.542 89.052 62.17 13.835 39.824 5.624 86.247-29.026 110.612-33.656 23.666-78.587 12.988-111.31-11.93C14.4 151.105 1.77 112.928 13.127 77.307c11.651-36.545 43.788-63.82 82.439-64.3Z"
      clipRule="evenodd"
    />
    <path
      fill="#FEFDFE"
      fillRule="evenodd"
      stroke="#DEC6BA"
      d="M87.566 1.007c40.133-.498 75.98 24.542 89.052 62.17 13.835 39.824 5.624 86.247-29.026 110.612-33.656 23.666-78.587 12.988-111.31-11.93C6.4 139.105-6.23 100.928 5.127 65.307c11.651-36.545 43.788-63.82 82.439-64.3Z"
      clipRule="evenodd"
    />
  </svg>
);

export const PickBtnClickedBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={190}
    height={195}
    fill="none"
    {...props}
  >
    <path
      fill="#DEC6BA"
      fillRule="evenodd"
      d="M94.566 9.007c40.133-.499 75.98 24.542 89.052 62.17 13.835 39.824 5.624 86.247-29.026 110.612-33.656 23.666-78.587 12.987-111.31-11.93C13.4 147.105.77 108.928 12.127 73.307c11.651-36.545 43.788-63.82 82.439-64.3Z"
      clipRule="evenodd"
    />
    <path
      fill="#FEFDFE"
      fillRule="evenodd"
      stroke="#DEC6BA"
      d="M87.566 1.007c40.133-.499 75.98 24.542 89.052 62.17 13.835 39.824 5.624 86.247-29.026 110.612-33.656 23.666-78.587 12.987-111.31-11.93C6.4 139.105-6.23 100.928 5.127 65.307c11.651-36.545 43.788-63.82 82.439-64.3Z"
      clipRule="evenodd"
    />
  </svg>
);
