export const previewRemovedObserver = (removedCallback: () => void) => {
  const config = { attributes: false, childList: true, subtree: false };
  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        if (mutation.removedNodes.length > 0) {
          observer.disconnect();
          removedCallback();
        }
      }
    }
  };
  const observer = new MutationObserver(callback);
  const target = document.getElementById("preview-runtime-view");
  observer.observe(target, config);
};
