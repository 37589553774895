import { ScreenOrientation } from "features/creator/types";

export const SCREEN_SIZE = {
  // visible area based on 1280x960 size
  [ScreenOrientation.LANDSCAPE]: {
    WIDTH: 1024,
    HEIGHT: 768,
  },
  [ScreenOrientation.PORTRAIT]: {
    WIDTH: 1024,
    HEIGHT: 768,
  },
};
export const DEFAULT_SCREEN_ORIENTATION = ScreenOrientation.LANDSCAPE; // orientation: "portrait"(縦) | "landscape"(横)
export const INITIAL_SCREEN_CANVAS_SCALE = 1;
export const SCREEN_CANVAS_SCALE_MAX = 1.5;
export const SCREEN_CANVAS_SCALE_MIN = 0.1;
export const MAX_SCREENS = 5;
export const MAX_PROJECTS = 5;
export const HISTORY_STACK_MAX = 20;
export const ASSET_LIMIT = 50;
