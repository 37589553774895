import { SVGProps } from "react";

export const IconUserProfile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 21.428a8.571 8.571 0 1 0 0-17.142 8.571 8.571 0 0 0 0 17.142Z"
      fill="#FFB96E"
      stroke="#667786"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 42.857H12v-7.714c0-4.286 2.571-9.429 8.571-9.429h6.858c6 0 8.571 5.143 8.571 9.429v7.714Z"
      fill="#FFB96E"
      stroke="#667786"
      strokeWidth={3}
    />
  </svg>
);
