import i18n from "i18n-js";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import throttle from "lodash-es/throttle";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { fetchAuthToken } from "app/Auth";
import {
  ComponentTypeIds,
  ComponentManager,
  ComponentCategory,
} from "common/components";
import { client } from "common/apiClient";
import {
  ProjectType,
  CanvasEntity,
  ProjectEntity,
  BlocklyEntity,
  ScreensEntity,
  PropertiesEntity,
  ScreenOrientation,
  ComponentsEntityData,
} from "features/creator/types";
import {
  SCREEN_SIZE,
  DEFAULT_SCREEN_ORIENTATION,
} from "features/creator/constants";

export const getAppProjects = createAsyncThunk<
  ProjectEntity[],
  {
    uid: string;
    project_types?: ProjectType[];
  }
>("creator/getAppProjects", async ({ uid, project_types }) => {
  const token = await fetchAuthToken();
  const response = await client({
    token: token,
  }).get(
    `/v1/projects/user/${uid}${
      !!project_types && project_types.length > 0
        ? `?project_type=${project_types.join(",")}`
        : ""
    }`
  );
  return response.data;
});

export const createScreen = (
  orientation: ScreenOrientation,
  name: string,
  order: number
): { screen: ScreensEntity; screenProperty: PropertiesEntity } => {
  const screenId = uuidv4();
  const screenTypeId = ComponentTypeIds.SCREEN;
  const screenCategoryId = ComponentCategory.ACTION;
  const screen: ScreensEntity = {
    id: screenId,
    name: name,
    order: order,
    children: [],
    childrenOrder: [],
  };
  const defaultProperty = ComponentManager[screenTypeId].component.property;
  const property = produce(defaultProperty, (draft) => {
    draft.name = name;
    draft.defaultName = name;
    draft.screenshot = "";
    draft.style.layout.width = SCREEN_SIZE[orientation].WIDTH;
    draft.style.layout.height = SCREEN_SIZE[orientation].HEIGHT;
  });
  const screenProperty: PropertiesEntity = {
    id: screenId,
    typeId: screenTypeId,
    categoryId: screenCategoryId,
    screenId: screenId,
    property: property,
  };
  return { screen, screenProperty };
};

export const createProject = createAsyncThunk<
  { project: ProjectEntity },
  {
    uid: string;
    name: string;
    type: ProjectType;
    tags?: string;
  }
>("creator/createProject", async ({ uid, name, type, tags }) => {
  const screenTypeId = "screen";
  const { screen, screenProperty } = createScreen(
    DEFAULT_SCREEN_ORIENTATION,
    `${i18n.t("MSG_CREATOR_COMPONENT_NAME_SCREEN")}1`,
    0
  );
  const components: ComponentsEntityData = Array.from(
    Object.values(ComponentManager)
  ).reduce(
    (acc, component) =>
      Object.assign(
        acc,
        component.id === screenTypeId
          ? {
              [component.id]: [{ id: screen.id, deleted: false }],
            }
          : { [component.id]: [] }
      ),
    {}
  );
  const canvas: CanvasEntity = { id: screen.id, x: 0, y: 0 };
  const blockly: BlocklyEntity = {
    screenId: screen.id,
    xmlText: '<xml xmlns="https://developers.google.com/blockly/xml"></xml>',
  };

  const token = await fetchAuthToken();
  const response = await client({
    token: token,
  }).post(`/v1/projects/user/${uid}`, {
    type,
    tags,
    name,
    content: {
      selectedScreenId: screen.id,
      defaultScreenId: screen.id,
      screenSize: {
        width: SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].WIDTH,
        height: SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].HEIGHT,
        orientation: DEFAULT_SCREEN_ORIENTATION,
      },
      canvas: [canvas],
      screens: [screen], // {id: String, name: String, typeId: String, children: Array}
      components: [{ screenId: screen.id, data: components }], // [{screenId: String, data: [{componentTypeId:[componentId]}]]
      properties: [screenProperty], // [{id(componentId): String, screenId: String, property: Object}]
      blockly: [blockly],
    },
  });
  return { ...response.data.data };
});

export const copyProject = createAsyncThunk<
  { project: ProjectEntity },
  {
    uid: string;
    copied_project_id: string;
    name: string;
  }
>("creator/copyProject", async ({ uid, copied_project_id, name }) => {
  const token = await fetchAuthToken();
  const response = await client({
    token: token,
  }).post(`/v1/projects/user/${uid}/copy/${copied_project_id}`, { name });
  return { ...response.data.data };
});

export const getUserProjectContent = createAsyncThunk(
  "creator/getUserProjectContent",
  async ({ project_id }: { project_id: string }) => {
    const token = await fetchAuthToken();
    const response = await client({
      token: token,
    }).get(`/v1/projects/user/content/${project_id}`);
    return { ...response.data.data };
  }
);

export const updateProject = createAsyncThunk(
  "creator/updateProject",
  async ({
    uid,
    project_id,
    project,
  }: {
    uid: string;
    project_id: string;
    project: ProjectEntity;
  }) => {
    const token = await fetchAuthToken();
    const response = await client({
      token: token,
    }).put(`/v1/projects/user/${uid}/meta/${project_id}`, project);
    return { ...response.data.data };
  }
);

export const updateProjectCapture = async ({
  uid,
  project_id,
  project,
}: {
  uid: string;
  project_id: string;
  project: ProjectEntity;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).put(`/v1/projects/user/${uid}/meta/${project_id}`, project);
};

export const updateProjectContentImmediately = createAsyncThunk(
  "creator/updateProjectContentImmediately",
  async ({ uid }: { uid: string }, thunkAPI) => {
    const token = await fetchAuthToken();
    const { creator } = thunkAPI.getState() as RootState;
    try {
      await client({
        token: token,
      }).put(`/v1/projects/user/${uid}/content/${creator.editingProjectId}`, {
        content: {
          defaultScreenId: creator.defaultScreenId,
          screenSize: creator.screenSize,
          canvas: creator.canvas.entities,
          screens: creator.screens.entities,
          components: creator.components.entities,
          properties: creator.properties.entities,
          blockly: creator.blockly.entities,
        },
      });
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }
);

export const updateProjectContent = createAsyncThunk(
  "creator/updateProjectContent",
  throttle(async ({ uid }: { uid: string }, thunkAPI) => {
    const token = await fetchAuthToken();
    const { creator } = thunkAPI.getState() as RootState;
    try {
      await client({
        token: token,
      }).put(`/v1/projects/user/${uid}/content/${creator.editingProjectId}`, {
        content: {
          defaultScreenId: creator.defaultScreenId,
          screenSize: creator.screenSize,
          canvas: creator.canvas.entities,
          screens: creator.screens.entities,
          components: creator.components.entities,
          properties: creator.properties.entities,
          blockly: creator.blockly.entities,
        },
      });
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  }, 5000)
);

export const removeProject = createAsyncThunk(
  "creator/removeProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    const response = await client({
      token: token,
    }).delete(`/v1/projects/user/${uid}/${project_id}`);
    return { project_id, deleted_at: response.data.deleted_at };
  }
);

export const deleteProject = createAsyncThunk(
  "creator/deleteProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).delete(`/v1/projects/user/${uid}/${project_id}/completely`);
    return project_id;
  }
);

export const restoreProject = createAsyncThunk(
  "creator/restoreProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).put(`/v1/projects/user/${uid}/restore/${project_id}`);
    return project_id;
  }
);

export const publishProject = createAsyncThunk(
  "creator/publishProject",
  async ({
    uid,
    project_id,
    enable_copied,
  }: {
    uid: string;
    project_id: string;
    enable_copied: boolean;
  }) => {
    const token = await fetchAuthToken();
    const response = await client({
      token: token,
    }).post(`/v1/projects/published/${uid}/${project_id}`, { enable_copied });
    return response.data;
  }
);

export const stopPublishProject = createAsyncThunk(
  "creator/stopPublishProject",
  async ({ uid, project_id }: { uid: string; project_id: string }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).delete(`/v1/projects/published/${uid}/${project_id}`);
    return project_id;
  }
);

export const favoriteProject = async ({
  uid,
  project_id,
}: {
  uid: string;
  project_id: string;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).post(`/v1/projects/favorited/${uid}/${project_id}`);
};

export const stopFavoriteProject = async ({
  uid,
  project_id,
}: {
  uid: string;
  project_id: string;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).delete(`/v1/projects/favorited/${uid}/${project_id}`);
};

export const tagProject = createAsyncThunk(
  "creator/tagProject",
  async ({
    uid,
    project_id,
    tag,
    tags,
  }: {
    uid: string;
    project_id: string;
    tag: string;
    tags: string;
  }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).post(`/v1/projects/user/${uid}/tag/${project_id}`, { tag, tags });
    return { project_id, tags };
  }
);

export const removeTagProject = createAsyncThunk(
  "creator/removeTagProject",
  async ({
    uid,
    project_id,
    tags,
  }: {
    uid: string;
    project_id: string;
    tags: string;
  }) => {
    const token = await fetchAuthToken();
    await client({
      token: token,
    }).put(`/v1/projects/user/${uid}/tag/${project_id}`, { tags });
    return { project_id, tags };
  }
);

export const getProjectRanking = async ({
  project_types,
}: {
  project_types?: ProjectType[];
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  })
    .get(
      `/v1/ranking/projects/by/favorited${
        !!project_types && project_types.length > 0
          ? `?project_type=${project_types.join(",")}`
          : ""
      }`
    )
    .then((res) => res.data.data);
};

export const getUserRanking = async ({
  project_types,
}: {
  project_types?: ProjectType[];
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  })
    .get(
      `/v1/ranking/user/by/viewed${
        !!project_types && project_types.length > 0
          ? `?project_type=${project_types.join(",")}`
          : ""
      }`
    )
    .then((res) => res.data.data);
};

export const getUserRankingProjects = async ({
  uid,
  project_types,
}: {
  uid: string;
  project_types?: ProjectType[];
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).get(
    `/v1/ranking/user/${uid}/projects${
      !!project_types && project_types.length > 0
        ? `?project_type=${project_types.join(",")}`
        : ""
    }`
  );
};

export const getPickupProject = async () => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  })
    .get("/v1/pickup/projects")
    .then((res) => res.data.data);
};
