import { useScale } from "common/utils";
import { PickUpBtn } from "./pickup";
import { RankingBtn } from "./ranking";

export const RecommendPanel = () => {
  const { scale } = useScale();
  return (
    <div
      className="flex-col-el"
      style={{ width: 230 * scale, height: 518 * scale }}
    >
      <div
        className="flex-col-el w-[230px] origin-top-left"
        style={{ transform: `scale(${scale})` }}
      >
        <div className="flex-col-el gap-16 mt-14">
          <RankingBtn />
          <PickUpBtn />
        </div>
      </div>
    </div>
  );
};
