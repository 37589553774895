import { ComponentProperty } from "common/components";
import { PropertiesEntity } from "features/creator/types";

export enum TabIndex {
  NONE = 0,
  BASIC = 1,
  POSITION = 2,
  SIZE = 3,
  SHAPE = 4,
  APPEARANCE = 5,
  COLOR = 6,
  LOGIC = 7,
}

export type PropertyProps = {
  selectedTabIndex: TabIndex;
  show?: boolean;
  scale: number;
  updateProperty: (id?: string, property?: ComponentProperty) => void;
  propertyEntity: PropertiesEntity;
};
