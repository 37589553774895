/* eslint-disable no-new-func */
import { useState } from "react";
import I18n from "i18n-js";
import anime from "animejs";
import Constants from "common/constant";
import { btnDelay, sleep, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const {
    event,
    highlightBlock,
    setRunning,
    setSuccess,
    gameOver,
    setGameOver,
  } = props;
  const play = usePlaySound();
  let target: HTMLImageElement;
  const targetEles = [20, 40, 40, 60, 80, 80];
  const [clicked, setClicked] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [result, setResult] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleEvent = (index: number) =>
    new Function(
      "anime",
      "sleep",
      "highlightBlock",
      "setClicked",
      "clickedIndex",
      "setClickedIndex",
      "target",
      "targetEles",
      "result",
      "setResult",
      "setRunning",
      "setSuccess",
      "setGameOver",
      event[index]
        ? event[index]
        : `return () => {
              setSuccess(false);
              setGameOver(true);
          }
          `
    );

  const handleImageClick = () => {
    if (!clicked && !gameOver) {
      play();
      setClicked(true);
      setRunning(true);
      handleEvent(0)(
        anime,
        sleep,
        highlightBlock,
        setClicked,
        clickedIndex,
        setClickedIndex,
        target,
        targetEles,
        result,
        setResult,
        setRunning,
        setSuccess,
        setGameOver
      )();
    }
  };

  const handleButtonClick = () => {
    if (!clicked && !gameOver) {
      btnDelay(() => {
        play();
        setClicked(true);
        setRunning(true);
        handleEvent(1)(
          anime,
          sleep,
          highlightBlock,
          setClicked,
          clickedIndex,
          setClickedIndex,
          target,
          targetEles,
          result,
          setResult,
          setRunning,
          setSuccess,
          setGameOver
        )();
      });
    }
  };

  return (
    <div className="flex-row-center w-full gap-20">
      <div className="flex-col-el flex-center mt-8">
        <div className="flex-row-el flex-center absolute top-8">
          <p className="text-body text-white text-[20px]">
            {I18n.t("MSG_COURSE_COORDINATE_X")}
          </p>
        </div>

        <div className="flex-col-center w-[360px] h-[5px]">
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
            <p className="text text-white !mb-[35px]">0</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[25%]">
            <p className="text text-white !mb-[35px]">20</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
            <p className="text text-white !mb-[35px]">40</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[75%]">
            <p className="text text-white !mb-[35px]">60</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
            <p className="text text-white !mb-[35px]">80</p>
          </div>
          <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />
          <div
            data-value="0"
            id="course-program-image-1"
            className="flex-row-el flex-center absolute top-4 -left-10 z-50 cursor-pointer"
          >
            <img
              alt="*"
              onClick={handleImageClick}
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] active:opacity-70"
            />
            <AnswerMark id={1} />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${20 * 4.5}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${40 * 4.5}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${60 * 4.5}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-10">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${80 * 4.5}px)` }}
            />
          </div>
        </div>
      </div>

      <div className="flex-col-center gap-2">
        <div className="flex-col-center w-[312px]">
          <div className="flex-row-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
            <p
              id="course-program-answer-label-1"
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              40
            </p>
            <p
              id="course-program-answer-label-2"
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-0"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              80
            </p>
            <p
              id="course-program-label-1"
              className="text-body text-white text-[100px] leading-[100px] opacity-0 absolute"
            />

            <AnswerMark id={2} />
          </div>

          <p className="text text-white">ボックス1</p>
        </div>

        <div className="flex-row-el flex-center w-[150px] h-[50px] relative">
          <div className="flex-row-el flex-center upsilon absolute bottom-0 pointer-events-auto cursor-pointer z-50">
            <button
              disabled={clicked[1] || gameOver}
              onClick={handleButtonClick}
              className="btn btn-blue"
            >
              <p className="text !mb-[2px]">ボタン１</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
