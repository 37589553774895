import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Howl } from "howler";
import platfrom from "platform";
import { RootState } from "app/store";
import { Vector2D } from "common/types";
import Constants, { BASE_WIDTH, BASE_HEIGHT } from "common/constant";

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const calScale = (width: number, height: number) => {
  const scaleX = width / BASE_WIDTH;
  const scaleY = height / BASE_HEIGHT;

  return scaleX < scaleY ? scaleX : scaleY;
};

export const getRandomArbitrary = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

export const useWindowDimensions = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const resize = useCallback(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { width, height };
};

export const useScale = () => {
  const { width, height } = useWindowDimensions();
  const scale = calScale(width, height);
  const ratio = width / height;
  return { width, height, scale, ratio };
};

export const calcVector = (x: number, y: number, radius: number): Vector2D => {
  const refPointDist = Math.hypot(y, x);
  const refPointRadius = Math.atan2(y, x) + radius;
  return {
    x: refPointDist * Math.cos(refPointRadius),
    y: refPointDist * Math.sin(refPointRadius),
  };
};

export const shuffle = (array: any[]) => {
  for (let i = array.length - 1; i >= 0; i--) {
    let rand = Math.floor(Math.random() * (i + 1));
    // 配列の数値を入れ替える
    [array[i], array[rand]] = [array[rand], array[i]];
  }
  return array;
};

export const insideInstalledApp = () =>
  window.matchMedia("(display-mode: standalone)").matches ||
  ("standalone" in window.navigator && window.navigator["standalone"] === true);

export const usePlaySound = (src?: string, option?: any) => {
  const [soundSource, setSoundSource] = useState(null);
  const { sound } = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if ("AudioContext" in window) {
      const soundSource = new Howl({
        mute: !sound.enable,
        volume: sound.volume,
        autoplay: false,
        loop: false,
        src: [src ?? `${Constants.assetHost}/assets/sounds/pop-up-off.mp3`],
        ...option,
      });
      setSoundSource(soundSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sound]);

  const playNone = () => {};
  return useCallback(() => {
    if (soundSource) {
      soundSource.play();
    } else {
      playNone();
    }
  }, [soundSource]);
};

export const btnDelay = (callback: () => void) =>
  setTimeout(() => callback(), 300);

export const getUserLanguage = () => {
  const userLanguage = window.kidsAppHostLanguage ?? window.navigator.language;
  const code1 = userLanguage.split("-")[0];

  if (code1 === "en") {
    return "en";
  } else if (code1 === "ja") {
    return "ja";
  } else if (code1 === "zh") {
    const code2 = userLanguage.split("-")[1];
    if (code2 === "Hans") {
      return "zh_cn";
    } else if (code2 === "Hant") {
      return "zh_tw";
    } else {
      return "zh_cn";
    }
  } else {
    return "en";
  }
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isDev =
  process.env.NODE_ENV === "development" || import.meta.env.DEV;
export const isProd =
  process.env.NODE_ENV === "production" || import.meta.env.PROD;
export const isDebugMode =
  typeof Constants.debugMode === "boolean"
    ? Constants.debugMode
    : Constants.debugMode.toLocaleLowerCase() === "true";

export const isMobile = () => {
  const isIos = platfrom.os.family === "iOS";
  const isFire = FireModels.test(platfrom.os.family);
  const isAndroid = platfrom.os.family === "Android";
  return isIos || isAndroid || isFire;
};

export const isChrome = () => {
  return /Chrome/.test(platfrom.name);
};

export const isIos = () => {
  return platfrom.os.family === "iOS";
};

export const isAndroid = () => {
  return platfrom.os.family === "Android";
};

const FireModels = /KFQUWI|KFTRWI|KFTRPWI|KFONWI|KFMAWI|KFMUWI|KFKAWI|KFSUWI|KFDOWI|KFAUWI|KFGIWI|KFTBWI|KFMEWI|KFFOWI|KFSAWA|KFSAWI|KFASWI|KFARWI|KFTHWA|KFTHWI|KFAPWA|KFAPWI|KFSOWI|KFOT|KFTT|KFJWA|KFJWI|Kindle Fire/;

export const isFire = () => {
  return FireModels.test(platfrom.product);
};

export const dataURLToBlob = (dataURL: string) => {
  const binaryString = window.atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: mimeString });
};

export const handleError = (
  error: any,
  title: string,
  callback?: () => void
) => {
  console.error(error);
  console.error(JSON.stringify(error));
  toast(
    <div>
      <p className="text text-textcolor/black">{title}</p>
    </div>
  );
  if (callback) {
    callback();
  }
};
