import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Blockly from "blockly";
import { RootState } from "app/store";
import { btnDelay, useScale } from "common/utils";
import {
  PeerConnectionMessageType,
  PeerConnectionMessageContent,
} from "features/connection/type";
import { CameraIcon } from "features/creator/property/components";
import { Camera } from "./Camera";
import { ExecutableBlock } from "./type";
import { useWorkspaceBlockGenerator } from "./useBlockGenerator";

export const Control = ({
  workspace,
  setEvent,
}: {
  workspace: Blockly.WorkspaceSvg;
  setEvent: (code: string) => void;
}) => {
  const TOP_OFFSET = 120 + 32;
  const COMMAND_PANEL_HEIGHT = 82;
  const { scale } = useScale();
  const [local, setLocal] = useState(false);
  const message = useSelector((state: RootState) => state.connection.receive);

  const { generateWorkspaceBlock } = useWorkspaceBlockGenerator(
    workspace,
    scale,
    TOP_OFFSET + COMMAND_PANEL_HEIGHT,
    setEvent
  );

  const handleLocalCamera = () => {
    btnDelay(() => setLocal(true));
  };

  const handleMessage = (message: PeerConnectionMessageContent) => {
    try {
      if (!message) return;

      const { content } = message;
      switch (content.type) {
        case PeerConnectionMessageType.TANGIBLE:
          const { blocks } = JSON.parse(content.data) as {
            blocks: ExecutableBlock[][];
            isAiRobotTeacher: boolean;
          };

          generateWorkspaceBlock(blocks);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("unknown message!");
    }
  };

  useEffect(() => {
    handleMessage(message);
  }, [message]);

  const handleSuccess = (result: string) => {
    const { blocks } = JSON.parse(result);
    generateWorkspaceBlock(blocks);
  };

  return (
    <div className="flex-col-el">
      {workspace && (
        <Camera
          open={local}
          remote={false}
          setOpen={setLocal}
          success={handleSuccess}
        />
      )}

      <div
        className="flex-col-el flex-center h-full gap-20 absolute"
        style={{ transform: `scale(${scale})` }}
      >
        <div className="flex-col-el flex-center upsilon px-5 pointer-events-auto">
          <button className="btn btn-blue" onClick={handleLocalCamera}>
            <div className="flex-col-el flex-center">
              <CameraIcon width={36} height={36} />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
