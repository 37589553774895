import {
  ComponentTypeIds,
  ComponentCategory,
  InitEventBlock,
  InitToggleBlock,
  InitSwitchBlock,
  InitCheckBoxBlock,
  InitTextInputBlock,
  InitScreenEventBlock,
  InitSizePropertyBlock,
  InitShadowPropertyBlock,
  InitBorderPropertyBlock,
  InitOpacityPropertyBlock,
  InitPositionPropertyBlock,
  InitActionTextPropertyBlock,
  InitBackgroundPropertyBlock,
  InitBorderRadiusPropertyBlock,
} from "common/components";
import { ScreensEntity } from "features/creator/types";

export const InitComponentBlockly = (
  allScreenNames: string[][],
  selectedScreen: ScreensEntity,
  selectedComponentId: string
) => {
  const selectedScreenName = [
    [selectedScreen.name, `${ComponentTypeIds.SCREEN}_${selectedScreen.id}`],
  ];
  InitScreenEventBlock(selectedScreenName, allScreenNames);

  const selectedComponent = selectedScreen.children
    .filter((c) => c.id === selectedComponentId)
    .shift();
  const selectedComponentLabelId = selectedComponent
    ? selectedComponent.categoryId === ComponentCategory.SHAPE
      ? `${selectedComponent.categoryId}_${selectedComponent.id}`
      : `${selectedComponent.typeId}_${selectedComponent.id}`
    : undefined;

  const buttonComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.BUTTON)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitEventBlock(
    "button",
    buttonComponent,
    selectedComponent?.typeId === ComponentTypeIds.BUTTON &&
      selectedComponentLabelId
  );

  const imageComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.IMAGE)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitEventBlock(
    "image",
    imageComponent,
    selectedComponent?.typeId === ComponentTypeIds.IMAGE &&
      selectedComponentLabelId
  );

  const stampComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.STAMP)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitEventBlock(
    "stamp",
    stampComponent,
    selectedComponent?.typeId === ComponentTypeIds.STAMP &&
      selectedComponentLabelId
  );

  const drawingComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.DRAWING)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitEventBlock(
    "drawing",
    drawingComponent,
    selectedComponent?.typeId === ComponentTypeIds.DRAWING &&
      selectedComponentLabelId
  );

  const labelComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.LABEL)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitEventBlock(
    "label",
    labelComponent,
    selectedComponent?.typeId === ComponentTypeIds.LABEL &&
      selectedComponentLabelId
  );

  const textinputComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.TEXTINPUT)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitTextInputBlock(
    textinputComponent,
    selectedComponent?.typeId === ComponentTypeIds.TEXTINPUT &&
      selectedComponentLabelId
  );

  const checkboxComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.CHECKBOX)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitCheckBoxBlock(
    checkboxComponent,
    selectedComponent?.typeId === ComponentTypeIds.CHECKBOX &&
      selectedComponentLabelId
  );

  const switchComponent = selectedScreen.children
    .filter((c) => c.typeId === ComponentTypeIds.SWITCH)
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitSwitchBlock(
    switchComponent,
    selectedComponent?.typeId === ComponentTypeIds.SWITCH &&
      selectedComponentLabelId
  );

  const components = selectedScreen.children.map((c) => [
    c.name,
    c.categoryId === ComponentCategory.SHAPE
      ? `${c.categoryId}_${c.id}`
      : `${c.typeId}_${c.id}`,
  ]);
  InitSizePropertyBlock(components, selectedComponentLabelId);
  InitOpacityPropertyBlock(components, selectedComponentLabelId);
  InitShadowPropertyBlock(components, selectedComponentLabelId);
  InitPositionPropertyBlock(components, selectedComponentLabelId);

  const textComponents = selectedScreen.children
    .filter(
      (c) =>
        c.typeId === ComponentTypeIds.BUTTON ||
        c.typeId === ComponentTypeIds.LABEL ||
        c.typeId === ComponentTypeIds.CHECKBOX ||
        c.typeId === ComponentTypeIds.TEXTINPUT
    )
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitActionTextPropertyBlock(
    textComponents,
    (selectedComponent?.typeId === ComponentTypeIds.BUTTON ||
      selectedComponent?.typeId === ComponentTypeIds.LABEL ||
      selectedComponent?.typeId === ComponentTypeIds.CHECKBOX ||
      selectedComponent?.typeId === ComponentTypeIds.TEXTINPUT) &&
      selectedComponentLabelId
  );

  const backgroundComponents = selectedScreen.children
    .filter(
      (c) =>
        c.typeId !== ComponentTypeIds.LABEL &&
        c.typeId !== ComponentTypeIds.CHECKBOX &&
        c.typeId !== ComponentTypeIds.TEXTINPUT &&
        c.typeId !== ComponentTypeIds.IMAGE &&
        c.typeId !== ComponentTypeIds.STAMP &&
        c.typeId !== ComponentTypeIds.DRAWING
    )
    .map((c) => [
      c.name,
      c.categoryId === ComponentCategory.SHAPE
        ? `${c.categoryId}_${c.id}`
        : `${c.typeId}_${c.id}`,
    ]);
  InitBackgroundPropertyBlock(
    [
      [selectedScreen.name, `${ComponentTypeIds.SCREEN}_${selectedScreen.id}`],
      ...backgroundComponents,
    ],
    selectedComponent?.typeId === ComponentTypeIds.SCREEN
      ? `${ComponentTypeIds.SCREEN}_${selectedComponentId}`
      : selectedComponent?.typeId !== ComponentTypeIds.LABEL &&
          selectedComponent?.typeId !== ComponentTypeIds.CHECKBOX &&
          selectedComponent?.typeId !== ComponentTypeIds.TEXTINPUT &&
          selectedComponent?.typeId !== ComponentTypeIds.IMAGE &&
          selectedComponent?.typeId !== ComponentTypeIds.STAMP &&
          selectedComponent?.typeId !== ComponentTypeIds.DRAWING &&
          selectedComponentLabelId
  );

  const borderComponents = selectedScreen.children
    .filter(
      (c) =>
        c.typeId !== ComponentTypeIds.LABEL &&
        c.typeId !== ComponentTypeIds.CHECKBOX
    )
    .map((c) => [
      c.name,
      c.categoryId === ComponentCategory.SHAPE
        ? `${c.categoryId}_${c.id}`
        : `${c.typeId}_${c.id}`,
    ]);
  InitBorderPropertyBlock(
    borderComponents,
    selectedComponent?.typeId !== ComponentTypeIds.LABEL &&
      selectedComponent?.typeId !== ComponentTypeIds.CHECKBOX &&
      selectedComponentLabelId
  );

  const borderRadiusComponents = selectedScreen.children
    .filter(
      (c) =>
        c.typeId !== ComponentTypeIds.LABEL &&
        c.typeId !== ComponentTypeIds.CHECKBOX
    )
    .filter((c) =>
      c.categoryId === ComponentCategory.SHAPE
        ? c.typeId === ComponentTypeIds.RECTANGLE
        : true
    )
    .map((c) => [
      c.name,
      c.categoryId === ComponentCategory.SHAPE
        ? `${c.categoryId}_${c.id}`
        : `${c.typeId}_${c.id}`,
    ]);
  InitBorderRadiusPropertyBlock(
    borderRadiusComponents,
    selectedComponent?.typeId !== ComponentTypeIds.LABEL &&
      selectedComponent?.typeId !== ComponentTypeIds.CHECKBOX &&
      (selectedComponent?.categoryId === ComponentCategory.SHAPE
        ? selectedComponent?.typeId === ComponentTypeIds.RECTANGLE
        : true) &&
      selectedComponentLabelId
  );

  const toggleComponents = selectedScreen.children
    .filter(
      (c) =>
        c.typeId === ComponentTypeIds.CHECKBOX ||
        c.typeId === ComponentTypeIds.SWITCH
    )
    .map((c) => [c.name, `${c.typeId}_${c.id}`]);
  InitToggleBlock(
    toggleComponents,
    (selectedComponent?.typeId === ComponentTypeIds.CHECKBOX ||
      selectedComponent?.typeId === ComponentTypeIds.SWITCH) &&
      selectedComponentLabelId
  );
};
