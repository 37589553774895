import { SVGProps } from "react";

export const FlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 19 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 25h6.177"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.094 2.33 18 7.66 4.094 13" fill="#FFA03B" />
    <path
      d="M4.094 2.33 18 7.66 4.094 13M4.083 25V1"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
