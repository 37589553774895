import { SVGProps } from "react";

export const AllStageClearSakana = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={207}
    height={125}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M86.408 16.498c1.069-1.272-53.369-2.602-79.781 37.1 0 0 14.569 38.635 51.987 43.711-2.86-.387-.943-36.79 18.041-25.94 2.384 1.381 5.36 7.588 8.255 5.899 1.977-1.134.035-4.88.045-6.454a13.787 13.787 0 0 1 3.129-9.173 28.26 28.26 0 0 1 7.933-4.157c.463-.17.814-.31.746-.752-9.139-2.586-26.072 6.226-30.52-3.415-3.819-8.307 15.176-30.762 20.165-36.82Z"
      fill="#FFB000"
    />
    <path
      d="M30.919 33.296c.492-1.072.991-2.303 1.548-3.682a104.873 104.873 0 0 0-4.59 2.883c-.472 1.103-.882 2.165-1.311 3.063A142.473 142.473 0 0 0 15.24 69c1.048 1.503 2.178 3.129 3.448 4.597 1.108-14.324 7.045-29.103 12.23-40.301ZM69.443 30.939c1.59-3.753 3.445-9.534 5.915-14.544l-3.196.317a151.654 151.654 0 0 0-7.18 17.003c-7.21 17.023-16.25 41.487-13.884 62.07l5.123 1.075c-4.176-21.256 5.57-47.816 13.222-65.921ZM48.283 34.536c1.537-3.63 3.308-9.134 5.622-14.044l-3.666 1.154c-2.738 5.275-4.763 11.607-6.468 15.656-5.44 13.175-12.21 30.77-13.864 47.57a53.1 53.1 0 0 0 4.105 3c.479-18.463 8.062-38.666 14.27-53.336Z"
      fill="#628F5B"
    />
    <path
      d="M22.35 47.87a2.168 2.168 0 0 0 .547 2.424 2.175 2.175 0 0 0 3.39-.596 2.166 2.166 0 0 0-.319-2.465 2.174 2.174 0 0 0-1.126-.656 1.991 1.991 0 0 0-2.492 1.293Z"
      fill="#1A0901"
    />
    <path
      d="M203.117 41.21c1.006-.59-34.57-14.442-61.471 5.419 0 0 .368 28.95 23.841 41.222-1.783-.935 8.162-24.489 18.088-12.79 1.265 1.494 1.734 6.267 4.119 5.86 1.578-.257 1.18-3.198 1.559-4.24a9.722 9.722 0 0 1 4.268-5.301 19.807 19.807 0 0 1 6.224-.857c.333.07.591-.01.65-.291-5.406-3.806-18.675-2.13-19.386-9.572-.505-6.382 17.37-16.626 22.108-19.45Z"
      fill="#FFB000"
    />
    <path
      d="M162.52 39.044a34.19 34.19 0 0 0 1.903-2.057c-1.229.25-2.458.499-3.723.794-.566.656-1.09 1.24-1.592 1.722a101.622 101.622 0 0 0-15.506 19.342c.358 1.223.726 2.53 1.196 3.857 4.176-9.193 11.818-17.445 17.722-23.657ZM188.427 46.593c1.94-2.104 4.542-5.457 7.36-8.178l-2.177-.457a110.011 110.011 0 0 0-8.809 9.476c-8.814 9.501-20.626 23.475-23.961 37.706a35.67 35.67 0 0 0 3.093 1.958c2.398-15.122 15.186-30.306 24.494-40.505ZM173.674 44.003c1.872-2.037 4.36-5.228 7.008-7.904l-2.647-.128c-3.055 2.831-5.918 6.53-7.997 8.792a120.643 120.643 0 0 0-20.519 28.064c.622.986 1.27 1.976 2.024 3.096 4.702-12.208 14.531-23.74 22.131-31.92Z"
      fill="#628F5B"
    />
    <path
      d="M153.293 46.563a1.543 1.543 0 0 0-.238 1.757 1.541 1.541 0 0 0 1.574.82 1.544 1.544 0 0 0 1.22-2.126 1.55 1.55 0 0 0-.603-.709 1.416 1.416 0 0 0-1.953.258Z"
      fill="#1A0901"
    />
  </svg>
);
