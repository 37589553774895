import { SVGProps } from "react";

export const ResetBtnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={48} height={48} rx={10} fill="#FC6560" />
    <path
      d="M39.9 22a16 16 0 1 0-1 8m1 10V30h-10"
      stroke="#FEFDFE"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
