import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";

export const InitColourBlock = () => {
  const colourPickerBlock = () => {
    return {
      type: "colour_picker_internal",
      message0: "%1",
      args0: [
        {
          type: "field_colour",
          name: "COLOUR",
          colour: "#71cdfc",
          colourOptions: ["#71cdfc", "#fb3a69", "#fdd73e"],
          colourTitles: ["blue", "red", "yellow"],
          columns: 1,
          style: "colour_blocks",
        },
      ],
      output: "Colour",
    };
  };
  Blockly.Blocks["colour_picker_internal"] = {
    init: function () {
      this.jsonInit(colourPickerBlock());
      this.contextMenu = false;
    },
  };

  BlocklyJs["colour_picker_internal"] = function (block: Blockly.Block) {
    const colour = block.getFieldValue("COLOUR");
    return [`"${colour}"`, (Blockly as any).JavaScript.ORDER_NONE];
  };
};
