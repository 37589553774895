import { createEntityAdapter } from "@reduxjs/toolkit";
import {
  CanvasEntity,
  BlocklyEntity,
  ScreensEntity,
  ProjectEntity,
  ComponentsEntity,
  PropertiesEntity,
} from "features/creator/types";

export const projectsAdapter = createEntityAdapter<ProjectEntity>({
  selectId: (project) => project.project_id,
});
export const canvasAdapter = createEntityAdapter<CanvasEntity>();
export const screensAdapter = createEntityAdapter<ScreensEntity>({
  sortComparer: (a, b) => {
    if (a.order > b.order) {
      return 1;
    } else {
      return -1;
    }
  },
});
export const componentsAdapter = createEntityAdapter<ComponentsEntity>({
  selectId: (components) => components.screenId,
});
export const propertiesAdapter = createEntityAdapter<PropertiesEntity>();
export const blocklyAdapter = createEntityAdapter<BlocklyEntity>({
  selectId: (blockly) => blockly.screenId,
});
