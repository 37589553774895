import { SVGProps } from "react";

export const HintIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.533 7.467 23.6 8.4M4 16h1.333H4ZM16 4v1.333V4Zm10.667 12H28h-1.333Zm-19.2-8.533L8.4 8.4l-.933-.933ZM12 21.333a6.667 6.667 0 1 1 8 0 4.667 4.667 0 0 0-1.333 4 2.667 2.667 0 0 1-5.334 0 4.667 4.667 0 0 0-1.333-4M12.933 22.667h6.134"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
