import { SVGProps } from "react";

export const IconHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={41}
    fill="none"
    {...props}
  >
    <path
      stroke={props.stroke ?? "#A54D21"}
      strokeLinejoin="round"
      strokeWidth={4}
      d="M4 20.914 20.239 5.43 36 20.914"
    />
    <path
      stroke={props.stroke ?? "#A54D21"}
      strokeLinejoin="round"
      strokeWidth={4}
      d="M7.765 17.043v17.419h24.47v-17.42"
    />
    <path
      fill={props.stroke ?? "#A54D21"}
      d="M16.235 24.785h7.529v7.742h-7.529z"
    />
  </svg>
);
