import dayjs from "dayjs";
import I18n from "i18n-js";
import { ProjectEntity } from "features/creator/types";
import { IconArrowRight } from "features/creator/assets";
import { ProjectUser, TextBlueStroke } from "features/creator/elements";

export const ProjectAuthor = ({ project }: { project: ProjectEntity }) => {
  return (
    <div className="flex-row-center">
      <div className="flex-col-center">
        <div>
          <TextBlueStroke text={I18n.t("MSG_CREATOR_APP_DETAIL_APP_AUTHOR")} />
        </div>
        <ProjectUser
          name={project?.original_author_name ?? project?.author_name}
          icon={project?.original_author_icon ?? project?.author_icon}
        />

        <div className="flex-row-el">
          <p className="text text-textcolor/black">
            {dayjs(
              project?.original_project_created_at ?? project?.created_time
            ).format("YYYY/MM/DD")}
          </p>
        </div>
      </div>

      <div className="flex-col-center w-[54px] h-[48px]">
        <IconArrowRight />
      </div>

      <div className="flex-col-center">
        <div>
          <TextBlueStroke text={I18n.t("MSG_CREATOR_APP_DETAIL_APP_EDITOR")} />
        </div>

        <ProjectUser name={project?.author_name} icon={project?.author_icon} />

        <div className="flex-row-el">
          <p className="text text-textcolor/black">
            {dayjs(project?.updated_time).format("YYYY/MM/DD")}
          </p>
        </div>
      </div>
    </div>
  );
};
