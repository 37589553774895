import { SVGProps } from "react";

export const IconUserChange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={12}
      cy={12}
      r={9}
      fill="#FEFDFE"
      stroke="#667786"
      strokeWidth={2}
    />
    <path
      d="m16.17 14.76-1.1-1.1c.71-1.33.53-3.01-.59-4.13A3.482 3.482 0 0 0 12 8.5c-.03 0-.06.01-.09.01L13 9.6l-1.06 1.06-2.83-2.83L11.94 5 13 6.06l-.96.96c1.27.01 2.53.48 3.5 1.44 1.7 1.71 1.91 4.36.63 6.3Zm-1.28 1.41L12.06 19 11 17.94l.95-.95a4.97 4.97 0 0 1-3.48-1.46 5.006 5.006 0 0 1-.64-6.29l1.1 1.1c-.71 1.33-.53 3.01.59 4.13.7.7 1.63 1.04 2.56 1.01L11 14.4l1.06-1.06 2.83 2.83Z"
      fill="#667786"
    />
  </svg>
);
