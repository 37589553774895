import { SVGProps } from "react";

export const IconItem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.013 41.888 8.99 16.51a.1.1 0 0 1 .099-.089h29.822a.1.1 0 0 1 .1.088l2.976 25.38a.1.1 0 0 1-.1.111H6.113a.1.1 0 0 1-.099-.112Z"
      fill="#C9947A"
      stroke="#667786"
      strokeWidth={3}
      strokeLinejoin="round"
    />
    <path
      d="M16 22.105V13.58C16 11.684 18 6 24 6s8 5.684 8 7.579v8.526"
      stroke="#667786"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
