import { SVGProps } from "react";

export const IconVerticalArrowMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24.007c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12Z"
      fill="#94C4F7"
    />
    <path
      d="M6 12.225h12"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.506 30.09v16M6.397 40.747l5.76 5.657 5.76-5.657"
      stroke="#94C4F7"
      strokeWidth={4}
    />
  </svg>
);
