import Constants from "common/constant";

export const Step5 = () => {
  return (
    <div className="flex-row-view w-full h-full">
      <div className="flex-row-view mt-[30px] w-full h-[400px]">
        <div className="flex-col-center w-[514px] h-full ml-[39px] mr-[48px]">
          <p className="text text-textcolor/black">
            トップページにある
            <br />
            「保護者の方用」ボタンで
            <br />
            アプリの設定や
            <br />
            アカウントの引き継ぎ、
            <br />
            「コースでまなぶ」の解答確認や
            <br />
            各種お知らせを
            <br />
            ご確認いただけます。
            <br />
            <br />
            （お子様による操作を防止するため、
            <br />
            保護者の方用メニューの表示には
            <br />
            画像操作による簡単なログイン認証が
            <br />
            あります）
          </p>
        </div>
        <div className="flex-row-center">
          <img
            alt="保護者機能のキャプチャー"
            src={`${Constants.assetHost}/assets/images/app_parent_gate_capture.png`}
            className="w-[400px] h-[386px]"
          />
        </div>
      </div>
    </div>
  );
};
