import I18n from "i18n-js";
import { Category, Block, Value, Shadow, Field, Label } from "common/blockly";

export const Appearance = () => {
  return (
    <Category
      name="shape"
      categorystyle="text_category"
      toolboxitemid="appearance"
    >
      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_OPACITY")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_opacity" />
      <Block type="component_property_setter_opacity">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">100</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_opacity_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">-10</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_ROTATION")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_rotation" />
      <Block type="component_property_setter_left_rotation">
        <Value name="value">
          <Shadow type="math_angle">
            <Field name="value">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_left_rotation_by_step">
        <Value name="value">
          <Shadow type="math_angle">
            <Field name="value">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Block type="component_property_setter_right_rotation">
        <Value name="value">
          <Shadow type="math_angle">
            <Field name="value">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_right_rotation_by_step">
        <Value name="value">
          <Shadow type="math_angle">
            <Field name="value">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER_RADIUS")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_border_radius" />
      <Block type="component_property_setter_border_radius">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_border_radius_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
      </Block>
    </Category>
  );
};
