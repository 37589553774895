import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { showAnswerSnippetByLoop } from "features/courses/program/game/blockly/uitls";

export const InitButtonClickBlock = () => {
  Blockly.Blocks["program_button_click_event_block"] = {
    init: function () {
      const components = [["ボタン１", "button_1"]];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_button_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          let target;
          let border;
          let answerMark;
          var loop_count = 0;
          const targetIdPrefix = "course-program-image-";
          const targetImages = ["Pineapple", "Pineapple", "Pineapple"];
          const result = [false, false, false];
          window.LoopTrap = 10;
          if(${result}){
            ${logic}
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitShowImageBlock = () => {
  Blockly.Blocks["show_image_block"] = {
    init: function () {
      const input = this.appendDummyInput();
      const options = [
        [
          {
            src: `${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
            width: 30,
            height: 30,
            alt: "Fragaria",
          },
          `Fragaria#${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
            width: 30,
            height: 30,
            alt: "Pineapple",
          },
          `Pineapple#${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          `Grape#${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
        ],
      ];
      input
        .appendField(new Blockly.FieldDropdown(options), "Image")
        .appendField("%{BKY_DISPLAY_EVENT}");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour("#FFAB19");
    },
  };
  BlocklyJs["show_image_block"] = function (block: Blockly.Block) {
    const selectedValue = block.getField("Image").getValue().split("#");
    const selectedImage = selectedValue[0];
    const selectedImageSrc = selectedValue[1];
    return `
        highlightBlock("${block.id}", true);
        target = document.getElementById(targetIdPrefix.concat('', loop_count + 1));
        if(target instanceof HTMLImageElement){
          target.src = "${selectedImageSrc}";
          sound();
          target.style.opacity = "1";
          target.style.transform = "scale(1)";
        };
        result[loop_count] = targetImages[loop_count] === "${selectedImage}";
        await sleep(700);
        ${showAnswerSnippetByLoop()}
        if(!result[loop_count]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        await sleep(700);
        target = document.getElementById("course-program-arrow-".concat('', loop_count + 1));
        if(target){
          target.style.opacity = "1";
        };
        loop_count += 1;
        highlightBlock("${block.id}", false);
    `;
  };
};
