export const GameTitle = (props: {
  scale?: number;
  level: number;
  title: string;
}) => {
  const HEIGHT = 80;
  const { level, title } = props;
  const scale = props.scale ? props.scale : 1;

  return (
    <div className="flex-row-view w-full" style={{ height: HEIGHT * scale }}>
      <div
        className="flex-row-center origin-top-left"
        style={{
          height: HEIGHT,
          transform: `scale(${scale})`,
        }}
      >
        <div
          className="flex-col-center z-[1] bg-danger mr-[10px] rounded-[50px]"
          style={{
            width: HEIGHT,
            height: HEIGHT,
          }}
        >
          <p className="title-text text-white !text-[13px] !tracking-[.2em]">
            Level
          </p>
          <p className="title-text text-white !text-[45px] !leading-[40px] ">
            {level}
          </p>
        </div>

        <div className="flex-row-view items-center justify-center h-[68px] bg-[#2766ae] pl-[55px] pr-[15px] ml-[-55px] rounded-r-[10px] ">
          <p className="title-text text-white !text-[36px] !tracking-[.2em]">
            {title}
          </p>
        </div>
      </div>
    </div>
  );
};
