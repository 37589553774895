import { SVGProps } from "react";

export const ProfileBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={314}
    height={90}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 3h306.749l-26.793 75.355A12.999 12.999 0 0 1 270.708 87H3V3Z"
      fill="#F8F1E8"
      stroke="#FEFDFE"
      strokeWidth={6}
    />
  </svg>
);
