import { SVGProps } from "react";

export const RenderOrderBackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={59}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={6}
      y={24}
      width={34}
      height={34}
      rx={3}
      fill="#80EAC1"
      stroke="#C2C9CF"
      strokeWidth={2}
    />
    <rect
      x={14}
      y={16}
      width={34}
      height={34}
      rx={3}
      fill="#FEFDFE"
      stroke="#C2C9CF"
      strokeWidth={2}
    />
    <rect
      x={22}
      y={8}
      width={34}
      height={34}
      rx={3}
      fill="#FEFDFE"
      stroke="#C2C9CF"
      strokeWidth={2}
    />
    <path
      d="M8 0v16M2 10.657l5.657 5.657 5.657-5.657M22 0v16M16 10.657l5.657 5.657 5.657-5.657"
      stroke="#94C4F7"
      strokeWidth={4}
    />
  </svg>
);
