import { useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { useSelector } from "react-redux";
import produce from "immer";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { ComponentManager } from "common/components";

export const DragLayer = () => {
  const { scale } = useScale();
  const canvasScale = useSelector(
    (state: RootState) => state.creator.canvasScale
  );
  const { item, clientOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const DragLayerComponent = useMemo(
    () =>
      item?.type
        ? ComponentManager[item.type].component.DragLayerComponent
        : null,
    [item]
  );
  const property = useMemo(
    () =>
      item?.type
        ? produce(ComponentManager[item.type].component.property, (draft) => {
            draft.style.layout.top = -0;
            draft.style.layout.left = -0;
          })
        : null,
    [item]
  );

  return !isDragging || !clientOffset || !DragLayerComponent || !property ? (
    <></>
  ) : (
    <div className="fixed pointer-events-none z-[100] w-full h-full inset-0">
      <div
        style={{
          willChange: "transform",
          transform: `translate(${clientOffset.x}px, ${clientOffset.y}px)`,
          WebkitTransform: `translate(${clientOffset.x}px, ${clientOffset.y}px)`,
        }}
      >
        <DragLayerComponent scale={scale * canvasScale} property={property} />
      </div>
    </div>
  );
};
