export const TextBlueStroke = ({ text }: { text: string }) => (
  <p
    className="flex-col-el flex-center text-body text-font-caption2-narrow whitespace-pre text-center text-blue/100"
    style={{
      WebkitTextStroke: "6px #FEFDFE",
    }}
  >
    <span
      className="inline-block leading-0"
      style={{
        WebkitTextStroke: "0",
        position: "absolute",
      }}
    >
      {text}
    </span>
    {text}
  </p>
);
