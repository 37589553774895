import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/electronic/89ac81d0297168e63110e5778eff4617.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          "button_1",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async () => {
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          let value;
          if(${result}){
            ${logic}
            setClicked(false);
            setClickedIndex(clickedIndex + 1);
            if(clickedIndex > 0){
              setSuccess(!result.includes(false));
              setGameOver(true);
            } else {
              setRunning(false);
            };
          } else {
            setSuccess(false);
            setGameOver(true);
          };
        }
      `;
    return code;
  };
};

export const InitMathPlusBlock = () => {
  Blockly.Blocks["program_math_plus_block"] = {
    init: function () {
      this.jsonInit({
        type: "program_math_plus_block",
        message0: "%1+%2",
        args0: [
          {
            type: "input_value",
            name: "A",
            check: "Number",
          },
          {
            type: "input_value",
            name: "B",
            check: "Number",
          },
        ],
        inputsInline: true,
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["program_math_plus_block"] = function (block: Blockly.Block) {
    const A = BlocklyJs.valueToCode(block, "A", BlocklyJsOperator.ORDER_NONE);
    const B = BlocklyJs.valueToCode(block, "B", BlocklyJsOperator.ORDER_NONE);
    return [`(Number(${A}) + Number(${B}))`, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitInlineMathNumberBlock = () => {
  Blockly.Blocks["program_inline_math_number"] = {
    init: function () {
      this.jsonInit({
        type: "program_inline_math_number",
        message0: "%1",
        args0: [
          {
            type: "field_number",
            name: "NUM",
            value: 0,
            check: "Number",
          },
        ],
        inputsInline: true,
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["program_inline_math_number"] = function (block: Blockly.Block) {
    const code = Number(block.getFieldValue("NUM"));
    const order =
      code >= 0
        ? BlocklyJsOperator.ORDER_ATOMIC
        : BlocklyJsOperator.ORDER_UNARY_NEGATION;
    return [code, order];
  };
};

export const InitHandGetterRotateBlock = () => {
  Blockly.Blocks["hand_getter_rotate_block"] = {
    init: function () {
      this.jsonInit({
        type: "hand_getter_rotate_block",
        message0: "%{BKY_GETTER_VALUE}",
        args0: [
          {
            type: "field_dropdown",
            name: "time",
            options: [
              ["%{BKY_COURSE_PROGRAM_CLOCK_SHOT_HAND}", "hour"],
              ["%{BKY_COURSE_PROGRAM_CLOCK_LONG_HAND}", "minute"],
            ],
          },
        ],
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["hand_getter_rotate_block"] = function (block: Blockly.Block) {
    const target = block.getField("time").getValue();
    const code = `document.getElementById("course-program-clock-${target}-hand").dataset.value`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitHandGetterRotateInlineBlock = () => {
  Blockly.Blocks["hand_getter_rotate_inline_block"] = {
    init: function () {
      this.jsonInit({
        type: "hand_getter_rotate_inline_block",
        message0: "%{BKY_GETTER_VALUE}",
        args0: [
          {
            type: "field_dropdown",
            name: "time",
            options: [
              ["%{BKY_COURSE_PROGRAM_CLOCK_SHOT_HAND}", "hour"],
              ["%{BKY_COURSE_PROGRAM_CLOCK_LONG_HAND}", "minute"],
            ],
          },
        ],
        output: "Number",
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["hand_getter_rotate_inline_block"] = function (
    block: Blockly.Block
  ) {
    const target = block.getField("time").getValue();
    const code = `document.getElementById("course-program-clock-${target}-hand").dataset.value`;
    return [code, BlocklyJsOperator.ORDER_ATOMIC];
  };
};

export const InitHandSetterRotateBlock = () => {
  Blockly.Blocks["hand_setter_rotate_block"] = {
    init: function () {
      this.jsonInit({
        type: "hand_setter_rotate_block",
        message0: "%{BKY_COURSE_PROGRAM_CLOCK}",
        args0: [
          {
            type: "input_value",
            name: "hour",
            check: "Number",
          },
          {
            type: "input_value",
            name: "minute",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "math_blocks",
      });
    },
  };
  BlocklyJs["hand_setter_rotate_block"] = function (block: Blockly.Block) {
    const hour = BlocklyJs.valueToCode(
      block,
      "hour",
      BlocklyJsOperator.ORDER_NONE
    );
    const minute = BlocklyJs.valueToCode(
      block,
      "minute",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        const hour = document.getElementById("course-program-clock-hour-hand");
        const minute = document.getElementById("course-program-clock-minute-hand");
        const hour_value = Number(${hour});
        const minute_value = Number(${minute});
        if(hour){
          result[clickedIndex * 4] = targetEles[clickedIndex * 4] === hour_value;
          hour.dataset.value = hour_value;
          await anime({
            targets: hour,
            rotate: 30 * hour_value + 30 * (minute_value / 60),
            duration: 1000,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        };
        if(minute){
          result[clickedIndex * 4 + 1] = targetEles[clickedIndex * 4 + 1] === minute_value;
          minute.dataset.value = minute_value;
          await anime({
            targets: minute,
            rotate: 360 * (minute_value / 60),
            duration: 1000,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        };
        if(!result[clickedIndex * 4] || !result[clickedIndex * 4 + 1]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        highlightBlock("${block.id}", false);
    `;
  };
};

export const InitLabelTextShowSetterBlock = () => {
  Blockly.Blocks["label_text_show_setter_block"] = {
    init: function () {
      this.jsonInit({
        type: "label_text_show_setter_block",
        message0: "%1に%2%{BKY_DISPLAY_EVENT}",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [
              ["ボックス1", "1"],
              ["ボックス2", "2"],
            ],
          },
          {
            type: "input_value",
            name: "value",
            check: "Number",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "logic_blocks",
      });
    },
  };
  BlocklyJs["label_text_show_setter_block"] = function (block: Blockly.Block) {
    const selectedLabelValue = block.getField("label").getValue();
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById("course-program-label-".concat('', ${selectedLabelValue}));
      value = Number(${value});
      if(target){
        target.innerText = value;
        target.style.opacity = "1";
        target.style.transform = "scale(1)";
      };
      result[clickedIndex * 4 + 1 + Number(${selectedLabelValue})] = targetEles[clickedIndex * 4 + 1 + Number(${selectedLabelValue})] === value;
      await sleep(700);
      if(result[clickedIndex * 4 + 1 + Number(${selectedLabelValue})]){
        answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', ${selectedLabelValue}));
        if(answerMark){
          answerMark.style.opacity = "0";
        };
        answerMark = document.getElementById("course-program-answer-mark-right-".concat('', ${selectedLabelValue}));
        if(answerMark){
          answerMark.style.opacity = "1";
        };
      } else {
        answerMark = document.getElementById("course-program-answer-mark-right-".concat('', ${selectedLabelValue}));
        if(answerMark){
          answerMark.style.opacity = "0";
        };
        answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', ${selectedLabelValue}));
        if(answerMark){
          answerMark.style.opacity = "1";
        };
        setSuccess(false);
        setGameOver(true);
        return;
      };
      setResult(result);
      await sleep(700);
      if(clickedIndex < 1){
        if(target instanceof HTMLParagraphElement){
          target.innerText = "";
          target.style.opacity = "0";
          target.style.transform = "scale(1.5)";
        };
        target = document.getElementById("course-program-answer-label-".concat('', Number(${selectedLabelValue}) * 2 - 1));
        if(target instanceof HTMLParagraphElement){
          target.style.opacity = "0";
        };
        target = document.getElementById("course-program-answer-label-".concat('', Number(${selectedLabelValue}) * 2));
        if(target instanceof HTMLParagraphElement){
          target.style.opacity = "0.3";
        };
        answerMark = document.getElementById("course-program-answer-mark-right-".concat('', ${selectedLabelValue}));
        answerMark.style.opacity = "0";
        answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', ${selectedLabelValue}));
        answerMark.style.opacity = "0";
      };
      await sleep(700);
      highlightBlock("${block.id}", false);
    `;
  };
};
