import { SVGProps } from "react";

export const IconCleanDrawing = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4.1 6.4 1.1 13.2a2.2 2.2 0 0 0 2.2 2.2h8.8a2.2 2.2 0 0 0 2.2-2.2l1.1-13.2"
      fill="#DBD8DA"
    />
    <path
      d="m4.1 6.4 1.1 13.2a2.2 2.2 0 0 0 2.2 2.2h8.8a2.2 2.2 0 0 0 2.2-2.2l1.1-13.2M3 6.4h17.6M9.6 10.8v6.6M14 10.8v6.6M8.5 6.4V3.1A1.1 1.1 0 0 1 9.6 2H14a1.1 1.1 0 0 1 1.1 1.1v3.3"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
