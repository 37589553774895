import { useState, useEffect } from "react";
import { useScale } from "common/utils";
import { Overlay } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;
  const canvasScale = 0.96;
  const { scale } = useScale();
  const [order, setOrder] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      handleOrder(order);
    }, 300);

    const handleOrder = (currentOrder: number) => {
      if (currentOrder === 5) {
        next();
      } else {
        setTimeout(() => {
          const newOrder = currentOrder + 1;
          setOrder(newOrder);
          handleOrder(newOrder);
        }, 2000);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  return (
    <Overlay>
      <div className="flex-col-el w-full h-full">
        <div
          className={`w-[600px] h-[600px] absolute origin-top-left ${
            order === 0 ? "block" : "hidden"
          }`}
          style={{
            borderRadius: "100%",
            transform: `scale(${canvasScale * scale}) translate(450px, -250px)`,
            boxShadow: "0px 0px 9999px 9999px rgba(0, 0, 0, 0.35)",
          }}
        />
        <div
          className={`w-[600px] h-[600px] absolute origin-top-left ${
            order === 1 ? "block" : "hidden"
          }`}
          style={{
            borderRadius: "100%",
            transform: `scale(${canvasScale * scale}) translate(950px, 200px)`,
            boxShadow: "0px 0px 9999px 9999px rgba(0, 0, 0, 0.35)",
          }}
        />
        <div
          className={`w-[600px] h-[600px] absolute origin-top-left ${
            order === 2 ? "block" : "hidden"
          }`}
          style={{
            borderRadius: "100%",
            transform: `scale(${canvasScale * scale}) translate(450px, 700px)`,
            boxShadow: "0px 0px 9999px 9999px rgba(2, 1, 1, 0.35)",
          }}
        />
        <div
          className={`w-[600px] h-[600px] absolute origin-top-left ${
            order === 3 ? "block" : "hidden"
          }`}
          style={{
            borderRadius: "100%",
            transform: `scale(${canvasScale * scale}) translate(-60px, 560px)`,
            boxShadow: "0px 0px 9999px 9999px rgba(0, 0, 0, 0.35)",
          }}
        />
        <div
          className={`w-[600px] h-[600px] absolute origin-top-left ${
            order === 4 ? "block" : "hidden"
          }`}
          style={{
            borderRadius: "100%",
            transform: `scale(${canvasScale * scale}) translate(-200px, 120px)`,
            boxShadow: "0px 0px 9999px 9999px rgba(0, 0, 0, 0.35)",
          }}
        />
      </div>
    </Overlay>
  );
};

export default Step;
