import * as React from "react";

export const MaterialTextSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={25}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.906 24.767a5.417 5.417 0 0 1-5.8-5.8 8.282 8.282 0 0 1 1.856-5.313 11.333 11.333 0 0 1 5.208-3.562.325.325 0 0 0 .209-.325v-2.97a.244.244 0 0 0-.267-.278H3.006a1.554 1.554 0 0 1-1.16-.452 1.578 1.578 0 0 1 0-2.239 1.52 1.52 0 0 1 1.16-.464h4.118a.244.244 0 0 0 .267-.267V1.67c.003-.435.178-.852.487-1.16A1.612 1.612 0 0 1 9.038 0h.348a1.74 1.74 0 0 1 1.693 1.705v1.427c0 .174.082.267.232.267H22.1a1.52 1.52 0 0 1 1.16.464 1.578 1.578 0 0 1-1.16 2.691H11.31c-.15 0-.232.093-.232.279v2.169c0 .174.082.243.232.209.982-.114 1.97-.164 2.958-.151 3.41 0 6.052.746 7.923 2.239a7.379 7.379 0 0 1 2.796 6.101 7.923 7.923 0 0 1-1.856 5.244 10.706 10.706 0 0 1-5.174 3.283 1.485 1.485 0 0 1-1.264-.163 1.868 1.868 0 0 1-.824-1.055l-.058-.128a1.45 1.45 0 0 1 .14-1.23c.213-.4.57-.706.997-.858a7.586 7.586 0 0 0 3.132-2.111 4.512 4.512 0 0 0 1.16-2.982 4.222 4.222 0 0 0-1.96-3.84c-.128-.057-.22 0-.302.117a19.859 19.859 0 0 1-5.65 8.224 11.392 11.392 0 0 1-7.423 2.866Zm1.276-10.835C5 15.138 3.91 16.685 3.91 18.572c-.048.736.17 1.465.615 2.053a2.226 2.226 0 0 0 1.763.72c.28.007.56-.017.836-.07a.301.301 0 0 0 .267-.325v-6.96a.128.128 0 0 0-.07-.128.127.127 0 0 0-.14.07Zm7.064-1.59c-.98 0-1.957.08-2.923.244a.29.29 0 0 0-.267.302v6.009s0 .08.093.104c.093.023.093 0 .116 0a18.201 18.201 0 0 0 4.234-6.31.302.302 0 0 0 0-.198.128.128 0 0 0-.128-.104c-.266-.035-.626-.046-1.125-.046Z"
        fill="#E6CCA1"
      />
    </svg>
  );
};
