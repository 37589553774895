import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { BrowserRouter } from "react-router-dom";
import { TouchBackend } from "react-dnd-touch-backend";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store } from "app/store";
import { AppIndexPage } from "features/top";
import { NetworkErrorPage } from "features/error";
import { AuthProvider } from "./AuthProvider";

const queryClient = new QueryClient();

declare global {
  namespace JSX {
    interface IntrinsicElements {
      xml: unknown;
      sep: unknown;
      category: unknown;
      block: unknown;
      value: unknown;
      field: unknown;
      shadow: unknown;
      variables: unknown;
      variable: unknown;
      mutation: unknown;
    }
  }
}

const theme = createTheme({
  palette: {
    text: {
      primary: "#3F4A61",
    },
  },
  typography: {
    fontFamily: [
      '"MPLUSRounded1c-Bold"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <DndProvider
          backend={TouchBackend}
          options={{ enableTouchEvents: true, enableMouseEvents: true }}
        >
          <NetworkErrorPage />
          <BrowserRouter>
            <AuthProvider>
              <AppIndexPage />
            </AuthProvider>
          </BrowserRouter>
        </DndProvider>
      </QueryClientProvider>
    </Provider>
  </ThemeProvider>
);

export default App;
