import { SVGProps } from "react";

export const IconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#FEFDFE"
      d="M5.333 8.889 8.89 5.334l17.778 17.778-3.556 3.555z"
    />
    <path
      fill="#FEFDFE"
      d="M8.888 26.667 5.333 23.11 23.111 5.333l3.556 3.556z"
    />
  </svg>
);
