/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { animated, useSpring } from "@react-spring/web";
import { usePlaySound } from "common/utils";

export const AnimationContainer = forwardRef(
  (
    props: {
      value: number;
      min: number;
      max: number;
      onChange: (value: number) => void;
      children:
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null;
    },
    ref
  ) => {
    const play = usePlaySound();
    const { min, max, value, onChange, children } = props;
    const [plusLongPress, setPlusLongPress] = useState(false);
    const [updateInterval, setUpdateInterval] = useState(null);
    const [minusLongPress, setMinusLongPress] = useState(false);
    const [animationStyles, animationApi] = useSpring(() => ({
      from: { transform: "scale(1)" },
    }));
    const [floatPlus, floatPlusApi] = useSpring(() => ({
      from: { transform: "translate(0px,0px)", opacity: 0 },
    }));
    const [floatMinus, floatMinusApi] = useSpring(() => ({
      from: { transform: "translate(0px,0px)", opacity: 0 },
    }));

    useEffect(() => {
      if (plusLongPress) {
        handlePlus(1);
        setPlusLongPress(false);
      }
      if (minusLongPress) {
        handleMinus(1);
        setMinusLongPress(false);
      }
    }, [plusLongPress, minusLongPress]);

    const handlePlusLongPress = () => {
      const interval = setInterval(() => {
        setPlusLongPress(true);
      }, 100);
      setUpdateInterval(interval);
    };

    const handleMinusLongPress = () => {
      const interval = setInterval(() => {
        setMinusLongPress(true);
      }, 100);
      setUpdateInterval(interval);
    };

    const handleLongPressOut = () => {
      clearInterval(updateInterval);
      setUpdateInterval(null);
    };
    const handlePlus = (step: number) => {
      play();
      if (value + step >= max) {
        onChange(max);
      } else {
        onChange(Math.floor((value + step) * 100) / 100);
      }
      animationApi.start({
        to: [{ transform: "scale(1.2)" }, { transform: "scale(1)" }],
        config: { duration: 100 },
      });
      floatPlusApi.start({
        to: [
          { transform: "translate(0px,-10px)", opacity: 0.5 },
          { transform: "translate(0px,-20px)", opacity: 1 },
          { transform: "translate(0px,-30px)", opacity: 0.5 },
          { transform: "translate(0px,-40px)", opacity: 0 },
        ],
        reset: true,
        config: { duration: 100 },
      });
    };
    const handleMinus = (step: number) => {
      play();
      if (value - step <= min) {
        onChange(min);
      } else {
        onChange(Math.floor((value - step) * 100) / 100);
      }
      animationApi.start({
        to: [{ transform: "scale(1.2)" }, { transform: "scale(1)" }],
        config: { duration: 100 },
      });
      floatMinusApi.start({
        to: [
          { transform: "translate(0px,-10px)", opacity: 0.5 },
          { transform: "translate(0px,-20px)", opacity: 1 },
          { transform: "translate(0px,-30px)", opacity: 0.5 },
          { transform: "translate(0px,-40px)", opacity: 0 },
        ],
        reset: true,
        config: { duration: 100 },
      });
    };

    useImperativeHandle(ref, () => ({
      handlePlusLongPress,
      handleMinusLongPress,
      handleLongPressOut,
      handlePlus,
      handleMinus,
    }));

    return (
      <div className="flex-col-center">
        <animated.div
          style={{
            pointerEvents: "none",
            zIndex: 100,
            position: "absolute",
            top: 0,
            right: -10,
            ...floatPlus,
          }}
        >
          <div className="w-[20px] h-[20px] flex-row-center bg-light/red rounded-[10px]">
            <p className="text text-white !text-[10px]">1</p>
          </div>
        </animated.div>
        <animated.div
          style={{
            pointerEvents: "none",
            zIndex: 100,
            position: "absolute",
            top: 0,
            right: -10,
            ...floatMinus,
          }}
        >
          <div className="w-[20px] h-[20px] flex-row-center bg-light/red rounded-[10px]">
            <p className="text text-white !text-[10px]">-1</p>
          </div>
        </animated.div>
        <animated.div
          className="w-full"
          style={{
            ...animationStyles,
          }}
        >
          {children}
        </animated.div>
      </div>
    );
  }
);
