import { useState, ChangeEventHandler } from "react";
import i18n from "i18n-js";
import { Dialog } from "@mui/material";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { FadeTransition } from "common/elements";
import { Course } from "features/courses/types";
import { string2Course } from "features/courses/utils";
import { RouteProps } from "./type";
import { GoBackBtn } from "./GoBackBtn";

export const CourseAnswer = (props: RouteProps) => {
  const play = usePlaySound();
  const [step, setStep] = useState(1);
  const [stage, setStage] = useState(1);
  const [modal, setModal] = useState(false);
  const handleGoBack = () => props.navigate("/");
  const [course, setCourse] = useState(Course.ALGORITHM);
  const handleChangeCourse: ChangeEventHandler<HTMLSelectElement> = (e) => {
    play();
    setCourse(string2Course(e.target.value));
    setStage(1);
    setStep(1);
  };
  const handleChangeStage: ChangeEventHandler<HTMLSelectElement> = (e) => {
    play();
    setStage(Number(e.target.value));
  };
  const handleChangeStep: ChangeEventHandler<HTMLSelectElement> = (e) => {
    play();
    setStep(Number(e.target.value));
  };
  const handleModal = (show: boolean) => () => {
    play();
    setModal(show);
  };

  return (
    <>
      <Dialog
        fullScreen
        maxWidth={false}
        open={modal}
        sx={{ zIndex: 200000 }}
        TransitionComponent={FadeTransition}
        PaperProps={{ style: { backgroundColor: "transparent" } }}
        transitionDuration={{ enter: 1000, exit: 500 }}
      >
        <div
          className="flex-col-center w-full h-full bg-textcolor/black/60"
          onClick={handleModal(false)}
        >
          <img
            alt="コースの正解のブロックの組み方の拡大表示"
            src={`${Constants.assetHost}/assets/${course}/answer/stage${stage}/step${step}.png`}
            className="border-solid border-[4px] border-brown/80 rounded-[12px] p-[20px] bg-white "
          />
        </div>
      </Dialog>
      <div className="flex-col-view items-center !flex-1 w-full mt-4 gap-4">
        <div className="flex-row-center">
          <label className="text text-brown/80">
            {i18n.t("MSG_SETTING_COURSE_ANSWER_COURSE_TITLE")}
          </label>
          <select
            value={course}
            onChange={handleChangeCourse}
            className="cursor-pointer flex-col-center !ml-[8px] bg-white rounded-[2px] border-solid border-gray/80 border-[1px] text text-brown/80 !text-left"
          >
            <option value={Course.ALGORITHM}>アルゴリズムをまなぼう</option>
            <option value={Course.PROGRAM}>はじめてのプログラミング</option>
          </select>
        </div>

        <div className="flex-row-el">
          <div className="flex-col-el gap-4 items-start">
            <div className="flex-row-center mr-[24px]">
              <label className="text-body text-right text-[20px] text-brown/80 w-[150px]">
                {i18n.t("MSG_SETTING_COURSE_ANSWER_STAGE_TITLE")}
              </label>
              <select
                value={stage}
                onChange={handleChangeStage}
                className="cursor-pointer flex-col-center !ml-[8px] bg-white rounded-[2px] border-solid border-gray/80 border-[1px] text text-brown/80 !text-left"
              >
                {Array(6)
                  .fill(0)
                  .map((value, index) => (
                    <option key={index} value={index + 1}>
                      {i18n.t("MSG_SETTING_COURSE_ANSWER_STAGE")} {index + 1}
                    </option>
                  ))}
              </select>
            </div>

            <div className="flex-row-center">
              <label className="text-body text-right text-[20px] text-brown/80 w-[150px]">
                {i18n.t("MSG_SETTING_COURSE_ANSWER_LEVEL_TITLE")}
              </label>
              <select
                value={step}
                onChange={handleChangeStep}
                className="cursor-pointer flex-col-center !ml-[8px] bg-white rounded-[2px] border-solid border-gray/80 border-[1px] text text-brown/80 !text-left"
              >
                {Array(7)
                  .fill(0)
                  .map((value, index) => (
                    <option key={index} value={index + 1}>
                      {i18n.t("MSG_SETTING_COURSE_ANSWER_LEVEL")} {index + 1}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div
            className="flex-col-view cursor-pointer"
            onClick={handleModal(true)}
          >
            <img
              alt="コースの正解のブロックの組み方"
              src={`${Constants.assetHost}/assets/${course}/answer/stage${stage}/step${step}.png`}
              className="scale-50 origin-top border-solid rounded-[12px] border-[4px] border-brown/80 p-[20px] bg-white"
            />
          </div>
        </div>
      </div>

      <div className="flex-col-el flex-center w-full">
        <div className="flex-col-el absolute bottom-4">
          <GoBackBtn handleGoBack={handleGoBack} />
        </div>
      </div>
    </>
  );
};
