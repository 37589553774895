import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Scene } from "@babylonjs/core/scene";
import { actions } from "features/builder/slice";
import { BuilderScene } from "./scene";
import { ActionMenu } from "./ActionMenu";
import { Controller } from "./Controller";

export const Builder = () => {
  const dispatch = useDispatch();
  const [scene, setScene] = useState<Scene | null>(null);

  useEffect(() => {
    setTimeout(() => dispatch(actions.updateLoading(false)), 300);
  }, []);

  return (
    <>
      <ActionMenu />
      <Controller scene={scene} />
      <BuilderScene scene={scene} setScene={setScene} />
    </>
  );
};
