import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";
import { creatStarPoint, ComponentTypeIds } from "common/components";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const getShapeWidth = (id: string) => {
  const svg = $(`#shape-svg-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = svg.width();
  return currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
};
export const getShapeHeight = (id: string) => {
  const svg = $(`#shape-svg-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerHeight = svg.height();
  return currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;
};
export const setShapeSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#shape-container-${id}`);
  const svg = $(`#shape-svg-${id}`);
  const shape = $(`#shape-shape-${id}`);
  const shapeType = container.data("typeId");

  const viewBox = svg.attr("viewBox").split(" ");

  const currentContainerWidth = svg.width();
  const currentContainerHeight = svg.height();

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );

  svg.attr("width", `${newContainerSize.x}`);
  svg.attr("height", `${newContainerSize.y}`);
  svg.attr(
    "viewBox",
    `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`
  );

  switch (shapeType) {
    case ComponentTypeIds.RECTANGLE:
      const currentBorderRadius = Number(shape.data("borderRadius"));
      const size = Math.min(newShapeSize.x, newShapeSize.y);
      shape.attr("rx", size * (currentBorderRadius / 200));
      shape.attr("width", `${newShapeSize.x}`);
      shape.attr("height", `${newShapeSize.y}`);
      break;
    case ComponentTypeIds.CIRCLE:
      shape.attr("cx", `${newShapeSize.x / 2}`);
      shape.attr("cy", `${newShapeSize.y / 2}`);
      shape.attr("rx", `${newShapeSize.x / 2}`);
      shape.attr("ry", `${newShapeSize.y / 2}`);
      break;
    case ComponentTypeIds.LINE:
      shape.attr("width", `${newShapeSize.x}`);
      shape.attr("height", `${newShapeSize.y}`);
      break;
    case ComponentTypeIds.STAR:
      const numPoints = Number(shape.data("numPoints"));
      const innerRadius = Number(shape.data("innerRadius"));
      const outerRadius = Number(shape.data("outerRadius"));
      const pointsStr = creatStarPoint(
        newShapeSize.x,
        newShapeSize.y,
        numPoints,
        innerRadius,
        outerRadius
      );
      shape.attr("points", `${pointsStr}`);
      break;
    case ComponentTypeIds.TRIANGLE:
      shape.attr(
        "points",
        `${newShapeSize.x / 2},${0} ${0},${newShapeSize.y} ${newShapeSize.x},${
          newShapeSize.y
        }`
      );
      break;
    default:
      break;
  }
};
export const setShapeSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const svg = $(`#shape-svg-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");

  const currentContainerWidth = svg.width();
  const currentContainerHeight = svg.height();

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const newShapeWidth =
    widthStep === undefined ? undefined : currentShapeWidth + widthStep;
  const newShapeHeight =
    heightStep === undefined ? undefined : currentShapeHeight + heightStep;

  setShapeSize(id, newShapeWidth, newShapeHeight);
};
export const setShapeSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#shape-container-${id}`);
  const svg = $(`#shape-svg-${id}`);
  const shape = $(`#shape-shape-${id}`);
  const shapeType = container.data("typeId");

  const viewBox = svg.attr("viewBox").split(" ");

  const currentContainerWidth = svg.width();
  const currentContainerHeight = svg.height();

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    width,
    height
  );
  const animation1 = anime({
    targets: `#shape-container-${id}`,
    translateX: `-=${offset.x}`,
    translateY: `+=${offset.y}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#shape-svg-${id}`,
    width: `+=${newShapeSize.x - currentShapeWidth}`,
    height: `+=${newShapeSize.y - currentShapeHeight}`,
    viewBox: `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  let animation3: anime.AnimeInstance;

  switch (shapeType) {
    case ComponentTypeIds.RECTANGLE:
      const currentBorderRadius = Number(shape.data("borderRadius"));
      const size = Math.min(newShapeSize.x, newShapeSize.y);
      animation3 = anime({
        targets: `#shape-shape-${id}`,
        rx: size * (currentBorderRadius / 200),
        width: newShapeSize.x,
        height: newShapeSize.y,
        duration: time * 1000,
        autoplay: false,
        easing: "easeInOutQuad",
      });
      animation1.play();
      animation2.play();
      animation3.play();
      previewRemovedObserver(() => {
        animation1.pause();
        animation2.pause();
        animation3.pause();
      });
      return Promise.all([
        animation1.finished,
        animation2.finished,
        animation3.finished,
      ]);
    case ComponentTypeIds.CIRCLE:
      animation3 = anime({
        targets: `#shape-shape-${id}`,
        cx: newShapeSize.x / 2,
        cy: newShapeSize.y / 2,
        rx: newShapeSize.x / 2,
        ry: newShapeSize.y / 2,
        duration: time * 1000,
        autoplay: false,
        easing: "easeInOutQuad",
      });
      animation1.play();
      animation2.play();
      animation3.play();
      previewRemovedObserver(() => {
        animation1.pause();
        animation2.pause();
        animation3.pause();
      });
      return Promise.all([
        animation1.finished,
        animation2.finished,
        animation3.finished,
      ]);
    case ComponentTypeIds.LINE:
      animation3 = anime({
        targets: `#shape-shape-${id}`,
        width: newShapeSize.x,
        height: newShapeSize.y,
        duration: time * 1000,
        autoplay: false,
        easing: "easeInOutQuad",
      });
      animation1.play();
      animation2.play();
      animation3.play();
      previewRemovedObserver(() => {
        animation1.pause();
        animation2.pause();
        animation3.pause();
      });
      return Promise.all([
        animation1.finished,
        animation2.finished,
        animation3.finished,
      ]);
    case ComponentTypeIds.STAR:
      const numPoints = Number(shape.data("numPoints"));
      const innerRadius = Number(shape.data("innerRadius"));
      const outerRadius = Number(shape.data("outerRadius"));
      const pointsStr = creatStarPoint(
        newShapeSize.x,
        newShapeSize.y,
        numPoints,
        innerRadius,
        outerRadius
      );
      animation3 = anime({
        targets: `#shape-shape-${id}`,
        points: pointsStr,
        duration: time * 1000,
        autoplay: false,
        easing: "easeInOutQuad",
      });
      animation1.play();
      animation2.play();
      animation3.play();
      previewRemovedObserver(() => {
        animation1.pause();
        animation2.pause();
        animation3.pause();
      });
      return Promise.all([
        animation1.finished,
        animation2.finished,
        animation3.finished,
      ]);
    case ComponentTypeIds.TRIANGLE:
      animation3 = anime({
        targets: `#shape-shape-${id}`,
        points: `${newShapeSize.x / 2},${0} ${0},${newShapeSize.y} ${
          newShapeSize.x
        },${newShapeSize.y}`,
        duration: time * 1000,
        autoplay: false,
        easing: "easeInOutQuad",
      });
      animation1.play();
      animation2.play();
      animation3.play();
      previewRemovedObserver(() => {
        animation1.pause();
        animation2.pause();
        animation3.pause();
      });
      return Promise.all([
        animation1.finished,
        animation2.finished,
        animation3.finished,
      ]);
    default:
      break;
  }
};
