import * as Blockly from "blockly";

export const updateFlyout = (workspace, offset: number, scale: number) => {
  const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
  const blockToolbox = workspace.getToolbox() as Blockly.Toolbox;
  const item = blockToolbox.getToolboxItemById(
    "category"
  ) as Blockly.ToolboxCategory;
  blockToolbox.setSelectedItem(item);
  if (!blocklyFlyout.isVisible()) {
    blockToolbox.setSelectedItem(item);
  }
  // @ts-ignore
  blocklyFlyout.positionAt_(
    blocklyFlyout.getWidth(),
    blocklyFlyout.getHeight() - offset - 75 * scale,
    0,
    offset
  );
};

export const showAnswerSnippetByOnly = (result: boolean) => {
  return `
    if(${result}){
      let answerMark;
      answerMark = document.getElementById("course-program-answer-mark-wrong-1");
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-right-1");
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    } else {
      let answerMark;
      answerMark = document.getElementById("course-program-answer-mark-right-1");
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-wrong-1");
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    };
  `;
};

export const showAnswerSnippetByOrder = (result: boolean) => {
  return `
    if(${result}){
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', order));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    } else {
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', order));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    };
  `;
};

export const showAnswerSnippet = () => {
  return `
    if(result[order - 1]){
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', order));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    } else {
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', order));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    };
  `;
};

export const showAnswerSnippetByComponent = (comonentValue: number) => {
  return `
    if(result[Number(${comonentValue}) - 1]){
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', Number(${comonentValue})));
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', Number(${comonentValue})));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    } else {
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', Number(${comonentValue})));
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', Number(${comonentValue})));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    };
  `;
};

export const showAnswerSnippetByLoop = () => {
  return `
    if(result[loop_count]){
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', loop_count + 1));
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', loop_count + 1));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    } else {
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', loop_count + 1));
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', loop_count + 1));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    };
  `;
};

export const showAnswerSnippetByClickIndex = () => {
  return `
    if(result[clickedIndex]){
      answerMark = document.getElementById("course-program-answer-mark-wrong-1");
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-right-1");
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    } else {
      answerMark = document.getElementById("course-program-answer-mark-right-1");
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-wrong-1");
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    };
  `;
};

export const showAnswerSnippetByClickIndexOrder = () => {
  return `
    if(result[clickedIndex]){
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', clickedIndex + 1));
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', clickedIndex + 1));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    } else {
      answerMark = document.getElementById("course-program-answer-mark-right-".concat('', clickedIndex + 1));
      if(answerMark){
        answerMark.style.opacity = "0";
      };
      answerMark = document.getElementById("course-program-answer-mark-wrong-".concat('', clickedIndex + 1));
      if(answerMark){
        answerMark.style.opacity = "1";
      };
    };
  `;
};
