import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  tagProject,
  copyProject,
  createProject,
  updateProject,
  removeProject,
  deleteProject,
  getAppProjects,
  publishProject,
  restoreProject,
  removeTagProject,
  stopPublishProject,
  getUserProjectContent,
} from "features/creator/api";
import {
  DisplayMode,
  SortedOrder,
  KeyDirection,
  ProjectEntity,
  GroupingDefault,
  ProjectPanelType,
  CreatorInitialState,
  PanelCollapseDefault,
  FreeDrawingToolDefault,
  UserSortedOrder,
} from "features/creator/types";
import {
  SCREEN_SIZE,
  DEFAULT_SCREEN_ORIENTATION,
} from "features/creator/constants";
import {
  reducers,
  canvasAdapter,
  blocklyAdapter,
  screensAdapter,
  projectsAdapter,
  componentsAdapter,
  propertiesAdapter,
} from "features/creator/slice/reducers";

export const {
  selectAll: selectAllProjects,
  selectById: selectProjectById,
} = projectsAdapter.getSelectors((state: RootState) => state.creator.projects);

export const { selectById: selectCanvasById } = canvasAdapter.getSelectors(
  (state: RootState) => state.creator.canvas
);

export const {
  selectAll: selectAllScreens,
  selectById: selectScreenById,
  selectIds: selectScreenIds,
} = screensAdapter.getSelectors((state: RootState) => state.creator.screens);

export const {
  selectById: selectPropertyById,
} = propertiesAdapter.getSelectors(
  (state: RootState) => state.creator.properties
);

export const { selectById: selectBlocklyById } = blocklyAdapter.getSelectors(
  (state: RootState) => state.creator.blockly
);

export const initialState: CreatorInitialState = {
  tutorialMenu: { creator_top_menu: false, creator_panel_menu: false },
  panelTabIndex: 0,
  projectPanel: ProjectPanelType.SHARED,
  keyword: "",
  sortedOrderPanel: false,
  projectSortedOrder: SortedOrder.DATE,
  publishedProjectFilter: { onlyOfficial: false, userFavorited: false },
  userProjectSortedOrder: UserSortedOrder.DATE,
  userProjectFilter: { userFavorited: false },
  cursor: KeyDirection.NONE,
  action: { back: false, confirm: false },
  displayMode: DisplayMode.NONE,
  selectedProjectId: null,
  recommendReturnToHome: false,
  recommendProject: null,
  isCopyProject: false,
  projects: projectsAdapter.getInitialState(),
  focus: true,
  capture: false,
  clipboard: null,
  editingProjectId: null,
  selectedScreenId: null,
  selectedComponentId: null,
  defaultScreenId: null,
  canvasScale: 0.96,
  screenSize: {
    width: SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].WIDTH,
    height: SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].HEIGHT,
    orientation: DEFAULT_SCREEN_ORIENTATION,
  },
  grouping: GroupingDefault,
  undoStacks: [],
  redoStacks: [],
  template: { newComponentIds: [], newBlockIds: [] },
  drawing: FreeDrawingToolDefault,
  panelCollapse: PanelCollapseDefault,
  canvas: canvasAdapter.getInitialState(),
  screens: screensAdapter.getInitialState(),
  components: componentsAdapter.getInitialState(),
  properties: propertiesAdapter.getInitialState(),
  blockly: blocklyAdapter.getInitialState(),
};

export const creatorSlice = createSlice({
  name: "creator",
  initialState,
  reducers: reducers,
  extraReducers: (builder) => {
    builder
      .addCase(
        createProject.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<{ project: ProjectEntity }>
        ) => {
          projectsAdapter.addOne(state.projects, action.payload.project);
        }
      )
      .addCase(
        copyProject.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<{ project: ProjectEntity }>
        ) => {
          projectsAdapter.addOne(state.projects, action.payload.project);
        }
      )
      .addCase(
        getAppProjects.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<ProjectEntity[]>
        ) => {
          projectsAdapter.setAll(state.projects, action.payload);
        }
      )
      .addCase(
        publishProject.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<{
            project_id: string;
            enable_copied: boolean;
            updated_time: string;
          }>
        ) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload.project_id,
            changes: {
              published: true,
              enable_copied: action.payload.enable_copied,
              updated_time: action.payload.updated_time,
            },
          });
        }
      )
      .addCase(
        stopPublishProject.fulfilled,
        (state: CreatorInitialState, action: PayloadAction<string>) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload,
            changes: { published: false },
          });
        }
      )
      .addCase(
        tagProject.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<{ project_id: string; tags: string }>
        ) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload.project_id,
            changes: {
              tags: action.payload.tags,
            },
          });
        }
      )
      .addCase(
        removeTagProject.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<{ project_id: string; tags: string }>
        ) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload.project_id,
            changes: {
              tags: action.payload.tags,
            },
          });
        }
      )
      .addCase(
        updateProject.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<{ project: ProjectEntity }>
        ) => {
          const { project } = action.payload;
          projectsAdapter.updateOne(state.projects, {
            id: project.project_id,
            changes: project,
          });
        }
      )
      .addCase(
        getUserProjectContent.fulfilled,
        (state: CreatorInitialState, action: PayloadAction<any>) => {
          state.clipboard = null;
          state.undoStacks = [];
          state.redoStacks = [];
          state.capture = false;
          state.grouping = GroupingDefault;
          state.drawing = FreeDrawingToolDefault;
          state.panelCollapse = PanelCollapseDefault;

          const { content } = action.payload;
          state.selectedScreenId = content.defaultScreenId;
          state.selectedComponentId = content.defaultScreenId;
          state.defaultScreenId = content.defaultScreenId;
          state.screenSize = content.screenSize;
          canvasAdapter.setAll(state.canvas, content.canvas);
          screensAdapter.setAll(state.screens, content.screens);
          componentsAdapter.setAll(state.components, content.components);
          propertiesAdapter.setAll(state.properties, content.properties);
          blocklyAdapter.setAll(state.blockly, content.blockly);
        }
      )
      .addCase(
        removeProject.fulfilled,
        (
          state: CreatorInitialState,
          action: PayloadAction<{ project_id: string; deleted_at: string }>
        ) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload.project_id,
            changes: { deleted_at: action.payload.deleted_at },
          });
        }
      )
      .addCase(
        deleteProject.fulfilled,
        (state: CreatorInitialState, action: PayloadAction<string>) => {
          projectsAdapter.removeOne(state.projects, action.payload);
        }
      )
      .addCase(
        restoreProject.fulfilled,
        (state: CreatorInitialState, action: PayloadAction<string>) => {
          projectsAdapter.updateOne(state.projects, {
            id: action.payload,
            changes: { deleted_at: null },
          });
        }
      );
  },
});

export const actions = { ...creatorSlice.actions };

export default creatorSlice.reducer;
