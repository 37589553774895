import { SVGProps } from "react";

export const NotificationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={38}
    height={32}
    viewBox="0 0 38 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={38} height={32} rx={8} fill={props.fill} />
    <path
      d="M9.81 9.636a9.312 9.312 0 0 1 3.25-2.044 9.54 9.54 0 0 1 7.566.41 9.224 9.224 0 0 1 2.996 2.381L17.829 16l5.793 5.617a9.219 9.219 0 0 1-2.882 2.32 9.549 9.549 0 0 1-7.31.591 9.349 9.349 0 0 1-3.239-1.825 9.025 9.025 0 0 1-2.23-2.918 8.76 8.76 0 0 1-.207-7.113A8.981 8.981 0 0 1 9.81 9.636v0Z"
      stroke="#FEFDFE"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.887 12.4 34 8.8M26.887 18.538l6.956 3.877"
      stroke="#FEFDFE"
      strokeWidth={3}
    />
  </svg>
);
