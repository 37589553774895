export const Stage1LevelRect = [
  {
    left: 80,
    top: 170,
  },
  {
    left: 300,
    top: 400,
  },
  {
    left: 400,
    top: 90,
  },
  {
    left: 700,
    top: 0,
  },
  {
    left: 680,
    top: 300,
  },
  {
    left: 900,
    top: 520,
  },
  {
    left: 1010,
    top: 250,
  },
];
export const Stage2LevelRect = [
  {
    left: 80,
    top: 100,
  },
  {
    left: 114,
    top: 500,
  },
  {
    left: 380,
    top: 300,
  },
  {
    left: 500,
    top: 32,
  },
  {
    left: 720,
    top: 280,
  },
  {
    left: 800,
    top: 550,
  },
  {
    left: 1050,
    top: 200,
  },
];
export const Stage3LevelRect = [
  {
    left: 100,
    top: 50,
  },
  {
    left: 80,
    top: 400,
  },
  {
    left: 400,
    top: 240,
  },
  {
    left: 550,
    top: 500,
  },
  {
    left: 730,
    top: 230,
  },
  {
    left: 1020,
    top: 400,
  },
  {
    left: 1030,
    top: 50,
  },
];
export const Stage4LevelRect = [
  {
    left: 60,
    top: 350,
  },
  {
    left: 200,
    top: 50,
  },
  {
    left: 400,
    top: 300,
  },
  {
    left: 650,
    top: 32,
  },
  {
    left: 950,
    top: 100,
  },
  {
    left: 700,
    top: 400,
  },
  {
    left: 1000,
    top: 500,
  },
];
export const Stage5LevelRect = [
  {
    left: 50,
    top: 100,
  },
  {
    left: 100,
    top: 396,
  },
  {
    left: 500,
    top: 430,
  },
  {
    left: 380,
    top: 132,
  },
  {
    left: 720,
    top: 50,
  },
  {
    left: 900,
    top: 500,
  },
  {
    left: 1010,
    top: 200,
  },
];
export const Stage6LevelRect = [
  {
    left: 70,
    top: 80,
  },
  {
    left: 70,
    top: 396,
  },
  {
    left: 340,
    top: 230,
  },
  {
    left: 610,
    top: 50,
  },
  {
    left: 730,
    top: 310,
  },
  {
    left: 950,
    top: 550,
  },
  {
    left: 1050,
    top: 250,
  },
];

export const StageLevelRects = [
  Stage1LevelRect,
  Stage2LevelRect,
  Stage3LevelRect,
  Stage4LevelRect,
  Stage5LevelRect,
  Stage6LevelRect,
];
