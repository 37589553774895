import { useRef, useState, useEffect, PointerEventHandler } from "react";
import I18n from "i18n-js";
import Blockly from "blockly";
import Constants from "common/constant";
import { Vector2D } from "common/types";
import { IconPlayerPlay } from "common/assets";
import { useScale, btnDelay, usePlaySound } from "common/utils";
import { RuntimeLayout } from "features/courses/program/game/components";

export const DisplayPanel = (props: {
  stage: number;
  step: number;
  question: string;
  isFinished: boolean;
  event: string[];
  workspace: Blockly.WorkspaceSvg;
  Runtime: any;
  run: boolean;
  setRun: (run: boolean) => void;
  children: any;
}) => {
  const {
    stage,
    step,
    question,
    isFinished,
    event,
    workspace,
    Runtime,
    run,
    setRun,
    children,
  } = props;
  const MARGIN = 16;
  const ref = useRef();
  const play = usePlaySound();
  const sound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/itemget.mp3`
  );
  const { width, scale } = useScale();
  const [disable, setDisable] = useState(false);
  const [position, _setPosition] = useState({ x: 0, y: 0 });
  const positionRef = useRef(position);
  const setPosition = (data: Vector2D) => {
    positionRef.current = data;
    _setPosition(data);
  };
  const [blockPanelSize, setBlockPanelSize] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setDisable(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("courses-program-block-panel");
      const rect = panel.getBoundingClientRect();
      setPosition({
        x: rect.right + MARGIN * scale,
        y: rect.y - 25 * scale,
      });
      setBlockPanelSize({ x: rect.width, y: rect.height });
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  const handleRun: PointerEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    play();
    if (!disable && e.isPrimary) {
      setDisable(true);
      const blocklySvg = document.getElementsByClassName(
        "blocklySvg"
      )[0] as HTMLDivElement;
      blocklySvg.style.zIndex = "10002";
      btnDelay(() => setRun(true));

      const stamp = document.getElementById("course-program-level-clear-stamp");
      if (stamp) {
        stamp.style.zIndex = "0";
      }
    }
  };

  const handleReset: PointerEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    play();
    if (e.isPrimary) {
      btnDelay(() => {
        setRun(false);
        setDisable(false);
        const blocklySvg = document.getElementsByClassName(
          "blocklySvg"
        )[0] as HTMLDivElement;
        blocklySvg.style.zIndex = "21";
        workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
          block.unselect();
          block.setHighlighted(false);
          block.setMovable(true);
          block.setEditable(true);
        });
      });
    }
  };

  return (
    <>
      {run && (
        <RuntimeLayout
          sound={sound}
          stage={stage}
          step={step}
          isFinished={isFinished}
          question={question}
          event={event}
          workspace={workspace}
          reset={handleReset}
          Runtime={Runtime}
        />
      )}

      <div
        ref={ref}
        id="courses-program-display-panel"
        style={{
          display: position.x !== 0 ? "flex" : "none",
          width: width - position.x - MARGIN * scale,
          height: 360 * scale,
          borderWidth: 8 * scale,
          borderRadius: 6 * scale,
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="flex-col-el flex-center absolute top-0 left-0 border-solid border-[#b2771f]"
      >
        <div className="flex-col-center overflow-visible w-full h-full z-10">
          <img
            alt="courses blackboard"
            className="w-full h-full absolute top-0 left-0"
            src={`${Constants.assetHost}/assets/images/blackboard.png`}
          />
        </div>

        <div
          className="flex-row-el items-center w-full absolute z-50 top-0 left-0 origin-top-left"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-row-center">
            <div className="flex-row-el items-center h-[48px] bg-white/80 border-solid border-[1px] border-l-0 border-white rounded-r-[8px]">
              <div className="flex-col-el flex-center shrink-0 w-[48px] h-[48px] bg-red/100 rounded-br-[10px]">
                <p className="title-text text-white !text-[39px]">Q</p>
              </div>

              <div className="flex-col-center shrink-0 ml-[12px] pr-2">
                <p className="text-body text-textcolor/black text-left text-[20px]">
                  {question}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="flex-col-el flex-center origin-top absolute top-0 z-50 w-[800px] h-[360px]"
          style={{ transform: `scale(${scale})` }}
        >
          {children}
        </div>
      </div>

      {blockPanelSize.x > 0 && !run && (
        <div
          id="game-scene-start-btn"
          className="flex-col-el flex-center absolute origin-top-left w-[250px]"
          style={{
            bottom: 20 * scale,
            left:
              position.x -
              8 * scale +
              (width - position.x - MARGIN * scale - 250 * scale + 16 * scale) /
                2,
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-row-el flex-center relative alpha">
            <button className="btn btn-primary" onPointerDown={handleRun}>
              <div className="flex-row-center gap-1 mb-[3px]">
                <div className="flex-col-center w-[40px] h-[40px]">
                  <IconPlayerPlay color="white" width={2} />
                </div>

                <div className="flex-col-center">
                  <p className="text-body text-white text-[36px]">
                    {I18n.t("MSG_COURSE_PROGRAM_RUN")}
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};
