import { useEffect, useState } from "react";
import * as Blockly from "blockly";
import { Dialog as Modal } from "@mui/material";
import { Vector2D } from "common/types";
import { FadeTransition } from "common/elements";
import { useScale, usePlaySound } from "common/utils";
import { ColorPicker } from "features/creator/property/components";

export const DialogColourPicker = () => {
  const PANEL_WIDTH = 280;
  const PANEL_HEIGHT = 304;
  const play = usePlaySound();
  const { scale } = useScale();
  const [value, setValue] = useState("");
  const [dialog, setDialog] = useState(false);
  const [onCallback, setOnCallback] = useState({
    onOkay: null,
    onCancel: null,
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (dialog) {
      const workspaceDiv = document.getElementById("blocklyDiv");
      const workspackRect = workspaceDiv.getBoundingClientRect();
      const newX =
        position.x + (PANEL_WIDTH * scale) / 2 > workspackRect.right
          ? workspackRect.right - PANEL_WIDTH * scale
          : position.x - (PANEL_WIDTH * scale) / 2;
      const newY =
        position.y + PANEL_HEIGHT * scale > workspackRect.bottom
          ? position.y - (PANEL_HEIGHT + 20) * scale
          : position.y + 20 * scale;
      setPosition({
        x: newX,
        y: newY,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  Blockly.dialog.colour = (defaultValue, position: Vector2D, callback) => {
    setPosition(position);
    setValue(defaultValue);
    setOnCallback({
      onOkay: callback,
      onCancel: callback,
    });
    setDialog(true);
  };

  const handleChangeTextShadowColor = (color: string) => {
    play();
    setValue(color);
    onCallback.onOkay(color);
  };

  const handleOk = () => {
    play();
    setDialog(false);
    onCallback.onOkay(value);
  };

  return dialog ? (
    <Modal
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 10000 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="w-full h-full" onPointerDown={handleOk}>
        <div
          className="flex-col-center !absolute top-0 left-0"
          style={{
            width: PANEL_WIDTH * scale,
            height: PANEL_HEIGHT * scale,
            transform: `translate(${position.x}px,${position.y}px)`,
          }}
        >
          <div
            className="flex-col-center origin-top-left bg-gray2/20/50 border-solid border-[4px] border-white rounded-[8px] backdrop-blur-[8px]"
            style={{
              width: PANEL_WIDTH,
              height: PANEL_HEIGHT,
              transform: `scale(${scale})`,
            }}
          >
            <div className="flex-col-center w-full h-full px-[16px] py-[24px]">
              <div className="flex-col-center w-full h-full bg-white rounded-[12px]">
                <ColorPicker
                  color={value}
                  onChange={handleChangeTextShadowColor}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};
