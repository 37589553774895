import $ from "jquery";
import anime from "animejs";
import { TEXTINPUT_MAX } from "common/components";
import { setSize, setSizeByTime } from "common/blockly/codeGen";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const setTextinputTextFontSize = (id: string, value: number) => {
  const container = $(`#textinput-container-${id}`);
  const text = $(`#textinput-text-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentInnerShapeWidth = text.innerWidth();
  const currentInnerShapeHeight = text.innerHeight();
  const currentShapeWidth = text.width();
  const currentShapeHeight = text.height();
  const PADDING_HORIZONTAL = currentInnerShapeWidth - currentShapeWidth;
  const PADDING_VERTICAL = currentInnerShapeHeight - currentShapeHeight;
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const fontSizeScale = value / currentFontSize;

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentInnerShapeWidth,
    currentInnerShapeHeight,
    currentInnerShapeWidth * fontSizeScale,
    currentInnerShapeHeight * fontSizeScale
  );

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);

  const borderRadius = Number(text.data("borderRadius"));
  const size = Math.min(
    newShapeSize.x - PADDING_HORIZONTAL,
    newShapeSize.y - PADDING_VERTICAL
  );
  text.css("border-radius", size * (borderRadius / 200));
  text.css("font-size", `${value}px`);
  text.css("width", `${newShapeSize.x - PADDING_HORIZONTAL}`);
  text.css("height", `${newShapeSize.y - PADDING_VERTICAL}`);
};
export const setTextinputTextFontSizeByStep = (id: string, value: number) => {
  const text = $(`#textinput-text-${id}`);
  const currentFontSize = Number(text.css("font-size").split("px")[0]);
  setTextinputTextFontSize(id, currentFontSize + value);
};
export const setTextinputTextFontSizeByTime = (
  id: string,
  time: number,
  value: number
) => {
  const container = $(`#textinput-container-${id}`);
  const text = $(`#textinput-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentInnerShapeWidth = text.innerWidth();
  const currentInnerShapeHeight = text.innerHeight();
  const currentShapeWidth = text.width();
  const currentShapeHeight = text.height();
  const PADDING_HORIZONTAL = currentInnerShapeWidth - currentShapeWidth;
  const PADDING_VERTICAL = currentInnerShapeHeight - currentShapeHeight;
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const fontSizeScale = value / currentFontSize;

  const [, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentInnerShapeWidth,
    currentInnerShapeHeight,
    currentInnerShapeWidth * fontSizeScale,
    currentInnerShapeHeight * fontSizeScale
  );
  const borderRadius = Number(text.data("borderRadius"));
  const size = Math.min(
    newShapeSize.x - PADDING_HORIZONTAL,
    newShapeSize.y - PADDING_VERTICAL
  );

  const animation1 = anime({
    targets: `#textinput-container-${id}`,
    translateX: `-=${offset.x}`,
    translateY: `+=${offset.y}`,
    width: `+=${currentContainerWidth * (fontSizeScale - 1)}`,
    height: `+=${currentContainerHeight * (fontSizeScale - 1)}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#textinput-text-${id}`,
    width: newShapeSize.x - PADDING_HORIZONTAL,
    height: newShapeSize.y - PADDING_VERTICAL,
    borderRadius: size * (borderRadius / 200),
    fontSize: value,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
  });
  return Promise.all([animation1.finished, animation2.finished]);
};

export const getTextinputText = (id: string): string =>
  $(`#textinput-text-${id}`).val().toString();
export const setTextinputText = (id: string, value: string) => {
  if (value.length > TEXTINPUT_MAX) {
    $(`#textinput-text-${id}`).val(value.slice(0, TEXTINPUT_MAX));
  } else {
    $(`#textinput-text-${id}`).val(value);
  }
};
