import { SVGProps } from "react";

export const IconCirclePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 24"
    {...props}
  >
    <path
      d="M42 24c6.627 0 12-5.373 12-12S48.627 0 42 0 30 5.373 30 12s5.373 12 12 12Z"
      fill="#FDA3A0"
    />
    <path
      d="M42 6.1v12M36 12.1h12"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 7.831c10.043 0 14.948 5.784 14.948 5.784"
      stroke="#FDA3A0"
      strokeWidth={4}
    />
    <path d="M10.056 15 18 13.928 16.925 6" stroke="#FDA3A0" strokeWidth={4} />
  </svg>
);
