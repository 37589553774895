import { BlockType } from "features/builder/types";

export const ToolboxDef2 = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      toolboxitemid: "category",
      contents: [
        {
          kind: "block",
          type: BlockType.CONTROLS_REPEAT_INTERNAL,
          extraState: {
            max: 3,
          },
        },
        {
          kind: "block",
          type: BlockType.CONTROLS_WHILEUNTIL_COLOUR_INTERNAL,
          inputs: {
            COLOUR: {
              shadow: {
                type: "colour_picker_internal",
                fields: {
                  COLOUR: "#71cdfc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: BlockType.CONTROLS_IF_COLOUR_INTERNAL,
          inputs: {
            COLOUR: {
              shadow: {
                type: "colour_picker_internal",
                fields: {
                  COLOUR: "#71cdfc",
                },
              },
            },
          },
        },
      ],
    },
  ],
};
