import i18n from "i18n-js";
import { DiamondIconSvg } from "features/courses/assets";

export const Score = (props: { gemScore: number; totalGem: number }) => (
  <div className="flex-row-center h-full rounded-[26px] bg-[#F4E9E0]">
    <p className="text text-textcolor/black !p-[16px]">
      {i18n.t("MSG_COURSE_GAME_SCORE_TEXT")}
    </p>
    <div className="flex-row-center bg-white mr-[4px] px-[10px] h-[48px] rounded-[24px]">
      <div className="flex-col-center mr-[10px] w-[33px] h-[36px]">
        <DiamondIconSvg />
      </div>

      <div className="flex-row-view items-baseline">
        <p className="text text-textcolor/black !text-[28px]">
          {props.gemScore}
        </p>
        <p className="text text-textcolor/black">
          {i18n.t("MSG_COURSE_GAME_SCORE_STATUS", { total: props.totalGem })}
        </p>
      </div>
    </div>
  </div>
);
