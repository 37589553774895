import { useRef, FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { IconMagnifierWhite } from "features/creator/assets";

export const ProjectKeywordSearch = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const keyword = useSelector((state: RootState) => state.creator.keyword);

  useEffect(() => {
    if (ref.current) {
      ref.current.value = "";
    }
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      const keyword = data.get("keyword").toString();
      dispatch(actions.updateKeyword(keyword));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex-row-el flex-center w-full gap-4 my-4"
    >
      <div className="flex-row-center bg-green/100 rounded-xl px-8 h-[22px]">
        <p className="text-body text-white text-font-size-caption1">
          {I18n.t("MSG_CREATOR_APP_KEYWORD_SEARCH_TITLE")}
        </p>
      </div>
      <div className="flex-col-center w-[300px] h-[40px]">
        <input
          ref={ref}
          id="keyword"
          name="keyword"
          type="text"
          pattern={".{0,10}"}
          defaultValue={keyword}
          className="peer input text-body text-textcolor/black text-font-size-body2-narrow !border-4 !border-gray2/40 !rounded-2xl"
          autoFocus={false}
        />
        <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
          {I18n.t("MSG_BLOCKLY_INPUT_LENGTH_LIMIT", {
            limit: 10,
          })}
        </label>
      </div>
      <div className="flex-row-center upsilon-square gap-5">
        <button
          type="submit"
          className="btn btn-primary w-[40px] h-[40px] !p-0 !rounded-lg"
        >
          <div className="flex-col-center w-full h-full">
            <div className="flex-col-center w-[32px] h-[32px]">
              <IconMagnifierWhite />
            </div>
          </div>
        </button>
      </div>
    </form>
  );
};
