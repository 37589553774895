/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef,
  useState,
  MutableRefObject,
  PointerEventHandler,
} from "react";
import produce from "immer";
import { Mask } from "common/elements";
import { Vector2D } from "common/types";
import { ComponentProperty } from "common/components";
import { ContextMenu } from "features/creator/design/ContextMenu";

export const ComponentMovePanel = (props: {
  id: string;
  selected: boolean;
  scale: number;
  zIndex: number;
  groupDelta: Vector2D;
  grouping: boolean;
  groupMoving: boolean;
  panelMargin: number;
  propertyRef: MutableRefObject<ComponentProperty>;
  setProperty: (property: ComponentProperty) => void;
  onSelected: () => void;
  onActionEnd: (newProperty: ComponentProperty) => void;
  handleDelete: (id: string) => void;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | null;
}) => {
  const {
    id,
    selected,
    scale,
    zIndex,
    groupDelta,
    grouping,
    groupMoving,
    panelMargin,
    propertyRef,
    setProperty,
    onSelected,
    onActionEnd,
    handleDelete,
    children,
  } = props;

  const width =
    propertyRef.current.style.layout.width +
    propertyRef.current.style.view.borderWidth +
    propertyRef.current.style.shadow.shadowRadius;
  const height =
    propertyRef.current.style.layout.height +
    propertyRef.current.style.view.borderWidth +
    propertyRef.current.style.shadow.shadowRadius;

  const [start, setStart] = useState({
    x: propertyRef.current.style.transform.translateX,
    y: propertyRef.current.style.transform.translateY,
  });

  const [clicked, setClicked] = useState(false);
  const [context, setContext] = useState(false);
  const [contextPosition, setContextPosition] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const [preClientXY, _setPreClientXY] = useState<Vector2D>({ x: 0, y: 0 });
  const preClientRef = useRef(preClientXY);
  const setPreClientXY = (data: Vector2D) => {
    preClientRef.current = data;
    _setPreClientXY(data);
  };
  const [timestamp, _setTimestamp] = useState<number>(0);
  const timestampRef = useRef(timestamp);
  const setTimestamp = (data: number) => {
    timestampRef.current = data;
    _setTimestamp(data);
  };
  const [startClientXY, setStartClientXY] = useState<Vector2D>({ x: 0, y: 0 });

  const onPointerStart: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.isPrimary) return;

    onSelected();

    setPreClientXY({ x: e.clientX, y: e.clientY });
    setStartClientXY({ x: e.clientX, y: e.clientY });
    if (e.pointerType === "mouse") {
      setClicked(true);
    } else {
      setTimestamp(e.timeStamp);
    }
  };

  const onPointerMove: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.isPrimary) return;

    if (e.pointerType === "touch" || clicked) {
      const dx = (e.clientX - preClientRef.current.x) / scale;
      const dy = (e.clientY - preClientRef.current.y) / scale;

      const newX = propertyRef.current.style.transform.translateX + dx;
      const newY = propertyRef.current.style.transform.translateY + dy;

      setPreClientXY({ x: e.clientX, y: e.clientY });
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.transform.translateX = newX;
        draft.style.transform.translateY = newY;
      });
      setProperty(newProperty);
    }
  };

  const onPointerEnd: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!e.isPrimary) return;

    if (e.pointerType === "mouse") {
      setClicked(false);
      if (e.button === 2) {
        setContext(true);
        setContextPosition({ x: e.clientX, y: e.clientY });
      }
    } else {
      const elapsed = (e.timeStamp - timestamp) / 1000;
      const dx = (e.clientX - startClientXY.x) / scale;
      const dy = (e.clientY - startClientXY.y) / scale;
      if (elapsed > 0.5 && Math.abs(dx) < 3 && Math.abs(dy) < 3) {
        setContext(true);
        setContextPosition({ x: e.clientX, y: e.clientY });
      }
      setTimestamp(0);
    }
    if (
      propertyRef.current.style.transform.translateX +
        propertyRef.current.style.layout.width <
        0 ||
      propertyRef.current.style.transform.translateX > 1024 ||
      propertyRef.current.style.transform.translateY +
        propertyRef.current.style.layout.height <
        0 ||
      propertyRef.current.style.transform.translateY > 768
    ) {
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.transform.translateX = start.x;
        draft.style.transform.translateY = start.y;
      });
      setProperty(newProperty);
      onActionEnd(newProperty);
    } else {
      setStart({
        x: propertyRef.current.style.transform.translateX,
        y: propertyRef.current.style.transform.translateY,
      });
      onActionEnd(propertyRef.current);
    }
  };

  return (
    <>
      {selected && context && (
        <ContextMenu
          id={id}
          scale={scale}
          postion={{
            x: contextPosition.x,
            y: contextPosition.y,
          }}
          close={() => setContext(false)}
          handleDelete={handleDelete}
        />
      )}

      {clicked && (
        <Mask onPointerMove={onPointerMove} onPointerUp={onPointerEnd} />
      )}

      <div
        id={`component-move-panel-${id}`}
        className="absolute top-0 left-0 cursor-grab overflow-visible mix-blend-normal"
        style={{
          zIndex: zIndex,
          backfaceVisibility: "hidden",
          width: width,
          height: height,
          padding: panelMargin / 2,
          transform: `translate(${
            propertyRef.current.style.transform.translateX -
            panelMargin / 2 +
            (grouping && groupMoving ? groupDelta.x : 0)
          }px, ${
            propertyRef.current.style.transform.translateY -
            panelMargin / 2 +
            (grouping && groupMoving ? groupDelta.y : 0)
          }px) rotate(${propertyRef.current.style.transform.rotation}deg)`,
          willChange: "transform",
          opacity: propertyRef.current.style.view.opacity / 100,
          filter:
            propertyRef.current.style.shadow.shadowRadius !== 0 &&
            `drop-shadow(${propertyRef.current.style.shadow.shadowOffset.width}px ${propertyRef.current.style.shadow.shadowOffset.height}px ${propertyRef.current.style.shadow.shadowRadius}px ${propertyRef.current.style.shadow.shadowColor})`,
        }}
        onPointerDown={onPointerStart}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerEnd}
      >
        {children}
      </div>
    </>
  );
};
