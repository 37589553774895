import { SVGProps } from "react";

export const IconProject = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36 12.5v11.293a.5.5 0 0 1-.146.353l-12.5 12.5c-.196.196-.518.201-.752.053C22.14 36.405 21.273 36 20 36c-4.8 0-6 4-6 6H6.5a.5.5 0 0 1-.5-.5V34c2 0 6-1.4 6-7s-4-7-6-7v-7.5a.5.5 0 0 1 .5-.5H16c0-2 1-6 5-6s5 4 5 6h9.5a.5.5 0 0 1 .5.5Z"
      fill="#AAF1D6"
    />
    <path
      d="M40.08 24.26c-.28 0-.56.12-.78.34l-2 2 4.1 4.1 2-2c.44-.42.44-1.12 0-1.54l-2.56-2.56a1.068 1.068 0 0 0-.76-.34Zm-3.94 3.5L24 39.88V44h4.12l12.12-12.14-4.1-4.1Z"
      fill="#667786"
    />
    <path
      d="M36 24V12.5a.5.5 0 0 0-.5-.5H26c0-2-1-6-5-6s-5 4-5 6H6.5a.5.5 0 0 0-.5.5V20c2 0 6 1.4 6 7s-4 7-6 7v7.5a.5.5 0 0 0 .5.5H14c0-2 1.2-6 6-6 2 0 3 1 3 1"
      stroke="#667786"
      strokeWidth={3}
    />
  </svg>
);
