import { useEffect, useRef, useState, PointerEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { RootState, AppDispatch } from "app/store";
import { Mask } from "common/elements";
import { Vector2D } from "common/types";
import { usePlaySound } from "common/utils";
import { ComponentManager } from "common/components";
import {
  actions,
  selectCanvasById,
  selectPropertyById,
} from "features/creator/slice";
import { useActionCommand } from "features/creator/utils";
import { DropLayer } from "features/creator/design/DropLayer";
import { DesignView } from "features/creator/design/DesignView";
import { ContextMenu } from "features/creator/design/ContextMenu";
import { ScreensEntity, CreatorOptions } from "features/creator/types";
import { ActionCommandType, GroupingDefault } from "features/creator/types";
import { FreeDrawingPanel } from "features/creator/drawing/FreeDrawingPanel";

const FocusContainer = (props: { size: Vector2D; selected: boolean }) => {
  const { size, selected } = props;
  const MARGIN = 8;

  return (
    <div
      id="selected-screen-border"
      className={`z-[100000] pointer-events-none absolute box-border border-solid border-[4px] ${
        selected ? "border-[#70B1F4]/40" : "border-[#E5CCA1]"
      } `}
      style={{
        top: -MARGIN / 2,
        left: -MARGIN / 2,
        width: size.x + MARGIN,
        height: size.y + MARGIN,
      }}
    />
  );
};

export const RenderTemplateDesignScreen = (props: {
  scale: number;
  screen: ScreensEntity;
  options: CreatorOptions;
}) => {
  const play = usePlaySound();
  const { scale, screen } = props;
  const handleAction = useActionCommand();
  const dispatch = useDispatch<AppDispatch>();

  const [timestamp, setTimestamp] = useState(0);
  const [context, setContext] = useState(false);
  const [contextPosition, setContextPosition] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const [startClientXY, setStartClientXY] = useState<Vector2D>({ x: 0, y: 0 });

  const canvas = useSelector((state: RootState) =>
    selectCanvasById(state, screen.id)
  );
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const { typeId, property } = useSelector((state: RootState) =>
    selectPropertyById(state, screen.id)
  );
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const defaultScreenId = useSelector(
    (state: RootState) => state.creator.defaultScreenId
  );
  const drawing = useSelector((state: RootState) => state.creator.drawing);
  const selected = selectedScreenId === screen.id;
  const HEADER_CONTAINER_WIDTH = 250;
  const SCREEN_WIDTH = property.style.layout.width;
  const SCREEN_HEIGHT = property.style.layout.height;
  const Screen = ComponentManager[typeId].component.DesignComponent;

  const screenElement = document.getElementById(screen.id);
  const [screenPoistion, setScreenPosition] = useState<Vector2D>({
    x: 0,
    y: 0,
  });

  const {
    enable: groupingEnable,
    componentIds: groupingComponentIds,
    position,
    size,
  } = useSelector((state: RootState) => state.creator.grouping);

  const [groupMoving, setGroupMoving] = useState(false);

  const [groupClientDelta, _setGroupClientDelta] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const groupClientDeltaRef = useRef(groupClientDelta);
  const setGroupClientDelta = (data: Vector2D) => {
    groupClientDeltaRef.current = data;
    _setGroupClientDelta(data);
  };
  const [groupPreClientXY, _setGroupPreClientXY] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const groupPreClientRef = useRef(groupPreClientXY);
  const setGroupPreClientXY = (data: Vector2D) => {
    groupPreClientRef.current = data;
    _setGroupPreClientXY(data);
  };

  const [groupOffset, _setGroupOffset] = useState<Vector2D>({ x: 0, y: 0 });
  const groupOffsetRef = useRef(groupOffset);
  const setGroupOffset = (data: Vector2D) => {
    groupOffsetRef.current = data;
    _setGroupOffset(data);
  };

  const [startSelection, setStartSelection] = useState(false);
  const [startSelectionPoint, setStartSelectionPoint] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const [endSelectionPoint, _setEndSelectionPoint] = useState<Vector2D>({
    x: 0,
    y: 0,
  });
  const endSelectionPointRef = useRef(endSelectionPoint);
  const setEndSelectionPoint = (data: Vector2D) => {
    endSelectionPointRef.current = data;
    _setEndSelectionPoint(data);
  };

  useEffect(() => {
    setGroupOffset(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  useEffect(() => {
    if (!groupMoving) {
      setGroupClientDelta({ x: 0, y: 0 });
    }
  }, [groupMoving]);

  const handleSelectionStart = (e: PointerEvent) => {
    play();
    e.stopPropagation();
    if (drawing.enable || startSelection || !e.isPrimary) {
      return;
    }
    dispatch(
      actions.updateSelectedComponentId({
        screenId: screen.id,
        id: screen.id,
      })
    );
    const screenRect = screenElement.getBoundingClientRect();
    setScreenPosition({ x: screenRect.x, y: screenRect.y });
    setStartSelectionPoint({ x: e.clientX, y: e.clientY });
    setEndSelectionPoint({ x: e.clientX, y: e.clientY });
    setStartSelection(true);
    dispatch(actions.updateGrouping(GroupingDefault));
    dispatch(actions.handleAllCollapse());
  };
  const handleSelecting = (e: PointerEvent) => {
    e.stopPropagation();
    if (!drawing.enable && startSelection && e.isPrimary) {
      setEndSelectionPoint({ x: e.clientX, y: e.clientY });
    }
  };
  const handleSelectionEnd = (e: PointerEvent) => {
    e.stopPropagation();
    if (drawing.enable || !e.isPrimary) {
      return;
    }

    setStartSelection(false);

    let startX: number;
    let endX: number;
    if (startSelectionPoint.x <= endSelectionPointRef.current.x) {
      startX = startSelectionPoint.x;
      endX = endSelectionPointRef.current.x;
    } else {
      startX = endSelectionPointRef.current.x;
      endX = startSelectionPoint.x;
    }

    let startY: number;
    let endY: number;
    if (startSelectionPoint.y <= endSelectionPointRef.current.y) {
      startY = startSelectionPoint.y;
      endY = endSelectionPointRef.current.y;
    } else {
      startY = endSelectionPointRef.current.y;
      endY = startSelectionPoint.y;
    }

    const pointX = [];
    const pointY = [];
    const elementIds = [];
    const screenChildren = screenElement.children;

    for (let i = 0; i < screenChildren.length; i++) {
      const element = screenChildren.item(i);

      const elementId = element
        .getAttribute("id")
        .split("component-move-panel-")[1];
      const elementRect = element.getBoundingClientRect();

      if (!elementId) continue;

      if (
        elementRect.right >= startX &&
        elementRect.x <= endX &&
        elementRect.bottom >= startY &&
        elementRect.y <= endY
      ) {
        pointX.push(elementRect.x);
        pointX.push(elementRect.right);
        pointY.push(elementRect.y);
        pointY.push(elementRect.bottom);
        elementIds.push(elementId);
      }
    }

    if (elementIds.length > 1) {
      const offset = {
        x: (Math.min(...pointX) - screenPoistion.x) / scale,
        y: (Math.min(...pointY) - screenPoistion.y) / scale,
      };
      const start = {
        x: Math.min(...pointX) / scale,
        y: Math.min(...pointY) / scale,
      };
      const end = {
        x: Math.max(...pointX) / scale,
        y: Math.max(...pointY) / scale,
      };
      setGroupOffset(offset);
      dispatch(
        actions.updateGrouping({
          enable: true,
          componentIds: elementIds,
          position: offset,
          size: { x: end.x - start.x, y: end.y - start.y },
        })
      );
    } else if (elementIds.length === 1) {
      dispatch(
        actions.updateSelectedComponentId({
          screenId: screen.id,
          id: elementIds[0],
        })
      );
      dispatch(actions.updateGrouping(GroupingDefault));
    }
  };

  const handleGroupStart = (e: PointerEvent) => {
    play();
    setTimestamp(e.timeStamp);
    e.stopPropagation();
    setGroupMoving(true);
    setGroupPreClientXY({ x: e.clientX, y: e.clientY });
    setGroupClientDelta({ x: 0, y: 0 });
  };
  const handleGroupMoving = (e: PointerEvent) => {
    if (groupMoving) {
      e.stopPropagation();
      const dx = (e.clientX - groupPreClientRef.current.x) / scale;
      const dy = (e.clientY - groupPreClientRef.current.y) / scale;
      setGroupPreClientXY({ x: e.clientX, y: e.clientY });
      setGroupClientDelta({
        x: groupClientDeltaRef.current.x + dx,
        y: groupClientDeltaRef.current.y + dy,
      });
      setGroupOffset({
        x: groupOffsetRef.current.x + dx,
        y: groupOffsetRef.current.y + dy,
      });
    }
  };
  const handleGroupEnd = (e: PointerEvent) => {
    e.stopPropagation();
    if (groupMoving) {
      dispatch(
        actions.updateGroupingMovingEnd({
          enable: true,
          componentIds: groupingComponentIds,
          position: {
            x: groupOffsetRef.current.x,
            y: groupOffsetRef.current.y,
          },
          size,
          delta: {
            x: groupClientDeltaRef.current.x,
            y: groupClientDeltaRef.current.y,
          },
        })
      );
    }
    setGroupMoving(false);

    if (e.pointerType === "mouse") {
      if (e.button === 2) {
        setContext(true);
        setContextPosition({ x: e.clientX, y: e.clientY });
      }
    } else {
      const elapsed = (e.timeStamp - timestamp) / 1000;
      if (
        elapsed > 0.5 &&
        Math.abs(groupClientDelta.x) < 3 &&
        Math.abs(groupClientDelta.y) < 3
      ) {
        setContext(true);
        setContextPosition({ x: e.clientX, y: e.clientY });
      }
    }
    // mouse eventならコンポーネントはジッターする、原因不明
    setTimestamp(0);
    setTimestamp(0);
    setTimestamp(0);
  };

  const updateSelected = (e: PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.isPrimary) return;
    dispatch(
      actions.updateSelectedComponentId({
        screenId: screen.id,
        id: screen.id,
      })
    );
    if (selected) {
      dispatch(actions.updateSelectedScreenId(screen.id));
    }
    if (!drawing.enable) {
      dispatch(actions.handleAllCollapse());
    }
  };

  const onClickStart = (e: PointerEvent) => {
    e.stopPropagation();
    if (!e.isPrimary) return;
    setTimestamp(e.timeStamp);
    setStartClientXY({ x: e.clientX, y: e.clientY });
  };
  const onDragStop = (e: PointerEvent) => {
    play();
    e.stopPropagation();
    if (!e.isPrimary) return;

    updateSelected(e);
    if (defaultScreenId !== screen.id) {
      if (e.pointerType === "mouse") {
        if (e.button === 2) {
          setContext(true);
          setContextPosition({ x: e.clientX, y: e.clientY });
        }
      } else {
        const elapsed = (e.timeStamp - timestamp) / 1000;
        const dx = (e.clientX - startClientXY.x) / scale;
        const dy = (e.clientY - startClientXY.y) / scale;
        if (elapsed > 0.5 && Math.abs(dx) < 3 && Math.abs(dy) < 3) {
          setContext(true);
          setContextPosition({ x: e.clientX, y: e.clientY });
        }
      }
    }

    setTimestamp(0);
  };

  const handleDelete = () => {
    if (groupingEnable) {
      // delete grouping element
      anime({
        targets: groupingComponentIds.reduce(
          (pre, cur) => [
            ...pre,
            document.getElementById(`component-move-panel-${cur}`),
            document.getElementById(`component-resize-panel-${cur}`),
          ],
          []
        ),
        scale: 0.9,
        opacity: 0,
        duration: 400,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        handleAction({
          type: ActionCommandType.REMOVE_GROUPING,
          screenId: screen.id,
          componentIds: groupingComponentIds,
        });
      });
    } else {
      // delete screen
      anime({
        targets: document.getElementById(`wrap-${screen.id}`),
        scale: 0.9,
        opacity: 0,
        duration: 400,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        handleAction({
          type: ActionCommandType.REMOVE_SCREEN,
          screenId: screen.id,
        });
      });
    }
  };

  return (
    <>
      {startSelection && (
        <Mask
          onPointerMove={handleSelecting}
          onPointerUp={handleSelectionEnd}
        />
      )}

      {selectedComponentId === screen.id && context && (
        <ContextMenu
          id={screen.id}
          scale={scale}
          postion={{
            x: contextPosition.x,
            y: contextPosition.y,
          }}
          close={() => {
            setContext(false);
          }}
          handleDelete={handleDelete}
        />
      )}

      {groupMoving && (
        <Mask onPointerMove={handleGroupMoving} onPointerUp={handleGroupEnd} />
      )}

      <div
        onPointerDown={onClickStart}
        onPointerUp={onDragStop}
        className="z-[10000] absolute top-0 left-0"
        style={{
          pointerEvents: drawing.enable ? "none" : "auto",
          backfaceVisibility: "hidden",
          width: HEADER_CONTAINER_WIDTH,
          height: 0,
          transform: `translate(${
            (SCREEN_WIDTH - HEADER_CONTAINER_WIDTH) / 2 + canvas.x
          }px, ${canvas.y}px)`,
          willChange: "transform",
          zIndex: 10001,
        }}
      >
        <div
          id={`wrap-${screen.id}`}
          className="flex-col-center !justify-end w-full h-full"
        >
          <div
            className="flex-col-view !absolute top-0 left-0 cursor-auto"
            style={{
              width: SCREEN_WIDTH,
              height: SCREEN_HEIGHT,
              pointerEvents: drawing.enable
                ? selected
                  ? "auto"
                  : "none"
                : "auto",
              transform: `translate(${
                (HEADER_CONTAINER_WIDTH - SCREEN_WIDTH) / 2
              }px, 0px)`,
            }}
            onContextMenu={(e) => e.preventDefault()}
            onPointerDown={handleSelectionStart}
            onPointerMove={handleSelecting}
            onPointerUp={handleSelectionEnd}
          >
            <div
              style={{
                pointerEvents: drawing.enable ? "none" : "auto",
              }}
            >
              <Screen id={screen.id} property={property}>
                {screen.children.map((component) => {
                  return (
                    <DesignView
                      key={component.id}
                      componentId={component.id}
                      scale={scale}
                      groupDelta={groupClientDelta}
                      grouping={groupingComponentIds.includes(component.id)}
                      groupMoving={groupMoving}
                      zIndex={screen.childrenOrder.indexOf(component.id)}
                    />
                  );
                })}
              </Screen>
            </div>

            <DropLayer
              scale={scale}
              size={{ x: SCREEN_WIDTH, y: SCREEN_HEIGHT }}
              screenId={screen.id}
            />

            <FocusContainer
              size={{ x: SCREEN_WIDTH, y: SCREEN_HEIGHT }}
              selected={selectedComponentId === screen.id}
            />

            {selected && (
              <FreeDrawingPanel
                screenId={screen.id}
                width={SCREEN_WIDTH}
                height={SCREEN_HEIGHT}
              />
            )}

            {selected && startSelection && (
              <div
                className="bg-green/altcolor/10 border-solid border-green/60 absolute top-0 left-0"
                style={{
                  left:
                    startSelectionPoint.x <= endSelectionPoint.x
                      ? (startSelectionPoint.x - screenPoistion.x) / scale
                      : (endSelectionPoint.x - screenPoistion.x) / scale,
                  top:
                    startSelectionPoint.y <= endSelectionPoint.y
                      ? (startSelectionPoint.y - screenPoistion.y) / scale
                      : (endSelectionPoint.y - screenPoistion.y) / scale,
                  width:
                    Math.abs(endSelectionPoint.x - startSelectionPoint.x) /
                    scale,
                  height:
                    Math.abs(endSelectionPoint.y - startSelectionPoint.y) /
                    scale,
                }}
              />
            )}
            {selected && groupingEnable && (
              <div
                className="bg-green/altcolor/10 z-[1000] box-border border-solid border-green/60 absolute top-0 left-0 pointer-events-auto"
                style={{
                  transform: `translate(${groupOffset.x}px, ${groupOffset.y}px)`,
                  width: size.x,
                  height: size.y,
                }}
                onPointerDown={handleGroupStart}
                onPointerMove={handleGroupMoving}
                onPointerUp={handleGroupEnd}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
