import { useSelector } from "react-redux";
import I18n from "i18n-js";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { RootState } from "app/store";
import { useUserConfig } from "app/hook";
import { ClickEvent } from "common/types";
import { Switch, RadioButton } from "common/elements";

export const Step6 = () => {
  const languageMapping = {
    ja: " ひらがな",
    ja_kanji: "漢字",
  };
  const handleChangeUserConfig = useUserConfig();
  const config = useSelector((state: RootState) => state.config.userConfig);

  const handleChangeLocale = (locale: string) => () => {
    handleChangeUserConfig("locale", locale);
    I18n.locale = locale;
  };

  const hanldeSoundVolume = (value: number) =>
    handleChangeUserConfig("sound.volume", value);

  const hanldeBgmVolume = (value: number) =>
    handleChangeUserConfig("bgm.volume", value);

  const handleSoundEnable = (e: ClickEvent) => {
    e.stopPropagation();
    handleChangeUserConfig("sound.enable", !config.sound.enable);
  };

  const handleBgmEnable = (e: ClickEvent) => {
    e.stopPropagation();
    handleChangeUserConfig("bgm.enable", !config.bgm.enable);
  };

  return (
    <div className="flex-col-center w-full h-full">
      <div className="flex-col-view w-[540px] h-full">
        <div className="flex-row-view justify-center h-[60px] mt-[12px]">
          <p className="text text-textcolor/black">
            最後に、アプリの初期設定を行います。
          </p>
        </div>

        <div className="flex-col-view justify-between w-full h-[170px] my-[32px]">
          {/* 言語 */}
          <div className="flex-row-view">
            <div className="flex-row-view items-center justify-end w-[162px]">
              <p className="text text-textcolor/black">
                {I18n.t("MSG_SETTING_APP_LANGUAGE")}
              </p>
            </div>

            <div className="flex-row-center w-[360px]">
              <div className="flex-row-view justify-start !flex-1 ml-[50px]">
                <RadioButton
                  size={30}
                  value={config.locale === "ja"}
                  label={languageMapping["ja"]}
                  onChange={handleChangeLocale("ja")}
                />
              </div>
              <div className="flex-row-view justify-start !flex-1 ml-[50px]">
                <RadioButton
                  size={30}
                  value={config.locale === "ja_kanji"}
                  label={languageMapping["ja_kanji"]}
                  onChange={handleChangeLocale("ja_kanji")}
                />
              </div>
            </div>
          </div>

          {/* 効果音 */}
          <div className="flex-row-view">
            <div className="flex-row-view items-center justify-end w-[162px]">
              <label className="text text-textcolor/black">
                {I18n.t("MSG_SETTING_SOUND")}
              </label>
            </div>
            <div className="flex-row-center mx-[50px] pointer-events-auto">
              <div className="w-[160px]">
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={config.sound.volume}
                  onChange={hanldeSoundVolume}
                  trackStyle={{
                    height: 32 * 0.3,
                    backgroundColor: "#94C4F7",
                  }}
                  handleStyle={{
                    height: 32,
                    width: 32,
                    borderColor: "transparent",
                    opacity: 1,
                    marginTop: -11, // - height / 2 + 5 (slider default marginTop is -5)
                    backgroundColor: "#94C4F7",
                  }}
                  railStyle={{
                    height: 32 * 0.3,
                    backgroundColor: "#DBD8DA",
                  }}
                />
              </div>
            </div>
            <Switch
              color="#B8B1B5"
              background="#DBEBFC"
              thumb="#94C4F7"
              margin={3}
              width={96}
              height={40}
              value={config.sound.enable}
              onClick={handleSoundEnable}
            />
          </div>

          {/* BGM */}
          <div className="flex-row-view">
            <div className="flex-row-view items-center justify-end w-[162px]">
              <label className="text text-textcolor/black">
                {I18n.t("MSG_SETTING_BGM")}
              </label>
            </div>
            <div className="flex-row-center mx-[50px] pointer-events-auto">
              <div className="w-[160px]">
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={config.bgm.volume}
                  onChange={hanldeBgmVolume}
                  trackStyle={{
                    height: 32 * 0.3,
                    backgroundColor: "#94C4F7",
                  }}
                  handleStyle={{
                    height: 32,
                    width: 32,
                    borderColor: "transparent",
                    opacity: 1,
                    marginTop: -11,
                    backgroundColor: "#94C4F7",
                  }}
                  railStyle={{
                    height: 32 * 0.3,
                    backgroundColor: "#DBD8DA",
                  }}
                />
              </div>
            </div>
            <Switch
              color="#B8B1B5"
              background="#DBEBFC"
              thumb="#94C4F7"
              margin={3}
              width={96}
              height={40}
              value={config.bgm.enable}
              onClick={handleBgmEnable}
            />
          </div>
        </div>

        <div className="flex-row-view items-end justify-start w-full flex-wrap">
          <div className="flex-row-center">
            <p className="text text-textcolor/black">
              設定は、アプリ画面内の右上にある
            </p>
            <img alt="ロゴ" src="/logo.png" className="w-[56px] h-[56px] m-2" />
            <p className="text text-textcolor/black">マークから</p>
          </div>
          <p className="text text-textcolor/black">
            いつでも設定変更が可能です。
          </p>
        </div>
      </div>
    </div>
  );
};
