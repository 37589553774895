import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import Constants from "common/constant";
import { FadeTransition } from "common/elements";

export const Failed = (props: { scale: number; reset: () => void }) => {
  const { scale, reset } = props;
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(false);
  }, []);

  const handleReset = () => {
    if (!disable) {
      setDisable(true);
      reset();
    }
  };

  return (
    <Dialog
      open
      fullScreen
      TransitionComponent={FadeTransition}
      transitionDuration={{ enter: 1000, exit: 500 }}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      sx={{ zIndex: 10001, backgroundColor: "transparent" }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-col-center !absolute w-full h-full bg-textcolor/black/20">
        <div
          className="flex-col-center w-[650px] h-[450px] rounded-[8px] border-[8px] border-solid border-white bg-white"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-center w-full h-full p-4 rounded-[4px] bg-beige/60">
            <div className="flex-row-center !justify-around w-full">
              <img
                className="w-[209px] h-[240px] flex-col-view"
                src={`${Constants.assetHost}/assets/images/duck_attention.png`}
                alt="*"
              />

              <div className="flex-col-center h-full">
                <p className="text-body text-textcolor/black text-[32px] whitespace-pre">
                  {I18n.t("MSG_COURSE_GAME_FAILED_TITLE")}
                </p>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_COURSE_GAME_FAILED_TEXT")}
                </p>
              </div>
            </div>

            <div className="flex-row-el flex-center alpha mt-12 ml-12">
              <button className="btn btn-danger" onPointerDown={handleReset}>
                <p className="text-body text-white text-[20px] !mb-[3px]">
                  {I18n.t("MSG_COURSE_GAME_FAILED_RESET_BTN")}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
