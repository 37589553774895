import Rect, { useRef, useEffect, useState, PointerEvent } from "react";
import * as Blockly from "blockly";
import { Vector2D } from "common/types";
import { Modal } from "common/elements";
import { usePlaySound } from "common/utils";
import {
  RenderScreen,
  RenderScreenComponent,
} from "common/blockly/dialog/RenderComponent";
import { ComponentTypeIds } from "common/components";
import { BASE_WIDTH, BASE_HEIGHT } from "common/constant";

const RenderComponentList = Rect.memo(
  (props: {
    components: string[][];
    selectedComponent: string;
    handleOk: (value: string) => (e) => void;
  }) => {
    const LIST_ITEM_WIDTH = 100;
    const LIST_ITEM_MARGIN = 6;
    const LIST_ITEM_HEIGHT = LIST_ITEM_WIDTH / (BASE_WIDTH / BASE_HEIGHT);
    const LIST_ITEM_NAME_WIDHT = 80;
    const { components, selectedComponent, handleOk } = props;
    return (
      <>
        {components.map((component, index) => {
          if (component[1] === "") {
            return (
              <div
                key={0}
                className={`flex-row-center w-full rounded-[12px] ${
                  selectedComponent === component[1] && "bg-green/altcolor"
                }`}
                style={{
                  height: LIST_ITEM_HEIGHT,
                }}
                onPointerDown={handleOk("")}
              >
                <div
                  className="flex-row-center"
                  style={{ width: LIST_ITEM_NAME_WIDHT }}
                >
                  <p className="w-full text text-gray/100 !text-[16px] text-body-ellipsis">
                    パーツ
                  </p>
                </div>
              </div>
            );
          } else {
            const componentId = component[1].split("_");
            if (componentId[0] === ComponentTypeIds.SCREEN) {
              return (
                <div
                  key={componentId[1]}
                  className={`flex-row-center w-full rounded-[12px] ${
                    selectedComponent === component[1] && "bg-green/altcolor"
                  }`}
                >
                  <RenderScreen
                    id={componentId[1]}
                    itemWidth={LIST_ITEM_WIDTH}
                    itemHeight={LIST_ITEM_HEIGHT}
                    itemMargin={LIST_ITEM_MARGIN}
                    nameWidth={LIST_ITEM_NAME_WIDHT}
                    onClick={handleOk}
                  />
                </div>
              );
            } else {
              return (
                <div
                  key={componentId[1]}
                  className={`flex-row-center w-full rounded-[12px] ${
                    selectedComponent === component[1] && "bg-green/altcolor"
                  }`}
                >
                  <RenderScreenComponent
                    id={componentId[1]}
                    itemWidth={LIST_ITEM_WIDTH}
                    itemHeight={LIST_ITEM_HEIGHT}
                    itemMargin={LIST_ITEM_MARGIN}
                    nameWidth={LIST_ITEM_NAME_WIDHT}
                    onClick={handleOk}
                  />
                </div>
              );
            }
          }
        })}
      </>
    );
  }
);

export const DialogComponentList = (props: {
  workspace: Blockly.WorkspaceSvg;
}) => {
  const PANEL_WIDTH = 264;
  const PANEL_HEIGHT = 352;

  const play = usePlaySound();
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const scale = props.workspace?.getScale();
  const [dialog, setDialog] = useState(false);
  const [onCallback, setOnCallback] = useState({
    onOkay: null,
    onCancel: null,
  });
  const [components, setComponents] = useState<string[][]>([]);
  const [selectedComponent, setSelectedComponent] = useState("");
  const [position, setPosition] = useState<Vector2D>({ x: 0, y: 0 });

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 87 * index;
    }
  }, [index]);

  useEffect(() => {
    if (dialog && ref.current) {
      const workspaceDiv = document.getElementById("blocklyDiv");
      const workspackRect = workspaceDiv.getBoundingClientRect();
      const newX =
        position.x + (PANEL_WIDTH * scale) / 2 > workspackRect.right
          ? workspackRect.right - PANEL_WIDTH * scale
          : position.x - (PANEL_WIDTH * scale) / 2;
      const menuHeight = ref.current.getBoundingClientRect().height;
      const newY =
        position.y + menuHeight > workspackRect.bottom
          ? position.y - (menuHeight + 20)
          : position.y + 20 * scale;
      setPosition({
        x: newX,
        y: newY,
      });
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, ref]);

  Blockly.dialog.component = (
    position: Vector2D,
    selectedComponent: string,
    components: string[][],
    callback: any
  ) => {
    setSelectedComponent(selectedComponent);
    setPosition(position);
    setComponents(components);
    setOnCallback({
      onOkay: callback,
      onCancel: callback,
    });
    setDialog(true);
    setIndex(
      components.findIndex((component) => component[1] === selectedComponent)
    );
  };

  const handleOk = (value: string) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    play();
    setShow(false);
    setDialog(false);
    onCallback.onOkay(value);
  };

  const handleCancel = () => {
    play();
    setShow(false);
    setDialog(false);
  };

  const handleStopPropagation = (e: PointerEvent<HTMLDivElement>) =>
    e.stopPropagation();

  return dialog ? (
    <Modal transparent>
      <div
        className="w-full h-full"
        onPointerDown={handleStopPropagation}
        onPointerUp={handleCancel}
        onPointerMove={handleStopPropagation}
      >
        <div
          className={`flex-col-el z-[1] absolute top-0 left-0 !box-border`}
          style={{
            width: PANEL_WIDTH * scale,
            maxHeight: PANEL_HEIGHT * scale,
            transform: `translate(${position.x}px,${position.y}px)`,
          }}
          onPointerUp={handleStopPropagation}
          onPointerMove={handleStopPropagation}
          onPointerDown={handleStopPropagation}
        >
          <div
            className="flex-col-view justify-start origin-top-left backdrop-blur-[8px]"
            style={{
              width: PANEL_WIDTH,
              maxHeight: PANEL_HEIGHT,
              backfaceVisibility: "hidden",
              WebkitBackfaceVisibility: "hidden",
              transform: `translateZ(0) scale(${scale})`,
            }}
          >
            <div
              ref={ref}
              className={`flex-col-el px-6 py-4 flex-1 overflow-y-auto bg-gray/20/50 border-solid border-[4px] border-white rounded-lg  ${
                show ? "visible" : "!hidden"
              }`}
            >
              <div className="flex-col-el w-full justify-start bg-white rounded-xl">
                {components.length > 0 && (
                  <RenderComponentList
                    components={components}
                    selectedComponent={selectedComponent}
                    handleOk={handleOk}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};
