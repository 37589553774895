/* eslint-disable no-new-func */
import { useEffect } from "react";
import * as codeGen from "common/blockly/codeGen";
import { RuntimeWrapComponentProps } from "common/components/types";

const WrapComponent = (props: RuntimeWrapComponentProps) => {
  const {
    id,
    property,
    navigate,
    events,
    initVariables,
    proceduresCode,
  } = props;

  const onLoadingEvents = events
    .filter((event) => event.eventId.includes(`${id}_screen_loading_event`))
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
      ${proceduresCode};
      return async () => {
        ${event.code}
      }`
      );
    });
  const handleOnLoading = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    ${initVariables}
    return async () => {
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  useEffect(() => {
    const loading = setTimeout(
      () => handleOnLoading(onLoadingEvents, navigate, codeGen)(),
      100
    );
    return () => {
      clearTimeout(loading);
    };
  });

  const onClickEvents = events
    .filter((event) => event.eventId.includes(`${id}_screen_click_event`))
    .map((event) => {
      return new Function(
        "navigate",
        "codeGen",
        `
        ${proceduresCode};
        return async () => {
          ${event.code}
        }`
      );
    });

  const handleOnClick = new Function(
    "events",
    "navigate",
    "codeGen",
    `
    return async (e)=>{
      e.stopPropagation();
      events.forEach((f) => f(navigate, codeGen)())
    }`
  );

  return (
    <div
      id={`screen-container-${id}`}
      style={{
        width: property.style.layout.width,
        height: property.style.layout.height,
        backgroundColor: property.style.view.backgroundColor,
        overflow: "hidden",
      }}
      onPointerDown={handleOnClick(onClickEvents, navigate, codeGen)}
    >
      {props.children}
    </div>
  );
};

export default WrapComponent;
