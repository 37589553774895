/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Howl } from "howler";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { User } from "features/user";
import { HomePage } from "features/home";
import { TopTutorial } from "features/tutorial";
import { UniversalBtn } from "features/universal";
import { CoursePageRoutes } from "features/courses";
import { ParentSettingPage } from "features/parent";
import { DialogPage } from "features/top/DialogPage";
import { useInitialApp } from "features/top/useInitialApp";
import { CreatorTop, CreatorMain } from "features/creator";
import { NotificationDetail } from "features/notification";

export const ConfigContext = React.createContext<{
  bgmSound: Howl;
  audioContext: AudioContext;
} | null>(null);

export const AppIndexPage = () => {
  const { scale } = useScale();
  const location = useLocation();
  const { bgmSound, audioContext } = useInitialApp();
  const user = useSelector((state: RootState) => state.user.appUser);

  useEffect(() => {
    return () => {
      const course = location.pathname.split("/")[2];
      if (
        (/\/courses\/[a-zA-Z0-9]+\/stage\/\d\/step\/\d$/.test(
          location.pathname
        ) &&
          course === "algorithm") ||
        course === "tangible" ||
        course === "builder" ||
        course === "nishishinjuku" ||
        course === "takushoku_basic" ||
        course === "takushoku_advance" ||
        course === "ocha"
      ) {
        const canvas = document.getElementById(
          "app-canvas"
        ) as HTMLCanvasElement;
        if (canvas) {
          canvas.style.opacity = "0";
        }
      }
    };
  }, [location]);

  return (
    <ConfigContext.Provider value={{ bgmSound, audioContext }}>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={Slide}
        hideProgressBar={true}
        style={{
          zIndex: 9999999,
          left: "50%",
          transform: `translateX(-50%) scale(${scale})`,
          transformOrigin: "top",
        }}
      />
      {user.active && (
        <>
          <User />
          <DialogPage />
          <TopTutorial />
          <UniversalBtn />
        </>
      )}
      <ParentSettingPage />
      <NotificationDetail />
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={location.key || location.pathname + location.search}
          classNames="fade"
          timeout={400}
        >
          <Routes location={location}>
            <Route path="/" element={<HomePage />} />
            <Route path="/courses">{CoursePageRoutes}</Route>
            <Route path="/creator">
              <Route index element={<CreatorTop />} />
              <Route path=":id" element={<CreatorMain />} />
            </Route>
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </CSSTransition>
      </SwitchTransition>
    </ConfigContext.Provider>
  );
};
