/* eslint-disable no-new-func */
import { useState } from "react";
import produce from "immer";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import {
  CheckMark,
  AnswerMark,
} from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const {
    event,
    highlightBlock,
    setRunning,
    setSuccess,
    gameOver,
    setGameOver,
  } = props;
  const play = usePlaySound();
  const sound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/itemget.mp3`
  );
  const [done, setDone] = useState(true);
  const [clickedIds, setClickedIds] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(0);
  const targetEles = ["Fragaria", "Grape", "Fragaria", "Grape", "Fragaria"];
  const [result, setResult] = useState([false, false, false, false, false]);
  const handleEvent = (index: number) =>
    new Function(
      "sleep",
      "sound",
      "highlightBlock",
      "clickedIndex",
      "setClickedIndex",
      "targetEles",
      "setDone",
      "result",
      "setResult",
      "setRunning",
      "setSuccess",
      "setGameOver",
      event[index]
        ? event[index]
        : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
    );
  const handleClick = (index: number, imageId: number) => () => {
    if (!clickedIds.includes(imageId) && !gameOver && done) {
      play();
      setDone(false);
      setClickedIds(
        produce((draft) => {
          draft.push(imageId);
        })
      );
      handleEvent(index)(
        sleep,
        sound,
        highlightBlock,
        clickedIndex,
        setClickedIndex,
        targetEles,
        setDone,
        result,
        setResult,
        setRunning,
        setSuccess,
        setGameOver
      )(imageId);
    }
  };

  return (
    <div className="flex-col-center gap-4 mt-2">
      <div className="flex-row-center gap-8">
        <div className="flex-row-center gap-8">
          <div
            id="course-program-image-1"
            className="flex-col-center mt-16 cursor-pointer"
          >
            <img
              alt="*"
              onClick={handleClick(1, 1)}
              src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
              className="w-[100px] h-[100px] active:opacity-70"
            />
            <CheckMark id={1} />
          </div>
          <div
            id="course-program-image-2"
            className="flex-col-center cursor-pointer"
          >
            <img
              alt="*"
              onClick={handleClick(1, 2)}
              src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
              className="w-[100px] h-[100px] active:opacity-70"
            />
            <CheckMark id={2} />
          </div>
        </div>

        <div className="flex-row-center gap-8">
          <div className="flex-row-center gap-8">
            <div
              id="course-program-image-3"
              className="flex-col-center mt-2 cursor-pointer"
            >
              <img
                alt="*"
                onClick={handleClick(0, 3)}
                src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
                className="w-[100px] h-[100px] active:opacity-70"
              />
              <CheckMark id={3} />
            </div>
            <div
              id="course-program-image-4"
              className="flex-col-center mt-16 cursor-pointer"
            >
              <img
                alt="*"
                onClick={handleClick(0, 4)}
                src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
                className="w-[100px] h-[100px] active:opacity-70"
              />
              <CheckMark id={4} />
            </div>
          </div>

          <div className="flex-row-center gap-4">
            <div
              id="course-program-image-5"
              className="flex-col-center cursor-pointer"
            >
              <img
                alt="*"
                onClick={handleClick(0, 5)}
                src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
                className="w-[100px] h-[100px] active:opacity-70"
              />
              <CheckMark id={5} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row-center gap-2">
        <div
          id="course-program-border-1"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-6"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={1} />
        </div>
        <p
          id="course-program-arrow-1"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-2"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-7"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={2} />
        </div>
        <p
          id="course-program-arrow-2"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-3"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-8"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={3} />
        </div>
        <p
          id="course-program-arrow-3"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-4"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-9"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={4} />
        </div>
        <p
          id="course-program-arrow-4"
          className="text text-white !text-[30px] opacity-0"
        >
          ▶︎
        </p>
        <div
          id="course-program-border-5"
          className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-10"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={5} />
        </div>
      </div>
    </div>
  );
};
