import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import produce from "immer";
import * as echarts from "echarts";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import {
  IconMedal,
  IconCourseClear,
  IconCourseProgressBlock,
  IconCourseProgressEndBlock,
  IconCourseProgressStartBlock,
} from "features/user/assets";
import {
  selectAllProgressesProgram,
  selectAllProgressesAlgorithm,
} from "features/courses/coursesSlice";
import { Course } from "features/courses/types";

enum ChartType {
  TOTAL_CLEARED = "TOTAL_CLEARED",
  NEW_CLEARED = "NEW_CLEARED",
  AVG_TOTAL_CLEARED = "AVG_TOTAL_CLEARED",
}

const CourseCard = ({
  selected,
  handleSelectCourse,
  progress,
  children,
}: {
  selected: boolean;
  handleSelectCourse: () => void;
  progress: number;
  children: any;
}) => (
  <div
    className={`flex-col-el flex-center relative shrink-0 !box-content cursor-pointer rounded-full border-solid border-8 ${
      selected
        ? "scale-[1.3] border-green/100/40"
        : "scale-100 border-transparent"
    }`}
  >
    <div
      onClick={handleSelectCourse}
      className="flex-col-el relative shrink-0 w-[135px] h-[129px] cursor-pointer rounded-full bg-white"
      style={{
        filter:
          "drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12))",
      }}
    >
      {children}

      <div
        className="flex-row-el items-end shrink-0 absolute bottom-2 right-1"
        style={{
          filter:
            "drop-shadow(-1px 1px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 3px rgba(63, 74, 97, 0.25))",
        }}
      >
        <div
          className="flex-col-el flex-center w-[60px] h-[60px] rounded-full border-white border-solid border-[6px] z-10"
          style={{
            background: `conic-gradient(from 0deg at 50% 50%, #F9E532 0deg, rgba(249, 229, 50, 0.8) ${
              (progress * 360) / 100
            }deg, rgba(249, 229, 50, 0) ${
              (progress * 360) / 100
            }deg,  rgba(249, 229, 50, 0) 360deg), #DBD8DA`,
          }}
        >
          <div className="flex-row-el items-baseline">
            <p className="text-body text-white text-center text-[14px] leading-4">
              {progress}
            </p>
            <p className="text-body text-white text-center text-[12px] leading-4">
              %
            </p>
          </div>
          <p className="text-body text-white text-center text-[12px] leading-4">
            {I18n.t("MSG_COURSE_GAME_CLEAR")}
          </p>
        </div>

        <div className="flex-row-el w-[96px] h-[15px] p-[3px] bg-white rounded-[8px] overflow-hidden -ml-6">
          <div
            className="flex-row-el items-center w-full h-full rounded-[12px] overflow-hidden pl-2"
            style={{
              background: "linear-gradient(180deg, #8E979E 0%, #B3BEC8 100%)",
              boxShadow:
                "inset 3.03894e-16px 4.96296px 4.96296px rgba(137, 145, 152, 0.5), inset -3.03894e-16px -4.96296px 4.96296px rgba(184, 195, 206, 0.5)",
            }}
          >
            <div
              className={`flex-row-el w-full h-full rounded-r-[12px]`}
              style={{
                transform: `translateX(${progress - 100}%)`,
                background:
                  "linear-gradient(90deg, #F9E532 0%, #FAAF85 94.33%)",
                boxShadow:
                  "inset -1.33096e-16px -2.17363px 2.17363px #CABA29, inset 1.33096e-16px 2.17363px 2.17363px rgba(255, 255, 58, 0.5)",
              }}
            />
          </div>
          {progress === 100 && (
            <div className="flex-col-el absolute -right-2 top-4">
              <IconMedal />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

const CourseProgress = ({
  stage,
  finished,
}: {
  stage: number;
  finished: number;
}) => (
  <div
    className={`flex-row-el items-end ${stage % 2 === 1 ? "mr-4" : "ml-4"}`}
    style={{
      filter:
        "drop-shadow(-1px 1px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 3px rgba(63, 74, 97, 0.25))",
    }}
  >
    <div className="flex-col-el flex-center w-[48px] h-[48px] border-solid border-white border-[8px] rounded-full bg-danger -mr-8 z-10">
      <p className="text-body text-white text-[24px]">{stage}</p>
    </div>
    <div className="flex-col-el items-end">
      <div className="flex-row-el h-[20px] relative shrink-0 items-baseline bg-white px-3 pt-[1px] rounded-t-[16px]">
        <p className="text-body text-brown/60 text-[20px] leading-4">
          {finished}
        </p>
        <p className="text-body text-brown/60">/7</p>
      </div>

      <div className="flex-row-el items-center justify-end w-[184px] h-[28px] bg-white rounded-b-[13px] py-[6px] px-2">
        <div
          className="flex-row-el items-center w-[144px] h-full rounded-[8px] overflow-hidden"
          style={{
            background: "linear-gradient(180deg, #8E979E 0%, #B3BEC8 100%)",
            boxShadow:
              "inset 3.03894e-16px 4.96296px 4.96296px rgba(137, 145, 152, 0.5), inset -3.03894e-16px -4.96296px 4.96296px rgba(184, 195, 206, 0.5)",
          }}
        >
          {finished > 0 && (
            <div className="flex-row-el flex-center">
              <IconCourseProgressStartBlock />
            </div>
          )}

          {finished > 1 &&
            Array(finished - 1)
              .fill(0)
              .map((_, index) => (
                <div key={index} className="flex-row-el flex-center -ml-1">
                  <IconCourseProgressBlock />
                </div>
              ))}

          {finished === 7 && (
            <>
              <div className="flex-row-el flex-center -ml-1">
                <IconCourseProgressEndBlock />
              </div>
              <div className="flex-col-el absolute -right-5 top-2">
                <IconMedal />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

const CourseList = ({
  courseAlgorithmProgress,
  courseProgramProgress,
}: {
  courseAlgorithmProgress: number;
  courseProgramProgress: number;
}) => {
  const play = usePlaySound();
  const [selectedCourse, setSelectedCourse] = useState(Course.ALGORITHM);
  const courseProgramProgresses = useSelector(selectAllProgressesProgram);
  const courseAlgorithmProgresses = useSelector(selectAllProgressesAlgorithm);

  const handleSelectCourse = (course: Course) => () => {
    play();
    setSelectedCourse(course);
  };

  return (
    <>
      <div
        className="flex-col-el flex-center relative"
        style={{
          filter:
            "drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12))",
        }}
      >
        <div className="flex-col-el relative items-center bg-white shrink-0 w-[260px] rounded-[56px] pb-8 mr-4">
          <div
            className={`absolute ${
              selectedCourse === Course.ALGORITHM
                ? "top-[15%]"
                : selectedCourse === Course.PROGRAM
                ? "top-[50%]"
                : "top-[80%]"
            } w-0 h-0 border-solid border-y-transparent border-l-white`}
            style={{
              right: -30,
              borderRightWidth: 0,
              borderLeftWidth: "32px",
              borderTopWidth: "16px",
              borderBottomWidth: "16px",
            }}
          />

          <div className="flex-row-el relative shrink-0 h-[34px] items-center mt-4 mb-2 px-2">
            <p className="text-body text-cerulean text-[20px]">
              {I18n.t("MSG_USER_PROFILE_PROGRESS_COURSE_CLEARED_TITLE")}
            </p>
          </div>

          <div className="flex-col-el flex-center gap-2">
            {selectedCourse === Course.ALGORITHM &&
              courseAlgorithmProgresses.map((progress, index) => (
                <CourseProgress
                  key={index}
                  stage={index + 1}
                  finished={progress.stage.finished}
                />
              ))}

            {selectedCourse === Course.PROGRAM &&
              courseProgramProgresses.map((progress, index) => (
                <CourseProgress
                  key={index}
                  stage={index + 1}
                  finished={progress.stage.finished}
                />
              ))}
          </div>
        </div>
      </div>

      <div className="flex-col-el flex-center mx-10 gap-8 mt-8">
        <CourseCard
          selected={selectedCourse === Course.ALGORITHM}
          handleSelectCourse={handleSelectCourse(Course.ALGORITHM)}
          progress={courseAlgorithmProgress}
        >
          <img
            alt="アルゴリズム学ぶソフト"
            src={`${Constants.assetHost}/assets/images/course_soft_algorithm.png`}
            className="w-[77px] h-[95px] absolute right-[5px] top-[15px]"
          />
        </CourseCard>

        <CourseCard
          selected={selectedCourse === Course.PROGRAM}
          handleSelectCourse={handleSelectCourse(Course.PROGRAM)}
          progress={courseProgramProgress}
        >
          <img
            alt="プログラム学ぶソフト"
            src={`${Constants.assetHost}/assets/images/course_soft_program.png`}
            className="w-[77px] h-[95px] absolute right-[5px] top-[15px]"
          />
        </CourseCard>

        <div className="flex-col-el w-[135px] h-[129px] bg-transparent" />
      </div>
    </>
  );
};

const generateChartOption = (
  periodSeries: string[], // MM-DD
  totalClearedSeries: number[],
  newClearedSeries: number[],
  newClearedAvgValue?: number
) => {
  return {
    animation: false,
    grid: {
      left: "3%",
      right: "4%",
      bottom: "120px",
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: true,
        data: periodSeries,
        splitArea: {
          show: true,
          areaStyle: {
            color: ["rgba(148, 196, 247, 0)", "rgba(112, 177, 244, 0.15)"],
          },
        },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: {
          interval: 0, // https://stackoverflow.com/questions/44076557/echarts-how-to-show-all-axis-labels
          formatter: (value: string) => {
            const date = value.split("-");
            return `{month|${Number(date[0])}/}{day|${date[1]}}`;
          },
          rich: {
            month: {
              fontSize: 12,
              color: "#3F4A61",
              verticalAlign: "top",
              fontFamily: "MPLUSRounded1c-Bold",
            },
            day: {
              fontSize: 24,
              color: "#3F4A61",
              fontFamily: "MPLUSRounded1c-Bold",
            },
          },
          show: true,
          margin: 45,
          padding: [18, 0, 0, 0],
          width: 56,
          height: 32,
          backgroundColor: {
            image: `${Constants.assetHost}/assets/images/user_page_axis_label_bg.png`,
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        max: function (value) {
          return value.max / 0.7;
        },
        axisTick: { show: false },
      },
      {
        show: false,
        max: function (value) {
          return value.max / 0.7;
        },
        axisTick: { show: false },
      },
      {
        show: false,
        max: function (value) {
          return value.max / 0.2;
        },
        axisTick: { show: false },
      },
    ],
    series: [
      {
        type: "bar",
        barWidth: "98%",
        itemStyle: { color: "#7cc148" },
        data: totalClearedSeries,
      },
      {
        type: "line",
        yAxisIndex: 1,
        data: newClearedSeries,
        lineStyle: { color: "#F9E532", width: 8 },
        label: {
          show: true,
          position: "inside",
          formatter: (params: Object) =>
            params["dataIndex"] === newClearedSeries.length - 1
              ? `{latest|${params["value"]}}`
              : `{normal|${params["value"]}}`,
          rich: {
            latest: {
              fontSize: 32,
              color: "white",
              fontFamily: "MPLUSRounded1c-Bold",
            },
            normal: {
              fontSize: 24,
              color: "white",
              fontFamily: "MPLUSRounded1c-Bold",
            },
          },
        },
        labelLayout(params: Object) {
          if (params["dataIndex"] === newClearedSeries.length - 1) {
            return {
              x: params["labelRect"].x,
              y: params["rect"].y + params["rect"].height / 2 + 5,
            };
          }
        },
        showSymbol: true,
        symbolSize: (value: number, params: Object) => {
          if (params["dataIndex"] === newClearedSeries.length - 1) {
            return [90, 90];
          } else {
            return [56, 48];
          }
        },
        symbol: (value: number, params: Object) => {
          if (params["dataIndex"] === newClearedSeries.length - 1) {
            return `image://${Constants.assetHost}/assets/images/user_page_course_clear_icon_latest.png`;
          } else {
            return `image://${Constants.assetHost}/assets/images/user_page_course_clear_icon.png`;
          }
        },
      },
      {
        type: "line",
        yAxisIndex: 2,
        lineStyle: { opacity: 0 },
        showSymbol: false,
        markLine: {
          symbol: "none",
          label: { show: false },
          data: [{ type: "max" }],
          lineStyle: {
            width: 12,
            cap: "round",
            type: [0, 20],
            color: "#F9E532",
          },
        },
        data: periodSeries.map(() => newClearedAvgValue),
      },
    ],
  };
};

export const UserProgressCourse = ({ selected }: { selected: boolean }) => {
  const play = usePlaySound();
  const ref = useRef<HTMLDivElement>(null);
  const [render, setRender] = useState(false);
  const [chartTypes, setChartTypes] = useState<ChartType[]>([
    ChartType.TOTAL_CLEARED,
    ChartType.NEW_CLEARED,
  ]);
  const [chart, _setChart] = useState<echarts.ECharts | null>(null);
  const chartRef = useRef(chart);
  const setChart = (chart: echarts.ECharts) => {
    _setChart(chart);
    chartRef.current = chart;
  };

  const courseAlgorithmSummary = useSelector(
    (state: RootState) => state.courses.progresses.algorithm.summary
  );
  const courseProgramSummary = useSelector(
    (state: RootState) => state.courses.progresses.program.summary
  );
  const history = useSelector((state: RootState) => state.user.history);

  useEffect(() => {
    setTimeout(() => setRender(true), 1000);
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      const option = generateChartOption(
        history.period,
        chartTypes.includes(ChartType.TOTAL_CLEARED)
          ? history.course.total.day
              .map((value) => value.finished)
              .concat([
                courseAlgorithmSummary.finished + courseProgramSummary.finished,
              ])
          : [],
        chartTypes.includes(ChartType.NEW_CLEARED)
          ? history.course.new.day
              .map((value) => value.finished)
              .concat([
                courseAlgorithmSummary.finished +
                  courseProgramSummary.finished -
                  history.course.total.day[history.course.total.day.length - 1]
                    .finished,
              ])
          : []
      );
      chartRef.current.setOption(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTypes.length]);

  useEffect(() => {
    if (ref.current && history && !chartRef.current && selected) {
      const chart = echarts.init(ref.current, null, {
        renderer: "svg",
        width: 530,
        height: 600,
      });
      chart.showLoading();
      const option = generateChartOption(
        history.period,
        chartTypes.includes(ChartType.TOTAL_CLEARED)
          ? history.course.total.day
              .map((value) => value.finished)
              .concat([
                courseAlgorithmSummary.finished + courseProgramSummary.finished,
              ])
          : [],
        chartTypes.includes(ChartType.NEW_CLEARED)
          ? history.course.new.day
              .map((value) => value.finished)
              .concat([
                courseAlgorithmSummary.finished +
                  courseProgramSummary.finished -
                  history.course.total.day[history.course.total.day.length - 1]
                    .finished,
              ])
          : []
      );
      chart.setOption(option);
      chart.hideLoading();
      setChart(chart);
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
      }
      setChart(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, history, selected]);

  const handleSelectChartType = (chartType: ChartType) => () => {
    play();
    setChartTypes(
      produce((draft) => {
        const targetIndex = draft.findIndex((type) => type === chartType);
        if (targetIndex !== -1) {
          draft.splice(targetIndex, 1);
        } else {
          draft.push(chartType);
        }
      })
    );
  };

  return (
    <div
      className="flex-col-el flex-1 w-full h-full items-start justify-start overflow-hidden z-10 mt-3"
      style={{ display: !selected && "none" }}
    >
      <div className="flex-row-el flex-center relative w-full h-full overflow-hidden">
        <img
          alt="ゲームの背景"
          src={`${Constants.assetHost}/assets/images/bg_user_page_history.png`}
          className="w-full h-full absolute top-0 left-0"
        />

        <div className="flex-row-el flex-center w-[530px] h-[600px] z-10">
          <div ref={ref} className="flex-row-el w-full h-full" />
        </div>

        <div className="flex-col-el flex-center relative gap-4 w-[104px] mb-60">
          <div
            className="flex-col-el flex-center relative cursor-pointer transition"
            style={{
              transform: `translateY(${
                chartTypes.includes(ChartType.TOTAL_CLEARED) ? 3 : 0
              }px)`,
            }}
            onClick={handleSelectChartType(ChartType.TOTAL_CLEARED)}
          >
            <div className="flex-col-el z-10">
              <IconCourseClear />
            </div>
            <div
              className={`flex-col-el flex-center rounded-full w-[96px] h-[96px] -mt-3 transition ${
                chartTypes.includes(ChartType.TOTAL_CLEARED)
                  ? "bg-yellow/100"
                  : "bg-white"
              }`}
              style={{
                boxShadow: chartTypes.includes(ChartType.TOTAL_CLEARED)
                  ? "0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 4px 1px rgba(0, 0, 0, 0.14), 0px 1px 8px 3px rgba(0, 0, 0, 0.12)"
                  : "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
              }}
            >
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t("MSG_USER_PROFILE_PROGRESS_COURSE_LEVEL_TITLE")}
              </p>
              <p className="text-body whitespace-pre text-[32px] leading-8">
                {courseAlgorithmSummary.finished +
                  courseProgramSummary.finished}
              </p>
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t("MSG_COURSE_GAME_CLEAR")}
              </p>
            </div>
          </div>

          <div
            className="flex-col-el flex-center relative cursor-pointer transition"
            style={{
              transform: `translateY(${
                chartTypes.includes(ChartType.NEW_CLEARED) ? 3 : 0
              }px)`,
            }}
            onClick={handleSelectChartType(ChartType.NEW_CLEARED)}
          >
            <div className="flex-col-el z-10">
              <IconCourseClear />
            </div>
            <div
              className={`flex-col-el flex-center text-textcolor/black rounded-full w-[96px] h-[96px] -mt-3 transition ${
                chartTypes.includes(ChartType.NEW_CLEARED)
                  ? "bg-yellow/100"
                  : "bg-white"
              }`}
              style={{
                boxShadow: chartTypes.includes(ChartType.NEW_CLEARED)
                  ? "0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 4px 1px rgba(0, 0, 0, 0.14), 0px 1px 8px 3px rgba(0, 0, 0, 0.12)"
                  : "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
              }}
            >
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t("MSG_USER_PROFILE_PROGRESS_NEW_TITLE")}
              </p>
              <p className="text-body whitespace-pre text-[32px] leading-8">
                {courseAlgorithmSummary.finished +
                  courseProgramSummary.finished -
                  history.course.total.day[history.course.total.day.length - 1]
                    .finished}
              </p>
              <p className="text-body whitespace-pre text-[16px] leading-5">
                {I18n.t("MSG_COURSE_GAME_CLEAR")}
              </p>
            </div>
          </div>
        </div>

        <div className="flex-row-el flex-center relative w-[530px] h-full z-50">
          {render ? (
            <CourseList
              courseAlgorithmProgress={Math.floor(
                (courseAlgorithmSummary.finished /
                  courseAlgorithmSummary.total) *
                  100
              )}
              courseProgramProgress={Math.floor(
                (courseProgramSummary.finished / courseProgramSummary.total) *
                  100
              )}
            />
          ) : (
            <div className="flex-col-center w-[100px] h-[100px]">
              <div className="dots-loader" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
