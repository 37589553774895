/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useRef, useState, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Collapse } from "@mui/material";
import { AppDispatch, RootState } from "app/store";
import { Spin } from "common/elements";
import { usePlaySound } from "common/utils";
import { AppUser } from "features/user/types";
import { getChatRoomMessages } from "features/user/api";
import { actions, selectChatRoomMessageSyncById } from "features/user/slice";
import { ChatRoom, ChatRoomMessage, ChatRoomType } from "features/user/types";
import { TalkChat } from "./TalkChat";
import { InputBar } from "./InputBar";
import { ContactChat } from "./ContactChat";
import { NotificationChat } from "./NotificationChat";
import { ContactActionBar } from "./ContactActionBar";

export const ChatDetail = memo(
  (props: {
    user: AppUser;
    chatRoom: ChatRoom;
    chatRoomMessages: ChatRoomMessage[];
  }) => {
    if (!props.chatRoom) return <></>;

    switch (props.chatRoom.type) {
      case ChatRoomType.CONTACT:
        return <ContactChat {...props} />;
      case ChatRoomType.NOTIFICATION:
        return <NotificationChat {...props} />;
      case ChatRoomType.TALK:
        return <TalkChat {...props} />;
      default:
        return <></>;
    }
  },
  (preProps, nextProps) =>
    preProps.chatRoomMessages.length === nextProps.chatRoomMessages.length
);

export const Chat = memo(
  (props: {
    user: AppUser;
    selected: boolean;
    chatRoom: ChatRoom;
    messages: ChatRoomMessage[];
  }) => {
    const play = usePlaySound();
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();
    const [refresh, setRefresh] = useState(false);
    const isRefreshed = useSelector(
      (state: RootState) => state.user.isRefreshed
    );
    const [rendered, setRendered] = useState(false);
    const { user, selected, chatRoom, messages } = props;
    const chatRoomMessageSync = useSelector((state: RootState) =>
      selectChatRoomMessageSyncById(state, chatRoom.id)
    );

    useEffect(() => {
      if (ref.current && selected) {
        if (!rendered) {
          setRendered(true);
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }
    }, [selected]);

    useEffect(() => {
      if (ref.current) {
        if (isRefreshed) {
          ref.current.scrollTop = 200;
          dispatch(actions.updateIsRefreshed({ refreshed: false }));
        } else {
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }
    }, [user.uid, ref.current, messages.length]);

    const handleRefresh = () =>
      new Promise((resolve) => {
        dispatch(actions.updateIsRefreshed({ refreshed: true }));
        dispatch(
          getChatRoomMessages({
            roomId: chatRoom.id,
            lastMessageSyncAt: chatRoomMessageSync.lastSyncAt,
            messageCount: 5,
          })
        )
          .then(unwrapResult)
          .then(() => {
            resolve(0);
          });
      });

    const handleClickRefresh = () => {
      play();
      setRefresh(true);
      setTimeout(() => {
        dispatch(actions.updateIsRefreshed({ refreshed: true }));
        dispatch(
          getChatRoomMessages({
            roomId: chatRoom.id,
            lastMessageSyncAt: chatRoomMessageSync.lastSyncAt,
            messageCount: 5,
          })
        )
          .then(unwrapResult)
          .then(() => {
            setRefresh(false);
          });
      }, 1000);
    };

    return (
      <div className="flex-col-el relative w-full h-full bg-white rounded-[8px] border-solid border-orange/alt border-[1px]">
        <div className="w-full">
          <Collapse in={refresh} sx={{ width: "100%" }}>
            <div
              className="flex-col-center w-full h-[100px]"
              style={{ display: !refresh && "none" }}
            >
              <Spin />
            </div>
          </Collapse>
        </div>

        <PullToRefresh
          className="flex-col-el w-full h-full bg-gray/20"
          onRefresh={handleRefresh}
          pullingContent={""}
          refreshingContent={<Spin />}
        >
          <div
            ref={ref}
            className="flex-col-view w-full h-full pt-2 bg-white invisible-scrollbar"
          >
            <button
              className="bg-transparent border-none my-1"
              onClick={handleClickRefresh}
            >
              <p className="text text-blue/100">もっとみる</p>
            </button>

            <ChatDetail
              user={user}
              chatRoom={chatRoom}
              chatRoomMessages={messages}
            />
          </div>
        </PullToRefresh>

        <ContactActionBar visible={chatRoom.type === ChatRoomType.CONTACT} />
        <InputBar visible={chatRoom.type === ChatRoomType.TALK} />
      </div>
    );
  },
  (preProps, nextProps) =>
    preProps.messages.length === nextProps.messages.length &&
    preProps.selected === nextProps.selected &&
    preProps.user.uid === nextProps.user.uid
);
