import { SVGProps } from "react";

export const IconVerticalArrowPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 24.596c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12s-12 5.373-12 12c0 6.628 5.373 12 12 12Z"
      fill="#FDA3A0"
    />
    <path
      d="M12 6.793v12.19M6 12.888h12"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.808 47.007v-16M17.903 36.35l-5.746-5.657L6.41 36.35"
      stroke="#FDA3A0"
      strokeWidth={4}
    />
  </svg>
);
