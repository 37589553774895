import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { ProjectType } from "features/creator/types";
import { selectAllProjects } from "features/creator/slice";
import { UserProjectPanel } from "./UserProjectPanel";
import { SharedProjectPanel } from "./SharedProjectPanel";

export const ProjectPanel = () => {
  const { scale } = useScale();
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.author_uid === user.active.uid &&
          !project.deleted_at &&
          project.type === ProjectType.BASIC
      ),
    [projects, user]
  );
  const sharedProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.published &&
          !project.deleted_at &&
          project.type === ProjectType.BASIC
      ),
    [projects]
  );

  return (
    <div
      className="flex-col-el z-50"
      style={{ width: 242 * scale, height: 798 * scale }}
    >
      <div
        className="flex-col-el w-[242px] origin-top-left"
        style={{ transform: `scale(${scale})` }}
      >
        <div className="flex-col-el mt-[15px]">
          <UserProjectPanel user={user.active} projects={userProjects} />
          <SharedProjectPanel user={user.active} projects={sharedProjects} />
        </div>
      </div>
    </div>
  );
};
