/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { css } from "@emotion/css";
import { RootState } from "app/store";
import { useScale, usePlaySound } from "common/utils";
import { BASE_WIDTH, BASE_HEIGHT } from "common/constant";
import { CreatorOptions } from "features/creator/types";
import {
  RenderScreen,
  RenderScreenComponent,
} from "features/creator/componentPreview/RenderComponent";
import { actions, selectScreenById } from "features/creator/slice";
import { MouseIcon, IconMinimize, IconMaximize } from "features/creator/assets";

export const ComponentPreview = (props: { options: CreatorOptions }) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const { margin } = props.options;
  const { width, scale } = useScale();
  const ref = useRef<HTMLDivElement>(null);
  const fullRef = useRef<HTMLDivElement>(null);
  const [minimize, setMinimize] = useState(false);
  const [fullScreen, _setFullScreen] = useState(false);
  const setFullScreen = (isFullScreen: boolean) => {
    dispatch(actions.handleComponentListCollapse(!isFullScreen));
    dispatch(actions.handleMaterialPanelCollapse(true));
    _setFullScreen(isFullScreen);
  };
  const [fullScreenListWidth, setFullScreenListWidth] = useState(0);

  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedScreen = useSelector((state: RootState) =>
    selectScreenById(state, selectedScreenId)
  );
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const collapse = useSelector(
    (state: RootState) => state.creator.panelCollapse.componentList
  );

  const ITEM_WIDTH = minimize ? 71 : 202;
  const ITEM_MARGIN = margin - 4;
  const ITEM_BORDER = 0;
  const ITEM_HEIGHT = ITEM_WIDTH / (BASE_WIDTH / BASE_HEIGHT);
  const ITEM_NAME_HEIGHT = 30;
  const ITEM_NAME_MARGIN_TOP = 8;

  const LIST_ITEM_WIDTH = 90;
  const LIST_ITEM_MARGIN = 12;
  const LIST_ITEM_BORDER = 6;
  const LIST_ITEM_HEIGHT = LIST_ITEM_WIDTH / (BASE_WIDTH / BASE_HEIGHT);
  const LIST_ITEM_NAME_HEIGHT = 104 - LIST_ITEM_HEIGHT;

  const HEADER_HEIGHT = 32;
  const PANEL_HEADER_HEIGHT = 32;
  const COMPONENT_BORDER = 4;
  const COMPONENT_WIDTH =
    ITEM_WIDTH + ITEM_MARGIN * 2 + ITEM_BORDER * 2 + COMPONENT_BORDER * 2;
  const COMPONENT_HEIGHT =
    ITEM_HEIGHT + ITEM_BORDER * 2 + ITEM_NAME_HEIGHT + ITEM_MARGIN * 1.2;

  const WIDTH_OFFSET = COMPONENT_WIDTH + margin + 100;
  const COMPONENT_LIST_CONTENTS_HEIGHT = 550 * scale;

  useEffect(() => {
    _setFullScreen(!collapse);
  }, [collapse]);

  useEffect(() => {
    if (fullScreen && fullRef.current) {
      const width = fullRef.current.clientWidth;
      const count = Math.floor(
        width / (LIST_ITEM_WIDTH + LIST_ITEM_MARGIN * 2 + LIST_ITEM_BORDER * 2)
      );
      setFullScreenListWidth(
        (LIST_ITEM_WIDTH + LIST_ITEM_MARGIN * 2 + LIST_ITEM_BORDER * 2) * count
      );
    }
  }, [fullScreen]);

  useEffect(() => {
    if (selectedComponentId === selectedScreenId) {
      ref.current.scrollLeft = 0;
    } else {
      const selectedComponentIndex =
        selectedScreen.children.length -
        selectedScreen.childrenOrder.findIndex(
          (componentId) => componentId === selectedComponentId
        );
      ref.current.scrollLeft =
        selectedComponentIndex * (COMPONENT_WIDTH - COMPONENT_BORDER * 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponentId, selectedScreen, minimize]);

  const handleMinimize = () => {
    play();
    setFullScreen(false);
    setMinimize(!minimize);
  };

  const handleComponentTabClick = (id: string, isMove?: boolean) => () => {
    play();
    if (selectedComponentId === id) {
      if (!isMove) {
        setFullScreen(!fullScreen);
      }
    } else {
      dispatch(
        actions.updateSelectedComponentId({
          screenId: selectedScreenId,
          id: id,
        })
      );
    }
  };

  const handleFullScreenComponentTabClick = (id: string) => () => {
    play();
    handleComponentTabClick(id)();
    setFullScreen(false);
  };

  return (
    <>
      <div
        className="flex-col-view z-[1002] !absolute bottom-0 pointer-events-none"
        style={{
          left: margin * scale,
          bottom: margin * scale,
        }}
      >
        <div
          id="component-list-panel"
          className="flex-col-center !box-content"
          style={{
            width: COMPONENT_WIDTH * scale,
            height: (COMPONENT_HEIGHT + PANEL_HEADER_HEIGHT + margin) * scale,
          }}
        >
          <div
            className="flex-col-center transition-transform duration-500 drop-shadow-normal_show"
            style={{
              width: COMPONENT_WIDTH,
              height: COMPONENT_HEIGHT + PANEL_HEADER_HEIGHT + margin,
              transform: `scale(${scale})`,
            }}
          >
            <div
              className="flex-col-center w-[24px] h-[24px] !absolute z-10 pointer-events-auto cursor-pointer"
              style={{ left: margin, bottom: margin }}
              onClick={handleMinimize}
            >
              {minimize ? <IconMaximize /> : <IconMinimize />}
            </div>

            <div className="flex-row-center w-full h-[32px] bg-gray2/60 rounded-t-[12px]">
              <p className="flex-row-center text text-white !text-[16px] !leading-[18px]">
                <span className="inline-block w-[24px] h-[24px] mr-[4px]">
                  <MouseIcon />
                </span>
                {!minimize && I18n.t("MSG_CREATOR_COMPONENT_LIST_HEADER")}
              </p>
            </div>

            <div
              className="flex-row-view w-full bg-gray2/20 overflow-hidden border-solid border-white border-[4px] !border-t-0 rounded-b-[16px]"
              style={{ height: COMPONENT_HEIGHT + margin }}
            >
              <div
                ref={ref}
                className="flex-row-view invisible-scrollbar"
                style={{ width: ITEM_WIDTH + ITEM_MARGIN }} // fix safari bug
              >
                <RenderScreen
                  color={false}
                  minimize={minimize}
                  screen={selectedScreen}
                  itemWidth={ITEM_WIDTH}
                  itemHeight={ITEM_HEIGHT}
                  itemMargin={ITEM_MARGIN}
                  itemBorder={ITEM_BORDER}
                  nameHeight={ITEM_NAME_HEIGHT}
                  nameMarginTop={ITEM_NAME_MARGIN_TOP}
                  selectedComponentId={selectedComponentId}
                  onClick={handleComponentTabClick}
                />

                {selectedScreen.childrenOrder
                  .map((id) => id)
                  .reverse()
                  .map((id) => (
                    <RenderScreenComponent
                      key={id}
                      id={id}
                      color={false}
                      minimize={minimize}
                      itemWidth={ITEM_WIDTH}
                      itemHeight={ITEM_HEIGHT}
                      itemMargin={ITEM_MARGIN}
                      itemBorder={ITEM_BORDER}
                      nameHeight={ITEM_NAME_HEIGHT}
                      nameMarginTop={ITEM_NAME_MARGIN_TOP}
                      selectedComponentId={selectedComponentId}
                      onClick={handleComponentTabClick}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {fullScreen && (
        <div
          className="flex-row-view z-[1002] !absolute !box-border rounded-xl bg-gray2/20 border-white border-solid border-4"
          style={{
            left: (WIDTH_OFFSET - 76) * scale,
            bottom: (margin / 2) * scale,
            width: width - WIDTH_OFFSET * scale,
            height: COMPONENT_LIST_CONTENTS_HEIGHT,
            padding: 24 * scale,
            filter:
              "drop-shadow(-1px 0px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 1px rgba(63, 74, 97, 0.25))",
          }}
        >
          <div
            className="absolute bottom-[20%] w-0 h-0 border-solid !border-l-0 border-y-transparent border-r-white"
            style={{ left: -20 * scale, borderWidth: 20 * scale }}
          >
            <div
              className="absolute w-0 h-0 border-solid !border-l-0 border-y-transparent border-r-gray2/20"
              style={{
                left: 5,
                top: -30 * scale,
                borderWidth: 30 * scale,
              }}
            />
          </div>

          <div className="flex-col-el w-full">
            <div
              className="flex-row-view w-full bg-gray2/60"
              style={{
                height: HEADER_HEIGHT * scale,
                borderTopLeftRadius: 12 * scale,
                borderTopRightRadius: 12 * scale,
              }}
            >
              <div
                className="flex-row-view items-center origin-top-left"
                style={{ height: HEADER_HEIGHT, transform: `scale(${scale})` }}
              >
                <p className="text !text-left text-white !ml-[16px]">
                  {I18n.t("MSG_CREATOR_MENU_COMPONENT_PREVIEW_TITLE", {
                    name: selectedScreen.name,
                  })}
                </p>
              </div>
            </div>

            <div
              className={`flex-row-view bg-white rounded-b-[16px] origin-top-left ${css`
                height: calc((100% - ${HEADER_HEIGHT * scale}px) / ${scale});
                width: calc(100% / ${scale});
              `}`}
              style={{
                transform: `scale(${scale})`,
              }}
            >
              <div
                ref={fullRef}
                className="flex-row-el items-start justify-center flex-1 py-4  visible-scrollbar"
              >
                {fullScreenListWidth > 0 && (
                  <div
                    className="flex-row-view items-center flex-wrap"
                    style={{ width: fullScreenListWidth }}
                  >
                    <RenderScreen
                      color={true}
                      minimize={false}
                      screen={selectedScreen}
                      itemWidth={LIST_ITEM_WIDTH}
                      itemHeight={LIST_ITEM_HEIGHT}
                      itemMargin={LIST_ITEM_MARGIN}
                      itemBorder={LIST_ITEM_BORDER}
                      nameHeight={LIST_ITEM_NAME_HEIGHT}
                      nameMarginTop={0}
                      selectedComponentId={selectedComponentId}
                      onClick={handleFullScreenComponentTabClick}
                    />

                    {selectedScreen.childrenOrder
                      .map((id) => id)
                      .reverse()
                      .map((id) => (
                        <RenderScreenComponent
                          key={id}
                          id={id}
                          color={true}
                          minimize={false}
                          itemWidth={LIST_ITEM_WIDTH}
                          itemHeight={LIST_ITEM_HEIGHT}
                          itemMargin={LIST_ITEM_MARGIN}
                          itemBorder={LIST_ITEM_BORDER}
                          nameHeight={LIST_ITEM_NAME_HEIGHT}
                          nameMarginTop={0}
                          selectedComponentId={selectedComponentId}
                          onClick={handleFullScreenComponentTabClick}
                        />
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
