import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { RootState } from "app/store";
import { actions } from "app/configSlice";
import { useFetchUserData } from "app/hook";
import { FadeTransition } from "common/elements";
import { btnDelay, useScale, handleError, usePlaySound } from "common/utils";
import { CheckMark } from "features/user/assets";
import { activeParentsKidsUser } from "./api";
import { UserType, AccountType } from "./types";

export const UserList = ({
  visible,
  close,
}: {
  visible: boolean;
  close: () => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const [selectUid, setSelectUid] = useState<string>();
  const { handleFetchParentsUser } = useFetchUserData();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const users = useSelector((state: RootState) => state.user.appUser);

  useEffect(() => {
    setSelectUid(users.active.uid);
    setTimeout(() => {
      const anchorEl = document.getElementById("user-page-user-list-btn");
      if (anchorEl) {
        const rect = anchorEl.getBoundingClientRect();
        setPosition({
          x: rect.left,
          y: rect.bottom,
        });
      }
    }, 300);
  }, [scale, visible]);

  const handleClose = () => {
    setTimeout(() => close(), 300);
  };

  const handleSelectUser = (uid: string) => () => {
    play();
    setSelectUid(uid);
  };
  const handleConfirm = () => {
    if (selectUid === users.active.uid) return;
    dispatch(actions.updateResign(true));
    btnDelay(async () => {
      try {
        const auth = getAuth();
        const response = await activeParentsKidsUser({
          parents_uid: users.main.uid,
          kids_uid: selectUid,
        });
        await signInWithCustomToken(auth, response.data.idToken);
        await handleFetchParentsUser({ uid: users.main.uid });
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_USER_PROFILE_CHANGE_USER_SUCCESS")}
            </p>
          </div>
        );
        close();
      } catch (error) {
        handleError(
          error,
          I18n.t("MSG_USER_PROFILE_CHANGE_USER_FAILED"),
          () => {
            dispatch(actions.updateResign(false));
          }
        );
      }
    });
  };

  return (
    <Dialog
      fullScreen
      open={visible && position.y !== 0}
      maxWidth={false}
      sx={{ zIndex: 99999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div className="flex-col-el flex-center relative w-full h-full">
        <div
          className="flex-col-el flex-center absolute top-0 left-0 origin-top-left"
          style={{
            transform: `translateX(${position.x}px) translateY(${position.y}px) translateZ(0) scale(${scale})`,
          }}
        >
          <div
            className="flex-col-el flex-center relative m-h-[900px]"
            style={{
              filter:
                "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
            }}
          >
            <div className="flex-row-el items-start w-full h-full p-2 relative border-solid border-white border-8 rounded-lg bg-beige/60">
              <div
                className="absolute w-0 h-0 border-solid border-x-transparent border-b-white"
                style={{
                  top: -40,
                  left: 0,
                  borderRightWidth: 40,
                  borderLeftWidth: 40,
                  borderTopWidth: 0,
                  borderBottomWidth: 40,
                }}
              />
              <div
                className="absolute w-0 h-0 border-solid border-x-transparent border-b-beige/60"
                style={{
                  top: -25,
                  left: 13,
                  borderRightWidth: 26,
                  borderLeftWidth: 26,
                  borderTopWidth: 0,
                  borderBottomWidth: 26,
                }}
              />

              <div className="flex-col-el w-[1050px]">
                <div className="flex-col-el items-start w-full h-[500px] p-3 bg-white rounded-xl">
                  <div className="flex-row-el flex-center relative w-full h-full">
                    <div className="flex-row-el flex-wrap w-full h-full bg-white items-start justify-start pt-2 gap-6 z-10 pointer-events-auto visible-scrollbar">
                      {users.users
                        .filter(
                          (user) =>
                            user.type !== UserType.FROZEN &&
                            user.account_type === AccountType.KIDS
                        )
                        .sort((a, b) => {
                          return dayjs(a.last_sign_in_time) <
                            dayjs(b.last_sign_in_time)
                            ? 1
                            : -1;
                        })
                        .map((user) => (
                          <div
                            key={user.uid}
                            onClick={handleSelectUser(user.uid)}
                            className="flex-col-center w-[120px] pointer-events-auto"
                          >
                            <div
                              className={`flex-col-center cursor-pointer bg-white rounded-full w-[80px] h-[80px] !box-content border-solid border-[6px] ${
                                user.uid === selectUid
                                  ? "border-green/100/40"
                                  : "border-transparent"
                              }`}
                              style={{
                                boxShadow:
                                  "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                              }}
                            >
                              {user.uid === users.active.uid && (
                                <div className="flex-col-el absolute -top-4 -right-4 z-50">
                                  <CheckMark />
                                </div>
                              )}
                              <div className="flex-col-center w-full h-full rounded-full overflow-hidden">
                                <img
                                  key={user.uid}
                                  alt="ユーザプロフィール画像"
                                  src={user.icon}
                                  className="w-full h-full object-contain"
                                />
                              </div>
                            </div>

                            <div className="flex-col-center text-gray2/100">
                              <p className="text-body text-[16px] w-full text-body-ellipsis">
                                {user.display_name}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="flex-row-el flex-center alpha mt-2 gap-3">
                  <button onClick={handleClose} className="btn btn-blue">
                    <div className="flex-row-center gap-1 mb-[3px]">
                      <span className="material-symbols-outlined text-[36px]">
                        close
                      </span>
                      <p className="text-body text-white text-font-size-body1">
                        {I18n.t("MSG_CLOSE_BTN")}
                      </p>
                    </div>
                  </button>

                  <button
                    disabled={selectUid === users.active.uid}
                    onClick={handleConfirm}
                    className="btn btn-primary"
                  >
                    <div className="flex-row-center gap-1 mb-[3px]">
                      <span className="material-symbols-outlined text-[36px] text-white">
                        check
                      </span>
                      <p className="text-body text-white text-font-size-body1">
                        {I18n.t("MSG_CONFIRM_BTN")}
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
