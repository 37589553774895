import { SVGProps } from "react";

export const AppUnselected = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={118}
    height={121}
    fill="none"
    {...props}
  >
    <g fill="#C9C4C8">
      <path
        fillRule="evenodd"
        d="m19.518 45.857-2.244 1.704 5.777 4.368 5.735-4.368-2.327-1.704-1.785 1.355V40.5h-3.289v6.724l-1.867-1.367Z"
        clipRule="evenodd"
      />
      <path d="M8.63 2.5a2 2 0 0 1 2-2h24.78a2 2 0 0 1 2 2v30.286a2 2 0 0 1-2 2H14.266a2 2 0 0 1-1.429-.601L9.201 30.47a2 2 0 0 1-.571-1.4V2.5Z" />
      <path
        fillRule="evenodd"
        d="M34.508 57.643H9.574a8 8 0 0 0-8 8V120.5H117.06V83.906c.55-.209.94-.74.94-1.363v-5.086c0-.623-.39-1.154-.94-1.363v-10.45a8 8 0 0 0-8-8H34.508v-.001Zm0 0v.095c0 .805-.653 1.457-1.457 1.457H17.144a1.457 1.457 0 0 1-1.456-1.457v-.095h18.82ZM1.457 76.267C.652 76.267 0 76.92 0 77.724v1.743c0 .805.652 1.457 1.457 1.457h.111v-4.657h-.111Zm0 7.76c-.805 0-1.457.652-1.457 1.456v1.743c0 .805.652 1.457 1.457 1.457h.111v-4.656h-.111Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
