import { SVGProps } from "react";

export const ProgressStep5 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={88} height={88} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.41.097c11.854.915 20.297 11.083 27.956 21.09C80.42 31.71 89.914 43.084 87.672 56.621c-2.316 13.985-13.948 23.49-25.936 28.754-10.938 4.803-22.678 2.48-33.49-2.659C16.67 77.213 4.675 69.962 1.212 56.606c-3.636-14.026 1.33-28.991 9.859-40.148C19.315 5.673 31.64-.888 44.41.098Z"
      fill={props.fill}
    />
  </svg>
);
