import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { IconPlayerPlay } from "common/assets";
import { IconRunPreviewBg } from "features/creator/assets";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("creator-display-run-preview");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 25 * scale,
        y: rect.y - 25 * scale,
        width: rect.width + 50 * scale,
        height: rect.width + 50 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent next={next} close={close}>
        <Title activeStep={5} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "アプリを うごかう ときは、"
              : "アプリをうごかうときは、"}
          </p>
          <div className="flex-row-el flex-center gap-2">
            <div className="flex-col-center w-[76px] h-[55px]">
              <div className="flex-col-center w-[152px] h-[109px] scale-50">
                <div className="flex-col-center w-full h-full">
                  <div className="flex-col-el flex-center w-full h-full">
                    <div className="flex-col-center w-full h-full">
                      <IconRunPreviewBg />
                    </div>
                  </div>

                  <div className="flex-col-el flex-center absolute -bottom-1 mt-0">
                    <div className="flex-col-center w-[56px] h-[56px]">
                      <IconPlayerPlay color="white" width={2} />
                    </div>
                    <p className="text-body text-white text-[28px]">
                      {I18n.t("MSG_CREATOR_RUN_APP_BTN")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              を
            </p>
          </div>
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja" ? "おします。" : "押します。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
