/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect, useCallback } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import * as rrweb from "rrweb";
import { nanoid } from "nanoid";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";
import { listenerHandler } from "@rrweb/types";
import { useQueryClient } from "@tanstack/react-query";
import { logEvent, getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  record,
  recordEventId,
  getUserConfig,
  getBannedWords,
  recordSessionId,
  getNotifications,
  updateUserConfig,
  uploadImageAsset,
  getKidsLatestVersion,
  getParentsUserAppConfig,
} from "app/api";
import { getEnv, actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import * as serviceWorkerRegistration from "app/serviceWorkerRegistration";
import { Asset, EventType, DialogType, AnalyticsEventType } from "app/types";
import Constants from "common/constant";
import { usePlaySound, insideInstalledApp } from "common/utils";
import {
  getUsers,
  getGroupUsers,
  getUserHistory,
  updateKidsLogin,
  getUserChatRooms,
  activeParentsKidsUser,
} from "features/user/api";
import {
  getCourses,
  getCourseMessages,
  getCourseProgresses,
} from "features/courses/api";
import { Course } from "features/courses/types";
import { getAppProjects } from "features/creator/api";
import { AppUser, UserType } from "features/user/types";
import { actions as userActions } from "features/user/slice";

export const useKidsLatestVersion = () => {
  const dispatch = useDispatch();
  return async (preloading: () => void) => {
    // https://stackoverflow.com/questions/61133389/service-worker-addeventlistener-beforeinstallprompt-will-not-run-a2hs-on-m
    // beforeinstallpromptは必ずservice worker登録する前に登録しないといけない！
    if (!window.ReactNativeWebView) {
      window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        window.deferredPrompt = event;
        if (!insideInstalledApp()) {
          dispatch(
            actions.updateEvent({
              type: EventType.BEFORE_INSTALL_PROMPT,
              value: true,
            })
          );
        }
      });
    }

    getKidsLatestVersion({ key: Constants.apiAuthKey })
      .then((result) => {
        if (
          result.version !==
            `${Constants.major}.${Constants.minor}.${Constants.buildNumber}` &&
          !window.location.hash
        ) {
          window.location.href =
            window.location.href +
            `#loaded?version=${result.version}&id=${nanoid()}`;

          if (!window.ReactNativeWebView && import.meta.env.PROD) {
            serviceWorkerRegistration.register({
              isUpdate: true,
            });
          } else {
            window.location.reload();
          }
        } else {
          if (!window.ReactNativeWebView && import.meta.env.PROD) {
            serviceWorkerRegistration.register({
              isUpdate: false,
            });
          }
          preloading();
        }
      })
      .catch((error) => {
        if (!window.ReactNativeWebView && import.meta.env.PROD) {
          serviceWorkerRegistration.register({
            isUpdate: false,
          });
        }
        console.error(error);
        preloading();
      });
  };
};

export const useDialog = () => {
  const dispatch = useDispatch();
  return useCallback(
    ({
      type,
      value,
      args,
    }: {
      type: DialogType;
      value: boolean;
      args?: { [key: string]: string };
    }) => {
      dispatch(
        actions.updateDialog({
          type,
          value,
          args,
        })
      );
    },
    []
  );
};

export const useUserConfig = () => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const activeUser = useSelector(
    (state: RootState) => state.user.appUser.active
  );

  return (key: string, value: string | boolean | number, sound?: boolean) => {
    if (!!sound) {
      play();
    }
    dispatch(actions.updateUserConfig({ key, value }));
    dispatch(updateUserConfig({ uid: activeUser.uid }));
  };
};

export const useFetchUserData = () => {
  const handleDialog = useDialog();
  const dispatch = useDispatch<AppDispatch>();

  const handleFetch = ({
    uid,
    requests,
  }: {
    uid: string;
    requests: Promise<any>[];
  }) =>
    new Promise((resolve, reject) =>
      Promise.all([
        dispatch(getUserHistory({ uid })).unwrap(),
        dispatch(getUserChatRooms({ uid })).unwrap(),
        dispatch(getAppProjects({ uid })).unwrap(),
        dispatch(getGroupUsers({ uid })).unwrap(),
        dispatch(getCourses({ uid })).unwrap(),
        dispatch(getCourseMessages()).unwrap(),
        dispatch(getCourseProgresses({ uid })).unwrap(),
        dispatch(getBannedWords()).unwrap(),
        dispatch(getNotifications()).unwrap(),
        dispatch(getUserConfig({ uid })).unwrap(),
        ...requests,
      ])
        .then(resolve)
        .catch(reject)
    );

  const handleParentsFetch = ({ uid }: { uid: string }) =>
    new Promise((resolve, reject) =>
      Promise.all([
        dispatch(getBannedWords()).unwrap(),
        dispatch(getNotifications()).unwrap(),
        dispatch(getCourseMessages()).unwrap(),
        dispatch(getUserConfig({ uid })).unwrap(),
        dispatch(getParentsUserAppConfig({ uid })),
      ])
        .then(resolve)
        .catch(reject)
    );

  const handleFetchParentsUser = ({
    uid,
    callback,
  }: {
    uid: string;
    callback?: () => void;
  }) =>
    dispatch(getUsers({ uid: uid, env: getEnv() }))
      .then(unwrapResult)
      .then(async (result) => {
        const auth = getAuth();
        var activeUser: AppUser = (result.users as AppUser[])
          .filter(
            (user) =>
              user.uid === auth.currentUser?.uid && user.type === UserType.SUB
          )
          .shift();

        if (result.users.length > 0) {
          if (!activeUser) {
            activeUser = (result.users as AppUser[])
              .filter((user) => user.type === UserType.SUB)
              .shift();
            const response = await activeParentsKidsUser({
              parents_uid: uid,
              kids_uid: activeUser.uid,
            });
            const credential = await signInWithCustomToken(
              auth,
              response.data.idToken
            );
            await auth.updateCurrentUser(credential.user);
          }

          Sentry.setUser({ id: uid, type: "parents" });

          dispatch(userActions.updateActiveUser(activeUser));
          updateKidsLogin({ kids_uid: activeUser.uid });
          handleFetch({
            uid: activeUser.uid,
            requests: [dispatch(getParentsUserAppConfig({ uid }))],
          })
            .then(() => {
              if (callback) {
                callback();
              }
              dispatch(actions.updateResign(false));
            })
            .catch((error) => {
              console.error(JSON.stringify(error));
              handleDialog({
                type: DialogType.ERROR_NETWORK,
                value: true,
              });
            });
        } else {
          handleParentsFetch({
            uid,
          })
            .then(() => {
              if (callback) {
                callback();
              }
              dispatch(actions.updateResign(false));
            })
            .catch((error) => {
              console.error(JSON.stringify(error));
              handleDialog({
                type: DialogType.ERROR_NETWORK,
                value: true,
              });
            });
        }
      })
      .catch((error) => {
        console.error(error);
        console.error(JSON.stringify(error));
        handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
      });

  const handleFetchKidsUser = ({
    uid,
    callback,
    fallback,
  }: {
    uid: string;
    callback?: () => void;
    fallback?: () => void;
  }) =>
    dispatch(getUsers({ uid: uid, env: getEnv() }))
      .then(unwrapResult)
      .then(async (result) => {
        if (result.users.length > 0) {
          Sentry.setUser({ id: uid, type: "kids" });
          handleFetch({
            uid: result.active.uid,
            requests: [],
          })
            .then(() => {
              if (callback) {
                callback();
              }
              dispatch(actions.updateResign(false));
            })
            .catch((error) => {
              console.error(JSON.stringify(error));
              handleDialog({
                type: DialogType.ERROR_NETWORK,
                value: true,
              });
            });
        } else {
          if (fallback) {
            fallback();
          }
        }
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
      });

  return { handleFetchParentsUser, handleFetchKidsUser };
};

export const useRecordEvent = () => {
  const supported = useSelector((state: RootState) => state.config.supported);
  try {
    if (supported) {
      const analytics = getAnalytics();
      return (type: AnalyticsEventType, params?: object) => {
        logEvent(analytics, type, {
          ...params,
          page_path: location.pathname,
          page_title: location.pathname,
        });
      };
    }
  } catch (error) {
    console.log("useRecordEvent error:");
    console.warn(error);
  }
};

export const usePickImageAsset = () => {
  const handleDialog = useDialog();
  const queryClient = useQueryClient();

  return async (
    file: File,
    uid: string,
    callback: (asset: Asset) => void,
    fallback: () => void
  ) => {
    const uuid = uuidv4();
    const filename = `${I18n.t(
      "MSG_CREATOR_COMPONENT_NAME_IMAGE"
    )}_${dayjs().format("YYYY_MM_DD_HH_mm_ss")}`;
    const object_key = `users/${uid}/assets/drawing/${uuid}.png`;
    const image = new Image();
    const objectUrl = URL.createObjectURL(file);
    image.onload = function () {
      handleDialog({ type: DialogType.LOADING, value: true });
      uploadImageAsset({
        uid: uid,
        object_key,
        filename,
        tags: `${I18n.t("MSG_CREATOR_COMPONENT_NAME_IMAGE")}`,
        fileBlob: file,
        metadata: {
          filename: encodeURIComponent(file.name),
          size: String(file.size),
          width: String(image.width),
          height: String(image.height),
        },
        contentType: file.type,
      })
        .then(async (response) => {
          queryClient.setQueryData(
            ["creator/getUserAssets", uid],
            (old: Asset[]) => [...old, response.data]
          );
          if (callback) {
            callback(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          if (fallback) {
            fallback();
          }
        })
        .finally(() =>
          handleDialog({ type: DialogType.LOADING, value: false })
        );
      URL.revokeObjectURL(objectUrl);
    };
    image.src = objectUrl;
  };
};

export const useCourseRecord = (
  course: Course,
  stageId: number,
  stepId: number
) => {
  const EVENT_TIMEOUT = 5000;
  const RESET_TIMEOUT = 30000;
  const [reset, setReset] = useState(false);
  const [start, setStart] = useState(false);
  const sessionId = useMemo(() => nanoid(), []);
  const user = useSelector((state: RootState) => state.user.appUser.active);

  useEffect(() => {
    let chunk = [];
    let events = [];
    let eventId: string;
    let timer: NodeJS.Timer;
    let stopFn: listenerHandler;
    if (start) {
      eventId = nanoid();
      recordEventId({
        uid: user.uid,
        sessionId,
        eventId,
        course,
        stageId,
        stepId,
      });

      stopFn = rrweb.record({
        emit(event) {
          events.push(event);
        },
        recordCanvas: true,
        sampling: {
          canvas: 2,
          mousemove: true,
        },
        dataURLOptions: {
          type: "image/webp",
          quality: 0.1,
        },
      });

      timer = setInterval(async () => {
        chunk = chunk.concat(events);
        events = [];
        record({
          eventId,
          events: chunk,
        })
          .then(() => {
            chunk = [];
          })
          .catch((e) => {
            console.error(e);
          });
      }, EVENT_TIMEOUT);
    }

    return () => {
      if (stopFn) {
        stopFn();
      }
      if (timer) {
        clearInterval(timer);
      }
      if (events.length > 0) {
        record({
          eventId,
          events: chunk.concat(events),
        });
      }
    };
  }, [start, reset, user.uid]);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (start) {
      timer = setInterval(() => {
        setReset((pre) => !pre);
      }, RESET_TIMEOUT);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [start]);

  const handleTrack = useCallback(async (start: boolean) => {
    if (start) {
      await recordSessionId({
        uid: user.uid,
        sessionId,
        course,
        stageId,
        stepId,
      });
    }
    setStart(start);
  }, []);

  return handleTrack;
};

export const useCustomEvent = () => {
  const handleCustomEvent = (name: string) => {
    rrweb.record.addCustomEvent("custom-click", { name });
  };
  return handleCustomEvent;
};
