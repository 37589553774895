import { SVGProps } from "react";

export const IconUserAddInvalid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1.5} y={1.5} width={49} height={49} rx={24.5} fill="#FEFDFE" />
    <circle
      cx={21.668}
      cy={19.333}
      stroke="#F2EFF1"
      strokeWidth={3}
      r={5.333}
    />
    <path
      d="M21.667 28.667C15 28.667 15 31.333 15 38h13.331c0-6.667 0-9.333-6.665-9.333ZM29.668 26h10.667M35 20.667v10.666"
      stroke="#F2EFF1"
      strokeWidth={3}
    />
    <rect
      x={1.5}
      y={1.5}
      width={49}
      height={49}
      rx={24.5}
      stroke="#F2EFF1"
      strokeWidth={3}
    />
  </svg>
);
