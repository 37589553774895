import { BlockType } from "features/builder/types";

export const ToolboxDef4 = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      toolboxitemid: "category",
      contents: [
        {
          kind: "block",
          type: BlockType.MATH_NUMBER,
        },
        {
          kind: "block",
          type: BlockType.COLOUR_PICKER_INTERNAL,
        },
      ],
    },
  ],
};
