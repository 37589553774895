import $ from "jquery";
import anime from "animejs";
import {
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BORDER_RADIUS_MAX,
  BORDER_RADIUS_MIN,
} from "common/components";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const getStampBorderColor = (id: string) =>
  $(`#stamp-${id}`).css("border-color");
export const setStampBorderColor = (id: string, value: string) =>
  $(`#stamp-${id}`).css("border-color", `${value}`);

export const getStampBorderWidth = (id: string): number =>
  Number($(`#stamp-${id}`).css("border-width").split("px")[0]) * 2;
export const setStampBorderWidth = (id: string, value: number) => {
  const container = $(`#stamp-container-${id}`);
  const stamp = $(`#stamp-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(stamp.css("border-width").split("px")[0]) * 2;

  let target: number;
  if (value > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (value < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = value;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  stamp.css("border-width", `${target / 2}`);
};
export const setStampBorderWidthByStep = (id: string, value: number) => {
  const container = $(`#stamp-container-${id}`);
  const stamp = $(`#stamp-${id}`);
  const currentWidth = container.width();
  const currentHeight = container.height();
  const currentBorderWidth =
    Number(stamp.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  container.css("width", currentWidth + delta);
  container.css("height", currentHeight + delta);
  stamp.css("border-width", `${target / 2}`);
};
export const setStampBorderWidthByTime = (
  id: string,
  time: number,
  value: number
) => {
  const stamp = $(`#stamp-${id}`);
  const currentBorderWidth =
    Number(stamp.css("border-width").split("px")[0]) * 2;
  const newBorderWidth = currentBorderWidth + value;
  let target: number;
  if (newBorderWidth > BORDER_SIZE_MAX) {
    target = BORDER_SIZE_MAX;
  } else if (newBorderWidth < BORDER_SIZE_MIN) {
    target = BORDER_SIZE_MIN;
  } else {
    target = newBorderWidth;
  }
  const delta = target - currentBorderWidth;
  const animation1 = anime({
    targets: `#stamp-container-${id}`,
    width: `+=${delta}`,
    height: `+=${delta}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#stamp-${id}`,
    borderWidth: value / 2,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
  });
  return Promise.all([animation1.finished, animation2.finished]);
};

export const getStampBorderRadius = (id: string): number =>
  Number($(`#stamp-${id}`).data("borderRadius"));
export const setStampBorderRadius = (id: string, value: number) => {
  const stamp = $(`#stamp-${id}`);
  const currentShapeWidth = stamp.width();
  const currentShapeHeight = stamp.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  stamp.data("borderRadius", target);
  stamp.css("border-radius", size * (target / 200));
};

export const setStampBorderRadiusByStep = (id: string, value: number) => {
  const newBorderRadius =
    Number($(`#stamp-${id}`).data("borderRadius")) + value;
  setStampBorderRadius(id, newBorderRadius);
};
export const setStampBorderRadiusByTime = (
  id: string,
  time: number,
  value: number
) => {
  const stamp = $(`#stamp-${id}`);
  const currentShapeWidth = stamp.width();
  const currentShapeHeight = stamp.height();
  const size = Math.min(currentShapeWidth, currentShapeHeight);

  let target: number;
  if (value > BORDER_RADIUS_MAX) {
    target = BORDER_RADIUS_MAX;
  } else if (value < BORDER_RADIUS_MIN) {
    target = BORDER_RADIUS_MIN;
  } else {
    target = value;
  }

  stamp.data("borderRadius", target);
  const animation = anime({
    targets: `#stamp-${id}`,
    borderRadius: size * (target / 200),
    autoplay: false,
    duration: time * 1000,
    easing: "easeInOutQuad",
  });
  animation.play();
  previewRemovedObserver(() => animation.pause());
  return animation.finished;
};
