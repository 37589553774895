import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { ComponentManager } from "common/components";
import { ScreensEntity } from "features/creator/types";
import { selectPropertyById } from "features/creator/slice";

export const RenderScreenComponent = (props: {
  id: string;
  color: boolean;
  minimize: boolean;
  itemWidth: number;
  itemHeight: number;
  itemMargin: number;
  itemBorder: number;
  nameHeight: number;
  nameMarginTop: number;
  selectedComponentId: string;
  onClick: (id: string) => () => void;
}) => {
  const {
    color,
    minimize,
    itemWidth,
    itemHeight,
    itemMargin,
    itemBorder,
    nameHeight,
    nameMarginTop,
    selectedComponentId,
    onClick,
  } = props;
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, props.id)
  );
  const { id, typeId, property } = propertyEntity;
  const ChildComponent = ComponentManager[typeId].component.DesignComponent;
  const width =
    property.style.layout.width +
    property.style.view.borderWidth +
    property.style.shadow.shadowRadius;
  const height =
    property.style.layout.height +
    property.style.view.borderWidth +
    property.style.shadow.shadowRadius;

  const COMPONENT_WIDTH = itemWidth;
  const COMPONENT_HEIGHT = itemHeight - 15;
  const componentScale =
    COMPONENT_WIDTH / width < COMPONENT_HEIGHT / height
      ? COMPONENT_WIDTH / width
      : COMPONENT_HEIGHT / height;

  return (
    <div
      className={`flex-col-view items-center justify-start cursor-pointer pointer-events-auto overflow-hidden rounded-[12px] snap-always snap-center ${
        color && "bg-beige/alt2"
      }`}
      onClick={onClick(id)}
      style={{
        marginTop: itemMargin,
        marginLeft: itemMargin,
        marginRight: itemMargin,
        width: itemWidth + itemBorder * 2,
        height: itemHeight + itemBorder * 2 + nameHeight,
      }}
    >
      <div
        className="flex-col-center !box-content cursor-pointer border-solid overflow-hidden rounded-[12px]"
        style={{
          borderWidth: itemBorder,
          borderColor:
            selectedComponentId === id
              ? "rgba(112, 177, 244, 0.4)"
              : "transparent",
          width: itemWidth,
          height: itemHeight,
        }}
      >
        <div
          style={{
            flexShrink: 0,
            backfaceVisibility: "hidden",
            WebkitBackfaceVisibility: "hidden",
            width: width,
            height: height,
            pointerEvents: "none",
            transform: `scale(${componentScale}) rotate(${property.style.transform.rotation}deg)`,
            willChange: "transform",
            opacity: property.style.view.opacity / 100,
            filter:
              property.style.shadow.shadowRadius !== 0 &&
              `drop-shadow(${property.style.shadow.shadowOffset.width}px ${property.style.shadow.shadowOffset.height}px ${property.style.shadow.shadowRadius}px ${property.style.shadow.shadowColor})`,
          }}
        >
          <ChildComponent property={property} id={id} />
        </div>
      </div>
      {!minimize && (
        <div
          className="flex-row-center"
          style={{
            width: itemWidth * 0.7,
            height: nameHeight,
            marginTop: nameMarginTop,
          }}
        >
          <p className="text text-gray/100 !text-[16px] text-body-ellipsis w-full">
            {property.name}
          </p>
        </div>
      )}
    </div>
  );
};

const RenderScreenChildComponent = (props: { id: string; zIndex: number }) => {
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, props.id)
  );
  const { id, typeId, property } = propertyEntity;
  const ChildComponent = useMemo(
    () => ComponentManager[typeId].component.DesignComponent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className="absolute top-0 left-0 cursor-grab"
      style={{
        zIndex: props.zIndex,
        backfaceVisibility: "hidden",
        width:
          property.style.layout.width +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        height:
          property.style.layout.height +
          property.style.view.borderWidth +
          property.style.shadow.shadowRadius,
        transform: `translate(${property.style.transform.translateX}px, ${property.style.transform.translateY}px) rotate(${property.style.transform.rotation}deg)`,
        willChange: "transform",
        opacity: property.style.view.opacity / 100,
        filter:
          property.style.shadow.shadowRadius !== 0 &&
          `drop-shadow(${property.style.shadow.shadowOffset.width}px ${property.style.shadow.shadowOffset.height}px ${property.style.shadow.shadowRadius}px ${property.style.shadow.shadowColor})`,
      }}
    >
      <ChildComponent
        property={property}
        id={`component-preview-component-${id}`}
      />
    </div>
  );
};

export const RenderScreen = (props: {
  color: boolean;
  minimize: boolean;
  screen: ScreensEntity;
  itemWidth: number;
  itemHeight: number;
  itemMargin: number;
  itemBorder: number;
  nameHeight: number;
  nameMarginTop: number;
  selectedComponentId: string;
  onClick: (id: string) => () => void;
}) => {
  const {
    color,
    minimize,
    screen,
    itemWidth,
    itemHeight,
    itemMargin,
    itemBorder,
    nameHeight,
    nameMarginTop,
    selectedComponentId,
    onClick,
  } = props;

  const screenProperty = useSelector((state: RootState) =>
    selectPropertyById(state, screen.id)
  );

  return (
    <div
      className={`flex-col-view items-center justify-start cursor-pointer pointer-events-auto snap-always snap-center overflow-hidden rounded-[12px] ${
        color && "bg-beige/alt2"
      }`}
      onClick={onClick(screen.id)}
      style={{
        marginTop: itemMargin,
        marginLeft: itemMargin,
        marginRight: itemMargin,
        width: itemWidth + itemBorder * 2,
        height: itemHeight + itemBorder * 2 + nameHeight,
      }}
    >
      <div
        className="flex-col-view !box-content rounded-[14px] border-solid border-beige/alt cursor-pointer"
        style={{
          borderColor:
            selectedComponentId === screen.id
              ? "rgba(112, 177, 244, 0.4)"
              : "transparent",
          borderWidth: itemBorder,
          width: itemWidth,
          height: itemHeight,
          backgroundColor: screenProperty.property.style.view.backgroundColor,
        }}
      >
        <div
          className="flex-col-view w-[1024px] h-[768px] origin-top-left rounded-[14px]"
          style={{
            transform: `scale(${itemWidth / 1024}, ${itemHeight / 768})`,
          }}
        >
          {screen.children.map((component, index) => (
            <RenderScreenChildComponent
              key={component.id}
              id={component.id}
              zIndex={screen.childrenOrder.indexOf(component.id)}
            />
          ))}
        </div>
      </div>
      {!minimize && (
        <div
          className={`flex-row-center ${color && "bg-beige/alt2"}`}
          style={{
            width: itemWidth * 0.7,
            height: nameHeight,
            marginTop: nameMarginTop,
          }}
        >
          <p className="text text-gray/100 !text-[16px] text-body-ellipsis w-full">
            {screenProperty.property.name}
          </p>
        </div>
      )}
    </div>
  );
};
