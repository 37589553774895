import { usePlaySound } from "common/utils";

export const RadioButton = (props: {
  size?: number;
  value: boolean;
  label: string;
  fontSize?: number;
  onChange?: (value: boolean) => void;
}) => {
  const { size, value, label, fontSize, onChange } = props;
  const radius = size ?? 40;
  const play = usePlaySound();

  const handleClick = () => {
    play();
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="flex-row-center cursor-pointer" onClick={handleClick}>
      <div
        className="flex-row-center rounded-full border-[#B8B1B5] border-solid overflow-hidden mx-[5px]"
        style={{ width: radius, height: radius, borderWidth: radius * 0.1 }}
      >
        <div
          className={`flex-col-center rounded-full ${
            value ? "bg-[#94C4F7]" : "bg-white"
          } `}
          style={{
            width: radius * 0.6,
            height: radius * 0.6,
          }}
        />
      </div>
      <p
        className="text text-textcolor/black"
        style={{ fontSize: fontSize ?? 20 }}
      >
        {label}
      </p>
    </div>
  );
};
