import { SVGProps } from "react";

export const IconBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#creator-icon-back-a)"
      stroke={props.stroke ?? "#A54D21"}
      strokeWidth={4}
    >
      <circle cx={20} cy={20} r={14} />
      <path d="m22 16-6 4 6 4" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="creator-icon-back-a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
