import { PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { ComponentProperty } from "common/components";
import { CreatorInitialState } from "features/creator/types";
import { HISTORY_STACK_MAX } from "features/creator/constants";
import { propertiesAdapter } from "features/creator/slice/reducers/adapter";

const reducers = {
  updateProperty(
    state: CreatorInitialState,
    action: PayloadAction<{
      id: string;
      newProperty: ComponentProperty;
      record?: boolean;
    }>
  ) {
    const { id, newProperty, record } = action.payload;
    const propertyEntity = propertiesAdapter
      .getSelectors()
      .selectById(state.properties, id);
    const newPropertyEntity = produce(propertyEntity, (draft) => {
      draft.property = newProperty;
    });
    propertiesAdapter.updateOne(state.properties, {
      id,
      changes: { property: newProperty },
    });

    if (record !== false) {
      state.undoStacks.push({
        before: {
          properties: [propertyEntity],
        },
        after: {
          properties: [newPropertyEntity],
        },
      });
      if (state.undoStacks.length > HISTORY_STACK_MAX) {
        state.undoStacks.shift();
      }
      state.redoStacks = [];
    }
  },
};

export default reducers;
