import { useEffect } from "react";
import I18n from "i18n-js";
import Constants from "common/constant";
import {
  InitImageClickBlock,
  InitImageGetterXBlock,
  InitImageSetterXBlock,
  InitLabelTextShowSetterBlock,
} from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitImageClickBlock();
    InitImageGetterXBlock();
    InitImageSetterXBlock();
    InitLabelTextShowSetterBlock();
  }, []);

  return (
    <div className="flex-row-center w-full gap-16">
      <div className="flex-col-el flex-center">
        <div className="flex-row-el w-full justify-between mb-6">
          <div className="flex-row-el flex-center absolute left-20 top-4">
            <p className="text-body text-white text-[20px]">
              {I18n.t("MSG_COURSE_COORDINATE_X")}
            </p>
          </div>

          <div className="flex-row-view">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/bc572b5812e0937f8ed15e88321cc54c.png`}
              className="w-[60px] h-[60px] opacity-0 -ml-7"
            />
          </div>

          <div className="flex-row-view cursor-pointer">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/bc572b5812e0937f8ed15e88321cc54c.png`}
              className="w-[60px] h-[60px]"
            />
          </div>

          <div className="flex-row-view cursor-pointer">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/869a1175dc52418410e65a2e056c6ac7.png`}
              className="w-[60px] h-[60px] -mr-7"
            />
          </div>
        </div>

        <div className="flex-col-center w-[300px] h-[5px]">
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
            <p className="text text-white !mb-[35px]">0</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
            <p className="text text-white !mb-[35px]">35</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
            <p className="text text-white !mb-[35px]">70</p>
          </div>
          <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

          <div className="flex-row-el absolute top-4 -left-8">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
              className="w-[60px] h-[60px]"
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-8">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${35 * 4.3}px)` }}
            />
          </div>

          <div className="flex-row-el absolute top-4 -left-8">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
              className="w-[60px] h-[60px] opacity-30"
              style={{ transform: `translateX(${70 * 4.3}px)` }}
            />
          </div>
        </div>
      </div>

      <div className="flex-col-center w-[380px] mt-24">
        <div className="flex-row-center gap-2 p-2 border-solid border-[4px] rounded-[6px] border-white/20">
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            35
          </p>
          <p className="text text-white !text-[50px] opacity-70">→</p>
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            70
          </p>
        </div>

        <p className="text text-white">ボックス1</p>
      </div>
    </div>
  );
};
