/* eslint-disable react-hooks/exhaustive-deps */
import { PointerEventHandler } from "react";
import { RotationSvg } from "features/creator/assets";

export const RotationPanel = (props: {
  width: number;
  onRotationPointerStart: PointerEventHandler<HTMLDivElement>;
  onRotationPointerMove: PointerEventHandler<HTMLDivElement>;
  onRotationPointerUp: PointerEventHandler<HTMLDivElement>;
}) => {
  const {
    width,
    onRotationPointerStart,
    onRotationPointerMove,
    onRotationPointerUp,
  } = props;

  const ROTATION_INNER_SIZE = 24;
  const ROTATION_SIZE = ROTATION_INNER_SIZE + 24;
  const ROTATION_OFFSET_Y = 57;

  return (
    <div
      className="flex-row-center !absolute top-0 left-0 pointer-events-auto cursor-grab"
      style={{
        width: ROTATION_SIZE,
        height: ROTATION_OFFSET_Y,
        borderRadius: ROTATION_SIZE,
        transform: `translate(${
          (width - ROTATION_SIZE) / 2
        }px, ${-ROTATION_OFFSET_Y}px)`,
        willChange: "transform",
      }}
      onPointerDown={onRotationPointerStart}
      onPointerMove={onRotationPointerMove}
      onPointerUp={onRotationPointerUp}
    >
      <div className="flex-col-center w-[24px] h-[57px]">
        <RotationSvg />
      </div>
    </div>
  );
};
