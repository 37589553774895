/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import anime from "animejs";
import throttle from "lodash-es/throttle";
import { RootState, AppDispatch } from "app/store";
import {
  sleep,
  btnDelay,
  useScale,
  isDebugMode,
  usePlaySound,
} from "common/utils";
import { GameCaseManager } from "common/elements";
import Constants, { BASE_WIDTH } from "common/constant";
import {
  allProgramStageClearAnimation,
  allAlgorithmStageClearAnimation,
} from "features/courses/api";
import {
  BuilderStagePage,
  BuilderStageStartPage,
} from "features/courses/builder";
import {
  ProgramStagePage,
  ProgramStageStartPage,
} from "features/courses/program/stage";
import {
  TangibleStagePage,
  TangibleStageStartPage,
} from "features/courses/tangible/stage";
import {
  AlgorithmStagePage,
  AlgorithmStageStartPage,
} from "features/courses/algorithm/stage";
import {
  NishishinjukuStagePage,
  NishishinjukuStageStartPage,
} from "features/courses/nishishinjuku/stage";
import { Course } from "features/courses/types";
import {
  TakushokuBasicStagePage,
  TakushokuBasicStageStartPage,
} from "features/courses/takushoku_basic/stage";
import {
  TakushokuAdvanceStagePage,
  TakushokuAdvanceStageStartPage,
} from "features/courses/takushoku_advance/stage";
import { CourseTitleSvg } from "features/home/assets";
import { actions } from "features/courses/coursesSlice";
import { AllStageClear } from "features/courses/assets";
import { OchaStagePage, OchaStageStartPage } from "features/courses/ocha/stage";

const CourseTitleAndBg = ({ scale }: { scale: number }) => (
  <>
    <img
      alt="*"
      src={`${Constants.assetHost}/assets/images/new_bg_puzzle.png`}
      className="absolute inset-0 object-cover w-full h-full z-[-1]"
    />

    <div
      className="flex-row-el flex-center absolute top-0 left-0 w-full"
      style={{
        marginTop: 9 * scale,
        height: 90 * scale,
      }}
    >
      <div
        id="courses-top-title"
        className="flex-col-el flex-center absolute z-50"
        style={{
          width: 321 * scale,
          height: 64 * scale,
        }}
      >
        <CourseTitleSvg />
      </div>
    </div>
  </>
);

const AllStageClearComponent = React.memo(() => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.appUser);

  const algorithmSummary = useSelector(
    (state: RootState) => state.courses.progresses.algorithm.summary
  );
  const algorithmStageProgresses = Math.floor(
    (algorithmSummary.finished / algorithmSummary.total) * 100
  );
  const isAllAlgorithmStageClearAnimation =
    !algorithmSummary.all_stage_clear_animation &&
    algorithmStageProgresses === 100;
  const [allAlgorithmStageClear, setAllAlgorithmStageClear] = useState(
    isAllAlgorithmStageClearAnimation
  );

  const programSummary = useSelector(
    (state: RootState) => state.courses.progresses.program.summary
  );
  const programStageProgresses = Math.floor(
    (programSummary.finished / programSummary.total) * 100
  );
  const isAllProgramStageClearAnimation =
    !programSummary.all_stage_clear_animation && programStageProgresses === 100;
  const [allProgramStageClear, setAllProgramStageClear] = useState(
    isAllProgramStageClearAnimation
  );

  const handleCloseAllStageClear = () =>
    btnDelay(() => {
      play();
      setAllProgramStageClear(false);
      setAllAlgorithmStageClear(false);
    });

  useEffect(() => {
    if (allAlgorithmStageClear) {
      dispatch(actions.allAlgorithmStageClearAnimation());
      allAlgorithmStageClearAnimation({
        uid: user.active.uid,
      });
    }
  }, [allAlgorithmStageClear]);

  useEffect(() => {
    if (allProgramStageClear) {
      dispatch(actions.allProgramStageClearAnimation());
      allProgramStageClearAnimation({
        uid: user.active.uid,
      });
    }
  }, [allProgramStageClear]);

  return allAlgorithmStageClear || allProgramStageClear ? (
    <AllStageClear close={handleCloseAllStageClear} />
  ) : (
    <></>
  );
});

const SoftCardComponent = ({
  scale,
  selectedCourse,
  setSelectedCourse,
}: {
  scale: number;
  selectedCourse: Course;
  setSelectedCourse: (course: Course) => void;
}) => {
  const SOFT_CARD_WIDTH = 136;
  const SOFT_CARD_HEIGHT = 168;
  const SOFT_CARD_OFFSET = 450;
  const SOFT_CARD_MARGIN = 40;
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [animation, setAnimation] = useState(false);
  const [rumAnimation, setRumAnimation] = useState(false);
  const appConfig = useSelector((state: RootState) => state.config.appConfig);

  useEffect(() => {
    if (selectedCourse !== Course.ALGORITHM) {
      const currentSelectedCourseTarget = document.getElementById(
        `courses-soft-card-algorithm`
      );
      const clickedCourseTarget = document.getElementById(
        `courses-soft-card-${selectedCourse}`
      );

      const translateX = clickedCourseTarget.style.transform
        .split(" ")[0]
        .split("(")[1]
        .split("px")[0];

      currentSelectedCourseTarget.style.transform = `translateX(${translateX}px) translateY(0px)`;
      clickedCourseTarget.style.transform = `translateX(${
        120 * scale
      }px) translateY(${49 * scale}px)`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  const handleAnimation = useMemo(
    () =>
      throttle(
        () => {
          toast.warn(
            <div>
              <p className="text text-textcolor/black">
                {I18n.t("MSG_COURSE_COMING_SOON_WARNING_MESSAGE")}
              </p>
            </div>
          );
          setRumAnimation(true);
        },
        2000,
        { trailing: false }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSetSelectCourse = useMemo(
    () =>
      throttle(
        (course: Course) => {
          setSelectedCourse(course);
        },
        1000,
        { trailing: false }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSelectCourse = (course: Course) => async () => {
    if (animation) return;

    play();

    if (selectedCourse !== course) {
      setAnimation(true);

      const currentSelectedCourseTarget = document.getElementById(
        `courses-soft-card-${selectedCourse}`
      );
      const clickedCourseTarget = document.getElementById(
        `courses-soft-card-${course}`
      );
      const translateX = clickedCourseTarget.style.transform
        .split(" ")[0]
        .split("(")[1]
        .split("px")[0];

      anime({
        targets: currentSelectedCourseTarget,
        opacity: [1, 0],
        duration: 800,
        easing: "easeInOutQuad",
      }).finished.then(async () => {
        await anime({
          targets: currentSelectedCourseTarget,
          translateX: Number(translateX),
          translateY: 0,
          duration: 10,
          easing: "easeInOutQuad",
        }).finished;
        await anime({
          targets: currentSelectedCourseTarget,
          opacity: [0, 1],
          duration: 800,
          easing: "easeInOutQuad",
        }).finished;
      });

      anime({
        targets: clickedCourseTarget,
        opacity: [1, 0],
        duration: 800,
        easing: "easeInOutQuad",
      }).finished.then(async () => {
        await anime({
          targets: clickedCourseTarget,
          translateX: 120 * scale,
          translateY: 0,
          duration: 10,
          easing: "easeInOutQuad",
        }).finished;
        anime({
          targets: clickedCourseTarget,
          opacity: [0, 1],
          translateY: 59 * scale,
          duration: 800,
          easing: "easeInOutQuad",
        });

        await anime({
          targets: document.getElementById("course-stage-list"),
          scaleX: [1, 0.2, 0.2],
          scaleY: [0.002, 0.002, 0.002],
          duration: 500,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;

        handleSetSelectCourse(course);
        dispatch(actions.updateShowCourseTop(true));
        dispatch(actions.updateShowCourseList(false));

        await sleep(100);

        await anime({
          targets: document.getElementById("course-stage-list"),
          opacity: [0, 1],
          scaleX: [0.3, 1],
          scaleY: [0.3, 1],
          duration: 400,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;

        setAnimation(false);
      });
    }
  };

  const handleClickComingSoon = () => {
    play();
    handleAnimation();
  };

  return (
    <div
      className="flex-row-view mt-2"
      style={{ width: BASE_WIDTH * scale, height: 190 * scale }}
    >
      <div
        className="flex-col-center !absolute"
        style={{
          width: 96 * scale,
          height: 80 * scale,
          left: 304 * scale,
          bottom: 73 * scale,
        }}
      >
        <img
          alt="コースの選択指示装飾"
          src={`${Constants.assetHost}/assets/images/course_select_decoration.png`}
          className="w-full h-full"
        />
      </div>

      <div
        id="courses-soft-card-algorithm"
        className="flex-col-center !absolute cursor-pointer will-change-transform"
        style={{
          width: SOFT_CARD_WIDTH * scale,
          height: SOFT_CARD_HEIGHT * scale,
          transform: `translateX(${120 * scale}px) translateY(${49 * scale}px)`,
        }}
        onClick={handleSelectCourse(Course.ALGORITHM)}
      >
        <img
          alt="アルゴリズム学ぶソフト"
          src={`${Constants.assetHost}/assets/images/course_soft_algorithm.png`}
          className="w-full h-full"
        />
      </div>

      <div
        id="courses-soft-card-program"
        className="flex-col-center !absolute cursor-pointer will-change-transform"
        style={{
          width: SOFT_CARD_WIDTH * scale,
          height: SOFT_CARD_HEIGHT * scale,
          transform: `translateX(${
            SOFT_CARD_OFFSET * scale
          }px) translateY(0px)`,
        }}
        onClick={handleSelectCourse(Course.PROGRAM)}
      >
        <img
          alt="プログラム学ぶソフト"
          src={`${Constants.assetHost}/assets/images/course_soft_program.png`}
          className="w-full h-full"
        />
      </div>

      {(appConfig?.enable_lab || isDebugMode) && (
        <div
          id="courses-soft-card-tangible"
          className="flex-col-center !absolute cursor-pointer will-change-transform"
          style={{
            width: SOFT_CARD_WIDTH * scale,
            height: SOFT_CARD_HEIGHT * scale,
            transform: `translateX(${
              (SOFT_CARD_OFFSET + (SOFT_CARD_WIDTH + SOFT_CARD_MARGIN)) * scale
            }px) translateY(0px)`,
          }}
          onClick={handleSelectCourse(Course.TANGIBLE)}
        >
          <img
            alt="タンジブル"
            src={`${Constants.assetHost}/assets/images/course_soft_tangible.png`}
            className="w-full h-full"
          />
        </div>
      )}

      {isDebugMode ? (
        <>
          <div
            id="courses-soft-card-ocha"
            className="flex-col-center !absolute cursor-pointer will-change-transform"
            style={{
              width: SOFT_CARD_WIDTH * scale,
              height: SOFT_CARD_HEIGHT * scale,
              transform: `translateX(${
                (SOFT_CARD_OFFSET + (SOFT_CARD_WIDTH + SOFT_CARD_MARGIN) * 2) *
                scale
              }px) translateY(0px)`,
            }}
            onClick={handleSelectCourse(Course.OCHA)}
          >
            <img
              alt="お茶の水女子大学"
              src={`${Constants.assetHost}/assets/images/course_soft_ocha.png`}
              className="w-full h-full"
            />
          </div>
          <div
            id="courses-soft-card-nishishinjuku"
            className="flex-col-center !absolute cursor-pointer will-change-transform"
            style={{
              width: SOFT_CARD_WIDTH * scale,
              height: SOFT_CARD_HEIGHT * scale,
              transform: `translateX(${
                (SOFT_CARD_OFFSET + (SOFT_CARD_WIDTH + SOFT_CARD_MARGIN) * 3) *
                scale
              }px) translateY(0px)`,
            }}
            onClick={handleSelectCourse(Course.NISHISHINJUKU)}
          >
            <img
              alt="西新宿小学校ソフト"
              src={`${Constants.assetHost}/assets/images/course_soft_kogakuin.png`}
              className="w-full h-full"
            />
          </div>
          <div
            id="courses-soft-card-takushoku_basic"
            className="flex-col-center !absolute cursor-pointer will-change-transform"
            style={{
              width: SOFT_CARD_WIDTH * scale,
              height: SOFT_CARD_HEIGHT * scale,
              transform: `translateX(${
                (SOFT_CARD_OFFSET + (SOFT_CARD_WIDTH + SOFT_CARD_MARGIN) * 4) *
                scale
              }px) translateY(0px)`,
            }}
            onClick={handleSelectCourse(Course.TAKUSHOKU_BASIC)}
          >
            <img
              alt="拓殖大学"
              src={`${Constants.assetHost}/assets/images/course_soft_takushoku_basic.png`}
              className="w-full h-full"
            />
          </div>
          <div
            id="courses-soft-card-takushoku_advance"
            className="flex-col-center !absolute cursor-pointer will-change-transform"
            style={{
              width: SOFT_CARD_WIDTH * scale,
              height: SOFT_CARD_HEIGHT * scale,
              transform: `translateX(${
                (SOFT_CARD_OFFSET + (SOFT_CARD_WIDTH + SOFT_CARD_MARGIN) * 5) *
                scale
              }px) translateY(0px)`,
            }}
            onClick={handleSelectCourse(Course.TAKUSHOKU_ADVANCE)}
          >
            <img
              alt="拓殖大学"
              src={`${Constants.assetHost}/assets/images/course_soft_takushoku_advance.png`}
              className="w-full h-full"
            />
          </div>
          <div
            id="courses-soft-card-builder"
            className="flex-col-center !absolute cursor-pointer will-change-transform"
            style={{
              width: SOFT_CARD_WIDTH * scale,
              height: SOFT_CARD_HEIGHT * scale,
              transform: `translateX(${
                (SOFT_CARD_OFFSET + (SOFT_CARD_WIDTH + SOFT_CARD_MARGIN) * 6) *
                scale
              }px) translateY(0px)`,
            }}
            onClick={handleSelectCourse(Course.BUILDER)}
          >
            <img
              alt="マップビルド"
              src={`${Constants.assetHost}/assets/images/course_soft_builder.png`}
              className="w-full h-full"
            />
          </div>
        </>
      ) : (
        <div
          className="cursor-pointer"
          onClick={handleClickComingSoon}
          style={{
            transform: `translateX(${
              (SOFT_CARD_OFFSET +
                (SOFT_CARD_WIDTH + SOFT_CARD_MARGIN) *
                  (appConfig?.enable_lab ? 2 : 1)) *
              scale
            }px) translateY(0px)`,
          }}
        >
          <div
            className={`flex-col-center !absolute ${rumAnimation && "rum"}`}
            style={{
              width: SOFT_CARD_WIDTH * scale,
              height: SOFT_CARD_HEIGHT * scale,
            }}
            onAnimationEnd={() => setRumAnimation(false)}
          >
            <img
              alt="コースでまなぶのComing Soon"
              src={`${Constants.assetHost}/assets/images/course_soft_comingsoon_1.png`}
              className="absolute"
              style={{
                width: SOFT_CARD_WIDTH * scale,
                height: SOFT_CARD_HEIGHT * scale,
              }}
            />
            <img
              alt="コースでまなぶのComing Soon"
              src={`${Constants.assetHost}/assets/images/course_soft_comingsoon_2.png`}
              className="absolute"
              style={{
                width: SOFT_CARD_WIDTH * scale,
                height: SOFT_CARD_HEIGHT * scale,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const CourseStageList = () => {
  const selectedCourse = useSelector(
    (state: RootState) => state.courses.selectedCourse
  );
  const show = useSelector((state: RootState) => state.courses.showCourseList);

  return (
    <div className="w-full h-full absolute">
      {selectedCourse === Course.ALGORITHM && show && <AlgorithmStagePage />}
      {selectedCourse === Course.PROGRAM && show && <ProgramStagePage />}
      {selectedCourse === Course.TANGIBLE && <TangibleStagePage />}
      {selectedCourse === Course.BUILDER && <BuilderStagePage />}
      {selectedCourse === Course.NISHISHINJUKU && show && (
        <NishishinjukuStagePage />
      )}
      {selectedCourse === Course.TAKUSHOKU_BASIC && show && (
        <TakushokuBasicStagePage />
      )}
      {selectedCourse === Course.TAKUSHOKU_ADVANCE && show && (
        <TakushokuAdvanceStagePage />
      )}
      {selectedCourse === Course.OCHA && show && <OchaStagePage />}
    </div>
  );
};

const CourseStageComponent = ({
  scale,
  selectedCourse,
}: {
  scale: number;
  selectedCourse: Course;
}) => {
  const showCourseTop = useSelector(
    (state: RootState) => state.courses.showCourseTop
  );
  const config = useSelector((state: RootState) => state.config.userConfig);
  const GameCaseComponent = useMemo(() => GameCaseManager[config.game_case], [
    config.game_case,
  ]);

  useEffect(() => {
    anime({
      targets: document.getElementById("course-stage-list"),
      scaleX: [0.3, 1],
      scaleY: [0.3, 1],
      duration: 200,
      autoplay: true,
      easing: "easeInOutQuad",
    });
  }, []);

  return (
    <div
      className="flex-col-center"
      style={{
        width: 1216 * scale,
        height: 646 * scale,
      }}
    >
      <div className="flex-col-center" style={{ transform: `scale(${scale})` }}>
        <div className="flex-col-el w-[1126px] h-[574px] absolute top-[38px] left-[45px] z-[100] pointer-events-none">
          <div className="flex-col-el w-full h-full rounded-[17px] border-solid border-[1px] border-[#000000]/40 p-1">
            <div className="flex-col-el w-full h-full rounded-[12px] border-solid border-[2px] border-[#000000]/30 overflow-hidden">
              <div className="flex-col-el w-full h-full rounded-[10px] border-solid border-[4px] border-[#FFFFFF]/40" />
            </div>
          </div>
        </div>

        <div className="flex-col-el flex-center w-[1124px] h-[572px] absolute top-[39px] left-[46px] border-solid border-gray/100 border-[6px] rounded-[16px] overflow-hidden z-50">
          <div className="flex-col-center w-full h-full bg-gray2/100 !absolute top-0 left-0" />

          <div className="flex-col-center w-[1118px] h-[566px] !absolute top-0 left-0 scale-x-100 scale-y-100">
            <div
              id="course-stage-list"
              className="w-full h-full absolute z-[100] will-change-transform"
            >
              {selectedCourse === Course.ALGORITHM && showCourseTop && (
                <AlgorithmStageStartPage />
              )}
              {selectedCourse === Course.PROGRAM && showCourseTop && (
                <ProgramStageStartPage />
              )}
              {selectedCourse === Course.TANGIBLE && showCourseTop && (
                <TangibleStageStartPage />
              )}
              {selectedCourse === Course.BUILDER && showCourseTop && (
                <BuilderStageStartPage />
              )}
              {selectedCourse === Course.NISHISHINJUKU && showCourseTop && (
                <NishishinjukuStageStartPage />
              )}
              {selectedCourse === Course.TAKUSHOKU_BASIC && showCourseTop && (
                <TakushokuBasicStageStartPage />
              )}
              {selectedCourse === Course.TAKUSHOKU_ADVANCE && showCourseTop && (
                <TakushokuAdvanceStageStartPage />
              )}
              {selectedCourse === Course.OCHA && showCourseTop && (
                <OchaStageStartPage />
              )}
              <CourseStageList />
            </div>
          </div>
        </div>

        <div
          className="flex-col-center w-[1216px] h-[646px]"
          style={{
            filter: "drop-shadow(0px 0px 3px rgba(63, 74, 97, 0.3))",
          }}
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export const CourseTopPage = () => {
  const { scale } = useScale();
  const dispatch = useDispatch();
  const defaultSelectedCourse = useSelector(
    (state: RootState) => state.courses.selectedCourse
  );
  const [selectedCourse, _setSelectedCourse] = useState(defaultSelectedCourse);
  const setSelectedCourse = (course: Course) => {
    dispatch(actions.updateSelectedCourse({ course }));
    _setSelectedCourse(course);
  };

  return (
    <div className="flex-col-el w-full h-full">
      <AllStageClearComponent />
      <CourseTitleAndBg scale={scale} />
      <div
        className="flex-col-el flex-center flex-1 w-full h-full"
        style={{ marginTop: 87 * scale }}
      >
        <SoftCardComponent
          scale={scale}
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
        />
        <CourseStageComponent scale={scale} selectedCourse={selectedCourse} />
      </div>
    </div>
  );
};
