export const Sep = (p) => {
  const { children, ...props } = p;
  return (
    <sep is="blockly" {...props}>
      {children}
    </sep>
  );
};

export const Label = (p) => {
  const { children, ...props } = p;
  return (
    <label is="blockly" {...props}>
      {children}
    </label>
  );
};

export const Category = (p) => {
  const { children, ...props } = p;
  return (
    <category is="blockly" {...props}>
      {children}
    </category>
  );
};

export const Block = (p) => {
  const { children, ...props } = p;
  return (
    <block is="blockly" {...props}>
      {children}
    </block>
  );
};

export const Value = (p) => {
  const { children, ...props } = p;
  return (
    <value is="blockly" {...props}>
      {children}
    </value>
  );
};

export const Field = (p) => {
  const { children, ...props } = p;
  return (
    <field is="blockly" {...props}>
      {children}
    </field>
  );
};

export const Shadow = (p) => {
  const { children, ...props } = p;
  return (
    <shadow is="blockly" {...props}>
      {children}
    </shadow>
  );
};

export const Variables = (p) => {
  const { children, ...props } = p;
  return (
    <variables is="blockly" {...props}>
      {children}
    </variables>
  );
};

export const Variable = (p) => {
  const { children, ...props } = p;
  return (
    <variable is="blockly" {...props}>
      {children}
    </variable>
  );
};

export const Mutation = (p) => {
  const { children, ...props } = p;
  return (
    <mutation is="blockly" {...props}>
      {children}
    </mutation>
  );
};
