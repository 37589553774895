export const UserAvatar = ({ size, icon }: { size: number; icon: string }) => (
  <div
    className="flex-col-center cursor-pointer bg-white rounded-full"
    style={{
      width: size,
      height: size,
      boxShadow:
        "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
    }}
  >
    <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
      <img
        alt="ユーザプロフィール画像"
        src={icon}
        className="w-full h-full object-contain"
      />
    </div>
  </div>
);
