import { Vector3 } from "@babylonjs/core/Maths/math.vector";

export enum CHARACTER_TYPE {
  CHARACTER_TYPE_DUCK,
  CHARACTER_TYPE_DOG,
  CHARACTER_TYPE_BEAR,
}

type Characters = {
  name: string;
  filename: string;
  offset: Vector3;
  scaling: Vector3;
};

export const characters: Characters[] = [
  {
    name: "character_duck",
    filename: "character_duck.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(1.2, 1.2, -1.2),
  },
  {
    name: "character_dog",
    filename: "character_dog.gltf",
    offset: new Vector3(0, 0.3, 0),
    scaling: new Vector3(1.3, 1.3, -1.3),
  },
  {
    name: "character_bear",
    filename: "character_bear.gltf",
    offset: new Vector3(0, 0.3, 0),
    scaling: new Vector3(1.3, 1.3, -1.3),
  },
];

export enum CHARACTER_DIRECTION {
  CHARACTER_DIRECTION_X,
  CHARACTER_DIRECTION_MINUS_X,
  CHARACTER_DIRECTION_Z,
  CHARACTER_DIRECTION_MINUS_Z,
}

export const characterDirection = {
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_X]: new Vector3(0, -Math.PI / 2, 0),
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X]: new Vector3(
    0,
    Math.PI / 2,
    0
  ),
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_Z]: new Vector3(0, Math.PI, 0),
  [CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_Z]: new Vector3(0, 0, 0),
};

const tilesets = [
  {
    id: 0,
    name: "nothing",
    collides: true,
  },
  {
    id: 1,
    name: "arrow_teamBlue",
    filename: "arrow_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 2,
    name: "arrow_teamRed",
    filename: "arrow_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 3,
    name: "arrow_teamYellow",
    filename: "arrow_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 4,
    name: "ball",
    filename: "ball.gltf.glb",
    offset: new Vector3(0, 1, 0),
    collides: true,
  },
  {
    id: 5,
    name: "ball_teamBlue",
    filename: "ball_teamBlue.gltf.glb",
    offset: new Vector3(0, 1, 0),
    collides: true,
  },
  {
    id: 6,
    name: "ball_teamRed",
    filename: "ball_teamRed.gltf.glb",
    offset: new Vector3(0, 1, 0),
    collides: true,
  },
  {
    id: 7,
    name: "ball_teamYellow",
    filename: "ball_teamYellow.gltf.glb",
    offset: new Vector3(0, 1, 0),
    collides: true,
  },
  {
    id: 8,
    name: "barrierFloor",
    filename: "barrierFloor.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 9,
    name: "barrierLadder",
    filename: "barrierLadder.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 10,
    name: "barrierLarge",
    filename: "barrierLarge.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 11,
    name: "barrierMedium",
    filename: "barrierMedium.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 12,
    name: "barrierSmall",
    filename: "barrierSmall.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 13,
    name: "barrierStrut",
    filename: "barrierStrut.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 14,
    name: "blaster_teamBlue",
    filename: "blaster_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 15,
    name: "blaster_teamRed",
    filename: "blaster_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 16,
    name: "blaster_teamYellow",
    filename: "blaster_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, -0.5),
    collides: true,
  },
  {
    id: 17,
    name: "bomb_teamBlue",
    filename: "bomb_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 18,
    name: "bomb_teamRed",
    filename: "bomb_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 19,
    name: "bomb_teamYellow",
    filename: "bomb_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 20,
    name: "bow_teamBlue",
    filename: "bow_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 21,
    name: "bow_teamRed",
    filename: "bow_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 22,
    name: "bow_teamYellow",
    filename: "bow_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 23,
    name: "button_teamBlue",
    filename: "button_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1.6, 0.3, 1.6),
    collides: false,
  },
  {
    id: 24,
    name: "button_teamRed",
    filename: "button_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1.6, 0.3, 1.6),
    collides: false,
  },
  {
    id: 25,
    name: "button_teamYellow",
    filename: "button_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1.6, 0.3, 1.6),
    collides: false,
  },
  {
    id: 26,
    name: "detail_desert",
    filename: "detail_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1.7, 0.3, 1.7),
    collides: true,
  },
  {
    id: 27,
    name: "detail_forest",
    filename: "detail_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1.7, 0.3, 1.7),
    collides: true,
  },
  {
    id: 28,
    name: "diamond_teamBlue",
    filename: "diamond_teamBlue.gltf.glb",
    offset: new Vector3(0, 1, 0),
    collides: false,
  },
  {
    id: 29,
    name: "diamond_teamRed",
    filename: "diamond_teamRed.gltf.glb",
    offset: new Vector3(0, 1, 0),
    collides: false,
  },
  {
    id: 30,
    name: "diamond_teamYellow",
    filename: "diamond_teamYellow.gltf.glb",
    offset: new Vector3(0, 1, 0),
    collides: false,
  },
  {
    id: 31,
    name: "flag_teamBlue",
    filename: "flag_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 32,
    name: "flag_teamRed",
    filename: "flag_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 33,
    name: "flag_teamYellow",
    filename: "flag_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 34,
    name: "gateLargeWide_teamBlue",
    filename: "gateLargeWide_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 35,
    name: "gateLargeWide_teamRed",
    filename: "gateLargeWide_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 36,
    name: "gateLargeWide_teamYellow",
    filename: "gateLargeWide_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 37,
    name: "gateLarge_teamBlue",
    filename: "gateLarge_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 38,
    name: "gateLarge_teamRed",
    filename: "gateLarge_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 39,
    name: "gateLarge_teamYellow",
    filename: "gateLarge_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 40,
    name: "gateSmallWide_teamBlue",
    filename: "gateSmallWide_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 41,
    name: "gateSmallWide_teamRed",
    filename: "gateSmallWide_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 42,
    name: "gateSmallWide_teamYellow",
    filename: "gateSmallWide_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 43,
    name: "gateSmall_teamBlue",
    filename: "gateSmall_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 44,
    name: "gateSmall_teamRed",
    filename: "gateSmall_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 45,
    name: "gateSmall_teamYellow",
    filename: "gateSmall_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 46,
    name: "heart_teamBlue",
    filename: "heart_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 47,
    name: "heart_teamRed",
    filename: "heart_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 48,
    name: "heart_teamYellow",
    filename: "heart_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 49,
    name: "hoop_teamBlue",
    filename: "hoop_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 50,
    name: "hoop_teamRed",
    filename: "hoop_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 51,
    name: "hoop_teamYellow",
    filename: "hoop_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 52,
    name: "lightning",
    filename: "lightning.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 53,
    name: "plantA_desert",
    filename: "plantA_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 54,
    name: "plantA_forest",
    filename: "plantA_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 55,
    name: "plantB_desert",
    filename: "plantB_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 56,
    name: "plantB_forest",
    filename: "plantB_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 57,
    name: "powerupBlock_teamBlue",
    filename: "powerupBlock_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 58,
    name: "powerupBlock_teamRed",
    filename: "powerupBlock_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 59,
    name: "powerupBlock_teamYellow",
    filename: "powerupBlock_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 60,
    name: "powerupBomb",
    filename: "powerupBomb.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 61,
    name: "ring_teamBlue",
    filename: "ring_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 62,
    name: "ring_teamRed",
    filename: "ring_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 63,
    name: "ring_teamYellow",
    filename: "ring_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 64,
    name: "rocksA_desert",
    filename: "rocksA_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 65,
    name: "rocksA_forest",
    filename: "rocksA_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 66,
    name: "rocksB_desert",
    filename: "rocksB_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 67,
    name: "rocksB_forest",
    filename: "rocksB_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 68,
    name: "slingshot_teamBlue",
    filename: "slingshot_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 69,
    name: "slingshot_teamRed",
    filename: "slingshot_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 70,
    name: "slingshot_teamYellow",
    filename: "slingshot_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 71,
    name: "spikeRoller",
    filename: "spikeRoller.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 72,
    name: "star",
    filename: "star.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 73,
    name: "swiperDouble_teamBlue",
    filename: "swiperDouble_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 74,
    name: "swiperDouble_teamRed",
    filename: "swiperDouble_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 75,
    name: "swiperDouble_teamYellow",
    filename: "swiperDouble_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 76,
    name: "swiperLong_teamBlue",
    filename: "swiperLong_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 77,
    name: "swiperLong_teamRed",
    filename: "swiperLong_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 78,
    name: "swiperLong_teamYellow",
    filename: "swiperLong_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 79,
    name: "swiper_teamBlue",
    filename: "swiper_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 80,
    name: "swiper_teamRed",
    filename: "swiper_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 81,
    name: "swiper_teamYellow",
    filename: "swiper_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 82,
    name: "sword_teamBlue",
    filename: "sword_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 83,
    name: "sword_teamRed",
    filename: "sword_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 84,
    name: "sword_teamYellow",
    filename: "sword_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 85,
    name: "target",
    filename: "target.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 86,
    name: "targetStand",
    filename: "targetStand.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 87,
    name: "tileHigh_desert",
    filename: "tileHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 88,
    name: "tileHigh_forest",
    filename: "tileHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 89,
    name: "tileHigh_teamBlue",
    filename: "tileHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 90,
    name: "tileHigh_teamRed",
    filename: "tileHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 91,
    name: "tileHigh_teamYellow",
    filename: "tileHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 92,
    name: "tileLarge_desert",
    filename: "tileLarge_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(6, 1, 6),
    collides: true,
  },
  {
    id: 93,
    name: "tileLarge_forest",
    filename: "tileLarge_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(6, 1, 6),
    collides: true,
  },
  {
    id: 94,
    name: "tileLarge_teamBlue",
    filename: "tileLarge_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(6, 1, 6),
    collides: true,
  },
  {
    id: 95,
    name: "tileLarge_teamRed",
    filename: "tileLarge_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(6, 1, 6),
    collides: true,
  },
  {
    id: 96,
    name: "tileLarge_teamYellow",
    filename: "tileLarge_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(6, 1, 6),
    collides: true,
  },
  {
    id: 97,
    name: "tileLow_desert",
    filename: "tileLow_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1, 2),
    collides: true,
  },
  {
    id: 98,
    name: "tileLow_forest",
    filename: "tileLow_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1, 2),
    collides: true,
  },
  {
    id: 99,
    name: "tileLow_teamBlue",
    filename: "tileLow_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1, 2),
    collides: false,
  },
  {
    id: 100,
    name: "tileLow_teamRed",
    filename: "tileLow_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1, 2),
    collides: false,
  },
  {
    id: 101,
    name: "tileLow_teamYellow",
    filename: "tileLow_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1, 2),
    collides: false,
  },
  {
    id: 102,
    name: "tileMedium_desert",
    filename: "tileMedium_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: false,
  },
  {
    id: 103,
    name: "tileMedium_forest",
    filename: "tileMedium_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: false,
  },
  {
    id: 104,
    name: "tileMedium_teamBlue",
    filename: "tileMedium_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: false,
  },
  {
    id: 105,
    name: "tileMedium_teamRed",
    filename: "tileMedium_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: false,
  },
  {
    id: 106,
    name: "tileMedium_teamYellow",
    filename: "tileMedium_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: false,
  },
  {
    id: 107,
    name: "tileSlopeLowHigh_desert",
    filename: "tileSlopeLowHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 108,
    name: "tileSlopeLowHigh_forest",
    filename: "tileSlopeLowHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 109,
    name: "tileSlopeLowHigh_teamBlue",
    filename: "tileSlopeLowHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 110,
    name: "tileSlopeLowHigh_teamRed",
    filename: "tileSlopeLowHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 111,
    name: "tileSlopeLowHigh_teamYellow",
    filename: "tileSlopeLowHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 112,
    name: "tileSlopeLowMedium_teamRed",
    filename: "tileSlopeLowMedium_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: true,
  },
  {
    id: 113,
    name: "tileSlopeLowMedium_desert",
    filename: "tileSlopeLowMedium_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: true,
  },
  {
    id: 114,
    name: "tileSlopeLowMedium_forest",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: true,
  },
  {
    id: 115,
    name: "tileSlopeLowMedium_teamBlue",
    filename: "tileSlopeLowMedium_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: true,
  },
  {
    id: 116,
    name: "tileSlopeLowMedium_teamYellow",
    filename: "tileSlopeLowMedium_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1.5, 2),
    collides: true,
  },
  {
    id: 117,
    name: "tileSlopeMediumHigh_desert",
    filename: "tileSlopeMediumHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 118,
    name: "tileSlopeMediumHigh_forest",
    filename: "tileSlopeMediumHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 119,
    name: "tileSlopeMediumHigh_teamBlue",
    filename: "tileSlopeMediumHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 120,
    name: "tileSlopeMediumHigh_teamRed",
    filename: "tileSlopeMediumHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 121,
    name: "tileSlopeMediumHigh_teamYellow",
    filename: "tileSlopeMediumHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 122,
    name: "tileSmall_desert",
    filename: "tileSmall_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1, 1, 1),
    collides: true,
  },
  {
    id: 123,
    name: "tileSmall_forest",
    filename: "tileSmall_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1, 1, 1),
    collides: true,
  },
  {
    id: 124,
    name: "tileSmall_teamBlue",
    filename: "tileSmall_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1, 1, 1),
    collides: true,
  },
  {
    id: 125,
    name: "tileSmall_teamRed",
    filename: "tileSmall_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1, 1, 1),
    collides: true,
  },
  {
    id: 126,
    name: "tileSmall_teamYellow",
    filename: "tileSmall_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1, 1, 1),
    collides: true,
  },
  {
    id: 127,
    name: "tree_desert",
    filename: "tree_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 3, 0.6),
    collides: true,
  },
  {
    id: 128,
    name: "tree_forest",
    filename: "tree_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(1.5, 3, 1.5),
    collides: true,
  },
  {
    id: 129,
    name: "gateLargeWide_teamBlue_x",
    filename: "gateLargeWide_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.4, 0.4),
    collides: true,
  },
  {
    id: 130,
    name: "gateLargeWide_teamBlue_z",
    filename: "gateLargeWide_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.4, 0.4),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 131,
    name: "gateLargeWide_teamRed_x",
    filename: "gateLargeWide_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.4, 0.4),
    collides: true,
  },
  {
    id: 132,
    name: "gateLargeWide_teamRed_z",
    filename: "gateLargeWide_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.4, 0.4),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 133,
    name: "gateLargeWide_teamYellow_x",
    filename: "gateLargeWide_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.4, 0.4),
    collides: true,
  },
  {
    id: 134,
    name: "gateLargeWide_teamYellow_z",
    filename: "gateLargeWide_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.4, 0.4),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 135,
    name: "gateSmallWide_teamBlue_x",
    filename: "gateSmallWide_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.5, 0.4),
    collides: true,
  },
  {
    id: 136,
    name: "gateSmallWide_teamBlue_z",
    filename: "gateSmallWide_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.5, 0.4),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 137,
    name: "gateSmallWide_teamRed_x",
    filename: "gateSmallWide_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.5, 0.4),
    collides: true,
  },
  {
    id: 138,
    name: "gateSmallWide_teamRed_z",
    filename: "gateSmallWide_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.5, 0.4),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 139,
    name: "gateSmallWide_teamYellow_x",
    filename: "gateSmallWide_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.5, 0.4),
    collides: true,
  },
  {
    id: 140,
    name: "gateSmallWide_teamYellow_z",
    filename: "gateSmallWide_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.4, 0.5, 0.4),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 141,
    name: "gateSmall_teamBlue_x",
    filename: "gateSmall_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.8, 0.8, 0.8),
    collides: true,
  },
  {
    id: 142,
    name: "gateSmall_teamBlue_z",
    filename: "gateSmall_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.8, 0.8, 0.8),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 143,
    name: "gateSmall_teamRed_x",
    filename: "gateSmall_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.8, 0.8, 0.8),
    collides: true,
  },
  {
    id: 144,
    name: "gateSmall_teamRed_z",
    filename: "gateSmall_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.8, 0.8, 0.8),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 145,
    name: "gateSmall_teamYellow_x",
    filename: "gateSmall_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.8, 0.8, 0.8),
    collides: true,
  },
  {
    id: 146,
    name: "gateSmall_teamYellow_z",
    filename: "gateSmall_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(0.8, 0.8, 0.8),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 147,
    name: "swiper_teamBlue_z",
    filename: "swiper_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, -0.7),
    scaling: new Vector3(0.5, 0.5, -0.5),
    collides: true,
  },
  {
    id: 148,
    name: "swiper_teamBlue_minus_z",
    filename: "swiper_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0.7),
    scaling: new Vector3(0.5, 0.5, 0.5),
    collides: true,
  },
  {
    id: 149,
    name: "swiper_teamBlue_x",
    filename: "swiper_teamBlue.gltf.glb",
    offset: new Vector3(-0.7, 0, 0),
    scaling: new Vector3(0.5, 0.5, 0.5),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 150,
    name: "swiper_teamBlue_minus_x",
    filename: "swiper_teamBlue.gltf.glb",
    offset: new Vector3(0.7, 0, 0),
    scaling: new Vector3(0.5, 0.5, 0.5),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 151,
    name: "swiper_teamRed_z",
    filename: "swiper_teamRed.gltf.glb",
    offset: new Vector3(0, 0, -0.7),
    scaling: new Vector3(0.5, 0.5, -0.5),
    collides: true,
  },
  {
    id: 152,
    name: "swiper_teamRed_minus_z",
    filename: "swiper_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0.7),
    scaling: new Vector3(0.5, 0.5, 0.5),
    collides: true,
  },
  {
    id: 153,
    name: "swiper_teamRed_x",
    filename: "swiper_teamRed.gltf.glb",
    offset: new Vector3(-0.7, 0, 0),
    scaling: new Vector3(0.5, 0.5, 0.5),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 154,
    name: "swiper_teamRed_minus_x",
    filename: "swiper_teamRed.gltf.glb",
    offset: new Vector3(0.7, 0, 0),
    scaling: new Vector3(0.5, 0.5, 0.5),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 155,
    name: "swiper_teamYellow_z",
    filename: "swiper_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, -0.7),
    scaling: new Vector3(0.5, 0.5, -0.5),
    collides: true,
  },
  {
    id: 156,
    name: "swiper_teamYellow_minus_z",
    filename: "swiper_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0.7),
    scaling: new Vector3(0.5, 0.5, 0.5),
    collides: true,
  },
  {
    id: 157,
    name: "swiper_teamYellow_x",
    filename: "swiper_teamYellow.gltf.glb",
    offset: new Vector3(-0.7, 0, 0),
    scaling: new Vector3(0.5, 0.5, 0.5),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 158,
    name: "swiper_teamYellow_minus_x",
    filename: "swiper_teamYellow.gltf.glb",
    offset: new Vector3(0.7, 0, 0),
    scaling: new Vector3(0.5, 0.5, 0.5),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 159,
    name: "arrow_teamBlue_z",
    filename: "arrow_teamBlue.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, Math.PI / 2, -Math.PI / 2),
    collides: true,
  },
  {
    id: 160,
    name: "arrow_teamBlue_minus_z",
    filename: "arrow_teamBlue.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, Math.PI / 2, Math.PI / 2),
    collides: true,
  },
  {
    id: 161,
    name: "arrow_teamBlue_x",
    filename: "arrow_teamBlue.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, 0, Math.PI / 2),
    collides: true,
  },
  {
    id: 162,
    name: "arrow_teamBlue_minus_x",
    filename: "arrow_teamBlue.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, 0, -Math.PI / 2),
    collides: true,
  },
  {
    id: 163,
    name: "arrow_teamRed_z",
    filename: "arrow_teamRed.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, Math.PI / 2, -Math.PI / 2),
    collides: true,
  },
  {
    id: 164,
    name: "arrow_teamRed_minus_z",
    filename: "arrow_teamRed.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, Math.PI / 2, Math.PI / 2),
    collides: true,
  },
  {
    id: 165,
    name: "arrow_teamRed_x",
    filename: "arrow_teamRed.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, 0, Math.PI / 2),
    collides: true,
  },
  {
    id: 166,
    name: "arrow_teamRed_minus_x",
    filename: "arrow_teamRed.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, 0, -Math.PI / 2),
    collides: true,
  },
  {
    id: 167,
    name: "arrow_teamYellow_z",
    filename: "arrow_teamYellow.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, Math.PI / 2, -Math.PI / 2),
    collides: true,
  },
  {
    id: 168,
    name: "arrow_teamYellow_minus_z",
    filename: "arrow_teamYellow.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, Math.PI / 2, Math.PI / 2),
    collides: true,
  },
  {
    id: 169,
    name: "arrow_teamYellow_x",
    filename: "arrow_teamYellow.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, 0, Math.PI / 2),
    collides: true,
  },
  {
    id: 170,
    name: "arrow_teamYellow_minus_x",
    filename: "arrow_teamYellow.gltf.glb",
    offset: new Vector3(0, 0.5, 0),
    direction: new Vector3(0, 0, -Math.PI / 2),
    collides: true,
  },
  {
    id: 171,
    name: "targetStand_z",
    filename: "targetStand.gltf.glb",
    offset: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 172,
    name: "targetStand_minus_z",
    filename: "targetStand.gltf.glb",
    offset: new Vector3(0, 0, 0),
    scaling: new Vector3(1, 1, 1),
    collides: true,
  },
  {
    id: 173,
    name: "targetStand_x",
    filename: "targetStand.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 174,
    name: "targetStand_minus_x",
    filename: "targetStand.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 175,
    name: "tileHigh_desert_ground",
    filename: "tileHigh_desert_ground.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 2, 2),
    collides: true,
  },
  {
    id: 176,
    name: "tileSlopeLowHigh_desert_z",
    filename: "tileSlopeLowHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 177,
    name: "tileSlopeLowHigh_desert_minus_z",
    filename: "tileSlopeLowHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 178,
    name: "tileSlopeLowHigh_desert_x",
    filename: "tileSlopeLowHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 179,
    name: "tileSlopeLowHigh_desert_minus_x",
    filename: "tileSlopeLowHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 180,
    name: "tileSlopeLowHigh_forest_z",
    filename: "tileSlopeLowHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 181,
    name: "tileSlopeLowHigh_forest_minus_z",
    filename: "tileSlopeLowHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 182,
    name: "tileSlopeLowHigh_forest_x",
    filename: "tileSlopeLowHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 183,
    name: "tileSlopeLowHigh_forest_minus_x",
    filename: "tileSlopeLowHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 184,
    name: "tileSlopeLowHigh_teamBlue_z",
    filename: "tileSlopeLowHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 185,
    name: "tileSlopeLowHigh_teamBlue_minus_z",
    filename: "tileSlopeLowHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 186,
    name: "tileSlopeLowHigh_teamBlue_x",
    filename: "tileSlopeLowHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 187,
    name: "tileSlopeLowHigh_teamBlue_minus_x",
    filename: "tileSlopeLowHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 188,
    name: "tileSlopeLowHigh_teamRed_z",
    filename: "tileSlopeLowHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 189,
    name: "tileSlopeLowHigh_teamRed_minus_z",
    filename: "tileSlopeLowHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 190,
    name: "tileSlopeLowHigh_teamRed_x",
    filename: "tileSlopeLowHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 191,
    name: "tileSlopeLowHigh_teamRed_minus_x",
    filename: "tileSlopeLowHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 192,
    name: "tileSlopeLowHigh_teamYellow_z",
    filename: "tileSlopeLowHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 193,
    name: "tileSlopeLowHigh_teamYellow_minus_z",
    filename: "tileSlopeLowHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 194,
    name: "tileSlopeLowHigh_teamYellow_x",
    filename: "tileSlopeLowHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 195,
    name: "tileSlopeLowHigh_teamYellow_minus_x",
    filename: "tileSlopeLowHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 196,
    name: "tileSlopeLowMedium_teamRed_z",
    filename: "tileSlopeLowMedium_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 197,
    name: "tileSlopeLowMedium_teamRed_minus_z",
    filename: "tileSlopeLowMedium_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 198,
    name: "tileSlopeLowMedium_teamRed_x",
    filename: "tileSlopeLowMedium_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 199,
    name: "tileSlopeLowMedium_teamRed_minus_x",
    filename: "tileSlopeLowMedium_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 200,
    name: "tileSlopeLowMedium_desert_z",
    filename: "tileSlopeLowMedium_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 201,
    name: "tileSlopeLowMedium_desert_minus_z",
    filename: "tileSlopeLowMedium_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 202,
    name: "tileSlopeLowMedium_desert_x",
    filename: "tileSlopeLowMedium_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 203,
    name: "tileSlopeLowMedium_desert_minus_x",
    filename: "tileSlopeLowMedium_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 204,
    name: "tileSlopeLowMedium_forest_z",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 205,
    name: "tileSlopeLowMedium_forest_minus_z",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 206,
    name: "tileSlopeLowMedium_forest_x",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 207,
    name: "tileSlopeLowMedium_forest_minus_x",
    filename: "tileSlopeLowMedium_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 208,
    name: "tileSlopeLowMedium_teamBlue_z",
    filename: "tileSlopeLowMedium_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 209,
    name: "tileSlopeLowMedium_teamBlue_minus_z",
    filename: "tileSlopeLowMedium_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 210,
    name: "tileSlopeLowMedium_teamBlue_x",
    filename: "tileSlopeLowMedium_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 211,
    name: "tileSlopeLowMedium_teamBlue_minus_x",
    filename: "tileSlopeLowMedium_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 212,
    name: "tileSlopeLowMedium_teamYellow_z",
    filename: "tileSlopeLowMedium_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 213,
    name: "tileSlopeLowMedium_teamYellow_minus_z",
    filename: "tileSlopeLowMedium_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 214,
    name: "tileSlopeLowMedium_teamYellow_x",
    filename: "tileSlopeLowMedium_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 215,
    name: "tileSlopeLowMedium_teamYellow_minus_x",
    filename: "tileSlopeLowMedium_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 216,
    name: "tileSlopeMediumHigh_desert_z",
    filename: "tileSlopeMediumHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 217,
    name: "tileSlopeMediumHigh_desert_minus_z",
    filename: "tileSlopeMediumHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 218,
    name: "tileSlopeMediumHigh_desert_x",
    filename: "tileSlopeMediumHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 219,
    name: "tileSlopeMediumHigh_desert_minus_x",
    filename: "tileSlopeMediumHigh_desert.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 220,
    name: "tileSlopeMediumHigh_forest_z",
    filename: "tileSlopeMediumHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 221,
    name: "tileSlopeMediumHigh_forest_minus_z",
    filename: "tileSlopeMediumHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 222,
    name: "tileSlopeMediumHigh_forest_x",
    filename: "tileSlopeMediumHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 223,
    name: "tileSlopeMediumHigh_forest_minus_x",
    filename: "tileSlopeMediumHigh_forest.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 224,
    name: "tileSlopeMediumHigh_teamBlue_z",
    filename: "tileSlopeMediumHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 225,
    name: "tileSlopeMediumHigh_teamBlue_minus_z",
    filename: "tileSlopeMediumHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 226,
    name: "tileSlopeMediumHigh_teamBlue_x",
    filename: "tileSlopeMediumHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 227,
    name: "tileSlopeMediumHigh_teamBlue_minus_x",
    filename: "tileSlopeMediumHigh_teamBlue.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 228,
    name: "tileSlopeMediumHigh_teamRed_z",
    filename: "tileSlopeMediumHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 229,
    name: "tileSlopeMediumHigh_teamRed_minus_z",
    filename: "tileSlopeMediumHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 230,
    name: "tileSlopeMediumHigh_teamRed_x",
    filename: "tileSlopeMediumHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 231,
    name: "tileSlopeMediumHigh_teamRed_minus_x",
    filename: "tileSlopeMediumHigh_teamRed.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 232,
    name: "tileSlopeMediumHigh_teamYellow_z",
    filename: "tileSlopeMediumHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 233,
    name: "tileSlopeMediumHigh_teamYellow_minus_z",
    filename: "tileSlopeMediumHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, -Math.PI / 2, 0),
    collides: true,
  },
  {
    id: 234,
    name: "tileSlopeMediumHigh_teamYellow_x",
    filename: "tileSlopeMediumHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI, 0),
    collides: true,
  },
  {
    id: 235,
    name: "tileSlopeMediumHigh_teamYellow_minus_x",
    filename: "tileSlopeMediumHigh_teamYellow.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 236,
    name: "tileLow_forest_ground",
    filename: "tileLow_forest_ground.glb",
    offset: new Vector3(0, 0, 0),
    size: new Vector3(2, 1, 2),
    collides: true,
  },
  {
    id: 237,
    name: "barrierSmall_x",
    filename: "barrierSmall.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, 0, 0),
    collides: true,
  },
  {
    id: 238,
    name: "barrierSmall_z",
    filename: "barrierSmall.gltf.glb",
    offset: new Vector3(0, 0, 0),
    direction: new Vector3(0, Math.PI / 2, 0),
    collides: true,
  },
];

export const TILE_TYPE_NOTHING = 0;
export const TILE_TYPE_BUTTON = 23;

export const isGround = (id: number) => {
  if ((87 <= id && id <= 96) || id === 175) {
    return true;
  } else {
    return false;
  }
};

export const isStep = (id: number) => {
  switch (id) {
    case 97:
    case 98:
    case 100:
    case 101:
      return true;
    default:
      return false;
  }
};

export const isButton = (id: number) => {
  switch (id) {
    case 23:
    case 24:
    case 25:
      return true;
    default:
      return false;
  }
};

export default tilesets;
