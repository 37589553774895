import chroma from "chroma-js";
import { css } from "@emotion/css";
import { IconCheck, ColorTransparentBtn } from ".";

export const ColorPicker = (props: {
  color: string;
  onChange: (color: string) => void;
}) => {
  const { color, onChange } = props;
  const componentColor = color === "#ffffff" ? "rgba(253,252,253,1)" : color;
  const colorHsl = chroma.valid(componentColor) && chroma(componentColor).hsl();
  const colorRgba =
    chroma.valid(componentColor) && chroma(componentColor).rgba();
  const pickerHsl = [
    [354, 0.86, 0.5],
    [15, 0.97, 0.5],
    [30, 0.98, 0.5],
    [49, 1, 0.5],
    [57, 0.96, 0.5],

    [67, 0.94, 0.5],
    [90, 0.77, 0.5],
    [124, 0.73, 0.5],
    [168, 0.38, 0.5],
    [190, 0.91, 0.5],

    [204, 0.97, 0.5],
    [214, 0.76, 0.5],
    [212, 0.65, 0.5],
    [259, 0.53, 0.5],
    [256, 0.47, 0.5],

    [45, 0.25, 0.5],
    [24, 0.86, 0.5],
    [0, 0.1, 0.1],
    [300, 0.3, 0.99],
  ];

  const pickerHex = pickerHsl.map((c) => chroma(c[0], c[1], c[2], "hsl").hex());
  const whitePicker = ["#fdfcfd", "#e3e2e3", "#cac9ca", "#b1b0b1", "#9f9e9f"];
  const blackPicker = ["#8d8b8b", "#767373", "#605c5c", "#322e2e", "#1c1717"];

  const findMinIndex = () => {
    if (chroma.valid(color) && colorRgba[3] !== 0) {
      const colorDifference = pickerHsl.map((p) =>
        Math.abs(colorHsl[0] - p[0])
      );
      return colorDifference.indexOf(Math.min(...colorDifference));
    } else {
      return -1;
    }
  };

  const minIndex = findMinIndex();
  const handleColoPick = (color: string) => (e) => {
    e.stopPropagation();
    onChange(color);
  };
  const handleTransparent = (e) => {
    e.stopPropagation();
    if (chroma.valid(color)) {
      const rgba = chroma(color).rgba();
      if (rgba[3] === 0) {
        // current color is transparent
        onChange(`rgba(${rgba[0]},${rgba[1]},${rgba[2]},1)`);
      } else {
        onChange(`rgba(${rgba[0]},${rgba[1]},${rgba[2]},0)`);
      }
    }
  };

  const handleClick = (index: number) => (e) => {
    e.stopPropagation();
    onChange(
      chroma.hsl(colorHsl[0], colorHsl[1], (90 - 20 * index) / 100).hex()
    );
  };
  const handlePicker = (color: string) => (e) => {
    e.stopPropagation();
    onChange(color);
  };

  return (
    <div className="flex-row-view items-center">
      <div className="flex-col-center">
        <div className="w-[220px] ml-[12px] flex-row-view flex-wrap">
          {pickerHex.map((color, index) => (
            <button
              key={index}
              className={`opacity-button w-[32px] h-[32px] !rounded-[16px] mr-[12px] mb-[12px] ${css(
                {
                  backgroundColor: color,
                },
                index === 18 && {
                  borderStyle: "solid",
                  borderColor: "#A3ADB6",
                  borderWidth: 2,
                }
              )}`}
              onPointerDown={handleColoPick(color)}
            >
              {minIndex === index && (
                <div className="w-[18px] h-[18px] z-[1]">
                  <IconCheck stroke={index === 18 ? "black" : undefined} />
                </div>
              )}
            </button>
          ))}
          <button
            className="opacity-button w-[32px] h-[32px] !rounded-[16px]"
            onPointerDown={handleTransparent}
          >
            <ColorTransparentBtn />
          </button>
        </div>

        <div className="w-full h-[12px] flex-row-view">
          {colorHsl &&
            colorHsl[0] !== 0 &&
            colorHsl[0] !== 300 &&
            Array(5)
              .fill(0)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex-col-view w-[20%] h-[12px] mt-[14px] cursor-pointer"
                    style={{
                      borderStyle: "solid",
                      borderColor: "#A3ADB6",
                      borderWidth: 1,
                      backgroundColor: `hsl(${colorHsl[0]},${
                        colorHsl[1] * 100
                      }%,${90 - 20 * index}%)`,
                      transform: `scale(${
                        Math.abs(colorHsl[2] * 100 - (90 - 20 * index)) < 10
                          ? 1.4
                          : 1
                      })`,
                      zIndex:
                        Math.abs(colorHsl[2] * 100 - (90 - 20 * index)) < 10 &&
                        1000,
                    }}
                    onPointerDown={handleClick(index)}
                  />
                );
              })}
          {colorHsl[0] === 0 &&
            Array(5)
              .fill(0)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex-col-view w-[20%] h-[12px] mt-[14px] cursor-pointer"
                    style={{
                      borderStyle: "solid",
                      borderColor: "#A3ADB6",
                      borderWidth: 1,
                      backgroundColor: `${blackPicker[index]}`,
                      transform: `scale(${
                        Math.abs(chroma.deltaE(color, blackPicker[index])) < 5
                          ? 1.4
                          : 1
                      })`,
                      zIndex:
                        Math.abs(chroma.deltaE(color, blackPicker[index])) <
                          5 && 1000,
                    }}
                    onPointerDown={handlePicker(blackPicker[index])}
                  />
                );
              })}
          {colorHsl[0] === 300 &&
            Array(5)
              .fill(0)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex-col-view w-[20%] h-[12px] mt-[14px] cursor-pointer"
                    style={{
                      borderStyle: "solid",
                      borderColor: "#A3ADB6",
                      borderWidth: 1,
                      backgroundColor: `${whitePicker[index]}`,
                      transform: `scale(${
                        Math.abs(chroma.deltaE(color, whitePicker[index])) < 5
                          ? 1.4
                          : 1
                      })`,
                      zIndex:
                        Math.abs(chroma.deltaE(color, whitePicker[index])) <
                          5 && 1000,
                    }}
                    onPointerDown={handlePicker(whitePicker[index])}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
};
