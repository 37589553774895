import dayjs from "dayjs";
import I18n from "i18n-js";
import Constants from "common/constant";
import {
  ChatRoom,
  MessageType,
  ChatRoomMessage,
  ChatRoomMemberType,
  FormMessageContact,
  FormMessageContactReply,
  FormMessageKidsReviewRating,
  FormMessageKidsReviewFeedback,
  FormMessageKidsReviewFeedbackReply,
} from "features/user/types";
import { AppUser } from "features/user/types";
import { ChatMessageItem } from "./item";
import { MessageDate } from "./MessageDate";

const contactTitleByType = (type: string) => {
  switch (type) {
    case "how_to":
      return `【${I18n.t("MSG_CONTACT_TYPE_HOW_TO").replaceAll("\n", "")}】`;
    case "bug":
      return `【${I18n.t("MSG_CONTACT_TYPE_BUG").replaceAll("\n", "")}】`;
    case "request":
      return `【${I18n.t("MSG_CONTACT_TYPE_REQUEST").replaceAll("\n", "")}】`;
    case "other":
      return `【${I18n.t("MSG_CONTACT_TYPE_OTHER").replaceAll("\n", "")}】`;
    default:
      return "";
  }
};

const reviewFeedbackTitleByType = (type: string) => {
  switch (type) {
    case "NONE":
      return `・${I18n.t("MSG_REVIEW_FEEDBACK_TYPE_NONE").replaceAll(
        "\n",
        ""
      )}`;
    case "APP_HOW_TO_USE":
      return `・アプリぜんたい：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_APP_HOW_TO_USE"
      ).replaceAll("\n", "")}`;
    case "APP_HARD_TO_USE":
      return `・アプリぜんたい：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_APP_HARD_TO_USE"
      ).replaceAll("\n", "")}`;
    case "COURSE_TOO_DIFFICULT":
      return `・コースでまなぶ：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_DIFFICULT"
      ).replaceAll("\n", "")}`;
    case "COURSE_TOO_EASY":
      return `・コースでまなぶ：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_EASY"
      ).replaceAll("\n", "")}`;
    case "COURSE_TOO_MANY":
      return `・コースでまなぶ：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_MANY"
      ).replaceAll("\n", "")}`;
    case "COURSE_TOO_FEW":
      return `・コースでまなぶ：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_FEW"
      ).replaceAll("\n", "")}`;
    case "CREATOR_HARD_TO_CREATE":
      return `・じゆうにつくる：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_CREATOR_HARD_TO_CREATE"
      ).replaceAll("\n", "")}`;
    case "CREATOR_HARD_TO_USE":
      return `・じゆうにつくる：${I18n.t(
        "MSG_REVIEW_FEEDBACK_TYPE_CREATOR_HARD_TO_USE"
      ).replaceAll("\n", "")}`;
    default:
      return "";
  }
};

const formMessageText = (
  message: ChatRoomMessage,
  display_name: string,
  index?: number
) => {
  switch (message.message_type) {
    case MessageType.CONTACT_INITIAL:
      if (index === 0) {
        return {
          text: I18n.t(
            `MSG_USER_PROFILE_MESSAGE_OFFICIAL_WELCOME_TEXT_${index}`,
            {
              name: display_name,
            }
          ),
        };
      } else {
        return {
          text: I18n.t(
            `MSG_USER_PROFILE_MESSAGE_OFFICIAL_WELCOME_TEXT_${index}`
          ),
        };
      }

    case MessageType.CONTACT:
      const messageContact = message.message_content as FormMessageContact;
      return {
        text: `${I18n.t("MSG_USER_PROFILE_MESSAGE_CONTACT_TITLE", {
          type: contactTitleByType(messageContact.type),
        })}${messageContact.detail === "" ? "" : "\n—————————————————————\n"}${
          messageContact.detail
        }`,
      };

    case MessageType.CONTACT_REPLY:
      const messageContactReply = message.message_content as FormMessageContactReply;
      return {
        text: `${messageContactReply.title}\n—————————————————————\n${messageContactReply.content}`,
      };

    case MessageType.CONTACT_INPUT_AUTO_REPLY:
      return {
        text: I18n.t("MSG_USER_PROFILE_MESSAGE_CONTACT_INPUT_AUTO_REPLY"),
      };

    case MessageType.KIDS_REVIEW_RATING:
      const messageReviewRating = message.message_content as FormMessageKidsReviewRating;
      return {
        text: `${"⭐".repeat(messageReviewRating.rating)}${I18n.t(
          "MSG_USER_PROFILE_MESSAGE_REVIEW_RATING"
        )}`,
      };

    case MessageType.KIDS_REVIEW_RATING_AUTO_REPLY:
      return {
        text: I18n.t("MSG_USER_PROFILE_MESSAGE_REVIEW_RATING_AUTO_REPLY"),
      };

    case MessageType.KIDS_REVIEW_FEEDBACK:
      const messageReviewFeedback = message.message_content as FormMessageKidsReviewFeedback;
      const complaintTypeMessage = messageReviewFeedback.type
        .split(",")
        .map((type) => reviewFeedbackTitleByType(type))
        .join("\n");
      return {
        text: `${I18n.t(
          "MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_TITLE"
        )}\n—————————————————————\n${
          complaintTypeMessage.length > 0
            ? complaintTypeMessage.concat("\n")
            : ""
        }${
          messageReviewFeedback.detail
            ? I18n.t("MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_TYPE_OTHER")
            : ""
        }${messageReviewFeedback.detail}`,
      };

    case MessageType.KIDS_REVIEW_FEEDBACK_REPLY:
      const messageReviewFeedbackReply = message.message_content as FormMessageKidsReviewFeedbackReply;
      return {
        text: `${messageReviewFeedbackReply.title}\n—————————————————————\n${messageReviewFeedbackReply.content}`,
      };

    case MessageType.KIDS_REVIEW_FEEDBACK_AUTO_REPLY:
      return {
        text: I18n.t("MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_AUTO_REPLY"),
      };

    case MessageType.KIDS_REVIEW_FEEDBACK_INPUT_AUTO_REPLY:
      return {
        text: I18n.t(
          "MSG_USER_PROFILE_MESSAGE_REVIEW_FEEDBACK_INPUT_AUTO_REPLY"
        ),
      };

    default:
      return message.message_content;
  }
};

export const ContactChat = (props: {
  user: AppUser;
  chatRoom: ChatRoom;
  chatRoomMessages: ChatRoomMessage[];
}) => {
  const { user, chatRoomMessages } = props;

  return (
    <>
      {chatRoomMessages.map((message, index) => {
        const isFromUser = message.user_type === ChatRoomMemberType.KIDS;
        return (
          <div key={message.id}>
            {index === 0 ? (
              <MessageDate timestamp={message.created_at} />
            ) : (
              dayjs(
                dayjs(message.created_at, "YYYY-MM-DDTHH:mm:ssZ").format(
                  "YYYY-MM-DD"
                )
              ).diff(
                dayjs(
                  dayjs(
                    chatRoomMessages[index - 1].created_at,
                    "YYYY-MM-DDTHH:mm:ssZ"
                  ).format("YYYY-MM-DD")
                ),
                "days"
              ) > 0 && <MessageDate timestamp={message.created_at} />
            )}
            {message.message_type === MessageType.CONTACT_INITIAL ? (
              Array(4)
                .fill(0)
                .map((_, index) => (
                  <ChatMessageItem
                    key={index}
                    isFromUser={isFromUser}
                    name={
                      isFromUser
                        ? user.display_name
                        : I18n.t("MSG_INEXUS_OFFICIAL_CONTACT")
                    }
                    icon={
                      isFromUser
                        ? user.icon
                        : `${Constants.assetHost}/assets/images/app_official_icon.png`
                    }
                    messageContentType={message.message_content_type}
                    messageContent={formMessageText(
                      message,
                      user.display_name,
                      index
                    )}
                    created_at={message.created_at}
                  />
                ))
            ) : (
              <ChatMessageItem
                isFromUser={isFromUser}
                name={
                  isFromUser
                    ? user.display_name
                    : I18n.t("MSG_INEXUS_OFFICIAL_CONTACT")
                }
                icon={
                  isFromUser
                    ? user.icon
                    : `${Constants.assetHost}/assets/images/app_official_icon.png`
                }
                messageContentType={message.message_content_type}
                messageContent={formMessageText(
                  message,
                  user.display_name,
                  index
                )}
                created_at={message.created_at}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
