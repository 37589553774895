import { SVGProps } from "react";

export const IconProjectColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 7h5V3.5h3V7h5v6l-6 6-1-1-3 3H4v-5l2-2.5L4 11V7Z"
      fill="#DDF9EF"
    />
    <path
      d="M20.04 12.13c-.14 0-.28.06-.39.17l-1 1 2.05 2.05 1-1c.22-.21.22-.56 0-.77l-1.28-1.28a.532.532 0 0 0-.38-.17Zm-1.97 1.75L12 19.94V22h2.06l6.06-6.07-2.05-2.05Zm-7.02 5.18c-.18-.06-.36-.06-.55-.06-1.5 0-2.7 1.21-2.7 2.7v.3H4a2 2 0 0 1-2-2v-3.8h.3C3.79 16.2 5 15 5 13.5c0-1.5-1.21-2.7-2.7-2.7H2V7c0-1.1.9-2 2-2h3.04c.24-1.7 1.7-3 3.46-3s3.22 1.3 3.46 3H17a2 2 0 0 1 2 2v4l-.88 1H17V7h-5V5.5c0-.83-.67-1.5-1.5-1.5S9 4.67 9 5.5V7H4v2.12c1.76.68 3 2.38 3 4.38s-1.25 3.7-3 4.38V20h2.12a4.7 4.7 0 0 1 4.38-3c.76 0 1.5.18 2.11.5l-1.56 1.56Z"
      fill="#A69DA3"
    />
  </svg>
);
