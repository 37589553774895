import { FreebuildTitleSmallSvg } from "features/home/assets";
import { TitleLayout } from "features/creator/tutorial/top/stages/Layout";

const TOTAL_STEP = 9;

export const Title = ({ activeStep }: { activeStep: number }) => (
  <TitleLayout steps={TOTAL_STEP} activeStep={activeStep}>
    <div className="flex-col-el flex-center gap-2">
      <div className="flex-col-el flex-center bg-orange/100 rounded-full border-solid border-[4px] border-orange/100 px-1 w-[24px] h-[24px]">
        <p className="text-body text-[18px] text-white leading-[26px]">1</p>
      </div>
      <div className="flex-col-center">
        <div className="flex-col-center w-[106px] h-[24px]">
          <FreebuildTitleSmallSvg />
        </div>
        <p className="text-body text-[18px] leading-6 text-orange/100">
          について
        </p>
      </div>
    </div>
  </TitleLayout>
);
