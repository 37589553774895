import { useRef, useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { IconClose } from "common/assets";
import { btnDelay, useScale } from "common/utils";
import { SlideUpTransition } from "common/elements";

export const QrCodeScan = ({
  open,
  setOpen,
  peerConnect,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  peerConnect: (callee_uid: string) => void;
}) => {
  const ref = useRef(null);
  const [start, setStart] = useState(false);
  const { scale, width, height } = useScale();

  useEffect(() => {
    if (open) {
      setStart(false);
    }
  }, [open]);

  useEffect(() => {
    let html5QrCode: Html5Qrcode;
    if (start && ref.current) {
      const html5QrCode = new Html5Qrcode("qr-reader", {
        verbose: false,
        formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      });
      html5QrCode
        .start(
          { facingMode: "environment" },
          {
            fps: 33,
            videoConstraints: {
              facingMode: "environment",
              width: width,
              height: height,
            },
          },
          (decodedText, decodedResult) => {
            if (
              decodedResult.result.format.formatName === "QR_CODE" &&
              decodedText !== ""
            ) {
              console.log(decodedText);
              html5QrCode.stop();
              peerConnect(decodedText);
            }
          },
          (error) => {}
        )
        .catch((error) => {
          console.log(error);
          setOpen(false);
        });
    }

    return () => {
      if (html5QrCode?.isScanning) {
        console.log("clean up.");
        html5QrCode.stop();
        html5QrCode.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  return (
    <Dialog
      fullScreen
      open={open}
      maxWidth={false}
      sx={{ zIndex: 200000 }}
      TransitionComponent={SlideUpTransition}
      componentsProps={{
        backdrop: {
          style: { backgroundColor: "transparent" },
        },
      }}
      PaperProps={{
        style: { backgroundColor: "transparent", boxShadow: "unset" },
      }}
      onTransitionEnd={() => setStart(true)}
    >
      <div className="flex-col-el flex-center w-full h-full bg-[#000000]">
        <div
          className="flex-row-el flex-center absolute z-50"
          style={{
            width: 50 * scale,
            height: 50 * scale,
            right: 50 * scale,
            top: 50 * scale,
          }}
        >
          <div
            onClick={() => btnDelay(() => setOpen(false))}
            style={{ transform: `scale(${scale})` }}
            className="flex-row-center pointer-events-auto cursor-pointer active:opacity-70"
          >
            <div className="flex-col-el flex-center mb-[3px]">
              <span className="material-symbols-outlined text-[120px] text-white">
                cancel
              </span>
            </div>
          </div>
        </div>

        <div
          ref={ref}
          id="qr-reader"
          className="flex flex-col items-center justify-center w-full h-full"
        />
      </div>
    </Dialog>
  );
};
