import { PointerEvent } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { Vector2D } from "common/types";
import { FadeTransition } from "common/elements";

export const ContextMenu = (props: {
  id: string;
  scale: number;
  postion: Vector2D;
  close: () => void;
  handleDelete: (id: string) => void;
}) => {
  const { id, scale, postion, close, handleDelete } = props;
  const handleDeleleClick = (e: PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();
    close();
    handleDelete(id);
  };

  const handleStopPropagation = (e: PointerEvent<HTMLDivElement>) =>
    e.stopPropagation();

  const handleClose = (e: PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    close();
  };

  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 10000 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div
        className="flex-col-center w-full h-full"
        onPointerDown={handleStopPropagation}
        onPointerUp={handleClose}
        onPointerMove={handleStopPropagation}
      >
        <div
          className="flex-col-center origin-top-left m-0 z-[99999] py-[4px] bg-white pointer-events-auto cursor-pointer rounded-[4px] !absolute top-0 left-0"
          style={{
            boxShadow: "0 0 6px 1px rgb(0 0 0 / 30%)",
            transform: `translate(${postion.x}px, ${postion.y}px) scale(${scale})`,
          }}
          onPointerUp={handleStopPropagation}
          onPointerMove={handleStopPropagation}
          onPointerDown={handleStopPropagation}
        >
          <div
            className="flex-row-center z-[99999] w-[132px] pointer-events-auto px-[15px] py-[6px]"
            onPointerMove={handleStopPropagation}
            onPointerDown={handleStopPropagation}
            onPointerUp={handleDeleleClick}
          >
            <p className="text text-textcolor/black">
              {I18n.t("MSG_CREATOR_MENU_CONTEXT_DELETE")}
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
