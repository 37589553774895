import { SVGProps } from "react";

export const PickupCommentBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 260 66"
    fill="none"
    {...props}
  >
    <path
      stroke="#B7D8F9"
      strokeLinecap="round"
      strokeWidth={3}
      d="M2.296 4.945c-1.474 0 2.958-.06 4.415-.36 2.91-.6 5.41-1.996 8.41-1.996H42.99c4.255 0 9.079-.075 13.268.883C60.992 4.555 64.941 2 69.528 2c4.916 0 9.781.589 14.699.589 4.67 0 9.282-.589 13.96-.589 6.784 0 13.453 1.767 20.223 1.767h17.313c7.526 0 15.066-.167 22.591.033 9.231.244 18.464 1.145 27.696 1.145 1.199 0 2.476.144 3.675-.033 1.552-.229 2.652-1.127 4.291-1.145 3.321-.037 6.643 0 9.964 0h25.945c5.804 0 11.595.589 17.363.589 3.594 0 7.161-.59 10.753-.59M2 31.162h44.147c7.554 0 15.407.9 22.887-.567 3.992-.783 8.01-.568 12.038-.568 8.84 0 17.581 1.703 26.454 1.703 10.048 0 20.083 1.059 30.119 1.702 2.655.17 5.215.695 7.888.505 3.005-.214 5.767-.517 8.743-.946 1.969-.284 7.746-.41 9.931-.41 1.125.126 3.14.14 4.091.41 3.351.95 6.535 1.009 9.951 1.009 13.907 0 28.059-.278 41.938-1.419 9.445-.777 18.853.284 28.299.284 3.231 0 6.306-.568 9.514-.568M2 60.434c2.855 0 5.597-.963 8.43-1.334 2.823-.37 5.628.375 8.431.785 7.832 1.142 15.774.552 23.63 1.294 10.313.974 20.738 2.079 31.084 2.079 7.723 0 15.485.935 23.19.706 3.148-.094 6.535-1.525 9.678-2.118 5.207-.984 10.647-1.14 15.884-.55 10.796 1.217 21.705.55 32.525.55 5.137 0 9.888-3.165 15.127-2.51 4.584.572 8.979 1.804 13.611 1.804 24.779 0 49.605-.706 74.41-.706"
    />
  </svg>
);
