import { SVGProps } from "react";

export const IconShapeParts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m13 2-5 8h10l-5-8ZM18 21a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM9.667 13H4.333C3.597 13 3 13.597 3 14.333v5.334C3 20.403 3.597 21 4.333 21h5.334c.736 0 1.333-.597 1.333-1.333v-5.334C11 13.597 10.403 13 9.667 13Z"
      fill="#94C4F7"
      stroke="#3F4A61"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
