import { Category } from "common/blockly";
import { Control } from "./Control";
import { Logic } from "./Logic";
import { Math } from "./Math";

export const Common = () => {
  return (
    <>
      <Control />
      <Logic />
      <Math />
      <Category
        name="へんすう"
        categorystyle="variable_category"
        custom="VARIABLE"
        toolboxitemid="variable"
      />

      <Category
        name="かんすう"
        categorystyle="procedure_category"
        custom="PROCEDURE"
        toolboxitemid="procedure"
      />
    </>
  );
};
