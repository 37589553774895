import { IconCrown, IconSmallCrown } from "features/creator/assets";

export const NumberPlateGreen = ({ number }: { number: number }) => (
  <div className="flex-col-el flex-center">
    <div className="flex-col-el absolute bg-green/60 w-[32px] h-[32px] rounded-full" />
    <div
      style={{
        filter:
          "drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
      }}
    >
      <p
        className="text-body text-[#2DA074] text-[20px] z-50"
        style={{ WebkitTextStroke: "6px #FEFDFE" }}
      >
        <span
          style={{
            WebkitTextStroke: "0",
            position: "absolute",
          }}
        >
          {number}
        </span>
        {number}
      </p>
    </div>
  </div>
);

export const NumberPlateOrange = ({ number }: { number: number }) => (
  <div
    className="flex-col-center w-[32px] h-[32px] rounded-full"
    style={{
      background:
        "radial-gradient(100% 100% at 0% 0%, #FFD1A1 0%, #F8BD7E 59.9%, #FF8300 100%)",
    }}
  >
    <div
      className="flex-col-center h-[30px]"
      style={{
        filter:
          "drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
      }}
    >
      <p
        className="text-body text-[22px] text-[#F27D00] leading-8"
        style={{ WebkitTextStroke: "6px #FEFDFE" }}
      >
        <span
          className="inline-block leading-8"
          style={{
            WebkitTextStroke: "0",
            position: "absolute",
          }}
        >
          {number}
        </span>
        {number}
      </p>
    </div>
  </div>
);

export const DetailTitle = ({ ranking }: { ranking: number }) => (
  <div className="flex-col-el flex-center w-[64px] h-[64px]">
    {ranking > 9 ? (
      <div className="flex-col-el flex-center">
        <div className="flex-col-el absolute bg-green/60 w-[64px] h-[64px] rounded-full" />
        <div
          style={{
            filter:
              "drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
          }}
        >
          <p
            className="text-body text-[#2DA074] text-[32px] z-50"
            style={{ WebkitTextStroke: "6px #FEFDFE" }}
          >
            <span
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {ranking}
            </span>
            {ranking}
          </p>
        </div>
      </div>
    ) : ranking < 4 ? (
      <div
        className="flex-col-el flex-center w-[64px] h-[64px] rounded-full border-solid border-white border-4"
        style={{
          background:
            ranking === 1
              ? "radial-gradient(100% 100% at 0% 0%, #F9EB74 0%, #EFD851 61.46%, #CC9B1C 100%)"
              : ranking === 2
              ? "radial-gradient(100% 100% at 0% 0%, #C9D4DE 0%, #ADB7C0 56.77%, #667786 100%)"
              : "radial-gradient(100% 100% at 0% 0%, #F2C3AC 0%, #DB9675 63.02%, #A74210 100%)",
        }}
      >
        <div className="flex-col-el flex-center absolute">
          <IconCrown />
        </div>

        <p
          className={`text-body text-[36px] z-50 ${
            ranking === 1
              ? "text-[#9B902B]"
              : ranking === 2
              ? "text-[#5F72B7]"
              : "text-[#AC5D57]"
          }`}
          style={{
            WebkitTextStroke: "6px #FEFDFE",
          }}
        >
          <span
            style={{
              WebkitTextStroke: "0",
              position: "absolute",
            }}
          >
            {ranking}
          </span>
          {ranking}
        </p>
      </div>
    ) : (
      <div
        className="flex-col-center w-[64px] h-[64px] rounded-full"
        style={{
          background:
            "radial-gradient(100% 100% at 0% 0%, #FFD1A1 0%, #F8BD7E 59.9%, #FF8300 100%)",
        }}
      >
        <div
          className="flex-col-center h-[48px]"
          style={{
            filter:
              "drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
          }}
        >
          <p
            className="text-body text-[32px] text-[#F27D00] leading-8"
            style={{ WebkitTextStroke: "6px #FEFDFE" }}
          >
            <span
              className="inline-block leading-8"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {ranking}
            </span>
            {ranking}
          </p>
        </div>
      </div>
    )}
  </div>
);

export const RankingPlate = ({ ranking }: { ranking: number }) => (
  <div className="flex-col-el flex-center w-[32px] h-[32px]">
    {ranking > 9 ? (
      <NumberPlateGreen number={ranking} />
    ) : ranking < 4 ? (
      <div
        className="flex-col-el flex-center w-[32px] h-[32px] rounded-full"
        style={{
          background:
            ranking === 1
              ? "radial-gradient(100% 100% at 0% 0%, #F9EB74 0%, #EFD851 61.46%, #CC9B1C 100%)"
              : ranking === 2
              ? "radial-gradient(100% 100% at 0% 0%, #C9D4DE 0%, #ADB7C0 56.77%, #667786 100%)"
              : "radial-gradient(100% 100% at 0% 0%, #F2C3AC 0%, #DB9675 63.02%, #A74210 100%)",
        }}
      >
        <div className="flex-col-el flex-center absolute">
          <IconSmallCrown />
        </div>

        <p
          className={`text-body text-[20px] z-50 ${
            ranking === 1
              ? "text-[#9B902B]"
              : ranking === 2
              ? "text-[#5F72B7]"
              : "text-[#AC5D57]"
          }`}
          style={{
            WebkitTextStroke: "6px #FEFDFE",
          }}
        >
          <span
            style={{
              WebkitTextStroke: "0",
              position: "absolute",
            }}
          >
            {ranking}
          </span>
          {ranking}
        </p>
      </div>
    ) : (
      <NumberPlateOrange number={ranking} />
    )}
  </div>
);
