import dayjs from "dayjs";
import I18n from "i18n-js";
import Constants from "common/constant";
import { AppUser } from "features/user/types";
import { ChatRoom, ChatRoomMessage } from "features/user/types";
import { ChatMessageItem } from "./item";
import { MessageDate } from "./MessageDate";

export const NotificationChat = (props: {
  user: AppUser;
  chatRoom: ChatRoom;
  chatRoomMessages: ChatRoomMessage[];
}) => {
  const { chatRoomMessages } = props;

  return (
    <>
      {chatRoomMessages.map((message, index) => {
        return (
          <div key={message.id}>
            {index === 0 ? (
              <MessageDate timestamp={message.created_at} />
            ) : (
              dayjs(
                dayjs(message.created_at, "YYYY-MM-DDTHH:mm:ssZ").format(
                  "YYYY-MM-DD"
                )
              ).diff(
                dayjs(
                  dayjs(
                    chatRoomMessages[index - 1].created_at,
                    "YYYY-MM-DDTHH:mm:ssZ"
                  ).format("YYYY-MM-DD")
                ),
                "days"
              ) > 0 && <MessageDate timestamp={message.created_at} />
            )}
            <ChatMessageItem
              isFromUser={false}
              name={I18n.t("MSG_INEXUS_OFFICIAL")}
              icon={`${Constants.assetHost}/assets/images/app_pluzze_icon.png`}
              messageContentType={message.message_content_type}
              messageContent={message.message_content}
              created_at={message.created_at}
            />
          </div>
        );
      })}
    </>
  );
};
