import { SVGProps } from "react";

export const Icon4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={96}
    height={96}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={48}
      cy={48}
      r={46}
      fill="#FDA3A0"
      stroke="#667786"
      strokeWidth={4}
    />
    <path
      d="M16 42c.974 1.333 4.385 4 10.23 4 5.847 0 8.283-2.667 8.77-4M60 42c.974 1.333 4.385 4 10.23 4 5.847 0 8.283-2.667 8.77-4M37 74l11.35-6L59 74"
      stroke="#667786"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
