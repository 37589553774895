import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  const { width, height, scale } = useScale();
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const btn = document.getElementById("user-project-panel-new-app-btn");
      const rect = btn.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 25 * scale,
        y: rect.y - 25 * scale,
        width: rect.width + 50 * scale,
        height: rect.height + 50 * scale,
      });
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout
        transparent
        position={{
          x: width / 2 - 560 / 2 - 60,
          y: height / 2 - 336 / 2 + 120,
        }}
        next={next}
        close={close}
      >
        <Title activeStep={3} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "あたらしくアプリを\nつくるには"
              : "あたらしくアプリを\n作るには"}
          </p>

          <div className="flex-row-el items-end gap-2">
            <div className="flex-col-center upsilon w-[58px] h-[64px]">
              <div className="flex-col-el flex-center !flex gap-3 btn btn-primary !p-0 !m-0 w-[116px] h-[128px] scale-50">
                <div className="flex-col-center my-4">
                  <div className="flex-row-center">
                    <div className="flex-col-el flex-center w-[40px] h-[9px] bg-white absolute" />
                    <div className="flex-col-center w-[9px] h-[40px] bg-white" />
                  </div>

                  <div className="flex-col-el flex-center h-[40px] mt-4">
                    <p className="text-body text-white text-center text-[20px] leading-[20px]">
                      {I18n.t("MSG_CREATOR_CREATE_APP")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              {I18n.locale === "ja" ? "を おします。" : "を押します。"}
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Step;
