/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";
import { Asset } from "app/types";
import { uploadImageAsset } from "app/api";
import { RootState, AppDispatch } from "app/store";
import { ComponentTypeIds, ComponentCategory } from "common/components";
import {
  DrawingModel,
  GroupingDefault,
  ActionCommandType,
} from "features/creator/types";
import { actions } from "features/creator/slice";
import { useActionCommand } from "features/creator/utils";

export const FreeDrawingPanel = ({
  screenId,
  width,
  height,
}: {
  screenId: string;
  width: number;
  height: number;
}) => {
  const queryClient = useQueryClient();
  const handleAction = useActionCommand();
  const dispatch = useDispatch<AppDispatch>();
  const ref = useRef<HTMLCanvasElement>(null);
  const user = useSelector((state: RootState) => state.user.appUser);
  const [fabricCanvas, setFabricCanvas] = useState<fabric.Canvas>(null);
  const drawing = useSelector((state: RootState) => state.creator.drawing);
  const [show, setShow] = useState(drawing.enable);
  const drawingModelRef = useRef(drawing.model);
  const setDrawingModelRef = (mode: DrawingModel) => {
    drawingModelRef.current = mode;
  };

  useEffect(() => {
    if (drawing.enable) {
      setShow(true);
      if (ref.current && !fabricCanvas) {
        dispatch(
          actions.updateSelectedComponentId({
            screenId: screenId,
            id: screenId,
          })
        );
        dispatch(actions.updateGrouping(GroupingDefault));

        //@ts-ignore
        const canvas = new fabric.Canvas(ref.current, { isDrawingMode: true });
        canvas.freeDrawingBrush.width = drawing.pen.radius;
        canvas.freeDrawingBrush.color = drawing.color;
        setFabricCanvas(canvas);
      }
    } else {
      if (fabricCanvas) {
        const objects = fabricCanvas.toObject().objects;
        if (objects.length > 0) {
          // create new drawing component
          const lefts = fabricCanvas.toObject().objects.map((o) => o.left);
          const tops = fabricCanvas.toObject().objects.map((o) => o.top);
          const rights = fabricCanvas
            .toObject()
            .objects.map((o) => o.left + o.width + drawing.pen.radius);
          const bottoms = fabricCanvas
            .toObject()
            .objects.map((o) => o.top + o.height + drawing.pen.radius);

          const minX = Math.min(...lefts);
          const minY = Math.min(...tops);
          const maxX = Math.max(...rights);
          const maxY = Math.max(...bottoms);
          const width = maxX - minX;
          const height = maxY - minY;

          const svg = fabricCanvas.toSVG({
            viewBox: {
              x: minX,
              y: minY,
              width: maxX - minX,
              height: maxY - minY,
            },
            width: "100%",
            height: "100%",
          });

          const uuid = uuidv4();
          const fileBlob = new Blob([svg], {
            type: "image/svg+xml;charset=utf-8",
          });
          const filename = `${I18n.t(
            "MSG_CREATOR_COMPONENT_NAME_DRAWING"
          )}_${dayjs().format("YYYY_MM_DD_HH_mm_ss")}`;
          const object_key = `users/${user.active.uid}/assets/drawing/${uuid}.svg`;
          uploadImageAsset({
            uid: user.active.uid,
            is_sync: false,
            fileBlob,
            object_key,
            filename,
            tags: I18n.t("MSG_CREATOR_COMPONENT_NAME_DRAWING"),
            metadata: {
              width: String(width),
              height: String(height),
            },
            contentType: "image/svg+xml",
          }).then((response) => {
            if (
              queryClient.getQueryData([
                "creator/getUserAssets",
                user.active.uid,
              ])
            ) {
              queryClient.setQueryData(
                ["creator/getUserAssets", user.active.uid],
                (old: Asset[]) => [...old, response.data]
              );
            }
          });
          handleAction({
            type: ActionCommandType.ADD_COMPONENT,
            componentType: ComponentTypeIds.DRAWING,
            category: ComponentCategory.ACTION,
            screenId: screenId,
            position: { x: minX, y: minY },
            drawing: {
              svg,
              width,
              height,
            },
          });
        }

        fabricCanvas.clear();
        fabricCanvas.setWidth(0);
        fabricCanvas.setHeight(0);
        fabricCanvas.dispose();
        setFabricCanvas(null);
        ref.current.width = 0;
        ref.current.height = 0;
        ref.current = null;
        setShow(false);
      }
    }
  }, [drawing.enable, ref.current]);

  useEffect(() => {
    if (fabricCanvas) {
      setDrawingModelRef(drawing.model);
      if (drawing.model === DrawingModel.PEN) {
        //@ts-ignore
        fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas);
        fabricCanvas.freeDrawingBrush.width = drawing.pen.radius;
        fabricCanvas.freeDrawingBrush.color = drawing.color;
      } else if (drawing.model === DrawingModel.ERASER) {
        //@ts-ignore
        fabricCanvas.freeDrawingBrush = new fabric.EraserBrush(fabricCanvas);
        fabricCanvas.freeDrawingBrush.width = drawing.eraser.radius;
      }
    }
  }, [drawing.model]);

  useEffect(() => {
    if (fabricCanvas) {
      if (drawing.model === DrawingModel.PEN) {
        fabricCanvas.freeDrawingBrush.width = drawing.pen.radius;
        fabricCanvas.freeDrawingBrush.color = drawing.color;
      } else if (drawing.model === DrawingModel.ERASER) {
        fabricCanvas.freeDrawingBrush.width = drawing.eraser.radius;
      }
    }
  }, [drawing.color, drawing.pen.radius, drawing.eraser.radius]);

  useEffect(() => {
    if (fabricCanvas) {
      fabricCanvas.clear();
    }
  }, [drawing.reset]);

  return drawing.enable || show ? (
    <div
      id={`drawing-panel-${screenId}`}
      className="w-full h-full flex-row-view !absolute top-0 left-0 z-50"
    >
      <div
        className="w-full h-full flex-row-view !absolute top-0 left-0"
        style={{
          backgroundImage:
            "linear-gradient(0deg, transparent 31px, #d9effa 32px), linear-gradient(90deg,  transparent 31px, #d9effa 32px)",
          backgroundSize: "32px 32px",
        }}
      />
      <canvas id="drawing-canvas" ref={ref} width={width} height={height} />
    </div>
  ) : (
    <></>
  );
};
