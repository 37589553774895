import { EntityState } from "@reduxjs/toolkit";
import {
  ComponentTypeIds,
  ComponentCategory,
  ComponentProperty,
} from "common/components";
import { Vector2D } from "common/types";
import { ProjectReaction } from "features/reaction/types";

export enum ProjectType {
  BASIC = "basic",
  MAP = "map",
  TEMPLATE = "template",
}

export type ProjectEntity = {
  project_id: string;
  inquiry_id: string;
  type: ProjectType;
  tags: string;
  category: string;
  author_uid: string;
  author_name: string;
  author_icon: string;
  copied_project_id: string;
  original_project_id: string;
  original_project_created_at: string;
  original_author_uid: string;
  original_author_name: string;
  original_author_icon: string;
  name: string;
  version: number;
  description: string;
  capture?: string;
  cover?: string;
  blockly_capture: string;
  reaction: ProjectReaction;
  published: boolean;
  copied: number;
  favorited: number;
  viewed: number;
  enable_copied: boolean;
  is_favorited: boolean;
  created_time: string;
  updated_time: string;
  deleted_at?: string;
};

export type CreatorOptions = {
  margin: number;
  headerHeight: number;
  componentListWidth: number;
  tabHeight: number;
  projectId: string;
};

export enum ScreenOrientation {
  LANDSCAPE = "LANDSCAPE",
  PORTRAIT = "PORTRAIT",
}

// 常に1280*960サイズのscaleが１である座標
export type CanvasEntity = {
  id: string;
  x: number;
  y: number;
};

export type ScreenComponent = {
  id: string;
  typeId: string;
  categoryId: string;
  name: string;
};
export type ScreensEntity = {
  id: string;
  name: string;
  order?: number;
  children: ScreenComponent[]; // component id
  childrenOrder: string[]; // component id
};

export type ComponentsEntityData = {
  [componentId: string]: {
    id: string;
    deleted: boolean;
  }[];
};
export type ComponentsEntity = {
  screenId: string;
  data: ComponentsEntityData;
};

export type PropertiesEntity = {
  id: string;
  typeId: string;
  categoryId: string;
  screenId: string;
  property: ComponentProperty;
};

export type BlocklyEntity = {
  screenId: string;
  xmlText?: string;
};

export enum ClipboardTargetType {
  SCREEN = "screen",
  COMPONENT = "component",
  GROUPING = "grouping",
}

export type ClipboardScreen = {
  type: ClipboardTargetType.SCREEN;
  screen: ScreensEntity;
  components: ComponentsEntity;
  propertiesEntities: { [componentId: string]: PropertiesEntity };
};

export type ClipboardComponent = {
  type: ClipboardTargetType.COMPONENT;
  propertiesEntity: PropertiesEntity;
};

export interface ClipboardGrouping {
  screenId: string;
  type: ClipboardTargetType.GROUPING;
  position: Vector2D;
  size: Vector2D;
  components: { componentId: string; propertiesEntity: PropertiesEntity }[];
}

export type ClipboardComponentType =
  | ClipboardScreen
  | ClipboardComponent
  | ClipboardGrouping;

export enum ActionCommandType {
  ADD_COMPONENT = "ADD_COMPONENT",
  REMOVE_COMPONENT = "REMOVE_COMPONENT",
  ADD_SCREEN = "ADD_SCREEN",
  REMOVE_SCREEN = "REMOVE_SCREEN",
  REMOVE_GROUPING = "REMOVE_GROUPING",
  CHANGE_CANVAS = "CHANGE_CANVAS",
  CHANGE_BLOCKLY = "CHANGE_BLOCKLY",
  CHANGE_PROPERTY = "CHANGE_PROPERTY",
  COPY = "COPY",
  PASTE = "PASTE",
  UNDO = "UNDO",
  REDO = "REDO",
  INSERT_TEMPLATE = "INSERT_TEMPLATE",
}

export interface AddComponentCommand {
  type: ActionCommandType.ADD_COMPONENT;
  screenId: string;
  category: ComponentCategory;
  componentType: ComponentTypeIds;
  position?: Vector2D;
  image?: {
    url: string;
    size: number;
    width: number;
    height: number;
  };
  drawing?: {
    svg: string;
    width: number;
    height: number;
  };
}

export interface RemoveComponentCommand {
  type: ActionCommandType.REMOVE_COMPONENT;
  screenId: string;
  componentId: string;
}

export interface AddScreenCommand {
  type: ActionCommandType.ADD_SCREEN;
}

export interface RemoveScreenCommand {
  type: ActionCommandType.REMOVE_SCREEN;
  screenId: string;
}

export interface RemoveGroupingCommand {
  type: ActionCommandType.REMOVE_GROUPING;
  screenId: string;
  componentIds: string[];
}

export interface ChangeCanvasCommand {
  type: ActionCommandType.CHANGE_CANVAS;
  screenId: string;
  canvas: CanvasEntity;
}

export interface ChangeBlocklyCommand {
  type: ActionCommandType.CHANGE_BLOCKLY;
  screenId: string;
  text: string;
}

export interface ChangePropertyCommand {
  type: ActionCommandType.CHANGE_PROPERTY;
  componentId: string;
  property: ComponentProperty;
  record?: boolean;
}

export interface CopyCommand {
  type: ActionCommandType.COPY;
  targetType: ClipboardTargetType;
}

export interface PasteCommand {
  type: ActionCommandType.PASTE;
  targetType: ClipboardTargetType;
}

export interface UndoCommand {
  type: ActionCommandType.UNDO;
}

export interface RedoCommand {
  type: ActionCommandType.REDO;
}

export interface InsertTemplateCommand {
  type: ActionCommandType.INSERT_TEMPLATE;
  projectId: string;
}

export type ActionCommand =
  | AddComponentCommand
  | RemoveComponentCommand
  | AddScreenCommand
  | RemoveScreenCommand
  | RemoveGroupingCommand
  | ChangeCanvasCommand
  | ChangeBlocklyCommand
  | ChangePropertyCommand
  | CopyCommand
  | PasteCommand
  | UndoCommand
  | RedoCommand
  | InsertTemplateCommand;

export interface ScreenSize {
  screenSize: {
    width: number;
    height: number;
    orientation: ScreenOrientation;
  };
}

export enum DrawingModel {
  PEN = "pen",
  ERASER = "eraser",
}

export type FreeDrawingTool = {
  enable: boolean;
  pen: {
    radius: number;
  };
  eraser: {
    radius: number;
  };
  model: DrawingModel;
  color: string;
  reset: boolean;
};

export const FreeDrawingToolDefault = {
  enable: false,
  pen: {
    radius: 5,
  },
  eraser: {
    radius: 20,
  },
  model: DrawingModel.PEN,
  color: "#ed1228",
  reset: false,
};

type PanelCollapse = {
  material: boolean;
  blocklyWorkspace: boolean;
  property: boolean;
  componentList: boolean;
};

export const PanelCollapseDefault = {
  material: true,
  blocklyWorkspace: true,
  property: true,
  componentList: true,
};

export type Grouping = {
  enable: boolean;
  componentIds: string[];
  position: Vector2D;
  size: Vector2D;
};

export const GroupingDefault = {
  enable: false,
  componentIds: [],
  position: { x: 0, y: 0 },
  size: { x: 0, y: 0 },
};

export type HistoryStackType = {
  before: {
    selectedScreenId?: string;
    selectedComponentId?: string;
    defaultScreenId?: string;
    grouping?: Grouping;
    clipboard?: ClipboardComponentType;
    canvas?: CanvasEntity;
    screen?: ScreensEntity;
    components?: ComponentsEntity;
    properties: PropertiesEntity[];
    blockly?: BlocklyEntity;
  };
  after: {
    selectedScreenId?: string;
    selectedComponentId?: string;
    defaultScreenId?: string;
    grouping?: Grouping;
    clipboard?: ClipboardComponentType;
    canvas?: CanvasEntity;
    screen?: ScreensEntity;
    components?: ComponentsEntity;
    properties: PropertiesEntity[];
    blockly?: BlocklyEntity;
  };
};

export enum ProjectPanelType {
  USER = "USER",
  SHARED = "SHARED",
  TRASHCAN = "TRASHCAN",
  NONE = "NONE",
}

export enum SortedOrder {
  DATE = "date",
  FAVORITED = "favorited",
}

export enum UserSortedOrder {
  DATE = "date",
  VIEWED = "viewed",
  FAVORITED = "favorited",
}

export enum DisplayMode {
  RANKING = "RANKING",
  PICKUP = "PICKUP",
  CAPTURE = "CAPTURE",
  DETAIL = "DETAIL",
  NONE = "NONE",
}

export enum KeyDirection {
  UP = "UP",
  RIGHT = "RIGHT",
  DOWN = "DOWN",
  LEFT = "LEFT",
  NONE = "NONE",
}

export interface CreatorInitialState extends ScreenSize {
  tutorialMenu: { creator_top_menu: boolean; creator_panel_menu: boolean };
  projectPanel: ProjectPanelType;
  panelTabIndex: number;
  keyword: string;
  sortedOrderPanel: boolean;
  projectSortedOrder: SortedOrder;
  publishedProjectFilter: { onlyOfficial: boolean; userFavorited: boolean };
  userProjectSortedOrder: UserSortedOrder;
  userProjectFilter: { userFavorited: boolean };
  cursor: KeyDirection;
  action: { back: boolean; confirm: boolean };
  displayMode: DisplayMode;
  selectedProjectId: string;
  recommendReturnToHome: boolean;
  recommendProject: ProjectEntity;
  isCopyProject: boolean;
  projects: EntityState<ProjectEntity>;
  focus: boolean; // キャンパスでスクリーンを切り替えするため
  capture: boolean;
  canvasScale: number; // キャンパスの表示を拡大と縮小を行う値、アプリのスクリーン自体のデータをスケール変更しない、データベースに保存しない
  panelCollapse: PanelCollapse;
  editingProjectId: string;
  selectedScreenId: string;
  selectedComponentId: string;
  defaultScreenId: string;
  grouping: Grouping;
  clipboard: ClipboardComponentType;
  undoStacks: HistoryStackType[];
  redoStacks: HistoryStackType[];
  template: { newComponentIds: string[]; newBlockIds: string[] };
  drawing: FreeDrawingTool;
  canvas: EntityState<CanvasEntity>;
  screens: EntityState<ScreensEntity>;
  components: EntityState<ComponentsEntity>;
  properties: EntityState<PropertiesEntity>;
  blockly: EntityState<BlocklyEntity>;
}

export enum TabIndex {
  NONE = "none",
  EVENT = "event",
  BASIC = "basic",
  SIZE = "size",
  POSITION = "position",
  APPEARANCE = "appearance",
  ANIMATION = "animation",
  SOUND = "sound",
  CAMERA = "camera",
  COLOR = "color",
  CONTROL = "control",
  LOGIC = "logic",
  MATH = "math",
  VARIABLE = "variable",
  PROCEDURE = "procedure",
}

export enum StockTabIndex {
  ALBUM = "ALBUM",
  DRAWING = "DRAWING",
}
