import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { RouteProps } from "./type";
import { GoBackBtn } from "./GoBackBtn";

export const Notification = (props: RouteProps) => {
  const { navigate } = props;
  const handleGoBack = () => navigate("/");
  const notification = useSelector(
    (state: RootState) => state.config.notification.parents
  );

  return (
    <div className="flex flex-col items-center w-full h-full mt-[56px]">
      <div className="flex-col-view items-center justify-start w-[880px] h-full pb-[80px]">
        <div className="flex-col-el flex-1 w-full h-full overflow-hidden mb-2">
          <div className="flex-col-el items-center w-full h-[550px] overflow-hidden">
            <div className="flex-col-view h-full invisible-scrollbar">
              {notification.map((content, index) => (
                <div key={index} className="w-[880px] py-[20px]">
                  <div className="flex-row-view !flex-1">
                    <p className="text text-white !text-left !mb-[16px] !leading-[30px] bg-brown/80 rounded-[4px] !px-[8px]">
                      {content["ja_kanji"].date}
                    </p>
                  </div>
                  <div className="flex-row-view !mb-[12px] flex-wrap">
                    <div>
                      <p className="w-full text text-brown/80 !text-left !text-[28px] !leading-[42px] border-solid border-brown/80 border-0 !border-b-[3px]">
                        {content["ja_kanji"].title}
                      </p>
                    </div>
                  </div>
                  <div>
                    {content["ja_kanji"].content
                      .split("\n")
                      .map((p: string, index: number) => (
                        <p
                          key={index}
                          className="text text-textcolor/black !text-left !mb-[12px]"
                        >
                          {p}
                        </p>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <GoBackBtn handleGoBack={handleGoBack} />
      </div>
    </div>
  );
};
