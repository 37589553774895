import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

export const performMarkJitter = async (
  scene: Scene,
  character: AbstractMesh,
  frames: number
) => {
  const markAnimation = new Animation(
    "markAnimation",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );
  const mark = scene.getMeshByName("mark");
  mark.setEnabled(true);
  mark.position = new Vector3().copyFrom(character.position);
  mark.position.y += 4;

  const makrAnimationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    makrAnimationKeys.push({
      frame: i,
      value: mark.position
        .multiply(new Vector3(10, 10, 10))
        .add(new Vector3(0, (i % 2 === 0 ? 0.2 : -0.2) * 10, 0))
        .divide(new Vector3(10, 10, 10)),
    });
  }
  markAnimation.setKeys(makrAnimationKeys);
  const animation = scene.beginDirectAnimation(
    mark,
    [markAnimation],
    0,
    frames,
    false
  );
  await animation.waitAsync();
  mark.setEnabled(false);
};
