import { PointerEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "rc-slider";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { ZoomInSvg, ZoomOutSvg } from "features/creator/assets";

export const ZoomPanel = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const canvasScale = useSelector(
    (state: RootState) => state.creator.canvasScale
  );

  const handleCanvasScale = (scale: number) => {
    play();
    dispatch(actions.updateScreenScale(scale));
  };

  const handleClickCanvasScale = (scale: number) => (e: PointerEvent) => {
    play();
    e.stopPropagation();
    dispatch(actions.updateScreenScale(scale));
  };

  return (
    <div className="flex-col-center w-[28px] h-[280px] mb-3">
      <div className="flex-col-el flex-center h-full relative upsilon-square">
        <button
          id="creator-zoom-in-btn"
          className="btn btn-primary"
          onPointerDown={handleClickCanvasScale(canvasScale + 0.1)}
        >
          <div className="flex-col-el flex-center relative w-[28px] h-[28px] mb-[3px]">
            <ZoomInSvg />
          </div>
        </button>
      </div>

      <div className="flex-row-center w-[28px] h-[160px]">
        <Slider
          vertical
          min={0.1}
          max={1.5}
          step={0.1}
          value={canvasScale}
          onChange={(valueY) => handleCanvasScale(valueY as number)}
          trackStyle={{
            width: 4,
            backgroundColor: "#80EAC1",
          }}
          handleStyle={{
            height: 18,
            width: 18,
            borderColor: "transparent",
            opacity: 1,
            marginLeft: -7,
            backgroundColor: "#80EAC1",
          }}
          railStyle={{ width: 4, backgroundColor: "#DBD8DA" }}
        />
      </div>

      <div className="flex-col-el flex-center h-full relative upsilon-square">
        <button
          id="creator-zoom-out-btn"
          className="btn btn-primary"
          onPointerDown={handleClickCanvasScale(canvasScale - 0.1)}
        >
          <div className="flex-col-el flex-center relative w-[28px] h-[28px] mb-[3px]">
            <ZoomOutSvg />
          </div>
        </button>
      </div>
    </div>
  );
};
