import { SVGProps } from "react";

export const LogoPlate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 156 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <filter id="top-shadow">
        <feDropShadow
          dx="1"
          dy="1"
          stdDeviation="5"
          floodColor="rgb(63, 74, 97)"
          floodOpacity={0.5}
        />
      </filter>
    </defs>

    <path
      filter={"url(#top-shadow)"}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 0h55.999v55.693L156 56l-.001.307V156s-3.81-36.875-19.002-57.96C127.131 106.728 114.181 112 100 112c-30.928 0-56-25.072-56-56a55.768 55.768 0 0 1 12.554-35.336C35.386 4.264 0 0 0 0h100Z"
      fill="#FEFDFE"
    />
  </svg>
);
