/* eslint-disable no-new-func */
import { useState } from "react";
import I18n from "i18n-js";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  let target: HTMLImageElement;
  const [clickedIndex, setClickedIndex] = useState(0);
  const [result, setResult] = useState([false, false]);
  const [clicked, setClicked] = useState([false, false]);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const targetEles = [50, 100];

  const handleEvent = (index: number) =>
    new Function(
      "anime",
      "sleep",
      "highlightBlock",
      "setRunning",
      "setSuccess",
      "setGameOver",
      "clickedIndex",
      "setClickedIndex",
      "target",
      "targetEles",
      "result",
      "setResult",
      event[index]
        ? event[index]
        : `return () => {
            setSuccess(false);
            setGameOver(true);
          }
          `
    );

  const handleButton1Click = () => {
    if (!clicked[0] && clickedIndex === 0) {
      play();
      setClicked([true, clicked[1]]);
      handleEvent(0)(
        anime,
        sleep,
        highlightBlock,
        setRunning,
        setSuccess,
        setGameOver,
        clickedIndex,
        setClickedIndex,
        target,
        targetEles,
        result,
        setResult
      )();
    }
  };
  const handleButton2Click = () => {
    if (!clicked[1] && clickedIndex === 1) {
      play();
      setClicked([clicked[0], true]);
      handleEvent(1)(
        anime,
        sleep,
        highlightBlock,
        setRunning,
        setSuccess,
        setGameOver,
        clickedIndex,
        setClickedIndex,
        target,
        targetEles,
        result,
        setResult
      )();
    }
  };
  return (
    <div className="flex-col-el flex-center absolute top-56">
      <div className="flex-row-el flex-center absolute -top-32">
        <p className="text-body text-white text-[20px]">
          {I18n.t("MSG_COURSE_COORDINATE_X")}
        </p>
      </div>

      <div className="flex-col-center w-[450px] h-[5px]">
        <div className="flex-row-el absolute -top-24 left-[195px] cursor-pointer">
          <img
            alt="*"
            onClick={handleButton1Click}
            src={`${Constants.assetHost}/stamps/vehicle/bc572b5812e0937f8ed15e88321cc54c.png`}
            className="w-[60px] h-[60px] active:opacity-70"
          />
        </div>

        <div className="flex-row-el absolute -top-24 -right-8 cursor-pointer">
          <img
            alt="*"
            onClick={handleButton2Click}
            src={`${Constants.assetHost}/stamps/vehicle/869a1175dc52418410e65a2e056c6ac7.png`}
            className="w-[60px] h-[60px] active:opacity-70"
          />
        </div>

        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
          <p className="text text-white !mb-[35px]">0</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
          <p className="text text-white !mb-[35px]">50</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
          <p className="text text-white !mb-[35px]">100</p>
        </div>
        <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

        <div
          id="course-program-image-1"
          className="flex-row-el flex-center z-50 absolute top-4 -left-8"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px]"
          />
          <AnswerMark id={1} />
        </div>

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${50 * 4.5}px)` }}
          />
        </div>

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${100 * 4.5}px)` }}
          />
        </div>
      </div>
    </div>
  );
};
