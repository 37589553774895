import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("user-project-panel-drawer");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 25 * scale,
        y: rect.y - 25 * scale,
        width: rect.width + 50 * scale,
        height: rect.height + 50 * scale,
      });
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={2} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre text-center">
            {I18n.locale === "ja" ? "なかを みるには" : "中を見るには"}
          </p>
          <div className="flex-row-el gap-2">
            <div className="flex-row-el flex-center px-3 bg-orange/100 rounded-xl">
              <p className="text-body text-[20px] leading-8 text-white">
                {I18n.t("MSG_CREATOR_APP_MY_APPS")}
              </p>
            </div>

            <p className="text-body text-textcolor/black text-[22px] whitespace-pre text-center">
              {I18n.locale === "ja" ? "の みだしを" : "の見出しを"}
            </p>
          </div>
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre text-center">
            {I18n.locale === "ja" ? "おします。" : "押します。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
