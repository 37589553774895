import * as React from "react";

export const MaterialCircleSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={38}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 37.03c9.389 0 17-7.625 17-17.03 0-9.405-7.611-17.03-17-17.03S2 10.595 2 20c0 9.405 7.611 17.03 17 17.03Z"
        fill="#F8F1E8"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
