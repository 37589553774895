import { SVGProps } from "react";

export const IconBlueBird = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.75 6.471 53 14.06s-2.313 8.706-8.75 14.912c-12.363 11.916-30.418 4.129-37.5-1.25-6.696-5.462-3.396-6.665-1.25-6.25l6.25 1.25C.419 4.845 3.531 2.936 6.75 3.971l11.25 5c7.211 3.971 10.625 1.875 12.5 0l3.75-3.75c5.625-5 11.212-.2 12.5 1.25Z"
      fill="#B4E0CF"
      stroke="#3F4A61"
      strokeWidth={5.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.5 16.471a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"
      fill="#3F4A61"
    />
  </svg>
);

export const IconOrangeBird = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={38}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M46.75 6.5 53 14.087S50.687 22.794 44.25 29c-12.363 11.916-30.418 4.129-37.5-1.25-6.696-5.463-3.396-6.665-1.25-6.25l6.25 1.25C.419 4.874 3.531 2.965 6.75 4L18 9c7.211 3.971 10.625 1.875 12.5 0l3.75-3.75c5.625-5 11.212-.2 12.5 1.25Z"
      fill="#FFDEBA"
      stroke="#3F4A61"
      strokeWidth={5.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M40.5 16.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z" fill="#3F4A61" />
  </svg>
);
