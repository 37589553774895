import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { AppPlatform, DialogType } from "app/types";
import { ConfirmMessageBox } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";

enum SubscriptionSku {
  BASIC_PLAN_MONTHLY = "basic_plan_monthly",
  BASIC_PLAN_ANNUAL = "basic_plan_annual",
}

export const Purchase = () => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [update, setUpdate] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser.main);

  const handleMessage = (message) => {
    const { command, data } = JSON.parse(message.data);
    switch (command) {
      case "subscription":
        const { result } = data;
        if (result === "success") {
          console.log("success");
        } else {
          console.log("failed");
        }
        handleDialog({ type: DialogType.LOADING, value: false });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (window.ReactNativeWebView) {
      if (window.kidsAppPlatform === "ios") {
        window.addEventListener("message", handleMessage);
      } else {
        document.addEventListener("message", handleMessage);
      }
    }

    return () => {
      if (window.ReactNativeWebView) {
        if (window.kidsAppPlatform === "ios") {
          window.removeEventListener("message", handleMessage);
        } else {
          document.removeEventListener("message", handleMessage);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePurchase = (sku: SubscriptionSku) => () => {
    play();
    handleDialog({ type: DialogType.LOADING, value: true });
    if ("ReactNativeWebView" in window) {
      if (
        window.kidsAppPlatform === AppPlatform.iOS &&
        Number(window.kidsBuildVersion) > 90
      ) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "subscription",
            data: { uid: user.uid, sku },
          })
        );
      } else if (
        window.kidsAppPlatform === AppPlatform.Android &&
        Number(window.kidsBuildVersion) > 70
      ) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "subscription",
            data: { uid: user.uid, sku },
          })
        );
      } else if (
        window.kidsAppPlatform === AppPlatform.Amazon &&
        Number(window.kidsBuildVersion) > 64
      ) {
        handleDialog({ type: DialogType.LOADING, value: true });
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "subscription",
            data: { uid: user.uid, sku },
          })
        );
      } else {
        setUpdate(true);
      }
    } else {
      // buy from web
    }
  };

  const handleGotoUpgrade = () => {
    play();
    if (window.kidsAppPlatform === AppPlatform.iOS) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "openLink",
          data: {
            url: "https://apps.apple.com/jp/app/id1642655214",
          },
        })
      );
    } else if (window.kidsAppPlatform === AppPlatform.Android) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "openLink",
          data: {
            url:
              "https://play.google.com/store/apps/details?id=com.inexus.app.kids",
          },
        })
      );
    } else if (window.kidsAppPlatform === AppPlatform.Amazon) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          command: "openLink",
          data: {
            url:
              "http://www.amazon.co.jp/gp/mas/dl/android?p=com.inexus.app.kids",
          },
        })
      );
    }
    setUpdate(false);
  };

  const handleCloseUpgrade = () => {
    play();
    btnDelay(() => setUpdate(false));
  };

  const handleGotoWebStore = () => {
    play();
    if ("ReactNativeWebView" in window) {
      const WEB_STORE_URL = "";
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "openLink", data: { url: WEB_STORE_URL } })
      );
    }
  };

  return (
    <div className="flex-row-el flex-center w-full gap-5 overflow-hidden">
      {update && (
        <ConfirmMessageBox
          title={I18n.t("MSG_APP_VERSION_UPDATE_WARNING_TITLE")}
          message={I18n.t("MSG_APP_VERSION_UPDATE_WARNING_MESSAGE")}
          close={handleCloseUpgrade}
          confirm={handleGotoUpgrade}
          is_parents
        />
      )}
    </div>
  );
};
