import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import I18n from "i18n-js";
import anime from "animejs";
import { RootState } from "app/store";
import { useScale, usePlaySound } from "common/utils";
import Constants, { BASE_HEIGHT, BASE_WIDTH } from "common/constant";
import { ParentGateButton, WarningMessageBox } from "common/elements";
import {
  TitleSvg,
  BackgroundSvg,
  CourseTitleSvg,
  FreebuildTitleSvg,
  CourseAnimationSvg,
  FreebuildAnimationSvg,
} from "features/home/assets";
import { NotificationBanner } from "features/notification";

const components = [
  {
    width: 597,
    Component: ({ scale }: { scale: number }) => (
      <div
        id="home-course-component"
        className="flex-col-view items-end !justify-start cursor-pointer will-change-transform"
        style={{ width: 597 * scale }}
      >
        <div
          id="home-course-component-icon"
          className="flex-col-center !justify-start h-full"
          style={{ right: 73 * scale, width: 420 * scale }}
        >
          <img
            alt="プログラミングとアルゴリズムを『コースでまなぶ』"
            src={`${Constants.assetHost}/assets/images/course_top_bg@2x.png`}
            className="flex-col-view pointer-events-none"
            style={{
              width: 420 * scale,
              height: 420 * scale,
              margin: `${16 * scale}px 0px`,
            }}
          />

          <div
            id="course-title"
            className="flex-col-el flex-center absolute bottom-0 z-50"
            style={{ width: 321 * scale, height: 64 * scale }}
          >
            <CourseTitleSvg />
          </div>
        </div>

        <div
          className="flex-col-el flex-center absolute left-0"
          style={{
            top: 16 * scale,
            width: 199 * scale,
            height: 254 * scale,
          }}
        >
          <CourseAnimationSvg className="animate-fuwafuwa1" />
        </div>

        <img
          alt="*"
          src={`${Constants.assetHost}/assets/images/course_top_icon@2x.png`}
          className="absolute bottom-0 right-0 pointer-events-none"
          style={{ width: 507 * scale, height: 267 * scale }}
        />
      </div>
    ),
    target: "courses",
  },
  {
    width: 518,
    Component: ({ scale }: { scale: number }) => (
      <div
        id="home-creator-component"
        className="flex-col-view !justify-start cursor-pointer will-change-transform"
        style={{ width: 518 * scale }}
      >
        <div
          id="home-creator-component-icon"
          className="flex-col-center !justify-start h-full"
          style={{ width: 420 * scale, marginLeft: 7 * scale }}
        >
          <img
            alt="デザインとアプリケーションを『じゆうにつくる』"
            src={`${Constants.assetHost}/assets/images/freebuild_top_bg@2x.png`}
            className="flex-col-view pointer-events-none"
            style={{
              width: 420 * scale,
              height: 420 * scale,
              margin: `${16 * scale}px 0px`,
            }}
          />

          <div
            id="creator-title"
            className="flex-col-el flex-center absolute bottom-0 z-50"
            style={{ width: 321 * scale, height: 64 * scale }}
          >
            <FreebuildTitleSvg />
          </div>
        </div>
        <div
          className="flex-col-el flex-center absolute"
          style={{
            top: 105 * scale,
            left: 382 * scale,
            width: 129 * scale,
            height: 186 * scale,
          }}
        >
          <FreebuildAnimationSvg className="animate-fuwafuwa2" />
        </div>
        <img
          alt="*"
          src={`${Constants.assetHost}/assets/images/freebuild_top_icon@2x.png`}
          className="absolute top-[191px] w-[453px] h-[259px] pointer-events-none"
          style={{
            top: 191 * scale,
            width: 453 * scale,
            height: 259 * scale,
          }}
        />
      </div>
    ),
    target: "creator",
  },
];

export const HomePage = () => {
  const TITLE_WIDTH = 828;
  const TITLE_HEIGHT = 182;
  const ITEM_HEIGHT = 454;
  const ITEM_TOP_MARGIN = 28;

  const play = usePlaySound();
  const navigate = useNavigate();
  const { width, height, scale } = useScale();
  const scaleX = width / BASE_WIDTH;
  const scaleY = height / BASE_HEIGHT;
  const [error, setError] = useState(false);
  const [render, setRender] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser);

  useEffect(() => {
    setTimeout(() => setRender(true), 50);
  }, []);

  const handleNavigate = (target: string) => async () => {
    play();

    if (!disabled && user.active) {
      setDisabled(true);
      const containerComponent = document.getElementById("home-container");
      const courseComponent = document.getElementById("home-course-component");
      const creatorComponent = document.getElementById(
        "home-creator-component"
      );
      const courseIconComponent = document.getElementById(
        "home-course-component-icon"
      );
      const creatorIconComponent = document.getElementById(
        "home-creator-component-icon"
      );

      if (target === "courses") {
        const courseTitleComponent = document.getElementById("course-title");
        await anime({
          targets: courseTitleComponent,
          scale: [1, 1.05, 1],
          duration: 300,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        if (!courseIconComponent || !courseComponent) return;
        const iconRect = courseIconComponent.getBoundingClientRect();
        const rect = courseComponent.getBoundingClientRect();
        const dx = iconRect.x - rect.x;
        const dy = iconRect.y - rect.y;
        anime({
          targets: courseComponent,
          translateX: width / 2 - (iconRect.left + iconRect.width / 2),
          duration: 500,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished.then(async () => {
          anime({
            targets: containerComponent,
            opacity: [1, 0],
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          });
          courseComponent.style.transformOrigin = `${
            dx + iconRect.width / 2
          }px ${dy + iconRect.height / 2}px`;
          anime({
            targets: courseComponent,
            scaleX: [1, 4],
            scaleY: [1, 4],
            duration: 600,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished.then(() => navigate("/courses"));
        });

        anime({
          targets: creatorComponent,
          translateX: 1000,
          duration: 300,
          autoplay: true,
          easing: "easeInOutQuad",
        });
      } else {
        const creatorTitleComponent = document.getElementById("creator-title");
        await anime({
          targets: creatorTitleComponent,
          scale: [1, 1.05, 1],
          duration: 300,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
        if (!creatorIconComponent) return;
        const iconRect = creatorIconComponent.getBoundingClientRect();
        anime({
          targets: creatorComponent,
          translateX: -(iconRect.left + iconRect.width / 2 - width / 2),
          duration: 500,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished.then(() => {
          anime({
            targets: containerComponent,
            opacity: [1, 0],
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          });
          creatorComponent.style.transformOrigin = `${iconRect.width / 2}px ${
            iconRect.height / 2
          }px`;
          anime({
            targets: creatorComponent,
            scaleX: [1, 4],
            scaleY: [1, 4],
            duration: 600,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished.then(() => navigate("/creator"));
        });

        anime({
          targets: courseComponent,
          translateX: -2000,
          duration: 300,
          autoplay: true,
          easing: "easeInOutQuad",
        });
      }
    } else if (!user.active) {
      setError(true);
    }
  };

  const handleCloseWarning = () => {
    play();
    setError(false);
  };

  return (
    <div id="home-container" className="flex-col-center w-full h-full">
      {disabled && (
        <div className="w-full h-full absolute inset-0 pointer-events-auto z-[20000]" />
      )}

      {error && (
        <WarningMessageBox
          zIndex={999999}
          title={I18n.t("MSG_ERROR_NO_USER_TITLE")}
          message={I18n.t("MSG_ERROR_NO_USER_MESSAGE")}
          close={handleCloseWarning}
        />
      )}

      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/new_bg_puzzle.png`}
        className="absolute inset-0 object-cover w-full h-full z-[-1]"
      />

      <NotificationBanner />

      <div
        className="flex-col-view self-center"
        style={{
          width: TITLE_WIDTH * scale,
          height: TITLE_HEIGHT * scale,
        }}
      >
        <div
          className="flex-col-view origin-top-left"
          style={{
            width: TITLE_WIDTH,
            height: TITLE_HEIGHT,
            transform: `scale(${scale})`,
          }}
        >
          <h1>
            <TitleSvg />
          </h1>
        </div>
      </div>

      <div className="flex-row-center self-center">
        {components.map(({ width, Component, target }, index) => (
          <div
            key={index}
            className="flex-col-center"
            style={{
              width: width * scale,
              height: ITEM_HEIGHT * scale,
              margin: `${ITEM_TOP_MARGIN * scale}px ${4 * scale}px 0`,
            }}
          >
            <button
              className="flex-col-el flex-center w-full h-full bg-transparent border-none cursor-pointer"
              onClick={handleNavigate(target)}
            >
              {render && <Component scale={scale} />}
            </button>
          </div>
        ))}
      </div>

      <div
        className="flex-col-center !absolute bottom-0 z-[-1]"
        style={{
          width: width,
          height: 235 * scaleY,
        }}
      >
        <div
          className="flex-col-center w-[1280px] h-[235px]"
          style={{ transform: `scale(${scaleX}, ${scaleY})` }}
        >
          <BackgroundSvg />
        </div>
      </div>

      <div
        className="flex-col-center !absolute w-full"
        style={{ bottom: 30 * scale }}
      >
        <div
          className="flex-col-center w-full origin-top"
          style={{ transform: `scale(${scale})` }}
        >
          <p className="text text-textcolor/black !leading-[30px]">
            ver{Constants.major}.{Constants.minor}.{Constants.buildNumber}
          </p>
        </div>
      </div>

      <ParentGateButton />
    </div>
  );
};
