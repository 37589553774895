import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PeeringStatus,
  ConnectionState,
  ConnectionStatus,
  PeerConnectionMessageContent,
} from "./type";

const initialState: ConnectionState = {
  status: ConnectionStatus.OFFLINE,
  peeringStatus: PeeringStatus.DISCONNECTED,
  receive: null,
  send: null,
  peerId: null,
};

const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    updateConnectionStatus(
      state: ConnectionState,
      action: PayloadAction<ConnectionStatus>
    ) {
      state.status = action.payload;
    },
    updatePeerConnectionStatus(
      state: ConnectionState,
      action: PayloadAction<{ status: PeeringStatus; peerId?: string }>
    ) {
      state.peeringStatus = action.payload.status;
      state.peerId = action.payload.peerId;
    },
    receiveMessage(
      state: ConnectionState,
      action: PayloadAction<PeerConnectionMessageContent>
    ) {
      state.receive = action.payload;
    },
    sendMessage(
      state: ConnectionState,
      action: PayloadAction<PeerConnectionMessageContent>
    ) {
      state.send = action.payload;
    },
  },
});

export const actions = { ...connectionSlice.actions };

export default connectionSlice.reducer;
