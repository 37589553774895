import { Scene } from "@babylonjs/core/scene";
import { Ray } from "@babylonjs/core/Culling/ray";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { sleep } from "common/utils";
import {
  MAP_MESH_SIZE,
  AssetModelType,
  BUTTON_ASSET_SIZE,
} from "features/builder/types";
import { performMarkJitter } from "./mark";

const performStepJump = async (
  scene: Scene,
  character: AbstractMesh,
  diff: number,
  nextStepOffset: number
) => {
  const jumpStep = 3;
  const frames = jumpStep * 2;
  const characterMove = new Animation(
    "jump",
    "position",
    frames,
    Animation.ANIMATIONTYPE_VECTOR3,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );
  const animationKeys = [];
  const w = ((6 - Math.abs(diff) / diff) * Math.PI) / 36;
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: character.position
        .multiply(new Vector3(100, 100, 100))
        .add(
          new Vector3(
            ((i * (MAP_MESH_SIZE * character.forward.x)) / (jumpStep * 2)) *
              100,

            Math.round(
              (Math.abs(diff) * Math.sin(w * i) +
                (i > frames / 2 ? nextStepOffset : 0)) *
                100
            ),
            ((i * (MAP_MESH_SIZE * character.forward.z)) / (jumpStep * 2)) * 100
          )
        )
        .divide(new Vector3(100, 100, 100)),
    });
  }
  characterMove.setKeys(animationKeys);
  const animation = scene.beginDirectAnimation(
    character,
    [characterMove],
    0,
    frames,
    false
  );
  await animation.waitAsync();
  await sleep(1000);
};

const performPlaceJump = async (scene: Scene, character: AbstractMesh) => {
  const jumpStep = 5;
  const frames = jumpStep * 2;

  performMarkJitter(scene, character, frames);

  const placeJump = new Animation(
    "placeJump",
    "position.y",
    frames,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );
  const initial = character.position.y;
  const animationKeys = [];
  for (let i = 0; i < frames + 1; i++) {
    animationKeys.push({
      frame: i,
      value: initial + 0.1 * (i % 2),
    });
  }
  placeJump.setKeys(animationKeys);
  const animation = scene.beginDirectAnimation(
    character,
    [placeJump],
    0,
    frames,
    false
  );
  await animation.waitAsync();
  await sleep(1000);
};

export const performJump = async (
  scene: Scene,
  character: AbstractMesh,
  setSuccess: (success: boolean) => void,
  setGameOver: (over: boolean) => void
) => {
  const rayCastAssetPos = new Vector3(
    character.position.x + character.forward.x * MAP_MESH_SIZE,
    character.position.y + 2,
    character.position.z + character.forward.z * MAP_MESH_SIZE
  );
  const groundAssetRay = new Ray(rayCastAssetPos, new Vector3(0, -1, 0), 4.5);
  const pickAsset = scene.pickWithRay(groundAssetRay, (mesh) =>
    mesh.name.includes("asset")
  );
  const pickedMesh = pickAsset.pickedMesh;
  if (
    !pickedMesh ||
    pickedMesh.name.includes(AssetModelType.DECORATION) ||
    pickedMesh.name.includes(AssetModelType.RIVER)
  ) {
    await performPlaceJump(scene, character);
    if (scene && !scene.isDisposed) {
      scene.dispose();
    }
    setSuccess(false);
    setGameOver(true);
  } else {
    const boundingInfo = pickedMesh.getBoundingInfo();
    const diff =
      Math.round(
        (boundingInfo.boundingBox.maximumWorld.y - character.position.y) * 10
      ) / 10;
    if (diff === 0 || diff > 1.5) {
      await performPlaceJump(scene, character);
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setSuccess(false);
      setGameOver(true);
    } else {
      const rayCastCurrentConditionPos = new Vector3(
        character.position.x,
        character.position.y,
        character.position.z
      );
      const currentConditionRay = new Ray(
        rayCastCurrentConditionPos,
        new Vector3(0, -1, 0),
        1
      );
      const pickCurrentCondition = scene.pickWithRay(
        currentConditionRay,
        (mesh) => mesh.name.includes(AssetModelType.CONDITION)
      );
      const pickNextCondition = scene.pickWithRay(groundAssetRay, (mesh) =>
        mesh.name.includes(AssetModelType.CONDITION)
      );
      const nextStepOffset = pickCurrentCondition.hit
        ? pickNextCondition.hit
          ? 0
          : -BUTTON_ASSET_SIZE.y
        : pickNextCondition.hit
        ? BUTTON_ASSET_SIZE.y
        : 0;
      scene.getAnimationGroupByName("ArmLeftAction")?.start();
      scene.getAnimationGroupByName("ArmRightAction")?.start();
      await performStepJump(scene, character, diff * 2, nextStepOffset);
    }
  }
};
