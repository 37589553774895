import i18n from "i18n-js";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ImageType,
  ImageResizeMode,
  ComponentManager,
  ComponentTypeIds,
} from "common/components";
import { Vector2D } from "common/types";
import { getRandomArbitrary } from "common/utils";
import {
  ScreensEntity,
  ComponentsEntity,
  CreatorInitialState,
  ComponentsEntityData,
  GroupingDefault,
} from "features/creator/types";
import {
  SCREEN_SIZE,
  HISTORY_STACK_MAX,
  DEFAULT_SCREEN_ORIENTATION,
} from "features/creator/constants";
import {
  screensAdapter,
  componentsAdapter,
  propertiesAdapter,
} from "features/creator/slice/reducers/adapter";

const reducers = {
  updateSelectedComponentId(
    state: CreatorInitialState,
    action: PayloadAction<{ screenId: string; id: string }>
  ) {
    if (state.selectedComponentId !== action.payload.id) {
      if (!state.grouping.enable) {
        state.undoStacks.push({
          before: {
            selectedScreenId: state.selectedScreenId,
            selectedComponentId: state.selectedComponentId,
            properties: [],
          },
          after: {
            selectedScreenId: action.payload.screenId,
            selectedComponentId: action.payload.id,
            properties: [],
          },
        });
        if (state.undoStacks.length > HISTORY_STACK_MAX) {
          state.undoStacks.shift();
        }
        state.redoStacks = [];
      }
    }

    state.selectedScreenId = action.payload.screenId;
    state.selectedComponentId = action.payload.id;
  },
  addComponent(
    state: CreatorInitialState,
    action: PayloadAction<{
      type: ComponentTypeIds;
      category: string;
      screenId: string;
      position?: Vector2D;
      image?: { url: string; size: number; width: number; height: number };
      drawing?: { svg: string; width: number; height: number };
    }>
  ) {
    // componentリストにcomponent追加
    const componentId = uuidv4();
    const {
      type: componentTypeId,
      category: categoryId,
      screenId: screenIdOpt,
      position,
      image,
      drawing,
    } = action.payload;
    const selectedComponentId = state.selectedComponentId;
    const screenId = screenIdOpt ? screenIdOpt : state.selectedScreenId;

    const components: ComponentsEntity = componentsAdapter
      .getSelectors()
      .selectById(state.components, screenId);
    const componentsData: ComponentsEntityData = produce(
      components.data,
      (draft) => {
        if (draft[componentTypeId]) {
          draft[componentTypeId].push({ id: componentId, deleted: false });
        } else {
          draft[componentTypeId] = [{ id: componentId, deleted: false }];
        }
      }
    );
    const newComponents = produce(components, (draft) => {
      draft.data = componentsData;
    });
    componentsAdapter.updateOne(state.components, {
      id: screenId,
      changes: newComponents,
    });

    // propertyにcomponent追加
    const count = componentsData[componentTypeId].length;
    const defaultProperty =
      ComponentManager[componentTypeId].component.property;
    const name = `${i18n.t(defaultProperty.name)}${count}`;
    const property = produce(defaultProperty, (draft) => {
      draft.name = name;
      draft.defaultName = name;
      if (draft.text) {
        draft.text = i18n.t(draft.text);
      }
      if (draft.defaultText) {
        draft.defaultText = i18n.t(draft.defaultText);
      }
      const x = position
        ? position.x
        : SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].WIDTH / 2 -
          Number(defaultProperty.style.layout.width) / 2 +
          getRandomArbitrary(-100, 100);
      const y = position
        ? position.y
        : SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].HEIGHT / 2 -
          Number(defaultProperty.style.layout.height) / 2 +
          getRandomArbitrary(-100, 100);
      draft.style.transform.translateX = x;
      draft.style.transform.translateY = y;
      draft.style.defaultTransform.translateX = x;
      draft.style.defaultTransform.translateY = y;

      if (image) {
        // for stamp or image from stock
        const { url, size, width, height } = image;
        const ratio = width / height;
        const layoutWidth =
          ratio > 1
            ? width > SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].WIDTH / 2
              ? SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].WIDTH / 2
              : width
            : height > SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].HEIGHT / 2
            ? (SCREEN_SIZE[DEFAULT_SCREEN_ORIENTATION].HEIGHT / 2) * ratio
            : height * ratio;
        const layoutHeight = layoutWidth / ratio;
        draft.style.layout.width = layoutWidth;
        draft.style.layout.height = layoutHeight;
        draft.style.defaultLayout.width = width;
        draft.style.defaultLayout.height = height;

        draft.style.view.borderWidth = 0;
        draft.style.defaultView.borderWidth = 0;

        draft.style.image = {
          name: name,
          size: size,
          width: width,
          height: height,
          uri: url,
          type:
            componentTypeId === ComponentTypeIds.STAMP
              ? ImageType.STAMP
              : ImageType.PHOTO,
          resizeMode: ImageResizeMode.CONTAIN,
        };
        draft.style.defaultImage = {
          name: name,
          size: size,
          width: width,
          height: height,
          uri: url,
          type:
            componentTypeId === ComponentTypeIds.STAMP
              ? ImageType.STAMP
              : ImageType.PHOTO,
          resizeMode: ImageResizeMode.CONTAIN,
        };
      }

      if (drawing) {
        // for drawing
        draft.drawing = { svg: drawing.svg };
        draft.style.layout.width = drawing.width;
        draft.style.layout.height = drawing.height;
        draft.style.defaultLayout.width = drawing.width;
        draft.style.defaultLayout.height = drawing.height;
      }
    });
    const newPropertyEntity = {
      id: componentId,
      typeId: componentTypeId,
      categoryId: categoryId,
      screenId: screenId,
      property: property,
    };
    propertiesAdapter.addOne(state.properties, newPropertyEntity);

    // screenにcomponent追加
    const screen: ScreensEntity = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenId);
    const children = produce(screen.children, (draft) => {
      draft.push({
        id: componentId,
        typeId: componentTypeId,
        categoryId: categoryId,
        name: name,
      });
    });
    const childrenOrder = produce(screen.childrenOrder, (draft) => {
      draft.push(componentId);
    });
    const newScreen = produce(screen, (draft) => {
      draft.children = children;
      draft.childrenOrder = childrenOrder;
    });
    screensAdapter.updateOne(state.screens, {
      id: screenId,
      changes: newScreen,
    });

    state.undoStacks.push({
      before: {
        screen,
        components,
        properties: [],
        selectedComponentId,
      },
      after: {
        screen: newScreen,
        components: newComponents,
        selectedComponentId: componentId,
        properties: [newPropertyEntity],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];

    state.selectedComponentId = componentId;
  },
  removeComponent(
    state: CreatorInitialState,
    action: PayloadAction<{
      screenId: string;
      componentId: string;
    }>
  ) {
    const { screenId, componentId } = action.payload;

    // remove component from screen children list
    const screen = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenId);
    const newScreen = produce(screen, (draft) => {
      draft.children = screen.children.filter((c) => c.id !== componentId);
      draft.childrenOrder = screen.childrenOrder.filter(
        (id) => id !== componentId
      );
    });
    screensAdapter.updateOne(state.screens, {
      id: screenId,
      changes: newScreen,
    });

    // remove blocky
    const property = propertiesAdapter
      .getSelectors()
      .selectById(state.properties, componentId);
    if (!property) {
      console.error(
        `failed to get component property. project id: ${state.editingProjectId}, screen id: ${screenId}, component id: ${componentId} `
      );
      return;
    }
    const componentTypeId = property.typeId;

    // remove component from property list
    propertiesAdapter.removeOne(state.properties, componentId);

    const components: ComponentsEntity = componentsAdapter
      .getSelectors()
      .selectById(state.components, screenId);
    const componentsData: ComponentsEntityData = produce(
      components.data,
      (draft) => {
        draft[componentTypeId] = draft[
          componentTypeId
        ].map((components: { id: string; deleted: boolean }) =>
          components.id === componentId
            ? { id: componentId, deleted: true }
            : components
        );
      }
    );
    const newComponents = produce(components, (draft) => {
      draft.data = componentsData;
    });
    componentsAdapter.updateOne(state.components, {
      id: screenId,
      changes: newComponents,
    });

    state.undoStacks.push({
      before: {
        screen,
        components,
        properties: [property],
        selectedComponentId: componentId,
      },
      after: {
        screen: newScreen,
        components: newComponents,
        selectedComponentId: screenId,
        properties: [],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];

    state.selectedComponentId = screenId;
  },
  removeGroupComponents(
    state: CreatorInitialState,
    action: PayloadAction<{
      screenId: string;
      componentIds: string[];
    }>
  ) {
    const { screenId, componentIds } = action.payload;

    // remove component from screen children list
    const screen = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenId);
    const newScreen = produce(screen, (draft) => {
      draft.children = screen.children.filter(
        (c) => !componentIds.includes(c.id)
      );
      draft.childrenOrder = screen.childrenOrder.filter(
        (id) => !componentIds.includes(id)
      );
    });
    screensAdapter.updateOne(state.screens, {
      id: screenId,
      changes: newScreen,
    });

    const propertySelector = propertiesAdapter.getSelectors();
    const propertiesEntities = componentIds.map((componentId) =>
      propertySelector.selectById(state.properties, componentId)
    );
    propertiesAdapter.removeMany(state.properties, componentIds);

    const components: ComponentsEntity = componentsAdapter
      .getSelectors()
      .selectById(state.components, screenId);
    const componentsData: ComponentsEntityData = produce(
      components.data,
      (draft) => {
        componentIds.forEach((componentId) => {
          const propertyEntity = propertiesEntities
            .filter((entity) => entity.id === componentId)
            .shift();
          draft[propertyEntity.typeId] = draft[
            propertyEntity.typeId
          ].map((components: { id: string; deleted: boolean }) =>
            components.id === componentId
              ? { id: componentId, deleted: true }
              : components
          );
        });
      }
    );
    const newComponents = produce(components, (draft) => {
      draft.data = componentsData;
    });
    componentsAdapter.updateOne(state.components, {
      id: screenId,
      changes: newComponents,
    });

    const beforeGrouping = state.grouping;
    state.grouping = GroupingDefault;

    state.undoStacks.push({
      before: {
        screen,
        components,
        grouping: beforeGrouping,
        properties: propertiesEntities,
        selectedComponentId: screenId,
      },
      after: {
        screen: newScreen,
        components: newComponents,
        grouping: GroupingDefault,
        selectedComponentId: screenId,
        properties: [],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];

    state.selectedComponentId = screenId;
  },
};

export default reducers;
