import { SVGProps } from "react";

export const IconCourseProgressFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={144}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#user-page-icon-course-progress-full-a)">
      <g filter="url(#user-page-icon-course-progress-full-b)">
        <path d="M0 0h24l-2.4 16H0V0Z" fill="#F9E532" />
      </g>
      <g filter="url(#user-page-icon-course-progress-full-c)">
        <path d="M22.182 0H44l-2.182 16H20l2.182-16Z" fill="#F9E532" />
      </g>
      <g filter="url(#user-page-icon-course-progress-full-d)">
        <path d="M42.182 0H64l-2.182 16H40l2.182-16Z" fill="#F9E532" />
      </g>
      <g filter="url(#user-page-icon-course-progress-full-e)">
        <path d="M62.182 0H84l-2.182 16H60l2.182-16Z" fill="#F9E532" />
      </g>
      <g filter="url(#user-page-icon-course-progress-full-f)">
        <path d="M82.182 0H104l-2.182 16H80l2.182-16Z" fill="#F9E532" />
      </g>
      <g filter="url(#user-page-icon-course-progress-full-g)">
        <path d="M102.182 0H124l-2.182 16H100l2.182-16Z" fill="#F9E532" />
      </g>
      <g filter="url(#user-page-icon-course-progress-full-h)">
        <path d="M122.182 0H136a8 8 0 0 1 0 16h-16l2.182-16Z" fill="#F9E532" />
      </g>
    </g>
    <defs>
      <filter
        id="user-page-icon-course-progress-full-b"
        x={-2}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15122" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15122"
          result="effect2_innerShadow_3700_15122"
        />
      </filter>
      <filter
        id="user-page-icon-course-progress-full-c"
        x={18}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15122" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15122"
          result="effect2_innerShadow_3700_15122"
        />
      </filter>
      <filter
        id="user-page-icon-course-progress-full-d"
        x={38}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15122" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15122"
          result="effect2_innerShadow_3700_15122"
        />
      </filter>
      <filter
        id="user-page-icon-course-progress-full-e"
        x={58}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15122" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15122"
          result="effect2_innerShadow_3700_15122"
        />
      </filter>
      <filter
        id="user-page-icon-course-progress-full-f"
        x={78}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15122" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15122"
          result="effect2_innerShadow_3700_15122"
        />
      </filter>
      <filter
        id="user-page-icon-course-progress-full-g"
        x={98}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15122" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15122"
          result="effect2_innerShadow_3700_15122"
        />
      </filter>
      <filter
        id="user-page-icon-course-progress-full-h"
        x={118}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15122" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15122"
          result="effect2_innerShadow_3700_15122"
        />
      </filter>
      <clipPath id="user-page-icon-course-progress-full-a">
        <path d="M0 8a8 8 0 0 1 8-8h136v16H8a8 8 0 0 1-8-8Z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export const IconCourseProgressStartBlock = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={24}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#user-page-icon-course-progress-start-block-a)">
      <path d="M0 0h24l-2.4 16H0V0Z" fill="#F9E532" />
    </g>
    <defs>
      <filter
        id="user-page-icon-course-progress-start-block-a"
        x={-2}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_7306" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_7306"
          result="effect2_innerShadow_3700_7306"
        />
      </filter>
    </defs>
  </svg>
);

export const IconCourseProgressBlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#user-page-icon-course-progress-block-a)">
      <path d="M2.182 0H24l-2.182 16H0L2.182 0Z" fill="#F9E532" />
    </g>
    <defs>
      <filter
        id="user-page-icon-course-progress-block-a"
        x={-2}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_8603" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_8603"
          result="effect2_innerShadow_3700_8603"
        />
      </filter>
    </defs>
  </svg>
);

export const IconCourseProgressEndBlock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#user-page-icon-course-progress-end-block-a)">
      <path d="M2.182 0H16a8 8 0 1 1 0 16H0L2.182 0Z" fill="#F9E532" />
    </g>
    <defs>
      <filter
        id="user-page-icon-course-progress-end-block-a"
        x={-2}
        y={-2.17}
        width={26}
        height={20.34}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.225784 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_3700_15129" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-2} dy={-2.17} />
        <feGaussianBlur stdDeviation={1.087} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.791551 0 0 0 0 0.727973 0 0 0 0 0.158946 0 0 0 1 0" />
        <feBlend
          in2="effect1_innerShadow_3700_15129"
          result="effect2_innerShadow_3700_15129"
        />
      </filter>
    </defs>
  </svg>
);
