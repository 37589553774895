import { useRef, useEffect } from "react";
import { useScale } from "common/utils";
import { CloseBtnOuter } from "common/elements";
import { GuideMessageBalloonSvg } from "features/courses/assets";
import { Button } from "features/courses/nishishinjuku/game/tips/Button";
import { StepProps } from "./type";

const Step = (props: StepProps) => {
  const ref = useRef<HTMLDivElement>();
  const { width, height, scale } = useScale();
  const { message, handleBack, handleMove, close } = props;

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = message;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <div
      className="flex-col-view !absolute top-0 left-0"
      style={{
        width: 500 * scale,
        height: 300 * scale,
        transform: `translate(${(width - 500 * scale) / 2}px, ${
          (height - 300 * scale) / 2
        }px)`,
      }}
    >
      <div
        className="flex-col-view items-center origin-top-left w-[500px] h-[300px]"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div className="flex-col-el absolute top-4 right-4">
          <CloseBtnOuter close={close} />
        </div>

        <div className="flex-col-view !absolute top-0 left-0 w-[500px] h-[300px]">
          <GuideMessageBalloonSvg />
        </div>

        <div className="flex-col-center top-[45px] w-[500px] h-[158px]">
          <p ref={ref} className="text !leading-[30px] text-textcolor/black" />
        </div>

        <Button handleBack={handleBack} handleMove={handleMove} />
      </div>
    </div>
  );
};

export default Step;
