import I18n from "i18n-js";
import Constants from "common/constant";
import { TitleLayout } from "features/creator/tutorial/top/stages/Layout";

const TOTAL_STEP = 14;

export const Title = ({ activeStep }: { activeStep: number }) => (
  <TitleLayout margin={0} steps={TOTAL_STEP} activeStep={activeStep}>
    <div className="flex-col-el flex-center gap-2">
      <div className="flex-col-el flex-center bg-orange/100 rounded-full border-solid border-[4px] border-orange/100 px-1 w-[24px] h-[24px]">
        <p className="text-body text-[18px] text-white leading-[26px]">5</p>
      </div>

      <div className="flex-col-center">
        <div className="flex-col-center">
          <img
            alt="app card"
            src={`${Constants.assetHost}/assets/images/app_card_user.png`}
            className="w-[20px] h-[24px]"
          />

          <div className="flex-col-el flex-center absolute top-[6px] w-full px-[1px] h-[15px]">
            <div className="w-full h-full bg-white" />
          </div>
        </div>
        <div className="flex-row-el flex-center">
          <p className="text-body text-[18px] text-orange/100 text-center leading-5 whitespace-pre">
            {I18n.locale === "ja" ? "アプリの\nつくりかた" : "アプリの\n作り方"}
          </p>
        </div>
      </div>
    </div>
  </TitleLayout>
);
