import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useAnalytics } from "./useAnalytics";
import { useSubscribe } from "./useSubscribe";
import { useStoreReview } from "./useStoreReview";
import { useSoundConfig } from "./useSoundConfig";
import { useNativeMessage } from "./useNativeMessage";

Sentry.init({
  dsn: Constants.sentryDSN,
  integrations: [captureConsoleIntegration({ levels: ["error"] })],
  tracesSampleRate: 0.2,
  environment: Constants.sentryEnv,
  release: `${Constants.sentryReleasePrefix}-ver${Constants.major}.${Constants.minor}.${Constants.buildNumber}`,
});

export const useInitialApp = () => {
  const user = useSelector((state: RootState) => state.user.appUser);

  if (user) {
    useAnalytics();
    useSubscribe();
    useStoreReview();
    useNativeMessage();
    const { bgmSound, audioContext } = useSoundConfig();
    return { bgmSound, audioContext };
  } else {
    return { bgmSound: null, audioContext: null };
  }
};
