import Constants from "common/constant";
import { ProjectActionPanel } from "features/creator/panel/ProjectActionPanel";

export const TabletCapture = ({ scale }: { scale: number }) => {
  return (
    <div
      className="flex-col-view z-50"
      style={{
        width: 664 * scale,
        height: 498 * scale,
      }}
    >
      <div
        className="flex-col-view w-[664px] h-[498px] origin-top-left"
        style={{ transform: `scale(${scale})` }}
      >
        <div
          className="flex-col-center w-[664px] h-[498px]"
          style={{
            filter: "drop-shadow(0px 0px 3px rgba(63, 74, 97, 0.3))",
          }}
        >
          {/* ケース */}
          <img
            alt="game case"
            src={`${Constants.assetHost}/assets/images/small_gray_case.png`}
            className="w-full h-full object-fill"
          />
          <div className="flex-col-center !absolute top-[-10px] left-[51px] z-[-1]">
            <img
              alt="officail app card"
              src={`${Constants.assetHost}/assets/images/app_card_official.png`}
              className="w-[80px] h-[99px]"
            />
          </div>
        </div>

        <div className="absolute top-[35px] left-[45px] w-[562px] h-[414px] !box-content border-solid border-gray/100 border-[6px] rounded-[16px] overflow-hidden flex flex-col">
          <div className="flex-col-view w-full h-full">
            <div className="flex-col-center w-full h-full bg-white !absolute top-0 left-0" />
          </div>

          <ProjectActionPanel capture="" isUserProject={true} project={null} />
        </div>
      </div>
    </div>
  );
};
