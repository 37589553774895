import { Category, Block, Value, Shadow, Field } from "common/blockly";

export const Control = () => {
  return (
    <Category
      name="せいぎょ"
      categorystyle="loop_category"
      toolboxitemid="control"
    >
      <Block type="controls_repeat_ext">
        <Value name="TIMES">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="controls_whileUntil" />
      <Block type="controls_flow_statements" />
    </Category>
  );
};
