import { IconCrown } from "features/creator/assets";

export const Top3Title = ({
  order,
  title,
}: {
  order: number;
  title: string;
}) => {
  return (
    <div
      className="flex-row-el flex-center relative w-[176px] h-[52px] ml-10"
      style={{
        filter:
          "drop-shadow(-1px 1px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 3px rgba(63, 74, 97, 0.25))",
      }}
    >
      <div
        className="flex-col-el flex-center z-10 w-[64px] h-[64px] rounded-full border-solid border-white border-4 absolute -left-10"
        style={{
          background:
            order === 1
              ? "radial-gradient(100% 100% at 0% 0%, #F9EB74 0%, #EFD851 61.46%, #CC9B1C 100%)"
              : order === 2
              ? "radial-gradient(100% 100% at 0% 0%, #C9D4DE 0%, #ADB7C0 56.77%, #667786 100%)"
              : "radial-gradient(100% 100% at 0% 0%, #F2C3AC 0%, #DB9675 63.02%, #A74210 100%)",
        }}
      >
        <div className="flex-col-el flex-center absolute">
          <IconCrown />
        </div>

        <p
          className={`text-body text-[36px] z-50 ${
            order === 1
              ? "text-[#9B902B]"
              : order === 2
              ? "text-[#5F72B7]"
              : "text-[#AC5D57]"
          }`}
          style={{
            WebkitTextStroke: "6px #FEFDFE",
          }}
        >
          <span
            style={{
              WebkitTextStroke: "0",
              position: "absolute",
            }}
          >
            {order}
          </span>
          {order}
        </p>
      </div>
      <div className="flex-col-el justify-center relative w-full h-full bg-white rounded-r-[26px] pl-6 py-1">
        <div className="flex-row-center w-[144px]">
          <p className="text-body text-body-ellipsis text-textcolor/black text-left text-[20px] w-full">
            {title}
          </p>
        </div>
      </div>
    </div>
  );
};
