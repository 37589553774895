import { useEffect } from "react";

export const useOpenCv = ({
  setInitialized,
}: {
  setInitialized?: () => void;
}) => {
  useEffect(() => {
    if ("cv" in window) {
      if (setInitialized) {
        setInitialized();
      }
      console.log("already import opencv!");
    } else {
      const script = document.createElement("script");
      script.src = "https://assets.kids.inexus-co.com/lib/opencv.js";
      script.async = true;
      script.onload = () => {
        // @ts-ignore
        cv["onRuntimeInitialized"] = () => {
          console.log("OpenCV is ready to use.");
          if (setInitialized) {
            setInitialized();
          }
        };
      };
      script.onerror = () => {
        console.error("Failed to load OpenCV.js");
      };
      document.head.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
