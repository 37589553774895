import { ClickEvent } from "common/types";

export const Switch = (props: {
  color?: string;
  trackInactive?: string;
  background?: string;
  thumb?: string;
  margin?: number;
  width: number;
  height: number;
  value: boolean;
  onClick: (e: ClickEvent) => void;
}) => {
  const {
    color,
    trackInactive,
    background,
    thumb,
    margin,
    width,
    height,
    value,
    onClick,
  } = props;
  const BORDER_WIDTH = 4;
  const THUMB_SIZE = height * 0.7;
  const MARGIN = height * 0.3;
  return (
    <div
      className="flex-row-center !justify-start w-full h-full cursor-pointer border-solid border-[4px] box-border rounded-full overflow-hidden"
      style={{
        width: width,
        height: height,
        borderColor: color ?? "#FEFDFE",
        borderWidth: BORDER_WIDTH,
        backgroundColor: trackInactive ?? "transparent",
      }}
      onClick={onClick}
    >
      <div
        className="flex-col-center !absolute top-0 left-0 w-full h-full border-solid border-white rounded-full"
        style={{
          borderWidth: margin ?? 0,
          backgroundColor: value ? background ?? "#94C4F7" : "transparent",
          transition: "background-color 0.4s",
        }}
      >
        <p
          className="text"
          style={{
            color: color ?? "#FEFDFE",
            transform: `translateX(${value ? -MARGIN : MARGIN}px)`,
            transition: "transform 0.4s",
          }}
        >
          {value ? "ON" : "OFF"}
        </p>
      </div>

      <div
        className="rounded-full"
        style={{
          width: THUMB_SIZE,
          height: THUMB_SIZE,
          transform: `translateX(${
            value
              ? width - BORDER_WIDTH - THUMB_SIZE - MARGIN / 2
              : BORDER_WIDTH
          }px)`,
          backgroundColor: thumb ?? "#FEFDFE",
          transition: "transform 0.4s",
        }}
      ></div>
    </div>
  );
};
