import { useRef, useState, useEffect } from "react";
import { animated, useSpring } from "@react-spring/web";
import I18n from "i18n-js";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { CloseBtnOuter } from "common/elements";
import { GuideMessageBalloonBigSvg } from "features/courses/assets";
import { StepProps } from "./type";

const Step = (props: StepProps) => {
  const { scale } = useScale();
  const ref = useRef<HTMLDivElement>();
  const { message, handleBack, close } = props;
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [arrowOffset, setArrowOffset] = useState({ x: 0, y: 0 });
  const [animationStyles, animationApi] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const handleArrowAnimation = () =>
    animationApi.start({
      to: [
        { opacity: 1 },
        { opacity: 0 },
        { opacity: 1 },
        { opacity: 0 },
        { opacity: 1 },
      ],
      config: { duration: 1000 },
    });

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = message;

      const blocks = document.getElementsByClassName("blocklyDraggable");
      const eventBlockRect = blocks[0].getBoundingClientRect();

      setOffset({
        x: eventBlockRect.x + eventBlockRect.width / 2,
        y: eventBlockRect.y + eventBlockRect.height / 2,
      });
      setArrowOffset({
        x: eventBlockRect.x + eventBlockRect.width / 2 - 20 * scale,
        y: eventBlockRect.y + eventBlockRect.height / 2 - 20 * scale,
      });

      handleArrowAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <>
      <div
        className="flex-col-view !absolute top-0 left-0 z-[100]"
        style={{
          width: 109 * scale,
          height: 109 * scale,
          transform: `translate(${arrowOffset.x}px, ${arrowOffset.y}px)`,
        }}
      >
        <div
          className="flex-col-view w-[109px] h-[109px] origin-top-left"
          style={{
            transform: `rotate(0deg) scale(${scale})`,
          }}
        >
          <animated.div
            className="w-full h-full"
            style={{ ...animationStyles }}
          >
            <img
              alt="*"
              className="w-full h-full"
              src={`${Constants.assetHost}/assets/images/arrow.png`}
            />
          </animated.div>
        </div>
      </div>
      <div
        className="flex-col-view !absolute top-0 left-0"
        style={{
          opacity: offset.x * offset.y === 0 ? 0 : 1,
          width: 500 * scale,
          height: 500 * scale,
          transform: `translate(${offset.x}px, ${offset.y}px)`,
        }}
      >
        <div
          className="flex-col-view items-center origin-top-left w-[500px] h-[500px]"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-el absolute top-8 right-8 z-[100]">
            <CloseBtnOuter close={close} />
          </div>

          <div className="flex-col-view !absolute top-0 left-0 w-[500px] h-[500px]">
            <GuideMessageBalloonBigSvg />
          </div>

          <div className="flex-col-center top-[65px] w-[500px]">
            <div className="h-[90px]">
              <p
                ref={ref}
                className="text !leading-[30px] text-textcolor/black"
              ></p>
            </div>

            <img
              alt="*"
              src={`${Constants.assetHost}/assets/images/tutorial_02.gif`}
              className="w-[320px] h-[240px] mt-3"
            />
          </div>

          <div className="flex-row-el flex-center gap-4 absolute bottom-6 alpha">
            <button className="btn btn-blue" onClick={handleBack}>
              <p className="text-body text-white text-[20px] !mb-[3px]">
                {I18n.t("MSG_COURSE_GAME_TIPS_BACK_BTN")}
              </p>
            </button>

            <button className="btn btn-primary" onClick={close}>
              <p className="text-body text-white text-[20px] !mb-[3px]">
                {I18n.t("MSG_COURSE_GAME_TIPS_ACTION_BTN")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step;
