export enum Path {
  TOP = "top",
  APP = "app",
  USER = "user",
}

export enum PERIOD {
  RECENTLY = "RECENTLY",
  ALL = "ALL",
}

export enum RANKING_RANGE {
  TOP_3 = "top-3",
  TOP_10 = "top-10",
  TOP_20 = "top-20",
  TOP_30 = "top-30",
  TOP_40 = "top-40",
  TOP_50 = "top-50",
}
