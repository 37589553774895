import { SVGProps } from "react";

export const IconPublished = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.43 32.099h11.142C28.456 37.991 26.223 42 24 42c-2.154 0-4.318-3.77-5.466-9.368l-.105-.53h11.143-11.143V32.1Zm-10.507.002h7.75c.654 3.748 1.768 6.938 3.224 9.168a18.049 18.049 0 0 1-10.71-8.666l-.264-.502Zm24.408 0h7.747a18.052 18.052 0 0 1-10.972 9.168c1.358-2.084 2.418-5 3.086-8.426l.139-.742h7.747-7.747Zm.543-11.701h8.764a18.094 18.094 0 0 1-.462 9H32.71c.276-2.596.354-5.21.234-7.82l-.072-1.182h8.764-8.764l.002.002Zm-26.51-.002h8.765c-.188 2.65-.169 5.31.06 7.957l.102 1.044H6.826a18.092 18.092 0 0 1-.465-9.001h.004Zm11.477 0h12.325a46.522 46.522 0 0 1-.067 7.966l-.111 1.035H18.015a46.798 46.798 0 0 1-.237-8.123l.06-.878h12.326-12.327.004ZM29.3 7.033l-.195-.306a18.043 18.043 0 0 1 11.76 10.97h-8.26c-.569-4.349-1.722-8.086-3.308-10.662l-.194-.306.194.306.002-.002Zm-10.622-.24.22-.066c-1.587 2.432-2.766 5.995-3.392 10.178l-.112.793H7.14a18.052 18.052 0 0 1 11.54-10.904l.22-.067-.22.067ZM24.001 6c2.373 0 4.761 4.576 5.783 11.13l.085.568H18.132C19.106 10.84 21.56 6 24 6Z"
      fill="#AAF1D6"
    />
    <circle cx={24} cy={24} r={18.5} stroke="#667786" strokeWidth={3} />
  </svg>
);
