/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { InitMsg } from "common/blockly";
import {
  InitColourBlock,
  InitControlsBlock,
  InitVariableBlock,
  InitCharacterBlock,
  InitProceduresCallBlock,
} from "features/courses/algorithm/game/blockly/blocks";
import { BlocklyOptions } from "./BlocklyOptions";

const useInitWorkspace = (
  scale: number,
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void
) => {
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    InitMsg(config.locale);
  }, [config.locale]);

  useEffect(() => {
    InitCharacterBlock();
    InitControlsBlock();
    InitColourBlock();
    InitVariableBlock();
    InitProceduresCallBlock();

    Blockly.WorkspaceSvg.prototype.refreshToolboxSelection = function () {
      // disable refresh toolbox
    };

    const workspace: Blockly.WorkspaceSvg = Blockly.inject(
      "blocklyDiv",
      BlocklyOptions(scale)
    );
    BlocklyJs.init(workspace);

    workspace.clearUndo();
    workspace.showContextMenu = () => {};

    BlocklyJs.addReservedWords("highlightBlock");
    setWorkspace(workspace);

    return () => {
      setWorkspace(null);
    };
  }, []);
};

const useWorkspaceListener = (
  scale: number,
  workspace: Blockly.WorkspaceSvg
) => {
  useEffect(() => {
    if (workspace) {
      workspace.setScale(scale);
    }
    return () => {
      if (workspace) {
        workspace.clear();
      }
    };
  }, [workspace, scale]);

  useEffect(() => {
    return () => {
      if (workspace) {
        workspace.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useBlocklyInit = (
  workspace: Blockly.WorkspaceSvg,
  setWorkspace: (workspace: any) => void
) => {
  const { scale } = useScale();
  useInitWorkspace(scale, setWorkspace);
  useWorkspaceListener(scale, workspace);
};
