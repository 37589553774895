import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { Vector2D } from "common/types";
import { usePlaySound } from "common/utils";
import { ComponentProperty } from "common/components";
import { useActionCommand } from "features/creator/utils";
import { ActionPanel } from "features/creator/design/actionPanel";
import { actions, selectPropertyById } from "features/creator/slice";
import { ActionCommandType, GroupingDefault } from "features/creator/types";
import { ResponderLinePanel } from "features/creator/design/ResponderLinePanel";

export const DesignView = ({
  componentId,
  scale,
  zIndex,
  groupDelta,
  grouping,
  groupMoving,
}: {
  componentId: string;
  scale: number;
  zIndex: number;
  groupDelta: Vector2D;
  grouping: boolean;
  groupMoving: boolean;
}) => {
  const handleAction = useActionCommand();
  const dispatch = useDispatch<AppDispatch>();
  const propertyEntity = useSelector((state: RootState) =>
    selectPropertyById(state, componentId)
  );
  if (!propertyEntity) {
    console.error(componentId);
    console.error(JSON.stringify(propertyEntity));
    console.error("Right side of assignment cannot be destructured");
  }
  const { id, screenId, typeId, property } = propertyEntity;
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const play = usePlaySound(`${Constants.assetHost}/assets/sounds/delete.mp3`);
  const onSelected = () => {
    dispatch(actions.updateSelectedComponentId({ screenId, id }));
    dispatch(actions.handlePropertyCollapse(true));
    dispatch(actions.handleMaterialPanelCollapse(true));
    dispatch(actions.updateGrouping(GroupingDefault));
  };

  const onDragResizeStop = (
    newProperty: ComponentProperty,
    record?: boolean
  ) => {
    if (newProperty !== property) {
      handleAction({
        type: ActionCommandType.CHANGE_PROPERTY,
        componentId: id,
        property: newProperty,
        record,
      });
    }
  };

  const handleDelete = (id: string) => {
    play();
    dispatch(actions.updateSelectedComponentId({ screenId, id: screenId }));
    anime({
      targets: [
        document.getElementById(`component-move-panel-${id}`),
        document.getElementById(`component-resize-panel-${id}`),
      ],
      scale: 0.9,
      opacity: 0,
      duration: 400,
      easing: "easeInOutQuad",
    }).finished.then(() => {
      handleAction({
        type: ActionCommandType.REMOVE_COMPONENT,
        screenId: screenId,
        componentId: id,
      });
    });
  };

  return typeId === "line" ? (
    <ResponderLinePanel
      scale={scale}
      zIndex={zIndex}
      selected={selectedComponentId === id}
      groupDelta={groupDelta}
      grouping={grouping}
      groupMoving={groupMoving}
      propertyEntity={propertyEntity}
      onSelected={onSelected}
      onActionEnd={onDragResizeStop}
      handleDelete={handleDelete}
    />
  ) : (
    <ActionPanel
      scale={scale}
      zIndex={zIndex}
      selected={selectedComponentId === id}
      groupDelta={groupDelta}
      grouping={grouping}
      groupMoving={groupMoving}
      propertyEntity={propertyEntity}
      onSelected={onSelected}
      onActionEnd={onDragResizeStop}
      handleDelete={handleDelete}
    />
  );
};
