import I18n from "i18n-js";
import { Category, Block, Value, Shadow, Field, Label } from "common/blockly";

export const Size = () => {
  return (
    <Category name="size" categorystyle="text_category" toolboxitemid="size">
      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_WIDTH")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_width" />
      <Block type="component_property_setter_width">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">110</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_width_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_HEIGHT")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_height" />
      <Block type="component_property_setter_height">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">110</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_height_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>

      <Block type="component_property_getter_scale" />
      <Block type="component_property_setter_scale">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">1.1</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_scale_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">0.1</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_border_width" />
      <Block type="component_property_setter_border_width">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_border_width_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_SHADOW")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_shadow_radius" />
      <Block type="component_property_setter_shadow_radius">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_shadow_radius_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SIZE")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_text_font_size" />
      <Block type="component_property_setter_text_font_size">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">14</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_text_font_size_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
      </Block>

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_text_shadow_radius" />
      <Block type="component_property_setter_text_shadow_radius">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_setter_text_shadow_radius_by_step">
        <Value name="value">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
      </Block>
    </Category>
  );
};
