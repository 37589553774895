export const IconFeedback = () => (
  <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56 112c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18Z"
      stroke="#FEFDFE"
      fill="#FEFDFE"
      strokeWidth={8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64 174H12v-13.55c0-4.065 1.354-8.479 4.152-11.808 2.705-3.22 6.932-5.642 13.277-5.642H46.57c6.345 0 10.572 2.422 13.277 5.642C62.645 151.971 64 156.385 64 160.45V174ZM105.703 109.81l-1.243-.327-1.202.458-22.196 8.466 4.45-15.451.69-2.395-1.845-1.674C75.967 91.274 72 80.963 72 69.317c0-12.067 4.26-22.714 13.315-30.41C94.443 31.149 108.906 26 130 26c16.323 0 30.951 5.054 41.414 13.039C181.873 47.02 188 57.763 188 69.318c0 12.068-4.26 22.715-13.315 30.41-9.128 7.758-23.591 12.908-44.685 12.908-9.355 0-17.422-1.015-24.297-2.826Z"
      stroke="#FEFDFE"
      fill="#FEFDFE"
      strokeWidth={8}
    />
    <path
      d="M129.882 79.027c-1.085 0-2.013-.387-2.782-1.161-.769-.823-1.176-1.815-1.221-2.977l-1.018-28.75c-.045-1.114.294-2.082 1.018-2.905S127.485 42 128.525 42h3.664c1.041 0 1.923.411 2.646 1.234.724.823 1.063 1.791 1.018 2.904l-1.018 28.751c-.045 1.162-.452 2.154-1.221 2.977-.769.774-1.696 1.161-2.782 1.161h-.95Zm1.629 5.809c1.04 0 1.922.411 2.646 1.234.769.823 1.153 1.79 1.153 2.904v1.888c0 1.113-.384 2.08-1.153 2.904-.724.823-1.606 1.234-2.646 1.234h-2.307c-1.041 0-1.945-.411-2.714-1.234-.724-.823-1.086-1.791-1.086-2.904v-1.888c0-1.113.362-2.081 1.086-2.904.769-.823 1.673-1.234 2.714-1.234h2.307Z"
      fill="#FFA03B"
    />
    <path
      d="m102 147.667-8.23 4.326 1.573-9.164-6.667-6.489 9.2-1.333 4.115-8.338 4.114 8.338 9.2 1.333-6.666 6.489 1.572 9.164-8.211-4.326Z"
      stroke="#FEFDFE"
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m139 147.667-8.229 4.326 1.572-9.164-6.667-6.489 9.2-1.333 4.115-8.338 4.114 8.338 9.2 1.333-6.666 6.489 1.572 9.164-8.211-4.326Z"
      stroke="#FEFDFE"
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m176 147.667-8.229 4.326 1.572-9.164-6.667-6.489 9.2-1.333 4.115-8.338 4.114 8.338 9.2 1.333-6.666 6.489 1.572 9.164-8.211-4.326Z"
      stroke="#FEFDFE"
      strokeWidth={5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
