const ToolboxInfo = {
  maxInstances: {
    label_text_setter_block: 3,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "label_text_setter_block",
            inputs: {
              value: {
                shadow: {
                  type: "text",
                },
              },
            },
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
