import { SVGProps } from "react";

export const IconArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={15}
    height={18}
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 17V1L1 9l13 8Z"
      fill="#FEFDFE"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
