import { IconProjectCardFavoritedReverse } from "features/creator/assets";

export const FavoritedMarkReadOnly = ({ favorited }: { favorited: number }) => (
  <div className="flex-row-center">
    <div className="flex-col-center w-[32px] h-[32px]">
      <IconProjectCardFavoritedReverse />
    </div>
    <div className="flex-col-center h-[30px]">
      <p
        className="text-body text-[28px] text-red/100 leading-8"
        style={{
          WebkitTextStroke: "6px #FEFDFE",
        }}
      >
        <span
          className="inline-block leading-8"
          style={{
            WebkitTextStroke: "0",
            position: "absolute",
          }}
        >
          {favorited}
        </span>
        {favorited}
      </p>
    </div>
  </div>
);
