import { SVGProps } from "react";

export const AllStageClearFooter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={1280}
    height={202}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M425.515 136.405c-2.976 3.456 4.653 7.641 7.216 5.134 3.174-3.061-3.963-8.846-7.216-5.134Z"
      fill="#73963B"
    />
    <path
      d="M428.57 135.793s30.913-23.16 40.198-6.752c5.875 10.385-16.797 20.948-40.198 6.752Z"
      fill="#F4B74B"
    />
    <path
      d="M428.926 136.445s-5.914-38.995 8.872-39.844c11.887-.71 12.991 23.417-8.872 39.844Z"
      fill="#F4B74B"
    />
    <path
      d="M429.892 136.623s-35.486-11.215-33.672-23.002c1.813-11.788 26.535-3.416 33.672 23.002Z"
      fill="#F4B74B"
    />
    <path
      d="M428.433 134.747s38.561-1.975 37.142 16.802c-.887 11.906-25.531 7.977-37.142-16.802Z"
      fill="#F6D651"
    />
    <path
      d="M428.236 132.792c0 .395-2.977 40.93-20.622 74.534l1.972 1.007c17.841-34.019 20.838-75.027 20.858-75.403l-2.208-.138Z"
      fill="#73963B"
    />
    <path
      d="M419.7 180.771s-21.213-7.898-17.467-17.178c2.779-6.91 12.893 3.238 15.456 8.352a16.182 16.182 0 0 1 2.011 8.826Z"
      fill="#47863F"
    />
    <path
      d="M419.798 178.658s15.2-16.782 22.435-9.872c5.362 5.173-7.886 10.761-13.504 11.234a15.99 15.99 0 0 1-8.931-1.362Z"
      fill="#73963B"
    />
    <path
      d="M429.458 136.366s18.532-34.789 30.893-26.655c10.034 6.575-3.47 26.556-30.893 26.655Z"
      fill="#F6D651"
    />
    <path
      d="M430.089 136.958s-24.052-28.49-16.186-37.513c7.866-9.024 24.249 11.372 16.186 37.513Z"
      fill="#F6D651"
    />
    <path
      d="M431.252 137.491s-36.492 7.484-40.573-3.731c-4.081-11.215 21.529-15.894 40.573 3.731Z"
      fill="#F6D651"
    />
    <path
      d="M17.425 146.317c-.966 4.048 6.979 4.225 8.004 1.145 1.183-3.81-7.058-5.508-8.004-1.145Z"
      fill="#73963B"
    />
    <path
      d="M18.549 145.192s11.08-29.617 24.722-22.45c8.635 4.561-2.366 21.798-24.722 22.45Z"
      fill="#F4B74B"
    />
    <path
      d="M19.1 145.488S-2.19 121.242 7.608 114.114c7.846-5.745 19.28 10.366 11.494 31.374Z"
      fill="#F4B74B"
    />
    <path
      d="M19.83 145.172s-29.434 8.016-33.357-.928c-3.923-8.944 16.777-13.999 33.357.928Z"
      fill="#F4B74B"
    />
    <path
      d="M17.997 144.54s25.628-18.441 33.021-4.877c4.633 8.609-14.076 16.803-33.021 4.877Z"
      fill="#F6D651"
    />
    <path
      d="M17.839 142.092c0 .296 6.94 28.984 14.53 69.539l2.129-.395c-7.61-40.614-14.47-69.361-14.55-69.638l-2.11.494Z"
      fill="#73963B"
    />
    <path
      d="M27.874 183.831S7.33 181.6 8.574 172.498c.926-6.753 12.124 0 15.534 3.949a14.673 14.673 0 0 1 3.766 7.384ZM29.608 193.604s5.323-20.001 14.116-17.375c6.526 1.975-1.833 12.005-6.25 14.789a14.625 14.625 0 0 1-7.866 2.586Z"
      fill="#47863F"
    />
    <path
      d="M19.396 145.152s-2.582-32.163 9.523-32.025c9.837.119 9.344 19.863-9.523 32.025Z"
      fill="#F6D651"
    />
    <path
      d="M20.126 145.33s-29.158-8.984-27.699-18.658c1.459-9.675 21.706-2.923 27.699 18.658Z"
      fill="#F6D651"
    />
    <path
      d="M21.15 145.172s-21.843 21.324-29.57 15.4c-7.729-5.923 7.786-20.455 29.57-15.4Z"
      fill="#F6D651"
    />
    <path
      d="M332.108 127.994c2.129 4.66-6.486 7.247-8.438 3.772-2.366-4.265 6.151-8.806 8.438-3.772Z"
      fill="#73963B"
    />
    <path
      d="M330.491 126.987s-19.931-32.933-32.903-20.079c-8.103 8.134 8.339 25.924 32.903 20.079Z"
      fill="#F4B74B"
    />
    <path
      d="M329.978 127.501s16.777-35.974 4.199-41.779c-10.113-4.66-18.275 18.441-4.199 41.779Z"
      fill="#F4B74B"
    />
    <path
      d="M329.092 127.343s34.224 1.027 36.117-11.077c1.892-12.103-22.021-12.241-36.117 11.077Z"
      fill="#F4B74B"
    />
    <path
      d="M330.886 126.02s-32.884-14.887-37.28 3.949c-2.76 11.807 19.813 16.249 37.28-3.949Z"
      fill="#F6D651"
    />
    <path
      d="M328.146 122.999c0 .375.118 37.514 2.582 89.402l2.169-.099c-2.465-51.828-2.583-88.849-2.583-89.303h-2.168Z"
      fill="#73963B"
    />
    <path
      d="M330.571 176.98s21.804-8.865 18.038-19.606c-2.819-7.898-13.248 3.613-15.909 9.457a20.393 20.393 0 0 0-2.129 10.149ZM331.26 189.478s-11.119-22.864-20.011-17.04c-6.584 4.324 5.185 14.118 10.745 16.21a14.921 14.921 0 0 0 9.266.83Z"
      fill="#73963B"
    />
    <path
      d="M329.466 127.205s-5.697-40.081-18.867-36.31c-10.606 3.04-4.85 27.05 18.867 36.31Z"
      fill="#F6D651"
    />
    <path
      d="M328.914 127.6s29.414-19.646 25.254-31.018c-4.159-11.373-24.544 2.862-25.254 31.018Z"
      fill="#F6D651"
    />
    <path
      d="M327.633 127.718s29.473 19.566 36.373 10.01c6.9-9.556-13.958-22.686-36.373-10.01Z"
      fill="#F6D651"
    />
    <path
      d="M219.538 123.513c-2.267 5.133 8.221 7.344 10.33 3.474 2.602-4.639-7.886-9.003-10.33-3.474Z"
      fill="#73963B"
    />
    <path
      d="M221.411 122.348s21.982-36.566 38.286-23.693c10.33 8.154-8.478 28.313-38.286 23.693Z"
      fill="#F2A236"
    />
    <path
      d="M222.081 122.841s-22.277-37.375-7.531-44.424c11.849-5.667 23.007 18.52 7.531 44.424Z"
      fill="#F2A236"
    />
    <path
      d="M223.126 122.624s-40.986 3.376-43.983-9.457c-2.997-12.834 25.688-14.512 43.983 9.457Z"
      fill="#F2A236"
    />
    <path
      d="M220.858 121.321s38.582-18.125 44.949 1.678c3.963 12.538-22.79 18.797-44.949-1.678Z"
      fill="#F4BF3B"
    />
    <path
      d="M221.647 118.182c0 .552 9.858 55.698 2.169 95.403l2.129.415c7.787-40.12-2.07-95.661-2.168-96.213l-2.13.395Z"
      fill="#73963B"
    />
    <path
      d="M227.66 193.822s-26.693-8.036-22.79-19.745c2.879-8.707 16.068 2.982 19.715 9.063a19.962 19.962 0 0 1 3.075 10.682Z"
      fill="#47863F"
    />
    <path
      d="M227.423 193.782s-.867-27.899 11.494-27.938c9.167 0 2.287 16.23-2.346 21.521a19.947 19.947 0 0 1-9.148 6.417Z"
      fill="#73963B"
    />
    <path
      d="M222.673 122.506s4.455-43.28 20.464-40.12c12.834 2.547 7.412 28.609-20.464 40.12Z"
      fill="#F4BF3B"
    />
    <path
      d="M223.462 122.9s-36.433-19.092-32.135-31.59c4.298-12.498 29.493 1.421 32.135 31.59Z"
      fill="#F4BF3B"
    />
    <path
      d="M224.881 122.94s-34.185 22.883-43.037 13.228c-8.852-9.654 15.397-25.312 43.037-13.228Z"
      fill="#F4BF3B"
    />
    <path
      d="M745.64 129.298c-.828 5.271 9.364 4.718 10.369.651 1.223-4.975-9.482-6.338-10.369-.651Z"
      fill="#73963B"
    />
    <path
      d="M747.078 127.738s11.218-38.955 29.355-31.216c11.533 4.976-.808 28.156-29.355 31.216Z"
      fill="#F4B74B"
    />
    <path
      d="M747.808 128.054s-29.572-28.906-17.842-39.015c9.542-8.154 25.728 11.353 17.842 39.015Z"
      fill="#F4B74B"
    />
    <path
      d="M748.714 127.599s-36.866 13.19-42.78 2.133c-5.914-11.057 20.069-19.725 42.78-2.133Z"
      fill="#F4B74B"
    />
    <path
      d="M746.31 126.948s31.03-26.161 41.775-9.536c6.781 10.543-16.344 22.923-41.775 9.536Z"
      fill="#F6D651"
    />
    <path
      d="M746.211 123.888c.237.493 22.77 48.788 25.451 87.21l2.149-.158c-2.681-38.817-25.412-87.487-25.629-87.98l-1.971.928Z"
      fill="#73963B"
    />
    <path
      d="M770.381 191.965s-26.536-.809-25.826-12.577c.513-8.726 15.515-1.224 20.286 3.495a19.097 19.097 0 0 1 5.54 9.082Z"
      fill="#73963B"
    />
    <path
      d="M770.164 191.985s-7.669-25.45 3.706-28.53c8.418-2.271 6.072 14.354 3.115 20.376a18.925 18.925 0 0 1-6.821 8.154Z"
      fill="#73963B"
    />
    <path
      d="M748.261 127.6s-6.525-40.91 8.99-41.937c12.499-.83 13.84 24.483-8.99 41.937Z"
      fill="#F6D651"
    />
    <path
      d="M749.109 127.757s-38.207-8.588-37.3-21.086c.907-12.498 27.462-5.943 37.3 21.086Z"
      fill="#F6D651"
    />
    <path
      d="M750.41 127.442s-25.826 29.478-36.353 22.686c-10.528-6.792 7.964-26.99 36.353-22.686Z"
      fill="#F6D651"
    />
    <path
      d="M547.429 108.388c2.938 5.924-10.586 8.392-13.346 3.949-3.352-5.37 10.192-10.286 13.346-3.949Z"
      fill="#73963B"
    />
    <path
      d="M545.004 107.026s-28.349-41.818-49.404-27.07c-13.327 9.34 10.941 32.381 49.404 27.07Z"
      fill="#F2A236"
    />
    <path
      d="M544.157 107.618s28.744-42.746 9.719-50.801c-15.318-6.476-29.788 21.165-9.719 50.801Z"
      fill="#F2A236"
    />
    <path
      d="M542.797 107.381s52.933 3.949 56.797-10.82c3.864-14.768-33.179-16.644-56.797 10.82Z"
      fill="#F2A236"
    />
    <path
      d="M545.774 105.881s-49.799-20.731-58.04 1.974c-5.244 14.275 29.375 21.443 58.04-1.974Z"
      fill="#F4BF3B"
    />
    <path
      d="M542.285 101.853c-.138.632-12.854 64.129-2.82 109.995l2.13-.454c-9.956-45.412 2.681-108.593 2.819-109.126l-2.129-.415Z"
      fill="#73963B"
    />
    <path
      d="M536.942 188.767s34.461-9.161 29.433-22.607c-3.726-9.971-20.759 3.416-25.333 10.366-4.573 6.95-4.1 12.241-4.1 12.241Z"
      fill="#47863F"
    />
    <path
      d="M537.237 188.728s1.144-31.907-14.825-31.927c-11.829 0-2.938 18.54 3.036 24.601a26.316 26.316 0 0 0 11.789 7.326Z"
      fill="#73963B"
    />
    <path
      d="M543.408 107.243s-5.757-49.518-26.437-45.885c-16.659 2.922-9.581 32.716 26.437 45.885Z"
      fill="#F4BF3B"
    />
    <path
      d="M542.363 107.678s47.039-21.837 41.401-36.053c-5.639-14.216-37.99 1.639-41.401 36.053Z"
      fill="#F4BF3B"
    />
    <path
      d="M540.529 107.737s44.141 26.161 55.556 15.006c11.414-11.156-19.872-28.847-55.556-15.006Z"
      fill="#F4BF3B"
    />
    <path
      d="M126.604 88.605c2.721 7.029-11.494 9.32-14.096 3.949-3.194-6.457 11.178-11.53 14.096-3.95Z"
      fill="#73963B"
    />
    <path
      d="M124.159 86.808S97.032 36.322 74.321 52.552c-14.372 10.366 9.542 38.64 49.838 34.256Z"
      fill="#F4B74B"
    />
    <path
      d="M123.253 87.539s32.292-48.71 12.972-59.233c-15.555-8.273-32.056 23.456-12.972 59.233Z"
      fill="#F4B74B"
    />
    <path
      d="M121.912 87.183s54.787 7.187 59.636-9.872c4.85-17.059-33.593-21.166-59.636 9.872Z"
      fill="#F4B74B"
    />
    <path
      d="M124.987 85.584s-50.607-26.832-60.444-.671c-6.23 16.565 29.355 26.714 60.444.671Z"
      fill="#F6D651"
    />
    <path
      d="M121.656 80.727c-.178.73-16.975 74.574-9.148 128.85l2.149-.316c-7.787-53.862 8.95-127.31 9.108-128.04l-2.109-.494Z"
      fill="#73963B"
    />
    <path
      d="M110.931 160.73s36.334-9.043 31.878-25.055c-3.312-11.847-21.686 2.961-26.95 10.879a26.935 26.935 0 0 0-4.928 14.176ZM110.655 187.503s2.977-37.375-13.623-38.224c-12.302-.632-4.1 21.718 1.755 29.043a26.652 26.652 0 0 0 11.868 9.181Z"
      fill="#73963B"
    />
    <path
      d="M122.484 87.045s-3.174-58.383-24.88-55.165c-17.487 2.586-11.81 37.928 24.88 55.165Z"
      fill="#F6D651"
    />
    <path
      d="M121.38 87.519s50.134-23.259 45.166-40.239c-4.968-16.98-39.666.04-45.166 40.239Z"
      fill="#F6D651"
    />
    <path
      d="M119.467 87.48s44.397 32.933 56.896 20.435c12.499-12.498-18.966-34.928-56.896-20.436Z"
      fill="#F6D651"
    />
    <path
      d="M1247.43 122.348c-2.44 4.817 7.51 7.503 9.86 3.949 2.68-4.463-7.2-9.162-9.86-3.949Z"
      fill="#73963B"
    />
    <path
      d="M1250.72 120.768s29.24-35.401 44.54-19.053c9.68 10.346-13.56 28.787-44.54 19.053Z"
      fill="#F2A236"
    />
    <path
      d="M1251.34 121.439s-17.75-43.437-.91-48.511c13.54-4.146 21.55 23.397.91 48.511Z"
      fill="#F2A236"
    />
    <path
      d="M1252.48 121.36s-44.16-2.981-45.34-17.118c-1.19-14.136 29.71-11.372 45.34 17.118Z"
      fill="#F2A236"
    />
    <path
      d="M1250.27 119.623s43.98-13.11 47.61 8.983c2.29 13.999-27.26 16.368-47.61-8.983Z"
      fill="#F4BF3B"
    />
    <path
      d="M1249.72 117.352c0 .494 8 48.156-3 91.969l2.11.513c11.1-44.247 3.1-92.363 3.02-92.798l-2.13.316Z"
      fill="#73963B"
    />
    <path
      d="M1253.07 175.222s-26.71-3.139-24.98-14.946c1.28-8.766 15.77.119 20.17 5.291a19.251 19.251 0 0 1 4.81 9.655Z"
      fill="#47863F"
    />
    <path
      d="M1252.58 172.754s12.87-23.693 23.16-17.631c7.65 4.462-5.91 14.611-12.44 16.763a19.182 19.182 0 0 1-10.72.868Z"
      fill="#73963B"
    />
    <path
      d="M1252.03 121.163s11.65-45.412 28.21-39.488c13.32 4.857 3.31 31.709-28.21 39.488Z"
      fill="#F4BF3B"
    />
    <path
      d="M1252.81 121.696s-35.74-26.16-29.17-38.718c6.56-12.557 31.17 6.279 29.17 38.718Z"
      fill="#F4BF3B"
    />
    <path
      d="M1254.31 121.973s-40.06 18.895-47.92 7.088c-7.87-11.807 20.42-24.384 47.92-7.088Z"
      fill="#F4BF3B"
    />
    <path
      d="M847.09 136.623c-2.129 4.185 6.526 6.515 8.478 3.396 2.385-3.85-6.191-7.799-8.478-3.396Z"
      fill="#73963B"
    />
    <path
      d="M848.706 135.714s20.05-29.616 33.101-18.006c8.24 7.305-8.399 23.278-33.101 18.006Z"
      fill="#F2A236"
    />
    <path
      d="M849.239 136.169S832.344 103.887 845 98.655c10.173-4.147 18.374 16.585 4.239 37.514Z"
      fill="#F2A236"
    />
    <path
      d="M850.126 136.05s-34.422.928-36.334-9.872c-1.912-10.8 22.139-11.057 36.334 9.872Z"
      fill="#F2A236"
    />
    <path
      d="M848.293 134.846s33.081-13.348 37.457 3.494c2.839 10.662-19.872 14.67-37.457-3.494Z"
      fill="#F4BF3B"
    />
    <path
      d="M848.904 132.22c0 .335-.138 33.565-2.602 80.141l2.168.119c2.465-46.557 2.603-79.925 2.603-80.241l-2.169-.019Z"
      fill="#73963B"
    />
    <path
      d="M848.648 180.593s-21.943-7.898-18.157-17.592c2.819-7.147 13.307 3.238 16.008 8.49a16.807 16.807 0 0 1 2.149 9.102Z"
      fill="#47863F"
    />
    <path
      d="M847.938 191.788s11.178-20.515 20.128-15.302c6.624 3.949-5.224 12.676-10.804 14.551a16.614 16.614 0 0 1-9.324.751Z"
      fill="#73963B"
    />
    <path
      d="M849.752 135.932s5.717-35.994 18.965-32.598c10.666 2.744 4.87 24.266-18.965 32.598Z"
      fill="#F4BF3B"
    />
    <path
      d="M850.402 136.287s-29.572-17.651-25.412-27.839c4.16-10.188 24.584 2.566 25.412 27.839Z"
      fill="#F4BF3B"
    />
    <path
      d="M851.585 136.386s-29.571 17.552-36.59 8.983c-7.018-8.569 14.037-20.356 36.59-8.983Z"
      fill="#F4BF3B"
    />
    <path
      d="M1157.93 140.947c1.79 3.948-5.5 6.14-7.14 3.198-2.01-3.613 5.19-7.443 7.14-3.198Z"
      fill="#73963B"
    />
    <path
      d="M1156.55 140.117s-16.88-27.859-27.84-16.999c-6.94 6.871 7.06 21.935 27.84 16.999Z"
      fill="#F4B74B"
    />
    <path
      d="M1156.11 140.532s14.2-30.446 3.57-35.342c-8.57-3.969-15.47 15.578-3.57 35.342Z"
      fill="#F4B74B"
    />
    <path
      d="M1155.37 140.413s28.96.849 30.57-9.378c1.62-10.227-18.65-10.366-30.57 9.378Z"
      fill="#F4B74B"
    />
    <path
      d="M1156.92 139.288s-27.83-12.617-31.54 3.278c-2.39 10.049 16.74 13.821 31.54-3.278Z"
      fill="#F6D651"
    />
    <path
      d="M1154.4 136.721c0 .316 0 31.788 2.19 75.68l2.17-.099c-2.11-44.168-2.19-75.284-2.19-75.581h-2.17Z"
      fill="#73963B"
    />
    <path
      d="M1156.61 182.409s18.47-7.502 15.28-16.585c-2.39-6.752-11.2 3.061-13.47 7.997a17.37 17.37 0 0 0-1.81 8.588ZM1157.2 192.953s-9.4-19.349-16.94-14.413c-5.58 3.652 4.4 11.846 9.11 13.722a12.513 12.513 0 0 0 7.83.691Z"
      fill="#73963B"
    />
    <path
      d="M1155.68 140.295s-4.81-33.94-15.95-30.742c-8.99 2.587-4.12 22.884 15.95 30.742Z"
      fill="#F6D651"
    />
    <path
      d="M1155.13 140.631s24.9-16.645 21.39-26.26c-3.51-9.615-20.7 2.429-21.39 26.26Z"
      fill="#F6D651"
    />
    <path
      d="M1154.14 140.73s24.94 16.545 30.78 8.47c5.83-8.076-11.81-19.211-30.78-8.47Z"
      fill="#F6D651"
    />
    <path
      d="M1045.77 123.513c-2.26 5.133 8.22 7.344 10.33 3.474 2.61-4.639-7.88-9.003-10.33-3.474Z"
      fill="#73963B"
    />
    <path
      d="M1047.65 122.348s21.98-36.566 38.28-23.693c10.33 8.154-8.48 28.313-38.28 23.693Z"
      fill="#F4B74B"
    />
    <path
      d="M1048.32 122.841s-22.28-37.375-7.54-44.424c11.87-5.667 23.03 18.52 7.54 44.424Z"
      fill="#F4B74B"
    />
    <path
      d="M1049.36 122.624s-40.99 3.376-43.98-9.457c-3-12.834 25.69-14.512 43.98 9.457Z"
      fill="#F4B74B"
    />
    <path
      d="M1047.09 121.321s38.59-18.125 44.95 1.678c4.04 12.538-22.79 18.797-44.95-1.678Z"
      fill="#F6D651"
    />
    <path
      d="M1047.88 118.182c0 .552 9.86 55.698 2.17 95.403l2.13.415c7.89-40.12-1.97-95.661-2.17-96.213l-2.13.395Z"
      fill="#73963B"
    />
    <path
      d="M1053.89 193.822s-26.69-8.036-22.79-19.745c2.88-8.707 16.07 2.982 19.72 9.063 2.02 3.195 3.09 6.9 3.07 10.682Z"
      fill="#73963B"
    />
    <path
      d="M1053.66 193.782s-.87-27.899 11.49-27.938c9.17 0 2.29 16.23-2.34 21.521a19.953 19.953 0 0 1-9.15 6.417Z"
      fill="#47863F"
    />
    <path
      d="M1048.91 122.506s4.43-43.28 20.46-40.12c12.89 2.547 7.41 28.609-20.46 40.12Z"
      fill="#F6D651"
    />
    <path
      d="M1049.7 122.9s-36.44-19.092-32.12-31.59c4.32-12.498 29.47 1.421 32.12 31.59Z"
      fill="#F6D651"
    />
    <path
      d="M1051.12 122.94s-34.17 22.883-43.04 13.228c-8.873-9.654 15.4-25.312 43.04-13.228Z"
      fill="#F6D651"
    />
    <path
      d="M953.371 71.348c3.095 8.036-13.13 10.642-16.107 4.561-3.647-7.443 12.755-13.228 16.107-4.56Z"
      fill="#73963B"
    />
    <path
      d="M950.571 69.394S919.58 11.662 893.636 30.22c-16.462 11.768 10.941 44.07 56.935 39.173Z"
      fill="#F4B74B"
    />
    <path
      d="M949.546 70.124s36.906-55.678 14.826-67.584c-17.783-9.576-36.689 26.694-14.826 67.584Z"
      fill="#F4B74B"
    />
    <path
      d="M947.949 69.71s62.611 8.213 68.151-11.235c5.54-19.448-38.323-24.226-68.151 11.235Z"
      fill="#F4B74B"
    />
    <path
      d="M951.518 67.874s-57.823-30.643-69.001-.77c-7.196 18.954 33.475 30.544 69.001.77Z"
      fill="#F6D651"
    />
    <path
      d="M947.949 62.365c-.197.849-19.399 85.216-10.449 147.212l2.149-.316c-8.911-61.601 10.212-145.554 10.41-146.403l-2.11-.493Z"
      fill="#73963B"
    />
    <path
      d="M935.47 153.78s41.519-10.346 36.413-28.648c-3.785-13.584-24.88 3.376-30.774 12.438-5.895 9.063-5.639 16.21-5.639 16.21Z"
      fill="#47863F"
    />
    <path
      d="M935.135 184.384s3.411-42.727-15.555-43.694c-14.056-.711-4.692 24.7 1.972 33.19 6.663 8.49 13.583 10.504 13.583 10.504Z"
      fill="#73963B"
    />
    <path
      d="M948.659 69.572s-3.627-66.736-28.428-63.063c-19.99 2.961-13.504 43.338 28.428 63.063Z"
      fill="#F6D651"
    />
    <path
      d="M947.397 70.105s57.313-26.576 51.613-45.985c-5.697-19.408-45.324.02-51.613 45.984Z"
      fill="#F6D651"
    />
    <path
      d="M945.229 70.065s50.745 37.632 65.061 23.338c14.31-14.295-21.788-39.824-65.061-23.338Z"
      fill="#F6D651"
    />
    <path
      d="M654.539 127.461c1.833 4.759-7.788 6.318-9.562 2.705-2.169-4.422 7.571-7.818 9.562-2.705Z"
      fill="#73963B"
    />
    <path
      d="M652.882 126.296s-18.393-34.275-33.81-23.258c-9.759 6.989 6.466 26.161 33.81 23.258Z"
      fill="#F4B74B"
    />
    <path
      d="M652.232 126.731s21.903-33.052 8.793-40.12c-10.508-5.726-21.726 15.854-8.793 40.12Z"
      fill="#F4B74B"
    />
    <path
      d="M651.306 126.494s37.181 4.877 40.474-6.674c3.292-11.55-22.751-14.393-40.474 6.674Z"
      fill="#F4B74B"
    />
    <path
      d="M653.435 125.408s-34.323-18.204-41.006-.454c-4.219 11.215 19.931 18.125 41.006.454Z"
      fill="#F6D651"
    />
    <path
      d="M650.852 122.032c-.118.493-11.553 50.663-6.21 87.545l2.129-.316c-5.264-36.487 6.072-86.242 6.19-86.736l-2.109-.493Z"
      fill="#73963B"
    />
    <path
      d="M643.912 176.387s24.643-6.14 21.686-16.999c-2.247-8.056-14.786 1.974-18.275 7.384a18.085 18.085 0 0 0-3.411 9.615ZM643.715 194.552s1.972-25.351-9.226-25.944c-8.359-.414-2.8 14.69 1.183 19.744a18.182 18.182 0 0 0 8.043 6.2Z"
      fill="#47863F"
    />
    <path
      d="M651.739 126.395s-2.149-39.607-16.876-37.514c-11.868 1.876-8.004 25.826 16.876 37.514Z"
      fill="#F6D651"
    />
    <path
      d="M650.99 126.711s34.027-15.795 30.637-27.286c-3.391-11.491-26.911.02-30.637 27.286Z"
      fill="#F6D651"
    />
    <path
      d="M649.708 126.691s30.124 22.351 38.601 13.821c8.477-8.529-12.913-23.614-38.601-13.821Z"
      fill="#F6D651"
    />
  </svg>
);
