import { useRef } from "react";
import anime from "animejs";
import Constants from "common/constant";
import {
  AlgorithmProgress,
  CourseMessageContent,
} from "features/courses/types";
import { StageLevelRects } from "features/courses/algorithm/level/StageLevelRects";

export const Level = ({
  stage,
  step,
  scale,
  offset,
  radius,
  display,
  progresses,
  handleLevelPress,
}: {
  stage: number;
  step: number;
  scale: number;
  offset: number;
  radius: number;
  progresses: AlgorithmProgress;
  display: CourseMessageContent;
  handleLevelPress: (stepId: number) => void;
}) => {
  const isClear = progresses.level[step].is_finished;
  const isCurrent = step === progresses.stage.finished;

  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (stepId: number) => () => {
    anime({
      targets: [ref.current, `#level-animation-circle-${step}`],
      scale: [1, 0.96, 1],
      duration: 200,
      easing: "easeInOutQuad",
    }).finished.then(() => handleLevelPress(stepId));
  };

  return (
    <button
      onClick={handleClick(step + 1)}
      disabled={!(isClear || isCurrent)}
      className="flex-col-center origin-top-left border-none m-0 p-0 cursor-pointer !absolute top-0 left-0"
      style={{
        backgroundColor: "transparent",
        transform: `translate(${
          StageLevelRects[stage - 1][step].left * scale + offset
        }px, ${
          StageLevelRects[stage - 1][step].top * scale
        }px) scale(${scale})`,
      }}
    >
      <div
        className="flex-col-center !justify-start !items-start border-none"
        ref={ref}
      >
        <div className="flex-col-center !justify-start !items-start border-none">
          <img
            alt="*"
            src={`${
              Constants.assetHost
            }/assets/algorithm/shotcut/stage${stage}/step${step + 1}.webp`}
            onError={(e) =>
              // @ts-ignore
              (e.target.src = `${
                Constants.assetHost
              }/assets/algorithm/shotcut/stage${stage}/step${step + 1}.png`)
            }
            className="border-0 object-center pointer-events-none"
            style={{
              borderRadius: radius,
              width: radius * 2,
              height: radius * 2,
            }}
          />
          {!(isClear || isCurrent) && (
            <div className="flex-col-view !absolute w-full h-full bg-[rgba(0,0,0,0.5)] rounded-[100px] border-[rgb(156,157,163)]" />
          )}
        </div>

        <div className="flex-row-view items-center !absolute bottom-[-30px] -left-[20px]">
          <div
            className="flex-col-center z-[1] !absolute left-[-40px] w-[80px] h-[80px] text-white rounded-[50px]"
            style={{
              backgroundColor:
                isClear || isCurrent ? "rgb(235,91,96)" : "rgb(132,54,58)",
            }}
          >
            <p
              className="title-text !tracking-[.2em] !text-[13px]"
              style={{
                color: isClear || isCurrent ? "white" : "rgb(156,157,163)",
              }}
            >
              Level
            </p>
            <p
              className="title-text !text-[45px] !leading-[40px]"
              style={{
                color: isClear || isCurrent ? "white" : "rgb(156,157,163)",
              }}
            >
              {step + 1}
            </p>
          </div>

          <div
            className="flex-col-center !items-start w-[242px] h-[64px] pl-10 rounded-[8px]"
            style={{
              backgroundColor:
                isClear || isCurrent ? "#2766ae" : "rgb(30,53,86)",
            }}
          >
            <p
              className="title-text !tracking-[.2em] !text-[22px] !leading-[26px] self-center flex-wrap"
              style={{
                color: isClear || isCurrent ? "white" : "rgb(156,157,163)",
              }}
            >
              {display !== undefined ? display.level[step].name : ""}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};
