import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { UserAvatar } from "common/elements";
import { Path } from "./type";
import { actions } from "features/creator/slice";
import { DisplayMode } from "features/creator/types";

const CardLayout = ({
  click,
  children,
}: {
  click: () => void;
  children: any;
}) => {
  const [clicked, setClicked] = useState(false);
  const handlePointerDown = () => {
    setClicked(true);
  };
  const handlePointerUp = () => {
    setClicked(false);
    click();
  };
  return (
    <div
      onPointerUp={handlePointerUp}
      onPointerDown={handlePointerDown}
      className="flex-col-center w-[216px] h-[262px] rounded-[12px] cursor-pointer"
      style={{
        boxShadow: clicked
          ? "inset 0px -1px 0px rgba(0, 0, 0, 0.3)"
          : "inset 0px -2px 0px rgba(0, 0, 0, 0.3)",
        transform: `translateY(${clicked ? 2 : 0}px)`,
        background:
          "linear-gradient(34.2deg, #FFFFFF 0.1%, #EAEAF7 15.29%, #FFFFFF 15.3%, #EDEDF5 55.31%, #EBEBF5 71.97%, #FBFBFF 74.58%)",
      }}
    >
      <img
        alt="トロフィー"
        className="w-[168px] h-[186px] object-cover"
        src={`${Constants.assetHost}/assets/images/trophy.png`}
      />
      <div className="flex-col-el flex-center absolute">{children}</div>
    </div>
  );
};

export const RankingTop = ({
  path,
  navigate,
}: {
  path: Path;
  navigate: (path: Path) => void;
}) => {
  const handleNavigate = (path: Path) => () => {
    navigate(path);
  };
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.appUser);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const panelAction = useSelector((state: RootState) => state.creator.action);

  useEffect(() => {
    if (
      displayMode === DisplayMode.RANKING &&
      path === Path.TOP &&
      panelAction.back
    ) {
      dispatch(actions.updateDisplayMode(DisplayMode.NONE));
      dispatch(
        actions.updateProjectPanelAction({
          ...panelAction,
          back: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelAction.back]);

  return (
    <div className="flex-col-el items-center mt-2 w-full h-full absolute top-0 left-0 -z-0 overflow-hidden">
      <div className="flex-col-center">
        <div className="flex-row-center">
          <img
            alt="creator ranking top title"
            src={`${Constants.assetHost}/assets/images/creator_ranking_top_title.png`}
            className="w-[447px] h-[81px]"
          />
        </div>

        <div className="flex-col-center w-full h-[40px] my-1" />

        <div
          className="flex-row-center gap-10"
          style={{
            filter:
              "drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.2)) drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.14)) drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.12))",
          }}
        >
          <CardLayout click={handleNavigate(Path.APP)}>
            <div className="flex-col-center gap-4">
              <img
                alt="pick up"
                src={`${Constants.assetHost}/assets/images/ranking_card.png`}
                className="w-[112px] h-[138px]"
              />
              <img
                alt="pick up"
                src={`${Constants.assetHost}/assets/images/creator_ranking_app_title.png`}
                className="w-[200px] h-[64px]"
              />
            </div>
          </CardLayout>
          <CardLayout click={handleNavigate(Path.USER)}>
            <div className="flex-col-center gap-7">
              <UserAvatar size={128} icon={user.active.icon} />
              <img
                alt="pick up"
                src={`${Constants.assetHost}/assets/images/creator_ranking_user_title.png`}
                className="w-[200px] h-[64px]"
              />
            </div>
          </CardLayout>
        </div>
      </div>
    </div>
  );
};
