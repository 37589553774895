import { useState, ChangeEventHandler } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { useScale, usePlaySound } from "common/utils";
import { Spin, CloseBtn, ActionButton, FadeTransition } from "common/elements";
import { sendContact } from "features/universal/api";

export enum ContactType {
  HOW_TO = "how_to",
  BUG = "bug",
  REQUEST = "request",
  OTHER = "other",
  NONE = "none",
}

const ContactCard = ({
  selected,
  message,
  handleSelect,
  children,
}: {
  selected: boolean;
  message: string;
  handleSelect: () => void;
  children: any;
}) => (
  <button
    className="flex-col-el flex-center relative border-none bg-transparent p-0 m-6 rounded-[12px] overflow-hidden transition"
    onClick={handleSelect}
    style={{
      transform: `translateY(${selected ? 3 : 0}px)`,
      boxShadow: selected
        ? "0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 4px 1px rgba(0, 0, 0, 0.14), 0px 1px 8px 3px rgba(0, 0, 0, 0.12)"
        : "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    }}
  >
    <div className="flex-col-el flex-center relative">{children}</div>

    <div
      className={`flex-col-center w-[200px] h-[96px] px-4 transition-colors ${
        selected ? "bg-red/100" : "bg-blue/80"
      }`}
    >
      <p className="text-body text-white text-center text-[24px] whitespace-pre">
        {message}
      </p>
    </div>

    <div
      className={`flex-col-el flex-center absolute w-full h-full rounded-t-[12px] overflow-hidden box-border transition-colors ${
        selected
          ? "border-solid border-8 border-red/100"
          : "border-solid border-8 border-transparent"
      }`}
    />
  </button>
);

export const Contact = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const DETAIL_MAX_LENGTH = 200;
  const location = useLocation();
  const [detail, setDetail] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(ContactType.NONE);
  const contact = useSelector(
    (state: RootState) => state.config.dialog.contact
  );
  const activeUser = useSelector(
    (state: RootState) => state.user.appUser.active
  );

  const handleClose = () => {
    setType(ContactType.NONE);
    setDetail("");
    dispatch(
      actions.updateDialog({ type: DialogType.CONTACT, value: false, args: {} })
    );
  };

  const handleSelectType = (contactType: ContactType) => () => {
    play();
    setType(contactType);
  };

  const handleChangeDetail: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    let value: string;
    if (e.target.value.length > DETAIL_MAX_LENGTH) {
      value = e.target.value.substring(0, DETAIL_MAX_LENGTH);
    } else {
      value = e.target.value;
    }
    setDetail(value);
  };

  const handleSendContact = () => {
    setLoading(true);

    sendContact({
      uid: activeUser.uid,
      contact: {
        type,
        detail,
        url: `${location.pathname}${location.search}`,
      },
    })
      .then(() => {
        toast.success(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_TOAST_CONTACT_SEND")}
            </p>
          </div>
        );
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={contact.value}
      sx={{ zIndex: 100000 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      onClose={handleClose}
    >
      <div
        className="flex-col-center w-full h-full !absolute top-0 left-0 z-50"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin />
      </div>
      <div className="flex-col-center !absolute inset-0 bg-textcolor/black/50 w-full h-full">
        <div
          className="flex-col-view items-center justify-start w-[1180px] h-[900px] rounded-[8px] border-[12px] border-solid border-white bg-beige/60"
          style={{ transform: `scale(${scale})`, marginTop: 32 * scale }}
        >
          <CloseBtn close={handleClose} />

          <div className="flex-col-el flex-center w-[568px] mt-8">
            <div className="flex-row-el flex-center relative">
              <p
                className="title-text text-orange/100 text-[46px] tracking-widest text-body-ellipsis px-4"
                style={{ WebkitTextStroke: "16px #FFA03B" }}
              >
                {I18n.t("MSG_CONTACT_TITLE")}
              </p>
              <p
                className="title-text text-body-ellipsis text-[46px] tracking-widest text-white absolute z-10 px-4"
                style={{ WebkitTextStroke: "8px #FEFDFE" }}
              >
                {I18n.t("MSG_CONTACT_TITLE")}
              </p>
              <p className="title-text text-body-ellipsis text-[46px] tracking-widest text-textcolor/black absolute z-50">
                {I18n.t("MSG_CONTACT_TITLE")}
              </p>
            </div>
          </div>

          <div className="flex-col-center mt-16 border-solid border-orange/100 border-4 rounded-[12px] p-6">
            <div className="flex-row-el flex-center bg-beige/60 absolute -top-6 px-2">
              <p className="text-body text-[28px] text-textcolor/black">
                {I18n.t("MSG_CONTACT_TYPE_TITLE")}
              </p>
            </div>

            <div className="flex-row-center">
              <ContactCard
                message={I18n.t("MSG_CONTACT_TYPE_HOW_TO")}
                selected={type === ContactType.HOW_TO}
                handleSelect={handleSelectType(ContactType.HOW_TO)}
              >
                <img
                  alt="お問合せのアイコンー使い方"
                  src={`${Constants.assetHost}/assets/images/contact_how_to.png`}
                  className="w-[200px] h-[200px]"
                />
              </ContactCard>

              <ContactCard
                message={I18n.t("MSG_CONTACT_TYPE_BUG")}
                selected={type === ContactType.BUG}
                handleSelect={handleSelectType(ContactType.BUG)}
              >
                <img
                  alt="お問合せのアイコンーバグ"
                  src={`${Constants.assetHost}/assets/images/contact_bug.png`}
                  className="w-[200px] h-[200px]"
                />
              </ContactCard>

              <ContactCard
                message={I18n.t("MSG_CONTACT_TYPE_REQUEST")}
                selected={type === ContactType.REQUEST}
                handleSelect={handleSelectType(ContactType.REQUEST)}
              >
                <img
                  alt="お問合せのアイコンー機能要望"
                  src={`${Constants.assetHost}/assets/images/contact_request.png`}
                  className="w-[200px] h-[200px]"
                />
              </ContactCard>

              <ContactCard
                message={I18n.t("MSG_CONTACT_TYPE_OTHER")}
                selected={type === ContactType.OTHER}
                handleSelect={handleSelectType(ContactType.OTHER)}
              >
                <img
                  alt="お問合せのアイコンーその他"
                  src={`${Constants.assetHost}/assets/images/contact_other.png`}
                  className="w-[200px] h-[200px]"
                />
              </ContactCard>
            </div>
          </div>

          <div className="flex-col-center mt-10">
            <div className="flex-col-center w-[880px] h-[160px]">
              <textarea
                readOnly={type === ContactType.NONE}
                className="resize-none input text-body text-textcolor/black text-[26px]"
                placeholder={I18n.t("MSG_CONTACT_DETAIL_TITLE")}
                rows={4}
                minLength={1}
                value={detail}
                onChange={handleChangeDetail}
              />
            </div>
          </div>

          {/* Close Page Button */}
          <div className="flex-row-el flex-center mt-5">
            <ActionButton
              type="primary"
              disabled={type === ContactType.NONE || detail === ""}
              Icon={
                <MailOutlineIcon sx={{ color: "white", fontSize: "40px" }} />
              }
              text={I18n.t("MSG_CONTACT_SEND_BTN")}
              onClick={handleSendContact}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};
