import anime from "animejs";

export const projectAnimation = async (
  pre: string,
  next: string,
  callback: () => void
) => {
  anime({
    targets: document.getElementsByClassName(`app-project-card-${pre}`),
    opacity: [0, 1],
    duration: 800,
    easing: "easeInOutQuad",
  });
  anime({
    targets: document.getElementsByClassName(`app-project-card-${next}`),
    opacity: [1, 0],
    duration: 800,
    easing: "easeInOutQuad",
  }).finished.then(callback);
};
