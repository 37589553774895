import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useFetchUserData } from "app/hook";
import { getEnv, actions } from "app/configSlice";
import { AppDispatch, RootState } from "app/store";
import { btnDelay, handleError, usePlaySound } from "common/utils";
import { ConfirmMessageBox, WarningMessageBox } from "common/elements";
import {
  activeKidsUser,
  createKidsAccount,
  activeParentsKidsUser,
} from "features/user/api";
import {
  AppUser,
  UserType,
  AccountType,
  ChatRoomMessageSync,
  BillingType,
} from "features/user/types";
import {
  IconUserAdd,
  IconUserChange,
  IconUserAddInvalid,
} from "features/user/assets";
import { selectAllChatRoomMessageSync } from "features/user/slice";
import { UserList } from "./UserList";
import { CreateKidsAccount } from "./CreateKidsAccount";

const MessageUnread = ({
  uid,
  chatRoomMessageSync,
}: {
  uid: string;
  chatRoomMessageSync: ChatRoomMessageSync[];
}) =>
  chatRoomMessageSync.filter(
    (sync) =>
      sync.uid === uid &&
      (dayjs(sync.latestMessageCreatedAt, "YYYY-MM-DDTHH:mm:ssZ").diff(
        dayjs(sync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")
      ) > 0 ||
        (sync.latestReadAt === null && sync.latestMessageCreatedAt !== null))
  ).length ? (
    <div className="flex-col-el flex-center absolute right-0 top-0 bg-danger rounded-full w-[20px] h-[20px] z-50" />
  ) : (
    <></>
  );

const Account = ({
  user,
  handleOpen,
  chatRoomMessageSync,
}: {
  user: AppUser;
  handleOpen: () => void;
  chatRoomMessageSync: ChatRoomMessageSync[];
}) => (
  <div
    className="flex-col-center w-[92px] pointer-events-auto"
    onPointerDown={handleOpen}
  >
    <div
      className="flex-col-center cursor-pointer bg-white rounded-full w-[80px] h-[80px]"
      style={{
        boxShadow:
          "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
      }}
    >
      <MessageUnread uid={user.uid} chatRoomMessageSync={chatRoomMessageSync} />

      <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
        <img
          key={user.icon}
          alt="ユーザプロフィール画像"
          src={user.icon}
          className="w-full h-full object-contain"
        />
      </div>
    </div>

    <div className="flex-col-center w-[84px] h-[24px] text-gray2/100">
      <p className="text !text-[16px] w-full text-body-ellipsis">
        {user.display_name}
      </p>
    </div>
  </div>
);

export const UserAccount = () => {
  const play = usePlaySound();
  const [list, setList] = useState(false);
  const [sign, setSign] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [change, setChange] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [maxUser, setMaxUser] = useState(false);
  const { handleFetchParentsUser } = useFetchUserData();
  const [changeUser, setChangeUser] = useState<AppUser | null>(null);
  const user = useSelector((state: RootState) => state.user.appUser);
  const chatRoomMessageSync = useSelector(selectAllChatRoomMessageSync);

  const handleShowUserList = () => {
    play();
    setList(true);
  };
  const handleCloseUserList = () => {
    play();
    setList(false);
  };

  const handleOpenAddNewUser = () => {
    play();
    if (user.active.account_type === AccountType.GUEST) {
      setSign(true);
    } else {
      if (user.users.length > 4) {
        setMaxUser(true);
      } else {
        setNewUser(true);
      }
    }
  };
  const handleCreateKidsAccount = (name: string) => {
    dispatch(actions.updateResign(true));
    btnDelay(async () => {
      try {
        play();
        setNewUser(false);
        const auth = getAuth();
        const response = await createKidsAccount({
          user: {
            account_type: AccountType.KIDS,
            display_name: name,
            locale: I18n.locale,
            env: getEnv(),
            parents_user_uid: user.main.uid,
          },
        });
        await signInWithCustomToken(auth, response.data.idToken);
        await handleFetchParentsUser({ uid: user.main.uid });
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_USER_PROFILE_ADD_USER_SUCCESS")}
            </p>
          </div>
        );
      } catch (error) {
        console.log(error);
        handleError(error, I18n.t("MSG_USER_PROFILE_ADD_USER_FAILED"), () =>
          dispatch(actions.updateResign(false))
        );
      }
    });
  };
  const handleAddNewUserCancel = () => {
    play();
    btnDelay(() => setNewUser(false));
  };
  const handleCloseSign = () => {
    play();
    setSign(false);
  };
  const handleCloseWarning = () => {
    play();
    setMaxUser(false);
  };

  const handleOpenChangeUser = (user: AppUser) => () => {
    play();
    setChange(true);
    setChangeUser(user);
  };
  const handleChangeUser = () => {
    dispatch(actions.updateResign(true));
    btnDelay(async () => {
      setChange(false);
      try {
        const auth = getAuth();
        if (user.active.account_type === AccountType.KIDS) {
          const response = await activeParentsKidsUser({
            parents_uid: user.main.uid,
            kids_uid: changeUser.uid,
          });
          await signInWithCustomToken(auth, response.data.idToken);
          await handleFetchParentsUser({ uid: user.main.uid });
        } else {
          const response = await activeKidsUser({
            uid: changeUser.uid,
          });
          await signInWithCustomToken(auth, response.data.idToken);
        }
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_USER_PROFILE_CHANGE_USER_SUCCESS")}
            </p>
          </div>
        );
      } catch (error) {
        console.error(changeUser.email);
        console.error(JSON.stringify(changeUser));
        handleError(
          error,
          I18n.t("MSG_USER_PROFILE_CHANGE_USER_FAILED"),
          () => {
            dispatch(actions.updateResign(false));
          }
        );
      }
    });
  };
  const handleChangeUserCancel = () => {
    play();
    setChange(false);
    setChangeUser(null);
  };

  return (
    <>
      <UserList visible={list} close={handleCloseUserList} />
      {newUser && (
        <CreateKidsAccount
          close={handleAddNewUserCancel}
          confirm={handleCreateKidsAccount}
        />
      )}
      {maxUser && (
        <WarningMessageBox
          zIndex={10110}
          title={I18n.t("MSG_USER_PROFILE_MAX_USER_TITLE")}
          message={I18n.t("MSG_USER_PROFILE_MAX_USER_MESSAGE")}
          close={handleCloseWarning}
        />
      )}
      {sign && (
        <WarningMessageBox
          zIndex={10110}
          title={I18n.t("MSG_USER_PROFILE_SIGN_TITLE")}
          message={I18n.t("MSG_USER_PROFILE_SIGN_MESSAGE")}
          close={handleCloseSign}
        />
      )}
      {change && (
        <ConfirmMessageBox
          title={I18n.t("MSG_USER_PROFILE_CHANGE_USER_TITLE")}
          message={I18n.t("MSG_USER_PROFILE_CHANGE_USER_MESSAGE", {
            name: changeUser.display_name,
          })}
          close={handleChangeUserCancel}
          confirm={handleChangeUser}
        />
      )}

      <div className="flex-col-view justify-start items-end w-[416px] h-[168px]">
        <div className="flex-col-center w-[370px] overflow-hidden">
          <div className="flex-row-center px-[8px] h-[28px] bg-orange/alt rounded-[14px]">
            <IconUserChange />
            <p className="text text-textcolor/black !text-[16px] !px-[8px]">
              {I18n.t("MSG_USER_PROFILE_CHANGE_USER")}
            </p>
          </div>

          <div className="flex-row-el flex-center mt-[20px]">
            {user.main.billing_type === BillingType.EDUCATION ? (
              user.users.filter(
                (u) =>
                  u.type !== UserType.FROZEN &&
                  u.account_type !== AccountType.PARENTS
              ).length > 4 && (
                <button
                  id="user-page-user-list-btn"
                  onClick={handleShowUserList}
                  className="opacity-button w-[92px]"
                >
                  <div className="flex-col-center">
                    <span className="material-symbols-outlined text-[#FFD1A1] text-[64px]">
                      expand_circle_down
                    </span>
                  </div>
                  <p className="text text-gray2/100 !text-[16px]">
                    {I18n.t("MSG_USER_PROFILE_SHOW_USER_LIST_BTN")}
                  </p>
                </button>
              )
            ) : (
              <button
                onClick={handleOpenAddNewUser}
                className="opacity-button w-[92px]"
              >
                <div className="flex-col-center w-[80px] h-[80px]">
                  {user.active.account_type === AccountType.KIDS &&
                  user.users.length < 5 ? (
                    <IconUserAdd />
                  ) : (
                    <IconUserAddInvalid />
                  )}
                </div>
                <p className="text text-gray2/100 !text-[16px]">
                  {I18n.t("MSG_USER_PROFILE_ADD_USER_BTN")}
                </p>
              </button>
            )}

            {user.users
              .filter(
                (u) =>
                  u.uid !== user.active.uid &&
                  u.type !== UserType.FROZEN &&
                  u.account_type !== AccountType.PARENTS
              )
              .sort((a, b) => {
                return dayjs(a.created_at) < dayjs(b.created_at) ? 1 : -1;
              })
              .slice(0, 3)
              .map((user) => (
                <Account
                  key={user.uid}
                  user={user}
                  handleOpen={handleOpenChangeUser(user)}
                  chatRoomMessageSync={chatRoomMessageSync}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
