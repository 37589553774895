import { useMemo, ChangeEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { useUserConfig } from "app/hook";
import { actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { PageProps } from "features/tutorial/types";
import { TopMessageBg, TopPageBackground } from "features/tutorial/assets";

export const Top = (props: PageProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleChangeUserConfig = useUserConfig();
  const config = useSelector((state: RootState) => state.config.userConfig);

  const messages = [
    I18n.t("MSG_TOP_TUTORIAL_FIRST_WELCOM_MESSAGE"),
    I18n.t("MSG_TOP_TUTORIAL_WELCOM_MESSAGE_1"),
    I18n.t("MSG_TOP_TUTORIAL_WELCOM_MESSAGE_2"),
    I18n.t("MSG_TOP_TUTORIAL_WELCOM_MESSAGE_3"),
  ];
  const message = useMemo(() => {
    return config.is_first
      ? messages[0]
      : messages[Math.floor(Math.random() * 3) + 1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartApp = () => {
    props.close();
    dispatch(actions.closeTutorial({ key: "top" }));
  };

  const handleDoNotShow: ChangeEventHandler<HTMLInputElement> = () =>
    handleChangeUserConfig("tutorial.top", !config.tutorial.top);

  return (
    <>
      <img
        alt="title logo"
        src={`${Constants.assetHost}/assets/images/title_logo.png`}
        className="w-[580px] h-[130px] absolute top-[48px]"
      />

      <img
        alt="duck puzzle"
        src={`${Constants.assetHost}/assets/images/duck_puzzle.png`}
        className="w-[204px] h-[250px] absolute top-[323px] left-[142px]"
      />

      <div className="flex-col-center !absolute top-[189px] left-[164px] w-[285px] h-[202px] z-10">
        <div className="flex-col-center !absolute top-[33px] left-[34px]">
          <TopMessageBg />
          <div className="flex-col-view !absolute top-[30px] w-[180px] h-[90px]">
            <p className="text text-[#000000] w-full">{message}</p>
          </div>
        </div>
      </div>

      <div
        className="flex-col-center !absolute top-[190px] left-[382px] w-[435px] h-[350px]"
        style={{
          filter:
            "drop-shadow(-1px 0px 0px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 1px rgba(63, 74, 97, 0.25))",
        }}
      >
        <div className="flex-col-el absolute">
          <div
            className="flex-col-el flex-center w-[160px] h-[180px] rounded-full absolute left-0 bottom-0 bg-[#FFDEBA] wave"
            style={{
              borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
            }}
          />
          <div
            className="flex-col-el flex-center w-[160px] h-[180px] rounded-full absolute right-0 top-0 bg-[#F9E532] wave"
            style={{
              borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
            }}
          />
          <div
            className="flex-col-el flex-center w-[300px] h-[280px] rounded-full bg-[#2BDC98] wave"
            style={{
              borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
            }}
          />
        </div>

        <div
          id="start-app-button"
          className="flex-col-center !absolute top-0 left-[92px] w-[286px] h-[273px] z-10 cursor-pointer"
          onClick={handleStartApp}
        >
          <div className="flex-row-view items-end justify-center h-[76px] !absolute top-[123px]">
            <p
              className="title-text text-white !text-[64px] !leading-none !tracking-[0.02em]"
              style={{
                textShadow:
                  "-1px 0px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
              }}
            >
              {I18n.t("MSG_TOP_TUTORIAL_APP_START")}
            </p>
          </div>
        </div>
      </div>

      <div
        className="flex-col-center !absolute top-[300px] left-[849px] w-[300px] h-[270px]"
        style={{
          filter:
            "drop-shadow(-1px 0px 0px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 1px rgba(63, 74, 97, 0.25))",
        }}
      >
        <div className="flex-col-el absolute">
          <div
            className="flex-col-el flex-center w-[100px] h-[112px] rounded-full absolute -left-10 bottom-10 bg-[#FEC1BF] wave"
            style={{
              borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
            }}
          />
          <div
            className="flex-col-el flex-center w-[200px] h-[220px] rounded-full bg-[#94C4F7] wave"
            style={{
              borderRadius: "50% 50% 50% 70% / 50% 50% 70% 60%",
            }}
          />
        </div>

        <div
          className="flex-col-center !absolute top-[70px] left-[40px] w-[203px] h-[145px] z-10 cursor-pointer"
          onClick={props.next}
        >
          <div className="flex-col-view justify-around w-[188px] h-[76px] !absolute top-[40px]">
            <p
              className="title-text text-white !text-[28px] !leading-[38px] !tracking-[0.02em] w-full"
              style={{
                textShadow:
                  "-1px 0px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
              }}
            >
              {I18n.t("MSG_TOP_TUTORIAL_START")}
            </p>
          </div>
        </div>
      </div>

      <div className="flex-col-center !absolute top-[577px] left-[879px]">
        <div className="flex-row-center">
          <input
            id="tutorial-checkbox"
            type="checkbox"
            className="w-[24px] h-[24px] mr-[8px]"
            checked={!config.tutorial.top}
            onChange={handleDoNotShow}
          />
          <label
            htmlFor="tutorial-checkbox"
            className="text text-textcolor/black cursor-pointer"
          >
            {I18n.t("MSG_TOP_TUTORIAL_DONOT_SHOW")}
          </label>
        </div>
      </div>

      <div className="flex-col-center !absolute top-0 left-0 w-full h-full pointer-events-none">
        <TopPageBackground />
      </div>
    </>
  );
};
