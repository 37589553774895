import { useDispatch } from "react-redux";
import I18n from "i18n-js";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { IconPlayerPlay } from "common/assets";
import { CreatorGameCaseManager } from "common/elements";
import { IconRunPreview } from "features/creator/assets";

export const ProjectItemComponent = (props: {
  isFromUser: boolean;
  messageContent: any;
}) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const { isFromUser, messageContent } = props;
  const GameCaseComponent = messageContent.game_case
    ? CreatorGameCaseManager[messageContent.game_case]
    : "small_gray_case.png";

  const handleRunProject = () => {
    play();
    dispatch(
      actions.updateDialog({
        type: DialogType.PROJECT_PREVIEW,
        value: true,
        args: { projectId: messageContent.project_id, isUserProject: "false" },
      })
    );
  };

  return (
    <div
      className={`flex-col-el items-end justify-center w-[60%] ${
        isFromUser ? "ml-1" : "mr-1"
      }`}
    >
      <div className={`flex-row-el justify-end w-full my-2`}>
        <div
          className={`p-3 rounded-md ${
            isFromUser ? "bg-green/80" : "bg-gray/20"
          }`}
        >
          <p className="text-body text-[20px] text-textcolor/black">
            {I18n.t("MSG_USER_PROFILE_MESSAGE_PUBLISHED_PROJECT_MESSAGE", {
              name: messageContent.name,
            })}
          </p>
        </div>
      </div>

      <div className="flex-col-el flex-center w-[90%]">
        {/* 4 : 3 */}
        <div className="w-[100%] pt-[75%] relative">
          <div className="w-full h-full absolute top-0 left-0">
            {/* ケースのキャプチャエリアのオリジナルサイズ: 562px * 414px */}
            <div className="absolute z-10 flex flex-col justify-center items-center w-full h-full">
              <div className="w-[85%] h-[86%] relative box-border border-solid border-gray/100 border-[6px] rounded-[16px] overflow-hidden">
                {messageContent.capture ? (
                  <img
                    alt="プロジェクトキャプチャ画像"
                    src={messageContent.capture}
                    className="w-ull h-full object-contain"
                  />
                ) : (
                  <div className="w-full h-full bg-white" />
                )}

                <div
                  className="flex-row-center !absolute bottom-0 left-0 w-full h-[32px] bg-gray/10 border-solid border-0 border-t-[1px] border-gray/20"
                  style={{
                    boxShadow: "0px -1px 4px rgba(63, 74, 97, 0.15)",
                  }}
                >
                  <div
                    className="flex-col-center"
                    style={{
                      transform: `translateZ(0) scale(0.8)`,
                    }}
                  >
                    <button
                      className="flex-col-el flex-center border-none bg-transparent p-0 relative active:opacity-70 w-[120px] h-full top-[-20px]"
                      onClick={handleRunProject}
                    >
                      <div className="flex-col-view items-center w-full h-full !absolute top-[-12px] left-0">
                        <div className="flex-col-center w-[124px] h-[95px]">
                          <IconRunPreview />
                        </div>
                      </div>

                      <div className="flex-col-center">
                        <div className="flex-col-center w-[40px] h-[40px]">
                          <IconPlayerPlay color="white" width={2} />
                        </div>
                        <p className="text text-white">
                          {I18n.t("MSG_CREATOR_RUN_APP_BTN")}
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* ケースのオリジナルサイズ: 664px * 498px */}
            <div className="absolute top-0 left-0 w-full h-full">
              <img
                alt="game case"
                src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
