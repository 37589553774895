import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import produce from "immer";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { updateSpecificUserConfig } from "app/api";
import { AppDispatch, RootState } from "app/store";
import { useDialog, useFetchUserData } from "app/hook";
import {
  Switch,
  RadioButton,
  ConfirmMessageBox,
  WarningMessageBox,
  DeleteConfirmMessageBox,
} from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";
import {
  AppUser,
  UserType,
  AccountType,
  BillingType,
  BILLING_LIMIT_ACCOUNT,
} from "features/user/types";
import { changeKidsUserType, deleteParentsKidsUser } from "features/user/api";
import { UserConfigs } from "./ParentsUser";

const languageMapping = {
  ja: " ひらがな",
  ja_kanji: "漢字",
};

const Title = ({ text }: { text: string }) => (
  <div className="flex-row-el w-[240px] items-center justify-end h-[80px]">
    <p className="text-body text-[20px] text-textcolor/black text-right leading-[30px]">
      {text}
    </p>
  </div>
);

export const UserSetting = ({
  selectedUser,
  userConfigs,
  setUserConfigs,
}: {
  selectedUser: AppUser;
  userConfigs: UserConfigs;
  setUserConfigs: (config: UserConfigs) => void;
}) => {
  const auth = getAuth();
  const play = usePlaySound();
  const handleDialog = useDialog();
  const dispatch = useDispatch<AppDispatch>();
  const [change, setChange] = useState(false);
  const [remove, setRemove] = useState(false);
  const { handleFetchParentsUser } = useFetchUserData();
  const [removeWarning, setRemoveWarning] = useState(false);
  const [changeMaxWarning, setChangeMaxWarning] = useState(false);
  const [changeMinWarning, setChangeMinWarning] = useState(false);
  const appUser = useSelector((state: RootState) => state.user.appUser);

  const handleChangeUserConfig = (
    uid: string,
    key: string,
    value: string | boolean
  ) => async () => {
    play();
    if (uid === appUser.active.uid) {
      dispatch(actions.updateUserConfig({ key, value }));
      if (key === "locale") {
        I18n.locale = value as string;
      }
    }

    const newConfig = produce(userConfigs[uid], (draft) => {
      draft[key] = value;
    });
    const newUserConfigs = produce(userConfigs, (draft) => {
      draft[uid] = newConfig;
    });
    setUserConfigs(newUserConfigs);

    await updateSpecificUserConfig({
      uid: uid,
      config: newConfig,
    });
  };

  const handleOpenChangeUserStatus = () => {
    play();
    btnDelay(() => {
      if (selectedUser.type === UserType.FROZEN) {
        if (appUser.main.billing_type === BillingType.EDUCATION) {
          if (
            appUser.users.filter(
              (u) =>
                u.account_type !== AccountType.PARENTS &&
                u.type !== UserType.FROZEN
            ).length >
            BILLING_LIMIT_ACCOUNT.EDUCATION - 1
          ) {
            setChangeMaxWarning(true);
          } else {
            setChange(true);
          }
        } else {
          if (
            appUser.users.filter(
              (u) =>
                u.account_type !== AccountType.PARENTS &&
                u.type !== UserType.FROZEN
            ).length > 3
          ) {
            setChangeMaxWarning(true);
          } else {
            setChange(true);
          }
        }
      } else {
        if (
          appUser.users.filter(
            (u) =>
              u.account_type !== AccountType.PARENTS &&
              u.type !== UserType.FROZEN
          ).length === 1
        ) {
          setChangeMinWarning(true);
        } else {
          setChange(true);
        }
      }
    });
  };
  const handleChangeUserStatus = () => {
    play();
    btnDelay(async () => {
      try {
        dispatch(actions.updateResign(true));
        const response = await changeKidsUserType({
          parents_uid: appUser.main.uid,
          kids_uid: selectedUser.uid,
          type:
            selectedUser.type === UserType.FROZEN
              ? UserType.SUB
              : UserType.FROZEN,
          is_user_sign:
            selectedUser.type === UserType.SUB &&
            auth.currentUser.uid === selectedUser.uid,
        });
        if (response.data.idToken) {
          await signInWithCustomToken(auth, response.data.idToken);
        }
        await handleFetchParentsUser({ uid: appUser.main.uid });
        setChange(false);
      } catch (error) {
        console.error(error);
        dispatch(actions.updateResign(false));
        handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
      }
    });
  };

  const handleOpenDeleteKidsUser = () => {
    play();
    btnDelay(() => {
      if (
        appUser.users.filter(
          (u) =>
            u.account_type === AccountType.KIDS && u.type !== UserType.FROZEN
        ).length === 1
      ) {
        setRemoveWarning(true);
      } else {
        setRemove(true);
      }
    });
  };
  const handleDeleteKidsUser = () => {
    play();
    btnDelay(async () => {
      try {
        dispatch(actions.updateResign(true));
        const response = await deleteParentsKidsUser({
          parents_uid: appUser.main.uid,
          kids_uid: selectedUser.uid,
          is_user_sign: auth.currentUser.uid === selectedUser.uid,
        });
        if (response.data.idToken) {
          await signInWithCustomToken(auth, response.data.idToken);
        }
        await handleFetchParentsUser({ uid: appUser.main.uid });
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_SETTING_DATA_DELETE_SUCCESS")}
            </p>
          </div>
        );
        setRemove(false);
      } catch (error) {
        console.error(error);
        dispatch(actions.updateResign(false));
        handleDialog({ type: DialogType.ERROR_NETWORK, value: true });
      }
    });
  };

  const handleCloseDialog = () => {
    play();
    btnDelay(() => {
      setChange(false);
      setRemove(false);
      setRemoveWarning(false);
      setChangeMaxWarning(false);
      setChangeMinWarning(false);
    });
  };

  return (
    <>
      {change && (
        <ConfirmMessageBox
          title={
            selectedUser.type === UserType.FROZEN
              ? I18n.t("MSG_SETTING_PARENTS_ENABLE_KIDS_USER_TITLE")
              : I18n.t("MSG_SETTING_PARENTS_DISABLE_KIDS_USER_TITLE")
          }
          message={
            selectedUser.type === UserType.FROZEN
              ? I18n.t("MSG_SETTING_PARENTS_ENABLE_KIDS_USER_MESSAGE", {
                  name: selectedUser?.display_name,
                })
              : I18n.t("MSG_SETTING_PARENTS_DISABLE_KIDS_USER_MESSAGE", {
                  name: selectedUser?.display_name,
                })
          }
          close={handleCloseDialog}
          confirm={handleChangeUserStatus}
          is_parents
        />
      )}
      {remove && (
        <DeleteConfirmMessageBox
          title={I18n.t("MSG_SETTING_PARENTS_REMOVE_KIDS_USER_TITLE")}
          message={I18n.t("MSG_SETTING_PARENTS_REMOVE_KIDS_USER_MESSAGE", {
            name: selectedUser?.display_name,
          })}
          close={handleCloseDialog}
          confirm={handleDeleteKidsUser}
          is_parents
        />
      )}
      {removeWarning && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t("MSG_SETTING_PARENTS_REMOVE_KIDS_MIN_WARNING_TITLE")}
          message={I18n.t(
            "MSG_SETTING_PARENTS_REMOVE_KIDS_MIN_WARNING_MESSAGE"
          )}
          close={handleCloseDialog}
        />
      )}
      {changeMaxWarning && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t(
            "MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MAX_WARNING_TITLE"
          )}
          message={I18n.t(
            "MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MAX_WARNING_MESSAGE"
          )}
          close={handleCloseDialog}
        />
      )}
      {changeMinWarning && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t(
            "MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MIN_WARNING_TITLE"
          )}
          message={I18n.t(
            "MSG_SETTING_PARENTS_CHANGE_KIDS_USER_MIN_WARNING_MESSAGE"
          )}
          close={handleCloseDialog}
        />
      )}

      <div className="flex-col-el w-full gap-10 px-10 mt-10">
        <div className="flex-col-el flex-1 gap-10">
          <div className="flex-row-center h-[80px] gap-8">
            <Title text={I18n.t("MSG_SETTING_MENU_PROJECT_PUBLISH")} />
            <div className="flex-row-center">
              <Switch
                trackInactive="rgba(63, 74, 97, 0.5)"
                width={120}
                height={50}
                value={userConfigs[selectedUser.uid]?.project_publish}
                onClick={handleChangeUserConfig(
                  selectedUser.uid,
                  "project_publish",
                  !userConfigs[selectedUser.uid]?.project_publish
                )}
              />
            </div>
          </div>
          <div className="flex-row-center h-[80px] gap-8">
            <Title text={I18n.t("MSG_SETTING_MENU_PROJECT_COPIED")} />
            <div className="flex-row-center">
              <Switch
                trackInactive="rgba(63, 74, 97, 0.5)"
                width={120}
                height={50}
                value={userConfigs[selectedUser.uid]?.project_copied}
                onClick={handleChangeUserConfig(
                  selectedUser.uid,
                  "project_copied",
                  !userConfigs[selectedUser.uid]?.project_copied
                )}
              />
            </div>
          </div>
          <div className="flex-row-center h-[80px] gap-8">
            <Title text={I18n.t("MSG_SETTING_MENU_LANGUAGE")} />
            <div className="flex-col-el items-start gap-2">
              <div className="flex-row-center">
                <RadioButton
                  size={30}
                  value={userConfigs[selectedUser.uid]?.locale === "ja"}
                  label={languageMapping["ja"]}
                  onChange={handleChangeUserConfig(
                    selectedUser.uid,
                    "locale",
                    "ja"
                  )}
                />
              </div>
              <div className="flex-row-center">
                <RadioButton
                  size={30}
                  value={userConfigs[selectedUser.uid]?.locale === "ja_kanji"}
                  label={languageMapping["ja_kanji"]}
                  onChange={handleChangeUserConfig(
                    selectedUser.uid,
                    "locale",
                    "ja_kanji"
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex-row-center h-[80px] gap-8">
            <Title text={I18n.t("MSG_SETTING_APP_SETTING_USER_ACTIVE")} />
            <div className="flex-row-center">
              <Switch
                trackInactive="rgba(63, 74, 97, 0.5)"
                width={120}
                height={50}
                value={selectedUser.type !== UserType.FROZEN}
                onClick={handleOpenChangeUserStatus}
              />
            </div>
          </div>
        </div>

        <div className="flex-col-el flex-1 gap-10">
          <div className="flex-row-el flex-center alpha">
            <button
              disabled={
                appUser.users.filter(
                  (u) =>
                    u.account_type === AccountType.KIDS &&
                    u.type !== UserType.FROZEN
                ).length === 1
              }
              className="btn btn-danger"
              onPointerDown={handleOpenDeleteKidsUser}
            >
              <p className="text-body text-white text-[24px] !mb-[3px]">
                {I18n.t("MSG_PARENTS_KIDS_USER_DELETE_BTN")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
