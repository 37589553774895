import { Category, Block, Value, Shadow, Field } from "common/blockly";

export const Math = () => {
  return (
    <Category
      name="えんざん"
      categorystyle="math_category"
      toolboxitemid="math"
    >
      <Block type="math_angle">
        <Field name="value">0</Field>
      </Block>
      <Block type="math_number">
        <Field name="NUM">0</Field>
      </Block>
      <Block type="math_arithmetic">
        <Field name="OP">ADD</Field>
        <Value name="A">
          <Shadow type="math_number" />
        </Value>
        <Value name="B">
          <Shadow type="math_number" />
        </Value>
      </Block>
      <Block type="math_arithmetic">
        <Field name="OP">MINUS</Field>
        <Value name="A">
          <Shadow type="math_number" />
        </Value>
        <Value name="B">
          <Shadow type="math_number" />
        </Value>
      </Block>
      <Block type="math_arithmetic">
        <Field name="OP">MULTIPLY</Field>
        <Value name="A">
          <Shadow type="math_number" />
        </Value>
        <Value name="B">
          <Shadow type="math_number" />
        </Value>
      </Block>
      <Block type="math_arithmetic">
        <Field name="OP">DIVIDE</Field>
        <Value name="A">
          <Shadow type="math_number" />
        </Value>
        <Value name="B">
          <Shadow type="math_number" />
        </Value>
      </Block>
      <Block type="math_round">
        <Value name="NUM">
          <Shadow type="math_number">
            <Field name="NUM">3.1</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="math_modulo">
        <Value name="DIVIDEND">
          <Shadow type="math_number">
            <Field name="NUM">64</Field>
          </Shadow>
        </Value>
        <Value name="DIVISOR">
          <Shadow type="math_number">
            <Field name="NUM">10</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="math_constrain">
        <Value name="VALUE">
          <Shadow type="math_number">
            <Field name="NUM">50</Field>
          </Shadow>
        </Value>
        <Value name="LOW">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="HIGH">
          <Shadow type="math_number">
            <Field name="NUM">100</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="math_random_int">
        <Value name="FROM">
          <Shadow type="math_number">
            <Field name="NUM">1</Field>
          </Shadow>
        </Value>
        <Value name="TO">
          <Shadow type="math_number">
            <Field name="NUM">100</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="math_random_float" />
    </Category>
  );
};
