import * as React from "react";

export const NewBtnSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={72}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x={1} y={1} width={70} height={62} rx={11} fill="#FEFDFE" />
      <path
        d="M16.7 55a1.13 1.13 0 0 1-1.12-1.14V41.54a1.13 1.13 0 0 1 1.12-1.14h.44c.76 0 1.32.333 1.68 1l4.94 8.92c0 .013.007.02.02.02.013 0 .02-.007.02-.02v-8.78c0-.307.113-.573.34-.8.227-.227.493-.34.8-.34h.44c.307 0 .567.113.78.34.227.227.34.493.34.8v12.32c0 .307-.113.573-.34.8a1.03 1.03 0 0 1-.78.34h-.44c-.76 0-1.32-.333-1.68-1l-4.94-8.92c0-.013-.007-.02-.02-.02-.013 0-.02.007-.02.02v8.78c0 .307-.113.573-.34.8-.227.227-.493.34-.8.34h-.44Zm14.102 0c-.307 0-.573-.113-.8-.34a1.095 1.095 0 0 1-.34-.8V41.54c0-.307.113-.573.34-.8.227-.227.493-.34.8-.34h6.8c.307 0 .573.113.8.34.227.227.34.493.34.8a1.13 1.13 0 0 1-1.14 1.12h-4.96c-.12 0-.18.06-.18.18v3.22c0 .12.06.18.18.18h4.62c.307 0 .56.107.76.32.213.213.32.467.32.76s-.107.547-.32.76c-.2.2-.453.3-.76.3h-4.62c-.12 0-.18.06-.18.18v4c0 .12.06.18.18.18h4.96c.307 0 .573.113.8.34.227.213.34.473.34.78 0 .307-.113.573-.34.8-.227.227-.493.34-.8.34h-6.8Zm12.751-1.12-2.56-12.36a.865.865 0 0 1 .18-.78c.2-.227.447-.34.74-.34h.58c.334 0 .62.107.86.32.24.213.387.48.44.8l1.66 10.52c0 .013.007.02.02.02.027 0 .04-.007.04-.02l1.6-10.52c.054-.32.2-.587.44-.8.24-.213.527-.32.86-.32h1.2c.334 0 .62.107.86.32.24.213.387.48.44.8l1.62 10.52c0 .013.007.02.02.02.014 0 .02-.007.02-.02l1.66-10.52c.054-.32.2-.587.44-.8.24-.213.527-.32.86-.32h.5a.89.89 0 0 1 .72.34.915.915 0 0 1 .2.78l-2.56 12.36a1.39 1.39 0 0 1-.48.8c-.253.213-.546.32-.88.32h-1.06c-.333 0-.626-.107-.88-.32a1.333 1.333 0 0 1-.44-.8l-1.66-10.52c0-.013-.006-.02-.02-.02-.013 0-.02.007-.02.02l-1.66 10.52c-.053.32-.206.587-.46.8a1.25 1.25 0 0 1-.86.32h-1.06c-.333 0-.626-.107-.88-.32a1.39 1.39 0 0 1-.48-.8ZM36 32c6.627 0 12-5.373 12-12S42.627 8 36 8s-12 5.373-12 12 5.373 12 12 12Z"
        fill="#94C4F7"
      />
      <rect
        x={28.5}
        y={19.25}
        width={15}
        height={1.5}
        rx={0.75}
        fill="#FEFDFE"
      />
      <rect
        x={35.25}
        y={27.5}
        width={15}
        height={1.5}
        rx={0.75}
        transform="rotate(-90 35.25 27.5)"
        fill="#FEFDFE"
      />
      <rect
        x={1}
        y={1}
        width={70}
        height={62}
        rx={11}
        stroke="#DBEBFC"
        strokeWidth={2}
      />
    </svg>
  );
};
