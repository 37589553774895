import { useEffect } from "react";
import { InitButtonClickBlock, InitLabelTextSetterBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitButtonClickBlock();
    InitLabelTextSetterBlock();
  }, []);

  return (
    <div className="flex-col-center gap-2 mt-8">
      <div className="flex-row-center gap-2">
        <div className="flex-col-center">
          <div className="flex-row-center gap-4 p-2 border-solid border-[4px] rounded-[6px] border-white/20">
            <p
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              あ
            </p>
            <p className="text text-white !text-[50px] opacity-70">→</p>
            <p
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              い
            </p>
          </div>

          <p className="text text-white">ボックス1</p>
        </div>
      </div>

      <div className="flex-row-el flex-center h-[50px] relative">
        <div className="flex-row-el flex-center gap-24 upsilon z-50">
          <button
            disabled
            className="btn btn-blue"
            onPointerDown={(e) => e.stopPropagation()}
          >
            <p className="text !mb-[2px]">ボタン１</p>
          </button>
          <button
            disabled
            className="btn btn-blue"
            onPointerDown={(e) => e.stopPropagation()}
          >
            <p className="text !mb-[2px]">ボタン２</p>
          </button>
        </div>
      </div>
    </div>
  );
};
