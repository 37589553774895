import { useRef, useEffect, MutableRefObject } from "react";
import Blockly from "blockly";
import { ToolboxInfoType } from "features/courses/algorithm/game/type";

const blockStyleMapping = (
  blockType: string
): { height: number; color: string; borderColor: string } => {
  switch (blockType) {
    case "character_motion_move":
    case "character_motion_jump":
    case "character_motion_turn_left":
    case "character_motion_turn_right":
      return { height: 56, color: "#4C97FF", borderColor: "#3971bf" };

    case "controls_repeat_internal":
      return { height: 125, color: "#FFAB19", borderColor: "#bf8013" };

    case "controls_repeat_var_internal":
    case "controls_whileUntil_colour_internal":
    case "controls_whileUntil_colour_var_internal":
      return { height: 112, color: "#FFAB19", borderColor: "#bf8013" };

    case "controls_if_colour_internal":
    case "controls_if_colour_var_internal":
      return { height: 112, color: "#FFAB19", borderColor: "#bf8013" };

    case "math_number":
      return { height: 40, color: "#389438", borderColor: "#389438" };

    case "colour_picker_internal":
      return { height: 40, color: "#71cdfc", borderColor: "#fff" };

    case "variables_set":
    case "in_variables_set":
      return { height: 69, color: "#ff8c1a", borderColor: "#db6e00" };

    case "variables_get":
    case "in_variables_get":
      return { height: 40, color: "#ff8c1a", borderColor: "#db6e00" };

    case "procedures_defnoreturn":
      return { height: 139, color: "#ff6680", borderColor: "#ff3355" };

    case "procedures_callnoreturn":
    case "procedures_callnoreturn_1":
    case "procedures_callnoreturn_2":
      return { height: 56, color: "#ff6680", borderColor: "#ff3355" };

    default:
      return { height: 0, color: "", borderColor: "" };
  }
};

export const BlockLimits = (props: {
  offset: number;
  width: number;
  height: number;
  scale: number;
  workspace: Blockly.WorkspaceSvg;
  toolboxRef: MutableRefObject<ToolboxInfoType>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { offset, width, height, scale, workspace, toolboxRef } = props;

  useEffect(() => {
    if (workspace) {
      const ws = (workspace.getFlyout() as Blockly.Flyout).getWorkspace();
      const onEvent = (event) => {
        switch (event.type) {
          case Blockly.Events.BLOCK_CREATE:
            const block = ws.getBlockById(event.blockId);
            if (block) {
              block.contextMenu = false;
              block.setEditable(false);
              block
                .getChildren(false)
                .forEach((block) => block.setEditable(false));
            }
            break;
          case Blockly.Events.VIEWPORT_CHANGE:
            if (ref.current) {
              ref.current.scrollTop = event.viewTop;
            }
            break;
          default:
            break;
        }
      };
      ws.addChangeListener(onEvent);
      return () => {
        if (workspace) {
          const flyout = workspace.getFlyout() as Blockly.Flyout;
          if (flyout) {
            const ws = flyout.getWorkspace();
            ws.removeChangeListener(onEvent);
          }
        }
      };
    }
  }, [workspace]);

  return (
    <div
      className="flex-col-view"
      style={{
        top: offset * scale,
        left: 6 * scale,
        width: width * scale,
        height: height - offset * scale - 75 * scale,
      }}
    >
      <div
        className="flex-col-center !justify-start w-full h-full overflow-x-clip overflow-y-hidden"
        ref={ref}
      >
        {toolboxRef.current.json.contents[0].contents.map((content, index) => {
          const style = blockStyleMapping(content.type);
          return (
            <div
              key={index}
              className="flex-col-center"
              style={{
                width: 56 * scale,
                height: style.height * scale,
                marginTop: index === 0 ? 8 * scale : 0,
                marginBottom: 24 * scale,
              }}
            >
              <div
                key={index}
                className="flex-col-center w-[56px] rounded-[6px] border-solid border-[1px]"
                style={{
                  transform: `scale(${scale})`,
                  borderColor: style.borderColor,
                  backgroundColor: style.color,
                  height: style.height,
                }}
              >
                <p className="text text-white !text-[14px] !leading-[14px]">
                  のこり
                </p>
                <p className="text text-white !text-[20px] !leading-[20px]">
                  {toolboxRef.current.maxInstances[content.type]}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
