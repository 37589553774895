import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import { ComponentTypeIds } from "common/components";
import {
  ScreensEntity,
  ClipboardScreen,
  ComponentsEntity,
  ClipboardGrouping,
  ClipboardComponent,
  ClipboardTargetType,
  CreatorInitialState,
  ComponentsEntityData,
} from "features/creator/types";
import {
  canvasAdapter,
  blocklyAdapter,
  screensAdapter,
  componentsAdapter,
  propertiesAdapter,
} from "features/creator/slice/reducers/adapter";
import { HISTORY_STACK_MAX } from "features/creator/constants";

const reducers = {
  handleCopyScreen(state: CreatorInitialState) {
    const screen = screensAdapter
      .getSelectors()
      .selectById(state.screens, state.selectedScreenId);

    const components = componentsAdapter
      .getSelectors()
      .selectById(state.components, state.selectedScreenId);

    const propertiesSelector = propertiesAdapter.getSelectors();
    const targetIds = screen.children.map((component) => component.id);
    targetIds.push(screen.id);
    const propertiesEntities = Array.from(targetIds).reduce((acc, id) => {
      const property = propertiesSelector.selectById(state.properties, id);
      return Object.assign(acc, { [id]: property });
    }, {});

    state.clipboard = {
      type: ClipboardTargetType.SCREEN,
      screen,
      components,
      propertiesEntities,
    };
  },
  handleCopyComponent(state: CreatorInitialState) {
    const componentId = state.selectedComponentId;
    const property = propertiesAdapter
      .getSelectors()
      .selectById(state.properties, componentId);
    state.clipboard = {
      type: ClipboardTargetType.COMPONENT,
      propertiesEntity: property,
    };
  },
  handleCopyGrouping(state: CreatorInitialState) {
    const components = state.grouping.componentIds.map((componentId) => {
      const property = propertiesAdapter
        .getSelectors()
        .selectById(state.properties, componentId);
      return {
        componentId: componentId,
        propertiesEntity: property,
      };
    });
    state.clipboard = {
      screenId: state.selectedScreenId,
      type: ClipboardTargetType.GROUPING,
      position: state.grouping.position,
      size: state.grouping.size,
      components,
    };
  },
  handlePasteScreen(state: CreatorInitialState) {
    const {
      screen,
      components,
      propertiesEntities,
    } = state.clipboard as ClipboardScreen;
    const screenId = uuidv4();
    const selectedScreenId = state.selectedScreenId;
    const selectedComponentId = state.selectedComponentId;
    const componentIdMapping = Array.from(screen.children).reduce(
      (acc, component) => Object.assign(acc, { [component.id]: uuidv4() }),
      {}
    );

    const screenIds = screensAdapter.getSelectors().selectIds(state.screens);
    const latestScreen = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenIds[screenIds.length - 1]);

    const newScreenName = `${screen.name}のコピー`;
    const newScreen = produce(screen, (draft) => {
      draft.id = screenId;
      draft.name = newScreenName;
      draft.order = latestScreen.order + 1;
      draft.children = draft.children.map((component) => {
        return { ...component, id: componentIdMapping[component.id] };
      });
      draft.childrenOrder = draft.childrenOrder.map(
        (componentId) => componentIdMapping[componentId]
      );
    });

    const latestScreenCanvas = canvasAdapter
      .getSelectors()
      .selectById(state.canvas, latestScreen.id);
    const canvas = {
      id: screenId,
      x: latestScreenCanvas.x + state.screenSize.width + 20,
      y: latestScreenCanvas.y,
    };
    canvasAdapter.addOne(state.canvas, canvas);
    screensAdapter.addOne(state.screens, newScreen);
    blocklyAdapter.addOne(state.blockly, { screenId: screenId });

    const screenProperty = propertiesEntities[screen.id];
    const newScreenProperty = produce(screenProperty.property, (draft) => {
      draft.name = newScreenName;
      draft.defaultName = newScreenName;
    });
    const newScreenPropertyEntity = {
      id: screenId,
      typeId: screenProperty.typeId,
      categoryId: screenProperty.categoryId,
      screenId: screenId,
      property: newScreenProperty,
    };
    propertiesAdapter.addOne(state.properties, newScreenPropertyEntity);

    screen.children.forEach((component) => {
      const property = propertiesEntities[component.id];
      propertiesAdapter.addOne(state.properties, {
        ...property,
        id: componentIdMapping[component.id],
        screenId: screenId,
      });
    });

    const newComponentsData = produce(components.data, (draft) => {
      Object.keys(draft).forEach((key) => {
        if (key === ComponentTypeIds.SCREEN) {
          draft[key] = [{ id: screenId, deleted: false }];
        } else {
          draft[key] = draft[key].map((component) => ({
            id: componentIdMapping[component.id],
            deleted: component.deleted,
          }));
        }
      });
    });
    const newComponents = {
      screenId: screenId,
      data: newComponentsData,
    };
    componentsAdapter.addOne(state.components, newComponents);

    state.undoStacks.push({
      before: {
        selectedScreenId,
        selectedComponentId,
        properties: [],
      },
      after: {
        screen: newScreen,
        components: newComponents,
        selectedScreenId: screenId,
        selectedComponentId: screenId,
        canvas,
        blockly: { screenId: screenId },
        properties: [
          newScreenPropertyEntity,
          ...screen.children.map((component) => ({
            ...propertiesEntities[component.id],
            id: componentIdMapping[component.id],
            screenId: screenId,
          })),
        ],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];

    state.selectedScreenId = screenId;
    state.selectedComponentId = screenId;
    state.clipboard = null;
    state.focus = !state.focus;
  },
  handlePasteComponent(state: CreatorInitialState) {
    const {
      typeId,
      categoryId,
      property,
    } = (state.clipboard as ClipboardComponent).propertiesEntity;
    const componentId = uuidv4();
    const screenId = state.selectedScreenId;
    const selectedComponentId = state.selectedComponentId;

    const components: ComponentsEntity = componentsAdapter
      .getSelectors()
      .selectById(state.components, screenId);
    const componentsData: ComponentsEntityData = produce(
      components.data,
      (draft) => {
        if (draft[typeId]) {
          draft[typeId].push({ id: componentId, deleted: false });
        } else {
          draft[typeId] = [{ id: componentId, deleted: false }];
        }
      }
    );
    const newComponents = produce(components, (draft) => {
      draft.data = componentsData;
    });
    componentsAdapter.updateOne(state.components, {
      id: screenId,
      changes: newComponents,
    });

    // propertyにcomponent追加
    const name = `${property.name}のコピー`;
    const newProperty = produce(property, (draft) => {
      draft.name = name;
      draft.defaultName = name;

      draft.style.transform.translateX += 50;
      draft.style.transform.translateY += 50;
      draft.style.defaultTransform.translateX += 50;
      draft.style.defaultTransform.translateY += 50;
    });
    const newPropertyEntity = {
      id: componentId,
      typeId: typeId,
      categoryId: categoryId,
      screenId: screenId,
      property: newProperty,
    };
    propertiesAdapter.addOne(state.properties, newPropertyEntity);

    // screenにcomponent追加
    const screen: ScreensEntity = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenId);
    const children = produce(screen.children, (draft) => {
      draft.push({
        id: componentId,
        typeId: typeId,
        categoryId: categoryId,
        name: name,
      });
    });
    const childrenOrder = produce(screen.childrenOrder, (draft) => {
      draft.push(componentId);
    });
    const newScreen = produce(screen, (draft) => {
      draft.children = children;
      draft.childrenOrder = childrenOrder;
    });
    screensAdapter.updateOne(state.screens, {
      id: screenId,
      changes: newScreen,
    });

    state.undoStacks.push({
      before: {
        screen,
        components,
        properties: [],
        selectedComponentId,
      },
      after: {
        screen: newScreen,
        components: newComponents,
        selectedComponentId: componentId,
        properties: [newPropertyEntity],
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];

    state.selectedComponentId = componentId;
    state.clipboard = null;
  },
  handlePasteGrouping(state: CreatorInitialState) {
    const grouping = state.grouping;
    const screenId = state.selectedScreenId;
    const {
      screenId: oldScreenId,
      position,
      size,
      components,
    } = state.clipboard as ClipboardGrouping;

    const componentIdMapping = Array.from(components).reduce(
      (acc, component) =>
        Object.assign(acc, { [component.componentId]: uuidv4() }),
      {}
    );

    const oldComponents: ComponentsEntity = componentsAdapter
      .getSelectors()
      .selectById(state.components, screenId);

    const newPropertyEntities = [];
    // propertyにcomponent追加
    const screenComponents = components.map((component) => {
      const { typeId, categoryId, property } = component.propertiesEntity;
      const name = `${property.name}のコピー`;
      const newProperty = produce(property, (draft) => {
        draft.name = name;
        draft.defaultName = name;

        draft.style.transform.translateX += 50;
        draft.style.transform.translateY += 50;
        draft.style.defaultTransform.translateX += 50;
        draft.style.defaultTransform.translateY += 50;
      });

      const componentId = componentIdMapping[component.componentId];
      const newPropertyEntity = {
        id: componentId,
        typeId: typeId,
        categoryId: categoryId,
        screenId: screenId,
        property: newProperty,
      };
      propertiesAdapter.addOne(state.properties, newPropertyEntity);
      newPropertyEntities.push(newPropertyEntity);

      const ComponentsEntity: ComponentsEntity = componentsAdapter
        .getSelectors()
        .selectById(state.components, screenId);
      const componentsData: ComponentsEntityData = produce(
        ComponentsEntity.data,
        (draft) => {
          if (draft[typeId]) {
            draft[typeId].push({ id: componentId, deleted: false });
          } else {
            draft[typeId] = [{ id: componentId, deleted: false }];
          }
        }
      );
      const newComponents = produce(ComponentsEntity, (draft) => {
        draft.data = componentsData;
      });
      componentsAdapter.updateOne(state.components, {
        id: screenId,
        changes: newComponents,
      });

      return {
        id: componentId,
        typeId: typeId,
        categoryId: categoryId,
        name: name,
      };
    });

    const newComponentsEntity = componentsAdapter
      .getSelectors()
      .selectById(state.components, screenId);

    const componentIds = components.map(
      (component) => componentIdMapping[component.componentId]
    );

    // screenにcomponent追加
    const screen: ScreensEntity = screensAdapter
      .getSelectors()
      .selectById(state.screens, screenId);
    const children = produce(screen.children, (draft) => {
      draft.push(...screenComponents);
    });
    const childrenOrder = produce(screen.childrenOrder, (draft) => {
      draft.push(...componentIds);
    });
    const newScreen = produce(screen, (draft) => {
      draft.children = children;
      draft.childrenOrder = childrenOrder;
    });
    screensAdapter.updateOne(state.screens, {
      id: screenId,
      changes: newScreen,
    });

    const newGrouping = {
      enable: true,
      componentIds: componentIds,
      position: { x: position.x + 50, y: position.y + 50 },
      size,
    };
    state.grouping = newGrouping;
    state.clipboard = null;

    state.undoStacks.push({
      before: {
        selectedScreenId: oldScreenId,
        grouping,
        screen,
        components: oldComponents,
        properties: [],
      },
      after: {
        selectedScreenId: screenId,
        screen: newScreen,
        grouping: newGrouping,
        components: newComponentsEntity,
        properties: newPropertyEntities,
      },
    });
    if (state.undoStacks.length > HISTORY_STACK_MAX) {
      state.undoStacks.shift();
    }
    state.redoStacks = [];
  },
};

export default reducers;
