import { Animation } from "@babylonjs/core/Animations/animation";

export const ySlide = (initial: number, frameRate: number) => {
  const ySlide = new Animation(
    "ySlide",
    "position.y",
    frameRate,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CYCLE
  );

  const keyFrames = [];
  keyFrames.push({
    frame: 0,
    value: initial,
  });
  keyFrames.push({
    frame: frameRate,
    value: initial + 0.5,
  });
  keyFrames.push({
    frame: 2 * frameRate,
    value: initial,
  });
  ySlide.setKeys(keyFrames);

  return ySlide;
};
