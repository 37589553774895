import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n-js";
import produce from "immer";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { DrawingModel } from "features/creator/types";
import { ItemContainer } from "features/creator/property/components";
import { PanelProps, TabIndex } from "features/creator/drawing/types";

export const SizePanel = (props: PanelProps) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const drawing = useSelector((state: RootState) => state.creator.drawing);

  const handleClick = (size: number) => () => {
    play();
    const newDrawing = produce(drawing, (draft) => {
      if (drawing.model === DrawingModel.PEN) {
        draft.pen.radius = size;
      } else {
        draft.eraser.radius = size;
      }
    });
    dispatch(actions.handleUpdateDrawing(newDrawing));
    props.setSelectedTabeIndex(TabIndex.NONE);
  };

  return (
    <div className="flex-row-center !justify-start bg-beige/60 p-[24px] rounded-[12px] border-solid border-white border-[4px]">
      <ItemContainer
        name={`${
          drawing.model === DrawingModel.PEN
            ? i18n.t("MSG_CREATOR_MENU_DRAWING_PEN")
            : i18n.t("MSG_CREATOR_MENU_DRAWING_ERASER")
        }の${i18n.t("MSG_CREATOR_MENU_DRAWING_SIZE")}`}
        scale={1}
        width={240}
        height={120}
        right={0}
        left={0}
      >
        <div className="flex-row-center !justify-around w-full">
          <div
            className="flex-col-center w-[80px] h-[88px]"
            onClick={handleClick(drawing.model === DrawingModel.PEN ? 5 : 20)}
          >
            {(drawing.model === DrawingModel.PEN
              ? drawing.pen.radius === 5
              : drawing.eraser.radius === 20) && (
              <div className="flex-col-view w-full h-full !absolute top-0 left-0 bg-green/altcolor" />
            )}
            <div className="flex-col-view">
              <div className="flex-col-center w-[40px] h-[40px]">
                <div className="flex-col-view w-[5px] h-[5px] rounded-full bg-gray2/100" />
              </div>
              <p className="text text-textcolor/black !text-[16px]">
                {i18n.t("MSG_CREATOR_MENU_DRAWING_SIZE_LIGHT")}
              </p>
            </div>
          </div>

          <div
            className="flex-col-center w-[80px] h-[88px]"
            onClick={handleClick(drawing.model === DrawingModel.PEN ? 20 : 50)}
          >
            {(drawing.model === DrawingModel.PEN
              ? drawing.pen.radius === 20
              : drawing.eraser.radius === 50) && (
              <div className="flex-col-view w-full h-full !absolute top-0 left-0 bg-green/altcolor" />
            )}
            <div className="flex-col-view">
              <div className="flex-col-center w-[40px] h-[40px]">
                <div className="flex-col-view w-[16px] h-[15px] rounded-full bg-gray2/100" />
              </div>
              <p className="text text-textcolor/black !text-[16px]">
                {i18n.t("MSG_CREATOR_MENU_DRAWING_SIZE_NORMAL")}
              </p>
            </div>
          </div>

          <div
            className="flex-col-center w-[80px] h-[88px]"
            onClick={handleClick(drawing.model === DrawingModel.PEN ? 50 : 100)}
          >
            {(drawing.model === DrawingModel.PEN
              ? drawing.pen.radius === 50
              : drawing.eraser.radius === 100) && (
              <div className="flex-col-view w-full h-full !absolute top-0 left-0 bg-green/altcolor" />
            )}
            <div className="flex-col-view">
              <div className="flex-col-center w-[40px] h-[40px]">
                <div className="flex-col-view w-[40px] h-[40px] rounded-full bg-gray2/100" />
              </div>
              <p className="text text-textcolor/black !text-[16px]">
                {i18n.t("MSG_CREATOR_MENU_DRAWING_SIZE_BOLD")}
              </p>
            </div>
          </div>
        </div>
      </ItemContainer>
    </div>
  );
};
