import { useMemo, useState, MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { animated, useSpring } from "@react-spring/web";
import throttle from "lodash-es/throttle";
import { AppDispatch } from "app/store";
import { usePlaySound } from "common/utils";
import {
  IconProjectCardFavorited,
  IconProjectCardFavoritedReverse,
} from "features/creator/assets";
import { AppUser } from "features/user/types";
import { actions } from "features/creator/slice";
import { ProjectEntity } from "features/creator/types";
import { favoriteProject, stopFavoriteProject } from "features/creator/api";

export const FavoritedMark = ({
  user,
  project,
}: {
  user: AppUser;
  project: ProjectEntity;
}) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const [favoritedProjectId, setFavoritedProjectId] = useState(null);
  const [favoritedStyles, favoritedApi] = useSpring(() => ({ from: { x: 1 } }));

  const handleFavoriteProject = useMemo(
    () =>
      throttle(
        (project: ProjectEntity) => {
          setFavoritedProjectId(project.project_id);
          favoritedApi.start({
            to: async (next) => {
              await next({ x: 1 });
              await next({ x: 1.2 });
              await next({ x: 1 });
            },
            config: { duration: 200 },
          });
          dispatch(
            actions.updateProjectFavorited({
              project_id: project.project_id,
              favorited: project.is_favorited
                ? project.favorited - 1
                : project.favorited + 1,
              is_favorited: !project.is_favorited,
            })
          );
          if (project.is_favorited) {
            stopFavoriteProject({
              uid: user.uid,
              project_id: project.project_id,
            });
          } else {
            favoriteProject({
              uid: user.uid,
              project_id: project.project_id,
            });
          }
        },
        1000,
        { trailing: false }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const handleFavorite = (project: ProjectEntity) => (e: MouseEvent) => {
    play();
    e.stopPropagation();
    handleFavoriteProject(project);
  };

  return (
    <animated.div
      onClick={handleFavorite(project)}
      className="flex-row-el items-center absolute right-3 bottom-4 pointer-events-auto"
      style={{
        transform:
          favoritedProjectId === project.project_id
            ? favoritedStyles.x.to((value) => `scale(${value})`)
            : "",
      }}
    >
      {project.is_favorited ? (
        <div className="flex-col-center w-[32px] h-[32px]">
          <IconProjectCardFavorited />
        </div>
      ) : (
        <div className="flex-col-center w-[32px] h-[32px]">
          <IconProjectCardFavoritedReverse />
        </div>
      )}

      <div className="flex-col-center h-[30px]">
        <p
          className="text text-red/100 leading-8"
          style={{
            WebkitTextStroke: "6px #FEFDFE",
          }}
        >
          <span
            className="inline-block leading-8"
            style={{
              WebkitTextStroke: "0",
              position: "absolute",
            }}
          >
            {project.favorited}
          </span>
          {project.favorited}
        </p>
      </div>
    </animated.div>
  );
};
