import { useEffect } from "react";
import Constants from "common/constant";
import {
  InitShowImageBlock,
  InitButtonClickBlock,
  InitShowLabelTextBlock,
} from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitShowImageBlock();
    InitButtonClickBlock();
    InitShowLabelTextBlock();
  }, []);

  return (
    <div className="flex-col-el flex-center gap-2 mt-8">
      <div className="flex-row-view flex-center w-full gap-2">
        <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
        </div>
        <p className="text text-white !text-[30px] opacity-70">▶︎</p>
        <div className="flex-col-center">
          <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
            <p
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              あ
            </p>
          </div>
        </div>
        <p className="text text-white !text-[30px] opacity-70">▶︎</p>
        <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
        </div>
        <p className="text text-white !text-[30px] opacity-70">▶︎</p>
        <div className="flex-col-center">
          <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
            <p
              className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              あ
            </p>
          </div>
        </div>
      </div>

      <div className="flex-row-el flex-center w-full h-[50px] relative shrink-0">
        <div className="flex-row-el flex-center upsilon absolute bottom-0 pointer-events-auto cursor-pointer z-50">
          <button
            disabled
            className="btn btn-blue"
            onPointerDown={(e) => e.stopPropagation()}
          >
            <p className="text !mb-[2px]">ボタン１</p>
          </button>
        </div>
      </div>
    </div>
  );
};
