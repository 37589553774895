/* eslint-disable no-new-func */
import { useRef, useCallback } from "react";
import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { KeyboardEventTypes } from "@babylonjs/core/Events/keyboardEvents";
import { TileSetMapType } from "common/model";
import { useCanvas } from "features/courses/hook";
import * as action from "./animations";
import { loadMap, loadMesh } from "./maps";

interface SceneRenderConfigFunc {
  util: {
    setSuccess: (success: boolean) => void;
    setGameOver: (over: boolean) => void;
    action: any;
  };
  setLoading: (loading: boolean) => void;
  setScene?: (scene: Scene) => void;
  setCharacter: (character: AbstractMesh) => void;
  handleGetGem: () => void;
}

const offset = new Vector3(0, 1, 0);

export const GameScene = ({
  stage,
  scale,
  map,
  config,
  reload,
}: {
  stage: number;
  scale: number;
  map: TileSetMapType;
  config: SceneRenderConfigFunc;
  reload: boolean;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const onSceneReady = useCallback(
    async (canvas: HTMLCanvasElement, engine: Engine, scene: Scene) => {
      const camera = new ArcRotateCamera(
        "camera",
        Math.PI / 4,
        Math.PI / 4,
        10 / scale,
        Vector3.Zero(),
        scene
      );

      camera.attachControl(canvas, true);

      const light = new HemisphericLight(
        "light",
        new Vector3(-3, 15, -3),
        scene
      );
      light.intensity = 1.5;

      scene.clearColor = new Color4(0, 0, 0, 0);

      const { character, gems } = await loadMap(scene, map);
      character.setEnabled(false);
      config.setCharacter(character);

      if (stage === 1) {
        const characterPosition = character.position;
        const targetPosition = characterPosition.add(offset);
        camera.lockedTarget = targetPosition;
      } else {
        camera.lockedTarget = character;
      }

      const mark = await loadMesh(scene, "lightning.gltf.glb");
      mark.name = "mark";
      mark.setEnabled(false);

      config.setLoading(false);

      gems.forEach((gem) => {
        action.performAnimation(scene, gem);
      });

      scene.onAfterRenderObservable.add(() => {
        if (!scene.isDisposed) {
          gems.forEach((gem) => {
            if (!gem.isDisposed() && character.intersectsMesh(gem)) {
              gem.dispose();
              config.handleGetGem();
            }
          });
        }
      });

      if (stage === 1) {
        scene.registerBeforeRender(() => {
          let characterPosition = character.position;
          let targetPosition = characterPosition.add(offset);
          camera.lockedTarget = targetPosition;
        });
      }

      var animation = false;
      scene.onKeyboardObservable.add(async (keyboardInfo) => {
        if (
          keyboardInfo.type === KeyboardEventTypes.KEYDOWN &&
          !animation &&
          character.isEnabled()
        ) {
          animation = true;
          switch (keyboardInfo.event.code) {
            case "KeyW":
              await config.util.action.performMove(scene, character);
              animation = false;
              break;
            case "KeyA":
              await action.performTurnToLeft(scene, character);
              animation = false;
              break;
            case "KeyD":
              await action.performTurnToLeft(scene, character);
              animation = false;
              break;
            case "Space":
              await config.util.action.performJump(scene, character);
              animation = false;
              break;
            default:
              animation = false;
              break;
          }
        }
      });

      config.setScene(scene);

      engine.runRenderLoop(() => {
        scene.render();
      });
    },
    []
  );

  useCanvas(
    elementRef,
    onSceneReady,
    (error) => {
      console.log(error);
    },
    reload
  );

  return (
    <div
      ref={elementRef}
      className="absolute w-full h-full pointer-events-none"
    />
  );
};
