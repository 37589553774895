import { ySlide } from "./ySlide";
import { spin } from "./spin";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

export const performAnimation = (scene: Scene, gem: AbstractMesh) => {
  const frameRate = 10;
  const ySlideAnimation = ySlide(gem.position.y, frameRate);
  const spinAnimation = spin(frameRate);

  scene.beginDirectAnimation(
    gem,
    [ySlideAnimation, spinAnimation],
    0,
    2 * frameRate,
    true
  );
};
