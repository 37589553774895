import { useEffect } from "react";
import Constants from "common/constant";
import { InitShowImageBlock, InitImageClickBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitShowImageBlock();
    InitImageClickBlock();
  }, []);

  return (
    <div className="flex-row-center gap-8">
      <div className="flex-col-center">
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
          className="w-[100px] h-[100px]"
        />
      </div>

      <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
          className="w-[100px] h-[100px] opacity-40"
          style={{
            filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
          }}
        />
      </div>
    </div>
  );
};
