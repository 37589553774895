/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import I18n from "i18n-js";
import { PromptInputNumber } from "common/elements";
import { IconCirclePlus, IconCircleMinus, AnimationContainer } from ".";

export const InputBorderRadius = (props: {
  name: string;
  min: number;
  max: number;
  value: number;
  play: () => void;
  onChange: (value: number) => void;
}) => {
  const ref = useRef(null);
  const [prompt, setPrompt] = useState(false);
  const { name, min, max, value, play, onChange } = props;

  const handlePlusLongPress = () => {
    if (ref.current) {
      ref.current.handlePlusLongPress();
    }
  };

  const handleMinusLongPress = () => {
    if (ref.current) {
      ref.current.handleMinusLongPress();
    }
  };

  const handleLongPressOut = () => {
    if (ref.current) {
      ref.current.handleLongPressOut();
    }
  };

  const handlePlus = (step: number) => {
    if (ref.current) {
      ref.current.handlePlus(step);
    }
  };

  const handleMinus = (step: number) => {
    if (ref.current) {
      ref.current.handleMinus(step);
    }
  };

  const handleShowPromptInput = () => {
    play();
    setPrompt(true);
  };

  const handleInputValueChange = (inputValue: number) => {
    play();
    if (inputValue < min) {
      onChange(0);
    } else if (inputValue > max) {
      onChange(max);
    } else {
      onChange(inputValue);
    }
    setPrompt(false);
  };

  const handleCloseInput = () => {
    play();
    setPrompt(false);
  };

  const styles = StyleSheet.create({
    btn: {
      marginHorizontal: 18,
      width: 54,
      height: 24,
    },
  });

  return (
    <>
      {prompt && (
        <PromptInputNumber
          defaultValue={value}
          message={I18n.t("MSG_CREATOR_MENU_BLOCKLY_PROMPT_INPUT_MESSAGE", {
            placeholder: name,
          })}
          confirm={handleInputValueChange}
          cancel={handleCloseInput}
        />
      )}

      <div className="flex-col-center">
        <div className="flex-row-view">
          <TouchableOpacity
            style={styles.btn}
            onPress={() => handleMinus(1)}
            onLongPress={handleMinusLongPress}
            onPressOut={handleLongPressOut}
          >
            <div className="flex-col-center w-[54px] h-[24px]">
              <IconCircleMinus />
            </div>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => handlePlus(1)}
            onLongPress={handlePlusLongPress}
            onPressOut={handleLongPressOut}
          >
            <div className="flex-col-center w-[54px] h-[24px]">
              <IconCirclePlus />
            </div>
          </TouchableOpacity>
        </div>

        <div className="flex-col-center mt-[8px] mb-[24px]">
          <AnimationContainer
            ref={ref}
            value={value}
            min={min}
            max={max}
            onChange={onChange}
          >
            <div>
              <svg width={64} height={64} viewBox={`62 -2 64 64`}>
                <rect
                  x={0}
                  y={0}
                  rx={124 * (value / 200)}
                  paintOrder="fill"
                  width={124}
                  height={124}
                  stroke="#C9C4C8"
                  strokeWidth={4}
                  fill="#F6F5F6"
                />
                <rect
                  x={0}
                  y={0}
                  rx={124 * (value / 200)}
                  paintOrder="fill"
                  width={124}
                  height={124}
                  fill="#F6F5F6"
                  stroke="#80EAC1"
                  strokeWidth={4}
                  strokeDasharray={64}
                  strokeDashoffset={-96 + 124 * (value / 160)}
                />
              </svg>
            </div>
          </AnimationContainer>
        </div>

        <div className="flex-row-center w-full">
          <input
            type="text"
            className="property-input-value_input"
            readOnly
            value={value}
            onClick={handleShowPromptInput}
          />
          <p className="text text-textcolor/black !text-[16px]">%</p>
        </div>
      </div>
    </>
  );
};
