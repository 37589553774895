import { useState, useEffect, ReactNode, ChangeEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import produce from "immer";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { btnDelay, usePlaySound } from "common/utils";
import { IconCheckCircle, IconArrowCircleLeft } from "common/assets";
import {
  IconAppName,
  IconPublish,
  IconTrashcan,
  IconProjectCard,
  IconCopiedEnable,
  IconCopiedDisable,
} from "features/creator/assets";
import {
  FavoritedMark,
  ProjectAuthor,
  TextBlueStroke,
} from "features/creator/elements";
import { actions } from "features/creator/slice";
import { updateProject } from "features/creator/api";
import { IconBasic } from "features/creator/property/components";
import { DisplayMode, ProjectEntity } from "features/creator/types";

const ProjectCard = ({
  project,
  isUserProject,
}: {
  project: ProjectEntity;
  isUserProject: boolean;
}) => (
  <div className="flex-col-center w-[188px] h-[222px]">
    <img
      alt="app card"
      src={`${Constants.assetHost}/assets/images/${
        project.author_name === "まなパズ公式"
          ? "app_card_official"
          : isUserProject
          ? "app_card_user"
          : "app_card_shared"
      }.png`}
      className="w-full h-full"
    />

    <div className="flex-col-el flex-center absolute top-[47px] w-full px-[11px] h-[148px]">
      {project?.cover || project?.capture ? (
        <div className="flex-col-center w-full h-full overflow-hidden bg-white">
          <img
            alt="アプリのキャプチャー"
            className="w-full h-full object-cover"
            src={project?.cover ?? project?.capture}
            onError={(e) =>
              // @ts-ignore
              (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
            }
          />
        </div>
      ) : (
        <div className="w-full h-full bg-white" />
      )}
    </div>

    <div className="flex-col-el absolute right-4 bottom-6">
      <FavoritedMark project={project} />
    </div>
  </div>
);

export const ProjectInfo = ({
  title,
  icon,
  content,
}: {
  title: string;
  icon: ReactNode;
  content: string;
}) => (
  <div className="flex-row-center gap-2">
    <div className="flex-col-el w-[70px]">
      <p className="text-body text-textcolor/black text-font-caption2-narrow text-right whitespace-pre">
        {title}
      </p>
    </div>
    <div className="flex-col-center w-[40px] h-[40px]">{icon}</div>
    <div className="flex-col-el w-[160px]">
      <p className="text-body text-textcolor/black text-[20px] text-left select-text">
        {content}
      </p>
    </div>
  </div>
);

const Title = ({
  readonly,
  name,
  handleChangeName,
}: {
  readonly: boolean;
  name: string;
  handleChangeName: ChangeEventHandler<HTMLInputElement>;
}) => (
  <div className="flex-row-center w-full">
    <div className="flex-col-center w-[646px] h-[62px]">
      <img
        alt="description"
        src={`${Constants.assetHost}/assets/images/creator_project_title_bg.png`}
        className="w-full h-full"
      />
    </div>

    <div className="flex-row-el flex-center absolute gap-2">
      <div className="flex-col-center w-[40px] h-[40px]">
        <IconAppName />
      </div>

      <div className="flex-row-el flex-center h-[44px]">
        <input
          className={`w-[500px] text-body text-center text-textcolor/black text-[24px] !mr-12 border-none bg-transparent ${
            !readonly &&
            "!bg-white rounded-lg outline outline-gray2/40 outline-4 focus:outline-blue/80/40"
          }`}
          placeholder={I18n.t("MSG_CREATOR_APP_NAME_PLACEHOLDER")}
          maxLength={20}
          value={name}
          size={20}
          readOnly={readonly}
          onChange={handleChangeName}
        />
      </div>
    </div>
  </div>
);

const Description = ({
  readonly,
  description,
  handleChangeDescription,
}: {
  readonly: boolean;
  description: string;
  handleChangeDescription: ChangeEventHandler<HTMLTextAreaElement>;
}) => {
  const DESCRIPTION_MAX_LENGTH = 100;
  return (
    <>
      <div className="flex-row-el items-center justify-between">
        <div className="flex-row-center">
          <div className="flex-col-center w-[32px] h-[32px]">
            <IconBasic />
          </div>
          <div className="flex-col-center">
            <TextBlueStroke
              text={I18n.t("MSG_CREATOR_APP_DETAIL_DESCRIPTION_TITLE")}
            />
          </div>
        </div>

        <p className="text text-gray2/100">
          {description?.length ?? 0}/{100}
        </p>
      </div>

      <div className="flex-col-center mb-4">
        <div className="flex-col-center w-[360px] h-[156px]">
          <img
            alt="description"
            src={`${Constants.assetHost}/assets/images/creator_project_description_bg_small.png`}
            className="w-full h-full"
          />
        </div>

        <div className="flex-row-el absolute">
          <textarea
            readOnly={readonly}
            className={`w-[300px] h-[120px] text-body text-left placeholder:text-gray2/60 resize-none text-textcolor/black text-[20px] px-2 m-0 border-none bg-transparent ${
              !readonly &&
              "!bg-white rounded-lg outline outline-gray2/40 outline-4 focus:outline-blue/80/40"
            }`}
            value={description ?? ""}
            minLength={1}
            maxLength={DESCRIPTION_MAX_LENGTH}
            onChange={handleChangeDescription}
            placeholder={
              readonly
                ? ""
                : I18n.t("MSG_CREATOR_APP_DETAIL_DESCRIPTION_PLACEHOLDER")
            }
          />
        </div>
      </div>
    </>
  );
};

export const ProjectDetailPanel = ({
  readonly,
  project,
}: {
  readonly: boolean;
  project: ProjectEntity;
}) => {
  const play = usePlaySound();
  const DESCRIPTION_MAX_LENGTH = 100;
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState(project.name);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const [description, setDescription] = useState(project.description ?? "");
  const panelAction = useSelector((state: RootState) => state.creator.action);

  useEffect(() => {
    if (displayMode === DisplayMode.DETAIL && panelAction.back) {
      dispatch(actions.updateDisplayMode(DisplayMode.CAPTURE));
      dispatch(
        actions.updateProjectPanelAction({
          ...panelAction,
          back: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelAction.back]);

  const handleConfirm = () => {
    if (!readonly) {
      btnDelay(() => {
        play();
        const newProject = produce(project, (draft) => {
          draft.name = name;
          draft.description = description;
        });

        dispatch(
          updateProject({
            uid: user.active.uid,
            project_id: project.project_id,
            project: newProject,
          })
        );
      });
    }
  };

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
  };

  const handleChangeDescription: ChangeEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    let value: string;
    if (e.target.value.length > DESCRIPTION_MAX_LENGTH) {
      value = e.target.value.substring(0, DESCRIPTION_MAX_LENGTH);
    } else {
      value = e.target.value;
    }
    setDescription(value);
  };

  const handleBack = () => {
    play();
    btnDelay(() => dispatch(actions.updateDisplayMode(DisplayMode.CAPTURE)));
  };

  return (
    <div
      className="flex-col-center w-full h-full"
      style={{
        backgroundBlendMode: "multiply, normal",
        background: "linear-gradient(180deg, #EAFEFF 0%, #F5FBFF 99.48%)",
      }}
    >
      <div
        className="flex-col-center w-full h-full gap-4"
        style={{
          backgroundImage:
            "linear-gradient(0deg, transparent 31px, rgba(102, 119, 134, 15%) 32px), linear-gradient(90deg,  transparent 31px, rgba(102, 119, 134, 15%) 32px)",
          backgroundSize: "32px 32px",
        }}
      >
        <Title
          readonly={readonly}
          name={name}
          handleChangeName={handleChangeName}
        />

        <div className="flex-row-el items-start justify-center gap-2">
          <div className="flex-col-el">
            <Description
              readonly={readonly}
              description={description}
              handleChangeDescription={handleChangeDescription}
            />

            <ProjectAuthor project={project} />
          </div>

          <div className="flex-col-center gap-1">
            <ProjectCard
              project={project}
              isUserProject={project.author_uid === user.active.uid}
            />

            <div className="flex-col-el justify-start gap-2">
              <ProjectInfo
                title={I18n.t("MSG_CREATOR_APP_DETAIL_TITLE_APP_CODE")}
                icon={<IconProjectCard />}
                content={project.inquiry_id}
              />
              {project?.deleted_at ? (
                <ProjectInfo
                  title={I18n.t("MSG_CREATOR_APP_DETAIL_APP_DELETED_AT")}
                  icon={<IconTrashcan />}
                  content={dayjs(project.deleted_at).format(
                    "YYYY/MM/DD\nHH:mm:ss"
                  )}
                />
              ) : (
                <ProjectInfo
                  title={I18n.t("MSG_CREATOR_APP_DETAIL_TITLE_SHARED_APP")}
                  icon={
                    project?.published ? (
                      <IconPublish fill="#4C9DF1" stroke="#3F4A61" />
                    ) : (
                      <img
                        alt="publish btn"
                        src={`${Constants.assetHost}/assets/images/publish_on.png`}
                        className="w-[40px] h-[40px]"
                      />
                    )
                  }
                  content={
                    project.published
                      ? I18n.t(
                          "MSG_CREATOR_APP_DETAIL_TITLE_SHARED_APP_PUBLISHED"
                        )
                      : I18n.t(
                          "MSG_CREATOR_APP_DETAIL_TITLE_SHARED_APP_NOT_PUBLISHED"
                        )
                  }
                />
              )}
              {project.published && (
                <ProjectInfo
                  title={I18n.t("MSG_CREATOR_APP_DETAIL_INPUT_COPIED_STATUS")}
                  icon={
                    project.enable_copied ? (
                      <IconCopiedEnable />
                    ) : (
                      <IconCopiedDisable />
                    )
                  }
                  content={
                    project.enable_copied
                      ? I18n.t(
                          "MSG_CREATOR_APP_DETAIL_INPUT_COPIED_STATUS_ENABLE"
                        )
                      : I18n.t(
                          "MSG_CREATOR_APP_DETAIL_INPUT_COPIED_STATUS_DISABLE"
                        )
                  }
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex-row-el flex-center upsilon h-[40px] gap-8">
          <button className="btn btn-blue !px-6" onClick={handleBack}>
            <div className="flex-row-el flex-center relative gap-1">
              <div className="flex-row-center w-[32px] h-[32px]">
                <IconArrowCircleLeft />
              </div>
              <p className="text-body text-white text-[20px]">
                {I18n.t("MSG_BACK_BTN")}
              </p>
            </div>
          </button>

          {!readonly && (
            <button
              className="btn btn-primary !px-6"
              onClick={handleConfirm}
              disabled={
                name.length === 0 ||
                (name === project.name &&
                  description === (project.description ?? ""))
              }
            >
              <div className="flex-row-el flex-center relative gap-1">
                <div className="flex-row-center w-[32px] h-[32px]">
                  <IconCheckCircle />
                </div>
                <p className="text-body text-white text-[20px]">
                  {I18n.t("MSG_CONFIRM_BTN")}
                </p>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
