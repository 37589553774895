import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById("shared-project-panel");
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 200 * scale,
        y: rect.y - 60 * scale,
        width: rect.width + 400 * scale,
        height: rect.height + 400 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={8} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <div className="flex-row-el flex-center px-3 bg-blue/100 rounded-lg">
            <p className="text-body text-[20px] leading-8 text-white">
              {I18n.t("MSG_CREATOR_APP_SHARED_APPS")}
            </p>
          </div>

          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "ほかのユーザーや、\n『まなんでパズル』こうしき\nの アプリを みることが\nできます。"
              : "ほかのユーザーや、\n『まなんでパズル』公式\nの アプリを見ることが\nできます。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
