import { useState, PointerEventHandler } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePlaySound } from "common/utils";
import { Course } from "features/courses/types";
import { actions } from "features/courses/coursesSlice";
import { StageCard } from "features/courses/ocha/stage/StageCard";

export const StageList = (props: {
  selectedIndex: number;
  setSelectedIndex: (selectedIndex: number) => void;
}) => {
  const ITEM_WIDTH = 552;
  const ITEM_HEIGHT = 360;
  const ITEM_MARGIN = -180;
  const ITEM_SCALE = 0.5;
  const CENTER_ITEM_MARGIN_LEFT = -ITEM_MARGIN * ITEM_SCALE;
  const CENTER_ITEM_MARGIN_RIGHT = -ITEM_MARGIN * 1.3 * ITEM_SCALE;

  const play = usePlaySound();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedIndex, setSelectedIndex } = props;
  const [offsetX, setOffsetX] = useState(
    (ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * selectedIndex
  );

  const handleTapMap = (
    index: number
  ): PointerEventHandler<HTMLDivElement> => () => {
    play();
    if (selectedIndex === index) {
      dispatch(actions.updateSelectedStage({ stage: selectedIndex + 1 }));
      if (index === 0) {
        navigate(`${Course.OCHA}/stage/1/step/1`);
      } else if (index === 1) {
        navigate(`${Course.OCHA}/stage/2`);
      } else {
        navigate(`${Course.OCHA}/recognition`);
      }
    } else {
      setSelectedIndex(index);
      setOffsetX((ITEM_WIDTH + ITEM_MARGIN) * ITEM_SCALE * index);
    }
  };

  return (
    <div className="flex-row-view w-[1100px] h-full pointer-events-auto">
      <div
        className="flex-row-view items-center w-full h-full pt-[12px] pointer-events-auto"
        style={{
          transform: `translateX(${-offsetX}px)`,
          transition: "transform 300ms ease-in-out",
        }}
      >
        <div className="flex-row-view w-[330px] h-full" />
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className={`flex-col-view cursor-pointer ${
                selectedIndex === index && "scale-[2]"
              }`}
              style={{
                transition: "transform 300ms ease-in-out",
                zIndex: selectedIndex === index ? 10 : 1,
                pointerEvents: selectedIndex === index ? "none" : "auto",
              }}
            >
              <div
                onPointerDown={handleTapMap(index)}
                className="flex-col-center pointer-events-auto cursor-pointer"
                style={{
                  transform: `translateY(0px)`,
                  marginLeft:
                    selectedIndex === index ? CENTER_ITEM_MARGIN_LEFT : 0,
                  marginRight:
                    selectedIndex === index
                      ? CENTER_ITEM_MARGIN_RIGHT
                      : ITEM_MARGIN * ITEM_SCALE,
                }}
              >
                {selectedIndex !== index && (
                  <div className="flex-col-center !absolute top-0 left-0 w-full h-full bg-textcolor/black/20 z-10" />
                )}
                <StageCard
                  stage={index + 1}
                  width={ITEM_WIDTH}
                  height={ITEM_HEIGHT}
                  cardScale={ITEM_SCALE}
                  selected={selectedIndex === index}
                />
              </div>
            </div>
          ))}
        <div className="flex-row-view w-[450px] h-full" />
      </div>
    </div>
  );
};
