import { useEffect, useState, ChangeEventHandler } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { customAlphabet } from "nanoid";
import { isIos, useScale } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";

export const CreateKidsAccount = (props: {
  close: () => void;
  confirm: (name: string) => void;
}) => {
  const { scale } = useScale();
  const { close, confirm } = props;
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);
  const customNanoid = customAlphabet("1234567890", 7);
  const random = customNanoid();
  const [name, setName] = useState(
    I18n.t("MSG_USER_PROFILE_DEFAULT_NAME", {
      random: random,
    })
  );

  useEffect(() => {
    setDisable(false);
  }, []);

  const handleUserName: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length > 10) {
      setError(true);
    } else {
      if (error) {
        setError(false);
      }
    }
    setName(e.target.value);
  };

  const handleClick = () => {
    if (!disable && !error) {
      setDisable(true);
      confirm(name);
    }
  };

  return (
    <Dialog
      open
      maxWidth={false}
      sx={{ zIndex: 999999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{
          filter:
            "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
        }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            transform: `scale(${scale})`,
          }}
        >
          <div className="flex-col-el justify-start w-[510px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
            <CloseBtn close={close} />
            <div className="flex-col-view justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
              <div className="flex-row-view justify-start py-4">
                <p className="text-body text-textcolor/black text-[28px]">
                  {I18n.t("MSG_USER_PROFILE_ADD_USER_TITLE")}
                </p>
              </div>
              <div className="flex-col-view items-start py-4">
                <p className="text-body text-textcolor/black text-[20px]">
                  {I18n.t("MSG_USER_PROFILE_ADD_USER_MESSAGE")}
                </p>

                <div className="flex-col-el w-full relative my-4">
                  <input
                    type="text"
                    value={name}
                    autoFocus={!isIos()}
                    onChange={handleUserName}
                    className={`input-modal text-body text-textcolor/black text-[26px] ${
                      error && "!border-danger"
                    }`}
                  />

                  <label className="text-body text-left text-[14px] h-5 text-danger">
                    {error
                      ? I18n.t("MSG_BLOCKLY_INPUT_LENGTH_LIMIT", {
                          limit: 10,
                        })
                      : ""}
                  </label>
                </div>
              </div>

              <div className="flex-row-el flex-center alpha">
                <button
                  onClick={handleClick}
                  className="btn btn-primary"
                  disabled={name.length === 0 || error}
                >
                  <div className="flex-row-center gap-1 mb-[3px]">
                    <span className="material-symbols-outlined text-[36px] text-white">
                      check
                    </span>
                    <p className="text-body text-white text-font-size-body1">
                      {I18n.t("MSG_CONFIRM_BTN")}
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
