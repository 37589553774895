import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { useUserConfig } from "app/hook";
import { actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import { useScale, usePlaySound } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";

import {
  Top,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  StepProgressLayout,
} from "./pages";

const StepPages = [
  () => <Step1 />,
  () => <Step2 />,
  () => <Step3 />,
  () => <Step4 />,
  () => <Step5 />,
  () => <Step6 />,
];

export const TopTutorial = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch<AppDispatch>();
  const handleChangeUserConfig = useUserConfig();
  const config = useSelector((state: RootState) => state.config.userConfig);
  const [show, setShow] = useState(config.tutorial.top);
  const [step, setStep] = useState<number>(0);

  const Page = StepPages[step - 1];
  const navigate = (step: number) => () => {
    play();
    setStep(step);
  };
  const next = () => {
    play();
    setStep(step + 1);
  };
  const back = () => {
    play();
    setStep(step - 1);
  };
  const close = () => {
    play();
    setShow(false);
    dispatch(actions.closeTutorial({ key: "top" }));
    if (config.is_first) {
      handleChangeUserConfig("is_first", false, false);
    }
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={show}
      sx={{ zIndex: 100001 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-el flex-center w-full h-full bg-textcolor/black/50">
        <div
          className="flex-col-el flex-center"
          style={{ width: 1200 * scale, height: 800 * scale }}
        >
          <div
            className="flex-col-el flex-center shrink-0 w-[1200px] h-[800px]"
            style={{ transform: `scale(${scale})` }}
          >
            <div className="w-full h-full bg-beige/60 box-border absolute top-0 left-0 rounded-[8px] border-[12px] border-white border-solid" />

            <div className="flex-col-el flex-center !absolute top-[14px] right-[14px]">
              <CloseBtn close={close} />
            </div>

            {step === 0 ? (
              <Top next={next} back={back} close={close} />
            ) : (
              <StepProgressLayout
                navigate={navigate}
                next={next}
                back={back}
                close={close}
                step={step}
                isInApp={false}
              >
                <Page />
              </StepProgressLayout>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
