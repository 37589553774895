import I18n from "i18n-js";
import { TitleLayout } from "features/creator/tutorial/top/stages/Layout";
import { TabletCapture } from "features/creator/tutorial/top/TabletCapture";

const TOTAL_STEP = 12;

export const Title = ({ activeStep }: { activeStep: number }) => (
  <TitleLayout margin={0} steps={TOTAL_STEP} activeStep={activeStep}>
    <div className="flex-col-el flex-center gap-2">
      <div className="flex-col-el flex-center bg-orange/100 rounded-full border-solid border-[4px] border-orange/100 px-1 w-[24px] h-[24px]">
        <p className="text-body text-[18px] text-white leading-[26px]">4</p>
      </div>
      <div className="flex-col-center">
        <TabletCapture scale={0.05} />

        <p className="text-body text-center whitespace-pre text-[18px] leading-5 text-orange/100">
          {I18n.locale === "ja" ? "タブレットの\nそうさ" : "タブレットの\n操作"}
        </p>
      </div>
    </div>
  </TitleLayout>
);
