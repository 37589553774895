import { SVGProps } from "react";

export const GuideMessageBalloonBigSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m298.756 4.901.011.002.012.001c24.179 2.659 48.18 8.706 71.74 18.078l.003.001.028.011c31.299 12.687 57.464 23.294 80.581 52.139 24.393 30.443 36.428 70.013 42.322 105.653 5.891 35.625 5.63 67.219 5.5 81.652v.001c-.384 47.181-10.948 83.68-16.356 102.363l-.429 1.483v.001c-7.295 25.391-14.326 49.71-31.093 73.606-20.81 29.628-44.028 40.643-57.275 46.692l.415.91-.415-.91c-11.143 5.088-28.475 11.241-81.253 9.999H308.767l-1.085-.04c-21.765-.807-66.387-2.461-86.982.332-6.002.815-50.953 6.726-97.868-5.629l-.017-.004c-32.157-8.49-47.92-12.652-63.313-27.998v-.001c-22.043-21.936-37.178-57.254-46.524-96.267-9.34-38.987-12.867-81.53-11.792-117.783v-.003l.037-1.461c.264-10.673.987-39.979 7.149-73.869 6.475-35.611 18.933-76.149 43.06-105.443C73.34 41.85 96.14 34.065 142.344 18.337 213.282-5.836 270.706 1.15 298.756 4.901Z"
      fill="#FEFDFE"
      stroke="#3F4A61"
      strokeWidth={2}
    />
  </svg>
);
