import { BlockType } from "features/builder/types";

export const ToolboxDef5 = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      toolboxitemid: "category",
      contents: [
        {
          kind: "block",
          type: BlockType.VARIABLES_SET,
        },
        {
          kind: "block",
          type: BlockType.VARIABLES_GET,
        },
        {
          kind: "block",
          type: BlockType.PROCEDURES_CALLNORETURN_DEFINITION,
          fields: {
            NAME: "なにかする",
          },
        },
      ],
    },
  ],
};
