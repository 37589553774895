import Constants from "common/constant";
import { theme } from "features/courses/algorithm/game/blockly/BlocklyOptions";

export const BlocklyOptions = (scale: number) => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: false,
  horizontalLayout: false,
  media: `${Constants.assetHost}/assets/blockly/game/`,
  move: {
    scrollbars: false,
    drag: true,
    wheel: false,
  },
  zoom: {
    startScale: scale,
  },
  oneBasedIndex: true,
  readOnly: false,
  rtl: false,
  trashcan: true,
  maxTrashcanContents: 32,
  renderer: "zelos",
  theme: theme,
});
