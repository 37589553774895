import { PointerEvent } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { IconClipboard } from "features/creator/assets";
import { MAX_SCREENS } from "features/creator/constants";
import { selectScreenIds } from "features/creator/slice";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType, ClipboardTargetType } from "features/creator/types";

export const PasteBtn = () => {
  const play = usePlaySound();
  const handleAction = useActionCommand();
  const clipboard = useSelector((state: RootState) => state.creator.clipboard);
  const currentScreens = useSelector((state: RootState) =>
    selectScreenIds(state)
  );
  const canPast = () => {
    if (!clipboard) {
      return false;
    } else if (
      clipboard.type === ClipboardTargetType.SCREEN &&
      currentScreens.length > MAX_SCREENS - 1
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handlePast = (e: PointerEvent) => {
    play();
    const disabled = !canPast();
    if (disabled) return;
    e.stopPropagation();
    handleAction({
      type: ActionCommandType.PASTE,
      targetType: clipboard.type,
    });
    toast.success(
      <div>
        <p className="text text-textcolor/black">{I18n.t("MSG_TOAST_PAST")}</p>
      </div>
    );
  };

  return (
    <div className="flex-col-el flex-center h-full relative upsilon-square">
      <button
        id="creator-past-btn"
        className="btn btn-secondary"
        disabled={!canPast()}
        onPointerDown={handlePast}
      >
        <div className="flex-col-el flex-center relative w-[32px] h-[32px] mb-[3px]">
          <IconClipboard />
        </div>
      </button>
    </div>
  );
};
