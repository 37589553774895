import { useMemo } from "react";
import { Scene } from "@babylonjs/core/scene";
import { Tools } from "@babylonjs/core/Misc/tools";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import {
  IconCircleZoomIn,
  IconCircleZoomOut,
  IconCircleZoomReset,
} from "common/assets";
import { useScale, usePlaySound } from "common/utils";
import { CameraIcon } from "features/creator/property/components";

const Stick = ({
  reset,
  vertical,
  horizontal,
}: {
  reset: () => void;
  vertical: (step: number) => () => void;
  horizontal: (step: number) => () => void;
}) => (
  <div className="flex-col-el flex-center gap-4 bg-white/60 border-white border-8 border-solid rounded-full">
    <div
      onClick={vertical(-0.1)}
      className="flex-col-center w-[64px] h-[64px] cursor-pointer active:opacity-70"
    >
      <div
        className="flex-col-el relative w-0 h-0 border-solid border-x-transparent border-b-white"
        style={{
          borderWidth: "0 28px 44px 28px",
        }}
      />
    </div>

    <div className="flex-row-center gap-4">
      <div
        onClick={horizontal(-0.1)}
        className="flex-col-center w-[64px] h-[64px] cursor-pointer active:opacity-70"
      >
        <div
          className="flex-col-el relative w-0 h-0 border-solid border-y-transparent border-r-white"
          style={{
            borderWidth: "28px 44px 28px 0",
          }}
        />
      </div>

      <div
        className="flex-col-el flex-center w-[64px] h-[64px] bg-white rounded-full cursor-pointer active:opacity-70"
        onClick={reset}
      >
        <p className="text-body text-textcolor/black text-[24px]">R</p>
      </div>

      <div
        onClick={horizontal(0.1)}
        className="flex-col-center w-[64px] h-[64px] cursor-pointer active:opacity-70"
      >
        <div
          className="flex-col-el relative w-0 h-0 border-solid border-y-transparent border-l-white"
          style={{
            borderWidth: "28px 0 28px 44px",
          }}
        />
      </div>
    </div>

    <div
      onClick={vertical(0.1)}
      className="flex-col-center w-[64px] h-[64px] cursor-pointer active:opacity-70"
    >
      <div
        className="flex-col-el relative w-0 h-0 border-solid border-x-transparent border-t-white"
        style={{
          borderWidth: "44px 28px 0 28px",
        }}
      />
    </div>
  </div>
);

export const Controller = ({ scene }: { scene: Scene }) => {
  const play = usePlaySound();
  const { scale, ratio } = useScale();

  const camera = useMemo(
    () => scene?.getCameraByName("camera") as ArcRotateCamera,
    [scene]
  );
  const radius = useMemo(
    () => (ratio > 1 ? (scale > 0.7 ? 20 / scale : 40 * scale) : 20 / scale),
    [scale]
  );

  const handleSceneZoom = (zoom: number) => () => {
    const camera = scene.getCameraByName("camera") as ArcRotateCamera;
    if (camera) {
      play();
      if (zoom > 0) {
        camera.radius -= 1;
      } else if (zoom < 0) {
        camera.radius += 1;
      } else {
        camera.radius = radius;
        camera.alpha = Math.PI / 4;
        camera.beta = Math.PI / 4;
      }
    }
  };

  const handleRotationAlpha = (step: number) => () => {
    play();
    camera.alpha += step;
  };
  const handleRotationBeta = (step: number) => () => {
    play();
    camera.beta += step;
  };
  const handleResetRotation = () => {
    play();
    camera.radius = 40 * scale;
    camera.alpha = Math.PI / 4;
    camera.beta = Math.PI / 4;
  };

  const handleCapture = async () => {
    const grounds = scene.getMeshesById("ground");
    for (let i = 0; i < grounds.length; i++) {
      const ground = grounds[i];
      ground.showBoundingBox = false;
    }

    setTimeout(async () => {
      const data = await Tools.CreateScreenshotUsingRenderTargetAsync(
        scene.getEngine(),
        scene.getCameraByName("camera") as ArcRotateCamera,
        { precision: 1 },
        null,
        null,
        true,
        null,
        null,
        null,
        null,
        100
      );

      const downloadLink = document.createElement("a");
      downloadLink.href = data;
      downloadLink.download = "capture.png";
      downloadLink.click();

      for (let i = 0; i < grounds.length; i++) {
        const ground = grounds[i];
        ground.showBoundingBox = true;
      }
    }, 500);
  };

  return (
    <div
      style={{
        transform: `scale(${scale})`,
        right: 12 * scale,
      }}
      className="flex-col-el items-end absolute origin-right gap-10 z-10"
    >
      <div className="flex-col-el flex-center gap-8">
        <div
          onClick={handleSceneZoom(1)}
          className="flex-col-center w-[100px] h-[100px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomIn color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(0)}
          className="flex-col-center w-[100px] h-[100px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomReset color="#FEFDFE" />
        </div>
        <div
          onClick={handleSceneZoom(-1)}
          className="flex-col-center w-[100px] h-[100px] cursor-pointer opacity-50 active:opacity-30"
        >
          <IconCircleZoomOut color="#FEFDFE" />
        </div>

        <div
          onClick={handleCapture}
          className="flex-col-el flex-center cursor-pointer pointer-events-auto z-10 w-[150px] h-[150px] rounded-xl border-solid border-white border-8 bg-white/60 active:opacity-70"
        >
          <p className="flex-col-center text-body text-textcolor/black text-[24px] text-center">
            <span className="inline-block w-[48px] h-[48px] mb-[4px]">
              <CameraIcon />
            </span>
            キャプチャ（テスト）
          </p>
        </div>
      </div>

      <Stick
        reset={handleResetRotation}
        vertical={handleRotationBeta}
        horizontal={handleRotationAlpha}
      />
    </div>
  );
};
