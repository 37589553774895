import { useState, useEffect } from "react";
import { Image as RNImage } from "react-native";
import { Howl } from "howler";
import { images, sounds } from "app/assets";
import { useKidsLatestVersion } from "app/hook";
import Constants from "common/constant";
import { AssetLoading } from "./AssetLoading";

export const LoadAsset = ({
  authing,
  loading,
  setLoading,
  setLatestVersion,
}: {
  authing: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setLatestVersion: (latestVersion: boolean) => void;
}) => {
  const [progress, setProgress] = useState(0);
  const fetchKidsLatestVersion = useKidsLatestVersion();

  useEffect(() => {
    const preloading = async () => {
      setLatestVersion(true);
      const total = images.length + sounds.length;
      let index = 0;

      const imageAssets = () =>
        images.map((image_uri) => {
          try {
            return RNImage.prefetch(
              `${Constants.assetHost}${image_uri}`
            ).finally(() => {
              index++;
              setProgress(Math.round((index * 100) / total));
            });
          } catch (error) {
            // some old browser doesn't support RNImage.prefetch
            return new Promise((resolve, reject) => {
              const image = new Image();
              image.src = `${Constants.assetHost}${image_uri}`;
              image.onload = () => {
                index++;
                setProgress(Math.round((index * 100) / total));
                resolve("");
              };
              image.onerror = (error) => {
                index++;
                setProgress(Math.round((index * 100) / total));
                reject(error);
              };
            });
          }
        });

      const soundAssets = () =>
        sounds.map(
          (sound) =>
            new Promise((resolve, reject) => {
              if ("AudioContext" in window) {
                new Howl({
                  loop: false,
                  preload: true,
                  autoplay: false,
                  src: [`${Constants.assetHost}${sound}`],
                })
                  .once("load", () => {
                    index++;
                    setProgress(Math.round((index * 100) / total));
                    resolve("");
                  })
                  .once("loaderror", (soundId: number, error: unknown) => {
                    index++;
                    setProgress(Math.round((index * 100) / total));
                    reject(error);
                  });
              } else {
                index++;
                setProgress(Math.round((index * 100) / total));
                resolve("");
              }
            })
        );

      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 30000);

      Promise.all([...imageAssets(), ...soundAssets()])
        .then(() => {
          clearTimeout(timeoutId);
          setLoading(false);
        })
        .catch((error) => {
          console.warn(JSON.stringify(error));
          clearTimeout(timeoutId);
          setLoading(false);
        });
    };

    fetchKidsLatestVersion(preloading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AssetLoading open={loading || authing} progress={progress} />;
};
