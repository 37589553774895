import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { useQuery } from "@tanstack/react-query";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { btnDelay, usePlaySound } from "common/utils";
import { actions } from "features/creator/slice";
import { getPickupProject } from "features/creator/api";
import { PickupCommentBg } from "features/creator/assets";
import { DisplayMode, ProjectEntity } from "features/creator/types";
import { FavoritedMarkReadOnly } from "features/creator/recommend/ranking/FavoritedMark";
import { ProjectDetailPanel } from "./ProjectDetailPanel";

const ProjectUser = ({ name, icon }: { name: string; icon: string }) => {
  return (
    <div className="flex-row-center gap-1">
      <div
        className="flex-col-center w-[32px] h-[32px] bg-white rounded-full border-solid border-[4px] overflow-hidden border-white"
        style={{
          boxShadow:
            "-1px 3px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
        }}
      >
        <img alt="*" src={icon} className="w-full h-full object-contain" />
      </div>

      <div className="flex-row-el w-[100px]">
        <p
          className="text-body text-textcolor/black text-[20px] w-full text-body-ellipsis"
          style={{
            WebkitTextStroke: "6px #FEFDFE",
          }}
        >
          <span
            className="inline-block w-full text-body-ellipsis"
            style={{
              WebkitTextStroke: "0",
              position: "absolute",
            }}
          >
            {name}
          </span>
          {name}
        </p>
      </div>
    </div>
  );
};

const CardLayout = ({
  index,
  click,
  project,
}: {
  index: number;
  click: () => void;
  project: ProjectEntity;
}) => {
  const [clicked, setClicked] = useState(false);
  const handlePointerDown = () => {
    setClicked(true);
  };
  const handlePointerUp = () => {
    setClicked(false);
    click();
  };
  return (
    <div
      onPointerUp={handlePointerUp}
      onPointerDown={handlePointerDown}
      className="flex-col-el items-center w-[288px] h-[334px] p-4 rounded-2xl cursor-pointer bg-beige/20"
      style={{
        boxShadow: clicked
          ? "inset 0px -1px 0px rgba(0, 0, 0, 0.3)"
          : "inset 0px -2px 0px rgba(0, 0, 0, 0.3)",
        transform: `translateY(${clicked ? 2 : 0}px)`,
      }}
    >
      <div className="flex-col-el flex-center absolute -top-10">
        <div className="flex-col-center">
          <img
            alt="creator pickup title"
            src={`${Constants.assetHost}/assets/images/creator_pickup_title.png`}
            className="w-[296px] h-[64px]"
          />
        </div>

        <div className="flex-row-el absolute">
          <p
            className="text-body text-textcolor/black text-[24px] leading-8"
            style={{
              WebkitTextStroke: "6px #FEFDFE",
            }}
          >
            <span
              className="inline-block absolute text-[24px] leading-8"
              style={{
                WebkitTextStroke: "0",
              }}
            >
              {project.name}
            </span>
            {project.name}
          </p>
        </div>
      </div>

      <div className="flex-col-center w-[256px] h-[192px] -z-10 overflow-hidden border-solid border-[2px] border-gray2/40 rounded-b-2xl">
        {project?.capture ? (
          <img
            alt="project capture"
            className="w-full h-full object-cover"
            src={project.capture}
          />
        ) : (
          <div className="flex-col-center w-full h-full bg-white" />
        )}
      </div>

      <div className="flex-col-el absolute -left-7 top-24">
        {index === 0 ? (
          <img
            alt="pick nice icon"
            src={`${Constants.assetHost}/assets/images/creator_pick_up_nice.png`}
            className="w-[96px] h-[119px]"
          />
        ) : (
          <img
            alt="pick fun icon"
            src={`${Constants.assetHost}/assets/images/creator_pick_up_fun.png`}
            className="w-[96px] h-[119px]"
          />
        )}
      </div>

      <div className="flex-row-el w-full justify-between -mt-4">
        <ProjectUser name={project.author_name} icon={project.author_icon} />
        <div className="flex-col-el">
          <FavoritedMarkReadOnly favorited={project.favorited} />
        </div>
      </div>

      <div className="flex-col-el mt-1">
        <div className="flex-col-el absolute justify-end h-[90px]">
          <div className="flex-col-el w-[258px] h-[64px]">
            <PickupCommentBg />
          </div>
        </div>
        <div className="flex-col-el">
          <p className="text-body whitespace-pre text-command/cobalt text-[20px]">
            {index === 0
              ? "いきものたちの うごきが\nとても うまく ひょうげん\nされて いますね！"
              : "しぜんな うごきが じょう\nず！ カラフルに かわる は\nいけいも たのしいです。"}
          </p>
        </div>
      </div>
    </div>
  );
};

export const PickUpPage = () => {
  const play = usePlaySound();
  const { data } = useQuery({
    queryKey: ["creator/getPickupProject"],
    queryFn: () => getPickupProject(),
    staleTime: Infinity,
    placeholderData: { projects: [] },
  });
  const dispatch = useDispatch<AppDispatch>();
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const recommendReturnToHome = useSelector(
    (state: RootState) => state.creator.recommendReturnToHome
  );
  const panelAction = useSelector((state: RootState) => state.creator.action);
  const [selectedProject, setSelectedProject] = useState<ProjectEntity | null>(
    null
  );

  useEffect(() => {
    if (
      displayMode === DisplayMode.PICKUP &&
      panelAction.back &&
      !selectedProject
    ) {
      dispatch(actions.updateDisplayMode(DisplayMode.NONE));
      dispatch(
        actions.updateProjectPanelAction({
          ...panelAction,
          back: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelAction.back]);

  useEffect(() => {
    if (recommendReturnToHome) {
      handleCloseDetail();
      dispatch(actions.updateRecommendReturnToHome(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendReturnToHome]);

  const handleSelectProject = (project: ProjectEntity) => () => {
    btnDelay(async () => {
      play();
      setSelectedProject(project);
      dispatch(actions.updateRecommendProject(project));
    });
  };

  const handleCloseDetail = () => {
    btnDelay(async () => {
      play();
      setSelectedProject(null);
      dispatch(actions.updateRecommendProject(null));
    });
  };

  return (
    <>
      <ProjectDetailPanel
        id="pickup-app"
        project={selectedProject}
        close={handleCloseDetail}
      />

      <div className="flex-col-el items-center w-full h-full bg-gray/20 absolute top-0 left-0 -z-0 overflow-hidden">
        <img
          alt="creator display default bg"
          src={`${Constants.assetHost}/assets/images/creator_display_bg.png`}
          className="w-full h-full object-fill"
        />

        <div className="flex-col-el items-center mt-2 w-full h-full absolute top-0 left-0 -z-0 overflow-hidden">
          <div className="flex-col-center gap-14">
            <div className="flex-col-center">
              <div className="flex-row-center gap-2">
                <div className="flex-row-center px-2 py-1 bg-orange/100 rounded-full">
                  <p className="text-body text-white text-[20px]">
                    {I18n.t("MSG_CREATOR_CREATE_APP_PICKUP_MONTH_TITLE", {
                      month: dayjs().month() + 1,
                    })}
                  </p>
                </div>
                <div
                  className="flex-row-center"
                  style={{
                    filter:
                      "drop-shadow(-1px 1px 1px rgba(63, 74, 97, 0.15)) drop-shadow(1px 1px 3px rgba(63, 74, 97, 0.25))",
                    fontFamily: "MPLUSRounded1c-Black",
                    WebkitTextStroke: "8px #FEFDFE",
                  }}
                >
                  <p
                    className="text-body text-red/100 text-[50px] leading-normal"
                    style={{
                      fontFamily: "MPLUSRounded1c-Black",
                      WebkitTextStroke: "8px #FEFDFE",
                    }}
                  >
                    <span
                      className="inline-block absolute text-[50px] leading-normal"
                      style={{
                        fontFamily: "MPLUSRounded1c-Black",
                        WebkitTextStroke: "0",
                      }}
                    >
                      {I18n.t("MSG_CREATOR_CREATE_APP_PICKUP_NAME")}
                    </span>
                    {I18n.t("MSG_CREATOR_CREATE_APP_PICKUP_NAME")}
                  </p>
                </div>
              </div>
              <div className="flex-row-center">
                <p
                  className="text-body text-command/cobalt text-[20px] leading-8"
                  style={{
                    fontFamily: "MPLUSRounded1c-Black",
                    WebkitTextStroke: "6px #FEFDFE",
                  }}
                >
                  <span
                    className="inline-block absolute text-[20px] leading-8"
                    style={{
                      fontFamily: "MPLUSRounded1c-Black",
                      WebkitTextStroke: "0",
                    }}
                  >
                    {I18n.t("MSG_CREATOR_CREATE_APP_PICKUP_SUBTITLE")}
                  </span>
                  {I18n.t("MSG_CREATOR_CREATE_APP_PICKUP_SUBTITLE")}
                </p>
              </div>
            </div>

            <div
              className="flex-row-center gap-10"
              style={{
                filter:
                  "drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.2)) drop-shadow(0px 10px 14px rgba(0, 0, 0, 0.14)) drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.12))",
              }}
            >
              {data.projects.map((project, index) => (
                <CardLayout
                  key={index}
                  index={index}
                  click={handleSelectProject(project)}
                  project={project}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
