import { PointerEvent } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { IconCornerUpLeft } from "common/assets";
import { actions } from "features/creator/slice";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType } from "features/creator/types";

export const UndoBtn = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const handleAction = useActionCommand();
  const stacks = useSelector((state: RootState) => state.creator.undoStacks);
  const disabled = stacks.length === 0;

  const handleUndo = (e: PointerEvent) => {
    play();
    e.stopPropagation();
    if (disabled) return;
    dispatch(actions.handleAllCollapse());
    handleAction({ type: ActionCommandType.UNDO });
    toast.info(
      <div>
        <p className="text text-textcolor/black">{I18n.t("MSG_TOAST_UNDO")}</p>
      </div>
    );
  };

  return (
    <div className="flex-col-el flex-center h-full relative upsilon-square">
      <button
        id="creator-undo-btn"
        className="btn btn-blue"
        disabled={disabled}
        onPointerDown={handleUndo}
      >
        <div className="flex-col-el flex-center relative w-[32px] h-[32px] mb-[3px]">
          <IconCornerUpLeft />
        </div>
      </button>
    </div>
  );
};
