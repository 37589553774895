import { SVGProps } from "react";

export const IconProjectCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#3F4A61"
      d="M8 8a3 3 0 0 1 3-3h18a3 3 0 0 1 3 3v24a3 3 0 0 1-3 3H11.45a1.5 1.5 0 0 1-1.067-.446l-1.95-1.973A1.5 1.5 0 0 1 8 31.527V8Z"
    />
    <path
      fill="#FEFDFE"
      d="M11.2 9.833a1.5 1.5 0 0 1 1.5-1.5h14.6a1.5 1.5 0 0 1 1.5 1.5V28.5a1.5 1.5 0 0 1-1.5 1.5H13.89a1.5 1.5 0 0 1-1.059-.438l-1.19-1.186a1.5 1.5 0 0 1-.441-1.062V9.834Z"
    />
    <path
      fill="#FFA03B"
      d="M11.2 9.833a1.5 1.5 0 0 1 1.5-1.5h14.6a1.5 1.5 0 0 1 1.5 1.5v4.334H11.2V9.833Z"
    />
  </svg>
);
