import I18n from "i18n-js";
import { useScale } from "common/utils";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;

  return (
    <>
      <Overlay>
        <div className="flex-col-el w-full h-full">
          <div
            className="w-[650px] h-[650px] absolute origin-top-left"
            style={{
              borderRadius: "100%",
              transform: `scale(${scale}) translate(-400px, 70px)`,
              boxShadow: "0px 0px 9999px 9999px rgba(0, 0, 0, 0.35)",
            }}
          />
        </div>
      </Overlay>
      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={10} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "ほかのページには\nさらにくわしい\nせつめいがあるので、"
              : "ほかのページには\nさらに詳しい\n説明があるので、"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
