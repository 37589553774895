import { fetchAuthToken } from "app/Auth";
import { client } from "common/apiClient";

export const createRating = async ({
  uid,
  rating,
}: {
  uid: string;
  rating: {
    rating: number;
    web_version: string;
    app_version: string;
    env: string;
  };
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post(`/v1/review/rating/${uid}`, rating);
};

export const createFeedback = async ({
  uid,
  input,
  feedback,
}: {
  uid: string;
  input?: boolean;
  feedback: {
    rating?: number;
    complaint_type: string;
    detail: string;
    web_version: string;
    app_version: string;
    env: string;
    url: string;
  };
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post(
    `/v1/review/feedback/${uid}?input=${input ?? false}`,
    feedback
  );
};
