import I18n from "i18n-js";
import { QRCodeSVG } from "qrcode.react";
import SecurityUpdateOutlinedIcon from "@mui/icons-material/SecurityUpdateOutlined";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import {
  AppleBadge,
  AmazonBadge,
  ParentsAppLogo,
  GooglePlayBadge,
} from "./assets";
import { RouteProps } from "./type";
import { GoBackBtn } from "./GoBackBtn";

enum AppStore {
  APPLE = "apple",
  AMAZON = "amazon",
  GOOGLE = "google",
  WEB = "web",
}

export const ParentsApp = (props: RouteProps) => {
  const { navigate } = props;
  const play = usePlaySound();

  const handleGoBack = () => navigate("/");
  const handleOpenStore = (store: AppStore) => () => {
    play();
    if ("ReactNativeWebView" in window) {
      switch (store) {
        case AppStore.APPLE:
          if (
            window.kidsAppPlatform === "ios" &&
            Number(window.kidsBuildVersion) > 73
          ) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "openApp",
                data: {
                  schema: "com.inexus.app.parents://",
                  url: "https://apps.apple.com/jp/app/id1642655214",
                },
              })
            );
          } else {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "openLink",
                data: {
                  url: "https://apps.apple.com/jp/app/id1642655214",
                },
              })
            );
          }
          break;
        case AppStore.GOOGLE:
          if (
            window.kidsAppPlatform === "android" &&
            Number(window.kidsBuildVersion) > 58
          ) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "openApp",
                data: {
                  schema: "com.inexus.app.parents://",
                  url:
                    "https://play.google.com/store/apps/details?id=com.inexus.app.parents",
                },
              })
            );
          } else {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "openLink",
                data: {
                  url:
                    "https://play.google.com/store/apps/details?id=com.inexus.app.parents",
                },
              })
            );
          }
          break;
        case AppStore.AMAZON:
          if (
            window.kidsAppPlatform === "amazon" &&
            Number(window.kidsBuildVersion) > 52
          ) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "openApp",
                data: {
                  schema: "com.inexus.app.parents://",
                  url: "amzn://apps/android?p=com.inexus.app.parents",
                },
              })
            );
          } else {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "openLink",
                data: {
                  url:
                    "http://www.amazon.co.jp/gp/mas/dl/android?p=com.inexus.app.parents",
                },
              })
            );
          }
          break;
        default:
          break;
      }
    } else {
      switch (store) {
        case AppStore.APPLE:
          window.open("https://apps.apple.com/jp/app/id1642655214", "_blank");
          break;
        case AppStore.GOOGLE:
          window.open(
            "https://play.google.com/store/apps/details?id=com.inexus.app.parents",
            "_blank"
          );
          break;
        case AppStore.AMAZON:
          window.open(
            "http://www.amazon.co.jp/gp/mas/dl/android?p=com.inexus.app.parents",
            "_blank"
          );
          break;
        case AppStore.WEB:
          window.open(Constants.parentsHost, "_blank");
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="flex-col-el items-center justify-center w-full h-full px-4 py-8">
      <div className="relative flex-col-el gap-4 shrink-0 items-center w-full p-8 bg-white/40 rounded-lg my-16">
        <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
          <SecurityUpdateOutlinedIcon />
          <p className="text-body text-textcolor/black text-[16px] px-[8px]">
            {I18n.t("MSG_SETTING_PARENTS_LINK_DOWNLOAD_TITLE")}
          </p>
        </div>

        <div className="flex-row-center gap-5">
          <img
            alt="parents app icon"
            className="w-[100px] h-[100px]"
            src={`${Constants.assetHost}/assets/images/parents_icon.png`}
          />
          <div className="flex-col-el flex-center shrink-0 w-[405px] h-[60px]">
            <ParentsAppLogo />
          </div>
        </div>

        <div className="relative flex-row-el gap-4 shrink-0 items-center w-full">
          <div className="relative mb-[78px]">
            <QRCodeSVG size={160} value={`${Constants.parentsLinkPageHost}`} />
          </div>

          <div className="flex-col-el mx-4 mb-[78px]">
            <p className="text-body text-textcolor/black text-[36px]">OR</p>
          </div>

          <div className="flex-col-el flex-center w-full gap-6">
            <p className="text-body text-textcolor/black text-[20px]">
              {I18n.t("MSG_SETTING_PARENTS_LINK_DOWNLOAD_DESCRIPTION")}
            </p>

            <div className="flex-col-center gap-5">
              <div className="flex-row-el flex-center w-full h-[70px] gap-4">
                <div
                  className="flex-col-el flex-center shrink-0 w-[200px] h-[60px] cursor-pointer"
                  onClick={handleOpenStore(AppStore.APPLE)}
                >
                  <AppleBadge />
                </div>
                <div
                  className="flex-col-el flex-center shrink-0 w-[200px] h-[100px] mr-4 cursor-pointer"
                  onClick={handleOpenStore(AppStore.GOOGLE)}
                >
                  <GooglePlayBadge />
                </div>
                <div
                  className="flex-col-el flex-center shrink-0 w-[200px] h-[100px] mr-2 cursor-pointer"
                  onClick={handleOpenStore(AppStore.AMAZON)}
                >
                  <AmazonBadge />
                </div>
              </div>

              {!window.ReactNativeWebView && (
                <div className="flex-col-el flex-center shrink-0 cursor-pointer">
                  <img
                    alt="parents app icon"
                    className="w-[374px] h-[64px]"
                    onClick={handleOpenStore(AppStore.WEB)}
                    src={`${Constants.assetHost}/assets/images/parents_web_link.png`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row-el">
        <GoBackBtn handleGoBack={handleGoBack} />
      </div>
    </div>
  );
};
