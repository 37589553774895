/* eslint-disable no-new-func */
import { useState } from "react";
import Constants from "common/constant";
import { sleep, btnDelay, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const sound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/itemget.mp3`
  );
  const [clicked, setClicked] = useState(false);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "sleep",
    "sound",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      btnDelay(() => {
        play();
        setRunning(true);
        setClicked(true);
        handleEvent(sleep, sound, highlightBlock, setSuccess, setGameOver)();
      });
    }
  };

  return (
    <div className="flex-col-el flex-center gap-2 mt-12">
      <div className="flex-col-el flex-center gap-4">
        <div className="flex-row-view flex-center w-full gap-2">
          <div
            id="course-program-border-1"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <p
              className="text-body text-textcolor/black text-[70px] leading-[70px] opacity-30 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              い
            </p>
            <img
              alt="*"
              id="course-program-image-1"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-1"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={1} />
          </div>
          <p
            id="course-program-arrow-1"
            className="text text-white !text-[30px] opacity-0"
          >
            ▶︎
          </p>
          <div
            id="course-program-border-2"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
              className="w-[70px] h-[70px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-image-2"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-2"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={2} />
          </div>
          <p
            id="course-program-arrow-2"
            className="text text-white !text-[30px] opacity-0"
          >
            ▶︎
          </p>
          <div
            id="course-program-border-3"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
              className="w-[70px] h-[70px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-image-3"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-3"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={3} />
          </div>
          <p
            id="course-program-arrow-3"
            className="text text-white !text-[30px] opacity-0"
          >
            ▶︎
          </p>
          <div
            id="course-program-border-4"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <p
              className="text-body text-textcolor/black text-[70px] leading-[70px] opacity-30 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              い
            </p>
            <img
              alt="*"
              id="course-program-image-4"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-4"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={4} />
          </div>
          <p
            id="course-program-arrow-4"
            className="text text-white !text-[30px] opacity-0"
          >
            ▶︎
          </p>
          <div
            id="course-program-border-5"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
              className="w-[70px] h-[70px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-image-5"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-5"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={5} />
          </div>
          <p
            id="course-program-arrow-5"
            className="text text-white !text-[30px] opacity-0"
          >
            ▶︎
          </p>
          <div
            id="course-program-border-6"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
              className="w-[70px] h-[70px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-image-6"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-6"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={6} />
          </div>
        </div>

        <div className="flex-row-view justify-end w-full">
          <div className="flex-col-center p-2">
            <div className="flex-col-center w-[70px] h-[0px]">
              <p
                id="course-program-arrow-6"
                className="text text-white !text-[30px] opacity-0"
              >
                ▼
              </p>
            </div>
          </div>
        </div>

        <div className="flex-row-view !flex-row-reverse items-center justify-end w-full gap-2">
          <div
            id="course-program-border-7"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
              className="w-[70px] h-[70px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-image-7"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-7"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={7} />
          </div>
          <p
            id="course-program-arrow-7"
            className="text text-white !text-[30px] opacity-0"
          >
            ←
          </p>
          <div
            id="course-program-border-8"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <p
              className="text-body text-textcolor/black text-[70px] leading-[70px] opacity-30 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              あ
            </p>
            <img
              alt="*"
              id="course-program-image-8"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-8"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={8} />
          </div>
          <p
            id="course-program-arrow-8"
            className="text text-white !text-[30px] opacity-0"
          >
            ←
          </p>
          <div
            id="course-program-border-9"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
              className="w-[70px] h-[70px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-image-9"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-9"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={9} />
          </div>
          <p
            id="course-program-arrow-9"
            className="text text-white !text-[30px] opacity-0"
          >
            ←
          </p>
          <div
            id="course-program-border-10"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <p
              className="text-body text-textcolor/black text-[70px] leading-[70px] opacity-30 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              あ
            </p>
            <img
              alt="*"
              id="course-program-image-10"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-10"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={10} />
          </div>
          <p
            id="course-program-arrow-10"
            className="text text-white !text-[30px] opacity-0"
          >
            ←
          </p>
          <div
            id="course-program-border-11"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
              className="w-[70px] h-[70px] opacity-40 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            />
            <img
              alt="*"
              id="course-program-image-11"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-11"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={11} />
          </div>
          <p
            id="course-program-arrow-11"
            className="text text-white !text-[30px] opacity-0"
          >
            ←
          </p>
          <div
            id="course-program-border-12"
            className="flex-col-center w-[70px] h-[70px] !box-content p-2 border-solid border-[2px] rounded-[20px] border-white/20"
          >
            <p
              className="text-body text-textcolor/black text-[70px] leading-[70px] opacity-30 absolute"
              style={{
                filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
              }}
            >
              あ
            </p>
            <img
              alt="*"
              id="course-program-image-12"
              className="w-[70px] h-[70px] opacity-0 absolute z-10 transition duration-700 scale-150"
            />
            <p
              id="course-program-label-12"
              className="text-body text-white text-[70px] leading-[70px] opacity-0 z-10 transition duration-700 scale-150"
            />
            <AnswerMark id={12} />
          </div>
        </div>
      </div>

      <div className="flex-row-el flex-center w-full h-[50px] relative shrink-0">
        <div className="flex-row-el flex-center upsilon absolute bottom-0 pointer-events-auto cursor-pointer z-50">
          <button
            disabled={clicked}
            onClick={handleClick}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン１</p>
          </button>
        </div>
      </div>
    </div>
  );
};
