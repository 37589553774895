import {
  canvasAdapter,
  screensAdapter,
  blocklyAdapter,
  componentsAdapter,
  propertiesAdapter,
} from "features/creator/slice/reducers/adapter";
import { CreatorInitialState } from "features/creator/types";

const reducers = {
  handleUndo(state: CreatorInitialState) {
    const stack = state.undoStacks.pop();
    const { before, after } = stack;

    if (before.screen) {
      if (after.screen) {
        screensAdapter.updateOne(state.screens, {
          id: before.screen.id,
          changes: before.screen,
        });
      } else {
        screensAdapter.addOne(state.screens, before.screen);
      }
    } else if (after.screen) {
      screensAdapter.removeOne(state.screens, after.screen.id);
    }

    if (before.canvas) {
      if (after.canvas) {
        canvasAdapter.updateOne(state.canvas, {
          id: before.canvas.id,
          changes: before.canvas,
        });
      } else {
        canvasAdapter.addOne(state.canvas, before.canvas);
      }
    } else if (after.canvas) {
      canvasAdapter.removeOne(state.canvas, after.canvas.id);
    }

    if (before.components) {
      if (after.components) {
        componentsAdapter.updateOne(state.components, {
          id: before.components.screenId,
          changes: before.components,
        });
      } else {
        componentsAdapter.addOne(state.components, before.components);
      }
    } else if (after.components) {
      componentsAdapter.removeOne(state.components, after.components.screenId);
    }

    if (before.properties.length > 0) {
      if (after.properties.length > 0) {
        before.properties.forEach((propertyEntity) => {
          propertiesAdapter.updateOne(state.properties, {
            id: propertyEntity.id,
            changes: propertyEntity,
          });
        });
      } else {
        before.properties.forEach((propertyEntity) => {
          propertiesAdapter.addOne(state.properties, propertyEntity);
        });
      }
    } else {
      after.properties.forEach((propertyEntity) => {
        propertiesAdapter.removeOne(state.properties, propertyEntity.id);
      });
    }

    if (before.blockly) {
      if (after.blockly) {
        blocklyAdapter.updateOne(state.blockly, {
          id: before.blockly.screenId,
          changes: { xmlText: before.blockly.xmlText },
        });
      } else {
        blocklyAdapter.addOne(state.blockly, before.blockly);
      }
    } else if (after.blockly) {
      blocklyAdapter.removeOne(state.blockly, after.blockly.screenId);
    }

    if (before.grouping) {
      state.grouping = before.grouping;
    }

    if (before.defaultScreenId) {
      state.defaultScreenId = before.defaultScreenId;
    }
    if (before.selectedScreenId) {
      state.selectedScreenId = before.selectedScreenId;
    }
    if (before.selectedComponentId) {
      state.selectedComponentId = before.selectedComponentId;
    }

    state.focus = !state.focus;
    state.redoStacks.push(stack);
  },
  handleRedo(state: CreatorInitialState) {
    const stack = state.redoStacks.pop();
    const { before, after } = stack;

    if (after.screen) {
      if (before.screen) {
        screensAdapter.updateOne(state.screens, {
          id: after.screen.id,
          changes: after.screen,
        });
      } else {
        screensAdapter.addOne(state.screens, after.screen);
      }
    } else if (before.screen) {
      screensAdapter.removeOne(state.screens, before.screen.id);
    }

    if (after.canvas) {
      if (before.canvas) {
        canvasAdapter.updateOne(state.canvas, {
          id: after.canvas.id,
          changes: after.canvas,
        });
      } else {
        canvasAdapter.addOne(state.canvas, after.canvas);
      }
    } else if (before.canvas) {
      canvasAdapter.removeOne(state.canvas, before.canvas.id);
    }

    if (after.components) {
      if (before.components) {
        componentsAdapter.updateOne(state.components, {
          id: after.components.screenId,
          changes: after.components,
        });
      } else {
        componentsAdapter.addOne(state.components, after.components);
      }
    } else if (before.components) {
      componentsAdapter.removeOne(state.components, before.components.screenId);
    }

    if (before.properties.length > 0) {
      if (after.properties.length > 0) {
        after.properties.forEach((propertyEntity) => {
          propertiesAdapter.updateOne(state.properties, {
            id: propertyEntity.id,
            changes: propertyEntity,
          });
        });
      } else {
        before.properties.forEach((propertyEntity) => {
          propertiesAdapter.removeOne(state.properties, propertyEntity.id);
        });
      }
    } else {
      after.properties.forEach((propertyEntity) => {
        propertiesAdapter.addOne(state.properties, propertyEntity);
      });
    }

    if (after.blockly) {
      if (before.blockly) {
        blocklyAdapter.updateOne(state.blockly, {
          id: after.blockly.screenId,
          changes: { xmlText: after.blockly.xmlText },
        });
      } else {
        blocklyAdapter.addOne(state.blockly, after.blockly);
      }
    } else if (before.blockly) {
      blocklyAdapter.removeOne(state.blockly, before.blockly.screenId);
    }

    if (after.grouping) {
      state.grouping = after.grouping;
    }

    if (after.defaultScreenId) {
      state.defaultScreenId = after.defaultScreenId;
    }
    if (after.selectedScreenId) {
      state.selectedScreenId = after.selectedScreenId;
    }
    if (after.selectedComponentId) {
      state.selectedComponentId = after.selectedComponentId;
    }

    state.focus = !state.focus;
    state.undoStacks.push(stack);
  },
};

export default reducers;
