import { SVGProps } from "react";

export const IconCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path
      d="m2 9 4.667 7L16 2"
      stroke={props.stroke ? props.stroke : "#FEFDFE"}
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        mixBlendMode: "screen",
      }}
    />
  </svg>
);
