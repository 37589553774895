import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useScale } from "common/utils";
import { CloseBtn } from "./closeBtn";

export const Modal = ({
  transparent,
  children,
}: {
  transparent?: boolean;
  children: any;
}) =>
  createPortal(
    <div
      className={`flex-col-el absolute top-0 left-0 w-full h-full z-[999999] ${
        transparent ? "bg-transparent" : "bg-white"
      }`}
    >
      {children}
    </div>,
    document.body
  );

export const ActionModal = ({
  show,
  close,
  children,
}: {
  show: boolean;
  close: () => void;
  children: any;
}) => {
  const { scale } = useScale();
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const animationEnd = () => {
    if (!show) setRender(false);
  };

  return render ? (
    createPortal(
      <div
        onAnimationEnd={animationEnd}
        className={`flex-col-el flex-center absolute z-[999999] inset-0 bg-textcolor/black/50 modal-overlay ${
          show ? "fade-in" : "fade-out"
        }`}
      >
        <div
          style={{ transform: `scale(${scale})`, marginTop: 32 * scale }}
          className="flex-col-el relative items-center justify-start w-[1180px] h-[890px] rounded-[8px] border-[8px] border-solid border-white bg-white"
        >
          <CloseBtn close={close} />
          <div className="flex-col-el w-full h-full relative rounded-[4px] bg-white overflow-hidden">
            {children}
          </div>
        </div>
      </div>,
      document.body
    )
  ) : (
    <></>
  );
};
