/* eslint-disable no-new-func */
import { useState } from "react";
import Constants from "common/constant";
import { sleep, btnDelay, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const [clicked, setClicked] = useState(false);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "sleep",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      btnDelay(() => {
        play();
        setRunning(true);
        setClicked(true);
        handleEvent(sleep, highlightBlock, setSuccess, setGameOver)();
      });
    }
  };
  return (
    <div className="flex-col-el flex-center gap-2 mt-8">
      <div className="flex-col-center">
        <div
          id="course-program-border-1"
          className="flex-col-center p-2 border-solid border-[4px] rounded-[6px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-image-1"
            className="w-[100px] h-[100px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={1} />
        </div>
        <p className="text text-white">ボックス1</p>
      </div>

      <div className="flex-row-el flex-center w-[150px] h-[50px] relative">
        <div className="flex-row-el flex-center upsilon absolute bottom-0 pointer-events-auto cursor-pointer z-50">
          <button
            disabled={clicked}
            onClick={handleClick}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン１</p>
          </button>
        </div>
      </div>
    </div>
  );
};
