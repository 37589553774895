import Blockly from "blockly";

export type ToolboxInfoType = {
  maxInstances: {
    [type: string]: number;
  };
  json: {
    kind: string;
    contents: {
      kind: string;
      toolboxitemid: string;
      contents: { kind: string; type: string; [key: string]: any }[];
    }[];
  };
};

export enum BlockType {
  UNKNOWN = "unknown",
  EVENT_START = "character_event_start",
  EVENT_END = "character_event_end",
  MOTION_MOVE = "character_motion_move",
  MOTION_JUMP = "character_motion_jump",
  MOTION_TURN_LEFT = "character_motion_turn_left",
  MOTION_TURN_RIGHT = "character_motion_turn_right",
  CONTROLS_REPEAT_INTERNAL_START = "controls_repeat_internal_start",
  CONTROLS_REPEAT_VAR_INTERNAL_START = "controls_repeat_var_internal_start",
  CONTROLS_WHILEUNTIL_COLOUR_INTERNAL_START = "controls_whileUntil_colour_internal_start",
  CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL_START = "controls_whileUntil_colour_var_internal_start",
  CONTROLS_LOOP_VAR_INTERNAL_START = "controls_loop_var_internal_start",
  CONTROLS_LOOP_END = "controls_loop_end",
  CONTROLS_IF_COLOUR_INTERNAL_START = "controls_if_colour_internal_start",
  CONTROLS_IF_COLOUR_VAR_INTERNAL_START = "controls_if_colour_var_internal_start",
  CONTROLS_IF_END = "controls_if_end",
  VARIABLES_SET = "variables_set",
  VARIABLES_GET = "variables_get",
  PROCEDURES_CALLNORETURN_DEFINITION_START = "procedures_defnoreturn_start",
  PROCEDURES_CALLNORETURN_DEFINITION_END = "procedures_defnoreturn_end",
  PROCEDURES_CALLNORETURN_CALL = "procedures_callnoreturn",
  FUNCTION_END = "function_end",
  CONTROLS_END = "controls_end",
}

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Label = {
  text: string;
  boundingPolygon: { x: number; y: number }[];
};

export type Block = {
  id: string;
  type: BlockType;
  args?: string[];
  label: Label;
  rootId?: string;
};

export type ExecutableBlock = {
  id: string;
  parentId?: string;
  type: BlockType;
  args?: string[];
};

export type WorkspaceBlockList = {
  block: ExecutableBlock;
  workspaceBlock?: Blockly.BlockSvg;
};

export enum ConnectionMessageType {
  TEST = "TEST",
  BLOCK = "BLOCK",
}
