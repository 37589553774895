import { SVGProps } from "react";

export const OpacityBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={128}
    height={128}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#FEFDFE"
      d="M16 0h16v16H16zM16 32h16v16H16zM16 64h16v16H16zM16 96h16v16H16zM32 16h16v16H32zM32 48h16v16H32zM32 80h16v16H32zM32 112h16v16H32zM48 0h16v16H48zM48 32h16v16H48zM48 64h16v16H48zM48 96h16v16H48zM64 16h16v16H64zM64 48h16v16H64zM64 80h16v16H64zM64 112h16v16H64zM80 0h16v16H80zM80 32h16v16H80zM80 64h16v16H80zM80 96h16v16H80zM96 16h16v16H96zM96 48h16v16H96zM96 80h16v16H96zM96 112h16v16H96zM112 0h16v16h-16zM112 32h16v16h-16zM112 64h16v16h-16zM112 96h16v16h-16zM0 16h16v16H0zM0 48h16v16H0zM0 80h16v16H0zM0 112h16v16H0z"
    />
    <path
      fill="#F2EFF1"
      d="M0 0h16v16H0zM0 32h16v16H0zM0 64h16v16H0zM0 96h16v16H0zM16 16h16v16H16zM16 48h16v16H16zM16 80h16v16H16zM16 112h16v16H16zM32 0h16v16H32zM32 32h16v16H32zM32 64h16v16H32zM32 96h16v16H32zM48 16h16v16H48zM48 48h16v16H48zM48 80h16v16H48zM48 112h16v16H48zM64 0h16v16H64zM64 32h16v16H64zM64 64h16v16H64zM64 96h16v16H64zM80 16h16v16H80zM80 48h16v16H80zM80 80h16v16H80zM80 112h16v16H80zM96 0h16v16H96zM96 32h16v16H96zM96 64h16v16H96zM96 96h16v16H96zM112 16h16v16h-16zM112 48h16v16h-16zM112 80h16v16h-16zM112 112h16v16h-16z"
    />
  </svg>
);
