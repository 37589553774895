import { SVGProps } from "react";

export const IconCircleArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g
      stroke="#FEFDFE"
      strokeWidth={4}
      clipPath="url(#icon-circle-arrow-left-a)"
    >
      <circle cx={20} cy={20} r={14} />
      <path strokeLinecap="round" strokeLinejoin="round" d="m22 16-6 4 6 4" />
    </g>
    <defs>
      <clipPath id="icon-circle-arrow-left-a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconCircleArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <g
      stroke="#FEFDFE"
      strokeWidth={4}
      clipPath="url(#icon-circle-arrow-right-a)"
    >
      <circle cx={20} cy={20} r={14} transform="rotate(180 20 20)" />
      <path strokeLinecap="round" strokeLinejoin="round" d="m18 24 6-4-6-4" />
    </g>
    <defs>
      <clipPath id="icon-circle-arrow-right-a">
        <path fill="#fff" d="M40 40H0V0h40z" />
      </clipPath>
    </defs>
  </svg>
);
