import { SVGProps } from "react";

export const GuideBtnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={72}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1.5} y={1.5} width={69} height={53} rx={6.5} fill="#DBEBFC" />
    <g clipPath="url(#creator-guide-btn-icon-a)">
      <path
        d="M11.5 10.938h16.625l8.094 3.28s4.265-3.28 9.843-3.28h15.75l-2.187 31.937-22.75 1.75-24.5-1.75-.875-31.938Z"
        fill="#FEFDFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.063 8.75h6.82s4.378 0 11.117 4.614C42.739 8.75 47.118 8.75 47.118 8.75h9.007v1.313H61.813V44.85l-12.346.943a13.32 13.32 0 0 0 1.817-2.332l8.341-.637V12.25h-3.5v29.564h-3.954c.334-.718.614-1.454.84-2.187h.926v-28.69h-6.8l-.095.006c-.1.008-.276.024-.52.061a14.36 14.36 0 0 0-2.264.56c-1.6.522-3.822 1.484-6.508 3.259v.052h-3.5v-.052c-2.686-1.775-4.908-2.737-6.508-3.259a14.36 14.36 0 0 0-2.264-.56 6.834 6.834 0 0 0-.615-.066h-6.8v28.689h6.82v2.187H15.874V12.25h-3.5v30.574l21.438 1.637v-.02c-5.913-2.627-8.93-2.627-8.93-2.627 0-2.187 0-2.187.002-2.187h.076l.044.002a11.635 11.635 0 0 1 .977.088c.636.084 1.539.244 2.704.553 1.36.36 3.078.921 5.145 1.794.908 2.157 2.256 3.98 3.944 5.186h-4.02v-.6l-23.568-1.8V10.063H15.876V8.75h2.188Z"
        fill="#4C9DF1"
      />
      <path
        d="m34.51 32.137 3.733 10.481c.604 1.037 2.378 3.112 4.642 3.118 2.265.005 4.205-1.954 4.893-2.934l2.76-6.77c.291-.612.706-1.97.041-2.516-.664-.546-1.468-.125-1.787.154.134-.43.237-1.428-.428-1.973-.664-.546-1.298-.14-1.532.132.05-.423-.065-1.351-.916-1.68-.852-.329-1.518.298-1.745.653l2.315-5.978c.056-.34.186-1.423-.683-1.951s-2.021.425-2.234.946l-4.482 10.687-1.599-3.63c-.32-.727-.89-1.381-1.895-1.094-1.004.288-1.219 1.78-1.084 2.355Z"
        stroke="#4C9DF1"
        strokeWidth={2.188}
        strokeLinejoin="round"
      />
      <path
        d="m38.991 22.586-3.34-5.962M36.983 25.202l-6.673-1.473"
        stroke="#4C9DF1"
        strokeWidth={2.188}
      />
    </g>
    <rect
      x={1.5}
      y={1.5}
      width={69}
      height={53}
      rx={6.5}
      stroke="#4C9DF1"
      strokeWidth={3}
    />
    <defs>
      <clipPath id="creator-guide-btn-icon-a">
        <path fill="#fff" transform="translate(8)" d="M0 0h56v56H0z" />
      </clipPath>
    </defs>
  </svg>
);
