import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippetByComponent } from "features/courses/program/game/blockly/uitls";

export const InitStartBlock = () => {
  const startBlock = () => {
    return {
      type: "start_answer_event",
      message0: "%1",
      args0: [
        {
          type: "input_statement",
          name: "DO",
        },
      ],
    };
  };

  Blockly.Blocks["start_answer_event"] = {
    init: function () {
      this.appendDummyInput().appendField("%{BKY_COURSE_START_BLOCK}");
      this.jsonInit(startBlock());
      this.setCollapsed(false);
      this.setColour("#FFBF00");
    },
  };

  BlocklyJs["start_answer_event"] = function (block: Blockly.Block) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
      return async () => {
        var order = 1;
        let target;
        let border;
        let answerMark;
        const targetIdPrefix = "course-program-label-";
        const targetChars = ["あ", "い", "う"];
        const result = [false, false, false];
        if(${result}){
          ${logic}
          setSuccess(!result.includes(false));
          setGameOver(true);
        } else {
          setSuccess(false);
          setGameOver(true);
        };
      }
    `;
    return code;
  };
};

export const InitLabelTextSetterBlock = () => {
  Blockly.Blocks["label_text_setter_block"] = {
    max: 1,
    init: function () {
      this.jsonInit({
        type: "label_text_setter_block",
        message0: "%1を%2にする",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [
              ["ボックス1", "1"],
              ["ボックス2", "2"],
              ["ボックス3", "3"],
            ],
          },
          {
            type: "input_value",
            name: "value",
            check: "String",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "text_blocks",
      });
    },
  };
  BlocklyJs["label_text_setter_block"] = function (block: Blockly.Block) {
    const selectedLabelValue = block.getField("label").getValue();
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById(targetIdPrefix.concat('', ${selectedLabelValue}));
      target.innerText = ${value};
      sound();
      target.style.opacity = "1";
      target.style.transform = "scale(1)";
      result[Number(${selectedLabelValue}) - 1] = targetChars[Number(${selectedLabelValue}) - 1] === ${value};
      await sleep(700);
      ${showAnswerSnippetByComponent(selectedLabelValue)}
      await sleep(700);
      highlightBlock("${block.id}", false);
    `;
  };
};
