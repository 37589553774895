import { SVGProps } from "react";

export const RenderOrderFrontIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={62}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={6}
      y={27}
      width={34}
      height={34}
      rx={3}
      fill="#FEFDFE"
      stroke="#C2C9CF"
      strokeWidth={2}
    />
    <rect
      x={14}
      y={19}
      width={34}
      height={34}
      rx={3}
      fill="#FEFDFE"
      stroke="#C2C9CF"
      strokeWidth={2}
    />
    <rect
      x={22}
      y={11}
      width={34}
      height={34}
      rx={3}
      fill="#80EAC1"
      stroke="#C2C9CF"
      strokeWidth={2}
    />
    <path
      d="M7.314 19.314v-16M13.314 8.657 7.657 3 2 8.657M21.314 19.314v-16M27.314 8.657 21.657 3 16 8.657"
      stroke="#FDA3A0"
      strokeWidth={4}
    />
  </svg>
);
