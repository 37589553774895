import { SVGProps } from "react";

export const IconContact = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56 112c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18Z"
      stroke="#FEFDFE"
      fill="#FEFDFE"
      strokeWidth={8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64 160.45V174H12v-13.55c0-4.065 1.354-8.479 4.152-11.808 2.705-3.22 6.932-5.642 13.277-5.642H46.57c6.345 0 10.572 2.422 13.277 5.642C62.645 151.971 64 156.385 64 160.45ZM85.512 102.956l.69-2.395-1.845-1.674C75.967 91.274 72 80.963 72 69.317c0-12.067 4.26-22.714 13.315-30.41C94.443 31.149 108.906 26 130 26c16.323 0 30.951 5.054 41.414 13.039C181.873 47.02 188 57.763 188 69.318c0 12.068-4.26 22.715-13.315 30.41-9.128 7.758-23.591 12.908-44.685 12.908-9.355 0-17.422-1.015-24.297-2.826l-1.243-.327-1.202.458-22.196 8.466 4.45-15.451Z"
      stroke="#FEFDFE"
      fill="#FEFDFE"
      strokeWidth={8}
    />
    <path
      d="M134.074 80.863h-9.444c.025-2.45.197-4.56.518-6.33.346-1.797.926-3.417 1.741-4.86.84-1.443 1.951-2.872 3.333-4.288a46.179 46.179 0 0 0 3.037-3.267c.864-1.035 1.543-2.097 2.037-3.186.494-1.116.741-2.355.741-3.716 0-1.58-.223-2.886-.667-3.92-.42-1.063-1.062-1.866-1.926-2.41-.839-.545-1.901-.817-3.185-.817a5.917 5.917 0 0 0-2.963.776c-.913.49-1.666 1.252-2.259 2.287-.568 1.035-.864 2.396-.888 4.084h-10.741c.075-3.73.852-6.807 2.334-9.23 1.506-2.45 3.518-4.26 6.036-5.432 2.519-1.198 5.346-1.797 8.481-1.797 3.457 0 6.42.627 8.889 1.88 2.469 1.224 4.357 3.035 5.666 5.43 1.308 2.37 1.963 5.255 1.963 8.659 0 2.369-.42 4.479-1.259 6.33a21.778 21.778 0 0 1-3.297 5.105 114.765 114.765 0 0 1-4.481 4.9c-1.407 1.39-2.37 2.846-2.888 4.37-.494 1.525-.753 3.336-.778 5.432Zm-10.555 12.62c0-1.743.543-3.186 1.629-4.33 1.087-1.17 2.544-1.755 4.371-1.755 1.802 0 3.246.585 4.333 1.756 1.111 1.144 1.666 2.587 1.666 4.329 0 1.688-.555 3.117-1.666 4.288-1.087 1.17-2.531 1.756-4.333 1.756-1.827 0-3.284-.585-4.371-1.756-1.086-1.17-1.629-2.6-1.629-4.288Z"
      fill="#FFA03B"
    />
  </svg>
);
