import { useEffect } from "react";
import Constants from "common/constant";
import { InitShowImageBlock, InitImageClickBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitShowImageBlock();
    InitImageClickBlock();
  }, []);

  return (
    <div className="flex-col-center gap-4">
      <div className="flex-row-center gap-8">
        <div className="flex-col-center mt-16">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px]"
          />
        </div>
        <div className="flex-col-center">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px]"
          />
        </div>
        <div className="flex-col-center mt-12">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px]"
          />
        </div>
        <div className="flex-col-center">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px]"
          />
        </div>
        <div className="flex-col-center mt-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px]"
          />
        </div>
      </div>

      <div className="flex-row-center gap-2">
        <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
        </div>
        <p className="text text-white !text-[30px] opacity-70">▶︎</p>
        <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
        </div>
        <p className="text text-white !text-[30px] opacity-70">▶︎</p>
        <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
        </div>
        <p className="text text-white !text-[30px] opacity-70">▶︎</p>
        <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
        </div>
        <p className="text text-white !text-[30px] opacity-70">▶︎</p>
        <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`}
            className="w-[100px] h-[100px] opacity-40"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
        </div>
      </div>
    </div>
  );
};
