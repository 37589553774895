import { useRef, useMemo, useState, useEffect, WheelEventHandler } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useVirtualizer } from "@tanstack/react-virtual";
import dayjs from "dayjs";
import I18n from "i18n-js";
import anime from "animejs";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { useScale, usePlaySound } from "common/utils";
import { IconArrowLeft, IconArrowRight } from "common/assets";
import {
  SortedOrder,
  DisplayMode,
  ProjectEntity,
} from "features/creator/types";
import {
  CardBg,
  IconDrawer,
  IconMagnifierWhite,
} from "features/creator/assets";
import { AppUser } from "features/user/types";
import { actions } from "features/creator/slice";
import { ProjectPanelType } from "features/creator/types";
import { ProjectSort } from "./ProjectSort";
import { ProjectFilter } from "./ProjectFilter";
import { FavoritedMark } from "./FavoritedMark";
import { ProjectKeywordSearch } from "./ProjectKeywordSearch";

const ProjectCard = ({
  user,
  project,
  handleSelectProject,
}: {
  user: AppUser;
  project: ProjectEntity;
  handleSelectProject: (projectId: string) => () => void;
}) => (
  <div
    id={
      project.name === "チュートリアル"
        ? "creator-published-project-tutorial-sample-app"
        : `creator-published-project-${project.project_id}`
    }
    onClick={handleSelectProject(project.project_id)}
    className="flex-col-el flex-center w-[148px] cursor-pointer"
  >
    <div className="flex-col-center rounded-[16px] p-2">
      <div className="flex-col-el flex-center w-[144px] h-[156px] absolute -z-0">
        <CardBg />
      </div>

      <div className="flex-col-center w-[120px] h-[148px] ml-3">
        <div className="flex-col-el flex-center absolute w-[62px] h-[64px]">
          <img
            alt="switch"
            className="w-full h-full"
            src={`${Constants.assetHost}/assets/images/project_card_switch.png`}
          />
        </div>

        <div
          className={`flex-col-center w-full h-full app-project-card-${project.project_id}`}
        >
          <img
            alt="app card"
            src={`${Constants.assetHost}/assets/images/${
              project.author_name === "まなパズ公式"
                ? "app_card_official"
                : project.author_uid === user.uid
                ? "app_card_user"
                : "app_card_shared"
            }.png`}
            className="w-full h-full"
          />

          <div className="flex-col-el flex-center absolute top-[32px] w-full px-[7px] h-[100px]">
            {project?.cover || project?.capture ? (
              <div className="flex-col-center w-full h-full overflow-hidden bg-white">
                <img
                  alt="アプリのキャプチャー"
                  className="w-full h-full object-cover"
                  src={project?.cover ?? project?.capture}
                  loading="lazy"
                  onError={(e) =>
                    // @ts-ignore
                    (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                  }
                />
              </div>
            ) : (
              <div className="w-full h-full bg-white" />
            )}
          </div>

          <FavoritedMark user={user} project={project} />
        </div>
      </div>
    </div>

    <div className="flex-col-center w-[145px] h-[50px] gap-1">
      <div className="flex-row-center w-full">
        <p className="text-body text-center text-body-ellipsis text-[20px] leading-6 text-textcolor/black w-full">
          {project.name}
        </p>
      </div>

      <div className="flex-row-el items-start w-full">
        <div
          className="flex-col-center cursor-pointer bg-white rounded-full w-[24px] h-[24px]"
          style={{
            boxShadow:
              "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
          }}
        >
          <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
            <img
              alt="ユーザプロフィール画像"
              src={project.author_icon}
              className="w-full h-full object-contain"
            />
          </div>
        </div>

        <p className="text-body text-body-ellipsis text-[20px] text-textcolor/black leading-6 text-left w-[128px]">
          {project.author_name}
        </p>
      </div>
    </div>
  </div>
);

export const SharedProjectPanel = ({
  user,
  projects,
}: {
  user: AppUser;
  projects: ProjectEntity[];
}) => {
  const play = usePlaySound();
  const { width, scale } = useScale();
  const ref = useRef<HTMLDivElement>(null);
  const [render, setRender] = useState(false);
  const [scroll, setScroll] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const projectPanel = useSelector(
    (state: RootState) => state.creator.projectPanel
  );
  const sortedOrderPanel = useSelector(
    (state: RootState) => state.creator.sortedOrderPanel
  );
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );
  const sortedOrder = useSelector(
    (state: RootState) => state.creator.projectSortedOrder
  );
  const { onlyOfficial, userFavorited } = useSelector(
    (state: RootState) => state.creator.publishedProjectFilter
  );
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const keyword = useSelector((state: RootState) => state.creator.keyword);

  const items = useMemo(
    () =>
      projects
        .filter((project) => {
          if (
            project.name.includes(keyword) ||
            project.description?.includes(keyword) ||
            project.author_name.includes(keyword) ||
            project.original_author_name?.includes(keyword) ||
            project.tags?.includes(keyword)
          ) {
            if (onlyOfficial) {
              return project.author_name === "まなパズ公式";
            } else {
              return true;
            }
          }
        })
        .filter((project) => {
          if (userFavorited) {
            return project.is_favorited;
          } else {
            return true;
          }
        })
        .sort((a, b) => {
          // TODO: nameで判断するのがいいのかな？
          if (a.name === "チュートリアル" && a.author_name === "まなパズ公式") {
            return -1;
          } else if (
            b.name === "チュートリアル" &&
            b.author_name === "まなパズ公式"
          ) {
            return 1;
          }

          if (sortedOrder === SortedOrder.DATE) {
            return dayjs(a.updated_time) < dayjs(b.updated_time) ? 1 : -1;
          } else {
            return a.favorited < b.favorited ? 1 : -1;
          }
        }),
    [keyword, projects, sortedOrder, onlyOfficial, userFavorited]
  );

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: items.length,
    getScrollElement: () => ref.current,
    estimateSize: () => 176,
    overscan: 2,
  });

  useEffect(() => {
    setTimeout(() => setRender(true), 1000);
  }, []);

  useEffect(() => {
    if (render && ref.current) {
      ref.current.scrollLeft = 0;
      setShowLeftArrow(false);
      if (columnVirtualizer.getTotalSize() > ref.current.offsetWidth) {
        setShowRightArrow(true);
      } else {
        setShowRightArrow(false);
      }
    }
  }, [render, sortedOrder, onlyOfficial, userFavorited, columnVirtualizer]);

  const handleExpend = () => {
    play();
    if (projectPanel === ProjectPanelType.SHARED) {
      setShowLeftArrow(false);
      dispatch(actions.updateSortFilterPanel(true));
      dispatch(actions.updateProjectPanel(ProjectPanelType.NONE));
    } else {
      if (ref.current.scrollLeft > 0) {
        setShowLeftArrow(true);
      }
      dispatch(actions.updateSortFilterPanel(false));
      dispatch(actions.updateProjectPanel(ProjectPanelType.SHARED));
    }
  };
  const handleSortFilterExpend = () => {
    play();
    if (projectPanel === ProjectPanelType.SHARED) {
      dispatch(actions.updateSortFilterPanel(!sortedOrderPanel));
    } else {
      dispatch(actions.updateProjectPanel(ProjectPanelType.SHARED));
    }
  };

  const handleScrollBtn = (direction: number) => () => {
    if (scroll) return;
    play();
    setScroll(true);

    if (direction > 0) {
      // 右へ
      if (ref.current) {
        if (
          ref.current.offsetWidth + ref.current.scrollLeft ===
          ref.current.scrollWidth
        ) {
          setShowRightArrow(false);
        } else {
          const offset = ref.current.scrollLeft + 176;
          columnVirtualizer.scrollToOffset(offset);
          setShowLeftArrow(true);
        }
        setScroll(false);
      }
    } else {
      // 左へ
      if (ref.current) {
        if (ref.current.scrollLeft === 0) {
          setShowLeftArrow(false);
        } else {
          const offset = ref.current.scrollLeft - 176;
          columnVirtualizer.scrollToOffset(offset);
          setShowRightArrow(true);
        }
        setScroll(false);
      }
    }
  };

  const handleWheel: WheelEventHandler<HTMLDivElement> = (e) => {
    if (ref.current) {
      if (e.deltaY > 0) {
        // 右へ
        if (ref.current) {
          if (
            ref.current.offsetWidth + ref.current.scrollLeft ===
            ref.current.scrollWidth
          ) {
            setShowRightArrow(false);
          } else {
            setShowLeftArrow(true);
            const offset = ref.current.scrollLeft + 176;
            columnVirtualizer.scrollToOffset(offset);
          }
        }
      } else {
        // 左へ
        if (ref.current) {
          if (ref.current.scrollLeft === 0) {
            setShowLeftArrow(false);
          } else {
            const offset = ref.current.scrollLeft - 176;
            columnVirtualizer.scrollToOffset(offset);
            setShowRightArrow(true);
          }
        }
      }
    }
  };

  const handleSelectProject = (projectId: string) => () => {
    if (projectId !== selectedProjectId) {
      play();
      anime({
        targets: document.getElementsByClassName(
          `app-project-card-${selectedProjectId}`
        ),
        opacity: [0, 1],
        duration: 800,
        easing: "easeInOutQuad",
      });
      anime({
        targets: document.getElementsByClassName(
          `app-project-card-${projectId}`
        ),
        opacity: [1, 0],
        duration: 800,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        dispatch(actions.updateDisplayMode(DisplayMode.CAPTURE));
        dispatch(actions.updateSelectedProjectId(projectId));
      });
    }
  };

  return (
    <div className="flex-col-el" style={{ transform: "translateY(28px)" }}>
      {/* フィルタのパーツとプロジェクトのパーツを同じdivにするとぼやけてしまう */}
      <div
        style={{
          transform: `translateX(${
            projectPanel === ProjectPanelType.SHARED
              ? -1204 + 242
              : 242 - 234 - 220 + 48
          }px)`,
        }}
        className="flex-row-el relative w-[234px] transition-transform duration-700 will-change-contents origin-top-left pointer-events-none"
      >
        <div className="flex-col-el w-[1204px] relative shrink-0 pointer-events-none">
          <div className="flex-col-el w-full items-end pointer-events-none">
            <div
              id="shared-project-panel-sort-filter-panel"
              className="flex-row-el items-end w-full h-[56px] cursor-pointer pointer-events-none transition-transform duration-700 will-change-transform"
              style={{
                transform: `translateX(${
                  sortedOrderPanel ? 220 - 48 : 1204 - 234
                }px)`,
              }}
            >
              <div
                onClick={handleSortFilterExpend}
                id="shared-project-panel-sort-filter-btn"
                className="flex-row-center w-[186px] h-full bg-green/100 rounded-tl-2xl gap-4 ml-12 pointer-events-auto"
              >
                <div className="flex-row-center gap-2">
                  <div className="flex-col-center w-[40px] h-[40px]">
                    <IconMagnifierWhite />
                  </div>
                  <p className="text-body text-white text-[20px]">
                    {I18n.t("MSG_CREATOR_APP_SORT_FILTER_TITLE")}
                  </p>
                </div>
                <div className="flex-row-center h-[24px] gap-1">
                  <div className="w-[4px] h-full bg-textcolor/black/25" />
                  <div className="w-[4px] h-full bg-textcolor/black/25" />
                  <div className="w-[4px] h-full bg-textcolor/black/25" />
                </div>
              </div>

              <div className="flex-col-el bg-blue/10 rounded-tl-3xl pointer-events-auto w-[800px]">
                <ProjectKeywordSearch />

                <div className="flex-row-center h-[84px]">
                  <ProjectSort />
                  <div className="flex-row-center w-[4px] h-full pt-5 pb-2">
                    <div className="flex-row-center w-[2px] h-full bg-textcolor/black/20" />
                    <div className="flex-row-center w-[2px] h-full bg-white" />
                  </div>
                  <ProjectFilter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          transform: `translateX(${
            projectPanel === ProjectPanelType.SHARED
              ? -(width * 0.95) / scale + 242
              : 242 - 234
          }px)`,
        }}
        className="flex-row-el relative w-[234px] transition-transform duration-700 will-change-transform origin-top-left pointer-events-none"
      >
        <div
          className="flex-col-el relative shrink-0 pointer-events-none"
          style={{ width: (width * 0.95) / scale }}
        >
          <div
            id="shared-project-panel"
            className="flex-col-el w-full items-end pointer-events-none"
          >
            <div className="flex-row-el w-full">
              <div className="flex-col-el absolute -top-[1px] w-full h-[262px] bg-[#9BBFD4] rounded-tl-[24px] rounded-bl-[32px] z-[-1]" />

              <div
                onClick={handleExpend}
                id="shared-project-panel-drawer"
                className="flex-col-el shrink-0 z-50 items-center justify-between relative w-[48px] h-full rounded-l-[24px] py-3 cursor-pointer pointer-events-auto bg-blue/100"
              >
                <p className="text-body text-white text-[20px] w-[20px] leading-[22px]">
                  {I18n.t("MSG_CREATOR_APP_SHARED_APPS")}
                </p>

                <div>
                  <IconDrawer />
                </div>
              </div>

              {render ? (
                <div
                  ref={ref}
                  onWheel={handleWheel}
                  className="flex-row-el items-center flex-grow px-4 h-[254px] will-change-transform bg-white pointer-events-auto invisible-scrollbar"
                >
                  <div
                    className="relative h-full"
                    style={{
                      width: `${columnVirtualizer.getTotalSize()}px`,
                    }}
                  >
                    {columnVirtualizer
                      .getVirtualItems()
                      .map((virtualColumn) => (
                        <div
                          key={virtualColumn.index}
                          className="w-[176px] h-full flex-col-el justify-center"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            transform: `translateX(${virtualColumn.start}px)`,
                          }}
                        >
                          <ProjectCard
                            key={items[virtualColumn.index].project_id}
                            user={user}
                            project={items[virtualColumn.index]}
                            handleSelectProject={handleSelectProject}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="flex-row-center w-full h-[254px] bg-white">
                  <div className="flex-col-center w-[100px] h-[100px]">
                    <div className="dots-loader" />
                  </div>
                </div>
              )}

              {items.length === 0 && (
                <div className="flex-col-el flex-center absolute w-full h-full">
                  <div className="flex-col-el absolute">
                    <p className="text-body text-font-size-body2 text-textcolor/black whitespace-pre-wrap text-center">
                      {I18n.t("MSG_CREATOR_APP_SHARED_APPS_EMPTY_MESSAGE")}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onClick={handleScrollBtn(-1)}
            className={`flex-row-el flex-center shrink-0 w-[80px] h-[254px] upsilon z-50 absolute bottom-0 left-[48px] bg-textcolor/black/20 cursor-pointer pointer-events-auto ${
              showLeftArrow && items.length > 0 ? "block" : "!hidden"
            }`}
          >
            <button
              className="btn btn-primary w-[40px] h-[72px]"
              onClick={handleScrollBtn(-1)}
            >
              <div className="flex-col-center">
                <IconArrowLeft />
              </div>
            </button>
          </div>

          <div
            onClick={handleScrollBtn(1)}
            className={`flex-row-el flex-center shrink-0 w-[80px] h-[254px] upsilon z-50 absolute bottom-0 right-0 bg-textcolor/black/20 cursor-pointer pointer-events-auto ${
              showRightArrow && render && items.length > 0 ? "block" : "!hidden"
            }`}
          >
            <button
              className="btn btn-primary w-[40px] h-[72px]"
              onClick={handleScrollBtn(1)}
            >
              <div className="flex-col-center">
                <IconArrowRight />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
