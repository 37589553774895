import { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { GameCase } from "app/types";
import { RootState } from "app/store";
import { useUserConfig } from "app/hook";
import Constants from "common/constant";
import { FadeTransition } from "common/elements";
import { useScale, usePlaySound } from "common/utils";
import { IconArrowLeft, IconArrowRight } from "common/assets";
import { CheckMark } from "./assets";

const GameCaseArray = [
  {
    type: GameCase.GRAY,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_gray_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.SOFT_YELLOW,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_soft_yellow_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.GREEN,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_green_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.CYAN,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_cyan_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.BLUE,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_blue_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.MILK,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_milk_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.PINK,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_pink_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.ORANGE,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_orange_case.png`}
        className="w-full h-full"
      />
    ),
  },
  {
    type: GameCase.SOFT_PINK,
    icon: () => (
      <img
        alt="*"
        src={`${Constants.assetHost}/assets/images/mini_soft_pink_case.png`}
        className="w-full h-full"
      />
    ),
  },
];

export const GameCaseStock = ({
  visible,
  gameCase,
  selectGameCase,
  close,
}: {
  visible: boolean;
  gameCase: GameCase;
  selectGameCase: (gameCase: GameCase) => void;
  close: (reset: boolean) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const ref = useRef<HTMLDivElement>();
  const handleChangeUserConfig = useUserConfig();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    setTimeout(() => {
      const iconEle = document.getElementById("user-page-setting-case");
      if (iconEle) {
        const rect = iconEle.getBoundingClientRect();
        setPosition({
          x: rect.left,
          y: rect.bottom,
        });
      }
    }, 300);
  }, [scale, visible]);

  const handleScroll = (direction: number) => () => {
    play();
    if (direction > 0) {
      // 右へ
      if (ref.current) {
        ref.current.scrollLeft += 120 * scale;
      }
    } else {
      // 左へ
      if (ref.current) {
        ref.current.scrollLeft -= 120 * scale;
      }
    }
  };

  const handleSelected = (gameCase: GameCase) => () => {
    play();
    selectGameCase(gameCase);
  };

  const handleConfirm = () => {
    handleChangeUserConfig("game_case", gameCase);
    toast(
      <div>
        <p className="text text-textcolor/black">
          {I18n.t("MSG_TOAST_EDIT_SUCCESS")}
        </p>
      </div>
    );
  };

  const handleClose = () => {
    setTimeout(() => close(true), 300);
  };

  return (
    <Dialog
      fullScreen
      open={visible && position.y !== 0}
      maxWidth={false}
      sx={{ zIndex: 99999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div className="flex-col-el flex-center relative w-full h-full">
        <div
          className="flex-col-el flex-center absolute top-0 left-0 origin-top-left"
          style={{
            transform: `translateX(${position.x}px) translateY(${position.y}px) translateZ(0) scale(${scale})`,
          }}
        >
          <div
            className="flex-col-el flex-center relative m-h-[900px]"
            style={{
              filter:
                "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
            }}
          >
            <div className="flex-row-el items-start w-full h-full p-2 relative border-solid border-white border-8 rounded-lg bg-beige/60">
              <div
                className="absolute w-0 h-0 border-solid border-x-transparent border-b-white"
                style={{
                  top: -40,
                  left: 130,
                  borderRightWidth: 40,
                  borderLeftWidth: 40,
                  borderTopWidth: 0,
                  borderBottomWidth: 40,
                }}
              />
              <div
                className="absolute w-0 h-0 border-solid border-x-transparent border-b-beige/60"
                style={{
                  top: -25,
                  left: 143,
                  borderRightWidth: 26,
                  borderLeftWidth: 26,
                  borderTopWidth: 0,
                  borderBottomWidth: 26,
                }}
              />

              <div className="flex-col-el w-[900px]">
                <div className="flex-col-el items-start w-full gap-3 bg-white rounded-xl">
                  <div className="flex-row-el flex-center relative w-full h-full">
                    <div className="flex-row-el flex-center upsilon">
                      <button
                        className="btn btn-primary w-[40px] h-[72px]"
                        onClick={handleScroll(-1)}
                      >
                        <div className="flex-col-center mb-[2px]">
                          <IconArrowLeft />
                        </div>
                      </button>
                    </div>

                    <div
                      ref={ref}
                      className="flex-row-el w-full pt-4 bg-white items-center justify-start gap-4 z-10 pointer-events-auto visible-scrollbar"
                    >
                      {GameCaseArray.map((g, index) => {
                        const Icon = g.icon;
                        return (
                          <div className="flex-col-center" key={index}>
                            {config.game_case === g.type && (
                              <div className="flex-col-center !absolute top-[-16px] left-[0px] z-10">
                                <CheckMark />
                              </div>
                            )}

                            <div
                              className={`flex-col-center w-[170px] h-[100px] mx-[10px] my-[5px] cursor-pointer ${
                                gameCase === g.type
                                  ? "border-solid border-[8px] border-green/100/40"
                                  : ""
                              }`}
                            >
                              <div
                                className="flex-col-center w-[152px] h-[80px]"
                                onClick={handleSelected(g.type)}
                              >
                                <Icon />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="flex-row-el flex-center upsilon">
                      <button
                        className="btn btn-primary w-[40px] h-[72px]"
                        onClick={handleScroll(1)}
                      >
                        <div className="flex-col-center mb-[2px]">
                          <IconArrowRight />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex-row-el flex-center alpha mt-2 gap-3">
                  <button onClick={handleClose} className="btn btn-blue">
                    <div className="flex-row-center gap-1 mb-[3px]">
                      <span className="material-symbols-outlined text-[36px]">
                        close
                      </span>
                      <p className="text-body text-white text-font-size-body1">
                        {I18n.t("MSG_CLOSE_BTN")}
                      </p>
                    </div>
                  </button>
                  <button
                    onClick={handleConfirm}
                    className="btn btn-primary"
                    disabled={config.game_case === gameCase}
                  >
                    <div className="flex-row-center gap-1 mb-[3px]">
                      <span className="material-symbols-outlined text-[36px] text-white">
                        check
                      </span>
                      <p className="text-body text-white text-font-size-body1">
                        {I18n.t("MSG_CONFIRM_BTN")}
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
