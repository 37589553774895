const ToolboxInfo = {
  maxInstances: {
    controls_whileUntil_colour_internal: 1,
    controls_if_colour_internal: 2,
    controls_repeat_internal: 1,
    character_motion_move: 1,
    character_motion_turn_right: 2,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "controls_whileUntil_colour_internal",
            inputs: {
              COLOUR: {
                shadow: {
                  type: "colour_picker_internal",
                  fields: {
                    COLOUR: "#71cdfc",
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: "controls_if_colour_internal",
            inputs: {
              COLOUR: {
                shadow: {
                  type: "colour_picker_internal",
                  fields: {
                    COLOUR: "#71cdfc",
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: "controls_repeat_internal",
            extraState: {
              max: 2,
            },
          },
          {
            kind: "block",
            type: "character_motion_move",
          },
          {
            kind: "block",
            type: "character_motion_turn_right",
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
