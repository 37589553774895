import { Dialog } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { SlideUpTransition } from "common/elements";

export const PeeringInfo = ({
  open,
  value,
  close,
}: {
  open: boolean;
  value?: string;
  close: () => void;
}) => (
  <Dialog
    fullScreen
    open={open && !!value}
    maxWidth={false}
    onClick={close}
    sx={{ zIndex: 200000 }}
    TransitionComponent={SlideUpTransition}
    PaperProps={{
      style: { backgroundColor: "transparent", boxShadow: "unset" },
    }}
  >
    <div className="flex-col-el flex-center w-full h-full">
      <div className="flex-col-el flex-center p-10 rounded-md bg-white relative">
        <QRCodeSVG size={160} value={`${value}`} />
      </div>
    </div>
  </Dialog>
);
