import { FreebuildTitleSmallSvg } from "features/home/assets";
import { TopLayout } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next } = props;

  return (
    <TopLayout next={next}>
      <div className="flex-col-el flex-center gap-6 ml-4 mt-10">
        <p className="text-body text-[80px] text-white leading-[70px]">1</p>

        <div className="flex-col-center gap-2">
          <div className="flex-col-center w-[180px] h-[36px]">
            <FreebuildTitleSmallSvg />
          </div>

          <p className="text-body text-[18px] text-white">について</p>
        </div>
      </div>
    </TopLayout>
  );
};

export default Step;
