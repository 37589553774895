/* eslint-disable no-new-func */
import { useState } from "react";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { Clock } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  const [clicked, setClicked] = useState(false);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "anime",
    "sleep",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked) {
      play();
      setRunning(true);
      setClicked(true);
      handleEvent(anime, sleep, highlightBlock, setSuccess, setGameOver)();
    }
  };

  return (
    <div className="flex-row-el items-end justify-center gap-2 mt-8">
      <Clock question={{ hour: 1, minutes: 0 }} />
      <img
        alt="*"
        onClick={handleClick}
        src={`${Constants.assetHost}/stamps/electronic/89ac81d0297168e63110e5778eff4617.png`}
        className="w-[80px] h-[80px] z-50 active:opacity-70 cursor-pointer"
      />
    </div>
  );
};
