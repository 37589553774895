import { SVGProps } from "react";

export const IconSizeDisable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 5h11M12 7l2-2-2-2M5 3 3 5l2 2M19 10v11M17 19l2 2 2-2M21 12l-2-2-2 2M12 10H5a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2Z"
      stroke="#B8B1B5"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
