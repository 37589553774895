import { useEffect } from "react";
import I18n from "i18n-js";
import Constants from "common/constant";
import {
  InitImageClickBlock,
  InitImageGetterXBlock,
  InitImageSetterXBlock,
  InitLabelTextShowSetterBlock,
} from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitImageClickBlock();
    InitImageGetterXBlock();
    InitImageSetterXBlock();
    InitLabelTextShowSetterBlock();
  }, []);

  return (
    <div className="flex-row-center w-full gap-20 mt-8">
      <div className="flex-col-el flex-center">
        <div className="flex-row-el flex-center absolute top-0">
          <p className="text-body text-white text-[20px]">
            {I18n.t("MSG_COURSE_COORDINATE_X")}
          </p>
        </div>

        <div className="flex-col-center w-[300px] h-[5px]">
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
            <p className="text text-white !mb-[35px]">0</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
            <p className="text text-white !mb-[35px]">35</p>
          </div>
          <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
            <p className="text text-white !mb-[35px]">70</p>
          </div>
          <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

          <div className="flex-row-el absolute -left-10 top-4 cursor-pointer">
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px]"
            />
          </div>

          <div
            className="flex-row-el absolute -left-10 top-4"
            style={{ transform: `translateX(${70 * 4.3}px)` }}
          >
            <img
              alt="*"
              src={`${Constants.assetHost}/stamps/vehicle/f98552ed024421f272d098f07ea5b9a0.png`}
              className="w-[60px] h-[60px] opacity-30"
            />
          </div>
        </div>
      </div>

      <div className="flex-col-center">
        <div className="flex-row-center p-2 border-solid border-[4px] rounded-[6px] border-white/20">
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            70
          </p>
        </div>

        <p className="text text-white">ボックス1</p>
      </div>
    </div>
  );
};
