import { TileSetMapType, CHARACTER_DIRECTION } from "common/model";

const landscapeLayer1 = [
  [0, 0, 99, 0, 0],
  [0, 0, 103, 0, 0],
  [0, 0, 99, 0, 0],
  [99, 99, 99, 0, 0],
  [0, 0, 0, 0, 0],
];
const landscapeLayer2 = [
  [0, 0, 113, 0, 0],
  [0, 0, 90, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const landscape = [landscapeLayer1, landscapeLayer2];

const map: TileSetMapType = {
  character: {
    direction: CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X,
  },
  landscape: landscape,
};

export default map;
