import { useRef, useState, MutableRefObject, PointerEventHandler } from "react";
import produce from "immer";
import { calcVector } from "common/utils";
import { Vector2D, Vector2 } from "common/types";
import { ComponentProperty, WIDTH_MIN, HEIGHT_MIN } from "common/components";
import { RotationPanel } from "./RotationPanel";

const ResizeEdgeUnitVector = {
  TOP: { x: 0, y: 1 },
  BOTTOM: { x: 0, y: -1 },
  LEFT: { x: -1, y: 0 },
  RIGHT: { x: 1, y: 0 },
};

const ResizeCornerUnitVector = {
  TOP_RIGHT: { x: 1, y: 1 },
  BOTTOM_RIGHT: { x: 1, y: -1 },
  BOTTOM_LEFT: { x: -1, y: -1 },
  TOP_LEFT: { x: -1, y: 1 },
};

export const ResizePanel = (props: {
  id: string;
  scale: number;
  groupDelta: Vector2D;
  grouping: boolean;
  groupMoving: boolean;
  panelMargin: number;
  propertyRef: MutableRefObject<ComponentProperty>;
  setProperty: (property: ComponentProperty) => void;
  onActionEnd: (newProperty: ComponentProperty) => void;
}) => {
  const {
    id,
    scale,
    groupDelta,
    grouping,
    groupMoving,
    panelMargin,
    propertyRef,
    setProperty,
    onActionEnd,
  } = props;
  const ref = useRef<HTMLDivElement>();
  const [rotate, setRotate] = useState(false);
  const [resize, setResize] = useState(false);

  const [direction, _setDirection] = useState<Vector2D>(
    ResizeCornerUnitVector.TOP_LEFT
  ); // default top left
  const directionRef = useRef(direction);
  const setDirection = (data) => {
    directionRef.current = data;
    _setDirection(data);
  };

  const [preClientXY, _setPreClientXY] = useState<Vector2D>({ x: 0, y: 0 });
  const preClientRef = useRef(preClientXY);
  const setPreClientXY = (data: Vector2D) => {
    preClientRef.current = data;
    _setPreClientXY(data);
  };

  const RESIZE_PANEL_BORDER_SIZE = 2;
  const DEFAULT_BTN_BORDER_SIZE = 1;
  const RESIZE_CORNER_INNER_BTN_SIZE = 16;
  const RESIZE_CORNER_BTN_SIZE = RESIZE_CORNER_INNER_BTN_SIZE + 24;
  const RESIZE_CORNER_BTN_OFFSET = -RESIZE_CORNER_BTN_SIZE / 2;

  const radius = propertyRef.current.style.transform.radius;
  const width =
    propertyRef.current.style.layout.width +
    propertyRef.current.style.view.borderWidth +
    propertyRef.current.style.shadow.shadowRadius +
    panelMargin;
  const height =
    propertyRef.current.style.layout.height +
    propertyRef.current.style.view.borderWidth +
    propertyRef.current.style.shadow.shadowRadius +
    panelMargin;

  const onResize = (dx: number, dy: number) => {
    const preWidth = propertyRef.current.style.layout.width;
    const preHeight = propertyRef.current.style.layout.height;
    const preRefPoint = calcVector(
      (preWidth * -direction.x) / 2,
      (preHeight * -direction.y) / 2,
      -radius
    );
    const ratio = preWidth / preHeight;
    const dragPoint = calcVector(
      (preWidth * direction.x) / 2,
      (preHeight * direction.y) / 2,
      -radius
    );
    let increment: Vector2;
    if (direction.x * direction.y === 0) {
      const dragPointUnitVector = {
        x:
          dragPoint.x === 0
            ? Math.abs(direction.x)
            : dragPoint.x / Math.abs(dragPoint.x),
        y:
          dragPoint.y === 0
            ? Math.abs(direction.y)
            : dragPoint.y / Math.abs(dragPoint.y),
      };

      const theta =
        Math.abs(radius) > Math.PI / 2
          ? Math.abs(radius) - Math.PI / 2
          : Math.abs(radius);
      const deltaX =
        dx * dragPointUnitVector.x * Math.abs(Math.cos(theta)) -
        dy * dragPointUnitVector.y * Math.sin(theta);
      const deltaY =
        dx * dragPointUnitVector.x * Math.sin(theta) -
        dy * dragPointUnitVector.y * Math.abs(Math.cos(theta));
      const delta = Math.abs(deltaX) > Math.abs(deltaY) ? deltaX : deltaY;
      increment = new Vector2(
        delta * Math.abs(direction.x),
        delta * Math.abs(direction.y)
      );
    } else {
      const dragPointUnitVector = {
        x: dragPoint.x / Math.abs(dragPoint.x),
        y: dragPoint.y === 0 ? 1 : dragPoint.y / Math.abs(dragPoint.y),
      };
      const dragPointRadius = Math.atan2(dragPoint.y, dragPoint.x);

      // dx:
      // delta = w * cosθ + h * sinθ
      // w / h = ratio
      // w = delta / (cosθ+(1/ratio)*sinθ)

      // dy:
      // delta = w*sinθ+h*cosθ
      // w / h = ratio
      // w = delta / (sinθ+(1/ratio)*cosθ)

      // drag pointはx,y軸はどちらが方向に偏る
      const incrementWidth =
        Math.abs(Math.cos(dragPointRadius)) >
        Math.abs(Math.sin(dragPointRadius))
          ? (dragPointUnitVector.x * dx) /
            (Math.abs(Math.cos(Math.abs(radius))) +
              (1 / ratio) * Math.sin(Math.abs(radius)))
          : (-dragPointUnitVector.y * dy) /
            (Math.sin(Math.abs(radius)) +
              (1 / ratio) * Math.abs(Math.cos(Math.abs(radius))));
      increment = new Vector2(incrementWidth, incrementWidth / ratio);
    }

    const originOffset = calcVector(increment.x / 2, increment.y / 2, 0);

    const newWidth = preWidth + increment.x;
    const newHeight = preHeight + increment.y;

    const newRefPoint = calcVector(
      (newWidth * -direction.x) / 2,
      (newHeight * -direction.y) / 2,
      -radius
    );

    const offsetX = newRefPoint.x - (preRefPoint.x - originOffset.x);
    const offsetY = newRefPoint.y - (preRefPoint.y + originOffset.y);

    if (newWidth >= WIDTH_MIN && newHeight >= HEIGHT_MIN) {
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.layout.width = newWidth;
        draft.style.layout.height = newHeight;
        draft.style.transform.translateX -= offsetX;
        draft.style.transform.translateY += offsetY;

        if (draft.style.text && direction.x * direction.y !== 0) {
          draft.style.text.fontSize *= newWidth / preWidth;
        }
      });
      setProperty(newProperty);
    } else if (newWidth < WIDTH_MIN) {
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.layout.width = WIDTH_MIN;
      });

      setProperty(newProperty);
    } else if (newHeight < HEIGHT_MIN) {
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.layout.height = HEIGHT_MIN;
      });
      setProperty(newProperty);
    }
  };

  const onPointerStart = (
    direction: Vector2D
  ): PointerEventHandler<HTMLDivElement> => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setResize(true);
    }
    setDirection(direction);
    setPreClientXY({ x: e.clientX, y: e.clientY });
  };
  const onPointerMove: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "touch" || resize) {
      const dx = (e.clientX - preClientRef.current.x) / scale;
      const dy = (e.clientY - preClientRef.current.y) / scale;
      setPreClientXY({ x: e.clientX, y: e.clientY });
      onResize(dx, dy);
    }
  };
  const onPointerEnd: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setResize(false);
    }
    onActionEnd(propertyRef.current);
  };

  const onRotate = (clientX: number, clientY: number) => {
    if (ref.current) {
      const target = ref.current.getBoundingClientRect();
      const r = Math.atan2(
        -(clientY - (target.y + target.height / 2)),
        clientX - (target.x + target.width / 2)
      );

      const rotation =
        Math.PI / 2 - r > Math.PI
          ? Math.PI / 2 - r - 2 * Math.PI
          : Math.PI / 2 - r;

      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.transform.radius = rotation;
        draft.style.transform.rotation = `${(rotation * 180) / Math.PI}`;
      });
      setProperty(newProperty);
    }
  };

  const onRotationPointerStart: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setRotate(true);
    }
  };

  const onRotationPointerMove: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "touch" || rotate) {
      onRotate(e.clientX, e.clientY);
    }
  };

  const onRotationPointerEnd: PointerEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.pointerType === "mouse") {
      setRotate(false);
    }
    onActionEnd(propertyRef.current);
  };

  return (
    <>
      {resize && (
        <div
          id="action-resize-mask"
          className="w-full h-full absolute top-0 left-0 z-[10002]"
          onPointerMove={onPointerMove}
          onPointerUp={onPointerEnd}
        />
      )}
      {rotate && (
        <div
          id="action-rotate-mask"
          className="w-full h-full absolute top-0 left-0 z-[10002]"
          onPointerMove={onRotationPointerMove}
          onPointerUp={onRotationPointerEnd}
        />
      )}
      <div
        id={`component-resize-panel-${id}`}
        ref={ref}
        className={`absolute top-0 left-0 z-[10001] pointer-events-none box-border border-solid ${
          grouping
            ? "bg-green/altcolor/10 border-green/60"
            : "bg-[#70b1f4]/10 border-[#70B1F4]"
        }`}
        style={{
          backfaceVisibility: "hidden",
          width: width,
          height: height,
          transform: `translate(${
            propertyRef.current.style.transform.translateX -
            panelMargin / 2 +
            (grouping && groupMoving ? groupDelta.x : 0)
          }px, ${
            propertyRef.current.style.transform.translateY -
            panelMargin / 2 +
            (grouping && groupMoving ? groupDelta.y : 0)
          }px) rotate(${propertyRef.current.style.transform.rotation}deg) `,
          willChange: "transform",
          borderWidth: RESIZE_PANEL_BORDER_SIZE,
        }}
      >
        <div
          className="flex-row-center !absolute z-[10002] bottom-[-34px] left-0"
          style={{
            backfaceVisibility: "hidden",
            width: width,
            height: 24,
          }}
        >
          <p className="text text-blue/80 !text-[14px]">
            {propertyRef.current.name}
          </p>
        </div>

        {!grouping && (
          <RotationPanel
            width={width}
            onRotationPointerStart={onRotationPointerStart}
            onRotationPointerMove={onRotationPointerMove}
            onRotationPointerUp={onRotationPointerEnd}
          />
        )}

        {!grouping &&
          Object.values(ResizeCornerUnitVector).map((direction, index) => {
            return (
              <div
                className="flex-row-center !absolute pointer-events-auto z-[10001] cursor-grab"
                key={index}
                style={{
                  width: RESIZE_CORNER_BTN_SIZE,
                  height: RESIZE_CORNER_BTN_SIZE,
                  borderRadius: RESIZE_CORNER_BTN_SIZE / 2,
                  top: direction.y > 0 && RESIZE_CORNER_BTN_OFFSET,
                  left: direction.x < 0 && RESIZE_CORNER_BTN_OFFSET,
                  right: direction.x > 0 && RESIZE_CORNER_BTN_OFFSET,
                  bottom: direction.y < 0 && RESIZE_CORNER_BTN_OFFSET,
                }}
                onPointerDown={onPointerStart(direction)}
                onPointerMove={onPointerMove}
                onPointerUp={onPointerEnd}
              >
                <div
                  className="bg-white border-solid border-[1px] border-blue/80 absolute box-border"
                  style={{
                    width: RESIZE_CORNER_INNER_BTN_SIZE,
                    height: RESIZE_CORNER_INNER_BTN_SIZE,
                    borderRadius: RESIZE_CORNER_INNER_BTN_SIZE,
                  }}
                />
              </div>
            );
          })}
        {!grouping &&
          (!propertyRef.current.resize.lockAspectRatioX ||
            !propertyRef.current.resize.lockAspectRatioY) &&
          Object.values(ResizeEdgeUnitVector).map((direction, index) => {
            if (
              propertyRef.current.resize.lockAspectRatioX &&
              direction.x !== 0
            ) {
              return null;
            }
            if (
              propertyRef.current.resize.lockAspectRatioY &&
              direction.y !== 0
            ) {
              return null;
            }
            return (
              <div
                className="flex-row-center !absolute pointer-events-auto"
                key={index}
                style={{
                  width: RESIZE_CORNER_BTN_SIZE / (1 + Math.abs(direction.x)),
                  height: RESIZE_CORNER_BTN_SIZE / (1 + Math.abs(direction.y)),
                  borderRadius: RESIZE_CORNER_BTN_SIZE / 2,
                  top:
                    direction.y >= 0 &&
                    (height * (1 - direction.y) -
                      RESIZE_CORNER_BTN_SIZE / (1 + Math.abs(direction.y)) -
                      RESIZE_PANEL_BORDER_SIZE * Math.abs(direction.x)) /
                      2,
                  right:
                    direction.x > 0 &&
                    (-RESIZE_CORNER_BTN_SIZE / (1 + Math.abs(direction.x)) -
                      RESIZE_PANEL_BORDER_SIZE) /
                      2,
                  left:
                    direction.x <= 0 &&
                    (width * (1 + direction.x) -
                      RESIZE_CORNER_BTN_SIZE / (1 + Math.abs(direction.x)) -
                      RESIZE_PANEL_BORDER_SIZE * Math.abs(direction.x)) /
                      2,
                  bottom:
                    direction.y < 0 &&
                    (-RESIZE_CORNER_BTN_SIZE / (1 + Math.abs(direction.y)) -
                      RESIZE_PANEL_BORDER_SIZE) /
                      2,
                }}
                onPointerDown={onPointerStart(direction)}
                onPointerMove={onPointerMove}
                onPointerUp={onPointerEnd}
              >
                <div
                  className="bg-white border-solid border-blue/80 absolute box-border"
                  style={{
                    width:
                      RESIZE_CORNER_INNER_BTN_SIZE /
                      (1 + Math.abs(direction.x)),
                    height:
                      RESIZE_CORNER_INNER_BTN_SIZE /
                      (1 + Math.abs(direction.y)),
                    borderWidth: DEFAULT_BTN_BORDER_SIZE,
                    borderRadius: RESIZE_CORNER_INNER_BTN_SIZE / 2,
                  }}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};
