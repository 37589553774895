import React from "react";
import { Block } from "common/blockly";

const Toolbox = (props) => {
  return (
    <xml is="blockly" id={props.toolboxId} style={{ display: "none" }}>
      <Block type="character_motion_move" />
      <Block type="character_motion_jump" />
    </xml>
  );
};

const ToolboxInfo = {
  maxInstances: { character_motion_move: 1, character_motion_jump: 2 },
  Toolbox: Toolbox,
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "character_motion_move",
          },
          {
            kind: "block",
            type: "character_motion_jump",
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
