import {
  Stage1LevelRect,
  Stage2LevelRect,
  Stage3LevelRect,
  Stage4LevelRect,
  Stage5LevelRect,
  Stage6LevelRect,
  Stage7LevelRect,
} from "features/courses/program/level/StageLevelRects";

const Stage1LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage1LevelRect[0].left + radius * 2) * scale + offset} ${
    (Stage1LevelRect[0].top + radius) * scale
  } L ${Stage1LevelRect[1].left * scale + offset} ${
    (Stage1LevelRect[1].top + radius) * scale
  }`,
  `M ${(Stage1LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage1LevelRect[1].top + radius) * scale
  } L ${Stage1LevelRect[2].left * scale + offset} ${
    (Stage1LevelRect[2].top + radius) * scale
  }`,
  `M ${(Stage1LevelRect[2].left + radius * 2) * scale + offset} ${
    (Stage1LevelRect[2].top + radius) * scale
  } L ${Stage1LevelRect[3].left * scale + offset} ${
    (Stage1LevelRect[3].top + radius) * scale
  }`,
  `M ${(Stage1LevelRect[3].left + radius) * scale + offset} ${
    (Stage1LevelRect[3].top + radius * 2) * scale
  } L ${(Stage1LevelRect[4].left + radius) * scale + offset} ${
    Stage1LevelRect[4].top * scale
  }`,
  `M ${Stage1LevelRect[4].left * scale + offset} ${
    (Stage1LevelRect[4].top + radius) * scale
  } L ${(Stage1LevelRect[5].left + radius * 2) * scale + offset} ${
    (Stage1LevelRect[5].top + radius) * scale
  }`,
  `M ${Stage1LevelRect[5].left * scale + offset} ${
    (Stage1LevelRect[5].top + radius) * scale
  } L ${(Stage1LevelRect[6].left + radius * 2) * scale + offset} ${
    (Stage1LevelRect[6].top + radius) * scale
  }`,
];

const Stage2LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage2LevelRect[0].left + radius) * scale + offset} ${
    (Stage2LevelRect[0].top + radius * 2) * scale
  } L ${(Stage2LevelRect[1].left + radius) * scale + offset} ${
    Stage2LevelRect[1].top * scale
  }`,
  `M ${(Stage2LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage2LevelRect[1].top + radius) * scale
  } L ${Stage2LevelRect[2].left * scale + offset} ${
    (Stage2LevelRect[2].top + radius) * scale
  }`,
  `M ${(Stage2LevelRect[2].left + radius) * scale + offset} ${
    Stage2LevelRect[2].top * scale
  } L ${(Stage2LevelRect[3].left + radius) * scale + offset} ${
    (Stage2LevelRect[3].top + radius * 2) * scale
  }`,
  `M ${(Stage2LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage2LevelRect[3].top + radius) * scale
  } L ${Stage2LevelRect[4].left * scale + offset} ${
    (Stage2LevelRect[4].top + radius) * scale
  }`,
  `M ${(Stage2LevelRect[4].left + radius) * scale + offset} ${
    (Stage2LevelRect[4].top + radius * 2) * scale
  } L ${(Stage2LevelRect[5].left + radius) * scale + offset} ${
    Stage2LevelRect[5].top * scale
  }`,
  `M ${(Stage2LevelRect[5].left + radius * 2) * scale + offset} ${
    (Stage2LevelRect[5].top + radius) * scale
  } L ${Stage2LevelRect[6].left * scale + offset} ${
    (Stage2LevelRect[6].top + radius) * scale
  }`,
];
const Stage3LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage3LevelRect[0].left + radius) * scale + offset} ${
    (Stage3LevelRect[0].top + radius * 2) * scale
  } L ${(Stage3LevelRect[1].left + radius) * scale + offset} ${
    Stage3LevelRect[1].top * scale
  }`,
  `M ${(Stage3LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage3LevelRect[1].top + radius) * scale
  } L ${Stage3LevelRect[2].left * scale + offset} ${
    (Stage3LevelRect[2].top + radius) * scale
  }`,
  `M ${(Stage3LevelRect[2].left + radius * 2) * scale + offset} ${
    (Stage3LevelRect[2].top + radius) * scale
  } L ${Stage3LevelRect[3].left * scale + offset} ${
    (Stage3LevelRect[3].top + radius) * scale
  }`,
  `M ${(Stage3LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage3LevelRect[3].top + radius) * scale
  } L ${Stage3LevelRect[4].left * scale + offset} ${
    (Stage3LevelRect[4].top + radius) * scale
  }`,
  `M ${(Stage3LevelRect[4].left + radius) * scale + offset} ${
    Stage3LevelRect[4].top * scale
  } L ${(Stage3LevelRect[5].left + radius) * scale + offset} ${
    (Stage3LevelRect[5].top + radius * 2) * scale
  }`,
  `M ${Stage3LevelRect[5].left * scale + offset} ${
    (Stage3LevelRect[5].top + radius) * scale
  } L ${(Stage3LevelRect[6].left + radius * 2) * scale + offset} ${
    (Stage3LevelRect[6].top + radius) * scale
  }`,
];
const Stage4LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage4LevelRect[0].left + radius) * scale + offset} ${
    Stage4LevelRect[0].top * scale
  } L ${(Stage4LevelRect[1].left + radius) * scale + offset} ${
    (Stage4LevelRect[1].top + radius * 2) * scale
  }`,
  `M ${(Stage4LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage4LevelRect[1].top + radius) * scale
  } L ${Stage4LevelRect[2].left * scale + offset} ${
    (Stage4LevelRect[2].top + radius) * scale
  }`,
  `M ${(Stage4LevelRect[2].left + radius) * scale + offset} ${
    (Stage4LevelRect[2].top + radius * 2) * scale
  } L ${(Stage4LevelRect[3].left + radius) * scale + offset} ${
    Stage4LevelRect[3].top * scale
  }`,
  `M ${(Stage4LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage4LevelRect[3].top + radius) * scale
  } L ${Stage4LevelRect[4].left * scale + offset} ${
    (Stage4LevelRect[4].top + radius) * scale
  }`,
  `M ${(Stage4LevelRect[4].left + radius) * scale + offset} ${
    Stage4LevelRect[4].top * scale
  } L ${(Stage4LevelRect[5].left + radius) * scale + offset} ${
    (Stage4LevelRect[5].top + radius * 2) * scale
  }`,
  `M ${(Stage4LevelRect[5].left + radius * 2) * scale + offset} ${
    (Stage4LevelRect[5].top + radius) * scale
  } L ${Stage4LevelRect[6].left * scale + offset} ${
    (Stage4LevelRect[6].top + radius) * scale
  }`,
];
const Stage5LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage5LevelRect[0].left + radius) * scale + offset} ${
    (Stage5LevelRect[0].top + radius * 2) * scale
  } L ${(Stage5LevelRect[1].left + radius) * scale + offset} ${
    Stage5LevelRect[1].top * scale
  }`,
  `M ${(Stage5LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage5LevelRect[1].top + radius) * scale
  } L ${Stage5LevelRect[2].left * scale + offset} ${
    (Stage5LevelRect[2].top + radius) * scale
  }`,
  `M ${(Stage5LevelRect[2].left + radius) * scale + offset} ${
    Stage5LevelRect[2].top * scale
  } L ${(Stage5LevelRect[3].left + radius) * scale + offset} ${
    (Stage5LevelRect[3].top + radius * 2) * scale
  }`,
  `M ${(Stage5LevelRect[3].left + radius * 2) * scale + offset} ${
    (Stage5LevelRect[3].top + radius) * scale
  } L ${Stage5LevelRect[4].left * scale + offset} ${
    (Stage5LevelRect[4].top + radius) * scale
  }`,
  `M ${(Stage5LevelRect[4].left + radius * 2) * scale + offset} ${
    (Stage5LevelRect[4].top + radius) * scale
  } L ${Stage5LevelRect[5].left * scale + offset} ${
    (Stage5LevelRect[5].top + radius) * scale
  }`,
  `M ${(Stage5LevelRect[5].left + radius) * scale + offset} ${
    (Stage5LevelRect[5].top + radius * 2) * scale
  } L ${(Stage5LevelRect[6].left + radius) * scale + offset} ${
    Stage5LevelRect[6].top * scale
  }`,
];
const Stage6LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage6LevelRect[0].left + radius) * scale + offset} ${
    (Stage6LevelRect[0].top + radius * 2) * scale
  } L ${(Stage6LevelRect[1].left + radius) * scale + offset} ${
    Stage6LevelRect[1].top * scale
  }`,
  `M ${(Stage6LevelRect[1].left + radius * 2) * scale + offset} ${
    (Stage6LevelRect[1].top + radius) * scale
  } L ${Stage6LevelRect[2].left * scale + offset} ${
    (Stage6LevelRect[2].top + radius) * scale
  }`,
  `M ${(Stage6LevelRect[2].left + radius * 2) * scale + offset} ${
    (Stage6LevelRect[2].top + radius) * scale
  } L ${Stage6LevelRect[3].left * scale + offset} ${
    (Stage6LevelRect[3].top + radius) * scale
  }`,
  `M ${(Stage6LevelRect[3].left + radius) * scale + offset} ${
    Stage6LevelRect[3].top * scale
  } L ${(Stage6LevelRect[4].left + radius) * scale + offset} ${
    (Stage6LevelRect[4].top + radius * 2) * scale
  }`,
  `M ${(Stage6LevelRect[4].left + radius * 2) * scale + offset} ${
    (Stage6LevelRect[4].top + radius) * scale
  } L ${Stage6LevelRect[5].left * scale + offset} ${
    (Stage6LevelRect[5].top + radius) * scale
  }`,
  `M ${(Stage6LevelRect[5].left + radius) * scale + offset} ${
    (Stage6LevelRect[5].top + radius * 2) * scale
  } L ${(Stage6LevelRect[6].left + radius) * scale + offset} ${
    Stage6LevelRect[6].top * scale
  }`,
];
const Stage7LevelPath = (radius: number, scale: number, offset: number) => [
  `M ${(Stage7LevelRect[0].left + radius * 2) * scale + offset} ${
    (Stage7LevelRect[0].top + radius) * scale
  } L ${Stage7LevelRect[1].left * scale + offset} ${
    (Stage7LevelRect[1].top + radius) * scale
  }`,
  `M ${(Stage7LevelRect[1].left + radius) * scale + offset} ${
    (Stage7LevelRect[1].top + radius * 2) * scale
  } L ${(Stage7LevelRect[2].left + radius) * scale + offset} ${
    Stage7LevelRect[2].top * scale
  }`,
  `M ${(Stage7LevelRect[2].left + radius * 2) * scale + offset} ${
    (Stage7LevelRect[2].top + radius) * scale
  } L ${Stage7LevelRect[3].left * scale + offset} ${
    (Stage7LevelRect[3].top + radius) * scale
  }`,
  `M ${(Stage7LevelRect[3].left + radius) * scale + offset} ${
    Stage7LevelRect[3].top * scale
  } L ${(Stage7LevelRect[4].left + radius) * scale + offset} ${
    (Stage7LevelRect[4].top + radius * 2) * scale
  }`,
  `M ${(Stage7LevelRect[4].left + radius * 2) * scale + offset} ${
    (Stage7LevelRect[4].top + radius) * scale
  } L ${Stage7LevelRect[5].left * scale + offset} ${
    (Stage7LevelRect[5].top + radius) * scale
  }`,
  `M ${(Stage7LevelRect[5].left + radius) * scale + offset} ${
    (Stage7LevelRect[5].top + radius * 2) * scale
  } L ${(Stage7LevelRect[6].left + radius) * scale + offset} ${
    Stage7LevelRect[6].top * scale
  }`,
];

export const StageLevelPaths = [
  Stage1LevelPath,
  Stage2LevelPath,
  Stage3LevelPath,
  Stage4LevelPath,
  Stage5LevelPath,
  Stage6LevelPath,
  Stage7LevelPath,
];
