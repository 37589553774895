import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { showAnswerSnippetByClickIndexOrder } from "features/courses/program/game/blockly/uitls";

export const InitImageClickBlock = () => {
  Blockly.Blocks["program_image_click_event_block"] = {
    init: function () {
      const components = [
        [
          {
            src: `${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          "button_1",
        ],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_image_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
        return async (imageId) => {
          let target;
          let answerMark;
          highlightBlock("${block.id}", true);
          await sleep(100);
          highlightBlock("${block.id}", false);
          const checkMark = document.getElementById("course-program-check-mark-".concat('', imageId));
          checkMark.style.opacity = "1";
          if(${result}){
            ${logic}
            setDone(true);
            setClickedIndex(clickedIndex + 1);
            if(clickedIndex > 3){
              setSuccess(!result.includes(false));
              setGameOver(true);
            }
          } else {
            setSuccess(false);
            setGameOver(true);
          }
        }
      `;
    return code;
  };
};

export const InitShowImageBlock = () => {
  Blockly.Blocks["show_image_block"] = {
    init: function () {
      const input = this.appendDummyInput();
      const options = [
        [
          {
            src: `${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
            width: 30,
            height: 30,
            alt: "Fragaria",
          },
          `Fragaria#${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
            width: 30,
            height: 30,
            alt: "Pineapple",
          },
          `Pineapple#${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          `Grape#${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
        ],
      ];
      input
        .appendField(new Blockly.FieldDropdown(options), "Image")
        .appendField("%{BKY_DISPLAY_EVENT}");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour("#FFAB19");
    },
  };
  BlocklyJs["show_image_block"] = function (block: Blockly.Block) {
    const selectedValue = block.getField("Image").getValue().split("#");
    const selectedImage = selectedValue[0];
    const selectedImageSrc = selectedValue[1];
    return `
      highlightBlock("${block.id}", true);
      target = document.getElementById("course-program-image-".concat('', clickedIndex + 1 + 5));
      target.src = "${selectedImageSrc}";
      sound();
      target.style.opacity = "1";
      target.style.transform = "scale(1)";
      result[clickedIndex] = targetEles[clickedIndex] === "${selectedImage}";
      await sleep(700);
      ${showAnswerSnippetByClickIndexOrder()}
      if(!result[clickedIndex]){
        setSuccess(false);
        setGameOver(true);
      };
      await sleep(700);
      target = document.getElementById("course-program-arrow-".concat('', clickedIndex + 1));
      if(target){
        target.style.opacity = "1";
      };
      highlightBlock("${block.id}", false);
    `;
  };
};
