/* eslint-disable no-new-func */
import { useState } from "react";
import I18n from "i18n-js";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const play = usePlaySound();
  let target: HTMLImageElement;
  const targetEles = [50, 100];
  const [clickedIndex, setClickedIndex] = useState(0);
  const [result, setResult] = useState([false, false]);
  const [clicked, setClicked] = useState([false, false]);
  const { event, highlightBlock, setRunning, setSuccess, setGameOver } = props;

  const handleEvent = new Function(
    "anime",
    "sleep",
    "highlightBlock",
    "clickedIndex",
    "setClickedIndex",
    "target",
    "targetEles",
    "result",
    "setResult",
    "setRunning",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setClickedIndex(2);
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  const handleClick = () => {
    if (!clicked[clickedIndex] && clickedIndex < 2) {
      play();
      if (clickedIndex === 0) {
        setClicked([true, clicked[1]]);
      } else if (clickedIndex === 1) {
        setClicked([clicked[0], true]);
      }
      handleEvent(
        anime,
        sleep,
        highlightBlock,
        clickedIndex,
        setClickedIndex,
        target,
        targetEles,
        result,
        setResult,
        setRunning,
        setSuccess,
        setGameOver
      )();
    }
  };

  return (
    <div className="flex-col-el items-center w-full h-full absolute top-32">
      <div className="flex-row-el flex-center absolute -top-16">
        <p className="text-body text-white text-[20px]">
          {I18n.t("MSG_COURSE_COORDINATE_X")}
        </p>
      </div>

      <div className="flex-col-center w-[450px] h-[5px]">
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
          <p className="text text-white !mb-[35px]">0</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
          <p className="text text-white !mb-[35px]">50</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
          <p className="text text-white !mb-[35px]">100</p>
        </div>
        <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

        <div
          data-value="0"
          id="course-program-image-1"
          className="flex-row-el flex-center absolute top-4 -left-8 z-50 cursor-pointer"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px]"
          />
          <AnswerMark id={1} />
        </div>

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${50 * 4.5}px)` }}
          />
        </div>

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${100 * 4.5}px)` }}
          />
        </div>
      </div>

      <div className="flex-row-el flex-center mt-24 cursor-pointer">
        <img
          alt="*"
          onClick={handleClick}
          src={`${Constants.assetHost}/stamps/internet/1b69d8850494bfd07a6c05a64a721633.png`}
          className="w-[100px] h-[100px] active:opacity-70"
        />
      </div>
    </div>
  );
};
