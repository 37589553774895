import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { IconCalendar, IconCourseClear } from "features/user/assets";

export const GameHeader = ({ finished }: { finished: number }) => {
  const user = useSelector((state: RootState) => state.user.appUser.active);
  const period = dayjs().diff(user.created_at, "days");
  return (
    <div className="flex-row-el justify-between px-5 absolute top-0 w-full">
      <div className="flex-row-el flex-center relative z-50 gap-1">
        <div className="flex-row-el">
          <IconCalendar />
        </div>
        <div className="flex-row-el items-baseline">
          <p
            className="text-body text-white text-[32px] text-body-ellipsis px-1"
            style={{
              WebkitTextStroke: "6px #3F4A61",
            }}
          >
            <span
              className="inline-block text-body-ellipsis"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {period}
            </span>
            {period}
          </p>

          <p
            className="text-body text-white text-[20px] text-body-ellipsis px-1"
            style={{
              WebkitTextStroke: "6px #3F4A61",
            }}
          >
            <span
              className="inline-block text-body-ellipsis"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {I18n.t("MSG_USER_PROFILE_PROGRESS_COURSE_CHART_DATE_TITLE")}
            </span>
            {I18n.t("MSG_USER_PROFILE_PROGRESS_COURSE_CHART_DATE_TITLE")}
          </p>
        </div>
      </div>
      <div className="flex-row-el flex-center relative z-50 gap-1">
        <div className="flex-row-el">
          <IconCourseClear />
        </div>
        <div className="flex-row-el items-baseline">
          <p
            className="text-body text-white text-[32px] text-body-ellipsis px-1"
            style={{
              WebkitTextStroke: "6px #3F4A61",
            }}
          >
            <span
              className="inline-block text-body-ellipsis"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {finished}
            </span>
            {finished}
          </p>

          <p
            className="text-body text-white text-[20px] text-body-ellipsis px-1"
            style={{
              WebkitTextStroke: "6px #3F4A61",
            }}
          >
            <span
              className="inline-block text-body-ellipsis"
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >
              {I18n.t("MSG_SETTING_COURSE_ANSWER_LEVEL")}
            </span>
            {I18n.t("MSG_SETTING_COURSE_ANSWER_LEVEL")}
          </p>
        </div>
      </div>
    </div>
  );
};
