import I18n from "i18n-js";
import { Typography } from "@mui/material";
import { btnDelay } from "common/utils";

export const ParentsSignTop = ({
  navigate,
}: {
  navigate: (path: string) => void;
}) => {
  const handleGotoSign = (signIn: boolean) => () => {
    btnDelay(() => {
      if (signIn) {
        navigate("/parentsSignIn");
      } else {
        navigate("/parentsSignUp");
      }
    });
  };

  const handleGotoInstitutionSign = () => {
    btnDelay(() => {
      navigate("/institutionSign");
    });
  };

  return (
    <>
      <Typography variant="subtitle1">{I18n.t("MSG_SIGN_NOTICE")}</Typography>

      <div className="flex-col-center gap-4">
        <Typography variant="subtitle1">
          {I18n.t("MSG_SIGN_ALREADY_SIGN_IN")}
        </Typography>

        <div className="flex-col-el flex-center relative upsilon w-full">
          <button
            onClick={handleGotoSign(true)}
            className="btn btn-primary w-full"
          >
            <p className="text-body text-[24px] text-white !mb-[3px]">
              {I18n.t("MSG_SIGN_IN_BTN")}
            </p>
          </button>
        </div>
      </div>

      <div className="flex-col-center gap-4">
        <Typography variant="subtitle1">
          {I18n.t("MSG_SIGN_NOT_SIGN_IN")}
        </Typography>

        <div className="flex-col-el flex-center relative upsilon w-full">
          <button
            onClick={handleGotoSign(false)}
            className="btn btn-primary w-full"
          >
            <p className="text-body text-[24px] text-white !mb-[3px]">
              {I18n.t("MSG_SIGN_UP_BTN")}
            </p>
          </button>
        </div>
      </div>

      <div className="flex-col-center gap-4 w-full">
        <p className="text-body text-textcolor/black whitespace-pre">
          {I18n.t("MSG_SIGN_INSTITUTION_NOTICE")}
        </p>
        <div className="flex-col-el flex-center relative upsilon">
          <button
            onClick={handleGotoInstitutionSign}
            className="btn btn-primary w-full"
          >
            <p className="text-body text-[24px] text-white !mb-[3px]">
              {I18n.t("MSG_SIGN_IN_INSTITUTION_BTN")}
            </p>
          </button>
        </div>
      </div>
    </>
  );
};
