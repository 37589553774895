/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import anime from "animejs";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { RootState, AppDispatch } from "app/store";
import { actions as appActions } from "app/configSlice";
import Constants from "common/constant";
import { btnDelay, usePlaySound } from "common/utils";
import { DialogMessage, WarningMessageBox } from "common/elements";
import { IconTrash, IconRotate, IconSettings } from "common/assets";
import {
  deleteProject,
  publishProject,
  restoreProject,
  stopPublishProject,
} from "features/creator/api";
import {
  DisplayMode,
  ProjectType,
  KeyDirection,
  ProjectEntity,
  ProjectPanelType,
} from "features/creator/types";
import {
  IconDetail,
  IconAppName,
  IconPublish,
  IconPublishCopiedOn,
  IconPublishCopiedOff,
} from "features/creator/assets";
import {
  ProjectUser,
  ToggleSwitch,
  FavoritedMark,
  RestoreDialogMessage,
  CompleteDeleteMessageBox,
  PublishProjectDialogMessage,
} from "features/creator/elements";
import { AppUser } from "features/user/types";
import { ReactionPanel } from "features/reaction";
import { Reaction } from "features/reaction/types";
import { IconBasic } from "features/creator/property/components";
import { actions, selectAllProjects } from "features/creator/slice";

const ProjectActionHeader = ({ project }: { project?: ProjectEntity }) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const panelIndex = useSelector(
    (state: RootState) => state.creator.panelTabIndex
  );
  const handleFavoritePanelIndex = () => dispatch(actions.updatePanelIndex(1));
  const handleShowDetail = () => {
    play();
    btnDelay(() => {
      dispatch(actions.updatePanelIndex(2));
      dispatch(actions.updateDisplayMode(DisplayMode.DETAIL));
    });
  };
  return (
    <div className="flex-row-el items-center justify-between absolute top-0 left-0 w-full h-[84px] px-3 border-solid border-white border-0 border-b-[1px] bg-white/80 backdrop-blur-sm">
      <div className="flex-col-center">
        <div className="flex-row-center">
          <div className="flex-col-center w-[40px] h-[40px]">
            <IconAppName />
          </div>
          <p className="text-body text-body-ellipsis text-textcolor/black text-[28px] w-[400px] leading-[28px]">
            {project?.name}
          </p>
        </div>

        <div className="flex-row-el items-center justify-between w-full">
          <div className="flex-row-center">
            <div className="flex-col-center w-[32px] h-[32px]">
              <IconBasic />
            </div>

            <div className="flex-row-center w-[322px]">
              <p className="text-body text-body-ellipsis text-textcolor/black text-[20px] w-[322px]">
                {project?.description}
              </p>
            </div>
          </div>

          <div id="creator-display-favorited-mark" className="flex-col-center">
            {panelIndex === 1 && (
              <div className="flex-col-el absolute pointer-events-none w-[64px] h-[40px] !box-content z-50 border-solid border-green/100/40 border-[16px] rounded-2xl" />
            )}
            {project && (
              <FavoritedMark
                project={project}
                handlePanelIndex={
                  !project.deleted_at && handleFavoritePanelIndex
                }
              />
            )}
          </div>
        </div>
      </div>

      <ProjectUser name={project?.author_name} icon={project?.author_icon} />

      <div className="flex-row-center upsilon">
        {panelIndex === 2 && (
          <div className="flex-col-el absolute pointer-events-none w-[78px] h-[63px] !box-content z-50 border-solid border-green/100/40 border-[16px] rounded-2xl" />
        )}
        <button
          id="creator-display-detail-btn"
          onClick={handleShowDetail}
          className="btn btn-blue-outlined !rounded-lg !px-3 !m-0"
        >
          <div className="flex-col-el flex-center">
            <div className="flex-col-center">
              <IconDetail />
            </div>
            <p className="text-body text-green/100">
              {I18n.t("MSG_CREATOR_APP_DETAIL_BTN")}
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

const ProjectPublish = ({ project }: { project: ProjectEntity }) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const [warning, setWarning] = useState(false);
  const [publish, setPublish] = useState(false);
  const panelIndex = useSelector(
    (state: RootState) => state.creator.panelTabIndex
  );
  const project_publish = useSelector(
    (state: RootState) => state.config.userConfig.project_publish
  );
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleOpenPublish = () => {
    play();
    dispatch(actions.updatePanelIndex(3));
    btnDelay(() => {
      if (project_publish || project.published) {
        setPublish(true);
      } else {
        setWarning(true);
      }
    });
  };
  const handlePublish = async (enable_copied?: boolean) => {
    if (project.published) {
      await dispatch(
        stopPublishProject({
          uid: user.active.uid,
          project_id: project.project_id,
        })
      ).unwrap();
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_DISABLE_PUBLISHED_SUCCESS")}
          </p>
        </div>
      );
    } else {
      await dispatch(
        publishProject({
          uid: user.active.uid,
          project_id: project.project_id,
          enable_copied,
        })
      ).unwrap();
      toast.success(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_TOAST_PUBLISHED_SUCCESS")}
          </p>
        </div>
      );
    }
    setPublish(false);
  };
  const handlePublishClose = () => {
    play();
    btnDelay(() => setPublish(false));
  };

  const handleCloseWarning = () => {
    play();
    btnDelay(() => setWarning(false));
  };

  return (
    <>
      {warning && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t("MSG_CREATOR_APP_PUBLISH_DISABLED_TITLE")}
          message={I18n.t("MSG_CREATOR_APP_PUBLISH_DISABLED_MESSAGE")}
          close={handleCloseWarning}
        />
      )}

      <PublishProjectDialogMessage
        name={project?.name}
        visible={publish}
        published={project?.published}
        enableCopied={project?.enable_copied}
        publish={handlePublish}
        close={handlePublishClose}
      />

      <div className="flex-col-el flex-center h-full absolute left-4 pt-1">
        <p className="text-body text-textcolor/black text-[18px] bg-white px-4 rounded-full leading-[22px]">
          {I18n.t("MSG_CREATOR_APP_SETTING_PUBLISH_TITLE")}
        </p>

        <div className="flex-row-center gap-1">
          <div className="flex-col-center w-[60px] h-[64px]">
            <img
              alt="publish btn"
              src={
                project?.published
                  ? `${Constants.assetHost}/assets/images/publish_off.png`
                  : `${Constants.assetHost}/assets/images/publish_on.png`
              }
              className="w-[48px] h-[48px]"
            />
          </div>

          <div className="flex-col-el flex-center w-[72px] -mt-2">
            {panelIndex === 3 && (
              <div className="flex-col-el absolute pointer-events-none w-[86px] h-[46px] !box-content z-50 border-solid border-green/100/40 border-[16px] rounded-full" />
            )}

            <ToggleSwitch
              id="creator-display-publish-btn"
              checked={project?.published}
              onChange={handleOpenPublish}
            />
          </div>

          <div className="flex-col-center w-[80px] h-[64px]">
            <div className="flex-col-center w-[60px] h-[64px]">
              {project?.published ? (
                project?.enable_copied ? (
                  <IconPublishCopiedOn />
                ) : (
                  <IconPublishCopiedOff />
                )
              ) : (
                <IconPublish />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/* heightは100%すると、chromeでクリックすると上に跳ね上がるというバグが発生する */
const ProjectRunPreviewBtn = ({
  project_id,
  isUserProject,
}: {
  project_id: string;
  isUserProject: boolean;
}) => {
  const play = usePlaySound();
  const dispatch = useDispatch<AppDispatch>();
  const panelIndex = useSelector(
    (state: RootState) => state.creator.panelTabIndex
  );

  const handleRunProject = (isUserProject: boolean) => () => {
    play();
    dispatch(actions.updatePanelIndex(4));
    btnDelay(() =>
      dispatch(
        appActions.updateDialog({
          type: DialogType.PROJECT_PREVIEW,
          value: true,
          args: {
            projectId: project_id,
            isUserProject: String(isUserProject),
          },
        })
      )
    );
  };

  return (
    <div
      id="creator-display-run-preview"
      className="flex-col-el flex-center absolute bottom-0 ml-4"
    >
      {panelIndex === 4 && (
        <div className="flex-col-el absolute pointer-events-none w-[178px] h-[178px] z-50 mt-10 border-solid border-green/100/40 border-[16px] border-b-0 rounded-full" />
      )}

      <button
        id="creator-display-run-preview-btn"
        className="opacity-button w-[152px] h-[109px]"
        onClick={handleRunProject(isUserProject)}
      >
        <div className="flex-col-center w-full h-full">
          <div className="flex-col-el flex-center w-full h-full">
            <div className="flex-col-center w-[152px] h-[109px]">
              {/* svgはsafariで表示されない */}
              <img
                alt="preview run btn"
                src={`${Constants.assetHost}/assets/images/creator_preview_run_btn.png`}
                className="w-ful h-full"
              />
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

const ProjectGotoEditBtn = ({
  project_id,
  type,
  isUserProject,
}: {
  project_id: string;
  type: ProjectType;
  isUserProject: boolean;
}) => {
  const play = usePlaySound();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const panelIndex = useSelector(
    (state: RootState) => state.creator.panelTabIndex
  );

  const handleEditorProject = () => {
    play();
    dispatch(actions.updatePanelIndex(5));
    btnDelay(() => navigate(`${project_id}?type=${type}`));
  };

  return (
    <div className="flex-row-el flex-center h-full absolute right-6 upsilon">
      {panelIndex === 5 && (
        <div className="flex-col-el absolute pointer-events-none w-[212px] h-[64px] !box-content z-50 border-solid border-green/100/40 border-[16px] rounded-full" />
      )}

      <button
        id="creator-display-edit-btn"
        className="btn btn-primary !rounded-full !px-8 !py-1 !m-0"
        onClick={handleEditorProject}
        disabled={!isUserProject}
      >
        <div className="flex-row-el flex-center relative gap-2">
          <div className="flex-col-el flex-center relative w-[56px] h-[56px]">
            <IconSettings color="white" width={2} />
          </div>
          <p className="text-body text-white text-[28px]">
            {I18n.t("MSG_CREATOR_APP_EDITOR_BTN")}
          </p>
        </div>
      </button>
    </div>
  );
};

const ProjectDeleteBtn = ({
  user,
  project,
}: {
  user: AppUser;
  project: ProjectEntity;
}) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const panelIndex = useSelector(
    (state: RootState) => state.creator.panelTabIndex
  );

  const handleOpenDeleteProject = () => {
    play();
    btnDelay(() => {
      setShow(true);
    });
  };

  const handleDeleteProject = () => {
    play();
    setShow(false);
    handleDialog({ type: DialogType.LOADING, value: true });
    btnDelay(() => {
      btnDelay(() => {
        anime({
          targets: document.getElementById(
            `user-app-project-container-${project.project_id}`
          ),
          scale: 0.8,
          opacity: 0,
          duration: 400,
          easing: "easeInOutQuad",
        }).finished.then(() => {
          dispatch(actions.updateDisplayMode(DisplayMode.NONE));
          dispatch(
            deleteProject({
              uid: user.uid,
              project_id: project.project_id,
            })
          )
            .then(unwrapResult)
            .then(() =>
              toast.success(
                <div>
                  <p className="text text-textcolor/black">
                    {I18n.t("MSG_TOAST_DELETED_SUCCESS")}
                  </p>
                </div>
              )
            )
            .catch((error) => {
              console.error(error);
              anime({
                targets: document.getElementById(
                  `user-app-project-container-${project.project_id}`
                ),
                scale: 1,
                opacity: 1,
                duration: 400,
                easing: "easeInOutQuad",
              });
              anime({
                targets: document.getElementsByClassName(
                  `app-project-card-${project.project_id}`
                ),
                opacity: [0, 1],
                duration: 800,
                easing: "easeInOutQuad",
              });
              toast.success(
                <div>
                  <p className="text text-textcolor/black">
                    {I18n.t("MSG_TOAST_FAILED")}
                  </p>
                </div>
              );
            })
            .finally(() =>
              handleDialog({ type: DialogType.LOADING, value: false })
            );
        });
      });
    });
  };

  const handleCloseDeleteProject = () => {
    play();
    btnDelay(() => {
      setShow(false);
    });
  };

  return (
    <div className="flex-row-el flex-center h-full absolute left-10 upsilon">
      <CompleteDeleteMessageBox
        visible={show}
        name={project.name}
        confirm={handleDeleteProject}
        close={handleCloseDeleteProject}
      />

      {panelIndex === 3 && (
        <div className="flex-col-el absolute pointer-events-none w-[212px] h-[54px] !box-content z-50 border-solid border-green/100/40 border-[16px] rounded-3xl" />
      )}

      <button
        id="creator-display-complete-delete-btn"
        className="btn btn-danger !px-3 !py-1 !m-0 !h-[54px]"
        onClick={handleOpenDeleteProject}
      >
        <div className="flex-row-el flex-center relative gap-2">
          <div className="flex-col-el flex-center relative w-[40px] h-[40px]">
            <IconTrash color="white" />
          </div>
          <p className="text-body text-white text-font-size-body2">
            {I18n.t("MSG_CREATOR_APP_COMPLETELY_BTN")}
          </p>
        </div>
      </button>
    </div>
  );
};

const ProjectRestoreBtn = ({ project }: { project: ProjectEntity }) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const dispatch = useDispatch<AppDispatch>();
  const [restore, setRestore] = useState(false);
  const projects = useSelector(selectAllProjects);
  const [guidance, setGuidance] = useState(false);
  const panelIndex = useSelector(
    (state: RootState) => state.creator.panelTabIndex
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = useMemo(
    () =>
      projects.filter(
        (project) =>
          project.author_uid === user.active.uid &&
          !project.deleted_at &&
          project.type === ProjectType.BASIC
      ),
    [projects, user]
  );

  const handleOpenRestore = () => {
    play();
    btnDelay(() => {
      if (userProjects.length > 4) {
        setGuidance(true);
      } else {
        setRestore(true);
      }
    });
  };

  const handleRestore = () => {
    play();
    setRestore(false);
    handleDialog({ type: DialogType.LOADING, value: true });
    btnDelay(() => {
      anime({
        targets: document.getElementById(
          `user-app-project-container-${project.project_id}`
        ),
        scale: 0.8,
        opacity: 0,
        duration: 400,
        easing: "easeInOutQuad",
      }).finished.then(() => {
        dispatch(actions.updateDisplayMode(DisplayMode.NONE));
        dispatch(
          restoreProject({
            uid: user.active.uid,
            project_id: project.project_id,
          })
        )
          .then(unwrapResult)
          .then(() => {
            dispatch(actions.updateProjectPanel(ProjectPanelType.USER));
            setTimeout(() => {
              anime({
                targets: document.getElementById(
                  `user-app-project-container-${project.project_id}`
                ),
                scale: [1.3, 1],
                opacity: [0, 1],
                duration: 800,
                easing: "easeInOutQuad",
              });
            }, 700);
            toast.success(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_TOAST_RESTORE_SUCCESS")}
                </p>
              </div>
            );
          })
          .catch((error) => {
            console.error(error);
            anime({
              targets: document.getElementById(
                `user-app-project-container-${project.project_id}`
              ),
              scale: 1,
              opacity: 1,
              duration: 400,
              easing: "easeInOutQuad",
            });
            anime({
              targets: document.getElementsByClassName(
                `app-project-card-${project.project_id}`
              ),
              opacity: [0, 1],
              duration: 800,
              easing: "easeInOutQuad",
            });
            toast.success(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_TOAST_FAILED")}
                </p>
              </div>
            );
          })
          .finally(() =>
            handleDialog({ type: DialogType.LOADING, value: false })
          );
      });
    });
  };

  const handleCloseRestore = () => {
    play();
    setRestore(false);
  };

  const handleCloseGuidance = () => {
    play();
    btnDelay(() => setGuidance(false));
  };

  return (
    <div className="flex-row-el flex-center h-full absolute right-10 upsilon">
      <RestoreDialogMessage
        name={project.name}
        visible={restore}
        confirm={handleRestore}
        close={handleCloseRestore}
      />

      <DialogMessage
        open={guidance}
        title={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_APP_LIMIT_WARNING_MESSAGE")}
        close={handleCloseGuidance}
      />

      {panelIndex === 5 && (
        <div className="flex-col-el absolute pointer-events-none w-[192px] h-[54px] !box-content z-50 border-solid border-green/100/40 border-[16px] rounded-3xl" />
      )}

      <button
        id="creator-display-restore-btn"
        className="btn btn-primary !px-3 !py-1 !m-0 !h-[54px]"
        onClick={handleOpenRestore}
      >
        <div className="flex-row-el flex-center relative gap-2">
          <div className="flex-col-el flex-center relative w-[40px] h-[40px]">
            <IconRotate color="white" width={2} />
          </div>
          <p className="text-body text-white text-font-size-body2">
            {I18n.t("MSG_CREATOR_APP_RESTORE_BTN")}
          </p>
        </div>
      </button>
    </div>
  );
};

const usePanelIndex = ({
  isUserProject,
  deleted,
}: {
  isUserProject: boolean;
  deleted: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const panelIndex = useSelector(
    (state: RootState) => state.creator.panelTabIndex
  );
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );
  const cursor = useSelector((state: RootState) => state.creator.cursor);
  const panelAction = useSelector((state: RootState) => state.creator.action);

  useEffect(() => {
    dispatch(actions.updatePanelIndex(0));
  }, [selectedProjectId]);

  useEffect(() => {
    if (displayMode === DisplayMode.CAPTURE && panelAction.back) {
      anime({
        targets: document.getElementsByClassName(
          `app-project-card-${selectedProjectId}`
        ),
        opacity: [0, 1],
        duration: 800,
        easing: "easeInOutQuad",
      });

      dispatch(actions.updateDisplayMode(DisplayMode.NONE));
      dispatch(
        actions.updateProjectPanelAction({
          ...panelAction,
          back: false,
        })
      );
    }
  }, [panelAction.back]);

  useEffect(() => {
    if (
      displayMode === DisplayMode.CAPTURE &&
      isUserProject &&
      panelAction.confirm
    ) {
      dispatch(
        actions.updateProjectPanelAction({
          ...panelAction,
          confirm: false,
        })
      );
      switch (panelIndex) {
        case 1:
          document.getElementById("creator-display-favorited-mark-btn").click();
          return;
        case 2:
          document.getElementById("creator-display-detail-btn").click();
          return;
        case 3:
          if (deleted) {
            document
              .getElementById("creator-display-complete-delete-btn")
              .click();
          } else {
            document.getElementById("creator-display-publish-btn").click();
          }
          return;
        case 4:
          document.getElementById("creator-display-run-preview-btn").click();
          return;
        case 5:
          if (deleted) {
            document.getElementById("creator-display-restore-btn").click();
          } else {
            document.getElementById("creator-display-edit-btn").click();
          }
          return;
        default:
          return;
      }
    }
  }, [panelAction.confirm]);

  useEffect(() => {
    if (displayMode === DisplayMode.CAPTURE) {
      dispatch(actions.updateProjectPanelCursor(KeyDirection.NONE));
      switch (cursor) {
        case KeyDirection.UP:
          if (deleted) {
            dispatch(actions.updatePanelIndex(2));
          } else {
            if (panelIndex > 2) {
              dispatch(actions.updatePanelIndex(1));
            } else if (panelIndex === 0) {
              dispatch(actions.updatePanelIndex(1));
            }
          }
          break;
        case KeyDirection.RIGHT:
          if (panelIndex === 5 || panelIndex === 0) {
            if (deleted) {
              dispatch(actions.updatePanelIndex(2));
            } else {
              dispatch(actions.updatePanelIndex(1));
            }
          } else {
            if (isUserProject) {
              dispatch(actions.updatePanelIndex(panelIndex + 1));
            } else {
              if (panelIndex === 2) {
                dispatch(actions.updatePanelIndex(4));
              } else if (panelIndex === 4) {
                dispatch(actions.updatePanelIndex(1));
              } else {
                dispatch(actions.updatePanelIndex(panelIndex + 1));
              }
            }
          }
          break;
        case KeyDirection.LEFT:
          if (isUserProject) {
            if (
              panelIndex === 1 ||
              panelIndex === 0 ||
              (deleted && panelIndex === 2)
            ) {
              dispatch(actions.updatePanelIndex(5));
            } else {
              dispatch(actions.updatePanelIndex(panelIndex - 1));
            }
          } else {
            if (panelIndex === 1 || panelIndex === 0) {
              dispatch(actions.updatePanelIndex(4));
            } else if (panelIndex === 4) {
              dispatch(actions.updatePanelIndex(2));
            } else {
              dispatch(actions.updatePanelIndex(panelIndex - 1));
            }
          }
          break;
        case KeyDirection.DOWN:
          if (isUserProject) {
            if (panelIndex < 3) {
              dispatch(actions.updatePanelIndex(3));
            }
          } else {
            if (panelIndex < 3) {
              dispatch(actions.updatePanelIndex(4));
            }
          }
          break;
        default:
          break;
      }
    }
  }, [cursor]);
};

export const ProjectActionPanel = ({
  isUserProject,
  project,
}: {
  isUserProject: boolean;
  project: ProjectEntity;
}) => {
  const dispatch = useDispatch();
  const preview = useSelector(
    (state: RootState) => state.config.dialog.project_preview
  );
  usePanelIndex({ isUserProject, deleted: !!project?.deleted_at });
  const user = useSelector((state: RootState) => state.user.appUser);

  const incrProjectReaction = (type: Reaction, number: number) => {
    dispatch(
      actions.updateProjectReaction({
        project_id: project.project_id,
        reaction: {
          ...project.reaction,
          [type]: project.reaction[type] + number,
        },
      })
    );
  };

  return (
    <div className="flex-col-center w-full h-full bg-white">
      {false && preview.value && (
        <ReactionPanel
          project={project}
          incrProjectReaction={incrProjectReaction}
        />
      )}

      <ProjectActionHeader project={project} />

      {(project?.cover || project?.capture) && (
        <img
          key={project?.project_id}
          alt="アプリのキャプチャー"
          className="w-full h-full object-cover"
          src={project?.cover ?? project?.capture}
          onError={(e) =>
            // @ts-ignore
            (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
          }
        />
      )}

      <div
        className="flex-row-el flex-center absolute bottom-0 left-0 w-full h-[88px] z-50 bg-gray/10"
        style={{ boxShadow: "0px -1px 4px rgba(63, 74, 97, 0.15)" }}
      >
        {project?.deleted_at ? (
          <ProjectDeleteBtn user={user.active} project={project} />
        ) : (
          isUserProject && <ProjectPublish project={project} />
        )}

        <ProjectRunPreviewBtn
          project_id={project?.project_id}
          isUserProject={isUserProject}
        />

        {project?.deleted_at ? (
          <ProjectRestoreBtn project={project} />
        ) : (
          isUserProject && (
            <ProjectGotoEditBtn
              project_id={project?.project_id}
              type={project?.type}
              isUserProject={isUserProject}
            />
          )
        )}
      </div>
    </div>
  );
};
