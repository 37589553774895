import { useRef, useState, useEffect } from "react";
import { useScale } from "common/utils";
import { CloseBtnOuter } from "common/elements";
import { GuideMessageBalloonSvg } from "features/courses/assets";
import { Button } from "features/courses/nishishinjuku/game/tips/Button";
import { StepProps } from "./type";

const Step = (props: StepProps) => {
  const { scale } = useScale();
  const ref = useRef<HTMLDivElement>();
  const { message, handleMove, close } = props;
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = message;
      const GameCommandPanel = document.getElementById("game-command-panel");
      const GameCommandPanelRect = GameCommandPanel.getBoundingClientRect();

      setOffset({
        x: GameCommandPanelRect.x + GameCommandPanelRect.width + 200 * scale,
        y: 300 * scale,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <div
      className="flex-col-view !absolute top-0 left-0"
      style={{
        opacity: offset.x * offset.y === 0 ? 0 : 1,
        width: 500 * scale,
        height: 300 * scale,
        transform: `translate(${offset.x}px, ${offset.y}px)`,
      }}
    >
      <div
        className="flex-col-view items-center origin-top-left w-[500px] h-[300px]"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div className="flex-col-el absolute top-4 right-4">
          <CloseBtnOuter close={close} />
        </div>

        <div className="flex-col-view !absolute top-0 left-0 w-[500px] h-[300px]">
          <GuideMessageBalloonSvg />
        </div>

        <div className="flex-col-center top-[45px] w-[500px] h-[158px]">
          <p
            ref={ref}
            className="text !leading-[30px] text-textcolor/black"
          ></p>
        </div>
        <Button handleMove={handleMove} />
      </div>
    </div>
  );
};

export default Step;
