import i18n from "i18n-js";
import Constants from "common/constant";

export const LevelClearStamp = (props: { scale: number; date: string }) => {
  const { scale, date } = props;
  return (
    <div
      className="clear-stamp-animation !absolute pointer-events-none"
      style={{
        right: 50 * scale,
        top: 116 * scale,
      }}
    >
      <div
        className="flex-col-view w-[245px] h-[245px] object-contain origin-top-right"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <img
          alt="レベルクリアスタンプ"
          src={`${Constants.assetHost}/assets/images/stamp_cleared.png`}
          className="w-full h-full"
        />
        <div className="flex-col-center !absolute left-[80px] bottom-[55px]">
          <p
            className="text text-brown/80 !text-[16px] !leading-[18px]"
            style={{
              WebkitTextStroke: "6px #FEFDFE",
            }}
          >
            <span
              style={{
                WebkitTextStroke: "0",
                position: "absolute",
              }}
            >{`${date}\n${i18n.t("MSG_COURSE_GAME_CLEAR")}`}</span>
            {`${date}\n${i18n.t("MSG_COURSE_GAME_CLEAR")}`}
          </p>
        </div>
      </div>
    </div>
  );
};
