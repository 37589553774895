/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState } from "react";
import { TouchableOpacity } from "react-native";
import I18n from "i18n-js";
import { PromptInputNumber } from "common/elements";
import {
  AnimationContainer,
  IconVerticalArrowPlus,
  IconVerticalArrowMinus,
} from ".";

export const InputHeight = (props: {
  name: string;
  min: number;
  max: number;
  value: number;
  play: () => void;
  onChange: (value: number) => void;
}) => {
  const ref = useRef(null);
  const [prompt, setPrompt] = useState(false);
  const { name, min, max, value, play, onChange } = props;

  const handlePlusLongPress = () => {
    if (ref.current) {
      ref.current.handlePlusLongPress();
    }
  };

  const handleMinusLongPress = () => {
    if (ref.current) {
      ref.current.handleMinusLongPress();
    }
  };

  const handleLongPressOut = () => {
    if (ref.current) {
      ref.current.handleLongPressOut();
    }
  };

  const handlePlus = (step: number) => {
    if (ref.current) {
      ref.current.handlePlus(step);
    }
  };

  const handleMinus = (step: number) => {
    if (ref.current) {
      ref.current.handleMinus(step);
    }
  };

  const handleShowPromptInput = () => {
    play();
    setPrompt(true);
  };

  const handleInputValueChange = (inputValue: number) => {
    play();
    if (inputValue < min) {
      onChange(0);
    } else if (inputValue > max) {
      onChange(max);
    } else {
      onChange(inputValue);
    }
    setPrompt(false);
  };

  const handleCloseInput = () => {
    play();
    setPrompt(false);
  };

  return (
    <>
      {prompt && (
        <PromptInputNumber
          defaultValue={Math.floor(value)}
          message={I18n.t("MSG_CREATOR_MENU_BLOCKLY_PROMPT_INPUT_MESSAGE", {
            placeholder: name,
          })}
          confirm={handleInputValueChange}
          cancel={handleCloseInput}
        />
      )}

      <div className="flex-col-center">
        <TouchableOpacity
          style={{
            width: 45,
            height: 48,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => handlePlus(1)}
          onLongPress={handlePlusLongPress}
          onPressOut={handleLongPressOut}
        >
          <div className="flex-col-center w-[45px] h-[48px]">
            <IconVerticalArrowPlus />
          </div>
        </TouchableOpacity>

        <div className="flex-row-center">
          <AnimationContainer
            ref={ref}
            value={value}
            min={min}
            max={max}
            onChange={onChange}
          >
            <div className="flex-col-center w-[24px] h-[104px]">
              <div className="flex-col-center w-[24px] h-[4px] bg-green/60" />
              <div className="flex-col-center w-[4px] h-[96px] bg-green/60" />
              <div className="flex-col-center w-[24px] h-[4px] bg-green/60" />
            </div>
          </AnimationContainer>

          <div className="flex-row-view items-center w-[120px] ml-[8px]">
            <input
              type="text"
              className="property-input-value_input"
              readOnly
              value={Math.round(value)}
              onClick={handleShowPromptInput}
            />
            <p className="text text-textcolor/black !text-[16px]">px</p>
          </div>
        </div>

        <TouchableOpacity
          style={{
            width: 45,
            height: 48,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => handleMinus(1)}
          onLongPress={handleMinusLongPress}
          onPressOut={handleLongPressOut}
        >
          <div className="flex-col-center w-[45px] h-[48px]">
            <IconVerticalArrowMinus />
          </div>
        </TouchableOpacity>
      </div>
    </>
  );
};
