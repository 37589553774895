import { SVGProps } from "react";

export const NewMarkSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 58 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={58} height={24} rx={8} fill="#FC6560" />
    <path
      d="M11.427 20c-.307 0-.574-.113-.8-.34a1.129 1.129 0 0 1-.32-.8V6.54a1.129 1.129 0 0 1 1.12-1.14h.44c.76 0 1.32.333 1.68 1l4.94 8.92c0 .013.006.02.02.02.013 0 .02-.007.02-.02V6.54c0-.307.113-.573.34-.8.226-.227.493-.34.8-.34h.44c.306 0 .566.113.78.34.226.227.34.493.34.8v12.32c0 .307-.114.573-.34.8a1.03 1.03 0 0 1-.78.34h-.44c-.76 0-1.32-.333-1.68-1l-4.94-8.92c0-.013-.007-.02-.02-.02-.014 0-.02.007-.02.02v8.78c0 .307-.114.573-.34.8-.227.227-.494.34-.8.34h-.44Zm17.002-8.74c-.68 0-1.207.193-1.58.58-.36.373-.587.98-.68 1.82 0 .107.053.16.16.16h3.92c.106 0 .16-.053.16-.16-.054-1.6-.714-2.4-1.98-2.4Zm.5 8.94c-1.747 0-3.094-.453-4.04-1.36-.934-.92-1.4-2.267-1.4-4.04 0-1.76.42-3.1 1.26-4.02.84-.92 2.053-1.38 3.64-1.38 2.96 0 4.48 1.68 4.56 5.04.013.32-.1.587-.34.8-.24.213-.52.32-.84.32h-5.46c-.12 0-.167.06-.14.18.093.893.386 1.547.88 1.96.506.4 1.24.6 2.2.6.6 0 1.266-.1 2-.3a.732.732 0 0 1 .66.12c.213.147.32.347.32.6a1.3 1.3 0 0 1-.28.82 1.095 1.095 0 0 1-.7.42c-.747.16-1.52.24-2.32.24Zm7.502-1.3-2-8.2a.864.864 0 0 1 .16-.76.87.87 0 0 1 .7-.34h.5c.334 0 .627.107.88.32.254.213.407.48.46.8l1.24 7.06c0 .013.007.02.02.02.027 0 .04-.007.04-.02l1.26-7.06a1.39 1.39 0 0 1 .48-.8 1.3 1.3 0 0 1 .86-.32h1c.334 0 .627.107.88.32.254.213.407.48.46.8l1.26 7.06c0 .013.014.02.04.02.014 0 .02-.007.02-.02l1.24-7.06a1.39 1.39 0 0 1 .48-.8 1.3 1.3 0 0 1 .86-.32h.42c.294 0 .527.113.7.34a.79.79 0 0 1 .16.76l-2 8.2c-.08.32-.253.587-.52.8-.253.2-.546.3-.88.3h-1a1.3 1.3 0 0 1-.86-.32 1.39 1.39 0 0 1-.48-.8l-1.3-6.86c0-.013-.006-.02-.02-.02-.013 0-.02.007-.02.02l-1.3 6.86c-.053.32-.206.587-.46.8-.253.213-.546.32-.88.32h-1c-.32 0-.613-.1-.88-.3a1.454 1.454 0 0 1-.52-.8Z"
      fill="#FEFDFE"
    />
  </svg>
);
