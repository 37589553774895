import { fetchAuthToken } from "app/Auth";
import { client } from "common/apiClient";

export const sendContact = async ({
  uid,
  input,
  contact,
}: {
  uid: string;
  input?: boolean;
  contact: {
    type: string;
    detail: string;
    url: string;
  };
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).post(
    `/v1/contacts/${uid}?input=${input ?? false}`,
    contact
  );
};
