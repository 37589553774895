import { useScale } from "common/utils";

export const GameSceneTitle = (props: { level: number; title: string }) => {
  const HEIGHT = 80;
  const { level, title } = props;
  const { width, scale } = useScale();

  return (
    <div
      className="flex-row-el flex-center absolute top-0"
      style={{
        left: 440 * scale,
        height: HEIGHT * scale,
        width: width - (440 + 156) * scale,
      }}
    >
      <div
        className="flex-row-center"
        style={{
          height: HEIGHT,
          marginTop: 32 * scale,
          transform: `scale(${scale})`,
        }}
      >
        <div
          className="flex-col-center z-[1] bg-danger mr-[10px] rounded-[50px]"
          style={{
            width: HEIGHT,
            height: HEIGHT,
          }}
        >
          <p className="title-text text-white !text-[13px] !tracking-[.2em]">
            Level
          </p>
          <p className="title-text text-white !text-[45px] !leading-[40px] ">
            {level}
          </p>
        </div>

        <div className="flex-row-view items-center justify-center h-[68px] bg-[#2766ae] pl-[55px] pr-[15px] ml-[-55px] rounded-r-[10px] ">
          <p className="title-text text-white !text-[36px] !tracking-[.2em]">
            {title}
          </p>
        </div>
      </div>
    </div>
  );
};
