import { SVGProps } from "react";

export const IconRankingBar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={85}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 7h24v71L0 72V7Z" fill="#B7714D" />
    <path d="M8 9h16v76H8V9Z" fill="#FEFDFE" />
    <path
      d="M0 1h22a2 2 0 0 1 2 2v66.674a2 2 0 0 1-1.97 2L0 72V1Z"
      fill="#FFA03B"
    />
    <path d="M0 .997h4v70.61l-4 .39v-71Z" fill="url(#a)" />
    <defs>
      <linearGradient
        id="a"
        x1={0}
        y1={34.997}
        x2={4}
        y2={34.997}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D7750D" />
        <stop offset={0.792} stopColor="#FFA03B" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
