import * as React from "react";

export const MaterialSwitchSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={54}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.07 2h19.85a14.93 14.93 0 0 1 14.87 15 14.94 14.94 0 0 1-14.87 15H17.07A14.93 14.93 0 0 1 2.21 17 14.92 14.92 0 0 1 17.07 2Z"
        fill="#FEFDFE"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.08 26.4c5.13 0 9.29-4.195 9.29-9.37 0-5.175-4.16-9.37-9.29-9.37-5.13 0-9.29 4.195-9.29 9.37 0 5.175 4.16 9.37 9.29 9.37Z"
        fill="#F8F1E8"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeMiterlimit={10}
      />
    </svg>
  );
};
