import Constants from "common/constant";
import Blockly from "blockly";

const theme = Blockly.Theme.defineTheme("inexus-course-program", {
  base: Blockly.Themes.Zelos,
  fontStyle: { family: "MPLUSRounded1c-Bold", weight: "normal", size: 12 },
  startHats: true,
});

export const CreatorBlocklyOptions = (
  scale: number,
  maxInstances: { [type: string]: number },
  toolbox: Blockly.utils.toolbox.ToolboxDefinition
): Blockly.BlocklyOptions => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: true,
  horizontalLayout: false,
  maxInstances: JSON.parse(JSON.stringify(maxInstances)),
  media: `${Constants.assetHost}/assets/blockly/creator/`,
  move: {
    scrollbars: false,
    drag: true,
    wheel: false,
  },
  zoom: {
    startScale: scale,
  },
  oneBasedIndex: true,
  readOnly: false,
  rtl: false,
  toolbox: toolbox,
  toolboxPosition: "start",
  trashcan: true,
  maxTrashcanContents: 32,
  renderer: "zelos",
  theme: theme,
});
