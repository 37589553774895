import { SVGProps } from "react";

export const ProgressStep4 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={88} height={88} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.862.006c13.654.185 27.09 5.167 35.661 16.154 8.624 11.055 11.663 26.014 7.882 39.667-3.562 12.86-14.503 21.235-26.283 26.772-11.386 5.352-24.26 7.777-35.597 2.313-11.494-5.54-18.322-17.276-21.678-29.918-3.586-13.51-4.982-28.618 3.166-39.814C14.375 3.69 28.953-.181 42.863.006Z"
      fill={props.fill}
    />
  </svg>
);
