import { useScale } from "common/utils";
import Constants from "common/constant";
import { MainTitleTag } from "features/courses/assets";
import {
  StageLevelRect,
  StageDescriptionRect,
} from "features/courses/nishishinjuku/level/StageLevelPaths";
import { Level } from "features/courses/nishishinjuku/level/Level";
import { CourseProgress, CourseMessageContent } from "features/courses/types";
import { LevelAnimation } from "features/courses/nishishinjuku/level/LevelAnimation";

export const Levels = (props: {
  scale: number;
  stage: number;
  display: CourseMessageContent;
  progresses: CourseProgress;
  handleLevelPress: (stepId: number) => void;
}) => {
  const { width } = useScale();
  const { scale, stage, display, progresses, handleLevelPress } = props;

  const TITLE_HEIGHT = 200;
  const LEVEL_RECT_CIRCLE_RADIUS = 96;
  const LEVEL_RECT_CIRCLE_STROKE = 20;

  const LEVEL_RECT_LEFTS = StageLevelRect.map((r) => r.left);
  const MIN_LEFT =
    Math.min(...LEVEL_RECT_LEFTS) -
    (282 / 2 - LEVEL_RECT_CIRCLE_RADIUS - LEVEL_RECT_CIRCLE_STROKE / 2);

  const MAX_LEFT =
    Math.max(...LEVEL_RECT_LEFTS) +
    282 / 2 +
    LEVEL_RECT_CIRCLE_RADIUS +
    LEVEL_RECT_CIRCLE_STROKE / 2;

  const offsetX =
    (width - (MAX_LEFT - MIN_LEFT) * scale) / 2 - MIN_LEFT * scale + 20 * scale;

  return (
    <>
      <div
        className="flex-row-center self-center"
        style={{
          width: 1000 * scale,
          height: TITLE_HEIGHT * scale,
        }}
      >
        <div
          className="flex-row-center"
          style={{
            width: 437 * scale,
            height: TITLE_HEIGHT * scale,
          }}
        >
          <div
            className="flex-col-center w-[437px] h-[199px] z-[10]"
            style={{
              transform: `scale(${scale})`,
            }}
          >
            <MainTitleTag />
            <p
              className={`title-text !text-[60px] text-textcolor/black !absolute rotate-[-10deg] ${
                stage === 4 ? "top-[22%] left-[12%]" : "top-[25%] left-[15%]"
              }`}
            >
              {display?.name}
            </p>
          </div>
        </div>
      </div>

      <div className="flex-col-el flex-1 flex-center w-full h-full">
        <div className="flex-col-view w-full" style={{ height: 800 * scale }}>
          {progresses.level.map((_, index) => (
            <Level
              key={index}
              stage={stage}
              offset={offsetX}
              scale={scale}
              radius={LEVEL_RECT_CIRCLE_RADIUS}
              step={index}
              display={display}
              progresses={progresses}
              handleLevelPress={handleLevelPress}
            />
          ))}
          <LevelAnimation
            offset={offsetX}
            scale={scale}
            stroke={LEVEL_RECT_CIRCLE_STROKE}
            radius={LEVEL_RECT_CIRCLE_RADIUS}
            stage={stage}
            progresses={progresses}
          />
          <div
            className="flex-row-center !absolute"
            style={{
              top: (StageDescriptionRect.top - TITLE_HEIGHT) * scale,
              left: offsetX + StageDescriptionRect.left * scale,
              width: 471 * scale,
              height: 232 * scale,
            }}
          >
            <div
              className="flex-col-center w-[471px] h-[232px] z-0"
              style={{
                transform: `scale(${scale})`,
              }}
            >
              <img
                alt="ステージ概要の背景"
                src={`${Constants.assetHost}/assets/images/stage_description_bg.png`}
                className="w-[471px] h-[232px] object-contain"
              />
              <div className="flex-col-center w-[346px] h-[60px] !absolute flex-wrap rotate-[-2deg]">
                <p className="text text-textcolor/black !text-left">
                  {display?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
