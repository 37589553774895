/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import CachedIcon from "@mui/icons-material/Cached";
import { useScale } from "common/utils";
import { actions } from "features/connection/slice";
import { PeerConnectionMessageType } from "features/connection/type";
import { useProcessing } from "./useProcessing";

export const Recognition = ({ close }: { close: () => void }) => {
  const dispatch = useDispatch();
  const { width, height } = useScale();
  const [start, setStart] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [cameraIds, setCameraIds] = useState([]);
  const [cameraIndex, setCameraIndex] = useState(0);
  const { handleObjectDetection } = useProcessing();
  const videoRef = useRef<HTMLVideoElement>(null);
  const streamCanvas = useRef<HTMLCanvasElement>(null);

  const handleOpenCamera = useCallback(
    async (
      deviceId: string,
      size: { width: number; height: number },
      success?: () => void,
      error?: (err: any) => void
    ) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            deviceId,
            aspectRatio:
              size.width > size.height
                ? size.width / size.height
                : size.height / size.width,
            width: { ideal: 1920 },
          },
        })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = function () {
            videoRef.current.play();
            videoRef.current.width = videoRef.current.videoWidth; // width, heightを設定しないとcap.read(src)で失敗する。
            videoRef.current.height = videoRef.current.videoHeight;
            if (success) {
              success();
            }
          };
        })
        .catch(error);
    },
    []
  );

  const handleInitCamera = useCallback(
    async (
      size: { width: number; height: number },
      success?: () => void,
      error?: (err: any) => void
    ) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        stream.getVideoTracks().forEach((track) => track.stop());
        const devices = await navigator.mediaDevices.enumerateDevices();
        const deviceIds = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => device.deviceId);
        setCameraIds(deviceIds);
        setCameraIndex(deviceIds.length - 1);
        handleOpenCamera(deviceIds[deviceIds.length - 1], size, success, error);
      } catch (err) {
        error(err);
      }
    },
    []
  );

  const handleLocalRecognize = useCallback(async () => {
    // @ts-ignore
    const cap = new cv.VideoCapture(videoRef.current);
    // @ts-ignore
    const cameraFrame = new cv.Mat(
      videoRef.current.height,
      videoRef.current.width,
      // @ts-ignore
      cv.CV_8UC4
    );
    cap.read(cameraFrame);

    const blocks = handleObjectDetection(cameraFrame);

    console.log(blocks);

    dispatch(
      actions.sendMessage({
        content: {
          type: PeerConnectionMessageType.TSUMIKI,
          data: blocks,
        },
      })
    );

    cameraFrame.delete();

    if (streamCanvas.current) {
      streamCanvas.current.width = videoRef.current.width;
      streamCanvas.current.height = videoRef.current.height;
      const context = streamCanvas.current.getContext("2d");
      context.clearRect(0, 0, videoRef.current.width, videoRef.current.height);
      for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i];
        context.strokeStyle = "#00FF00";
        context.lineWidth = 3;
        context.strokeRect(
          block.bbox[0],
          block.bbox[1],
          block.bbox[2],
          block.bbox[3]
        );
      }
    }
  }, []);

  useEffect(() => {
    let recognizeTimer: NodeJS.Timeout;
    if (start) {
      handleInitCamera(
        { width, height },
        () => {
          const processRecognize = async () => {
            handleLocalRecognize().then(
              () => (recognizeTimer = setTimeout(processRecognize, 10))
            );
          };

          processRecognize();
        },
        (error) => {
          console.log(error);
          setTimeout(() => {
            setStart(false);
          }, 500);
        }
      );
    }
    return () => {
      if (recognizeTimer) {
        clearTimeout(recognizeTimer);
      }
    };
  }, [start]);

  useEffect(() => {
    if (start && videoRef.current?.srcObject) {
      (videoRef.current.srcObject as MediaStream)
        .getVideoTracks()
        .forEach((track) => track.stop());
      videoRef.current.srcObject = null;
      setTimeout(
        () => handleOpenCamera(cameraIds[cameraIndex], { width, height }),
        200
      );
    }
  }, [width, height]);

  const handleCancel = () => {
    try {
      (videoRef.current.srcObject as MediaStream)
        .getVideoTracks()
        .forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    } catch (error) {
      console.log(error);
    }
    setStart(false);
    close();
  };

  const handleSwitchCamera = () => {
    if (clicked) return;
    setClicked(true);
    if (cameraIndex === cameraIds.length - 1) {
      setCameraIndex(0);
      handleOpenCamera(cameraIds[0], { width, height }, () => {
        setClicked(false);
      });
    } else {
      handleOpenCamera(cameraIds[cameraIndex + 1], { width, height }, () => {
        setClicked(false);
      });
      setCameraIndex(cameraIndex + 1);
    }
  };

  return (
    <div className="flex-col-el flex-center w-full h-full bg-[#000000]">
      <div
        className={`flex-col-el flex-center absolute ${
          width > height ? "h-full" : "w-full"
        }`}
        style={{
          aspectRatio: width / height,
        }}
      >
        <div
          id="camera-container"
          className="flex-row-el flex-center w-full h-full z-50"
        >
          <video muted playsInline ref={videoRef} className="w-full h-full" />

          <canvas
            ref={streamCanvas}
            className="flex-co-el flex-center absolute top-0 left-0 w-full h-full"
          />

          <div
            style={{ bottom: 60 }}
            className="flex-row-el w-full items-center justify-evenly z-50 absolute pointer-events-auto"
          >
            <div className="flex-row-center" style={{ width: 150, height: 50 }}>
              <div
                onClick={handleCancel}
                className="flex-row-center cursor-pointer active:opacity-70"
              >
                <p className="text text-white">キャンセル</p>
              </div>
            </div>

            <div
              className="flex-row-center z-50"
              style={{ width: 150, height: 50 }}
            >
              <div
                onClick={handleSwitchCamera}
                className="flex-row-center bg-textcolor/black/60 w-[50px] h-[50px] p-2 rounded-[40px] pointer-events-auto cursor-pointer active:opacity-70"
              >
                <div className="flex-row-center w-full h-full">
                  <CachedIcon sx={{ fontSize: 32, color: "white" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
