import { GameCase as GameCaseType } from "app/types";

export const GameCaseManager = {
  [GameCaseType.GRAY]: "normal_gray_case.png",
  [GameCaseType.SOFT_YELLOW]: "normal_soft_yellow_case.png",
  [GameCaseType.GREEN]: "normal_green_case.png",
  [GameCaseType.CYAN]: "normal_cyan_case.png",
  [GameCaseType.BLUE]: "normal_blue_case.png",
  [GameCaseType.MILK]: "normal_milk_case.png",
  [GameCaseType.PINK]: "normal_pink_case.png",
  [GameCaseType.ORANGE]: "normal_orange_case.png",
  [GameCaseType.SOFT_PINK]: "normal_soft_pink_case.png",
};

export const GameMiniCaseManager = {
  [GameCaseType.GRAY]: "mini_gray_case.png",
  [GameCaseType.SOFT_YELLOW]: "mini_soft_yellow_case.png",
  [GameCaseType.GREEN]: "mini_green_case.png",
  [GameCaseType.CYAN]: "mini_cyan_case.png",
  [GameCaseType.BLUE]: "mini_blue_case.png",
  [GameCaseType.MILK]: "mini_milk_case.png",
  [GameCaseType.PINK]: "mini_pink_case.png",
  [GameCaseType.ORANGE]: "mini_orange_case.png",
  [GameCaseType.SOFT_PINK]: "mini_soft_pink_case.png",
};

export const RuntimeGameCaseManager = {
  [GameCaseType.GRAY]: "middle_gray_case.png",
  [GameCaseType.SOFT_YELLOW]: "middle_soft_yellow_case.png",
  [GameCaseType.GREEN]: "middle_green_case.png",
  [GameCaseType.CYAN]: "middle_cyan_case.png",
  [GameCaseType.BLUE]: "middle_blue_case.png",
  [GameCaseType.MILK]: "middle_milk_case.png",
  [GameCaseType.PINK]: "middle_pink_case.png",
  [GameCaseType.ORANGE]: "middle_orange_case.png",
  [GameCaseType.SOFT_PINK]: "middle_soft_pink_case.png",
};

export const CreatorGameCaseManager = {
  [GameCaseType.GRAY]: "small_gray_case.png",
  [GameCaseType.SOFT_YELLOW]: "small_soft_yellow_case.png",
  [GameCaseType.GREEN]: "small_green_case.png",
  [GameCaseType.CYAN]: "small_cyan_case.png",
  [GameCaseType.BLUE]: "small_blue_case.png",
  [GameCaseType.MILK]: "small_milk_case.png",
  [GameCaseType.PINK]: "small_pink_case.png",
  [GameCaseType.ORANGE]: "small_orange_case.png",
  [GameCaseType.SOFT_PINK]: "small_soft_pink_case.png",
};

export const CreatorTopCaseManager = {
  [GameCaseType.GRAY]: "big_gray_case.png",
  [GameCaseType.SOFT_YELLOW]: "big_soft_yellow_case.png",
  [GameCaseType.GREEN]: "big_green_case.png",
  [GameCaseType.CYAN]: "big_cyan_case.png",
  [GameCaseType.BLUE]: "big_blue_case.png",
  [GameCaseType.MILK]: "big_milk_case.png",
  [GameCaseType.PINK]: "big_pink_case.png",
  [GameCaseType.ORANGE]: "big_orange_case.png",
  [GameCaseType.SOFT_PINK]: "big_soft_pink_case.png",
};

export { DisplayOff } from "./DisplayOff";
