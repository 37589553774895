import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { FadeTransition } from "common/elements";

export const NetworkErrorPage = () => {
  const { scale } = useScale();
  const error = useSelector(
    (state: RootState) => state.config.dialog.error_network
  );

  const handleConfirm = () => {
    console.error("Network error has occurred!");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={error.value}
      sx={{ zIndex: 99999999 }}
      TransitionComponent={FadeTransition}
      PaperProps={{
        style: { backgroundColor: "transparent", overflow: "hidden" },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
    >
      <div className="flex-col-center w-full h-full bg-textcolor/black/75">
        <div
          className="flex-col-center w-[600px] h-[300px] bg-beige/60 rounded-[8px] border-[12px] border-solid border-white"
          style={{ transform: `scale(${scale})` }}
        >
          <div className="flex-col-center w-full mb-5">
            <div className="flex-col-center mr-2">
              <WarningAmberIcon sx={{ fontSize: 40, color: "#FFA03B" }} />
            </div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_NETWORK_ERROR_MESSAGE")}
            </p>
          </div>

          <div className="flex-row-el flex-center alpha">
            <button className="btn btn-blue" onClick={handleConfirm}>
              <p className="text-body text-white text-[24px] !mb-[3px]">
                {I18n.t("MSG_ERROR_RELOAD_BTN")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
