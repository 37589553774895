export const StageDescriptionRect = { left: 40, top: 750 };

export const StageLevelRect = [
  {
    left: 80,
    top: 170,
  },
  {
    left: 300,
    top: 400,
  },
  {
    left: 400,
    top: 90,
  },
  {
    left: 700,
    top: 0,
  },
  {
    left: 680,
    top: 300,
  },
];

export const StageLevelPath = (
  radius: number,
  scale: number,
  offset: number
) => [
  `M ${(StageLevelRect[0].left + radius) * scale + offset} ${
    (StageLevelRect[0].top + radius) * scale
  } Q ${(StageLevelRect[0].left + radius) * scale + offset} ${
    (StageLevelRect[0].top +
      radius * 2 +
      (StageLevelRect[1].top - StageLevelRect[0].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[1].left * scale + offset} ${
    (StageLevelRect[1].top + radius) * scale
  }`,
  `M ${(StageLevelRect[1].left + radius) * scale + offset} ${
    (StageLevelRect[1].top + radius) * scale
  } Q ${(StageLevelRect[2].left + radius) * scale + offset} ${
    (StageLevelRect[2].top +
      radius * 2 +
      (StageLevelRect[1].top - StageLevelRect[2].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[2].left * scale + offset} ${
    (StageLevelRect[2].top + radius) * scale
  }`,
  `M ${(StageLevelRect[2].left + radius) * scale + offset} ${
    (StageLevelRect[2].top + radius) * scale
  } Q ${(StageLevelRect[3].left + radius) * scale + offset} ${
    (StageLevelRect[3].top +
      radius * 2 +
      (StageLevelRect[2].top - StageLevelRect[2].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[3].left * scale + offset} ${
    (StageLevelRect[3].top + radius) * scale
  }`,
  `M ${(StageLevelRect[3].left + radius) * scale + offset} ${
    (StageLevelRect[3].top + radius) * scale
  } Q ${(StageLevelRect[4].left + radius) * scale + offset} ${
    (StageLevelRect[4].top +
      radius * 2 +
      (StageLevelRect[3].top - StageLevelRect[2].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[4].left * scale + offset} ${
    (StageLevelRect[4].top + radius) * scale
  }`,
];
