import { SVGProps } from "react";

export const IconHartColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 9.8c0-.503.101-1.001.299-1.466.197-.465.487-.887.852-1.241.366-.355.8-.634 1.277-.822.478-.188.99-.28 1.504-.27h.023a4.45 4.45 0 0 1 1.812.374c.57.25 1.078.617 1.491 1.074l.742.82.742-.82a4.389 4.389 0 0 1 1.491-1.074 4.45 4.45 0 0 1 1.812-.374h.023a3.914 3.914 0 0 1 1.504.27c.477.188.911.467 1.277.822.365.354.655.776.852 1.241.198.465.3.963.299 1.466 0 .684-.105 1.201-.332 1.702-.236.519-.629 1.074-1.286 1.803-.742.824-1.726 1.77-3.048 3.04-.932.897-2.032 1.954-3.334 3.244a293.017 293.017 0 0 0-3.335-3.244c-1.321-1.27-2.305-2.216-3.047-3.04-.658-.729-1.05-1.284-1.286-1.803-.227-.5-.332-1.017-.332-1.701Z"
      fill="#FEE0DF"
      stroke="#A69DA3"
      strokeWidth={2}
    />
  </svg>
);
