const ToolboxInfo = {
  maxInstances: {
    program_button_click_event_block: 1,
    show_image_block: 1,
    show_label_text_block: 1,
    controls_repeat_ext: 1,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "program_button_click_event_block",
          },
          {
            kind: "block",
            type: "show_image_block",
          },
          {
            kind: "block",
            type: "show_label_text_block",
            inputs: {
              value: {
                shadow: {
                  type: "text",
                },
              },
            },
          },
          {
            kind: "block",
            type: "controls_repeat_ext",
            inputs: {
              TIMES: {
                shadow: {
                  type: "math_number",
                  fields: {
                    NUM: 0,
                  },
                },
              },
            },
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
