import { SVGProps } from "react";

export const IconPublish = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
    <g clipPath="url(#icon-publish-btn-a)">
      <circle cx={24} cy={24} r={20.4} fill={props.fill ?? "#C9C4C8"} />
      <path
        fill="#FEFDFE"
        d="M24 3.6C12.732 3.6 3.6 12.733 3.6 24S12.733 44.4 24 44.4 44.4 35.265 44.4 24c0-11.267-9.133-20.4-20.4-20.4Zm-12.622 7.779a17.854 17.854 0 0 1 6.651-4.202c-1.21 1.465-2.218 3.34-3.009 5.508h-4.823c.373-.453.766-.89 1.181-1.306Zm-2.55 3.218h5.578c-.718 2.545-1.149 5.405-1.224 8.447H6.176a17.739 17.739 0 0 1 2.652-8.447Zm0 18.806a17.728 17.728 0 0 1-2.652-8.447h7.012c.077 3.038.495 5.904 1.212 8.447H8.828Zm2.55 3.219a18.19 18.19 0 0 1-1.182-1.306h4.827c.34.937.718 1.828 1.14 2.646a16.153 16.153 0 0 0 1.86 2.86 17.867 17.867 0 0 1-6.645-4.2Zm11.666 5.202a18.142 18.142 0 0 1-.89-.069 6.263 6.263 0 0 1-1.394-.873c-1.405-1.145-2.708-3.082-3.698-5.566h5.982v6.508Zm0-8.421h-6.65c-.755-2.484-1.22-5.364-1.3-8.447h7.95v8.447Zm0-10.36H15.1c.08-3.084.548-5.962 1.304-8.447h6.64v8.447Zm0-10.36H17.07c.248-.62.508-1.22.792-1.768.847-1.643 1.846-2.94 2.896-3.798.46-.375.925-.663 1.395-.873.295-.03.591-.053.89-.068v6.508Zm16.128 1.913a17.736 17.736 0 0 1 2.652 8.447h-7.012c-.076-3.037-.495-5.904-1.212-8.447h5.572Zm-2.55-3.218c.415.415.81.852 1.182 1.306h-4.827a22.86 22.86 0 0 0-1.14-2.647 16.153 16.153 0 0 0-1.861-2.86 17.87 17.87 0 0 1 6.646 4.2ZM24.956 6.176c.298.015.595.038.889.068.47.211.934.498 1.394.873 1.406 1.145 2.708 3.082 3.698 5.567h-5.981V6.176Zm0 8.42h6.649c.755 2.484 1.22 5.364 1.301 8.447h-7.95v-8.447Zm0 10.36h7.943c-.08 3.085-.548 5.962-1.304 8.447h-6.64v-8.447Zm2.283 15.926c-.46.375-.925.662-1.394.873-.294.03-.59.053-.89.069v-6.508h5.973c-.248.62-.508 1.22-.791 1.769-.848 1.643-1.846 2.94-2.897 3.797Zm9.382-4.26a17.857 17.857 0 0 1-6.65 4.202c1.21-1.465 2.217-3.34 3.008-5.508h4.825c-.373.454-.767.891-1.183 1.306Zm2.55-3.22h-5.578c.718-2.544 1.148-5.405 1.225-8.446h7.005a17.739 17.739 0 0 1-2.652 8.447Z"
      />
      <circle
        cx={24}
        cy={24}
        r={18.9}
        stroke={props.stroke ?? "#A69DA3"}
        strokeWidth={3}
      />
    </g>
    <defs>
      <clipPath id="icon-publish-btn-a">
        <path fill="#fff" d="M0 0h48v48H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconPublishCopiedOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 64"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-publish-copied-on-btn-a)">
      <circle cx={22} cy={32} r={18.7} fill="#4C9DF1" />
      <path
        fill="#FEFDFE"
        d="M22 13.3C11.671 13.3 3.3 21.672 3.3 32S11.672 50.7 22 50.7 40.7 42.326 40.7 32c0-10.328-8.372-18.7-18.7-18.7Zm-11.57 7.13a16.367 16.367 0 0 1 6.096-3.85c-1.108 1.342-2.033 3.06-2.758 5.048H9.347a16.63 16.63 0 0 1 1.083-1.197Zm-2.338 2.95h5.113c-.658 2.333-1.053 4.956-1.122 7.744H5.661a16.26 16.26 0 0 1 2.431-7.743Zm0 17.24a16.25 16.25 0 0 1-2.43-7.744h6.427c.07 2.785.453 5.412 1.11 7.743H8.094Zm2.338 2.95c-.38-.38-.742-.782-1.084-1.197h4.424c.313.86.66 1.675 1.046 2.425a14.8 14.8 0 0 0 1.705 2.622 16.378 16.378 0 0 1-6.092-3.85Zm10.693 4.768a16.52 16.52 0 0 1-.815-.063 5.742 5.742 0 0 1-1.278-.8c-1.288-1.05-2.482-2.825-3.39-5.102h5.483v5.965Zm0-7.719h-6.095c-.692-2.277-1.119-4.917-1.193-7.743h7.287v7.743h.001Zm0-9.496h-7.282c.074-2.828.503-5.465 1.195-7.743h6.087v7.743Zm0-9.496h-5.475c.228-.57.466-1.119.726-1.621.777-1.506 1.692-2.695 2.655-3.482a5.749 5.749 0 0 1 1.278-.8c.27-.028.542-.049.816-.063v5.966Zm14.784 1.753a16.258 16.258 0 0 1 2.431 7.743h-6.427c-.07-2.785-.454-5.412-1.111-7.743h5.107Zm-2.337-2.95c.38.38.742.781 1.084 1.197h-4.426a20.923 20.923 0 0 0-1.045-2.426 14.801 14.801 0 0 0-1.705-2.622 16.381 16.381 0 0 1 6.092 3.85Zm-10.694-4.77c.274.015.545.036.815.064.43.193.856.456 1.278.8 1.289 1.05 2.482 2.825 3.39 5.103h-5.483V15.66Zm0 7.72h6.095c.692 2.277 1.119 4.917 1.192 7.743h-7.287V23.38Zm0 9.496h7.281c-.074 2.828-.502 5.466-1.195 7.743h-6.087v-7.743Zm2.093 14.599a5.772 5.772 0 0 1-1.278.8 16.5 16.5 0 0 1-.815.063v-5.965h5.474a18.506 18.506 0 0 1-.725 1.621c-.777 1.506-1.692 2.695-2.655 3.48Zm8.6-3.905a16.367 16.367 0 0 1-6.097 3.852c1.11-1.343 2.033-3.061 2.759-5.049h4.422c-.342.417-.703.817-1.084 1.198v-.001Zm2.337-2.95h-5.113c.658-2.333 1.053-4.956 1.123-7.744h6.421a16.262 16.262 0 0 1-2.43 7.743Z"
      />
      <circle cx={22} cy={32} r={17.2} stroke="#3F4A61" strokeWidth={3} />
    </g>
    <g clipPath="url(#icon-publish-copied-on-btn-b)">
      <path
        fill="#FEFDFE"
        d="M47 36v-2.5a3.5 3.5 0 0 0-3.5-3.5h-14a3.5 3.5 0 0 0-3.5 3.5v14a3.5 3.5 0 0 0 3.5 3.5H33"
      />
      <path
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M47 36v-2.5a3.5 3.5 0 0 0-3.5-3.5h-14a3.5 3.5 0 0 0-3.5 3.5v14a3.5 3.5 0 0 0 3.5 3.5H33"
      />
      <path
        fill="#FEFDFE"
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M50.5 36h-14c-1.933 0-3.5 1.642-3.5 3.667v14.666C33 56.358 34.567 58 36.5 58h14c1.933 0 3.5-1.642 3.5-3.667V39.667C54 37.642 52.433 36 50.5 36Z"
      />
      <path
        fill="#3F4A61"
        d="M41.248 46.89c0-2.427-.886-3.64-2.66-3.64-1.773 0-2.66 1.213-2.66 3.64s.887 3.64 2.66 3.64c1.773 0 2.66-1.213 2.66-3.64Zm.742 3.864c-.83.924-1.965 1.386-3.402 1.386s-2.576-.462-3.416-1.386c-.83-.924-1.246-2.212-1.246-3.864 0-1.652.416-2.94 1.246-3.864.84-.924 1.979-1.386 3.416-1.386 1.438 0 2.572.462 3.402 1.386.84.924 1.26 2.212 1.26 3.864 0 1.652-.42 2.94-1.26 3.864ZM45.621 52a.79.79 0 0 1-.784-.798v-8.624a.79.79 0 0 1 .784-.798H46c.215 0 .402.08.56.238a.766.766 0 0 1 .238.56v3.626c0 .01.005.014.014.014.01 0 .014-.005.014-.014l2.814-3.78a1.47 1.47 0 0 1 1.26-.644h.7c.16 0 .276.075.35.224.075.15.061.285-.042.406l-3.22 4.172a.155.155 0 0 0 0 .196l3.374 4.578c.103.13.117.27.042.42a.379.379 0 0 1-.364.224h-.7c-.532 0-.947-.215-1.246-.644l-2.968-4.2c0-.01-.004-.014-.014-.014-.009 0-.014.005-.014.014v4.046c0 .215-.079.401-.238.56A.766.766 0 0 1 46 52h-.378Z"
      />
    </g>
    <defs>
      <clipPath id="icon-publish-copied-on-btn-a">
        <path fill="#fff" d="M0 10h44v44H0z" />
      </clipPath>
      <clipPath id="icon-publish-copied-on-btn-b">
        <path fill="#fff" d="M20 24h40v40H20z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconPublishCopiedOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 64"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-publish-copied-off-btn-a)">
      <circle cx={22} cy={32} r={18.7} fill="#4C9DF1" />
      <path
        fill="#FEFDFE"
        d="M22 13.3C11.671 13.3 3.3 21.672 3.3 32S11.672 50.7 22 50.7 40.7 42.326 40.7 32c0-10.328-8.372-18.7-18.7-18.7Zm-11.57 7.13a16.367 16.367 0 0 1 6.096-3.85c-1.108 1.342-2.033 3.06-2.758 5.048H9.347a16.63 16.63 0 0 1 1.083-1.197Zm-2.338 2.95h5.113c-.658 2.333-1.053 4.956-1.122 7.744H5.661a16.26 16.26 0 0 1 2.431-7.743Zm0 17.24a16.25 16.25 0 0 1-2.43-7.744h6.427c.07 2.785.453 5.412 1.11 7.743H8.094Zm2.338 2.95c-.38-.38-.742-.782-1.084-1.197h4.424c.313.86.66 1.675 1.046 2.425a14.8 14.8 0 0 0 1.705 2.622 16.378 16.378 0 0 1-6.092-3.85Zm10.693 4.768a16.52 16.52 0 0 1-.815-.063 5.742 5.742 0 0 1-1.278-.8c-1.288-1.05-2.482-2.825-3.39-5.102h5.483v5.965Zm0-7.719h-6.095c-.692-2.277-1.119-4.917-1.193-7.743h7.287v7.743h.001Zm0-9.496h-7.282c.074-2.828.503-5.465 1.195-7.743h6.087v7.743Zm0-9.496h-5.475c.228-.57.466-1.119.726-1.621.777-1.506 1.692-2.695 2.655-3.482a5.749 5.749 0 0 1 1.278-.8c.27-.028.542-.049.816-.063v5.966Zm14.784 1.753a16.258 16.258 0 0 1 2.431 7.743h-6.427c-.07-2.785-.454-5.412-1.111-7.743h5.107Zm-2.337-2.95c.38.38.742.781 1.084 1.197h-4.426a20.923 20.923 0 0 0-1.045-2.426 14.801 14.801 0 0 0-1.705-2.622 16.381 16.381 0 0 1 6.092 3.85Zm-10.694-4.77c.274.015.545.036.815.064.43.193.856.456 1.278.8 1.289 1.05 2.482 2.825 3.39 5.103h-5.483V15.66Zm0 7.72h6.095c.692 2.277 1.119 4.917 1.192 7.743h-7.287V23.38Zm0 9.496h7.281c-.074 2.828-.502 5.466-1.195 7.743h-6.087v-7.743Zm2.093 14.599a5.772 5.772 0 0 1-1.278.8 16.5 16.5 0 0 1-.815.063v-5.965h5.474a18.506 18.506 0 0 1-.725 1.621c-.777 1.506-1.692 2.695-2.655 3.48Zm8.6-3.905a16.367 16.367 0 0 1-6.097 3.852c1.11-1.343 2.033-3.061 2.759-5.049h4.422c-.342.417-.703.817-1.084 1.198v-.001Zm2.337-2.95h-5.113c.658-2.333 1.053-4.956 1.123-7.744h6.421a16.262 16.262 0 0 1-2.43 7.743Z"
      />
      <circle cx={22} cy={32} r={17.2} stroke="#3F4A61" strokeWidth={3} />
    </g>
    <g clipPath="url(#icon-publish-copied-off-btn-b)">
      <path
        fill="#FEFDFE"
        d="M47 36v-2.5a3.5 3.5 0 0 0-3.5-3.5h-14a3.5 3.5 0 0 0-3.5 3.5v14a3.5 3.5 0 0 0 3.5 3.5H33"
      />
      <path
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M47 36v-2.5a3.5 3.5 0 0 0-3.5-3.5h-14a3.5 3.5 0 0 0-3.5 3.5v14a3.5 3.5 0 0 0 3.5 3.5H33"
      />
      <path
        fill="#FEFDFE"
        stroke="#3F4A61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.5}
        d="M50.5 36h-14c-1.933 0-3.5 1.642-3.5 3.667v14.666C33 56.358 34.567 58 36.5 58h14c1.933 0 3.5-1.642 3.5-3.667V39.667C54 37.642 52.433 36 50.5 36Z"
      />
      <path
        fill="#FEFDFE"
        d="m21.57 29.803 4.233-4.233L58.36 58.128l-4.233 4.234z"
      />
      <path
        fill="#3F4A61"
        d="m22.45 28.924 2.474-2.475 32.559 32.558-2.475 2.475z"
      />
    </g>
    <defs>
      <clipPath id="icon-publish-copied-off-btn-a">
        <path fill="#fff" d="M0 10h44v44H0z" />
      </clipPath>
      <clipPath id="icon-publish-copied-off-btn-b">
        <path fill="#fff" d="M20 24h40v40H20z" />
      </clipPath>
    </defs>
  </svg>
);
