import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import Constants from "common/constant";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        "creator-published-project-tutorial-sample-app"
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 45 * scale,
        y: rect.y - 20 * scale,
        width: rect.height + 30 * scale,
        height: rect.height + 30 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={3} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {"きどうちゅうの\nアプリは"}
          </p>

          <div className="flex-row-el flex-center">
            <img
              alt="switch"
              className="w-[32px] h-[34px]"
              src={`${Constants.assetHost}/assets/images/project_card_switch.png`}
            />
            <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
              マークで
            </p>
          </div>

          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja" ? "ひょうじされます。" : "表示されます。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
