import {
  useState,
  useCallback,
  FormEventHandler,
  MouseEventHandler,
} from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useQueryClient } from "@tanstack/react-query";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { Asset, DialogType } from "app/types";
import { deleteAsset, updateImageAsset } from "app/api";
import {
  CloseBtn,
  DialogMessage,
  FadeTransition,
  DeleteMessageBox,
} from "common/elements";
import { btnDelay, useScale, usePlaySound } from "common/utils";

export const ImagePreview = ({
  asset,
  visible,
  limit,
  readOnly,
  message,
  update,
  confirm,
  close,
}: {
  asset?: Asset;
  limit: boolean;
  visible: boolean;
  readOnly: boolean;
  message?: string;
  update: (asset: Asset) => void;
  confirm?: MouseEventHandler<HTMLButtonElement>;
  close: () => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const queryClient = useQueryClient();
  const [disable, setDisable] = useState(true);
  const [warning, setWarning] = useState(false);
  const [removeAsset, setRemoveAsset] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser);

  const handleFormChange: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      const data = new FormData(event.currentTarget);
      const name = data.get("name").toString();
      if (name !== asset.name) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    },
    [asset]
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name").toString();
    if (name !== asset.name) {
      updateImageAsset({
        id: asset.id,
        name,
        tags: asset.tags,
      }).then(() => {
        setDisable(true);
        update({ ...asset, name, tags: asset.tags });
        toast(
          <div>
            <p className="text text-textcolor/black">
              {I18n.t("MSG_TOAST_EDIT_SUCCESS")}
            </p>
          </div>
        );
      });
    }
  };

  const handleAdd: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (limit) {
      btnDelay(() => {
        play();
        setWarning(true);
      });
    } else {
      confirm(event);
    }
  };
  const handleCloseLimitWarning = () => {
    btnDelay(() => {
      play();
      setWarning(false);
    });
  };
  const handleOpenDeleteAsset = () => {
    btnDelay(() => {
      play();
      setRemoveAsset(true);
    });
  };
  const handleDeleteAsset = () => {
    if (asset) {
      setRemoveAsset(false);
      handleDialog({ type: DialogType.LOADING, value: true });
      btnDelay(() => {
        deleteAsset({ id: asset.id })
          .then(() => {
            queryClient.setQueryData(
              ["creator/getUserAssets", user.active.uid],
              (old: Asset[]) => old.filter((a) => a.id !== asset.id)
            );
            handleDialog({ type: DialogType.LOADING, value: false });
            close();
          })
          .catch((e) => {
            console.error(e);
            handleDialog({ type: DialogType.LOADING, value: false });
          });
      });
    }
  };
  const handleCloseDeleteAsset = () => {
    btnDelay(() => {
      play();
      setRemoveAsset(false);
    });
  };

  return (
    <Dialog
      fullScreen
      open={visible}
      maxWidth={false}
      sx={{ zIndex: 99999 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <DialogMessage
        open={warning}
        title={I18n.t("MSG_CREATOR_ASSET_LIMIT_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_ASSET_LIMIT_WARNING_MESSAGE")}
        close={handleCloseLimitWarning}
      />

      <DeleteMessageBox
        zIndex={99999}
        visible={removeAsset}
        title={I18n.t("MSG_CREATOR_ASSET_DELETE_WARNING_TITLE")}
        message={I18n.t("MSG_CREATOR_ASSET_DELETE_WARNING_MESSAGE", {
          name: asset?.name,
        })}
        close={handleCloseDeleteAsset}
        handleDelete={handleDeleteAsset}
      />

      <div className="flex-col-el flex-center w-full h-full">
        <div
          className="flex-col-el flex-center relative"
          style={{ transform: `scale(${scale})` }}
        >
          <div className="flex-col-el flex-center relative w-[1200px] h-[900px]">
            <div className="flex-row-el flex-center w-full h-full p-4 rounded-[4px] gap-8">
              <div className="flex-col-el flex-center relative m-w-[800px] m-h-[800px]">
                <div className="flex-col-el relative border-solid border-[24px] bg-beige/20 p-16 frame-full">
                  <img
                    alt="*"
                    src={asset?.url}
                    className="w-full h-full object-contain bg-white"
                  />

                  <CloseBtn close={close} />

                  {confirm && (
                    <div className="flex-col-el flex-center upsilon absolute left-0 bottom-0">
                      <button className="btn btn-primary" onClick={handleAdd}>
                        <div className="flex-col-el flex-center relative mb-[3px]">
                          <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                            <AddIcon sx={{ fontSize: 28, color: "white" }} />
                          </div>
                          <p className="text-body text-white text-font-caption2 whitespace-pre">
                            {message}
                          </p>
                        </div>
                      </button>
                    </div>
                  )}

                  <div className="flex-col-el flex-center upsilon absolute right-0 bottom-0">
                    <button
                      className="btn btn-danger"
                      onClick={handleOpenDeleteAsset}
                    >
                      <div className="flex-col-el flex-center relative mb-[3px]">
                        <div className="flex-col-el flex-center relative w-[32px] h-[32px]">
                          <DeleteForeverIcon
                            sx={{ fontSize: 28, color: "white" }}
                          />
                        </div>
                        <p className="text-body text-white text-font-caption2 whitespace-pre">
                          {I18n.t("MSG_ASSET_DELETE_BTN")}
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex-col-el bg-orange/10 rounded-lg border-solid border-[8px] border-white p-4 gap-3">
                <div className="flex-col-el gap-5">
                  <form
                    onSubmit={handleSubmit}
                    onChange={handleFormChange}
                    className="flex-col-el gap-3"
                  >
                    <div className="flex-row-center gap-3">
                      <DriveFileRenameOutlineIcon />
                      <div className="flex-col-el flex-center h-[60px] gap-1">
                        <input
                          required
                          name="name"
                          className={`peer w-[300px] px-3 invalid:!mt-6 text-body text-center text-textcolor/black text-[16px] border-none bg-transparent ${
                            !readOnly &&
                            "!bg-white rounded-lg outline outline-gray2/40 outline-4 focus:outline-blue/80/40"
                          }`}
                          placeholder={I18n.t(
                            "MSG_CREATOR_APP_NAME_PLACEHOLDER"
                          )}
                          pattern={".{1,30}"}
                          readOnly={readOnly}
                          defaultValue={asset?.name}
                        />
                        <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
                          {I18n.t(
                            "MSG_SETTING_PARENTS_USER_PROFILE_DISPLAY_NAME_LIMIT",
                            {
                              limit: 30,
                            }
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="flex-col-el gap-5">
                      <div className="flex-row-el gap-5">
                        <div className="flex-row-center gap-2">
                          <FlipToBackIcon />
                          <p className="text-body text-textcolor/black text-font-caption2">
                            {`${Math.floor(
                              Number(asset?.metadata.width)
                            )} x ${Math.floor(Number(asset?.metadata.height))}`}
                          </p>
                        </div>
                        <div className="flex-row-center gap-2">
                          <InsertDriveFileOutlinedIcon />
                          <p className="text-body text-textcolor/black text-font-caption2">
                            {Math.floor(asset?.file_size / 1024)}KB
                          </p>
                        </div>
                      </div>
                      <div className="flex-row-el items-center gap-2">
                        <UploadFileIcon />
                        <p className="text-body text-textcolor/black text-font-caption2">
                          {dayjs(asset?.created_at).format("YYYY/MM/DD")}
                        </p>
                      </div>
                    </div>

                    <div className="flex-row-el flex-center alpha">
                      <button
                        type="submit"
                        disabled={disable}
                        className="btn btn-primary"
                      >
                        <div className="flex-row-center gap-1 mb-[3px]">
                          <span className="material-symbols-outlined text-[36px] text-white">
                            check
                          </span>
                          <p className="text-body text-white text-font-size-body1">
                            {I18n.t("MSG_CONFIRM_BTN")}
                          </p>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
