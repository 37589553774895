import { SVGProps } from "react";

export const IconSmile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={24}
      cy={24}
      r={18.5}
      fill="#F9E532"
      stroke="#667786"
      strokeWidth={3}
    />
    <path
      d="M29.658 29.656a8.11 8.11 0 0 1-2.544 1.716 8.004 8.004 0 0 1-9.75-2.9l-3.316 2.238a12.126 12.126 0 0 0 3.242 3.24c1.3.879 2.76 1.492 4.296 1.806 1.594.325 3.237.325 4.83 0a11.943 11.943 0 0 0 6.068-3.268 11.897 11.897 0 0 0 1.468-1.778l-3.316-2.238a8.033 8.033 0 0 1-.978 1.184ZM17 24a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM30.986 23.972a2.986 2.986 0 1 0 0-5.972 2.986 2.986 0 0 0 0 5.972Z"
      fill="#667786"
    />
  </svg>
);
