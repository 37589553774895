/* eslint-disable react-hooks/exhaustive-deps */
import { useState, PointerEventHandler } from "react";
import I18n from "i18n-js";
import Slider from "rc-slider";
import { Vector2D } from "common/types";
import { PromptInputNumber } from "common/elements";

export const InputPosition = (props: {
  value: Vector2D;
  play: () => void;
  onChange: (value: Vector2D) => void;
}) => {
  const { value, play, onChange } = props;
  const [promptX, setPromptX] = useState(false);
  const [promptY, setPromptY] = useState(false);

  const handleChangeX = (valueX: number) => {
    onChange({ x: valueX, y: value.y });
  };

  const handleChangeY = (valueY: number) => {
    onChange({ x: value.x, y: valueY });
  };

  const handlePositionClick: PointerEventHandler<HTMLDivElement> = (e) => {
    onChange({
      x: e.nativeEvent.offsetX / (168 / 1024),
      y: e.nativeEvent.offsetY / (126 / 768),
    });
  };

  const handleShowPromptInputX = () => {
    play();
    setPromptX(true);
  };
  const handleShowPromptInputY = () => {
    play();
    setPromptY(true);
  };

  const handleInputValueXChange = (inputValue: number) => {
    play();
    if (inputValue < 0) {
      onChange({ x: 0, y: value.y });
    } else if (inputValue > 1024) {
      onChange({ x: 1024, y: value.y });
    } else {
      onChange({ x: inputValue, y: value.y });
    }
    setPromptX(false);
  };
  const handleInputValueYChange = (inputValue: number) => {
    play();
    if (inputValue < 0) {
      onChange({ x: value.x, y: 0 });
    } else if (inputValue > 768) {
      onChange({ x: value.x, y: 768 });
    } else {
      onChange({ x: value.x, y: inputValue });
    }
    setPromptY(false);
  };

  const handleCloseInput = () => {
    play();
    setPromptX(false);
    setPromptY(false);
  };

  return (
    <>
      {promptX && (
        <PromptInputNumber
          defaultValue={Math.floor(value.x)}
          message={I18n.t("MSG_CREATOR_MENU_BLOCKLY_PROMPT_INPUT_MESSAGE", {
            placeholder: I18n.t("MSG_CREATOR_MENU_PROPERTY_X"),
          })}
          confirm={handleInputValueXChange}
          cancel={handleCloseInput}
        />
      )}

      {promptY && (
        <PromptInputNumber
          defaultValue={Math.floor(value.y)}
          message={I18n.t("MSG_CREATOR_MENU_BLOCKLY_PROMPT_INPUT_MESSAGE", {
            placeholder: I18n.t("MSG_CREATOR_MENU_PROPERTY_Y"),
          })}
          confirm={handleInputValueYChange}
          cancel={handleCloseInput}
        />
      )}

      <div className="flex-col-center w-full h-full">
        <div className="flex-col-view w-full">
          <div className="flex-col-view ml-[146px]">
            <div className="flex-row-view items-center">
              <p className="text text-textcolor/black !text-[16px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_X_COORDINATE")} X:
              </p>
              <input
                type="text"
                className="property-input-value_input"
                readOnly
                value={Math.round(value.x)}
                onClick={handleShowPromptInputX}
              />
              <p className="text text-textcolor/black !text-[16px]">px</p>
            </div>

            <div className="flex-col-view justify-start w-[168px] h-[24px] z-50">
              <div
                className="bg-green/60 w-[4px] h-[168px] absolute top-0 left-0 pointer-events-none"
                style={{
                  transform: `translate(${
                    168 * (value.x < 0 ? 0 : value.x / 1024) - 2
                  }px, 24px)`,
                }}
              />
              <Slider
                min={0}
                max={1024}
                step={1}
                value={value.x < 0 ? 0 : value.x}
                onChange={(valueX) => handleChangeX(valueX as number)}
                trackStyle={{ height: 36, opacity: 0 }}
                handleStyle={{
                  height: 24,
                  width: 24,
                  borderColor: "transparent",
                  opacity: 1,
                  marginTop: 7,
                  backgroundColor: "#80EAC1",
                }}
                railStyle={{ height: 36, opacity: 0 }}
              />
            </div>
          </div>
        </div>

        <div className="flex-row-view justify-start items-center w-full h-[168px]">
          <div className="flex-row-view items-center justify-end w-[146px] z-50 pr-[18px]">
            <div className="flex-col-view items-start w-[94px]">
              <p className="text text-textcolor/black !text-[16px] !ml-[8px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_Y_COORDINATE")} Y:
              </p>
              <div className="flex-row-center mt-[8px]">
                <input
                  type="text"
                  className="property-input-value_input"
                  readOnly
                  value={Math.round(value.y)}
                  onClick={handleShowPromptInputY}
                />
                <p className="text text-textcolor/black !text-[16px]">px</p>
              </div>
            </div>

            <div className="flex-row-view h-[126px] w-[24px]">
              <div
                className="bg-green/60 w-[204px] h-[4px] absolute top-0 left-0 pointer-events-none z-50"
                style={{
                  transform: `translate(24px, ${
                    126 * ((value.y < 0 ? 0 : value.y) / 768) - 2
                  }px)`,
                }}
              />
              <Slider
                vertical
                reverse
                min={0}
                max={768}
                step={1}
                value={value.y < 0 ? 0 : value.y}
                onChange={(valueY) => handleChangeY(valueY as number)}
                trackStyle={{ width: 36, opacity: 0 }}
                handleStyle={{
                  height: 24,
                  width: 24,
                  borderColor: "transparent",
                  opacity: 1,
                  marginLeft: 7,
                  backgroundColor: "#80EAC1",
                }}
                railStyle={{ width: 36, opacity: 0 }}
              />
            </div>
          </div>

          <div
            className="flex-col-center w-[168px] h-[126px] bg-gray2/10 border-solid border-[1px] border-gray2/60"
            onPointerDown={handlePositionClick}
          >
            <div className="flex-row-view w-[204px] h-[4px] bg-gray/80 !absolute top-[-3px] pointer-events-none" />
            <div className="flex-row-view w-[4px] h-[168px] bg-gray/80 !absolute left-[-3px] pointer-events-none" />
            <div
              className="flex-col-center w-[24px] h-[24px] bg-green/60 border-solid border-[1px] border-white rounded-[4px] !absolute top-0 left-0 pointer-events-none"
              style={{
                transform: `translate(${
                  value.x < 0 ? 0 : value.x * (168 / 1024) + 1
                }px, ${value.y < 0 ? 0 : value.y * (126 / 768) + 1}px)`,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
