import { useState, useEffect, PointerEvent } from "react";
import I18n from "i18n-js";
import { IconRotate } from "common/assets";

export const ReturnBtn = (props: { scale: number; click: () => void }) => {
  const { scale, click } = props;
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(false);
  }, []);

  const handleStart = (e: PointerEvent) => {
    if (!disable && e.isPrimary) {
      setDisable(true);
      click();
    }
  };

  return (
    <div
      id="game-scene-start-btn"
      className="flex-col-el flex-center relative origin-top  pointer-events-auto"
      style={{
        bottom: 120 * scale,
        transform: `scale(${scale})`,
      }}
    >
      <div className="flex-row-el flex-center w-[210px] relative alpha">
        <button className="btn btn-blue" onPointerDown={handleStart}>
          <div className="flex-row-center gap-1 mb-[3px]">
            <div className="flex-col-center w-[40px] h-[40px]">
              <IconRotate color="white" width={2} />
            </div>

            <div className="flex-col-center">
              <p className="text-body text-white text-[36px]">
                {I18n.t("MSG_BACK_BTN")}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
