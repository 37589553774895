import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { IconTrash } from "common/assets";
import { useScale, usePlaySound } from "common/utils";
import {
  ProjectType,
  DisplayMode,
  ProjectEntity,
  ProjectPanelType,
} from "features/creator/types";
import { AppUser } from "features/user/types";
import { CardBg, IconTrashcan } from "features/creator/assets";
import { actions, selectAllProjects } from "features/creator/slice";
import { projectAnimation } from "./util";

const ProjectCardPlaceholder = () => (
  <div className="flex-col-el flex-center gap-2 w-[148px] h-[200px]">
    <div className="flex-col-center w-[148px] h-[156px]">
      <div className="flex-col-center rounded-[16px] p-2">
        <div className="flex-col-el flex-center w-[148px] h-[156px] bg-textcolor/black/15">
          <CardBg />
        </div>
      </div>
    </div>

    <div className="flex-col-center w-full flex-1" />
  </div>
);

const ProjectCard = ({
  user,
  project,
  handleSelectProject,
}: {
  user: AppUser;
  project?: ProjectEntity;
  handleSelectProject: (projectId: string) => () => void;
}) => (
  <div
    id={`user-app-project-container-${project.project_id}`}
    onClick={handleSelectProject(project.project_id)}
    className="flex-col-el flex-center w-[148px] h-[200px] cursor-pointer pointer-events-auto"
  >
    <div className="flex-col-center gap-2">
      <div className="flex-col-center w-[148px] h-[156px] rounded-[16px] p-2">
        <div className="flex-col-el flex-center w-[148px] h-[156px] absolute -z-0 bg-textcolor/black/15">
          <CardBg />
        </div>

        <div className="flex-col-center w-[120px] h-[148px] ml-4">
          <div className="flex-col-el flex-center absolute w-[62px] h-[64px]">
            <img
              alt="switch"
              className="w-full h-full"
              src={`${Constants.assetHost}/assets/images/project_card_switch.png`}
            />
          </div>

          <div
            className={`flex-col-center w-full h-full app-project-card-${project.project_id}`}
          >
            <img
              alt="app card"
              src={`${Constants.assetHost}/assets/images/${
                project.author_name === "まなパズ公式"
                  ? "app_card_official"
                  : project.author_uid === user.uid
                  ? "app_card_user"
                  : "app_card_shared"
              }.png`}
              className="w-full h-full"
            />

            <div className="flex-col-el flex-center absolute top-[31px] w-full px-[7px] h-[99px]">
              {project?.capture ? (
                <div className="flex-col-center w-full h-full overflow-hidden">
                  <img
                    alt="アプリのキャプチャー"
                    className="w-full h-full object-cover"
                    src={project?.capture}
                    onError={(e) =>
                      // @ts-ignore
                      (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                    }
                  />
                </div>
              ) : (
                <div className="w-full h-full bg-white" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row-el justify-start items-center w-full gap-1">
        <div className="flex-col-center w-[32px] h-[32px]">
          <IconTrashcan />
        </div>
        <p className="text-body text-font-caption2-narrow text-textcolor/black text-left whitespace-pre-wrap">
          {dayjs(project.deleted_at).format("YYYY/MM/DD\nHH:mm:ss")}
        </p>
      </div>
    </div>
  </div>
);

export const ProjectTrashcan = () => {
  const play = usePlaySound();
  const { width, scale } = useScale();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const projects = useSelector(selectAllProjects);
  const projectPanel = useSelector(
    (state: RootState) => state.creator.projectPanel
  );
  const selectedProjectId = useSelector(
    (state: RootState) => state.creator.selectedProjectId
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const [localSelectedProjectId, setLocalSelectedProjectId] = useState(
    selectedProjectId
  );
  const deletedProjects = useMemo(
    () =>
      projects
        .filter(
          (project) =>
            project.author_uid === user.active.uid &&
            project.type === ProjectType.BASIC &&
            project.deleted_at
        )
        .sort((a, b) => (dayjs(a.deleted_at) < dayjs(b.deleted_at) ? 1 : -1)),
    [projects]
  );

  useEffect(() => {
    if (projectPanel === ProjectPanelType.SHARED) {
      setShow(false);
    } else {
      setTimeout(() => {
        setShow(true);
      }, 700);
    }
  }, [projectPanel]);

  useEffect(() => {
    setLocalSelectedProjectId(selectedProjectId);
  }, [selectedProjectId]);

  const handleSelectProject = (projectId: string) => () => {
    play();
    if (projectId !== localSelectedProjectId) {
      play();
      setLocalSelectedProjectId(projectId);
      projectAnimation(localSelectedProjectId, projectId, () => {
        dispatch(actions.updateDisplayMode(DisplayMode.CAPTURE));
        dispatch(actions.updateSelectedProjectId(projectId));
      });
    }
  };

  const handleExpendTrashcan = () => {
    play();
    if (projectPanel === ProjectPanelType.TRASHCAN) {
      dispatch(actions.updateProjectPanel(ProjectPanelType.NONE));
    } else {
      dispatch(actions.updateProjectPanel(ProjectPanelType.TRASHCAN));
    }
  };

  return (
    <div
      style={{ width: width * 0.97, height: 264 * scale }}
      className="flex-col-el absolute right-0 bottom-0 pointer-events-none"
    >
      <div
        style={{
          transform: `scale(${scale})`,
          width: (width * 0.97) / scale,
          zIndex:
            projectPanel === ProjectPanelType.TRASHCAN ? 50 : show ? 50 : 40,
        }}
        className="flex-row-el h-[264px] origin-top-left"
      >
        <div
          style={{
            transform: `translateY(${
              projectPanel === ProjectPanelType.TRASHCAN
                ? 0
                : 263 + (show ? 0 : 56)
            }px)`,
          }}
          className="flex-col-el w-full h-[264px] relative shrink-0 transition-transform duration-700 will-change-transform"
        >
          <div
            onClick={handleExpendTrashcan}
            className="flex-row-el flex-center absolute -top-[56px] left-0 w-[190px] h-[56px] gap-3 bg-brown/80 rounded-t-2xl cursor-pointer pointer-events-auto"
          >
            <div className="flex-row-center gap-1">
              <div className="flex-col-center w-[40px] h-[40px]">
                <IconTrash color="white" />
              </div>
              <p className="text-body text-white text-[18px]">
                {I18n.t("MSG_CREATOR_APP_DELETED_APPS")}
              </p>
            </div>
            <div className="flex-col-center w-[20px] gap-1">
              <div className="h-[4px] w-full bg-textcolor/black/25" />
              <div className="h-[4px] w-full bg-textcolor/black/25" />
              <div className="h-[4px] w-full bg-textcolor/black/25" />
            </div>
          </div>

          <div className="flex-row-el items-center justify-start relative w-full h-full px-2 bg-[#F6EDE9] border-solid border-0 border-t-[1px] border-l-[1px] border-[#E5B876]">
            <div className="flex-row-el relative items-center gap-4">
              {Array(5)
                .fill(0)
                .map((_, index) =>
                  deletedProjects[index] ? (
                    <ProjectCard
                      key={deletedProjects[index].project_id}
                      user={user.active}
                      project={deletedProjects[index]}
                      handleSelectProject={handleSelectProject}
                    />
                  ) : (
                    <ProjectCardPlaceholder key={index} />
                  )
                )}
              <div className="flex-col-el h-full flex-1">
                <p className="text-body text-font-size-body2 text-gray2/80 whitespace-pre-wrap text-center">
                  {I18n.t("MSG_CREATOR_APP_DELETED_APPS_MESSAGE")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
