import { SVGProps } from "react";

export const IconStock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#B37350"
      stroke="#3F4A61"
      strokeWidth={2.1}
      d="M2.65 8.4A2.55 2.55 0 0 1 5.2 5.85h21.6a2.55 2.55 0 0 1 2.55 2.55v17.75H2.65V8.4Z"
    />
    <path
      stroke="#3F4A61"
      strokeWidth={2.1}
      d="M3.2 15.75h9.6M19.2 15.75h9.6"
    />
    <path
      fill="#F9E532"
      stroke="#3F4A61"
      strokeWidth={2.1}
      d="M12.25 12.25h6.7v4.915l-1.785 1.785h-3.13l-1.785-1.785V12.25Z"
    />
  </svg>
);
