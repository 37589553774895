import $ from "jquery";
import anime from "animejs";
import {
  setSize,
  calcOffset,
  setSizeByTime,
  getTransformPropertyValue,
} from "common/blockly/codeGen";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const setButtonTextFontSize = (id: string, value: number) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const shape = $(`#button-shape-${id}`);
  const text = $(`#button-text-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const fontSizeScale = value / currentFontSize;

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    currentShapeWidth * fontSizeScale,
    currentShapeHeight * fontSizeScale
  );

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);

  svg.attr(
    "viewBox",
    `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`
  );
  const borderRadius = Number(shape.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);
  shape.attr("width", `${newShapeSize.x}`);
  shape.attr("height", `${newShapeSize.y}`);
  shape.attr("rx", `${size * (borderRadius / 200)}`);
  text.css("font-size", `${value}px`);
};
export const setButtonTextFontSizeByStep = (id: string, value: number) => {
  const text = $(`#button-text-${id}`);
  const currentFontSize = Number(text.css("font-size").split("px")[0]);
  setButtonTextFontSize(id, currentFontSize + value);
};
export const setButtonTextFontSizeByTime = (
  id: string,
  time: number,
  value: number
) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const shape = $(`#button-shape-${id}`);
  const text = $(`#button-text-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentFontSize = Number(text.css("font-size").split("px")[0]);

  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;

  const fontSizeScale = value / currentFontSize;

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentShapeWidth,
    currentShapeHeight,
    currentShapeWidth * fontSizeScale,
    currentShapeHeight * fontSizeScale
  );

  const borderRadius = Number(shape.data("borderRadius"));
  const size = Math.min(newShapeSize.x, newShapeSize.y);

  const animation1 = anime({
    targets: `#button-container-${id}`,
    translateX: `-=${offset.x}`,
    translateY: `+=${offset.y}`,
    width: `+=${newShapeSize.x - currentShapeWidth}`,
    height: `+=${newShapeSize.y - currentShapeHeight}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#button-svg-${id}`,
    viewBox: `${viewBox[0]} ${viewBox[1]} ${newContainerSize.x} ${newContainerSize.y}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation3 = anime({
    targets: `#button-shape-${id}`,
    width: newShapeSize.x,
    height: newShapeSize.y,
    rx: size * (borderRadius / 200),
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation4 = anime({
    targets: `#button-text-${id}`,
    fontSize: value,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  animation3.play();
  animation4.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
    animation3.pause();
    animation4.pause();
  });
  return Promise.all([
    animation1.finished,
    animation2.finished,
    animation3.finished,
    animation4.finished,
  ]);
};

export const getButtonText = (id: string): string =>
  String($(`#button-text-${id}`).text());
export const setButtonText = (id: string, value?: string) => {
  const container = $(`#button-container-${id}`);
  const svg = $(`#button-svg-${id}`);
  const shape = $(`#button-shape-${id}`);
  const text = $(`#button-text-${id}`);
  const viewBox = svg.attr("viewBox").split(" ");
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentShapeWidth =
    currentContainerWidth - Math.abs(Number(viewBox[0])) * 2;
  const currentShapeHeight =
    currentContainerHeight - Math.abs(Number(viewBox[0])) * 2;
  const currentText = text.text();
  const fontSize = Number(text.css("font-size").split("px")[0]);
  const letterSpacing = Number(text.css("letter-spacing").split("px")[0]);

  const [translateX, translateY, rotate, scale] = getTransformPropertyValue(
    container
  );
  const textValue = value ?? "";

  const newShapeWidth =
    currentShapeWidth +
    (textValue.length - currentText.length) * (fontSize + letterSpacing);

  const offset = calcOffset(
    currentShapeWidth,
    currentShapeHeight,
    (rotate * Math.PI) / 180,
    {
      x: newShapeWidth - currentShapeWidth,
      y: 0,
    }
  );

  container.css(
    "transform",
    `translateX(${translateX - offset.x}px) translateY(${
      translateY + offset.y
    }px) rotate(${rotate}deg) scale(${scale})`
  );

  const newContainerWidth = newShapeWidth + Math.abs(Number(viewBox[0])) * 2;
  const borderRadius = Number(shape.data("borderRadius"));
  container.css("width", `${newContainerWidth}`);
  svg.attr(
    "viewBox",
    `${viewBox[0]} ${viewBox[1]} ${newContainerWidth} ${currentContainerHeight}`
  );
  shape.attr("width", `${newShapeWidth}`);
  shape.attr("ry", `${newShapeWidth * (borderRadius / 200)}`);
  text.text(textValue);
};
