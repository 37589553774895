import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { customAlphabet } from "nanoid";
import { QRCodeSVG } from "qrcode.react";
import { doc, setDoc, deleteDoc, getFirestore } from "firebase/firestore";
import OfflineShareOutlinedIcon from "@mui/icons-material/OfflineShareOutlined";
import { RootState } from "app/store";
import { parentsFirebase } from "app/Auth";
import { usePlaySound } from "common/utils";
import { RouteProps } from "./type";
import { GoBackBtn } from "./GoBackBtn";

export const ParentsLink = (props: RouteProps) => {
  const { navigate } = props;
  const play = usePlaySound();
  const [code, setCode] = useState("000000");
  const nanoid = customAlphabet("0123456789", 6);
  const handleGoBack = () => navigate("/parentsDownload");
  const user = useSelector((state: RootState) => state.user.appUser);

  useEffect(() => {
    const code = nanoid();
    const db = getFirestore(parentsFirebase);
    setDoc(doc(db, "link", code), {
      uid: user.main.uid,
    }).then(() => setCode(code));
    return () => {
      deleteDoc(doc(db, "link", code));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex-col-el items-center w-full h-full px-16 py-8">
      <div className="relative flex-row-el gap-4 shrink-0 items-center w-full px-16 py-8 bg-white/40 rounded-[8px] mt-10 mb-6">
        <div className="flex-row-el flex-center px-[8px] h-[28px] bg-orange/alt rounded-[14px] z-10 absolute -top-[14px] left-4">
          <OfflineShareOutlinedIcon />
          <p className="text-body text-textcolor/black !text-[16px] !px-[8px]">
            {I18n.t("MSG_SETTING_PARENTS_LINK_ADD_USER_TITLE")}
          </p>
        </div>

        <div className="relative">
          <QRCodeSVG size={160} value={`uid=${user.main.uid}`} />
        </div>

        <div className="flex-col-el mx-4">
          <p className="text-body text-textcolor/black text-[36px]">OR</p>
        </div>

        <div className="flex-col-el flex-center w-full px-4 gap-6">
          <p className="text-body text-textcolor/black text-[20px]">
            {I18n.t("MSG_SETTING_PARENTS_LINK_ADD_USER_DESCRIPTION")}
          </p>

          <div className="flex-col-el flex-center">
            <p className="text-body text-textcolor/black">
              {I18n.t("MSG_SETTING_PARENTS_LINK_ADD_USER_AUTH_CODE_TITLE")}
            </p>

            <div className="flex-row-el bg-white">
              {code.split("").map((num, index) => (
                <div
                  key={index}
                  className="relative flex-col-el flex-center w-12 border-solid border-[1px] border-l-0 border-gray/40 first:border-l-[1px] first:rounded-l-[8px] last:rounded-r-[8px]"
                >
                  <p className="text-body text-textcolor/black text-[28px]">
                    {num}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
