import { SVGProps } from "react";

export const IconCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 14.267a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2V24.6H9V14.267Z"
      fill="#FC6560"
      stroke="#3F4A61"
      strokeWidth={4}
    />
    <path
      d="M9 23.933h38V43.6a2 2 0 0 1-2 2H11a2 2 0 0 1-2-2V23.933Z"
      fill="#FEFDFE"
      stroke="#3F4A61"
      strokeWidth={4}
    />
    <rect
      x={16.333}
      y={7}
      width={4.667}
      height={11.667}
      rx={0.5}
      fill="#3F4A61"
    />
    <rect x={35} y={7} width={4.667} height={11.667} rx={0.5} fill="#3F4A61" />
  </svg>
);
