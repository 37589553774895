/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  query,
  limit,
  orderBy,
  collection,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { Unsubscribe } from "firebase/auth";
import { parentsFirebase } from "app/Auth";
import { AppDispatch, RootState } from "app/store";
import { ChatRoomMessage } from "features/user/types";
import { actions, selectAllChatRooms } from "features/user/slice";

export const useSubscribe = () => {
  const dispatch = useDispatch<AppDispatch>();
  const chatRooms = useSelector(selectAllChatRooms);
  const resign = useSelector((state: RootState) => state.config.resign);
  const [chatMessageSubscribes, setChatMessageSubscribes] = useState<
    { chatRoomId: string; unsubscribe: Unsubscribe }[]
  >([]);

  const subscribeUserChatRoomMessage = (chatRoomId: string, uid: string) => {
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ssZ");
    dispatch(
      actions.updateChatRoomMessageLastSyncAt({
        chatRoomId: chatRoomId,
        uid,
        lastMessageSyncAt: now,
      })
    );

    const db = getFirestore(parentsFirebase);
    const initialMessages = [];
    const messages = query(
      collection(db, "rooms", chatRoomId, "messages"),
      orderBy("created_at", "desc"),
      limit(20)
    );

    var initial = true;
    const unsubscribe = onSnapshot(
      messages,
      (snapshot) => {
        if (initial) {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              initialMessages.push(change.doc.data());
              if (change.newIndex === snapshot.size - 1) {
                dispatch(
                  actions.bulkInsertChatRoomMessage({
                    uid,
                    messages: initialMessages,
                  })
                );
              }
            }
          });
          initial = false;
        } else {
          snapshot.docChanges().forEach((change) => {
            switch (change.type) {
              case "added":
                dispatch(
                  actions.insertChatRoomMessage({
                    uid,
                    message: change.doc.data() as ChatRoomMessage,
                  })
                );
                break;
            }
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return { chatRoomId, unsubscribe };
  };

  useEffect(() => {
    const newUserChatSubscribes = chatRooms.map((chatRoom) =>
      subscribeUserChatRoomMessage(chatRoom.id, chatRoom.uid)
    );
    setChatMessageSubscribes(newUserChatSubscribes);
  }, [chatRooms]);

  useEffect(() => {
    if (resign) {
      chatMessageSubscribes.map((chatMessageSubscribe) =>
        chatMessageSubscribe.unsubscribe()
      );
      setChatMessageSubscribes([]);
    }
  }, [resign]);
};
