import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const getTextinputWidth = (id: string) =>
  $(`#textinput-text-${id}`).innerWidth();

export const getTextinputHeight = (id: string) =>
  $(`#textinput-text-${id}`).innerHeight();

export const setTextinputSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#textinput-container-${id}`);
  const text = $(`#textinput-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentInnerShapeWidth = text.innerWidth();
  const currentInnerShapeHeight = text.innerHeight();
  const currentShapeWidth = text.width();
  const currentShapeHeight = text.height();
  const PADDING_HORIZONTAL = currentInnerShapeWidth - currentShapeWidth;
  const PADDING_VERTICAL = currentInnerShapeHeight - currentShapeHeight;

  const [newContainerSize, newShapeSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentInnerShapeWidth,
    currentInnerShapeHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newContainerSize.x / Number(container.data("defaultWidth"));
  const scaleY = newContainerSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);
  text.css(
    "font-size",
    `${newFontSize < defaultFontSize ? defaultFontSize : newFontSize}px`
  );

  const borderRadius = Number(text.data("borderRadius"));
  const size = Math.min(
    newShapeSize.x - PADDING_HORIZONTAL,
    newShapeSize.y - PADDING_VERTICAL
  );
  text.css("border-radius", size * (borderRadius / 200));
  text.css("width", `${newShapeSize.x - PADDING_HORIZONTAL}`);
  text.css("height", `${newShapeSize.y - PADDING_VERTICAL}`);
};

export const setTextinputSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const text = $(`#textinput-text-${id}`);

  const currentInnerShapeWidth = text.innerWidth();
  const currentInnerShapeHeight = text.innerHeight();

  const newShapeWidth =
    widthStep === undefined ? undefined : currentInnerShapeWidth + widthStep;
  const newShapeHeight =
    heightStep === undefined ? undefined : currentInnerShapeHeight + heightStep;

  setTextinputSize(id, newShapeWidth, newShapeHeight);
};

export const setTextinputSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#textinput-container-${id}`);
  const text = $(`#textinput-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();
  const currentInnerShapeWidth = text.innerWidth();
  const currentInnerShapeHeight = text.innerHeight();
  const currentShapeWidth = text.width();
  const currentShapeHeight = text.height();
  const PADDING_HORIZONTAL = currentInnerShapeWidth - currentShapeWidth;
  const PADDING_VERTICAL = currentInnerShapeHeight - currentShapeHeight;

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentInnerShapeWidth,
    currentInnerShapeHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newContainerSize.x / Number(container.data("defaultWidth"));
  const scaleY = newContainerSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;
  const borderRadius = Number(text.data("borderRadius"));
  const size = Math.min(
    newShapeSize.x - PADDING_HORIZONTAL,
    newShapeSize.y - PADDING_VERTICAL
  );

  const animation1 = anime({
    targets: `#textinput-container-${id}`,
    translateX: `-=${offset.x}`,
    translateY: `+=${offset.y}`,
    width: `+=${newContainerSize.x - currentContainerWidth}`,
    height: `+=${newContainerSize.y - currentContainerHeight}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#textinput-text-${id}`,
    width: newShapeSize.x - PADDING_HORIZONTAL,
    height: newShapeSize.y - PADDING_VERTICAL,
    borderRadius: size * (borderRadius / 200),
    fontSize: newFontSize < defaultFontSize ? defaultFontSize : newFontSize,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
  });
  return Promise.all([animation1.finished, animation2.finished]);
};
