import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import produce from "immer";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import { useScale, usePlaySound } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";
import assets from "features/builder/assets";
import { actions } from "features/builder/slice";
import { AssetModel, AssetModelType } from "features/builder/types";
import { Asset } from "./ModelAsset";

export const ModelAssetList = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const selectedSlotIndex = useSelector(
    (state: RootState) => state.builder.selectedSlotIndex
  );
  const show = useSelector(
    (state: RootState) => state.builder.actionMenu.assetList
  );
  const [type, setType] = useState(AssetModelType.DECORATION);
  const slot = useSelector((state: RootState) => state.builder.slot);

  useEffect(() => {
    if (show && !selectedSlotIndex) {
      dispatch(actions.selectSlot(0));
    }
  }, [show]);

  const handleChangeType = (type: AssetModelType) => () => {
    play();
    setType(type);
    ref.current.scrollTop = 0;
  };

  const handleClose = () => {
    play();
    dispatch(actions.updateActionMenu({ key: "assetList", value: false }));
  };

  const handleScroll = (direction: number) => () => {
    if (ref.current) {
      play();
      if (direction > 0) {
        ref.current.scrollTop += 100;
      } else {
        ref.current.scrollTop -= 100;
      }
    }
  };

  const handleAddAssetToSlot = (asset: AssetModel) => () => {
    play();
    const index = slot.findIndex((v) => v?.name === asset.name);
    if (index === -1) {
      const newSlot = produce(slot, (draft) => {
        draft[selectedSlotIndex] = asset;
      });
      dispatch(actions.updateSlot(newSlot));
    } else {
      const newSlot = produce(slot, (draft) => {
        draft[index] = null;
        draft[selectedSlotIndex] = asset;
      });
      dispatch(actions.updateSlot(newSlot));
    }
    dispatch(actions.selectedAssetMesh(asset));
  };

  return (
    <Dialog
      fullScreen
      open={show}
      onClick={handleClose}
      maxWidth={false}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-col-el flex-center absolute inset-0 bg-textcolor/black/50 w-full h-full">
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ transform: `scale(${scale})` }}
          className="flex-row-el items-center justify-start shrink-0 w-[900px] h-[600px] p-3 rounded-[8px] border-[8px] border-solid border-white bg-white/60"
        >
          <CloseBtn close={handleClose} />
          <div className="flex-row-el gap-2 w-full h-full relative rounded-[4px] backdrop-blur-[4px]">
            <div className="flex-col-el gap-1 bg-transparent">
              <div
                onClick={handleChangeType(AssetModelType.DECORATION)}
                className={`flex-col-center w-[80px] h-[80px] cursor-pointer bg-safe border-solid border-2 ${
                  type === AssetModelType.DECORATION
                    ? "border-blue/100"
                    : "border-white"
                }`}
              >
                <p className="text-body text-white break-all">{"かざり"}</p>
              </div>
              <div
                onClick={handleChangeType(AssetModelType.RIVER)}
                className={`flex-col-center w-[80px] h-[80px] cursor-pointer bg-safe border-solid border-2 ${
                  type === AssetModelType.RIVER
                    ? "border-blue/100"
                    : "border-white"
                }`}
              >
                <p className="text-body text-white break-all">{"川"}</p>
              </div>
              <div
                onClick={handleChangeType(AssetModelType.GROUND)}
                className={`flex-col-center w-[80px] h-[80px] cursor-pointer bg-safe border-solid border-2 ${
                  type === AssetModelType.GROUND
                    ? "border-blue/100"
                    : "border-white"
                }`}
              >
                <p className="text-body text-white break-all">{"グランド"}</p>
              </div>
            </div>
            <div className="flex-row-el w-full h-full">
              <div
                ref={ref}
                className="flex-row-el flex-wrap gap-3 w-full h-full invisible-scrollbar"
              >
                {type === AssetModelType.DECORATION &&
                  assets
                    .filter((asset) => asset.name === AssetModelType.DECORATION)
                    .shift()
                    .models.map((model, index) => (
                      <Asset
                        key={index}
                        asset={model}
                        selected={
                          slot.filter((v) => v?.name === model.name).length > 0
                        }
                        select={handleAddAssetToSlot}
                      />
                    ))}
                {type === AssetModelType.RIVER &&
                  assets
                    .filter((asset) => asset.name === AssetModelType.RIVER)
                    .shift()
                    .models.map((model, index) => (
                      <Asset
                        key={index}
                        asset={model}
                        selected={
                          slot.filter((v) => v?.name === model.name).length > 0
                        }
                        select={handleAddAssetToSlot}
                      />
                    ))}
                {type === AssetModelType.GROUND &&
                  assets
                    .filter((asset) => asset.name === AssetModelType.GROUND)
                    .shift()
                    .models.map((model, index) => (
                      <Asset
                        key={index}
                        asset={model}
                        selected={
                          slot.filter((v) => v?.name === model.name).length > 0
                        }
                        select={handleAddAssetToSlot}
                      />
                    ))}
              </div>
            </div>
          </div>
          <div className="flex-col-center gap-20">
            <div
              onClick={handleScroll(-0.1)}
              className="flex-col-center w-[64px] h-[64px] cursor-pointer active:opacity-70"
            >
              <div
                className="flex-col-el relative w-0 h-0 border-solid border-x-transparent border-b-brown/60"
                style={{
                  borderWidth: "0 20px 36px 20px",
                }}
              />
            </div>
            <div
              onClick={handleScroll(0.1)}
              className="flex-col-center w-[64px] h-[64px] cursor-pointer active:opacity-70"
            >
              <div
                className="flex-col-el relative w-0 h-0 border-solid border-x-transparent border-t-brown/60"
                style={{
                  borderWidth: "36px 20px 0 20px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
