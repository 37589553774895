import { useEffect } from "react";
import { InitStartBlock, InitShowLabelTextBlock } from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitStartBlock();
    InitShowLabelTextBlock();
  }, []);

  return (
    <div className="flex-row-view flex-center w-full gap-2">
      <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
        <p
          className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
          style={{
            filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
          }}
        >
          あ
        </p>
      </div>
      <p className="text text-white !text-[30px] opacity-70">▶︎</p>
      <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
        <p
          className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
          style={{
            filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
          }}
        >
          い
        </p>
      </div>
      <p className="text text-white !text-[30px] opacity-70">▶︎</p>
      <div className="flex-col-center p-2 border-solid border-[2px] rounded-[20px] border-white/20">
        <p
          className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30"
          style={{
            filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
          }}
        >
          う
        </p>
      </div>
    </div>
  );
};
