import { SVGProps } from "react";

export const IconMaximize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 20a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v16Z"
      fill="#80EAC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.296 19.607a1.345 1.345 0 0 1-1.901-1.904L15.41 6.689H7.966a1.345 1.345 0 1 1 0-2.689h10.69A1.345 1.345 0 0 1 20 5.344v10.69a1.345 1.345 0 0 1-2.69 0V8.59L6.297 19.607Z"
      fill="#FEFDFE"
    />
  </svg>
);
