import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import { FadeTransition } from "common/elements";
import { usePlaySound, useScale } from "common/utils";
import {
  ColorPicker,
  ItemContainer,
} from "features/creator/property/components";
import { actions } from "features/creator/slice";
import { PanelProps, TabIndex } from "features/creator/drawing/types";

export const PenColorPanel = (props: PanelProps) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [postition, setPoisition] = useState({ x: 0, y: 0 });
  const drawing = useSelector((state: RootState) => state.creator.drawing);

  const handleChangeColor = (color: string) => {
    play();
    dispatch(actions.handleUpdateDrawing({ ...drawing, color: color }));
  };

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPoisition({ x: rect.x, y: rect.y });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <>
      <div ref={ref} />
      <Dialog
        open
        fullScreen
        maxWidth={false}
        sx={{ zIndex: 10000 }}
        TransitionComponent={FadeTransition}
        componentsProps={{
          backdrop: { style: { backgroundColor: "transparent" } },
        }}
        PaperProps={{ style: { backgroundColor: "transparent" } }}
        onPointerDown={(e) => {
          e.stopPropagation();
          props.setSelectedTabeIndex(TabIndex.NONE);
        }}
      >
        <div
          className="flex-row-center !justify-start bg-beige/60 p-[24px] rounded-[12px] border-solid border-white border-[4px] !absolute top-0 left-0 origin-top-left"
          style={{
            transform: `translate(${postition.x}px,${postition.y}px) scale(${scale})`,
          }}
        >
          <ItemContainer
            name="ペンのいろ"
            scale={1}
            width={280}
            height={304}
            right={0}
            left={0}
          >
            <ColorPicker color={drawing.color} onChange={handleChangeColor} />
          </ItemContainer>
        </div>
      </Dialog>
    </>
  );
};
