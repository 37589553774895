import I18n from "i18n-js";
import Constants from "common/constant";
import { LatestLayout } from "features/creator/tutorial/top/stages/Layout";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { next, close } = props;
  return (
    <LatestLayout next={next} close={close}>
      <div className="flex-row-el items-center justify-start w-full p-0 gap-3">
        <div className="flex-col-el flex-center bg-orange/100 rounded-full border-solid border-[4px] border-orange/100 px-1 w-[32px] h-[32px]">
          <p className="text-body text-[22px] text-white leading-[48px]">5</p>
        </div>

        <div className="flex-row-center gap-1">
          <div className="flex-col-center">
            <img
              alt="app card"
              src={`${Constants.assetHost}/assets/images/app_card_user.png`}
              className="w-[30px] h-[36px]"
            />

            <div className="flex-col-el flex-center absolute top-[8px] w-full px-[2px] h-[24px]">
              <div className="w-full h-full bg-white" />
            </div>
          </div>
          <div className="flex-row-el flex-center gap-1">
            <p className="text-body text-[20px] text-textcolor/black text-left">
              {I18n.locale === "ja" ? "アプリの つくりかた" : "アプリの作り方"}
            </p>
          </div>
        </div>
      </div>
    </LatestLayout>
  );
};

export default Step;
