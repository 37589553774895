import * as React from "react";

export const MaterialTriangleSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={42}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m18.475 3.485-15.29 23.79a3 3 0 0 0 2.52 4.62h30.59a3 3 0 0 0 2.52-4.62l-15.29-23.79a3 3 0 0 0-5.05 0Z"
        fill="#F8F1E8"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
