import { useEffect } from "react";
import Constants from "common/constant";
import { InitImageClickBlock, InitImageSetterXBlock } from "./blocks";
import I18n from "i18n-js";

export const Display = () => {
  useEffect(() => {
    InitImageClickBlock();
    InitImageSetterXBlock();
  }, []);

  return (
    <div className="flex-col-el flex-center absolute top-56">
      <div className="flex-row-el flex-center absolute -top-32">
        <p className="text-body text-white text-[20px]">
          {I18n.t("MSG_COURSE_COORDINATE_X")}
        </p>
      </div>

      <div className="flex-col-center w-[450px] h-[5px]">
        <div className="flex-row-el absolute -top-24 left-[195px] cursor-pointer">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/bc572b5812e0937f8ed15e88321cc54c.png`}
            className="w-[60px] h-[60px]"
          />
        </div>

        <div className="flex-row-el absolute -top-24 -right-8 cursor-pointer">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/869a1175dc52418410e65a2e056c6ac7.png`}
            className="w-[60px] h-[60px]"
          />
        </div>

        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-0">
          <p className="text text-white !mb-[35px]">0</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 left-[50%]">
          <p className="text text-white !mb-[35px]">50</p>
        </div>
        <div className="flex-row-center w-[2px] h-[5px] bg-danger !absolute top-0 right-0">
          <p className="text text-white !mb-[35px]">100</p>
        </div>
        <div className="flex-row-center w-full h-[2px] bg-danger !absolute top-[-1px] left-0" />

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px]"
          />
        </div>

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${50 * 4.5}px)` }}
          />
        </div>

        <div className="flex-row-el absolute top-4 -left-8">
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/vehicle/139cf73ef288cfe6d1786914c202a07f.png`}
            className="w-[60px] h-[60px] opacity-30"
            style={{ transform: `translateX(${100 * 4.5}px)` }}
          />
        </div>
      </div>
    </div>
  );
};
