import { PointerEventHandler } from "react";
import I18n from "i18n-js";
import { LevelClearIcon } from "features/courses/assets";

export const Success = (props: {
  scale: number;
  handleBack: () => void;
  handleReset: PointerEventHandler<HTMLButtonElement>;
}) => {
  const { scale, handleBack, handleReset } = props;

  return (
    <div className="flex-col-el flex-center w-full h-full absolute top-0 left-0 z-50 bg-white/70">
      <div
        className="flex-col-center"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <div className="flex-col-center animate-fade-in">
          <div className="flex-col-view w-[696px] h-[264px] mb-[40px]">
            <LevelClearIcon />
          </div>
          <div className="flex-col-view">
            <svg width={608} height={60} viewBox="0 0 608 60">
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="central"
                fill="#3F4A61"
                fontSize="32"
                fontFamily="MPLUSRounded1c-Bold"
                stroke="#FEFDFE"
                strokeWidth={8}
                paintOrder="stroke"
              >
                {I18n.t("MSG_COURSE_GAME_SUCCESS_TEXT")}
              </text>
            </svg>
          </div>

          <div className="flex-row-el flex-center gap-8 mt-10 alpha">
            <button className="btn btn-blue" onPointerDown={handleReset}>
              <p className="text-body text-white text-[20px] !mb-[3px]">
                {I18n.t("MSG_COURSE_GAME_SUCCESS_REVIEW_BTN")}
              </p>
            </button>

            <button className="btn btn-primary" onClick={handleBack}>
              <p className="text-body text-white text-[20px] !mb-[3px]">
                {I18n.t("MSG_COURSE_GAME_SUCCESS_NEXT_LEVEL_BTN")}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
