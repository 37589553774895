import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import Step9 from "./step9";
import Step10 from "./step10";
import Step11 from "./step11";
import Step12 from "./step12";
import Step13 from "./step13";
import Step14 from "./step14";
import Step15 from "./step15";
import Step16 from "./step16";
import Step17 from "./step17";
import Step18 from "./step18";
import Step19 from "./step19";

const Stage = [
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  Step10,
  Step11,
  Step12,
  Step13,
  Step14,
  Step15,
  Step16,
  Step17,
  Step18,
  Step19,
];

export default Stage;
