import { SVGProps } from "react";

export const IconFavorited = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-favorited-a)">
      <path
        fill="#FC6560"
        d="M3 8.88c0-.621.129-1.237.378-1.81a4.676 4.676 0 0 1 1.077-1.53 4.882 4.882 0 0 1 1.608-1.008c.6-.23 1.241-.343 1.887-.331a5.57 5.57 0 0 1 2.218.447A5.387 5.387 0 0 1 12 5.934a5.387 5.387 0 0 1 1.832-1.286 5.57 5.57 0 0 1 2.218-.447 5.026 5.026 0 0 1 1.887.33c.6.23 1.147.574 1.608 1.01.46.435.827.955 1.077 1.528.25.574.378 1.19.378 1.812C21 12 19 12.975 12 19.8 5 12.975 3 12 3 8.88Z"
      />
      <path
        stroke="#FC6560"
        strokeWidth={4}
        d="M3 8.88c0-.621.129-1.237.378-1.81a4.676 4.676 0 0 1 1.077-1.53 4.882 4.882 0 0 1 1.608-1.008c.6-.23 1.241-.343 1.887-.331a5.57 5.57 0 0 1 2.218.447A5.387 5.387 0 0 1 12 5.934a5.387 5.387 0 0 1 1.832-1.286 5.57 5.57 0 0 1 2.218-.447 5.026 5.026 0 0 1 1.887.33c.6.23 1.147.574 1.608 1.01.46.435.827.955 1.077 1.528.25.574.378 1.19.378 1.812C21 12 19 12.975 12 19.8 5 12.975 3 12 3 8.88Z"
      />
    </g>
    <defs>
      <clipPath id="icon-favorited-a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconFavoritedDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-favorited-disabled-a)">
      <path
        fill="#fefdfe"
        d="M3 8.88c0-.621.129-1.237.378-1.81a4.676 4.676 0 0 1 1.077-1.53 4.882 4.882 0 0 1 1.608-1.008c.6-.23 1.241-.343 1.887-.331a5.57 5.57 0 0 1 2.218.447A5.387 5.387 0 0 1 12 5.934a5.387 5.387 0 0 1 1.832-1.286 5.57 5.57 0 0 1 2.218-.447 5.026 5.026 0 0 1 1.887.33c.6.23 1.147.574 1.608 1.01.46.435.827.955 1.077 1.528.25.574.378 1.19.378 1.812C21 12 19 12.975 12 19.8 5 12.975 3 12 3 8.88Z"
      />
      <path
        stroke="#fefdfe"
        strokeWidth={4}
        d="M3 8.88c0-.621.129-1.237.378-1.81a4.676 4.676 0 0 1 1.077-1.53 4.882 4.882 0 0 1 1.608-1.008c.6-.23 1.241-.343 1.887-.331a5.57 5.57 0 0 1 2.218.447A5.387 5.387 0 0 1 12 5.934a5.387 5.387 0 0 1 1.832-1.286 5.57 5.57 0 0 1 2.218-.447 5.026 5.026 0 0 1 1.887.33c.6.23 1.147.574 1.608 1.01.46.435.827.955 1.077 1.528.25.574.378 1.19.378 1.812C21 12 19 12.975 12 19.8 5 12.975 3 12 3 8.88Z"
      />
    </g>
    <defs>
      <clipPath id="icon-favorited-disabled-a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconFavoritedReverse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#FEFDFE"
        stroke="#3F4A61"
        strokeWidth={3}
        d="M4 11.84c0-.829.171-1.65.504-2.414A6.234 6.234 0 0 1 5.94 7.387a6.51 6.51 0 0 1 2.143-1.345 6.701 6.701 0 0 1 2.517-.44 7.426 7.426 0 0 1 2.958.596A7.183 7.183 0 0 1 16 7.912a7.183 7.183 0 0 1 2.442-1.714A7.426 7.426 0 0 1 21.4 5.6a6.702 6.702 0 0 1 2.517.441 6.51 6.51 0 0 1 2.143 1.345 6.234 6.234 0 0 1 1.436 2.039c.333.765.504 1.585.504 2.415C28 16 25.333 17.3 16 26.4 6.667 17.3 4 16 4 11.84Z"
      />
      <path
        stroke="#FEFDFE"
        strokeWidth={4}
        d="M4 11.84c0-.829.171-1.65.504-2.414A6.234 6.234 0 0 1 5.94 7.387a6.51 6.51 0 0 1 2.143-1.345 6.701 6.701 0 0 1 2.517-.44 7.426 7.426 0 0 1 2.958.596A7.183 7.183 0 0 1 16 7.912a7.183 7.183 0 0 1 2.442-1.714A7.426 7.426 0 0 1 21.4 5.6a6.702 6.702 0 0 1 2.517.441 6.51 6.51 0 0 1 2.143 1.345 6.234 6.234 0 0 1 1.436 2.039c.333.765.504 1.585.504 2.415C28 16 25.333 17.3 16 26.4 6.667 17.3 4 16 4 11.84Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconProjectCardFavorited = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-project-card-favorited-a)">
      <path
        fill="#FC6560"
        stroke="#FC6560"
        strokeWidth={4}
        d="M7 16.334c0-.794.16-1.58.472-2.314.311-.733.769-1.4 1.346-1.96a6.112 6.112 0 0 1 2.018-1.298 6.19 6.19 0 0 1 2.378-.428h.047a7.081 7.081 0 0 1 2.883.597c.907.398 1.715.98 2.373 1.708L20 14.279l1.484-1.64a6.984 6.984 0 0 1 2.373-1.708 7.082 7.082 0 0 1 2.883-.596h.047a6.19 6.19 0 0 1 2.378.427 6.112 6.112 0 0 1 2.018 1.298 5.997 5.997 0 0 1 1.346 1.96c.312.734.472 1.52.471 2.314 0 1.102-.167 1.914-.524 2.698-.373.82-1.001 1.717-2.086 2.92-1.233 1.367-2.858 2.93-5.05 5.037-1.498 1.44-3.259 3.132-5.34 5.19-2.08-2.058-3.843-3.752-5.34-5.19-2.192-2.108-3.817-3.67-5.05-5.037-1.084-1.203-1.713-2.1-2.086-2.92C7.168 18.25 7 17.436 7 16.334Z"
      />
    </g>
    <defs>
      <clipPath id="icon-project-card-favorited-a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const IconProjectCardFavoritedReverse = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    fill="none"
    {...props}
  >
    <g clipPath="url(#icon-project-card-favorited-reverse-a)">
      <path
        fill="#FEFDFE"
        stroke="#FC6560"
        strokeWidth={4}
        d="M6.285 14.43v-.001c0-.642.13-1.278.381-1.871a4.854 4.854 0 0 1 1.09-1.587A4.953 4.953 0 0 1 9.392 9.92a5.02 5.02 0 0 1 1.928-.347h.047a5.786 5.786 0 0 1 2.355.487c.74.325 1.4.801 1.937 1.395l1.483 1.64 1.484-1.64a5.7 5.7 0 0 1 1.937-1.395 5.786 5.786 0 0 1 2.355-.486h.047a5.02 5.02 0 0 1 1.928.346c.612.24 1.168.599 1.635 1.052.468.454.838.993 1.09 1.587.252.593.382 1.23.381 1.871 0 .911-.138 1.566-.423 2.194-.302.665-.82 1.409-1.74 2.43-1.052 1.167-2.434 2.495-4.304 4.292-1.235 1.187-2.683 2.579-4.39 4.264-1.707-1.686-3.155-3.078-4.39-4.265-1.87-1.797-3.251-3.124-4.303-4.29-.922-1.022-1.438-1.766-1.74-2.43-.286-.628-.424-1.284-.424-2.195Z"
      />
    </g>
    <defs>
      <clipPath id="icon-project-card-favorited-reverse-a">
        <path fill="#fff" d="M0 .429h34.286v34.286H0z" />
      </clipPath>
    </defs>
  </svg>
);
