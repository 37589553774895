/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "app/store";
import {
  actions,
  selectAllChatRooms,
  selectAllChatRoomMessages,
} from "features/user/slice";
import { markChatRoomMessage } from "features/user/api";
import { Chat } from "./chat";
import { ChatRoomList } from "./ChatRoomList";

export const UserMessage = ({ selected }: { selected: boolean }) => {
  const dispatch = useDispatch<AppDispatch>();
  const chatRooms = useSelector(selectAllChatRooms);
  const messages = useSelector(selectAllChatRoomMessages);
  const selectedChatRoomId = useSelector(
    (state: RootState) => state.user.selectedChatRoomId
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const userChatRooms = useMemo(
    () => chatRooms.filter((chatRoom) => chatRoom.uid === user.active.uid),
    [chatRooms, user.active.uid]
  );

  useEffect(() => {
    return () => {
      dispatch(actions.updateSelectedChatRoomId({ id: null }));
      dispatch(markChatRoomMessage());
    };
  }, [user.active.uid]);

  return (
    <div
      className="flex-col-center w-full h-full"
      style={{ display: !selected && "none" }}
    >
      <div className="flex-row-el relative items-center w-full h-full p-[32px]">
        <div className="flex-col-el relative shrink-0 bg-white rounded-l-[8px] h-full overflow-hidden">
          <div className="flex-col-view w-[280px] h-full invisible-scrollbar">
            <ChatRoomList
              selected={selected}
              uid={user.active.uid}
              userChatRooms={userChatRooms}
              selectedChatRoomId={selectedChatRoomId}
            />
          </div>
        </div>
        {userChatRooms.map((userChatRoom) => (
          <div
            key={userChatRoom.id}
            className="flex-col-el relative overflow-hidden flex-grow h-full p-[16px] rounded-r-[8px] bg-blue/20"
            style={{
              display: userChatRoom.id !== selectedChatRoomId && "none",
            }}
          >
            <Chat
              user={user.active}
              selected={userChatRoom.id === selectedChatRoomId}
              chatRoom={userChatRoom}
              messages={messages?.filter(
                (message) => message.chat_room_id === userChatRoom.id
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
