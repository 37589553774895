import { SVGProps } from "react";

export const ProgressStep3 = (props: SVGProps<SVGSVGElement>) => (
  <svg width={88} height={88} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.254.013c12.962.243 26.04 4.264 34.103 14.78 8.047 10.494 9.094 24.64 6.127 37.684-2.938 12.917-10.242 24.42-21.634 30.49-11.769 6.271-25.632 6.718-37.722 1.14C14.566 78.31 4.471 67.455 1.225 53.612c-3.299-14.067.168-29.208 9.342-40.128C19.312 3.075 32.936-.236 46.254.013Z"
      fill={props.fill}
    />
  </svg>
);
