import { useState, FormEvent } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { Button } from "@mui/material";
import {
  getAuth,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { parentsFirebase } from "app/Auth";
import { usePlaySound } from "common/utils";
import { AccountType } from "features/user/types";
import { RouteProps } from "../type";
import { GoBackBtn } from "../GoBackBtn";

export const MailResetPassword = (props: RouteProps) => {
  const { navigate } = props;
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [required, setRequired] = useState(false);
  const user = useSelector((state: RootState) => state.user.appUser.main);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    try {
      handleDialog({ type: DialogType.LOADING, value: true });
      const auth = getAuth(parentsFirebase);
      const data = new FormData(event.currentTarget);
      const email = data.get("email").toString();
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods.includes("password")) {
        await sendPasswordResetEmail(auth, email);
      }
      toast(
        <div>
          <p className="text text-textcolor/black">
            {I18n.t("MSG_SIGN_IN_FORGOT_PASSWORD_SUCCESS_CONTENT")}
          </p>
        </div>
      );
      setTimeout(() => {
        handleDialog({ type: DialogType.LOADING, value: false });
        navigate("/");
      }, 500);
    } catch (error) {
      console.error(error);
      handleDialog({ type: DialogType.LOADING, value: false });
    }
  };

  const handleGoBack = () => navigate("/mailSignIn");

  return (
    <div className="flex-col-el items-center justify-around w-full h-full py-4">
      <div className="flex-col-center w-[375px] h-[300px]">
        <form className="flex-col-el w-full gap-4" onSubmit={handleSubmit}>
          <div className="flex-col-el">
            <input
              id="email"
              name="email"
              type="email"
              required={required}
              defaultValue={
                user.account_type === AccountType.PARENTS ? user.email : ""
              }
              readOnly={user.account_type === AccountType.PARENTS}
              className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
              placeholder={I18n.t("MSG_SIGN_USER_MAIL")}
              autoFocus={false}
            />
            <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
              {I18n.t("MSG_APP_ERROR_EMAIL_NOT_VALID")}
            </label>
          </div>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => setRequired(true)}
          >
            {I18n.t("MSG_SIGN_IN_FORGOT_PASSWORD_SEND_BTN")}
          </Button>
        </form>
      </div>

      <GoBackBtn handleGoBack={handleGoBack} />
    </div>
  );
};
