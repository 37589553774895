import {
  getShadowColor,
  setShadowColor,
  getShadowRadius,
  setShadowRadius,
  setShadowRadiusByStep,
  setShadowRadiusByTime,
} from "common/blockly/codeGen";

export const getDrawingShadowColor = (id: string) =>
  getShadowColor(`drawing-shape-container-${id}`);
export const setDrawingShadowColor = (id: string, value: string) =>
  setShadowColor(`drawing-shape-container-${id}`, value);

export const getDrawingShadowRadius = (id: string) =>
  getShadowRadius(`drawing-shape-container-${id}`);
export const setDrawingShadowRadius = (id: string, value: number) =>
  setShadowRadius(`drawing-shape-container-${id}`, value);
export const setDrawingShadowRadiusByStep = (id: string, value: number) =>
  setShadowRadiusByStep(`drawing-shape-container-${id}`, value);
export const setDrawingShadowRadiusByTime = (
  id: string,
  time: number,
  value: number
) => setShadowRadiusByTime(`drawing-shape-container-${id}`, time, value);
