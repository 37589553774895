import { Animation } from "@babylonjs/core/Animations/animation";

export const spin = (frameRate: number) => {
  const gemSpin = new Animation(
    "spin",
    "rotation.y",
    frameRate,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_RELATIVE
  );

  const animationKeys = [];
  animationKeys.push({
    frame: 0,
    value: 0,
  });
  animationKeys.push({
    frame: 1 * frameRate,
    value: Math.PI / 4,
  });
  gemSpin.setKeys(animationKeys);
  return gemSpin;
};
