/* eslint-disable no-new-func */
import { useEffect } from "react";
import Constants from "common/constant";
import { sleep, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const sound = usePlaySound(
    `${Constants.assetHost}/assets/blockly/itemget.mp3`
  );
  const { event, highlightBlock, setSuccess, setGameOver } = props;
  const handleEvent = new Function(
    "sleep",
    "sound",
    "highlightBlock",
    "setSuccess",
    "setGameOver",
    event[0]
      ? event[0]
      : `return () => {
          setSuccess(false);
          setGameOver(true);
        }
        `
  );
  useEffect(() => {
    const loading = setTimeout(() => {
      handleEvent(sleep, sound, highlightBlock, setSuccess, setGameOver)();
    }, 300);
    return () => {
      clearTimeout(loading);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex-row-view items-center justify-evenly w-full">
      <div className="flex-col-center">
        <div
          id="course-program-border-1"
          className="flex-col-center w-[100px] h-[100px] !box-content p-2 border-solid border-[4px] rounded-[6px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            id="course-program-image-1"
            alt="*"
            className="w-[100px] h-[100px] opacity-0 absolute z-10 transition duration-700 scale-150"
          />
          <p
            id="course-program-label-1"
            className="text-body text-white text-[96px] leading-[96px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={1} />
        </div>
        <p className="text text-white">ボックス1</p>
      </div>

      <div className="flex-col-center">
        <div
          id="course-program-border-2"
          className="flex-col-center min-w-[100px] h-[100px] !box-content p-2 border-solid border-[4px] rounded-[6px] border-white/20"
        >
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            あ
          </p>
          <img
            alt="*"
            id="course-program-image-2"
            className="w-[100px] h-[100px] opacity-0 absolute z-10 transition duration-700 scale-150"
          />
          <p
            id="course-program-label-2"
            className="text-body text-white text-[96px] leading-[96px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={2} />
        </div>
        <p className="text text-white">ボックス2</p>
      </div>

      <div className="flex-col-center">
        <div
          id="course-program-border-3"
          className="flex-col-center w-[100px] h-[100px] !box-content p-2 border-solid border-[4px] rounded-[6px] border-white/20"
        >
          <img
            alt="*"
            src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            id="course-program-image-3"
            alt="*"
            className="w-[100px] h-[100px] opacity-0 absolute z-10 transition duration-700 scale-150"
          />
          <p
            id="course-program-label-3"
            className="text-body text-white text-[96px] leading-[96px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={3} />
        </div>
        <p className="text text-white">ボックス3</p>
      </div>

      <div className="flex-col-center">
        <div
          id="course-program-border-4"
          className="flex-col-center min-w-[100px] h-[100px] !box-content p-2 border-solid border-[4px] rounded-[6px] border-white/20"
        >
          <p
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-30 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            い
          </p>
          <img
            alt="*"
            id="course-program-image-4"
            className="w-[100px] h-[100px] opacity-0 absolute z-10 transition duration-700 scale-150"
          />
          <p
            id="course-program-label-4"
            className="text-body text-white text-[96px] leading-[96px] opacity-0 z-10 transition duration-700 scale-150"
          />
          <AnswerMark id={4} />
        </div>
        <p className="text text-white">ボックス4</p>
      </div>
    </div>
  );
};
