import { configureStore } from "@reduxjs/toolkit";
import { isDev, isDebugMode } from "common/utils";
import configSlice from "./configSlice";
import userSlice from "features/user/slice";
import creatorSlice from "features/creator/slice";
import builderSlice from "features/builder/slice";
import connectionSlice from "features/connection/slice";
import coursesSlice from "features/courses/coursesSlice";

export const store = configureStore({
  reducer: {
    config: configSlice,
    user: userSlice,
    courses: coursesSlice,
    creator: creatorSlice,
    builder: builderSlice,
    connection: connectionSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: isDev || isDebugMode,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
