export const GuideMessageBalloonSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 500 300">
      <path
        fill="#FEFDFE"
        stroke="#3F4A61"
        strokeWidth="2"
        d="M298.808 3.343h.015a431.944 431.944 0 0171.834 10.861c31.34 7.623 57.54 13.996 80.655 31.3 48.655 36.435 47.9 94.609 47.641 111.949v.001c-.382 28.168-10.896 49.965-16.301 61.169l-.424.881-.001.002c-7.287 15.217-14.28 29.731-30.983 44.014-20.781 17.752-44.001 24.37-57.293 28.012-11.202 3.069-28.596 6.763-81.413 6.018H308.749l-1.058-.024c-21.773-.484-66.377-1.476-86.938.197-6.015.49-51.03 4.042-98.02-3.383l-.005-.001c-32.171-5.096-48.002-7.603-63.418-16.826l-.001-.001C15.287 251.228-.961 192.964 1.186 149.548v-.006l.036-.862c.541-13.122 3.009-72.95 50.027-107.203 21.905-15.939 44.748-20.622 90.973-30.063h0C213.241-3.106 270.738 1.09 298.808 3.343z"
      ></path>
    </svg>
  );
};
