import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import { RootState } from "app/store";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { Spin, FadeTransition, RuntimeGameCaseManager } from "common/elements";
import { Menu } from "features/preview/Menu";

export const ProjectPreview = () => {
  const { scale } = useScale();
  const screenScale = 1000 / 1024;
  const [loading, setLoading] = useState(true);
  const preview = useSelector(
    (state: RootState) => state.config.dialog.project_preview
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const GameCaseComponent = RuntimeGameCaseManager[config.game_case];

  useEffect(() => {
    if (preview.value) {
      setLoading(true);
    }
  }, [preview.value]);

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={preview.value}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      PaperProps={{
        style: { backgroundColor: "transparent", overflow: "hidden" },
      }}
      transitionDuration={{ enter: 500, exit: 500 }}
    >
      <div className="flex-col-center w-full h-full">
        <img
          alt="じゆうにつくる背景"
          src={`${Constants.assetHost}/assets/images/bg_creator.png`}
          className="absolute inset-0 object-cover w-full h-full z-[-1]"
        />

        <div
          className="flex-col-center !absolute"
          style={{
            width: 1116 * scale,
            height: 844 * scale,
            filter: "drop-shadow(0px 0px 3px rgba(63, 74, 97, 0.3))",
          }}
        >
          <div
            className="flex-col-center w-[1116px] h-[844px]"
            style={{ transform: `scale(${scale})` }}
          >
            <div
              className="flex-col-center !absolute top-[39px]"
              style={{
                width: 1036 * screenScale,
                height: 780 * screenScale,
                left: (1116 - 1036 * screenScale) / 2,
              }}
            >
              <div
                className="flex-col-center w-[1036px] h-[780px]"
                style={{ transform: `scale(${screenScale})` }}
              >
                <div className="flex-col-el w-[1038px] h-[782px] absolute -mt-[1px] -ml-[1px] z-[100] pointer-events-none">
                  <div className="flex-col-el w-full h-full rounded-[17px] border-solid border-[1px] border-[#000000]/40 p-1">
                    <div className="flex-col-el w-full h-full rounded-[12px] border-solid border-[2px] border-[#000000]/30 overflow-hidden">
                      <div className="flex-col-el w-full h-full rounded-[10px] border-solid border-[4px] border-[#FFFFFF]/40" />
                    </div>
                  </div>
                </div>

                <div className="flex-col-center w-[1024px] h-[768px] !box-content border-solid border-gray/100 border-[6px] rounded-[16px] overflow-hidden">
                  {preview.args?.projectId && (
                    <iframe
                      className="w-full h-full border-0"
                      title="Live Preview"
                      allow="microphone; camera;"
                      onLoad={(e) => setLoading(false)}
                      src={`${Constants.runtimeHost}/?project_id=${preview.args?.projectId}&source=app&uid=${user.active.uid}`}
                    />
                  )}

                  {loading && (
                    <div className="absolute top-0 left-0 w-full h-full">
                      <Spin />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex-col-view justify-between h-[844px] -z-10">
              <img
                alt="game case"
                src={`${Constants.assetHost}/assets/images/${GameCaseComponent}`}
                className="w-full h-full"
              />
              <img
                alt="アルゴリズム学ぶソフト"
                src={`${Constants.assetHost}/assets/images/course_soft_algorithm.png`}
                className="w-[133px] h-[168px] absolute top-[-20px] left-[87px] z-[-1]"
              />
            </div>
          </div>
        </div>
        <Menu
          projectId={preview.args?.projectId}
          isUserProject={
            preview.args?.isUserProject?.toLocaleLowerCase() === "true"
          }
        />
      </div>
    </Dialog>
  );
};
