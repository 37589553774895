import { ActionModal } from "common/elements";
import {
  ToolboxDef1,
  ToolboxDef2,
  ToolboxDef3,
  ToolboxDef4,
  ToolboxDef5,
} from "./toolbox";
import { BlockCount } from "./BlockCount";
import { useInitWorkspace } from "./initial";

export const ProgramBlock = ({
  show,
  close,
}: {
  show: boolean;
  close: () => void;
}) => {
  useInitWorkspace(show, "blocklyDiv1", ToolboxDef1);
  useInitWorkspace(show, "blocklyDiv2", ToolboxDef2);
  useInitWorkspace(show, "blocklyDiv3", ToolboxDef3);
  useInitWorkspace(show, "blocklyDiv4", ToolboxDef4);
  useInitWorkspace(show, "blocklyDiv5", ToolboxDef5);

  return (
    <ActionModal show={show} close={close}>
      <div className="flex-row-el justify-center gap-3 pt-8 w-full h-full relative bg-orange/alt">
        <div className="flex-col-el">
          <div className="flex-row-el w-[380px]">
            <BlockCount toolbox={ToolboxDef1} />
            <div id="blocklyDiv1" className="flex-col-el w-full h-full" />
          </div>

          <div className="flex-row-el w-[300px]">
            <BlockCount toolbox={ToolboxDef4} />
            <div id="blocklyDiv4" className="flex-col-el w-full h-full" />
          </div>

          <div className="flex-row-el w-[530px]">
            <BlockCount toolbox={ToolboxDef5} />
            <div id="blocklyDiv5" className="flex-col-el w-full h-full" />
          </div>
        </div>

        <div className="flex-col-el">
          <div className="flex-row-el w-[530px]">
            <BlockCount toolbox={ToolboxDef2} />
            <div id="blocklyDiv2" className="flex-col-el w-full h-full" />
          </div>
          <div className="flex-row-el w-[530px]">
            <BlockCount toolbox={ToolboxDef3} />
            <div id="blocklyDiv3" className="flex-col-el w-full h-full" />
          </div>
        </div>
      </div>
    </ActionModal>
  );
};
