import { useEffect } from "react";
import I18n from "i18n-js";
import Constants from "common/constant";
import { Clock } from "features/courses/program/game/components";
import {
  InitMathPlusBlock,
  InitImageClickBlock,
  InitInlineMathNumberBlock,
  InitHandGetterRotateBlock,
  InitHandSetterRotateBlock,
  InitLabelTextShowSetterBlock,
  InitHandGetterRotateInlineBlock,
} from "./blocks";

export const Display = () => {
  useEffect(() => {
    InitMathPlusBlock();
    InitImageClickBlock();
    InitInlineMathNumberBlock();
    InitHandGetterRotateBlock();
    InitHandSetterRotateBlock();
    InitLabelTextShowSetterBlock();
    InitHandGetterRotateInlineBlock();
  }, []);

  return (
    <div className="flex-row-el flex-center gap-8 w-full mt-8">
      <div className="flex-row-el items-end justify-center gap-2">
        <Clock question={{ hour: 1, minutes: 0 }} />
        <img
          alt="*"
          src={`${Constants.assetHost}/stamps/electronic/89ac81d0297168e63110e5778eff4617.png`}
          className="w-[80px] h-[80px]"
        />
      </div>

      <div className="flex-col-el items-start justify-center w-[430px]">
        <div className="flex-row-el items-end justify-center gap-2">
          <div className="flex-col-center">
            <div className="flex-row-center gap-2 p-2 border-solid border-[4px] rounded-[6px] border-white/20">
              <p
                className="text-body text-textcolor/black text-[90px] leading-[90px] opacity-30"
                style={{
                  filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
                }}
              >
                11
              </p>
            </div>
            <p className="text text-white">ボックス1</p>
          </div>

          <div className="flex-col-center">
            <div className="flex-col-center">
              <p className="text-body text-white text-[50px] leading-[50px]">
                {I18n.t("MSG_DATETIME_FORMAT_HOUR")}
              </p>
            </div>
            <div className="h-[30px]" />
          </div>
        </div>

        <div className="flex-row-el items-end justify-center gap-2">
          <div className="flex-col-center">
            <div className="flex-row-center gap-2 p-2 border-solid border-[4px] rounded-[6px] border-white/20">
              <p
                className="text-body text-textcolor/black text-[90px] leading-[90px] opacity-30"
                style={{
                  filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
                }}
              >
                20
              </p>
              <p className="text text-white !text-[50px] opacity-70">→</p>
              <p
                className="text-body text-textcolor/black text-[90px] leading-[90px] opacity-30"
                style={{
                  filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
                }}
              >
                40
              </p>
            </div>
            <p className="text text-white">ボックス2</p>
          </div>

          <div className="flex-col-center">
            <div className="flex-col-center">
              <p className="text-body text-white text-[50px] leading-[50px]">
                {I18n.t("MSG_DATETIME_FORMAT_MINUTE")}
              </p>
            </div>
            <div className="h-[30px]" />
          </div>
        </div>
      </div>
    </div>
  );
};
