/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useOpenCv } from "common/hook";
import { btnDelay } from "common/utils";
import { actions } from "features/connection/slice";
import { PeerConnectionMessageType } from "features/connection/type";
import { Camera } from "./Camera";

export const Recognition = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  useOpenCv({ setInitialized: () => setLoading(false) });

  const handleSendResult = (result: string) => {
    dispatch(
      actions.sendMessage({
        content: {
          type: PeerConnectionMessageType.TANGIBLE,
          data: result,
        },
      })
    );
  };

  return (
    <>
      <Camera
        open={open}
        remote={true}
        setOpen={setOpen}
        success={handleSendResult}
      />

      <div className="flex-row-center w-full h-full">
        <div className="flex-col-center w-full h-full gap-5">
          <div className="flex-row-center flex-wrap w-[70%] gap-5 alpha">
            <button
              disabled={loading}
              className="btn btn-primary"
              onClick={() => btnDelay(() => setOpen(true))}
            >
              <p className="text-body text-white text-[16px] !mb-[3px]">
                カメラを起動する
              </p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
