const ToolboxInfo = {
  maxInstances: {
    controls_whileUntil_colour_var_internal: 1,
    controls_repeat_var_internal: 1,
    controls_if_colour_var_internal: 2,
    character_motion_move: 1,
    character_motion_turn_left: 2,
    math_number: 1,
    colour_picker_internal: 3,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "controls_whileUntil_colour_var_internal",
          },
          {
            kind: "block",
            type: "controls_repeat_var_internal",
          },
          {
            kind: "block",
            type: "controls_if_colour_var_internal",
          },
          {
            kind: "block",
            type: "character_motion_move",
          },
          {
            kind: "block",
            type: "character_motion_turn_left",
          },
          {
            kind: "block",
            type: "math_number",
            fields: {
              NUM: 4,
            },
          },
          {
            kind: "block",
            type: "colour_picker_internal",
            fields: {
              COLOUR: "#fdd73e",
            },
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
