import { SVGProps } from "react";

export const IconClose = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={props.fill ?? "#FEFDFE"}
      d="m9.333 15.556 6.223-6.222 31.111 31.11-6.222 6.223z"
    />
    <path
      fill={props.fill ?? "#FEFDFE"}
      d="m15.555 46.667-6.223-6.223L40.444 9.333l6.222 6.222z"
    />
  </svg>
);
