/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useMemo, useEffect } from "react";
import produce from "immer";
import { Vector2D } from "common/types";
import { ComponentManager, ComponentProperty } from "common/components";
import { PropertiesEntity } from "features/creator/types";
import { ResizePanel } from "./ResizePanel";
import { ComponentMovePanel } from "./ComponentMovePanel";

export const ActionPanel = (props: {
  scale: number;
  zIndex: number;
  selected: boolean;
  groupDelta: Vector2D;
  grouping: boolean;
  groupMoving: boolean;
  propertyEntity: PropertiesEntity;
  onSelected: () => void;
  onActionEnd: (newProperty: ComponentProperty, record?: boolean) => void;
  handleDelete: (id: string) => void;
}) => {
  const PANEL_MARGIN = 48;
  const {
    scale,
    zIndex,
    selected,
    groupDelta,
    grouping,
    groupMoving,
    propertyEntity,
    onSelected,
    onActionEnd,
    handleDelete,
  } = props;
  const { id, typeId, property } = propertyEntity;
  const ChildComponent = useMemo(
    () => ComponentManager[typeId].component.DesignComponent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [localProperty, setLocalProperty] = useState<ComponentProperty>(
    property
  );

  const propertyRef = useRef(property);
  const setProperty = (property: ComponentProperty) => {
    propertyRef.current = property;
    setLocalProperty(property);
  };

  useEffect(() => {
    setProperty(property);
  }, [property]);

  useEffect(() => {
    if (grouping && !groupMoving) {
      const newProperty = produce(propertyRef.current, (draft) => {
        draft.style.transform.translateX += groupDelta.x;
        draft.style.transform.translateY += groupDelta.y;
      });
      onActionEnd(newProperty, false);
    }
  }, [groupMoving]);

  return (
    <>
      <ComponentMovePanel
        id={id}
        selected={selected}
        scale={scale}
        zIndex={zIndex}
        groupDelta={groupDelta}
        grouping={grouping}
        groupMoving={groupMoving}
        panelMargin={PANEL_MARGIN}
        propertyRef={propertyRef}
        setProperty={setProperty}
        onSelected={onSelected}
        onActionEnd={onActionEnd}
        handleDelete={handleDelete}
      >
        <ChildComponent id={id} property={localProperty} />
      </ComponentMovePanel>
      {(selected || grouping) && (
        <>
          <ResizePanel
            id={id}
            scale={scale}
            groupDelta={groupDelta}
            grouping={grouping}
            groupMoving={groupMoving}
            panelMargin={PANEL_MARGIN}
            propertyRef={propertyRef}
            setProperty={setProperty}
            onActionEnd={onActionEnd}
          />
        </>
      )}
    </>
  );
};
