import * as React from "react";

export const MaterialBtnSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={64}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#creator-material-btn-a)">
        <rect width={64} height={30} rx={15} fill="#F8F1E8" />
        <rect
          x={2}
          y={2}
          width={60}
          height={26}
          rx={13}
          stroke="#E6CCA1"
          strokeWidth={4}
        />
      </g>
      <defs>
        <filter
          id="creator-material-btn-a"
          width="150%"
          height="150%"
          x="-25%"
          y="-25%"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.898039 0 0 0 0 0.835294 0 0 0 0 0.721569 0 0 0 1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_707_800"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_707_800"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
