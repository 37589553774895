import { SVGProps } from "react";

export const IconBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Zm3.11-18.336a2 2 0 1 0-2.22-3.328l-6 4a2 2 0 0 0 0 3.328l6 4a2 2 0 1 0 2.22-3.328L15.605 16l3.503-2.336Z"
      fill="#FEFDFE"
    />
  </svg>
);
