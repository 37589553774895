import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Howl } from "howler";
import { Vector3 } from "@babylonjs/core";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { RootState } from "app/store";
import { sleep } from "common/utils";
import Constants from "common/constant";
import {
  PeerConnectionMessageType,
  PeerConnectionMessageContent,
} from "features/connection/type";
import { actions } from "features/connection/slice";
import { Blocks } from "./Blocks";

export type BlockList = {
  color: string;
  bbox: number[];
  area: number;
};

export const Connection = ({
  stage,
  util,
  character,
  scene,
}: {
  stage: number;
  util: any;
  character: AbstractMesh;
  scene: Scene;
}) => {
  const dispatch = useDispatch();
  const [running, _setRunning] = useState(false);
  const runningRef = useRef(running);
  const setRunning = (running: boolean) => {
    _setRunning(running);
    runningRef.current = running;
  };
  const [blockList, setBlockList] = useState([]);
  const [gameBgm, setGameBgm] = useState<Howl | null>(null);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const message = useSelector((state: RootState) => state.connection.receive);

  useEffect(() => {
    setRunning(false);
  }, []);

  useEffect(() => {
    if (gameBgm) {
      gameBgm.play();
    }
    return () => {
      if (gameBgm) {
        gameBgm.stop();
      }
    };
  }, [gameBgm]);

  const handleMessage = async (message: PeerConnectionMessageContent) => {
    try {
      if (runningRef.current || !message) return;
      const { data: blocks, type } = message.content;
      if (type !== PeerConnectionMessageType.TSUMIKI) return;
      setRunning(true);
      setBlockList(blocks);
      console.log(blocks);
      for (let i = 0; i < blocks.length; i++) {
        const block = blocks[blocks.length - i - 1];
        switch (block.type) {
          case "anchor":
            if (!character.isEnabled()) {
              console.log("ready to enable character.");

              if ("AudioContext" in window) {
                const bgmSound = new Howl({
                  mute: !config.bgm.enable,
                  volume: config.bgm.volume,
                  autoplay: false,
                  loop: true,
                  src: [`${Constants.assetHost}/assets/bgm/bgm_game.mp3`],
                });
                setGameBgm(bgmSound);
              }

              character.position.addInPlace(new Vector3(0, 10, 0));
              await sleep(300);
              character.setEnabled(true);
              await util.action.performAppearance(scene, character);
            }
            break;
          case "green":
            await util.action.performMove(scene, character);
            break;
          case "purple":
            await util.action.performJump(scene, character);
            break;
          case "aqua":
            await util.action.performTurnToLeft(scene, character);
            break;
          case "blue":
            await util.action.performTurnToRight(scene, character);
            break;
          default:
            break;
        }
      }

      dispatch(actions.receiveMessage(null));

      if (stage != 1) {
        util.setGameOver(true);
      }

      setRunning(false);
    } catch (error) {
      setRunning(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleMessage(message);
  }, [message]);

  return (
    <div className="flex-col-el">
      <Blocks blocks={blockList} />
    </div>
  );
};
