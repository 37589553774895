import { Label } from "./type";

export const wrapPromise = (f: (resolve: (value: any) => void) => void) =>
  new Promise((resolve) => f(resolve));

export const rotateCurrentImage = (
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  degree: number
) => {
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  // 计算旋转后的图像尺寸
  const radians = (degree * Math.PI) / 180;
  const sin = Math.sin(radians);
  const cos = Math.cos(radians);
  const newWidth = Math.abs(image.width * cos) + Math.abs(image.height * sin);
  const newHeight = Math.abs(image.width * sin) + Math.abs(image.height * cos);
  // 调整canvas大小
  canvas.width = newWidth;
  canvas.height = newHeight;
  // 将原点移动到canvas的中心
  ctx.translate(newWidth / 2, newHeight / 2);
  // 应用旋转
  ctx.rotate(radians);
  // 绘制旋转后的图像
  ctx.drawImage(image, -image.width / 2, -image.height / 2);
};

const tolerance = 0.05;
export const findConditionLabel = (label: Label, labels: Label[]) =>
  labels
    .filter(
      (row) =>
        row.text !== label.text &&
        ((row.boundingPolygon[0].y >
          label.boundingPolygon[0].y * (1 - tolerance) &&
          row.boundingPolygon[0].y <
            label.boundingPolygon[3].y * (1 + tolerance)) ||
          (row.boundingPolygon[3].y >
            label.boundingPolygon[0].y * (1 - tolerance) &&
            row.boundingPolygon[3].y <
              label.boundingPolygon[3].y * (1 + tolerance)))
    )
    .sort((a, b) => {
      if (a.boundingPolygon[0].x < b.boundingPolygon[0].x) {
        return -1;
      } else {
        return 1;
      }
    });
