import { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import { AppPlatform, DeviceNotification, LocalStorageKey } from "app/types";
import { useScale } from "common/utils";

export const useNativeMessage = () => {
  const { width, height } = useScale();
  const [keyboardHeight, _setKeyboardHeight] = useState(0);
  const keyboardHeightRef = useRef(keyboardHeight);
  const setKeyboardHeight = (value: number) => {
    _setKeyboardHeight(value);
    keyboardHeightRef.current = value;
  };
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: RootState) => state.user.appUser);

  const handleUpdateKeyboardPosition = useCallback(
    (enabled: boolean, offset: number) => {
      if (
        window.ReactNativeWebView &&
        (window.kidsAppPlatform === AppPlatform.Android ||
          window.kidsAppPlatform === AppPlatform.Amazon)
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "keyboardAvoidingViewConfig",
            data: {
              enabled: enabled,
              behavior: "position",
              keyboardVerticalOffset: offset,
            },
          })
        );
      }
    },
    []
  );

  const handleMessage = (message) => {
    try {
      const { command, data } = JSON.parse(message.data);
      switch (command) {
        case "notifications":
          const { status: pushStatus } = data;
          if (pushStatus === DeviceNotification.NOT_DETERMINED) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                command: "registerNotifications",
                data: { uid: user.active.uid },
              })
            );
          }
          dispatch(
            actions.updateDeviceNotification({ notification: pushStatus })
          );
          break;
        case "keyboardDidShow":
          const { event } = data;
          if (event.height !== keyboardHeightRef.current) {
            localStorage.setItem(LocalStorageKey.KEYBOARD_HEIGHT, event.height);
            setKeyboardHeight(event.height);
          }
          break;
        case "keyboardDidHide":
          handleUpdateKeyboardPosition(false, 0);
          break;
        default:
          break;
      }
    } catch (error) {
      console.warn(JSON.stringify(message));
      console.warn(JSON.stringify(error));
    }
  };

  useEffect(() => {
    const handleInput = (event: Event) => {
      const element = event.target as HTMLElement;
      if (
        element &&
        (element.tagName === "INPUT" || element.tagName === "TEXTAREA")
      ) {
        const rect = element.getBoundingClientRect();
        if (keyboardHeightRef.current > 0) {
          const enabled = height - rect.bottom < keyboardHeightRef.current;
          const offset = enabled ? rect.bottom - height + 10 : 0;
          handleUpdateKeyboardPosition(enabled, offset);
        }
      }
    };
    if (
      window.ReactNativeWebView &&
      (window.kidsAppPlatform === AppPlatform.Android ||
        window.kidsAppPlatform === AppPlatform.Amazon)
    ) {
      window.addEventListener("focus", handleInput, true);
    }
    return () => {
      if (
        window.ReactNativeWebView &&
        (window.kidsAppPlatform === AppPlatform.Android ||
          window.kidsAppPlatform === AppPlatform.Amazon)
      ) {
        window.removeEventListener("focus", handleInput, true);
      }
    };
  }, []);

  useEffect(() => {
    if ("ReactNativeWebView" in window) {
      if (
        window.kidsAppPlatform === AppPlatform.iOS ||
        window.kidsAppPlatform === AppPlatform.Android
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            command: "getNotifications",
          })
        );
      }

      if (window.kidsAppPlatform === AppPlatform.iOS) {
        window.addEventListener("message", handleMessage);
      } else {
        document.addEventListener("message", handleMessage);
      }

      if (
        window.kidsAppPlatform === AppPlatform.Android ||
        window.kidsAppPlatform === AppPlatform.Amazon
      ) {
        const keyboardHeight = localStorage.getItem(
          LocalStorageKey.KEYBOARD_HEIGHT
        );
        if (keyboardHeight) {
          setKeyboardHeight(Number(keyboardHeight));
        }
      }
    }

    return () => {
      if ("ReactNativeWebView" in window) {
        if (window.kidsAppPlatform === AppPlatform.iOS) {
          window.removeEventListener("message", handleMessage);
        } else {
          document.removeEventListener("message", handleMessage);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleUpdateKeyboardPosition(false, 0);
  }, [width, height]);
};
