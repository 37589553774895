import { Display } from "./Display";
import { Runtime } from "./Runtime";
import ToolboxInfo from "./ToolboxInfo";
import { GameSceneType } from "features/courses/program/game/stages/types";

const GameScene: GameSceneType = {
  Display: Display,
  Runtime: Runtime,
  ToolboxInfo: ToolboxInfo,
};

export default GameScene;
