import Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import Constants from "common/constant";
import { BlocklyJsOperator } from "common/blockly";
import { showAnswerSnippetByClickIndex } from "features/courses/program/game/blockly/uitls";

export const InitButtonClickBlock = () => {
  Blockly.Blocks["program_button_click_event_block"] = {
    init: function () {
      const components = [
        ["ボタン１", "1"],
        ["ボタン２", "2"],
      ];
      this.appendDummyInput()
        .appendField(new Blockly.FieldDropdown(components), "ComponentIndex")
        .appendField("%{BKY_EVENT_CLICK}");
      this.appendStatementInput("DO");
      this.setStyle("text_blocks");
    },
  };
  BlocklyJs["program_button_click_event_block"] = function (
    block: Blockly.Block
  ) {
    const logic = BlocklyJs.statementToCode(block, "DO");
    const result = logic !== "";
    const code = `
      return async () => {
        setRunning(true);
        let target;
        let answerMark;
        let targetLabel;
        let targetImage;
        highlightBlock("${block.id}", true);
        await sleep(100);
        highlightBlock("${block.id}", false);
        if(${result}){
          ${logic}
          setDisabled(false);
          setClickedIndex(clickedIndex + 1);
          if(clickedIndex > 0){
            setSuccess(!result.includes(false));
            setGameOver(true);
          } else {
            setRunning(false);
          };
        } else {
          setSuccess(false);
          setGameOver(true);
        };
      }
    `;
    return code;
  };
};

export const InitImageSetterBlock = () => {
  Blockly.Blocks["image_setter_block"] = {
    init: function () {
      const input = this.appendDummyInput();
      const components = [["ボックス1", "1"]];
      const options = [
        [
          {
            src: `${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
            width: 30,
            height: 30,
            alt: "Fragaria",
          },
          `Fragaria#${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
            width: 30,
            height: 30,
            alt: "Pineapple",
          },
          `Pineapple#${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`,
        ],
        [
          {
            src: `${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
            width: 30,
            height: 30,
            alt: "Grape",
          },
          `Grape#${Constants.assetHost}/stamps/food/f0441f75aa862c94ed0c484b3911bd1c.png`,
        ],
      ];
      input
        .appendField(new Blockly.FieldDropdown(components), "Component")
        .appendField("を")
        .appendField(new Blockly.FieldDropdown(options), "Image")
        .appendField("にする");
      this.setInputsInline(true);
      this.setPreviousStatement(true);
      this.setNextStatement(true);
      this.setColour("#FFAB19");
    },
  };
  BlocklyJs["image_setter_block"] = function (block: Blockly.Block) {
    const selectedValue = block.getField("Image").getValue().split("#");
    const selectedImage = selectedValue[0];
    const selectedImageSrc = selectedValue[1];
    return `
        highlightBlock("${block.id}", true);
        targetImage = document.getElementById("course-program-image-1");
        targetLabel = document.getElementById("course-program-label-1");
        targetLabel.style.opacity = "0";
        if(targetImage instanceof HTMLImageElement){
          targetImage.src = "${selectedImageSrc}";
          targetImage.style.opacity = "1";
          await anime({
            targets: targetImage,
            scale: [1.5, 1],
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        };
        result[clickedIndex] = targetEles[clickedIndex] === "${selectedImage}";
        ${showAnswerSnippetByClickIndex()}
        if(!result[clickedIndex]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        await sleep(700);
        setResult(result);
        if(clickedIndex === 0){
          if(targetImage instanceof HTMLImageElement){
            targetImage.style.opacity = "0";
          }
          if(targetLabel instanceof HTMLParagraphElement){
            targetLabel.innerText = "";
            targetLabel.style.opacity = "0";
          }
          target = document.getElementById("course-program-answer-image-1");
          if(target instanceof HTMLImageElement){
            target.style.opacity = "0";
          }
          target = document.getElementById("course-program-answer-label-1");
          if(target instanceof HTMLParagraphElement){
            target.style.opacity = "0.3";
          }
          answerMark = document.getElementById("course-program-answer-mark-right-1");
          answerMark.style.opacity = "0";
          answerMark = document.getElementById("course-program-answer-mark-wrong-1");
          answerMark.style.opacity = "0";  
        };
        highlightBlock("${block.id}", false);
    `;
  };
};

export const InitLabelTextSetterBlock = () => {
  Blockly.Blocks["label_text_setter_block"] = {
    max: 1,
    init: function () {
      this.jsonInit({
        type: "set_label_text_block",
        message0: "%1を%2にする",
        args0: [
          {
            type: "field_dropdown",
            name: "label",
            options: [["ボックス1", "1"]],
          },
          {
            type: "input_value",
            name: "value",
            check: "String",
          },
        ],
        previousStatement: null,
        nextStatement: null,
        style: "text_blocks",
      });
    },
  };
  BlocklyJs["label_text_setter_block"] = function (block: Blockly.Block) {
    const value = BlocklyJs.valueToCode(
      block,
      "value",
      BlocklyJsOperator.ORDER_NONE
    );
    return `
        highlightBlock("${block.id}", true);
        targetLabel = document.getElementById("course-program-label-1");
        targetImage = document.getElementById("course-program-image-1");
        targetImage.style.opacity = "0";
        if(targetLabel instanceof HTMLParagraphElement){
          targetLabel.innerText = ${value};
          targetLabel.style.opacity = "1";
          await anime({
            targets: targetLabel,
            scale: [1.5, 1],
            duration: 700,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        }
        result[clickedIndex] = targetEles[clickedIndex] === ${value};
        ${showAnswerSnippetByClickIndex()}
        await sleep(700);
        if(!result[clickedIndex]){
          setSuccess(false);
          setGameOver(true);
          return;
        };
        if(clickedIndex === 0){
          if(targetImage instanceof HTMLImageElement){
            targetImage.src = "";
            targetImage.style.opacity = "0";
          };
          if(targetLabel instanceof HTMLParagraphElement){
            targetLabel.innerText = "";
            targetLabel.style.opacity = "0";
          };
          target = document.getElementById("course-program-answer-image-1");
          if(target instanceof HTMLImageElement){
            target.style.opacity = "0";
          };
          target = document.getElementById("course-program-answer-label-1");
          if(target instanceof HTMLParagraphElement){
            target.style.opacity = "0.3";
          };
          answerMark = document.getElementById("course-program-answer-mark-right-1");
          answerMark.style.opacity = "0";
          answerMark = document.getElementById("course-program-answer-mark-wrong-1");
          answerMark.style.opacity = "0";  
        };
        highlightBlock("${block.id}", false);
    `;
  };
};
