import Rect from "react";
import Constants, { BASE_HEIGHT, BASE_WIDTH } from "common/constant";

const titles = ["ビルダー", "マップ"];

export const LevelCard = Rect.memo(
  (props: {
    stage: number;
    width: number;
    height: number;
    cardScale: number;
    selected: boolean;
  }) => {
    const { stage, width, height, cardScale, selected } = props;
    const scaleX = width / BASE_WIDTH;
    const scaleY = height / BASE_HEIGHT;
    const scale = scaleX < scaleY ? scaleX : scaleY;

    return (
      <div
        className="flex-col-el shrink-0"
        style={{
          borderRadius: 12 * cardScale,
          width: width * cardScale,
          height: height * cardScale,
          boxShadow: selected && "0px 0px 6px 1px rgba(63, 74, 97, 0.3)",
        }}
      >
        <div
          className="flex-col-view origin-top-left border-solid border-textcolor/black overflow-hidden"
          style={{
            borderWidth: 4,
            borderRadius: 12,
            width: width,
            height: height,
            transform: `scale(${cardScale})`,
          }}
        >
          <img
            alt="ステージの背景画像"
            src={`${Constants.assetHost}/assets/images/bg_course_program_stage_${stage}.webp`}
            onError={(e) =>
              // @ts-ignore
              (e.target.src = `${Constants.assetHost}/assets/images/bg_course_program_stage_${stage}.jpg`)
            }
            className="w-full h-full absolute inset-0 z-[-2] object-cover"
          />

          <div className="flex-col-center w-[16px] h-full bg-red/60 !absolute top-0 left-0 z-50">
            <div className="flex-row-view items-center w-[280px] h-[48px] bg-white/80 border-solid border-[1px] border-l-0 border-white rounded-r-[8px] !absolute top-[20px] left-[16px]">
              <div className="flex-col-center w-[48px] h-[48px] bg-red/100 rounded-br-[10px]">
                <p className="title-text text-white !text-[39px]">{stage}</p>
              </div>
              <div className="flex-col-center ml-[12px]">
                <p className="text text-textcolor/black !text-[32px]">
                  {titles[stage - 1]}
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex-col-view !flex-1"
            style={{
              width: width,
              transform: `translateY(${Math.floor(166 * scale)}px)`,
            }}
          ></div>
        </div>
      </div>
    );
  }
);
