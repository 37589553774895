/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import dayjs from "dayjs";
import Blockly from "blockly";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Scene } from "@babylonjs/core/scene";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { Dialog } from "common/blockly";
import { Loading } from "common/elements";
import { MainScenePage } from "./scene";
import { BlocklyWorkspace } from "./blockly";

export const FreeMapGamePage = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { width, scale } = useScale();
  const [event, setEvent] = useState(null);
  const [reload, _setReload] = useState(false);
  const reloadRef = useRef(reload);
  const setReload = (reload: boolean) => {
    reloadRef.current = reload;
    _setReload(reload);
  };
  const [loading, setLoading] = useState(true);
  const [gameStart, setGameStart] = useState(false);

  const [scene, setScene] = useState<Scene | null>(null);

  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);

  const handleHideChaff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target && workspace) {
      var target = e.target as HTMLElement;

      while (target) {
        const className = target.getAttribute("class");
        if (className !== null && className.indexOf("blocklySelected") !== -1) {
          return;
        }
        target = target.parentElement;
      }
      workspace.hideChaff(true);
    }
  };

  return (
    <div
      onClick={handleHideChaff}
      className="flex-col-view w-full h-full bg-transparent"
    >
      <Dialog workspace={workspace} />

      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_scene.jpg`}
        className="absolute top-0 left-0 w-full h-full"
      />

      <div className="flex-col-view items-start w-full h-full z-[1] opacity-100 pointer-events-none">
        <div
          className="flex-col-view !flex-1 w-full h-full pointer-events-none"
          style={{
            marginTop: 32 * scale,
          }}
        >
          <div
            id="game-main-scene-canvas"
            className="flex-col-view !absolute left-0 h-full rounded-[10px] pointer-events-none"
            style={{
              width: width,
              padding: `0px ${10 * scale}px ${10 * scale}px`,
            }}
          >
            <MainScenePage
              reload={reload}
              setReload={setReload}
              scene={scene}
              gameStart={gameStart}
              setGameStart={setGameStart}
              setScene={setScene}
              workspace={workspace}
              event={event}
              setLoading={setLoading}
            />
          </div>
        </div>

        <div
          className="flex-col-view !absolute top-0 left-0 !flex-1 h-full w-full rounded-[10px] z-20 pointer-events-none"
          style={{
            padding: `0px ${10 * scale}px ${17 * scale}px`,
          }}
        >
          <BlocklyWorkspace
            scale={scale}
            workspace={workspace}
            setWorkspace={setWorkspace}
          />
        </div>
      </div>

      {loading && <Loading />}
    </div>
  );
};
