import { PointerEvent } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { IconCopy } from "common/assets";
import { usePlaySound } from "common/utils";
import { useActionCommand } from "features/creator/utils";
import { ActionCommandType, ClipboardTargetType } from "features/creator/types";

export const CopyBtn = () => {
  const play = usePlaySound();
  const handleAction = useActionCommand();
  const selectedScreenId = useSelector(
    (state: RootState) => state.creator.selectedScreenId
  );
  const selectedComponentId = useSelector(
    (state: RootState) => state.creator.selectedComponentId
  );
  const grouping = useSelector((state: RootState) => state.creator.grouping);

  const handleCopy = (e: PointerEvent) => {
    play();
    e.stopPropagation();
    if (grouping.enable) {
      handleAction({
        type: ActionCommandType.COPY,
        targetType: ClipboardTargetType.GROUPING,
      });
    } else if (selectedScreenId === selectedComponentId) {
      handleAction({
        type: ActionCommandType.COPY,
        targetType: ClipboardTargetType.SCREEN,
      });
    } else {
      handleAction({
        type: ActionCommandType.COPY,
        targetType: ClipboardTargetType.COMPONENT,
      });
    }
    toast.info(
      <div>
        <p className="text text-textcolor/black">{I18n.t("MSG_TOAST_COPY")}</p>
      </div>
    );
  };

  return (
    <div className="flex-col-el flex-center h-full relative upsilon-square">
      <button
        id="creator-copy-btn"
        className="btn btn-secondary"
        onPointerDown={handleCopy}
      >
        <div className="flex-col-el flex-center relative w-[32px] h-[32px] mb-[3px]">
          <IconCopy width={2} />
        </div>
      </button>
    </div>
  );
};
