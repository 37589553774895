import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { useQuery } from "@tanstack/react-query";
import { AppDispatch, RootState } from "app/store";
import Constants from "common/constant";
import { btnDelay, usePlaySound } from "common/utils";
import {
  IconCircleArrowLeft,
  IconCircleArrowRight,
} from "features/creator/assets";
import { actions } from "features/creator/slice";
import { getUserRanking } from "features/creator/api";
import { DisplayMode, ProjectType } from "features/creator/types";
import { Period } from "./Period";
import { Top3Title } from "./Top3Title";
import { NumberPlateGreen } from "./elements";
import { Path, PERIOD, RANKING_RANGE } from "./type";
import { UserDetailPanel, ProjectViewedWithIcon } from "./UserDetailPanel";

const RankingTop3 = ({
  users,
  selectUser,
}: {
  users: any[];
  selectUser: (user: any, ranking: number) => () => void;
}) => {
  return (
    <div
      id="user-ranking-top-3"
      className="flex-row-el justify-center relative mt-7 gap-2 w-[664px] h-[270px]"
    >
      {users.map((user, index) => (
        <div
          key={user.uid}
          onClick={selectUser(user, index + 1)}
          style={{ order: index === 1 ? -9999 : index + 1 }}
          className="clickable flex-col-el items-center justify-start shrink-0 w-[216px]"
        >
          <img
            alt="トロフィー"
            className={`w-[140px] absolute -z-0 bottom-7 ${
              index === 0
                ? "h-[156px]"
                : index === 1
                ? "h-[140px]"
                : "h-[132px]"
            }`}
            src={`${Constants.assetHost}/assets/images/${
              index === 0
                ? "trophy"
                : index === 1
                ? "trophy_silver"
                : "trophy_bronze"
            }.png`}
          />

          <Top3Title order={index + 1} title={user.display_name} />

          <div
            key={user.uid}
            className={`flex-col-el flex-center relative w-[104px] h-[128px] ${
              index === 0 ? "mt-1" : index === 1 ? "mt-5" : "mt-7"
            }`}
          >
            <div
              className="flex-col-center w-[112px] h-[112px] cursor-pointer bg-white rounded-full"
              style={{
                filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
              }}
            >
              <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                <img
                  alt="user icon"
                  src={user.icon}
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>

          <div className="flex-row-el flex-center absolute -bottom-4">
            <ProjectViewedWithIcon viewed={Number(user.viewed)} />
          </div>
        </div>
      ))}
    </div>
  );
};

const RankingTop10 = ({
  users,
  selectUser,
}: {
  users: any[];
  selectUser: (user: any, ranking: number) => () => void;
}) => {
  return (
    <div
      id="user-ranking-top-10"
      className="flex-row-el flex-center flex-wrap relative gap-4 mt-7 w-[656px] alpha"
    >
      {users.map((user, index) => (
        <div
          key={user.uid}
          onClick={selectUser(user, index + 4)}
          style={{ boxShadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.25)" }}
          className="clickable flex-row-el bg-beige/20 gap-1 rounded-xl w-[320px] h-[80px] px-2 py-2"
        >
          <div
            className="flex-col-center w-[32px] h-[32px] rounded-full"
            style={{
              background:
                "radial-gradient(100% 100% at 0% 0%, #FFD1A1 0%, #F8BD7E 59.9%, #FF8300 100%)",
            }}
          >
            <div
              className="flex-col-center h-[30px]"
              style={{
                filter:
                  "drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))",
              }}
            >
              <p
                className="text-body text-[22px] text-[#F27D00] leading-8"
                style={{ WebkitTextStroke: "6px #FEFDFE" }}
              >
                <span
                  className="inline-block leading-8"
                  style={{
                    WebkitTextStroke: "0",
                    position: "absolute",
                  }}
                >
                  {index + 4}
                </span>
                {index + 4}
              </p>
            </div>
          </div>

          <div className="flex-col-el">
            <div className="flex-col-el w-[200px]">
              <p className="text-body text-textcolor/black text-[20px] whitespace-nowrap overflow-hidden text-ellipsis">
                {user.display_name}
              </p>
            </div>

            <ProjectViewedWithIcon viewed={Number(user.viewed)} />
          </div>

          <div
            className="flex-col-center w-[64px] h-[64px] cursor-pointer bg-white rounded-full"
            style={{
              filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
            }}
          >
            <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
              <img alt="user icon" src={user.icon} className="w-full h-full" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const RankingTopOthers = ({
  startIndex,
  users,
  selectUser,
}: {
  startIndex: number;
  users: any[];
  selectUser: (user: any, ranking: number) => () => void;
}) => {
  return (
    <div
      id={`user-ranking-top-${startIndex + 10}`}
      className="flex-row-el flex-wrap relative gap-4 mt-7 w-[640px] alpha"
    >
      {users.map((user, index) => (
        <div
          key={user.uid}
          onClick={selectUser(user, startIndex + index)}
          style={{ boxShadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.25)" }}
          className="clickable flex-row-el items-center bg-beige/20 rounded-xl w-[312px] h-[40px] px-1 py-1"
        >
          <div className="flex-col-el flex-center w-[40px] h-[32px]">
            <NumberPlateGreen number={startIndex + index} />
          </div>

          <div className="flex-row-el items-center gap-1 w-[140px] mr-1">
            <div
              className="flex-col-center w-[24px] h-[24px] cursor-pointer bg-white rounded-full"
              style={{
                filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
              }}
            >
              <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                <img
                  alt="user icon"
                  src={user.icon}
                  className="w-full h-full"
                />
              </div>
            </div>

            <p className="text-body text-textcolor/black text-[20px] whitespace-nowrap overflow-hidden text-ellipsis">
              {user.display_name}
            </p>
          </div>

          <ProjectViewedWithIcon viewed={Number(user.viewed)} />
        </div>
      ))}
    </div>
  );
};

export const RankingUser = ({
  path,
  goToHome,
}: {
  path: Path;
  goToHome: () => void;
}) => {
  const rangeMapping = [
    RANKING_RANGE.TOP_3,
    RANKING_RANGE.TOP_10,
    RANKING_RANGE.TOP_20,
    RANKING_RANGE.TOP_30,
    RANKING_RANGE.TOP_40,
    RANKING_RANGE.TOP_50,
  ];
  const play = usePlaySound();
  const [max, setMax] = useState(0);
  const [init, setInit] = useState(false);
  const { data } = useQuery({
    queryKey: ["creator/getUserRanking"],
    queryFn: () => getUserRanking({ project_types: [ProjectType.BASIC] }),
    staleTime: Infinity,
    placeholderData: { all: [], recently: [] },
  });
  const [detail, setDetail] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [rangeIndex, setRangeIndex] = useState(0);
  const [animation, setAnimation] = useState(false);
  const [period, setPeriod] = useState(PERIOD.RECENTLY);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [selectedUserRaking, setSelectedUserRaking] = useState<number>(0);
  const displayMode = useSelector(
    (state: RootState) => state.creator.displayMode
  );
  const panelAction = useSelector((state: RootState) => state.creator.action);

  useEffect(() => {
    if (
      displayMode === DisplayMode.RANKING &&
      path === Path.USER &&
      panelAction.back &&
      !selectedUser
    ) {
      goToHome();
      dispatch(
        actions.updateProjectPanelAction({
          ...panelAction,
          back: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelAction.back]);

  useEffect(() => {
    setDetail(false);
    setRangeIndex(0);
    setPeriod(PERIOD.RECENTLY);
    if (path === Path.USER) {
      setTimeout(() => setInit(true), 300);
    } else {
      setInit(false);
    }
    return () => {
      if (selectedUser) {
        setSelectedUser(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  useEffect(() => {
    setRangeIndex(0);
    if (period === PERIOD.RECENTLY) {
      if (data.recently.length < 3) {
        setMax(0);
      } else if (data.recently.length < 10) {
        setMax(1);
      } else if (data.recently.length < 20) {
        setMax(2);
      } else if (data.recently.length < 30) {
        setMax(3);
      } else if (data.recently.length < 40) {
        setMax(4);
      } else {
        setMax(5);
      }
    } else {
      if (data.all.length < 3) {
        setMax(0);
      } else if (data.all.length < 10) {
        setMax(1);
      } else if (data.all.length < 20) {
        setMax(2);
      } else if (data.all.length < 30) {
        setMax(3);
      } else if (data.all.length < 40) {
        setMax(4);
      } else {
        setMax(5);
      }
    }
  }, [period, data.all.length, data.recently.length]);

  const handleChangeRange = (direction: number) => async () => {
    if (!animation) {
      play();
      setAnimation(true);
      if (direction < 0) {
        if (rangeIndex !== 0) {
          await anime({
            targets: document.getElementById(
              `user-ranking-${rangeMapping[rangeIndex]}`
            ),
            translateX: [0, "100%"],
            duration: 500,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
          setRangeIndex(rangeIndex - 1);
          await anime({
            targets: document.getElementById(
              `ranking-${rangeMapping[rangeIndex - 1]}`
            ),
            translateX: ["-100%", 0],
            duration: 500,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        }
      } else {
        if (rangeIndex !== rangeMapping.length - 1) {
          await anime({
            targets: document.getElementById(
              `user-ranking-${rangeMapping[rangeIndex]}`
            ),
            translateX: [0, "-100%"],
            duration: 500,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
          setRangeIndex(rangeIndex + 1);
          await anime({
            targets: document.getElementById(
              `user-ranking-${rangeMapping[rangeIndex + 1]}`
            ),
            translateX: ["100%", 0],
            duration: 500,
            autoplay: true,
            easing: "easeInOutQuad",
          }).finished;
        }
      }
      setAnimation(false);
    }
  };

  const handleSelectUser = (user: any, ranking: number) => () => {
    btnDelay(async () => {
      play();
      document.getElementById("ranking-user-viewed-banner").style.zIndex = "0";
      setSelectedUser(user);
      setSelectedUserRaking(ranking);
      setDetail(true);
    });
  };

  const handleCloseDetail = () => {
    if (init) {
      btnDelay(async () => {
        play();
        setDetail(false);
        setTimeout(() => {
          document.getElementById("ranking-user-viewed-banner").style.zIndex =
            "10000";
          setSelectedUser(null);
        }, 1000);
      });
    }
  };

  return (
    <>
      <UserDetailPanel
        path={path}
        show={detail}
        user={selectedUser}
        ranking={selectedUserRaking}
        close={handleCloseDetail}
      />

      {init && (
        <>
          <div className="flex-col-el items-center w-full h-full absolute top-0 left-0 -z-0 overflow-hidden">
            <div className="flex-row-center gap-6 mt-4">
              <img
                className="w-[400px] h-[54px]"
                alt="creator ranking top title"
                src={`${Constants.assetHost}/assets/images/creator_ranking_viewed_title.png`}
              />
              <div className="flex-col-center bg-orange/100 px-2 rounded-lg w-[90px] -mr-6">
                <p className="text-body text-white text-[20px]">
                  {rangeIndex === 0 && "TOP3"}
                  {rangeIndex === 1 && "4〜9"}
                  {rangeIndex === 2 && "10〜19"}
                  {rangeIndex === 3 && "20〜29"}
                  {rangeIndex === 4 && "30〜39"}
                  {rangeIndex === 5 && "40〜49"}
                </p>
              </div>
            </div>

            <Period period={period} setPeriod={setPeriod} />

            <div className="flex-row-center">
              {rangeMapping[rangeIndex] === RANKING_RANGE.TOP_3 && (
                <RankingTop3
                  users={
                    period === PERIOD.RECENTLY
                      ? data.recently.slice(0, 3)
                      : data.all.slice(0, 3)
                  }
                  selectUser={handleSelectUser}
                />
              )}
              {rangeMapping[rangeIndex] === RANKING_RANGE.TOP_10 && (
                <RankingTop10
                  users={
                    period === PERIOD.RECENTLY
                      ? data.recently.slice(3, 9)
                      : data.all.slice(3, 9)
                  }
                  selectUser={handleSelectUser}
                />
              )}
              {rangeMapping[rangeIndex] === RANKING_RANGE.TOP_20 && (
                <RankingTopOthers
                  startIndex={10}
                  users={
                    period === PERIOD.RECENTLY
                      ? data.recently.slice(9, 19)
                      : data.all.slice(9, 19)
                  }
                  selectUser={handleSelectUser}
                />
              )}
              {rangeMapping[rangeIndex] === RANKING_RANGE.TOP_30 && (
                <RankingTopOthers
                  startIndex={20}
                  users={
                    period === PERIOD.RECENTLY
                      ? data.recently.slice(19, 29)
                      : data.all.slice(19, 29)
                  }
                  selectUser={handleSelectUser}
                />
              )}
              {rangeMapping[rangeIndex] === RANKING_RANGE.TOP_40 && (
                <RankingTopOthers
                  startIndex={30}
                  users={
                    period === PERIOD.RECENTLY
                      ? data.recently.slice(29, 39)
                      : data.all.slice(29, 39)
                  }
                  selectUser={handleSelectUser}
                />
              )}
              {rangeMapping[rangeIndex] === RANKING_RANGE.TOP_50 && (
                <RankingTopOthers
                  startIndex={40}
                  users={
                    period === PERIOD.RECENTLY
                      ? data.recently.slice(39, 49)
                      : data.all.slice(39, 49)
                  }
                  selectUser={handleSelectUser}
                />
              )}
            </div>
          </div>
          <div className="flex-row-el flex-center gap-2 absolute right-6 bottom-2 z-[20001] pointer-events-auto">
            <div className="flex-col-center upsilon">
              <button
                disabled={rangeIndex === 0}
                className="btn btn-primary !m-0 !px-2"
                onClick={handleChangeRange(-1)}
              >
                <div className="flex-col-center w-[40px] h-[40px]">
                  <IconCircleArrowLeft />
                </div>
                <p className="text-body text-white text-[16px]">
                  {rangeIndex === 0 && "〜"}
                  {rangeIndex === 1 && "〜3"}
                  {rangeIndex === 2 && "〜9"}
                  {rangeIndex === 3 && "〜19"}
                  {rangeIndex === 4 && "〜29"}
                  {rangeIndex === 5 && "〜39"}
                </p>
              </button>
            </div>

            <div className="flex-col-center upsilon">
              <button
                disabled={rangeIndex === 5 || rangeIndex + 1 > max}
                className="btn btn-primary !m-0 !px-2"
                onClick={handleChangeRange(1)}
              >
                <div className="flex-col-center w-[40px] h-[40px]">
                  <IconCircleArrowRight />
                </div>
                <p className="text-body text-white text-[16px]">
                  {rangeIndex === 0 && rangeIndex < max && "4〜"}
                  {rangeIndex === 1 && rangeIndex < max && "10〜"}
                  {rangeIndex === 2 && rangeIndex < max && "20〜"}
                  {rangeIndex === 3 && rangeIndex < max && "30〜"}
                  {rangeIndex === 4 && rangeIndex < max && "40〜"}
                  {(rangeIndex === 5 || rangeIndex + 1 > max) && "〜"}
                </p>
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
