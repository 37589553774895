import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18n-js";
import produce from "immer";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import {
  IconEraser,
  IconDrawingPen,
  IconCleanDrawing,
  IconFinishDrawing,
} from "features/creator/assets";
import { actions } from "features/creator/slice";
import { DrawingModel } from "features/creator/types";
import { TabIndex } from "features/creator/drawing/types";
import { SizePanel } from "features/creator/drawing/SizePanel";
import { PenColorPanel } from "features/creator/drawing/PenColorPanel";

export const ToolPanel = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(true);
  const [selectedTabIndex, setSelectedTabeIndex] = useState<TabIndex>(
    TabIndex.NONE
  );

  const drawing = useSelector((state: RootState) => state.creator.drawing);
  const handleModel = (mode: DrawingModel) => () => {
    play();
    setCollapse(false);
    setSelectedTabeIndex(TabIndex.NONE);
    const newDrawing = produce(drawing, (draft) => {
      if (mode === DrawingModel.PEN && !drawing.enable) {
        draft.enable = true;
      }
      draft.model = mode;
    });
    dispatch(actions.handleAllCollapse());
    dispatch(actions.handleUpdateDrawing(newDrawing));
    dispatch(actions.updateFocus());
  };

  const handleChangeSize = () => {
    if (selectedTabIndex === TabIndex.SIZE) {
      setSelectedTabeIndex(TabIndex.NONE);
    } else {
      setSelectedTabeIndex(TabIndex.SIZE);
    }
  };

  const handleChangeColor = () => {
    if (selectedTabIndex === TabIndex.COLOR) {
      setSelectedTabeIndex(TabIndex.NONE);
    } else {
      setSelectedTabeIndex(TabIndex.COLOR);
    }
  };

  const handleReset = () => {
    play();
    dispatch(
      actions.handleUpdateDrawing({ ...drawing, reset: !drawing.reset })
    );
    setSelectedTabeIndex(TabIndex.NONE);
  };

  const handleFinish = () => {
    play();
    setCollapse(true);
    dispatch(
      actions.handleUpdateDrawing({
        ...drawing,
        enable: false,
        model: DrawingModel.PEN,
      })
    );
    setSelectedTabeIndex(TabIndex.NONE);
  };

  const drawingSizeProperty = () => {
    if (drawing.model === DrawingModel.PEN) {
      if (drawing.pen.radius === 5) {
        return "w-[5px] h-[5px]";
      } else if (drawing.pen.radius === 20) {
        return "w-[12px] h-[12px]";
      } else {
        return "w-[24px] h-[24px]";
      }
    } else {
      if (drawing.eraser.radius === 20) {
        return "w-[5px] h-[5px]";
      } else if (drawing.eraser.radius === 50) {
        return "w-[12px] h-[12px]";
      } else {
        return "w-[24px] h-[24px]";
      }
    }
  };
  return (
    <div className="flex-col-view items-start h-full">
      <div className="flex-row-view h-full overflow-hidden mb-[5px]">
        <div
          className={`flex-col-center cursor-pointer z-10 w-[80px] h-full bg-beige/60 border-y-[4px] border-x-0 border-solid border-white ${
            drawing.enable &&
            (drawing.model === DrawingModel.PEN
              ? "bg-green/altcolor"
              : "bg-gray2/40")
          }`}
          onClick={handleModel(DrawingModel.PEN)}
        >
          <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
            <span className="inline-block w-[24px] h-[24px] mb-[4px]">
              <IconDrawingPen />
            </span>
            {i18n.t("MSG_CREATOR_MENU_DRAWING_PEN")}
          </p>
        </div>

        <div
          className="flex-row-center h-full bg-beige/60 rounded-r-[12px] border-[4px] !border-l-0 border-solid border-white transition-transform duration-500"
          style={{
            transform: `translateX(${collapse ? -5 * 80 + 6 - 8 - 4 : 0}px)`,
          }}
        >
          <button
            className={`flex-col-el flex-center px-0 border-none w-[80px] h-full ${
              drawing.enable && drawing.model === DrawingModel.ERASER
                ? "bg-green/altcolor"
                : "bg-gray2/40"
            }`}
            disabled={!drawing.enable}
            onClick={handleModel(DrawingModel.ERASER)}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block w-[24px] h-[24px] mb-[4px]">
                <IconEraser />
              </span>
              {i18n.t("MSG_CREATOR_MENU_DRAWING_ERASER")}
            </p>
          </button>

          <button
            className="flex-col-el flex-center px-0 border-none bg-transparent w-[80px] h-full"
            disabled={!drawing.enable}
            onClick={handleChangeSize}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block flex-col-center w-[24px] h-[24px] mb-[4px]">
                <span
                  className={`inline-block rounded-full bg-gray2/100 ${drawingSizeProperty()}`}
                />
              </span>

              {i18n.t("MSG_CREATOR_MENU_DRAWING_SIZE")}
            </p>
          </button>

          <button
            className="flex-col-el flex-center px-0 border-none bg-transparent w-[80px] h-full"
            disabled={drawing.model === DrawingModel.ERASER}
            onPointerDown={handleChangeColor}
          >
            <p
              className={`flex-col-center text !text-[16px] !leading-[18px] ${
                drawing.model === DrawingModel.ERASER
                  ? "text-gray2/80"
                  : "text-textcolor/black"
              }`}
            >
              <span
                className="inline-block w-[24px] h-[24px] rounded-[12px] mb-[4px] bg-gray2/80"
                style={{
                  backgroundColor:
                    drawing.model === DrawingModel.PEN && drawing.color,
                }}
              />
              {i18n.t("MSG_CREATOR_MENU_DRAWING_COLOR")}
            </p>
          </button>

          <button
            className="flex-col-el flex-center px-0 border-none bg-transparent w-[88px] h-full"
            disabled={!drawing.enable}
            onClick={handleReset}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block w-[24px] h-[24px] mb-[4px]">
                <IconCleanDrawing />
              </span>
              {i18n.t("MSG_CREATOR_MENU_DRAWING_RESET")}
            </p>
          </button>

          <div className="w-[2px] h-full bg-beige/alt" />
          <div className="w-[2px] h-full bg-white" />

          <button
            className="flex-col-el flex-center px-0 border-none bg-transparent w-[80px] h-full"
            disabled={!drawing.enable}
            onClick={handleFinish}
          >
            <p className="flex-col-center text text-textcolor/black !text-[16px] !leading-[18px]">
              <span className="inline-block w-[24px] h-[24px] mb-[4px]">
                <IconFinishDrawing />
              </span>
              {i18n.t("MSG_CREATOR_MENU_DRAWING_FINISH")}
            </p>
          </button>
        </div>
      </div>

      {selectedTabIndex === TabIndex.SIZE && (
        <SizePanel setSelectedTabeIndex={setSelectedTabeIndex} />
      )}
      {selectedTabIndex === TabIndex.COLOR && (
        <PenColorPanel setSelectedTabeIndex={setSelectedTabeIndex} />
      )}
    </div>
  );
};
