import { useState } from "react";
import { btnDelay } from "common/utils";
import { useOpenCv } from "common/hook";
import { Recognition } from "./recognition";

export const Control = () => {
  const [initialized, setInitialized] = useState(false);

  useOpenCv({
    setInitialized: () => {
      setInitialized(true);
    },
  });

  const [camera, setCamera] = useState(false);

  const handleOpenCamera = () => {
    btnDelay(() => {
      setCamera(true);
    });
  };

  const handleCloseCamera = () => {
    btnDelay(() => {
      setCamera(false);
    });
  };

  return (
    <div className="flex-col-el flex-center w-full h-full">
      {camera ? (
        <Recognition close={handleCloseCamera} />
      ) : (
        <div className="flex-col-el flex-center h-full gap-20">
          <div className="flex-col-el flex-center upsilon px-5 pointer-events-auto">
            <button
              disabled={!initialized}
              className="btn btn-primary"
              onClick={handleOpenCamera}
            >
              <p className="text-body text-white text-[24px] !mb-[3px]">
                カメラを起動する
              </p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
