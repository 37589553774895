import Blockly from "blockly";
import Constants from "common/constant";

export const theme = Blockly.Theme.defineTheme("inexus-creator", {
  base: Blockly.Themes.Zelos,
  fontStyle: { family: "MPLUSRounded1c-Bold", weight: "normal", size: 12 },
  startHats: true,
});

export const creatorBlocklyOptions = (
  scale: number,
  toolbox: Blockly.utils.toolbox.ToolboxDefinition
): Blockly.BlocklyOptions => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: true,
  horizontalLayout: false,
  media: `${Constants.assetHost}/assets/blockly/creator/`,
  move: {
    scrollbars: {
      horizontal: true,
      vertical: true,
    },
    drag: true,
    wheel: true,
  },
  zoom: {
    controls: true,
    wheel: true,
    startScale: scale,
    maxScale: 3,
    minScale: 0.1,
    scaleSpeed: 1.2,
    pinch: true,
  },
  oneBasedIndex: true,
  readOnly: false,
  rtl: false,
  toolbox: toolbox,
  toolboxPosition: "start",
  grid: { spacing: 20, length: 20, colour: "#d9effa", snap: false },
  trashcan: true,
  maxTrashcanContents: 32,
  renderer: "zelos",
  theme: theme,
});

export const creatorBlocklyOptionsForCapture = (
  toolbox: Blockly.utils.toolbox.ToolboxDefinition
): Blockly.BlocklyOptions => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: false,
  horizontalLayout: false,
  media: `${Constants.assetHost}/assets/blockly/creator/`,
  move: {
    scrollbars: {
      horizontal: false,
      vertical: false,
    },
    drag: false,
    wheel: false,
  },
  zoom: {
    controls: false,
    wheel: false,
    startScale: 0.3,
    maxScale: 3,
    minScale: 0.3,
    scaleSpeed: 1.2,
    pinch: true,
  },
  oneBasedIndex: true,
  readOnly: true,
  rtl: false,
  toolbox: toolbox,
  toolboxPosition: "start",
  grid: { spacing: 20, length: 20, colour: "#d9effa", snap: true },
  trashcan: false,
  renderer: "zelos",
  theme: theme,
});
