import { SVGProps } from "react";

export const FreebuildAnimationSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 129 186"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.626 114.984c-.13.31-.26.6-.38.84l-1.71 3.43.19 11.09c.67-2 1.35-3.89 2.08-5.81l-.18-9.55Z"
      fill="#865D34"
    />
    <path
      d="M20.626 114.984v-2.95l-2.15.92.11 6.3 1.71-3.43c.07-.24.2-.53.33-.84Z"
      fill="#9C6D45"
    />
    <path
      d="M21.006 134.824a142.274 142.274 0 0 0-2.13 4.32l.13 7.42c.75-1.07 1.48-2.16 2.16-3.28l-.16-8.46Z"
      fill="#865D34"
    />
    <path
      d="M20.806 124.534c-.73 1.92-1.41 3.86-2.08 5.81l.15 8.8a85.717 85.717 0 0 1 2.13-4.32l-.2-10.29ZM21.436 157.574l-.27-14.29c-.68 1.12-1.41 2.21-2.16 3.28l.19 11.07 2.24-.06Z"
      fill="#9C6D45"
    />
    <path
      d="M51.946 117.104c-.2.27-.4.51-.57.72l-2.52 2.9-2.56 10.78c1.13-1.72 2.28-3.43 3.46-5.11l2.19-9.29Z"
      fill="#865D34"
    />
    <path
      d="m51.946 117.104.68-2.87-2.31.35-1.46 6.14 2.52-2.9c.17-.21.38-.45.57-.72Z"
      fill="#9C6D45"
    />
    <path
      d="M47.396 136.414c-1.06 1.2-2.1 2.43-3.13 3.66l-1.72 7.22c1-.85 2-1.73 2.91-2.65l1.94-8.23Z"
      fill="#865D34"
    />
    <path
      d="M49.756 126.394c-1.18 1.68-2.33 3.39-3.46 5.11l-2 8.57c1-1.23 2.07-2.46 3.13-3.66l2.33-10.02ZM42.176 158.564l3.28-13.92c-.94.92-1.91 1.8-2.91 2.65l-2.56 10.77 2.19.5Z"
      fill="#9C6D45"
    />
    <path
      d="m83.556 127.034-.74.55-3.18 2.16-5.23 9.77c1.53-1.38 3.07-2.73 4.65-4.05l4.5-8.43Z"
      fill="#865D34"
    />
    <path
      d="m83.556 127.034 1.39-2.6-2.33-.25-3 5.56 3.18-2.16.76-.55Z"
      fill="#9C6D45"
    />
    <path
      d="M74.206 144.534c-1.33.89-2.65 1.82-4 2.73l-3.45 6.55c1.18-.57 2.35-1.17 3.49-1.81l3.96-7.47Z"
      fill="#865D34"
    />
    <path
      d="M79.056 135.464c-1.58 1.32-3.12 2.669-4.65 4.049l-4.16 7.75c1.31-.91 2.63-1.839 4-2.729l4.81-9.07ZM63.496 164.614l6.73-12.61c-1.14.64-2.31 1.24-3.49 1.81l-5.23 9.76 1.99 1.04Z"
      fill="#9C6D45"
    />
    <path
      d="M18.146 178.184s-1.4-26.64-.36-28.39 48.71 9 49.83 9.95c1.12.95-11.27 25.83-11.27 25.83l-38.2-7.39Z"
      fill="#614018"
    />
    <path
      d="M66.526 163.674c-16.35-4.13-32.63-8.43-49-12.39-.08 1-.12 2.38-.12 4 16 3.64 31.9 7.56 47.86 11.35.49-1.05.9-2.06 1.26-2.96Z"
      fill="#614018"
    />
    <path
      d="M67.616 159.744c-1.12-.91-48.79-11.69-49.83-9.95-.17.478-.257.982-.26 1.49 16.37 4 32.65 8.26 49 12.39.87-2.2 1.35-3.72 1.09-3.93Z"
      fill="#7E5320"
    />
    <path
      d="m18.146 178.184 38.2 7.39 1.78-3.61c-13.4-2.69-26.78-5.41-40.19-8.06.12 2.57.21 4.28.21 4.28Z"
      fill="#614018"
    />
    <path
      d="M17.936 173.904c13.41 2.65 26.79 5.37 40.19 8.06l1.51-3.14c-14-3.42-27.88-7.14-41.92-10.36.04 2.01.15 3.88.22 5.44ZM17.506 162.184c0 1.45.08 2.91.13 4.34 14.57 2.66 29.08 5.83 43.56 9.08l1.46-3.13c-15.05-3.47-30.09-6.95-45.15-10.29ZM64.286 168.874c.34-.76.67-1.5 1-2.21-16-3.79-31.88-7.71-47.86-11.35v4l1.8.37c14.96 3.27 29.99 6.4 45.06 9.19Z"
      fill="#7E5320"
    />
    <path
      d="M17.636 166.494c0 .67 0 1.32.08 2 14 3.22 27.94 6.94 41.92 10.36.5-1 1-2.12 1.56-3.25-14.44-3.28-28.99-6.42-43.56-9.11ZM64.286 168.874c-15.07-2.79-30.1-5.92-45-9.2l-1.8-.37c0 .93 0 1.88.07 2.85 15.06 3.37 30.1 6.85 45.15 10.29.51-1.2 1.05-2.41 1.58-3.57Z"
      fill="#614018"
    />
    <path
      d="M18.556 117.434s47.07 11.16 62.79 14.33c0 0 39.12-18.6 46.27-58.58 8.79-49.1-30.89-66.81-52.94-71.66-25-5.49-64 2.19-73.6 50.8-6.32 32.04 17.48 65.11 17.48 65.11Z"
      fill="#F88BB0"
    />
    <path
      d="M69.756.654a68.37 68.37 0 0 0-12.18-.59c-20 14.17-55.38 50.49-29.36 119.65 4.69 1.1 10.67 2.5 17 4-3.46-50.17 18.78-108.87 24.54-123.06Z"
      fill="#F55698"
    />
    <path
      d="M82.116 3.494c-2.62-.81-5.13-1.46-7.44-2-1.59-.34-3.24-.64-4.94-.87-5.74 14.19-28 72.89-24.52 123 4.88 1.13 10 2.3 14.85 3.41 13.58-19.04 38.6-64.52 22.05-123.54ZM57.556.064c-22.9 1-48.87 13.8-56.48 52.26-6.35 32 17.48 65.11 17.48 65.11l9.64 2.28c-26.02-69.16 9.33-105.48 29.36-119.65Z"
      fill="#F88BB0"
    />
    <path
      d="M127.616 73.183c6.58-36.77-14-55.94-34-65.28 7.1 11.63 23.65 44.1 11.9 78.44-8.3 24.261-18.28 37.891-25.4 45.171l1.27.25s39.08-18.58 46.23-58.58Z"
      fill="#F88BB0"
    />
    <path
      d="M93.576 7.904a92.268 92.268 0 0 0-11.46-4.41c16.55 59-8.47 104.5-22.05 123.6 7.84 1.79 15.11 3.4 20 4.42 7.12-7.28 17.1-20.91 25.4-45.17 11.76-34.34-4.79-66.81-11.89-78.44Z"
      fill="#F55698"
    />
    <path
      d="m43.846 72.903.39-.05-.39.05ZM49.186 54.413a16.174 16.174 0 0 0-3.12-3.35 14.318 14.318 0 0 0 3.12 3.35ZM68.286 50.423c-.34.83-.62 1.57-.84 2.17a9.724 9.724 0 0 0 .84-2.17Z"
      fill="#FB9C0F"
    />
    <path
      d="m77.216 80.784.21.26c4.39 3.81 15.87 18.06 21 9.1 4.87-8.57-11-11.45-15.45-14.12.77-.086 1.545-.13 2.32-.13-2-.26-4-.39-5.29-.6-3.64-.6-7.06-2.24-10.5-3.73.84.85 1.67 1.72 2.45 2.65 1.8 2.22 3.55 4.3 5.26 6.57Z"
      fill="#FDD57A"
    />
    <path
      d="M62.956 69.183c.66 3.59.64 7.3 1.89 10.75 1.09 3 2.87 5.84 3.37 9 .11.71.25 1.48.4 2.28a19.601 19.601 0 0 0 1.78 3.95c3.43 5.641 14.34 5.841 12.9-2.61-.82-4.77-3-8.28-6.22-11.78.11.09.23.18.35.29l-.21-.26c-1.71-2.27-3.42-4.35-5.29-6.57-.78-.93-1.61-1.8-2.45-2.65a43.742 43.742 0 0 0-5.53-2.09l-.99-.31Z"
      fill="#F5EEB8"
    />
    <path
      d="M71.556 68.713c-2.09.35-4.19.77-6.28 1.18 4.9 1.66 9.59 4.55 14.7 5.4 1.31.21 3.29.34 5.29.6 6.53-.13 18.68.92 18.36-6.33-.3-6.65-11.41-4.89-17.12-4.21-.41.14-.82.28-1.22.4a106.122 106.122 0 0 1-13.73 2.96Z"
      fill="#F5EEB8"
    />
    <path
      d="m63.056 69.183.89.29 1.33.42c2.09-.41 4.19-.83 6.28-1.18a106.115 106.115 0 0 0 13.73-3c.4-.12.81-.26 1.22-.4-.65.07-1.23.14-1.72.17 4.81-2.62 17.14-13.77 10.6-18.86-5.29-4.12-15.38 4.55-17.89 9l.63-1.08c-3.36 4-7.78 7.31-11.65 11-1.2 1.2-2.31 2.43-3.42 3.64Z"
      fill="#FDD57A"
    />
    <path
      d="M48.236 82.094c-2.44 2.51-10.84 12.16-6.16 15.44 2.43 1.7 7.93-1.35 9.82-2.91 2.77-2.27 4.05-5.44 5.28-8.7.24-1.12.5-2.23.75-3.34.73-3.22 1.42-6.33 2-9.62 0-.26.12-.53.18-.79-1.62 1.44-3.46 2.57-5.14 4-2.71 2.27-5.89 4-8.39 6.6l-1.56 1.64a18.528 18.528 0 0 0 3.22-2.32Z"
      fill="#F5EEB8"
    />
    <path
      d="M57.926 82.584c-.25 1.11-.51 2.22-.75 3.34l.18-.49c-1.31 4.46-2.78 16.6 3.34 17.85 7.41 1.53 7.28-15.27 5.78-19.54.88 2.52 1.31 5 2.14 7.45-.15-.8-.29-1.57-.4-2.28-.5-3.18-2.28-6-3.37-9-1.24-3.41-1.24-7.07-1.87-10.62-.77.84-1.54 1.68-2.36 2.46-.15.16-.33.29-.49.44-.16.15-.13.53-.18.79-.6 3.27-1.29 6.38-2.02 9.6ZM68.846 47.923c1-6 .9-15-3.51-16.54-7.22-2.54-9 13.08-9.07 18.71 1.46 4 2.87 8.16 4.44 12.17.45 1.17.93 2.34 1.34 3.52 1.4-3.73 3.09-7.39 4.22-11.2a47.248 47.248 0 0 1 2.58-6.66Z"
      fill="#FDD57A"
    />
    <path
      d="M62.956 69.183h.1c1.11-1.21 2.22-2.44 3.42-3.58 3.87-3.67 8.29-6.94 11.65-11 2.61-4.57 8.65-16.17 3.55-18-6.14-2.24-11.07 8-13.39 13.83a9.726 9.726 0 0 1-.84 2.17c.22-.6.5-1.34.84-2.17.34-.83.39-1.58.56-2.5a47.273 47.273 0 0 0-2.58 6.66c-1.13 3.81-2.82 7.47-4.22 11.2.355.98.642 1.982.86 3 .02.14.03.25.05.39Z"
      fill="#F5EEB8"
    />
    <path
      d="m49.186 54.413-.35-.29c2.23 2.57 4.75 4.87 6.92 7.52a72.696 72.696 0 0 1 4.72 6.49c.82.37 1.64.72 2.48 1 0-.1 0-.21-.05-.32a38.892 38.892 0 0 0-2.2-6.56c-1.57-4-3-8.13-4.44-12.17v1.15c0-1-2.08-3.5-2.51-4.63-.68-1.8-1.16-3.68-2-5.43-1-2.21-3.07-4.7-5.66-5-3.23-.39-3.58 2.07-3.5 4.67a20.23 20.23 0 0 0 3.44 10.21 16.17 16.17 0 0 1 3.15 3.36Z"
      fill="#F5EEB8"
    />
    <path
      d="M42.426 62.353a26.61 26.61 0 0 0-2.94-.53c1.76.51 3.52 1.06 5.26 1.64 3.48 1.16 7.23 1.67 10.78 2.68a38.753 38.753 0 0 1 5 2 72.677 72.677 0 0 0-4.72-6.49c-2.17-2.65-4.69-4.95-6.92-7.52a13.549 13.549 0 0 1-2.08-2.13c-1-.95-2.14-1.82-3.25-2.8l-.2-.18c-4.73-3.09-10.88-5.14-14.39-2-7.31 6.36 9.79 13.96 13.46 15.33ZM42.756 73.183c-4.5 1.3-13.34 6.45-12.54 11.71.9 5.9 9.66 2.58 14.79-.47l1.56-1.64c2.5-2.59 5.68-4.33 8.39-6.6 1.85-1.55 3.91-2.74 5.63-4.42l.42-.41c-3.84.4-7.69.64-11.47 1.16a38.919 38.919 0 0 1-6.78.67Z"
      fill="#FDD57A"
    />
    <path
      d="M24.836 66.963c.42 5.28 8.88 6.3 14.83 6.21a8.908 8.908 0 0 1 2.07-.08c.81-.05 1.53-.12 2.11-.19l.39-.05-.39.05a9.327 9.327 0 0 0-1.09.28 38.918 38.918 0 0 0 6.78-.67c3.78-.52 7.63-.76 11.47-1.16.66-.67 1.3-1.35 1.94-2.05v-.13c-2.51-.88-4.83-2.25-7.43-3-3.55-1-7.3-1.52-10.78-2.68-1.74-.58-3.5-1.13-5.26-1.64-5.58-.78-15.1-.8-14.64 5.11Z"
      fill="#F5EEB8"
    />
    <path
      d="M88.106 69.493c-.24 12.66-14.62 19.5-26.26 17.9-8.81-1.22-17.82-7.13-20.43-16-2-6.71.49-13.42 4.7-18.6 4.82-5.92 13.67-6.46 20.69-5.58 12.33 1.53 20.67 8.08 21.29 21.17.01.36.02.74.01 1.11Z"
      fill="#80452C"
    />
    <path
      d="m79.866 80.183 3.39.45c.43-.44.84-.9 1.23-1.37l-4.47-.58c.34-3.386.643-6.773.91-10.16 2.36.25 4.75.54 7.18.84v-1.5c-2.37-.29-4.71-.57-7-.82.26-3.29.5-6.56.74-9.82l3.34.36a18.655 18.655 0 0 0-1.09-1.64l-2.15-.23c0-.32.05-.63.07-.95l.08-1.11c-.452-.436-.926-.85-1.42-1.24l-.17 2.24-.06.91c-2.68-.27-5.36-.51-8-.74.09-2.18.16-4.32.22-6.44-.5-.14-1-.29-1.5-.41-.06 2.21-.13 4.45-.24 6.73-2.63-.22-5.27-.42-7.9-.62.26-2.38.513-4.766.76-7.16-.51 0-1-.05-1.52-.06-.24 2.38-.487 4.75-.74 7.11l-4.89-.35-3.65-.27c.09-.57.19-1.14.28-1.71l.62-3.74c-.55.17-1.09.35-1.63.57l-.48 2.92c-.1.62-.21 1.23-.31 1.85l-3-.22-1.75-.13h-.56c-.38.47-.74.95-1.09 1.44l1.53.12 1.75.13 2.88.21c-.55 3.36-1.12 6.75-1.71 10.13-2.81-.06-5.58-.07-8.29-.05h-.31c-.05.51-.08 1-.09 1.52h.41c2.633 0 5.3.017 8 .05-.51 2.86-1 5.74-1.6 8.6l-4.9-.47c.27.55.58 1.09.9 1.61l3.72.35-.69 3.43c.41.42.84.82 1.29 1.21.31-1.49.62-3 .91-4.49 3.333.34 6.617.694 9.85 1.06-.367 2.92-.743 5.827-1.13 8.72.49.15 1 .3 1.48.42.393-2.98.78-5.98 1.16-9 2.94.33 5.86.68 8.78 1-.31 2.81-.66 5.69-1 8.62.51-.05 1-.12 1.54-.2.36-2.8.693-5.55 1-8.25 2.65.32 5.29.66 7.93 1-.16 1.51-.32 3-.5 4.54.56-.3 1.1-.61 1.63-.93.02-1.16.15-2.3.26-3.48Zm.42-23.14c-.24 3.25-.48 6.52-.74 9.81a403.097 403.097 0 0 0-7.88-.77c.25-3.33.47-6.59.63-9.77 2.66.25 5.33.49 7.99.76v-.03Zm-9.53-.86c-.16 3.18-.37 6.44-.63 9.78-2.89-.25-5.71-.45-8.48-.61.38-3.26.75-6.523 1.11-9.79 2.667.214 5.333.42 8 .62Zm-18.13-1.36 3.79.28 4.83.35c-.36 3.28-.73 6.55-1.11 9.81-3.14-.17-6.21-.3-9.21-.37a751.51 751.51 0 0 0 1.73-10.04l-.03-.03Zm-3.54 20.36a510.28 510.28 0 0 0 1.6-8.71c3.04.074 6.14.2 9.3.38-.37 3.13-.76 6.26-1.15 9.38-3.24-.4-6.49-.75-9.75-1.05Zm11.26 1.21c.39-3.14.78-6.29 1.15-9.46 2.787.174 5.63.377 8.53.61-.26 3.21-.57 6.51-.93 9.87-2.893-.38-5.81-.736-8.75-1.07v.05Zm10.25 1.2c.36-3.38.68-6.69.94-9.92 2.58.22 5.21.48 7.89.76-.28 3.38-.583 6.76-.91 10.14l-7.92-.98Z"
      fill="#BF7157"
    />
    <path
      d="M67.206 61.033a1.024 1.024 0 1 1-2.002-.43 1.024 1.024 0 0 1 2.002.43ZM56.966 60.684a1 1 0 1 1-1.95-.438 1 1 0 0 1 1.95.438ZM55.756 71.084a1 1 0 1 1-1.951-.443 1 1 0 0 1 1.951.443ZM65.866 71.924a1 1 0 1 1-1.95-.443 1 1 0 0 1 1.95.443ZM76.136 72.954a1 1 0 1 1-1.952-.44 1 1 0 0 1 1.952.44ZM76.486 61.843a1 1 0 1 1-1.955-.423 1 1 0 0 1 1.955.423ZM76.326 52.353a1 1 0 0 1-1.2.77 1.023 1.023 0 1 1 .43-2 1 1 0 0 1 .77 1.23ZM66.656 50.934a1 1 0 1 1-1.95-.442 1 1 0 0 1 1.95.442ZM57.996 50.413a1 1 0 1 1-1.953-.422 1 1 0 0 1 1.953.422ZM48.056 58.913a.999.999 0 0 1-1.2.77 1.023 1.023 0 1 1 .43-2 1 1 0 0 1 .77 1.23ZM46.506 70.784a1.021 1.021 0 0 1-1.581.626 1.023 1.023 0 1 1 1.581-.626ZM54.966 82.444A1 1 0 1 1 53.014 82a1 1 0 0 1 1.952.444ZM66.256 83.523a1 1 0 1 1-1.954-.423 1 1 0 0 1 1.954.424ZM74.546 82.584a1 1 0 1 1-1.95-.443 1 1 0 0 1 1.95.443ZM84.356 73.884a1.002 1.002 0 0 1-1.2.77 1 1 0 1 1 1.2-.77ZM84.846 64.534a1 1 0 1 1-1.95-.443 1 1 0 0 1 1.95.443Z"
      fill="#BF7157"
    />
  </svg>
);
