import { useState, FormEvent } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { useDialog } from "app/hook";
import { DialogType } from "app/types";
import { RootState, AppDispatch } from "app/store";
import { WarningMessageBox } from "common/elements";
import { btnDelay, usePlaySound } from "common/utils";
import { AppUser } from "features/user/types";
import { actions } from "features/user/slice";
import { updateUserProfile } from "features/user/api";

export const UserDisplayName = ({
  selectedUser,
}: {
  selectedUser: AppUser;
}) => {
  const play = usePlaySound();
  const handleDialog = useDialog();
  const [error, setError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const bannedWords = useSelector(
    (state: RootState) => state.config.banned.words
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    play();
    event.preventDefault();
    try {
      handleDialog({ type: DialogType.LOADING, value: true });
      const data = new FormData(event.currentTarget);
      const display_name = data.get("display_name").toString();
      if (
        bannedWords.filter((word) => display_name.includes(word.word)).length >
        0
      ) {
        setError(true);
        handleDialog({ type: DialogType.LOADING, value: false });
      } else {
        updateUserProfile({
          uid: selectedUser.uid,
          userInfo: {
            name: display_name,
          },
        })
          .then(() => {
            dispatch(
              actions.updateUserProfile({
                ...selectedUser,
                display_name,
              })
            );
            toast(
              <div>
                <p className="text text-textcolor/black">
                  {I18n.t("MSG_TOAST_EDIT_SUCCESS")}
                </p>
              </div>
            );
          })
          .finally(() =>
            handleDialog({ type: DialogType.LOADING, value: false })
          );
      }
    } catch (error) {
      console.error(error);
      handleDialog({ type: DialogType.LOADING, value: false });
    }
  };

  const handleCloseDialog = () => {
    play();
    btnDelay(() => {
      setError(false);
    });
  };

  return (
    <>
      {error && (
        <WarningMessageBox
          zIndex={9999999}
          title={I18n.t("MSG_PARENTS_USER_PROFILE_NAME_ERROR_TITLE")}
          message={I18n.t("MSG_PARENTS_USER_PROFILE_NAME_ERROR_MESSAGE")}
          close={handleCloseDialog}
        />
      )}

      <form
        onSubmit={handleSubmit}
        className="flex-row-el flex-center w-full gap-4 mb-8"
      >
        <p className="text-body text-[20px] text-textcolor/black text-right leading-[30px]">
          {I18n.t("MSG_USER_PROFILE_FIELD_NAME")}
        </p>
        <div className="flex-col-center w-[300px] h-[50px]">
          <input
            key={selectedUser.uid}
            id="display_name"
            name="display_name"
            type="text"
            required
            className="peer input-form text-body text-textcolor/black text-font-size-body2-narrow"
            pattern={".{1,10}"}
            defaultValue={selectedUser.display_name}
            autoFocus={false}
          />
          <label className="text-body text-left text-[14px] h-5 text-danger hidden peer-invalid:block">
            {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_DISPLAY_NAME_LIMIT", {
              limit: 10,
            })}
          </label>
        </div>

        <div className="flex-col-el flex-center relative upsilon">
          <button type="submit" className="btn btn-primary w-full">
            <p className="text-body text-[24px] text-white !mb-[3px]">
              {I18n.t("MSG_SETTING_PARENTS_USER_PROFILE_SAVE_BTN")}
            </p>
          </button>
        </div>
      </form>
    </>
  );
};
