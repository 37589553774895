const ToolboxInfo = {
  maxInstances: {
    program_image_click_event_block: 2,
    program_math_plus_block: 1,
    math_number: 1,
    image_getter_x_block: 2,
    image_setter_x_block: 1,
    label_text_show_setter_block: 1,
  },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "program_image_click_event_block",
          },
          {
            kind: "block",
            type: "program_math_plus_block",
          },
          {
            kind: "block",
            type: "math_number",
          },
          {
            kind: "block",
            type: "image_getter_x_block",
          },
          {
            kind: "block",
            type: "image_setter_x_block",
          },
          {
            kind: "block",
            type: "label_text_show_setter_block",
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
