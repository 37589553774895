import { SVGProps } from "react";

export const AllStageClearFlower = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={109}
    height={170}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M45.742 73.873c.097.507 10.575 50.768 2.714 90.153l2.702.511c7.977-39.944-2.622-90.746-2.73-91.255l-2.686.591ZM84.859 36.09c-.001.652-.208 66.306-27.788 122.522l2.478 1.211c27.856-56.815 28.056-123.08 28.06-123.742l-2.75.01Z"
      fill="#136739"
    />
    <path
      d="M37.553 54.628s5.159-17.71 8.253-17.98c2.867-.252 8.94 16.507 8.94 16.507s15.829 1.974 15.976 5.34c.148 3.366-10.844 9.827-10.844 9.827s5.576 16.645 3.383 18.236c-2.194 1.59-17.17-7.154-17.17-7.154s-11.972 9.869-15.386 9.305c-3.415-.564 1.438-19.563 1.438-19.563S17.854 59.24 18.509 56.962c.654-2.279 19.044-2.334 19.044-2.334Z"
      fill="#FD7E35"
    />
    <path
      d="M44.764 45.335a1.13 1.13 0 1 0 2.257.129 1.13 1.13 0 0 0-2.257-.129ZM44.667 53.876a1.13 1.13 0 1 0 1.216-1.052 1.12 1.12 0 0 0-1.216 1.052ZM49.826 71.002a1.13 1.13 0 1 0 2.255.149 1.13 1.13 0 0 0-2.255-.15ZM56.03 77.802a1.13 1.13 0 1 0 2.256.149 1.13 1.13 0 0 0-2.255-.148ZM38.8 72.042a1.13 1.13 0 1 0 2.258.128 1.13 1.13 0 0 0-2.257-.128ZM34.318 80.466a1.13 1.13 0 1 0 1.218-1.062 1.14 1.14 0 0 0-1.218 1.063ZM35.773 62.596a1.13 1.13 0 1 0 1.216-1.053 1.14 1.14 0 0 0-1.216 1.053ZM27.26 59.816a1.13 1.13 0 1 0 2.257.129 1.13 1.13 0 0 0-2.256-.129ZM52.642 61.917a1.13 1.13 0 1 0 2.258.128 1.13 1.13 0 0 0-2.258-.128ZM60.89 59.578a1.13 1.13 0 1 0 1.217-1.053 1.14 1.14 0 0 0-1.217 1.053ZM43.59 63.819a2.12 2.12 0 1 0 2.249-1.987 2.13 2.13 0 0 0-2.248 1.987Z"
      fill="#DD560E"
    />
    <path
      d="M76.45 34.756s-11.846-8.345-11.2-10.737c.537-2.198 14.562-2.417 14.562-2.417s5.52-11.281 8.092-10.51c2.572.77 4.535 10.576 4.535 10.576s13.807.126 14.43 2.157c.624 2.031-9.731 10.943-9.731 10.943s4.281 11.434 2.96 13.79c-1.32 2.356-14.185-6.09-14.185-6.09S74.888 50.56 73.39 49.493c-1.498-1.067 3.06-14.738 3.06-14.738Z"
      fill="#F6B03F"
    />
    <path
      d="M71.408 27.025a.89.89 0 1 0-.481-1.17.9.9 0 0 0 .48 1.17ZM77.695 29.302a.889.889 0 0 0 1.17-.481.893.893 0 1 0-1.652.001.89.89 0 0 0 .482.48ZM91.787 29.798a.87.87 0 0 0 1.111-.492.89.89 0 0 0-1.166-1.16.9.9 0 0 0-.486.481.88.88 0 0 0 .541 1.171ZM98.417 26.98a.9.9 0 0 0 1.17-.48.894.894 0 1 0-1.17.48ZM89.724 38.293a.89.89 0 1 0-.48-1.17.9.9 0 0 0 .48 1.17ZM94.844 43.78a.89.89 0 1 0 .675-1.647.89.89 0 0 0-.675 1.646ZM81.922 38.11a.89.89 0 1 0 .697-1.637.89.89 0 0 0-.697 1.638ZM77.672 43.75a.89.89 0 1 0-.482-1.17.9.9 0 0 0 .482 1.17ZM85.74 25.398a.89.89 0 1 0 .677-1.642.87.87 0 0 0-1.11.482.89.89 0 0 0 .433 1.16ZM86.121 18.642a.87.87 0 0 0 1.11-.483.89.89 0 1 0-1.11.483ZM84.843 32.617a1.671 1.671 0 1 0 1.257-3.096 1.671 1.671 0 0 0-1.257 3.096Z"
      fill="#F99610"
    />
  </svg>
);
