import $ from "jquery";
import anime from "animejs";
import { setSize, setSizeByTime } from "common/blockly/codeGen";
import { previewRemovedObserver } from "common/blockly/codeGen/utils";

export const getLabelWidth = (id: string) =>
  $(`#label-container-${id}`).width();

export const getLabelHeight = (id: string) =>
  $(`#label-container-${id}`).height();

export const setLabelSize = (
  id: string,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#label-container-${id}`);
  const text = $(`#label-text-${id}`);
  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const [newContainerSize] = setSize(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth,
    currentContainerHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newContainerSize.x / Number(container.data("defaultWidth"));
  const scaleY = newContainerSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;

  container.css("width", `${newContainerSize.x}`);
  container.css("height", `${newContainerSize.y}`);
  text.css(
    "font-size",
    `${newFontSize < defaultFontSize ? defaultFontSize : newFontSize}px`
  );
};

export const setLabelSizeByStep = (
  id: string,
  widthStep?: number | undefined,
  heightStep?: number | undefined
) => {
  const container = $(`#label-container-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const newShapeWidth =
    widthStep === undefined ? undefined : currentContainerWidth + widthStep;
  const newShapeHeight =
    heightStep === undefined ? undefined : currentContainerHeight + heightStep;

  setLabelSize(id, newShapeWidth, newShapeHeight);
};

export const setLabelSizeByTime = (
  id: string,
  time: number,
  width?: number | undefined,
  height?: number | undefined
) => {
  const container = $(`#label-container-${id}`);

  const currentContainerWidth = container.width();
  const currentContainerHeight = container.height();

  const [newContainerSize, newShapeSize, offset] = setSizeByTime(
    container,
    currentContainerWidth,
    currentContainerHeight,
    currentContainerWidth,
    currentContainerHeight,
    width,
    height
  );

  const defaultFontSize = Number(container.data("defaultFontSize"));
  const scaleX = newContainerSize.x / Number(container.data("defaultWidth"));
  const scaleY = newContainerSize.y / Number(container.data("defaultHeight"));
  const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
  const newFontSize = defaultFontSize * fontSizeScale;

  const animation1 = anime({
    targets: `#label-container-${id}`,
    translateX: `-=${offset.x}`,
    translateY: `+=${offset.y}`,
    width: `+=${newShapeSize.x - currentContainerWidth}`,
    height: `+=${newShapeSize.y - currentContainerHeight}`,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  const animation2 = anime({
    targets: `#label-text-${id}`,
    fontSize: newFontSize < defaultFontSize ? defaultFontSize : newFontSize,
    duration: time * 1000,
    autoplay: false,
    easing: "easeInOutQuad",
  });
  animation1.play();
  animation2.play();
  previewRemovedObserver(() => {
    animation1.pause();
    animation2.pause();
  });
  return Promise.all([animation1.finished, animation2.finished]);
};
