import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import anime from "animejs";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import { UserAvatar } from "common/elements";
import { IconHome } from "features/creator/assets";
import { IconRankingBar } from "features/creator/assets";
import { ProjectType, ProjectEntity } from "features/creator/types";
import { actions, selectAllProjects } from "features/creator/slice";
import { Path } from "./type";
import { RankingTop } from "./RankingTop";
import { RankingApp } from "./RankingApp";
import { RankingUser } from "./RankingUser";
import { NumberPlateGreen } from "./elements";

const Route = ({
  page,
  path,
  goToHome,
  navigate,
}: {
  page: Path;
  path: Path;
  goToHome: () => void;
  navigate: (page: Path) => void;
}) => {
  switch (page) {
    case Path.TOP:
      return <RankingTop path={path} navigate={navigate} />;
    case Path.APP:
      return <RankingApp path={path} goToHome={goToHome} />;
    case Path.USER:
      return <RankingUser path={path} goToHome={goToHome} />;
    default:
      return <></>;
  }
};

const UserViewedBanner = ({
  userIcon,
  projects,
}: {
  userIcon: string;
  projects: ProjectEntity[];
}) => (
  <div
    id="ranking-user-viewed-banner"
    className="flex-row-el flex-center absolute bottom-0 left-0 h-[72px] z-[10000]"
  >
    <div className="flex-row-el h-[72px] absolute left-7">
      <div className="flex-col-el relative -mt-[13px]">
        <IconRankingBar />
        <div className="flex-col-el flex-center absolute w-full py-1">
          <p className="text-body text-white text-center leading-[18px]">
            あなた
          </p>
        </div>
      </div>
      <div className="flex-row-el w-[458px] h-[72px] gap-8 bg-white rounded-tr-[16px]">
        <div className="flex-col-center ml-2 mb-2">
          <UserAvatar size={72} icon={userIcon} />
          <div className="flex-col-el flex-center absolute right-0 bottom-0">
            <NumberPlateGreen
              number={projects
                .map((project) => project.viewed)
                .reduce((pre, cur) => pre + cur, 0)}
            />
          </div>
        </div>

        <div className="flex-row-el items-center justify-start gap-6">
          {projects.map((project) => (
            <div
              key={project.project_id}
              className="flex-col-center w-[44px] h-[56px]"
            >
              <img
                alt="app card"
                src={`${Constants.assetHost}/assets/images/app_card_user.png`}
                className="w-full h-full"
              />
              <div className="flex-col-el flex-center absolute top-3 left-0 w-full px-[3px] h-[38px]">
                {project?.capture ? (
                  <div className="flex-col-center w-full h-full overflow-hidden">
                    <img
                      alt="アプリのキャプチャー"
                      className="w-full h-full object-cover"
                      src={project?.capture}
                      onError={(e) =>
                        // @ts-ignore
                        (e.target.src = `${Constants.assetHost}/assets/images/default_capture.png`)
                      }
                    />
                  </div>
                ) : (
                  <div className="w-full h-full bg-white" />
                )}
              </div>
              <div className="flex-col-el flex-center absolute right-0 bottom-0">
                <NumberPlateGreen
                  number={projects
                    .map((project) => project.viewed)
                    .reduce((pre, cur) => pre + cur, 0)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const RankingLayoutPage = ({
  page,
  path,
  setPath,
}: {
  page: Path;
  path: Path;
  setPath: (path: Path) => void;
}) => {
  const play = usePlaySound();
  const [animation, setAnimation] = useState(false);

  const handleGoToHome = async () => {
    if (!animation && path !== Path.TOP) {
      play();
      setAnimation(true);

      const currentPage = document.getElementById(`ranking-page-${path}`);
      if (currentPage) {
        currentPage.style.zIndex = "0";
      }

      const topPage = document.getElementById("ranking-page-top");
      if (topPage) {
        topPage.style.zIndex = "10000";
        topPage.style.display = "block";
        document.getElementById("ranking-user-viewed-banner").style.zIndex =
          "10000";
        await anime({
          targets: topPage,
          translateY: ["-100%", 0],
          duration: 500,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;

        currentPage.style.display = "none";
      }
      setPath(Path.TOP);
      setAnimation(false);
    }
  };
  const handleNavigate = async (nextPath: Path) => {
    if (!animation) {
      play();
      setAnimation(true);

      const nextPage = document.getElementById(`ranking-page-${nextPath}`);
      if (nextPage) {
        nextPage.style.display = "block";
        nextPage.style.zIndex = "10000";
        await anime({
          targets: nextPage,
          opacity: [0, 1],
          scaleX: [0.3, 1],
          scaleY: [0.3, 1],
          duration: 200,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished;
      }

      const currentPage = document.getElementById(`ranking-page-${path}`);
      if (currentPage) {
        currentPage.style.zIndex = "0";
        currentPage.style.display = "none";
      }

      setPath(nextPath);
      setAnimation(false);
    }
  };

  return (
    <div
      id={`ranking-page-${page}`}
      className="w-full h-full absolute hidden pointer-events-auto"
    >
      <div className="flex-col-el flex-center w-full h-full bg-gray/20 absolute top-0 left-0 -z-0 overflow-hidden">
        <img
          alt="creator display default bg"
          src={`${Constants.assetHost}/assets/images/creator_display_bg.png`}
          className="w-full h-full object-fill"
        />

        <div className="flex-col-el absolute w-full h-full bg-white/30" />

        {path !== Path.TOP && (
          <div
            onClick={handleGoToHome}
            className="flex-col-el flex-center absolute top-2 right-2 active:opacity-70 cursor-pointer z-[30001]"
          >
            <div className="flex-col-center">
              <IconHome stroke="#A54D21" />
            </div>
            <p className="text-body text-[20px] text-[#A54D21]">HOME</p>
          </div>
        )}

        <Route
          page={page}
          path={path}
          goToHome={handleGoToHome}
          navigate={handleNavigate}
        />
      </div>
    </div>
  );
};

export const RankingPage = () => {
  const dispatch = useDispatch();
  const [path, setPath] = useState(Path.TOP);
  const projects = useSelector(selectAllProjects);
  const user = useSelector((state: RootState) => state.user.appUser);
  const userProjects = projects.filter(
    (project) =>
      project.author_uid === user.active.uid &&
      !project.deleted_at &&
      project.type === ProjectType.BASIC
  );
  const recommendReturnToHome = useSelector(
    (state: RootState) => state.creator.recommendReturnToHome
  );

  useEffect(() => {
    if (recommendReturnToHome) {
      if (path !== Path.TOP) {
        const currentPage = document.getElementById(`ranking-page-${path}`);
        currentPage.style.zIndex = "0";

        const topPage = document.getElementById("ranking-page-top");
        topPage.style.zIndex = "10000";
        topPage.style.display = "block";
        document.getElementById("ranking-user-viewed-banner").style.zIndex =
          "10000";
        anime({
          targets: topPage,
          translateY: ["-100%", 0],
          duration: 500,
          autoplay: true,
          easing: "easeInOutQuad",
        }).finished.then(() => {
          currentPage.style.display = "none";
          setPath(Path.TOP);
          dispatch(actions.updateRecommendReturnToHome(false));
        });
      } else {
        dispatch(actions.updateRecommendReturnToHome(false));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendReturnToHome]);

  useEffect(() => {
    const currentPage = document.getElementById("ranking-page-top");
    currentPage.style.display = "block";
  }, []);

  return (
    <>
      <RankingLayoutPage page={Path.TOP} path={path} setPath={setPath} />
      <RankingLayoutPage page={Path.APP} path={path} setPath={setPath} />
      <RankingLayoutPage page={Path.USER} path={path} setPath={setPath} />
      <UserViewedBanner userIcon={user.active.icon} projects={userProjects} />
    </>
  );
};
