import { SVGProps } from "react";

export const ProgressArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width={25} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.437 15.413 6.09-6.009a1.968 1.968 0 0 0 0-2.809L3.437.587C2.17-.665 0 .22 0 1.99v12.017c0 1.77 2.169 2.656 3.437 1.405Zm13.884 0 6.09-6.007a1.967 1.967 0 0 0 0-2.809L17.322.589c-1.268-1.251-3.437-.365-3.437 1.405v12.015c0 1.77 2.169 2.656 3.437 1.405Z"
      fill={props.fill}
    />
  </svg>
);
