import { SVGProps } from "react";

export const Decoration = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={64}
    height={54}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.07 0 64 35.447 60.832 40 0 23.415 5.07 0Z" fill="#B7714D" />
    <path
      d="M21.868 54 12.8 37.101 55.325 32l3.752 3.826L21.867 54Z"
      fill="#B7714D"
    />
  </svg>
);
