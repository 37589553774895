import { SVGProps } from "react";

export const InstallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={64}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1} y={1} width={62} height={38} rx={3} fill="#FEFDFE" />
    <g
      clipPath="url(#install-prompt-icon-a)"
      stroke="#94C4F7"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.8 25.38v5.02h22.4v-5.02M32 23.945V9.6M32 23.945l5.6-5.738M32 23.945l-5.6-5.738" />
    </g>
    <rect
      x={1}
      y={1}
      width={62}
      height={38}
      rx={3}
      stroke="#94C4F7"
      strokeWidth={2}
    />
    <defs>
      <clipPath id="install-prompt-icon-a">
        <path fill="#fff" transform="translate(16 4)" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
