const ToolboxInfo = {
  maxInstances: { character_motion_move: 2, character_motion_jump: 4 },
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "character_motion_move",
          },
          {
            kind: "block",
            type: "character_motion_jump",
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
