import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { actions } from "app/configSlice";
import { useScale, usePlaySound } from "common/utils";
import { NewMarkSvg, NotificationIcon } from "features/home/assets";

export const NotificationBanner = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: RootState) => state.config.notification.kids
  );
  const maintenances = useSelector(
    (state: RootState) => state.config.notification.maintenances
  );
  const config = useSelector((state: RootState) => state.config.userConfig);

  const NOTIFICATION_WIDTH = 600;
  const NOTIFICATION_HEIGHT = 90;

  const handleShowNotification = () => {
    play();
    dispatch(
      actions.updateDialog({
        type: DialogType.NOTIFICATION,
        value: true,
      })
    );
  };

  return notification.length !== 0 ? (
    <div
      className="flex-col-view items-center cursor-pointer !absolute"
      style={{
        top: 11 * scale,
        right: 180 * scale,
        width: NOTIFICATION_WIDTH * scale,
        height: NOTIFICATION_HEIGHT * scale,
      }}
    >
      <div
        className="flex-col-view items-start origin-top !justify-start rounded-[12px] border-[2px] bg-white/60 border-solid border-white pl-[14px] py-[5px]"
        style={{
          width: NOTIFICATION_WIDTH,
          height: NOTIFICATION_HEIGHT,
          transform: `scale(${scale}) translateZ(0)`,
          backfaceVisibility: "hidden",
          WebkitBackfaceVisibility: "hidden",
          boxShadow: "0px 1px 3px rgba(63, 74, 97, 0.3)",
        }}
        onClick={handleShowNotification}
      >
        <div className="flex-col-center w-full h-full !absolute top-0 left-0 backdrop-blur-[16px] z-[-1]" />
        <p className="flex-row-center text text-textcolor/black !leading-[38px] !text-left">
          <span className="inline-block w-[38px] h-[32px] mr-[8px]">
            <NotificationIcon
              fill={maintenances.length > 0 ? "#FC6560" : "#4C9DF1"}
            />
          </span>
          {maintenances.length > 0 ? (
            <>
              {maintenances[0][config.locale]?.date}
              {dayjs().diff(maintenances[0].published_at, "day") < 7 && (
                <span className="inline-block w-[58px] h-[32px] ml-[8px]">
                  <NewMarkSvg />
                </span>
              )}
            </>
          ) : (
            <>
              {notification[0][config.locale]?.date}
              {dayjs().diff(notification[0].published_at, "day") < 7 && (
                <span className="inline-block w-[58px] h-[32px] ml-[8px]">
                  <NewMarkSvg />
                </span>
              )}
            </>
          )}
        </p>
        <p className="text text-textcolor/black !leading-[38px] !text-left w-full text-body-ellipsis">
          {maintenances.length > 0
            ? maintenances[0][config.locale]?.title
            : notification[0][config.locale]?.title}
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};
