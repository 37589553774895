import { useState, ChangeEventHandler } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import produce from "immer";
import { Dialog } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { DialogType } from "app/types";
import { getEnv, actions } from "app/configSlice";
import { RootState, AppDispatch } from "app/store";
import Constants from "common/constant";
import { useScale, usePlaySound } from "common/utils";
import { Spin, CloseBtn, FadeTransition } from "common/elements";
import { createFeedback } from "features/review/api";
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
} from "./assets";
import { ThanksToFeedback } from "./ThanksToFeedback";

export enum ComplaintType {
  NONE = "NONE", // ふまんはない
  APP_HOW_TO_USE = "APP_HOW_TO_USE", //　つかいかたがわからない
  APP_HARD_TO_USE = "APP_HARD_TO_USE", // つかいづらい
  COURSE_TOO_DIFFICULT = "COURSE_TOO_DIFFICULT", // むずかしすぎる
  COURSE_TOO_EASY = "COURSE_TOO_EASY", // かんたんすぎる
  COURSE_TOO_MANY = "COURSE_TOO_MANY", // ステージがおおすぎる
  COURSE_TOO_FEW = "COURSE_TOO_FEW", // ステージがすくなすぎる
  CREATOR_HARD_TO_CREATE = "CREATOR_HARD_TO_CREATE", // うまくつくれない
  CREATOR_HARD_TO_USE = "CREATOR_HARD_TO_USE", // つかいかたがむずかしい
}

const ComplaintTypeItem = ({
  icon,
  message,
  selected,
  handelSelected,
}: {
  icon: React.ReactNode;
  message: string;
  selected: boolean;
  handelSelected: () => void;
}) => (
  <div
    className="flex-col-center w-[176px] h-[200px] rounded-[12px] overflow-hidden transition"
    onPointerDown={handelSelected}
    style={{
      transform: `translateY(${selected ? 3 : 0}px)`,
      boxShadow: selected
        ? "0px 3px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 4px 1px rgba(0, 0, 0, 0.14), 0px 1px 8px 3px rgba(0, 0, 0, 0.12)"
        : "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    }}
  >
    <div className="flex-col-el flex-center relative w-full h-[120px] bg-white">
      {icon}
    </div>

    <div
      className={`flex-col-el flex-center w-full h-full flex-1 transition-colors ${
        selected ? "bg-red/100" : "bg-blue/80"
      }`}
    >
      <p className="text-body text-white text-center text-[24px] whitespace-pre">
        {message}
      </p>
    </div>

    <div
      className={`flex-col-el flex-center absolute w-full h-full rounded-t-[12px] overflow-hidden box-border transition-colors ${
        selected
          ? "border-solid border-8 border-red/100"
          : "border-solid border-8 border-transparent"
      }`}
    />
  </div>
);

export const Feedback = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const DETAIL_MAX_LENGTH = 200;
  const location = useLocation();
  const [detail, setDetail] = useState("");
  const [thanks, setThanks] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const feedback = useSelector(
    (state: RootState) => state.config.dialog.feedback
  );
  const user = useSelector((state: RootState) => state.user.appUser);
  const [complaintTypes, setComplaintTypes] = useState<ComplaintType[]>([]);

  const handleReset = () => {
    setDetail("");
    setComplaintTypes([]);
    setThanks(false);
  };

  const handleClose = () => {
    play();
    setThanks(false);
    dispatch(
      actions.updateDialog({
        type: DialogType.FEEDBACK,
        value: false,
        args: {},
      })
    );
  };

  const handleThanksClose = () => {
    play();
    dispatch(
      actions.updateDialog({
        type: DialogType.FEEDBACK,
        value: false,
        args: {},
      })
    );
  };

  const handleSelectComplaintType = (target: ComplaintType) => () => {
    play();
    setComplaintTypes(
      produce((draft) => {
        const targetIndex = draft.findIndex((type) => type === target);
        if (targetIndex !== -1) {
          draft.splice(targetIndex, 1);
        } else {
          draft.push(target);
        }
      })
    );
  };

  const handleChangeDetail: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    let value: string;
    if (e.target.value.length > DETAIL_MAX_LENGTH) {
      value = e.target.value.substring(0, DETAIL_MAX_LENGTH);
    } else {
      value = e.target.value;
    }
    setDetail(value);
  };

  const handleConfirm = () => {
    play();
    setLoading(true);
    const orderedComplaintTypes = produce(complaintTypes, (draft) => {
      draft.sort();
    });

    createFeedback({
      uid: user.active.uid,
      feedback: {
        rating: Number(feedback.args.rating),
        complaint_type: orderedComplaintTypes.join(),
        detail: detail,
        web_version: `${Constants.major}.${Constants.minor}.${Constants.buildNumber}`,
        app_version:
          "ReactNativeWebView" in window
            ? `${window.kidsAppVersion}(${window.kidsBuildVersion})`
            : "",
        env: getEnv(),
        url: `${location.pathname}${location.search}`,
      },
    })
      .then(() => {
        setLoading(false);
        setThanks(true);
      })
      .catch((error) => console.error(JSON.stringify(error)));
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={feedback.value}
      sx={{ zIndex: 9999 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      TransitionProps={{ onExited: handleReset }}
    >
      <div
        className="flex-col-center w-full h-full !absolute top-0 left-0 z-50"
        style={{ display: loading ? "flex" : "none" }}
      >
        <Spin />
      </div>

      <div
        className="flex-col-center bg-textcolor/black/50 !absolute inset-0 w-full h-full"
        style={{ display: thanks ? "none" : "flex" }}
      >
        <div
          className="flex-col-el relative items-center justify-start w-[1180px] h-[900px] rounded-[8px] border-[12px] border-solid border-white bg-beige/60"
          style={{ transform: `scale(${scale})`, marginTop: 32 * scale }}
        >
          <CloseBtn close={handleClose} />

          <div className="flex-col-el flex-center w-[568px] mt-4">
            <div className="flex-row-el flex-center relative">
              <p
                className="title-text text-orange/100 text-[46px] tracking-widest text-body-ellipsis px-4"
                style={{ WebkitTextStroke: "16px #FFA03B" }}
              >
                {I18n.t("MSG_REVIEW_FEEDBACK_TITLE")}
              </p>
              <p
                className="title-text text-body-ellipsis text-[46px] tracking-widest text-white absolute z-10 px-4"
                style={{ WebkitTextStroke: "8px #FEFDFE" }}
              >
                {I18n.t("MSG_REVIEW_FEEDBACK_TITLE")}
              </p>
              <p className="title-text text-body-ellipsis text-[46px] tracking-widest text-red/100 absolute z-50">
                {I18n.t("MSG_REVIEW_FEEDBACK_TITLE")}
              </p>
            </div>
          </div>

          <div className="flex-col-center h-[670px] mt-8">
            <div className="flex-row-el flex-center gap-8">
              <div className="flex-col-center border-solid border-orange/100 border-4 rounded-[12px] p-8">
                <div className="flex-row-el flex-center bg-beige/60  absolute -top-6 px-2">
                  <p className="text-body text-textcolor/black text-[24px]">
                    {I18n.t("MSG_REVIEW_FEEDBACK_TYPE_APP")}
                  </p>
                </div>

                <div className="flex-col-center gap-6">
                  <ComplaintTypeItem
                    icon={<Icon1 />}
                    message={I18n.t("MSG_REVIEW_FEEDBACK_TYPE_APP_HOW_TO_USE")}
                    selected={complaintTypes.includes(
                      ComplaintType.APP_HOW_TO_USE
                    )}
                    handelSelected={handleSelectComplaintType(
                      ComplaintType.APP_HOW_TO_USE
                    )}
                  />
                  <ComplaintTypeItem
                    icon={<Icon2 />}
                    message={I18n.t("MSG_REVIEW_FEEDBACK_TYPE_APP_HARD_TO_USE")}
                    selected={complaintTypes.includes(
                      ComplaintType.APP_HARD_TO_USE
                    )}
                    handelSelected={handleSelectComplaintType(
                      ComplaintType.APP_HARD_TO_USE
                    )}
                  />
                </div>
              </div>

              <div className="flex-col-center border-solid border-orange/100 border-4 rounded-[12px] p-8">
                <div className="flex-row-el flex-center bg-beige/60  absolute -top-6 px-2">
                  <p className="text-body text-textcolor/black text-[24px]">
                    {I18n.t("MSG_REVIEW_FEEDBACK_TYPE_COURSE")}
                  </p>
                </div>

                <div className="flex-col-center gap-6">
                  <div className="flex-row-center gap-7">
                    <ComplaintTypeItem
                      icon={<Icon3 />}
                      message={I18n.t(
                        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_DIFFICULT"
                      )}
                      selected={complaintTypes.includes(
                        ComplaintType.COURSE_TOO_DIFFICULT
                      )}
                      handelSelected={handleSelectComplaintType(
                        ComplaintType.COURSE_TOO_DIFFICULT
                      )}
                    />
                    <ComplaintTypeItem
                      icon={<Icon4 />}
                      message={I18n.t(
                        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_EASY"
                      )}
                      selected={complaintTypes.includes(
                        ComplaintType.COURSE_TOO_EASY
                      )}
                      handelSelected={handleSelectComplaintType(
                        ComplaintType.COURSE_TOO_EASY
                      )}
                    />
                  </div>

                  <div className="flex-row-center gap-7">
                    <ComplaintTypeItem
                      icon={<Icon5 />}
                      message={I18n.t(
                        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_MANY"
                      )}
                      selected={complaintTypes.includes(
                        ComplaintType.COURSE_TOO_MANY
                      )}
                      handelSelected={handleSelectComplaintType(
                        ComplaintType.COURSE_TOO_MANY
                      )}
                    />
                    <ComplaintTypeItem
                      icon={<Icon6 />}
                      message={I18n.t(
                        "MSG_REVIEW_FEEDBACK_TYPE_COURSE_TOO_FEW"
                      )}
                      selected={complaintTypes.includes(
                        ComplaintType.COURSE_TOO_FEW
                      )}
                      handelSelected={handleSelectComplaintType(
                        ComplaintType.COURSE_TOO_FEW
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-col-center border-solid border-orange/100 border-4 rounded-[12px] p-8">
                <div className="flex-row-el flex-center bg-beige/60 absolute -top-6 px-2">
                  <p className="text-body text-textcolor/black text-[24px]">
                    {I18n.t("MSG_REVIEW_FEEDBACK_TYPE_CREATOR")}
                  </p>
                </div>

                <div className="flex-col-center gap-6">
                  <ComplaintTypeItem
                    icon={<Icon7 />}
                    message={I18n.t(
                      "MSG_REVIEW_FEEDBACK_TYPE_CREATOR_HARD_TO_CREATE"
                    )}
                    selected={complaintTypes.includes(
                      ComplaintType.CREATOR_HARD_TO_CREATE
                    )}
                    handelSelected={handleSelectComplaintType(
                      ComplaintType.CREATOR_HARD_TO_CREATE
                    )}
                  />
                  <ComplaintTypeItem
                    icon={<Icon8 />}
                    message={I18n.t(
                      "MSG_REVIEW_FEEDBACK_TYPE_CREATOR_HARD_TO_USE"
                    )}
                    selected={complaintTypes.includes(
                      ComplaintType.CREATOR_HARD_TO_USE
                    )}
                    handelSelected={handleSelectComplaintType(
                      ComplaintType.CREATOR_HARD_TO_USE
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex-row-center w-full mt-4">
              <div className="flex-col-center w-[880px] h-[160px]">
                <textarea
                  className="resize-none input text-body text-textcolor/black text-[26px]"
                  rows={4}
                  minLength={1}
                  value={detail}
                  placeholder={I18n.t("MSG_REVIEW_FEEDBACK_PLACEHOLDER")}
                  onChange={handleChangeDetail}
                />
              </div>
            </div>
          </div>

          <div className="flex-row-el flex-center mt-3 alpha">
            <button
              className="btn btn-primary"
              onClick={handleConfirm}
              disabled={complaintTypes.length === 0 && detail === ""}
            >
              <div className="flex-row-el flex-center gap-1 mb-[3px]">
                <MailOutlineIcon sx={{ color: "white", fontSize: "40px" }} />
                <p className="text-body text-white text-[24px]">
                  {I18n.t("MSG_REVIEW_SEND_FEEDBACK_CONFIRM")}
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <ThanksToFeedback visible={thanks} close={handleThanksClose} />
    </Dialog>
  );
};
