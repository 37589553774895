import { useState, useEffect } from "react";
import I18n from "i18n-js";
import { useScale } from "common/utils";
import { IconMagnifierWhite } from "features/creator/assets";
import { Layout, Overlay } from "features/creator/tutorial/top/stages/Layout";
import { Title } from "../Title";

const Step = (props: {
  back: () => void;
  next: () => void;
  close: () => void;
}) => {
  const { scale } = useScale();
  const { back, next, close } = props;
  const [panelRect, setPanelRect] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      const panel = document.getElementById(
        "shared-project-panel-sort-filter-btn"
      );
      const rect = panel.getBoundingClientRect();
      setPanelRect({
        x: rect.x - 0 * scale,
        y: rect.y - rect.width / 2 + 20 * scale,
        width: rect.width + 0 * scale,
        height: rect.width + 0 * scale,
      });
    }, 300);
  }, [scale]);

  return (
    <>
      <Overlay area={panelRect}>
        <></>
      </Overlay>

      <Layout transparent back={back} next={next} close={close}>
        <Title activeStep={3} />

        <div className="flex-col-el items-start justify-center w-[312px]">
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "アプリを さがす ときは"
              : "アプリを探すときは"}
          </p>
          <div
            id="shared-project-panel-sort-filter-btn"
            className="flex-row-center w-[186px] h-full bg-green/100 rounded-tl-2xl gap-4"
          >
            <div className="flex-row-center gap-2">
              <div className="flex-col-center">
                <IconMagnifierWhite />
              </div>
              <p className="text-body text-white text-[20px]">
                {I18n.t("MSG_CREATOR_APP_SORT_FILTER_TITLE")}
              </p>
            </div>
            <div className="flex-row-center h-[24px] gap-1">
              <div className="w-[4px] h-full bg-textcolor/black/25" />
              <div className="w-[4px] h-full bg-textcolor/black/25" />
              <div className="w-[4px] h-full bg-textcolor/black/25" />
            </div>
          </div>
          <p className="text-body text-textcolor/black text-[22px] whitespace-pre">
            {I18n.locale === "ja"
              ? "を おしてみましょう。"
              : "を押してみましょう。"}
          </p>
        </div>
      </Layout>
    </>
  );
};

export default Step;
