import { MouseEventHandler, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import { RootState } from "app/store";
import { btnDelay, usePlaySound, useScale } from "common/utils";
import assets from "features/builder/assets";
import { actions, selectAllMapModel } from "features/builder/slice";
import { IconEraser } from "features/creator/assets";
import { AssetModel, AssetModelType } from "features/builder/types";
import Constants from "../../../common/constant";

export const Asset = ({
  selected,
  asset,
  select,
  disabled,
}: {
  selected: boolean;
  asset?: AssetModel;
  select?: (asset: AssetModel) => MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}) => (
  <div
    onClick={select && !disabled && select(asset)}
    className={`flex-col-el flex-center cursor-pointer shrink-0 w-[100px] h-[100px] rounded border-solid border-[2px] ${
      selected ? "border-blue/100" : "border-transparent"
    } ${disabled ? "bg-gray/40" : "bg-transparent"}`}
  >
    <img
      alt="asset capture"
      className="w-full h-full"
      src={`${Constants.assetHost}/assets/models/${asset.name}.png`}
    />
  </div>
);

const AssetListFull = ({
  collapse,
  assets,
  currentMesh,
  selectedMesh,
  select,
}: {
  collapse: {
    gem: boolean;
    condition: boolean;
    character: boolean;
  };
  assets: AssetModel[];
  currentMesh: AssetModel;
  selectedMesh?: AssetModel;
  select: (asset: AssetModel) => () => void;
}) => {
  const play = usePlaySound();
  const dispatch = useDispatch();

  const handleCollapse = () => {
    play();
    dispatch(
      actions.updateCollapse({
        key: currentMesh.type,
        value: !collapse[currentMesh.type],
      })
    );
  };
  const handleSelect = (
    asset: AssetModel
  ): MouseEventHandler<HTMLDivElement> => (e) => {
    e.stopPropagation();
    select(asset)();
    dispatch(actions.selectSlot(null));
    dispatch(actions.updateActionMenu({ key: "assetList", value: false }));
    dispatch(actions.updatePredefined({ key: asset.type, value: asset.name }));
    btnDelay(() =>
      dispatch(actions.updateCollapse({ key: asset.type, value: true }))
    );
  };

  useEffect(() => {
    window.addEventListener("click", handleCollapse);
    return () => {
      window.removeEventListener("click", handleCollapse);
    };
  }, []);

  return (
    <div className="flex-col-center w-[100px] h-[100px]">
      <div className="flex-col-el gap-3 absolute bottom-3  border-solid rounded-[10px] border-[2px] border-white bg-white/60 backdrop-blur-[4px] p-3">
        {assets.map((model, index) => (
          <Asset
            key={index}
            selected={selectedMesh?.name === model.name}
            asset={model}
            select={handleSelect}
          />
        ))}
      </div>
    </div>
  );
};
const AssetList = ({
  current,
  assets,
  selectedMesh,
  select,
  disabled,
}: {
  current: string;
  assets: AssetModel[];
  selectedMesh?: AssetModel;
  select: (asset: AssetModel) => () => void;
  disabled?: boolean;
}) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const currentMesh = useMemo(
    () => assets.filter((asset) => asset.name === current).shift(),
    [current]
  );
  const collapse = useSelector((state: RootState) => state.builder.collapse);

  const handleCollapse: MouseEventHandler<HTMLDivElement> = (e) => {
    if (disabled) return;
    e.stopPropagation();
    play();
    dispatch(
      actions.updateCollapse({
        key: currentMesh.type,
        value: !collapse[currentMesh.type],
      })
    );
  };

  return (
    <div className="flex-col-el justify-end gap-3 relative w-[100px] h-[100px]">
      {!collapse[currentMesh.type] && (
        <AssetListFull
          collapse={collapse}
          assets={assets}
          currentMesh={currentMesh}
          selectedMesh={selectedMesh}
          select={select}
        />
      )}

      <div onClick={handleCollapse} className="flex-col-el flex-1">
        <Asset
          selected={selectedMesh?.name === currentMesh.name}
          asset={currentMesh}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const ModelAsset = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const mapModel = useSelector(selectAllMapModel);
  const actionMenu = useSelector(
    (state: RootState) => state.builder.actionMenu
  );
  const predefined = useSelector(
    (state: RootState) => state.builder.predefined
  );
  const selectedSlotIndex = useSelector(
    (state: RootState) => state.builder.selectedSlotIndex
  );
  const selectedAssetModel = useSelector(
    (state: RootState) => state.builder.selectedAssetModel
  );
  const slot = useSelector((state: RootState) => state.builder.slot);

  const gems = useMemo(
    () =>
      assets.filter((asset) => asset.name === AssetModelType.GEM).shift()
        .models,
    []
  );
  const conditions = useMemo(
    () =>
      assets.filter((asset) => asset.name === AssetModelType.CONDITION).shift()
        .models,
    []
  );
  const characters = useMemo(
    () =>
      assets.filter((asset) => asset.name === AssetModelType.CHARACTER).shift()
        .models,
    []
  );

  const handleSelectTap = () => {
    play();
    dispatch(
      actions.updateActionMenu({ key: "tap", value: true, exclusive: true })
    );
    dispatch(actions.selectSlot(null));
    dispatch(actions.selectedAssetMesh(null));
  };
  const handleSelectEraser = () => {
    play();
    dispatch(
      actions.updateActionMenu({ key: "eraser", value: true, exclusive: true })
    );
    dispatch(actions.selectSlot(null));
    dispatch(actions.selectedAssetMesh(null));
  };
  const handleSelectAsset = (asset: AssetModel) => () => {
    play();
    dispatch(
      actions.updateActionMenu({ key: "asset", value: true, exclusive: true })
    );
    dispatch(actions.selectedAssetMesh(asset));
  };
  const handleSelectSlot = (index: number) => () => {
    dispatch(actions.selectSlot(index));
    if (!slot[index]) {
      play();
      dispatch(
        actions.updateActionMenu({
          key: "assetList",
          value: true,
          exclusive: true,
        })
      );
      dispatch(actions.selectedAssetMesh(null));
    }
  };

  return createPortal(
    <div
      style={{
        transform: `scale(${scale})`,
        left: 12 * scale,
        bottom: 12 * scale,
        zIndex: actionMenu.assetList ? 10000 : 1,
      }}
      className="flex-col-el absolute origin-bottom-left pointer-events-auto"
    >
      <div className="flex-row-el border-solid rounded-[10px] border-[2px] border-white bg-white/60 backdrop-blur-[4px] p-3">
        <div className="flex-row-el h-[100px] gap-3">
          <div
            onClick={handleSelectTap}
            className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
              actionMenu.tap ? "border-blue/100" : "border-white"
            }`}
          >
            <div className="flex-col-center w-[48px] h-[48px] text-textcolor/black">
              <TouchAppOutlinedIcon fontSize="large" />
            </div>
          </div>

          <div
            onClick={handleSelectEraser}
            className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
              actionMenu.eraser ? "border-blue/100" : "border-white"
            }`}
          >
            <div className="flex-col-center w-[48px] h-[48px]">
              <IconEraser />
            </div>
          </div>

          <AssetList
            current={predefined.gem}
            assets={gems}
            selectedMesh={selectedAssetModel}
            select={handleSelectAsset}
          />
          <AssetList
            current={predefined.condition}
            assets={conditions}
            selectedMesh={selectedAssetModel}
            select={handleSelectAsset}
          />
          <AssetList
            current={predefined.character}
            assets={characters}
            selectedMesh={selectedAssetModel}
            select={handleSelectAsset}
            disabled={
              mapModel.filter((m) => m.type === AssetModelType.CHARACTER)
                .length > 0
            }
          />

          {slot.map((value, index) => (
            <div
              key={index}
              onClick={handleSelectSlot(index)}
              className={`flex-col-el flex-center cursor-pointer w-[100px] h-[100px] bg-white/60 rounded border-solid border-4 ${
                selectedSlotIndex === index ? "border-blue/100" : "border-white"
              }`}
            >
              {value && (
                <Asset
                  key={index}
                  selected={value?.name === selectedAssetModel?.name}
                  asset={value}
                  select={handleSelectAsset}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};
