import { SVGProps } from "react";

export const IconStopPublish = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
    <mask id="icon-stop-publish-btn-a" fill="#fff">
      <rect width={38.4} height={26.4} x={4.8} y={18} rx={1} />
    </mask>
    <rect
      width={38.4}
      height={26.4}
      x={4.8}
      y={18}
      fill={props.fill ?? "#F9E532"}
      stroke={props.stroke ?? "#3F4A61"}
      strokeWidth={7}
      mask="url(#icon-stop-publish-btn-a)"
      rx={1}
    />
    <mask id="icon-stop-publish-btn-b" fill="#fff">
      <path d="M12 4.6a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H13a1 1 0 0 1-1-1v-16Z" />
    </mask>
    <path
      fill={props.stroke ?? "#3F4A61"}
      d="M8.5 4.6A4.5 4.5 0 0 1 13 .1h22a4.5 4.5 0 0 1 4.5 4.5l-7 2.5h-17l-7-2.5Zm27.5 17H12h24Zm-23 0a4.5 4.5 0 0 1-4.5-4.5V4.6A4.5 4.5 0 0 1 13 .1l2.5 7v14.5H13ZM35 .1a4.5 4.5 0 0 1 4.5 4.5v12.5a4.5 4.5 0 0 1-4.5 4.5h-2.5V7.1l2.5-7Z"
      mask="url(#icon-stop-publish-btn-b)"
    />
    <path
      fill={props.stroke ?? "#3F4A61"}
      fillRule="evenodd"
      d="M25.2 32.079A2.399 2.399 0 0 0 24 27.6a2.4 2.4 0 0 0-1.2 4.479V34.3a.5.5 0 0 0 .5.5h1.4a.5.5 0 0 0 .5-.5v-2.221Z"
      clipRule="evenodd"
    />
  </svg>
);
