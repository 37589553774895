export const BASE_WIDTH = 1280;
export const BASE_HEIGHT = 960;

const Constants = {
  debugMode: import.meta.env.VITE_DEBUG_MODE ?? false,
  firebaseEmulatorsHost: import.meta.env.VITE_FIREBASE_EMULATORS_HOST,
  firebaseEmulatorsAuthPort: import.meta.env.VITE_FIREBASE_EMULATORS_AUTH_PORT,
  firebaseEmulatorsParentsAuthPort: import.meta.env
    .VITE_FIREBASE_EMULATORS_PARENTS_AUTH_PORT,
  firebaseEmulatorsFirestorePort: import.meta.env
    .VITE_FIREBASE_EMULATORS_FIRESTORE_PORT,
  major: import.meta.env.VITE_MAJOR_VERSION,
  minor: import.meta.env.VITE_MINOR_VERSION,
  buildNumber: import.meta.env.VITE_BUILD_NUMBER,
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  parentsApiKey: import.meta.env.VITE_PARENTS_FIREBASE_API_KEY,
  parentsAuthDomain: import.meta.env.VITE_PARENTS_FIREBASE_AUTH_DOMAIN,
  parentsProjectId: import.meta.env.VITE_PARENTS_FIREBASE_PROJECT_ID,
  parentsAppId: import.meta.env.VITE_PARENTS_FIREBASE_APP_ID,
  apiHost: import.meta.env.VITE_API_HOST,
  wsHost: import.meta.env.VITE_WS_HOST,
  apiAuthKey: import.meta.env.VITE_API_HEADER_AUTHORIZATION_KEY,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  sentryEnv: import.meta.env.VITE_SENTRY_ENV,
  sentryReleasePrefix: import.meta.env.VITE_SENTRY_RELEASE_PREFIX,
  assetHost: import.meta.env.VITE_KIDS_ASSET_HOST,
  runtimeHost: import.meta.env.VITE_KIDS_RUNTIME_HOST,
  parentsHost: import.meta.env.VITE_PARENTS_HOST,
  parentsLinkPageHost: import.meta.env.VITE_PARENTS_LINK_PAGE_HOST,
  stripeApiKey: import.meta.env.VITE_STRIPE_API_KEY,
  googleSignClientId: import.meta.env.VITE_GOOGLE_SIGN_CLIENT_ID,
  azureSpeechKey: import.meta.env.VITE_AZURE_SPEECH_KEY,
  azureSpeechRegion: import.meta.env.VITE_AZURE_SPEECH_REGION,
  azureCVEndpoint: import.meta.env.VITE_AZURE_COMPUTER_VISION_ENDPOINT,
  azureCVKey: import.meta.env.VITE_AZURE_COMPUTER_VISION_KEY,
};

export default Constants;
