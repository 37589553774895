import I18n from "i18n-js";
import { Category, Block, Value, Shadow, Field, Label } from "common/blockly";

export const Basic = () => {
  return (
    <Category name="shape" categorystyle="text_category" toolboxitemid="basic">
      <Block type="text_font_family" />
      <Block type="text_font_weight" />
      <Block type="text" />

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_text" />
      <Block type="component_property_setter_text" />

      <Label
        text={I18n.t("MSG_CREATOR_MENU_PROPERTY_FONT_FAMILY")}
        web-class="toolbox-label"
      />
      <Block type="component_property_getter_text_font_family" />
      <Block type="component_property_setter_text_font_family">
        <Value name="value">
          <Shadow type="text_font_family">
            <Field name="value">MPLUSRounded1c</Field>
          </Shadow>
        </Value>
      </Block>
      <Block type="component_property_getter_text_font_weight" />
      <Block type="component_property_setter_text_font_weight">
        <Value name="value">
          <Shadow type="text_font_weight">
            <Field name="value">Bold</Field>
          </Shadow>
        </Value>
      </Block>
    </Category>
  );
};
