import { characterDirection, CHARACTER_DIRECTION } from "../../tilesets";
import { TileMapType } from "features/courses/algorithm/game/scene/maps/TileMapType";

const character = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [1, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gemLayer1 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gemLayer2 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 29],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const gemLayer3 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];
const gem = [gemLayer1, gemLayer2, gemLayer3];

const landscapeLayer1 = [
  [88, 236, 236, 236, 236],
  [99, 99, 99, 99, 99],
  [87, 175, 87, 175, 87],
  [99, 99, 99, 99, 99],
  [88, 88, 88, 88, 88],
];
const landscapeLayer2 = [
  [0, 98, 98, 207, 103],
  [0, 0, 0, 0, 0],
  [0, 97, 0, 97, 0],
  [0, 0, 0, 0, 0],
  [27, 0, 0, 0, 128],
];
const landscapeLayer3 = [
  [0, 64, 0, 0, 26],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const landscape = [landscapeLayer1, landscapeLayer2, landscapeLayer3];

const map: TileMapType = {
  layers: 3,
  width: 5,
  height: 5,
  tileSize: 2,
  character: {
    layer: 2,
    data: character,
    direction:
      characterDirection[CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X],
  },
  gem: gem,
  landscape: landscape,
};

export default map;
