import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import i18n from "i18n-js";
import { Dialog } from "@mui/material";
import { useDialog } from "app/hook";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { useScale, usePlaySound } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";
import {
  MailSignUp,
  ParentsUser,
  ParentsSign,
  ChangePassword,
  InstitutionSign,
  MailResetPassword,
  ParentsSignAccount,
  ParentsUserAccount,
  ParentsUserUnsubscribe,
  ParentsSignUpUserProfile,
  ParentsUserUnsubscribeForm,
  ParentsSignInLinkedAccount,
} from "./sign";
import { Header } from "./Header";
import { MenuTop } from "./MenuTop";
import { RouteProps } from "./type";
import { EnableLab } from "./EnableLab";
import { ParentsApp } from "./ParentsApp";
import { ParentGate } from "./ParentGate";
import { ParentsLink } from "./ParentsLink";
import { CourseAnswer } from "./CourseAnswer";
import { Subscription } from "./subscription";
import { Notification } from "./Notification";

const PageTitle = (location: string) => {
  const path = location.split("?");
  switch (path[0]) {
    case "/enableLab":
      return i18n.t("MSG_SETTING_TITLE_ENABLE_LAB");
    case "/notification":
      return i18n.t("MSG_SETTING_TITLE_NOTIFICATION");
    case "/courseAnswer":
      return i18n.t("MSG_SETTING_TITLE_COURSE_ANSWER");
    case "/parentsApp":
      return i18n.t("MSG_SETTING_TITLE_PARENTS_LINK");
    case "/parentsUser":
      return i18n.t("MSG_SETTING_TITLE_PARENTS_USER_PROFILE");
    case "/parentsSign":
      return i18n.t("MSG_SETTING_TITLE_SIGN");
    case "/parentsSignIn":
      return i18n.t("MSG_SETTING_TITLE_SIGN_IN");
    case "/parentsSignInLinkedAccount":
      return i18n.t("MSG_SETTING_TITLE_SIGN_IN");
    case "/parentsSignUp":
      return i18n.t("MSG_SETTING_TITLE_SIGN_UP");
    case "/parentsSignUpUserProfile":
      return i18n.t("MSG_SETTING_TITLE_PARENTS_USER_ACCOUNT");
    case "/MailSignUp":
      return i18n.t("MSG_SETTING_TITLE_SIGN_UP_MAIL");
    case "/mailResetPassword":
      return i18n.t("MSG_SETTING_TITLE_SIGN_PASSWORD_RESET");
    case "/changePassword":
      return i18n.t("MSG_SETTING_TITLE_SIGN_PASSWORD_CHANGE");
    case "/parentsUserAccount":
      return i18n.t("MSG_SETTING_TITLE_PARENTS_USER_ACCOUNT");
    case "/parentsUserUnsubscribe":
      return i18n.t("MSG_SETTING_TITLE_PARENTS_USER_UNSUBSCRIBE");
    case "/parentsUserUnsubscribeForm":
      return i18n.t("MSG_SETTING_TITLE_PARENTS_USER_UNSUBSCRIBE");
    case "/institutionSign":
      return i18n.t("MSG_SETTING_TITLE_INSTITUTION_SIGN_IN");
    case "/subscription":
      return i18n.t("MSG_SETTING_TITLE_SUBSCRIPTION");
    case "/":
    default:
      return i18n.t("MSG_SETTING_TITLE_TOP");
  }
};

const MenuContentsPage = (props: RouteProps) => {
  const path = props.location.split("?");
  switch (path[0]) {
    case "/enableLab":
      return <EnableLab {...props} />;
    case "/notification":
      return <Notification {...props} />;
    case "/courseAnswer":
      return <CourseAnswer {...props} />;
    case "/parentsApp":
      return <ParentsApp {...props} />;
    case "/parentsUser":
      return <ParentsUser {...props} />;
    case "/parentsSign":
      return <ParentsSign {...props} />;
    case "/parentsSignIn":
      return <ParentsSignAccount {...props} />;
    case "/parentsSignInLinkedAccount":
      return <ParentsSignInLinkedAccount {...props} />;
    case "/parentsSignUp":
      return <ParentsSignAccount {...props} />;
    case "/parentsSignUpUserProfile":
      return <ParentsSignUpUserProfile {...props} />;
    case "/MailSignUp":
      return <MailSignUp {...props} />;
    case "/mailResetPassword":
      return <MailResetPassword {...props} />;
    case "/changePassword":
      return <ChangePassword {...props} />;
    case "/parentsUserAccount":
      return <ParentsUserAccount {...props} />;
    case "/parentsUserUnsubscribe":
      return <ParentsUserUnsubscribe {...props} />;
    case "/parentsUserUnsubscribeForm":
      return <ParentsUserUnsubscribeForm {...props} />;
    case "/institutionSign":
      return <InstitutionSign {...props} />;
    case "/subscription":
      return <Subscription {...props} />;
    case "/parentsLink":
      return <ParentsLink {...props} />;
    case "/":
    default:
      return <MenuTop {...props} />;
  }
};

export const ParentSettingPage = () => {
  const play = usePlaySound();
  const { scale } = useScale();
  const handleDialog = useDialog();
  const [location, setLocation] = useState("/");
  const parentGate = useSelector(
    (state: RootState) => state.config.dialog.parent_gate
  );

  const handleClose = () => {
    play();
    handleDialog({
      type: DialogType.PARENT_GATE,
      value: false,
    });
  };

  const navigate = (path: string) => {
    play();
    setLocation(path);
  };

  useEffect(() => {
    setLocation("/");
  }, [parentGate.value]);

  const handleOpenLink = (url: string) => () => {
    play();
    if ("ReactNativeWebView" in window) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "openLink", data: { url: url } })
      );
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      open={parentGate.value}
      sx={{ zIndex: 100000 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      transitionDuration={{ enter: 1000, exit: 500 }}
      onClose={handleClose}
    >
      <div className="flex-col-center !absolute inset-0 bg-textcolor/black/50 w-full h-full">
        <div
          className="flex-col-view items-center w-[1180px] h-[800px] rounded-[8px] border-[8px] border-solid border-white bg-white"
          style={{ transform: `scale(${scale})` }}
        >
          <CloseBtn close={handleClose} />
          <div className="flex-col-view items-center w-full h-full rounded-[4px] bg-beige/60">
            <Header title={PageTitle(location)} />

            <div className="flex-col-center w-full h-[630px] pointer-events-auto">
              {MenuContentsPage({ location, navigate, close: handleClose })}
            </div>

            <div className="flex-row-center w-full">
              <p
                className="text text-textcolor/black !leading-[30px] cursor-pointer"
                onClick={handleOpenLink("https://inexus-co.com/product/")}
              >
                {i18n.t("MSG_SETTING_FOOTER_GO_TO_SITE")}
              </p>
              <p className="text text-textcolor/black !leading-[30px]">｜</p>
              <p
                className="text text-textcolor/black !leading-[30px] cursor-pointer"
                onClick={handleOpenLink(
                  "https://inexus-co.com/privacy-policy/"
                )}
              >
                {i18n.t("MSG_SETTING_FOOTER_TERMS_OF_SERVICE")}
              </p>
              <p className="text text-textcolor/black !leading-[30px]">｜</p>
              <p
                className="text text-textcolor/black !leading-[30px] cursor-pointer"
                onClick={handleOpenLink(
                  "https://inexus-co.com/privacy-policy/"
                )}
              >
                {i18n.t("MSG_SETTING_FOOTER_PRIVACY_POLICY")}
              </p>
              <p className="text text-textcolor/black !leading-[30px]">｜</p>
              <p
                className="text text-textcolor/black !leading-[30px] cursor-pointer"
                onClick={handleOpenLink("https://inexus-co.com/contact/")}
              >
                {i18n.t("MSG_SETTING_FOOTER_INQUIRY")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {import.meta.env.PROD && (
        <ParentGate visible={parentGate.value} close={handleClose} />
      )}
    </Dialog>
  );
};
