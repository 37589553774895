import { ReactNode, MouseEventHandler } from "react";
import throttle from "lodash-es/throttle";
import { btnDelay, usePlaySound } from "common/utils";

export const ActionButton = ({
  type,
  Icon,
  text,
  onClick,
  disabled,
}: {
  type: string;
  Icon: ReactNode;
  text: string;
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const play = usePlaySound();
  const handleClick: MouseEventHandler<HTMLButtonElement> = throttle(
    (e) => {
      btnDelay(() => {
        play();
        onClick(e);
      });
    },
    1000,
    { trailing: false }
  );

  return (
    <div className="flex-row-el flex-center alpha">
      <button
        disabled={disabled}
        onClick={handleClick}
        className={`btn btn-${type}`}
      >
        <div className="flex-row-el flex-center relative gap-1">
          <div className="flex-col-center w-[48px] h-[48px]">{Icon}</div>
          <p className="text-body text-white text-font-size-body1">{text}</p>
        </div>
      </button>
    </div>
  );
};
