import React, { useState, useEffect } from "react";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { DisableTopBlocks } from "@blockly/disable-top-blocks";
import { InitMsg } from "common/blockly";
import { ToolboxInfoType } from "features/courses/program/game/type";
import { updateFlyout } from "features/courses/program/game/blockly/uitls";
import { CreatorBlocklyOptions } from "./CreatorBlocklyOptions";

export const useInitWorkspace = (args: {
  stage: number;
  step: number;
  scale: number;
  offset: number;
  answer: string;
  locale: string;
  toolboxRef: React.MutableRefObject<ToolboxInfoType>;
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void;
}) => {
  const {
    stage,
    scale,
    offset,
    answer,
    locale,
    toolboxRef,
    setWorkspace,
  } = args;

  useEffect(() => {
    InitMsg(locale);
  }, [locale]);

  useEffect(() => {
    // @ts-ignore
    Blockly.FieldTextInput.prototype.showEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this),
        this.getSourceBlock()?.getParent()?.max
      );
    };

    // @ts-ignore
    Blockly.FieldTextInput.prototype.showPromptEditor_ = function (e) {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this),
        this.getSourceBlock()?.getParent()?.max
      );
    };

    // @ts-ignore
    Blockly.FieldNumber.prototype.showEditor_ = function () {
      // @ts-ignore
      Blockly.dialog.customPrompt(
        this,
        // @ts-ignore
        Blockly.Msg["CHANGE_VALUE_TITLE"],
        this.getText(),
        function (text) {
          if (text !== null) {
            this.setValue(this.getValueFromEditorText_(text));
          }
        }.bind(this)
      );
    };

    const workspace = Blockly.inject(
      "blocklyDiv",
      CreatorBlocklyOptions(
        scale,
        toolboxRef.current.maxInstances,
        toolboxRef.current.json
      )
    );
    BlocklyJs.init(workspace);

    (Blockly.DropDownDiv.DIV_ as HTMLDivElement).style.zIndex = "10000";

    const toolbox = workspace.getToolbox() as Blockly.Toolbox;
    const div = toolbox.HtmlDiv as HTMLDivElement;
    div.style.width = "0px";
    div.style.top = `${offset * scale}px`;
    div.style.display = "none";

    workspace.updateToolbox(toolboxRef.current.json);
    updateFlyout(workspace, offset * scale, scale);

    const blocklyFlyoutEles = document.getElementsByClassName("blocklyFlyout");
    for (let i = 0; i < blocklyFlyoutEles.length; i++) {
      const blocklyFlyoutEle = blocklyFlyoutEles[i] as SVGAElement;
      blocklyFlyoutEle.setAttribute(
        "style",
        `width: ${300 * scale}px !important;overflow: visible;`
      );
    }

    const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
    blocklyFlyout.autoClose = false;

    if (!answer && stage < 3) {
      const eventBlock = new Blockly.BlockSvg(workspace, "start_answer_event");
      eventBlock.initSvg();
      eventBlock.render();
      const injectDivRect = workspace.getInjectionDiv().getBoundingClientRect();
      setTimeout(() => {
        const panel = document.getElementById("courses-program-block-panel");
        const rect = panel.getBoundingClientRect();
        eventBlock.moveBy(
          (rect.right - injectDivRect.left) / scale + 16,
          rect.y / scale + 350
        );
      }, 300);
    }
    workspace.clearUndo();
    workspace.showContextMenu = () => {};

    workspace.addChangeListener(Blockly.Events.disableOrphans);
    const disableTopBlocksPlugin = new DisableTopBlocks();
    disableTopBlocksPlugin.init();

    BlocklyJs.addReservedWords("highlightBlock");
    setWorkspace(workspace);

    return () => {
      setWorkspace(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useWorkspaceListener = (args: {
  scale: number;
  offset: number;
  answer: string;
  locale: string;
  workspace: Blockly.WorkspaceSvg;
  toolboxTree: any;
  setToolboxInfo: (toolbox: ToolboxInfoType) => void;
  toolboxRef: React.MutableRefObject<ToolboxInfoType>;
  setEvent: (event: string[]) => void;
}) => {
  const [blockXmlText, setBlockXmlText] = useState(null);
  const {
    scale,
    offset,
    answer,
    locale,
    workspace,
    toolboxRef,
    toolboxTree,
    setToolboxInfo,
    setEvent,
  } = args;

  useEffect(() => {
    if (workspace && answer) {
      const xml = Blockly.Xml.textToDom(answer);
      Blockly.Xml.domToWorkspace(xml, workspace);
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setHighlighted(false);
        block.setMovable(true);
        block.setEditable(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  useEffect(() => {
    if (workspace) {
      workspace.setScale(scale);
      workspace.updateToolbox(toolboxRef.current.json);
      updateFlyout(workspace, offset * scale, scale);
      setTimeout(() => {
        updateFlyout(workspace, offset * scale, scale);
      }, 300);

      const onWorkspaceChange = (event) => {
        switch (event.type) {
          case Blockly.Events.BLOCK_CHANGE:
          case Blockly.Events.BLOCK_CREATE:
          case Blockly.Events.BLOCK_DELETE:
          case Blockly.Events.BLOCK_MOVE:
            if (event.type === Blockly.Events.BLOCK_CREATE) {
              const block = workspace.getBlockById(event.blockId);
              if (!block) {
                // スペック低い端末でブロックをdragの後にすぐに手放して削除されると、ここでblockを読み取ることができなくなる。
                return;
              }

              Object.keys(toolboxRef.current.maxInstances).forEach(
                (type) =>
                  (toolboxRef.current.maxInstances[type] = toolboxTree[type])
              );

              workspace.getAllBlocks(true).forEach((block) => {
                block.contextMenu = false;
                if (block.type !== "start_answer_event") {
                  if (
                    toolboxRef.current.maxInstances[block.type] &&
                    toolboxTree[block.type]
                  ) {
                    toolboxRef.current.maxInstances[block.type] -= 1;
                  }
                } else {
                  if (!block.isDeletable()) {
                    block.setDeletable(true);
                  }
                }
              });

              setToolboxInfo(toolboxRef.current);
              updateFlyout(workspace, offset * scale, scale);
            }

            if (event.type === Blockly.Events.DELETE) {
              if (
                event.oldJson.type === "start_answer_event" &&
                workspace.getBlocksByType("start_answer_event", false)
                  .length === 0
              ) {
                const eventBlock = new Blockly.BlockSvg(
                  workspace,
                  "start_answer_event"
                );
                eventBlock.initSvg();
                eventBlock.render();
                const injectDivRect = workspace
                  .getInjectionDiv()
                  .getBoundingClientRect();
                const panel = document.getElementById(
                  "courses-program-block-panel"
                );
                const rect = panel.getBoundingClientRect();
                eventBlock.moveBy(
                  (rect.right - injectDivRect.left) / scale + 16,
                  rect.y / scale + 350
                );
              }

              Object.keys(toolboxRef.current.maxInstances).forEach(
                (type) =>
                  (toolboxRef.current.maxInstances[type] = toolboxTree[type])
              );
              workspace.getAllBlocks(true).forEach((block) => {
                if (
                  block.type !== "start_answer_event" &&
                  toolboxRef.current.maxInstances[block.type] &&
                  toolboxTree[block.type]
                ) {
                  toolboxRef.current.maxInstances[block.type] -= 1;
                }
              });
              workspace.updateToolbox(toolboxRef.current.json);
              const flyoutIsVisible = (workspace.getFlyout() as Blockly.Flyout).isVisible();
              updateFlyout(workspace, offset * scale, scale);
              if (!flyoutIsVisible) {
                (workspace.getFlyout() as Blockly.Flyout).setVisible(false);
              }
              setToolboxInfo(toolboxRef.current);
              workspace.trashcan.emptyContents();
            }

            if (!workspace.isDragging()) {
              BlocklyJs.init(workspace);
              const allTopBlocks = workspace
                .getTopBlocks(true)
                .filter((block) => block.isEnabled());

              if (allTopBlocks.length > 1) {
                const eventCode = Array(allTopBlocks.length).fill("");
                allTopBlocks.forEach((block) => {
                  const componentIndexFiled = block.getField(
                    "ComponentIndex"
                  ) as Blockly.FieldDropdown;
                  if (componentIndexFiled) {
                    const componentIndex = componentIndexFiled.getValue();
                    const code = BlocklyJs.blockToCode(block);
                    eventCode[Number(componentIndex) - 1] = code;
                  }
                });
                setEvent(eventCode);
              } else {
                const code = BlocklyJs.workspaceToCode(workspace).replace(
                  /function /g,
                  "async $&"
                ); // safari maybe has some problems.
                setEvent([code]);
              }
              const xml = Blockly.Xml.workspaceToDom(workspace);
              const blockXmlText = Blockly.Xml.domToText(xml);
              setBlockXmlText(blockXmlText);
            }

            break;
          default:
            break;
        }
      };
      workspace.addChangeListener(onWorkspaceChange);

      if (blockXmlText) {
        const domXml = Blockly.Xml.textToDom(blockXmlText);
        Blockly.Xml.domToWorkspace(domXml, workspace);
      }

      return () => {
        if (workspace) {
          workspace.removeChangeListener(onWorkspaceChange);
          workspace.clear();
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, workspace, scale]);

  useEffect(() => {
    return () => {
      if (workspace) {
        workspace.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
