/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useMemo, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Howl } from "howler";
import Blockly from "blockly";
import { RootState } from "app/store";
import Constants from "common/constant";

export const useSoundConfig = () => {
  const [bgmSound, setBgmSound] = useState<Howl | null>(null);
  const user = useSelector((state: RootState) => state.user.appUser);
  const play = useMemo(() => Blockly.WorkspaceAudio.prototype.play, []);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const bgm = useSelector((state: RootState) => state.config.userConfig.bgm);
  const [soundEnable, _setSoundEnable] = useState(config.sound.enable);
  const soundEnableRef = useRef(soundEnable);
  const setSoundEnable = (enable: boolean) => {
    soundEnableRef.current = enable;
    _setSoundEnable(enable);
  };
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);

  useEffect(() => {
    if (!user.active) {
      Howler.mute(true);
    }
  }, [user.active]);

  useEffect(() => {
    setSoundEnable(config.sound.enable);
  }, [config.sound.enable]);

  const handleVisibilityState = useCallback(() => {
    if (document.visibilityState === "visible") {
      if (bgm.enable && user.active) {
        // 音楽がONの場合は、バックグラウンドからアクティブへ戻す際にミュート解除
        Howler.mute(false);
      }
    } else {
      if (bgm.enable) {
        // バックグラウンドに入る前に音楽がONの場合はミュートさせる
        Howler.mute(true);
      }
    }
  }, [bgm.enable]);

  useEffect(() => {
    try {
      document.addEventListener("visibilitychange", handleVisibilityState);

      Blockly.WorkspaceAudio.prototype.play = function (name, opt_volume) {
        try {
          if (soundEnableRef.current) {
            play.apply(this, [name, opt_volume]);
          }
        } catch (error) {
          console.error(error);
        }
      };

      if ("AudioContext" in window) {
        const audioContext = new AudioContext();
        setAudioContext(audioContext);

        const bgmSoundSource = new Howl({
          mute: true,
          volume: bgm.volume,
          autoplay: false,
          loop: true,
          preload: true,
          src: [`${Constants.assetHost}/assets/bgm/bgm_normal.mp3`],
          onloaderror: (error) => console.error(JSON.stringify(error)),
          onplayerror: (error) => console.error(JSON.stringify(error)),
        });

        bgmSoundSource.play();
        setBgmSound(bgmSoundSource);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
    }
    return () => {
      if (audioContext) {
        audioContext.close();
      }
      document.removeEventListener("visibilitychange", handleVisibilityState);
    };
  }, []);

  useEffect(() => {
    if (bgmSound) {
      bgmSound.mute(!bgm.enable);
      bgmSound.volume(bgm.volume);
    }
  }, [bgm, bgmSound]);

  return { bgmSound, audioContext };
};
