import { SVGProps } from "react";

export const ZoomInSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={34}
    viewBox="0 0 32 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {/* <path
      d="M0 8a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8v18a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8Z"
      fill="#80EAC1"
    /> */}
    <path
      d="M13.998 21.995a6.998 6.998 0 1 0 0-13.995 6.998 6.998 0 0 0 0 13.995ZM9.498 14.998h9M13.998 19.497v-9M25 26l-6-6"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
