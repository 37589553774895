/* eslint-disable no-new-func */
import { useState } from "react";
import anime from "animejs";
import Constants from "common/constant";
import { sleep, btnDelay, usePlaySound } from "common/utils";
import { AnswerMark } from "features/courses/program/game/components";
import { RuntimePropType } from "features/courses/program/game/stages/types";

export const Runtime = (props: RuntimePropType) => {
  const {
    event,
    highlightBlock,
    setRunning,
    setSuccess,
    gameOver,
    setGameOver,
  } = props;
  const play = usePlaySound();
  var targetImage: HTMLImageElement;
  var targetLabel: HTMLParagraphElement;
  const [disabled, setDisabled] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [clicked, setClicked] = useState([false, false, false]);
  const targetEles = ["Pineapple", "あ", "Fragaria", "Pineapple", "あ"];
  const [result, setResult] = useState([false, false, false, false, false]);

  const handleEvent = (index: number) =>
    new Function(
      "anime",
      "sleep",
      "highlightBlock",
      "setRunning",
      "setSuccess",
      "setGameOver",
      "setDisabled",
      "clickedIndex",
      "setClickedIndex",
      "targetImage",
      "targetLabel",
      "targetEles",
      "result",
      "setResult",
      event[index]
        ? event[index]
        : `return () => {
            setSuccess(false);
            setGameOver(true);
          }
          `
    );

  const handleButton1Click = () => {
    if (!clicked[0] && !disabled) {
      btnDelay(() => {
        play();
        setRunning(true);
        setDisabled(true);
        if (clickedIndex > 1) {
          setClicked([true, clicked[1], clicked[2]]);
        }
        handleEvent(0)(
          anime,
          sleep,
          highlightBlock,
          setRunning,
          setSuccess,
          setGameOver,
          setDisabled,
          clickedIndex,
          setClickedIndex,
          targetImage,
          targetLabel,
          targetEles,
          result,
          setResult
        )();
      });
    }
  };
  const handleButton2Click = () => {
    if (!clicked[1] && !disabled) {
      btnDelay(() => {
        play();
        setRunning(true);
        setDisabled(true);
        if (clickedIndex > 1) {
          setClicked([clicked[0], true, clicked[2]]);
        }
        handleEvent(1)(
          anime,
          sleep,
          highlightBlock,
          setRunning,
          setSuccess,
          setGameOver,
          setDisabled,
          clickedIndex,
          setClickedIndex,
          targetImage,
          targetLabel,
          targetEles,
          result,
          setResult
        )();
      });
    }
  };
  const handleButton3Click = () => {
    if (!clicked[2] && !disabled) {
      btnDelay(() => {
        play();
        setRunning(true);
        setDisabled(true);

        if (clickedIndex > 1) {
          setClicked([clicked[0], clicked[1], true]);
        }
        handleEvent(2)(
          anime,
          sleep,
          highlightBlock,
          setRunning,
          setSuccess,
          setGameOver,
          setDisabled,
          clickedIndex,
          setClickedIndex,
          targetImage,
          targetLabel,
          targetEles,
          result,
          setResult
        )();
      });
    }
  };

  return (
    <div className="flex-col-center gap-2 mt-8">
      <div className="flex-col-center">
        <div
          id="course-program-border-1"
          className="flex-row-center w-[100px] h-[100px] !box-content p-2 border-solid border-[4px] rounded-[6px] border-white/20"
        >
          <img
            alt="*"
            id="course-program-answer-image-1"
            src={`${Constants.assetHost}/stamps/food/1a0dfd95f4e66ae82b697271462f8cff.png`}
            className="w-[100px] h-[100px] opacity-40 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <img
            alt="*"
            id="course-program-answer-image-2"
            src={`${Constants.assetHost}/stamps/food/b1d06bd0cb252619058dce3b50eb3a1f.png`}
            className="w-[100px] h-[100px] opacity-0 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          />
          <p
            id="course-program-answer-label-1"
            className="text-body text-textcolor/black text-[100px] leading-[100px] opacity-0 absolute"
            style={{
              filter: "drop-shadow(0px 1px 3px rgba(255, 255, 255, 1))",
            }}
          >
            あ
          </p>
          <img
            alt="*"
            id="course-program-image-1"
            className="w-[100px] h-[100px] opacity-0 absolute z-10"
          />
          <p
            id="course-program-label-1"
            className="text-body text-white text-[100px] leading-[100px] opacity-0 z-10"
          />
          <AnswerMark id={1} />
        </div>

        <p className="text text-white">ボックス1</p>
      </div>

      <div className="flex-row-el flex-center h-[50px] relative">
        <div className="flex-row-el flex-center gap-24 upsilon z-50">
          <button
            disabled={clicked[0] || gameOver}
            onClick={handleButton1Click}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン１</p>
          </button>
          <button
            disabled={clicked[1] || gameOver}
            onClick={handleButton2Click}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン２</p>
          </button>
          <button
            disabled={clicked[2] || gameOver}
            onClick={handleButton3Click}
            className="btn btn-blue"
          >
            <p className="text !mb-[2px]">ボタン３</p>
          </button>
        </div>
      </div>
    </div>
  );
};
