import { FormEvent } from "react";
import I18n from "i18n-js";
import { Dialog } from "@mui/material";
import { useScale } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";
import { ProjectType } from "features/creator/types";

export const CreateProjectModal = ({
  zIndex,
  visible,
  name,
  type,
  close,
  create,
}: {
  zIndex?: number;
  visible: boolean;
  name: string;
  type: ProjectType;
  close: () => void;
  create: (name: string, type: ProjectType) => void;
}) => {
  const { scale } = useScale();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name").toString();
    create(name, type);
  };

  return (
    <Dialog
      open={visible}
      maxWidth={false}
      sx={{ zIndex: zIndex ?? 9999 }}
      TransitionComponent={FadeTransition}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      PaperProps={{
        style: {
          margin: 0,
          overflow: "visible",
          backgroundColor: "transparent",
        },
        elevation: 0,
      }}
    >
      <div
        className="flex-col-el flex-center relative"
        style={{ transform: `scale(${scale})` }}
      >
        <div
          className="flex-col-el flex-center relative"
          style={{
            filter:
              "drop-shadow(0px 6px 6px rgb(0 0 0 / 20%)) drop-shadow(0px 10px 14px rgb(0 0 0 / 14%)) drop-shadow(0px 4px 18px rgb(0 0 0 / 12%))",
          }}
        >
          <div className="flex-col-el justify-start w-[510px] relative border-solid border-white border-[8px] rounded-[8px] bg-white">
            <CloseBtn close={close} />

            <div className="flex-col-el justify-start w-full h-full relative p-4 rounded-[4px] bg-beige/60">
              <div className="flex-row-el justify-start py-4">
                <p className="text-body text-textcolor/black text-[28px]">
                  {I18n.t("MSG_CREATOR_CREATE_APP_TITLE")}
                </p>
              </div>

              <div className="flex-col-el items-start py-4 gap-3">
                <p className="text-body text-textcolor/black text-[20px]">
                  {I18n.t("MSG_CREATOR_CREATE_APP_NAME")}
                </p>

                <form
                  onSubmit={handleSubmit}
                  className="flex-col-el w-full relative gap-5"
                >
                  <div className="flex-col-el">
                    <input
                      required
                      type="text"
                      name="name"
                      defaultValue={name}
                      readOnly={!visible}
                      autoFocus={false}
                      pattern={".{1,20}"}
                      className="peer input-modal text-body text-textcolor/black text-[26px]"
                    />
                    <label className="text-body text-left text-[14px] h-5 text-danger invisible peer-invalid:visible">
                      {I18n.t("MSG_BLOCKLY_INPUT_LENGTH_LIMIT", {
                        limit: 20,
                      })}
                    </label>
                  </div>

                  <div className="flex-row-el flex-center alpha">
                    <button type="submit" className="btn btn-primary">
                      <div className="flex-row-center gap-1 mb-[3px]">
                        <span className="material-symbols-outlined text-[36px] text-white">
                          check
                        </span>
                        <p className="text-body text-white text-font-size-body1">
                          {I18n.t("MSG_CONFIRM_BTN")}
                        </p>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
