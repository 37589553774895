import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Step8 from "./step8";
import Step9 from "./step9";

const Steps = [Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9];
export default Steps;
