import { SVGProps } from "react";

export const IconMinimize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z"
      fill="#80EAC1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.704 4.393a1.346 1.346 0 0 1 1.901 1.904L8.59 17.311h7.444a1.345 1.345 0 0 1 0 2.689H5.344A1.344 1.344 0 0 1 4 18.655V7.967a1.344 1.344 0 0 1 2.69 0v7.443L17.703 4.393Z"
      fill="#FEFDFE"
    />
  </svg>
);
