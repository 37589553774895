import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { RootState } from "app/store";
import Constants from "common/constant";
import { usePlaySound } from "common/utils";
import {
  ChatRoom,
  AccountType,
  ChatRoomType,
  ChatRoomMemberType,
} from "features/user/types";
import {
  actions,
  selectGroupUserById,
  selectAllChatRoomMessageSync,
  selectChatRoomMessageSyncById,
} from "features/user/slice";

export const MessageUserItemElement = (props: {
  isNew: boolean;
  icon: string;
  name: string;
  date?: string;
}) => {
  const { isNew, icon, name, date } = props;
  const messageDate = dayjs(date, "YYYY-MM-DDTHH:mm:ssZ");
  return (
    <>
      <div
        className="flex-col-center bg-white rounded-full z-10  w-[56px] h-[56px] mr-[16px]"
        style={{
          filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
        }}
      >
        <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
          <img alt="icon" src={icon} className="w-full h-full object-contain" />
        </div>
      </div>

      <div className="flex-col-view justify-center">
        {date && (
          <div className="flex-row-view items-center justify-start">
            <p className="text text-gray2/100 !text-left !text-[16px]">
              {messageDate.isToday()
                ? messageDate.format("HH:mm")
                : messageDate.format("MM/DD")}
            </p>

            {isNew ? (
              <div className="flex-row-center bg-red/80 w-[50px] h-[24px] text-white rounded-[4px] !ml-[24px]">
                <p className="text !text-[16px] !leading-[24px]">New</p>
              </div>
            ) : (
              <div className="flex-row-center w-[50px] h-[24px] !ml-[24px]" />
            )}
          </div>
        )}

        <div className="flex-row-view">
          <p className="text text-textcolor/black !text-left">{name}</p>
        </div>
      </div>
    </>
  );
};

const ContactChatRoomItem = (props: {
  selected: boolean;
  chatRoom: ChatRoom;
  handleSelect: () => void;
}) => {
  const { selected, chatRoom, handleSelect } = props;
  const chatRoomMessageSync = useSelector((state: RootState) =>
    selectChatRoomMessageSyncById(state, chatRoom.id)
  );

  if (!chatRoomMessageSync) return <></>;
  const latestChatRoomMessage = chatRoomMessageSync.latestMessage;

  const isNew =
    !selected &&
    (dayjs(
      chatRoomMessageSync.latestMessageCreatedAt,
      "YYYY-MM-DDTHH:mm:ssZ"
    ).diff(dayjs(chatRoomMessageSync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")) >
      0 ||
      (chatRoomMessageSync.latestReadAt === null &&
        chatRoomMessageSync.latestMessageCreatedAt !== null));

  return (
    <div
      className={`flex-row-view items-center cursor-pointer p-[16px] border-solid border-0 border-t-[1px] border-gray/20 ${
        selected && "bg-blue/20"
      }`}
      onClick={handleSelect}
    >
      <MessageUserItemElement
        isNew={isNew}
        icon={`${Constants.assetHost}/assets/images/app_official_icon.png`}
        name={I18n.t("MSG_INEXUS_OFFICIAL_CONTACT")}
        date={latestChatRoomMessage?.created_at}
      />
    </div>
  );
};

const NotificationChatRoomItem = (props: {
  selected: boolean;
  chatRoom: ChatRoom;
  handleSelect: () => void;
}) => {
  const { selected, chatRoom, handleSelect } = props;
  const chatRoomMessageSync = useSelector((state: RootState) =>
    selectChatRoomMessageSyncById(state, chatRoom.id)
  );

  if (!chatRoomMessageSync) return <></>;
  const latestChatRoomMessage = chatRoomMessageSync.latestMessage;
  if (!latestChatRoomMessage) return <></>;

  const isNew =
    !selected &&
    (dayjs(
      chatRoomMessageSync.latestMessageCreatedAt,
      "YYYY-MM-DDTHH:mm:ssZ"
    ).diff(dayjs(chatRoomMessageSync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")) >
      0 ||
      (chatRoomMessageSync.latestReadAt === null &&
        chatRoomMessageSync.latestMessageCreatedAt !== null));

  return (
    <div
      className={`flex-row-view items-center cursor-pointer p-[16px] border-solid border-0 border-t-[1px] border-gray/20 ${
        selected && "bg-blue/20"
      }`}
      onClick={handleSelect}
    >
      <MessageUserItemElement
        isNew={isNew}
        icon={`${Constants.assetHost}/assets/images/app_pluzze_icon.png`}
        name={I18n.t("MSG_INEXUS_OFFICIAL")}
        date={latestChatRoomMessage?.created_at}
      />
    </div>
  );
};

export const TalkChatRoomItem = (props: {
  selected: boolean;
  chatRoom: ChatRoom;
  handleSelect: () => void;
}) => {
  const { selected, chatRoom, handleSelect } = props;
  const user = useSelector((state: RootState) => state.user.appUser);

  const groupUserUid = chatRoom.members
    .filter(
      (member) =>
        member.type ===
        (user.active.account_type === AccountType.PARENTS
          ? ChatRoomMemberType.KIDS
          : ChatRoomMemberType.PARENTS)
    )
    .shift().uid;

  const groupUsers = useSelector((state: RootState) =>
    selectGroupUserById(state, groupUserUid)
  );
  const chatRoomMessageSync = useSelector((state: RootState) =>
    selectChatRoomMessageSyncById(state, chatRoom.id)
  );
  if (!chatRoomMessageSync) return <></>;
  const latestChatRoomMessage = chatRoomMessageSync.latestMessage;

  const isNew =
    !selected &&
    (dayjs(
      chatRoomMessageSync.latestMessageCreatedAt,
      "YYYY-MM-DDTHH:mm:ssZ"
    ).diff(dayjs(chatRoomMessageSync.latestReadAt, "YYYY-MM-DDTHH:mm:ssZ")) >
      0 ||
      (chatRoomMessageSync.latestReadAt === null &&
        chatRoomMessageSync.latestMessageCreatedAt !== null));

  return groupUsers ? (
    <div
      className={`flex-row-view items-center cursor-pointer p-[16px] border-solid border-0 border-t-[1px] border-gray/20 ${
        selected && "bg-blue/20"
      }`}
      onClick={handleSelect}
    >
      <MessageUserItemElement
        isNew={isNew}
        icon={groupUsers.icon}
        name={groupUsers.display_name}
        date={latestChatRoomMessage?.created_at}
      />
    </div>
  ) : (
    <></>
  );
};

export const ChatRoomListItem = (props: {
  selected: boolean;
  chatRoom: ChatRoom;
  handleSelect: () => void;
}) => {
  switch (props.chatRoom.type) {
    case ChatRoomType.CONTACT:
      return <ContactChatRoomItem {...props} />;
    case ChatRoomType.NOTIFICATION:
      return <NotificationChatRoomItem {...props} />;
    case ChatRoomType.TALK:
      return <TalkChatRoomItem {...props} />;
    default:
      return <></>;
  }
};

export const ChatRoomList = (props: {
  uid: string;
  selected: boolean;
  userChatRooms: ChatRoom[];
  selectedChatRoomId: string;
}) => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const { uid, selected, userChatRooms, selectedChatRoomId } = props;

  const allChatRoomMessageSync = useSelector(selectAllChatRoomMessageSync);
  const userChatRoomMessageSync = allChatRoomMessageSync.filter(
    (sync) => sync.uid === uid
  );

  const userChatRoomLookUp = Array.from(userChatRoomMessageSync).reduce(
    (acc, sync) =>
      Object.assign(acc, { [sync.id]: sync.latestMessage?.created_at }),
    {}
  );

  const sortedUserChatRoom = userChatRooms
    .filter((room) => userChatRoomLookUp[room.id])
    .sort((a, b) =>
      dayjs(userChatRoomLookUp[a.id], "YYYY-MM-DDTHH:mm:ssZ").diff(
        dayjs(userChatRoomLookUp[b.id], "YYYY-MM-DDTHH:mm:ssZ")
      ) > 0
        ? -1
        : 1
    );

  useEffect(() => {
    if (selected && sortedUserChatRoom.length > 0) {
      const userContactRoomId = sortedUserChatRoom[0].id;
      dispatch(actions.updateSelectedChatRoomId({ id: userContactRoomId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSelectChatRoom = (chatRoomId: string) => () => {
    play();
    dispatch(actions.updateSelectedChatRoomId({ id: chatRoomId }));
  };

  return (
    <>
      {sortedUserChatRoom.map((userChatRoom, index) => (
        <ChatRoomListItem
          key={index}
          selected={userChatRoom.id === selectedChatRoomId}
          chatRoom={userChatRoom}
          handleSelect={handleSelectChatRoom(userChatRoom.id)}
        />
      ))}
    </>
  );
};
