import { useState } from "react";
import I18n from "i18n-js";
import produce from "immer";
import {
  WIDTH_MIN,
  WIDTH_MAX,
  HEIGHT_MIN,
  HEIGHT_MAX,
  SHADOW_SIZE_MIN,
  SHADOW_SIZE_MAX,
  BORDER_SIZE_MIN,
  BORDER_SIZE_MAX,
  BorderStypeType,
  ShadowStyleType,
} from "common/components";
import { Vector2D } from "common/types";
import { usePlaySound } from "common/utils";
import {
  InputWidth,
  InputHeight,
  ItemContainer,
  InputBorderSize,
  InputShadowSize,
  InputTextFontSize,
  InputTextShadowSize,
} from "features/creator/property/components";
import { PropertyProps } from "features/creator/property/types";
import { calcOffset } from "features/creator/property/panels/utils";
import style from "./size.module.scss";

enum SizeItemIndex {
  WIDTH = "width",
  HEIGHT = "height",
  BORDER = "border",
  SHADOW = "shadow",
  TEXT = "text",
  TEXT_SHADOW = "text_shadow",
}

export const SizeProperty = (props: PropertyProps) => {
  const play = usePlaySound();
  const { scale, updateProperty } = props;
  const { id, property } = props.propertyEntity;

  const TAB_WIDHT = Number(style.TAB_WIDTH);
  const TAB_HEIGHT = Number(style.TAB_HEIGHT);

  const [selectedTabIndex, setSelectedTabIndex] = useState<SizeItemIndex>(
    SizeItemIndex.WIDTH
  );

  const createNewProperty = (delta: Vector2D, offset: Vector2D) => {
    const newProperty = produce(property, (draft) => {
      const newWidth = property.style.layout.width + delta.x;
      const newHeight = property.style.layout.height + delta.y;
      draft.style.layout.width = newWidth;
      draft.style.layout.height = newHeight;
      draft.style.transform.translateX -= offset.x;
      draft.style.transform.translateY += offset.y;
      if (draft.style.text) {
        const scaleX = newWidth / draft.style.defaultLayout.width;
        const scaleY = newHeight / draft.style.defaultLayout.height;
        const fontSizeScale = scaleX < scaleY ? scaleX : scaleY;
        const newFontSize = property.style.defaultText.fontSize * fontSizeScale;
        draft.style.text.fontSize =
          newFontSize < property.style.defaultText.fontSize
            ? property.style.defaultText.fontSize
            : newFontSize;
      }
    });
    return newProperty;
  };

  const handleChangeWidth = (width: number) => {
    const delta = {
      x: width - property.style.layout.width,
      y: 0,
    };
    const newOffset = calcOffset(property, delta);
    const newProperty = createNewProperty(delta, newOffset);
    updateProperty(id, newProperty);
  };
  const handleWidthReset = () => {
    const delta = {
      x: property.style.defaultLayout.width - property.style.layout.width,
      y: 0,
    };
    const newOffset = calcOffset(property, delta);
    const newProperty = createNewProperty(delta, newOffset);
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeHeight = (height: number) => {
    const delta = { x: 0, y: height - property.style.layout.height };
    const newOffset = calcOffset(property, delta);
    const newProperty = createNewProperty(delta, newOffset);
    updateProperty(id, newProperty);
  };
  const handleHeightReset = () => {
    const delta = {
      x: 0,
      y: property.style.defaultLayout.height - property.style.layout.height,
    };
    const newOffset = calcOffset(property, delta);
    const newProperty = createNewProperty(delta, newOffset);
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeBorderWidth = (width: number) => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.borderWidth = width;
    });
    updateProperty(id, newProperty);
  };
  const handleBorderWidthReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.view.borderWidth = draft.style.defaultView.borderWidth;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeFontSize = (size: number) => {
    const fontSizeScale = size / property.style.text.fontSize;
    const delta = {
      x: property.style.layout.width * (fontSizeScale - 1),
      y: property.style.layout.height * (fontSizeScale - 1),
    };
    const newOffset = calcOffset(property, delta);
    const newProperty = produce(property, (draft) => {
      draft.style.text.fontSize = size;
      draft.style.layout.width = draft.style.layout.width * fontSizeScale;
      draft.style.layout.height = draft.style.layout.height * fontSizeScale;
      draft.style.transform.translateX -= newOffset.x;
      draft.style.transform.translateY += newOffset.y;
    });
    updateProperty(id, newProperty);
  };
  const handleFontSizeReset = () => {
    const fontSizeScale =
      property.style.defaultText.fontSize / property.style.text.fontSize;
    const delta = {
      x: property.style.layout.width * (fontSizeScale - 1),
      y: property.style.layout.height * (fontSizeScale - 1),
    };
    const newOffset = calcOffset(property, delta);
    const newProperty = produce(property, (draft) => {
      draft.style.text.fontSize = draft.style.defaultText.fontSize;
      draft.style.layout.width = draft.style.layout.width * fontSizeScale;
      draft.style.layout.height = draft.style.layout.height * fontSizeScale;
      draft.style.transform.translateX -= newOffset.x;
      draft.style.transform.translateY += newOffset.y;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeShadowRadius = (radius: number) => {
    const newProperty = produce(property, (draft) => {
      draft.style.shadow.shadowRadius = radius;
    });
    updateProperty(id, newProperty);
  };
  const handleShadowRadiusReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.shadow.shadowRadius = draft.style.defaultShadow.shadowRadius;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleChangeTextShadowRadius = (radius: number) => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.textShadowRadius = radius;
    });
    updateProperty(id, newProperty);
  };
  const handleTextShadowRadiusReset = () => {
    const newProperty = produce(property, (draft) => {
      draft.style.text.textShadowRadius =
        draft.style.defaultText.textShadowRadius;
    });
    updateProperty(id, newProperty);
    play();
  };

  const handleTabClick = (tabIndex: SizeItemIndex) => () => {
    play();
    setSelectedTabIndex(tabIndex);
  };

  return (
    <div className="flex-row-center !flex-1 !items-start">
      <div
        className="flex-col-view justify-start !box-content !flex-1 bg-gray2/20 border-solid border-white overflow-hidden"
        style={{
          marginTop: 44 * scale,
          marginLeft: 12 * scale,
          borderTopLeftRadius: 16 * scale,
          borderBottomLeftRadius: 16 * scale,
          borderWidth: Math.round(4 * scale),
          borderRightWidth: 0,
        }}
      >
        <div
          className="flex-row-view"
          style={{ width: TAB_WIDHT * scale, height: TAB_HEIGHT * scale }}
        >
          <div
            className={`flex-row-view items-center origin-top-left ${
              style["property__size__panel__tab-button"]
            } ${
              selectedTabIndex === SizeItemIndex.WIDTH &&
              style["property__size__panel__tab-button__selected"]
            }`}
            style={{ transform: `scale(${scale})` }}
            onClick={handleTabClick(SizeItemIndex.WIDTH)}
          >
            <p className="text text-textcolor/black !text-[16px]">
              {I18n.t("MSG_CREATOR_MENU_PROPERTY_WIDTH")}
            </p>
          </div>
        </div>
        <div
          className="flex-row-view"
          style={{ width: TAB_WIDHT * scale, height: TAB_HEIGHT * scale }}
        >
          <div
            className={`flex-row-view items-center origin-top-left ${
              style["property__size__panel__tab-button"]
            } ${
              selectedTabIndex === SizeItemIndex.HEIGHT &&
              style["property__size__panel__tab-button__selected"]
            }`}
            style={{ transform: `scale(${scale})` }}
            onClick={handleTabClick(SizeItemIndex.HEIGHT)}
          >
            <p className="text text-textcolor/black !text-[16px]">
              {I18n.t("MSG_CREATOR_MENU_PROPERTY_HEIGHT")}
            </p>
          </div>
        </div>
        {property.style.view.borderStyle !== BorderStypeType.NONE && (
          <div
            className="flex-row-view"
            style={{ width: TAB_WIDHT * scale, height: TAB_HEIGHT * scale }}
          >
            <div
              className={`flex-row-view items-center origin-top-left ${
                style["property__size__panel__tab-button"]
              } ${
                selectedTabIndex === SizeItemIndex.BORDER &&
                style["property__size__panel__tab-button__selected"]
              }`}
              style={{ transform: `scale(${scale})` }}
              onClick={handleTabClick(SizeItemIndex.BORDER)}
            >
              <p className="text text-textcolor/black !text-[16px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER")}
              </p>
            </div>
          </div>
        )}

        {property.style.shadow.shadowStyle !== ShadowStyleType.NONE && (
          <div
            className="flex-row-view"
            style={{ width: TAB_WIDHT * scale, height: TAB_HEIGHT * scale }}
          >
            <div
              className={`flex-row-view items-center origin-top-left ${
                style["property__size__panel__tab-button"]
              } ${
                selectedTabIndex === SizeItemIndex.SHADOW &&
                style["property__size__panel__tab-button__selected"]
              }`}
              style={{ transform: `scale(${scale})` }}
              onClick={handleTabClick(SizeItemIndex.SHADOW)}
            >
              <p className="text text-textcolor/black !text-[16px]">
                {I18n.t("MSG_CREATOR_MENU_PROPERTY_SHADOW")}
              </p>
            </div>
          </div>
        )}
        {property.style.text && (
          <>
            <div
              className="flex-row-view"
              style={{ width: TAB_WIDHT * scale, height: TAB_HEIGHT * scale }}
            >
              <div
                className={`flex-row-view items-center origin-top-left ${
                  style["property__size__panel__tab-button"]
                } ${
                  selectedTabIndex === SizeItemIndex.TEXT &&
                  style["property__size__panel__tab-button__selected"]
                }`}
                style={{ transform: `scale(${scale})` }}
                onClick={handleTabClick(SizeItemIndex.TEXT)}
              >
                <p className="text text-textcolor/black !text-[16px]">
                  {I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SIZE")}
                </p>
              </div>
            </div>
            <div
              className="flex-row-view"
              style={{ width: TAB_WIDHT * scale, height: TAB_HEIGHT * scale }}
            >
              <div
                className={`flex-row-view items-center origin-top-left ${
                  style["property__size__panel__tab-button"]
                } ${
                  selectedTabIndex === SizeItemIndex.TEXT_SHADOW &&
                  style["property__size__panel__tab-button__selected"]
                }`}
                style={{ transform: `scale(${scale})` }}
                onClick={handleTabClick(SizeItemIndex.TEXT_SHADOW)}
              >
                <p className="text text-textcolor/black !text-[16px]">
                  {I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW")}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {selectedTabIndex === SizeItemIndex.WIDTH && (
        <ItemContainer
          name={I18n.t("MSG_CREATOR_MENU_PROPERTY_WIDTH")}
          scale={scale}
          width={256}
          height={308}
          left={0}
          onRest={handleWidthReset}
        >
          <InputWidth
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_WIDTH")}
            min={WIDTH_MIN}
            max={WIDTH_MAX}
            value={property.style.layout.width}
            play={play}
            onChange={handleChangeWidth}
          />
        </ItemContainer>
      )}
      {selectedTabIndex === SizeItemIndex.HEIGHT && (
        <ItemContainer
          name={I18n.t("MSG_CREATOR_MENU_PROPERTY_HEIGHT")}
          scale={scale}
          width={256}
          height={308}
          left={0}
          onRest={handleHeightReset}
        >
          <InputHeight
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_HEIGHT")}
            min={HEIGHT_MIN}
            max={HEIGHT_MAX}
            value={property.style.layout.height}
            play={play}
            onChange={handleChangeHeight}
          />
        </ItemContainer>
      )}
      {selectedTabIndex === SizeItemIndex.BORDER &&
        property.style.view.borderStyle !== BorderStypeType.NONE && (
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER")}
            scale={scale}
            width={256}
            height={308}
            left={0}
            onRest={handleBorderWidthReset}
          >
            <InputBorderSize
              name={I18n.t("MSG_CREATOR_MENU_PROPERTY_BORDER")}
              min={BORDER_SIZE_MIN}
              max={BORDER_SIZE_MAX}
              borderSize={property.style.view.borderWidth}
              borderColor={property.style.view.borderColor}
              play={play}
              onChange={handleChangeBorderWidth}
            />
          </ItemContainer>
        )}

      {selectedTabIndex === SizeItemIndex.SHADOW &&
        property.style.shadow.shadowStyle !== ShadowStyleType.NONE && (
          <ItemContainer
            name={I18n.t("MSG_CREATOR_MENU_PROPERTY_SHADOW")}
            scale={scale}
            width={256}
            height={308}
            left={0}
            onRest={handleShadowRadiusReset}
          >
            <InputShadowSize
              name={I18n.t("MSG_CREATOR_MENU_PROPERTY_SHADOW")}
              min={SHADOW_SIZE_MIN}
              max={SHADOW_SIZE_MAX}
              value={property.style.shadow.shadowRadius}
              color={property.style.shadow.shadowColor}
              play={play}
              onChange={handleChangeShadowRadius}
            />
          </ItemContainer>
        )}

      {property.style.text && (
        <>
          {selectedTabIndex === SizeItemIndex.TEXT && (
            <ItemContainer
              name={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SIZE")}
              scale={scale}
              width={256}
              height={308}
              left={0}
              onRest={handleFontSizeReset}
            >
              <InputTextFontSize
                name={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SIZE")}
                min={property.style.defaultText.fontSize}
                max={1024}
                value={property.style.text.fontSize}
                fontFamily={property.style.text.fontFamily}
                play={play}
                onChange={handleChangeFontSize}
              />
            </ItemContainer>
          )}

          {selectedTabIndex === SizeItemIndex.TEXT_SHADOW && (
            <ItemContainer
              name={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW")}
              scale={scale}
              width={256}
              height={308}
              left={0}
              onRest={handleTextShadowRadiusReset}
            >
              <InputTextShadowSize
                name={I18n.t("MSG_CREATOR_MENU_PROPERTY_TEXT_SHADOW")}
                min={SHADOW_SIZE_MIN}
                max={SHADOW_SIZE_MAX}
                value={property.style.text.textShadowRadius}
                fontFamily={property.style.text.fontFamily}
                textShadowColor={property.style.text.textShadowColor}
                play={play}
                onChange={handleChangeTextShadowRadius}
              />
            </ItemContainer>
          )}
        </>
      )}
    </div>
  );
};
