import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import I18n from "i18n-js";
import utc from "dayjs/plugin/utc";
import { Backdrop, Fade, Slider } from "@mui/material";
import { RootState } from "app/store";
import { DialogType } from "app/types";
import { useDialog, useUserConfig } from "app/hook";
import Constants from "common/constant";
import { ClickEvent } from "common/types";
import { Switch, ParentGateButton } from "common/elements";
import { btnDelay, useScale, usePlaySound, isDebugMode } from "common/utils";
import { Connection } from "features/connection";
import { IconContact, IconFeedback } from "./assets";

export const UniversalPanel = () => {
  dayjs.extend(utc);
  const play = usePlaySound();
  const { scale } = useScale();
  const navigate = useNavigate();
  const handleDialog = useDialog();
  const handleChangeUserConfig = useUserConfig();
  const universal = useSelector(
    (state: RootState) => state.config.dialog.universal
  );
  const config = useSelector((state: RootState) => state.config.userConfig);
  const appConfig = useSelector((state: RootState) => state.config.appConfig);

  const handleClose = () => {
    handleDialog({ type: DialogType.UNIVERSAL, value: false });
  };

  const handleClick = (path: string) => () => {
    btnDelay(() => {
      play();
      handleClose();
      navigate(path);
    });
  };

  const handleOpenContact = () => {
    btnDelay(() => {
      play();
      handleClose();
      handleDialog({ type: DialogType.CONTACT, value: true });
    });
  };

  const handleOpenReview = () => {
    btnDelay(() => {
      play();
      handleClose();
      handleDialog({ type: DialogType.RATING, value: true });
    });
  };

  const handleSoundVolume = (_: Event, value: number | Array<number>) =>
    handleChangeUserConfig("sound.volume", value as number);

  const handleBgmVolume = (_: Event, value: number | Array<number>) =>
    handleChangeUserConfig("bgm.volume", value as number);

  const handleSoundEnable = (e: ClickEvent) => {
    e.stopPropagation();
    handleChangeUserConfig("sound.enable", !config.sound.enable);
  };

  const handleBgmEnable = (e: ClickEvent) => {
    e.stopPropagation();
    handleChangeUserConfig("bgm.enable", !config.bgm.enable);
  };

  return (
    <Backdrop
      open={universal.value}
      sx={{
        zIndex: 100000,
        backgroundColor: "transparent",
        opacity: 1,
        backdropFilter: "blur(8px)",
      }}
    >
      <Fade in>
        <div
          className="absolute inset-0 bg-textcolor/black/50 pointer-events-auto"
          onClick={handleClose}
        >
          <div
            className="w-full h-full flex-col-center"
            style={{ transform: `scale(${scale})` }}
          >
            <div
              className="flex-col-view items-center pointer-events-auto"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <table>
                <tbody>
                  {(appConfig?.enable_lab || isDebugMode) && <Connection />}
                  <tr>
                    <td className="pb-[52px] w-[160px]">
                      <p className="text text-white !text-right !text-[30px] !leading-[30px]">
                        {I18n.t("MSG_SETTING_SOUND")}
                      </p>
                    </td>
                    <td className="px-[20px] pb-[52px] flex-row-center">
                      <div className="w-[200px]">
                        <Slider
                          min={0}
                          max={1}
                          step={0.01}
                          componentsProps={{
                            rail: {
                              style: {
                                height: 12,
                                backgroundColor: "#DBD8DA",
                                opacity: 1,
                              },
                            },
                            track: {
                              style: {
                                height: 12,
                                backgroundColor: "#94C4F7",
                                borderColor: "#94C4F7",
                              },
                            },
                            thumb: {
                              style: {
                                width: 40,
                                height: 40,
                                backgroundColor: "#94C4F7",
                              },
                            },
                          }}
                          value={config.sound.volume}
                          onChange={handleSoundVolume}
                          aria-label="sounde volume slider"
                        />
                      </div>
                    </td>
                    <td className="pb-[52px]">
                      <Switch
                        width={120}
                        height={50}
                        value={config.sound.enable}
                        onClick={handleSoundEnable}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-[52px] w-[160px]">
                      <p className="text text-white !text-right !text-[30px] !leading-[30px]">
                        {I18n.t("MSG_SETTING_BGM")}
                      </p>
                    </td>
                    <td className="px-[20px] pb-[52px] flex-row-center">
                      <div className="w-[200px]">
                        <Slider
                          min={0}
                          max={1}
                          step={0.01}
                          componentsProps={{
                            rail: {
                              style: {
                                height: 12,
                                backgroundColor: "#DBD8DA",
                                opacity: 1,
                              },
                            },
                            track: {
                              style: {
                                height: 12,
                                backgroundColor: "#94C4F7",
                                borderColor: "#94C4F7",
                              },
                            },
                            thumb: {
                              style: {
                                width: 40,
                                height: 40,
                                backgroundColor: "#94C4F7",
                              },
                            },
                          }}
                          value={config.bgm.volume}
                          onChange={handleBgmVolume}
                          aria-label="bgm volume slider"
                        />
                      </div>
                    </td>
                    <td className="pb-[52px]">
                      <Switch
                        width={120}
                        height={50}
                        value={config.bgm.enable}
                        onClick={handleBgmEnable}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Navi Page */}
              <div className="flex-row-view justify-start items-center">
                <div
                  className="flex-col-center cursor-pointer mx-[40px]"
                  onClick={handleClick("/")}
                >
                  <img
                    alt=""
                    src={`${Constants.assetHost}/assets/images/app_top_capture.webp`}
                    onError={(e) =>
                      // @ts-ignore
                      (e.target.src = `${Constants.assetHost}/assets/images/app_top_capture.png`)
                    }
                    className="flex-col-view w-[416px] h-[312px] border-solid border-white border-[12px] rounded-[8px]"
                  />
                  <p className="text text-white !text-[28px] !leading-[45px]">
                    {I18n.t("MSG_SETTING_GO_TO_TOP")}
                  </p>
                </div>

                <div className="flex-col-view justify-start w-[450px] h-[357px] mr-[40px]">
                  <button
                    className="flex-col-el flex-center cursor-pointer bg-orange/100 border-solid border-white border-[12px] rounded-[8px] active:opacity-70"
                    onClick={handleOpenContact}
                  >
                    <div className="flex-row-center">
                      <div
                        className="flex-col-center"
                        style={{ width: 320 * 0.5, height: 240 * 0.5 }}
                      >
                        <IconContact />
                      </div>

                      <p className="text text-white !text-[28px] !leading-[45px] !ml-3 !mt-3">
                        {I18n.t("MSG_SETTING_GO_TO_CONTACT")}
                      </p>
                    </div>
                  </button>

                  <button
                    className="flex-col-el flex-center cursor-pointer bg-orange/100 border-solid border-white border-[12px] rounded-[8px] mt-[22px] active:opacity-70"
                    onClick={handleOpenReview}
                  >
                    <div className="flex-row-center">
                      <div
                        className="flex-col-center"
                        style={{ width: 320 * 0.5, height: 240 * 0.5 }}
                      >
                        <IconFeedback />
                      </div>

                      <p className="text text-white !text-[28px] !leading-[45px] !mx-3 !mt-3">
                        {I18n.t("MSG_SETTING_GO_TO_REVIEW")}
                      </p>
                    </div>
                  </button>
                </div>
              </div>

              {/* Close Page Button */}
              <div className="flex-row-el flex-center w-full alpha mt-10">
                <button className="btn btn-blue" onClick={handleClose}>
                  <p className="text-body text-white text-[24px] !mb-[3px]">
                    {I18n.t("MSG_SETTING_CLOSE_BTN")}
                  </p>
                </button>
              </div>
            </div>
          </div>

          <ParentGateButton />
        </div>
      </Fade>
    </Backdrop>
  );
};
