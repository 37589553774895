import { useDispatch, useSelector } from "react-redux";
import I18n from "i18n-js";
import { RootState } from "app/store";
import { usePlaySound } from "common/utils";
import { RadioButton } from "common/elements";
import { actions } from "features/creator/slice";
import { SortedOrder } from "features/creator/types";
import { IconNew, IconFavorited } from "features/creator/assets";

export const ProjectSort = () => {
  const play = usePlaySound();
  const dispatch = useDispatch();
  const sortedOrder = useSelector(
    (state: RootState) => state.creator.projectSortedOrder
  );
  const handleSortedOrder = (order: SortedOrder) => () => {
    play();
    dispatch(actions.handleUpdateProjectSortedOrder(order));
  };

  return (
    <div
      id="shared-project-panel-sort-panel"
      className="flex-col-center gap-2 ml-2 mr-8"
    >
      <div className="flex-row-center bg-green/100 rounded-xl px-8 h-[22px]">
        <p className="text-body text-white text-font-size-caption1">
          {I18n.t("MSG_CREATOR_APP_SORT_TITLE")}
        </p>
      </div>

      <div className="flex-row-center gap-8">
        <div
          className="flex-row-center gap-1"
          onClick={handleSortedOrder(SortedOrder.FAVORITED)}
        >
          <div className="flex-col-center w-[32px] h-[32px]">
            <RadioButton
              size={32}
              value={sortedOrder === SortedOrder.FAVORITED}
              label={""}
              onChange={handleSortedOrder(SortedOrder.FAVORITED)}
            />
          </div>
          <div className="flex-col-center w-[24px] h-[24px]">
            <IconFavorited />
          </div>
          <p className="text-body text-textcolor/black text-[20px]">
            {I18n.t("MSG_CREATOR_APP_SORT_FAVORITED")}
          </p>
        </div>

        <div
          className="flex-row-center gap-1"
          onClick={handleSortedOrder(SortedOrder.DATE)}
        >
          <div className="flex-col-center w-[32px] h-[32px]">
            <RadioButton
              size={32}
              value={sortedOrder === SortedOrder.DATE}
              label={""}
              onChange={handleSortedOrder(SortedOrder.DATE)}
            />
          </div>
          <div className="flex-col-center w-[50px]">
            <IconNew color="#F2DA00" />
          </div>
          <p className="text-body text-textcolor/black text-[20px]">
            {I18n.t("MSG_CREATOR_APP_SORT_DATE")}
          </p>
        </div>
      </div>
    </div>
  );
};
