import { MessageContentType } from "features/user/types";
import { ProjectItemComponent } from "./ProjectItemComponent";

export const ChatMessageItemContent = (props: {
  isFromUser: boolean;
  messageContent: any;
  messageContentType: MessageContentType;
}) => {
  const { isFromUser, messageContent, messageContentType } = props;

  switch (messageContentType) {
    case MessageContentType.TEXT:
      return (
        <div
          className={`max-w-[60%] p-3 rounded-md ${
            isFromUser ? "bg-green/80 ml-1" : "bg-gray/20 mr-1"
          }`}
        >
          <p className="text text-textcolor/black !text-left break-all !whitespace-pre-line">
            {messageContent.text}
          </p>
        </div>
      );

    case MessageContentType.IMAGE:
      return (
        <div className={`max-w-[60%] ${isFromUser ? "ml-1" : "mr-1"}`}>
          <img
            alt="画像メッセージ"
            src={messageContent.url}
            className="w-[100px] h-[100px]"
          />
        </div>
      );

    case MessageContentType.COURSE_CLEAR:
      return (
        <div
          className={`flex-row-center gap-3 max-w-[70%] p-3 rounded-md ${
            isFromUser ? "bg-green/80 ml-1" : "bg-gray/20 mr-1"
          }`}
        >
          <img
            alt="画像メッセージ"
            className="w-[97px] h-[120px]"
            src={messageContent.image}
          />
          <div className="flex-row-center h-[120px]">
            <p className="text text-textcolor/black !text-left">
              {messageContent.text}
            </p>
          </div>
        </div>
      );

    case MessageContentType.PROJECT:
      return (
        <ProjectItemComponent
          isFromUser={isFromUser}
          messageContent={messageContent}
        />
      );

    default:
      return <></>;
  }
};
