export const Clock = ({
  question,
  answer,
}: {
  question: { hour: number; minutes: number };
  answer?: { hour: number; minutes: number };
}) => {
  return (
    <div className="relative w-[212px] h-[212px]">
      <div className="relative w-[200px] h-[200px] border-solid border-[6px] border-white rounded-full">
        {Array(12)
          .fill(0)
          .map((_, index) => (
            <div
              key={index}
              className="absolute top-0 left-[99px] w-[2px] h-[100px] after:content-[''] after:absolute after:top-0 after:w-[2px] after:h-[15px] after:bg-white origin-bottom"
              style={{ transform: `rotate(${30 * (index + 1)}deg)` }}
            >
              <div
                className="flex-col-center mt-[15px]"
                style={{ transform: `rotate(-${30 * (index + 1)}deg)` }}
              >
                <p className="text text-white">{index + 1}</p>
              </div>
            </div>
          ))}

        <div
          data-value={`${question.minutes}`}
          id="course-program-clock-minute-hand"
          className="absolute top-[40px] left-[98px] w-[4px] h-[60px] bg-[#5d0202] origin-bottom"
          style={{
            transform: `rotate(${360 * (question.minutes / 60)}deg)`,
          }}
        />
        <div
          data-value={`${question.hour}`}
          id="course-program-clock-hour-hand"
          className="absolute top-[50px] left-[98px] w-[4px] h-[50px] bg-[#ff105e] origin-bottom"
          style={{
            transform: `rotate(${
              30 * question.hour + 30 * (question.minutes / 60)
            }deg)`,
          }}
        />
        {answer && (
          <>
            <div
              className="absolute top-[40px] left-[98px] w-[4px] h-[60px] bg-[#5d0202] origin-bottom opacity-40"
              style={{
                transform: `rotate(${360 * (answer.minutes / 60)}deg)`,
              }}
            />
            <div
              className="absolute top-[50px] left-[98px] w-[4px] h-[50px] bg-[#ff105e] origin-bottom opacity-40"
              style={{
                transform: `rotate(${
                  30 * answer.hour + 30 * (answer.minutes / 60)
                }deg)`,
              }}
            />
          </>
        )}

        <div className="absolute top-[93px] left-[93px] w-[15px] h-[15px] rounded-full bg-red/100" />
      </div>
    </div>
  );
};
