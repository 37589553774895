import Constants from "common/constant";

export const Step3 = () => {
  return (
    <div className="flex-row-view w-full h-full">
      <div className="flex-row-view mt-[30px] w-full h-[400px]">
        <div className="flex-col-center w-[514px] h-full ml-[39px] mr-[48px]">
          <p className="text text-textcolor/black">
            「<span className="text text-safe">じゆうにつくる</span>」では、
            <br />
            「コースでまなぶ」で身につけた
            <br />
            知識を活かし
            <br />
            ブロックコーディングを使って
            <br />
            自分でアプリを作ることが
            <br />
            できます。
            <br />
            <br />
            自由にレイアウトを作るだけでなく
            <br />
            自由に描けるお絵かき機能などもあり、
            <br />
            お子様が楽しみながら
            <br />
            自分のアイデアを
            <br />
            形にすることができます。
          </p>
        </div>
        <div className="flex-row-center w-[400px] h-full">
          <img
            alt="じゆうにつくる"
            src={`${Constants.assetHost}/assets/images/freebuild_top_icon_capture.png`}
            className="w-[309px] h-[272px]"
          />
        </div>
      </div>
    </div>
  );
};
