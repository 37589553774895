import { sleep } from "common/utils";
import { Scene } from "@babylonjs/core/scene";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

// rotation direction:
// counterclockwise（turn left）: Vector3(0, -Math.PI / 2, 0)
// clockwise（turn right）: Vector3(0, Math.PI / 2, 0)
const performRotation = async (
  scene: Scene,
  character: AbstractMesh,
  direction: Vector3
) => {
  const characterRotation = new Animation(
    "rotation",
    "rotation.y",
    2,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );

  const initial = new Vector3().copyFrom(character.rotation);

  const animationKeys = [];
  animationKeys.push({
    frame: 0,
    value: character.rotation.y,
  });
  animationKeys.push({
    frame: 1,
    value: character.rotation.y + direction.y,
  });
  characterRotation.setKeys(animationKeys);
  scene.beginDirectAnimation(character, [characterRotation], 0, 1, false);

  await sleep(1500);

  // 上記のアニメーションの変化の値は累積となる、ここで一旦リセットさせ、rotationを自動計算させる。
  character.rotation = initial;
  character.addRotation(0, direction.y, 0);
};

export const performTurnToLeft = async (
  scene: Scene,
  character: AbstractMesh
) => {
  await performRotation(scene, character, new Vector3(0, -Math.PI / 2, 0));
};

export const performTurnToRight = async (
  scene: Scene,
  character: AbstractMesh
) => {
  await performRotation(scene, character, new Vector3(0, Math.PI / 2, 0));
};
