import { SVGProps } from "react";

export const IconArrowPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 45"
    {...props}
  >
    <path
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      fill="#FDA3A0"
    />
    <path
      d="M12 6.1v12M6 12.1h12"
      stroke="#FEFDFE"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 37.314h16M14.657 43.314l5.657-5.657L14.657 32"
      stroke="#FDA3A0"
      strokeWidth={4}
    />
  </svg>
);
