import React from "react";
import { Category, Block, Value, Shadow, Field } from "common/blockly";

const Toolbox = (props) => {
  return (
    <xml is="blockly" id={props.toolboxId} style={{ display: "none" }}>
      <Category toolboxitemid="category">
        <Block type="controls_whileUntil_colour_internal">
          <Value name="COLOUR">
            <Shadow type="colour_picker_internal">
              <Field name="COLOUR">#71cdfc</Field>
            </Shadow>
          </Value>
        </Block>
        <Block type="controls_if_colour_internal">
          <Value name="COLOUR">
            <Shadow type="colour_picker_internal">
              <Field name="COLOUR">#71cdfc</Field>
            </Shadow>
          </Value>
        </Block>
        <Block type="controls_repeat_var_internal" />
        <Block type="character_motion_move"></Block>
        <Block type="character_motion_jump"></Block>
        <Block type="character_motion_turn_right"></Block>
        <Block type="variables_set">
          <Field name="VAR">へんすう</Field>
          <Value name="VALUE">
            <Shadow type="math_number">
              <Field name="NUM">0</Field>
            </Shadow>
          </Value>
        </Block>
        <Block type="variables_get">
          <Field name="VAR">へんすう</Field>
        </Block>
      </Category>
    </xml>
  );
};

const ToolboxInfo = {
  maxInstances: {
    controls_whileUntil_colour_internal: 1,
    controls_if_colour_internal: 2,
    controls_repeat_var_internal: 2,
    character_motion_move: 1,
    character_motion_jump: 2,
    character_motion_turn_right: 2,
    variables_set: 1,
    variables_get: 2,
  },
  Toolbox: Toolbox,
  json: {
    kind: "categoryToolbox",
    contents: [
      {
        kind: "category",
        toolboxitemid: "category",
        contents: [
          {
            kind: "block",
            type: "controls_whileUntil_colour_internal",
            inputs: {
              COLOUR: {
                shadow: {
                  type: "colour_picker_internal",
                  fields: {
                    COLOUR: "#71cdfc",
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: "controls_if_colour_internal",
            inputs: {
              COLOUR: {
                shadow: {
                  type: "colour_picker_internal",
                  fields: {
                    COLOUR: "#71cdfc",
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: "controls_repeat_var_internal",
          },
          {
            kind: "block",
            type: "character_motion_move",
          },
          {
            kind: "block",
            type: "character_motion_jump",
          },
          {
            kind: "block",
            type: "character_motion_turn_right",
          },
          {
            kind: "block",
            type: "variables_set",
            fields: {
              VAR: { name: "へんすう" },
            },
            inputs: {
              VALUE: {
                shadow: {
                  type: "math_number",
                  fields: {
                    NUM: 0,
                  },
                },
              },
            },
          },
          {
            kind: "block",
            type: "variables_get",
            fields: {
              VAR: { name: "へんすう" },
            },
          },
        ],
      },
    ],
  },
};

export default ToolboxInfo;
