import * as React from "react";

export const MaterialStarSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={42}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m21 2.235 5.87 11.7L40 15.805l-9.5 9.11 2.24 12.85L21 31.695l-11.75 6.07 2.25-12.85-9.5-9.11 13.13-1.87L21 2.235Z"
        fill="#F8F1E8"
        stroke="#E6CCA1"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
