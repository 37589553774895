/* eslint-disable no-new-func */
import { useRef, useCallback } from "react";
import { Scene } from "@babylonjs/core/scene";
import { Engine } from "@babylonjs/core/Engines/engine";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { useCanvas } from "features/courses/hook";
import {
  loadMap,
  loadMesh,
  TileMapType,
  CHARACTER_TYPE,
} from "features/courses/algorithm/game/scene/maps";
import { performAnimation } from "features/courses/algorithm/game/scene/animations";

export const offset = new Vector3(1, 0, 1);

interface SceneRenderConfigFunc {
  setLoading: (loading: boolean) => void;
  setScene?: (scene: Scene) => void;
  setCharacter: (character: AbstractMesh) => void;
  handlePlusGemScore: (scene: Scene, totalGem: number) => void;
}

export const GameScene = ({
  width,
  height,
  scale,
  map,
  config,
  reload,
}: {
  width: number;
  height: number;
  scale: number;
  map: TileMapType;
  config: SceneRenderConfigFunc;
  reload: boolean;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const onSceneReady = useCallback(
    async (canvas: HTMLCanvasElement, engine: Engine, scene: Scene) => {
      const camera = new ArcRotateCamera(
        "camera",
        Math.PI / 4,
        Math.PI / 4,
        width > height ? 22 : 25 / scale,
        new Vector3(4.5 + offset.x, offset.y, 4 + offset.z),
        scene
      );
      camera.lowerBetaLimit = 0;
      camera.upperBetaLimit = Math.PI / 2;
      camera.lowerRadiusLimit = 10;
      camera.upperRadiusLimit = 40 / scale;
      camera.attachControl(canvas, true);

      const light = new HemisphericLight(
        "light",
        new Vector3(-3, 5, -3),
        scene
      );
      light.intensity = 1.5;

      scene.clearColor = new Color4(0, 0, 0, 0);

      const { character, gems } = await loadMap(
        scene,
        map,
        offset,
        CHARACTER_TYPE.CHARACTER_TYPE_DUCK
      );
      config.setCharacter(character);

      const mark = await loadMesh(scene, "lightning.gltf.glb");
      mark.name = "mark";
      mark.setEnabled(false);

      gems.forEach((gem) => {
        performAnimation(scene, gem);
      });
      config.setLoading(false);

      scene.onAfterRenderObservable.add(() => {
        if (!scene.isDisposed) {
          gems.forEach((gem) => {
            if (!gem.isDisposed() && character.intersectsMesh(gem)) {
              gem.dispose();
              config.handlePlusGemScore(scene, gems.length);
            }
          });
        }
      });

      config.setScene(scene);

      engine.runRenderLoop(() => {
        scene.render();
      });
    },
    []
  );

  useCanvas(
    elementRef,
    onSceneReady,
    (error) => {
      console.log(error);
    },
    reload
  );

  return (
    <div
      ref={elementRef}
      className="absolute w-full h-full pointer-events-none"
    />
  );
};
