import { SVGProps } from "react";

export const IconCircleZoomIn = (props: SVGProps<SVGSVGElement>) => {
  const width = props.width ? props.width : 1.5;
  const color = props.color ? props.color : "#ffffff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-circle-plus"
      viewBox="0 0 24 24"
      strokeWidth={width}
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
      <path d="M9 12l6 0" />
      <path d="M12 9l0 6" />
    </svg>
  );
};

export const IconCircleZoomOut = (props: SVGProps<SVGSVGElement>) => {
  const width = props.width ? props.width : 1.5;
  const color = props.color ? props.color : "#ffffff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-circle-minus"
      viewBox="0 0 24 24"
      strokeWidth={width}
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
      <path d="M9 12l6 0" />
    </svg>
  );
};

export const IconCircleZoomReset = (props: SVGProps<SVGSVGElement>) => {
  const width = props.width ? props.width : 1.5;
  const color = props.color ? props.color : "#ffffff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-augmented-reality"
      viewBox="0 0 24 24"
      strokeWidth={width}
      stroke={color}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
      <path d="M4 16v2a2 2 0 0 0 2 2h2" />
      <path d="M16 4h2a2 2 0 0 1 2 2v2" />
      <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
      <path d="M12 12.5l4 -2.5" />
      <path d="M8 10l4 2.5v4.5l4 -2.5v-4.5l-4 -2.5z" />
      <path d="M8 10v4.5l4 2.5" />
    </svg>
  );
};
