import { useState } from "react";
import { Dialog } from "@mui/material";
import { useScale, usePlaySound } from "common/utils";
import { CloseBtn, FadeTransition } from "common/elements";
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  StepProgressLayout,
} from "./pages";

const StepPages = [
  () => <Step1 />,
  () => <Step2 />,
  () => <Step3 />,
  () => <Step4 />,
  () => <Step5 />,
  () => <Step6 />,
];

export const TutorialInApp = (props: { close: () => void }) => {
  const { close } = props;
  const play = usePlaySound();
  const { scale } = useScale();
  const [step, setStep] = useState<number>(1);

  const Page = StepPages[step - 1];
  const navigate = (step: number) => () => {
    play();
    setStep(step);
  };
  const next = () => {
    play();
    setStep(step + 1);
  };
  const back = () => {
    play();
    setStep(step - 1);
  };

  return (
    <Dialog
      open
      fullScreen
      maxWidth={false}
      sx={{ zIndex: 100000 }}
      TransitionComponent={FadeTransition}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
      transitionDuration={{ enter: 1000, exit: 500 }}
    >
      <div className="flex-col-center w-full h-full bg-textcolor/black/50">
        <div
          className="flex-col-center"
          style={{ width: 1200 * scale, height: 800 * scale }}
        >
          <div
            className="flex-col-center w-[1180px] h-[800px]"
            style={{ transform: `scale(${scale})` }}
          >
            <div className="w-full h-full bg-beige/60 box-border absolute top-0 left-0 rounded-[8px] border-[12px] border-white border-solid" />

            <div className="flex-col-center !absolute top-[14px] right-[14px]">
              <CloseBtn close={close} />
            </div>

            <StepProgressLayout
              navigate={navigate}
              next={next}
              back={back}
              close={close}
              step={step}
              isInApp={true}
            >
              <Page />
            </StepProgressLayout>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
