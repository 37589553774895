import { SVGProps } from "react";

export const AppIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <mask
      id="app-icon-a"
      width={28}
      height={28}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <circle cx={14} cy={14} r={14} fill="#D9D9D9" />
    </mask>
    <g mask="url(#app-icon-a)">
      <path
        fill="#FC6560"
        d="M12.083 11.459v15.05c.632.095 1.271.143 1.91.142 3.355 0 6.572-1.333 8.944-3.706a12.657 12.657 0 0 0 3.449-11.489H12.083v.003Z"
      />
      <path
        fill="#F9E532"
        d="m17.447 25.811.508-3.88.04-.268a3.395 3.395 0 0 1-1.8.538 3.589 3.589 0 0 1 0-7.178 3.419 3.419 0 0 1 1.8.539c0-.081-.026-.17-.037-.25l-.436-3.342h-2.939a.807.807 0 0 1-.807-.807v-5.64H4.589A12.608 12.608 0 0 0 1.359 14c0 3.356 1.333 6.575 3.705 8.948a12.645 12.645 0 0 0 12.39 3.227v-.27c-.013-.024-.01-.059-.007-.094Z"
      />
      <path
        fill="#FEFDFE"
        d="M19.02 20.098c-.662-.538-1.346-.129-1.73.097-.32.233-.7.368-1.096.388a1.972 1.972 0 0 1-1.393-3.368c.37-.37.87-.578 1.393-.579a2.06 2.06 0 0 1 1.096.388c.374.226 1.076.643 1.73.097.756-.622.667-1.193.538-2.036l-.525-4.02a.808.808 0 0 0-.807-.702H15.39v-5.64a.808.808 0 0 0-.808-.808H6.356c-.334.253-.654.525-.96.808-.308.282-.539.538-.808.807h9.176v5.633a.808.808 0 0 0 .808.807h2.938l.436 3.336c0 .08.027.17.038.25a3.42 3.42 0 0 0-1.8-.538 3.589 3.589 0 0 0-3.312 4.965 3.593 3.593 0 0 0 3.322 2.218 3.395 3.395 0 0 0 1.8-.538l-.04.269-.508 3.885v.374c.269-.078.538-.162.807-.256.27-.094.576-.22.858-.345l.444-3.421c.132-.875.221-1.446-.535-2.07Z"
      />
      <path
        fill="#4C9DF1"
        d="m6.118 10.673.269.043a3.434 3.434 0 0 1-.538-1.804 3.592 3.592 0 0 1 3.584-3.586 3.59 3.59 0 0 1 3.587 3.586 3.416 3.416 0 0 1-.538 1.801l.25-.038h.06l6.132-.495V2.345A12.589 12.589 0 0 0 14 1.352a12.644 12.644 0 0 0-12.04 8.782c.051.005.103.012.153.022l4.005.517Z"
      />
      <path
        fill="#FEFDFE"
        d="M19.726 2.722c-.269-.137-.538-.269-.807-.382v7.835l-6.133.495h-.06l-.25.038c.345-.539.531-1.163.539-1.801a3.592 3.592 0 0 0-3.582-3.581 3.589 3.589 0 0 0-3.587 3.586c.008.64.194 1.264.538 1.804l-.269-.043-4.01-.523a1.496 1.496 0 0 0-.153-.021 11.767 11.767 0 0 0-.398 1.599h.137a.427.427 0 0 1 .118 0h.062l4.015.525a5.6 5.6 0 0 0 .83.092 1.456 1.456 0 0 0 1.226-.625c.538-.662.13-1.346-.094-1.728a2.052 2.052 0 0 1-.39-1.099A1.974 1.974 0 0 1 10.824 7.5c.37.37.578.871.579 1.394a2.052 2.052 0 0 1-.39 1.099c-.224.371-.64 1.076-.094 1.728.613.748 1.178.67 2.026.538l6.846-.554a.807.807 0 0 0 .74-.808v-7.73c-.258-.158-.53-.304-.805-.444Z"
      />
      <path
        fill="#1B775C"
        d="M19.227 2.48v7.633h6.803a12.688 12.688 0 0 0-6.803-7.633Z"
      />
      <path
        fill="#FEFDFE"
        d="M26.262 10.92c-.067-.269-.145-.538-.231-.807h-6.803V2.48a11.823 11.823 0 0 0-1.615-.606v9.046a.808.808 0 0 0 .808.808h8.014a11.609 11.609 0 0 0-.173-.808Z"
      />
      <path
        fill="#FEFDFE"
        d="M1.655 14.945a12.402 12.402 0 0 1 2.953-9.004 12.39 12.39 0 0 1 8.447-4.288 12.389 12.389 0 0 1 8.998 2.95 12.4 12.4 0 0 1 4.29 8.444 12.402 12.402 0 0 1-2.952 9.004 12.39 12.39 0 0 1-8.447 4.288 12.394 12.394 0 0 1-9-2.955 12.405 12.405 0 0 1-4.289-8.45v.011Zm-1.614.113a14.002 14.002 0 0 0 4.841 9.557 13.99 13.99 0 0 0 22.41-6.268c.573-1.748.795-3.59.655-5.424a14.002 14.002 0 0 0-4.844-9.551 13.99 13.99 0 0 0-19.73 1.512A14.001 14.001 0 0 0 .041 15.07v-.01Z"
      />
      <path
        fill="url(#app-icon-b)"
        d="M13.992 27.993c7.723 0 13.983-6.264 13.983-13.992C27.975 6.274 21.715.01 13.992.01 6.27.01.01 6.274.01 14.001c0 7.728 6.26 13.992 13.982 13.992Z"
        opacity={0.05}
        style={{
          mixBlendMode: "multiply",
        }}
      />
      <path
        fill="url(#app-icon-c)"
        d="M14.01 28C21.735 28 28 21.732 28 14S21.736 0 14.01 0C6.281 0 .018 6.268.018 14s6.263 14 13.99 14Z"
        opacity={0.1}
        style={{
          mixBlendMode: "screen",
        }}
      />
    </g>
    <defs>
      <linearGradient
        id="app-icon-b"
        x1={1.883}
        x2={26.11}
        y1={7.007}
        y2={20.989}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#3F4A61" />
      </linearGradient>
      <linearGradient
        id="app-icon-c"
        x1={2107.47}
        x2={3563.36}
        y1={-24310.6}
        y2={-26832}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#3F4A61" />
      </linearGradient>
    </defs>
  </svg>
);
