import { useScale } from "common/utils";
import { CreatorOptions } from "features/creator/types";
import { IconControlPanel } from "features/creator/assets";
import { RedoBtn } from "features/creator/control/RedoBtn";
import { UndoBtn } from "features/creator/control/UndoBtn";
import { PasteBtn } from "features/creator/control/PasteBtn";
import { ZoomPanel } from "features/creator/control/ZoomPanel";
import { DeleteBtn } from "features/creator/control/DeleteBtn";
import { TemplateCopyBtn } from "features/creator/control/TemplateCopyBtn";

export const TemplateControlPanel = (props: { options: CreatorOptions }) => {
  const { scale } = useScale();
  const { margin, headerHeight } = props.options;

  return (
    <div
      className="flex-col-center z-[1] !absolute"
      style={{
        right: margin * scale,
        top: headerHeight * scale,
        width: 72 * scale,
        height: 576 * scale,
        backfaceVisibility: "hidden",
      }}
    >
      <div
        className="flex-col-center drop-shadow-normal_show"
        style={{
          transform: `scale(${scale})`,
          filter: "drop-shadow(1px 1px 2px rgba(63, 74, 97, 0.4))",
        }}
      >
        {/* HEADER */}
        <div className="flex-col-center w-full bg-beige/alt rounded-t-[12px]">
          <div className="flex-col-center w-[24px] h-[24px] my-[6px]">
            <IconControlPanel />
          </div>
        </div>

        <div className="flex-col-center bg-beige/60 border-solid border-[4px] border-t-0 rounded-b-[16px] border-white">
          <TemplateCopyBtn />
          <PasteBtn />
          <UndoBtn />
          <RedoBtn />
          <DeleteBtn />
          <ZoomPanel />
        </div>
      </div>
    </div>
  );
};
