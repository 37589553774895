import en from "./en";
import ja from "./ja";
import ja_kanji from "./ja_kanji";
import zh_tw from "./zh_tw";

export const I18nTranslations = {
  en: en,
  ja: ja,
  ja_kanji: ja_kanji,
  zh_tw: zh_tw,
};
