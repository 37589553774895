export const StageDescriptionRect = { left: 20, top: 750 };

export const StageLastLevelRect = [
  {
    left: 0,
    top: 170,
  },
  {
    left: 350,
    top: 170,
  },
  {
    left: 700,
    top: 170,
  },
  {
    left: 1050,
    top: 170,
  },
];

export const StageLastLevelPath = (
  radius: number,
  scale: number,
  offset: number
) => [
  `M ${(StageLastLevelRect[0].left + radius) * scale + offset} ${
    (StageLastLevelRect[0].top + radius) * scale
  } Q ${(StageLastLevelRect[0].left + radius) * scale + offset} ${
    (StageLastLevelRect[0].top +
      radius * 2 +
      (StageLastLevelRect[1].top - StageLastLevelRect[0].top - radius * 2) /
        2) *
    scale
  } ${StageLastLevelRect[1].left * scale + offset} ${
    (StageLastLevelRect[1].top + radius) * scale
  }`,
  `M ${(StageLastLevelRect[1].left + radius) * scale + offset} ${
    (StageLastLevelRect[1].top + radius) * scale
  } Q ${(StageLastLevelRect[2].left + radius) * scale + offset} ${
    (StageLastLevelRect[2].top +
      radius * 2 +
      (StageLastLevelRect[1].top - StageLastLevelRect[2].top - radius * 2) /
        2) *
    scale
  } ${StageLastLevelRect[2].left * scale + offset} ${
    (StageLastLevelRect[2].top + radius) * scale
  }`,
  `M ${(StageLastLevelRect[2].left + radius) * scale + offset} ${
    (StageLastLevelRect[2].top + radius) * scale
  } Q ${(StageLastLevelRect[3].left + radius) * scale + offset} ${
    (StageLastLevelRect[3].top +
      radius * 2 +
      (StageLastLevelRect[2].top - StageLastLevelRect[2].top - radius * 2) /
        2) *
    scale
  } ${StageLastLevelRect[3].left * scale + offset} ${
    (StageLastLevelRect[3].top + radius) * scale
  }`,
];

export const StageLevelRect = [
  {
    left: 0,
    top: 170,
  },
  {
    left: 350,
    top: 170,
  },
  {
    left: 700,
    top: 170,
  },
];

export const StageLevelPath = (
  radius: number,
  scale: number,
  offset: number
) => [
  `M ${(StageLevelRect[0].left + radius) * scale + offset} ${
    (StageLevelRect[0].top + radius) * scale
  } Q ${(StageLevelRect[0].left + radius) * scale + offset} ${
    (StageLevelRect[0].top +
      radius * 2 +
      (StageLevelRect[1].top - StageLevelRect[0].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[1].left * scale + offset} ${
    (StageLevelRect[1].top + radius) * scale
  }`,
  `M ${(StageLevelRect[1].left + radius) * scale + offset} ${
    (StageLevelRect[1].top + radius) * scale
  } Q ${(StageLevelRect[2].left + radius) * scale + offset} ${
    (StageLevelRect[2].top +
      radius * 2 +
      (StageLevelRect[1].top - StageLevelRect[2].top - radius * 2) / 2) *
    scale
  } ${StageLevelRect[2].left * scale + offset} ${
    (StageLevelRect[2].top + radius) * scale
  }`,
];
